import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loaders-drm',
  templateUrl: './loadersDrm.component.html',
  styleUrls: ['./loadersDrm.css']
})
export class LoadersDrmComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
