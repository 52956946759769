import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {HttpClientModule} from '@angular/common/http';
import { CampainComponent } from './campain/campain.component';
import {OrderModule} from 'ngx-order-pipe';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';
import {SidebarModule} from 'ng-sidebar';
import {ReportsModule} from '../reports/reports.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgxDropzoneModule} from 'ngx-dropzone';

@NgModule({
  declarations: [CampainComponent],
  exports: [
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ReactiveFormsModule,
    HttpClientModule,
    OrderModule,
    Ng2SearchPipeModule,
    NgxPaginationModule,
    SidebarModule,
    ReportsModule,
    TranslateModule,
    NgxDropzoneModule

  ]
})
export class DataLifeCycleModule { }
