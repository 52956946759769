import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable} from "rxjs";
import {getCookieValue, Shared} from "../../shared";


@Injectable({
  providedIn: 'root'
})
export class UserMessageService {

  constructor(private http: HttpClient, private  router: Router, public jwtHelper: JwtHelperService) { }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getMessages(type: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/user-message/getMessages/' + localStorage.getItem('userId') + '/' + type , this.getOptions1());
  }
  updateMessage(id) {
    return this.http.put(Shared.API_URL + '/api/user-message/updateMessage/' + localStorage.getItem('userId') + '/' + id ,null,  this.getOptions1());
  }
  sendMessage(msg,files): Observable<any> {
    let formData = new FormData();
    if(files!=undefined){
      files.map( f => formData.append('file[]',f, f.name));
    }
    formData.append('data', msg);
    return this.http.post<any>(Shared.API_URL + '/api/user-message/sendMessage/' + localStorage.getItem('userId'), formData, this.getOptions1());
  }
  deleteMessage( ids: string, type: string): Observable<any> {
   // const data = "data=" + JSON.stringify(ids);
    return this.http.post(Shared.API_URL + '/api/user-message/deleteMessage/' + localStorage.getItem('userId') + '/'  + type , ids,  this.getOptions());
  }
  getNotificationMessage() : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/user-message/getNotificationMessage/' + localStorage.getItem('userId') , this.getOptions1());
  }

  clearNotification(not_ids: string) {
    return this.http.post(Shared.API_URL + '/api/user-message/clearNotification/' + localStorage.getItem('userId')  , not_ids,  this.getOptions());
  }
}
