import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UploadsService} from '../../services/uploads/uploads.service';
import {addProgressBar, addMultiProgressBar, conditionalValidator, setMultiProgressBarValue, addLoader, removeLoader, skipString} from '../../shared';
import {equals} from '@ngx-translate/core/lib/util';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ReportService } from 'src/app/services/reports/report.service';
import Swal from 'sweetalert2';
import * as echarts from 'echarts';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

declare const $: any;
type EChartsOption = echarts.EChartsOption;
@Component({
  selector: 'app-upload-dictionary',
  templateUrl: './upload-dictionary.component.html',
  styleUrls: ['./upload-dictionary.component.css']
})
export class UploadDictionaryComponent implements OnInit {
  lang: string;
  files: File[] = [];
  dictionaryFiles = [];
  idSelectedReport: string;
  arrayDetails = [];
  step = 0;
  totalProgress = 0;
  progressMessage: string;
  uploadedFileName: string;
  uploadedFullName: string;
  // start dimensions
  reportDimensionForm: FormGroup;
  uploadForm: FormGroup;
  conditions = [];
  listTypeDimensions: any;
  listReports = {
    reports: []
  };
  entitys = [];
  loadingEntities = true;
  listOwner: any;
  categoryId: any;
  categoriesIds = [];
  // end dimenpsions
  // error managment
  listIdsReportsError = [];
  listNameReportsError = [];
  countSelectedFiles = 0;
  reportsInPending = false;
  draftDictionary = 0;
  approvedDictionary = 0;
  archivedDictionary = 0;
  deletedDictionary = 0 ;
  pendingDictionary = 0;
  constructor(private http: HttpClient, private uploadService: UploadsService, private reportService: ReportService, private fb: FormBuilder,private translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
      this.setStatDictionaryToChart(this.draftDictionary, this.approvedDictionary, this.archivedDictionary, this.deletedDictionary, this.pendingDictionary)
    });
  
    this.uploadForm = fb.group({
      file_input: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.onCheckPendingModel('report');
    this.reportDimensionForm = this.fb.group({
      reports: this.fb.array([...this.createItems(1)])
    });
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }

    onCheckPendingModel(type) {
        this.uploadService.checkPendingModel(type).subscribe(
            result => {
                if (result === 0) {
                    this.GetAllDimensions();
                    this.GetOwnerUpload();
                    this.getEntities();
                    this.getDictionaryStatusChart();
                } else {
                    this.removeCheckedPendingReport();
                }
            },
            err => {
                this.GetAllDimensions();
                this.GetOwnerUpload();
                this.getEntities();
                this.getDictionaryStatusChart();
            }
        );
    }
    removeCheckedPendingReport() {
      let loadingText = this.lang === 'en' ? 'CLEANING&#160;&#160;MODELS . . .' : 'NETTOYAGE&#160;&#160;DES MODÈLES . . .';
        addLoader(loadingText);
        this.uploadService.removeAllReportEnPending().subscribe(
            result => {
                this.GetAllDimensions();
                this.GetOwnerUpload();
                this.getEntities();
                this.getDictionaryStatusChart();
            },
            err => {
                this.GetAllDimensions();
                this.GetOwnerUpload();
                this.getEntities();
                this.getDictionaryStatusChart();
            }
        );
    }
  /*********************** start add dimension to report **************************/
  private createItems(count: number): FormGroup[] {
    const arr = [];
    for (let i = 0; i < count; i++) {
      this.conditions[i] = false;
      // console.log('this.conditions[i] =>' + this.conditions[i], 'i =>' + i);
      arr.push(this.createItem(i));
    }
    return arr;
  }
  private createItem(i): FormGroup {
    return this.fb.group({
      reportName: '',
      reportId: '',
      ownerId: '',
      selectedDimension: [],
      categoriesIds: [],
      selectedDimensions: [null, [
        conditionalValidator(() => this.conditions[i],
          Validators.compose([Validators.required]))
      ]],
      entities: []
    });
  }
  get reports(): FormArray {
    return this.reportDimensionForm.get('reports') as FormArray;
  }
  // list entities in base
  getEntities() {
    this.loadingEntities = true;
    this.uploadService.getEntities().subscribe(
      d => {
        // @ts-ignore
        this.entitys = d;
        this.loadingEntities = false;
      }, err => {
        console.error(err);
      }
    );
  }
  /*******Owner********/
  onDimensionChange(event, i) {
    this.categoryId = event.id;
    // console.log(event.elementName);
    this.reports.get([i + '', 'selectedDimension']).patchValue(event.elementName);
    this.conditions[i] = event.elementName.length > 0;
    // console.log('this.conditions[i] => ' + this.conditions[i], 'i => ' + i, 'reportDimensionForm.invalid => ' + this.reportDimensionForm.invalid);
  }
  addElements(item, array, i) {
    let found = false;
    for ( i in array) {
      if (array[i].index == item.index) {
        array[i].elements = item.elements;
        found = true;
        break;
      }
    }
    if (!found) {
      array.push(item);
    }
  }
  onElementsChange(event, i) {
    const item = {
      index: i,
      elements: event
    };
    this.addElements(item, this.categoriesIds, i);
    this.reports.get([i + '', 'categoriesIds']).patchValue(this.categoriesIds);
  }
  GetOwnerUpload() {
    this.uploadService.getOwnerReportUpload().subscribe(
      data => {
        this.listOwner = data;
      },
      err => {},
      () => {
      });
  }

  /*******End Owner************/
  GetAllDimensions() {
    this.uploadService.getAllDimensions().subscribe(
      data => {
        this.listTypeDimensions = data;
      },
      err => {});
  }
  // get list report for dimensions
  getReportsForDimension() {
    this.uploadService.getReportsForDimension().subscribe(
      data => {
        $('#divGlobalProgress').remove();
        this.listReports.reports = data;
        this.reportDimensionForm = this.fb.group({
          reports: this.fb.array([...this.createItems(data.length)])
        });
      },
      err => {
        $('#divGlobalProgress').remove();
        console.error(err);
      },
      () => {
        if ( this.listReports.reports.length == 0) {
          $('#uploadDictionaryStatus').modal('show');
        } else {
          this.reportDimensionForm.setValue(this.listReports);
          $('#uploadDictionaryDimension').modal('show');
        }
      }
    );
  }
  addDimensionToReports() {
    const data = this.reportDimensionForm.value;
    // console.log(data);
    data.reports.map(e => {
      if (e.entities != null) {
        e.entityNames = [];
        for (let i = 0; i < e.entities.length; i++) {
          const ent = this.entitys.find(et => et.id == e.entities[i]);
          if (ent != null) {
            e.entityNames.push(skipString(ent.name));
          }
        }
      } else {
        e.entityNames = null;
      }
    });
    this.uploadService.addDimensionsToReports(data).subscribe(
      result => {
        $('#uploadDictionaryDimension').modal('hide');
      },
      err => {
        console.error(err);
        $('#uploadDictionaryDimension').modal('hide');
      },
      () => {
          $('#uploadDictionaryStatus').modal('show');
        // if ((this.listIdsReportsError.length > 0) || this.reportsInPending) {
        //   setTimeout(() => {
        //     Swal.fire(
        //       'You have Dictionarys that have not been successfully uploaded!',
        //       '' + this.listNameReportsError,
        //       'error'
        //     );
        //   }, 5);
        // } else {
        //   Swal.fire(
        //     'All Dictionarys have been successfully uploaded!',
        //     '',
        //     'success'
        //   );
        // }
          // show modal
        this.getDictionaryStatusChart();
      }
    );
  }
  onDiscardAddDimensions() {
      $('#uploadDictionaryStatus').modal('show');
      // console.log(this.dictionaryFiles);
    // if ((this.listIdsReportsError.length > 0) || this.reportsInPending) {
    //   setTimeout(() => {
    //     Swal.fire(
    //       'You have Dictionarys that have not been successfully uploaded!',
    //       '' + this.listNameReportsError,
    //       'error'
    //     );
    //   }, 5);
    // } else {
    //   Swal.fire(
    //     'All Dictionarys have been successfully uploaded!',
    //     '',
    //     'success'
    //   );
    // }
  }
    hideUploadedStatus() {
        $('#uploadDictionaryStatus').modal('hide');
    }
  /*********************** end add dimension to report **************************/

  // ************ start pass to next report */
  selectNextReportForUpoadOrFinishTrigger() {
    setTimeout(() => {
      if (this.files.length > 0) {
        const selectedFile = this.files.splice(0, 1);
        this.loadSelectedReportInBase(selectedFile[0]);
      } else {
        if (this.listIdsReportsError.length > 0) {
          const dataReport = {
            username: localStorage.getItem('username'),
            list: this.listIdsReportsError,
          };
          $('#divGlobalProgress').remove();
          addProgressBar(10, this.lang === 'en' ? 'Remove report details for cancelled reports...' : 'Supprimer les détails du rapport pour les rapports annulés...');

          $('#ldp').children().eq(0).toggleClass('bg-danger');
          let subscription19$ = this.reportService.removeMultipleReportDetailsByListIdsReport(dataReport).subscribe(
            data1 => {
              $('#divGlobalProgress').remove();
              addProgressBar(20, this.lang === 'en' ? 'Remove report details count for cancelled reports...' : 'Supprimer le compte des détails du rapport pour les rapports annulés...');

              $('#ldp').children().eq(0).toggleClass('bg-danger');
              let subscription20$ = this.reportService.removeMultipleReportDetailsCountByListIdsReport(dataReport).subscribe(
                data2 => {
                  $('#divGlobalProgress').remove();
                  addProgressBar(40, this.lang === 'en' ? 'Remove report object for cancelled reports...' : 'Supprimer l\'objet du rapport pour les rapports annulés...');

                  $('#ldp').children().eq(0).toggleClass('bg-danger');
                  let subscription21$ = this.reportService.removeMultipleReportObjectByListIdsReport(dataReport).subscribe(
                    data3 => {
                      $('#divGlobalProgress').remove();
                      addProgressBar(60, this.lang === 'en' ? 'Remove report object filter for cancelled reports...' : 'Supprimer le filtre de l\'objet du rapport pour les rapports annulés...');
                      $('#ldp').children().eq(0).toggleClass('bg-danger');
                      let subscription22$ = this.reportService.removeMultipleReportObjectFiltredByListIdsReport(dataReport).subscribe(
                        data4 => {
                          $('#divGlobalProgress').remove();
                          addProgressBar(80, this.lang === 'en' ? 'Remove report details filter for cancelled reports...' : 'Supprimer le filtre des détails du rapport pour les rapports annulés...');
                          $('#ldp').children().eq(0).toggleClass('bg-danger');
                          let subscription23$ = this.reportService.removeMultipleReportDetailFiltredByListIdsReport(dataReport).subscribe(
                            data5 => {
                              $('#divGlobalProgress').remove();
                              addProgressBar(100, this.lang === 'en' ? 'Remove cancelled reports...' : 'Supprimer les rapports annulés...');
                              $('#ldp').children().eq(0).toggleClass('bg-danger');
                              // this.subscription.add(subscription19$);
                              let subscription18$ = this.reportService.removeMultipleByListIdsReport(dataReport).subscribe(
                                data => {
                                  this.launchGlobalTrigger();
                                },
                                err => {
                                  this.launchGlobalTrigger();
                                }
                              );
                              // this.subscription.add(subscription18$);
                            },
                            err => {
                              this.launchGlobalTrigger();
                            }
                          );
                          // this.subscription.add(subscription23$);
                        },
                        err => {
                          this.launchGlobalTrigger();
                        }
                      );
                      // this.subscription.add(subscription22$);
                    },
                    err => {
                      this.launchGlobalTrigger();
                    }
                  );
                  // this.subscription.add(subscription21$);
                },
                err => {
                  this.launchGlobalTrigger();
                }
              );
              // this.subscription.add(subscription20$);
            },
            err => {
              this.launchGlobalTrigger();
            }
          );
        } else {
          this.launchGlobalTrigger();
       }
      }
    }, 50);
  }
  // start lunch global trigger
  launchGlobalTrigger() {
    $('#divGlobalProgress').remove();
    addProgressBar(5, this.lang === 'en' ? 'Add Global Object Model Tree...' : 'Ajouter l\'arbre de modèle d\'objet global...');
    this.uploadService.addObjectsJoinsTreeDict().subscribe(
      resul => {
        $('#divGlobalProgress').remove();
        addProgressBar(50, this.lang === 'en' ? 'Count fields by recordName...' : 'Compter les champs par nom d\'enregistrement...');
        this.uploadService.setCountFieldsByRecordNameDict().subscribe(
        data => {
          $('#divGlobalProgress').remove();
          addProgressBar(100, this.lang === 'en' ? 'Count fields by recordName Completed successfully...' : 'Compter les champs par nom d\'enregistrement terminé avec succès...');

          setTimeout(() => {
              // this.dictionaryFiles.push({
              //     name: this.uploadedFileName,
              //     status: 'OK',
              //     detail: 'UPLOAD COMPLETE SUCCESS'
              // });
            this.getReportsForDimension();
          }, 50);
        },
        err => {
          $('#divGlobalProgress').remove();
          addProgressBar(100, this.lang === 'en' ? 'Count fields by recordName Error...' : 'Erreur lors du comptage des champs par nom d\'enregistrement...');

          $('#ldp').children().eq(0).toggleClass('bg-danger');
          setTimeout(() => {
              // this.dictionaryFiles.push({
              //     name: this.uploadedFileName,
              //     status: 'OK',
              //     detail: 'UPLOAD COMPLETE SUCCESS'
              // });
            this.getReportsForDimension();
          }, 50);
        }
        );
      },
      errJoin => {
        $('#divGlobalProgress').remove();
        addProgressBar(50, this.lang === 'en' ? 'Error Object Joins Tree !!! Launch triggers...' : 'Erreur dans l\'arbre des jointures d\'objets !!! Lancer les déclencheurs...');
        $('#ldp').children().eq(0).toggleClass('bg-danger');
        this.uploadService.setCountFieldsByRecordNameDict().subscribe(
        data => {
          $('#divGlobalProgress').remove();
          addProgressBar(100, this.lang === 'en' ? 'Error Object Joins Tree !!! Count fields by recordName Completed successfully...' : 'Erreur dans l\'arbre des jointures d\'objets !!! Compter les champs par nom d\'enregistrement terminé avec succès...');

          $('#ldp').children().eq(0).toggleClass('bg-danger');
          setTimeout(() => {
              // this.dictionaryFiles.push({
              //     name: this.uploadedFileName,
              //     status: 'OK',
              //     detail: 'UPLOAD COMPLETE SUCCESS'
              // });
            this.getReportsForDimension();
          }, 50);
        },
        err => {
          $('#divGlobalProgress').remove();
          addProgressBar(100, this.lang === 'en' ? 'Error Object Joins Tree !!! Count fields by recordName Error...' : 'Erreur dans l\'arbre des jointures d\'objets !!! Erreur lors du comptage des champs par nom d\'enregistrement...');
          $('#ldp').children().eq(0).toggleClass('bg-danger');
          setTimeout(() => {
              // this.dictionaryFiles.push({
              //     name: this.uploadedFileName,
              //     status: 'OK',
              //     detail: 'UPLOAD COMPLETE SUCCESS'
              // });
            this.getReportsForDimension();
          }, 100);
        }
        );
      }
      );
  }
  // end lunch global trigger
  // ************ end pass to newt report */
  onSelectDictionary(event) {
    this.files = [];
    this.dictionaryFiles = [];
    this.listIdsReportsError = [];
    this.files.push(...event.addedFiles);
    // console.log(event.addedFiles[0].name);
    this.countSelectedFiles = this.files.length;
    if (this.files.length > 0) {
      addMultiProgressBar(this.files, 0);
      const selectedFile = this.files.splice(0, 1);
      this.reportsInPending = false;
      this.loadSelectedReportInBase(selectedFile[0]);
    }
  }
  loadSelectedReportInBase(report) {
    // start update chart stat
    this.pendingDictionary++;
    this.setStatDictionaryToChart(this.draftDictionary, this.approvedDictionary, this.archivedDictionary, this.deletedDictionary, this.pendingDictionary);
    // end update chart stat
    this.step = 0;
    this.totalProgress = 0;
    // this.dictionaryFiles.push({etat: 'pending', file: report});
    // const extension = this.files[indexKey].name.split('.')[1];
    this.uploadedFileName = report.name.split('.')[0].trim();
    const data = this.uploadedFileName.split('_');
    if (data.length === 5) {
      if (data[4].length > 7) {
        var effDate = data[4].slice(0, 8);
        this.uploadedFileName = data[0] + '_' + data[1] + '_' + data[2] + '_' + data[3] + '_' + effDate;
      } else {
          if (this.countSelectedFiles === 1) {
            setMultiProgressBarValue(this.uploadedFileName, 100, this.uploadedFileName + ': ' + (this.lang === 'en' ? 'THE DATE of Data Dictionary is Malformated !!!' : 'LA DATE du dictionnaire de données est mal formatée !!!'));
              $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
              setTimeout(() => {
                  $('#divGlobalProgress').remove();
                  $('#uploadDictionaryStatus').modal('show');
              }, 1000);

              // show modal
          } else if (this.countSelectedFiles > 1) {
            setMultiProgressBarValue(this.uploadedFileName, 100, this.uploadedFileName + ': ' + (this.lang === 'en' ? 'THE DATE of Data Dictionary is Malformated !!!' : 'LA DATE du dictionnaire de données est mal formatée !!!'));
              $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
              this.selectNextReportForUpoadOrFinishTrigger();
          }
          // this.listNameReportsError.push(this.uploadedFileName);
          this.dictionaryFiles.push({
              name: this.uploadedFileName,
              status: 'KO',
              detail: this.lang === 'en' ? 'THE DATE OF DATA DICTIONARY IS MALFORMATED' : 'LA DATE DU DICTIONNAIRE DE DONNÉES EST MAL FORMATÉE'

          });
      }
      const info = {
        application: data[0] ,
        reportName: data[0] + '_' + data[1] + '_' + data[2] + '_' + data[3],
        applicationCode: this.uploadedFileName,
        database: data[3],
        typeDatabase: data[1],
        environment: data[2],
        dateReport: data[4]
      };
      this.progressMessage = this.lang === 'en' ? 'Upload Data Dictionary: ' + this.uploadedFileName : 'Télécharger le dictionnaire de données: ' + this.uploadedFileName;

        this.uploadService.uploadDictionary(info).subscribe(
          result => {
            if (result.status == 'saved success') {
              setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.progressMessage + ' SUCCESS' : this.progressMessage + ' SUCCÈS');
              setTimeout(() => {
                // 374
                if ($('#loaderProgress').height() > 374) {
                  $('#loaderProgress').css('overflow-y', 'scroll');
                }
                this.step = 0;
                this.totalProgress = 0;
                this.progressMessage = this.lang === 'en' ? this.uploadedFileName + ': Add report details...' : this.uploadedFileName + ': Ajouter les détails du rapport...';
                this.idSelectedReport = result.reportId.$id;
                setMultiProgressBarValue(this.uploadedFileName, 0, this.progressMessage);
                this.getReportDetailsByReport(report);
              }, 10);
            }
          },
          error => {
            this.reportsInPending = true;
            setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.uploadedFileName + ': CREATION DICTIONARY ERROR !!!' : this.uploadedFileName + ': ERREUR DE CREATION DU DICTIONNAIRE !!!');
            $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
            // this.listNameReportsError.push(this.uploadedFileName);
              this.dictionaryFiles.push({
                  name: this.uploadedFileName,
                  status: 'KO',
                  detail: this.lang === 'en' ? 'CREATION DICTIONARY ERROR' : 'ERREUR DE CREATION DU DICTIONNAIRE'
              });
            this.selectNextReportForUpoadOrFinishTrigger();
          },
        );
    } else {
      if (this.countSelectedFiles === 1) {
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.uploadedFileName + ': Title Malformated !!!' : this.uploadedFileName + ': Titre mal formaté !!!');
        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
        setTimeout(() => {
          $('#divGlobalProgress').remove();
            $('#uploadDictionaryStatus').modal('show');
        }, 1000);

          // show modal
      } else if (this.countSelectedFiles > 1) {
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.uploadedFileName + ': Title Malformated !!!' : this.uploadedFileName + ': Titre mal formaté !!!');
        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
        this.selectNextReportForUpoadOrFinishTrigger();
      }
      // this.listNameReportsError.push(this.uploadedFileName);
        this.dictionaryFiles.push({
            name: this.uploadedFileName,
            status: 'KO',
            detail: this.lang === 'en' ? 'MALFORMATED DICTIONARY' : 'DICTIONNAIRE MAL FORMATÉ'
        });
    }
  }
  // report detail
  getReportDetailsByReport(report) {
    const reader = new FileReader();
    reader.readAsText(report);
    reader.onload = () => {
      const csvData = reader.result;
      const tabReportDetail = [];
      this.arrayDetails = (csvData as string).split(/\r\n|\n/);
      // console.log('countStep => ' + totals, 'countDetails => ' + this.arrayDetails.length, 'step => ' + this.step);
      if (this.arrayDetails.length > 0) {
        this.arrayDetails.splice(0, 1);
        const totals = this.arrayDetails.length / 1000;
        this.step = 100 / totals;
          // Math.round(100 / totals);
        const recupDetails = this.arrayDetails.splice(0, 1000);
        // recupDetails.map(item => (item as string).split(/\t/));
        // for (const index in recupDetails) {
        //   let record1 = (recupDetails[index] as string).split(/\t/);
        //   record1.map(item => this.skipString(item));
        //   tabReportDetail.push(record1);
        // }
        let newRecord1 = [];
        for (const index in recupDetails) {
          newRecord1 = [];
          const record1 = (recupDetails[index] as string).split(/\t/);
          record1.map(item => {
            newRecord1.push(skipString(item));
          });
          // console.log(record1);
          tabReportDetail.push(newRecord1);
        }
        const tabReportDetailJson = JSON.stringify(Object.assign({}, tabReportDetail));
        // console.table(tabReportDetail);
        this.uploadDictionaryDetail(tabReportDetailJson);
      }
      // console.table(csvRecordsArray);
    };
  }
  uploadDictionaryDetail(tabReportDetailJson) {
    // console.table(tabReportDetailJson);
    this.uploadService.uploadDictionaryDetail(this.idSelectedReport, tabReportDetailJson).subscribe(
      result => {
        // console.log(result);
        const tabReportDetail = [];
        const newRecupDetails = this.arrayDetails.splice(0, 1000);
        // still have more reportdetai
        if (newRecupDetails.length > 0) {
          // console.log(this.totalProgress);
          this.totalProgress += this.step;
          // setProgressBarValue(Math.round(this.totalProgress));
          setMultiProgressBarValue(this.uploadedFileName, Math.round(this.totalProgress), this.lang === 'en' ? this.uploadedFileName + ': Add report details... ' + this.arrayDetails.length + ' reports left' : this.uploadedFileName + ': Ajouter les détails du rapport... ' + this.arrayDetails.length + ' rapports restants');
          let newRecord = [];
          for (const index in newRecupDetails) {
            newRecord = [];
            const record = (newRecupDetails[index] as string).split(/\t/);
            record.map(item => {
              newRecord.push(skipString(item));
            });
            // console.log(record);
            tabReportDetail.push(newRecord);
          }
          // console.table(tabReportDetail);
          const tabReportDetailJson2 = JSON.stringify(Object.assign({}, tabReportDetail));
          this.uploadDictionaryDetail(tabReportDetailJson2);
        } else {
          this.step = 0;
          this.totalProgress = 0;
          this.progressMessage = this.lang === 'en' ? this.uploadedFileName + ': Count All Tables...' : this.uploadedFileName + ': Compter toutes les tables...';
          setMultiProgressBarValue(this.uploadedFileName, 0, this.progressMessage);
          this.uploadService.setCountAllTables().subscribe(
            data1 => {
              this.progressMessage = this.lang === 'en' ? this.uploadedFileName + ': Count Empty Tables...' : this.uploadedFileName + ': Compter les tables vides...';
              setMultiProgressBarValue(this.uploadedFileName, 20, this.progressMessage);
              this.uploadService.setCountEmptyTables().subscribe(
                data2 => {
                  this.progressMessage = this.lang === 'en' ? this.uploadedFileName + ': Count Not Empty Tables...' : this.uploadedFileName + ': Compter les tables non vides...';

                setMultiProgressBarValue(this.uploadedFileName, 40, this.progressMessage);
                this.uploadService.setCountNotEmptyTables().subscribe(
                  data3 => {
                    setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.uploadedFileName + ': UPLOAD COMPLETE SUCCESS' : this.uploadedFileName + ': TÉLÉCHARGEMENT TERMINÉ AVEC SUCCÈS');
                    $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-success');
                      this.dictionaryFiles.push({
                          name: this.uploadedFileName,
                          status: 'OK',
                          detail: this.lang === 'en' ? 'UPLOAD COMPLETE SUCCESS' : 'TÉLÉCHARGEMENT TERMINÉ AVEC SUCCÈS'
                      });
                    // start update chart stat
                    this.pendingDictionary--;
                    this.draftDictionary++;
                    this.setStatDictionaryToChart(this.draftDictionary, this.approvedDictionary, this.archivedDictionary, this.deletedDictionary, this.pendingDictionary);
                    // end update chart stat
                    this.selectNextReportForUpoadOrFinishTrigger();
                  },
                  err3 => {
                    setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.uploadedFileName + ': COUNT STATISTIC NOT EMPTY TABLES ERROR !!! upload canceled' : this.uploadedFileName + ': ERREUR DE COMPTE DES STATISTIQUES DES TABLES NON VIDES !!! Téléchargement annulé');

                    $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
                    this.listIdsReportsError.push(this.idSelectedReport);
                    // this.listNameReportsError.push(this.uploadedFileName);
                      this.dictionaryFiles.push({
                          name: this.uploadedFileName,
                          status: 'KO',
                          detail: this.lang === 'en' ? 'COUNT STATISTIC NOT EMPTY TABLES ERROR' : 'ERREUR DE COMPTE DES STATISTIQUES DES TABLES NON VIDES'
                      });
                    // start update chart stat
                    this.pendingDictionary--;
                    this.setStatDictionaryToChart(this.draftDictionary, this.approvedDictionary, this.archivedDictionary, this.deletedDictionary, this.pendingDictionary);
                    // end update chart stat
                    this.selectNextReportForUpoadOrFinishTrigger();
                  }
                );
                },
                err2 => {
                  setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.uploadedFileName + ': COUNT STATISTIC EMPTY TABLES ERROR !!! upload canceled' : this.uploadedFileName + ': ERREUR DE COMPTE DES STATISTIQUES DES TABLES VIDES !!! Téléchargement annulé');
                  $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
                  this.listIdsReportsError.push(this.idSelectedReport);
                  // this.listNameReportsError.push(this.uploadedFileName);
                    this.dictionaryFiles.push({
                        name: this.uploadedFileName,
                        status: 'KO',
                        detail: this.lang === 'en' ? 'COUNT STATISTIC EMPTY TABLES ERROR' : 'ERREUR DE COMPTE DES STATISTIQUES DES TABLES VIDES'
                    });
                  // start update chart stat
                  this.pendingDictionary--;
                  this.setStatDictionaryToChart(this.draftDictionary, this.approvedDictionary, this.archivedDictionary, this.deletedDictionary, this.pendingDictionary);
                  // end update chart stat
                  this.selectNextReportForUpoadOrFinishTrigger();
                }
              );
            },
            err2 => {
              setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.uploadedFileName + ': COUNT STATISTIC ALL TABLES ERROR !!! upload canceled' : this.uploadedFileName + ': ERREUR DE COMPTE DES STATISTIQUES DE TOUTES LES TABLES !!! Téléchargement annulé');
              $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
              this.listIdsReportsError.push(this.idSelectedReport);
              // this.listNameReportsError.push(this.uploadedFileName);
                this.dictionaryFiles.push({
                    name: this.uploadedFileName,
                    status: 'KO',
                    detail: this.lang === 'en' ? 'COUNT STATISTIC ALL TABLES ERROR' : 'ERREUR DE COMPTE DES STATISTIQUES DE TOUTES LES TABLES'

                });
              // start update chart stat
              this.pendingDictionary--;
              this.setStatDictionaryToChart(this.draftDictionary, this.approvedDictionary, this.archivedDictionary, this.deletedDictionary, this.pendingDictionary);
              // end update chart stat
              this.selectNextReportForUpoadOrFinishTrigger();
            }
          );
        }
      },
      err => {
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? this.uploadedFileName + ': CREATION DICTIONARY ERROR !!! upload canceled' : this.uploadedFileName + ': ERREUR DE CREATION DU DICTIONNAIRE !!! Téléchargement annulé');

        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
        this.listIdsReportsError.push(this.idSelectedReport);
          this.dictionaryFiles.push({
              name: this.uploadedFileName,
              status: 'KO',
              detail: this.lang === 'en' ? 'CREATION DICTIONARY ERROR' : 'ERREUR DE CREATION DU DICTIONNAIRE'
          });
        // this.listNameReportsError.push(this.uploadedFileName);
        // start update chart stat
        this.pendingDictionary--;
        this.setStatDictionaryToChart(this.draftDictionary, this.approvedDictionary, this.archivedDictionary, this.deletedDictionary, this.pendingDictionary);
        // end update chart stat
        this.selectNextReportForUpoadOrFinishTrigger();

      }
    );
  }
  // triggers aggrregate
  onSetCountFieldsByRecordNameDict() {
    this.uploadService.setCountFieldsByRecordNameDict().subscribe(
      data => {
      },
      err => {
        console.error(err);
      }
    );
  }
  // skipString(str) {
  //   if (str == null) {
  //     return str;
  //   }
  //   let name = str.toString().replace(/\//g, '__________');
  //   name = name.replace(/&/g, '::::::::');
  //     name = name.replace(/\+/g, '==========');
  //   return name;
  // }
  // onSelect2(event) {
  //   this.files = [];
  //   this.dictionaryFiles = [];
  //   this.files.push(...event.addedFiles);
  //   for (let i = 0; i < this.files.length; i++) {
  //     this.dictionaryFiles.push({etat: 'pending', file: this.files[i]});
  //     this.uploadService.uploadDictionary(this.files[i]).subscribe(
  //       data => {
  //         if (data === 'saved success') {
  //           this.dictionaryFiles[i].etat = 'success';
  //         } else {
  //           this.dictionaryFiles[i].etat = 'error';
  //         }
  //       },
  //       err => {
  //         console.error(err);
  //         this.dictionaryFiles[i].etat = 'error';
  //       },
  //       () => {
  //       }
  //     );
  //   }
  // }
  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    // console.log(this.files);
  }
  // global chart status
  getDictionaryStatusChart() {
    this.uploadService.getStatDictionaryToChart().subscribe(
      result => {
        // console.log(result);
        this.draftDictionary = result.draftDictionary;
        this.approvedDictionary = result.approvedDictionary;
        this.archivedDictionary = result.archivedDictionary;
        this.deletedDictionary = result.deletedDictionary;
        this.pendingDictionary = result.pendingDictionary;
        this.setStatDictionaryToChart(this.draftDictionary, this.approvedDictionary, this.archivedDictionary, this.deletedDictionary, this.pendingDictionary);
        removeLoader();
      },
        err => {
          removeLoader();
        }
    );
  }
  setStatDictionaryToChart(draftDictionary, approvedDictionary, archivedDictionary, deletedDictionary, pendingDictionary) {
    // tslint:disable-next-line:no-non-null-assertion
    const chartDom = document.getElementById('chart-dictionary')!;
    const myChart = echarts.init(chartDom);
    let option: EChartsOption;

    option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold',
              formatter(params) {
                return  params.name + '\n\n' + params.value;
              },
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: draftDictionary, name: this.lang === 'en' ? 'Draft' : 'Brouillon' },
            { value: approvedDictionary, name: this.lang === 'en' ? 'Approved' : 'Approuvé' },
            { value: archivedDictionary, name: this.lang === 'en' ? 'Archived' : 'Archivé' },
            { value: deletedDictionary, name: this.lang === 'en' ? 'Deleted' : 'Supprimé' },
            { value: pendingDictionary, name: this.lang === 'en' ? 'Pending' : 'En attente' }
          ],
          color: ['blue', 'green', 'orange', 'red', 'gray']
        }
      ]
    };
    // tslint:disable-next-line:no-unused-expression
    option && myChart.setOption(option);
  }
}
