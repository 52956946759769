import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

declare const CryptoJSAesJson: any;

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  constructor() { }

  secretKey = "g1254*-2fnknfnfdvd4454554futytggg";
  // CryptoJSAesJson = {
  //   stringify(cipherParams) {
  //     // @ts-ignore
  //     let j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64), iv: undefined,
  //       s: undefined
  //     };
  //     // @ts-ignore
  //     if (cipherParams.iv) { j.iv = cipherParams.iv.toString(); }
  //     // @ts-ignore
  //     if (cipherParams.salt) { j.s = cipherParams.salt.toString(); }
  //     return JSON.stringify(j).replace(/\s/g, '');
  //   },
  //   parse(jsonStr) {
  //     let j = JSON.parse(jsonStr);
  //     // @ts-ignore
  //     let cipherParams = CryptoJS.lib.cipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
  //     if (j.iv) { cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv); }
  //     if (j.s) { cipherParams.salt = CryptoJS.enc.Hex.parse(j.s); }
  //     return cipherParams;
  //   }
  // };

  set(value){
    var key = CryptoJS.enc.Utf8.parse(this.secretKey.toString().substring(0, 32));
    const iv = CryptoJS.enc.Utf8.parse(this.secretKey.toString().substring(0, 32));
    var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(value){
    var key = CryptoJS.enc.Utf8.parse(this.secretKey.toString().substring(0, 32));
    const iv = CryptoJS.enc.Utf8.parse(this.secretKey.toString().substring(0, 32));
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    // return  decrypted;
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encryptUsingAES256(val: string) {
    const _key = CryptoJS.enc.Utf8.parse(this.secretKey.toString().substring(0, 32));
    const _iv = CryptoJS.enc.Utf8.parse(this.secretKey.toString().substring(0, 32));
    const encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(val), _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
    return encrypted.toString();
  }
  decryptUsingAES256(val: string) {
    const _key = CryptoJS.enc.Utf8.parse(this.secretKey.toString().substring(0, 32));
    const _iv =  CryptoJS.enc.Utf8.parse(this.secretKey.toString().substring(0, 32));

    return CryptoJS.AES.decrypt(
      val, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
  }
  decryptPhp(val){
   return JSON.parse(CryptoJS.AES.decrypt(val, this.secretKey, {format: CryptoJSAesJson}).toString(CryptoJS.enc.Utf8));
   }
   encryptPhp(val) {
    return CryptoJS.AES.encrypt(JSON.stringify(val), this.secretKey, {format: CryptoJSAesJson}).toString();
   }
}
