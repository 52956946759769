import { Injectable } from '@angular/core';
import {filter} from 'rxjs/operators';
import {NavigationEnd, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class RouterhistoryService {

  private history = [];
  listVisitedRouter = [];
  showInputSearchInHeader = true;
  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  constructor(private router: Router, private http: HttpClient) {}

  public loadRouting(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.history = [...this.history, urlAfterRedirects];
        const words = urlAfterRedirects.split('/');
        let menu = words[1];
        let sousmenu;
        // start check component search
        if (menu == 'global-search') {
          $('#showInputSearchInHeader').hide();
        } else {
          $('#showInputSearchInHeader').show();
        }
        // end check component search
        if (menu == 'dashboard') {
          menu = 'Dashboard';
          sousmenu = 'Dashboard';
        } else if (menu == 'admin-model') {
          menu = 'Data Model';
          sousmenu = 'Model Administration';
        } else if (menu == 'data-governance') {
          menu = 'Data Governance';
          sousmenu = words[2];
          if (words[2] == 'data-lineage') {
            // sousmenu = 'Data Lineage';
          } else if (words[2] == 'project') {
            sousmenu = 'Project';
          } else if (words[2] == 'country') {
            sousmenu = 'Country';
          } else if (words[2] == 'stewards') {
            sousmenu = 'StewardShip AND OwnerShip';
          } else if (words[2] == 'data-roles') {
            sousmenu = 'Data Roles';
          } else if (words[2] == 'entity') {
            sousmenu = 'Entity';
          } else if (words[2] == 'dimension') {
            sousmenu = 'Dimension';
          }
        } else if (menu == 'business-Glossary') {
          menu = 'Data Governance';
          if (words[2] == 'Functional-map-group') {
            sousmenu = 'Functional Maps List';
          } else if (words[2] == 'Business-Terms') {
            sousmenu = 'Business Glossary';
          } else if (words[2] == 'Functional-map') {
              sousmenu = 'Functional Maps';
          }
        } else if (menu == 'data-lineage') {
          menu = 'Data Governance';
          if (words[2] == 'data-lineage-list') {
                sousmenu = 'Data Lineage List';
            } else if (words[2] == 'data-lineage') {
                sousmenu = 'Data Lineage';
            }
        } else if (menu == 'data-catalog') {
          menu = 'Data Governance';
          sousmenu = 'Data Catalog';
        } else if (menu == 'model-admin') {
          sousmenu = 'model-admin';
        } else if (menu == 'ddtm') {
          menu = 'Data Model';
          sousmenu = 'Data Dictionary';
        } else if (menu == 'object-models') {
          menu = 'Data Modeling';
          sousmenu = words[words.length - 1];
          if (sousmenu == 'model-designer') {
            sousmenu = 'Models Designer';
          } else if (sousmenu == 'object-models') {
            sousmenu = 'Object Model';
          }
        } else if (menu == 'global-designer') {
          menu = 'Data Modeling';
          sousmenu = 'Diagrams Designer';
        } else if (menu == 'data-transfert') {
          menu = 'Architecture';
          sousmenu = words[2];
          if (sousmenu == 'home-transfert') {
            sousmenu = 'Data Flow';
          } else if (sousmenu == 'applicationdefinition') {
            sousmenu = 'System/Definition';
          } else if (sousmenu == 'infrastructure') {
            sousmenu = 'Infrastructure';
          }
        } else if (menu == 'drm') {
          menu = 'Data Regulation';
          sousmenu = words[2];
          if (words[2] == 'drm-definition') {
            sousmenu = 'Data Classification/Definition';
          } else if ((words[2] == 'drm-classification') || (words[2] == 'drm-classificationdrm')) {
            sousmenu = 'Data Classification';
          } else if (words[2] == 'drm-security') {
            sousmenu = 'Data Security Risk';
          } else if (words[2] == 'drm-rules') {
            sousmenu = 'Rules';
          } else if (words[2] == 'drm-retention-period') {
            sousmenu = 'Retention Period';
          }
        } else if (menu == 'campain') {
          menu = 'Data Lifecycle';
          sousmenu = 'Campain';
        } else if (menu == 'userslist') {
          menu = 'Settings';
          sousmenu = 'Users';
        } else if (menu == 'usersroles') {
          menu = 'Settings';
          sousmenu = 'Roles';
        } else if (menu == 'metadata') {
          menu = 'Settings';
          sousmenu = 'Metadata';
        } else if (menu == 'user-message') {
          menu = 'user-message';
          sousmenu = 'Messages';
        } else if (menu == 'profileUser') {
          menu = 'Profile';
          sousmenu = 'Profile';
        } else if (menu == 'global-search') {
          menu = 'Global Search';
          sousmenu = 'Global Search';
        } else if (menu == 'Glossaires') {
            menu = 'Glossaires';
            sousmenu = 'glossaire';
        }

        if ((menu != 'auth') && (menu != '') && (menu != 'anonym-board') && (menu != 'uploads') && (menu != '') && (sousmenu != '')) {
          let dataRouter = {
            dataMenu: menu,
            dataSousmenu: sousmenu,
            dataPath: urlAfterRedirects,
            username: getCookieValue('username')
          };
          this.setNewNavigation(dataRouter).subscribe(
            data => {
              localStorage.setItem('dataMenu', data.menu);
              localStorage.setItem('dataSoumenu', data.sousmenu);
              localStorage.setItem('dataTips', data.tips);
            },
            err => {
              console.error(err);
            }
          );
        }
      });
  }
  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/index';
  }
  // add new path (navigation router) in DB
  setNewNavigation(dataRouter: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataRouter);
    return this.http.post<any>(Shared.API_URL + '/api/analytics/setNewNavigation' , data, this.getOptions());
  }
  // update tips status
  setUpdateTipsMenuByUser(dataRouter: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataRouter);
    return this.http.post<any>(Shared.API_URL + '/api/analytics/setUpdateTipsMenuByUser' , data, this.getOptions());
  }
  ////////////////////////////////// tips /////////////////////////////////////////////
  // setting tips
  getListTips(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/analytics/getListTips/' + getCookieValue('username'), this.getOptions1());
  }
  updateTipsAll(dataPut): Observable<any> {
    const data = 'data=' + JSON.stringify(dataPut);
    return this.http.put<any>(Shared.API_URL + '/api/analytics/updateTipsAll', data, this.getOptions());
  }
  updateTipsMain(dataPut): Observable<any> {
    const data = 'data=' + JSON.stringify(dataPut);
    return this.http.put<any>(Shared.API_URL + '/api/analytics/updateTipsMain', data, this.getOptions());
  }
  ///////////////////////////////// end tips /////////////////////////////////////////
  // new dashboar get all projects
  getAllProjectsInDash(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/projects-in-tips/getAllProjectsDash/' + localStorage.getItem('userId'), this.getOptions());
  }
}
