export class DictionaryItem {
  system: string;
  report: string;
  table: string;
  field: string;
  systemId: string;
  reportId: string;
  tableId: string;
  fieldId: string;
  group:number;
  id: string;

  constructor(system: string, report: string, table: string, field: string, systemId: string, reportId: string, tableId: string, fieldId: string, group: number, id: string) {
    this.system = system;
    this.report = report;
    this.table = table;
    this.field = field;
    this.systemId = systemId;
    this.reportId = reportId;
    this.tableId = tableId;
    this.fieldId = fieldId;
    this.group = group;
    this.id = id;
  }
}
