import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ListusersService } from '../../services/users/listusers.service';
import { UsersList } from '../../models/usersList';
import { UseraddService } from '../../services/users/useradd.service';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { Add } from '../../models/add';
import { Router } from '@angular/router';
import { UserremoveService } from '../../services/users/userremove.service';
import { CustomValidators } from '../../custom-validators';
import {addLoader, getCookieValue, refreshComponent, removeLoader, Shared} from '../../shared';
import { RoleService } from '../../services/users/role.service';
import Swal from 'sweetalert2';
import * as d3 from 'd3';
import { RouterhistoryService } from '../../services/appService/routerhistory.service';
import { OrderPipe } from 'ngx-order-pipe';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const swal: any;


@Component({
  selector: 'app-userslist',
  templateUrl: './userslist.component.html',
  styleUrls: ['./userslist.component.css']
})
export class UserslistComponent implements OnInit, AfterViewInit {
  lang: string;
  public frmSignup: FormGroup;
  userForm: FormGroup;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  password: string;
  roles: string;
  listusers: UsersList[];
  dataList: any;
  postData: string;
  add: Add;
  id: string;
  selectedApp: string;
  listAppli = ['Osmose', 'PeopleSoft'];
  useroles: any[];
  selectedRole: any;
  stacks: Object;
  selectedStack: any;
  postUserNameValidation: string;
  rolesEdit: any;
  editMode = false;
  userId: string;
  nameError = false;
  nameSuccess = false;
  emailError = false;
  emailSuccess = false;
  _openedSide = false;
  showcirclePack = true;
  constructor(private listuserService: ListusersService, private routerHistoryService: RouterhistoryService,
    private userAddService: UseraddService, private router: Router, private translate: TranslateService,
    private userremoveService: UserremoveService, private fb: FormBuilder, private roleService: RoleService) {
    this.frmSignup = this.initForm();
  }

  ngOnInit() {
    this.legendcirclepack(1, 'legendUserGlob', [10, 20, 30], ['Roles', 'Category', 'Dimension'], ['grey', 'rgb(81,183,196)', 'rgb(116,215,202)']);
    this.roleService.getRoles().subscribe(
      d => {
        this.rolesEdit = d;
        this.useroles = d;
        this.GetUsers();
      },
      error => {
        console.error(error);
      }
    );
    this.roleService.getStacks().subscribe(
      d => {
        this.stacks = d;
      },
      error => {
        console.log(error);
      }
    );
    this.userAddService.getRefreshNeeded().subscribe(
      () => {
        this.onGetList();
      }
    );
    this.userremoveService.getRefreshDelete().subscribe(
      () => {
        this.onGetList();
      }
    );

    //this.packedDimensionByStack();
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.showTipsUsers();
    }, 1000);
  }
  showTipsUsers() {
    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsUsers');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsUsers') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsUsers', 'true');
        setTimeout(() => {
          $('#tipsCompUsers').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsUsers') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsUsers', 'true');
          $('#tipsCompUsers').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips Users
  onUpdateTipsUsers() {
    let tipsUsers;
    if ($('#checked_show_users').is(':checked')) {
      tipsUsers = false;
    } else {
      tipsUsers = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsUsers,
      dataMenu: 'Settings',
      dataSousmenu: 'users'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  onGetList() {
    this.listuserService.getUsersList().subscribe(
      (data) => {
        this.dataList = data;
        this.listusers = this.dataList.data;

      },
      err => console.log(err)
    );
  }

  initForm(): FormGroup {
    return this.userForm = this.fb.group(
      {
        username11: [
          null,
          Validators.compose([Validators.required])
        ],
        firstName11: [
          null,
          Validators.compose([Validators.required])
        ],
        lastName11: [
          null,
          Validators.compose([Validators.required])
        ],
        email11: [
          null,
          [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
        ],
        selectedRole: [
          null,
          Validators.compose([Validators.required])
        ],
        selectedStack: [
          null,
          Validators.compose([Validators.required])
        ],
        active: [
          null
        ]
      }
    );
  }

  onSubmitAdd() {
    const formValue = this.userForm.value;
    this.firstName = formValue.firstName11;
    this.lastName = formValue.lastName11;
    this.email = formValue.email11;
    this.password = formValue.password;
    this.username = formValue.username11;
    this.selectedRole = formValue.selectedRole;
    this.selectedStack = formValue.selectedStack;
    const port = window.location.port; // .split('/#')[0];
    let loadingMessage = this.lang === 'en' ? 'S A V I N G&#160;&#160;U S E R . . .' : 'sauvegarde de l\'utilisateur . . .';
    addLoader(loadingMessage);
    if (this.editMode) {
      this.postData = 'id=' + this.userId + '&firstName=' + this.firstName + '&lastName=' +
        this.lastName + '&email=' + this.email + '&username=' + this.username +
        '&password=' + this.password + '&privilege=' + this.selectedRole + '&stack=' + this.selectedStack + '&active=' + formValue.active + '&port=' + port + '&userId=' + localStorage.getItem('userId');
      this.userAddService.addUser(this.postData).subscribe(
        data => {
          $('#exampleModal').modal('hide');
          removeLoader();
          swal({
            title: this.lang === 'en' ? 'edit Success!' : 'Succès de modification!',
            text: this.lang === 'en' ? 'User edited.' : 'Utilisateur modifié.',
            timer: 2000,
            showConfirmButton: false
          });
          // $('#tableExport').DataTable().ajax.reload();
          refreshComponent(this.router);
          // window.location.reload();
        }, // this.allLocs = data,
        err => {
          console.error(err);
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });

          Toast.fire({
            icon: 'error',
            title: this.lang === 'en' ? 'Wrong login name or password' : 'Nom d\'utilisateur ou mot de passe incorrect'
          });

          // this.showNotification('alert-danger', 'login ou mot de pass erronés',
          //   'top', 'right', 'animated fadeInRight', 'animated fadeOutRight');
        }
      );
    } else {
      this.postUserNameValidation = 'username=' + this.username;
      this.userAddService.checkUserName(this.postUserNameValidation).subscribe(
        validation => {
          // @ts-ignore
          if (validation.success === false) {
            $('#exampleModal').modal('hide');
            removeLoader();
            swal({
              title: this.lang === 'en' ? 'Error Username!' : 'Erreur Nom d\'utilisateur!',
              // @ts-ignore
              text: validation.message,
              timer: 2000,
              showConfirmButton: false
            });
            //  $('#tableExport').DataTable().ajax.reload();
            refreshComponent(this.router);
            //   window.location.reload();
          } else {
            this.postData = 'firstName=' + this.firstName + '&lastName=' +
              this.lastName + '&email=' + this.email + '&username=' + this.username +
              '&password=' + this.password + '&privilege=' + this.selectedRole + '&stack=' + this.selectedStack + '&port=' + port + '&userId=' + localStorage.getItem('userId');

            this.userAddService.addUser(this.postData).subscribe(
              data => {
                $('#exampleModal').modal('hide');
                removeLoader();
                swal({
                  title: this.lang === 'en' ? 'Are you sure?' : 'Êtes-vous sûr ?',
                  text: this.lang === 'en' ? 'New User Added.' : 'Nouvel utilisateur ajouté.',
                  timer: 2000,
                  showConfirmButton: false
                });
                refreshComponent(this.router);
                //  $('#tableExport').DataTable().ajax.reload();
                //  window.location.reload();
              }, // this.allLocs = data,
              err => {
                console.error(err);
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                });

                Toast.fire({
                  icon: 'success',
                  title: this.lang === 'en' ? 'Wrong login name or password' : 'Nom d\'utilisateur ou mot de passe incorrect'
                });
                // this.showNotification('alert-danger', 'login ou mot de pass erronés',
                //   'top', 'right', 'animated fadeInRight', 'animated fadeOutRight');
              }
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  showNotification(colorName, text, placementFrom, placementAlign, animateEnter, animateExit) {
    if (colorName === null || colorName === '') {
      colorName = 'bg-black';
    }
    if (text === null || text === '') {
      text = 'Turning standard Bootstrap alerts';
    }
    if (animateEnter === null || animateEnter === '') {
      animateEnter = 'animated fadeInDown';
    }
    if (animateExit === null || animateExit === '') {
      animateExit = 'animated fadeOutUp';
    }
    const allowDismiss = true;

    $.notify({
      message: text
    },
      {
        type: colorName,
        allow_dismiss: allowDismiss,
        newest_on_top: true,
        timer: 1000,
        placement: {
          from: placementFrom,
          align: placementAlign
        },
        animate: {
          enter: animateEnter,
          exit: animateExit
        },
        template: '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} '
          + (allowDismiss ? 'p-r-35' : '') + '" role="alert">' +
          '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0"' +
          'aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }

  GetUsers1() {

    // $('#tableExport').on('click', '.editor_remove', function (e) {
    //     e.preventDefault();
    //    const id=$(e.target).parent().parent().first().html();
    //     this.onRemoveUser( id );
    // } );
    $('#tableExport').empty();
    $('#tableExport').DataTable({
      columnDefs: [
        // {"visible": false, "targets": 0},
        // { "visible": false, "targets": 8 }
      ],
      responsive: true,
      processing: true,
      pageLength: 6,
      lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
      // "rowCallback": function (row, data) {
      //
      //     $('td:eq(5)', row).html('<button class="btn btn-circle btn-danger ng-scope" data-type="html-message" onclick="onRemoveUser( \'' + data.id + '\')"><i class="fas fa-trash-alt"></i></button>');
      //
      // },
      ajax: {
        url: Shared.API_URL + '/api/users/getList/' + localStorage.getItem('userId'),

        type: 'GET',
        contentType: 'application/json',
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
          // 'Accept': 'Application/json'
        },
      },


      columns: [
        { data: 'id', title: this.lang === 'en' ? 'Id User' : 'Identifiant Utilisateur', type: 'readonly' },
        { data: 'firstName', title: this.lang === 'en' ? 'First Name' : 'Prénom' },
        { data: 'lastName', title: this.lang === 'en' ? 'Last Name' : 'Nom de Famille' },
        { data: 'email', title: this.lang === 'en' ? 'Email' : 'Email' },
        { data: 'username', title: this.lang === 'en' ? 'User name' : 'Nom d\'utilisateur', required: true },
        { data: 'userRole.name', title: this.lang === 'en' ? 'Security Role' : 'Rôle de Sécurité' },
        { data: 'active', title: this.lang === 'en' ? 'Status' : 'Statut' },
        { data: 'lastLogin.date', title: this.lang === 'en' ? 'Last Connexion' : 'Dernière Connexion' },
        { data: 'createdAt', title: this.lang === 'en' ? 'Created At' : 'Créé à' },
        { data: 'createdBy', title: this.lang === 'en' ? 'Created By' : 'Créé par' },
        { data: 'updatedAt', title: this.lang === 'en' ? 'Updated At' : 'Mis à jour à' },
        { data: 'updatedBy', title: this.lang === 'en' ? 'Updated BY' : 'Mis à jour par' },

        {
          className: '',
          orderable: false,
          data: null,
          defaultContent: '<button class="btn btn-circle btn-danger ng-scope editor_remove" onclick="onRemoveUser(event,\'' + Shared.API_URL + '\')" data-type="html-message" ><i class="fas fa-trash-alt"></i></button>',
          title: 'Action'
        }

      ],
      // "initComplete": function(settings, json) {
      //     $('#tableExport > tbody  > tr').each(function(index, tr) {
      //         $(tr).find('td:first').hide() ;
      //     });
      //
      // }
      // rowGroup: {
      // dataSrc: 'joinType'
      // },
      /*  "drawCallback": function (settings) {
            var api = this.api();
            var rows = api.rows({page: 'current'}).nodes();
            var last = null;

            api.column(7, {page: 'current'}).data().each(function (group, i) {
                if (last !== group) {
                    switch (group) {
                        case 1:
                            $(rows).eq(i).before(
                                '<tr><td class="group_red" colspan="7">Key – Key </td></tr>'
                            );
                            break;
                        case 2:
                            $(rows).eq(i).before(
                                '<tr><td class="group_blue" colspan="7">Key – Field </td></tr>'
                            );
                            break;
                        case 3:
                            $(rows).eq(i).before(
                                '<tr><td class="group_green" colspan="7">Field – Field </td></tr>'
                            );
                            break;
                    }


                    last = group;
                }
            });
        },*/
    });
  }

  GetUsers() {
    const that = this;
    let temp;
    const roles = this.rolesEdit;
    const columnDefs = [{
      data: 'id',
      title: this.lang === 'en' ? 'Id User' : 'Identifiant Utilisateur',
      visible: false,
      searchable: false
    },
    {
      data: 'firstName',
      title: this.lang === 'en' ? 'First Name' : 'Prénom'
    },
    {
      data: 'lastName',
      title: this.lang === 'en' ? 'Last Name' : 'Nom de Famille'
    },
    {
      data: 'email',
      title: this.lang === 'en' ? 'Email' : 'Email'
    },
    {
      data: 'username',
      title: this.lang === 'en' ? 'User name' : 'Nom d\'utilisateur'
    },
    {
      data: 'privilege.name',
      title: this.lang === 'en' ? 'Security Role' : 'Rôle de Sécurité'
    },
    {
      data: 'active',
      title: this.lang === 'en' ? 'Active' : 'Actif'
    },
    {
      data: 'lastLogin',
      title: this.lang === 'en' ? 'Last Connexion' : 'Dernière Connexion'
    },
    {
      data: 'createdAt',
      title: this.lang === 'en' ? 'Created At' : 'Créé à'
    },
    {
      data: 'createdBy',
      title: this.lang === 'en' ? 'Created By' : 'Créé par'
    },
    {
      data: 'updatedAt',
      title: this.lang === 'en' ? 'Updated At' : 'Mis à jour à'
    },
    {
      data: 'updatedBy',
      title: this.lang === 'en' ? 'Updated BY' : 'Mis à jour par'
    },

    {
      className: 'editortab',
      orderable: false,
      data: 'id',
      render(data, type, row, meta) {
        let htm = '';
        if (!row.create) {
          if ($('#addRow').length) {
            $('#addRow').remove();
          }
        } else {
          $('#addRow').css('display', 'block');
        }
        if (row.delete) {
          htm += '<button class="btn btn-danger btn-circle waves-effect waves-circle waves-float ng-scope editor_remove"  onclick="onRemoveUser(\'' + data + '\',event,\'' + Shared.API_URL + '\')" data-type="html-message" ><i class="fas fa-trash-alt"></i></button>';
        }
        if (row.update) {
          htm += '<button class="btn bg-light-green btn-circle waves-effect waves-circle waves-float ng-scope editor_save" data_item="' + data + '"  data-type="html-message" ><i class="fas fa-pencil-alt"></i></button>';
        }
        if (row.update && !row.active) {
          htm += '<button class="btn bg-light-blue btn-circle waves-effect waves-circle waves-float ng-scope editor_reSent" data_item="' + data + '" data_name="' + row.username + '"  data-type="html-message" ><i class="fas fa-envelope-open"></i></button>';
        }
        if (row.id == localStorage.getItem('userId')) {
          return '';
        }
        return htm;
        // return '<button class="btn btn-danger btn-circle waves-effect waves-circle waves-float ng-scope editor_remove"  onclick="onRemoveUser(\''+data+'\',event,\'' + Shared.API_URL+'\')" data-type="html-message" ><i class="fas fa-trash-alt"></i></button> ' +
        //  '<button class="btn bg-light-green btn-circle waves-effect waves-circle waves-float ng-scope editor_save"   data-type="html-message" ><i class="fas fa-pencil-alt"></i></button>';// +
        //  '<button class="btn bg-light-blue btn-circle waves-effect waves-circle waves-float ng-scope editor_cancel"  data-type="html-message" ><i class="fas fa-list-alt"></i></button>';
      },
      // onclick="onEditUser(\''+data+'\',event,\'' + Shared.API_URL+'\')"
      // "defaultContent": '<button class="btn btn-circle btn-fab-material-red-200 ng-scope editor_remove"  onclick="onRemoveUser(event,\'' + Shared.API_URL+'\')" data-type="html-message" ><i class="fas fa-trash-alt"></i></button> ' +
      //   '<button class="btn btn-circle btn-fab-material-green-200 ng-scope editor_save" onclick="onEditUser(event,\'' + Shared.API_URL+'\')"  data-type="html-message" ><i class="fas fa-save"></i></button>' +
      //   '<button class="btn btn-circle btn-fab-material-yellow-200 ng-scope editor_cancel"  data-type="html-message" ><i class="fas fa-undo-alt"></i></button>',
      title: 'Action'
    }
    ];
    $('#tableExport').empty();
    const dataTable = $('#tableExport').DataTable({
      sPaginationType: 'full_numbers',
      ajax: {
        url: Shared.API_URL + '/api/users/getList/' + localStorage.getItem('userId'),
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
          // 'Accept': 'Application/json'
        },
        // our data is an array of objects, in the root node instead of /data node, so we need 'dataSrc' parameter
        data: ''
      },
      columns: columnDefs,
      //  dom: 'Bfrtip',        // Needs button container
      select: 'single',
      responsive: true,
      pageLength: 5,
      lengthMenu: [[5, 10, 20, -1], ['5 rows', '10 rows', '20 rows', 'Show all']],
      initComplete(settings, json) {
        temp = [...json.data];
        //  console.log(JSON.stringify(temp));
        $('.editortab').css('display', 'none');
      }
      //       onAddRow: function(datatable, rowdata, success, error) {
      //         let data = {
      //           firstName: undefined,
      //           lastName: undefined,
      //           email: undefined,
      //           username: undefined,
      //           privilege: undefined,
      //           id: undefined
      //         };
      //         data.id = rowdata.id;
      //         data.firstName = rowdata.firstName;
      //         data.lastName = rowdata.lastName;
      //         data.email = rowdata.email;
      //         data.username = rowdata.username;
      //         data.privilege = rowdata['privilege.name'];
      //
      //         $.ajax({
      //           // a tipycal url would be / with type='PUT'
      //           url: Shared.API_URL + '/api/users/new' ,
      //           headers: {
      //             'Accept': 'Application/json',
      //             'Authorization': 'Bearer ' + getCookieValue('tokendrd')
      // // 'Accept': 'Application/json'
      //           },
      //           type: 'POST',
      //           data: data,
      //           success: success,
      //           error: error
      //         });
      //       },
      //       onDeleteRow: function(datatable, rowdata, success, error) {
      //
      //         $.ajax({
      //           // a tipycal url would be /{id} with type='DELETE'
      //           url: Shared.API_URL + "/api/users/remove/"+ rowdata.id,
      //           headers: {
      //             'Accept': 'Application/json',
      //             'Authorization': 'Bearer ' + getCookieValue('tokendrd')
      // // 'Accept': 'Application/json'
      //           },
      //           type: 'POST',
      //           data: rowdata,
      //           success: success,
      //           error: error
      //         });
      //       },
      //       onEditRow: function(datatable, rowdata, success, error) {
      //         let data = {
      //           firstName: undefined,
      //           lastName: undefined,
      //           email: undefined,
      //           username: undefined,
      //           privilege: undefined,
      //           id: undefined
      //         };
      //         data.id = rowdata.id;
      //         data.firstName = rowdata.firstName;
      //         data.lastName = rowdata.lastName;
      //         data.email = rowdata.email;
      //         data.username = rowdata.username;
      //         data.privilege = rowdata['privilege.name'];
      //         $.ajax({
      //           // a tipycal url would be /{id} with type='POST'
      //           url: Shared.API_URL + '/api/users/new' ,
      //           headers: {
      //             'Accept': 'Application/json',
      //             'Authorization': 'Bearer ' + getCookieValue('tokendrd')
      // // 'Accept': 'Application/json'
      //           },
      //           type: 'POST',
      //           data: data,
      //           success: success,
      //           error: error
      //         });
      //       }
    });
    $('#tableExport').on('click', 'tbody > tr', function () {
      if (!$(this).hasClass('selected')) {
        $('#tableExport > tbody > tr .editortab').css('display', 'none');
        $('#tableExport > tbody > tr.selected').removeClass('selected');

        $(this).addClass('selected');

        $('#tableExport > tbody > tr.selected .editortab').css('display', 'block');
      }

    });
    $('#tableExport').on('click', '.editor_save', function () {
      that.editMode = true;
      // if ($('.parent.selected').length) {
      //   var data = dataTable.row($('.parent.selected')).data();
      // } else {
      //   var data = dataTable.row($(this).parents('tr')).data();
      // }

      // console.log(data);
      that.translate.get('users.editingUser').subscribe((res: string) => {
        $('#formModal').html(res);
      });

      // that.frmSignup.get('username11').patchValue(data.username);
      // that.frmSignup.get('firstName11').patchValue(data.firstName);
      // that.frmSignup.get('lastName11').patchValue(data.lastName);
      // that.frmSignup.get('email11').patchValue(data.email);
      // that.frmSignup.get('selectedRole').patchValue(data.privilege.id);
      // that.frmSignup.get('selectedStack').patchValue(data.stack.id);
      // that.frmSignup.get('active').patchValue(data.active);
      that.userId = $(this).attr('data_item');
      const data = temp.filter(item => item.id == that.userId)[0];
      that.frmSignup.get('username11').patchValue(data.username);
      that.frmSignup.get('firstName11').patchValue(data.firstName);
      that.frmSignup.get('lastName11').patchValue(data.lastName);
      that.frmSignup.get('email11').patchValue(data.email);
      that.frmSignup.get('selectedRole').patchValue(data.privilege.id);
      that.frmSignup.get('selectedStack').patchValue(data.stack.id);
      that.frmSignup.get('active').patchValue(data.active);
      $('#exampleModal').modal();
    });
    // $('#tableExport').on('dblclick', 'tbody td', function() {
    //   var roles=[];
    //
    //   var text = dataTable.cell($(this)).data();
    //   var column = dataTable.cell($(this)).index().column;
    //   if(column==5){
    //     var selectElement = document.createElement('select');
    //     selectElement.innerHTML='loading..';
    //     $.ajax({
    //       url: Shared.API_URL + `/api/users-role/getRoles`,
    //       headers: {Authorization: 'Bearer ' + getCookieValue('tokendrd')}
    //       ,
    //       success: function(data, textStatus) {
    //         roles = [...data];
    //         var htm='';
    //         roles.map(r => {if(r.name==text){htm+='<option value="'+r.id+'" selected>' + r.name + '</option>';}else{htm+='<option value="'+r.id+'">' + r.name + '</option>'}});
    //         selectElement.innerHTML =htm;
    //         selectElement.className = "form-control";
    //
    //         this.innerHTML = '';
    //         this.appendChild(selectElement);
    //
    //         $(selectElement).focus();
    //       },
    //       error: function(XMLHttpRequest, textStatus, errorThrown) {
    //         alert("error: " + textStatus);
    //       },
    //       complete: function(data) {
    //         // $('#spinnerlg').hide();
    //         // $('#data_usage1').show();
    //       }
    //     });
    //    // selectElement.value = text;
    //
    //
    //   }else{
    //     if(column==7){return;}
    //     if(column==6){    var Element = document.createElement('select');
    //                         Element.className="form-control editable";
    //                         var checked= text == true ? 'selected' : '';
    //                         var checked1= text == false ? 'selected' : '';
    //                          var htm="<option value='true' " + checked + ">true</option><option value='false' "+ checked1 +" >false</option>"
    //                          Element.innerHTML= htm;
    //                       this.innerHTML = '';
    //                       this.appendChild(Element);
    //
    //                       $(Element).focus();
    //     console.log(htm);
    //     return;
    //     }
    //     var inputElement = document.createElement('input');
    //
    //     inputElement.type = 'text';
    //
    //     inputElement.value = text;
    //
    //     inputElement.className = "editable";
    //
    //     this.innerHTML = '';
    //     this.appendChild(inputElement);
    //
    //     $(inputElement).focus();
    //   }
    // });
    //
    // $('#tableExport').on('change', '.editable', function() {
    //
    //   var inputVal = this.value;
    //
    //   var cell = dataTable.cell($(this).parent('td'));
    //
    //   var row = dataTable.row($(this).parents('tr'));
    //
    //   var oldData = cell.data();
    //
    //   cell.data(inputVal);
    //
    //   console.log(JSON.stringify(row.data()));
    //
    //   // Make an ajax call to update table.
    //
    //   // If the ajax call fails, put the old data back
    //   // and show a warning.
    //
    //   dataTable.draw();
    //
    // });
    //
    // $('#tableExport').on('blur', '.editable', function() {
    //
    //   $(this).parent('td').html(this.value);
    //  // console.log(this.value);
    //   dataTable.draw();
    //
    // });
    // $('#tableExport').on('blur', '.form-control', function() {
    //
    //   $(this).parent('td').html(this.options[this.selectedIndex].text);
    //   // console.log(this.value);
    //   dataTable.draw();
    //
    // });
    //
    // $('#tableExport').on('change', '.form-control', function() {
    //
    //   var inputVal = this.options[this.selectedIndex].text;
    //  console.log(inputVal);
    //   var cell = dataTable.cell($(this).parent('td'));
    //
    //   var row = dataTable.row($(this).parents('tr'));
    //
    //   var oldData = cell.data();
    //   $.ajax({
    //     url: Shared.API_URL + `/api/users-role/getRoleList/0/null/Id/asc`,
    //     headers: {Authorization: 'Bearer ' + getCookieValue('tokendrd')}
    //     ,
    //     success: function(data, textStatus) {
    //       row.data().privilege = data.data.filter(r =>r.name == inputVal);
    //
    //       console.log(JSON.stringify(row.data()));
    //
    //       // Make an ajax call to update table.
    //
    //       // If the ajax call fails, put the old data back
    //       // and show a warning.
    //
    //       dataTable.draw();
    //     },
    //     error: function(XMLHttpRequest, textStatus, errorThrown) {
    //       alert("error: " + textStatus);
    //     },
    //     complete: function(data) {
    //       // $('#spinnerlg').hide();
    //       // $('#data_usage1').show();
    //     }
    //   });
    //
    //
    // });
    $('#tableExport').on('click', 'tbody > tr .editor_cancel', function () {
      const row = dataTable.row($(this).parents('tr'));
      that.roleService.getUsersByRole(row.data().privilege.name).subscribe(
        d => {
          let htm = '<table class=\'table table-condensed\'><thead><tr><th>N°</th><th>UserName</th></tr></thead><tbody>';
          // @ts-ignore
          d.users.map((r, i) => {
            htm += '<tr><td style=\'text-align: center;\'>' + (i + 1) + '</td><td style=\'text-align: center;\'>' + r.username + '</td></tr>';
          });
          htm += '</tbody></table'; // $('#roles_list').html(htm);

          swal({
            // @ts-ignore
            title: this.lang === 'en' ? 'Users having role <b style=\'color:#0ba360\'>' + d.users[0].privilege.name + '</b>!' : 'Les utilisateurs ayant le rôle <b style=\'color:#0ba360\'>' + d.users[0].privilege.name + '</b>!',
            text: '<div class=\'body table-responsive\'>' + htm + '</div>',
            html: true
          });
        },
        err => {
          console.log(err);
        }
      );
      //   var row = dataTable.row($(this).parents('tr'));
      // // console.log(row.data());
      //  var t = temp.filter(d=> d.id == row.data().id)[0];
      //  console.log(t);
      //   row.data(t).invalidate();
    });
    // $('#tableExport').on('blur', '.form-control', function() {
    //
    //   $(this).parent('td').html(this.value);
    //   // console.log(this.value);
    //   dataTable.draw();
    //
    // });
    $('#tableExport').on('click', '.editor_reSent', function () {
      const userId = $(this).attr('data_item');
      const username = $(this).attr('data_name');
      // const port = window.location.port;
      that.roleService.reSendEmailToUser(username, userId).subscribe(
        d => {
          swal({
            title: this.lang === 'en' ? 'Email resent with success!' : 'Email renvoyé avec succès!',
            text: this.lang === 'en' ? 'User resend.' : 'Utilisateur renvoyé.',
            timer: 2000,
            showConfirmButton: false
          });
        },
        error => {
          console.error(error);
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });

          Toast.fire({
            icon: 'error',
            title: this.lang === 'en' ? 'error reset email' : 'erreur de réinitialisation de l\'email'
          });
        }
      );
    });
  }

  getApp() {
    console.log(this.selectedApp);
  }

  sendMail() {
    this.roleService.sendmail().subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.log(err);
      }
    );
  }

  addUser() {
    this.editMode = false;
    this.translate.get('users.newUser').subscribe((res: string) => {
      $('#formModal').html(res);
    });
    this.frmSignup.get('username11').patchValue('');
    this.frmSignup.get('firstName11').patchValue('');
    this.frmSignup.get('lastName11').patchValue('');
    this.frmSignup.get('email11').patchValue('');
    this.frmSignup.get('selectedRole').patchValue('');
    this.frmSignup.get('active').patchValue(false);
    this.emailError = false;
    this.nameError = false;
    this.emailSuccess = false;
    this.nameSuccess = false;
    $('#exampleModal').modal();
  }

  appReadCheckManager(id) {
    $('#' + id).change(function () {
      if ($('#create_app').prop('checked') || $('#update_app').prop('checked') || $('#delete_app').prop('checked')) { $('#read_app').prop('checked', true).prop('disabled', true); } else {
        $('#read_app').prop('checked', false).prop('disabled', false);
      }
    });
  }

  checkName() {
    const username = this.frmSignup.value.username11;
    if (username == '') { this.nameError = true; this.nameSuccess = false; return; }
    this.userAddService.checkUserName(username).subscribe(
      d => {
        // @ts-ignore
        if (d.success === false) { this.nameError = true; this.nameSuccess = false; } else { this.nameError = false; this.nameSuccess = true; }
      },
      e => { console.error(e); }
    );
  }
  // checkEmail() {
  //   const email = this.frmSignup.value.email11;
  //   if (email == '') {this.emailError = true; this.emailSuccess = false; return; }
  //   const mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  //   if(!email.match(mailformat)) {this.nameError = true; this.nameSuccess = false; return; }
  //   this.userAddService.checkEmail(email).subscribe(
  //     d => {
  //       // @ts-ignore
  //       if (d.success === false) {this.emailError = true; this.emailSuccess = false; } else {this.emailError = false; this.emailSuccess = true; }},
  //     e => {console.error(e); }
  //   );
  // }
  checkEmail() {
    const email = this.frmSignup.value.email11;
    if (email == '') { this.emailError = true; this.emailSuccess = false; return; }
    const mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email.match(mailformat)) { this.nameError = true; this.nameSuccess = false; return; }
    this.userAddService.checkEmail(email).subscribe(
      d => {
        // @ts-ignore
        if (d.success === false) { this.emailError = true; this.emailSuccess = false; } else { this.emailError = false; this.emailSuccess = true; }
      },
      e => { console.error(e); }
    );
  }

  onGetCirclePack(data) {

    const color = d3.scaleLinear()
      .domain([0, 5])
      .range(['hsl(152,80%,80%)', 'hsl(228,30%,40%)'])
      .interpolate(d3.interpolateHcl);
    const width = 932;
    const height = 932;

    const pack = data => d3.pack()
      .size([width, height])
      .padding(20)
      (d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value));

    const root = pack(data);
    console.log(root);

    let focus = root;
    let view;
    $('#userglobChart').empty();
    const svg = d3.select('#userglobChart');
    svg.attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)

      //  .style('margin', '6 35px')
      .style('background', '#ffffff')
      .style('cursor', 'pointer')
      .on('click', () => zoom(root));

    // add shadow filter
    svg.append('svg:defs').selectAll('filter')
      .data(['shadowGlobeSize'])
      .enter().append('svg:filter')
      .attr('id', String)
      .attr('y', -10)
      .attr('x', -10)
      .attr('height', 40)
      .attr('width', 150)
      .append('feOffset')
      .attr('in', 'SourceAlpha')
      .attr('dx', 3)
      .attr('dy', 3)
      .attr('result', 'offset2')
      .attr('stdDeviation', 4);
    svg.selectAll('filter')
      .append('feGaussianBlur')
      .attr('in', 'offset2')
      .attr('result', 'blur2')
      .attr('stdDeviation', 10);
    svg.selectAll('filter')
      .append('feMerge')
      .append('feMergeNode')
      .attr('in', 'blur2');
    svg.selectAll('filter')
      .select('feMerge')
      .append('feMergeNode')
      .attr('in', 'SourceGraphic');
    const cont = svg.append('g');

    const node = cont
      .selectAll('circle')
      .data(root.descendants().slice(1))
      .join('circle')
      .attr('class', 'circulos')
      .attr('id', d => d.data.type == 'role' ? d.parent.parent.data.id + d.parent.data.id + d.data.id : d.data.id)
      .attr('fill', d => d.children ? color(d.depth) : 'white')
      .on('click', d => focus !== d && (zoom(d), d3.event.stopPropagation()));

    const g = svg.append('g');

    let scaleAvr = 1;
    $('#zoomIn_button').on('click', function () {
      if (scaleAvr < 2) {
        scaleAvr += 0.1;
      } else {
        scaleAvr = 2;
      }
      zoomGroups();
    });
    $('#zoomOut_button').on('click', function () {
      if (scaleAvr > 0.5) {
        scaleAvr -= 0.1;
      } else {
        scaleAvr = 0.5;
      }
      zoomGroups();

    });
    $('#resetSize_button').on('click', function () {
      scaleAvr = 1;
      zoomGroups();
    });
    const zoomGroups = () => {
      cont.transition().duration(100).style('transform', 'scale(' + scaleAvr + ')');

    };
    // sunbusrt chart container init
    const width1 = 300;

    const label = svg.append('g')
      .style('font', '14px sans-serif')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .selectAll('text')
      .attr('class', 'labelZoom')
      .data(root.descendants())
      .join('text')
      .style('fill-opacity', d => d.parent === root ? 1 : 0)
      .style('display', d => d.parent === root ? 'inline' : 'none')
      .text(d => d.data.name);

    zoomTo([root.x, root.y, root.r * 2]);

    function zoomTo(v) {
      const k = width / v[2];

      view = v;
      label.attr('transform', d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);

      node.attr('transform', d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
      node.attr('r', d => d.r * k);
    }
    function zoom(d) {

      if (d.depth > 1) {
        g.attr('transform', 'translate(0,0) scale(2)');
        g.transition().duration(10).style('opacity', '1');
      }
      focus = d;

      if (d.depth == 0 || d.depth == 1) {
        g.style('display', 'none');
      } else {
        g.transition().duration(100).style('display', 'block');
      }
      const transition = svg.transition()
        .duration(d3.event.altKey ? 7500 : 750)
        .tween('zoom', d => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return t => zoomTo(i(t));
        });

      label
        .filter(function (d) {
          return d.parent === focus || this.style.display === 'inline';
        })
        .transition(transition)
        .style('fill-opacity', d => d.parent === focus ? 1 : 0)
        .on('start', function (d) {
          if (d.parent === focus) {
            this.style.display = 'inline';
          }
        })
        .on('end', function (d) {
          if (d.parent !== focus) {
            this.style.display = 'none';
          }
        });
    }

  }
  /* packedDimensionByStack() {
     this.roleService.packedDimensionByStack().subscribe(
       d => {
         this.onGetCirclePack(d);
         this.onGetTableDimension(d);
       },
       err => {
         console.error(err);
       }
     );
   }*/
  _toggleSidebar() {

    this._openedSide = !this._openedSide;
    this._openedSide ? $('#userglobChart').css({ 'width': '117%', 'height': '86vh' }) : $('#userglobChart').css({ 'width': '100%', 'height': '86vh' });
    if (this.showcirclePack) {
      $('#userListGlos').html('<i class="fas fa-arrow-circle-right fa-2x"></i>');
      this.showcirclePack = false;
      $('#legendGlobSizeRowWrapper').css('display', 'block');
    } else {

      $('#legendGlobSizeRowWrapper').css('display', 'none');
      $('#userListGlos').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
      this.showcirclePack = true;
    }
  }
  legendcirclepack(scaleFactor, id, legendSizes, legendTexts, legendColor) {



    const commaFormat = d3.format(',');

    const offset = legendSizes.length - 1;
    let width = $('#' + id).empty().width(),
      height = legendSizes[offset] * 2 * 1.2;
    width = 280;
    const legendCenter = -10,
      legendBottom = height,
      legendLineLength = legendSizes[offset] * 1.3,
      textPadding = 5;

    // Create SVG for the legend
    const legends = d3.select('#' + id).append('svg')
      .attr('width', width)
      .attr('height', height * 1.5)
      .append('g')
      .attr('class', 'legendWrapper')
      .attr('transform', 'translate(' + width / 2 + ',' + 0 + ')');
    //  .style("opacity", 0);

    // Draw the circles
    legends.selectAll('.legendCircle')
      .data(legendSizes)
      .enter().append('circle')
      .attr('r', d => d)
      .attr('class', 'legendCircle')
      .attr('cx', legendCenter)
      .style('stroke', (d, i) => legendColor[i])
      .attr('cy', d => legendBottom - d);

    legends.selectAll('.legendLine')
      .data(legendSizes)
      .enter().append('line')
      .attr('class', 'legendLine')
      .attr('x1', legendCenter)
      .attr('y1', d => legendBottom - 2 * d)
      .attr('x2', legendCenter + legendLineLength)
      .attr('y2', d => legendBottom - 2 * d);
    // Place the value next to the line
    legends.selectAll('.legendText')
      .data(legendSizes)
      .enter().append('text')
      .attr('class', 'legendText')
      .style('stroke', (d, i) => legendColor[i])
      .attr('x', legendCenter + legendLineLength + textPadding)
      .attr('y', d => legendBottom - 2 * d)
      .attr('dy', '0.3em')
      .text((d, i) => legendTexts[i]);

  }

  onGetTableDimension(d) {
    const t = d.children;

    const columnDefs = [{
      data: 'id',
      title: 'Id Dimension',
      visible: false,
      searchable: false
    },
    {
      data: 'name',
      title: 'Dimension'
    },
    { data: 'value', title: this.lang === 'en' ? 'Affected Categories' : 'Catégories Affectées' }
    ];
    const dataTable = $('#tableDimensionUser').DataTable({
      sPaginationType: 'full_numbers',
      data: t,
      destroy: true,
      columns: columnDefs,
      dom: 'Bfrtip',        // Needs button container
      select: 'single',
      responsive: true,
      rowCallback(row, data) {
        $('td:eq(0)', row).html('<a class="dataDimensionType" data-name="' + data.name + '" data-id="' + data.id + '" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
      },
      initComplete(settings, json) {
        $('#tableDimensionUser tbody').on('click', 'a.dataDimensionType', function () {
          const dim = $(this).attr('data-name');
          const tr = $(this).closest('tr');
          const id = $(this).attr('data-id');
          document.getElementById(id).dispatchEvent(new Event('click'));
          const row = $('#tableDimensionUser').DataTable().row(tr);
          $('.dmUsershown').next('tr').hide();
          const indexRow = row.index();
          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('dmUsershown');
          } else {
            tr.addClass('dmUsershown');
            if ($('#table_dimensionType_children').length) {
              $('#table_dimensionType_children').remove();
            }
            row.child('<table class="table_dimensionType_fils" id ="table_dimensionType_children" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();

            console.log(d);
            const categories = t.find(e => e.name == dim).children;
            $('#table_dimensionType_children').DataTable({
              data: categories,
              responsive: true,
              destroy: true,
              pageLength: 5,
              rowCallback(row, data) {
                $('td:eq(0)', row).html('<a class="dataDimensionDetail" data-count=' + data.funcs + ' data-name="' + data.name + '" data-id="' + data.id + '" data-parent ="' + id + '" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
              },
              lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
              columns: [
                { data: 'name', title: this.lang === 'en' ? 'Categories' : 'Catégories' },
                { data: 'funcs', title: this.lang === 'en' ? 'Affected Data roles' : 'Rôles de données affectés' }
              ],
              // init complete data roles
              initComplete(settings, json) {
                $('#table_dimensionType_children tbody').on('click', 'a.dataDimensionDetail', function () {
                  const funcs = $(this).attr('data-count');
                  if (funcs == 0) {
                    const id = $(this).attr('data-id');
                    document.getElementById(id).dispatchEvent(new Event('click'));
                  } else {
                    const dim = $(this).attr('data-name');
                    const tr = $(this).closest('tr');
                    const row = $('#table_dimensionType_children').DataTable().row(tr);
                    const id = $(this).attr('data-id');
                    const parent = $(this).attr('data-parent');
                    document.getElementById(id).dispatchEvent(new Event('click'));
                    $('.dmUserdetailshown').next('tr').hide();
                    const indexRow = row.index();
                    if (row.child.isShown()) {
                      // This row is already open - close it
                      row.child.hide();
                      tr.removeClass('dmUserdetailshown');
                    } else {
                      tr.addClass('dmUserdetailshown');
                      if ($('#table_dimensionDetail_children').length) {
                        $('#table_dimensionDetail_children').remove();
                      }
                      row.child('<table class="table_dimensionDetail_fils" id ="table_dimensionDetail_children" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();

                      const data = categories.find(e => e.name == dim);
                      $('#table_dimensionDetail_children').DataTable({
                        data: data.children,
                        responsive: true,
                        destroy: true,
                        pageLength: 5,
                        rowCallback(row, data) {
                          $('td:eq(0)', row).html('<a class="dataRoleUserClick" data-name="' + data.name + '" data-id="' + parent + id + data.id + '" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
                        },
                        initComplete(settings, json) {
                          $('#table_dimensionDetail_children tbody').on('click', 'a.dataRoleUserClick', function () {
                            const id = $(this).attr('data-id');
                            document.getElementById('userglobChart').dispatchEvent(new Event('click'));
                            $('.pulseVolume').toggleClass('pulseVolume');
                            $('#' + $.escapeSelector(id)).toggleClass('pulseVolume');
                            setTimeout(() => {
                              $('#' + $.escapeSelector(id)).removeClass('pulseVolume');
                            }, 10000);
                          });
                        },
                        lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                        columns: [
                          { data: 'name', title: this.lang === 'en' ? 'Data Role' : 'Rôle de Données' },
                          { data: 'value', title: this.lang === 'en' ? 'Affected Users' : 'Utilisateurs Affectés' }
                        ]
                      });
                    }
                  }
                });
              }
              // end init complete data roles
            });
          }
        });
      }
    });

  }
}
