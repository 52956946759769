import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HolderService } from '../../services/data-governance/holder/holder.service';
import {getCookieValue, Shared} from '../../shared';
import { stackJson } from '../stack/stackJson';
import Swal from 'sweetalert2';
import { holderJson } from './holderJson';
import * as d3 from 'd3';
import { RouterhistoryService } from '../../services/appService/routerhistory.service';
import { TranslateService } from '@ngx-translate/core';
declare const $: any;
declare const swal: any;

@Component({
  selector: 'app-holders',
  templateUrl: './holders.component.html',
  styleUrls: ['./holders.component.css']
})
export class HoldersComponent implements OnInit, AfterViewInit {
  frmHolder: FormGroup;
  frmHolder1: FormGroup;
  editMode = false;
  stacks: any;
  holderId: string;
  nameError = false;
  nameSuccess = false;
  types = [{ id: 1, name: 'Supplier' }, { id: 2, name: 'Participant' }, { id: 3, name: 'Consumer' }];
  // stackEdit: any;
  holderType: any;
  role = [];
  userRoles: any;
  userNameError = false;
  userNameSuccess = false;
  userName = '';
  suggest = '';
  userRight = false;
  isUser = false;
  modalSending = false;
  _openedSide = false;
  showcirclePack = true;
  lang: string;
  constructor(private holderService: HolderService, private fb: FormBuilder, private routerHistoryService: RouterhistoryService, private translate: TranslateService) {
    this.holderService.getUserRole().subscribe(
      d => {
        // @ts-ignore
        this.userRight = d.userAdd;
        // this.userRight? this.frmHolder = this.initForm():this.frmHolder = this.initForm1();
      },
      error => { console.error(error); }
    );
    this.frmHolder = this.initForm();
    // this.frmHolder1 = this.initForm1();
  }

  ngOnInit(): void {
    this.getHolders();
    this.getStackList();
    this.getRoles();
    this.legendCirclePack(1, 'legendHolderGlob', [10, 20, 30], ['StewardShip & OwnerShip', 'Category', 'Dimension'], ['grey', 'rgb(81,183,196)', 'rgb(116,215,202)']);
    this.packedDimensionByHolder();
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'en';
      this.translate.use(this.lang);
    }, 10);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.showTipsGovSteward();
    }, 1000);
  }
  showTipsGovSteward() {
    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsGovSteward');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsGovSteward') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsGovSteward', 'true');
        setTimeout(() => {
          $('#tipsCompGovSteward').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsGovSteward') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsGovSteward', 'true');
          $('#tipsCompGovSteward').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips data security
  onUpdateTipsGovSteward() {
    let tipsGovSteward;
    if ($('#checked_show_govsteward').is(':checked')) {
      tipsGovSteward = false;
    } else {
      tipsGovSteward = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsGovSteward,
      dataMenu: 'Data Governance',
      dataSousmenu: 'stewards'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  onSubmitAdd() {
    this.modalSending = true;
    const formValue = this.frmHolder.value;

    const firstName = formValue.firstName;
    const lastName = formValue.lastName;
    const email = formValue.email;
    const stack = formValue.selectedStack;
    const role = formValue.selectedRole;
    // console.log(formValue);return;
    if (this.suggest == '') {
      this.userName = firstName[0] + lastName;
      this.frmHolder.get('userName').patchValue(this.userName);
    }
    if (formValue.user && this.userRight) {
      this.holderService.checkUserNameWithSuggest(this.userName).subscribe(
        d => {
          // @ts-ignore
          if (d.success === false) {
            this.userNameError = true;
            this.userNameSuccess = false;
            // @ts-ignore
            this.suggest = d.suggest; this.modalSending = false;
          }
          else {
            this.userNameError = false;
            this.userNameSuccess = true;
            this.saveHolder(firstName, lastName, email, stack, true, role);
          }
        },
        error => {
          console.error(error);
          this.modalSending = false;
        }
      );
      // console.log(formValue);
      // console.log(this.userName);
    } else {
      this.saveHolder(firstName, lastName, email, stack, false, role);
    }
  }

  private initForm() {
    return this.fb.group(
      {
        firstName: [
          null,
          Validators.compose([Validators.required])
        ],
        lastName: [
          null,
          Validators.compose([Validators.required])
        ],
        email: [
          null,
          [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]
        ],
        selectedStack: [
          null,
          Validators.compose([Validators.required])
        ],
        user: [
          false
        ],
        selectedRole: [
          null,
          Validators.compose([Validators.required])
        ],
        userName: [new FormControl({ value: '', disabled: true })]
      }
    );
  }

  getHolders() {
    const that = this;
    let temp;
    // let stacks = this.stackEdit;
    const columnDefs = [
      {
        data: 'id',
        title: this.lang === 'en' ? 'Id holder' : 'Titulaire de l\'ID',
        visible: false,
        searchable: false
      },
      {
        data: 'firstName',
        title: this.lang === 'en' ? 'FirstName' : 'Prénom'
      },
      {
        data: 'lastName',
        title: this.lang === 'en' ? 'LastName' : 'Nom de famille'
      },
      {
        data: 'email',
        title: this.lang === 'en' ? 'Email' : 'Email'
      },
      {
        data: 'stack.role',
        title: this.lang === 'en' ? 'Data Role' : 'Rôle de données'
      },
      {
        data: 'type',
        title: this.lang === 'en' ? 'Type' : 'Type'
      },

      {
        className: 'editortab',
        orderable: false,
        data: 'id',
        render(data, type, row, meta) {
          let htm = '';
          // @ts-ignore
          if (!that.role.createHolder) {
            if ($('#addHolder').length) {
              $('#addHolder').remove();
            }
          } else {
            $('#addHolder').css('display', 'block');
          }
          // @ts-ignore
          if (that.role.deleteHolder && row.type == 'StakeHolder') {
            htm += '<button class="btn btn-danger btn-circle waves-effect waves-circle waves-float ng-scope holder_editor_remove" data_item="' + data + '" data-type="html-message" ><i class="fas fa-trash-alt"></i></button>';
          }
          // @ts-ignore
          if (that.role.updateHolder) {
            htm += '<button class="btn bg-light-green btn-circle waves-effect waves-circle waves-float ng-scope holder_editor_save" data_item="' + data + '"  data-type="html-message" ><i class="fas fa-pencil-alt"></i></button>';
          }
          htm += '<button class="btn bg-light-blue btn-circle waves-effect waves-circle waves-float ng-scope stack_editor_cancel"  data_item="' + row.id + '" data_name="' + row.lastName + '" data-type="html-message" ><i class="fas fa-list-alt"></i></button>';
          // if (row.id == localStorage.getItem('userId')) {
          //   return '';
          // }
          return htm;
        },
        title: 'Action'
      }
    ];
    $('#tableHolder').empty();
    const dataTable = $('#tableHolder').DataTable({
      sPaginationType: 'full_numbers',
      ajax: {
        url: Shared.API_URL + '/api/holder/getHolders/' + localStorage.getItem('userId'),
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
        dataSrc(d) {
          that.role = d.holderRole;
          return d.data;
        }
      },
      columns: columnDefs,
      rowCallback(row, data) {
        if (data.isUser && data.type == 'StakeHolder') {
          $('td:eq(4)', row).html('<span> Stewardship & Ownership/User</span>');
        }
        if (!data.isUser && data.type == 'StakeHolder') {
          $('td:eq(4)', row).html('<span> Stewardship & Ownership</span>');
        }
      },
      //  dom: 'Bfrtip',        // Needs button container
      select: 'single',
      responsive: true,
      pageLength: 5,
      lengthMenu: [[5, 10, 20, -1], ['5 rows', '10 rows', '20 rows', 'Show all']],
      "initComplete": function (settings, json) {
        $('.editortab').css('display', 'none');
      }
    });
    $('#tableHolder').on('click', 'tbody > tr', function () {
      if (!$(this).hasClass('selected')) {
        $('#tableHolder > tbody > tr .editortab').css('display', 'none');
        $('#tableHolder > tbody > tr.selected').removeClass('selected');

        $(this).addClass('selected');

        $('#tableHolder > tbody > tr.selected .editortab').css('display', 'block');
      }

    });
    $('#tableHolder').on('click', '.holder_editor_save', function () {
      that.editMode = true;
      let data;
      if ($('.parent.selected').length) {
        data = dataTable.row($('.parent.selected')).data();
      } else {
        data = dataTable.row($(this).parents('tr')).data();
      }

      // console.log(data);
      that.translate.get('holders.editStewardShip').subscribe((res: string) => {
        $('#holderTitle').html(res);
      });

      that.editMode = true;
      that.isUser = data.isUser;
      that.frmHolder.get('firstName').patchValue(data.firstName);
      that.frmHolder.get('lastName').patchValue(data.lastName);
      that.frmHolder.get('email').patchValue(data.email);
      that.frmHolder.get('selectedStack').patchValue(data.stack.id);
      that.frmHolder.get('user').patchValue(false);
      that.frmHolder.get('userName').patchValue('');
      that.userNameSuccess = false;
      that.userNameError = false;
      that.suggest = '';
      that.holderId = data.id;
      that.holderType = data.type;
      $('#holderModal').modal();
    });
    $('#tableHolder').on('click', '.holder_editor_remove', function () {
      let data;
      if ($('.parent.selected').length) {
        data = dataTable.row($('.parent.selected')).data();
      } else {
        data = dataTable.row($(this).parents('tr')).data();
      }
      that.holderId = data.id;
      that.holderType = data.type;
      that.removeHolder();
    });
    //
    $('#tableHolder').on('click', 'tbody > tr .stack_editor_cancel', function () {
      const id = $(this).attr('data_item');
      const name = $(this).attr('data_name');
      that.holderService.getProjectsByHolder(id).subscribe(
        d => {
          let htm = '<table class=\'table table-condensed\'><thead><tr><th>N°</th><th>Project</th>' + /*<th>Master Project</th>*/'<th>Start date</th><th>End date</th></tr></thead><tbody>';
          d.map((r, i) => {
            // let startDate = r.start!=null?r.start.date.split(' ')[0]: '';
            // let endDate = r.end!=null?r.end.date.split(' ')[0]: '';
            htm += '<tr><td style=\'text-align: center;\'>' + (i + 1) + '</td><td style=\'text-align: center;\'>' + r.name + '</td>' +/*<td style=\'text-align: center;\'>' + r.master + '</td>*/'<td style=\'text-align: center;\'>' + r.start + '</td><td style=\'text-align: center;\'>' + r.end + '</td></tr>';
          });
          htm += '</tbody></table';
          swal({
            title: this.lang === 'en' ? 'Projects having Stewardship & Ownership <b style=\'color:#0ba360\'>' + name + '</b>!' : 'Projets ayant la Gestion & Propriété <b style=\'color:#0ba360\'>' + name + '</b>!',
            text: '<div class=\'body table-responsive\'>' + htm + '</div>',
            html: true
          });

        },
        err => {
          console.log(err);
        }
      );

    });
  }

  removeHolder() {
    const that = this;
    let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
    Swal.fire({
      title: this.lang === 'en' ? 'DELETE Stewardship & Ownership?' : 'SUPPRIMER la Gestion & Propriété?',
      text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, L\'approuver!',
      cancelButtonText: cancelButtonText
    }).then((result) => {
      if (result.value) {
        that.holderService.removeHolder(that.holderId).subscribe(
          d => {
            swal({
              title: this.lang === 'en' ? 'Success!' : 'Succès!',
              text: this.lang === 'en' ? 'Stewardship & Ownership delete.' : 'Suppression de la Gestion & Propriété.',
              timer: 2000,
              showConfirmButton: false
            });

            $('#tableHolder').DataTable().ajax.reload();
          },
          err => {
            //  console.error(err);
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer);
                toast.addEventListener('mouseleave', Swal.resumeTimer);
              }
            });

            Toast.fire({
              icon: 'error',
              title: err.error
            });
          }
        );
      }
    });
  }
  checkName() {
    const email = this.frmHolder.value.email;
    if (email == '') { this.nameError = true; this.nameSuccess = false; return; }
    // const mailformat = /^\w+(?:[\.-]?\w+)*@\w+(?:[\.-]?\w+)*(?:\.\w{2,3})+$/;
    const mailformat = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    if (!email.match(mailformat)) { this.nameError = true; this.nameSuccess = false; return; }
    this.holderService.checkHolder(email).subscribe(
      d => {
        // @ts-ignore
        if (d.response.indexOf('taken') != -1) { this.nameError = true; this.nameSuccess = false; } else { this.nameError = false; this.nameSuccess = true; }
      },
      e => { console.error(e); }
    );
  }

  getStackList() {
    this.holderService.getStackList().subscribe(
      data => {
        this.stacks = data;
      },
      error => {
        console.error(error);
      }
    );
  }
  addHolder() {
    this.editMode = false;
    this.holderType = 'StakeHolder';
    this.translate.get('holders.addStewardShip').subscribe((res: string) => {
      console.log(res);
    });
    this.isUser = false;
    this.frmHolder.reset();
    this.userNameError = false;
    this.userNameSuccess = false;
    this.suggest = '';
    this.frmHolder.get('selectedRole').patchValue(this.userRoles[0].id);
    this.frmHolder.get('user').patchValue(false);
    $('#holderModal').modal();
  }

  getRoles() {
    this.holderService.getRoles().subscribe(
      d => {
        this.userRoles = d;
        if (d.length > 0) {
          this.frmHolder.get('selectedRole').patchValue(d[0].id);
        }
      },
      error => {
        console.error(error);
      }
    );
  }

  suggestName() {
    this.frmHolder.get('userName').patchValue(this.suggest);
    this.userName = this.suggest;
    this.userNameSuccess = false;
    this.userNameError = false;
  }
  saveHolder(firstName, lastName, email, stack, user, role) {
    const port = window.location.port;
    const userId = localStorage.getItem('userId');
    if (this.editMode) {
      const postData = new holderJson(this.holderId, firstName, lastName, email, stack, port, userId, user, role, this.userName);
      this.holderService.updateHolder(this.holderId, postData).subscribe(
        data => {
          $('#holderModal').modal('hide');
          swal({
            title: this.lang === 'en' ? 'Edit Success!' : 'Édition réussie!',
            text: this.lang === 'en' ? 'Stewardship & Ownership edited.' : 'Gestion & Propriété éditée.',
            timer: 2000,
            showConfirmButton: false
          });

          this.modalSending = false;
          $('#tableHolder').DataTable().ajax.reload();
          this.suggest = '';
          this.userName = '';
        },
        err => {
          console.error(err);
          this.modalSending = false;
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            }
          });
          Toast.fire({
            icon: 'error',
            title: this.lang === 'en' ? 'Wrong Stewardship & Ownership data' : 'Données incorrectes de Gestion & Propriété'
          });
        }
      );
    } else {
      const postData = new holderJson('', firstName, lastName, email, stack, port, userId, user, role, this.userName);
      // console.log(postData);return;
      this.holderService.checkHolder(email).subscribe(
        validation => {
          if (validation.response.indexOf('taken') != -1) {
            this.nameError = true; this.nameSuccess = false;
            $('#holderModal').modal('hide');
            swal({
              title: this.lang === 'en' ? 'Error Stewardship & Ownership!' : 'Erreur Gestion & Propriété!',
              text: validation.message,
              timer: 2000,
              showConfirmButton: false
            });
            this.modalSending = false;
            $('#tableHolder').DataTable().ajax.reload();
            this.suggest = '';
            this.userName = '';
          } else {
            this.nameError = false; this.nameSuccess = true;
            this.holderService.saveHolder(postData).subscribe(
              data => {
                $('#holderModal').modal('hide');
                swal({
                  title: this.lang === 'en' ? 'Add Success!' : 'Ajout réussi!',
                  text: this.lang === 'en' ? 'New Stewardship & Ownership Added.' : 'Nouvelle Gestion & Propriété ajoutée.',
                  timer: 2000,
                  showConfirmButton: false
                });
                this.modalSending = false;
                $('#tableHolder').DataTable().ajax.reload();
                this.suggest = '';
                this.userName = '';
              },
              err => {
                console.error(err);
                this.modalSending = false;
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer);
                    toast.addEventListener('mouseleave', Swal.resumeTimer);
                  }
                });
                Toast.fire({
                  icon: 'error',
                  title: this.lang === 'en' ? 'Wrong Stewardship & Ownership data' : 'Données incorrectes de Gestion & Propriété'
                });
              }
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    }
  }
  private initForm1() {
    return this.fb.group(
      {
        firstName: [
          null,
          Validators.compose([Validators.required])
        ],
        lastName: [
          null,
          Validators.compose([Validators.required])
        ],
        email: [
          null,
          Validators.compose([Validators.required])
        ],
        selectedStack: [
          null,
          Validators.compose([Validators.required])
        ],
        user: [
          false
        ],
        selectedRole: [
          null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }
  _toggleSidebar() {
    this._openedSide = !this._openedSide;
    this._openedSide ? $('#holderglobChart').css({ 'width': '117%', 'height': '86vh' }) : $('#holderglobChart').css({ 'width': '100%', 'height': '86vh' });
    if (this.showcirclePack) {
      $('#holderListGlos').html('<i class="fas fa-arrow-circle-right fa-2x"></i>');
      this.showcirclePack = false;
      $('#legendGlobSizeRowWrapper').css('display', 'block');
    } else {

      $('#legendGlobSizeRowWrapper').css('display', 'none');
      $('#holderListGlos').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
      this.showcirclePack = true;
    }
  }
  legendCirclePack(scaleFactor, id, legendSizes, legendTexts, legendColor) {



    const commaFormat = d3.format(',');

    const offset = legendSizes.length - 1;
    let width = $('#' + id).empty().width(),
      height = legendSizes[offset] * 2 * 1.2;
    width = 400;
    const legendCenter = -10,
      legendBottom = height,
      legendLineLength = legendSizes[offset] * 1.3,
      textPadding = 5;

    // Create SVG for the legend
    const legends = d3.select('#' + id).append('svg')
      .attr('width', width)
      .attr('height', height * 1.5)
      .append('g')
      .attr('class', 'legendWrapper')
      .attr('transform', 'translate(' + width / 2 + ',' + 0 + ')');
    //  .style("opacity", 0);

    // Draw the circles
    legends.selectAll('.legendCircle')
      .data(legendSizes)
      .enter().append('circle')
      .attr('r', d => d)
      .attr('class', 'legendCircle')
      .attr('cx', legendCenter)
      .style('stroke', (d, i) => legendColor[i])
      .attr('cy', d => legendBottom - d);

    legends.selectAll('.legendLine')
      .data(legendSizes)
      .enter().append('line')
      .attr('class', 'legendLine')
      .attr('x1', legendCenter)
      .attr('y1', d => legendBottom - 2 * d)
      .attr('x2', legendCenter + legendLineLength)
      .attr('y2', d => legendBottom - 2 * d);
    // Place the value next to the line
    legends.selectAll('.legendText')
      .data(legendSizes)
      .enter().append('text')
      .attr('class', 'legendText')
      .style('stroke', (d, i) => legendColor[i])
      .attr('x', legendCenter + legendLineLength + textPadding)
      .attr('y', d => legendBottom - 2 * d)
      .attr('dy', '0.3em')
      .text((d, i) => legendTexts[i]);

  }
  packedDimensionByHolder() {
    this.holderService.packedDimensionByHolder().subscribe(
      d => {
        // @ts-ignore
        if (d.children.length > 0) {
          this.onGetCirclePack(d);
          this.onGetTableDimension(d);
        }
      },
      err => {
        console.error(err);
      }
    );
  }
  onGetCirclePack(data) {

    const color = d3.scaleLinear()
      .domain([0, 5])
      .range(['hsl(152,80%,80%)', 'hsl(228,30%,40%)'])
      .interpolate(d3.interpolateHcl);
    const width = 932;
    const height = 932;

    const pack = data => d3.pack()
      .size([width, height])
      .padding(20)
      (d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value));

    const root = pack(data);
    console.log(root);

    let focus = root;
    let view;
    $('#userglobChart').empty();
    const svg = d3.select('#holderglobChart');
    svg.attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)

      // .style('margin', '6 35px')
      .style('background', '#ffffff')
      .style('cursor', 'pointer')
      .on('click', () => zoom(root));

    // add shadow filter
    svg.append('svg:defs').selectAll('filter')
      .data(['shadowGlobeSize'])
      .enter().append('svg:filter')
      .attr('id', String)
      .attr('y', -10)
      .attr('x', -10)
      .attr('height', 40)
      .attr('width', 150)
      .append('feOffset')
      .attr('in', 'SourceAlpha')
      .attr('dx', 3)
      .attr('dy', 3)
      .attr('result', 'offset2')
      .attr('stdDeviation', 4);
    svg.selectAll('filter')
      .append('feGaussianBlur')
      .attr('in', 'offset2')
      .attr('result', 'blur2')
      .attr('stdDeviation', 10);
    svg.selectAll('filter')
      .append('feMerge')
      .append('feMergeNode')
      .attr('in', 'blur2');
    svg.selectAll('filter')
      .select('feMerge')
      .append('feMergeNode')
      .attr('in', 'SourceGraphic');
    const cont = svg.append('g');

    const node = cont
      .selectAll('circle')
      .data(root.descendants().slice(1))
      .join('circle')
      .attr('class', 'circulos')
      .attr('id', d => d.data.type == 'Role' ? d.parent.parent.data.id + d.parent.data.id + d.data.id : d.data.id)
      .attr('fill', d => d.children ? color(d.depth) : 'white')
      .on('click', d => focus !== d && (zoom(d), d3.event.stopPropagation()));

    const g = svg.append('g');

    let scaleAvr = 1;
    $('#zoomIn_button').on('click', function () {
      if (scaleAvr < 2) {
        scaleAvr += 0.1;
      } else {
        scaleAvr = 2;
      }
      zoomGroups();
    });
    $('#zoomOut_button').on('click', function () {
      if (scaleAvr > 0.5) {
        scaleAvr -= 0.1;
      } else {
        scaleAvr = 0.5;
      }
      zoomGroups();

    });
    $('#resetSize_button').on('click', function () {
      scaleAvr = 1;
      zoomGroups();
    });
    const zoomGroups = () => {
      cont.transition().duration(100).style('transform', 'scale(' + scaleAvr + ')');

    };
    // sunbusrt chart container init
    const width1 = 300;

    const label = svg.append('g')
      .style('font', '14px sans-serif')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .selectAll('text')
      .attr('class', 'labelZoom')
      .data(root.descendants())
      .join('text')
      .style('fill-opacity', d => d.parent === root ? 1 : 0)
      .style('display', d => d.parent === root ? 'inline' : 'none')
      .text(d => d.data.name);

    zoomTo([root.x, root.y, root.r * 2]);

    function zoomTo(v) {
      const k = width / v[2];

      view = v;
      label.attr('transform', d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);

      node.attr('transform', d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
      node.attr('r', d => d.r * k);
    }
    function zoom(d) {

      if (d.depth > 1) {
        g.attr('transform', 'translate(0,0) scale(2)');
        g.transition().duration(10).style('opacity', '1');
      }
      focus = d;

      if (d.depth == 0 || d.depth == 1) {
        g.style('display', 'none');
      } else {
        g.transition().duration(100).style('display', 'block');
      }
      const transition = svg.transition()
        .duration(d3.event.altKey ? 7500 : 750)
        .tween('zoom', d => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return t => zoomTo(i(t));
        });

      label
        .filter(function (d) {
          return d.parent === focus || this.style.display === 'inline';
        })
        .transition(transition)
        .style('fill-opacity', d => d.parent === focus ? 1 : 0)
        .on('start', function (d) {
          if (d.parent === focus) {
            this.style.display = 'inline';
          }
        })
        .on('end', function (d) {
          if (d.parent !== focus) {
            this.style.display = 'none';
          }
        });
    }
  }
  onGetTableDimension(d) {
    const t = d.children;

    const columnDefs = [{
      data: 'id',
      title: this.lang === 'en' ? 'Id Dimension' : 'Identifiant Dimension',
      visible: false,
      searchable: false
    },
      {
        data: 'name',
        title: this.lang === 'en' ? 'Dimension' : 'Dimension'
      },
      {
        data: 'value',
        title: this.lang === 'en' ? 'Affected Categories' : 'Catégories affectées'
      }
    ];
    const dataTable = $('#tableDimensionHolder').DataTable({
      sPaginationType: 'full_numbers',
      data: t,
      destroy: true,
      columns: columnDefs,
      dom: 'Bfrtip',        // Needs button container
      select: 'single',
      responsive: true,
      rowCallback(row, data) {
        $('td:eq(0)', row).html('<a class="dataDimensionHolderType" data-name="' + data.name + '" data-id="' + data.id + '" style="color:#257EAD;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00829B\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
      },
      initComplete(settings, json) {
        $('#tableDimensionHolder tbody').on('click', 'a.dataDimensionHolderType', function () {
          const dim = $(this).attr('data-name');
          const tr = $(this).closest('tr');
          const id = $(this).attr('data-id');
          document.getElementById(id).dispatchEvent(new Event('click'));
          const row = $('#tableDimensionHolder').DataTable().row(tr);
          $('.dmHoldershown').next('tr').hide();
          const indexRow = row.index();
          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('dmHoldershown');
          } else {
            tr.addClass('dmHoldershown');
            if ($('#table_dimensionHolderType_children').length) {
              $('#table_dimensionHolderType_children').remove();
            }
            row.child('<table class="table_dimensionHolderType_fils" id ="table_dimensionHolderType_children" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();

            // console.log(d);
            const categories = t.find(e => e.name == dim).children;
            $('#table_dimensionHolderType_children').DataTable({
              data: categories,
              responsive: true,
              destroy: true,
              pageLength: 5,
              rowCallback(row, data) {
                $('td:eq(0)', row).html('<a class="dataDimensionHolderDetail" data-count=' + data.funcs + ' data-value=' + data.value + ' data-name="' + data.name + '" data-id="' + data.id + '" data-parent ="' + id + '" style="color:#257EAD;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00829B\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
              },
              lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
              columns: [
                {
                  data: 'name',
                  title: this.lang === 'en' ? 'Categories' : 'Catégories'
                },
                {
                  data: 'funcs',
                  title: this.lang === 'en' ? 'Affected Stewardship & Ownership' : 'Gestion & Propriété affectées'
                }
              ],
              // init complete data roles
              initComplete(settings, json) {
                $('#table_dimensionHolderType_children tbody').on('click', 'a.dataDimensionHolderDetail', function () {
                  const funcs = $(this).attr('data-count');
                  if (funcs == 0) {
                    const id = $(this).attr('data-id');
                    document.getElementById(id).dispatchEvent(new Event('click'));
                  } else {
                    console.log(json)
                    const dim = $(this).attr('data-name');
                    const value = $(this).attr('data-value');
                    if (value == 0) {
                      return;
                    }
                    const tr = $(this).closest('tr');
                    const row = $('#table_dimensionHolderType_children').DataTable().row(tr);
                    const id = $(this).attr('data-id');
                    const parent = $(this).attr('data-parent');
                    document.getElementById(id).dispatchEvent(new Event('click'));
                    $('.dmUserdetailshown').next('tr').hide();
                    const indexRow = row.index();
                    if (row.child.isShown()) {
                      // This row is already open - close it
                      row.child.hide();
                      tr.removeClass('dmUserdetailshown');
                    } else {
                      tr.addClass('dmUserdetailshown');
                      if ($('#table_dimensionHolderDetail_children').length) {
                        $('#table_dimensionHolderDetail_children').remove();
                      }
                      row.child('<table class="table_dimensionHolderDetail_fils" id ="table_dimensionHolderDetail_children" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();

                      const data = categories.find(e => e.name == dim);
                      $('#table_dimensionHolderDetail_children').DataTable({
                        data: data.children,
                        responsive: true,
                        destroy: true,
                        pageLength: 5,
                        rowCallback(row, data) {
                          $('td:eq(0)', row).html('<a class="dataRoleHolderClick" data-name="' + data.name + '" data-id="' + parent + id + data.id + '" style="color:#257EAD;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00829B\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
                        },
                        initComplete(settings, json) {
                          $('#table_dimensionHolderDetail_children tbody').on('click', 'a.dataRoleHolderClick', function () {
                            const id = $(this).attr('data-id');
                            document.getElementById('holderglobChart').dispatchEvent(new Event('click'));
                            $('.pulseVolume').toggleClass('pulseVolume');
                            $('#' + $.escapeSelector(id)).toggleClass('pulseVolume');
                            setTimeout(() => {
                              $('#' + $.escapeSelector(id)).removeClass('pulseVolume');
                            }, 10000);
                          });
                        },
                        lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                        columns: [
                          {
                            data: 'name',
                            title: this.lang === 'en' ? 'Stewardship & Ownership' : 'Gestion & Propriété'
                          }
                        ]
                      });
                    }
                  }
                });
              }
              // end init complete data roles
            });
          }
        });
      }
    });

  }
}
