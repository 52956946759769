import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';

import { AnonymBoardRoutingModule } from './anonym-board-routing.module';
import {MainAnonymComponent} from './main-anonym/main-anonym.component';
import {NgxDropzoneModule} from 'ngx-dropzone';


@NgModule({
  declarations: [MainAnonymComponent],
  imports: [
    CommonModule,
    AnonymBoardRoutingModule,
    TranslateModule,
    NgxDropzoneModule,
  ]
})
export class AnonymBoardModule { }
