export class RulesRole {
  appl: string;
  models: any[];
  filtersRead = false;


  constructor(appl, filtersRead,models) {
    this.appl = appl;
    this.models = [...models];
    this.filtersRead=filtersRead;
  }
}
