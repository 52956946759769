import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DataCatalogueComponent} from './mainData/data-catalogue.component';


const routes: Routes = [
  {
      path: '',
      redirectTo: 'data-catalogue',
      pathMatch: 'full'
  },
  {
      path: 'data-catalogue',
      component: DataCatalogueComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataCatalogueRoutingModule { }
