import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getCookieValue, Shared, skipString} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class BusinessTermService {

  constructor(private http: HttpClient) { }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptionsDelete(body) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      }),
      body: 'data=' + JSON.stringify(body)
    };
  }
  getBusinessTerms(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post<any>(Shared.API_URL + '/api/business_term/getBusinessTerms/' + localStorage.getItem('userId') , data , this.getOptions());
  }
  addBusinessTerm(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/business_term/addBusinessTerm/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  updateBusinessTerm(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/business_term/updateBusinessTerm/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  createNewVersionBusinessObject(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/business_term/createNewVersionBusinessObject/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  createFirstVersionBOS(d): Observable<any> {
      const data = 'data=' + JSON.stringify(d);
      return this.http.post(Shared.API_URL + '/api/business_term/createFirstVersionBOS/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  createNewVersionBusinessTerm(d): Observable<any> {
      const data = 'data=' + JSON.stringify(d);
      return this.http.post(Shared.API_URL + '/api/business_term/createNewVersionBusinessTerm/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  createFirstVersionBTS(d): Observable<any> {
      const data = 'data=' + JSON.stringify(d);
      return this.http.post(Shared.API_URL + '/api/business_term/createFirstVersionBTS/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  removeBusinessObject(idObject, idGroup, idFunc, lastVF, nameGlos, idGlos, action): Observable<any> {
      return this.http.delete(Shared.API_URL + '/api/business_term/removeBusinessObject/' + localStorage.getItem('userId') + '/'
          + idObject + '/' + idGroup + '/' + idFunc + '/' + lastVF + '/' + nameGlos + '/' + idGlos + '/' + action, this.getOptions());
  }
  removeModuleGroup(idGroup, idFunc, lastVF, nameGlos, idGlos): Observable<any> {
      return this.http.delete(Shared.API_URL + '/api/business_term/removeModuleGroup/' + localStorage.getItem('userId') + '/'
          + idGroup + '/' + idFunc + '/' + lastVF + '/' + nameGlos + '/' + idGlos, this.getOptions());
  }
  removeBusinessTerm(idTerm, idObject, idGroup, idFunc, lastVF, nameGlos, idGlos, action): Observable<any> {
    return this.http.delete(Shared.API_URL + '/api/business_term/removeBusinessTerm/' + localStorage.getItem('userId') + '/' + idTerm + '/'
      + idObject + '/' + idGroup + '/' + idFunc + '/' + lastVF + '/' + nameGlos + '/' + idGlos + '/' + action, this.getOptions());
  }
  // business id
  getBusinessIds(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getBusinessIds/' + localStorage.getItem('userId') , this.getOptions());
  }
  addBusinessId(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/business_term/addBusinessId/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  removeBusinessId(id): Observable<any> {
    return this.http.delete(Shared.API_URL + '/api/business_term/removeBusinessId/' + localStorage.getItem('userId')  + '/' + id, this.getOptions());
  }
  getCatalogueLineage(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getCatalogueLineage/' + localStorage.getItem('userId')  + '/' + id, this.getOptions());
  }
  saveCatalogueLineage(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/business_term/saveCatalogueLineage/' + localStorage.getItem('userId') , data, this.getOptions());
  }

  getBusinessTermList(): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getBusinessTermList' , this.getOptions());
  }
  checkBusinessTerm(name: string): Observable<any>  {
  return this.http.get<any>(Shared.API_URL + '/api/business_term/checkBusinessTerm/' + name , this.getOptions());
  }
  getBusinessObjectList(): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getBusinessObjectList' , this.getOptions());
  }
  getBusinessTermsList(): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getBusinessTermsList' , this.getOptions());
  }
  lockBusinessTerm(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/business_term/lockBusinessTerm' , data, this.getOptions());
  }
  getSynonymList(): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getSynonymList' , this.getOptions());
  }
  getObjectList(): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getObjectList' , this.getOptions());
  }
  getBusinessSynonymList(): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getBusinessSynonymList' , this.getOptions());
  }
  getFunctionalMap(id): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/business_glossary/getFunctionalMap/' + localStorage.getItem('userId') + '/' + id , this.getOptions());
  }
  getCatalogByBusinessTerm(name): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getCatalogByBusinessTerm/' + localStorage.getItem('userId') + '/' + name , this.getOptions());
  }
  ////////////////////// added by lotfi ///// dimension glossary ////////////////////
  // list dimension to chart Pack
  getListGlossaryDimensions() {
    return this.http.get(Shared.API_URL + '/api/business_term/getListGlossaryDimensions', this.getOptions());
  }
  // list dimensions to filter business glossary
  getBusinessDimensionsList(){
    return this.http.get(Shared.API_URL + '/api/business_term/getBusinessDimensionsList', this.getOptions());
  }
  getBusinessDimensionsByName(name){
    return this.http.get(Shared.API_URL + '/api/business_term/getBusinessDimensionsByName/' + name, this.getOptions());
  }
  getEntities(){
        return this.http.get(Shared.API_URL + '/api/business_term/getEntities' , this.getOptions());
    }
  getEntitiesFilter(){
    return this.http.get(Shared.API_URL + '/api/business_term/getEntitiesFilter' , this.getOptions());
  }
  getFunctionalMapsFilter(){
    return this.http.get(Shared.API_URL + '/api/business_term/getFunctionalMapsFilter' , this.getOptions());
  }
  uploadGlossary(d, type= 'json'): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post<any>(Shared.API_URL + '/api/business_term/uploadGlossary/' + localStorage.getItem('userId') + '/' + type , data , this.getOptions());
  }
  getFunctionalMaps(){
    return this.http.get(Shared.API_URL + '/api/business_term/getFunctionalMaps' , this.getOptions());
  }
  // bookMarks service
  getBookMarks(component = 'businessGlossary'){
    return this.http.get(Shared.API_URL + '/api/book_mark/getBookMarks/' + localStorage.getItem('userId') + '/' + component , this.getOptions());
  }
  addBookMarks(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/book_mark/addBookMarks/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  updateBookMarks(d, component = 'businessGlossary'): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/book_mark/updateBookMarks/' + localStorage.getItem('userId') + '/' + component , data, this.getOptions());
  }
  removeBookMarks(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.delete(Shared.API_URL + '/api/book_mark/removeBookMarks' , this.getOptionsDelete(d));
  }
  checkBookMark(name: string): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/book_mark/checkBookMark/' + name , this.getOptions());
  }
}
