import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ConservationPeriod} from '../../drm/retention-period/ConservationPeriod';
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class DefinitionService {
  username: string;
  userId: string;
  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  constructor(private http: HttpClient, private router: Router) { }

  // add new Definition in Modal
  setNewDefinition(dataDefinition: any) {
    // this.username = getCookieValue('username');
    const data = 'data=' + JSON.stringify(dataDefinition);
    return this.http.post(Shared.API_URL + '/api/drm-definition/setNewDefinition', data, this.getOptions());
  }
  // list all Definitions
  getListDefinitions() {
    this.userId = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/drm-definition/getListDefinitions/' + this.userId, this.getOptions1());
  }
  getDefinitionDimensionsByName(name) {
    return this.http.get(Shared.API_URL + '/api/drm-definition/getDefinitionDimensionsByName/' + name, this.getOptions());
  }
  // remove selected Definition
  setRemoveDefinition(id: string, action: string) {
    return this.http.delete(Shared.API_URL + '/api/drm-definition/setRemoveDefinition/' + id + '/' + action, this.getOptions());
  }
  // update selected Definition in Modal
  setUpdateDefinition(dataDefinition: any) {
    // this.username = getCookieValue('username');
    const data = 'data=' + JSON.stringify(dataDefinition);
    return this.http.put(Shared.API_URL + '/api/drm-definition/setUpdateDefinition', data, this.getOptions());
  }
  // list dimension to chart Pack
  getListClassificationDimensions() {
    return this.http.get(Shared.API_URL + '/api/drm-definition/getListClassificationDimensions', this.getOptions1());
  }
  /////////////// synchronization ClassificationDefinition and ObjectModelClassif ///////////////
  // not exist
  checkNotExistComplianceInDefinition() {
    return this.http.get(Shared.API_URL + '/api/drm-definition/checkNotExistComplianceInDefinition', this.getOptions1());
  }
  submitAddSelectedComplianceInDefinition(listCompliance: any) {
    const data = 'data=' + JSON.stringify(listCompliance);
    return this.http.post(Shared.API_URL + '/api/drm-definition/submitAddSelectedComplianceInDefinition',  data, this.getOptions());
  }
  // exit but not synchronized
  // checkExistComplianceInDefinition() {
  //   return this.http.get(Shared.API_URL + '/api/drm-definition/checkExistComplianceInDefinition', this.getOptions1());
  // }
  getCheckExistComplianceInDefinition() {
    return this.http.get(Shared.API_URL + '/api/drm-definition/getCheckExistComplianceInDefinition', this.getOptions1());
  }
  // remove exist definitions from list before synchronize
  removeDefinitionBeforeSynchro(listCompliance: any) {
    const dataList = 'data=' + JSON.stringify(listCompliance);
    return this.http.post(Shared.API_URL + '/api/drm-definition/removeDefinitionBeforeSynchro', dataList, this.getOptions());
  }
}
