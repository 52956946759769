import * as d3 from 'd3';
import {getCookieValue, Shared} from '../../shared';
import { renderTaskObject, toggleGroup } from "./treeDetail";

declare const $: any;

export const render = (campainData, app, listTreeObject) => {
  // // flag for displaying or hiding ok archives
  // let okChildrenShow = true;
  // // flag for displaying or hiding ko archives
  // let koChildrenShow = true;
  // // flag for displaying or hiding to be processed archives
  // let unProcessedChildrenShow = true;
  const getcolor = function (d) {
    var c;
    switch (d) {
      case 1: c = 'red'; break;
      case 2: c = 'blue'; break;
      case 3: c = 'green'; break;
    }
    return c;
  };
  const width = 900;
  const height = 900;
  const svg = d3.select("#TreeChartCampain").attr("viewBox", `0 0 ${width} ${height}`);
  svg.selectAll("*").remove();
  const container = svg.append('g')
    .attr('id', 'nodosContainer')
    .style("font", "8px sans-serif")
    .style("text-anchor", "middle");
  svg.attr('width', width);
  svg.attr('height', height);
  let { links, nodes } = campainData;
  svg.call(
    d3.zoom()
      .scaleExtent([.1, 20])
      .on("zoom", function () { container.attr("transform", d3.event.transform); })
  );
  initFilter(container);
  initGradian(container);
  const simulation = forceSimulation(d3, { width, height });

  const dragDiv = d3.drag()
    .subject(function () {
      return {
        x: parseInt(d3.select(this).style("left")),
        y: parseInt(d3.select(this).style("top"))
      };
    })
    .on("drag", function () {
      d3.select(this)
        .style("left", d3.event.x + "px")
        .style("top", d3.event.y + "px");
    });

  d3.select(".d3-tip").call(dragDiv);

  let link = container.selectAll(".link")
    .data(links)
    .enter()
    .append("line")
    .attr("class", "link")
    .attr('style', 'cursor:pointer;')
    .attr('stroke-width', '.4')
    .style('display', 'none')
    .attr('id', d => 'campainLink_' + d.id)
    // .attr('marker-end', (d) => 'url(#' + d.color + ')')
    // .attr('marker-mid', (d) => 'url(#arrow)')
    .attr('stroke', d => d.color);


  const node = container.selectAll(".node")
    .data(nodes)
    .enter()
    .append("g")
    .attr("class", 'node')
    .attr('id', d => 'campainNode_' + d.id)
    .style('display', (d) => d.group != 'noGroup' ? 'none' : 'block')
    .on("click", d => handleNodeClicked(d, nodes, links, app, listTreeObject))
    .call(d3.drag()
      .on("start", function dragenstart(d) {
        d3.event.sourceEvent.stopPropagation();
        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;

      })
      .on("drag", function dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;

      })
      .on("end", function dragended(d) {
        if (!d3.event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;

      })
    );

  node.append('rect');
  node.append("text").text((d) => d.name).attr("class", "node-text");
  node.append("svg:circle")
    .attr("class", "node-glyph")
    .attr('r', 10)
    .attr('cx', -5)
    .attr('cy', -25)
    .attr('id', (d) => 'node-glyph_' + d.name)
    .attr('fill', '#044278');

  node.append("svg:text").text(d => d.count)
    .attr("class", "glyph-label")
    .attr('x', -5)
    .attr('y', -23)
    .attr('fill', 'white')
    .attr('id', (d) => 'glyph-label_' + d.name)
    .attr("text-anchor", "middle");



  const paddingLeftRight = 18; // adjust the padding values depending on font and font size
  const paddingTopBottom = 5;

  container.selectAll("rect")
    .attr("x", function (d) { return this.parentNode.childNodes[1].getBBox().x - paddingLeftRight; })
    .attr("y", function (d) { return this.parentNode.childNodes[1].getBBox().y - paddingTopBottom - 6; })
    .attr("rx", 10)
    .attr("ry", 10)
    .attr("width", function (d) { return (this.parentNode.childNodes[1].getBBox().width + paddingLeftRight); })
    .attr("height", function (d) { return this.parentNode.childNodes[1].getBBox().height + paddingTopBottom * 2; })
    .attr('fill', d => {
      if (d.status == 'OK') {
        return 'url(#gradOk)';
      }
      if (d.status == 'KO') {
        return 'url(#gradKo)';
      }
      if (d.status == 'TO BE PROCESSED') {
        return 'url(#gradToBe)';
      }
    })
    .attr('id', (d) => d.name)
    .attr('filter', 'url(#shadow)')
    .style('text-align', 'middle');

  // zoom global chart buttons
  let scaleAvr = 1;
  $('#zoomIn_button').on('click', function () {
    if ($('#TreeChartCampain').css('display') == 'block') {
      if (scaleAvr < 2) {
        scaleAvr += 0.1;
      } else {
        scaleAvr = 2;
      }
      zoomGroups();
    }
  });
  $('#zoomOut_button').on('click', function () {
    if ($('#TreeChartCampain').css('display') == 'block') {
      if (scaleAvr > 0.5) {
        scaleAvr -= 0.1;
      } else {
        scaleAvr = 0.5;
      }
      zoomGroups();
    }
  });
  $('#resetSize_button').on('click', function () {
    if ($('#TreeChartCampain').css('display') == 'block') {
      scaleAvr = 1;
      zoomGroups();
    }
  });
  const zoomGroups = () => {
    container.transition().duration(100).style('transform', 'scale(' + scaleAvr + ')');
  };
  container.selectAll('text.node-text')
    .attr("x", function (d) { return this.parentNode.childNodes[0].getBBox().x - (this.parentNode.childNodes[0].getBBox().x + 10); })
    .attr('fill', '#f8f9fa')
    .attr('id', (d) => 'text_' + d.name)
    .style('cursor', 'pointer')
    .attr("y", function (d) { return this.parentNode.childNodes[1].getBBox().y + 2; });

  simulation
    .nodes(nodes)
    .on("tick", () => {
      ticked(link, node);
    })
    .on("end", () => { });
  simulation.force("link")
    .links(links);
  $('#treeGlobalSwitch').change(function () {
    if (this.checked) {
      toggleGroupGlobal(nodes);
    } else {
      toggleGroupGlobal(nodes, false);
    }
  });
};

export const ticked = (link, node) => {

  link
    .attr("x1", function (d) { return d.source.x; })
    .attr("y1", function (d) { return d.source.y; })
    .attr("x2", function (d) { return d.target.x; })
    .attr("y2", function (d) { return d.target.y; });

  node.attr("transform", function (d) { return "translate(" + d.x + ", " + d.y + ")"; });

};

//fin blue arrow
export const initFilter = (svg) => {
  svg.append("svg:defs").selectAll('filter')
    .data(['shadow'])
    .enter().append('svg:filter')
    .attr("id", String)
    .attr('y', -10)
    .attr('x', -10)
    .attr('height', 40)
    .attr('width', 150)
    .append('feOffset')
    .attr('in', 'SourceAlpha')
    .attr('dx', 3)
    .attr('dy', 3)
    .attr('result', 'offset2')
    .attr('stdDeviation', 4);
  svg.selectAll('filter')
    .append('feGaussianBlur')
    .attr('in', 'offset2')
    .attr('result', 'blur2')
    .attr('stdDeviation', 3);
  svg.selectAll('filter')
    .append('feMerge')
    .append('feMergeNode')
    .attr('in', 'blur2');
  svg.selectAll('filter')
    .select('feMerge')
    .append('feMergeNode')
    .attr('in', 'SourceGraphic');
};
// gradian color array
export const initGradian = (svg) => {
  svg.append("svg:defs").selectAll('linearGradient')
    .data(['gradOk'])
    .enter().append('svg:linearGradient')
    .attr("id", String)
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#04f364')
    .attr('stop-opacity', 1);
  svg.select('#gradOk')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#048551')
    .attr('stop-opacity', 1);
  // ko gradient
  svg.append("svg:defs").append('svg:linearGradient')
    .attr("id", 'gradKo')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#ea1248')
    .attr('stop-opacity', 1);
  svg.select('#gradKo')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#900404')
    .attr('stop-opacity', 1);
  // to be processed gradient
  svg.append("svg:defs").append('svg:linearGradient')
    .attr("id", 'gradToBe')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#888888')
    .attr('stop-opacity', 1);
  svg.select('#gradToBe')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#806969')
    .attr('stop-opacity', 1);
};
export const forceSimulation = (d3: d3, { width, height }) => d3.forceSimulation()
  .force("link",
    d3.forceLink()
      .id(function (d) { return d.id; })
      .distance(70)
      .strength(1))
  .force("charge", d3.forceManyBody().strength(-4000))
  .force("x", d3.forceX(width).strength(1))
  .force("y", d3.forceY(height).strength(1))
  .force('collision', d3.forceCollide())
  .force("center", d3.forceCenter(width / 2, height / 2));

export const handleNodeClicked = (d, nodes, links, app, listTreeObject) => {
  const nodeChilds = getNodesByGroup(nodes, d.id);
  const linkChilds = getNodesByGroup(links, d.id);
  if (d.closed) {
    $.each(nodeChilds, function (i, val) {
      $('#campainNode_' + val.id).css('display', 'block');
      if (val.closed != undefined) {
        const nodesChilds = getNodesByGroup(nodes, val.id);
        const linksChilds = getNodesByGroup(links, val.id);
        $.each(nodesChilds, function (i, data) {
          $('#campainNode_' + data.id).css('display', 'none');
        });
        $.each(linksChilds, function (i, data) {
          $('#campainLink_' + data.id).css('display', 'none');
        });
      }
    });
    $.each(linkChilds, function (i, val) {
      $('#campainLink_' + val.id).css('display', 'block');
    });
  } else {
    $.each(nodeChilds, function (i, val) {
      $('#campainNode_' + val.id).css('display', 'none');
      if (val.closed != undefined) {
        const nodesChilds = getNodesByGroup(nodes, val.id);
        const linksChilds = getNodesByGroup(links, val.id);
        $.each(nodesChilds, function (i, data) {
          $('#campainNode_' + data.id).css('display', 'none');
        });
        $.each(linksChilds, function (i, data) {
          $('#campainLink_' + data.id).css('display', 'none');
        });
      }
    });
    $.each(linkChilds, function (i, val) {
      $('#campainLink_' + val.id).css('display', 'none');
    });
  }
  d.closed = !d.closed;
  if (d.type == 'task' && d.status != 'TO BE PROCESSED') {
    $('#taskNodeModalTitle').html(d.name);
    //  $("#TreeChartObject").empty();
    $('#taskNodeModal').modal();
    $('#TreeChartObject').css({ 'width': '700', 'height': '700' });
    treeObject(d.identif, app, d.name, d.status, listTreeObject);
    $('#TreeChartObject').css({ 'width': '100%', 'height': '100vh' });
    initTableObject(d.identif, app);

  }
};
export const getNodesByGroup = (nodes, nodeId) => {
  return nodes.reduce((_nodes, node) => {
    if (node.group == nodeId) {
      _nodes.push(node);
    }
    return _nodes;
  }, []);
};
export const treeObject = (identif, app, name, status, listTreeObject) => {
  d3.json(Shared.API_URL + '/api/data-life-cycle-role/getTreeTaskDetails/' + identif + '/' + app, {
    headers: new Headers({
      'Accept': 'Application/json',
      'Authorization': 'Bearer ' + getCookieValue('tokendrd')
    }),
  }).then(json => {
    const data = json.data;
    const taskNodes = [];
    const taskLinks = [];
    const allLinks = [];
    allLinks.push(0);
    let id = 1;
    data.map(
      item => {
        let status;
        let color;
        let count;
        if (item.processed == 0) {
          status = 'TO BE PROCESSED';
          color = 'grey';
          count = 0;
        } else {
          status = item.status;
          if (status == 'OK') {
            color = "green";
            count = 3;
          }
          if (status == 'KO') {
            color = "red";
            count = 3;
          }
        }
        const objectNode = { 'id': id, 'name': item.objectName, 'links': [id, 0], 'status': status, 'count': count, 'group': 0, 'closed': true, 'type': 'object' };
        const objectLink = { 'id': id, 'source': 0, 'target': id, 'color': color, 'group': 0 };
        taskNodes.push(objectNode);
        taskLinks.push(objectLink);
        allLinks.push(id);

        if (item.purgeOk > 0 || item.purgeKO > 0) {
          let purgeLinks = [];
          // purge ok childs
          if (item.purgeOk > 0) {
            const purgOkId = id + 1;
            purgeLinks.push(purgOkId);
            item.childs.filter(d => d.cat == 'purge' && d.type == 'Done').map(
              e => {
                id++;
                const objectPurgeOkNode = { 'id': id + 1, 'name': e.name, 'links': [id + 1, purgOkId], 'status': 'OK', 'count': 3, 'group': purgOkId, 'closed': true, 'type': 'table', 'parent': item.idObject };
                const objectPurgeOkLink = { 'id': id + 1, 'source': purgOkId, 'target': id + 1, 'color': 'green', 'group': purgOkId };
                purgeLinks.push(id + 1);
                taskNodes.push(objectPurgeOkNode);
                taskLinks.push(objectPurgeOkLink);
                id++;
                const idPurge = id + 1;
                const objectPurgePurgeNode = { 'id': id + 1, 'name': 'count_purge', 'links': [id + 1, purgeLinks[purgeLinks.length - 1]], 'status': 'OK', 'count': e.count_purge, 'group': purgeLinks[purgeLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectPurgePurgeLink = { 'id': id + 1, 'source': purgeLinks[purgeLinks.length - 1], 'target': id + 1, 'color': 'green', 'group': purgeLinks[purgeLinks.length - 1] };
                taskNodes.push(objectPurgePurgeNode);
                taskLinks.push(objectPurgePurgeLink);
                id++;
                const idArch = id + 1;
                const objectPurgeArchNode = { 'id': id + 1, 'name': 'count_arch', 'links': [id + 1, purgeLinks[purgeLinks.length - 1]], 'status': 'OK', 'count': e.count_purge, 'group': purgeLinks[purgeLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectPurgeArchLink = { 'id': id + 1, 'source': purgeLinks[purgeLinks.length - 1], 'target': id + 1, 'color': 'green', 'group': purgeLinks[purgeLinks.length - 1] };
                taskNodes.push(objectPurgeArchNode);
                taskLinks.push(objectPurgeArchLink);
                id++;
                const idTemp = id + 1;
                const objectPurgeTempNode = { 'id': id + 1, 'name': 'count_purge', 'links': [id + 1, purgeLinks[purgeLinks.length - 1]], 'status': 'OK', 'count': e.count_purge, 'group': purgeLinks[purgeLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectPurgeTempLink = { 'id': id + 1, 'source': purgeLinks[purgeLinks.length - 1], 'target': id + 1, 'color': 'green', 'group': purgeLinks[purgeLinks.length - 1] };
                taskNodes.push(objectPurgeTempNode);
                taskLinks.push(objectPurgeTempLink);
                purgeLinks.push(idPurge);
                purgeLinks.push(idArch);
                purgeLinks.push(idTemp);
              }
            );
            id++;
          }
          //  purge ko childs
          if (item.purgeKO > 0) {
            if (purgeLinks.length == 0) {
              const purgeKOId = id + 1;
              purgeLinks.push(purgeKOId);
            }

            // let purgeKOLinks = [];
            // purgeKOLinks.push(purgeKOId);
            item.childs.filter(d => d.cat == 'purge' && d.type == 'Failed').map(
              e => {
                id++;
                const objectPurgeKONode = { 'id': id + 1, 'name': e.name, 'links': [id + 1, purgeLinks[0]], 'status': 'KO', 'count': 3, 'group': purgeLinks[0], 'closed': true, 'type': 'table', 'parent': item.idObject };
                const objectPurgeKOLink = { 'id': id + 1, 'source': purgeLinks[0], 'target': id + 1, 'color': 'red', 'group': purgeLinks[0] };
                purgeLinks.push(id + 1);
                taskNodes.push(objectPurgeKONode);
                taskLinks.push(objectPurgeKOLink);
                id++;
                const idPurge = id + 1;
                const objectPurgePurgeNode = { 'id': id + 1, 'name': 'count_purge', 'links': [id + 1, purgeLinks[purgeLinks.length - 1]], 'status': 'KO', 'count': e.count_purge, 'group': purgeLinks[purgeLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectPurgePurgeLink = { 'id': id + 1, 'source': purgeLinks[purgeLinks.length - 1], 'target': id + 1, 'color': 'red', 'group': purgeLinks[purgeLinks.length - 1] };
                taskNodes.push(objectPurgePurgeNode);
                taskLinks.push(objectPurgePurgeLink);
                id++;
                const idArch = id + 1;
                const objectPurgeArchNode = { 'id': id + 1, 'name': 'count_arch', 'links': [id + 1, purgeLinks[purgeLinks.length - 1]], 'status': 'KO', 'count': e.count_arch, 'group': purgeLinks[purgeLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectPurgeArchLink = { 'id': id + 1, 'source': purgeLinks[purgeLinks.length - 1], 'target': id + 1, 'color': 'red', 'group': purgeLinks[purgeLinks.length - 1] };
                taskNodes.push(objectPurgeArchNode);
                taskLinks.push(objectPurgeArchLink);
                id++;
                const idTemp = id + 1;
                const objectPurgeTempNode = { 'id': id + 1, 'name': 'count_temp', 'links': [id + 1, purgeLinks[purgeLinks.length - 1]], 'status': 'KO', 'count': e.count_temp, 'group': purgeLinks[purgeLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectPurgeTempLink = { 'id': id + 1, 'source': purgeLinks[purgeLinks.length - 1], 'target': id + 1, 'color': 'red', 'group': purgeLinks[purgeLinks.length - 1] };
                taskNodes.push(objectPurgeTempNode);
                taskLinks.push(objectPurgeTempLink);
                purgeLinks.push(idPurge);
                purgeLinks.push(idArch);
                purgeLinks.push(idTemp);
              }
            );
            // const purgeKONode = {'id':purgeKOId, 'name' : 'PurgeKO', 'links': purgeKOLinks, 'status': 'KO', 'count': item.purgeKO, 'group' : allLinks[allLinks.length-1], 'closed' : true, 'type' : 'cat'};
            // const purgeKOLink = {'id':purgeKOId, 'source' : allLinks[allLinks.length-1], 'target': purgeKOId, 'color': 'red', 'group' : allLinks[allLinks.length-1]};
            // taskNodes.push(purgeKONode);
            // taskLinks.push(purgeKOLink);
            id++;
          }
          const purgeOKNode = { 'id': purgeLinks[0], 'name': 'Purge', 'links': purgeLinks, 'status': item.purgeKO > 0 ? 'KO' : 'OK', 'count': item.purgeOk + item.purgeKO, 'group': purgeLinks[0] - 1, 'closed': true, 'type': 'cat', 'parent': item.idObject };
          const purgeOKLink = { 'id': purgeLinks[0], 'source': purgeLinks[0] - 1, 'target': purgeLinks[0], 'color': 'green', 'group': purgeLinks[0] - 1 };
          taskNodes.push(purgeOKNode);
          taskLinks.push(purgeOKLink);
        }

        if (item.archOk > 0 || item.archKO > 0) {
          let archiveLinks = [];
          //  archive ok childs
          if (item.archOk > 0) {
            const archiveOkId = id + 1;
            archiveLinks.push(archiveOkId);
            item.childs.filter(d => d.cat == 'Archive' && d.type == 'Done').map(
              e => {
                id++;
                const objectArchiveOkNode = { 'id': id + 1, 'name': e.name, 'links': [id + 1, archiveOkId], 'status': 'OK', 'count': 2, 'group': archiveOkId, 'closed': true, 'type': 'table', 'parent': item.idObject };
                const objectArchiveKOLink = { 'id': id + 1, 'source': archiveOkId, 'target': id + 1, 'color': 'green', 'group': archiveOkId };
                archiveLinks.push(id + 1);
                taskNodes.push(objectArchiveOkNode);
                taskLinks.push(objectArchiveKOLink);

                id++;
                const idArch = id + 1;
                const objectArchiveArchNode = { 'id': id + 1, 'name': 'count_arch', 'links': [id + 1, archiveLinks[archiveLinks.length - 1]], 'status': 'OK', 'count': e.count_arch, 'group': archiveLinks[archiveLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectArchiveArchLink = { 'id': id + 1, 'source': archiveLinks[archiveLinks.length - 1], 'target': id + 1, 'color': 'green', 'group': archiveLinks[archiveLinks.length - 1] };
                taskNodes.push(objectArchiveArchNode);
                taskLinks.push(objectArchiveArchLink);
                id++;
                const idCsv = id + 1;
                const objectArchiveCsvNode = { 'id': id + 1, 'name': 'count_csv', 'links': [id + 1, archiveLinks[archiveLinks.length - 1]], 'status': 'OK', 'count': e.count_csv, 'group': archiveLinks[archiveLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectArchiveCsvLink = { 'id': id + 1, 'source': archiveLinks[archiveLinks.length - 1], 'target': id + 1, 'color': 'green', 'group': archiveLinks[archiveLinks.length - 1] };
                taskNodes.push(objectArchiveCsvNode);
                taskLinks.push(objectArchiveCsvLink);
                archiveLinks.push(idArch);
                archiveLinks.push(idCsv);

              }
            );
            // const archiveOkNode = {'id':archiveOkId, 'name' : 'ArchiveOk', 'links': archiveOkLinks, 'status': 'OK', 'count': item.archOk, 'group' : allLinks[allLinks.length-1], 'closed' : true, 'type' : 'cat'};
            // const archiveOkLink = {'id':archiveOkId, 'source' : allLinks[allLinks.length-1], 'target': archiveOkId, 'color': 'green', 'group' : allLinks[allLinks.length-1]};
            // taskNodes.push(archiveOkNode);
            // taskLinks.push(archiveOkLink);
            id++;
          }

          //  archive ko childs
          if (item.archKO > 0) {
            if (archiveLinks.length == 0) {
              const archiveKOId = id + 1;
              archiveLinks.push(archiveKOId);
            }

            item.childs.filter(d => d.cat == 'Archive' && d.type == 'Failed').map(
              e => {
                id++;
                const objectArchiveKONode = { 'id': id + 1, 'name': e.name, 'links': [id + 1, archiveLinks[0]], 'status': 'KO', 'count': 2, 'group': archiveLinks[0], 'closed': true, 'type': 'table', 'parent': item.idObject };
                const objectArchiveKOLink = { 'id': id + 1, 'source': archiveLinks[0], 'target': id + 1, 'color': 'red', 'group': archiveLinks[0] };
                archiveLinks.push(id + 1);
                taskNodes.push(objectArchiveKONode);
                taskLinks.push(objectArchiveKOLink);
                id++;
                const idArch = id + 1;
                const objectArchiveArchNode = { 'id': id + 1, 'name': 'count_arch', 'links': [id + 1, archiveLinks[archiveLinks.length - 1]], 'status': 'KO', 'count': e.count_arch, 'group': archiveLinks[archiveLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectArchiveArchLink = { 'id': id + 1, 'source': archiveLinks[archiveLinks.length - 1], 'target': id + 1, 'color': 'red', 'group': archiveLinks[archiveLinks.length - 1] };
                taskNodes.push(objectArchiveArchNode);
                taskLinks.push(objectArchiveArchLink);
                id++;
                const idCsv = id + 1;
                const objectArchiveCsvNode = { 'id': id + 1, 'name': 'count_csv', 'links': [id + 1, archiveLinks[archiveLinks.length - 1]], 'status': 'KO', 'count': e.count_csv, 'group': archiveLinks[archiveLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectArchiveCsvLink = { 'id': id + 1, 'source': archiveLinks[archiveLinks.length - 1], 'target': id + 1, 'color': 'red', 'group': archiveLinks[archiveLinks.length - 1] };
                taskNodes.push(objectArchiveCsvNode);
                taskLinks.push(objectArchiveCsvLink);
                archiveLinks.push(idArch);
                archiveLinks.push(idCsv);
              }
            );
            // const archiveKONode = {'id':archiveKOId, 'name' : 'ArchiveKO', 'links': archiveKOLinks, 'status': 'KO', 'count': item.archKO, 'group' : allLinks[allLinks.length-1], 'closed' : true, 'type' : 'cat'};
            // const archiveKOLink = {'id':archiveKOId, 'source' : allLinks[allLinks.length-1], 'target': archiveKOId, 'color': 'red', 'group' : allLinks[allLinks.length-1]};
            // taskNodes.push(archiveKONode);
            // taskLinks.push(archiveKOLink);
            id++;
          }
          const archiveKONode = { 'id': archiveLinks[0], 'name': 'Archive', 'links': archiveLinks, 'status': item.archKO > 0 ? 'KO' : 'OK', 'count': item.archKO + item.archOk, 'group': allLinks[allLinks.length - 1], 'closed': true, 'type': 'cat', 'parent': item.idObject };
          const archiveKOLink = { 'id': archiveLinks[0], 'source': allLinks[allLinks.length - 1], 'target': archiveLinks[0], 'color': 'red', 'group': allLinks[allLinks.length - 1] };
          taskNodes.push(archiveKONode);
          taskLinks.push(archiveKOLink);
        }

        if (item.candOk > 0 || item.candKO > 0) {
          let candLinks = [];
          //  candidate ok childs
          if (item.candOk > 0) {
            const candOkId = id + 1;
            candLinks.push(candOkId);
            item.childs.filter(d => d.cat == 'Candidate' && d.type == 'Done').map(
              e => {
                id++;
                const objectcandOkNode = { 'id': id + 1, 'name': e.name, 'links': [id + 1, candOkId], 'status': 'OK', 'count': 1, 'group': candOkId, 'closed': true, 'type': 'table', 'parent': item.idObject };
                const objectcandKOLink = { 'id': id + 1, 'source': candOkId, 'target': id + 1, 'color': 'green', 'group': candOkId };
                candLinks.push(id + 1);
                taskNodes.push(objectcandOkNode);
                taskLinks.push(objectcandKOLink);
                id++;
                const idCsv = id + 1;
                const objectCandCsvNode = { 'id': id + 1, 'name': 'count_csv', 'links': [id + 1, candLinks[candLinks.length - 1]], 'status': 'OK', 'count': e.count_csv, 'group': candLinks[candLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectCandCsvLink = { 'id': id + 1, 'source': candLinks[candLinks.length - 1], 'target': id + 1, 'color': 'green', 'group': candLinks[candLinks.length - 1] };
                taskNodes.push(objectCandCsvNode);
                taskLinks.push(objectCandCsvLink);
                candLinks.push(idCsv);
              }
            );
            // const candOkNode = {'id':candOkId, 'name' : 'candOk', 'links': candOkLinks, 'status': 'OK', 'count': item.candOk, 'group' : allLinks[allLinks.length-1], 'closed' : true, 'type' : 'cat'};
            // const candOkLink = {'id':candOkId, 'source' : allLinks[allLinks.length-1], 'target': candOkId, 'color': 'green', 'group' : allLinks[allLinks.length-1]};
            // taskNodes.push(candOkNode);
            // taskLinks.push(candOkLink);
            id++;
          }
          //  candidate ko childs
          if (item.candKO > 0) {
            if (candLinks.length == 0) {
              const candKOId = id + 1;
              candLinks.push(candKOId);
            }

            item.childs.filter(d => d.cat == 'Candidate' && d.type == 'Failed').map(
              e => {
                id++;
                const objectcandKONode = { 'id': id + 1, 'name': e.name, 'links': [id + 1, candLinks[0]], 'status': 'KO', 'count': 1, 'group': candLinks[0], 'closed': true, 'type': 'table', 'parent': item.idObject };
                const objectcandKOLink = { 'id': id + 1, 'source': candLinks[0], 'target': id + 1, 'color': 'red', 'group': candLinks[0] };
                candLinks.push(id + 1);
                taskNodes.push(objectcandKONode);
                taskLinks.push(objectcandKOLink);
                id++;
                const idCsv = id + 1;
                const objectCandCsvNode = { 'id': id + 1, 'name': 'count_csv', 'links': [id + 1, candLinks[candLinks.length - 1]], 'status': 'KO', 'count': e.count_csv, 'group': candLinks[candLinks.length - 1], 'closed': true, 'type': 'count', 'parent': e.name };
                const objectCandCsvLink = { 'id': id + 1, 'source': candLinks[candLinks.length - 1], 'target': id + 1, 'color': 'red', 'group': candLinks[candLinks.length - 1] };
                taskNodes.push(objectCandCsvNode);
                taskLinks.push(objectCandCsvLink);
                candLinks.push(idCsv);
              }
            );

            id++;
          }
          const candKONode = { 'id': candLinks[0], 'name': 'Candidate', 'links': candLinks, 'status': item.candKO > 0 ? 'KO' : 'OK', 'count': item.candKO + item.candOk, 'group': allLinks[allLinks.length - 1], 'closed': true, 'type': 'cat', 'parent': item.idObject };
          const candKOLink = { 'id': candLinks[0], 'source': allLinks[allLinks.length - 1], 'target': candLinks[0], 'color': 'red', 'group': allLinks[allLinks.length - 1] };
          taskNodes.push(candKONode);
          taskLinks.push(candKOLink);
        }

        id++;
      });
    const task = { 'id': 0, 'name': name, 'links': allLinks, 'status': status, 'count': data.length, 'group': 'noGroup', 'closed': true, 'type': 'taskDetail' };
    taskNodes.push(task);
    //console.log({'links': taskLinks, 'nodes': taskNodes});
    $("#TreeChartObject").css('display', 'block');
    renderTaskObject({ 'links': taskLinks, 'nodes': taskNodes }, app);
    const t = [];
    t.push(taskNodes)
    listTreeObject = [...t];
    $('#treeModalSwitch').change(function () {
      if (this.checked) {
        toggleGroup(taskNodes);
      } else {
        toggleGroup(taskNodes, false);
      }
    });
    console.log(t);
  });
};
export const initTableObject = (id, app) => {
  function getStatus(val) {
    var status;
    switch (val) {
      case 'OK': status = "<span style='color: #00e396; font-size: 1.5em!important;'><i class='fas fa-thumbs-up'></i></span>"; break;
      case 'KO': status = "<span style='color: #ff4560; font-size: 1.5em!important;'><i class='fas fa-thumbs-down'></i></span>"; break;
      case 'TO BE PROCESSED': status = "<span style='color:grey;font-size: 1.5em!important;'><i class='fas fa-calendar-times'></i></span>"; break;
    }
    return status;
  }
  function getStatus1(val) {
    var status;
    switch (val) {
      case 'OK': status = "#00e396"; break;
      case 'KO': status = "#ff4560"; break;
      case 'TO BE PROCESSED': status = "grey"; break;
    }
    return status;
  }
  function getStatus2(val) {
    var status;
    switch (val) {
      case 'Done': status = "#00e396"; break;
      case 'Fail': status = "#ff4560"; break;
    }
    return status;
  }
  $('#table_object_detail').DataTable().destroy();
  $('#table_object_detail').empty();
  $('#table_object_detail').DataTable({
    // destroy: true,
    columnDefs: [],
    responsive: false,
    processing: true,
    pageLength: 5,
    destroy: true,
    lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
    rowCallback(row, data) {
      $('td:eq(0)', row).html('<a class="details_Node_modal_click" data-task="' + data.task + '" data-object="' + data.idObject + '" data-name="' + data.objectName + '"onmouseover="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'black\', \'text-decoration\': \'none\'});" style="cursor:pointer; ">' + data.objectName + '</a>');
      if (data.processed > 0) {
        if (data.archOk > 0 || data.archKO > 0) {
          let color;
          if (data.archKO > 0) {
            color = "KO";
          } else {
            color = "OK";
          }
          $('td:eq(3)', row).html('<a class="details_task_modal_click" data-task="' + data.task + '" data-object="' + data.idObject + '" data-cat="' + data.archive + '"onmouseover="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'white\', \'text-decoration\': \'none\'});" style="cursor:pointer;color: white ">' + data.archive + '</a>&nbsp;<i id="cato_' + data.archive + '" class="fas fa-angle-down" style="font-size: 1.2em!important;"></i>');
          $('td:eq(3)', row).css('background-color', "'" + getStatus1(color) + "'");
        }
        if (data.purgeOk > 0 || data.purgeKO > 0) {
          let color;
          if (data.purgeKO > 0) {
            color = "KO";
          } else {
            color = "OK";
          }
          $('td:eq(4)', row).html('<a class="details_task_modal_click" data-task="' + data.task + '" data-object="' + data.idObject + '" data-cat="' + data.purge + '"onmouseover="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'white\', \'text-decoration\': \'none\'});" style="cursor:pointer;color:white; ">' + data.purge + '</a>&nbsp;<i id="cato_' + data.purge + '" class="fas fa-angle-down" style="font-size: 1.2em!important;"></i>');
          $('td:eq(4)', row).css('background-color', "'" + getStatus1(color) + "'");
        }
        if (data.candOk > 0 || data.candKO > 0) {
          let color;
          if (data.candKO > 0) {
            color = "KO";
          } else {
            color = "OK";
          }
          $('td:eq(2)', row).html('<a class="details_task_modal_click" data-task="' + data.task + '" data-object="' + data.idObject + '" data-cat="' + data.cand + '"onmouseover="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'white\', \'text-decoration\': \'none\'});" style="cursor:pointer;color:white; ">' + data.cand + '</a>&nbsp;<i id="cato_' + data.cand + '" class="fas fa-angle-down" style="font-size: 1.2em!important;"></i>');
          $('td:eq(2)', row).css('background-color', "'" + getStatus1(color) + "'");
        }
      }
      if (data.processed == 0) {
        $('td:eq(5)', row).html(getStatus('TO BE PROCESSED'));
      } else {
        $('td:eq(5)', row).html(getStatus(data.status));
      }

    },
    ajax: {
      url: Shared.API_URL + '/api/data-life-cycle-role/getTableTaskDetails/' + id + '/' + app,
      contentType: 'application/json',
      headers: {
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      },
    },
    columns: [
      { data: 'objectName', title: 'Object name' },
      { data: 'description', title: 'Description' },
      { data: 'cand', title: 'Candidate' },
      { data: 'archive', title: 'Archive' },
      { data: 'purge', title: 'Purge' },
      { data: 'status', title: 'Status' },
      // {
      //   className: 'details-campain',
      //   orderable: false,
      //   data: null,
      //   defaultContent: '',
      //   title: ''
      // },
    ],
    initComplete(settings, json) {
      $('#table_object_detail tbody').on('click', '.details_Node_modal_click', function () {
        $('.pulseNode').removeClass('pulseNode');
        const nodeId = $(this).attr('data-name') + '_0';
        $("[id ='" + nodeId + "']").toggleClass('pulseNode');
        setTimeout(function () { $("[id ='" + nodeId + "']").removeClass('pulseNode'); }, 10000);
      });
      $('#table_object_detail tbody').on('click', '.details_task_modal_click', function () {
        const tr = $(this).closest('tr');
        const row = $('#table_object_detail').DataTable().row(tr);
        const type = $(this).attr('data-cat');
        const object = $(this).attr('data-object');
        const task = $(this).attr('data-task');
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('campainModalshown');
          $('i[id^=\'cato_\']').prop('className', 'fas fa-angle-down');
          // console.log('hide');
        } else {
          // Open this row
          $('.pulseNode').removeClass('pulseNode');
          const nodeId = type + '_' + object;
          console.log(nodeId);
          $("[id ='" + nodeId + "']").toggleClass('pulseNode');
          setTimeout(function () { $("[id ='" + nodeId + "']").removeClass('pulseNode'); }, 10000);
          tr.addClass('campainModalshown');
          $('#cato_' + $(this).attr('data-cat')).prop('className', 'fas fa-angle-up');
          if ($('#types_table_responsive_Modal').length) {
            $('#types_table_responsive_Modal').remove();
          }
          row.child('<div id="types_table_responsive_Modal" class="types_table_responsive_Modal"><table class="table_types_modal" id ="table_types_modal" cellpadding="5" cellspacing="0" border="0" style="width:100%;overflow: scroll"></table></div>').show();
          // console.log('show');
        }
        $('#table_types_modal').DataTable({
          // destroy: true,
          columnDefs: [],
          // responsive: true,
          processing: true,
          pageLength: 5,
          destroy: true,
          lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
          rowCallback(row, data) {
            if (data.count > 0) {
              $('td:eq(0)', row).html('<a class="details_cat_click_modal" data-cat="' + type + '" data-task="' + task + '" data-object="' + object + '" data-name="' + data.name + '"onmouseover="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'white\', \'text-decoration\': \'none\'});" style="cursor:pointer;color:white; ">' + data.name + '</a>');
            }
            $('td:eq(0)', row).css('background-color', "'" + getStatus2(data.name) + "'");
          },
          ajax: {
            url: Shared.API_URL + '/api/data-life-cycle-role/getTableObjectDoneAndFail/' + task + '/' + object + '/' + type,
            contentType: 'application/json',
            headers: {
              Accept: 'Application/json',
              Authorization: 'Bearer ' + getCookieValue('tokendrd')
            },
          },
          columns: [
            { data: 'name', title: 'status' },
            { data: 'count', title: 'count' }
          ],
          // done fail click
          initComplete(settings, json) {

            $('#table_types_modal tbody').on('click', '.details_cat_click_modal', function () {
              const tr = $(this).closest('tr');
              const row = $('#table_types_modal').DataTable().row(tr);
              const name = $(this).attr('data-name');
              const object = $(this).attr('data-object');
              const task = $(this).attr('data-task');
              const type = $(this).attr('data-cat');
              if (row.child.isShown()) {
                // This row is already open - close it
                row.child.hide();
                tr.removeClass('catModalshown');
                //  $('i[id^=\'cat_\']').prop('className', 'fas fa-angle-down');
                // console.log('hide');
              } else {
                // Open this row
                tr.addClass('catModalshown');
                if ($('#cat_table-responsive_modal').length) {
                  $('#cat_table-responsive_modal').remove();
                }
                //  $('#cat_'+ $(this).attr('data-cat')).prop('className', 'fas fa-angle-up');
                row.child('<div id="cat_table-responsive_modal" class="table-responsive"><table class="table_cat_modal" id ="table_cat_modal" cellpadding="5" cellspacing="0" border="0" style="width:100%;overflow: scroll"></table></div>').show();
                // console.log('show');
              }
              $('#table_cat_modal').DataTable({
                // destroy: true,
                columnDefs: [],
                // responsive: true,
                processing: true,
                pageLength: 5,
                destroy: true,
                lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                rowCallback(row, data) {
                  $('td:eq(0)', row).html('<a class="details_table_click_modal" data-cat="' + data.cat + '" data-type="' + name + '" data-task="' + task + '" data-object="' + object + '" data-name="' + data.name + '"  onmouseover="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'white\', \'text-decoration\': \'none\'});" style="cursor:pointer;color:white; ">' + data.name + '</a>');
                  $('td:eq(0)', row).css('background-color', "'" + getStatus2(name) + "'");
                },
                ajax: {
                  url: Shared.API_URL + '/api/data-life-cycle-role/getCatTables/' + task + '/' + object + '/' + name + '/' + type,
                  contentType: 'application/json',
                  headers: {
                    Accept: 'Application/json',
                    Authorization: 'Bearer ' + getCookieValue('tokendrd')
                  },
                },
                columns: [
                  { data: 'name', title: 'Table' }
                ],
                // table detail click
                initComplete(settings, json) {

                  $('#table_cat_modal tbody').on('click', '.details_table_click_modal', function () {
                    const tr = $(this).closest('tr');
                    const row = $('#table_cat_modal').DataTable().row(tr);
                    const name = $(this).attr('data-name');
                    const object = $(this).attr('data-object');
                    const task = $(this).attr('data-task');
                    const type = $(this).attr('data-type');
                    const cat = $(this).attr('data-cat');
                    if (row.child.isShown()) {
                      // This row is already open - close it
                      row.child.hide();
                      tr.removeClass('tableModalshown');
                      //  $('i[id^=\'cat_\']').prop('className', 'fas fa-angle-down');
                      // console.log('hide');
                    } else {
                      $('.pulseNode').removeClass('pulseNode');
                      const nodeId = name + '_' + object;
                      console.log(nodeId);
                      $("[id ='" + nodeId + "']").toggleClass('pulseNode');
                      setTimeout(function () { $("[id ='" + nodeId + "']").removeClass('pulseNode'); }, 10000);
                      // Open this row
                      tr.addClass('tableModalshown');
                      if ($('#detail_table-responsive_modal').length) {
                        $('#detail_table-responsive_modal').remove();
                      }
                      //  $('#cat_'+ $(this).attr('data-cat')).prop('className', 'fas fa-angle-up');
                      row.child('<div id="detail_table-responsive_modal" class="table-responsive"><table class="table_detail_modal" id ="table_detail_modal" cellpadding="5" cellspacing="0" border="0" style="width:100%;overflow: scroll"></table></div>').show();
                      // console.log('show');
                    }
                    $('#table_detail_modal').DataTable({
                      // destroy: true,
                      columnDefs: [],
                      // responsive: true,
                      processing: true,
                      pageLength: 5,
                      destroy: true,
                      lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                      rowCallback(row, data) {
                        // $('td:eq(0)', row).html('<a class="details_table_click" data-name="' + data.name + '" data-task="' + task + '" data-object="' + object + '"  onmouseover="$(this).css({\'color\':\'#257EAD\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'white\', \'text-decoration\': \'none\'});" style="cursor:pointer;color:white; ">' + data.name + '</a>');
                        $('td:eq(0)', row).css('background-color', "'" + getStatus2(type) + "'");
                      },
                      ajax: {
                        url: Shared.API_URL + '/api/data-life-cycle-role/getTableDetails/' + task + '/' + object + '/' + type + '/' + cat + '/' + name,
                        contentType: 'application/json',
                        headers: {
                          Accept: 'Application/json',
                          Authorization: 'Bearer ' + getCookieValue('tokendrd')
                        },
                      },
                      columns: [
                        { data: 'name', title: 'stat' },
                        { data: 'count', title: 'count' }
                      ]
                    });
                  });
                }
                //end table detail click
              });
            });
          }
          //end done fail click
        });
      });
    },

  });
}
export const toggleGroupGlobal = (nodes, open = true) => {
  if (open) {
    // if($('#campainNode_1').css('display', 'block') || $('#campainNode_2').css('display', 'block') || $('#campainNode_3').css('display', 'block') ) {
    //   document.getElementById('campainNode_0').dispatchEvent(new Event('click'));
    // }
    $.each(nodes.filter(e => e.type == 'campain'), function (i, data) {
      if (!data.closed) {
        document.getElementById(data.name).parentNode.dispatchEvent(new Event('click'));
        $.each(nodes.filter(e => e.type == 'status'), function (i, d) {
          d.closed = true;
        });
      }
    });
  } else {
    // if($('#campainNode_1').css('display', 'none') && $('#campainNode_2').css('display', 'none') && $('#campainNode_3').css('display', 'none') ) {
    //   document.getElementById('campainNode_0').dispatchEvent(new Event('click'));
    // }
    $.each(nodes.filter(e => e.type == 'campain'), function (i, data) {
      if (data.closed) {
        document.getElementById(data.name).parentNode.dispatchEvent(new Event('click'));
        $.each(nodes.filter(e => e.type == 'status'), function (i, d) {
          // console.log(d);
          if (d.closed) {
            document.getElementById(d.name).parentNode.dispatchEvent(new Event('click'));
          }
        });
      }
    });
  }
  //console.log(open);
};
