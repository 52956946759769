declare const $: any;
export const resetStyle = (d3, element) => {
  d3.selectAll(element)
    .style("fill", "lightgray");
  return d3;
};

export const clearView = (svg) => svg.selectAll("*").remove();

export const removeLinksRelations = (links, nodeId, linko) => {
  return links.reduce((_links, link) => {
    if (linko.indexOf(link.source.id) == -1) {
      _links.push(link);
    }
    return _links;
  }, []);
};

export const removeNodesRelations = (nodes, nodeId, linko) => {
  return nodes.reduce((_nodes, node) => {
    if (linko.indexOf(node.id) == -1) {
      _nodes.push(node);
    }
    return _nodes;
  }, []);
};

export const getNodesByGroup = (nodes, nodeId) => {
  return nodes.reduce((_nodes, node) => {
    if (node.group == nodeId) {
      _nodes.push(node);
    }
    return _nodes;
  }, []);
};

export const getLinksByGroup = (links, nodeId, childs) => {
  return links.reduce((_links, link) => {
      if(link.source.group==nodeId  && link.target.group==nodeId){
        _links.push(link);
      }
    return _links;
  }, []);
};

export const getMixteLinksByGroup = (links, nodeId) => {
  return links.reduce((_links, link) => {
    if(link.source.type=="node" && link.target.type=="node"){
      if(link.source.group==nodeId  && link.target.group!=nodeId){
        _links.push(link);
      }
      if(link.source.group!=nodeId  && link.target.group==nodeId){
        _links.push(link);
      }
    }

    return _links;
  }, []);
};

export const getVoidLinks = (links) => {
  return links.reduce((_links, link) => {
    if(link.source.type=="node" && link.target.type=="node"){
      if(link.source.group=='void'  && link.target.group=='void'){
        _links.push(link);
      }
    }

    return _links;
  }, []);
};

export const getNodesNoGroup = (nodes) => {
  return nodes.reduce((_nodes, node) => {
    if(node.group=="void"){
      _nodes.push(node);
    }
    return _nodes;
  }, []);
};

export const getExtraNodes = (nodes) => {
  return nodes.reduce((_nodes, node) => {
    if(node.mark){
      _nodes.push(node);
    }
    return _nodes;
  }, []);
};

export const getExtraLinks = (links,nodeId) => {
  return links.reduce((_links, link) => {
    if(link.source.mark && link.target.identif==nodeId){
        _links.push(link);
    }
    return _links;
  }, []);
};
// description toggle
export const toggleDescription = (links) =>{
  links.map(link =>{
    if ( $('#'+link.source.identif).parent().css('display')== "block" && $('#'+link.target.identif).parent().css('display')== "block"){
      $( ".edgelabel[target_node="+link.id + "]" ).css('display', 'block');
      $( ".edgelabel[source_node="+link.id + "]" ).css('display', 'block');
    }else{
      $( ".edgelabel[target_node="+link.id + "]" ).css('display', 'none');
      $( ".edgelabel[source_node="+link.id + "]" ).css('display', 'none');
    }
  });
};
// end description toggle
export const toggleLinks = (links) =>{
links.map(link =>{
    if ( $('#'+link.source.identif).parent().css('display')== "block" && $('#'+link.target.identif).parent().css('display')== "block"){
    $('#link' + link.id).css('display', 'block');
      $( ".edgelabel[target_node="+link.id + "]" ).css('display', 'block');
      $( ".edgelabel[source_node="+link.id + "]" ).css('display', 'block');
  }else{
    $('#link' + link.id).css('display', 'none');
      $( ".edgelabel[target_node="+link.id + "]" ).css('display', 'none');
      $( ".edgelabel[source_node="+link.id + "]" ).css('display', 'none');
  }
});
};
 export const toggleNoLinks =() => {
   $('.nolink').css('display') == 'none' ? $('.nolink').css('display', 'block'):$('.nolink').css('display', 'none');
};
export const ticked = (link, node, edgepaths, edgelabels) => {
  link
    .attr("x1", function (d) {return d.source.x;})
    .attr("y1", function (d) {return d.source.y;})
    .attr("x2", function (d) {return d.target.x;})
    .attr("y2", function (d) {return d.target.y;});

  node.attr("transform",
    function (d) {return "translate(" + d.x + ", " + d.y + ")";});

   edgepaths.attr('d', function (d) {
    return 'M ' + d.source.x + ' ' + d.source.y + ' L ' + d.target.x + ' ' + d.target.y;
  });

  edgelabels.attr('transform', function (d) {
    if (d.target.x < d.source.x) {
      let bbox = this.getBBox();

      let rx = bbox.x + bbox.width / 2;
      let ry = bbox.y + bbox.height / 2;
      return 'rotate(180 ' + rx + ' ' + ry + ')';
    } else {
      return 'rotate(0)';
    }
  });
};
// get the dom element width
export const getDivWidth = (d3, div) => {
    let width = d3.select(div)
    // get the width of div element
        .style('width')
        // take of 'px'
        .slice(0, -2);
    // return as an integer
    return Math.round(Number(width))
};
// get the dom element height
export const getDivHeight = (d3, div) => {
    let width = d3.select(div)
    // get the width of div element
        .style('height')
        // take of 'px'
        .slice(0, -2);
    // return as an integer
    return Math.round(Number(width));
};
export const dragended = (d3, d, simulation) => {

  if (!d3.event.active) simulation.alphaTarget(0);
  d.fx = null;
  d.fy = null;
};
export const dragenstart = (d3, d, simulation) => {
  d3.event.sourceEvent.stopPropagation();
  if (!d3.event.active) simulation.alphaTarget(0.3).restart();
  d.fx = d.x;
  d.fy = d.y;

};
export const initDefinitions =(svg) => {
//red arrow
    svg.append("svg:defs").selectAll("marker")
        .data(["red"])      // Different link/path types can be defined here
        .enter().append("svg:marker")    // This section adds in the arrows
        .attr("id", String)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 70)
        .attr("refY", 0)
        .attr("markerWidth", 15)
        .attr("markerHeight", 15)
        .attr("orient", "auto")
        .attr("fill", "red")
        .append("svg:path")
        .attr("d", "M0,-5L10,0L0,5");
//fin red arrow
//blue arrow
    svg.append("svg:defs").selectAll("marker")
        .data(["blue"])      // Different link/path types can be defined here
        .enter().append("svg:marker")    // This section adds in the arrows
        .attr("id", String)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 70)
        .attr("refY", 0)
        .attr("markerWidth", 15)
        .attr("markerHeight", 15)
        .attr("orient", "auto")
        .attr("fill", "blue")
        .append("svg:path")
        .attr("d", "M0,-5L10,0L0,5");
//fin blue arrow
//green arrow
    svg.append("svg:defs").selectAll("marker")
        .data(["green"])      // Different link/path types can be defined here
        .enter().append("svg:marker")    // This section adds in the arrows
        .attr("id", String)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 70)
        .attr("refY", 0)
        .attr("markerWidth", 15)
        .attr("markerHeight", 15)
        .attr("orient", "auto")
        .attr("fill", "green")
        .append("svg:path")
        .attr("d", "M0,-5L10,0L0,5");
    // mid marker
    svg.append("svg:defs").append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 0)
      .attr("refY", 0)
      .attr("markerWidth", 4)
      .attr("markerHeight", 4)
      .attr("orient", "auto")
      .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5");
};
//fin blue arrow
export const initFilter = (svg) => {
    svg.append("svg:defs").selectAll('filter')
        .data(['shadow'])
        .enter().append('svg:filter')
        .attr("id", String)
        .attr('y', -10)
        .attr('x', -10)
        .attr('height', 40)
        .attr('width', 150)
        .append('feOffset')
        .attr('in', 'SourceAlpha')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'offset2')
        .attr('stdDeviation', 4);
    svg.selectAll('filter')
        .append('feGaussianBlur')
        .attr('in', 'offset2')
        .attr('result', 'blur2')
        .attr('stdDeviation', 3);
    svg.selectAll('filter')
        .append('feMerge')
        .append('feMergeNode')
        .attr('in', 'blur2');
    svg.selectAll('filter')
        .select('feMerge')
        .append('feMergeNode')
        .attr('in', 'SourceGraphic');
};
// gradian color array
export const initGradian = (svg) => {
    svg.append("svg:defs").selectAll('linearGradient')
        .data(['grad1'])
        .enter().append('svg:linearGradient')
        .attr("id", String)
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '0%')
        .attr('y2', '100%')
        .attr('spreadMethod', 'pad')
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', '#04cef3')
        .attr('stop-opacity', 1);
    svg.select('#grad1')
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', '#045485')
        .attr('stop-opacity', 1);
    //second gradient grey
    svg.append("svg:defs").append('svg:linearGradient')
        .attr("id", 'grad2')
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '0%')
        .attr('y2', '100%')
        .attr('spreadMethod', 'pad')
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', '#ceced2')
        .attr('stop-opacity', 1);
    svg.select('#grad2')
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', '#49494c')
        .attr('stop-opacity', 1);
    //gradiant for filtred object by usage risk and compliance
    svg.append("svg:defs").append('svg:linearGradient')
      .attr("id", 'gradFilter')
      .attr('x1', '0%')
      .attr('x2', '0%')
      .attr('y1', '0%')
      .attr('y2', '100%')
      .attr('spreadMethod', 'pad')
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#04cef3')
      .attr('stop-opacity', 1);
    svg.select('#gradFilter')
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#000539')
      .attr('stop-opacity', 1);
    //gradiant isRule
  svg.append("svg:defs").selectAll('linearGradient')
    .data(['isRuleFilter'])
    .enter().append('svg:linearGradient')
    .attr("id", String)
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#6260ce')
    .attr('stop-opacity', 1);
  svg.select('#grad1')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#0728ac')
    .attr('stop-opacity', 1);
  // third gradient grey
    svg.append("svg:defs").append('svg:radialGradient')
      .attr("id", 'grad3')
      .attr('cx', '50%')
      .attr('cy', '50%')
      .attr('r', '75%')
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#7388FF')
      .attr('stop-opacity', 1);
    svg.select('#grad3')
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#000000')
      .attr('stop-opacity', 1);
  // four gradient grey
  svg.append("svg:defs").append('svg:radialGradient')
    .attr("id", 'grad4')
    .attr('cx', '50%')
    .attr('cy', '50%')
    .attr('r', '75%')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#ff5c4f')
    .attr('stop-opacity', 1);
  svg.select('#grad4')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#000000')
    .attr('stop-opacity', 1);
    //pattern
    svg.append("svg:defs").append("svg:pattern")
      .attr("id", 'stripes')
      .attr("width", 8)
      .attr("height", 8)
      .attr("fill", 'red')
      .attr("patternUnits", 'userSpaceOnUse')
      .attr("patternTransform", 'rotate(60)')
  };
// fin gradian color array

export const forceSimulation = (d3, {width, height}) => d3.forceSimulation()
  .force("link",
    d3.forceLink()
      .id(function (d) {return d.id;})
      .distance(70)
      .strength(1))
  .force("charge", d3.forceManyBody().strength(-4000))
  .force("x", d3.forceX(width ).strength(1))
  .force("y", d3.forceY(height ).strength(1))
  .force('collision', d3.forceCollide())
 .force("center", d3.forceCenter(width / 2, height / 2));
export const addLoader = (id = 'vide',id1='f' ,globale = false) => {
  if(globale){
      // element mere
       $($('app-root').last().append('<div id="divGlobal"></div>'));
       $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6)', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
      $("#divGlobal").append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
      $("#loader").html($('#loaderContainer').html());
  }else {
      $($('#' + id).append('<div id="' + id1 + '"></div>'));
      const pos = $('#' + id).position();
      const posWidth = $('#' + id).width() / 2;
      const posHeight = $('#' + id).height() / 2;
      $("#" + id1).css({ 'width': '5%', 'height': '10%','background': 'rgba(255, 255, 255, .7) url("assets/images/loader/Preloader_3.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': pos.x,'left': pos.y});

  }
};
export const addLoaderDelete = (id = 'vide',id1='f' ,globale = false) => {
  if(globale){
    $($('app-root').last().append('<div id="divGlobal"></div>'));
    $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6)', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
    $("#divGlobal").append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
    $("#loader").html($('#loaderContainerSupp').html());
  }else {
    $($('#' + id).append('<div id="' + id1 + '"></div>'));
    const pos = $('#' + id).position();
    const posWidth = $('#' + id).width() / 2;
    const posHeight = $('#' + id).height() / 2;
    $("#" + id1).css({ 'width': '5%', 'height': '10%','background': 'rgba(255, 255, 255, .7) url("assets/images/loader/Preloader_3.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': pos.x,'left': pos.y});

  }
};
export const addLoaderUpdate = (id = 'vide',id1='f' ,globale = false) => {
  if(globale){
    $($('app-root').last().append('<div id="divGlobal"></div>'));
    $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6)', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
    $("#divGlobal").append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
    $("#loader").html($('#loaderContainerUpdate').html());
  }else {
    $($('#' + id).append('<div id="' + id1 + '"></div>'));
    const pos = $('#' + id).position();
    const posWidth = $('#' + id).width() / 2;
    const posHeight = $('#' + id).height() / 2;
    $("#" + id1).css({ 'width': '5%', 'height': '10%','background': 'rgba(255, 255, 255, .7) url("assets/images/loader/Preloader_3.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': pos.x,'left': pos.y});

  }
};
//DRM
export const addLoaderDeleteRp = (id = 'vide',id1='f' ,globale = false) => {
  if(globale){
    $($('app-root').last().append('<div id="divGlobal"></div>'));
    $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6)', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
    $("#divGlobal").append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
    $("#loader").html($('#loaderContainerSuppRp').html());
  }else {
    $($('#' + id).append('<div id="' + id1 + '"></div>'));
    const pos = $('#' + id).position();
    const posWidth = $('#' + id).width() / 2;
    const posHeight = $('#' + id).height() / 2;
    $("#" + id1).css({ 'width': '5%', 'height': '10%','background': 'rgba(255, 255, 255, .7) url("assets/images/loader/Preloader_3.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': pos.x,'left': pos.y});

  }
};
export const addLoaderUpdateRp = (id = 'vide',id1='f' ,globale = false) => {
  if(globale){
    $($('app-root').last().append('<div id="divGlobal"></div>'));
    $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6)', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
    $("#divGlobal").append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
    $("#loader").html($('#loaderContainerUpdateRp').html());
  }else {
    $($('#' + id).append('<div id="' + id1 + '"></div>'));
    const pos = $('#' + id).position();
    const posWidth = $('#' + id).width() / 2;
    const posHeight = $('#' + id).height() / 2;
    $("#" + id1).css({ 'width': '5%', 'height': '10%','background': 'rgba(255, 255, 255, .7) url("assets/images/loader/Preloader_3.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': pos.x,'left': pos.y});

  }
};
export const addLoaderDrmSecurityQCM = (id = 'vide',id1='f' ,globale = false) => {
  if(globale){
    $($('app-root').last().append('<div id="divGlobal"></div>'));
    $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6)', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
    $("#divGlobal").append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
    $("#loader").html($('#loaderWhiteQcm').html());
  }else {
    $($('#' + id).append('<div id="' + id1 + '"></div>'));
    const pos = $('#' + id).position();
    const posWidth = $('#' + id).width() / 2;
    const posHeight = $('#' + id).height() / 2;
    $("#" + id1).css({ 'width': '5%', 'height': '10%','background': 'rgba(255, 255, 255, .7) url("assets/images/loader/Preloader_3.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': pos.x,'left': pos.y});

  }
};
export const removeLoader = (id = 'vide', id1='f', globale = false) => {
    if(globale) {
        $($('#divGlobal').remove());
    }else {
        $($('#' + id1).remove());
    }
};

export const selectNode = (nameObj) => {
    $('#' + nameObj).focus(function() {$( this ).css( 'color', 'red' );});
};
export const addLoaderMxgraph = (id = 'vide',id1='f' ,globale = false) => {
  if(globale){
    $($('app-root').last().append('<div id="divGlobal"></div>'));
    $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6)', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
    $("#divGlobal").append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
    $("#loader").html($('#loaderContainerMxgraph').html());
  }else {
    $($('#' + id).append('<div id="' + id1 + '"></div>'));
    const pos = $('#' + id).position();
    const posWidth = $('#' + id).width() / 2;
    const posHeight = $('#' + id).height() / 2;
    $("#" + id1).css({ 'width': '5%', 'height': '10%','background': 'rgba(255, 255, 255, .7) url("assets/images/loader/Preloader_3.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': pos.x,'left': pos.y});

  }
};
