export class ObjectModelClassif{
  private _dataUsageRisk: number;
  private _sensibilityField: number;
  private _dataAccess: number;
  private _accessLocation: number;
  private _identifiability: number;
  private _quantity: number;
  private _identif: string;
  private _object: string;
  private _table: string;

  constructor(dataUsageRisk: number, sensibilityField: number, dataAccess: number, accessLocation: number, identifiability: number, quantity: number, identif: string, object:string, table:string) {
    this._dataUsageRisk = dataUsageRisk;
    this._sensibilityField = sensibilityField;
    this._dataAccess = dataAccess;
    this._accessLocation = accessLocation;
    this._identifiability = identifiability;
    this._quantity = quantity;
    this._identif = identif;
    this._object = object;
    this._table = table;
  }

  get dataUsageRisk(): number {
    return this._dataUsageRisk;
  }

  set dataUsageRisk(value: number) {
    this._dataUsageRisk = value;
  }

  get sensibilityField(): number {
    return this._sensibilityField;
  }

  set sensibilityField(value: number) {
    this._sensibilityField = value;
  }

  get dataAccess(): number {
    return this._dataAccess;
  }

  set dataAccess(value: number) {
    this._dataAccess = value;
  }

  get accessLocation(): number {
    return this._accessLocation;
  }

  set accessLocation(value: number) {
    this._accessLocation = value;
  }

  get identifiability(): number {
    return this._identifiability;
  }

  set identifiability(value: number) {
    this._identifiability = value;
  }

  get quantity(): number {
    return this._quantity;
  }

  set quantity(value: number) {
    this._quantity = value;
  }

  get identif(): string {
    return this._identif;
  }

  set identif(value: string) {
    this._identif = value;
  }

  get object(): string {
    return this._object;
  }

  set object(value: string) {
    this._object = value;
  }

  get table(): string {
    return this._table;
  }

  set table(value: string) {
    this._table = value;
  }
}
