export class ExtraItem {
  id: string;
  description: string;
  connectors: any[];
  type: string;
  style: {top: number, left: number};
  trigger: string;
  lines: any[];

  constructor(id: string, description: string, connectors: any[], type: string, style: { top: number; left: number }, trigger: string, lines: any[]) {
    this.id = id;
    this.description = description;
    this.connectors = connectors;
    this.type = type;
    this.style = style;
    this.trigger = trigger;
    this.lines = lines;
  }
}
