import * as d3 from 'd3';
import 'd3-selection-multi';
import d3Tip from 'd3-tip';
declare const $: any;
import {
  clearView,
  removeLinksRelations,
  ticked,
  dragended,
  initDefinitions,
  forceSimulation,
  dragenstart,
  initFilter,
  initGradian,
  getDivWidth,
  getDivHeight,
  removeNodesRelations,
  getNodesByGroup, getLinksByGroup, getMixteLinksByGroup, toggleLinks, toggleNoLinks, getExtraLinks, toggleDescription
} from './utils';
import {getCookieValue, Shared, textToVoice} from '../../../shared';

  var nameModel;
  var name;
  var description;
  var keyCand;
  var recordKand;
  var recordList;
  var isRule;
  var filter;
  var objectType;


// document.querySelector('p').addEventListener('remove', function(event) {
//   console.log(event);
// });
//  const onGetDescriptionObjectInDiagramme = (objs:any) => {
//         objs.getDescriptionObjectInDiagramme().subscribe(
//             data => {
//                 name = data.name;
//                 description = data.description;
//                 keyCand = data.keyCand;
//                 recordKand = data.recordKand;
//                 recordList = data.recordList;
//                 isRule = data.isRule;
//                 filter = data.filter;
//             },
//             err => {
//                 console.error(err);
//             },
//             () => {
//                 console.log('name: ' + name);
//                 // $('#fullHeightModalRight').ngAfterViewInit();
//             }
//         );
// };

export const render = (data, idModel: string, objservice: any, securityRiskRead, filterRead, filterAvailable) => {

  const getcolor = function(d) {
    var c;
    switch(d){
      case 1:c='red';break;
      case 2:c='blue';break;
      case 3:c='green';break;
      case 4:c='black';break;
      case 5:c='grey';break;
      case 6:c='grey';break;
      case 7:c=' brown';break;
    }
    return c;
  };
  // const getIcon =function (d) {
  //     var ic;
  //     switch (d) {
  //         case 'Physical Person': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-user-circle\'></i>'; break;
  //         case 'Physical person': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-user-circle\'></i>'; break;
  //         case 'Legal Entity': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-building\'></i>'; break;
  //         case 'Legal entity': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-building\'></i>'; break;
  //         case 'Data Repository': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-warehouse\'></i>'; break;
  //         case 'Data repository': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-warehouse\'></i>'; break;
  //         case 'Technical Data': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-cogs\'></i>'; break;
  //         case 'Technical data': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-cogs\'></i>'; break;
  //         case 'Operational Data': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-database\'></i>'; break;
  //         case 'Operational data': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-database\'></i>'; break;
  //         case 'no_type': ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-times\' style=\'color:#797276\'></i>'; break;
  //     }
  //     return ic;
  // };
  const getIcon = (d) => {
    let ic;
    switch (d) {
      case 'Physical Person': ic = '\uf2bd'; break;
      case 'Physical person': ic = '\uf2bd'; break;
      case 'Legal Entity': ic = '\uf1ad'; break;
      case 'Legal entity': ic = '\uf1ad'; break;
      case 'Data Repository': ic = '\uf494'; break;
      case 'Data repository': ic = '\uf494'; break;
      case 'Technical data': ic = '\uf085'; break;
      case 'Technical Data': ic = '\uf085'; break;
      case 'Operational data': ic = '\uf1c0'; break;
      case 'Operational Data': ic = '\uf1c0'; break;
      default: ic = '\uf00d'; break;
    }
    return ic;
  };
  const getUsageRisk = (d) => {
    let ic;
    switch (d) {
      case 0: ic = 'grey'; break;
      case 1: ic = 'green'; break;
      case 2: ic = 'orange'; break;
      case 3: ic = 'red'; break;
    }
    return ic;
  };
    // const getUsageRisk =function (d) {
    //     var ic;
    //     switch (d) {
    //         case 0: ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-circle\' style=\'color:gray;\'></i>'; break;
    //         case 1: ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-circle\' style=\'color:green;\'></i>'; break;
    //         case 2: ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-circle\' style=\'color:orange;\'></i>'; break;
    //         case 3: ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-circle\' style=\'color:red;\'></i>'; break;
    //     }
    //     return ic;
    // };
    const getUsageRiskLabel =function (d) {
        var ic;
        switch (d) {
            case 0: ic = 'Nothing'; break;
            case 1: ic = 'Moderate'; break;
            case 2: ic = 'High'; break;
            case 3: ic = 'Critical'; break;
        }
        return ic;
    };

    // const getMapping =function (d) {
    //     var ic;
    //     switch (d) {
    //         case true: ic = '<i class=\'fas fa-check-double\' style=\'color:black;\'></i>'; break;
    //         case false: ic = ''; break;
    //     }
    //     return ic;
    // };

    const getCriticity =function (d) {
        var ic;
        switch (d) {
            case 0: ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-exclamation-triangle colGray\' style=\'color:gray;\'></i>'; break;
            case 1: ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-exclamation-triangle colGreen\' style=\'color:green;\'></i>'; break;
            case 2: ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-exclamation-triangle colOrange\' style=\'color:orange;\'></i>'; break;
            case 3: ic = '<i xmlns="http://www.w3.org/1999/xhtml" class=\'fas fa-exclamation-triangle colRed\' style=\'color:red;\'></i>'; break;
        }
        return ic;
    };
    const getCriticityLabel =function (d) {
        var ic;
        switch (d) {
            case 0: ic = 'No criticality available'; break;
            case 1: ic = 'Moderate'; break;
            case 2: ic = 'High'; break;
            case 3: ic = 'Critical'; break;
        }
        return ic;
    };

  const width = 900; //+svg.attr("width");
  const height = 700;//+svg.attr("height");
  // $('#svg').remove();
  // $('#nodeContainer1').append('<svg perserveAspectRatio="xMinYMid" xmlns="http://www.w3.org/2000/svg" id="svg" style="cursor: move"></svg> ');
  const svg = d3.select("#svg").attr("viewBox", `0 0 ${width} ${height}`);
  // const svg = d3.select("#svg");//.attr("viewBox", [0, 0, width, height]);
  clearView(svg); // removes everything!
  const container = svg.append('g').attr('id', 'nodosContainer').style("font", "8px sans-serif")
    .style("text-anchor", "middle");
  svg.attr('width', width);
  svg.attr('height', height);
  //console.log(svg.attr('id'));
  let { links, nodes } = data;
  // svg.call(
  //   d3.zoom()
  //     .scaleExtent([.1, 4])
  //     .on("zoom", function() { container.attr("transform", d3.event.transform); })
  // );
  const Zoom = d3.zoom()
    .scaleExtent([.1, 10])
    //.translateExtent([[-width, -height], [width, height]])
    .on("zoom", function() { container.attr("transform", d3.event.transform); });
  svg.call(Zoom);
  $('#zoomIn_button').on('click',function(){
    svg.transition().call(Zoom.scaleBy, 2);
  });
  $('#zoomOut_button').on('click',function(){
    svg.transition().call(Zoom.scaleBy, 0.5);
  });
  $('#resetSize_button').on('click',function(){
    svg.transition().duration(750).call(
      Zoom.transform,
      d3.zoomIdentity,
      d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
    );
  });
  svg.on("wheel.zoom", null);
 // clearView(svg); // removes everything!
  initDefinitions(container);
  initFilter(container);
  initGradian(container);
  const attractForce = d3.forceManyBody().strength(200).distanceMax(500).distanceMin(200);
  const repelForce = d3.forceManyBody().strength(-140).distanceMax(50).distanceMin(20);
  const simulation = forceSimulation(d3, {width, height});
    // Create Tooltips
    const tip = d3Tip();
    tip
        .attr("class", "d3-tip")
        .html(() => {
            return (
                `<div style="background-color: #00b0e4; border-radius: 8px; border: 2px solid aqua; height: 18%;"><br><span style="color:white; font-weight: bold; float: left;">&nbsp;&nbsp;` + nameModel + `</span>` +
                `<span style="color:darkblue; float: right;cursor: pointer;"><i class="fas fa-times-circle" onclick="$(this).parent().parent().parent().fadeOut();/*animate({'opacity':0},1000);*/"></i>` + "&nbsp;&nbsp;</span><br><br><br></div>" +
                '<div class="scrollTable"> <table class="table table-bordered table-striped table-hover js-basic-example dataTable" style="width:100%;">' +
                    '<tr>' +
                        '<td>Object Name</td>' +
                        '<td>' + name + '</td>' +
                    '</tr>' +
                    '<tr>' +
                    '<td>Object Type</td>' +
                    '<td>' + objectType + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>Description</td>' +
                        '<td>' + description + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>Keys Candidate</td>' +
                        '<td>' + keyCand + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>Table Candidate</td>' +
                        '<td>' + recordKand + '</td>' +
                    '</tr>' +
                    // '<tr>' +
                    //     '<td>List Records</td>' +
                    //     '<td>' + recordList + '</td>' +
                    // '</tr>' +
                    '<tr>' +
                        '<td>Filters</td>' +
                        '<td>' + filter + '</td>' +
                    '</tr>' +
                    '<tr>' +
                        '<td>is Rule ?</td>' +
                        '<td>' + isRule + '</td>' +
                    '</tr>' +
                '</table></div>'// +
                   // '<div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip"></div></div>'
            );
        });
 // d3.select(".d3-tip").call(d3.drag());
    const dragDiv = d3.drag()
        .subject(function() {
            return { x: parseInt(d3.select(this).style("left")),
                y: parseInt(d3.select(this).style("top"))};
        })
        .on("drag", function() {
            console.log(d3.event);
            d3.select(this)
                .style("left", d3.event.x + "px")
                .style("top", d3.event.y + "px");
        });

  d3.select(".d3-tip").call(dragDiv);

  // const attractForce = d3.forceManyBody().strength(200).distanceMax(400).distanceMin(60);
  // const repelForce = d3.forceManyBody().strength(-140).distanceMax(50).distanceMin(10);
  // const simulation = d3.forceSimulation(data.nodes).alphaDecay(0.03).force("attractForce",attractForce).force("repelForce",repelForce);
  // svg.call(
  //   d3.zoom()
  //     .scaleExtent([.1, 4])
  //     .on('zoom', function() { svg.attr('transform', d3.event.transform); }));
  // group lines
  const linkg = container.selectAll(".linko")
    .data(links.filter(function(d){return d.type == "groupement"}))
    .enter()
    .append("line")
    .attr('stroke-width', '.4')
    .attr('class' ,'linko')
    .attr('style', 'opacity:.5;')
    .attr('id', (d) => d.id + d.groupLink)
    .attr('stroke' , (d) => getcolor(d.color));
  // extra lines
  const extraLines = container.selectAll(".extraLink")
    .data(links.filter(function(d){return d.type == "extra"}))
    .enter()
    .append("line")
    .attr('stroke-width', '.4')
    .attr('class' ,'extraLink')
    .attr('style', 'opacity:.5;')
    .attr('id', (d) => 'extrat'+d.id )
    .attr('stroke' , (d) => getcolor(d.color));
  // group-group lines
  const grpLink = container.selectAll(".linky")
    .data(links.filter(function(d){return d.type == "group_group"}))
    .enter()
    .append("line")
    .attr('stroke-width', '.7')
    .attr('class' ,'linky')
    .attr('style', 'opacity:.6;')
    .attr('id', (d) => 'grplink' + d.id)
    .attr('data-group', d => d.groupLink)
    .attr('stroke' , (d) => getcolor(d.color));
  // no-group lines
  const noGrpLink = container.selectAll(".nolink")
    .data(links.filter(function(d){return d.type == "no_group"}))
    .enter()
    .append("line")
    .attr('stroke-width', '.7')
    .attr('class' ,'nolink')
    .attr('style', 'opacity:.6;')
    .attr('id', (d) => 'nolink' + d.id)
    .attr('stroke' , (d) => getcolor(d.color));
  // no-group lines
  const noGrpJoinLink = container.selectAll(".nojoinlink")
    .data(links.filter(function(d){return d.type == "no_group_link"}))
    .enter()
    .append("line")
    .attr('stroke-width', '.7')
    .attr('class' ,'nojoinlink')
    .attr('style', 'opacity:.6;')
    .attr('id', (d) => 'nojoinlink' + d.id)
    .attr('stroke' , (d) => getcolor(d.color));
  // nodes lines
  const link = container.selectAll(".protip")
    .data(links.filter(function(d){return d.type == "nr"}))
    .enter()
    .append("line")
    .attr("class", "protip")
    .attr('data-pt-title', (d) => { return  new String(d.descriptionJoin).length ==0 ? 'Aucune description disponible' :  d.descriptionJoin;})
    .attr('data-pt-animate', 'bounceIn')
    .attr('style', 'cursor:pointer;')
    .attr('stroke-width', '.4')
    .attr('data-pt-icon', 'info-circled')
    .attr('id', (d) => 'link'+d.id)
    .attr('data-pt-scheme', (d) => getcolor(d.color))
    .attr('data-pt-arrow', 'false')
    .attr('data-pt-gravity', 'top-right-corner')
    .attr('marker-end', (d) => 'url(#' + getcolor(d.color) + ')')
   // .attr('marker-mid', (d) => 'url(#arrow)')
    .attr('stroke' , (d) => getcolor(d.color));
  container.selectAll(".nolink").style("stroke-dasharray", "2,4");
  container.selectAll(".extraLink").style("stroke-dasharray", "4,20");
  container.selectAll(".nojoinlink").style("stroke-dasharray", "20,10");
  container.selectAll(".linky").style("stroke-dasharray", "1,1");
  container.selectAll(".linko").style("stroke-dasharray", "4,4");
//class='protip' data-pt-title='$description' data-pt-animate='bounceIn' data-pt-scheme = 'red' data-pt-position = 'top'
//   link.append("title")
//     .text(function (d) {return d.type;});

  const edgepaths = container.selectAll(".edgepath")
    .data(links)
    .enter()
    .append('path')
    .attr('class', 'edgepath')
    .attr('fill-opacity', 0)
    .attr('stroke-opacity', 0)
    .attr( 'id', function (d, i) {return 'edgepath' + i;})
    .style("pointer-events", "none");

  const edgelabels = container.selectAll(".edgelabel")
    .data(links)
    .enter()
    .append('text')
    .style('pointer-events', 'none')
    .attr('class', 'edgelabel')
    .attr('id', function (d, i) {return 'edgelabel' + i})
    .attr('target_node' , (d) =>  d.target)
    .attr('source_node' , (d) =>  d.source)
    .attr('font-size', 5)
    .attr('fill', '#000000');

  edgelabels.append('textPath')
    .attr('xlink:href', function (d, i) {return '#edgepath' + i})
    .style("text-anchor", "middle")
    .style("pointer-events", "none")
    .attr("startOffset", "50%")
    .text((d) => d.descriptionJoin);

  const node = container.selectAll(".node")
    .data(nodes)
   // .data(nodes.filter(function(d){return d.type == "node"}))
    .enter()
    .append("g")
    .attr("class", 'node')
    .attr('id',(d)=>{if(d.group=="nojoin_1234"){return "nojoinGroupkl"+d.id}})
    .style('display','block')
   // .call(simulation.drag);
    .call(d3.drag()
      .on("start", function dragenstart(d){
        d3.event.sourceEvent.stopPropagation();
        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
          // d3.select('.d3-tip')
          //     .style("left", d.x  + "px")
          //     .style("right", d.x + "px")
          //     .style("top", d.y   + "px");
    })
      .on("drag", function dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;
          // d3.select('.d3-tip')
          //     .style("left", d.fx + 20 + "px")
          //     .style("right", d.fx + "px")
          //     .style("top", d.fy + getDivHeight(d3, '.d3-tip') + 100  + "px");
       //   console.log("d.fx,d.width : " + d.fx+ "," + getDivHeight(d3, '.d3-tip'));
      })
      .on("end", function dragended(d){
          if (!d3.event.active) simulation.alphaTarget(0);
          d.fx = null;
          d.fy = null;
         // alert('name ' + $('.d3-tip').find('table').rows[0].cells[1].html());
         //  d3.select('.d3-tip')
         //      .style("left", d.fx + 20 + "px")
         //      .style("right", d.fx + "px")
         //      .style("top", d.fy + getDivHeight(d3, '.d3-tip') + 100  + "px");
      })
    );

  const colore = d3.scaleOrdinal(d3.schemeCategory10);
    // node.append('svg:foreignObject')
    //     //.attr('filter','url(#shadow)')
    //     .attr('class', 'icon-object-type');
//  console.log('nodeChart ', node);
  const hidedText = svg.append('g')
    .attr('id','hidedHelper')
    .style('display','none')
    .selectAll('.hiddenTextos')
    .data(nodes).enter()
    .append("text")
    .attr('class','hiddenTextos')
    .text((d) => {if(d.type =='groupNode'){ return d.name == "" ? "no group": d.name;} else {return d.name;}})
    .attr('text-anchor' ,  'middle')
    .each(function (d) {
      d.textLength = this.getBBox().width;
      d.textHeight = this.getBBox().height;//this.getComputedTextLength();
    });
  // console.log('nodos' , node);
  node.each(function(d,i){
 // console.log(d.textLength);
    // i is index on everything
    const self = d3.select(this);
    switch(d.type) {
      case "groupNode":
        self.append("rect").attr('width',d => d.textLength);
          // .attr("x", function(d) {if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().x - paddingLeftRight;} else {return this.parentNode.childNodes[3].getBBox().x - paddingLeftRight;}})
          // .attr("y", function(d) { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().y - paddingTopBottom - 6;} else { return this.parentNode.childNodes[3].getBBox().y - paddingTopBottom - 6;} })
          // .attr("rx", 10)
          // .attr("ry", 10)
          // .attr("width", function(d) { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().width + paddingLeftRight ;}else { return this.parentNode.childNodes[3].getBBox().width + paddingLeftRight ;}})
          // .attr("height", function(d) { if(d.type == 'node'){return this.parentNode.childNodes[5].getBBox().height  + paddingTopBottom * 2;}else{return this.parentNode.childNodes[3].getBBox().height  + paddingTopBottom * 2; } })
          // .attr('fill',(d) => { if(d.type == 'node') { return 'url(#grad1)'; } else { return 'url(#grad3)';}})
          // .attr('filter','url(#shadow)')
          // .attr('id' , (d) => d.identif)
          // .style('text-align','middle');
        self.append("svg:foreignObject").attr('class','icon-group');
        break;
      case "node":
        self.append("rect")
          // .attr("x", function(d) {if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().x - paddingLeftRight;} else {return this.parentNode.childNodes[3].getBBox().x - paddingLeftRight;}})
          // .attr("y", function(d) { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().y - paddingTopBottom - 6;} else { return this.parentNode.childNodes[3].getBBox().y - paddingTopBottom - 6;} })
          // .attr("rx", 10)
          // .attr("ry", 10)
          // .attr("width", function(d) { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().width + paddingLeftRight ;}else { return this.parentNode.childNodes[3].getBBox().width + paddingLeftRight ;}})
          // .attr("height", function(d) { if(d.type == 'node'){return this.parentNode.childNodes[5].getBBox().height  + paddingTopBottom * 2;}else{return this.parentNode.childNodes[3].getBBox().height  + paddingTopBottom * 2; } })
          // .attr('fill',(d) => { if(d.type == 'node') { return 'url(#grad1)'; } else { return 'url(#grad3)';}})
          // .attr('filter','url(#shadow)')
          // .attr('id' , (d) => d.identif)
          // .style('text-align','middle')
          .attr('class',(d) => d.isRule? 'ruleNode': 'rectoNode');
        // self.append('svg:foreignObject')
        // .attr('class', 'icon-object-type');
          self.append('text')
            .attr('class', 'icon-type-text');
        self.append('text')
          .attr('class', 'icon-usage-risk');
        self.append('text')
          .attr('class', 'icon-security-risk');
       // self.append('svg:foreignObject')
        // .attr('filter','url(#shadow)')
         // .attr('class', 'icon-usage-risk');
       // self.append('svg:foreignObject')
        // .attr('filter','url(#shadow)')
        //  .attr('class', 'icon-security-risk');
        break;
      default:
        break;
    }
  });
  // node.append(function(d) {
  //   return document.createElement(d.forme);
  // });
  // node.append((d) => d.shapes)
  //   .attr("x", 0)
  //   .attr("y", 1)
  //   .attr("r", 10)
  //   .attr('fill', (d) => colore(d.id)).attr('filter','url(#shadow)');
  // node.append( (d) => d.shapes )
  //   .attr("x", 0)
  //   .attr("y", 1)
  //   .attr("r", 10)
  //   .attr("width", 30)
  //   .attr("height", 10).attr('fill', (d) => colore(d.id)).attr('filter','url(#shadow)');
  // container.selectAll('rect');
    // .on('click',  tip.show)
    // .on('mouseover', onGetDescriptionObjectInDiagramme(objservice));
    // .on('mouseover', function () {
    //   // d3.selectAll('rect')
    //   //   .attr("stroke", "yellow");
    //
    //   d3.select(this)
    //     .attr("stroke", "black");
    // })
    // .on('mouseout', function () {
    //   // d3.selectAll('rect')
    //   //   .attr("stroke", "blue");
    //
    //   d3.select(this)
    //     .attr("stroke", "lightblue");
    // });
  // svg.selectAll('text').on('click',function(){
  //   d3.select(this).select("text").duration(750)
  //       .attr("x", 12)
  //       .style("stroke", "none")
  //       .style("fill", "black")
  //       .style("stroke", "none")
  //       .style("font", "10px sans-serif");
    // alert( d3.select(this).select("text").text());
  // });
  // appand trash icons to all nodes
  node.append('svg:foreignObject')
    .attr('class', 'delete-icon')
    //.attr('overflow', 'visible')
   // .append('<i class="fa fa-trash"></i>');
    .html('<i class="fa fa-trash"></i>');

  // container.selectAll('.delete-icon')
  //   .on('click', function ({id}) {
  //    // links = removeLinksRelations(links, id);
  //     nodes = nodes.filter(node => id !== node.id);
  //     render({ links, nodes }, idModel, objservice);
  //
  //     //todo dispatch custom event "remove"
  //     d3.select(this)
  //       .on('remove', function(d, i) {
  //         var evt = d3.event;
  //         //  console.log(evt);
  //       });
  //
  //     d3.select('p')
  //       .dispatch('remove', {detail: {myCustomObject:'bla bla bla'} });
  //   });

  const nodeText = node.append("text")
    .text((d) => {if(d.type =='groupNode'){ return d.name == "" ? "no group": d.name;} else {return d.name;}})
    .attr('class', (d) => {if(d.type =='groupNode') { return 'grpText'; } else{ return 'nodeText'; } })
    .attr('text-anchor' ,  'middle');
  // container.selectAll('.grpText')
  //   .attr("font-size", (d) => { return '5px'; });
  container.selectAll(".grpText").on('mouseover',function (d,index) {
    simulation.stop();
    $('#' +d.identif).next().css({opacity: 0})
      .animate({
        opacity: 1
      }, 200, function() {
        // Animation complete.
      });
    const nodeChilds = getNodesByGroup(nodes , d.identif);
    $.each( nodeChilds, function( i, val ) {
      //  console.log('node');console.log(val);
      $('#' + val.identif).attr('fill' , 'url(#grad4)');
    });
    const linkChilds = getLinksByGroup(links , d.identif , nodeChilds);
    const mixteLinks = getMixteLinksByGroup(links, d.identif);
   // console.log(mixteLinks);
    $.each( linkChilds, function( i, val ) {
      $('#link' + val.id).attr('stroke','purple');
      $('#link' + val.id).attr('stroke-width','.8');
    });
    $.each( mixteLinks, function( i, val ) {
      $('#link' + val.id).attr('stroke','lightgreen');
      $('#link' + val.id).attr('stroke-width','.8');
    });
   });
  container.selectAll(".grpText").on('mouseout',function (d,index) {
    simulation.restart();
    const nodeChilds = getNodesByGroup(nodes , d.identif);
    const linkChilds = getLinksByGroup(links , d.identif , nodeChilds);
    const mixteLinks = getMixteLinksByGroup(links, d.identif);
    $.each( nodeChilds, function( i, val ) {
      //  console.log('node');console.log(val);
      $('#' + val.identif).attr('fill' , 'url(#grad1)');
    });
    $.each( linkChilds, function( i, val ) {
      $('#link' + val.id).attr('stroke',(d) => getcolor(val.color));
      $('#link' + val.id).attr('stroke-width','.4');
    });
    $.each( mixteLinks, function( i, val ) {
      $('#link' + val.id).attr('stroke',(d) => getcolor(val.color));
      $('#link' + val.id).attr('stroke-width','.4');
    });
  });
  // group click handler
  container.selectAll(".grpText")
  // .on('click',  tip.show)
    .on('click',function(d, index, element) {
    //  console.log(links);
    //   const _nodes = nodes;
    //links = removeLinksRelations(links, d.id, d.links);
    //  // nodes = nodes.filter( (node) => node.id !== d.links.source.id || node.id === d.id);
    //   nodes = removeNodesRelations(nodes, d.id, d.links);
    //   nodes.push(_nodes[d.id]);
    //   console.log(links);
    //   console.log(nodes);
    //   render({ links, nodes }, idModel, objservice);
    //   console.log(d.name);
     // $(this).sibling("rect").css('fill','grey');
      if(d.identif=="nojoin_1234"){
        const nodeChilds = getNodesByGroup(nodes , d.identif);
        if(d.closed) {
          $.each(nodeChilds, function (i, val) {
            //  console.log('node');console.log(val);
            $('#' + val.identif).parent().css('display', 'none');
          });
          toggleNoLinks();
        }else{
          $.each(nodeChilds, function (i, val) {
            //  console.log('node');console.log(val);
            $('#' + val.identif).parent().css('display', 'block');
          });
          toggleNoLinks();
        }
        d.closed = !d.closed;
      }else{
        const nodeChilds = getNodesByGroup(nodes , d.identif);
        //  console.log(nodeChilds);
        const linkChilds = getLinksByGroup(links , d.identif , nodeChilds);
        const mixteLinks = getMixteLinksByGroup(links, d.identif);
        if(d.closed){
          $.each( d.links, function( i, val ) {
            // console.log('link');console.log( val + d.identif);
            if($('#' + val + d.identif).length > 0){
              $('#' + + val + d.identif).css('display','none');
            }
          });
          //    console.log(linkChilds);
          $.each( nodeChilds, function( i, val ) {
            //  console.log('node');console.log(val);
            $('#' + val.identif).parent().css('display','none');
          });
          $.each( linkChilds, function( i, val ) {
              // console.log('close link');console.log(val);
            $('#link' + val.id).css('display','none');
          //  $('#extrat'+val.id).css('display','none');
          });
          $.each( getExtraLinks(data.links,d.identif), function( i, val ) {
            //  console.log('node');console.log(val);
            $('#extrat'+val.id).css('display','none');
          });
          $( "line[data-group='"+d.identif+"']" ).css('display', 'none');
        }else{
          $.each( d.links, function( i, val ) {
            // console.log('link');console.log( val + d.identif);
            if($('#' + val + d.identif).length > 0){
              $('#' + + val + d.identif).css('display','block');
            }
          });
          //    console.log(linkChilds);
          $.each( nodeChilds, function( i, val ) {
            //  console.log('node');console.log(val);
            $('#' + val.identif).parent().css('display','block');
          });
          $.each( linkChilds, function( i, val ) {
         //    console.log('open link');console.log(val);
            $('#link' + val.id).css('display','block');
           // $('#' + val.id + 'extra').css('display','block');
          });
          $.each( getExtraLinks(data.links,d.identif), function( i, val ) {
            //  console.log('node');console.log(val);
            $('#extrat'+val.id).css('display','block');
          });
          $( "line[data-group='"+d.identif+"']" ).css('display', 'block');
        }
        toggleLinks(mixteLinks);
        toggleDescription(data.links);
        d.closed = !d.closed;
      }

    });
  // element click handler
  container.selectAll(".nodeText")
      // .on('click',  tip.show)
       .on('click',function(d, index, element) {
            simulation.stop();

           $('#first_tab').click();
           $('#secure_pop').hide();
           $('#map_pop').hide();
           $('#risk_pop').hide();
           $('#def_animation_1').html("<div class=\"text-center\"><div class=\"spinner-grow text-info\" role=\"status\">\n" +
             "  <p class=\"sr-only\">Loading...</p>\n" +
             "</div></div>");
           $('#nodeModal').modal();

           objservice.getDescriptionObjectInDiagramme(idModel, d.identif).subscribe(
             data => {
               nameModel = data.nameModel;
               objectType = data.objectType;
               name = data.name;
               description = data.description;
               keyCand = data.keyCand;
               recordKand = data.recordKand;
               // recordList = data.recordList;
               isRule = data.isRule;
               filter = data.filter;
             },
             err => {
               console.error(err);
             },
             () => {
               //  $('.d3-tip').style('left')
               //    var yy = this.getBBox().y;
               //    var hh = $('.d3-tip').attr('height') + 200;
               //    d3.select('.d3-tip')
               //         .style("top", yy - hh  + "px");
               //       $('.d3-tip').fadeIn();
               //       tip.show(d, element[index]);
               //       $('#selectedPopup').html(name);
               // modal init
               var htm = '<div class="scrollTable"> <table class="table table-bordered table-striped table-hover js-basic-example dataTable" style="width:100%;">' +
                 '<tr>' +
                 '<td>Object Name</td>' +
                 '<td>' + name + '</td>' +
                 '</tr>' +
                 '<tr>' +
                 '<td>Object Type</td>' +
                 '<td>' + objectType + '</td>' +
                 '</tr>' +
                 '<tr>' +
                 '<td>Description</td>' +
                 '<td>' + description + '</td>' +
                 '</tr>' +
                 '<tr>' +
                 '<td>Keys Candidate</td>' +
                 '<td>' + keyCand + '</td>' +
                 '</tr>' +
                 '<tr>' +
                 '<td>Table Candidate</td>' +
                 '<td>' + recordKand + '</td>' +
                 '</tr>' +
                 // '<tr>' +
                 //     '<td>List Records</td>' +
                 //     '<td>' + recordList + '</td>' +
                 // '</tr>' +
                 // '<tr>' +
                 // '<td>Filters</td>' +
                 // '<td>' + filter + '</td>' +
                 // '</tr>' +
                 '<tr>' +
                 '<td>is Rule ?</td>' +
                 '<td>' + getIsRule(isRule) + '</td>' +
                 '</tr>' +
                 '</table></div>'; // +
               function getIsRule(val){
                 if(val){
                   return '<span style="color:#4481eb">'+val+'</span>';
                 }else{
                   return val;
                 }
               }
               //  '<div class="leaflet-popup-tip-container"><div class="leaflet-popup-tip"></div></div>';
               $('#rule_pop').hide();
               $('#formModal1').html(nameModel);
               $('#def_animation_1').html(htm);
               // $('#first_tab').click();
               // $('#secure_pop').hide();
               // $('#map_pop').hide();
               // $('#nodeModal').modal();
               object_join(idModel , d.identif,filterRead);
               object_rules(d.identif, filterRead, filterAvailable);
               object_RecordField(d.identif);
               object_map(idModel , d.identif);
               security_risk_object(idModel , d.identif);
               usage_security_object( d.identif);
             }
           );
         }
       );
  //  .call(wrap, 30);
  // const height1 = parseInt(container.select('text').node().getBoundingClientRect().height);
  // const width1 = parseInt(container.select('text').node().getBoundingClientRect().width);
  // container.selectAll("text").each(function(d, i) {
  //   texts[i].bb = d.getBBox(); // get bounding box of text field and store it in texts array
  // });
  // container.selectAll('rect')
  //   .attr("width", function(d) {return this.parentNode.getBBox().width;});
  const paddingLeftRight = 18; // adjust the padding values depending on font and font size
  const paddingTopBottom = 5;
    // node.append('svg:foreignObject')
    //     // .attr('filter','url(#shadow)')
    //     .attr('class', 'icon-usage-risk');
    // node.append('svg:foreignObject')
    //     // .attr('filter','url(#shadow)')
    //     .attr('class', 'icon-security-risk');
 // container.select('text').attr('transform', 'translate(0, ' + (-height1 / 2) + ')');
 //  nodeText.selectAll("tspan.text")
 //    .data((d) =>  d.name/*.split(" ")*/)
 //    .enter()
 //    .append("tspan")
 //    .attr("class", "text")
 //    .text(d => d)
 //    .attr("x", -10)
 //    .attr("dx", 5)
 //    .attr("y", (d, i, nodes) => `${i - nodes.length / 2 + 0.8}em`)
 //    .attr("dy", 10);
  // const format = d3.format(",d");
  // nodeText.attr("clip-path", d => d.clipUid)
  //   .selectAll("tspan")
  //   .enter()
  //   .data(d => d.name.split(/(?=[A-Z][^A-Z])/g).concat(format(d.value)))
  //   .join("tspan")
  //   .attr("x", 3)
  //   .attr("y", function(d, i, nodes) {return  `${(i === nodes.length - 1) * 0.3 + 1.1 + i * 0.9}em`;})
  //   .attr("fill-opacity", (d, i, nodes) => i === nodes.length - 1 ? 0.7 : null)
  //   .text(d => d);
  container.selectAll('.icon-type-text')
    .text(d => getIcon(d.objectType))
    .attr("x",function(d) { return this.parentNode.childNodes[2].getBBox().x - paddingLeftRight / 2  - 4; })
    .attr("y",function(d) { return this.parentNode.childNodes[2].getBBox().y  - paddingTopBottom * 3.5; });
  // icon group
  container.selectAll('.icon-group').html((d) => '<span  xmlns="http://www.w3.org/1999/xhtml" class="notification-counter">'+ (d.links.length - 1) + '</span>').attr("x",function(d) { return this.parentNode.childNodes[2].getBBox().x - paddingLeftRight / 2  - 4; })
    .attr("y",function(d) { return this.parentNode.childNodes[2].getBBox().y  - paddingTopBottom * 5.4; }).attr('width','50').attr('height','50');
  node.append("title")
      // .text(function (d) {return 'Object type: ' + d.objectType + ', Mapping: ' + d.mappedObject + ', Criticity: ' + d.criticity; });
    .text(function (d) {return 'Object type: ' + d.objectType + ', Usage Risk: ' + getUsageRiskLabel(d.usageRisk) + ', Security Risk: ' + getCriticityLabel(d.criticity); });
  // rect

  container.selectAll("rect")
    .attr("x", function(d) {if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().x - paddingLeftRight;} else {return this.parentNode.childNodes[3].getBBox().x - paddingLeftRight;}})
    .attr("y", function(d) { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().y - paddingTopBottom - 6;} else { return this.parentNode.childNodes[3].getBBox().y - paddingTopBottom - 6;} })
    .attr("rx", 10)
    .attr("ry", 10)
    .attr("width", function(d) { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().width + paddingLeftRight ;}else { return this.parentNode.childNodes[3].getBBox().width + paddingLeftRight ;}})
    .attr("height", function(d) { if(d.type == 'node'){return this.parentNode.childNodes[5].getBBox().height  + paddingTopBottom * 2;}else{return this.parentNode.childNodes[3].getBBox().height  + paddingTopBottom * 2; } })
    .attr('fill',(d) => { if(d.type == 'node') { if(d.isRule){return 'url(#isRuleFilter)';}else{return 'url(#grad1)';}  } else { return 'url(#grad3)';}})
    .attr('filter','url(#shadow)')
    .attr('id' , (d) => d.identif)
    .style('text-align','middle');
  // groupe
  // container.selectAll('text')
  //   .call(wrap, (d) => d.r, (d) => d.type);
  // container.selectAll("circle")
  //    .attr("r", function(d) { const rs = (this.parentNode.childNodes[3].getBBox().width + paddingTopBottom) / 2; d.r = rs; return rs  ; })
  //    .attr('fill','url(#grad3)')
  //    .attr('filter','url(#shadow)');
    // end groupe
  container.selectAll('.nodeText,.grpText')
      .attr("x", function(d)  { if(d.type == 'node') {return this.parentNode.childNodes[3].getBBox().x - paddingLeftRight / 2 ;}else{ return this.parentNode.childNodes[2].getBBox().x - paddingLeftRight / 2 ;} })
      .attr('fill', '#f8f9fa')
      .style('cursor', 'pointer')
      .attr("y", function(d)  { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().y + 2;}else {return this.parentNode.childNodes[3].getBBox().y + 2;} });

    container.selectAll('.icon-security-risk')
      .text( '\uf071')
      .attr('fill', d => getUsageRisk(d.criticity))
      .style("display",  "none")
     // .html((d) => getCriticity(d.criticity))
        .attr("x",function(d) { return this.parentNode.childNodes[2].getBBox().x + 1; })
        .attr("y",function(d) { return this.parentNode.childNodes[2].getBBox().y  - paddingTopBottom * 3.8; });
        // .attr("x",function(d) { return this.parentNode.childNodes[3].getBBox().x - 14; })
        // .attr("y",function(d) { return this.parentNode.childNodes[2].getBBox().y  - paddingTopBottom * 2.6; });
    container.selectAll('.icon-usage-risk')
      .text('\uf111')
      .attr('fill', d => getUsageRisk(d.usageRisk))
      .style("display",  "none")
      //.html((d) => getUsageRisk(d.usageRisk))//, (c) => setClassByCriticity(c.criticity)
        //.attr('class', function(c) { return c; })
        .attr("x",function(d) { return this.parentNode.childNodes[3].getBBox().x - paddingLeftRight - 6 ; })
        .attr("y",function(d) { return this.parentNode.childNodes[3].getBBox().y-paddingTopBottom*3.8; });
    container.selectAll('.edgelabel').attr('fill', 'black');
  simulation
    .nodes(nodes)
    .on("tick", () => { ticked(link, node, edgepaths, edgelabels);ticked(linkg, node, edgepaths, edgelabels);ticked(grpLink, node, edgepaths, edgelabels);ticked(noGrpLink, node, edgepaths, edgelabels);ticked(noGrpJoinLink, node, edgepaths, edgelabels);ticked(extraLines, node, edgepaths, edgelabels);   });

  simulation.force("link")
    .links(links);
//wrap
  function wrap(text , rayon, type) {
    if(type=='groupNode'){
      text.each(function() {
        let text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.1, // ems
          x = text.attr("x"),
          y = text.attr("y"),
          dy = 1.1,
          tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");
        while (word = words.pop()) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > rayon) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
          }
        }
      });
    }
  }
  // $('#chart_info_tree').unbind().on('click',function(){
  //   textToVoice('the blue rectangles are the objects of this model if you click on one of them it will display you all the information about it and the violet ones are collapsible groups of objects click on them and they toggle showing their childrens');
  // });
};
// pop up methods
function object_join(model,id, filterRead){
  $('#join_table').DataTable().destroy();
  $('#join_table').empty();
  $('#join_table' ).DataTable({
    "columnDefs": [
      {"visible": false, "targets": 7},
// { "visible": false, "targets": 8 }
    ],
    responsive: true,
    processing: true,
    "pageLength": 5,
    "lengthMenu": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
    ajax: {
      "url": Shared.API_URL + "/api/object-model-join/PopUpJoins/" +  model + "/" + id,
      "type": "GET",
      "contentType": "application/json",
      headers: {
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
// 'Accept': 'Application/json'
      },
    },
    "columns": [
        {"data": "objectModelLeft", title: this.lang ? 'Object Model left' : 'Modèle d\'objet de gauche'},
        {"data": "recorLeft", title: this.lang ? 'Tables' : 'Tables'},
        {"data": "fieldLeft", title: this.lang ? 'Fields' : 'Champs'},
        {"data": "filterLeft", title: this.lang ? 'Left Filter' : 'Filtre gauche'},
        {"data": "linkLeftJoin", title: this.lang ? 'Link Left Join' : 'Lien Joindre Gauche'},
        {"data": "objectModelRight", title: this.lang ? 'Object Model right' : 'Modèle d\'objet de droite'},
        {"data": "recorRight", title: this.lang ? 'Tables' : 'Tables'},
        {"data": "fieldRight", title: this.lang ? 'Fields' : 'Champs'},
        {"data": "filterRight", title: this.lang ? 'Right Filter' : 'Filtre droit'},
        {"data": "joinType", title: ''}

    ],
    order: [[9, 'asc']],
// rowGroup: {
// dataSrc: 'joinType'
// },
    "drawCallback": function (settings) {
      var api = this.api();
      var rows = api.rows({page: 'current'}).nodes();
      var last = null;
      if(!filterRead){
        api.column(3).visible(false);
        api.column(8).visible(false);
      }
      api.column(9, {page: 'current'}).data().each(function (group, i) {
        if (last !== group) {
          switch (group) {
            case 1:
              $(rows).eq(i).before(
                '<tr><td class="group_red" colspan="7">Key – Key </td></tr>'
              );
              break;
            case 2:
              $(rows).eq(i).before(
                '<tr><td class="group_blue" colspan="7">Key – Field </td></tr>'
              );
              break;
            case 3:
              $(rows).eq(i).before(
                '<tr><td class="group_green" colspan="7">Field – Field </td></tr>'
              );
              break;
          }


          last = group;
        }
      });
    },
  });
}
function object_map(model,id){
  if(window.localStorage.getItem('mapping_val') == "" || window.localStorage.getItem('mapping_val') == "---"){
    // console.log('test ' +window.localStorage.getItem('mapping_val'));
    return false;
  }
  const map = window.localStorage.getItem('mapping_val');
  $('#map_table').DataTable().destroy();
  $('#map_table').empty();
  const tableMap =$('#map_table').DataTable({
    processing: true,
    responsive: true,
    keys: {
      columns: ':first-child'
    },
    columnDefs: [
      {visible: false, targets: [6]}
// { "visible": false, "targets": 8 }
    ],
    "rowCallback": function (row, data) {
      function getIconeUsageRiskObj(val) {
        var ic;
        switch (val) {
          case 0: ic = '<i class=\'fas fa-circle\' style=\'color:gray;\'></i>'; break;
          case 1: ic = '<i class=\'fas fa-circle\' style=\'color:green;\'></i>'; break;
          case 2: ic = '<i class=\'fas fa-circle\' style=\'color:orange;\'></i>'; break;
          case 3: ic = '<i class=\'fas fa-circle\' style=\'color:red;\'></i>'; break;
          default: ic = '<i class=\'fas fa-circle\' style=\'color:white;\'></i>'; break;
        }
        return ic;
      }
      function getIconeVulnerabilityObj(val) {
        var ic;
        switch (val) {
          case 0: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:gray;\'></i>'; break;
          case 1: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:green;\'></i>'; break;
          case 2: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:orange;\'></i>'; break;
          case 3: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:red;\'></i>'; break;
          default: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:white;\'></i>'; break;
        }
        return ic;
      }
      function getColorObject(val) {
        var color;
        switch (val) {
          case 0:
            color = 'lightgrey';
            break;
          case 1:
            color = 'lightgreen';
            break;
          case 2:
            color = 'orange';
            break;
          case 3:
            color = 'red';
            break;
        }
        return color;
      }

      function getColorRisk(val) {
        var color;
        switch (val) {
          case 'Moderate':
            color = 'green';
            break;
          case 'High':
            color = 'orange';
            break;
          case 'Critical':
            color = 'red';
            break;
        }
        return color;
      }
      $('td:eq(0)', row).html('<a class="dataRecordMappin" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.objectName + '</a>');
      // $('td:eq(4)', row).html('<p style="text-align: center">' + getIconeVulnerabilityObj(data.criticityObject) + '</p>').attr('id', 'crtObj_' + data.idObject);
      // $('td:eq(5)', row).html('<p style="text-align: center">' + getIconeUsageRiskObj(data.usageRiskObject) + '</p>').attr('id', 'usrObj_' + data.idObject);
    },
    "ajax": {
      "url": Shared.API_URL +"/api/object-model-mapp/getMapByObject/" + model +"/" + id +'/' + map,
      "contentType": "application/json",
      headers: {
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
// 'Accept': 'Application/json'
      },
    },
    "columns": [
      {"data": "objectName", title: this.lang ? 'Objects' : 'Objets'},
      {"data": "typeObject", title: this.lang ? 'Type' : 'Type'},
      {"data": "orderObject", title: this.lang ? 'Order' : 'Ordre'},
      {"data": "countMapObject", title: this.lang ? 'Count Mapping Column' : 'Nombre de colonnes de mappage'},
      {"data": "criticityObject", title: this.lang ? 'Vulnerability score' : 'Score de vulnérabilité'},
      {"data": "usageRiskObject", title: this.lang ? 'Data usage risk level' : 'Niveau de risque d\'utilisation des données'},
      {"data": "idObject", title: 'id'}
    ],
    "initComplete": function(settings, json) {
      if (json.data.length == 0){
        $('#map_pop').hide();
      } else {
        $('#map_pop').show();
        // map data
        $('#map_table tbody').on('click', 'a.dataRecordMappin', function () {
          var idObj ='';
          var tr = $(this).closest('tr');
          var row = $('#map_table').DataTable().row(tr);
          $('.glmpshown').next('tr').hide();
          // $('.lgShown').removeClass('lgShown');
          var indexRow = row.index();
          //  console.log('row : ' + indexRow);
          if (row.child.isShown()) {
            // This row is already open - close it
            if( $('#map_tabe').length){
              //   $('#record_tab').DataTable().destroy();
              $('#map_tabe').remove();
              // console.log('field removed');
            }
            if( $('#record_map_tabe').length){
              //   $('#record_tab').DataTable().destroy();
              $('#record_map_tabe').remove();
              // console.log('table removed');
            }
            row.child.hide();
            tr.removeClass('glmpshown');
          }
          else {
            if( $('#map_tabe').length){
              //   $('#record_tab').DataTable().destroy();
              $('#map_tabe').remove();
              // console.log('qcm removed');
            }
            if( $('#record_map_tabe').length){
              //   $('#record_tab').DataTable().destroy();
              $('#record_map_tabe').remove();
              // console.log('table removed');
            }
            // Open this row
            row.child('<table class="mapchilde" id ="record_map_tabe" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();
            const mapChildTable = $('#record_map_tabe').DataTable({
              processing: true,
              columnDefs: [
                {visible: false, targets: [0]}
// { "visible": false, "targets": 8 }
              ],
              paginate: false,
              responsive: true,
              "rowCallback": function (row, data) {
                function getIconeUsageRiskRec(val) {
                  var ic;
                  switch (val) {
                    case 1: ic = '<i class=\'fas fa-circle\' style=\'color:green;\'></i>'; break;
                    case 2: ic = '<i class=\'fas fa-circle\' style=\'color:orange;\'></i>'; break;
                    case 3: ic = '<i class=\'fas fa-circle\' style=\'color:red;\'></i>'; break;
                  }
                  return ic;
                }
                function getIconeVulnerabilityRec(val) {
                  var ic;
                  switch (val) {
                    case 0: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:gray;\'></i>'; break;
                    case 1: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:green;\'></i>'; break;
                    case 2: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:orange;\'></i>'; break;
                    case 3: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:red;\'></i>'; break;
                  }
                  return ic;
                }
                function getColorRecord(val) {
                  var color;
                  switch (val) {
                    case 0:
                      color = 'lightgrey';
                      break;
                    case 1:
                      color = 'lightgreen';
                      break;
                    case 2:
                      color = 'orange';
                      break;
                    case 3:
                      color = 'red';
                      break;
                  }
                  return color;
                }
                $('td:eq(0)', row).html('<a class="dataMappFieldo" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.table + '</a>');
                $('td:eq(3)', row).html('<p style="text-align: center">' + getIconeVulnerabilityRec(data.sensibilityRecord) + '</p>');
                $('td:eq(4)', row).html('<p style="text-align: center">' + getIconeUsageRiskRec(data.usageRiskRecord) + '</p>');
              },

              ajax: {
                url: Shared.API_URL +"/api/object-model/getRecordsByObjectMapping/" + row.data().idObject,
                contentType: "application/json",
                headers: {
                  Accept: 'Application/json',
                  Authorization: 'Bearer ' + getCookieValue('tokendrd')
// 'Accept': 'Application/json'
                },
              },
              "columns": [
                {"data": "idTable", "title": this.lang ? "idTable" : "ID Table"},
                {"data": "table", "title": this.lang ? "Tables" : "Tables"},
                {"data": "description", "title": this.lang ? "Description" : "Description"},
                {"data": "countMapRecord", "title": this.lang ? "Count Mapping Column" : "Nombre de colonnes de mappage"},
                {"data": "sensibilityRecord", "title": this.lang ? "Vulnerability score" : "Score de vulnérabilité"},
                {"data": "usageRiskRecord", "title": this.lang ? "Data usage risk level" : "Niveau de risque d'utilisation des données"}
              ],
              "initComplete": function(settings, json) {
                // Add event listener for opening and closing details
                $('#record_map_tabe tbody').on('click', 'a.dataMappFieldo', function () {
                  // console.log('lotfi child');
                  var tr = $(this).closest('tr');
                  var row = $('.mapchilde').DataTable().row(tr);

                  var rowData = row.data();
                  $('.mbshown').next('tr').hide();
                  var indexRow = row.index();
                  if (row.child.isShown()) {
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('mposhown');
                  }
                  else {
                    tr.addClass('mposhown');
                    if( $('#map_tabe').length){
                      //   $('#record_tab').DataTable().destroy();
                      $('#map_tabe').remove();
                    }
                    // Open this row
                    row.child('<table class="mape_tabe" id ="map_tabe" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();
                    $('#map_tabe').DataTable({
                      processing: true,
                      //destroy: true,
                      columnDefs: [
                        {visible: false, targets: [4]}
                      ],
                      paginate: false,
                      responsive: true,
                      "rowCallback": function (row, data) {
                        function getIconeUsageRiskRecField(val) {
                          var ic;
                          switch (val) {
                            case 1: ic = '<i class=\'fas fa-circle\' style=\'color:green;\'></i>'; break;
                            case 2: ic = '<i class=\'fas fa-circle\' style=\'color:orange;\'></i>'; break;
                            case 3: ic = '<i class=\'fas fa-circle\' style=\'color:red;\'></i>'; break;
                          }
                          return ic;
                        }
                        function getIconeVulnerabilityField(val) {
                          var ic;
                          switch (val) {
                            case 0: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:gray;\'></i>'; break;
                            case 1: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:green;\'></i>'; break;
                            case 2: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:orange;\'></i>'; break;
                            case 3: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:red;\'></i>'; break;
                          }
                          return ic;
                        }
                        function getColorClassif(val) {
                          var color;
                          switch (val) {
                            case 0:
                              color = 'lightgrey';
                              break;
                            case 1:
                              color = 'lightgreen';
                              break;
                            case 2:
                              color = 'orange';
                              break;
                            case 3:
                              color = 'red';
                              break;
                          }
                          return color;
                        }
                      },
                      ajax: {
                        url: Shared.API_URL + "/api/object-model/getFieldsMappByRecord/" + row.data().idTable,
                        contentType: "application/json",
                        headers: {
                          Accept: 'Application/json',
                          Authorization: 'Bearer ' + getCookieValue('tokendrd')
                        },
                      },
                      "columns": [

                          {"data": "name", "title": this.lang ? "Fields" : "Champs"},
                          {"data": "dataStorage", "title": this.lang ? "Data Storage" : "Stockage de données"},
                          {"data": "dataSrc", "title": this.lang ? "Data Source" : "Source de données"},
                          {"data": "dataMap", "title": this.lang ? "Data Mapping" : "Mapping de données"},
                          {"data": "idMapp", "title":"idMapp"}
                      ],
                    });
                  }

                });
              }
            });
            if(localStorage.getItem('usr_val')=='GDPR'){
              mapChildTable.column(5).visible(true);
            }else{
              mapChildTable.column(5).visible(false);
            }
            if(localStorage.getItem('dsr_val')=='GDPR'){
              mapChildTable.column(4).visible(true);
            }else{
              mapChildTable.column(4).visible(false);
            }

            tr.addClass('gmpshown');
          }
        });
        if(localStorage.getItem('usr_val')=='GDPR'){
          tableMap.column(5).visible(true);
        }else{
          tableMap.column(5).visible(false);
        }
        if(localStorage.getItem('dsr_val')=='GDPR'){
          tableMap.column(4).visible(true);
        }else{
          tableMap.column(4).visible(false);
        }
        // end map data
      }
    }
  });
}
function security_risk_object(model,id){
  if(window.localStorage.getItem('dsr_val') =='' || window.localStorage.getItem('dsr_val') =='---'){
    return false;
  }
  $('#dsk_table').DataTable().destroy();
  $('#dsk_table').empty();
  $('#dsk_table').DataTable({
    'processing': true,
    responsive: true,
    keys: {
      columns: ':first-child'
    },
    columnDefs: [
      {visible: false, targets: [8]}
// { "visible": false, "targets": 8 }
    ],
    "rowCallback": function (row, data) {
      function getIconeUsageRiskObj(val) {
        var ic;
        switch (val) {
          case 1: ic = '<i class=\'fas fa-circle\' style=\'color:green;\'></i>'; break;
          case 2: ic = '<i class=\'fas fa-circle\' style=\'color:orange;\'></i>'; break;
          case 3: ic = '<i class=\'fas fa-circle\' style=\'color:red;\'></i>'; break;
        }
        return ic;
      }
      function getIconeVulnerabilityObj(val) {
        var ic;
        switch (val) {
          case 0: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:gray;\'></i>'; break;
          case 1: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:green;\'></i>'; break;
          case 2: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:orange;\'></i>'; break;
          case 3: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:red;\'></i>'; break;
        }
        return ic;
      }
      function getColorObject(val) {
        var color;
        switch (val) {
          case 0:
            color = 'lightgrey';
            break;
          case 1:
            color = 'lightgreen';
            break;
          case 2:
            color = 'orange';
            break;
          case 3:
            color = 'red';
            break;
        }
        return color;
      }

      function getColorRisk(val) {
        var color;
        switch (val) {
          case 'Moderate':
            color = 'green';
            break;
          case 'High':
            color = 'orange';
            break;
          case 'Critical':
            color = 'red';
            break;
        }
        return color;
      }
      $('td:eq(0)', row).html('<a class="dataRecordObject" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.objectName + '</a>');
      $('td:eq(2)', row).html('<p style="text-align: center">' + getIconeVulnerabilityObj(data.criticityObject) + '</p>').attr('id', 'crtObj_' + data.idObject);
      // $('td:eq(2)', row).html('').css('background-color', getColorObject(data.criticityObject)).attr('id', 'crtObj_' + data.idObject);
      $('td:eq(3)', row).html('<p style="text-align: center">' + getIconeUsageRiskObj(data.usageRiskObject) + '</p>').attr('id', 'usrObj_' + data.idObject);
      // $('td:eq(3)', row).html('').css('background-color', getColorObject(data.usageRiskObject)).attr('id', 'usrObj_' + data.idObject);
      $('td:eq(5)', row).attr('id', 'avanc_' + data.idObject);
      $('td:eq(6)', row).attr('id', 'user_' + data.idObject);
      $('td:eq(7)', row).attr('id', 'date_' + data.idObject);
    },

    //     $('td:eq(0)', row).html('<a class="dataRecord" style="color:#00b0e4;font-size:9px !important;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.objectName + '</a>');
    //     // $('td:eq(1)', row).css('color', getColorRisk(data.dataRisk)).attr('id', 'dr_' + data.idClassif);
    //     $('td:eq(2)', row).html('').css('background-color', getColorObject(data.usageRiskObject)).attr('id', 'usrObj_' + data.idObject);
    //     $('td:eq(3)', row).html('').css('background-color', getColorObject(data.criticityObject)).attr('id', 'crtObj_' + data.idObject);
    //     $('td:eq(5)', row).attr('id', 'avanc_' + data.idObject);
    //     $('td:eq(6)', row).attr('id', 'user_' + data.idObject);
    //     $('td:eq(7)', row).css('white-space', 'nowrap').attr('id', 'date_' + data.idObject);
    // },
    "ajax": {
      "url": Shared.API_URL + "/api/object-model-record/getDataSecurityRiskByObject/" + model + "/" + id,
      "contentType": "application/json",
      headers: {
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
// 'Accept': 'Application/json'
      }
    },
    paging:false,
    "columns": [
      {"data": "objectName", title: this.lang ? 'Objects' : 'Objets'},
      {"data": "typeObject", title: this.lang ? 'Type' : 'Type'},
      {"data": "criticityObject", title: this.lang ? 'Vulnerability score' : 'Score de vulnérabilité'},
      {"data": "usageRiskObject", title: this.lang ? 'Data usage risk level' : 'Niveau de risque d\'utilisation des données'},
      {"data": "countSensitiveObject", title: this.lang ? 'Count of sensitive Columns' : 'Nombre de colonnes sensibles'},
      {"data": "avancementObject", title: this.lang ? '% of completion' : '% d\'avancement'},
      {"data": "userObject", title: this.lang ? 'Last User' : 'Dernier utilisateur'},
      {"data": "updateRiskObject", title: this.lang ? 'Last modification date' : 'Date de dernière modification'},
        {"data": "idObject", title: 'id'}
    ],
    "initComplete": function(settings, json) {
      // console.log(json.data.length);
      if (json.data.length == 0){
        $('#secure_pop').hide();
      } else {
        $('#secure_pop').show();
      }
    }
  });
  // Add event listener for opening and closing details
  $('#dsk_table tbody').on('click', 'a.dataRecordObject', function () {
    var idObj ='';
    var tr = $(this).closest('tr');
    var row = $('#dsk_table').DataTable().row(tr);
    // $('.lgShown').removeClass('lgShown');
    var indexRow = row.index();
    //  console.log('row : ' + indexRow);
    if (row.child.isShown()) {
      // This row is already open - close it
      if( $('#field_tab_obj').length){
        //   $('#record_tab').DataTable().destroy();
        $('#field_tab_obj').remove();
        // console.log('field removed');
      }
      if( $('#record_tab_secure').length){
        //   $('#record_tab').DataTable().destroy();
        $('#record_tab_secure').remove();
        // console.log('table removed');
      }
      row.child.hide();
    }
    else {
      if( $('#field_tab_obj').length){
        //   $('#record_tab').DataTable().destroy();
        $('#field_tab_obj').remove();
        // console.log('field removed');
      }
      if( $('#record_tab_secure').length){
        //   $('#record_tab').DataTable().destroy();
        $('#record_tab_secure').remove();
        // console.log('table removed');
      }
      // Open this row
      row.child('<table class="childo_table" id ="record_tab_secure" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();
      $('#record_tab_secure').DataTable({
        processing: true,
        columnDefs: [
          {visible: false, targets: [0]}
// { "visible": false, "targets": 8 }
        ],
        paginate: false,
        responsive: true,
        'rowCallback': function (row, data) {
          function getIconeUsageRiskRec(val) {
            var ic;
            switch (val) {
              case 1: ic = '<i class=\'fas fa-circle\' style=\'color:green;\'></i>'; break;
              case 2: ic = '<i class=\'fas fa-circle\' style=\'color:orange;\'></i>'; break;
              case 3: ic = '<i class=\'fas fa-circle\' style=\'color:red;\'></i>'; break;
            }
            return ic;
          }
          function getIconeVulnerabilityRec(val) {
            var ic;
            switch (val) {
              case 0: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:gray;\'></i>'; break;
              case 1: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:green;\'></i>'; break;
              case 2: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:orange;\'></i>'; break;
              case 3: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:red;\'></i>'; break;
            }
            return ic;
          }
          function getColorRecord(val) {
            var color;
            switch (val) {
              case 0:
                color = 'lightgrey';
                break;
              case 1:
                color = 'lightgreen';
                break;
              case 2:
                color = 'orange';
                break;
              case 3:
                color = 'red';
                break;
            }
            return color;
          }
          $('td:eq(0)', row).html('<a class="dataClassif_obj" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.table + '</a>');
          $('td:eq(2)', row).html('<p style="text-align: center">' + getIconeVulnerabilityRec(data.sensibilityRecord) + '</p>').attr('id', 'sensReco_' + data.idTable);
          // $('td:eq(2)', row).html('').css('background-color', getColorRecord(data.sensibilityRecord)).attr('id', 'sensReco_' + data.idTable);
          $('td:eq(3)', row).html('<p style="text-align: center">' + getIconeUsageRiskRec(data.usageRiskRecord) + '</p>').attr('id', 'usrReco_' + data.idTable);
          // $('td:eq(3)', row).html('').css('background-color', getColorRecord(data.usageRiskRecord)).attr('id', 'usrReco_' + data.idTable);
        },
        //     $('td:eq(0)', row).html('<a class="dataClassif" style="color:#00b0e4;font-size:9px !important;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.table + '</a>');
        //     $('td:eq(2)', row).html('').css('background-color', getColorRecord(data.usageRiskRecord)).attr('id', 'usrReco_' + data.idTable);
        //     $('td:eq(3)', row).html('').css('background-color', getColorRecord(data.sensibilityRecord)).attr('id', 'sensReco_' + data.idTable);
        // },
        "ajax": {
          "url": Shared.API_URL + "/api/object-model/getRecordsByObject/" + id,
          "contentType": "application/json",
          headers: {
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
// 'Accept': 'Application/json'
          },
        },
        "columns": [
            {"data": "idTable", "title": this.lang ? "idTable" : "ID Table"},
            {"data": "table", "title": this.lang ? "Tables" : "Tables"},
            {"data": "description", "title": this.lang ? "Description" : "Description"},
            {"data": "sensibilityRecord", "title": this.lang ? "Vulnerability score" : "Score de vulnérabilité"},
            {"data": "usageRiskRecord", "title": this.lang ? "Data usage risk level" : "Niveau de risque d'utilisation des données"}         
        ],
        "initComplete": function(settings, json) {
          // Add event listener for opening and closing details
          $('#record_tab_secure tbody').on('click', 'a.dataClassif_obj', function () {
            // console.log('lotfi child');
            var tr = $(this).closest('tr');
            var row = $('.childo_table').DataTable().row(tr);

            var rowData = row.data();
            $('.klshown').next('tr').hide();
            var indexRow = row.index();
            if (row.child.isShown()) {
              // This row is already open - close it
              row.child.hide();
              tr.removeClass('klshown');
            }
            else {
              tr.addClass('klshown');
              if( $('#field_tab_obj').length){
                //   $('#record_tab').DataTable().destroy();
                $('#field_tab_obj').remove();
              }
              // Open this row
              row.child('<table class="classif_table" id ="field_tab_obj" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();
              $('#field_tab_obj').DataTable({
                processing: true,
                //destroy: true,
                columnDefs: [
                  {visible: false, targets: [9]}
                ],
                paginate: false,
                responsive: true,
                "rowCallback": function (row, data) {
                  function getIconeUsageRiskRecField(val) {
                    var ic;
                    switch (val) {
                      case 1: ic = '<i class=\'fas fa-circle\' style=\'color:green;\'></i>'; break;
                      case 2: ic = '<i class=\'fas fa-circle\' style=\'color:orange;\'></i>'; break;
                      case 3: ic = '<i class=\'fas fa-circle\' style=\'color:red;\'></i>'; break;
                    }
                    return ic;
                  }
                  function getIconeVulnerabilityField(val) {
                    var ic;
                    switch (val) {
                      case 0: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:gray;\'></i>'; break;
                      case 1: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:green;\'></i>'; break;
                      case 2: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:orange;\'></i>'; break;
                      case 3: ic = '<i class=\'fas fa-exclamation-triangle\' style=\'color:red;\'></i>'; break;
                    }
                    return ic;
                  }
                  function getColorClassif(val) {
                    var color;
                    switch (val) {
                      case 0:
                        color = 'lightgrey';
                        break;
                      case 1:
                        color = 'lightgreen';
                        break;
                      case 2:
                        color = 'orange';
                        break;
                      case 3:
                        color = 'red';
                        break;
                    }
                    return color;
                  }
                  $('td:eq(0)', row).html('<p ">' + data.name + '</p>');
                  $('td:eq(3)', row).html('<p style="text-align: center">' + getIconeVulnerabilityField(data.sensibilityField) + '</p>').attr('id', 'sf_' + data.idClassif);
                  // $('td:eq(3)', row).html('').css('background-color', getColorClassif(data.sensibilityField)).attr('id', 'sf_' + data.idClassif);
                  $('td:eq(4)', row).html('<p style="text-align: center">' + getIconeUsageRiskRecField(data.dataUsageRisk) + '</p>').attr('id', 'usrFiel_' + data.idClassif);
                  // $('td:eq(4)', row).html('').css('background-color', getColorClassif(data.dataUsageRisk)).attr('id', 'usrFiel_' + data.idClassif);
                  $('td:eq(5)', row).html('').css('background-color', getColorClassif(data.dataAccess)).attr('id', 'dt_' + data.idClassif);
                  $('td:eq(6)', row).html('').css('background-color', getColorClassif(data.accessLocation)).attr('id', 'al_' + data.idClassif);
                  $('td:eq(7)', row).html('').css('background-color', getColorClassif(data.identifiability)).attr('id', 'ide_' + data.idClassif);
                  $('td:eq(8)', row).html('').css('background-color', getColorClassif(data.quantity)).attr('id', 'qt_' + data.idClassif);
                },
                "ajax": {
                  "url": Shared.API_URL + "/api/object-model/getFieldsByRecord/" + row.data().idTable,
                  "contentType": "application/json",
                  headers: {
                    'Accept': 'Application/json',
                    'Authorization': 'Bearer ' + getCookieValue('tokendrd')
// 'Accept': 'Application/json'
                  },
                },
                "columns": [
                  {"data": "name", "title": this.lang ? "Fields" : "Champs"},
                  {"data": "dataCategory", "title": this.lang ? "Data category" : "Catégorie de données"},
                  {"data": "compliance", "title": this.lang ? "Compliance" : "Conformité"},
                  {"data": "sensibilityField", "title": this.lang ? "Vulnerability score" : "Score de vulnérabilité"},
                  {"data": "dataUsageRisk", "title": this.lang ? "Data usage risk" : "Risque d'utilisation des données"},
                  {"data": "dataAccess", "title": this.lang ? "Data access" : "Accès aux données"},
                  {"data": "accessLocation", "title": this.lang ? "Access Location" : "Emplacement d'accès"},
                  {"data": "identifiability", "title": this.lang ? "Identifiability" : "Identifiabilité"},
                  {"data": "quantity", "title": this.lang ? "Quantity" : "Quantité"},
                  {"data": "idClassif", "title":"idClassif"}
                ]
              });
            }

          });
        }
      });
      tr.addClass('lgshown');
    }
  });
}
function usage_security_object(id) {
  $('.usr_tr').remove();
  var emptyy = false;
  if (window.localStorage.getItem('usr_val') == "" || window.localStorage.getItem('usr_val') == "---") {
    return false;
  }
  const cats = ['rgbdFinancier', 'rgbdLocalisation', 'rgbdConnexion', 'rgbdIdentification', 'rgbdPerso', 'rgbdPro', 'rgbdPolitique', 'rgbdSyndicat', 'rgbdSyndicat', 'rgbdNir', 'rgbdReligion', 'rgbdEthnie', 'rgbdBiometrie', 'rgbdSante', 'rgbdPenal', 'rgbdSexe', 'rgbdGenetique'];
  $.each(cats, function( index, value ) {
    $('#' + value).html('');
  });
  $('#data_usage1').hide();
  $.ajax({
    url: Shared.API_URL + '/api/usage-risk/getUsageRiskPop/' + id,
    headers: {Authorization: 'Bearer ' + getCookieValue('tokendrd')}
  ,
  success: function(data, textStatus) {
      if(data.data.length==0){
        emptyy =true;
        $("#risk_pop").hide();
        // console.log('empty');
        return false;
      }else{
        $("#risk_pop").show();
        $('#spinnerlg').show();
        $.each(data.data[0], function (key, val) {
          if (val.length > 0) {
            for (let i = 0; i < val.length; i++) {
              const index = cats.indexOf(key );
              var htm = "<button onclick=\"getPopUsageRiskDetail(event,'" + val[i].idObject + "','" + val[i].category + "','" + Shared.API_URL + "'," + index + ")\" type=\"button\" class=\"btn-hover btn-border-radius color-8 bt-bt10\"style=\"font-size: 10px !important; width: 100%; margin: 0; height: fit-content; font-weight: 100 !important;\">" + val[i].nameObject + "</button>";
              $('#' + key).html(htm);
              // console.log(htm);
            }
          }
        });
      }
    },
  error: function(XMLHttpRequest, textStatus, errorThrown) {
      alert("error: " + textStatus);
    },
  complete: function(data) {
    if(!emptyy) {
      // console.log('full');
      $('#spinnerlg').hide();
      $('#data_usage1').show();
    }
    }
});
}
// rules pop
function object_rules(id, filterRead, filterAvailable){

  if(!filterRead || !filterAvailable){
    return false;
  }
  $('#rules_table').DataTable().destroy();
  $('#rules_table').empty();
  $('#rules_table').DataTable({
    "columnDefs": [
      {"visible": false, "targets": 4}
    ],
    responsive: true,
    processing: true,
    "pageLength": 5,
    paginate:false,
    "lengthMenu": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
    ajax: {
      url: Shared.API_URL + '/api/object-model-filter/getObjectForFilter/' + id,
      "type": "GET",
      "contentType": "application/json",
      headers: {
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
        // 'Accept': 'Application/json'
      },
    },
    "columns": [
      {"data": "nameObject", "title": this.lang ? "Object" : "Objet"},
      {"data": "descObject", "title": this.lang ? "Description" : "Description"},
      {"data": "typeObject", "title": this.lang ? "Type" : "Type"},
      {"data": "countFilters", "title": this.lang ? "Filters count" : "Nombre de filtres"},
      {"data": "idObject",title : 'id'}
    ],
    "rowCallback": function (row, data) {
      function getIconeTypeObject(val) {
        var ic;
        switch (val) {
          case 'Physical Person': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-user-circle\'></i>'; break;
          case 'Physical person': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-user-circle\'></i>'; break;

          case 'Legal Entity': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-building\'></i>'; break;
          case 'Legal entity': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-building\'></i>'; break;

          case 'Data Repository': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-warehouse\'></i>'; break;
          case 'Data repository': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-warehouse\'></i>'; break;

          case 'Technical Data': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-cogs\'></i>'; break;
          case 'Technical data': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-cogs\'></i>'; break;

          case 'Operational Data': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-database\'></i>'; break;
          case 'Operational data': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-database\'></i>'; break;

          case 'no_type': ic = '<i style=\'color: #797276\' class=\'fas fa-times\'></i>'; break;
        }
        return ic;
      }
      $('td:eq(0)', row).html('<a class="popRules" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.nameObject + '</a>');
      $('td:eq(2)', row).html('<p style="text-align: center">' + getIconeTypeObject(data.typeObject) + '</p>');
      $('td:eq(3)', row).css('text-align', 'center');
    },
    "initComplete": function(settings, json) {
      if (json.data.length == 0){
        $('#rule_pop').hide();
      } else {
        $('#rule_pop').show();
      }
      // Add event listener for opening and closing details
      $('#rules_table tbody').on('click', 'a.popRules', function () {

        var tr = $(this).closest('tr');
        var row = $('#rules_table').DataTable().row(tr);

        var rowData = row.data();
        $('.rules_shown').next('tr').hide();
        var indexRow = row.index();
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('rules_shown');
        }
        else {
          tr.addClass('rules_shown');
          if( $('#child_filter').length){
            $('#child_filter').remove();
          }
          // console.log('child_filter');
          // Open this row
          row.child('<table class="table_filter" id ="child_filter" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();
          $('#child_filter' ).DataTable({
            //destroy: true,
            columnDefs: [
            ],
            paginate: true,
            // responsive: true,
            responsive: true,
            processing: true,
            "pageLength": 5,
            "lengthMenu": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
            ajax: {
              url: Shared.API_URL + "/api/object-model-filter/getFiltersByObject/" + row.data().idObject,
              contentType: "application/json",
              headers: {
                Accept: 'Application/json',
                Authorization: 'Bearer ' + getCookieValue('tokendrd')
              },
            },
            "columns": [
              {"data": "nameFilter", "title": this.lang ? "Filters" : "Filtres"},
              {"data": "pathFilter", "title": this.lang ? "Path" : "Chemin"},
              {"data": "descFilter", "title": this.lang ? "Description" : "Description"}
            ],
          });
        }
      });
    }
  });
}
// records fields tab
function object_RecordField(id){

  $('#recordFieldTable').DataTable().destroy();
  $('#recordFieldTable').empty();
  $('#recordFieldTable').DataTable({
    "columnDefs": [
      {"visible": false, "targets": 4}
    ],
    responsive: true,
    processing: true,
    paginate:false,
    "pageLength": 5,
    "lengthMenu": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
    ajax: {
      url: Shared.API_URL + '/api/record-filed/getObject/' + id,
      "type": "GET",
      "contentType": "application/json",
      headers: {
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
        // 'Accept': 'Application/json'
      },
    },
    "columns": [
      {"data": "nameObject", "title": this.lang ? "Object" : "Objet"},
      {"data": "descObject", "title": this.lang ? "Description" : "Description"},
      {"data": "typeObject", "title": this.lang ? "Type" : "Type"},
      {"data": "countRecord", "title": this.lang ? "table count" : "Nombre de tables"},
      {"data": "idObject",title : 'id'}
    ],
    "rowCallback": function (row, data) {
      function getIconeTypeObject(val) {
        var ic;
        switch (val) {
          case 'Physical Person': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-user-circle\'></i>'; break;
          case 'Physical person': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-user-circle\'></i>'; break;

          case 'Legal Entity': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-building\'></i>'; break;
          case 'Legal entity': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-building\'></i>'; break;

          case 'Data Repository': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-warehouse\'></i>'; break;
          case 'Data repository': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-warehouse\'></i>'; break;

          case 'Technical Data': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-cogs\'></i>'; break;
          case 'Technical data': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-cogs\'></i>'; break;

          case 'Operational Data': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-database\'></i>'; break;
          case 'Operational data': ic = '<i style=\'color: #00b0e4\' class=\'fas fa-database\'></i>'; break;

          case 'no_type': ic = '<i style=\'color: #797276\' class=\'fas fa-times\'></i>'; break;
        }
        return ic;
      }
      $('td:eq(0)', row).html('<a class="dataObjectRecord1" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.nameObject + '</a>');
      $('td:eq(2)', row).html('<p style="text-align: center">' + getIconeTypeObject(data.typeObject) + '</p>');
      $('td:eq(3)', row).css('text-align', 'center');
      $('th:eq(2)').css('text-align', 'center');
      $('th:eq(3)').css('text-align', 'center');
    },
    "initComplete": function(settings, json) {
      // Add event listener for opening and closing details
      $('#recordFieldTable  tbody').on('click', 'a.dataObjectRecord1', function () {
        // console.log('lotfi child');
        var tr = $(this).closest('tr');
        var row = $('#recordFieldTable').DataTable().row(tr);

        var rowData = row.data();
        $('.refshown').next('tr').hide();
        var indexRow = row.index();
        if (row.child.isShown()) {
          // This row is already open - close it
          row.child.hide();
          tr.removeClass('refshown');
        }
        else {
          tr.addClass('refshown');
          if( $('#recordFieldchild').length){
            $('#recordFieldchild').remove();
          }
          // Open this row
          row.child('<table class="table_recordField_fils" id ="recordFieldchild" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();
          $('#recordFieldchild').DataTable({
            //destroy: true,
            columnDefs: [
              {visible: false, targets: [3]}
            ],
            // paginate: false,
            // responsive: true,
            responsive: true,
            processing: true,
            paginate:true,
            "pageLength": 5,
            "lengthMenu": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
            "rowCallback": function (row, data) {
              $('td:eq(0)', row).html('<a class="rfChild" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.nameRecord + '</a>');
              $('td:eq(2)', row).css('text-align', 'center');
            },
            ajax: {
              url: Shared.API_URL + "/api/object-model-record/getRecordsByObject/" + row.data().idObject,
              contentType: "application/json",
              headers: {
                Accept: 'Application/json',
                Authorization: 'Bearer ' + getCookieValue('tokendrd')
              },
            },
            "columns": [//'idRecord', 'nameRecord', 'descRecord'
            {"data": "nameRecord", "title": this.lang ? "name" : "nom"},
            {"data": "descRecord", "title": this.lang ? "Description" : "Description"},
            {"data": "countFields", "title": this.lang ? "Fields count" : "Nombre de champs"},
              {"data": "idRecord", "title":""},
            ],
            "initComplete": function(settings, json) {
              // Add event listener for opening and closing details
              $('#recordFieldchild tbody').on('click', 'a.rfChild', function () {
                // console.log('lotfi child');
                var tr = $(this).closest('tr');
                var row = $('#recordFieldchild').DataTable().row(tr);

                var rowData = row.data();
                $('.fgdsshown').next('tr').hide();
                var indexRow = row.index();
                if (row.child.isShown()) {
                  // This row is already open - close it
                  row.child.hide();
                  tr.removeClass('fgdsshown');
                }
                else {
                  tr.addClass('fgdsshown');
                  if( $('#recordFieldchild1').length){
                    $('#recordFieldchild1').remove();
                  }
                  // Open this row
                  row.child('<table class="table_recordField_fils1" id ="recordFieldchild1" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();
                  $('#recordFieldchild1').DataTable({
                    //destroy: true,
                    columnDefs: [
                    ],
                    responsive: true,
                    processing: true,
                    paginate:true,
                    "pageLength": 5,
                    "lengthMenu": [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                    "rowCallback": function (row, data) {
                    },
                    ajax: {
                      url: Shared.API_URL + "/api/object-model-record/getFieldsByRecord/" + row.data().idRecord,
                      contentType: "application/json",
                      headers: {
                        Accept: 'Application/json',
                        Authorization: 'Bearer ' + getCookieValue('tokendrd')
                      },
                    },
                    "columns": [
                      {"data": "nameField", "title": this.lang ? "name" : "nom"},
                      {"data": "descField", "title": this.lang ? "Description" : "Description"},
                      {"data": "isKeyField", "title":"isKey"},
                    ],
                  });
                }
              });
            }
          });
        }
      });
    }
  });
}
//render(mockedData);
export const correctNode = () => {
  const paddingLeftRight = 18; // adjust the padding values depending on font and font size
  const paddingTopBottom = 5;
  d3.select('#nodosContainer').selectAll('rect')
    .attr("x", function(d) {if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().x - paddingLeftRight;} else {return this.parentNode.childNodes[3].getBBox().x - paddingLeftRight;}})
    .attr("y", function(d) { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().y - paddingTopBottom - 6;} else { return this.parentNode.childNodes[3].getBBox().y - paddingTopBottom - 6;} })
    .attr("rx", 10)
    .attr("ry", 10)
    .attr("width", function(d) { if(d.type == 'node') {return this.parentNode.childNodes[5].getBBox().width + paddingLeftRight ;}else { return this.parentNode.childNodes[3].getBBox().width + paddingLeftRight ;}})
    .attr("height", function(d) { if(d.type == 'node'){return this.parentNode.childNodes[5].getBBox().height  + paddingTopBottom * 2;}else{return this.parentNode.childNodes[3].getBBox().height  + paddingTopBottom * 2; } })
    .attr('fill',(d) => { if(d.type == 'node') { return 'url(#grad1)'; } else { return 'url(#grad3)';}})
    .attr('filter','url(#shadow)')
    .attr('id' , (d) => d.identif)
    .style('text-align','middle');

  // d3.select('#nodosContainer').selectAll('rect')
  //   .each(function(d) {
  //     console.log(d.textLength);
  //     d3.select(this).attr('width', d.textLength * 2 / 3);
  //   });
};
