import * as d3 from 'd3';
import d3Tip from 'd3-tip';

declare const $: any;

export const volumeSizeChart = (newData) => {
  //set margin
  $('#volumeChart').empty();
  const svg = d3.select("#volumeChart");
  $('#volumeChart').attr('width', 900).attr('height', 900);
  let margin = 10;
  const commaFormat = d3.format(',');

  const width = +svg.attr("width") - margin - margin;
  const height = +svg.attr("height") - margin - margin;
  svg.attr("viewBox", `0 0 ${width} ${height}`);

  //set size for rendering
  const diameter = +svg.attr("width");
  const g = svg.append("g")
    .attr('id','VolumeSizeChart')
    .attr("transform", "translate(" + diameter / 2 + "," + diameter / 2 + ")");

  const Zooms = d3.zoom()
    .scaleExtent([.1, 10])

    .on("zoom", function() {
      g.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ') scale(' + d3.event.transform.k + ')');});
  svg.call(Zooms);
  svg.on("wheel.zoom", null);

  if($('.d3-tipVolumeSize-Zoom').length > 0){
    $('.d3-tipVolumeSize-Zoom').remove();
  }
  const tooltip = d3Tip()
    .direction('n')
    .attr("class", "d3-tipVolumeSize-Zoom")
    .offset([-75, 0])
    .html((d) => {
      return '<div id="VolumeSizeTipo">' + d.data.name + '</div>';
    });
  svg.call(tooltip);

  const drawBarData = function(ele, data, d, zoomRadius) {
    if (!data && !data.parent)
      return;

    data.data.children.sort((a, b) => d3.descending(a.size, b.size));

    let rectwidth = (zoomRadius) ? zoomRadius : d.r;
    let rectheight = rectwidth;


    let x = d3.scaleBand()
      .range([0, rectwidth])
      .padding(0.1);

    let y = d3.scaleLinear()
      .range([rectheight, 0]);

    // Scale the range of the data in the domains
    x.domain(data.data.children.map(function(d) {
      return d.name + ' - ' +d.size ;
    }));
    y.domain([0, d3.max(data.data.children, function(d) {
      return d.size;
    })]);

    $("#baroos_" + data.data.id).html("");

    let bg = d3.select($('#baroos_' + data.data.id)[0]).append("g")
      .attr("class", "volumeChart-wrapper")
      .attr("transform", function(d) {
        return "rotate(90) translate(" + -rectwidth/2  + "," + -rectwidth/2  + ")";
      });

    bg.selectAll(".barVolume")
      .data(data.data.children)
      .enter().append("rect")
      .attr("class", "barVolume")
      .style('opacity','0.8')
      .attr("x", function(d) {
        return x(d.name + ' - ' +d.size );
      })
      .attr("width", x.bandwidth())
      .attr("y", function(d) {
        return y(d.size);
      })
      .attr("height", function(d) {
        return rectheight+5  - y(d.size);
      })
      .attr("fill",(d , i) => d.recordCand? '#2bbd2b':'#077dfb')
      .append("svg:title")
      .text((d) => d.name + '  have ' + d.size );

    //just a safe check to render axis only if we have space
    if(rectheight > 100){
      bg.append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + rectheight + ")")
        .call(d3.axisBottom(x))
        .selectAll("text")
        .attr('font-weight', 'bold')
        .attr("y", 0)
        .attr("x", function(d){
          return  this.getBBox().width;
        })
        .attr("dy", ".7em")
        .attr("transform", "rotate(-90)")
        .style("text-anchor", "end");

      bg.append("g")
        .attr("class", "axis axis--y")
        .call(d3.axisLeft(y));
    }
    d3.selectAll('.volumeChart-wrapper').selectAll('text').attr('fill', '#000000');
    d3.selectAll('.volumeChart-wrapper').selectAll('.axis').attr('fill', '#000000');

  };
  // wrap text
  const wrape = (texto, width) => {
    let text = d3.select(texto),
      words = text.text().split(/\s+/).reverse(),
      currentSize = +(text.style("font-size")).replace("px",""),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.2, // ems
      extraHeight = 0.2,
      y = text.attr("y"),
      dy = parseFloat(text.attr("dy")),
      //First span is different - smaller font
      tspan = text.text(null)
        .append("tspan")
        .attr("class","subCount")
        .attr("x", 0).attr("y", y)
        .attr("dy", dy + "em")
        .style("font-size", (Math.round(currentSize*0.5) <= 5 ? 0 : Math.round(currentSize*0.5))+"px");
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width || word === "|") {
        if (word = "|") word = "";
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan")
          .attr('class', 'objectTitle')
          .attr("x", 0).attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + extraHeight + dy + "em")
          .text(word);
      }// if
    }// while
  }; // wrap
  // calculate text font
  const calculateTextFontSize = function(d) {
    var id = d3.select(this).attr('id').split('_')[1];
    var radius = 0;
    if (d.fontsize){
      //if fontsize is already calculated use that.
      return d.fontsize;
    }
    if (!d.computed ) {
      //if computed not present get & store the getComputedTextLength() of the text field
      d.computed = this.getComputedTextLength();
      if(d.computed != 0){
        //if computed is not 0 then get the visual radius of DOM
        var r = d3.selectAll("#circle_" + id).attr("r");
        //if radius present in DOM use that
        if (r) {
          radius = r;
        }
        //calculate the font size and store it in object for future
        d.fontsize = (2 * radius - 8) / d.computed * 34 + "px";
        return d.fontsize;
      }
    }
  };
  // zoom element
  const zoomTo = (v, focus, ele) => {
    var k = diameter / v[2];
    view = v;
    node.attr("transform", function(d) {
      return "translate(" + (d.x - v[0]) * k + "," + (d.y - v[1]) * k + ")";
    });

    circle.attr("r", function(d) {
      if (d && d.height == 1) {
        setTimeout(function() {
          //reste bar charts
          drawBarData("", d, d, d.r * k);
        }, 0);
      }
      return d.r * k;
    });
  };

  var color = d3.scaleOrdinal()
    .domain([0,500])
    .range(d3.schemeSet1);

  //initiate circle pack
  let pack = d3.pack()
    .size([diameter - margin, diameter - margin])
    .padding(2);

  let root = newData;
  root = d3.hierarchy(root)
    .sum(function(d) {
      return d.size;
    });

  let focus = root,
    nodes = pack(root).descendants(),
    view;

  let circle = g.selectAll("circle")
    .data(nodes.filter(function(d) {
      return d.height > 0;
    }))
    .enter().append("circle")
    .attr("class", function(d) {
      return d.parent ? d.children ? "nodos" : "nodos node--leaf" : "nodos node--root";
    })
    .attr("fill", function(d) {
      return d.parent ? d.children ? 'white' : null : 'rgb(116, 215, 202)';
    })
    .attr('id', d => 'circleVlm_' + d.data.id )
    .on('click', null)
    .on('mouseover',function (d) {
      tooltip.show(d, this);
    })
    .on("mouseout", function() {
      tooltip.hide(); })
    .on("click", function(d) {
      $('text[id^=\'labelVolume_\']').css('display','none');
      d3.select('#labelVolume_'+d.data.id).style('display','inline');


      if (focus !== d) {
        if (d.children) {
          zoom(d, this); d3.event.stopPropagation();
        }
      } else{
        $('.typeIconSize').css('display', 'inline');
        document.getElementById('volumeChart').dispatchEvent(new Event('click'));
      }
    });

  let start_x, start_y,current_scale_string,current_scale;


  //platform to draw charts
  let leaf = g.selectAll(".baroos")
    .data(nodes.filter(function(d) {
      //get all leaf node data
      return d.height == 1;
    }))
    .enter()
    .append("g")
    .attr("id", (d) => 'baroos_' + d.data.id)
    .attr("height", function(d) {
      return d.x + d.r;
    })
    .attr("width", function(d) {
      return d.y + d.r;
    })
    .attr("class", "baroos")
    .each(function(d) {
      drawBarData(this, this.__data__, d, 0);
    });


  let text = g.selectAll(".labelVolume")
    .data(nodes.filter(function(d) {
      return d.height > 0;
    }))
    .enter().append("text")
    .attr("class", "labelVolume")
    .style("fill-opacity", function(d) {
      return d.parent === root ? 1 : 0;
    })
    .style("display", function(d) {
      return d.parent === root ? "inline" : "none";
    })
    .attr("y", function(d, i) {
      d.titleHeight = (-1 + 0.25) * d.r;
      return d.titleHeight;
    })
    .attr('dx', '-3em')
    .attr("id", function(d) {
      return 'labelVolume_'+ d.data.id;//setting text name as the ID
    })
    .attr("dy","0em")
    .text(function(d,i) { return d.name; })
    .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r / 10;
      return Math.round(d.fontTitleSize)+"px";
    })
    .each(function(d) {
      d.textLength = d.r*2*0.7;
      wrape(this, d.textLength);
    })
    .text(function(d) {
      return d.data.name;
    });

  let node = g.selectAll("circle,.baroos,.labelVolume");

  svg
    .on("click", function() {
      zoom(root, this);
    });
  // @ts-ignore
  zoomTo([root.x, root.y, root.r * 2 + margin]);
  const zoom = function(d, ele) {
    let focus0 = focus;
    focus = d;
    let transition = d3.transition()
      .duration(0)
      .tween("zoom", function(d) {
        // @ts-ignore
        let i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2 + margin]);
        return function(t) {
          zoomTo(i(t), focus, ele);
        };
      });

    // label transition zoom
    transition.selectAll(".labelVolume")
      .filter(function(d) {
        return d.parent === focus || this.style.display === "inline";
      }).text(function (d) {
      if(d.parent === focus ) {
        $('#volumeChart').click();
        return d.data.name;
      }else{
        if(d.data === undefined){ return;}
        if(d.data.size > 1){
          return d.data.name + ' ' +d.data.size +' tables';
        }
        else{
          return d.data.name + ' ' +d.data.size +' table';
        }
      }
    })
      .attr('y', function (d) {
        if(d.parent === focus ) {
          d3.selectAll('.labelVolume').style('display','inline');
          d.titleHeight = (-1 + 0.25) * d.r;
          return d.titleHeight;

        }else{
          return '-230';
        }
      })
      .style("font-size", function(d) {
        if(d.parent === focus ) {
          d3.selectAll('.subCount')
            .style('font-size','13px')
            .attr('y' ,function (d) {
              const y = $(this).parent().attr('y');
              return y;
            });
          d3.selectAll('.objectTitle')
            .attr('y',function (d) {
              const y = $(this).parent().attr('y');
              return y;
            });
          // Calculate best font-size
          d3.selectAll('.subCount').style('font-size','0');
          d.fontTitleSize = d.r / 10;
          return Math.round(d.fontTitleSize)+"px";
        }else{
          d3.selectAll('.subCount')
            .style('font-size','13px')
            .attr('y' ,function (d) {
              const id = $(this).parent().attr('id').split('_')[1];
              const radius = d3.select('#circle_' + id).attr('r');
              return -radius * 2;
            });
          d3.selectAll('.objectTitle')
            .attr('y',function (d) {
              const id = $(this).parent().attr('id').split('_')[1];
              const radius = d3.select('#circle_' + id).attr('r');
              return -radius * 1.5;
            });

          return "20px";
        }
      });

  };
  $('#zoomIn_button').on('click',function(){
    svg.transition().call(Zooms.scaleBy, 2);
  });
  $('#zoomOut_button').on('click',function(){
    svg.transition().call(Zooms.scaleBy, 0.5);
  });
  $('#resetSize_button').on('click',function(){
    svg.transition().duration(750).call(
      Zooms.transform,
      d3.zoomIdentity,
      d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
    );
  });
};
