import { Component, OnInit } from '@angular/core';

declare const $: any;
@Component({
	selector: 'app-carousel',
	templateUrl: './carousel.component.html',
	styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

	constructor() { }

	ngOnInit() {
		'use strict';
		$('#single_slide_autoplay').owlCarousel({
			items: 1,
			loop: true,
			nav:true,
			margin: 10,
			autoplay: true,
			autoplayTimeout: 3000,
			autoplayHoverPause: true
		});


	}

}
