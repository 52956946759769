export class stackJson{
  id: string;
  role: string;
  description: string;
 // type: string;

  constructor(id='',role: string, description: string/*, type: string*/) {
    this.role = role;
    this.description = description;
   // this.type = type;
    this.id = id;
  }
}
