import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {LocationStrategy, HashLocationStrategy, CommonModule} from '@angular/common';
import { DynamicScriptLoaderService } from './dynamic-script-loader-service.service';
import { AuthComponent } from './auth/auth.component';
import {AuthenticationService} from './services/authentication.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import { JwtModule } from '@auth0/angular-jwt';
import {ListusersService} from './services/users/listusers.service';
import {ProfileService} from './services/users/profile.service';
import {UseraddService} from './services/users/useradd.service';
import {UserremoveService} from './services/users/userremove.service';
import { ProfileuserComponent } from './settings/profileuser/profileuser.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import {AuthGuard} from './services/guard/auth-guard.service';
import {CarouselComponent} from './media/carousel/carousel.component';
import {DataClassifyModule} from './data-classify/data-classify.module';
import {ReportsModule} from './reports/reports.module';
import {DdtmModule} from './ddtm/ddtm.module';
import {ObjectModelsModule} from './object-models/object-models.module';
import {RulesModule} from './rules/rules.module';
import {DrmModule} from './drm/drm.module';
import {ActiveBoardModule} from './active-board/active-board.module';
import {AnonymBoardModule} from './anonym-board/anonym-board.module';
import {CookieService} from 'ngx-cookie-service';
import {DdtmService} from './services/ddtm/ddtm.service';

import {SettingsModule} from './settings/settings.module';
import {TempUserComponent} from './auth/temp-user/temp-user.component';
import { TicketingComponent } from './ticketing/ticketing.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPaginationModule} from 'ngx-pagination';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {OrderModule} from 'ngx-order-pipe';
import {DataLifeCycleModule} from './data-life-cycle/data-life-cycle.module';
import {DataGovernanceModule} from './data-governance/data-governance.module';
import {UserMessageModule} from './userMessage/user-message.module';
import {DataLineageModule} from './data-lineage/data-lineage.module';
import { DataCatalogueModule } from './data-catalogue/data-catalogue.module';
import { DataCatalogueService } from './services/data-catalogue/data-catalogue.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {SharedModule} from './shared/shared.module';
import {BusinessGlossaryModule} from './business-glossary/business-glossary.module';
import {GlobalSearchComponent} from './global-search/global-search.component';
import {RouterhistoryService} from './services/appService/routerhistory.service';
import {LoaderglobsearchComponent} from './loaders/loaderglobSearch/loaderglobsearch.component';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {UploadsModule} from './uploads/uploads.module';
import {FilterHtmlPipe} from './filter-html.pipe';
import {GlossaireModule} from './glossaires/glossaire.module';


export function TranslationLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http,  'assets/i18n/', '.json');
}

export function tokenGetter() {
    return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    ForgotPasswordComponent,
    AuthComponent,
    ProfileuserComponent,
    ChangePasswordComponent,
    CarouselComponent,
    TempUserComponent,
    TicketingComponent,
    GlobalSearchComponent,
    LoaderglobsearchComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
      },
    }),
    DataClassifyModule,
    ReportsModule,
    DdtmModule,
    ObjectModelsModule,
    RulesModule,
    DrmModule,
    ActiveBoardModule,
    AnonymBoardModule,
    SettingsModule,
    NgSelectModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    OrderModule,
    DataLifeCycleModule,
    DataGovernanceModule,
    UserMessageModule,
    DataLineageModule,
    DataCatalogueModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {provide: TranslateLoader, useFactory: TranslationLoaderFactory, deps: [HttpClient]}
    }),
    SharedModule,
    BusinessGlossaryModule,
    NgOptionHighlightModule,
    UploadsModule,
    GlossaireModule,
  ],
  providers:
    [{provide: LocationStrategy, useClass: HashLocationStrategy},
      DynamicScriptLoaderService, AuthenticationService, AuthComponent, ForgotPasswordComponent,
      ProfileService, ListusersService, UseraddService, UserremoveService, AuthGuard, CookieService,
      DdtmService, DataCatalogueService, RouterhistoryService
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
