import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable} from "rxjs";
import {getCookieValue, Shared} from "../../../shared";
import {holderJson} from "../../../data-governance/holders/holderJson";


@Injectable({
  providedIn: 'root'
})
export class HolderService {

  constructor(private http: HttpClient, private  router: Router, public jwtHelper: JwtHelperService) { }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  };

  getHolders() : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/holder/getHolders' , this.getOptions());
  }
  saveHolder(holder: holderJson): Observable<any> {
    const data = "data=" + JSON.stringify(holder);
    return this.http.post<any>(Shared.API_URL + '/api/holder/saveHolder' , data, this.getOptions());
  }
  updateHolder(id,holder: holderJson): Observable<any> {
    const data = "data=" + JSON.stringify(holder);
    return this.http.put<any>(Shared.API_URL + '/api/holder/updateHolder/' + id , data, this.getOptions());
  }
  removeHolder(id: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/holder/removeHolder/' + id, this.getOptions());
  }
  checkHolder(name: string) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/holder/checkHolder/' + name , this.getOptions());
  }
  getProjectsByHolder(holderId: string) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/holder/getProjectsByHolder/' + holderId , this.getOptions());
  }
  getStackList(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/holder/getStacksList'  , this.getOptions());
  }
  getRoles(): Observable<any> {
    return this.http.get(Shared.API_URL + `/api/users-role/getRoles`, this.getOptions());
  }
  checkUserNameWithSuggest(userName) {
    return this.http.get(Shared.API_URL + '/api/users/checkUserNameWithSuggest/' + userName, this.getOptions());
  }
  getUserRole() {
    return this.http.get(Shared.API_URL + '/api/holder/getUserRole/' + localStorage.getItem('userId'), this.getOptions());
  }
  packedDimensionByHolder() {
    return this.http.get(Shared.API_URL + '/api/holder/packedDimensionByHolder' , this.getOptions());
  }
}
