export class ObjmodelJson {
  appl: string;
  models: any[];
  readMd = false;
  createMd = false;
  updateMd = false;
  deleteMd = false;
  constructor(appl, readMd, models,createMd,updateMd,deleteMd) {
    this.appl = appl;
    this.readMd = readMd;
    this.models = [...models];
    this.deleteMd = deleteMd;
    this.createMd = createMd;
    this.updateMd = updateMd;
  }
}
