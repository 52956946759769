import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Profile} from '../../models/profile';
import {tap} from 'rxjs/operators';
import {getCookieValue, Shared} from '../../shared';
@Injectable({
  providedIn: 'root'
})
export class ProfileService {
    token = getCookieValue('tokendrd');
    data: string;
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  // test = 'https://1de7e37d.dm.systnaps.com/sight/ddtm-api/web';
  // test = 'https://39576b66.ddtm.systnaps.com/ddtm-api/web';
  test = 'http://localhost:8000';
  // test = 'http://54.36.121.107:5678';
    constructor(private http: HttpClient) { }
    /*getProfile(): Observable<Profile> {
        return this.http.get<Profile>('http://localhost/ddtm-api/web/api/get_profile', this.Options);
    }*/
    updateSettingUser(data) {
        const id = window.localStorage.getItem('userId');
        return this.http.post(Shared.API_URL + '/api/users/update/' + id, data, this.getOptions());
    }
}
