import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReportsRoutingModule } from './reports-routing.module';
import {ReportsComponent} from './mainReports/reports.component';
import {ReportsDetailsComponent} from './mainReports/reports-details/reports-details.component';
import {ObjectModelComponent} from './object-model/object-model.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {LoadersreportComponent} from '../loaders/loadersReport/loadersreport.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import {TranslateModule} from '@ngx-translate/core';
import {OrderModule} from 'ngx-order-pipe';


@NgModule({
  declarations: [ReportsComponent, ReportsDetailsComponent, ObjectModelComponent, LoadersreportComponent],
  imports: [
    CommonModule,
    ReportsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgxDropzoneModule,
    NgSelectModule,
    NgOptionHighlightModule,
    TranslateModule,
    OrderModule,
  ]
})
export class ReportsModule { }
