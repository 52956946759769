declare const $: any;
export const orgChartEditor = (data = null) => {
  const dataSource =    {
    id: 'templateId', name: 'Template', title: 'template',
    children: [
      {id: 'templateDefinition' , name: 'Definition', title: 'tab'},
      {id: 'governance' , name: 'Data Governance', title: 'tab', children: [
          {id: 'organization' , name: 'Organization', title: 'subTab', children: [
              {id: 'entity' , name: 'Entity', title: 'subTab'},
              // {id: 'dataRoles' , name: 'Data roles', title: 'subTab'},
              // {id: 'stewardShip' , name: 'StewardShip & OwnerShip', title: 'subTab'},
              {id: 'organizationDimensions' , name: 'Dimensions', title: 'subTab'},
              {id: 'country' , name: 'Country', title: 'subTab'}
            ]},
          {id: 'glossary' , name: 'Business Glossary', title: 'subTab'},
          {id: 'catalog' , name: 'Data Catalog', title: 'subTab'},
          {id: 'lineage' , name: 'Data Lineage', title: 'subTab'},
          {id: 'functionalMap' , name: 'Functional Maps', title: 'subTab'}
        ]},
      {id: 'dataModel', name: 'Data Model', title: 'subTab', children: [
          {id: 'dataModeling' , name: 'Data Modeling', title: 'subTab', children: [
              {id: 'diagrams' , name: 'Diagrams', title: 'subTab', children: [
                  {id: 'modelDesigner' , name: 'Model Designer', title: 'subTab'},
                  {id: 'diagramsDesigner' , name: 'Diagrams Designer', title: 'subTab'}
                ]}
            ]},
          { id: 'system' , name: 'System', title: 'tab', children: [
              { id: 'dataDictionary' , name: 'DataDictionary' , title: 'subTab'},
              { id: 'objectModel' , name: 'ObjectModel' , title: 'subTab', children: [
                  {id: 'campaign' , name: 'Campaign', title: 'subTab'}
                ]},
              {id: 'dataFlow' , name: 'Data Flow', title: 'subTab'}
            ] },
          {id: 'infraStructure' , name: 'InfraStructure', title: 'subTab'}
        ]},
      {id: 'dataRegulation', name: 'Data Regulation', title: 'tab', children: [
          {id: 'dataClassification', name: 'Data Classification', title: 'subTab', children: [
              {id: 'classDefinition' , name: 'Definition', title: 'subTab'},
              {id: 'subjectRights' , name: 'Subject Rights', title: 'subTab'}
            ]},
          {id: 'dataSecurityRisk' , name: 'Data Security Risk', title: 'subTab'},
          {id: 'rules' , name: 'Rules', title: 'subTab'},
          {id: 'retentionPeriod' , name: 'Retention Period', title: 'subTab'}
        ]},
      {id: 'dataLifeCycle', name: 'DataLife Cycle', title: 'tab', children: [
          {id: 'quality', name: 'Quality', title: 'subTab'},
          {id: 'reversibility' , name: 'Reversibility', title: 'subTab'},
          {id: 'refresh' , name: 'Refresh', title: 'subTab'},
          {id: 'anonymization' , name: 'Anonymization', title: 'subTab'},
          {id: 'comparison' , name: 'Comparison', title: 'subTab'},
          {id: 'purging' , name: 'Purging', title: 'subTab'},
          {id: 'archiving' , name: 'Archiving', title: 'subTab'}
        ]}
    ]
  };

  function getId() {
    return (new Date().getTime()) * 1000 + Math.floor(Math.random() * 1001);
  }
  if ($('#chart-container').length > 0) {
    $('#chart-container').empty().remove();
    $('#projectTemplateContainer').append('<div id="chart-container"></div>');
  } else {
    $('#projectTemplateContainer').append('<div id="chart-container"></div>');
  }
  // $('#chart-container').empty();
  const options = {
    data: dataSource,
    nodeContent: 'title',
    chartClass: 'edit-state',
    nodeClass: 'bg-blue-org',
    pan: true,
    zoom: true,
    depth: 2,
    zoomoutLimit: 0.5,
    // exportButton: true,
    // exportFilename: 'ProjectTemplate',
    parentNodeSymbol: 'fa-th-large',
    createNode($node, data) {
      $node[0].id = getId();
      const govs = ['governance', 'organization', 'entity', 'dataRoles' , 'stewardShip' , 'organizationDimensions', 'country' , 'glossary' , 'catalog' ,  'lineage' , 'functionalMap'];
      govs.forEach(e => {
        if ( data.id === e) {
          $node.children('.title').addClass('bg-gov');
        }
      });
      const models = ['dataModel', 'dataModeling', 'diagrams', 'modelDesigner', 'diagramsDesigner', 'system', 'dataDictionary', 'objectModel', 'campaign', 'dataFlow' , 'infraStructure'];
      models.forEach(
        e => {
          if ( data.id === e) {
            $node.children('.title').addClass('bg-model');
          }
        }
      );
      const reguls = [ 'dataRegulation', 'dataClassification', 'classDefinition', 'dataSecurityRisk' , 'rules' , 'retentionPeriod', 'subjectRights'];
      reguls.forEach(e => {
      if ( data.id === e) {
        $node.children('.title').addClass('bg-regulation');
        }
       });
      const lifeCycle = [ 'dataLifeCycle', 'quality', 'reversibility', 'refresh' , 'anonymization' , 'comparison' , 'purging' , 'archiving' ];
      lifeCycle.forEach(e => {
        if ( data.id === e) {
          $node.children('.title').addClass('bg-lifeCycle');
        }
      });
      if ( data.id === 'templateDefinition') {
         $node.children('.title').addClass('bg-info');
       }
      if (data.id === 'templateId') {
        $node[0].id = 'lg-123';
        $('#templateName').val(data.name);
        $node.children('.content').html(data.title + '&nbsp;<input id="templateId" style="opacity: 1 !important;pointer-events: all;margin-top: 2px" type="checkbox" value="' + data.name + '"/>');
      } else if (data.id === 'templateDefinition') {
        $node.children('.content').html(data.title);
      } else {
        $node.children('.content').html(data.title + '&nbsp;<input id="' + data.id + '" style="opacity: 1 !important;pointer-events: all;margin-top: 2px" type="checkbox" value="' + data.name + '"/>');
      }
    },
    initComplete($chart) {
      const $chartContainer = $('#chart-container');
      $chartContainer.scrollLeft(($chartContainer[0].scrollWidth - $chartContainer.width()) / 2 );
    }
  };
  let oc = $('#chart-container').orgchart(options);
  oc.init(options);
  setTimeout(() => {
    const $chartContainer = $('#chart-container');
    $chartContainer.scrollLeft(($chartContainer[0].scrollWidth - $chartContainer.width()) / 2 );
  }, 500);
  oc.$chartContainer.on('click', '.node', function() {
    // const $this = $(this);
    // $('#selected-node').val($this.find('.title').text()).data('node', $this);
    // if($(this).data('nodeData').id === 1){
    //   alert(JSON.stringify($(this).data('nodeData')));
    // }
  });

  oc.$chartContainer.on('click', '.orgchart', function(event) {
    if (!$(event.target).closest('.node').length) {
      $('#selected-node').val('');
    }
  });

  $('input[name="chart-state"]').on('click', function() {
    $('.orgchart').toggleClass('edit-state', this.value !== 'view');
    $('#edit-panel').toggleClass('edit-state', this.value === 'view');
    if ($(this).val() === 'edit') {
      $('.orgchart').find('tr').removeClass('hidden')
        .find('td').removeClass('hidden')
        .find('.node').removeClass('slide-up slide-down slide-right slide-left');
    } else {
      $('#btn-reset').trigger('click');
    }
  });

  $('input[name="node-type"]').on('click', function() {
    const $this = $(this);
    if ($this.val() === 'parent') {
      $('#edit-panel').addClass('edit-parent-node');
      $('#new-nodelist').children(':gt(0)').remove();
    } else {
      $('#edit-panel').removeClass('edit-parent-node');
    }
  });

  $('#btn-add-input').on('click', function() {
    $('#new-nodelist').append('<li><input type="text" class="new-node"></li>');
  });

  $('#btn-remove-input').on('click', function() {
    const inputs = $('#new-nodelist').children('li');
    if (inputs.length > 1) {
      inputs.last().remove();
    }
  });

  $('#btn-add-nodes').on('click', function() {
    const $chartContainer = $('#chart-container');
    const nodeVals = [];
    $('#new-nodelist').find('.new-node').each(function(index, item) {
      const validVal = item.value.trim();
      if (validVal.length) {
        nodeVals.push(validVal);
      }
    });
    const $node = $('#selected-node').data('node');
    if (!nodeVals.length) {
      alert('Please input value for new node');
      return;
    }
    const nodeType = $('input[name="node-type"]:checked');
    if (!nodeType.length) {
      alert('Please select a node type');
      return;
    }
    if (nodeType.val() !== 'parent' && !$('.orgchart').length) {
      alert('Please creat the root node firstly when you want to build up the orgchart from the scratch');
      return;
    }
    if (nodeType.val() !== 'parent' && !$node) {
      alert('Please select one node in orgchart');
      return;
    }
    if (nodeType.val() === 'parent') {
      if (!$chartContainer.children('.orgchart').length) {// if the original chart has been deleted
        oc = $chartContainer.orgchart({
          data: {name: nodeVals[0]},
          exportButton: true,
          exportFilename: 'ProjectTemplate',
          exportFileextension: 'pdf',
          parentNodeSymbol: 'fa-th-large',
          createNode($node, data) {
            $node[0].id = getId();
          },
          initComplete($chart) {
            $chartContainer.scrollLeft(($chartContainer[0].scrollWidth - $chartContainer.width()) / 2 );
          }
        });
        oc.$chart.addClass('view-state');
      } else {
        oc.addParent($chartContainer.find('.node:first'), {name: nodeVals[0], id: getId()});
      }
    } else if (nodeType.val() === 'siblings') {
      if ($node[0].id === oc.$chart.find('.node:first')[0].id) {
        alert('You are not allowed to directly add sibling nodes to root node');
        return;
      }
      oc.addSiblings($node, nodeVals.map(function(item) {
        return {name: item, relationship: '110', id: getId()};
      }));
    } else {
      const hasChild = $node.parent().attr('colspan') > 0;
      if (!hasChild) {
        const rel = nodeVals.length > 1 ? '110' : '100';
        oc.addChildren($node, nodeVals.map(function(item) {
          return {name: item, relationship: rel, id: getId()};
        }));
      } else {
        oc.addSiblings($node.closest('tr').siblings('.nodes').find('.node:first'), nodeVals.map(function(item) {
          return {name: item, relationship: '110', id: getId()};
        }));
      }
    }
  });

  $('#btn-delete-nodes').on('click', function() {
    const $node = $('#selected-node').data('node');
    if (!$node) {
      alert('Please select one node in orgchart');
      return;
    } else if ($node[0] === $('.orgchart').find('.node:first')[0]) {
      if (!window.confirm('Are you sure you want to delete the whole chart?')) {
        return;
      }
    }
    oc.removeNodes($node);
    $('#selected-node').val('').data('node', null);
  });

  $('#btn-reset').on('click', function() {
    $('.orgchart').find('.focused').removeClass('focused');
    $('#selected-node').val('');
    $('#new-nodelist').find('input:first').val('').parent().siblings().remove();
    $('#node-type-panel').find('input').prop('checked', false);
  });
  const checkBoxes = [
    {name: 'governance', parent: 'templateId'}, {name: 'organization', parent: 'governance'},
    {name: 'glossary', parent: 'governance'}, {name: 'catalog', parent: 'governance'},
    {name: 'lineage', parent: 'governance'}, {name: 'functionalMap', parent: 'governance'},
    {name: 'entity', parent: 'organization'}, {name: 'dataRoles', parent: 'organization'},
    {name: 'stewardShip', parent: 'organization'}, {name: 'organizationDimensions', parent: 'organization'},
    {name: 'country', parent: 'organization'}, {name: 'dataModel', parent: 'templateId'},
    {name: 'dataModeling', parent: 'dataModel'}, {name: 'diagrams', parent: 'dataModeling'},
     {name: 'modelDesigner', parent: 'diagrams'}, {name: 'diagramsDesigner', parent: 'diagrams'},
    {name: 'system', parent: 'dataModel'}, {name: 'objectModel', parent: 'system'},
    {name: 'dataDictionary', parent: 'system'}, {name: 'campaign', parent: 'objectModel'},
    {name: 'dataFlow', parent: 'system'},
    {name: 'infraStructure', parent: 'dataModel'}, {name: 'dataRegulation', parent: 'templateId'},
    {name: 'dataClassification', parent: 'dataRegulation'}, {name: 'classDefinition', parent: 'dataClassification'},
    {name: 'subjectRights', parent: 'dataClassification'},
    {name: 'dataSecurityRisk', parent: 'dataRegulation'}, {name: 'rules', parent: 'dataRegulation'},
    {name: 'retentionPeriod', parent: 'dataRegulation'}, {name: 'dataLifeCycle', parent: 'templateId'},
    {name: 'refresh', parent: 'dataLifeCycle'}, {name: 'quality', parent: 'dataLifeCycle'}, {name: 'comparison', parent: 'dataLifeCycle'},
    {name: 'reversibility', parent: 'dataLifeCycle'}, {name: 'anonymization', parent: 'dataLifeCycle'},
    {name: 'archiving', parent: 'dataLifeCycle'}, {name: 'purging', parent: 'dataLifeCycle'}
  ];
  // template principle init events
  $('#templateId').change(function() {
    checkBoxes.forEach( e => {
      $('#' + e.name).prop('checked', this.checked);
    });
  });
  // $('#templateDefinition').change(function() {
  //   if(this.checked){
  //     $('#templateId').prop('checked', true);
  //   }
  //   if(!this.checked){
  //     checkItem('templateDefinition', checkBoxes, 'templateId');
  //   }
  // });
  // $('#dashboard').change(function() {
  //   if(this.checked){
  //     $('#templateId').prop('checked', true);
  //   }
  //   if(!this.checked){
  //     checkItem('dashboard', checkBoxes, 'templateId');
  //   }
  // });
  // governance init events
  const governance = checkBoxes.filter(e => e.parent === 'governance' || e.parent === 'organization');
  $('#governance').change(function() {
    if (this.checked) {
      $('#templateId').prop('checked', true);
    }
    governance.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
    if (!this.checked) {
      checkItem('governance', checkBoxes, 'templateId');
    }
  });
  governance.filter(r => r.name !== 'organization').forEach(e => {
    $('#' + e.name).change(function() {
      if (this.checked) {
        $('#governance').prop('checked', this.checked);
        $('#templateId').prop('checked', true);
      } else {
        checkItem(e.name, governance, 'governance');
        checkItem(e.name, checkBoxes, 'templateId');
      }
    });
  });
  // organization init events
  const organization = checkBoxes.filter(e => e.parent === 'organization');
  $('#organization').change(function() {
    organization.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
    if (this.checked) {
      $('#governance').prop('checked', true);
      $('#templateId').prop('checked', true);
    } else {
      checkItem('governance', governance, 'governance');
      checkItem('organization', checkBoxes, 'templateId');
    }
  });
  organization.forEach(e => {
    $('#' + e.name).change(function() {
     if (this.checked) {
       $('#organization').prop('checked', this.checked);
       $('#templateId').prop('checked', this.checked);
       $('#governance').prop('checked', this.checked);
     } else {
       checkItem(e.name, organization, 'organization');
       checkItem(e.name, governance, 'governance');
       checkItem(e.name, checkBoxes, 'templateId');
     }
    });
  });
  // dataModel init events
  const dataModel  = checkBoxes.filter(e => e.parent === 'dataModel' || e.parent === 'dataModeling' || e.parent === 'system' || e.parent === 'diagrams' || e.parent === 'objectModel');
  $('#dataModel').change(function() {
    dataModel.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
    if (this.checked) {
      $('#templateId').prop('checked', true);
    }
    if (!this.checked) {
      checkItem('dataModel', checkBoxes, 'templateId');
    }
  });
  $('#infraStructure').change(function() {
    if (this.checked || $('#dataModeling').prop('checked') || $('#system').prop('checked')) {
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#dataModeling').prop('checked') && !$('#system').prop('checked')) {
      $('#dataModel').prop('checked', false);
      checkItem('dataModel', checkBoxes, 'templateId');
    }
  });
  // dataModel init events
  const dataModeling  = checkBoxes.filter(e => e.parent === 'dataModeling' || e.parent === 'diagrams');
  $('#dataModeling').change(function() {
    if (this.checked || $('#system').prop('checked') || $('#infraStructure').prop('checked')) {
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#system').prop('checked') && !$('#infraStructure').prop('checked')) {
      $('#dataModel').prop('checked', false);
      checkItem('dataModel', checkBoxes, 'templateId');
    }
    dataModeling.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
  });
  // system init events
  const system  = checkBoxes.filter(e => e.parent === 'system'  || e.parent === 'objectModel');
  $('#system').change(function() {
    if (this.checked || $('#dataModeling').prop('checked') || $('#infraStructure').prop('checked')) {
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#dataModeling').prop('checked') && !$('#infraStructure').prop('checked')) {
      $('#dataModel').prop('checked', false);
      checkItem('dataModel', checkBoxes, 'templateId');
    }
    system.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
  });
  // diagrams init events
  const diagrams  = checkBoxes.filter(e => e.parent === 'diagrams');
  $('#diagrams').change(function() {
    diagrams.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
    if (this.checked) {
      $('#dataModeling').prop('checked', this.checked);
      $('#dataModel').prop('checked', this.checked);
      $('#templateId').prop('checked', this.checked);
    }
    if (!this.checked) {
      $('#dataModeling').prop('checked', this.checked);
      const t = checkBoxes.filter(e => e.parent === 'dataModel');
      checkItem('dataModeling', t, 'dataModel');
      checkItem('dataModel', checkBoxes, 'templateId');
    }
  });
  $('#modelDesigner').change(function() {
    if (this.checked || $('#diagramsDesigner').prop('checked')) {
      $('#diagrams').prop('checked', true);
      $('#dataModeling').prop('checked', true);
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#diagramsDesigner').prop('checked')) {
      $('#diagrams').prop('checked', false);
      $('#dataModeling').prop('checked', false);
      const t = checkBoxes.filter(e => e.parent === 'dataModel');
      checkItem('dataModeling', t, 'dataModel');
      checkItem('dataModel', checkBoxes, 'templateId');
    }
  });
  $('#diagramsDesigner').change(function() {
    if (this.checked || $('#modelDesigner').prop('checked')) {
      $('#diagrams').prop('checked', true);
      $('#dataModeling').prop('checked', true);
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#modelDesigner').prop('checked')) {
      $('#diagrams').prop('checked', false);
      $('#dataModeling').prop('checked', false);
      const t = checkBoxes.filter(e => e.parent === 'dataModel');
      checkItem('dataModeling', t, 'dataModel');
      checkItem('dataModel', checkBoxes, 'templateId');
    }
  });
  // objectModel init events
  const objectModel  = checkBoxes.filter(e => e.parent === 'objectModel');
  $('#objectModel').change(function() {
    if (this.checked || $('#dataDictionary').prop('checked') || $('#dataFlow').prop('checked')) {
      $('#system').prop('checked', true);
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#dataDictionary').prop('checked') && !$('#dataFlow').prop('checked')) {
      $('#system').prop('checked', false);
      const t = checkBoxes.filter(e => e.parent === 'dataModel');
      checkItem('system', t, 'dataModel');
      checkItem('dataModel', checkBoxes, 'templateId');
    }
    objectModel.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
  });
  $('#dataDictionary').change(function() {
    if (this.checked || !$('#objectModel').prop('checked') || !$('#dataFlow').prop('checked')) {
      $('#system').prop('checked', true);
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#objectModel').prop('checked') && !$('#dataFlow').prop('checked')) {
      $('#system').prop('checked', false);
      const t = checkBoxes.filter(e => e.parent === 'dataModel');
      checkItem('system', t, 'dataModel');
      checkItem('dataModel', checkBoxes, 'templateId');
    }
  });
  $('#dataFlow').change(function() {
    if (this.checked || !$('#objectModel').prop('checked') || !$('#dataDictionary').prop('checked')) {
      $('#system').prop('checked', true);
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#objectModel').prop('checked') && !$('#dataDictionary').prop('checked')) {
      $('#system').prop('checked', false);
      const t = checkBoxes.filter(e => e.parent === 'dataModel');
      checkItem('system', t, 'dataModel');
      checkItem('dataModel', checkBoxes, 'templateId');
    }
  });
  $('#campaign').change(function() {
    if (this.checked) {
      $('#objectModel').prop('checked', true);
      $('#system').prop('checked', true);
      $('#dataModel').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#dataDictionary').prop('checked') && !$('#dataFlow').prop('checked')) {
      $('#objectModel').prop('checked', false);
      $('#system').prop('checked', false);
      const t = checkBoxes.filter(e => e.parent === 'dataModel');
      checkItem('system', t, 'dataModel');
      checkItem('dataModel', checkBoxes, 'templateId');

      $('#dataModel').prop('checked', false);
    }
    if (!this.checked) {
      $('#objectModel').prop('checked', false);
    }
      });
  // dataRegulation init events
  const dataRegulation  = checkBoxes.filter(e => e.parent === 'dataRegulation' || e.parent === 'dataClassification');
  $('#dataRegulation').change(function() {
    dataRegulation.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
    if (this.checked) {
      $('#templateId').prop('checked', true);
    } else {
      checkItem('dataRegulation', checkBoxes, 'templateId');
    }
  });
  // dataRegulation init events
  const dataClassification  = checkBoxes.filter(e => e.parent === 'dataClassification');
  $('#dataClassification').change(function() {
    dataClassification.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
    if (this.checked) {
      $('#classDefinition').prop('checked', true);
      $('#dataRegulation').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#dataSecurityRisk').prop('checked') && !$('#rules').prop('checked') && !$('#retentionPeriod').prop('checked')) {
      $('#dataRegulation').prop('checked', false);
      checkItem('dataRegulation', checkBoxes, 'templateId');
    }
  });
  $('#dataSecurityRisk').change(function() {
    if (this.checked) {
      $('#dataRegulation').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#dataClassification').prop('checked') && !$('#rules').prop('checked') && !$('#retentionPeriod').prop('checked')) {
      $('#dataRegulation').prop('checked', false);
      checkItem('dataRegulation', checkBoxes, 'templateId');
    }
  });
  $('#rules').change(function() {
    if (this.checked) {
      $('#dataRegulation').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#dataClassification').prop('checked') && !$('#dataSecurityRisk').prop('checked') && !$('#retentionPeriod').prop('checked')) {
      $('#dataRegulation').prop('checked', false);
      checkItem('dataRegulation', checkBoxes, 'templateId');
    }
  });
  $('#retentionPeriod').change(function() {
    if (this.checked) {
      $('#dataRegulation').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked && !$('#dataClassification').prop('checked') && !$('#dataSecurityRisk').prop('checked') && !$('#rules').prop('checked')) {
      $('#dataRegulation').prop('checked', false);
      checkItem('dataRegulation', checkBoxes, 'templateId');
    }
  });
  $('#classDefinition').change(function() {
    if (this.checked) {
      $('#dataClassification').prop('checked', true);
      $('#dataRegulation').prop('checked', true);
      $('#templateId').prop('checked', true);
    } else {
      $('#dataClassification').prop('checked', false);
      checkItem('dataRegulation', checkBoxes, 'templateId');
    }
    if (!this.checked && !$('#retentionPeriod').prop('checked') && !$('#dataSecurityRisk').prop('checked') && !$('#rules').prop('checked')) {
      $('#dataRegulation').prop('checked', false);
      $('#dataClassification').prop('checked', false);
      checkItem('dataRegulation', checkBoxes, 'templateId');
    }
  });
  // dataLifeCycle init events
  const dataLifeCycle  = checkBoxes.filter(e => e.parent === 'dataLifeCycle');
  $('#dataLifeCycle').change(function() {
    dataLifeCycle.forEach(e => {
      $('#' + e.name).prop('checked', this.checked);
    });
    if (this.checked) {
      $('#templateId').prop('checked', true);
    } else {
      checkItem('dataLifeCycle', checkBoxes, 'templateId');
    }
  });
  $('#quality').change(function() {
    if (this.checked) {
      $('#dataLifeCycle').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked) {
      checkItem('quality', dataLifeCycle, 'dataLifeCycle');
      checkItem('dataLifeCycle', checkBoxes, 'templateId');
    }
  });
  $('#refresh').change(function() {
    if (this.checked) {
      $('#dataLifeCycle').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked) {
      checkItem('refresh', dataLifeCycle, 'dataLifeCycle');
      checkItem('dataLifeCycle', checkBoxes, 'templateId');
    }
  });
  $('#reversibility').change(function() {
    if (this.checked) {
      $('#dataLifeCycle').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked) {
      checkItem('reversibility', dataLifeCycle, 'dataLifeCycle');
      checkItem('dataLifeCycle', checkBoxes, 'templateId');
    }
  });
  $('#anonymization').change(function() {
    if (this.checked) {
      $('#dataLifeCycle').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked) {
      checkItem('anonymization', dataLifeCycle, 'dataLifeCycle');
      checkItem('dataLifeCycle', checkBoxes, 'templateId');
    }
  });
  $('#comparison').change(function() {
    if (this.checked) {
      $('#dataLifeCycle').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked) {
      checkItem('comparison', dataLifeCycle, 'dataLifeCycle');
      checkItem('dataLifeCycle', checkBoxes, 'templateId');
    }
  });
  $('#archiving').change(function() {
    if (this.checked) {
      $('#dataLifeCycle').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked) {
      checkItem('archiving', dataLifeCycle, 'dataLifeCycle');
      checkItem('dataLifeCycle', checkBoxes, 'templateId');
    }
  });
  $('#purging').change(function() {
    if (this.checked) {
      $('#dataLifeCycle').prop('checked', true);
      $('#templateId').prop('checked', true);
    }
    if (!this.checked) {
      checkItem('purging', dataLifeCycle, 'dataLifeCycle');
      checkItem('dataLifeCycle', checkBoxes, 'templateId');
    }
  });
  // edit mode
  if ( data !== null) {
//    console.log(data);
    checkBoxes.forEach(e => {
      $('#templateName').val(data.name);
      $('#templateId').prop('checked', true).val(data.name);
      $('#lg-123 .title').html('<i class="fa fa-th-large symbol"></i>' + data.name);
      if ($('#' + e.name).length > 0 ) {
        $('#' + e.name).prop('checked',  data[e.name]);
      }
    });
  }
  function checkItem(name, checks, item) {
    let checked = false;
    checks.filter(f => f.name !== name).forEach(t => {
      if ($('#' + t.name).prop('checked')) {
        checked = true;
      }
    });
    $('#' + item).prop('checked', checked);
  }
};
