export class AppFlowRole  {
  createApp = false;
  readApp = false;
  updateApp = false;
  deleteApp = false;

  createflow = false;
  readFlow = false;
  updateFlow = false;
  deleteFlow = false;
  constructor(createApp, readApp, updateApp, deleteApp, createflow, readFlow , updateFlow , deleteFlow) {
    this.createApp = createApp;
    this.readApp = readApp;
    this.deleteApp = deleteApp;
    this.updateApp = updateApp;
    this.createflow = createflow;
    this.readFlow = readFlow;
    this.updateFlow = updateFlow;
    this.deleteFlow = deleteFlow;
  }
}
