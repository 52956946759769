import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable} from "rxjs";
import {getCookieValue, Shared} from "../../../shared";
import {stackJson} from "../../../data-governance/stack/stackJson";

@Injectable({
  providedIn: 'root'
})
export class StackService {

  constructor(private http: HttpClient, private  router: Router, public jwtHelper: JwtHelperService) { }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  };

  getHolders() : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/holder/getHolders/' + localStorage.getItem('userId') , this.getOptions());
  }
  saveStack(stack: stackJson): Observable<any> {
    const data = "data=" + JSON.stringify(stack);
    return this.http.post<any>(Shared.API_URL + '/api/stack/saveStack' , data, this.getOptions());
  }
  updateStack(stack: stackJson): Observable<any> {
    const data = "data=" + JSON.stringify(stack);
    return this.http.put<any>(Shared.API_URL + '/api/stack/updateStack'  , data, this.getOptions());
  }
  removeStack(id: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/stack/removeStack/' + id, this.getOptions());
  }
  checkStack(name: string) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/stack/checkStack/' + name , this.getOptions());
  }
  getHoldersByStack(stackId: string) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/holder/getHoldersByStack/' + stackId , this.getOptions());
  }
  getDimensionByStack(stackName: string) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/stack/getDimensionByStack/' + stackName , this.getOptions());
  }
}
