import { AfterViewInit, Component, HostListener, OnInit } from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { PlatformLocation } from '@angular/common';
import { AuthenticationService } from './services/authentication.service';
import { ReportService } from './services/reports/report.service';
import { CryptoService } from './crypto.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DynamicScriptLoaderService } from './dynamic-script-loader-service.service';
import { OrderPipe } from 'ngx-order-pipe';
import { VersionService } from './services/versioning/version.service';
import { BehaviorSubject, Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { addLoaderDeleteRp, removeLoader } from './object-models/graphs/diagramme/utils';
import { UserMessageService } from './services/userMessage/user-message.service';
import {getCookieValue, Shared} from './shared';
import { TranslateService } from '@ngx-translate/core';
import { RouterhistoryService } from './services/appService/routerhistory.service';
import { TipsService } from './services/tips/tips.service';
import { environment } from 'src/environments/environment.onprem';
declare const $: any;
declare const CKEDITOR: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app-component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  listDescTipsWithName: any;
  textSearchValue = '';
  // translate
  title = 'angular-9-i18n';
  langs = ['fr', 'en'];

  currentUrl: string;
  showLoadingIndicatior = true;
  roleUserCurrent: string;
  nameUserCurrent: string;
  emailUserCurrent: string;
  isAdmin: boolean = false;
  currentRoute = this.router.url;
  switchUserRole: string;
  isShow: boolean;
  topPosToStartShowing = 100;
  nameRole = '';
  // versioning
  applicationV: any;
  moduleV: any;
  submenuV: any;
  modifDescription = false;
  submoduleForUpdate: any;
  idSelectedVersion = 'null';
  listVersioning: any[];
  listTips = [];
  // add version by formGroup
  public frmAddVersion: FormGroup;
  addForm: FormGroup;
  // paginate versions
  // flag = false;
  ids = [];
  asyncVersions: Observable<any[]>;
  p = 1;
  totalVersions = 0;
  loadingVersions: boolean;
  versionconfig: any;
  order = 'id';
  reverse = false;
  sortedCollection: any[];
  selectedPageSize = 5;
  pageSizeList = [5, 10, 15, 20, 25, 30];
  versionFilter: any;
  dataFilterVersion: any;
  versionSite: string;
  // end paginate object models
  flagVersion = false;
  versionningRole = false;
  titleFormModal = 'new';
  overlayOpen = false;
  countMessage = 0;
  not_ids = [];
  notificationInterval: any;
  authenticated = false;
  email_encoded: any;
  user_login_encoded: any;
  wiki_website_url = Shared.WIKI;
  lang: string;
  // roles
  adminoRoleStruct = false;
  adminoRoleModel = false;
  appdefRole = false;
  appflowrole = true;
  infraRole = false;
  settingrole = false;
  structoRole = false;
  classificationRole = false;
  rulesRole = false;
  retentionRole = false;
  definitionCalssificationRole = false;
  securityRole = false;
  objRole = false;
  designerRole = false;
  lifeCycle = false;
  projectRole = false;
  metadataRole = false;
  holderRole = false;
  glossaryRole = false;
  dataLineageRole = false;
  dimensionRole = false;
  countryRole = false;
  dataCatalogueRole = false;
  entityRole = false;
  diagramSoftwareRole = false;
  userRole = false;
  roleRole = false;
  stackRole = false;
  functionalMapRole = false;
  archiveDeleteRole = false;
  logo = '';
  blackLogo = '';
  portal = 'false';
  // end role
  constructor(private router: Router, location: PlatformLocation, private authenticationService: AuthenticationService,
    private fb: FormBuilder, private reportService: ReportService, private cryptoService: CryptoService,
    private dynamicScriptLoader: DynamicScriptLoaderService, private versionService: VersionService,
    private serviceTips: TipsService, private orderPipe: OrderPipe, private userMessageService: UserMessageService,
    private translate: TranslateService, private routerHistoryService: RouterhistoryService) {
    this.versionconfig = { id: 'server', itemsPerPage: this.selectedPageSize, currentPage: this.p, totalItems: this.totalVersions };
    this.router.events.subscribe((routerEvent: Event) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoadingIndicatior = true;
        location.onPopState(() => {
        });
        this.currentUrl = routerEvent.url.substring(routerEvent.url.lastIndexOf('/') + 1);
      }
      if (this.currentUrl.trim() === 'auth' || this.currentUrl.trim() === ''
        || this.currentUrl.trim() === 'forgot-password' || this.currentUrl.trim() === 'change-password'
        || this.authenticationService.isAuthenticated() === false) {
        document.getElementById('main-component').style['display'] = 'none';
        $('.open-button').css('display', 'none');
        $('#myForm').css('display', 'none');
      } else {
        document.getElementById('main-component').style.removeProperty('display');
        $('.open-button').css('display', 'flex');
        $('#myForm').css('display', 'none');
      }
      if (routerEvent instanceof NavigationEnd) {
        this.showLoadingIndicatior = false;
      }
      window.scrollTo(0, 0);
    });
    this.frmAddVersion = this.initForm();
    // get history of router
    this.routerHistoryService.loadRouting();
  }

  ngOnInit(): void {
    this.logo = environment.envVar.LOGO;
    this.blackLogo = environment.envVar.BlACKLOGO;
    this.portal = environment.envVar.PORTAL;
    // console.log(this.portal);
    localStorage.setItem('recipient', 'none');
    localStorage.setItem('subject', 'none');
    localStorage.setItem('type', 'none');
    localStorage.setItem('lang', 'fr');
    this.translate.setDefaultLang('fr');
    this.notificationInterval = setInterval(() => {
      if (this.authenticationService.isAuthenticated()) {
        this.userMessageService.getNotificationMessage().subscribe(
          data => {
            if (data.count > this.countMessage) {
              this.countMessage = data.count;
              this.not_ids = data.ids;
              $('.notification-msg-counter').css({ opacity: 0, display: 'block' })
                .text(data.count)
                .css({ top: '-10px' })
                .animate({ top: '6px', opacity: 1 });
            }
            if (data.count == 0) {
              this.countMessage = 0;
              this.not_ids = [];
              $('.notification-msg-counter').css({ display: 'none' });
            }
          },
          err => {
            console.error(err);
          }
        );
      }
    }, 30000);

    this.user_login_encoded = window.localStorage.getItem('email_encoded');
    this.email_encoded = window.localStorage.getItem('user_login_encoded');
    let lang = localStorage.getItem('lang');
    $('#switchLang').html('<img height="13" style="margin-top: -3px;" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMCAIAAADgcHrrAAAAK3RFWHRDcmVhdGlvbiBUaW1lAHdvIDI4IGRlYyAyMDA1IDE5OjE1OjU1ICswMTAwahKd2QAAAAd0SU1FB9UMHBISI5QpVKUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAEZ0FNQQAAsY8L/GEFAAAAZElEQVR42t2RMQ7AIAhFP42n7lV6whqZtV1QaWyIg2XuC2EAXhg+AQdwAUV7R2THBNE0CHrd6n61T0S1os7pdBrJfPPCqm1+wfJnLdigPaQRQB4Z5GfBzGsnmW9FqxNjXGttXQHJPSkutxhpRwAAAABJRU5ErkJggg==">');
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    this.translate.stream('lang').subscribe(
      lang => {
        this.lang = lang;
        this.translate.use(this.lang);
      },
      error1 => {
        console.error(error1);
      }
    );
  }

  public useLanguage(language: string): void {
    localStorage.setItem('lang', language);
    $('#switchLang').html('<img height="13" style="margin-top: -3px;" src="assets/images/flags/' + language + '.png">');
    this.translate.use(language);
  }
  getDataForSearch(event) {
    this.textSearchValue = event.target.value;
    if (event.keyCode === 13) {
      $('#modelSearchMain').val('');
      if (this.textSearchValue != '') {
        let cryptTextSearch = this.cryptoService.set(this.textSearchValue);
        cryptTextSearch = cryptTextSearch.replace(/\//g, '__________');
        this.router.navigate(['/global-search/' + cryptTextSearch]);
      }
    }
  }
  redirectToSearch() {
    if (this.textSearchValue != '') {
      let cryptTextSearch = this.cryptoService.set(this.textSearchValue);
      cryptTextSearch = cryptTextSearch.replace(/\//g, '__________');
      this.router.navigate(['/global-search/' + cryptTextSearch]);
    } else {
      this.router.navigate(['/global-search/y02M3Dp94wAJwc3nh6QypA==']);
    }
  }
  infoUserName() {
    return this.nameUserCurrent = getCookieValue('username');
  }
  infoUserRole() {
    var role;
    if (window.localStorage.getItem('roles') === 'ROLE_USER') {
      role = 'Simple USER';
    } else if (window.localStorage.getItem('roles') === 'ROLE_ADMIN') {
      role = 'Administrateur';
    } else {
      role = 'Super Administrateur';
    }
    if (sessionStorage.hasOwnProperty('menu')) {
      const menu = window.sessionStorage.getItem('menu');
      const userRole = this.cryptoService.decryptPhp(menu);
      this.adminoRoleStruct = userRole.modelAdminStruct;
      this.adminoRoleModel = userRole.modelAdminModel;
      this.structoRole = userRole.modelStruc;
      this.objRole = userRole.objectModel;
      this.designerRole = userRole.designer;
      this.diagramSoftwareRole = userRole.diagramSoftware;
      this.appdefRole = userRole.applDef;
      this.appflowrole = userRole.applFlow;
      this.infraRole = userRole.infra;
      this.settingrole = userRole.setting;
      this.classificationRole = userRole.classification;
      this.definitionCalssificationRole = userRole.classificationDefinition;
      this.rulesRole = userRole.rule;
      this.retentionRole = userRole.retention;
      this.securityRole = userRole.security;
      this.lifeCycle = userRole.lifeCycle;
      this.projectRole = userRole.project;
      this.metadataRole = userRole.meta;
      this.stackRole = userRole.stack;
      this.holderRole = userRole.holder;
      this.userRole = userRole.userList;
      this.roleRole = userRole.roleList;
      this.glossaryRole = userRole.glossaryMenu;
      this.dataLineageRole = userRole.dataLineage;
      this.dimensionRole = userRole.dimension;
      this.countryRole = userRole.country;
      this.dataCatalogueRole = userRole.dataCatalogue;
      this.entityRole = userRole.entity;
      this.functionalMapRole = userRole.functionalMap;
      this.archiveDeleteRole = userRole.archiveDelete;
    }
    return role;
  }
  rediretLogoToDash2() {
    this.router.navigate(['/dashboard/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/dashboard2']);
  }
  // lien LOGO SYSTNAPS
  rediretLogoToHome() {
    if (this.objRole || this.structoRole) {
      // this.onGetLastReportsByTypebaseDash();
      this.router.navigate(['/dashboard/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/main']);
    } else if (this.projectRole) {
      this.router.navigate(['/data-governance/project/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    } else if (this.stackRole) {
      this.router.navigate(['/data-governance/data-roles']);
    } else if (this.holderRole) {
      this.router.navigate(['/data-governance/stewards']);
    } else if (this.glossaryRole) {
      this.router.navigate(['/Glossaires/glossaire']);
    } else if (this.dataLineageRole) {
      this.router.navigate(['/data-lineage/data-lineage/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    } else if (this.dimensionRole) {
      this.router.navigate(['/data-governance/dimension/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    } else if (this.countryRole) {
      this.router.navigate(['/data-governance/country']);
    } else if (this.dataCatalogueRole) {
      this.router.navigate(['/data-catalog/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/data-catalogue']);
    } else if (this.entityRole) {
      this.router.navigate(['/data-governance/entity']);
    } else if (this.adminoRoleStruct || this.adminoRoleModel) {
      this.router.navigate(['/model-admin/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/main']);
    } else if (this.structoRole) {
      this.router.navigate(['/ddtm/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    } else if (this.objRole) {
      this.router.navigate(['/object-models/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/object-models']);
    } else if (this.diagramSoftwareRole) {
      this.router.navigate(['/global-designer/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/diagrams-designer']);
    } else if (this.designerRole) {
      this.router.navigate(['/object-models/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/model-designer']);
    } else if (this.appdefRole) {
      this.router.navigate(['/data-transfert/applicationdefinition/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    } else if (this.appflowrole) {
      this.router.navigate(['/data-transfert/home-transfert']);
    } else if (this.infraRole) {
      this.router.navigate(['/data-transfert/infrastructure/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    } else if (this.classificationRole) {
      this.router.navigate(['/drm/drm-classification']);
    } else if (this.rulesRole) {
      this.router.navigate(['/drm/drm-rules/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    } else if (this.securityRole) {
      this.router.navigate(['/drm/drm-security']);
    } else if (this.retentionRole) {
      this.router.navigate(['/drm/drm-retention-period']);
    } else if (this.definitionCalssificationRole) {
      this.router.navigate(['/drm/drm-definition']);
    } else if (this.lifeCycle || this.archiveDeleteRole) {
      this.router.navigate(['/campain/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    } else if (this.userRole) {
      this.router.navigate(['/userslist']);
    } else if (this.roleRole) {
      this.router.navigate(['/usersroles']);
    } else if (this.metadataRole) {
      this.router.navigate(['/metadata']);
    } else if (this.functionalMapRole) {
      this.router.navigate(['Business-Glossary/FunctionalList/y02M3Dp94wAJwc3nh6QypA==']);
    } else {
      this.authenticationService.signOutNew();
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        onOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
      let titleText = this.lang === 'en' ? 'Restricted area' : 'Zone restreinte';
      Toast.fire({
        icon: 'error',
        title: titleText
      });

      this.router.navigate(['/auth']);
    }
  }
  onSignOut() {
    const id = localStorage.getItem('userId');
    this.authenticationService.removeReportDetailCollectionByUser(id).subscribe(
      data => {
      },
      err => {
        console.error(err);
        this.authenticationService.signOutNew();
      },
      () => {
        this.authenticationService.signOutNew();
      }
    );
  }
  // scroll Top
  // top code

  @HostListener('window:scroll')
  checkScroll() {

    // window의 scroll top
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
      $('#stickSearch').css('top', scrollPosition);
    } else {
      this.isShow = false;
    }
  }

  // TODO: Cross browsing
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  toggleMenu(event) {
    $(event.target).toggleClass('toggled').next('.ml-menu').slideToggle(320);
    return false;
  }
  // CKEditor add
  async startScript() {
    this.submenuV = 'null';
    this.idSelectedVersion = 'null';
    this.titleFormModal = 'new';
    this.frmAddVersion = this.initForm();
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'], '-', 'NumberedList']];
      this.loadData();
    }).catch(error => console.error(error));
  }

  private loadData() {
    const that = this;
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['submodule']) {
      CKEDITOR.instances['submodule'].destroy(true);
    }
    let editor = CKEDITOR.replace('submodule', { toolbar: 'MA' });
    editor.config.height = 80;
    editor.on('change', function (evt) {
      $('#labelSubmenu').css({ color: '#26a69a', 'font-size': '0.8rem' });
      that.submenuV = btoa(evt.editor.getData());
    });
  }
  initForm(): FormGroup {
    const that = this;
    return this.addForm = this.fb.group(
      {
        application: [
          null,
          Validators.compose([Validators.required, Validators.pattern('[0-9]{1}\.[0-9]{1}\.[0-9]{1}')]),
        ],
        active: [
          null
        ]
      }
    );
  }
  submitVersion() {
    if (this.idSelectedVersion == 'null') {
      const formValue = this.addForm.value;
      this.applicationV = formValue['application'];
      if (this.submenuV == undefined) {
        this.submenuV = 'null';
      }
      var dataVersion = {
        nameuser: getCookieValue('username'),
        application: this.applicationV,
        description: this.submenuV
      };
      this.versionService.addVersion(dataVersion).subscribe(
        data => {
        },
        err => {
          console.error(err);
        },
        () => {
          $('#version_cancel').click();
          this.onGetListVersion();
        }
      );
    } else {
      this.submitUpdateVersion();
    }
  }
  // get list of versioning
  onGetListVersion() {
    // if (this.overlayOpen === false) {
    this.listVersioning = [];
    this.versionService.getListVersion().subscribe(
      data => {
        this.versionningRole = data.role;
        this.listVersioning = data.versions;
      },
      err => {
        console.error(err);
      },
      () => {
        if (this.listVersioning.length > 0) {
          this.sortedCollection = this.orderPipe.transform(this.asyncVersions, 'id');
          this.versionSite = this.listVersioning[this.listVersioning.length - 1].application;
        }
      }
    );
  }
  pageVersionChanged(event) {
    this.versionconfig.currentPage = event;
  }
  getPage() {
    this.versionconfig.itemsPerPage = this.selectedPageSize;
    this.versionconfig.currentPage = 1;
    this.versionconfig.totalItems = this.listVersioning.length;
  }
  // sort list version
  setOrderVersion(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  // comparator sort
  sortAlphaNumVersion(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  showDetailVersion(version, event) {
    var id = version.id;
    const ul = $('#' + id).parent().parent();
    const li = $('#' + id).parent();
    if (!this.flagVersion) {
      this.flagVersion = true;
      $('#body' + id).slideDown();
      event.target.innerHTML = 'Back';
      this.idSelectedVersion = id;
      ul.children().hide();
      $('#titleVersion').show('slow');
      li.show('slow');
      if (version.sousmodule == 'null') {
        $('#listMenuByVersion' + version.id).children().eq(1).html('');
      } else {
        $('#listMenuByVersion' + version.id).children().eq(1).html(atob(version.sousmodule));
      }
    } else {
      this.idSelectedVersion = 'null';
      this.flagVersion = false;
      event.target.innerHTML = 'Show Details';
      $('#body' + id).slideUp();
      ul.children().show('slow');
    }
  }
  // CKEditor edit
  async startScriptEdit() {
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'], '-', 'NumberedList']];
      this.loadDataEdit();
    }).catch(error => console.error(error));
  }

  private loadDataEdit() {
    const that = this;
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['submodule']) {
      CKEDITOR.instances['submodule'].destroy(true);
    }
    let editor = CKEDITOR.replace('submodule', { toolbar: 'MA' });
    editor.config.height = 80;
    editor.setData(this.submenuV);
    editor.on('change', function (evt) {
      that.modifDescription = true;
      $('#labelSubmenu').css({ color: '#26a69a', 'font-size': '0.8rem' });
      that.submenuV = btoa(evt.editor.getData());
    });
  }
  onSetUpdateRecordVersion(version) {
    this.submenuV = '';
    this.versionService.getRecordVersionPreupdate(version.id).subscribe(
      data => {

        this.frmAddVersion = this.initForm();
        if (data.sousmodule == 'null') {
          this.submenuV = '';
        } else {
          this.submenuV = atob(data.sousmodule);
        }
        this.addForm.get('application').patchValue(data.application);
        this.titleFormModal = 'update';
      }, err => {
        console.error(err);
      },
      () => {
        this.startScriptEdit();
        $('#modalAddVersion').modal('show');
      }
    );
  }
  onSetRemoveRecordVersion(version) {
    var that = this;
    let titleText = this.lang === 'en' ? 'Are you sure?' : 'Êtes-vous sûr(e) ?';
    let contentText = this.lang === 'en' ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !";
    let confirmButtonText = this.lang === 'en' ? 'Yes, delete it!' : 'Oui, supprimer !';
    let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';

    Swal.fire({
      title: titleText,
      text: contentText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText
    }).then((result) => {
      if (result.value) {
        addLoaderDeleteRp('vide', 'f', true);
        $('#bt_' + that.idSelectedVersion).click();
        that.versionService.removeVersion(version.id).subscribe(
          data => {
          },
          err => {
            console.error(err);
          },
          () => {
            this.onGetListVersion();
            removeLoader('vide', 'f', true);
          }
        );
      }
    });
  }
  submitUpdateVersion() {
    if (this.versionningRole) {
      $('#version_submit')
        .html('<div class="spinner-grow spinner-grow-sm" style="color: #fbfbff" role="status">\n' +
          '  <span class="sr-only">' + this.lang === 'en' ? 'Loading...' : 'Chargement...' + '</span>\n' +
            '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
            '  <span class="sr-only">' + this.lang === 'en' ? 'Loading...' : 'Chargement...' + '</span>\n' +
              '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
              '  <span class="sr-only">' + this.lang === 'en' ? 'Loading...' : 'Chargement...' + '</span>\n' +
              '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
        '</div>');
      const formValue = this.addForm.value;
      this.applicationV = formValue['application'];
      if (this.modifDescription === false) {
        if (this.submenuV == undefined || this.submenuV == '') {
          this.submenuV = 'null';
        } else {
          this.submenuV = btoa(this.submenuV);
        }
      } else {
        if (this.submenuV == undefined || this.submenuV == '') {
          this.submenuV = 'null';
        }
      }
      var dataVersion = {
        id: this.idSelectedVersion,
        nameuser: getCookieValue('username'),
        application: this.applicationV,
        description: this.submenuV
      };
      this.versionService.updateVersion(dataVersion).subscribe(
        data => {
          if (data.etat === 'ko') {
            alert(data.error);
          } else {
            let indexVersion = this.listVersioning.findIndex(o => o.id === this.idSelectedVersion);
            this.listVersioning[indexVersion].application = data['application'];
            this.listVersioning[indexVersion].sousmodule = data['sousmodule'];
            this.listVersioning[indexVersion].updatedAt = data['updatedAt'];
            this.listVersioning[indexVersion].updatedBy = data['updatedBy'];
            if (data['sousmodule'] == 'null') {
              this.submenuV = '';
              $('#listMenuByVersion' + this.idSelectedVersion).children().eq(1).html('');
            } else {
              this.submenuV = data['sousmodule'];
              $('#listMenuByVersion' + this.idSelectedVersion).children().eq(1).html(atob(this.submenuV));
            }
          }
        },
        err => {
          console.error(err);
        },
        () => {
          $('#version_cancel').click();
          if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['submodule']) {
            CKEDITOR.instances['submodule'].destroy(true);
          }
        }
      );
    }
  }
  cancelModalVersion() {
    this.frmAddVersion = this.initForm();
  }
  clearNotification() {
    let ids = '';
    this.not_ids.map(i => {
      ids += i + ',';
    });
    // console.log(ids);
    ids = ids.substr(0, ids.lastIndexOf(','));
    // console.log(ids);
    ids = 'data=' + ids;
    if (ids != '') {
      $('.notification-msg-counter').animate({ opacity: 0, display: 'none' });
    }
    this.userMessageService.clearNotification(ids).subscribe(
      data => {
        this.countMessage = 0;
        this.not_ids = [];
      },
      err => {
        console.error(err);
      }
    );
  }
  // enable/disable show tips in all menus
  setActiveTipsAll(ev) {
    let tips = true;
    if ($('#' + ev.target.id).is(':checked')) {
      tips = true;
      $('#mainTips').prop('checked', true);
      // search tips
      $('#searchTips').prop('checked', true);
      // dashboard tips
      $('#dashTips').prop('checked', true);
      // messages tips
      $('#messageTips').prop('checked', true);
      ////////////////////////////// data model
      $('#datamodelTips').prop('checked', true);
      // model admin tips
      $('#modelAdminTips').prop('checked', true);
      // data dictionary tips
      $('#dictionaryTips').prop('checked', true);
      // object model tips
      $('#objectModelTips').prop('checked', true);
      // model designer tips
      $('#modeldesignerTips').prop('checked', true);
      // diagrams designer tips
      $('#diagramdesignerTips').prop('checked', true);
      // architecture system definition tips
      $('#systemDefTips').prop('checked', true);
      // architecture data flow tips
      $('#dataFlowTips').prop('checked', true);
      // atchitecture data flow tips
      $('#infrasTips').prop('checked', true);
      ////////////////////////////// data regulation
      $('#regulationTips').prop('checked', true);
      // classification definition tips
      $('#classifDefTips').prop('checked', true);
      // classification tips
      $('#classifTips').prop('checked', true);
      // rules tips
      $('#rulesTips').prop('checked', true);
      // dretention period tips
      $('#retentionTips').prop('checked', true);
      // drm security tips
      $('#securityTips').prop('checked', true);
      ////////////////////////////// data governance
      $('#govTips').prop('checked', true);
      // gov projects tips
      $('#projectTips').prop('checked', true);
      // gov entity tips
      $('#entityTips').prop('checked', true);
      // gov role tips
      $('#roleTips').prop('checked', true);
      // gov steward tips
      $('#stewardTips').prop('checked', true);
      // gov dimension tips
      $('#dimensionTips').prop('checked', true);
      // gov data lineage tips
      $('#lineageTips').prop('checked', true);
      // gov functional map tips
      $('#functionalmapTips').prop('checked', true);
      // gov glossary tips
      $('#glossaryTips').prop('checked', true);
      // gov catalog tips
      $('#catalogTips').prop('checked', true);
      // gov country tips
      $('#countryTips').prop('checked', true);
      ////////////////////////////// data lifecycle
      $('#lifecycleTips').prop('checked', true);
      // dlc campain tips
      $('#campainTips').prop('checked', true);
      ////////////////////////////// settings
      $('#settingsTips').prop('checked', true);
      // settings users tips
      $('#usersTips').prop('checked', true);
      // settings roles tips
      $('#rolesTips').prop('checked', true);
      // settings metadata tips
      $('#metadataTips').prop('checked', true);
    } else {
      tips = false;
      $('#mainTips').prop('checked', false);
      // search tips
      $('#searchTips').prop('checked', false);
      // dashboard tips
      $('#dashTips').prop('checked', false);
      // messages tips
      $('#messageTips').prop('checked', false);
      ////////////////////////////// data model
      $('#datamodelTips').prop('checked', false);
      // model admin tips
      $('#modelAdminTips').prop('checked', false);
      // data dictionary tips
      $('#dictionaryTips').prop('checked', false);
      // object model tips
      $('#objectModelTips').prop('checked', false);
      // model designer tips
      $('#modeldesignerTips').prop('checked', false);
      // diagrams designer tips
      $('#diagramdesignerTips').prop('checked', false);
      // architecture system definition tips
      $('#systemDefTips').prop('checked', false);
      // architecture data flow tips
      $('#dataFlowTips').prop('checked', false);
      // atchitecture data flow tips
      $('#infrasTips').prop('checked', false);
      ////////////////////////////// data regulation
      $('#regulationTips').prop('checked', false);
      // classification definition tips
      $('#classifDefTips').prop('checked', false);
      // classification tips
      $('#classifTips').prop('checked', false);
      // rules tips
      $('#rulesTips').prop('checked', false);
      // dretention period tips
      $('#retentionTips').prop('checked', false);
      // drm security tips
      $('#securityTips').prop('checked', false);
      ////////////////////////////// data governance
      $('#govTips').prop('checked', false);
      // gov projects tips
      $('#projectTips').prop('checked', false);
      // gov entity tips
      $('#entityTips').prop('checked', false);
      // gov role tips
      $('#roleTips').prop('checked', false);
      // gov steward tips
      $('#stewardTips').prop('checked', false);
      // gov dimension tips
      $('#dimensionTips').prop('checked', false);
      // gov country tips
      $('#countryTips').prop('checked', false);
      // gov data lineage tips
      $('#lineageTips').prop('checked', false);
      // gov functional map tips
      $('#functionalmapTips').prop('checked', false);
      // gov glossary tips
      $('#glossaryTips').prop('checked', false);
      // gov catalog tips
      $('#catalogTips').prop('checked', false);
      ////////////////////////////// data lifecycle
      $('#lifecycleTips').prop('checked', false);
      // dlc campain tips
      $('#campainTips').prop('checked', false);
      ////////////////////////////// settings
      $('#settingsTips').prop('checked', false);
      // settings users tips
      $('#usersTips').prop('checked', false);
      // settings roles tips
      $('#rolesTips').prop('checked', false);
      // settings metadata tips
      $('#metadataTips').prop('checked', false);
    }
    let dataPut = {
      username: getCookieValue('username'),
      allTips: tips
    };
    this.routerHistoryService.updateTipsAll(dataPut).subscribe(
      data => {
        // console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  setActiveTipsMain(ev) {
    let tips = true;
    if ($('#' + ev.target.id).is(':checked')) {
      tips = true;
      if ($('#mainTips').is(':checked') && $('#searchTips').is(':checked') && $('#dashTips').is(':checked') && $('#messageTips').is(':checked')
        && $('#datamodelTips').is(':checked') && $('#regulationTips').is(':checked') && $('#govTips').is(':checked')
        && $('#lifecycleTips').is(':checked') && $('#settingsTips').is(':checked')) {
        $('#allTips').prop('checked', true);
      }
    } else {
      tips = false;
      $('#allTips').prop('checked', false);
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tips
    };
    this.routerHistoryService.updateTipsMain(dataPut).subscribe(
      data => {
      },
      err => {
        console.error(err);
      }
    );
  }
  onUpdateTipsAnyMenu(ev, menu, sousmenu) {
    // console.log(menu);
    let tips = true;
    if ($('#' + ev.target.id).is(':checked')) {
      tips = true;
      if ((menu == 'Data Governance') && (sousmenu == 'all')) {
        $('#projectTips').prop('checked', true);
        $('#entityTips').prop('checked', true);
        $('#roleTips').prop('checked', true);
        $('#stewardTips').prop('checked', true);
        $('#dimensionTips').prop('checked', true);
        $('#countryTips').prop('checked', true);
        $('#lineageTips').prop('checked', true);
        $('#functionalmapTips').prop('checked', true);
        $('#glossaryTips').prop('checked', true);
        $('#catalogTips').prop('checked', true);
      } else if ((menu == 'Data Modeling') && (sousmenu == 'all')) {
        $('#modelAdminTips').prop('checked', true);
        $('#dictionaryTips').prop('checked', true);
        $('#objectModelTips').prop('checked', true);
        $('#modeldesignerTips').prop('checked', true);
        $('#diagramdesignerTips').prop('checked', true);
        $('#systemDefTips').prop('checked', true);
        $('#dataFlowTips').prop('checked', true);
        $('#infrasTips').prop('checked', true);
      } else if ((menu == 'Data Regulation') && (sousmenu == 'all')) {
        $('#classifDefTips').prop('checked', true);
        $('#classifTips').prop('checked', true);
        $('#rulesTips').prop('checked', true);
        $('#retentionTips').prop('checked', true);
        $('#securityTips').prop('checked', true);
      } else if ((menu == 'Data Lifecycle') && (sousmenu == 'all')) {
        $('#campainTips').prop('checked', true);
      } else if ((menu == 'Settings') && (sousmenu == 'all')) {
        $('#usersTips').prop('checked', true);
        $('#rolesTips').prop('checked', true);
        $('#metadataTips').prop('checked', true);
      }
      if ($('#projectTips').is(':checked') && $('#entityTips').is(':checked') && $('#roleTips').is(':checked') && $('#lineageTips').is(':checked') && $('#glossaryTips').is(':checked')
        && $('#stewardTips').is(':checked') && $('#dimensionTips').is(':checked') && $('#countryTips').is(':checked') && $('#functionalmapTips').is(':checked') && $('#catalogTips').is(':checked')) {
        $('#govTips').prop('checked', true);
      }
      if ($('#modelAdminTips').is(':checked') && $('#dictionaryTips').is(':checked') && $('#objectModelTips').is(':checked') && $('#modeldesignerTips').is(':checked')
        && $('#diagramdesignerTips').is(':checked') && $('#systemDefTips').is(':checked') && $('#dataFlowTips').is(':checked') && $('#infrasTips').is(':checked')) {
        $('#datamodelTips').prop('checked', true);
      }
      if ($('#classifTips').is(':checked') && $('#rulesTips').is(':checked') && $('#retentionTips').is(':checked')
        && $('#securityTips').is(':checked') && $('#classifDefTips').is(':checked')) {
        $('#regulationTips').prop('checked', true);
      }
      if ($('#campainTips').is(':checked')) {
        $('#lifecycleTips').prop('checked', true);
      }
      if ($('#usersTips').is(':checked') && $('#rolesTips').is(':checked') && $('#metadataTips').is(':checked')) {
        $('#settingsTips').prop('checked', true);
      }
      if ($('#mainTips').is(':checked') && $('#searchTips').is(':checked') && $('#dashTips').is(':checked') && $('#messageTips').is(':checked')
        && $('#datamodelTips').is(':checked') && $('#regulationTips').is(':checked') && $('#govTips').is(':checked')
        && $('#lifecycleTips').is(':checked') && $('#settingsTips').is(':checked')) {
        $('#allTips').prop('checked', true);
      }
    } else {
      if ((menu == 'Data Governance') && (sousmenu == 'all')) {
        $('#projectTips').prop('checked', false);
        $('#entityTips').prop('checked', false);
        $('#roleTips').prop('checked', false);
        $('#stewardTips').prop('checked', false);
        $('#dimensionTips').prop('checked', false);
        $('#countryTips').prop('checked', false);
        $('#lineageTips').prop('checked', false);
        $('#functionalmapTips').prop('checked', false);
        $('#glossaryTips').prop('checked', false);
        $('#catalogTips').prop('checked', false);
      } else if ((menu == 'Data Modeling') && (sousmenu == 'all')) {
        $('#modelAdminTips').prop('checked', false);
        $('#dictionaryTips').prop('checked', false);
        $('#objectModelTips').prop('checked', false);
        $('#modeldesignerTips').prop('checked', false);
        $('#diagramdesignerTips').prop('checked', false);
        $('#systemDefTips').prop('checked', false);
        $('#dataFlowTips').prop('checked', false);
        $('#infrasTips').prop('checked', false);
      } else if ((menu == 'Data Regulation') && (sousmenu == 'all')) {
        $('#classifDefTips').prop('checked', false);
        $('#classifTips').prop('checked', false);
        $('#rulesTips').prop('checked', false);
        $('#retentionTips').prop('checked', false);
        $('#securityTips').prop('checked', false);
      } else if ((menu == 'Data Lifecycle') && (sousmenu == 'all')) {
        $('#campainTips').prop('checked', false);
      } else if ((menu == 'Settings') && (sousmenu == 'all')) {
        $('#usersTips').prop('checked', false);
        $('#rolesTips').prop('checked', false);
        $('#metadataTips').prop('checked', false);
      }
      tips = false;
      if (menu == 'Data Governance') {
        $('#govTips').prop('checked', false);
      } if (((menu == 'Data Model') || (menu == 'Data Modeling') || (menu == 'Diagrams') || (menu == 'Architecture')) && (sousmenu != 'all')) {
        $('#datamodelTips').prop('checked', false);
      } if ((menu == 'Data Regulation') && (sousmenu != 'all')) {
        $('#regulationTips').prop('checked', false);
      } if ((menu == 'Data Lifecycle') && (sousmenu != 'all')) {
        $('#lifecycleTips').prop('checked', false);
      } if ((menu == 'Settings') && (sousmenu != 'all')) {
        $('#settingsTips').prop('checked', false);
      }
      $('#allTips').prop('checked', false);
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tips,
      dataMenu: menu,
      dataSousmenu: sousmenu
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
      },
      err => {
        console.error(err);
      }
    );
  }
  // get list of versioning
  onGetListTips() {
    // if (this.overlayOpen === false) {
    this.listTips = [];
    this.routerHistoryService.getListTips().subscribe(
      data => {
        // console.log(data);
        this.listTips = data[0];
        // console.log(this.listTips);
      },
      err => {
        console.error(err);
      },
      () => {
        this.onGetAllTipsDesc();
      }
    );
  }
  // get all tips descriptions format JSON
  onGetAllTipsDesc() {
    this.serviceTips.getAllTipsDesc().subscribe(
      result => {
        result.map(item => {
          switch (item.name) {
            case 'Main': {
              switch (item.typedesc) {
                case 'text': {
                  $('#textDescMain').css('color', 'blue');
                  $('#imgDescMain').css('color', 'black');
                  $('#vidDescMain').css('color', 'black');
                  break;
                }
                case 'image': {
                  $('#textDescMain').css('color', 'black');
                  $('#imgDescMain').css('color', 'blue');
                  $('#vidDescMain').css('color', 'black');
                  break;
                }
                case 'video': {
                  $('#textDescMain').css('color', 'black');
                  $('#imgDescMain').css('color', 'black');
                  $('#vidDescMain').css('color', 'blue');
                  break;
                }
              }
              break;
            }
            case 'Search': {
              switch (item.typedesc) {
                case 'text': {
                  $('#textDescSearch').css('color', 'blue');
                  $('#imgDescSearch').css('color', 'black');
                  $('#vidDescSearch').css('color', 'black');
                  break;
                }
                case 'image': {
                  $('#textDescSearch').css('color', 'black');
                  $('#imgDescSearch').css('color', 'blue');
                  $('#vidDescSearch').css('color', 'black');
                  break;
                }
                case 'video': {
                  $('#textDescSearch').css('color', 'black');
                  $('#imgDescSearch').css('color', 'black');
                  $('#vidDescSearch').css('color', 'blue');
                  break;
                }
              }
              break;
            }
            case 'Dashboard': {
              switch (item.typedesc) {
                case 'text': {
                  $('#textDescDash').css('color', 'blue');
                  $('#imgDescDash').css('color', 'black');
                  $('#vidDescDash').css('color', 'black');
                  break;
                }
                case 'image': {
                  $('#textDescDash').css('color', 'black');
                  $('#imgDescDash').css('color', 'blue');
                  $('#vidDescDash').css('color', 'black');
                  break;
                }
                case 'video': {
                  $('#textDescDash').css('color', 'black');
                  $('#imgDescDash').css('color', 'black');
                  $('#vidDescDash').css('color', 'blue');
                  break;
                }
              }
              break;
            }
            case 'Message': {
              switch (item.typedesc) {
                case 'text': {
                  $('#textDescMsg').css('color', 'blue');
                  $('#imgDescMsg').css('color', 'black');
                  $('#vidDescMsg').css('color', 'black');
                  break;
                }
                case 'image': {
                  $('#textDescMsg').css('color', 'black');
                  $('#imgDescMsg').css('color', 'blue');
                  $('#vidDescMsg').css('color', 'black');
                  break;
                }
                case 'video': {
                  $('#textDescMsg').css('color', 'black');
                  $('#imgDescMsg').css('color', 'black');
                  $('#vidDescMsg').css('color', 'blue');
                  break;
                }
              }
              break;
            }
          }
        });
        // this.listDescTipsWithName =
        // console.log(result);
      },
      err => {
        console.error(err);
      },
      () => {
        // all tips
        if (this.listTips['all'] == true) {
          $('#allTips').prop('checked', true);
        } else {
          $('#allTips').prop('checked', false);
        }
        // main tips
        if (this.listTips['main'] == true) {
          $('#mainTips').prop('checked', true);
        } else {
          $('#mainTips').prop('checked', false);
        }
        // search tips
        if (this.listTips['search'] == true) {
          $('#searchTips').prop('checked', true);
        } else {
          $('#searchTips').prop('checked', false);
        }
        // dashboard tips
        if (this.listTips['dash'] == true) {
          $('#dashTips').prop('checked', true);
        } else {
          $('#dashTips').prop('checked', false);
        }
        // messages tips
        if (this.listTips['message'] == true) {
          $('#messageTips').prop('checked', true);
        } else {
          $('#messageTips').prop('checked', false);
        }
        // all data model tips
        if (this.listTips['datModelAll'] == true) {
          $('#datamodelTips').prop('checked', true);
        } else {
          $('#datamodelTips').prop('checked', false);
        }
        // model admin tips
        if (this.listTips['modelAdmin'] == true) {
          $('#modelAdminTips').prop('checked', true);
        } else {
          $('#modelAdminTips').prop('checked', false);
        }
        // data dictionary tips
        if (this.listTips['dictionary'] == true) {
          $('#dictionaryTips').prop('checked', true);
        } else {
          $('#dictionaryTips').prop('checked', false);
        }
        // object model tips
        if (this.listTips['objectModel'] == true) {
          $('#objectModelTips').prop('checked', true);
        } else {
          $('#objectModelTips').prop('checked', false);
        }
        // model designer tips
        if (this.listTips['modelDesigner'] == true) {
          $('#modeldesignerTips').prop('checked', true);
        } else {
          $('#modeldesignerTips').prop('checked', false);
        }
        // diagrams designer tips
        if (this.listTips['diagramDesigner'] == true) {
          $('#diagramdesignerTips').prop('checked', true);
        } else {
          $('#diagramdesignerTips').prop('checked', false);
        }
        // architecture system definition tips
        if (this.listTips['systemDef'] == true) {
          $('#systemDefTips').prop('checked', true);
        } else {
          $('#systemDefTips').prop('checked', false);
        }
        // diagrams designer tips
        if (this.listTips['dataFlow'] == true) {
          $('#dataFlowTips').prop('checked', true);
        } else {
          $('#dataFlowTips').prop('checked', false);
        }
        // diagrams designer tips
        if (this.listTips['infras'] == true) {
          $('#infrasTips').prop('checked', true);
        } else {
          $('#infrasTips').prop('checked', false);
        }
        // all data regulation tips
        if (this.listTips['drmAll'] == true) {
          $('#regulationTips').prop('checked', true);
        } else {
          $('#regulationTips').prop('checked', false);
        }
        // classification definition tips
        if (this.listTips['drmDefinition'] == true) {
          $('#classifDefTips').prop('checked', true);
        } else {
          $('#classifDefTips').prop('checked', false);
        }
        // classification tips
        if (this.listTips['drmClassification'] == true) {
          $('#classifTips').prop('checked', true);
        } else {
          $('#classifTips').prop('checked', false);
        }
        // rules tips
        if (this.listTips['drmRules'] == true) {
          $('#rulesTips').prop('checked', true);
        } else {
          $('#rulesTips').prop('checked', false);
        }
        // dretention period tips
        if (this.listTips['drmRetention'] == true) {
          $('#retentionTips').prop('checked', true);
        } else {
          $('#retentionTips').prop('checked', false);
        }
        // drm security tips
        if (this.listTips['drmSecurity'] == true) {
          $('#securityTips').prop('checked', true);
        } else {
          $('#securityTips').prop('checked', false);
        }
        // all data governance tips
        if (this.listTips['govAll'] == true) {
          $('#govTips').prop('checked', true);
        } else {
          $('#govTips').prop('checked', false);
        }
        // gov projects tips
        if (this.listTips['govproject'] == true) {
          $('#projectTips').prop('checked', true);
        } else {
          $('#projectTips').prop('checked', false);
        }
        // gov entity tips
        if (this.listTips['goventity'] == true) {
          $('#entityTips').prop('checked', true);
        } else {
          $('#entityTips').prop('checked', false);
        }
        // gov role tips
        if (this.listTips['govrole'] == true) {
          $('#roleTips').prop('checked', true);
        } else {
          $('#roleTips').prop('checked', false);
        }
        // gov steward tips
        if (this.listTips['govsteward'] == true) {
          $('#stewardTips').prop('checked', true);
        } else {
          $('#stewardTips').prop('checked', false);
        }
        // gov dimension tips
        if (this.listTips['govdimension'] == true) {
          $('#dimensionTips').prop('checked', true);
        } else {
          $('#dimensionTips').prop('checked', false);
        }
        // gov country tips
        if (this.listTips['govcountry'] == true) {
          $('#countryTips').prop('checked', true);
        } else {
          $('#countryTips').prop('checked', false);
        }
        // gov lineage tips
        if (this.listTips['govlineage'] == true) {
          $('#lineageTips').prop('checked', true);
        } else {
          $('#lineageTips').prop('checked', false);
        }
        // gov functional map tips
        if (this.listTips['govfunctionalmap'] == true) {
          $('#functionalmapTips').prop('checked', true);
        } else {
          $('#functionalmapTips').prop('checked', false);
        }
        // gov glossary tips
        if (this.listTips['govglossary'] == true) {
          $('#glossaryTips').prop('checked', true);
        } else {
          $('#glossaryTips').prop('checked', false);
        }
        // gov catalog tips
        if (this.listTips['govcatalog'] == true) {
          $('#catalogTips').prop('checked', true);
        } else {
          $('#catalogTips').prop('checked', false);
        }
        // dlc campain tips
        if (this.listTips['campaign'] == true) {
          $('#campainTips').prop('checked', true);
          $('#lifecycleTips').prop('checked', true);
        } else {
          $('#campainTips').prop('checked', false);
          $('#lifecycleTips').prop('checked', false);
        }
        // all settings tips
        if (this.listTips['settingsAll'] == true) {
          $('#settingsTips').prop('checked', true);
        } else {
          $('#settingsTips').prop('checked', false);
        }
        // settings users tips
        if (this.listTips['users'] == true) {
          $('#usersTips').prop('checked', true);
        } else {
          $('#usersTips').prop('checked', false);
        }
        // settings roles tips
        if (this.listTips['roles'] == true) {
          $('#rolesTips').prop('checked', true);
        } else {
          $('#rolesTips').prop('checked', false);
        }
        // settings metadata tips
        if (this.listTips['metadata'] == true) {
          $('#metadataTips').prop('checked', true);
        } else {
          $('#metadataTips').prop('checked', false);
        }
        $('#settingAllTips').modal('show');
      }
    );
  }
  onUpdateTypeDescTipsPerName(type1, name1, event) {
    const data = {
      name: name1,
      type: type1
    };
    $(event.target.parentElement).css('opacity', 0.2);
    this.serviceTips.updateTypeDescTipsPerName(data).subscribe(
      res => {
        console.log(res);
      },
      err => {
        console.error(err);
      },
      () => {
        $(event.target.parentElement).css('opacity', 1);
        switch (name1) {
          case 'Main': {
            switch (type1) {
              case 'text': {
                $('#textDescMain').css('color', 'blue');
                $('#imgDescMain').css('color', 'black');
                $('#vidDescMain').css('color', 'black');
                break;
              }
              case 'image': {
                $('#textDescMain').css('color', 'black');
                $('#imgDescMain').css('color', 'blue');
                $('#vidDescMain').css('color', 'black');
                break;
              }
              case 'video': {
                $('#textDescMain').css('color', 'black');
                $('#imgDescMain').css('color', 'black');
                $('#vidDescMain').css('color', 'blue');
                break;
              }
            }
            break;
          }
          case 'Search': {
            switch (type1) {
              case 'text': {
                $('#textDescSearch').css('color', 'blue');
                $('#imgDescSearch').css('color', 'black');
                $('#vidDescSearch').css('color', 'black');
                break;
              }
              case 'image': {
                $('#textDescSearch').css('color', 'black');
                $('#imgDescSearch').css('color', 'blue');
                $('#vidDescSearch').css('color', 'black');
                break;
              }
              case 'video': {
                $('#textDescSearch').css('color', 'black');
                $('#imgDescSearch').css('color', 'black');
                $('#vidDescSearch').css('color', 'blue');
                break;
              }
            }
            break;
          }
          case 'Dashboard': {
            switch (type1) {
              case 'text': {
                $('#textDescDash').css('color', 'blue');
                $('#imgDescDash').css('color', 'black');
                $('#vidDescDash').css('color', 'black');
                break;
              }
              case 'image': {
                $('#textDescDash').css('color', 'black');
                $('#imgDescDash').css('color', 'blue');
                $('#vidDescDash').css('color', 'black');
                break;
              }
              case 'video': {
                $('#textDescDash').css('color', 'black');
                $('#imgDescDash').css('color', 'black');
                $('#vidDescDash').css('color', 'blue');
                break;
              }
            }
            break;
          }
          case 'Message': {
            switch (type1) {
              case 'text': {
                $('#textDescMsg').css('color', 'blue');
                $('#imgDescMsg').css('color', 'black');
                $('#vidDescMsg').css('color', 'black');
                break;
              }
              case 'image': {
                $('#textDescMsg').css('color', 'black');
                $('#imgDescMsg').css('color', 'blue');
                $('#vidDescMsg').css('color', 'black');
                break;
              }
              case 'video': {
                $('#textDescMsg').css('color', 'black');
                $('#imgDescMsg').css('color', 'black');
                $('#vidDescMsg').css('color', 'blue');
                break;
              }
            }
            break;
          }
        }
      }
    );
  }
  changeSidebarColorLight() {
    $('body').removeClass('menu_dark logo-black').addClass('menu_light logo-white');
    // switch logo dark
    $('#logo_switch').attr('src', 'assets/images/' + this.blackLogo);
    localStorage.setItem('choose_logoheader', 'logo-white');
    localStorage.setItem('menu_option', 'menu_light');
  }
  changeSidebarColorDark() {
    $('body').removeClass('menu_light logo-white').addClass('menu_dark logo-black');
    // switch logo light
    $('#logo_switch').attr('src', 'assets/images/' + this.logo);
    localStorage.setItem('choose_logoheader', 'logo-black');
    localStorage.setItem('menu_option', 'menu_dark');
  }
  changeLightTheme() {
    $("body").removeClass("dark submenu-closed menu_dark logo-black");
    $("body").addClass("light submenu-closed menu_light logo-white");
    $('#logo_switch').attr('src', 'assets/images/' + this.blackLogo);
    var theme = "light";
    var menu_option = "menu_light";
    localStorage.setItem("choose_logoheader", "logo-white");
    localStorage.setItem("choose_skin", "theme-black");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menu_option", menu_option);
  }
  changeDarkTheme() {
    $("body").removeClass("light submenu-closed menu_light logo-white");
    $("body").addClass("dark submenu-closed menu_dark logo-black");
    $('#logo_switch').attr('src', 'assets/images/' + this.logo);
    var theme = "dark";
    var menu_option = "menu_dark";
    localStorage.setItem("choose_logoheader", "logo-black");
    localStorage.setItem("choose_skin", "theme-black");
    localStorage.setItem("theme", theme);
    localStorage.setItem("menu_option", menu_option);
  }
  setStartupStyles() {
    //set theme on startup
    if (localStorage.getItem("theme")) {
      $("body").removeClass("dark light");
      $("body").addClass(localStorage.getItem("theme"));
    }

    // set dark sidebar menu on startup
    if (localStorage.getItem("menu_option")) {
      $("body").addClass(localStorage.getItem("menu_option"));


      if (localStorage.getItem("menu_option") === 'menu_light') {
        $('#logo_switch').attr('src', 'assets/images/' + this.blackLogo);

      } else $('#logo_switch').attr('src', 'assets/images/' + this.logo);
    } else { $("body").addClass("menu_dark"); }

    // set header color on startup
    if (localStorage.getItem("choose_skin")) {
      $("body").addClass(localStorage.getItem("choose_skin"));
    } else {
      $("body").addClass("theme-black");
    }
    if (localStorage.getItem("choose_skin_active")) {
      $(".right-sidebar .demo-choose-skin li").each(function (index) {
        $(this).removeClass("actived");
        if (
          $(this).attr("data-theme") ==
          localStorage.getItem("choose_skin_active")
        ) {
          $(this).addClass("actived");
        }
      });
    }
    // set logo color on startup
    if (localStorage.getItem("choose_logoheader")) {
      $("body").addClass(localStorage.getItem("choose_logoheader"));

    } else {
      $("body").addClass("logo-black");
    }
    if (localStorage.getItem("choose_logoheader_active")) {
      $(".right-sidebar .demo-choose-logoheader li").each(function (index) {
        $(this).removeClass("actived");
        if (
          $(this).attr("data-theme") ==
          localStorage.getItem("choose_logoheader_active")
        ) {
          $(this).addClass("actived");
        }
      });
    }
  }

  ngAfterViewInit(): void {
    this.setStartupStyles();
    $('#switchEnv').prop('checked', false);
  }

  changeUrl(event) {
    const checked = $(event.target).prop('checked');
    if (checked) {
      Swal.fire({
        title: this.lang == 'en' ? 'Redirect to DRI' : 'Redirection vers Informations',
        text: this.lang == 'en' ? 'You are about to be redirected to Information.' : 'Vous êtes sur le point d\'être redirigé vers Information.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang == 'en' ? 'Yes' : 'Oui',
        cancelButtonText: this.lang == 'en' ? 'No, cancel' : 'Non, annuler'
      }).then((result) => {
        if (result.value) {
          window.location.href = environment.envVar.DRI_URL;
        } else {
          $('#switchEnv').prop('checked', false);
        }
      });
      // window.location.href = environment.envVar.DRI_URL;
    }
  }
}
