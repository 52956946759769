export class GlossaryItem {
  glossary: string;
  functionalObject: string;
  moduleGroup: string;
  businessObject: string;
  businessTerm: string;
  glossaryId: string;
  functionalObjectId: string;
  moduleGroupId: string;
  businessObjectId: string;
  businessTermId: string;
  group: number;
  id:string;

  constructor(glossary: string, functionalObject: string, moduleGroup: string, businessObject: string, businessTerm: string, glossaryId: string, functionalObjectId: string, moduleGroupId: string, businessObjectId: string, businessTermId: string, group: number, id: string) {
    this.glossary = glossary;
    this.functionalObject = functionalObject;
    this.moduleGroup = moduleGroup;
    this.businessObject = businessObject;
    this.businessTerm = businessTerm;
    this.glossaryId = glossaryId;
    this.functionalObjectId = functionalObjectId;
    this.moduleGroupId = moduleGroupId;
    this.businessObjectId = businessObjectId;
    this.businessTermId = businessTermId;
    this.group = group;
    this.id = id;
  }
}
