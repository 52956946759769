import {ArchiveIdJson} from './ArchiveIdJson';


export class ArchiveJson {
  name: string;
  description: string;
  versionAdmin: boolean;
  archiveIdJson: ArchiveIdJson[];

  constructor(name: string, description: string, versionAdmin, archiveIdJson: ArchiveIdJson[]) {
    this.name = name;
    this.description = description;
    this.archiveIdJson = archiveIdJson;
    this.versionAdmin = versionAdmin;
  }
}
