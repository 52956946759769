import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SidebarModule} from 'ng-sidebar';
import { DdtmRoutingModule } from './ddtm-routing.module';
import { DdtmComponent} from './mainDdtm/ddtm.component';
import { LoadersddtmComponent} from '../loaders/loadersddtm/loadersddtm.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComparaisonComponent } from './comparaison/comparaison.component';
import {ReportsDetailsUserDdtmComponent} from "./mainDdtm/reports-details-user_ddtm/reports-details-user-ddtm.component";
import { VizComponent } from './viz/viz.component';
import { OwnerChartComponent } from './owner-chart/owner-chart.component';
import { SizeChartComponent } from './size-chart/size-chart.component';
import { WordCloudComponent } from './word-cloud/word-cloud.component';
import { SharedModule } from '../shared/shared.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderFactory} from '../app.module';

@NgModule({
  declarations: [DdtmComponent, LoadersddtmComponent, ComparaisonComponent, ReportsDetailsUserDdtmComponent, VizComponent, OwnerChartComponent, SizeChartComponent, WordCloudComponent],
  imports: [
    CommonModule,
    DdtmRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DropzoneModule,
    SidebarModule.forRoot(),
    SharedModule,
    TranslateModule
  ]
})
export class DdtmModule { }
