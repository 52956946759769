import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
@Injectable({
    providedIn: 'root'
})
export class ReportService {
  public reportsChart1: any;
  public reportsChart2: any;
    public reports: any ;
  public reportsPie: any;
    public reportsDash: any ;
  public repsDashDf: any ;

  // Options headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
	OptionFiles()  {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
	};
  // test = 'https://1de7e37d.dm.systnaps.com/sight/ddtm-api/web';
  // test = 'https://39576b66.ddtm.systnaps.com/ddtm-api/web';
  // test = 'http://localhost:8000';
  // test = 'http://54.36.121.107:5678';
    // transfer params from list-app to report-details
    private paramsSource = new BehaviorSubject([]);
    currentparams = this.paramsSource.asObservable();
    constructor(private http: HttpClient, private router: Router) { }
    // list application approved for report detail user ddtm
    getApplicationList(): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/report/getApplicationList/' + localStorage.getItem('userId'), this.getOptions());
    }

    getListApplication(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/report/list/getListByApplication/' + localStorage.getItem('userId'), this.getOptions());
    }

    // Pie model admin: Percentage of metadata reports per application model administration
    getStatisticalByApplication() {
      let promise = new Promise<any>((resolve, reject) => {
        let apiURL = Shared.API_URL + '/api/report/statistical/getStatisticalByApplication/' + localStorage.getItem('userId');
        this.http.get<any>(apiURL, this.getOptions())
          .toPromise()
          .then(
            res => {
              this.reportsPie = res;
              resolve( this.reportsPie);
            }
          );
      });
      return promise;
    }
  // Number of metadata report by application and environment model administration
    getApplicationByEnv() {
        let promise = new Promise<any>((resolve, reject) => {
            let apiURL = Shared.API_URL + '/api/report/statistical/getStatisticalDistinctEnvByApp/' + localStorage.getItem('userId');
            this.http.get<any>(apiURL, this.getOptions())
                .toPromise()
                .then(
                    res => {
                        this.reports = res;
                        resolve( this.reports);
                    }
                );
        });
        return promise;
    }
    // reports -> DDTM -> select DB ->details all
    getStatisticReportByStatusEnv(apps, typeDatabase, environment): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/report/getStatisticReportByStatusEnv/' + apps + '/'
            + typeDatabase + '/' + environment, this.getOptions());
    }
    // model administration -> select DB ->details Report
    getReportByAppTypeDbEnv(apps, typeDatabase, environment): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/report/getReportByAppAndTypeDbAndEnv/' + localStorage.getItem('userId') + '/' + apps + '/' + typeDatabase +
            '/' + environment, this.getOptions());
    }
  // test before Approve => model administration if exist version Approved -> Archive
  testMsBeforeApprove(reportName: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/report/testMsBeforeApprove/' + reportName, this.getOptions());
  }
    // report -> DDTM -> select DB ->details Report -> change status (approved/archived/drafted)
    setReportChangeStatus(id: string, status: string): Observable<any> {
      let dataJson = {
        username: getCookieValue('username'),
        userId: localStorage.getItem('userId'),
        idReport: id,
        statusReport: status
      };
      const data = "data=" + JSON.stringify(dataJson);
      return this.http.post<any>(Shared.API_URL + '/api/report/update', data, this.getOptions());
    }
  // report -> DDTM -> select DB ->details Report -> change description by report Name
  updateDescriptionReportName(reportname: string, description: string): Observable<any> {
    var username = getCookieValue('username');
    return this.http.get<any>(Shared.API_URL + '/api/report/updateDescriptionReportName/' + username + '/' + reportname + '/' + description, this.getOptions());
  }
    // report -> DDTM -> select DB ->details Report -> remove report
    // setRemoveReport(id, application): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/report/remove/' + id + '/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
    // }
    // transfer params from list-app to report-details
    // changeMessage(params: string[]) {
    //     this.paramsSource.next(params);
    // }
    // mise à jour counTable (report/reportDetail)
    // setCountTablesForAllReports(): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/uploads/setCountTablesForAllDictionarys', this.getOptions());
    // }
    // mise à jour counEmptyTable (report/reportDetailUser)
    // setCountEmptyTablesForAllReports(): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/uploads/setCountEmptyTablesForAllDictioanrys', this.getOptions());
    // }
    // mise à jour counNotTable (report/reportDetailUser)
    // setCountNotEmptyTablesForAllReports(): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/uploads/setCountNotEmptyTablesForAllDictioanrys', this.getOptions());
    // }
    // mise à jour count fields by recordName and report (ddtm - top volumetric fields)
    setCountFieldsByRecordName(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/report/setCountFieldsByRecordName', this.getOptions());
    }
    // report -> DDTM -> select DB ->details Report user ( pour séparer mode admin et mode user et éliminer les rapport DRAFT)
    getReportByAppTypeDbEnvUser(apps, typeDatabase, environment,selectedDimensions): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/report/getReportByUser/' + apps + '/' + typeDatabase +
            '/' + environment +'/' + selectedDimensions, this.getOptions());
    }
    // alimentation charts (report/envi/typedatabase
    getApplicationByReport(envi: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/report/getApplicationByReport/' + localStorage.getItem('userId') + '/' + envi, this.getOptions());
    }
    // get single application report detail user
    getReportByOneApplication(application: string,selectedDimensions): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/report/list/getReportByOneApplication/' + application + '/' + selectedDimensions, this.getOptions());
    }
    // redirection reportDetail1/2
    // redirectionReportDetails() {
    //     if (this.router.url === '/reports/reports') {
    //         this.router.navigate(['reports1/reports']);
    //     } else {
    //         this.router.navigate(['reports/reports']);
    //     }
    // }

    // mode user checkbox checked report : alimentation des charts
    getDataSetChartsSelectedReportsModeUser(application: string[]): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report/getDataSetChartsSelectedReportsModeUser/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
    }
    // mode user checkbox changed report : update etatSelectUser 0|1
    setSelctReportModeUser(id: string, etat: number, application: string): Observable<any> {
      return this.http.get(Shared.API_URL + '/api/userreport/setSelectedReportUser/' + localStorage.getItem('userId') + '/' + id + '/' + etat + '/' + application, this.getOptions());
    }
     // mode user persist report project :  etatSelectUser 1
    setSelectedReportProject(id: string, application: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/userreport/setSelectedReportProject/' + localStorage.getItem('userId') + '/' + id + '/'  + application, this.getOptions());
    }

    // get count report selected by user and application
    getCountSelectedReportByUser(application: string): Observable<any> {
      return this.http.get(Shared.API_URL + '/api/report/getCountSelectedReportByUser/' + localStorage.getItem('userId') + '/'  + localStorage.getItem('reportDetailApplication'), this.getOptions());
    }
    // check selected version of report after redirection bridge
  checkSelectedVersionReport(reportName: string): Observable<any> {
      return this.http.get(Shared.API_URL + '/api/report/checkSelectedVersionReport/' + localStorage.getItem('userId') + '/'  + reportName, this.getOptions());
    }
    // Object Model

    getList(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getList', this.getOptions());
    }
    // liste des application and model in role ObjectModelRole for model dashboard
    getModelByApplication(): Observable<any> {
        let userId = localStorage.getItem('userId');
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getModelByApplication/' + userId, this.getOptions());
    }
    // liste des application in role AdminRole for model administration
  getApplicationsForAdministration(user): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model/getApplicationsForAdministration/' + user, this.getOptions());
    }
  // chart top 10 model dashboard
  getTopModelByApplicationDash(application: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getTopModelByApplicationDash/' + application + '/' + localStorage.getItem('userId'), this.getOptions());
  }
    // chart top 10 model  model administration
    getTopModelByApplication(application: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getTopModelByApplication/' + application + '/' + localStorage.getItem('userId'), this.getOptions());
    }
    // liste des models by selected application in dashboard or model addministration
    getModelsByStatusDash(data: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getModelsByStatusDash/' + data + '/' + localStorage.getItem('userId'), this.getOptions());
    }
    // liste des models by selected application in dashboard or model addministration
    getModelsByStatusAdmin(data: string): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model/getModelsByStatusAdmin/' + data + '/' + localStorage.getItem('userId'), this.getOptions());
    }
    // get selected models by application
    getModelsListBy(data: any, dash: string, application): Observable<any> {
        // return this.http.get<any>(Shared.API_URL + '/api/object-model/getList?code=' + data, this.getOptions());
      return this.http.get<any>(Shared.API_URL + '/api/object-model/getModelDistinctByCodeList/' + data + '/' + dash + '/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
    }
    // get filtred models by application DASHBOARD
    getFiltredModelsListByApplicationDash(data: any): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/modelByApplicationListDash/' + data + '/' + localStorage.getItem('userId'), this.getOptions());
    }
  // get filtred models by application MODEL ADMINISTRATION
  modelByApplicationListAdmin(data: any): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/modelByApplicationListAdmin/' + data + '/' + localStorage.getItem('userId'), this.getOptions());
  }

  updateDescriptionModelName(code: string, description: any): Observable<any> {
      let username = getCookieValue('username');
      return this.http.get<any>(Shared.API_URL + '/api/object-model/updateDescriptionModelName/' + username + '/' + code + '/' + description, this.getOptions());
    }
    removeModel(id: string): Observable <any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/remove/' + id, this.getOptions());

    }
    // refreshModel() {
    //     if (this.router.url === '/reports/reports') {
    //         this.router.navigate(['reports/reports1']);
    //     } else {
    //         this.router.navigate(['reports/reports']);
    //     }
    // }
    // uploadHtmlFiles(data): Observable<any> {
    //   let formData = new FormData();
    //   formData.append('file',data);
    //   return this.http.post<any>(Shared.API_URL + '/api/object-model/insert', formData, this.OptionFiles());
    // }
    // uploadJsonFiles(data): Observable<any> {
    //     let formData = new FormData();
    //     let username = getCookieValue('username');
    //     formData.append('file', data);
    //     return this.http.post<any>(Shared.API_URL + '/api/object-model/insertJson/' + username, formData, this.OptionFiles());
    // }
    // uploadReportFiles(data): Observable<any> {
    //   let username = getCookieValue('username');
    //   let formData = new FormData();
    //   formData.append('file',data);
    //   return this.http.post<any>(Shared.API_URL + '/api/database/upload-report/' + username, formData, this.OptionFiles());
    // }

	// zone dashboard
  getChartStatRepByAppDash() {

    let promise = new Promise<any>((resolve, reject) => {
      let apiURL = Shared.API_URL + '/api/dashboard/getChartStatRepByAppDash/' + localStorage.getItem('userId');
      this.http.get<any>(apiURL, this.getOptions())
        .toPromise()
        .then(
          res => {
            this.reportsChart2 = res;
            resolve( this.reportsChart2);
          }
        );
    });
    return promise;
  }
  getChartRepByAppEnvDash() {

    let promise = new Promise<any>((resolve, reject) => {
      let apiURL = Shared.API_URL + '/api/dashboard/getChartRepByAppEnvDash/' + localStorage.getItem('userId');
      this.http.get<any>(apiURL, this.getOptions())
        .toPromise()
        .then(
          res => {
            this.reportsChart1 = res;
            resolve( this.reportsChart1);
          }
        );
    });
    return promise;
  }
  getReportByApplicationDash(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/dashboard/getReportByApplicationDash/' + localStorage.getItem('userId'), this.getOptions());
  }
  getLastReportsByTypebaseDash() {
    let promise = new Promise<any>((resolve, reject) => {
      let apiURL = Shared.API_URL + '/api/dashboard/getLastReportsByTypebaseDash/' + localStorage.getItem('userId');
      this.http.get<any>(apiURL, this.getOptions())
        .toPromise()
        .then(
          res => {
            this.repsDashDf = res;
            resolve( this.repsDashDf);
          }
        );
    });
    return promise;
  }

  getReportByAppBaseEnv(d: any[]) {
    const data = "data=" + JSON.stringify(d);
    let promise = new Promise<any>((resolve, reject) => {
      let apiURL = Shared.API_URL + '/api/dashboard/getReportByAppBaseEnv';
      this.http.post<any>(apiURL, data, this.getOptions())
        .toPromise()
        .then(
          res => {
            this.reportsDash = res;
            resolve( this.reportsDash);
          }
        );
    });
    return promise;
  }
  // fin zone dashboard
  /*********************Comparaison****************************/
  // add new fields will be used in compare
  // addNewFieldsForCompare(): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/report/addNewFieldsForCompare', this.getOptions());
  // }
  // add object joins model tree
  // addObjectsJoinsTree(): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/report/addObjectsJoinsTree', this.getOptions());
  // }
  // launch trigger model structure
  // launchTrigger(): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/report/launchTrigger', this.getOptions());
  // }
  /** zone data transfert **/
  // checkAppa(name: any): Observable <any> {
  //     return this.http.get<any>(Shared.API_URL + '/api/application/checkApp/' + name, this.getOptions());
  //
  // }
//     checkApp(d): Observable<any> {
//         const data = "data=" + d;
// // console.log(d);
//         return this.http.post(Shared.API_URL + '/api/application/checkApp', data, this.getOptions());
//
//     }
//     addUploadedApp(d): Observable<any> {
//         const data = "data=" + d;
// // console.log(d);
//         return this.http.post(Shared.API_URL + '/api/application/addUploadedApp', data, this.getOptions());
//
//     }

  resetApps(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/application/resetApps' , this.OptionFiles());
  }
  /** end data transfert **/
  getMenu(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/dashboard/getMenu/' + localStorage.getItem('userId'), this.getOptions());
  }
  refreshComponent(router: Router){
    let currentUrl = router.url;
    router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      router.navigate([currentUrl]);
    });
  }
  uploadArchiveId(/*archids: archid[]*/sql, ids: string, base: string) {
    let preData = {sql, ids, base};
    const data = "data=" + JSON.stringify(preData);
    return this.http.post(Shared.API_URL + '/api/object-model/uploadArchiveId', data, this.getOptions());
  }
  uploadArchiveCand(/*archCands: archCand[]*/sql, ids, base) {
    let preData = {sql, ids, base};
    const data = "data=" + JSON.stringify(preData);
    return this.http.post(Shared.API_URL + '/api/object-model/uploadArchiveCand', data, this.getOptions());
  }
  uploadArchiveLog(/*archLogs: archLog[]*/sql, ids, base) {
    let preData = {sql, ids, base};
    const data = "data=" + JSON.stringify(preData);
    return this.http.post(Shared.API_URL + '/api/object-model/uploadArchiveLog', data, this.getOptions());
  }
  uploadArchivePurge(/*archLogs: archPurge[]*/sql, ids, base) {
    let preData = {sql, ids, base};
    const data = "data=" + JSON.stringify(preData);
    return this.http.post(Shared.API_URL + '/api/object-model/uploadArchivePurge' , data, this.getOptions());
  }
  // model administration get list reportName to model for delete (by application)
  // getlistReportNameByApplication(app: string): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/report/getlistReportNameByApplication/' + app , this.OptionFiles());
  // }

  // new --------
  // model administration (model structure) get list ids version by reportName (checked all) to Approve
  getAllReportVersionIdByNameToApprove(reportName: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/report/getAllReportVersionIdByNameToApprove/' + reportName , this.getOptions());
  }
  // model administration (model structure) get list ids version by reportName (checked all) to Archive
  getAllReportVersionIdByNameToArchive(reportName: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/report/getAllReportVersionIdByNameToArchive/' + reportName , this.getOptions());
  }
  // model administration (model structure) get list ids version by reportName (checked all) to Delete
  getAllReportVersionIdByNameToDelete(reportName: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/report/getAllReportVersionIdByNameToDelete/' + reportName , this.getOptions());
  }
  // model administration (model structure) get list ids version by reportName (checked all) to Remove
  getAllReportVersionIdByNameToRemove(reportName: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/report/getAllReportVersionIdByNameToRemove/' + reportName , this.getOptions());
  }

// model administration APPROVE list reportName (by application)
  approveMultipleByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/approveMultipleByListIdsReport', data, this.getOptions());
  }
  // model administration ARCHIVE list reportName (by application)
  archiveMultipleByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/archiveMultipleByListIdsReport', data, this.getOptions());
  }
  // model administration DELETE list reportName (by application)
  deleteMultipleByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/deleteMultipleByListIdsReport', data, this.getOptions());
  }
  // test before REMOVE => model administration REMOVE list reportName (by application)
  testSelectedReportsIfUsed(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/testSelectedReportsIfUsed', data, this.getOptions());
  }
  // model administration REMOVE removeMultipleReportDetailsByListIdsReport (by application)
  removeMultipleReportDetailsByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/removeMultipleReportDetailsByListIdsReport', data, this.getOptions());
  }
  // model administration REMOVE removeMultipleReportDetailsCountByListIdsReport (by application)
  removeMultipleReportDetailsCountByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/removeMultipleReportDetailsCountByListIdsReport', data, this.getOptions());
  }
  // model administration REMOVE removeMultipleReportObjectByListIdsReport (by application)
  removeMultipleReportObjectByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/removeMultipleReportObjectByListIdsReport', data, this.getOptions());
  }
  // model administration REMOVE removeMultipleReportObjectFiltredByListIdsReport (by application)
  removeMultipleReportObjectFiltredByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/removeMultipleReportObjectFiltredByListIdsReport', data, this.getOptions());
  }
  // model administration REMOVE removeMultipleReportDetailFiltredByListIdsReport (by application)
  removeMultipleReportDetailFiltredByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/removeMultipleReportDetailFiltredByListIdsReport', data, this.getOptions());
  }
  // model administration REMOVE list reportName (by application)
  removeMultipleByListIdsReport(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/report/removeMultipleByListIdsReport', data, this.getOptions());
  }



  setTaskObjectPurge(d): Observable<any> {
    const data = "data=" + JSON.stringify(d);
    return this.http.post<any>(Shared.API_URL + '/api/data-life-cycle-role/setTaskObjectPurge', data , this.getOptions());
  }
  setTaskObjectArch(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/setTaskObjectArch', this.getOptions());
  }
  setTaskObjectCand(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/setTaskObjectCand', this.getOptions());
  }
  /***Report Dimensions***/
  addReportDimensions(params): Observable<any> {
    const data = "data=" + JSON.stringify(params);
        return this.http.post(Shared.API_URL + '/api/report/addReportDimensions', data, this.getOptions());
   }
  getReportIdsForDimensions(): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report/getReportIdsForDimensions', this.getOptions());
   }
  // getReportDimensionUpload(): Observable<any> {
  //   return this.http.get(Shared.API_URL + '/api/report/getReportDimensionUpload', this.getOptions());
  // }
  getReportPerDimension(application: string[]): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/report/getReportPerDimension/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
  }
  /*End Report Dimensions***/
  /* set dimension to Object Model */
  // getModelIdsForDimensions(): Observable<any> {
  //   return this.http.get(Shared.API_URL + '/api/object-model/getModelIdsForDimensions', this.getOptions());
  // }
  addDimensionsToModels(params): Observable<any> {
    const data = 'data=' + JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/object-model/addDimensionsToModels', data, this.getOptions());
  }
  /* end set dimension to Object Model */

  // reset all pending model after upload (cancel added dimension)
  // resetAllPendingModel(): Observable<any> {
  //   return this.http.get(Shared.API_URL + '/api/object-model/resetAllPendingModel', this.getOptions());
  // }
  /***Owner Report Upload***/
  // getOwnerReportUpload(): Observable<any> {
  //   return this.http.get(Shared.API_URL + '/api/report/getOwnerReportUpload/'+ localStorage.getItem('userId'), this.getOptions());
  // }
  /***End Owner Report Upload***/
  // getEntities(): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/object-model/getEntities'  , this.getOptions());
  // }
  updateArchive(): Observable<any> {
  return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/updateArchive'  , this.getOptions());
}
}


