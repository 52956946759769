import {Shared} from "../shared";

declare const mxEvent: any;
export class MxIconSet {
  private _images: HTMLImageElement[];

  constructor(state: any, editTip, deleteTip) {
    this._images = [];
    const graph = state.view.graph;
    const that = this;

    // Icon1
    let img = mxUtils.createImage(Shared.ASSETS + '/designer/images/edit24.png');
    img.setAttribute('title', editTip);
    this.initIcon(img, state);
    img.style.left = (state.x + state.width ) + 'px';
    img.style.top = (state.y - 30) + 'px';


    state.view.graph.container.appendChild(img);
    this._images.push(img);

    // Delete
    img = mxUtils.createImage(Shared.ASSETS + '/designer/images/delete24.png');
    img.setAttribute('title', deleteTip);
    this.initIcon(img, state);
    img.style.left = (state.x - 16) + 'px';
    img.style.top = (state.y - 30) + 'px';

    // mxEvent.addGestureListeners(img,
    //   mxUtils.bind(this, function(evt: MouseEvent) {
    //     // Disables dragging the image
    //     mxEvent.consume(evt);
    //   })
    // );

    state.view.graph.container.appendChild(img);
    this._images.push(img);
  }

  public destroy(): void {
    if (this._images != null) {
      for (let i = 0; i < this._images.length; i++) {
        const img = this._images[i];
        img.parentNode.removeChild(img);
      }
    }

    this._images = null;
  }
 public initIcon(img, state){
    img.style.position = 'absolute';
    img.style.cursor = 'pointer';
    img.style.width = '24px';
    img.style.height = '24px';
   img.setAttribute('class', 'mxIconLg');
   mxEvent.addGestureListeners(img,
     mxUtils.bind(this, function(evt: MouseEvent) {
       // Disables dragging the image
       mxEvent.consume(evt);
     })
   );
  }

 public getImages(): HTMLImageElement[] {
    return this._images;
  }
}
