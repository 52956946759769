import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, Subject} from "rxjs";
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
/**
 * This service is a dimension store
 *
 * See {@link Shared} for details about the main data of this store
 */
@Injectable({
  providedIn: 'root'
})
export class DimensionService {
/**
 * This option file for service
 *
 * See {@link Shared} for details about the main data of this store
 */
  OptionFiles()  {
    return {
        headers: new HttpHeaders({
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            // 'Accept': 'Application/json'
        })
    };
};
/**
 * This option file for service
 *
 * See {@link Shared} for details about the main data of this store
 */
Options = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
        //  'Accept': 'Application/json'
    })
};
headerss = new Headers({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + getCookieValue('tokendrd')
});
constructor(private http: HttpClient, private router: Router) { }
getListDimension(): Observable<any> {
  const id = window.localStorage.getItem('userId');
  return this.http.get<any>(Shared.API_URL +  `/api/dimension/getListDimension/${id}`, this.Options);
}
getListElement(type:string): Observable<any> {
  
  return this.http.get<any>(Shared.API_URL +  `/api/dimension/getListElement/${type}`, this.Options);
}
getHolders() : Observable<any> {
  return this.http.get<any>(Shared.API_URL + '/api/holder/getHolders/' + localStorage.getItem('userId') , this.Options);
}
getCircleMap(): Observable<any> {

  return this.http.get(Shared.API_URL +  `/api/dimension/getCircleMap`,  this.Options);

}

deleteDimension(id:string):Observable<any> {
  return this.http.get<any>(Shared.API_URL + `/api/dimension/deleteDimension/${id}`, this.Options);

}
updateDimension(d): Observable<any>{
  let formData = new FormData();
  formData.append('data', d);

  return this.http.post(Shared.API_URL + '/api/dimension/updateDimension' , formData,  this.OptionFiles() );


}
addDimension(d): Observable<any>{
  let formData = new FormData();
    formData.append('data', d);
// console.log(d);
  return this.http.post(Shared.API_URL + '/api/dimension/addDimension' , formData, this.OptionFiles() );


}
// update data by import fron excel
setUpdatexlxs(d): Observable<any>{
  let formData = new FormData();
  formData.append('data', d);
  return this.http.post(Shared.API_URL + '/api/dimension/addxlsDimensions' , formData, this.OptionFiles() );
}
}
