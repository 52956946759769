import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import {NodeItem} from '../../data-lineage/NodeItem';
import {DataLineageService} from '../../services/data-lineage/data-lineage.service';
import { ColorEvent } from 'ngx-color';

import {formatDate} from '@angular/common';

import {ITreeOptions, KEYS, TREE_ACTIONS} from '@circlon/angular-tree-component';
import {CryptoService} from '../../crypto.service';
import {ActivatedRoute, Router} from '@angular/router';
import { renderTreeLine } from 'src/app/data-lineage/TreeChartDataLine';
import {ExtraItem} from '../../data-lineage/ExtraItem';
import {BusinessTermService} from '../../services/business-term/business-term.service';
import {BusinessItem} from './BusinessItem';
import {BusinessObject} from './BusinessObject';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const LeaderLine: any;
declare const PlainDraggable: any;
declare const swal: any;
declare const CKEDITOR: any;
@Component({
  selector: 'app-data-lineage-term',
  templateUrl: './data-lineage-term.component.html',
  styleUrls: ['./data-lineage-term.component.css']
})
export class DataLineageTermComponent implements OnInit, OnDestroy, AfterViewInit {
  nodes = [
    { // node a
      data: {id: 'nodeFirst', type: 'start', label: ''}
    },
    { // node b
      data: {id: 'nodeLast', type: 'end', label: ''}
    }
  ];
  i = 0;
  types = [{id: 'stock', name: 'stock'}, {id: 'acceder', name: 'access'}, {
    id: 'archivage',
    name: 'archiving'
  }, {id: 'collecter', name: 'collect'}
    , {id: 'control', name: 'control'}, {id: 'destruction', name: 'destruction'}
    , {id: 'traitement', name: 'treatment'}, {id: 'transfert', name: 'transfer'}, {
      id: 'visa',
      name: 'visa'
    }, {id: 'validate', name: 'validate'}
    , {id: 'start', name: 'start'}, {id: 'end', name: 'end'}
  ];
  addNodeFrm: FormGroup;
  saveFrm: FormGroup;
  selectedNode = null;
  textColor: any;
  bgColor: any;
  // lg mindMap
  edges = [];
  draggables = [];
  targets = [];
  applicationTypes = [];
  applications = [];
  models = [];
  objects = [];
  tables = [];
  fields = [];
  choosenIcon = 'start';
  loadingApplication = false;
  loadingModel = false;
  loadingObject = [];
  loadingApplicationType = true;
  loadingTable = [];
  loadingField = [];
  customModelAndObjects = [];
  j = 0;
  modelAndObjectAndTableAndFields = [];
  // ngModel for form
  ngTypeApp = null;
  ngApp = null;
  ngNodeText = null;
  ngModels = [];
  ngObjects = [];
  ngTables = [];
  ngFields = [];
  ngExtern = false;
  ngCatalogue = true;
  ngCustomModel = [];
  ngCustomObjects = [];
  ngCustomTable = [];
  ngCustomsFields = [];
  ngDescription = '';
  ngCustomApplication = null;
  // customs
  customFields = [];
  loaders = [];
  disabled = true;
  error = false;
  errorMessage = '';
  nodesItems = [];
  startConnector = null;
  endConnector = null;
  interval: any;
  startColor = 'black';
  endColor = 'black';
  startParent = null;
  endParent = null;
  linesArch = [];
  clicked = null;
  patterns = [{id: 1, name: 'none'}, {id: 2, name: 'paper'}/*, {id: 4, name: 'tiny'}, {id: 5, name: 'connections'} , {id: 6, name: 'diagonal'} , {id: 7, name: 'parkayFloor'}, {id: 8, name: 'floorTile'}, {id: 9, name: 'polkaDots'}*/, {id: 3, name: 'paperDots'}, {id: 4, name: 'dotDiamond'}, {id: 5, name: 'gradientDots'}, {id: 6, name: 'bluePrint'}];
  pattern = 'none';
  prevPattern = 'none';
  flatDesign = true;
  treeData = [];
  orgData = [];
  modalData: any;
  zoom = 1;
  // external system
  registerFormApp: FormGroup;
  listTypeApp = ['Desktop application', 'Web application', 'Mobile application', 'Other'];
  listDepApp = ['On premise', 'Saas'];
  listStatusApp = ['Enabled', 'Disabled'];
  stateOption = ['Enabled', 'Disabled', 'all'];
  stackHolders = [];
  presubmitAdd = true;
  messagedangerAdd: string;
  messagesuccesAdd: string;
  todayDate = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  externalApplications = [];
  loadingApplicationExterne = false;
  holderLoading = false;
  tablesModal = [];
  loadingTableModal = false;
  tableModalValue = [];
  customTableModal = [];
  customTableModalValue = [];
  tableFieldIndex = 0;
  customModalFields = [];
  error1 = false;
  error2 = false;
  extraItems = [];
  extraDescription = '';
  editMode = false;
  options: ITreeOptions = {
    isExpandedField: 'expanded',
    actionMapping: {
      mouse: {
        dblClick: (tree, node, $event) => {
          if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
        }
      },
      keys: {
        [KEYS.ENTER]: (tree, node, $event) => {
          node.expandAll();
        }
      }
    },
    nodeHeight: 23,
    levelPadding: 10,
    useVirtualScroll: true,
    animateExpand: true,
    scrollOnActivate: true,
    animateSpeed: 30,
    animateAcceleration: 1.2
  };
  clickedEdges = [];
  selectedEdges = [];
  selectedEdge: any;
  startName: any;
  endName: any;
  simulation: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  id: any;
  tabIndex = 10;
  importData: any;
  offset = {top: 0, left: 0};
  dataLineageName = 'new DataLineage';
  createdBy = localStorage.getItem('username');
  createdAt = new Date();
  updateLn = false;
  owner = '';
  @ViewChild('screen', { static: true }) screen: any;
  img = '';
  name = null;
  businessTerm: any;
  ngTriggerExtra = 'Manual';
  ngDataLinesExtra = [];
  businessTerms = [];
  businessTermFrm: FormGroup;
  nameError = false;
  nameSuccess = false;
  completion = 0;
  ranger: any;
  status = [{id: 'Draft', name: 'Draft'}, {id: 'Reviewed', name: 'Reviewed'} , {id: 'Approved', name: 'Approved'} , {id: 'Rejected', name: 'Rejected'}, {id: 'Published', name: 'Published'}];
  startlinkType = null;
  endlinkType = null;
  businessObject: BusinessObject;
  termsInfo = [];
  lang: string
  constructor(private fb: FormBuilder, private businessTermService: BusinessTermService, private dynamicScriptLoader: DynamicScriptLoaderService, private cryptoService: CryptoService, private router: Router, private activeRoute: ActivatedRoute, private dataLineService: DataLineageService,private translate: TranslateService) {
    this.saveFrm = this.initChildForm();
    this.registerFormApp = this.initAppForm();
    this.businessTermFrm = this.initForm();
  }

  ngAfterViewInit(): void {
    $('#play-area').on('click', event => {
      event.stopPropagation();
      this.clicked = null;
      $('.node-item').removeClass('selected-node');
      $('.business-item').removeClass('selected-node');
    }).resize(() => {
      this.updateConnectors();
    }).css('flex-basis', '75%');
    $(window).scroll(() => {this.updateConnectors(); });
  }

  ngOnInit(): void {
    this.edges = [];
    this.id = null;
    // get params from url
    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      let name = params.get('name');
      id = id.replace(/__________/g, '/');
      this.id = this.cryptoService.get(id);
      name = name.replace(/__________/g, '/');
      this.name = this.cryptoService.get(name);
    });
    'use strict';
    $(document).ready(function() {
      $('.collapsible').collapsible();
    });
    if (this.id != null && this.id != 0) {
      this.addLoader();
     // this.addBusinessObjectHtml();
      this.getBusinessTerm(this.id);
      //this.addBusinessItem();
     // this.addLoader();
    //  this.getDataLineage(this.id);
    //   const term = new BusinessItem('ertyu', 'Bt Test', '', '14-04-2021', 'pending', '20', '', '', '', ['end_erty'],{top: 0, left: 0});
    //   const term1 = new BusinessItem('145g', 'Bt Pact', '', '03-03-2021', 'pending', '30', '', '', '', ['end_145g'],{top: 0, left: 0});
    //   const b = new BusinessObject('efg', 'Ot Contract',[term, term1], '', 'businessTerm', {top: 200, left: 50});
    //   this.addBusinessObjectHtml(b);
    }
    this.getApplicationTypes();
    PlainDraggable.draggableCursor = ['move', 'all-scroll', 'grab'];
    PlainDraggable.draggingCursor = ['move', 'grabbing'];
    this.onGetHolders();
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'en';
      this.translate.use(this.lang);
    }, 10);
  }
  initForm() {
    return this.fb.group(
      {
        oldId: [
          null
        ],
        businessId: [
          null,
          Validators.compose([Validators.required])
        ],
        name: [
          new Date(),
          Validators.compose([Validators.required])
        ],
        effective: [
          new Date(),
          Validators.compose([Validators.required])
        ],
        status: [
          null,
          Validators.compose([Validators.required])
        ],
        definition: [
          null
        ],
        example: [
          null
        ]
      }
    );
  }

  private initChildForm() {
    return this.fb.group(
      {
        LineName: [
          null,
          Validators.compose([Validators.required])
        ],
        lineEffective: [
          new Date(),
          Validators.compose([Validators.required])
        ],
        enabledLine: [
          true,
          Validators.compose([Validators.required])
        ],
        owner: [
          null,
          Validators.compose([Validators.required])
        ],
        description: [
          null
        ],
      }
    );
  }

  getImageFromType(type: string, color: string) {
    let imag = '';
    switch (type) {
      case 'store':
        imag = '<i class="fas fa-layer-group fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'access':
        imag = '<i class="fas fa-door-open fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'archive':
        imag = '<i class="fas fa-file-archive fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'collect':
        imag = '<i class="fas fa-tasks fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'control':
        imag = '<i class="fas fa-closed-captioning fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'destroy':
        imag = '<i class="fas fa-trash-alt fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'process':
        imag = '<i class="fas fa-microchip fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'transfer':
        imag = '<i class="fas fa-exchange-alt fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'sign':
        imag = '<i class="fab fa-cc-visa fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'validate':
        imag = '<i class="fas fa-clipboard-check fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'start':
        imag = '<i class="fas fa-circle fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'end':
        imag = '<i class="far fa-circle fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'use':
        imag = '<i class="far fa-hand-holding fa-stack-1x fa-inverse fa-middle"></i>';
        break;
    }
    return imag;
  }

  addElement() {
    if (this.editMode) {
      alert('Edition not implemented yet'); return;
    }
    $('body').css('position', '');
    if (this.ngNodeText == '' || this.ngNodeText == null) {
      this.error = true;
      this.errorMessage = this.lang === 'en' ? 'You must provide a Short Description' : 'Vous devez fournir une brève description';
      return;
    }
    if (this.ngExtern) {
      if (this.ngDescription == '' || this.ngDescription == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide a Long Description' : 'Vous devez fournir une description longue';
        return;
      }
      if (this.ngCustomApplication == '' || this.ngCustomApplication == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide an External System' : 'Vous devez fournir un système externe';
        return;
      }
      if (this.customModelAndObjects.length == 0) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide at least one External detail' : 'Vous devez fournir au moins un détail externe';
        return;
      } else {
        for (let index = 0; index < this.customModelAndObjects.length; index++) {
          if (this.ngCustomModel[index] == null) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an External Model' : 'Vous devez fournir un modèle externe';
            return;
          }
          if (this.ngCustomObjects[index] == null) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an External Object' : 'Vous devez fournir un objet externe';
            return;
          }
          if (this.ngCustomTable[index] != undefined) {
            if (this.ngCustomTable[index].length > 0 && this.ngCustomsFields[index] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide External Fields since External table was added' : 'Vous devez fournir des champs externes car une table externe a été ajoutée';
              return;
            }
          }
          if (this.ngCustomsFields[index] != undefined) {
            if (this.ngCustomTable[index] == undefined && this.ngCustomsFields[index].length > 0) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide External table since External fields was added' : 'Vous devez fournir une table externe car des champs externes ont été ajoutés';
              return;
            }
          }
        }
      }
    } else {
      if (this.ngTypeApp == '' || this.ngTypeApp == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must select the application type' : 'Vous devez sélectionner le type d\'application';
        return;
      }
      if (this.ngApp == '' || this.ngApp == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must select the application' : 'Vous devez sélectionner l\'application';

        return;
      }
      if (this.ngCatalogue) {
        // console.log(this.ngCatalogue);
        if (this.modelAndObjectAndTableAndFields.length != 0) {
          for (let item = 0; item < this.modelAndObjectAndTableAndFields.length; item++) {
            if (this.ngModels[item] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide model' : 'Vous devez fournir un modèle';
              return;
            }
            if (this.ngObjects[item] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide object' : 'Vous devez fournir un objet';
              return;
            }
            if (this.ngModels[item] != undefined && this.ngObjects[item] != undefined && this.ngTables[item] != undefined && this.ngFields[item] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide at least one field since table is selected' : 'Vous devez fournir au moins un champ puisque la table est sélectionnée';
              return;
            }
            if (this.ngModels[item] != undefined && this.ngObjects[item] != undefined && this.ngTables[item] == undefined && this.ngFields[item] != undefined && this.ngFields[item].length > 0) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide a table since fields is not empty' : 'Vous devez fournir une table car les champs ne sont pas vides';
              return;
            }
          }
        }
      } else {
        if (this.customModelAndObjects.length == 0) {
          this.error = true;
          this.errorMessage = this.lang === 'en' ? 'You must provide at least one External detail' : 'Vous devez fournir au moins un détail externe';
          return;
        } else {
          for (let index = 0; index < this.customModelAndObjects.length; index++) {
            if (this.ngCustomModel[index] == null) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide an External Model' : 'Vous devez fournir un modèle externe';
              return;
            }
            if (this.ngCustomObjects[index] == null) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide an External Object' : 'Vous devez fournir un objet externe';
              return;
            }
            if (this.ngCustomTable[index].length > 0 && this.ngCustomsFields[index] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide External Fields since External table was added' : 'Vous devez fournir des champs externes car une table externe a été ajoutée';
              return;
            }
            if (this.ngCustomTable[index] == undefined && this.ngCustomsFields[index].length > 0) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide External Fields since External table was added' : 'Vous devez fournir des champs externes car une table externe a été ajoutée';
              return;
            }
          }
        }
      }
    }
    this.error = false;
    this.errorMessage = '';
    const data = [];
    const customs = [];
    if (!this.ngCatalogue) {
      if (this.customModelAndObjects.length > 0) {
        this.customModelAndObjects.map(
          j => {
            const children = [];
            if (this.ngCustomTable[j] != undefined) {
              this.ngCustomTable[j].map(
                tab => {
                  const t = this.ngCustomsFields[j].filter( f => f.table == tab.name);
                  const t0 = [];
                  t.map( tt => t0.push({ id: this.create_UUID(), name: tt.name, description: tt.description }));
                  children.push({table: {id: this.create_UUID(), name: tab.name}, fields: t0});
                }
              );
            }
            customs.push({model : {id: this.create_UUID(), name: this.ngCustomModel[j]}, object: {id: this.create_UUID(), name: this.ngCustomObjects[j]}, children});
          }
        );
      }
    } else {
      if (this.modelAndObjectAndTableAndFields.length > 0) {
        this.modelAndObjectAndTableAndFields.map(
          i => {
            const children = [];
            if (this.ngTables[i] != undefined) {
              this.ngTables[i].map(
                tab => {
                  const t = this.ngFields[i].filter( f => f.table == tab.name);
                  const t0 = [];
                  t.map( tt => t0.push({ id: tt.id, name: tt.name, description: tt.description }));
                  children.push({table: {id: tab.id, name: tab.name}, fields: t0});
                }
              );
            }
            data.push({model: this.ngModels[i], object: this.ngObjects[i], children});
          }
        );
      }
    }
    if (this.editMode) {

    } else {

    }
    const id = this.create_UUID();
    // @ts-ignore
    const nodeItem = new NodeItem(id, this.choosenIcon, this.ngNodeText, this.ngApp, this.ngExtern, this.ngCatalogue, data, btoa(this.ngDescription), this.ngCustomApplication, 'black', customs, this.ngTypeApp, null, 'action', this.offset);
    // console.log(nodeItem);
    // const that = this;

    this.addHtmlContent(nodeItem);
  }

  getEdges(clicked: NodeItem) {
    this.clickedEdges = [];
    this.selectedEdges = [];
    if (clicked.data.length > 0) {
      clicked.data.map(
        d => {
          const startObject = 'start_' + d.object.id;
          const endObject = 'end_' + d.object.id;
          const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
          const objectEdgesParent = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == false);
          if (objectEdges.length > 0) {
            this.clickedEdges = this.clickedEdges.concat(objectEdges);
          }
          if (objectEdgesParent.length > 0) {
            this.selectedEdges = this.selectedEdges.concat(objectEdgesParent);
          }
          if (d.children.length > 0) {
            d.children.map(
              t => {
                t.fields.map(
                  f => {
                    const startField = 'start_' + f.id;
                    const endField = 'end_' + f.id;
                    const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == true);
                    const fieldEdgesParent = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == false);
                    if (fieldEdges.length > 0) {
                      this.clickedEdges = this.clickedEdges.concat(fieldEdges);
                    }
                    if (fieldEdgesParent.length > 0) {
                      this.selectedEdges = this.selectedEdges.concat(fieldEdgesParent);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
    if (clicked.customs.length > 0) {
      clicked.customs.map(
        d => {
          const startObject = 'start_' + d.object.id;
          const endObject = 'end_' + d.object.id;
          const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
          const objectEdgesParent = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == false);
          if (objectEdges.length > 0) {
            this.clickedEdges = this.clickedEdges.concat(objectEdges);
          }
          if (objectEdgesParent.length > 0) {
            this.selectedEdges = this.selectedEdges.concat(objectEdgesParent);
          }
          if (d.children.length > 0) {
            d.children.map(
              t => {
                t.fields.map(
                  f => {
                    const startField = 'start_' + f.id;
                    const endField = 'end_' + f.id;
                    const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == true);
                    const fieldEdgesParent = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == false);
                    if (fieldEdges.length > 0) {
                      this.clickedEdges = this.clickedEdges.concat(fieldEdges);
                    }
                    if (fieldEdgesParent.length > 0) {
                      this.selectedEdges = this.selectedEdges.concat(fieldEdgesParent);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
    //  console.table(this.clickedEdges);
  }

  enableLinking() {
    const that = this;
    $('.connector').off().on('click', function() {
      if (that.startConnector == null) {
        that.startConnector = $(this).attr('id');
        that.startName = $(this).attr('data-name');

        if ($(this).parent().hasClass('lg-start') || $(this).parent().hasClass('lg-end') || $(this).parent().hasClass('lg-aggregate') || $(this).parent().hasClass('lg-condition')) {
          if ($(this).parent().hasClass('lg-start')) {that.startColor = 'green'; that.startlinkType = 'start';}
          if ($(this).parent().hasClass('lg-end')) {that.startColor = 'red'; that.startlinkType = 'end';}
          if ($(this).parent().hasClass('lg-aggregate')) {that.startColor = 'blue'; that.startlinkType = 'aggregate';}
          if ($(this).parent().hasClass('lg-condition')) {that.startColor = 'blue'; that.startlinkType = 'condition';}
          that.startParent = that.startConnector;
        } else {
          that.startColor = $(this).parent().parent().parent().parent().attr('data-color');
          if ($(this).hasClass('start')) {
            that.startParent = $(this).parent().parent().prev('li').prev('li').find('.start').attr('id');
          }
          if ($(this).hasClass('end')) {
            that.startParent = $(this).parent().parent().prev('li').prev('li').find('.end').attr('id');
          }
        }
        if($(this).hasClass('field-line')){
          that.startlinkType = 'field';
        }
        // object circle connector has been clicked
        if ($(this).hasClass('object-line')) {
          that.startColor = $(this).parent().parent().parent().attr('data-color');
          that.startParent = that.startConnector;
          that.startlinkType = 'object';
        }
        // business connector has been clicked
        if ($(this).hasClass('business-line-object') || $(this).hasClass('business-line-term')) {
          that.startColor = 'black';
          that.startParent = that.startConnector;
          that.startlinkType = $(this).hasClass('business-line-object') ? 'businessObject':'businessTerm';
        }
        return;
      }
      if (that.endConnector == null) {
        that.endConnector = $(this).attr('id');
        that.endName = $(this).attr('data-name');
        if ($(this).parent().hasClass('lg-start') || $(this).parent().hasClass('lg-end') || $(this).parent().hasClass('lg-aggregate') || $(this).parent().hasClass('lg-condition')) {
          if ($(this).parent().hasClass('lg-start')) {
            that.endColor = '#11998e';
            that.endlinkType = 'start';
          }
          if ($(this).parent().hasClass('lg-end')) {
            that.endColor = '#D31027';
            that.endlinkType = 'end';
          }
          if ($(this).parent().hasClass('lg-aggregate')) {
            that.endColor = '#00c6ff';
            that.endlinkType = 'aggregate';
          }
          if ($(this).parent().hasClass('lg-condition')) {
            that.endColor = '#00c6ff';
            that.endlinkType = 'condition';
          }
          that.endParent = that.endConnector;
        } else {
          if(($(this).hasClass('field-line') && that.startlinkType == 'businessObject') || ($(this).hasClass('object-line') && that.startlinkType == 'businessTerm')){
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur !',
              text: this.lang === 'en' ? 'You can\'t link businessObject with field' : 'Vous ne pouvez pas lier l\'objet métier avec le champ',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
            });            
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            return;
          }
          if(($(this).hasClass('business-line-term') && that.startlinkType == 'object') || ($(this).hasClass('business-line-object') && that.startlinkType == 'field')){
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur !',
              text: this.lang === 'en' ? 'you can\'t link field with businessObject' : 'Vous ne pouvez pas lier le champ avec l\'objet métier',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
            });            
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            return;
          }
          that.endColor = $(this).parent().parent().parent().parent().attr('data-color');
          if ($(this).hasClass('start')) {
            that.endParent = $(this).parent().parent().prev('li').prev('li').find('.start').attr('id');
          }
          if ($(this).hasClass('end')) {
            that.endParent = $(this).parent().parent().prev('li').prev('li').find('.end').attr('id');
          }
        }
        if($(this).hasClass('field-line')){
          that.endlinkType = 'field';
        }
        // object circle connector has been clicked
        if ($(this).hasClass('object-line')) {
          that.endColor = $(this).parent().parent().parent().attr('data-color');
          that.endParent = that.endConnector;
          that.endlinkType = 'object';
        }
        // business connector has been clicked
        if ($(this).hasClass('business-line-object') || $(this).hasClass('business-line-term')) {
          that.endColor = 'black';
          that.endParent = that.startConnector;
          that.endlinkType = $(this).hasClass('business-line-object') ? 'businessObject': 'businessTerm';
        }
        console.log(that.startConnector, that.endConnector, that.startParent, that.endParent);
        // that.drawConnector(that.startConnector, that.endConnector);
        if ( that.startConnector != null && that.endConnector != null && that.startParent != null && that.endParent != null) {
          console.log(that.startConnector, that.endConnector, that.startParent, that.endParent);
          const groupId = that.create_UUID();
          if(that.startlinkType == 'field' && that.endlinkType == 'field'){
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur !',
              text: this.lang === 'en' ? 'You can\'t link two fields' : 'Vous ne pouvez pas lier deux champs',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
            });            
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            return;
          }
          if(that.startlinkType == 'object' && that.endlinkType == 'object'){
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur !',
              text: this.lang === 'en' ? 'You can\'t link two object' : 'Vous ne pouvez pas lier deux objets',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
            });            
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            return;
          }
          if((that.startlinkType == 'field' && that.endlinkType == 'object') || (that.startlinkType == 'object' && that.endlinkType == 'field')){
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur !',
              text: this.lang === 'en' ? 'You can\'t link field and object' : 'Vous ne pouvez pas lier un champ et un objet',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
            });            
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            return;
          }
          if(that.startlinkType == 'businessObject' && that.endlinkType == 'businessTerm'){
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur !',
              text: this.lang === 'en' ? 'You can\'t link businessObject with businessTerm' : 'Vous ne pouvez pas lier un objet métier avec un terme métier',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
            });            
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            return;
          }
          if(that.startlinkType == 'businessTerm' && that.endlinkType == 'businessTerm'){
            if(that.nodesItems.length == 0 && that.edges.length == 0){
              Swal.fire({
                title: this.lang === 'en' ? 'Error!' : 'Erreur !',
                text: this.lang === 'en' ? 'Two businessTerms can only be linked if one of them is linked to fields' : 'Deux termes métier ne peuvent être liés que si l\'un d\'eux est lié à des champs',
                icon: 'error',
                confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
              });              
              that.startConnector = null;
              that.endConnector = null;
              that.startParent = null;
              that.endParent = null;
              that.startlinkType = null;
              that.endlinkType = null;
              return;
            }
            if($('#' + that.startConnector).hasClass('end') || $('#' + that.endConnector).hasClass('end')){
              Swal.fire({
                title: this.lang === 'en' ? 'Error!' : 'Erreur !',
                text: this.lang === 'en' ? 'Two businessTerms can only be linked on the left side' : 'Deux termes métier ne peuvent être liés que du côté gauche',
                icon: 'error',
                confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
              });              
              that.startConnector = null;
              that.endConnector = null;
              that.startParent = null;
              that.endParent = null;
              that.startlinkType = null;
              that.endlinkType = null;
              return;
            }
            const line = that.addLine(that.startConnector, that.endConnector, 'lightGreen', 'lightGreen', false, true, that.startName, that.endName, groupId, 'fluid', 'top', 'left', '\uf0c1');
          } else {
            const line = that.addLine(that.startConnector, that.endConnector, that.startColor, that.endColor, false, true, that.startName, that.endName, groupId, 'fluid', 'auto', 'auto', '');
          }
          // const parentLine = that.addLine(that.startParent, that.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
          // const leftLine = that.addLine(that.startConnector, that.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
          // const rightLine = that.addLine(that.endConnector, that.startParent, that.endColor, that.startColor, true, false, that.startName, that.endName, groupId);
          // that.linesArch.push({line, parent: parentLine, source: that.startParent, target: that.endParent, leftParent: leftLine, rightParent: rightLine, groupId, start: that.startConnector, end : that.endConnector});
          that.startConnector = null;
          that.endConnector = null;
          that.startParent = null;
          that.endParent = null;
          that.startlinkType = null;
          that.endlinkType = null;
        }
      }
    });
  }

  removeElement(id) {
    $('#' + id).remove();
  }

  getFields(obj, fields) {
    let htm = '';
    fields.map(
      field => {
        htm += '<li class="list-group-item"><i class="fas fa-circle connector start static field-line" id="start_' + field.id + '" data-parent=" ' + obj + '" data-name="' + field.name + '"></i>  &nbsp; <span style="color: #4e4ebb;text-transform: capitalize"><i class="fas fa-receipt"></i>&nbsp;' + field.name + '</span>&nbsp;<i class="fas fa-circle connector end field-line" id="end_' + field.id + '" data-parent=" ' + obj + '" data-name="' + field.name + '"></i></li>';
      }
    );
    return htm;
  }

  makeDraggable(element) {
    // const draggable = Draggable.create('#'  + $.escapeSelector(element), {
    //   bounds: document.getElementById('play-area')
    // } );
    const that = this;
    const draggable =
      new PlainDraggable(document.getElementById(element), { // eslint-disable-line no-new
        onMove() {
          // that.clicked = that.nodesItems.find(item => item.id = element);
          // console.log(that.clicked);
          that.updateConnectors();
        },
        containment: document.getElementById('#play-area')// ,
        // handle: document.querySelector('#'+element+'>span')
      });
    this.draggables.push({element: draggable, id: element});
  }
  addLine(start, end , startColor, endColor, hideLine, parent, startName, endName, group, path, startSocket, endSocket, middleLabel) {
    const that = this;
    let newLine = null;
    if (document.getElementById(start).offsetLeft < document.getElementById(end).offsetLeft) {
      newLine = new LeaderLine(
        document.getElementById(start),
        document.getElementById(end),
        {
          endPlug: 'behind',
          startPlug: 'behind',
          endPlugColor: endColor,
          startPlugColor: startColor,
          gradient: true,
          path,
          startSocket,
          endSocket,
          middleLabel: LeaderLine.captionLabel(middleLabel, {color: 'gray'}),
          hide: hideLine
          // dropShadow: {dx: 0, dy: 7}
        }
      );
    } else {
      newLine = new LeaderLine(
        document.getElementById(end),
        document.getElementById(start),
        {
          endPlug: 'behind',
          startPlug: 'behind',
          endPlugColor: startColor,
          startPlugColor: endColor,
          gradient: true,
          path,
          startSocket,
          endSocket,
          middleLabel: LeaderLine.pathLabel(middleLabel, {color: 'gray'}),
          hide: hideLine
          // dropShadow: {dx: 0, dy: 7}
        }
      );
    }
    $('body>.leader-line:last-of-type').attr('data-source', start).attr('data-target', end).on('click', function(e) {
      e.stopPropagation();
      const source = $(this).attr('data-source');
      const target = $(this).attr('data-target');
      const line = that.edges.find(e => e.source == source && e.target == target);
      that.selectedEdge = line;
      that.highLightEdge();
      that.removeEdge();
     /* line.element.outline = true;
      line.element.setOptions({
        outlineColor: 'rgb(1,7,12)'
      });*/
    });
    $('body>.leader-line:last-of-type').find('text').addClass('icon-type-text');
    this.edges.push({element: newLine, source: start, target: end, sourceName: startName, targetName: endName, group , parent, hide: hideLine, startColor, endColor});
    return newLine;
  }

  create_UUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  create_UUIB() {
    let dt = new Date().getTime();
    return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  getApplicationTypes() {
    this.loadingApplicationType = true;
    this.dataLineService.getApplicationTypes().subscribe(
      d => {
        this.applicationTypes = d.data;
        this.loadingApplicationType = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getApplicationsByType(external = 'NO') {
    external == 'NO' ? this.loadingApplication = true : this.loadingApplicationExterne = true;
    this.ngApp = null;
    let type;
    this.ngTypeApp == null ? type = 'false' : type = this.ngTypeApp.name;
    this.dataLineService.getApplicationsByType(type, external).subscribe(
      d => {
        if (external == 'NO') {
          this.applications = d.data;
          this.loadingApplication = false;
        } else {
          this.externalApplications = d.data;
          this.loadingApplicationExterne = false;
        }
      },
      e => {
        console.error(e);
      }
    );
  }

  getModelsByApp() {
    this.loadingModel = true;
    this.models = [];
    this.objects = [];
    this.tables = [];
    this.fields = [];
    let d = this.ngApp.id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getModelsByApp(d).subscribe(
      d => {
        this.models[0] = d.data;
        this.loadingModel = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getObjectsByModel(i: number) {
    this.loadingObject[i] = true;
    this.ngObjects[i] = null;
    let d = this.ngModels[i].id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getObjectsByModel(d).subscribe(
      d => {
        this.objects[i] = d.data;
        this.loadingObject[i] = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getTablesByObject(i: number) {
    this.loadingTable[i] = true;
    this.ngTables[i] = null;
    // console.log(this.ngObjects[i].id);
    // return;
    let d = this.ngObjects[i].id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getTablesByObject(d).subscribe(
      d => {
        this.tables[i] = d.data;
        this.loadingTable[i] = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getFieldsByTable(i: number) {
    this.loadingField[i] = true;
    this.ngFields[i] = null;
    // console.log(this.ngTables[i]);
    if (this.ngTables[i] == null) { return; }
    let d = this.ngTables[i].replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getFieldsByTable(d).subscribe(
      d => {
        this.fields[i] = d.data;
        this.loadingField[i] = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  setCatalogue() {
    // this.addNodeFrm.patchValue({catalogue: !this.addNodeFrm.value['extern']})
    this.ngCatalogue = !this.ngExtern;
    this.resetModelObjectTableFields();
    this.resetCustomModelObject();
  }

  setCustomValue() {
    if (this.ngCatalogue) {
      this.i = 0;
      this.modelAndObjectAndTableAndFields = [];
      this.modelAndObjectAndTableAndFields.push(this.i);
      this.resetCustomModelObject();
    } else {
      this.j = 0;
      this.customModelAndObjects = [];
      this.customModelAndObjects.push(this.j);
      this.resetModelObjectTableFields();
    }
  }

  getObjectById(id: string) {
    const o = this.objects.filter(item => item.id == id);
    if (o.length > 0) {
      return o[0].name;
    }
  }

  showAddLineageModal() {
    this.addNodeFrm.reset();
    this.addNodeFrm.patchValue({customModel: 'null', customObject: 'null', extern: false, catalogue: true});
    // this.addNodeFrm.patchValue({extern: false, catalogue: true});
    // $('#extern').prop('checked', false).change();
    // $('#catalogue').prop('checked', true).change();
    $('#addLineageModal').modal();
  }
  initConnectors() {
    const ends = [];
    const connectors = document.getElementsByClassName('line-handle');
    Array.from(connectors).forEach(function(start, index) {
      const end = start.previousElementSibling;
      const line = new LeaderLine(start, end, {
        startPlug: 'none',
        startPlugColor: '#31b7f9',
        endPlugColor: '#a6dffc',
        gradient: true,
        path: 'fluid',
        startSocket: 'right',
        endSocket: 'left'
      });

      const draggable = new PlainDraggable(end, {
        onMove() {
          line.position();
        },
        onMoveStart() {
          line.dash = {animation: true};
        },
        onDragEnd() {
          line.dash = false;
        },
        autoScroll: true
      });
      ends.push(draggable);
    });
  }

  updateContainer(event) {
    $('body').css('position', '');
    const that = this;
    if (event.stopPropagation) {
      event.stopPropagation(); // stops the browser from redirecting.
    }
    event.preventDefault();
    // console.log(event);
    const id = event.dataTransfer.getData('text');
    if (id == 'lg-bt') {
      this.addBusinessGlossary();
      return;
    }
    if (id == 'lg-start') {
      const newId = this.create_UUID();
      const connectors = [];
      connectors.push({id: 'start_' + newId});
      connectors.push({id: 'end_' + newId});
      const extraItem = new ExtraItem(newId, '', connectors, 'start', {top:  event.clientY , left: event.clientX}, this.ngTriggerExtra, this.ngDataLinesExtra);
      this.addExtraHtml(extraItem);
      return;
    }
    if (id == 'lg-end') {
      const newId = this.create_UUID();
      const connectors = [];
      connectors.push({id: 'start_' + newId});
      connectors.push({id: 'end_' + newId});
      const extraItem = new ExtraItem(newId, '', connectors, 'end', {top:  event.clientY, left: event.clientX}, null, null);
      this.addExtraHtml(extraItem);
      window.scrollBy(5, 100);
      window.scrollBy(-5, -100);
      return;
    }
    if (id == 'lg-aggregate') {
      const newId = this.create_UUID();
      const connectors = [];
      connectors.push({id: 'start_' + newId});
      connectors.push({id: 'end_' + newId});
      const extraItem = new ExtraItem(newId, '', connectors, 'aggregate', {top:  event.clientY, left: event.clientX}, null, null);
      this.addExtraHtml(extraItem);
      window.scrollBy(5, 100);
      window.scrollBy(-5, -100);
      return;
    }
    if (id == 'lg-condition') {
      const newId = this.create_UUID();
      const connectors = [];
      connectors.push({id: 'start_' + newId});
      connectors.push({id: 'end_' + newId});
      connectors.push({id: 'input_' + newId});
      const extraItem = new ExtraItem(newId, '', connectors, 'condition', {top:  event.clientY, left: event.clientX}, null, null);
      this.addExtraHtml(extraItem);
      window.scrollBy(5, 100);
      window.scrollBy(-5, -100);
      return;
    }
    this.startScriptUP();
    // this.choosenIcon = type;
    this.ngExtern = false;
    this.ngCatalogue = true;
    this.ngApp = null;
    this.ngTypeApp = null;
    this.ngNodeText = null;
    this.ngDescription = null;
    this.models = [];
    this.tables = [];
    this.fields = [[]];
    this.ngModels = [];
    this.ngObjects = [];
    this.ngTables = [];
    this.ngFields = [[]];
    this.ngCustomApplication = null;
    this.ngCustomModel = [];
    this.ngCustomTable = [];
    this.ngCustomObjects = [];
    this.ngCustomsFields = [];
    this.j = 0;
    this.modelAndObjectAndTableAndFields = [];
    this.modelAndObjectAndTableAndFields.push(0);
    this.i = 0;
    this.customModelAndObjects = [];
    this.customModelAndObjects.push(0);
    this.ngDescription = '';
    this.editMode = false;
    this.getApplicationsByType( 'YES');
    $('#addLineageModal').modal('show');
    return false;
  }

  allowEvent(event) {
    event.preventDefault();
  }

  getIconForModal(type: string) {
    return '<i class="fas fa-circle fa-stack-2x fa-blue" style="color: rgb(60 172 196);vertical-align: middle;"></i>' +
      this.getImageFromType(type, '');
  }

  dragStart(event: DragEvent) {
    $(event.target).css('opacity', '0.4');
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
  }

  dragEnd(event: DragEvent, type: string) {
    $(event.target).css('opacity', 1);
    this.choosenIcon = type;
    this.offset = {top:  event.clientY , left: event.clientX};
  }
  requiredByExtern() {
    this.addNodeFrm.get('extern').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('nodeText').updateValueAndValidity();
        this.addNodeFrm.get('description').updateValueAndValidity();
      });
  }

  requiredByCatalogue() {
    this.addNodeFrm.get('catalogue').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('appType').updateValueAndValidity();
        this.addNodeFrm.get('app').updateValueAndValidity();
        this.addNodeFrm.get('model').updateValueAndValidity();
        this.addNodeFrm.get('objects').updateValueAndValidity();
      });
  }

  requiredByTable() {
    this.addNodeFrm.get('table').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('fields').updateValueAndValidity();
      });
  }

  requiredByCustomModel() {
    this.addNodeFrm.get('catalogue').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('customModel').updateValueAndValidity();
        this.addNodeFrm.get('customObject').updateValueAndValidity();
      });
  }

  addCustomModelObject() {
    this.i++;
    this.customModelAndObjects.push(this.i);
  }

  resetCustomModelObject() {
    this.i = 0;
    this.customModelAndObjects = [];
    this.customModelAndObjects.push(0);
    this.ngCustomApplication = null;
    this.ngCustomModel = [];
    this.ngCustomObjects = [];
    this.ngCustomTable = [];
    this.ngCustomsFields = [];
  }

  removeCustomModelObject(i: number) {
    this.customModelAndObjects.splice(i, 1);
    this.ngCustomModel[i] = null;
    this.ngCustomObjects[i] = null;
    this.ngCustomsFields[i] = null;
  }

  // dynamic model, object, table ,fields
  addModelObjectTableFields() {
    this.j++;
    this.models[this.j] = this.models[this.j - 1];
    this.modelAndObjectAndTableAndFields.push(this.j);
  }

  resetModelObjectTableFields() {
    this.j = 0;
    this.modelAndObjectAndTableAndFields = [];
    this.modelAndObjectAndTableAndFields.push(0);
    this.ngModels = [];
    this.ngObjects = [];
    this.ngTables = [];
    this.ngFields = [[]];
    this.objects = [];
    this.tables = [];
    this.fields = [[]];
  }

  removeModelObjectTableFields(i: number) {
    this.modelAndObjectAndTableAndFields.splice(i, 1);
    this.ngModels.splice(i, 1);
    this.ngObjects.splice(i, 1);
    this.ngTables.splice(i, 1);
    this.ngFields.splice(i, 1);
    this.objects.splice(i, 1);
    this.tables.splice(i, 1);
    this.fields.splice(i, 1);
  }

  async startScriptUP() {
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      this.loadData();
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      // this.loadData();
      const that = this;

      $('#addApp').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,


      })
        .on('change', function(e, date) {
          const dateapp = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          that.registerFormApp.get('dateApp').patchValue(dateapp);
        });
    }).catch(error => console.error(error));
  }

  private loadData() {
    // definition editor
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.ngDescription) {
      CKEDITOR.instances.ngDescription.destroy(true);
    }
    const editorDescription = CKEDITOR.replace('ngDescription');
    editorDescription.config.height = 60;
    editorDescription.on( 'change', evt => {
      this.ngDescription = evt.editor.getData();
    });
  }
  addCustomTable = (term) => ({id: term, name: term});

  addCustomField = (term) => ({id: term, name: term});
  // removeTable = (event, i) => {
  //   this.customTable[i].splice(this.customTable[i].indexOf(e => e.id == event.id), 1);
  // }
  removeField = (event, i) => {
    // this.customFields[i].splice(this.customFields[i].indexOf(e => e.id == event.id), 1);
  }
  // drawConnector(a, b) {
  //   const divA  = document.getElementById(a);
  //   const divB  = document.getElementById(b);
  //   const arrowLeft = document.getElementById('arrowLeft');
  //   console.log(divA, divA, arrowLeft);
  //   const posnALeft = {
  //     x: divA.offsetLeft - 8,
  //     y: divA.offsetTop  + divA.offsetHeight / 2
  //   };
  //   const posnARight = {
  //     x: divA.offsetLeft + divA.offsetWidth + 8,
  //     y: divA.offsetTop  + divA.offsetHeight / 2
  //   };
  //   const posnBLeft = {
  //     x: divB.offsetLeft - 8,
  //     y: divB.offsetTop  + divB.offsetHeight / 2
  //   };
  //   const posnBRight = {
  //     x: divB.offsetLeft + divB.offsetWidth + 8,
  //     y: divB.offsetTop  + divB.offsetHeight / 2
  //   };
  //   const dStrLeft =
  //     'M' +
  //     (posnALeft.x      ) + ',' + (posnALeft.y) + ' ' +
  //     'C' +
  //     (posnALeft.x - 100) + ',' + (posnALeft.y) + ' ' +
  //     (posnBLeft.x - 100) + ',' + (posnBLeft.y) + ' ' +
  //     (posnBLeft.x      ) + ',' + (posnBLeft.y);
  //   console.log(dStrLeft);
  //   arrowLeft.setAttribute('d', dStrLeft);
  // }
  // wrapperPosition() {
  //   const lineWrapper = document.getElementById('line-wrapper');
  //   lineWrapper.style.transform = 'none';
  //   const rect = lineWrapper.getBoundingClientRect();
  //   lineWrapper.style.transform = 'translate(' +
  //     (-(rect.left + pageXOffset)) + 'px, ' +
  //     (-(rect.top + pageYOffset)) + 'px)';
  // }
  updateConnectors() {
    try {
      this.edges.map(
        item => {
          item.element.position();
        }
      );
    } catch (e) {// console.error(e);
    }

  }

  getTables(obj, children) {
    let html = '';
    children.map(
      t => {
        // console.log(t);
        html += '<li class="list-group-item" style="float: left;"><b style="color: blue;text-transform: capitalize;"><i class="fas fa-table"></i>&nbsp;' + t.table.name + '</b>&nbsp;</li>' +
          this.getFields(obj, t.fields);
      }
    );
    return html;
  }

  ngOnDestroy(): void {
    this.edges.map(e => { e.element.remove(); });
  }

  collapse(collapse: string, event) {
    $('#' + collapse).collapse('toggle');
    if ($(event.target).hasClass('fa-minus')) {
      $(event.target).removeClass('fa-minus').addClass('fa-plus');
    } else {
      $(event.target).removeClass('fa-plus').addClass('fa-minus');
    }
  }
  getApplicationTypeIcon(app, custom) {
    let icon = '';
    const type = app != null ? app.name : custom.name;
    switch (type) {
      case 'Desktop application': icon = '<i class="fas fa-desktop" title="System type ' + type + '"></i>'; break;
      case 'Web application': icon = '<i class="fab fa-chrome" title="System type ' + type + '"></i>'; break;
      case 'Mobile application': icon = '<i class="fas fa-mobile-alt" title="System type ' + type + '"></i>'; break;
      default: icon = '<i class="fas fa-info" title="System type ' + type + '"></i>'; break;
    }
    return icon;
  }
  changePattern(cl) {
    this.pattern = cl;
    if (this.prevPattern != 'none') {
      $('#play-area').removeClass(this.prevPattern);
    }
    $('#play-area').addClass(this.pattern);
    this.prevPattern = this.pattern;
  }

  changeComplete(event: ColorEvent) {
    let style = $('#play-area').attr('style');
    style = style.split('background-color');
    style[0] += 'background-color:' + event.color.hex + ' !important;';
    $('#play-area').attr('style', style);
  }
  getHeaderStyle() {
    if (this.flatDesign) {
      return  '<div class="card-header text-center text-white node-header"  style="background-color: ';
    } else {
      return  '<div class="card-header text-center text-white node-header header-opaque"  style="background-color: ';
    }
  }
  getDetailsStyle() {
    if (this.flatDesign) {
      return '      <ul class="list-group list-group-flush text-black node-details flat-details" id="';
    } else {
      return '      <ul class="list-group list-group-flush text-black node-details opaque-details" id="';
    }
  }
  getFooterStyle() {
    if (this.flatDesign) {
      return '      <div class="card-body tools-line tools-line-flat">\n';
    } else {
      return '      <div class="card-body tools-line tools-line-opaque">\n';
    }
  }
  getNodeStyle() {
    if (this.flatDesign) {
      return '  <div class="card node-item" style="width: 170px;background: transparent;font-size:10px;" data-color="';
    } else {
      return '  <div class="card node-item shadow-design" style="width: 170px;background: transparent;font-size:10px;" data-color="';
    }
  }
  changeFlatStyle(event) {
    // console.log(event);
    const checked = $(event.target).prop('checked');
    if (checked) {
      this.flatDesign = true;
      if ($('.node-item').length > 0) {
        $('.node-item').removeClass('shadow-design');
      }
      if ($('.business-item').length > 0) {
        $('.business-item').removeClass('shadow-design');
      }
    } else {
      this.flatDesign = false;
      if ($('.node-item').length > 0) {
        $('.node-item').addClass('shadow-design');
      }
      if ($('.business-item').length > 0) {
        $('.business-item').addClass('shadow-design');
      }
    }
  }
  collapseMenuDetect() {
    console.log('test');
  }
  startEndDrag(event) {

  }
  dragstart_handler(ev) {
    //  console.log('dragStart');
    // Change the source element's background color to signify drag has started
    // ev.currentTarget.style.border = 'dashed';
    // Add the id of the drag source element to the drag data payload so
    // it is available when the drop event is fired
    ev.dataTransfer.setData('text', ev.target.id);
    // Tell the browser both copy and move are possible
    ev.effectAllowed = 'copy';
  }
  dragover_handler(ev) {
    //  console.log('dragOver');
    // Change the target element's border to signify a drag over event
    // has occurred
    // ev.currentTarget.style.background = 'lightblue';
    ev.preventDefault();
  }
  drop_handler(ev) {
    //  console.log('Drop');
    ev.preventDefault();
    // console.log(ev);
    // Get the id of drag source element (that was added to the drag data
    // payload by the dragstart event handler)
    const id = ev.dataTransfer.getData('text');
    // Only Move the element if the source and destination ids are both "move"
    if (id == 'src_move' && ev.target.id == 'dest_move') {
      ev.target.appendChild(document.getElementById(id));
    }
    // Copy the element if the source and destination ids are both "copy"
    if (id == 'src_copy' && ev.target.id == 'dest_copy') {
      const nodeCopy = document.getElementById(id).cloneNode(true);
      // @ts-ignore
      // nodeCopy.id = 'newId';
      // @ts-ignore
      nodeCopy.removeAttribute('draggable');
      // @ts-ignore
      nodeCopy.removeAttribute('id');
      ev.target.appendChild(nodeCopy);
    }
  }

  dragend_handler(ev) {
    // console.log('dragEnd');
    // Restore source's border
    // ev.target.style.border = null;
    // Remove all of the drag data
    ev.dataTransfer.clearData();
  }
  groupBy(key) {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  }
  groupByModel(array) {
    return array.reduce((acc, obj) => {
      const property = obj.model.id;
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  }
  getNodeData(clicked) {
    this.treeData = this.unifyObject(clicked);
    // console.log(this.treeData);
    this.orgData = [];
    const treeParent = {id: 1, name : clicked.text, children : [] , expanded: true};
    let orgIndex = 2;
    // tree node
    this.treeData.map(
      m => {
        const orgModeNode = { id: orgIndex, name:  m.model.name, title: 'treeModel', children: [] , className: 'treeModel', icon: '<i class="fas fa-server"></i>', expanded: true};
        // object loop
        m.data.map(
          det => {
            orgIndex++;
            const orgObjNode = { id: orgIndex , name:  det.object.name, title: 'treeObject', children: [], className: 'treeObject', icon: '<i class="fas fa-vector-square"></i>', expanded: true};
            // table loop
            det.children.map(
              t => {
                orgIndex++;
                const orgTabNode = { id: orgIndex , name:  t.table.name, title: 'treeTable', children: [], className: 'treeTable', icon: '<i class="fas fa-table"></i>', expanded: true };
                // fields loop
                t.fields.map(
                  f => {
                    orgIndex++;
                    const orgFieldNode = { id: orgIndex , name:  f.name, title: 'treeField' , className: 'treeField', icon: '<i class="fas fa-receipt"></i>', expanded: true};
                    orgTabNode.children.push(orgFieldNode);
                  }
                );
                orgObjNode.children.push(orgTabNode);
              }
            );
            orgModeNode.children.push(orgObjNode);
          }
        );
        treeParent.children.push(orgModeNode);
      }
    );
    this.orgData.push(treeParent);
  }
  zoomIn() {
    if (this.zoom < 3) {
      this.zoom += 0.2;
      $('#play-area').css('zoom', this.zoom);
      $('.leader-line').css('zoom', this.zoom);
      this.updateConnectors();
    }
  }
  zoomOut() {
    if (this.zoom > 0.3) {
      this.zoom -= 0.2;
      $('#play-area').css('zoom', this.zoom);
      $('.leader-line').css('zoom', this.zoom);
      this.updateConnectors();
    }
  }
  zoomReset() {
    this.zoom = 1;
    $('#play-area').css('zoom', this.zoom);
    $('.leader-line').css('zoom', this.zoom);
    this.updateConnectors();
  }

  private initAppForm() {
    return this.fb.group({
      nameApp: ['', Validators.compose([Validators.required])],
      dateApp: [''],
      versionApp: [''],
      statusApp: true,
      external: [{value: true, disabled: true }],
      typeApp: [''],
      deploymentApp: [''],
      descApp: [''],
      moduleApp: ['', Validators.compose([Validators.required])],
      descMod: [''],
      createdAt: [''],
      createdBy: [''],
      updatedAt: [''],
      updatedBy: [''],
      manager: ['', Validators.compose([Validators.required])],
      owner: [''],

      applicationManager: ['']
    });
  }
  onGetHolders() {
    this.holderLoading = true;
    this.dataLineService.getHolders().subscribe(
      data => {
        this.stackHolders = data.data;
        this.holderLoading = false;
      }
    );
  }
  groupByFn = (item) => item.stack.role;
  groupValueFn = (_: string, children: any[]) => ({ name: children[0].stack.role, total: children.length });
  onClose() {
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.registerFormApp.get('nameApp').patchValue('');
    this.registerFormApp.get('dateApp').patchValue('');
    this.registerFormApp.get('applicationManager').patchValue('');
    this.registerFormApp.get('versionApp').patchValue('');
    this.registerFormApp.get('statusApp').patchValue(true);
    this.registerFormApp.get('external').patchValue(true);
    this.registerFormApp.get('typeApp').patchValue('');
    this.registerFormApp.get('deploymentApp').patchValue('');
    this.registerFormApp.get('descApp').patchValue('');
    this.registerFormApp.get('moduleApp').patchValue('');
    this.registerFormApp.get('descMod').patchValue('');
    this.registerFormApp.get('manager').patchValue('');
  }
  checkAppnameAdd() {
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.dataLineService.checkApp(this.registerFormApp.get('nameApp').value).subscribe(
      d => {
        // @ts-ignore
        if (d.response.indexOf('taken') != -1) {this.presubmitAdd = true; this.messagedangerAdd = this.registerFormApp.controls.nameApp.value + ' exist choose another application !'; } else {this.presubmitAdd = false; this.messagesuccesAdd = this.registerFormApp.controls.nameApp.value + ' is available !'; }},
      e => {console.error(e); }
    );
  }
  addSystem() {
    const desc = window.btoa(encodeURIComponent(this.registerFormApp.get('descApp').value));
    this.registerFormApp.get('descApp').patchValue(desc);
    const username = localStorage.getItem('username');
    this.registerFormApp.get('createdBy').patchValue(username);
    this.dataLineService.addSystem(JSON.stringify(this.registerFormApp.value)).subscribe(
      d => {
        this.Toast.fire({
          icon: 'success',
          title: this.lang === 'en' ? 'Added in successfully' : 'Ajouté avec succès'
        });
        $('#addSystem').modal('hide');
        this.getApplicationsByType('YES');
      },
      err => {console.error(err); }
    );
  }

  addExternalSystem() {
    this.onClose();
    $('#addSystem').modal();
  }

  openTableField(i) {
    this.tableModalValue = [];
    this.customModalFields = [];
    if (this.ngTables[i] != undefined) {
      $('#addTableFieldTitle').html(this.lang === 'en' ? 'Edit Table and Fields' : 'Modifier la table et les champs');
    }
    this.error1 = false;
    this.getTablesByObjectModal(i);
    this.tableFieldIndex = i;
    $('#addTableField').modal();
  }
  addTableField() {
    let data = [];
    for ( let i = 0; i < this.tableModalValue.length; i++) {
      if (this.customModalFields[i] == undefined) {
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.tableModalValue[i].name : 'Vous devez fournir des champs pour la table ' + this.tableModalValue[i].name;
        this.error1 = true;
        return;        
      }
      if (this.customModalFields[i].length == 0) {
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.tableModalValue[i].name : 'Vous devez fournir des champs pour la table ' + this.tableModalValue[i].name;
        this.error1 = true;
        return;       
      }
    }
    this.ngTables[this.tableFieldIndex] = this.tableModalValue;
    this.tableModalValue.map( (table, i) => {
      data = data.concat(this.customModalFields[i]);
    });
    this.ngFields[this.tableFieldIndex] = data;
    $('#addTableField').modal('hide');
    this.tableModalValue = [];
    this.customModalFields = [];
  }
  getFieldsByTableModal() {
    //  console.log(this.tableModalValue);
    this.tableModalValue.map(
      (table, i) => {
        this.loadingField[i] = true;
        const d = table.id.replace(/\//g, '__________');
        this.dataLineService.getFieldsByTableModal(d).subscribe(
          d => {
            this.fields[i] = d.data;
            this.loadingField[i] = false;
            if (this.ngFields[this.tableFieldIndex] != undefined) {
              // console.log(this.ngFields[this.tableFieldIndex]);
              this.customModalFields[i] = this.ngFields[this.tableFieldIndex].filter(e => e.table == table.name);
              this.customModalFields[i] = [...this.customModalFields[i]];
              //  console.log(this.customModalFields[i]);
            }
          },
          e => {
            console.error(e);
          }
        );
      }
    );
  }
  getTablesByObjectModal(i) {
    this.loadingTableModal = true;
    this.tableModalValue = [];
    const dd = this.ngObjects[i].id.replace(/\//g, '__________');
    console.log(dd);
    this.dataLineService.getTablesByObject(dd).subscribe(
      d => {
        this.tablesModal = d.data;
        this.loadingTableModal = false;
        if (this.ngTables[i] != undefined) {
          this.tableModalValue = [...this.ngTables[i]];
          this.getFieldsByTableModal();
        }
      },
      e => {
        console.error(e);
      }
    );
  }
  openCustomTableField(i) {
    this.error2 = false;
    this.customTableModalValue = [];
    this.customTableModal = [];
    this.customModalFields = [];
    this.customFields = [];
    if (this.ngCustomTable[i] != undefined) {
      $('#addExternalTableFieldTitle').html(this.lang === 'en' ? 'Edit External Table and field' : 'Modifier la table externe et le champ');
      this.customTableModalValue = [...this.ngCustomTable[this.tableFieldIndex]];
      this.customTableModalValue.map(
        (tab , i) => this.customFields[i] = this.customModalFields[i] = this.ngCustomsFields[this.tableFieldIndex].filter(e => e.table == tab.name)
      );
    }
    this.tableFieldIndex = i;
    $('#addExternalTableField').modal();
  }
  addCustomTableField() {
    let data = [];
    for ( let i = 0; i < this.customTableModalValue.length; i++) {
      if (this.customModalFields[i] == undefined) {
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.customTableModalValue[i].name : 'Vous devez fournir des champs pour la table ' + this.customTableModalValue[i].name;
        this.error2 = true;
        return;        
      }
      if (this.customModalFields[i].length == 0) {
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.customTableModalValue[i].name : 'Vous devez fournir des champs pour la table ' + this.customTableModalValue[i].name;
        this.error2 = true;
        return;        
      }
    }
    this.ngCustomTable[this.tableFieldIndex] = this.customTableModalValue;
    this.customTableModalValue.map( (table, i) => {
      this.customModalFields[i].map(e => e.table = table.name);
      data = data.concat(this.customModalFields[i]);
    });
    this.ngCustomsFields[this.tableFieldIndex] = data;
    // console.log(this.ngCustomTable[this.tableFieldIndex],this.ngCustomsFields[this.tableFieldIndex] );
    $('#addExternalTableField').modal('hide');
  }

  closeAddTableField() {
    $('#addTableField').modal('hide');
  }
  closeAddCustomTableField() {
    $('#addExternalTableField').modal('hide');
  }
  getApplication(nodeItem) {
    return nodeItem.application != null ? nodeItem.application.name : nodeItem.customApplication.name;
  }
  async getEditorExtra() {
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.extraDescription) {
        CKEDITOR.instances.extraDescription.destroy(true);
      }
      if ( $('#extraDescription').length > 0 ) {
        const editorDescription = CKEDITOR.replace('extraDescription');
        editorDescription.config.height = 60;
        editorDescription.setData(this.extraDescription);
        editorDescription.on( 'change', evt => {
          this.extraDescription = evt.editor.getData();
          const extraItem = this.extraItems.find(e => e.id == this.clicked.id);
          extraItem.description = btoa(this.extraDescription);
          // console.log(this.extraItems);
        });
      }
    }).catch(error => console.error(error));
  }

  geTreeLine() {
    this.getTreeData();
    $('#LineageTreeModal').modal('show');
    setTimeout(() => {this.simulation = renderTreeLine(this.modalData); }, 500);
  }
  unifyObject(clicked: NodeItem) {
    const ids = [];
    const treeData = [];
    if (clicked.data.length > 0) {
      clicked.data.map(item => {
        const data = [];
        if (ids.indexOf(item.model.id) == -1) {
          clicked.data.filter(i => i.model.id == item.model.id).map(
            j => {
              data.push({object : j.object, children: j.children});
            }
          );
          ids.push(item.model.id);
          treeData.push({model: item.model, data, color: clicked.application.color, title: clicked.text});
        }
      });
    } else {
      clicked.customs.map(item => {
        const data = [];
        if (ids.indexOf(item.model) == -1) {
          clicked.customs.filter(i => i.model == item.model).map(
            j => {
              data.push({object : {id: j.object, name: j.object}, children: j.children});
            }
          );
          ids.push(item.model);
          treeData.push({model: {id: item.model, name: item.model}, data, color: clicked.customApplication.color, title: clicked.text});
        }
      });
    }
    return treeData;
  }
  getTreeData() {
    let i = 1;
    let k = 1;
    const nodes = [];
    const links = [];
    const data = [];
    // console.table(this.nodesItems);
    this.nodesItems.map(n => data.push(this.unifyObject(n)));
    // console.log(data);
    // tree node
    data.map(
      (nodeItem, j) => {
        nodeItem.map(
          m => {
            const modIndex = i;
            const modNode = {id: modIndex, name: m.model.name, idModel: m.model.id, type: 'model', group: j, color: m.color, title: m.title};
            nodes.push(modNode);
            // object loop
            m.data.map(
              det => {
                i++;
                const objIndex = i;
                const objNode = {id: objIndex, name: det.object.name, idObject: det.object.id, type: 'object', group: j , color: m.color, title: m.title};
                const linkObj = {id: k, source : modIndex , target: objIndex};
                nodes.push(objNode);
                links.push(linkObj);
                // table loop
                det.children.map(
                  t => {
                    i++;
                    k++;
                    const tabIndex = i;
                    const tabNode = {id: tabIndex, name: t.table.name, idTable: t.table.id, type: 'table', group: j , color: m.color, title: m.title};
                    const linkTab = {id: k, source : objIndex , target: tabIndex};
                    nodes.push(tabNode);
                    links.push(linkTab);
                    // fields loop
                    t.fields.map(
                      f => {
                        i++;
                        k++;
                        const fieldNode = {id: i, name: f.name, idField: f.id, type: 'field', group: j , color: m.color, title: m.title};
                        const linkField = {id: k, source : tabIndex , target: i};
                        nodes.push(fieldNode);
                        links.push(linkField);
                      }
                    );
                  }
                );
                k++;
              }
            );
            i++;
          }
        );
      }
    );
    this.modalData = {links, nodes};
    // console.log(nodes);
  }

  removeEdge() {
    // console.log(this.selectedEdge);
    const group = this.selectedEdge.group;
    const links = this.edges.filter(e => e.group == group);
    if ( links.length > 0) {
      links.map(
        l => l.element.remove()
      );
    }
    this.edges = this.edges.filter(e => e.group != group);
    this.linesArch = this.linesArch.filter(e => e.group != group);
    if(this.clicked!= null && this.clicked.data!= undefined){
      this.getEdges(this.clicked);
    }
    this.selectedEdge = null;
  }
  removeEdgeTerm(){
    const group = this.selectedEdge.group;
    const links = this.edges.filter(e => e.group == group);
    if ( links.length > 0) {
      links.map(
        l => l.element.remove()
      );
    }
    this.edges = this.edges.filter(e => e.group != group);
    if(this.clicked!= null && this.clicked.status!= undefined){
      this.getEdgesTerm();
    }
    this.selectedEdge = null;
    this.clicked = null;
  }
  highLightEdge() {
    console.log(this.selectedEdge);
    this.edges.map(
      l => {
        l.element.outline = false;
      }
    );
    this.selectedEdge.element.outline = true;
    this.selectedEdge.element.setOptions({
      outlineColor: 'rgb(1,7,12)'
    });
  }
  getEdgesForRemove(node: NodeItem) {
    this.selectedEdges = [];
    if (node.data.length > 0) {
      node.data.map(
        d => {
          const startObject = 'start_' + d.object.id;
          const endObject = 'end_' + d.object.id;
          const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject));
          if (objectEdges.length > 0) {
            this.selectedEdges = this.selectedEdges.concat(objectEdges);
          }
          if (d.children.length > 0) {
            d.children.map(
              t => {
                t.fields.map(
                  f => {
                    const startField = 'start_' + f.id;
                    const endField = 'end_' + f.id;
                    const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField));
                    if (fieldEdges.length > 0) {
                      this.selectedEdges = this.selectedEdges.concat(fieldEdges);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
    if (node.customs.length > 0) {
      node.customs.map(
        d => {
          const startObject = 'start_' + d.object.id;
          const endObject = 'end_' + d.object.id;
          const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
          if (objectEdges.length > 0) {
            this.selectedEdges = this.selectedEdges.concat(objectEdges);
          }
          if (d.children.length > 0) {
            d.children.map(
              t => {
                t.fields.map(
                  f => {
                    const startField = 'start_' + f.id;
                    const endField = 'end_' + f.id;
                    const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField));
                    if (fieldEdges.length > 0) {
                      this.selectedEdges = this.selectedEdges.concat(fieldEdges);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
    if (this.selectedEdges.length > 0) {
      this.selectedEdges.map(
        ed => {
          ed.element.remove();
          this.edges = this.edges.filter(e => e.group != ed.group);
          this.linesArch = this.linesArch.filter(e => e.group != ed.group);
        }
      );
    }
  }
  getEdgesForRemoveProcess(node: ExtraItem) {
    this.selectedEdges = [];
    console.log(node);
    console.log(node.connectors);
    node.connectors.map(
      con => {
        const edges = this.edges.filter( e => e.source == con.id || e.target == con.id );
        console.log(edges);
        if (edges.length > 0 ) {
          edges.map(l => {
            this.edges = this.edges.filter(e => e.source != con.id && e.target != con.id);
            l.element.remove();
            this.linesArch = this.linesArch.filter(e => e.line.source != con.id && e.line.target != con.id);
          });
        }
      }
    );
  }
  test(t: any) {
    console.log(t);
  }

  saveDataLineage() {
    // const data = this.saveFrm.value;
    this.nodesItems.map(item => {
      const obj = $('#' + item.id);
      item.style = obj.offset();
      //item.description = item.description!=null? item.description.replace('&', '##38'): item.description
    });
    this.businessObject.definition = this.businessObject.definition!=null? this.businessObject.definition.replace('&', '##38'): this.businessObject.definition;
    this.businessObject.example = this.businessObject.example!=null? this.businessObject.example.replace('&', '##38'): this.businessObject.example;
    const data = {nodes: this.nodesItems, business: this.businessObject, links: this.edges, styles: {flatDesign: this.flatDesign, pattern: this.pattern, zoom: this.zoom}};
   // const content = btoa(JSON.stringify(data));
    //const t = { content};
    // console.log(t);return;
    if (this.id != null && this.id != 0) {
      this.businessTermService.saveCatalogueLineage(data).subscribe(
        d => {
          this.Toast.fire({
            icon: 'success',
            title: this.lang === 'en' ? 'Related catalogue saved successfully' : 'Catalogue associé enregistré avec succès'
          });
        }, err =>  {
          this.Toast.fire({
            icon: 'error',
            title: err
          });
        }
      );
    }
  }
  getCatalogueLineage(id: string) {
    this.businessTermService.getCatalogueLineage(id).subscribe(
      d => {
        this.importData = d.catalogue;
        if (this.importData != null) {
          this.importData = atob(this.importData);
          this.importData = JSON.parse(this.importData);
        }
        // console.log(this.importData.content);
        // console.log(this.importData);
        if (this.importData != null) {
          this.importNodes();
        } else {
          this.removeLoader();
        }
        $('#spinner_getListApprovedObject').hide();
      },
      err => {
        console.log(err);
      }
    );
  }
  async importNodes() {
    $('body').css('position', '');
    this.nodesItems = [];
    this.extraItems = [];
    this.linesArch = [];
    this.edges = [];
    for (let i = 0; i < this.importData.content.nodes.length; i++) {
      const item = new NodeItem(this.importData.content.nodes[i].id, this.importData.content.nodes[i].icon, this.importData.content.nodes[i].text, this.importData.content.nodes[i].application, this.importData.content.nodes[i].extern, this.importData.content.nodes[i].catalogue, this.importData.content.nodes[i].data, this.importData.content.nodes[i].description, this.importData.content.nodes[i].customApplication, 'black', this.importData.content.nodes[i].customs, this.importData.content.nodes[i].applicationType, this.importData.content.nodes[i].trigger, this.importData.content.nodes[i].type, this.importData.content.nodes[i].style);
      this.addHtmlContent(item);
    }
    $('.collapse-lg').toggle();
    for (let j = 0; j < this.importData.content.extra.length; j++) {
      const item = new ExtraItem(this.importData.content.extra[j].id, this.importData.content.extra[j].description, this.importData.content.extra[j].connectors, this.importData.content.extra[j].type, this.importData.content.extra[j].style, this.importData.content.extra[j].trigger, this.importData.content.extra[j].lines);
      this.addExtraHtml(item);
    }

    const links = this.importData.content.links.filter(nd => nd.parent);
    console.log(links);
    // // $('#' + $.escapeSelector(links[0].source)).click().focus();
    // // $('#' + $.escapeSelector(links[0].target)).click().focus();
    for (let i = 0; i < links.length; i++) {
      $('#' + $.escapeSelector(links[i].source)).click().focus();
      await this.sleep(500);
      $('#' + $.escapeSelector(links[i].target)).click().focus();
      console.log('test');
      // setTimeout(() => {$('#' + $.escapeSelector(links[i].target)).click().focus(); }, 300);
    }
    // for(let k = 0; k < this.importData.content.links.length; k++){
    //   this.addLine(this.importData.content.links[k].source, this.importData.content.links[k].target, this.importData.content.links[k].startColor, this.importData.content.links[k].endColor, this.importData.content.links[k].hide, this.importData.content.links[k].parent, this.importData.content.links[k].sourceName, this.importData.content.links[k].targetName, this.importData.content.links[k].group);
    // }
    // this.importData.content.links.map(nd => {
    //   // $('#' + $.escapeSelector(nd.source)).click().focus();
    //   // setTimeout(() => {$('#' + $.escapeSelector(nd.target)).click().focus(); }, 1000);
    //  this.addLine(nd.source, nd.target, nd.startColor, nd.endColor, nd.hide, nd.parent, nd.sourceName, nd.targetName, nd.group);
    // });
    $('#play-area').click();
    // for(let z = 0; z < this.importData.content.lineArch.length; z++){
    //   const line = this.edges.find(e => e.source == this.importData.content.lineArch[z].start && e.target == this.importData.content.lineArch[z].end).element;
    //   const parent = this.edges.find(e => e.source == this.importData.content.lineArch[z].source && e.target == this.importData.content.lineArch[z].target).element;
    //   const leftParent = this.edges.find(e => e.source == this.importData.content.lineArch[z].start && e.target == this.importData.content.lineArch[z].target).element;
    //   const rightParent = this.edges.find(e => e.source == this.importData.content.lineArch[z].end && e.target == this.importData.content.lineArch[z].source).element;
    //   this.linesArch.push({line , parent , source: this.importData.content.lineArch[z].source, target: this.importData.content.lineArch[z].target, leftParent , rightParent, groupId: this.importData.content.lineArch[z].groupId, start: this.importData.content.lineArch[z].start, end: this.importData.content.lineArch[z].end});
    // }
    // console.log(this.linesArch);
    // this.importData.content.lineArch.map(nd => {
    //   //  const line = this.addLine(this.startConnector, this.endConnector, this.startColor, that.endColor, false, true, that.startName, that.endName, groupId);
    //   //  const parentLine = this.addLine(this.startParent, this.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
    //   //  const leftLine = this.addLine(this.startConnector, this.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
    //   //  const rightLine = this.addLine(this.endConnector, that.startParent, that.endColor, that.startColor, true, false, that.startName, that.endName, groupId);
    //   // that.linesArch.push({line, parent: parentLine, source: this.startParent, target: that.endParent, leftParent: leftLine, rightParent: rightLine, groupId, start: that.startConnector, end : that.endConnector, startParent: that.startParent, endParent: that.endParent});
    //    const line = this.edges.find(e => e.source == nd.start && e.target == nd.end).element;
    //    const parent = this.edges.find(e => e.source == nd.source && e.target == nd.target).element;
    //    const leftParent = this.edges.find(e => e.source == nd.start && e.target == nd.target).element;
    //    const rightParent = this.edges.find(e => e.source == nd.end && e.target == nd.source).element;
    //    this.linesArch.push({line , parent , source: nd.source, target: nd.target, leftParent , rightParent, groupId: nd.groupId, start: nd.start, end: nd.end});
    // });
    this.updateConnectors();
    this.flatDesign = this.importData.content.styles.flatDesign;
    if (this.flatDesign) {
      if ($('.node-item').length > 0) {
        $('.node-item').removeClass('shadow-design');
      }
    } else {
      if ($('.node-item').length > 0) {
        $('.node-item').addClass('shadow-design');
      }
    }

    this.pattern = this.importData.content.styles.pattern;
    this.changePattern(this.pattern);
    this.zoom = this.importData.content.styles.zoom;
    this.removeLoader();
  }
  addHtmlContent(nodeItem: NodeItem) {
    $('body').css('position', '');
    const that = this;
    const id = nodeItem.id;
    const childId = 'childs_' + id;
    const headerId = 'header_' + id;
    // const back = ['bg-info', 'bg-success', 'bg-primary', 'bg-danger', 'bg-secondary'];
    let bgColor = '#000000';
    if (nodeItem.application != undefined) {
      bgColor = nodeItem.application.color;
    }
    if (nodeItem.customApplication != undefined) {
      bgColor = nodeItem.customApplication.color;
    }
    //  console.log(nodeType, nodeText);
    let objectsHtml = '';
    if (!nodeItem.extern) {
      if (nodeItem.catalogue) {
        nodeItem.data.map(obj => {
          if (obj.children != undefined) {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
            if (obj.children.length > 0 ) {
              // objectsHtml += '<li style="background-color: white;border-top:0" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
              objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
                '<ul class="list-group list-group-flush text-black collapse-lg" style="border-top: 1px solid #f3dcdc">' + this.getTables(obj.object.id, obj.children) + '</ul>';
            }
          } else {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
          }
        });
      } else {
        nodeItem.customs.map(obj => {
          if (obj.children != undefined ) {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
            if (obj.children.length > 0 ) {
              objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.name + '" style="float: left;color:#31b7f9;cursor:pointer;display:none"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.name + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
                '<ul class="list-group list-group-flush text-black  collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(obj.object.id, obj.children) + '</ul>';
            }
          } else {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line"  id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
          }
        });
      }
    } else {
      nodeItem.customs.map(obj => {
        if (obj.children != undefined ) {
          objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
          if (obj.children.length > 0 ) {
            objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
              '<ul class="list-group list-group-flush text-black  collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(obj.object.id, obj.children) + '</ul>';
          }
        } else {
          objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
        }
      });
    }
    this.tabIndex++;
    const html = this.getNodeStyle() + bgColor + '" id="' + id + '" tabindex="' + this.tabIndex + '">\n' +
      '<span class="fa-stack" style="align-self: center;font-size: 1.2em;padding-top: 1.3px"><i class="fas fa-circle fa-stack-2x" style=";vertical-align: middle; color:' + bgColor + '"></i> ' + this.getImageFromType(nodeItem.icon, bgColor) + '</span>' +
      '<div class="card-header text-center text-white node-header"  style="background-color: rgb(60 172 196);border-top-left-radius: 23px;\n' +
      '    border-top-right-radius: 23px;text-transform: capitalize" id="' + headerId + '">' + nodeItem.text + '</div>\n' +
      // '      </div>\n' +
      '      <ul class="list-group list-group-flush text-black node-details flat-details" id="' + childId + '" >\n' + objectsHtml +
      '      </ul>\n' +
      '      <div class="card-body tools-line tools-line-flat">\n' +
      '        <span class="remove-drag" data-item="' + id + '"><i class="fas fa-times-circle edit-remove"></i></span>\n' +
      '        <span style="float:right">&nbsp;' + this.getApplication(nodeItem) + '</span>\n' +
      '      </div>\n' +
      '      <div id="line-wrapper"></div>\n' +
      '    </div>';
    $('#play-area').append(html);
    // $('.tree-modal').off().on('click', function(e) {
    //   e.stopPropagation();
    //   $('#LineageTreeModal').modal('show');
    //   const clicked = that.nodesItems.find(t => t.id == $(this).attr('data-item') );
    //   that.getNodeData(clicked);
    //   setTimeout(() => {renderTreeLine(that.modalData);}, 500);
    // });
    $('.show-node-Item').off().on('click', function() {
      $(this).parent().next().toggle();
      const p = $(this).attr('data-object');
      const start = 'start_' + p;
      const end = 'end_' + p;
      const childLines = that.linesArch.filter(item => item.source == start || item.target == start || item.source == end || item.target == end);
      console.log(childLines);
      // console.log(that.linesArch);
      if ($(this).hasClass('fa-angle-double-down') ) {
        $(this).removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        if (childLines != undefined) {
          childLines.map(l => {
            if ( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') == 'none') {
              l.line.hide('none'); l.parent.show('none'); l.leftParent.hide('none'); l.rightParent.hide();
            }
            if ( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') != 'none') {
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.show('none');
            }
            if ( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') == 'none') {
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.show('none'); l.rightParent.hide('none');
            }
            if ( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') != 'none') {
              l.line.show('none'); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.hide('none');
            }
          });
        }
        that.updateConnectors();
        if ($(this).next('i').length > 0 ) {
          $(this).next('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        }
        if ($(this).prev('i').length > 0 ) {
          $(this).prev('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        }
      } else {
        $(this).removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        if (childLines != undefined) {
          childLines.map(l => {
            if ( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') == 'none') {
              l.line.hide('none'); l.parent.show('none'); l.leftParent.hide('none'); l.rightParent.hide('none');
            }
            if ( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') != 'none') {
              l.line.hide(); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.show('none');
            }
            if ( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') == 'none') {
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.show('none'); l.rightParent.hide('none');
            }
            if ( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') != 'none') {
              l.line.hide('none'); l.parent.show('none'); l.leftParent.hide('none'); l.rightParent.hide('none');
            }
          });
        }
        that.updateConnectors();
        if ($(this).next('i').length > 0 ) {
          $(this).next('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        }
        if ($(this).prev('i').length > 0 ) {
          $(this).prev('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        }
      }
    });
    that.enableLinking();
    this.makeDraggable(id);
    window.scrollBy(5, 100);
    window.scrollBy(-5, -100);
    this.nodesItems.push(nodeItem);
    $('#addLineageModalClose').click();
    $('#' + id).offset(nodeItem.style).on('click', function(event) {
      event.stopPropagation();
      if (that.clicked != null) {
        if (that.clicked.id == $(this).attr('id')) {
          return;
        }
      }
      $('.node-item').removeClass('selected-node');
      $('.business-item').removeClass('selected-node');
      $(this).addClass('selected-node').focus();
      //  console.log($(this).attr('id') );
      that.clickedEdges = [];
      that.clicked = that.nodesItems.find(t => t.id == $(this).attr('id') );
      //  console.log(that.clicked);
      that.getNodeData(that.clicked);
      that.getEdges(that.clicked);
      // end tree node
      // console.log(that.orgData);

      // const nodeTemplate = (data) => {
      //   return `
      //     <span class="icon-org">${data.icon}</span>
      //     <div class="title">${data.name}</div>
      //     <div class="content">${data.title}</div>
      //   `;
      // };
      //
      // let htmk= '';
      // that.orgData.map(
      //   (u,i) => { htmk += '<div id="org-container_' + i + '" style="position: relative;height: 420px;border: 1px solid #aaa;margin: 0.5rem;text-align: center;overflow:hidden" ></div>'; }
      // );
      // setTimeout(() => {
      //
      //   $('#chart-container').empty().append(htmk);
      //   that.orgData.map(
      //     (u, i) => {
      //      // console.log(u);
      //      // console.log(i);
      //       const oc = $('#org-container_' + i).orgchart({
      //         data : u,
      //         nodeContent: 'title',
      //         nodeTemplate,
      //         pan: true,
      //         zoom: true
      //       });
      //       oc.$chartContainer.on('touchmove', function(event) {
      //         event.preventDefault();
      //       });
      //     }
      //   );
      // }, 1000);

    }).keydown(function(e) {
      if (e.key === 'Delete') {
        let title = this.lang === 'fr' ? 'Supprimer l\'objet de données ?' : 'DELETE Data Object?';
        let text = this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!';
        let confirmButtonText = this.lang === 'fr' ? 'Oui, confirmer !' : 'Yes, Approved it!';
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        
        Swal.fire({
          title: title,
          text: text,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id');
            const nodeItem = that.nodesItems.find(e => e.id == id);
            that.getEdgesForRemove(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.nodesItems.splice(that.nodesItems.indexOf(that.nodesItems.find( e => e.id == id)), 1) ;
              $('#play-area').click();
            }
          }
        });
      }
    });
    this.targets.push({name: nodeItem.text, id});
    this.targets = [...this.targets];
    // this.initConnectors();
    $('.card-body').off().on('click', '.remove-drag', function(e) {
      let  titleKey = this.lang === 'fr' ? 'Supprimer l\'objet de données ?' : 'DELETE Data Object?';
      let  textKey = this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!';
      let  confirmKey = this.lang === 'fr' ? 'Oui, confirmer !' : 'Yes, Approved it!';
      let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
      Swal.fire({
        title: titleKey,
        text: textKey,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmKey,
        cancelButtonText: cancelButtonText
      }).then((result) => {
        if (result.value) {
          const id = $(this).attr('data-item');
          const nodeItem = that.nodesItems.find(e => e.id == id);
          that.getEdgesForRemove(nodeItem);
          const t = that.draggables.find(item => item.id == id);
          if (t != undefined) {
            t.element.remove();
            that.draggables = that.draggables.filter(item => item.id != id);
            $('#' + id).remove();
            that.nodesItems.splice(that.nodesItems.indexOf(that.nodesItems.find( e => e.id == id)), 1) ;
          }
        }
      });
    }); /*.on('click', '.add-drag', function() {
      that.selectedId = $(this).attr('data-item');
      const nodeItem = that.nodesItems.find(e => e.id == that.selectedId);
      that.editMode = true;
      that.resetModelObjectTableFields();
      that.resetCustomModelObject();
      that.modelAndObjectAndTableAndFields = [];
      that.customModelAndObjects = [];
      that.ngNodeText = nodeItem.text;
      that.ngExtern = nodeItem.extern;
      that.ngTrigger = nodeItem.trigger;
      if(nodeItem.applicationType!= null){
        that.ngTypeApp = nodeItem.applicationType.id;
      }
      if(nodeItem.application!= null){
        that.ngApp = nodeItem.application;
      }
      that.ngCatalogue = nodeItem.catalogue;
      // bind data to from catalogue model,object,table and fields
      if(nodeItem.data.length > 0) {
        nodeItem.data.map(
          (item , i) => {
            that.modelAndObjectAndTableAndFields.push(i);
            that.ngModels[i] = item.model;
            that.ngObjects[i] = item.object;
            that.ngTables[i] = [];
            if(item.children.length > 0) {
              item.children.map(
                tab => {
                  that.ngTables[i].push(tab.table);
                  that.ngFields[i] = tab.fields;
                }
              );
            }
          }
        );
      }
      if(nodeItem.customApplication!= null){
        that.ngCustomApplication = nodeItem.customApplication;
      }
      // bind data to from catalogue model,object,table and fields
      if(nodeItem.customs.length > 0) {
        nodeItem.customs.map(
          (item , i) => {
            that.customModelAndObjects.push(i);
            that.ngCustomModel[i] = item.model.name;
            that.ngCustomObjects[i] = item.object.name;
            that.ngCustomTable[i] = [];
            if(item.children.length > 0) {
              item.children.map(
                tab => {
                  that.ngCustomTable[i].push({id: tab.table, name: tab.table});
                  that.ngCustomsFields[i] = tab.fields;
                }
              );
            }
          }
        );
      }
      that.ngDescription = atob(nodeItem.description);
      $('#addLineageModal').modal('show');
    });*/
  }
  decryptDescr(t: string) {
    return atob(t);
  }
  addExtraHtml(extraItem: ExtraItem) {
    const that = this;
    const type = extraItem.type;
    if (type == 'start') {
      const start = document.createElement('div');
      this.tabIndex++;
      $(start).addClass('node-item lg-start').attr('id', extraItem.id).css({width: '52px', height: '52px', position: 'absolute'}).attr('tabindex', this.tabIndex).html('<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x" style="background: -webkit-linear-gradient(to right, #38ef7d, #11998e);\n' +
        '                          background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ -webkit-background-clip: text; -webkit-text-fill-color: transparent;"></i>\n' +
        '                        <i class="fas fa-circle fa-stack-1x fa-inverse"></i></span>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="start"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="start"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing" title="remove this start"></i>');
      if (!this.flatDesign) {
        $(start).addClass('shadow-design');
      }
      document.getElementById('play-area').appendChild(start);
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(start).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $('.business-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if (e.key === 'Delete') {
          let  titleText = this.lang === 'fr' ? 'Supprimer l\'objet de démarrage ?' : 'DELETE Start Object?';
          let  confirmButtonText = this.lang === 'fr' ? 'Oui, approuvez-le !' : 'Yes, Approved it!';
          let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
          
          Swal.fire({
            title: titleText,
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        let titleText = this.lang === 'fr' ? 'Supprimer l\'objet de démarrage ?' : 'DELETE Start Object?';
        let confirmButtonText = this.lang === 'fr' ? 'Oui, approuvez-le !' : 'Yes, Approved it!';
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        
        Swal.fire({
          title: titleText,
          text: this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'end') {
      const end = document.createElement('div');
      this.tabIndex++;
      $(end).addClass('node-item lg-end').attr('id', extraItem.id).css({width: '52px', height: '52px', position: 'absolute'}).attr('tabindex', this.tabIndex).html('<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x" style=" background: -webkit-linear-gradient(to right, #EA384D, #D31027);  /* Chrome 10-25, Safari 5.1-6 */\n' +
        '                          background: linear-gradient(to right, #EA384D, #D31027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ -webkit-background-clip: text; -webkit-text-fill-color: transparent;"></i>\n' +
        '                        <i class="far fa-circle fa-stack-1x fa-inverse"></i></span>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="end"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="end"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-end-process" title="remove this end"></i>');
      if (!this.flatDesign) {
        $(end).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(end);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(end).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $('.business-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if (e.key === 'Delete') {
          let titleText = this.lang === 'fr' ? 'Supprimer l\'objet de fin ?' : 'DELETE End Object?';
          let confirmButtonText = this.lang === 'fr' ? 'Oui, approuvez-le !' : 'Yes, Approved it!';
          let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
          
          Swal.fire({
            title: titleText,
            text: this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        let titleText = this.lang === 'fr' ? 'Supprimer l\'objet de fin ?' : 'DELETE End Object?';
        let confirmButtonText = this.lang === 'fr' ? 'Oui, approuvez-le !' : 'Yes, Approved it!';
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        
        Swal.fire({
          title: titleText,
          text: this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'aggregate') {
      const aggregate = document.createElement('div');
      this.tabIndex++;
      $(aggregate).addClass('node-item lg-aggregate').attr('id', extraItem.id).attr('tabindex', this.tabIndex).css({width: '60px', height: '60px', position: 'absolute'}).html('     <svg style="width: 60px; height: 60px;">\n' +
        '                        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">\n' +
        '                          <stop offset="0%" stop-color="#0072ff"></stop>\n' +
        '                          <stop offset="100%" stop-color="#00c6ff"></stop>\n' +
        '                        </linearGradient>\n' +
        '                        <g style="transform: translate(16px, 10px)">\n' +
        '                          <circle cx="15" cy="18" r="26" fill="url(#linearColors1)"></circle>\n' +
        '                          <path fill="white" d="M 30.3188 26.8 H 27.075 c 2.1531 -2.8563 3.3375 -6.425 3.3375 -10.0531 c 0 -8.7844 -6.6344 -15.9313 -14.7875 -15.9313 c -8.1563 0 -14.7875 7.1469 -14.7875 15.9313 c 0 3.6281 1.1844 7.1969 3.3375 10.0531 H 0.9313 c -0.3406 0 -0.6188 0.2781 -0.6188 0.6188 V 29.8125 c 0 0.3406 0.2781 0.6188 0.6188 0.6188 H 9.7813 c 0.3406 0 0.6188 -0.2781 0.6188 -0.6188 v -2.6625 c 0 -0.1469 -0.0531 -0.2906 -0.1469 -0.4031 l -0.0344 -0.0406 c -0.0344 -0.0406 -0.075 -0.0781 -0.1187 -0.1094 c -3.0438 -2.1094 -4.9375 -5.8844 -4.9375 -9.85 c 0 -6.3969 4.6937 -11.6031 10.4625 -11.6031 c 5.7687 0 10.4625 5.2062 10.4625 11.6031 c 0 3.9813 -1.9031 7.7656 -4.9688 9.8719 c -0.1688 0.1156 -0.2687 0.3063 -0.2687 0.5094 V 29.8125 c 0 0.3406 0.2781 0.6188 0.6188 0.6188 h 8.8469 c 0.3406 0 0.6188 -0.2781 0.6188 -0.6188 v -2.3906 C 30.9375 27.0781 30.6594 26.8 30.3188 26.8 z"></path>\n' +
        '                        </g>\n' +
        '                      </svg>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="aggregate"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="aggregate"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-aggregate-process" title="remove this aggregation"></i>');
      if (!this.flatDesign) {
        $(aggregate).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(aggregate);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(aggregate).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $('.business-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if (e.key === 'Delete') {
          Swal.fire({
            title: 'DELETE Aggregate Object?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Approved it!'
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        let titleText = this.lang === 'fr' ? 'Supprimer l\'objet agrégé ?' : 'DELETE Aggregate Object?';
        let  confirmButtonText = this.lang === 'fr' ? 'Oui, approuvez-le !' : 'Yes, Approved it!';
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        
        Swal.fire({
          title: titleText,
          text: this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'condition') {
      const condition = document.createElement('div');
      this.tabIndex++;
      $(condition).addClass('node-item lg-condition').attr('id', extraItem.id).attr('tabindex', this.tabIndex).css({width: '60px', height: '60px', position: 'absolute'}).html('   <svg style="width: 60px; height: 60px;">\n' +
        '                        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">\n' +
        '                          <stop offset="0%" stop-color="#0072ff"></stop>\n' +
        '                          <stop offset="100%" stop-color="#00c6ff"></stop>\n' +
        '                        </linearGradient>\n' +
        '                        <g style="transform: translate(16px, 10px);">\n' +
        '                          <circle cx="14" cy="17" r="26" fill="url(#linearColors1)"></circle>\n' +
        '                          <path fill="white" d="M 11.8438 31 L 0.6875 17.8562 c -0.9125 -1.075 -0.9125 -2.6375 0 -3.7188 L 11.8438 1 c 1.1313 -1.3375 3.1812 -1.3313 4.3125 0 L 27.3125 14.1438 c 0.9125 1.075 0.9125 2.6375 0 3.7188 L 16.1563 31 c -1.1313 1.3375 -3.1812 1.3313 -4.3125 0 z M 3 16 l 11 12.9063 L 25 16 L 14 3.0938 L 3 16 z"></path>\n' +
        '                        </g>\n' +
        '                      </svg>' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="condition: yes"></i><i class="fas fa-circle connector end condition-line" id="end_' + extraItem.id + '" data-name="condition: no"></i></i><i class="fas fa-circle connector input-condition extra-line" id="input_' + extraItem.id + '" data-name="condition: input"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-condition-process" title="remove this condition"></i>');
      if (!this.flatDesign) {
        $(condition).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      connectors.push({id: 'input_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(condition);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(condition).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $('.business-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if (e.key === 'Delete') {
          let titleText = this.lang === 'fr' ? 'Supprimer l\'objet de condition ?' : 'DELETE Condition Object?';
          let confirmButtonText = this.lang === 'fr' ? 'Oui, approuvez-le !' : 'Yes, Approved it!';
          let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
          
          Swal.fire({
            title: titleText,
            text: this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        let titleText = this.lang === 'fr' ? 'Supprimer l\'objet de condition ?' : 'DELETE Condition Object?';
        let confirmButtonText = this.lang === 'fr' ? 'Oui, approuvez-le !' : 'Yes, Approved it!';
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        
        Swal.fire({
          title: titleText,
          text: this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: confirmButtonText,
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
  }

  gotoGlobalLineage() {
    let url  = 'Business-Glossary';
    if (this.id != null && this.id != 0) {
      let id = this.cryptoService.set(this.id);
      id = id.replace(/\//g, '__________');
      url = 'Business-Glossary/Business-Terms/' + id;
    } else {
      url = 'Business-Glossary/Business-Terms/y02M3Dp94wAJwc3nh6QypA==';
    }
    console.log(url);
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
  async loadSaveScriptModal() {
    const that = this;
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.BtDefinition) {
        CKEDITOR.instances.BtDefinition.destroy(true);
      }
      const editorDefinition = CKEDITOR.replace('BtDefinition');
      editorDefinition.config.height = 60;
      editorDefinition.on( 'change', evt => {
        this.businessTermFrm.get('definition').patchValue(evt.editor.getData());
      });
      // example editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.BtExample) {
        CKEDITOR.instances.BtExample.destroy(true);
      }
      const editorExample = CKEDITOR.replace('BtExample');
      editorExample.config.height = 60;
      editorExample.on( 'change', evt => {
        this.businessTermFrm.get('example').patchValue(evt.editor.getData());
      });
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      $('#BtEffective').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function(e, date) {
          that.businessTermFrm.get('effective').patchValue(formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en'));
        });
    });
    await this.dynamicScriptLoader.load('ion.rangeSlider').then( data => {
      that.completion = 0;
      if (that.ranger != null) {
        that.ranger.reset();
      }
      $('#BtCompletion').ionRangeSlider({
        skin: 'round',
        min: 0,
        max: 100,
        from: 0,
        onChange(data) {
          that.completion = data.from;
        }
      });
      this.ranger = $('#BtCompletion').data('ionRangeSlider');
    }).catch(error => console.log(error));
  }

  changeEnabled(event: Event) {
    const checked = $(event.target).prop('checked');
    this.saveFrm.get('enabledLine').patchValue(checked);

  }
  addLoader() {
    $('app-root').last().append('<div id="divGlobal"></div>');
    const html = "<svg width=\"300\" height=\"120\">\n" +
      "    <!-- Left arc path -->\n" +
      "    <svg>\n" +
      "      <path id=\"arc-left-up\" fill=\"none\" d=\"M 90 90 A 90 90 0 0 1 0 0\"/>\n" +
      "    </svg>\n" +
      "    <!-- Right arc path -->\n" +
      "    <svg>\n" +
      "      <path id=\"arc-right-up\" fill=\"none\" d=\"M 100 90 A 90 90 0 0 0 190 0\"/>\n" +
      "    </svg>\n" +
      "\n" +
      "    <text x=\"150\" y=\"50\" fill=\"#ffffff\" font-family=\"Helvetica Neue,Helvetica,Arial\" font-size=\"16\" font-weight=\"bold\"\n" +
      "          text-anchor=\"middle\">\n" +
      "      C O N T A C T I N G&#160;&#160;S E R V E R  . . .\n" +
      "    </text>\n" +
      "    <circle cx=\"15\" cy=\"15\" r=\"15\">\n" +
      "      <!-- I used a python script to calculate the keyPoints and keyTimes based on a quadratic function. -->\n" +
      "      <animateMotion dur=\"1.5s\" repeatCount=\"indefinite\"\n" +
      "                     calcMode=\"linear\"\n" +
      "                     keyPoints=\"0.0;0.19;0.36;0.51;0.64;0.75;0.84;0.91;0.96;0.99;1.0;0.99;0.96;0.91;0.84;0.75;0.64;0.51;0.36;0.19;0.0;0.0;0.05;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0\"\n" +
      "                     keyTimes=\"0.0;0.025;0.05;0.075;0.1;0.125;0.15;0.175;0.2;0.225;0.25;0.275;0.3;0.325;0.35;0.375;0.4;0.425;0.45;0.475;0.5;0.525;0.55;0.575;0.6;0.625;0.65;0.675;0.7;0.725;0.75;0.775;0.8;0.825;0.85;0.875;0.9;0.925;0.95;0.975;1.0\">\n" +
      "        <mpath xlink:href=\"#arc-left-up\"/>\n" +
      "      </animateMotion>\n" +
      "    </circle>\n" +
      "    <circle cx=\"135\" cy=\"105\" r=\"15\" />\n" +
      "    <circle cx=\"165\" cy=\"105\" r=\"15\" />\n" +
      "    <circle cx=\"95\" cy=\"15\" r=\"15\">\n" +
      "      <animateMotion dur=\"1.5s\" repeatCount=\"indefinite\"\n" +
      "                     calcMode=\"linear\"\n" +
      "                     keyPoints=\"0.0;0.0;0.05;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0;0.0;0.19;0.36;0.51;0.64;0.75;0.84;0.91;0.96;0.99;1.0;0.99;0.96;0.91;0.84;0.75;0.64;0.51;0.36;0.19;0.0\"\n" +
      "                     keyTimes=\"0.0;0.025;0.05;0.075;0.1;0.125;0.15;0.175;0.2;0.225;0.25;0.275;0.3;0.325;0.35;0.375;0.4;0.425;0.45;0.475;0.5;0.525;0.55;0.575;0.6;0.625;0.65;0.675;0.7;0.725;0.75;0.775;0.8;0.825;0.85;0.875;0.9;0.925;0.95;0.975;1.0\">\n" +
      "        <mpath xlink:href=\"#arc-right-up\"/>\n" +
      "      </animateMotion>\n" +
      "    </circle>\n" +
      "  </svg>";
    // $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6) url("assets/images/loader/740.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
    $('#divGlobal').css({ width: '100%', height: '100%', background: 'rgba(0, 0, 0, .6)', 'z-index': 10000, position: 'fixed', top: 0, left: 0}).append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
    $('#loader').html(html);
  }
  removeLoader() {
    $('#divGlobal').remove();
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  addBusinessItemHtml(businessItem: BusinessItem) {
    this.tabIndex++;
    const classBusiness = 'test';//(businessItem.type == 'businessObject')? 'business-line-object': 'business-line-term';
    const html = '<div class="card business-item" style="width: 170px; background: transparent; font-size: 10px;box-shadow: transparent 0px 0px 1px;" data-color="rgb(rgb(27, 77 87))" id="' + businessItem.id + '" tabindex="' + this.tabIndex + '">\n' +
      '<div class="card-header text-center text-white node-header" style="background-color: rgb(27 ,77 ,87);border-radius: 23px;height:45px;\n' +
      '    border-top-right-radius: 23px;text-transform: capitalize">' + businessItem.name + '<i class="fas fa-circle connector end ' + classBusiness + '" id="end_' + businessItem.id + '" data-name="end_' + businessItem.id + '"></i></div>\n' +
      '    </div>';
    $('#play-area').append(html);

    if (!this.flatDesign) {
      $('#' + businessItem.id).addClass('shadow-design');
    }
  //  $('#' + businessItem.id).offset(businessItem.style);
    this.enableLinking();
    this.makeDraggable(businessItem.id);
    this.businessTerms.push(businessItem);
  }
  addBusinessObjectHtml(businessObject: BusinessObject){
    const that = this;
   // const id = this.create_UUID();
    this.tabIndex++;
    const html = '<div class="card business-item" style="width: 170px;font-size: 10px;background: transparent" data-color="rgb(216,153,43)" id="' + businessObject.id + '" tabindex="' + this.tabIndex + '">\n' +
      '<div class="card-header text-center text-white node-header" style="\n' +
      '    background-color: transparent;\n' +
      '    text-transform: capitalize;\n' +
      '    border-bottom: 29px solid rgb(141, 43, 216);\n' +
      '    border-left: 25px solid transparent;\n' +
      '    border-right: 25px solid transparent;\n' +
      '    height: 0;\n' +
      '    width: 169px;"\n' +
      '   ><span style="\n' +
      '    position: relative;\n' +
      '    top: 13px;\n' +
      '">Business Object</span></div>\n' +
      '      <ul class="list-group list-group-flush text-black node-details flat-details">\n' +
      '<ul class="list-group list-group-flush text-black collapse-lg" style="border-top: 1px solid #f3dcdc" id="terms_' + businessObject.id + '">' + this.getTerms(businessObject) + '</ul></ul>\n' +
      '<div class="card-body tools-line tools-line-flat">\n' +
      '        \n' +
      '        \n' +
      '       <span class="term-modal" style="cursor: pointer;"><i class="fas fa-plus" title="add Business Term"></i>&nbsp;</span>\n' +
      '      </div>\n' +
      '      <div id="line-wrapper"></div>\n' +
      '    </div>';
    $('#play-area').append(html);
    $('.term-modal').on('click', () => {
       this.addBusinessGlossary();
    });
    $('#' + businessObject.id).offset(businessObject.style).on('click', function(event) {
      event.stopPropagation();
      if (that.clicked != null) {
        if (that.clicked.id == $(this).attr('id')) {
          return;
        }
      }
      that.clickedEdges = [];
      $('.node-item').removeClass('selected-node');
      $(this).addClass('selected-node').focus();
      that.clicked = that.businessObject;
      that.termsInfo = that.clicked.terms;
      that.getEdgesTerm();
    });
    $('.delete-term').on('click', function() {
      let titleText = this.lang === 'fr' ? 'Supprimer le terme commercial ?' : 'DELETE Business Term?';
      let confirmButtonText = this.lang === 'fr' ? 'Oui, approuvez-le !' : 'Yes, Approved it!';
      let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
      
      Swal.fire({
        title: titleText,
        text: this.lang === 'fr' ? 'Vous ne pourrez pas revenir en arrière !' : 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText
      }).then((result) => {
        if (result.value) {
          const id = $(this).attr('data-id');
          that.businessObject.terms = that.businessObject.terms.filter(e => e.id!= id);
          const ed = 'end_' + id;
          console.log(ed);
          const linksToRemove = that.edges.filter(e => e.source == ed || e.target == ed);
          console.log(linksToRemove);
          if(linksToRemove.length > 0){
            linksToRemove.map(
              l => l.element.remove()
            );
          }
          that.edges = that.edges.filter(e => e.source!= ed && e.target!= ed);
          $('#term_' + id).remove();
          that.updateConnectors();
        }
      });

    });
    if (!this.flatDesign) {
      $('#' + businessObject.id).addClass('shadow-design');
    }
    this.enableLinking();
    this.makeDraggable(businessObject.id);
    this.removeLoader();
 //   this.businessTerms.push(businessItem);
  }
  getEdgesTerm(){
    if(this.clicked.terms.length > 0){
      this.clicked.terms.map(
        e => {
          const source = 'start_' + e.id;
          const target = 'end_' + e.id;
          console.log(target);
          const objectEdges = this.edges.filter(l => l.source == source || l.target == source || l.source == target || l.target == target);
          console.log(objectEdges);
          if (objectEdges.length > 0 ) {
           objectEdges.map(t => {
             if(this.clickedEdges.find(f => f.source == t.source && f.target== t.target)==null){
               this.clickedEdges.push(t);
             }
           });
           // this.clickedEdges = this.clickedEdges.concat(objectEdges);
          }
        }
      );
      console.log(this.clickedEdges);
      console.log(this.edges);
      console.log(this.clicked.terms);
    }
  }
  getBusinessTerm(id: string){
    this.dataLineService.getBusinessTerm(id).subscribe(
      d => {
        if(d.businessObject.catalogueLineage!=null){
          this.dataLineageName = d.businessObject.name;
          this.businessObject = new BusinessObject(d.businessObject.id, d.businessObject.name, d.businessObject.catalogueLineage.business.terms,d.businessObject.catalogueLineage.business.synonyms,d.businessObject.catalogueLineage.business.antonyms,d.businessObject.catalogueLineage.business.hyperonyms, d.businessObject.businessId, '', d.businessObject.effective.date, d.businessObject.status,d.businessObject.completion, d.businessObject.definition, d.businessObject.example, d.businessObject.oldId, d.businessObject.catalogueLineage.business.cid, d.businessObject.catalogueLineage.business.style, d.businessObject.catalogueLineage.business.toAdd, d.businessObject.catalogueLineage.business.toEdit );
          this.addBusinessObjectHtml(this.businessObject);
          if(d.businessObject.catalogueLineage.nodes.length > 0){
            for (let i = 0; i < d.businessObject.catalogueLineage.nodes.length; i++) {
              const item = new NodeItem(d.businessObject.catalogueLineage.nodes[i].id, d.businessObject.catalogueLineage.nodes[i].icon, d.businessObject.catalogueLineage.nodes[i].text, d.businessObject.catalogueLineage.nodes[i].application,d.businessObject.catalogueLineage.nodes[i].extern, d.businessObject.catalogueLineage.nodes[i].catalogue, d.businessObject.catalogueLineage.nodes[i].data, d.businessObject.catalogueLineage.nodes[i].description, d.businessObject.catalogueLineage.nodes[i].customApplication, 'black', d.businessObject.catalogueLineage.nodes[i].customs, d.businessObject.catalogueLineage.nodes[i].applicationType, d.businessObject.catalogueLineage.nodes[i].trigger, d.businessObject.catalogueLineage.nodes[i].type, d.businessObject.catalogueLineage.nodes[i].style);
              this.addHtmlContent(item);
            }
          }
          if(d.businessObject.catalogueLineage.links.length > 0){
            for (let k = 0; k < d.businessObject.catalogueLineage.links.length; k++) {
              if (d.businessObject.catalogueLineage.links[k].parent) {
                this.addLine(d.businessObject.catalogueLineage.links[k].source, d.businessObject.catalogueLineage.links[k].target, d.businessObject.catalogueLineage.links[k].startColor, d.businessObject.catalogueLineage.links[k].endColor, d.businessObject.catalogueLineage.links[k].hide, d.businessObject.catalogueLineage.links[k].parent, d.businessObject.catalogueLineage.links[k].sourceName, d.businessObject.catalogueLineage.links[k].targetName, d.businessObject.catalogueLineage.links[k].group, d.businessObject.catalogueLineage.path, d.businessObject.catalogueLineage.startSocket, d.businessObject.catalogueLineage.endSocket, d.businessObject.catalogueLineage.middleLabel);
              }
            }
          }
        } else {
          const terms = [];
          d.terms.map((b, i) => {
            const connectors = [];
            connectors.push({id: 'end_' + b.id});
            // const type = b.name.includes('Ot')? 'businessObject': 'businessTerm';
            const businessItem = new BusinessItem(b.id,  b.businessId, b.name, b.objects, b.synonyms, b.antonyms,b.hyperonyms,'', b.effective.date, b.status, b.completion, b.definition, b.example, b.oldId, connectors,b.cid, b.toAdd, b.toEdit);
            terms.push(businessItem);
          });
          this.dataLineageName = d.businessObject.name;
          this.businessObject = new BusinessObject(d.businessObject.id, d.businessObject.name, terms, [], [], [], d.businessObject.businessId, '', d.businessObject.effective.date, d.businessObject.status,d.businessObject.completion, d.businessObject.definition, d.businessObject.example, d.businessObject.oldId, d.businessObject.cid, {top: 400, left: 250}, d.businessObject.toAdd, d.businessObject.toEdit );
          this.addBusinessObjectHtml(this.businessObject);
        }
        //this.removeLoader();
      },
      err => {
        console.error(err);
      }
    );
  }
  saveBusinessTerm() {
    const data = this.businessTermFrm.value;
    data.completion = this.completion;
    data.createdBy = localStorage.getItem('username');
    const id = this.create_UUID();
    const connectors = [];
    connectors.push({id: 'end_' + id});
    const businessItem = new BusinessItem(id, data['businessId'], data['name'], data['object'], data['synonyms'], data['antonyms'], data['hyperonyms'],'', data['effective'], data['status'], data['completion'], data['definition'], data['example'],data['oldId'], connectors, '',true, false);
    this.businessObject.terms.push(businessItem);
    const html = '<li id="term_' + id + '" class="list-group-item" style="background-color: rgb(27 ,77 ,87)"><i class="fas fa-circle connector start static business-line-term" id="start_' + businessItem.id + '" data-name="' + businessItem.name + '"></i>&nbsp; <span style="color: white;text-transform: capitalize" title="Business Term"><i class="fas fa-file-alt"></i>&nbsp;' + businessItem.name + '<i class="fas fa-minus" style="cursor: pointer;float: right" title="remove this business Term"></i></span>&nbsp;<i class="fas fa-circle connector end business-line-term" id="end_' + businessItem.id + '"  data-name="' + businessItem.name + '"></i></li>';;
    $('#terms_' + this.id).append(html);
    this.enableLinking();
    $('#saveBusinessGloassaryModal').modal('hide');
    // console.log(data);return;
   /* this.businessTermService.addBusinessTerm(data).subscribe(
      d => {
        Swal.fire({
          title: 'Success!',
          text: 'added with success',
          icon: 'success',
          confirmButtonText: 'Ok'
        });
        $('#saveBusinessGloassaryModal').modal('hide');
      },
      error => {console.log(error); }
    );*/

  }
  addBusinessGlossary() {
    this.loadSaveScriptModal();
    this.businessTermFrm.reset();
    this.businessTermFrm.get('status').patchValue(this.status[0].id);
    this.generateBusinessId();
    $('#saveBusinessGloassaryModal').modal();
  }
  generateBusinessId() {
      let id = this.create_UUIB();
      id = 'Bt_' + id;
      this.businessTermFrm.get('businessId').patchValue(id);
  }
  checkBusinessTerm(){
    const name = this.businessTermFrm.value.name;
    if (name == '') {this.nameError = true; this.nameSuccess = false; return; }
    this.businessTermService.checkBusinessTerm(name).subscribe(
      d => {
        // @ts-ignore
        if (d.response.indexOf('taken') != -1) {this.nameError = true; this.nameSuccess = false; } else {this.nameError = false; this.nameSuccess = true; }},
      e => {console.error(e); }
    );
  }
  async loadSaveScriptModalBussiness() {
    const that = this;
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.BtDefinition) {
        CKEDITOR.instances.BtDefinition.destroy(true);
      }
      const editorDefinition = CKEDITOR.replace('BtDefinition');
      editorDefinition.config.height = 60;
      editorDefinition.on( 'change', evt => {
        this.businessTermFrm.get('definition').patchValue(evt.editor.getData());
      });
      // example editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.BtExample) {
        CKEDITOR.instances.BtExample.destroy(true);
      }
      const editorExample = CKEDITOR.replace('BtExample');
      editorExample.config.height = 60;
      editorExample.on( 'change', evt => {
        this.businessTermFrm.get('example').patchValue(evt.editor.getData());
      });
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      $('#BtEffective').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function(e, date) {
          that.businessTermFrm.get('effective').patchValue(formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en'));
        });
    });
    await this.dynamicScriptLoader.load('ion.rangeSlider').then( data => {
      that.completion = 0;
      if (that.ranger != null) {
        that.ranger.reset();
      }
      $('#BtCompletion').ionRangeSlider({
        skin: 'round',
        min: 0,
        max: 100,
        from: 0,
        onChange(data) {
          that.completion = data.from;
        }
      });
      this.ranger = $('#BtCompletion').data('ionRangeSlider');
    }).catch(error => console.log(error));
  }
  getTerms(businessObject: BusinessObject) {
    let htm = '<li class="list-group-item" style="background-color: rgb(43,127,144);height: 30px;">&nbsp; <span style="color: white;text-transform: capitalize" title="Business Object"><i class="fas fa-book-open"></i>&nbsp;' + businessObject.name + '</span>&nbsp;<i class="fas fa-circle connector end business-line-object" id="end_' + businessObject.id + '"  data-name="' + businessObject.name + '"></i></li>';;
    businessObject.terms.map(
      term => {
        htm += '<li id="term_' + term.id + '" class="list-group-item" style="background-color: rgb(27 ,77 ,87); height: 30px;"><i class="fas fa-circle connector start static business-line-term" id="start_' + term.id + '" data-name="' + term.name + '"></i>&nbsp; <span style="color: white;text-transform: capitalize" title="Business Term"><i class="fas fa-file-alt"></i>&nbsp;' + term.name + '<i class="fas fa-minus delete-term" data-id="' + term.id + '" style="cursor: pointer;float: right" title="remove this business Term"></i></span>&nbsp;<i class="fas fa-circle connector end business-line-term" id="end_' + term.id + '"  data-name="' + term.name + '"></i></li>';
      }
    );
    return htm;
  }

  showTerm(id: any) {
    $('#termModalDetail').modal();
    const data = this.businessObject.terms.find(e => e.id == id);
    const columnDefs = [
      {
        data: 'name',
        title: 'Name'
      },
      {
        data: 'oldId',
        title: 'OwnId'
      },
      {
        data: 'status',
        title: 'Status'
      },
      {
        data: 'effective',
        title: 'Effective Date'
      },
      {
        data: 'completion',
        title: 'Completion'
      },
      {
        data: 'definition',
        title: 'Definition'
      },
      {
        data: 'example',
        title: 'Example'
      }
    ];
    $('#term-modal-table').DataTable().destroy();
    $('#term-modal-table').empty();
    $('#term-modal-table').DataTable({
      data,
      columns: columnDefs
    });
  }
}
