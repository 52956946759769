import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadersmall',
  templateUrl: './loadersreport.component.html',
  styleUrls: ['./loadersreport.css']
})
export class LoadersreportComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
