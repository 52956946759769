import * as d3 from 'd3';
import {getCookieValue, Shared} from "../../shared";
declare const $: any;

export const sunBurstTask =(svg,tip,objectId, objectName,id) => {
  let clicks = [];
  const width = 932,
    height = 932,
    maxRadius = (Math.min(width, height) / 2) - 5;


  const x = d3.scaleLinear()
    .range([0, 2 * Math.PI])
    .clamp(true);

  const y = d3.scaleSqrt()
    .range([maxRadius * .1, maxRadius]);


  const partition = d3.partition();

  const arc = d3.arc()
    .startAngle(d => x(d.x0))
    .endAngle(d => x(d.x1))
    .innerRadius(d => Math.max(0, y(d.y0)))
    .outerRadius(d => Math.max(0, y(d.y1)));

  const middleArcLine = d => {
    const halfPi = Math.PI / 2;
    const angles = [x(d.x0) - halfPi, x(d.x1) - halfPi];
    const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);

    const middleAngle = (angles[1] + angles[0]) / 2;
    const invertDirection = middleAngle > 0 && middleAngle < Math.PI; // On lower quadrants write text ccw
    if (invertDirection) {
      angles.reverse();
    }

    const path = d3.path();
    path.arc(0, 0, r, angles[0], angles[1], invertDirection);
    return path.toString();
  };

  const textFits = d => {
    const CHAR_SPACE = 6;

    const deltaAngle = x(d.x1) - x(d.x0);
    const r = Math.max(0, (y(d.y0) + y(d.y1)) / 2);
    const perimeter = r * deltaAngle;
  // console.log(d);
    return d.data.name.length * CHAR_SPACE < perimeter;
  };
  const getUsageRisk = (d) => {
    let ic;
    switch (d) {
      case 0:
        ic = 'grey';
        break;
      case 1:
        ic = 'green';
        break;
      case 2:
        ic = 'orange';
        break;
      case 3:
        ic = 'red';
        break;
    }
    return ic;
  };

  svg.on('click', () => focusOn()); // Reset zoom on canvas click

  $('#sunbirstContainer').empty();
  // if(json.children.length==0){
  //   //  console.log("json.children.length==0");
  //   d3.select('#recordIcons').style('display', 'none');
  //   d3.select('#toolsSunburst').transition().duration(400).style('opacity', '1');
  //   d3.select('#labelGlobos_' + record).style('display', 'inline').transition().duration(400).style('fill-opacity', '1');
  //   return;
  // }
  //record icons usgae and security
  // d3.selectAll('.usageRiskRecordGlobos')
  //   .attr('fill', getUsageRisk(json.usageRisk));
  // d3.selectAll('.securityRiskRecordGlobos')
  //   .attr('fill', getUsageRisk(json.securityRisk));
  function getColor(d) {
    var color;
    switch (d) {
      case 'TO BE PROCESSED':
        color = 'grey';
        break;
      case 'OK':
        color = '#06d023';
        break;
      case 'KO':
        color = '#da2302';
        break;
    }
    return color;
  }

  function getValue(d) {
    if (d == null) return 1;
    return d;
  }
 let objectNem = objectName.replace(/\//g, '__________');
  d3.json(Shared.API_URL + '/api/data-life-cycle-role/getSunburstByObjectAndTask/' + objectId + '/' + id + '/' + objectNem, {
    headers: new Headers({
      'Accept': 'Application/json',
      'Authorization': 'Bearer ' + getCookieValue('tokendrd')
    }),
  }).then(json => {
  // console.log(data[0]);
  //   let purgeData= {'name' : 'Purge','value' : getValue(data[0].nb_purge),'color': getColor(data[0].PURGE), 'fontSize' : 12, 'id' : 'purge_'+ data[0].systnaps_archid};
  //   let archData = {'name' : 'Archive','value' : getValue(data[0].nb_arch),'color': getColor(data[0].ARCH), 'fontSize' : 12, 'id' : 'arch_'+ data[0].systnaps_archid};
  //   let candData = {'name' : 'Candidate','value' : getValue(data[0].nb_cand),'color': getColor(data[0].CAND), 'fontSize' : 12, 'id' : 'cand_'+ data[0].systnaps_archid};
  //   let childs = [];
  //   childs.push(purgeData);
  //  childs.push(archData);
  //  childs.push(candData);
  //   let json = {'name' : data[0].systnaps_archname,'children' : childs,'color': getColor(data[0].STATUT), 'fontSize' : 12, 'id' : 'statut_'+ data[0].systnaps_archid};
  // console.log(json);
  const root = d3.hierarchy(json);
  root.sum(d => d.value);

  const slice = svg.selectAll('g.slice')
    .data(partition(root).descendants());
//console.log(partition(root).descendants());
  slice.exit().remove();

  const newSlice = slice.enter()
    .append('g').attr('class', 'slice')
    .attr('id', d => d.data.id)
    .style('fill-opacity', '0.8')
    .on('mouseover', function (d) {
      //if (d.parent) {
        $('#tooltip').css('opacity', 0);
        tip.show(d, this);
        d3.select('#toolTipos').style('background', function () {
          switch (d.data.color) {
            case '#06d023':
              return 'linear-gradient(0deg, rgba(6,208,35,1) 0%, rgba(6,210,35,1) 100%)';
              break;
            case 'orange':
              return 'linear-gradient(0deg, rgb(255, 165, 0) 0%, rgb(255, 175, 0) 100%)';
              break;
            case '#da2302':
              return 'linear-gradient(0deg, rgb(218, 35, 2) 0%, rgb(218, 35, 10) 100%)';
              break;
            case 'grey':
              return 'linear-gradient(0deg, rgb(128, 128, 128) 0%, rgb(128, 128, 125) 100%)';
              break;
          }
        });
        d3.select(this).transition().duration(100).style('fill-opacity', '1');
     // }
    })
    .on("mouseout", function (d) {
    //  if (d.parent) {
      $('#tooltip').css('opacity', 0);
        tip.hide();
        d3.select(this).transition().duration(0).style('fill-opacity', '0.8');
     // }
    })
    .on("mousemove", function (d) {
    // if (d.parent) {
      $('#tooltip').css('opacity', 0);
        let pos = d3.select(this).node().getBoundingClientRect();
        d3.select('#toolTipos')
          .style("left", (d3.event.pageX - 34) + "px")
          .style("top", (d3.event.pageY - 12) + "px");
     // }
    })
    .on('click', d => {
      d3.event.stopPropagation();
      focusOn(d);
      clicks.indexOf(d.data.id) == -1 ? clicks.push(d.data.id) : clicks.splice(clicks.indexOf(d.data.id), 1);
//      console.log(clicks);
    });

  // newSlice.append('title')
  //   .text(d => d.data.name + '\n' + formatNumber(d.value));

  newSlice.append('path')
    .attr('class', 'main-arc')
    .attr('filter', function () {
      return $('#tickCube').attr('display') == 'block' ? 'url(#shadowGlobeSize)' : 'none';
    })
    .style('fill', d => d.data.color)
    .style('opacity', d => d.data.color ? 1 : 0)
    .attr('d', arc);

  newSlice.append('path')
    .attr('class', 'hidden-arc')
    .attr('id', (_, i) => `hiddenArc${i}`)
    .attr('d', middleArcLine);

  const text = svg.selectAll('g.slice')
    .append('text')
    .attr('display', d => textFits(d) ? null : null);
 // text.attr('opacity', d => d.parent ? 1 : 0);
  // Add white contour
  text.append('textPath')
    .attr('startOffset', '50%')
    .attr('xlink:href', (_, i) => `#hiddenArc${i}`)
    .text(d => d.data.name)
    .style('fill', 'none')
    .style('stroke', '#fff')
    .style('stroke-width', 10)
    .style('stroke-linejoin', 'round');

  text.append('textPath')
    .attr('startOffset', '50%')
    .attr('xlink:href', (_, i) => `#hiddenArc${i}`)
    .text(d => d.data.name);
  svg.transition().duration(300).style('opacity', '1');
  //   d3.select('#labelGlobos_' + record).style('display', 'inline').transition().duration(400).style('fill-opacity', '1');
  d3.select('#recordIcons').style('display', 'inline').transition().duration(400).style('opacity', '1');

});
  function focusOn(d = { x0: 0, x1: 1, y0: 0, y1: 1 }) {
    // Reset to top-level if no data point specified

    const transition = svg.transition()
      .duration(750)
      .tween('scale', () => {
        const xd = d3.interpolate(x.domain(), [d.x0, d.x1]),
          yd = d3.interpolate(y.domain(), [d.y0, 1]);
        return t => { x.domain(xd(t)); y.domain(yd(t)); };
      });

    transition.selectAll('path.main-arc')
      .attrTween('d', d => () => arc(d));

    transition.selectAll('path.hidden-arc')
      .attrTween('d', d => () => middleArcLine(d));

    transition.selectAll('text')
      .attrTween('display', d => () => textFits(d) ? null : null);

    moveStackToFront(d);

    //

    function moveStackToFront(elD) {
      svg.selectAll('.slice').filter(d => d === elD)
        .each(function(d) {
          this.parentNode.appendChild(this);
          if (d.parent) { moveStackToFront(d.parent); }
        })
    }
  }
  svg.transition().duration(300).style('opacity', '1');
};
