export class projectJson{
  id: string;
  name: string;
  master: string;
  effective: string;
  definition: string;
  purpose: string;
  requirement: string;
  status: string;
  owner: string;
  projectModels = [];
  projectReports = [];
  campain: number;
  suppliers = [];
  participants = [];
  consumers = [];
  start: string;
  end: string;
  application: string;
  enabled: boolean;
  creator: string;
  campains = [];
  projectLineages = [];
  entities = [];
  entityNames = [];
  completion: number;
  template: string;
  projectGlossaries = [];
  projectFunctionMaps = [];
  projectCountries = [];
  projectRules = [];
  projectDiagramDesigners = [];
  projectModelDesigners = [];
  projectClassificationDefinitions = [];
  projectRetentions = [];
  projectFlows = [];
  projectCatalogs = [];
  projectDataSecurity = [];
  metadataField = [];
  metadataTable = [];
  constructor(id: string, name: string, master: string, effective: string, definition: string, purpose: string, requirement: string, status: string, owner: string, projectModels: any[],
              projectReports: any[], campain: number, suppliers: any[], participants: any[], consumers: any[], start: string, end: string, application: string, enabled: boolean,
              creator: string, campains: any[], projectLineages: any[], entities: any[], entityNames: any[], completion: number, template: string, projectGlossaries: any[],
              projectFunctionMaps: any[], projectCountries: any[], projectRules: any[], projectDiagramDesigners: any[], projectModelDesigners: any[], projectClassificationDefinitions: any[],
              projectFlows: any[], projectRetentions: any[], projectCatalogs: any[], projectDataSecurity: any[], metadataField: any[], metadataTable: any[]) {
    this.id = id;
    this.name = name;
    this.master = master;
    this.effective = effective;
    this.definition = definition;
    this.purpose = purpose;
    this.requirement = requirement;
    this.status = status;
    this.owner = owner;
    this.projectModels = projectModels;
    this.projectReports = projectReports;
    this.campain = campain;
    this.suppliers = suppliers;
    this.participants = participants;
    this.consumers = consumers;
    this.start = start;
    this.end = end;
    this.application = application;
    this.enabled = enabled;
    this.creator = creator;
    this.campains = campains;
    this.projectLineages = projectLineages;
    this.entities = entities;
    this.entityNames = entityNames;
    this.completion = completion;
    this.template = template;
    this.projectGlossaries = projectGlossaries;
    this.projectFunctionMaps = projectFunctionMaps;
    this.projectCountries = projectCountries;
    this.projectRules = projectRules;
    this.projectDiagramDesigners = projectDiagramDesigners;
    this.projectModelDesigners = projectModelDesigners;
    this.projectClassificationDefinitions = projectClassificationDefinitions;
    this.projectFlows = projectFlows;
    this.projectRetentions = projectRetentions;
    this.projectCatalogs = projectCatalogs;
    this.projectDataSecurity = projectDataSecurity;
    this.metadataField = metadataField;
    this.metadataTable = metadataTable;
  }
}
