import * as d3 from 'd3';
import d3Tip from 'd3-tip';
declare const $: any;

let node;
let groupIds;
let centroid;
let polygon;
let paths;
let annotations;
// const color = d3.scaleOrdinal(d3.schemeCategory10);
const valueLine = d3.line()
  .x(d => d[0])
  .y(d => d[1])
  .curve(d3.curveCatmullRomClosed);
let simulation;
export  const renderTreeLine = (data, lang = 'en', groupTitles = []) => {
  $('#treeChartContainer').empty().html('<svg id="TreeChartDataLine" width="900" height="900" preserveAspectRatio="xMinYMid"></svg>');
  const getIcon = (d) => {
    let c;
    switch (d) {
      case 'customModel':
      case 'model': c = '\uf233'; break;
      case 'object': c = '\uf5cb'; break;
      case 'reportTable':
      case 'table': c = '\uf0ce'; break;
      case 'reportField':
      case 'field': c = '\uf543'; break;
      case 'functionalObject': c = '\uf4d7'; break;
      case 'moduleGroup': c = '\uf5fd'; break;
      case 'businessObject': c = '\uf0ce'; break;
      case 'businessTerm': c = '\uf02d'; break;
      case 'dictionary':
      case 'report': c = '\uf201'; break;
    }
    return c;
  };
  const getColor = (d) => {
    let c;
    switch (d) {
      case 'model': c = 'url(#gradModel)'; break;
      case 'object': c = 'url(#gradObject)'; break;
      case 'table': c = 'url(#gradTable)'; break;
      case 'field': c = 'url(#gradField)'; break;
      case 'customModel': c = 'url(#customModel)'; break;
      case 'customObject': c = 'url(#customObject)'; break;
      case 'customTable': c = 'url(#customTable)'; break;
      case 'customField': c = 'url(#customField)'; break;
      case 'functionalObject': c = 'url(#gradFunctionalObject)'; break;
      case 'moduleGroup': c = 'url(#gradModuleGroup)'; break;
      case 'businessObject': c = 'url(#gradBusinessObject)'; break;
      case 'businessTerm': c = 'url(#gradBusinessTerm)'; break;
      case 'report': c = 'url(#gradReport)'; break;
      case 'reportTable': c = 'url(#reportTable)'; break;
      case 'reportField': c = 'url(#reportField)'; break;
    }
    return c;
  };
 // d3.select('#TreeChartDataLine').selectAll('*').remove();

  const width = 900;
  const height = 900;
  const svg = d3.select('#TreeChartDataLine').attr('viewBox', `0 0 ${width} ${height}`);

//  svg.selectAll('*').remove();
  const legendContainer = svg.append('g');
  createLineNodeLegend(legendContainer, lang);


  // create groups, links and nodes
  const groups = svg.append('g').attr('class', 'groups');

  const container = svg.append('g')
    .attr('id', 'linesContainer')
    .style('font', '8px sans-serif')
    .style('text-anchor', 'middle');
  svg.attr('width', width);
  svg.attr('height', height);
  const { links, nodes } = data;
  svg.call(
    d3.zoom()
      .scaleExtent([.1, 20])
      .on('zoom', () => { container.attr('transform', d3.event.transform); groups.attr('transform', d3.event.transform); })
  );
  initFilter(container);
  initGradian(container);
  simulation = forceSimulation(d3, {width, height});
  // if($('.d3-tipCampainSize-Zoom').length > 0){
  //   $('.d3-tipCampainSize-Zoom').remove();
  // }
  const toolTip = d3Tip()
    .direction('n')
    .attr('class', 'd3-tipLine')
    // .offset([-75, 0])
    .html(d => d.type + ' ' + d.name);
  svg.call(toolTip);
  const toolTipGroup = d3Tip()
    .direction('n')
    .attr('class', 'd3-tipLine-group')
    .offset([40, 0])
    // tslint:disable-next-line:max-line-length
    .html(d => '<span class="fa-stack" style="align-self: center;font-size: 1.2em;padding-top: 1.3px"><i class="fas fa-circle fa-stack-2x" style="color:' + colors[d] + '"></i> ' + getImageFromType(icons[d]) + '</span>  ' + titles[d]);
  svg.call(toolTipGroup);
  const dragDiv = d3.drag()
    .subject(function() {
      return { x: parseInt(d3.select(this).style('left'), 10),
        y: parseInt(d3.select(this).style('top'), 10) };
    })
    .on('drag', function() {
      d3.select(this)
        .style('left', d3.event.x + 'px')
        .style('top', d3.event.y + 'px');
    });

  d3.select('.d3-tip').call(dragDiv);

  const link = container.selectAll('.link')
    .data(links)
    .enter()
    .append('line')
    .attr('class', 'link')
    .attr('style', 'cursor:pointer;')
    .attr('stroke-width', '.4')
    // .style('display', 'none')
    .attr('id', d => 'dataLink_' +  d.id)
    // .attr('marker-end', (d) => 'url(#' + d.color + ')')
    // .attr('marker-mid', (d) => 'url(#arrow)')
    .attr('stroke' , 'gray');

  const linkLabelContainer = container.selectAll('.linkLabel').data(links).enter().append('text')
    .attr('class', 'linkLabel')
    .attr('dy', 5)
    .text(d => d.value);
  // linkLabelContainer.exit().remove();
  node = container.selectAll('.node')
    .data(nodes)
    .enter()
    .append('g')
    .attr('class', 'node')
    .attr('id', d => 'lineNode_' + d.id)
    .on('mouseover', function(d) {
      toolTip.show(d, this);
    })
    .on('mouseout', () => {
      toolTip.hide(); })
    // .style('display', (d) => d.group != 'noGroup' ? 'none' : 'block')
    // .on('click', d => handleNodeClicked(d, nodes, links, app, listTreeObject))
    .call(d3.drag()
      .on('start', function dragstart(d) {
        d3.event.sourceEvent.stopPropagation();
        if (!d3.event.active) { simulation.alphaTarget(0.3).restart(); }
        d.fx = d.x;
        d.fy = d.y;

      })
      .on('drag', function dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;

      })
      .on('end', function dragEnded(d) {
        if (!d3.event.active) { simulation.alphaTarget(0); }
        d.fx = d.x;
        d.fy = d.y;

      })
    );
  // count members of each group. Groups with less
  // than 3 member will not be considered (creating
  // a convex hull need 3 points at least)
  groupIds = d3.set(nodes.map(n => +n.group))
    .values()
    .map(groupId => ({
      groupId,
      // tslint:disable-next-line:triple-equals
      count: nodes.filter(n => +n.group == groupId).length
    }))
    .filter(group => group.count > 2)
    .map(group => parseInt(group.groupId, 10));

  const  getImageFromType = (type: string) => {
    let imag = '';
    switch (type) {
      case 'catalog':
        imag = '<i class="fas fa-server fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'glossary':
        imag = '<i class="fas fa-box fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'dictionary':
        imag = '<i class="fas fa-chart-line fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'custom':
        imag = '<i class="fas fa-code fa-stack-1x fa-inverse fa-middle"></i>';
        break;
    }
    return imag;
  };
  const colors1 = [];
  const titles1 = [];
  let icons = [];
  groupTitles.map(n => {colors1.push(n.color); titles1.push(n.title); icons.push(n.icon); });
  const colors = d3.set(colors1).values();
  const titles = d3.set(titles1).values();
  icons = d3.set(icons).values();

  const pathIds = [];

  paths = groups.selectAll('.path_placeholder')
    .data(groupIds, d => +d)
    .enter()
    .append('g')
    .attr('class', 'path_placeholder')
    .attr('fill-opacity', .1)
    .attr('stroke-opacity', 1)
    .append('path')
    .attr('stroke', d => colors[d])
    .attr('id', d => { const id = 'path_' + d; pathIds.push(id); return id; })
    .attr('fill', d => colors[d])
    .attr('opacity', 0);

  paths
    .transition()
    .duration(2000)
    .attr('opacity', 1);
  annotations = groups.selectAll('.path_placeholder')
    .on('mouseover', function(d) {
      toolTipGroup.show(d, this);
    })
    .on('mouseout', () => {
      toolTipGroup.hide(); })
    .append('text')
    .attr('dy', '10')
    .attr('fill-opacity', '0.2')
    .attr('font-weight', 'bold')
    .attr('font-variant', 'small-caps')
    .attr('font-variant-ligatures',  'none')
    .attr('stroke-width', '0.5px')
    .attr('font-size', '11px')
    .append('textPath')
    .attr('xlink:href', d => '#' + pathIds[d])
    // .style('text-anchor', 'middle')
    // .attr('startOffset', '50%')
    .text(d => titles[d]);
  // add interaction to the groups
  groups.selectAll('.path_placeholder')
    .call(d3.drag()
      .on('start', groupDragStarted)
      .on('drag', groupDragged)
      .on('end', groupDragended)
    );

  node.append('rect');
  node.append('text').text((d) => d.name ).attr('class', 'node-text');
  // node.append('svg:circle')
  //   .attr('class', 'node-glyph')
  //   .attr('r', 10)
  //   .attr('cx', -5)
  //   .attr('cy', -25)
  //   .attr('id' , (d) => 'node-glyph_' + d.name)
  //   .attr('fill', 'blue');

  node.append('svg:text')
    .attr('class', 'icon-security-risk')
    .attr('x', -8)
    .attr('y', -18)
    .attr('fill', d => getColor(d.type))
    .attr('id' , (d) => 'glyph-label_' + d.name)
    .text(d => getIcon(d.type))
    .attr('text-anchor', 'middle');



  const paddingLeftRight = 18; // adjust the padding values depending on font and font size
  const paddingTopBottom = 5;

  container.selectAll('rect')
    .attr('x', function() { return this.parentNode.childNodes[1].getBBox().x - paddingLeftRight; })
    .attr('y', function() { return this.parentNode.childNodes[1].getBBox().y - paddingTopBottom - 6;  })
    .attr('rx', 10)
    .attr('ry', 10)
    .attr('width', function() {  return (this.parentNode.childNodes[1].getBBox().width + paddingLeftRight)  ; })
    .attr('height', function() { return this.parentNode.childNodes[1].getBBox().height  + paddingTopBottom * 2; })
    .attr('fill', d => getColor(d.type))
    .attr('id' , (d) => d.name)
    .attr('filter', 'url(#shadow)')
    .style('text-align', 'middle');

  // zoom global chart buttons
  let scaleAvr = 1;
  $('#zoomIn_button-line').on('click', () => {
    if ($('#TreeChartDataLine').css('display') === 'block') {
      if (scaleAvr < 2) {
        scaleAvr += 0.1;
      } else {
        scaleAvr = 2;
      }
      zoomGroups();
    }
  });
  $('#zoomOut_button-line').on('click', () => {
    if ($('#TreeChartDataLine').css('display') === 'block') {
      if (scaleAvr > 0.5) {
        scaleAvr -= 0.1;
      } else {
        scaleAvr = 0.5;
      }
      zoomGroups();
    }
  });
  $('#resetSize_button-line').on('click', () => {
    if ($('#TreeChartDataLine').css('display') === 'block') {
      scaleAvr = 1;
      zoomGroups();
    }
  });
  const zoomGroups = () => {
    container.transition().duration(100).style('transform', 'scale(' + scaleAvr + ')');
  };
  container.selectAll('text.node-text')
    .attr('x', function() { return this.parentNode.childNodes[0].getBBox().x - (this.parentNode.childNodes[0].getBBox().x + 10); })
    .attr('fill', '#f8f9fa')
    .attr('id' , (d) => 'text_' + d.name)
    .style('cursor', 'pointer')
    .attr('y', function() { return this.parentNode.childNodes[1].getBBox().y + 2;  });

  simulation
    .nodes(nodes)
    .on('tick', () => {
      ticked(link, node, linkLabelContainer) ;
    })
    .on('end', () => {simulation.stop(); });
  simulation.force('link')
    .links(links);
  // setTimeout(() => simulation.stop(), 500);
  // $('#treeGlobalSwitch').change(function() {
  //   if (this.checked) {
  //     toggleGroupGlobal(nodes);
  //   } else {
  //     toggleGroupGlobal(nodes, false);
  //   }
  // });
  // select nodes of the group, retrieve its positions
// and return the convex hull of the specified points
// (3 points as minimum, otherwise returns null)

  valueLine.curve(d3.curveBasisClosed);
  updateGroups();

  $('#TreeChartDataLine').css({width: '100%', height: '100%'});
  return simulation;
};
export const polygonGenerator = (groupId) => {
  const nodeCoords = node
    .filter(d => d.group === groupId)
    .data()
    .map(d => [d.x, d.y]);

  return d3.polygonHull(nodeCoords);
};
export const  updateGroups = () => {
  groupIds.forEach(groupId => {
    const path = paths.filter(d => d === groupId)
      .attr('transform', 'scale(1) translate(0,0)')
      .attr('d', d => {
        polygon = polygonGenerator(d);
        centroid = d3.polygonCentroid(polygon);

        // to scale the shape properly around its points:
        // move the 'g' element to the centroid point, translate
        // all the path around the center of the 'g' and then
        // we can scale the 'g' element properly
        return valueLine(
          polygon.map(point => [point[0] - centroid[0] - 20, point[1] - centroid[1] - 20])
        );
      });
    annotations.raise();
    d3.select(path.node().parentNode).attr('transform', 'translate('  + (centroid[0] - 20) + ',' + (centroid[1] - 20) + ') scale(1.2)');
  });
};

export const ticked = (link, nod, linkLabelContainer) => {

  link
    .attr('x1', d => d.source.x)
    .attr('y1', d => d.source.y)
    .attr('x2', d => d.target.x)
    .attr('y2', d => d.target.y);

  linkLabelContainer.attr('transform', d => { // calcul de l'angle du label
    const angle = Math.atan((d.source.y - d.target.y) / (d.source.x - d.target.x)) * 180 / Math.PI;

    return 'translate(' + [((d.source.x + d.target.x) / 2), ((d.source.y + d.target.y) / 2)] + ')rotate(' + angle + ')';
  });
  nod.attr('transform', d => 'translate(' + d.x + ', ' + d.y + ')');
  updateGroups();
};
// drag groups
const groupDragStarted = () => {
  if (!d3.event.active) { simulation.alphaTarget(0.3).restart(); }
  d3.select(this).select('path').style('stroke-width', 3);
};

const  groupDragged = (groupId) => {
  node
    .filter(d => d.group === groupId)
    .each(d => {
      d.x += d3.event.dx;
      d.y += d3.event.dy;
    });
};

const groupDragended = (groupId) => {
  if (!d3.event.active) { simulation.alphaTarget(0.3).restart(); }
  d3.select(this).select('path').style('stroke-width', 1);
  node
    .filter(d => d.group === groupId)
    .each(d => {
      d.x += d3.event.dx;
      d.y += d3.event.dy;
    });
};
// fin blue arrow
export const initFilter = (svg) => {
  svg.append('svg:defs').selectAll('filter')
    .data(['shadow'])
    .enter().append('svg:filter')
    .attr('id', String)
    .attr('y', -10)
    .attr('x', -10)
    .attr('height', 40)
    .attr('width', 150)
    .append('feOffset')
    .attr('in', 'SourceAlpha')
    .attr('dx', 3)
    .attr('dy', 3)
    .attr('result', 'offset2')
    .attr('stdDeviation', 4);
  svg.selectAll('filter')
    .append('feGaussianBlur')
    .attr('in', 'offset2')
    .attr('result', 'blur2')
    .attr('stdDeviation', 3);
  svg.selectAll('filter')
    .append('feMerge')
    .append('feMergeNode')
    .attr('in', 'blur2');
  svg.selectAll('filter')
    .select('feMerge')
    .append('feMergeNode')
    .attr('in', 'SourceGraphic');
};
// gradian color array
export const initGradian = (svg) => {
  // model gradient
  svg.append('svg:defs').selectAll('linearGradient')
    .data(['gradModel'])
    .enter().append('svg:linearGradient')
    .attr('id', String)
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#04a7f3')
    .attr('stop-opacity', 1);
  svg.select('#gradModel')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#044985')
    .attr('stop-opacity', 1);
  // object gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'gradObject')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(4,119,133)')
    .attr('stop-opacity', 1);
  svg.select('#gradObject')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(4,167,243)')
    .attr('stop-opacity', 1);
  // table gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'gradTable')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(4,130,133)')
    .attr('stop-opacity', 1);
  svg.select('#gradTable')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(4,197,243)')
    .attr('stop-opacity', 1);
  // field gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'gradField')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(4,133,129)')
    .attr('stop-opacity', 1);
  svg.select('#gradField')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(4,243,236)')
    .attr('stop-opacity', 1);

  // gradFunctionalObject gradient
  svg.append('svg:defs').selectAll('linearGradient')
    .data(['gradFunctionalObject'])
    .enter().append('svg:linearGradient')
    .attr('id', String)
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#54f304')
    .attr('stop-opacity', 1);
  svg.select('#gradFunctionalObject')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#568504')
    .attr('stop-opacity', 1);
  // gradModuleGroup gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'gradModuleGroup')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(7,169,62)')
    .attr('stop-opacity', 1);
  svg.select('#gradModuleGroup')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(109,231,110)')
    .attr('stop-opacity', 1);
  // gradBusinessObject gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'gradBusinessObject')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(6,175,81)')
    .attr('stop-opacity', 1);
  svg.select('#gradBusinessObject')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(50,150,6)')
    .attr('stop-opacity', 1);
  // gradBusinessTerm gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'gradBusinessTerm')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(179,227,118)')
    .attr('stop-opacity', 1);
  svg.select('#gradBusinessTerm')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(112,176,95)')
    .attr('stop-opacity', 1);
  // report gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'gradReport')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(203,118,227)')
    .attr('stop-opacity', 1);
  svg.select('#gradReport')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(108,6,143)')
    .attr('stop-opacity', 1);
  // reportTable gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'reportTable')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(165,126,180)')
    .attr('stop-opacity', 1);
  svg.select('#reportTable')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgba(166,71,203,0.72)')
    .attr('stop-opacity', 1);
  // reportField gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'reportField')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(204,184,217)')
    .attr('stop-opacity', 1);
  svg.select('#reportField')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgba(120,76,134,0.72)')
    .attr('stop-opacity', 1);
  // customModel gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'customModel')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(222,188,13)')
    .attr('stop-opacity', 1);
  svg.select('#customModel')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgba(232,236,9,0.72)')
    .attr('stop-opacity', 1);
  // customObject gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'customObject')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(159,133,2)')
    .attr('stop-opacity', 1);
  svg.select('#customObject')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgba(242,243,70,0.72)')
    .attr('stop-opacity', 1);
  // customTable gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'customTable')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(187,160,28)')
    .attr('stop-opacity', 1);
  svg.select('#customTable')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgba(216,217,93,0.72)')
    .attr('stop-opacity', 1);
  // customField gradient
  svg.append('svg:defs').append('svg:linearGradient')
    .attr('id', 'customField')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(166,141,1)')
    .attr('stop-opacity', 1);
  svg.select('#customField')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgba(244,246,81,0.72)')
    .attr('stop-opacity', 1);
};
// tslint:disable-next-line:no-shadowed-variable
export const forceSimulation = (d3: d3,  {width, height}) =>  d3.forceSimulation()
  .force('link',
    d3.forceLink()
      .id((d) => d.id)
      .distance(50)
      .strength(1))
  .force('charge', d3.forceManyBody().strength(-5000))
  .force('x', d3.forceX(width).strength(1.5))
  .force('y', d3.forceY(height).strength(1.5))
  .force('collision', d3.forceCollide())
  .force('center', d3.forceCenter(width / 2, height / 2));
// create legend for chart node
export const createLineNodeLegend = (wrapper, lang) => {
  // tslint:disable-next-line:max-line-length
  const labelsEn = ['Model', 'Object', 'Table', 'Field', 'CustomModel', 'CustomObject', 'CustomTable', 'CustomField', 'Dictionnary', 'Dictionnary Table', 'Dictionnary Field', 'FunctionalObject', 'ModuleGroup', 'BusinessObject', 'BusinessTerm'];
  // tslint:disable-next-line:max-line-length
  const labelsFr = ['Modèle', 'Objet', 'Table', 'Champ', 'Modèle personnalisé', 'Objet personnalisé', 'Table personnalisé', 'Champ personnalisé', 'Dictionnaire', 'Table Dictionnaire', 'Champ Dictionnaire', 'Objet fonctionnel', 'Groupe de modules', 'Objet metier', 'Term metier'];
  // tslint:disable-next-line:max-line-length
  const colors = ['url(#gradField)', 'url(#gradTable)', 'url(#gradObject)', 'url(#gradModel)', 'url(#customModel)', 'url(#customObject)', 'url(#customTable)', 'url(#customField)', 'url(#gradReport)', 'url(#reportTable)', 'url(#reportField)', 'url(#gradFunctionalObject)', 'url(#gradModuleGroup)', 'url(#gradBusinessObject)', 'url(#gradBusinessTerm)'];
  const ws = [ 40, 40 , 40, 40, 100, 100, 100, 100, 70, 100, 100, 100, 100, 100, 100];
  const ys = [ 150, 100 , 50, 0, 200, 310, 420, 530, 0, 80 , 190, 300, 410, 520, 630, 740];
  const textsYs = [3 , 55 , 108, 154, 208, 320, 430, 540, 3 , 90 , 200, 310, 420, 530, 640, 750];
  // const ys = [ 0, 100, 200, 300];
  // const textsYs = [-3 , 55 , 119, 180];
  $('#legendTreeLineNode').empty();
  // Create SVG for the legend
  // const legends = d3.select("#legendTreeLineNode").append("svg")
  //   .attr("width", 250)
  //   .attr("height", 105)
  //   .append("g")
  wrapper.attr('class', 'legendWrapper')
    .attr('transform', 'translate(50,0)');
  wrapper.append('rect')
    .attr('x' , 0)
    .attr('y' , 0)
    .attr('width', '90%')
    .attr('height', 60)
    .attr('stroke', 'gray')
    .attr('stroke-dasharray', '2.2')
    .attr('stroke-width', '0.5')
    .attr('stroke-linecap', 'round')
    .attr('fill', 'white');
  wrapper.selectAll('.legendRect')
    .data(lang === 'en' ? labelsEn : labelsFr)
    .enter().append('rect')
    .attr('y' , (d, i) => i < 8 ? 5 : 30)
    // .attr('x' , (d, i) => i * 120)
   .attr('x' , (d, i) => ys[i])
    .attr('width', (d, i) => ws[i])
    .attr('height', 20)
    .attr('class', 'legendRect')
    .attr('rx', 3)
    .attr('ry', 3)
    .attr('fill', (d , i) => colors[i] );
  wrapper.selectAll('.legendoLabel')
    .data(lang === 'en' ? labelsEn : labelsFr)
    .enter().append('text')
    .attr('class', 'legendoLabel')
    .attr('font-family', 'auto')
    .attr('font-size', '10px')
    .attr('fill', 'white')
    .attr('y', (d, i) => i < 8 ? 17 : 43)
    // .attr('x', (d, i) =>  i * 50 )
   .attr('x', (d, i) =>  textsYs[i] )
    .text((d , i) => lang === 'en' ? labelsEn[i] : labelsFr[i]  );
};
