export class MetaRole{
  name: string;
  description: string;
  createMeta: boolean;
  readMeta: boolean;
  updateMeta: boolean;
  deleteMeta: boolean;


  constructor(name: string, description: string, createMeta: boolean, readMeta: boolean, updateMeta: boolean, deleteMeta: boolean) {
    this.name = name;
    this.description = description;
    this.createMeta = createMeta;
    this.readMeta = readMeta;
    this.updateMeta = updateMeta;
    this.deleteMeta = deleteMeta;
  }
}
