export class ApplicationDef  {
  appl: string;
  creato = false;
  reado = false;
  updato = false;
  deleto = false;

  constructor(appl, create, read, update, deleto){
    this.appl = appl;
    this.creato = create;
    this.reado = read;
    this.deleto = deleto;
    this.updato = update;
  }
}
