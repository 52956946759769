import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ReportService } from '../../../services/reports/report.service';
import { CookieService } from 'ngx-cookie-service';
import { DynamicScriptLoaderService } from "../../../dynamic-script-loader-service.service";
import { Router } from "@angular/router";
import { addLoader, d3FunctionCircleProgress, refreshComponent, removeLoader, Shared } from '../../../shared';
import Swal from 'sweetalert2';
import { ReportsComponent } from '../reports.component';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
declare const $: any;
declare const swal: any;
declare const ApexCharts: any;
declare const CKEDITOR: any;
@Component({
  selector: 'app-reports-details',
  templateUrl: './reports-details.component.html',
  styleUrls: ['./reportDetails.css']
})
export class ReportsDetailsComponent implements OnInit, OnDestroy {
  @ViewChild(ReportsComponent, { static: false }) reportsComponent: ReportsComponent;
  subscription: Subscription = new Subscription();
  lang: string;
  assets = Shared.ASSETS;
  public listApp = [];
  toggle = {};
  public app_env = [];
  postData: any;
  postData1: any;
  params: string[];
  app: string;
  env: string;
  environ: string;
  indexBase: string;
  type: string;
  reportApprovedCount = [];
  reportArchivedCount = [];
  reportDraftedCount = [];
  reportApprovedCount1 = 0;
  reportArchivedCount1 = 0;
  reportDraftedCount1 = 0;
  reportAllCount = 0;
  reportCountWithoutDrafted = 0;
  echelle = 0;
  reportApprovedCount2 = 0;
  reportArchivedCount2 = 0;
  reportDraftedCount2 = 0;
  reportListDate = [];
  reportApprovedArchivedListCount = [];
  reportCountAllTabs = 0;
  reportsData = [];
  switchUser: string;
  // CKEditor
  descreportname: any;
  evenement: any;
  public reports = [];
  enviLoc: any;
  listVersionId = [];
  listVersionIdSelected = [];
  enableDeleteAll = false;
  // remove spinner
  getListApps = false;
  getStatistBar = false;
  getStatisPie = false;
  roleCurrentUser = localStorage.getItem('roles');
  createReport = false;
  updateReport = false;
  deleteReport = false;
  removeReport = false;

  // updateStatus One Version
  countVersionListApp = 0;

  // mass update status
  approvedSelected = false;
  listVersionIdSelectedReportApproved = [];
  statusAct: string;
  event: string;
  idUpdateOne: string;
  typeApp: string;

  archivedSelected = false;
  listVersionIdSelectedReportArchived = [];

  deletedSelected = false;
  listVersionIdSelectedReportDeleted = [];

  removedSelected = false;
  listVersionIdSelectedReportRemoved = [];
  removed = false;
  haveListDesigner = false;
  haveListSelected = false;

  selectedReportNameUpdateMass = 'no';
  // end mass update status
  detailsMsRemoved = false;
  constructor(private reportService: ReportService, private cookieService: CookieService,
    private reportCompo: ReportsComponent, private dynamicScriptLoader: DynamicScriptLoaderService,
    private router: Router, private translate: TranslateService) { }

  ngOnInit() {
    $('.tableStat').css('display', 'none');
    this.showListApplication();
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onClick(event) {
    $('#' + event).slideToggle(500);
  }
  // list application (base pir type database and environment)
  showListApplication() {
    let subscription1$ = this.reportService.getListApplication().subscribe(
      data => {
        this.listApp = data;
        // console.log(this.listApp);
        for (let app_item of this.listApp) {

          for (let app_type of app_item.types) {
            this.app_env = app_type.env;
            for (let environement of app_type.env) {
            }
          }
        }
      },
      err => {
        $('#listAppReportDetail').css('display', 'none');
        $('#noListAppReportDetail').css('display', 'block');
        console.error(err);
      },
      () => {
        if (this.listApp.length == 0) {
          $('#listAppReportDetail').css('display', 'none');
          $('#noListAppReportDetail').css('display', 'block');
        }
      }
    );
    this.subscription.add(subscription1$);
  }
  // select db from list application
  onGetReportByAppTypeDbEnv(appl, typeDb, env, indexBase, event, countVersion) {
    this.countVersionListApp = countVersion;
    $('img').each(function () {
      $(this).attr('src', $(this).attr("src").replace('assets/images/icon-db/checked.png','assets/images/icon-db/clickable.png'));
    });
    $('#chartReportDetail').empty();
    // $(event.target).attr('src', '../../../../' + Shared.ASSETS + '/images/icon-db/checked.png');
    let envi = ['Reference', 'Developpement', 'QAL', 'Pre-Production', 'Production'];
    this.evenement = event;
    this.app = appl;
    this.env = env;
    this.environ = envi[env];
    this.indexBase = indexBase;
    this.type = typeDb;
    let subscription2$ = this.reportService.getReportByAppTypeDbEnv(this.app, this.type, envi[this.env]).subscribe(
      data => {
        this.reportsData = data;
        if (data.length > 0) {
          this.createReport = data[0].tabReports[0].create;
          this.deleteReport = data[0].tabReports[0].delete;
          this.updateReport = data[0].tabReports[0].update;
          this.removeReport = data[0].tabReports[0].remove;
        }
        $('html, body').animate({
          scrollTop: $('#scrollSelect').offset().top - 70
        }, 2000);
      },
      err => {
        console.error(err);
      },
      () => {
        $('#selected_base_' + this.app + '_' + indexBase + '_' + this.env).attr('src', 'assets/images/icon-db/checked.png');
        // show CKeditor
        setTimeout(() => {

          this.reportsData.map(item => {
            $('#descriptionDisabled' + item.reportName).html(this.reportsData[0].descreportname);
            $('#descriptionDisabled' + item.reportName).css({ opacity: 1, height: '80%', width: '100%' });
            $('#cke_' + item.reportName).css({ opacity: 0, height: 0, width: 0 });
            $('#' + item.reportName).css({ opacity: 0, height: 0, width: 0 });
            // $('.archivedGroupReport' + item.reportName).parent().css('display', 'table-caption');
          });
        }, 500);
        this.onGetStatisticReportByStatusEnv(this.app, this.type, envi[this.env]);
      }
    );
    this.subscription.add(subscription2$);
  }
  // masquer les version archiver
  hideArchivedVersionsReport(index) {
    $('.archivedGroupReport' + index).parent().css('display', 'none');
    $('#iconHideVersionReport' + index).css('display', 'none');
    $('#iconShowVersionReport' + index).css('display', 'inline-block');
  }
  // afficher les version archiver
  showArchivedVersionsReport(index) {
    $('.archivedGroupReport' + index).parent().css('display', 'table-caption');
    $('#iconHideVersionReport' + index).css('display', 'inline-block');
    $('#iconShowVersionReport' + index).css('display', 'none');
  }

  // statistique draft, approved and archived report
  onGetStatisticReportByStatusEnv(app, typeDB, env) {
    let subscription4$ = this.reportService.getStatisticReportByStatusEnv(app, typeDB, env).subscribe(
      data => {
        $('#knob-drafted1').empty();
        $('#knob-approved1').empty();
        $('#knob-archived1').empty();
        this.reportApprovedCount = [];
        this.reportArchivedCount = [];
        this.reportDraftedCount = [];
        this.reportApprovedCount1 = 0;
        this.reportArchivedCount1 = 0;
        this.reportDraftedCount1 = 0;
        this.reportAllCount = 0;
        this.reportApprovedCount = data.map(item => item.reportApprovedCount);
        this.reportApprovedCount1 = this.reportApprovedCount[0];
        this.reportDraftedCount = data.map(item => item.reportDraftedCount);
        this.reportDraftedCount1 = this.reportDraftedCount[0];
        this.reportArchivedCount = data.map(item => item.reportArchivedCount);
        this.reportArchivedCount1 = this.reportArchivedCount[0];
        this.reportAllCount += this.reportApprovedCount1 + this.reportDraftedCount1 + this.reportArchivedCount1;
        if (this.reportAllCount !== 0) {
          this.echelle = 100 / this.reportAllCount;
          this.reportApprovedCount2 = Math.round(this.reportApprovedCount1 * this.echelle);
          this.reportDraftedCount2 = Math.round(this.reportDraftedCount1 * this.echelle);
          this.reportArchivedCount2 = Math.round(this.reportArchivedCount1 * this.echelle);
        } else {
          this.reportApprovedCount2 = 0;
          this.reportDraftedCount2 = 0;
          this.reportArchivedCount2 = 0;
        }
        $('#knob-drafted1').html('<input type="text" class="dialDrafted1" value="' + this.reportDraftedCount1 + '" data-width="80" data-height="80"\n' +
          '                                       data-fgColor="#8FD8FE" readonly="readonly">');
        $('#knob-approved1').html('<input type="text" class="dialApproved1" value="' + this.reportApprovedCount1 + '" data-width="80" data-height="80"\n' +
          '                                       data-fgColor="#67de69" readonly="readonly">');
        $('#knob-archived1').html('<input type="text" class="dialArchived1" value="' + this.reportArchivedCount1 + '" data-width="80" data-height="80"\n' +
          '                                       data-fgColor="#ff763b" readonly="readonly">');
        setTimeout(() => {
          d3FunctionCircleProgress(this.reportDraftedCount2, 'contentKnobDrafted', this.reportDraftedCount1);
          setTimeout(() => {
            d3FunctionCircleProgress(this.reportApprovedCount2, 'contentKnobApproved', this.reportApprovedCount1);
            setTimeout(() => {
              d3FunctionCircleProgress(this.reportArchivedCount2, 'contentKnobArchived', this.reportArchivedCount1);
            }, 500);
          }, 500);
        }, 500);
        // $('.dialApproved1').knob({
        //     'min': 0,
        //     'max': this.reportAllCount,
        //     'thickness': 0.1
        // });
        // $('.dialDrafted1').knob({
        //     'min': 0,
        //     'max': this.reportAllCount,
        //     'thickness': 0.1
        // });
        // $('.dialArchived1').knob({
        //     'min': 0,
        //     'max': this.reportAllCount,
        //     'thickness': 0.1
        // });
        // $('.knob').knob({
        //     draw: function () {
        //         if ("tron" == this.$.data("skin")) {
        //             this.cursorExt = .3;
        //             var t, i = this.arc(this.cv);
        //             return this.g.lineWidth = this.lineWidth, this.o.displayPrevious && (
        //                 t = this.arc(this.v), this.g.beginPath(), this.g.strokeStyle = this.pColor, this.g.arc(
        //                     this.xy, this.xy, this.radius - this.lineWidth, t.s, t.e, t.d), this.g.stroke()),
        //                 this.g.beginPath(), this.g.strokeStyle = this.o.fgColor, this.g.arc(
        //                     this.xy, this.xy, this.radius - this.lineWidth, i.s, i.e, i.d), this.g.stroke(),
        //                 this.g.lineWidth = 2, this.g.beginPath(), this.g.strokeStyle = this.o.fgColor,
        //                 this.g.arc(this.xy, this.xy, this.radius - this.lineWidth + 1 + 2 * this.lineWidth / 3, 0, 2 * Math.PI, !1),
        //                 this.g.stroke(), !1;
        //         }
        //     }
        // });


        this.reportListDate = data.map(item => item.reportListDate);
        this.reportCountAllTabs = data.map(item => item.reportCountAllTabs);
        this.reportCountWithoutDrafted = data.map(item => item.reportCountWithoutDrafted);
        this.reportApprovedArchivedListCount = data.map(item => item.reportApprovedArchivedListCount);
        this.startScript();
        // API reports details

      },
      err => {
        console.error(err);
      },
      () => {
        $('.tableStat').css('display', 'block');
      }
    );
    this.subscription.add(subscription4$);
  }

  async startScript() {
    await this.dynamicScriptLoader.load('apexcharts').then(data => {
      this.loadChart3();
    }).catch(err => console.error(err));
  }
  private loadChart3() {
    $('#chartReportDetail').empty();
    if (document.querySelector('#chartReportDetail') != null) {
      var options = {
        chart: {
          height: 155,
          type: 'line',
          shadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: true
          }
        },
        colors: ['#39ea01'],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          width: 2,
          curve: 'smooth'
        },
        series: [{
          name: "Tables ",
          data: this.reportApprovedArchivedListCount[0],
        }],
        grid: {
          borderColor: '#ffffff',
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        markers: {
          size: 4,
          strokeWidth: 2,
          colors: ['#1f1bea'],
          hover: {
            size: 6,
          }
        },
        xaxis: {
          categories: this.reportListDate[0],
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          },
        },
        yaxis: {
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          },
          min: 0,
          max: this.reportCountWithoutDrafted
        },
        legend: {
          position: 'top',
          horizontalAlign: 'right',
          floating: true,
          offsetY: -25,
          offsetX: -5
        }
      };

      var chart = new ApexCharts(
        document.querySelector('#chartReportDetail'),
        options
      );

      chart.render();
    }
  }
  // in mass approve Report: test if exist version approved used in Designer
  onTestMsBeforeApprove(reportName, id, statusAct, event, type) {
    let subscription5$ = this.reportService.testMsBeforeApprove(reportName).subscribe(
      data => {
        if (data == 'no') {
          if (type == 'one') {
            this.onSetReportChangeStatus(id, statusAct, event);
          } else {
            this.OnApproveMultipleByListIdsReport();
          }
        } else {
          this.statusAct = statusAct;
          this.event = event;
          this.idUpdateOne = id;
          this.typeApp = type;
          const that = this;
          this.haveListDesigner = true;
          $('#ListAllDesignApprove').empty();
          if ($('#listDesignerAp').length > 0) {
            $('#listDesignerAp').remove();
          }
          $('#ListAllDesignApprove').append('<table id="listDesignerAp" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
            '<tr>' +
            '<th>Data Dictionary</th>' +
            '<th>Designers</th>' +
            '</tr>' +
            '</thead></table>');

          $('#listDesignerAp').DataTable({
            processing: true,
            destroy: true,
            pageLength: 3,
            rowCallback(row, data) {
            },
            initComplete(settings, json) {
              const table = this.api();
              data.map(item => {
                table.row.add([
                  item.nameModelStructure,
                  item.nameDesigner,
                ]).draw(false);
              });
            }
          });
          $('#approveMsUser').modal('show');
        }
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription5$);
  }
  // confirm Approve in Modal
  confirmApprove() {
    $('#approveMsUser').modal('hide');
    if (this.typeApp == 'one') {
      this.onSetReportChangeStatus(this.idUpdateOne, this.statusAct, this.event);
    } else if (this.typeApp == 'many') {
      this.OnApproveMultipleByListIdsReport();
    }
  }
  cancelApprove() {
    if (this.typeApp == 'one') {
      //@ts-ignore
      const select = $(this.event.target);
      select.html("<option style='color: blue' value='Draft' [selected]>Draft</option><option style='color: green' value='Approved'>Approved</option><option style='color: red' value='Deleted'>Deleted</option>");
    }
  }
  // show detail modal (list designer use approved version)
  showDetailMsDesigner(event) {
    let icon = $(event.target);
    if (icon.attr('class') === 'fas fa-list-alt') {
      icon.removeClass('fas fa-list-alt').addClass('far fa-list-alt');
      icon.html('&nbsp;Hide Details');
      $('#ListAllDesignApprove').css('display', 'block');
      $('#btnModal').css('display', 'block');
    } else if (icon.attr('class') === 'far fa-list-alt') {
      icon.removeClass('far fa-list-alt').addClass('fas fa-list-alt');
      icon.html('&nbsp;Show Details');
      $('#ListAllDesignApprove').css('display', 'none');
      $('#btnModal').css('display', 'none');
    }
  }
  onSetReportChangeStatus(id, statusAct, event) {
    var router = this.router;
    var status = $(event.target).val();
    var reportservice = this.reportService;
    let titleText = this.lang === 'en' ? 'Are you sure?' : 'Êtes-vous sûr(e) ?';
    let contentText = this.lang === 'en' ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !";
    let confirmButtonText = this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, Approuvé !';
    let cancelText = this.lang === 'en' ? 'Cancel' : 'Annuler';

    Swal.fire({
      title: titleText,
      text: contentText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelText
    }).then((result) => {
      if (result.value) {
        if (status == 'Deleted') {
          addLoader(this.lang === 'en' ? 'Deleting . . . ' : 'Suppression en cours ...')
        } else {
          addLoader(this.lang === 'en' ? 'Updating . . . ' : 'Mise à jour en cours ...');
        }
        let subscription6$ = reportservice.setReportChangeStatus(id, status).subscribe(
          data => {
            if (status == 'Deleted') {
              $('#chart1').empty();
              $('#chart3').empty();
            }
          },
          err => {
            if (status == 'Deleted') {
              // barchart
              this.reportCompo.getStaticsChartBar().then(() => this.reportCompo.startScriptBar());
              this.reportCompo.onGetStatisticApp().then(() => this.reportCompo.startScriptPie());
              if (this.countVersionListApp == 1) {
                this.countVersionListApp = 0;
                $('.tableStat').css('display', 'none');
                this.reportsData = [];
                this.showListApplication();
              } else {
                this.countVersionListApp -= 1;
                this.showListApplication();
                setTimeout(() => {
                  this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                }, 1000);
              }
              this.reportCompo.onGetApplicationByReport('null');
            } else {
              this.reportCompo.onGetApplicationByReport('null');
              setTimeout(() => {
                this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
              }, 1000);
            }
            removeLoader();
            console.error(err);
          },
          () => {
            if (status == 'Deleted') {
              // barchart
              this.reportCompo.getStaticsChartBar().then(() => this.reportCompo.startScriptBar());
              this.reportCompo.onGetStatisticApp().then(() => this.reportCompo.startScriptPie());
              if (this.countVersionListApp == 1) {
                this.countVersionListApp = 0;
                $('.tableStat').css('display', 'none');
                this.reportsData = [];
                this.showListApplication();
              } else {
                this.countVersionListApp -= 1;
                this.showListApplication();
                setTimeout(() => {
                  this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                }, 1000);
              }
              this.reportCompo.onGetApplicationByReport('null');
            } else {
              this.reportCompo.onGetApplicationByReport('null');
              setTimeout(() => {
                this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
              }, 1000);
            }
            removeLoader();
          }
        );
        this.subscription.add(subscription6$);
      } else if (
        result.dismiss === Swal.DismissReason.cancel
      ) {
        const select = $(event.target);
        if (statusAct == 'archived') {
          select.html("<option value='Archived' style='color: darkorange' [selected]>Archived</option><option style='color: red' value='Deleted'>Deleted</option>");
        } else if (statusAct == 'draft') {
          select.html("<option style='color: blue' value='Draft' [selected]>Draft</option><option style='color: green' value='Approved'>Approved</option><option style='color: red' value='Deleted'>Deleted</option>");
        }
      }
    });
  }

  // onSetRemoveReport(id, app) {
  //   var router = this.router;
  //   var reportservice = this.reportService;
  //   Swal.fire({
  //     title: 'Are you sure?',
  //     text: "You won't be able to revert this!",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     confirmButtonText: 'Yes, delete it!'
  //   }).then((result) => {
  //     if (result.value) {
  //       addLoaderDelete('vide', 'f', true);
  //       let subscription7$ = reportservice.setRemoveReport(id, app).subscribe(
  //         data => {
  //         },
  //         err => {
  //           console.error(err);
  //         },
  //         () => {
  //           removeLoader('vide', 'f', true);
  //           refreshComponent(router);
  //         }
  //       );
  //       this.subscription.add(subscription7$);
  //     }
  //   });
  // }
  onUpdateDescriptionReportName(reportname, description) {
    var router = this.router;
    // var reportservice = this.reportServ;
    let titleText = this.lang === 'en' ? 'Are you sure?' : 'Êtes-vous sûr(e) ?';
    let contentText = this.lang === 'en' ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !";
    let confirmButtonText = this.lang === 'en' ? 'Yes, Update it!' : 'Oui, Mettre à jour !';
    let cancelText = this.lang === 'en' ? 'Cancel' : 'Annuler';

    Swal.fire({
      title: titleText,
      text: contentText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelText
    }).then((result) => {
      if (result.value) {
        addLoader(this.lang === 'en' ? 'PLZ&#160;&#160;WAIT . . .' : 'VEUILLEZ&#160;&#160;PATIENTEZ . . .');
        if (description == '') {
          description = 'unset';
        }
        let subscriptions8$ = this.reportService.updateDescriptionReportName(reportname, description).subscribe(
          data => {
            if (description != 'undefined' && description != 'unset') {
              if (description.length > 0) {
                if (description != ' ') {
                  this.reportsData[0].descreportname = atob(description);
                } else {
                  this.reportsData[0].descreportname = '';
                }
              } else {
                this.reportsData[0].descreportname = '';
              }
            } else {
              this.reportsData[0].descreportname = '';
            }
            this.reportsData[0].tabReports.map(d => {
              d.updatedreportAt = data;
              d.updatedBy = localStorage.getItem('username');
            });
          },
          err => {
            console.error(err);
            removeLoader();
          },
          () => {
            removeLoader();
          }
        );
        this.subscription.add(subscriptions8$);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // if (this.reportsData[0].descreportname != null && this.reportsData[0].descreportname != 'undefined') {
        //   if (this.reportsData[0].descreportname.length > 0) {
        //     if (this.reportsData[0].descreportname != ' ') {
        $('#descriptionDisabled' + reportname).html(this.reportsData[0].descreportname);
        //     }
        //   }
        // }
      }
    });
  }
  // show hide icon + - description report
  showHideDescriptionReport(cl) {
    $('.' + cl).css('display') === 'none' ? $('.' + cl).css('display', 'table-row') : $('.' + cl).css('display', 'none');
    $('#' + cl).css('display') === 'none' ? $('#' + cl).css('display', 'table-row') : $('#' + cl).css('display', 'none');
  }
  // show hide icon edit save description CKEditor
  editOrSaveDescriptionCKEditor(cl, index) {
    // save edit
    if ($('#editDescEditor' + cl.reportName).css('display') === 'none') {
      this.onUpdateDescriptionReportName(cl.reportName, this.descreportname);
      $('#editDescEditor' + cl.reportName).css('display', 'inline');
      $('#saveDescEditor' + cl.reportName).css('display', 'none');
      $('#descriptionDisabled' + cl.reportName).css({ opacity: 1, height: '80%', width: '100%' });
      $('#cke_' + cl.reportName).css({ opacity: 0, height: 0, width: 0 });
      $('#' + cl.reportName).css({ opacity: 0, height: 0, width: 0 });
    } else {
      this.startScriptCkEditor(cl);
      // show edit
      $('#editDescEditor' + cl.reportName).css('display', 'none');
      $('#saveDescEditor' + cl.reportName).css('display', 'inline');
      $('#descriptionDisabled' + cl.reportName).css({ opacity: 0, height: 0, width: 0 });
      $('#cke_' + cl.reportName).css({ opacity: 1, height: '80%', width: '100%' });
      $('#' + cl.reportName).css({ opacity: 1, height: '80%', width: '100%' });
    }
  }
  // CKEditor add
  async startScriptCkEditor(report) {
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Link', 'Unlink', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', 'Superscript', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'], '-', 'NumberedList']];
      this.loadDataCkEditor(report);
    }).catch(error => console.error(error));
  }

  private loadDataCkEditor(report) {
    const that = this;
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances[report.reportName]) {
      CKEDITOR.instances[report.reportName].destroy(true);
    }
    var editor = CKEDITOR.replace(report.reportName, { toolbar: 'MA' });
    editor.config.height = 50;
    editor.setData(report.descreportname);
    editor.on('change', function (evt) {
      $('#descriptionDisabled' + report.reportName).html(evt.editor.getData());
      that.descreportname = btoa(evt.editor.getData());
    });
  }
  // tab modal import Data Map
  setActiveTabModal(event) {
    let activeTab = $(event.target);
    activeTab.attr('href') === '#tab_add' ? $('#tab_add').css('display', 'block') : $('#tab_add').css('display', 'none');
    activeTab.attr('href') === '#tab_import' ? $('#tab_import').css('display', 'block') : $('#tab_import').css('display', 'none');
  }
  selectOneVersionIdDelete(event, reportName, versionId) {
    if ($('#checked_all_' + reportName).is(':checked')) {
      $('#checked_all_' + reportName).prop('checked', false);
      const index = this.listVersionIdSelected.indexOf(versionId);
      if (index > -1) {
        this.listVersionIdSelected.splice(index, 1);
      }
    } else {
      if (event.currentTarget.checked) {
        this.listVersionIdSelected = [...this.listVersionIdSelected, versionId];
      } else {
        const index = this.listVersionIdSelected.indexOf(versionId);
        if (index > -1) {
          this.listVersionIdSelected.splice(index, 1);
        }
      }
    }
    if (this.listVersionIdSelected.length > 0) {
      $('#masstChange' + reportName).css('display', 'block');
    } else {
      $('#masstChange' + reportName).css('display', 'none');
    }
  }

  // new -------------------------------------------------

  // reset all
  checkAllReset(event) {
    this.selectedReportNameUpdateMass = 'rien';
    this.approvedSelected = false;
    this.archivedSelected = false;
    this.deletedSelected = false;
    this.removedSelected = false;

    this.listVersionIdSelectedReportApproved.map(version => {
      $('#checked_report_Approved_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportApproved = [];

    this.listVersionIdSelectedReportArchived.map(version => {
      $('#checked_report_Archived_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportArchived = [];

    this.listVersionIdSelectedReportDeleted.map(version => {
      $('#checked_report_Deleted_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportDeleted = [];

    this.listVersionIdSelectedReportRemoved.map(version => {
      $('#checked_Report_Removed_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportRemoved = [];

    $(event.target).next().css('color', 'black');
    $(event.target).next().next().css('color', 'black');
    $(event.target).next().next().next().css('color', 'black');
    $(event.target).next().next().next().next().css('color', 'black');
  }
  // start mass approved
  // *** choisir l'action Approved
  checkApproved(event, reportName) {
    $('.fa-check-circle').css('color', 'black');
    $('.fa-file-archive').css('color', 'black');
    $('.fa-trash').css('color', 'black');
    $('.fa-times-circle').css('color', 'black');
    this.selectedReportNameUpdateMass = reportName;
    this.approvedSelected = true;
    this.archivedSelected = false;
    this.deletedSelected = false;
    this.removedSelected = false;

    this.listVersionIdSelectedReportArchived.map(version => {
      $('#checked_report_Archived_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportArchived = [];

    this.listVersionIdSelectedReportDeleted.map(version => {
      $('#checked_report_Deleted_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportDeleted = [];

    this.listVersionIdSelectedReportRemoved.map(version => {
      $('#checked_Report_Removed_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportRemoved = [];

    $('#massChangeReportApproved' + reportName).css('color', 'green');
    $(event.target).css('color', 'blue');
    $(event.target).next().css('color', 'black');
    $(event.target).next().next().css('color', 'black');
    $(event.target).next().next().next().css('color', 'black');
  }
  selectAllReportByNameApprove(eve, reportName) {
    if (eve.currentTarget.checked) {
      this.listVersionIdSelectedReportApproved = [];
      let subscription9$ = this.reportService.getAllReportVersionIdByNameToApprove(reportName).subscribe(
        result => {
          result.map(item => {
            $('#checked_report_Approved_' + item.$id).prop('checked', true);
            this.listVersionIdSelectedReportApproved = [...this.listVersionIdSelectedReportApproved, item.$id];
          });
        },
        err => {
          console.error(err);
        },
        () => {
          if (this.listVersionIdSelectedReportApproved.length > 0) {
            $('#massChangeReportApproved' + reportName).css('display', 'inline-block');
          } else {
            $('#massChangeReportApproved' + reportName).css('display', 'none');
          }
        }
      );
      this.subscription.add(subscription9$);
    } else {
      $('#massChangeReportApproved' + reportName).css('display', 'none');
      this.listVersionIdSelectedReportApproved.map(version => {
        $('#checked_report_Approved_' + version).prop('checked', false);
      });
      this.listVersionIdSelectedReportApproved = [];
    }
  }
  selectOneVersionIdApproveReport(ev, reportName, versionId) {
    if ($('#checked_all_report_approved_' + reportName).is(':checked')) {
      $('#checked_all_report_approved_' + reportName).prop('checked', false);
      const index = this.listVersionIdSelectedReportApproved.indexOf(versionId);
      if (index > -1) {
        this.listVersionIdSelectedReportApproved.splice(index, 1);
      }
    } else {
      if (ev.currentTarget.checked) {
        this.listVersionIdSelectedReportApproved = [...this.listVersionIdSelectedReportApproved, versionId];
      } else {
        const index = this.listVersionIdSelectedReportApproved.indexOf(versionId);
        if (index > -1) {
          this.listVersionIdSelectedReportApproved.splice(index, 1);
        }
      }
    }
    if (this.listVersionIdSelectedReportApproved.length > 0) {
      $('#massChangeReportApproved' + reportName).css('display', 'inline-block');
    } else {
      $('#massChangeReportApproved' + reportName).css('display', 'none');
    }
  }
  OnApproveMultipleByListIdsReport() {
    if (this.listVersionIdSelectedReportApproved.length > 0) {
      var router = this.router;
      var reportService = this.reportService;
      var username = localStorage.getItem('username');
      let titleText = this.lang === 'en' ? 'APPROVE version(s)?' : 'APPROUVER la(les) version(s) ?';
      let contentText = this.lang === 'en' ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !";
      let confirmButtonText = this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, Approuver !';
      let cancelText = this.lang === 'en' ? 'Cancel' : 'Annuler';

      Swal.fire({
        title: titleText,
        text: contentText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelText
      }).then((result) => {
        if (result.value) {
          addLoader(this.lang === 'en' ? 'PLZ&#160;&#160;WAIT . . .' : 'VEUILLEZ&#160;&#160;PATIENTEZ . . .');
          var dataReport = {
            'username': username,
            'list': this.listVersionIdSelectedReportApproved,
          };
          let subscription10$ = reportService.approveMultipleByListIdsReport(dataReport).subscribe(
            data => {
              // reset list IDs and ICONs
              this.approvedSelected = false;
              this.archivedSelected = false;
              this.deletedSelected = false;
              this.removedSelected = false;

              this.listVersionIdSelectedReportApproved.map(version => {
                $('#checked_report_Approved_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportApproved = [];

              this.listVersionIdSelectedReportArchived.map(version => {
                $('#checked_report_Archived_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportArchived = [];

              this.listVersionIdSelectedReportDeleted.map(version => {
                $('#checked_report_Deleted_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportDeleted = [];

              this.listVersionIdSelectedReportRemoved.map(version => {
                $('#checked_Report_Removed_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportRemoved = [];

            },
            err => {
              this.reportCompo.onGetApplicationByReport('null');
              setTimeout(() => {
                this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                removeLoader();
              }, 1000);
              console.error(err);
            },
            () => {
              this.reportCompo.onGetApplicationByReport('null');
              setTimeout(() => {
                this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                removeLoader();
              }, 1000);
            }
          );
          this.subscription.add(subscription10$);
        }
      });
    } else {
      alert('No selected report');
    }
  }
  // end mass approved
  // start mass archived
  // *** choisir l'action Archived
  checkArchived(event, reportName) {
    $('.fa-check-circle').css('color', 'black');
    $('.fa-file-archive').css('color', 'black');
    $('.fa-trash').css('color', 'black');
    $('.fa-times-circle').css('color', 'black');
    this.selectedReportNameUpdateMass = reportName;
    this.approvedSelected = false;
    this.archivedSelected = true;
    this.deletedSelected = false;
    this.removedSelected = false;

    this.listVersionIdSelectedReportApproved.map(version => {
      $('#checked_report_Approved_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportApproved = [];

    this.listVersionIdSelectedReportDeleted.map(version => {
      $('#checked_report_Deleted_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportDeleted = [];

    this.listVersionIdSelectedReportRemoved.map(version => {
      $('#checked_Report_Removed_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportRemoved = [];

    $('#massChangeReportArchived' + reportName).css('color', 'orange');
    $(event.target).prev().css('color', 'black');
    $(event.target).css('color', 'blue');
    $(event.target).next().css('color', 'black');
    $(event.target).next().next().css('color', 'black');
  }
  selectAllReportByNameArchive(eve, reportName) {
    if (eve.currentTarget.checked) {
      this.listVersionIdSelectedReportArchived = [];
      let subscription12$ = this.reportService.getAllReportVersionIdByNameToArchive(reportName).subscribe(
        result => {
          result.map(item => {
            $('#checked_report_Archived_' + item.$id).prop('checked', true);
            this.listVersionIdSelectedReportArchived = [...this.listVersionIdSelectedReportArchived, item.$id];
          });
          // console.log(this.listVersionIdSelectedReportArchived);
        },
        err => {
          console.error(err);
        },
        () => {
          if (this.listVersionIdSelectedReportArchived.length > 0) {
            $('#massChangeReportArchived' + reportName).css('display', 'inline-block');
          } else {
            $('#massChangeReportArchived' + reportName).css('display', 'none');
          }
        }
      );
      this.subscription.add(subscription12$);
    } else {
      $('#massChangeReportArchived' + reportName).css('display', 'none');
      this.listVersionIdSelectedReportArchived.map(version => {
        $('#checked_report_Archived_' + version).prop('checked', false);
      });
      this.listVersionIdSelectedReportArchived = [];
    }
  }
  selectOneVersionIdArchiveReport(ev, reportName, versionId) {
    if ($('#checked_all_report_archived_' + reportName).is(':checked')) {
      $('#checked_all_report_archived_' + reportName).prop('checked', false);
      const index = this.listVersionIdSelectedReportArchived.indexOf(versionId);
      if (index > -1) {
        this.listVersionIdSelectedReportArchived.splice(index, 1);
      }
    } else {
      if (ev.currentTarget.checked) {
        this.listVersionIdSelectedReportArchived = [...this.listVersionIdSelectedReportArchived, versionId];
      } else {
        const index = this.listVersionIdSelectedReportArchived.indexOf(versionId);
        if (index > -1) {
          this.listVersionIdSelectedReportArchived.splice(index, 1);
        }
      }
    }
    if (this.listVersionIdSelectedReportArchived.length > 0) {
      $('#massChangeReportArchived' + reportName).css('display', 'inline-block');
    } else {
      $('#massChangeReportArchived' + reportName).css('display', 'none');
    }
  }
  OnArchiveMultipleByListIdsReport() {
    if (this.listVersionIdSelectedReportArchived.length > 0) {
      var router = this.router;
      var reportService = this.reportService;
      var username = localStorage.getItem('username');
      let titleText = this.lang === 'en' ? 'ARCHIVE version(s)?' : 'ARCHIVER la(les) version(s) ?';
      let contentText = this.lang === 'en' ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !";
      let confirmButtonText = this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, Approuver !';
      let cancelText = this.lang === 'en' ? 'Cancel' : 'Annuler';

      Swal.fire({
        title: titleText,
        text: contentText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelText
      }).then((result) => {
        if (result.value) {
          addLoader(this.lang === 'en' ? 'PLZ&#160;&#160;WAIT . . .' : 'VEUILLEZ&#160;&#160;PATIENTEZ . . .');
          var dataReport = {
            'username': username,
            'list': this.listVersionIdSelectedReportArchived,
          };
          let subscription13$ = reportService.archiveMultipleByListIdsReport(dataReport).subscribe(
            data => {
              // reset list IDs and ICONs
              this.approvedSelected = false;
              this.archivedSelected = false;
              this.deletedSelected = false;
              this.removedSelected = false;

              this.listVersionIdSelectedReportApproved.map(version => {
                $('#checked_report_Approved_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportApproved = [];

              this.listVersionIdSelectedReportArchived.map(version => {
                $('#checked_report_Archived_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportArchived = [];

              this.listVersionIdSelectedReportDeleted.map(version => {
                $('#checked_report_Deleted_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportDeleted = [];

              this.listVersionIdSelectedReportRemoved.map(version => {
                $('#checked_Report_Removed_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportRemoved = [];
            },
            err => {
              this.reportCompo.onGetApplicationByReport('null');
              setTimeout(() => {
                this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                removeLoader();
              }, 1000);
              console.error(err);
            },
            () => {
              this.reportCompo.onGetApplicationByReport('null');
              setTimeout(() => {
                this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                removeLoader();
              }, 1000);
            }
          );
          this.subscription.add(subscription13$);
        }
      });
    } else {
      alert('No selected report');
    }
  }
  // end mass archived
  // start mass deleted
  // *** choisir l'action Deleted
  checkDeleted(event, reportName) {
    $('.fa-check-circle').css('color', 'black');
    $('.fa-file-archive').css('color', 'black');
    $('.fa-trash').css('color', 'black');
    $('.fa-times-circle').css('color', 'black');
    this.selectedReportNameUpdateMass = reportName;
    this.approvedSelected = false;
    this.archivedSelected = false;
    this.deletedSelected = true;
    this.removedSelected = false;

    this.listVersionIdSelectedReportApproved.map(version => {
      $('#checked_report_Approved_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportApproved = [];

    this.listVersionIdSelectedReportArchived.map(version => {
      $('#checked_Report_Archived_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportArchived = [];

    this.listVersionIdSelectedReportRemoved.map(version => {
      $('#checked_Report_Removed_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportRemoved = [];

    $('#massChangeReportDeleted' + reportName).css('color', 'red');
    $(event.target).prev().prev().css('color', 'black');
    $(event.target).prev().css('color', 'black');
    $(event.target).css('color', 'blue');
    $(event.target).next().css('color', 'black');
  }
  selectAllReportByNameDelete(eve, reportName) {
    if (eve.currentTarget.checked) {
      this.listVersionIdSelectedReportDeleted = [];
      let subscriptions14$ = this.reportService.getAllReportVersionIdByNameToDelete(reportName).subscribe(
        result => {
          result.map(item => {
            $('#checked_report_Deleted_' + item.$id).prop('checked', true);
            this.listVersionIdSelectedReportDeleted = [...this.listVersionIdSelectedReportDeleted, item.$id];
          });
        },
        err => {
          console.error(err);
        },
        () => {
          if (this.listVersionIdSelectedReportDeleted.length > 0) {
            $('#massChangeReportDeleted' + reportName).css('display', 'inline-block');
          } else {
            $('#massChangeReportDeleted' + reportName).css('display', 'none');
          }
        }
      );
      this.subscription.add(subscriptions14$);
    } else {
      $('#massChangeReportDeleted' + reportName).css('display', 'none');
      this.listVersionIdSelectedReportDeleted.map(version => {
        $('#checked_report_Deleted_' + version).prop('checked', false);
      });
      this.listVersionIdSelectedReportDeleted = [];
    }
  }
  selectOneVersionIdDeleteReport(ev, reportName, versionId) {
    if ($('#checked_all_report_deleted_' + reportName).is(':checked')) {
      $('#checked_all_report_deleted_' + reportName).prop('checked', false);
      const index = this.listVersionIdSelectedReportDeleted.indexOf(versionId);
      if (index > -1) {
        this.listVersionIdSelectedReportDeleted.splice(index, 1);
      }
    } else {
      if (ev.currentTarget.checked) {
        this.listVersionIdSelectedReportDeleted = [...this.listVersionIdSelectedReportDeleted, versionId];
      } else {
        const index = this.listVersionIdSelectedReportDeleted.indexOf(versionId);
        if (index > -1) {
          this.listVersionIdSelectedReportDeleted.splice(index, 1);
        }
      }
    }
    if (this.listVersionIdSelectedReportDeleted.length > 0) {
      $('#massChangeReportDeleted' + reportName).css('display', 'inline-block');
    } else {
      $('#massChangeReportDeleted' + reportName).css('display', 'none');
    }
  }
  OnDeleteMultipleByListIdsReport() {
    if (this.listVersionIdSelectedReportDeleted.length > 0) {
      var router = this.router;
      var reportService = this.reportService;
      let countSelectedV = this.listVersionIdSelectedReportDeleted.length;
      var username = localStorage.getItem('username');
      var userId = localStorage.getItem('userId');

      let titleText = this.lang === 'en' ? 'DELETE version(s)?' : 'SUPPRIMER la(les) version(s) ?';
      let contentText = this.lang === 'en' ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !";
      let confirmButtonText = this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, Approuver !';
      let cancelText = this.lang === 'en' ? 'Cancel' : 'Annuler';

      Swal.fire({
        title: titleText,
        text: contentText,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelText
      }).then((result) => {
        if (result.value) {
          addLoader(this.lang === 'en' ? 'PLZ&#160;&#160;WAIT . . .' : 'VEUILLEZ&#160;&#160;PATIENTEZ . . .');
          var dataReport = {
            'username': username,
            'userId': userId,
            'list': this.listVersionIdSelectedReportDeleted,
          };
          let subscription15$ = reportService.deleteMultipleByListIdsReport(dataReport).subscribe(
            data => {
              $('#chart1').empty();
              $('#chart3').empty();
              // reset list IDs and ICONs
              this.approvedSelected = false;
              this.archivedSelected = false;
              this.deletedSelected = false;
              this.removedSelected = false;

              this.listVersionIdSelectedReportApproved.map(version => {
                $('#checked_report_Approved_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportApproved = [];

              this.listVersionIdSelectedReportArchived.map(version => {
                $('#checked_report_Archived_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportArchived = [];

              this.listVersionIdSelectedReportDeleted.map(version => {
                $('#checked_report_Deleted_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportDeleted = [];

              this.listVersionIdSelectedReportRemoved.map(version => {
                $('#checked_report_Removed_' + version).prop('checked', false);
              });
              this.listVersionIdSelectedReportRemoved = [];
            },
            err => {
              this.countVersionListApp -= countSelectedV;
              // console.log(this.countVersionListApp);
              if (this.countVersionListApp == 0) {
                $('.tableStat').css('display', 'none');
                this.reportsData = [];
                this.showListApplication();
              } else {
                this.showListApplication();
                setTimeout(() => {
                  this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                }, 1000);
              }
              this.reportCompo.onGetApplicationByReport('null');
              // barchart
              this.reportCompo.getStaticsChartBar().then(() => this.reportCompo.startScriptBar());
              this.reportCompo.onGetStatisticApp().then(() => this.reportCompo.startScriptPie());
              removeLoader();
              console.error(err);
            },
            () => {
              this.countVersionListApp -= countSelectedV;
              // console.log(this.countVersionListApp);
              if (this.countVersionListApp == 0) {
                $('.tableStat').css('display', 'none');
                this.reportsData = [];
                this.showListApplication();
              } else {
                this.showListApplication();
                setTimeout(() => {
                  this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                }, 1000);
              }
              this.reportCompo.onGetApplicationByReport('null');
              // barchart
              this.reportCompo.getStaticsChartBar().then(() => this.reportCompo.startScriptBar());
              this.reportCompo.onGetStatisticApp().then(() => this.reportCompo.startScriptPie());
              removeLoader();
            }
          );
          this.subscription.add(subscription15$);
        }
      });
    } else {
      alert('No selected report');
    }
  }
  // end mass deleted
  // start mass removed
  // *** choisir l'action Removed
  checkRemoved(event, reportName) {
    $('.fa-check-circle').css('color', 'black');
    $('.fa-file-archive').css('color', 'black');
    $('.fa-trash').css('color', 'black');
    $('.fa-times-circle').css('color', 'black');
    this.selectedReportNameUpdateMass = reportName;
    this.approvedSelected = false;
    this.archivedSelected = false;
    this.deletedSelected = false;
    this.removedSelected = true;

    this.listVersionIdSelectedReportApproved.map(version => {
      $('#checked_report_Approved_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportApproved = [];

    this.listVersionIdSelectedReportArchived.map(version => {
      $('#checked_Report_Archived_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportArchived = [];

    this.listVersionIdSelectedReportDeleted.map(version => {
      $('#checked_Report_Deleted_' + version).prop('checked', false);
    });
    this.listVersionIdSelectedReportDeleted = [];

    $('#massChangeReportRemoved' + reportName).css('color', 'red');
    $(event.target).prev().prev().prev().css('color', 'black');
    $(event.target).prev().prev().css('color', 'black');
    $(event.target).prev().css('color', 'black');
    $(event.target).css('color', 'blue');
  }
  selectAllReportByNameRemove(eve, reportName) {
    if (eve.currentTarget.checked) {
      this.listVersionIdSelectedReportRemoved = [];
      let subscription16$ = this.reportService.getAllReportVersionIdByNameToRemove(reportName).subscribe(
        result => {
          result.map(item => {
            $('#checked_report_Removed_' + item.$id).prop('checked', true);
            this.listVersionIdSelectedReportRemoved = [...this.listVersionIdSelectedReportRemoved, item.$id];
          });
        },
        err => {
          console.error(err);
        },
        () => {
          if (this.listVersionIdSelectedReportRemoved.length > 0) {
            $('#massChangeReportRemoved' + reportName).css('display', 'inline-block');
          } else {
            $('#massChangeReportRemoved' + reportName).css('display', 'none');
          }
        }
      );
      this.subscription.add(subscription16$);
    } else {
      $('#massChangeReportRemoved' + reportName).css('display', 'none');
      this.listVersionIdSelectedReportRemoved.map(version => {
        $('#checked_report_Removed_' + version).prop('checked', false);
      });
      this.listVersionIdSelectedReportRemoved = [];
    }
  }
  selectOneVersionIdRemoveReport(ev, reportName, versionId) {
    if ($('#checked_all_report_removed_' + reportName).is(':checked')) {
      $('#checked_all_report_removed_' + reportName).prop('checked', false);
      const index = this.listVersionIdSelectedReportRemoved.indexOf(versionId);
      if (index > -1) {
        this.listVersionIdSelectedReportRemoved.splice(index, 1);
      }
    } else {
      if (ev.currentTarget.checked) {
        this.listVersionIdSelectedReportRemoved = [...this.listVersionIdSelectedReportRemoved, versionId];
      } else {
        const index = this.listVersionIdSelectedReportRemoved.indexOf(versionId);
        if (index > -1) {
          this.listVersionIdSelectedReportRemoved.splice(index, 1);
        }
      }
    }
    if (this.listVersionIdSelectedReportRemoved.length > 0) {
      $('#massChangeReportRemoved' + reportName).css('display', 'inline-block');
    } else {
      $('#massChangeReportRemoved' + reportName).css('display', 'none');
    }
    // console.log(this.listVersionIdSelectedReportRemoved);
  }
  showDetailMsUsed(event) {
    let icon = $(event.target);
    if (icon.attr('class') === 'fas fa-list-alt') {
      icon.removeClass('fas fa-list-alt').addClass('far fa-list-alt');
      icon.html('&nbsp;Hide Details');
      if (this.haveListDesigner) {
        $('#ListAllDesigner').css('display', 'block');
        $('#buttonModal').css('display', 'block');
      }
      if (this.haveListSelected) {
        $('#ListAllUser').css('display', 'block');
        $('#buttonModal').css('display', 'block');
      }
    } else if (icon.attr('class') === 'far fa-list-alt') {
      icon.removeClass('far fa-list-alt').addClass('fas fa-list-alt');
      icon.html('&nbsp;Show Details');
      $('#ListAllUser').css('display', 'none');
      $('#ListAllDesigner').css('display', 'none');
      $('#buttonModal').css('display', 'none');
    }
  }
  onTestSelectedReportsIfUsed() {
    if (this.listVersionIdSelectedReportRemoved.length > 0) {
      var router = this.router;
      var reportService = this.reportService;

      let username = localStorage.getItem('username');
      let userId = localStorage.getItem('userId');

      let titleText = this.lang === 'en' ? 'Remove version(s)?' : 'Supprimer la(les) version(s) ?';
      let contentText = this.lang === 'en' ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !";
      let confirmButtonText = this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, Approuver !';
      let cancelText = this.lang === 'en' ? 'Cancel' : 'Annuler';

      Swal.fire({
        title: titleText,
        text: contentText,
        icon: 'error',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelText
      }).then((result) => {
        if (result.value) {
          addLoader(this.lang === 'en' ? 'PLZ&#160;&#160;WAIT . . .' : 'VEUILLEZ&#160;&#160;PATIENTEZ . . .');
          var dataReport = {
            username: username,
            userId: userId,
            list: this.listVersionIdSelectedReportRemoved,
          };
          $('#ListAllDesigner').empty();
          if ($('#listDesigner').length > 0) {
            $('#listDesigner').remove();
          }
          $('#ListAllUser').empty();
          if ($('#listSelectUser').length > 0) {
            $('#listSelectUser').remove();
          }
          let subscription17$ = reportService.testSelectedReportsIfUsed(dataReport).subscribe(
            data => {
              if ((data.listDesigner.length > 0) || (data.listSelected.length > 0)) {
                this.removed = false;
                const that = this;
                if (data.listDesigner.length > 0) {
                  this.haveListDesigner = true;
                  $('#ListAllDesigner').append('<table id="listDesigner" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
                    '<tr>' +
                    '<th>Data Dictionary</th>' +
                    '<th>Designers</th>' +
                    '</tr>' +
                    '</thead></table>');

                  $('#listDesigner').DataTable({
                    processing: true,
                    destroy: true,
                    pageLength: 3,
                    rowCallback(row, data) {
                    },
                    initComplete(settings, json) {
                      const table = this.api();
                      data.listDesigner.map(item => {
                        table.row.add([
                          item.nameModelStructure,
                          item.nameDesigner,
                        ]).draw(false);
                      });
                    }
                  });
                }
                if (data.listSelected.length > 0) {
                  this.haveListSelected = true;
                  $('#ListAllUser').append('<table id="listSelectUser" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
                    '<tr>' +
                    '<th>Model Structures</th>' +
                    '<th>Selected By</th>' +
                    '</tr>' +
                    '</thead></table>');

                  $('#listSelectUser').DataTable({
                    processing: true,
                    destroy: true,
                    pageLength: 3,
                    rowCallback(row, data) {
                    },
                    initComplete(settings, json) {
                      const table = this.api();
                      data.listSelected.map(item => {
                        table.row.add([
                          item.nameReport,
                          item.nameUser
                        ]).draw(false);
                      });
                    }
                  });
                }
                removeLoader();
                $('#removeMsUser').modal('show');
              } else {
                this.haveListDesigner = false;
                this.haveListSelected = false;
                this.removed = true;
                removeLoader();
                this.OnRemoveMultipleByListIdsReport();
              }
            },
            err => {
              this.haveListDesigner = false;
              this.haveListSelected = false;
              removeLoader();
              console.error(err);
            },
            () => {
            }
          );
          this.subscription.add(subscription17$);
        }
      });
    } else {
      alert('No selected report');
    }
  }
  OnRemoveMultipleByListIdsReport() {
    if (this.listVersionIdSelectedReportRemoved.length > 0) {
      addLoader(this.lang === 'en' ? 'PLZ&#160;&#160;WAIT . . .' : 'VEUILLEZ&#160;&#160;PATIENTEZ . . .');
      let countSelectedV = this.listVersionIdSelectedReportRemoved.length;
      let dataReport = {
        list: this.listVersionIdSelectedReportRemoved,
      };
      let subscription19$ = this.reportService.removeMultipleReportDetailsByListIdsReport(dataReport).subscribe(
        data1 => {
          let subscription20$ = this.reportService.removeMultipleReportDetailsCountByListIdsReport(dataReport).subscribe(
            data2 => {
              let subscription21$ = this.reportService.removeMultipleReportObjectByListIdsReport(dataReport).subscribe(
                data3 => {
                  let subscription22$ = this.reportService.removeMultipleReportObjectFiltredByListIdsReport(dataReport).subscribe(
                    data4 => {
                      let subscription23$ = this.reportService.removeMultipleReportDetailFiltredByListIdsReport(dataReport).subscribe(
                        data5 => {
                          this.subscription.add(subscription19$);
                          let subscription18$ = this.reportService.removeMultipleByListIdsReport(dataReport).subscribe(
                            data => {
                              $('#chart1').empty();
                              $('#chart3').empty();
                              // reset list IDs and ICONs
                              this.approvedSelected = false;
                              this.archivedSelected = false;
                              this.deletedSelected = false;
                              this.removedSelected = false;

                              this.listVersionIdSelectedReportApproved.map(version => {
                                $('#checked_report_Approved_' + version).prop('checked', false);
                              });
                              this.listVersionIdSelectedReportApproved = [];

                              this.listVersionIdSelectedReportArchived.map(version => {
                                $('#checked_report_Archived_' + version).prop('checked', false);
                              });
                              this.listVersionIdSelectedReportArchived = [];

                              this.listVersionIdSelectedReportDeleted.map(version => {
                                $('#checked_report_Deleted_' + version).prop('checked', false);
                              });
                              this.listVersionIdSelectedReportDeleted = [];

                              this.listVersionIdSelectedReportRemoved.map(version => {
                                $('#checked_report_Removed_' + version).prop('checked', false);
                              });
                              this.listVersionIdSelectedReportRemoved = [];
                            },
                            err => {
                              this.haveListDesigner = false;
                              this.haveListSelected = false;
                              this.countVersionListApp -= countSelectedV;
                              if (this.countVersionListApp == 0) {
                                $('.tableStat').css('display', 'none');
                                this.reportsData = [];
                                this.showListApplication();
                              } else {
                                this.showListApplication();
                                setTimeout(() => {
                                  this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                                }, 1000);
                              }
                              this.reportCompo.onGetApplicationByReport('null');
                              // barchart
                              this.reportCompo.getStaticsChartBar().then(() => this.reportCompo.startScriptBar());
                              this.reportCompo.onGetStatisticApp().then(() => this.reportCompo.startScriptPie());
                              removeLoader();
                              console.error(err);
                            },
                            () => {
                              this.countVersionListApp -= countSelectedV;
                              if (this.countVersionListApp == 0) {
                                $('.tableStat').css('display', 'none');
                                this.reportsData = [];
                                this.showListApplication();
                              } else {
                                this.showListApplication();
                                setTimeout(() => {
                                  this.onGetReportByAppTypeDbEnv(this.app, this.type, this.env, this.indexBase, this.evenement, this.countVersionListApp);
                                }, 1000);
                              }
                              this.reportCompo.onGetApplicationByReport('null');
                              // barchart
                              this.reportCompo.getStaticsChartBar().then(() => this.reportCompo.startScriptBar());
                              this.reportCompo.onGetStatisticApp().then(() => this.reportCompo.startScriptPie());
                              $('#removeMsUser').modal('hide');
                              removeLoader();
                            }
                          );
                          this.subscription.add(subscription18$);
                        },
                        err => {
                        }
                      );
                      this.subscription.add(subscription23$);
                    },
                    err => {
                    }
                  );
                  this.subscription.add(subscription22$);
                },
                err => {
                }
              );
              this.subscription.add(subscription21$);
            },
            err => {
            }
          );
          this.subscription.add(subscription20$);
        },
        err => {
        }
      );
    } else {
      alert('No selected report');
    }
  }
  // mass remove

}
