import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ObjectService} from '../../services/objects/object.service';
import {Observable, Subject, Subscription} from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
import {getCookieValue, Shared} from '../../shared';
import {DrmClassifService} from '../../services/drm/drm-classif.service';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import * as echarts from 'echarts';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {TranslateService} from '@ngx-translate/core';
import {CryptoService} from '../../crypto.service';
import {RouterhistoryService} from '../../services/appService/routerhistory.service';

declare const $: any;
@Component({
  selector: 'app-classificationdictdrm',
  templateUrl: './classificationdictdrm.component.html',
  styleUrls: ['./classificationdictdrm.component.css']
})
export class ClassificationdictdrmComponent implements OnInit, OnDestroy, AfterViewInit {
  ///// new classification version ////////
  listCompliances = [];
  selectedCompliance = '';

  dataRadarNo = [];
  dataRadarMod = [];
  dataRadarHigh = [];
  dataRadarCrit = [];
  listIndicators = [];
  dataRadarLegend = [];
  listSeries = [];

  ///// end classification version ///////
  lang: string;
  subscription: Subscription = new Subscription();
  Assets = Shared.ASSETS;
  selectedReportID: string;
  selectedReportName = [];
  selectedModel = '';
  listDictionarys = [];
  selectedDictionaryIDAncien: string;

  // reset chart sunburst after filter
  showResetSun = false;
  // disable button show detail/back when sunburst is not loaded
  endLoadSunburst = false;
  // toggle
  flagMenuData = true;
  // datatable filter
  _opened = false;
  showListObjs = true;
  showOLC = true;
  showLOC = false;
  showLCO = false;
  // selectedModelChart = [];
  loadingApplication = true;
  flag = false;
  ids = [];
  hiderIndicators = true;
  listAppliForDic$: Observable<string[]>;
  appEmpty = true;
  selectedApp = '';

  // paginate object models
  asyncDictionarys: Observable<any[]>;
  p = 1;
  totalDictionarys = 0;
  loadingDictionarys: boolean;
  dictionaryconfig: any;
  order = 'id';
  reverse = false;
  sortedCollection: any[];
  selectedPageSize = 5;
  pageSizeList = [5, 10 , 15 , 20 , 25 , 30 ];
  dictionaryFilter: any;
  // end paginate object models

  // line chart count category
  listeCateg = [];
  listeCountCateg = [];
  listeCountSymbole = [];

  // Radar chart count category by usage risk
  dataLegend = [];
  radar = [];
  series = [];
  colorLoc = [];

  // chart Sunbirst
  dataChart = [];
  dataChartV2 = [];
  dataChartV3 = [];

  // menu data security risk
  dataSecurityRisk = true;
  // menu data usage risk
  dataMenuUsageRiskDrm = true;
  // popup usage risk
  popupUsageRisk = [];
  tableRecord = [];
  cat  = '';
  nameObject = '';
  usageRiskCategInPopup = 0;
  // pagination usage risk
  term: any;
  model: any;
  // recupération des objets par categorie et pagination
  pagesize = 6;
  classificationDicBridge = {system: '', dictionary: '', component: ''};

  // start new data usage risk
  dataUsageRiskNew = [];
  familyNo = [];
  familyMod = [];
  familyHigh = [];
  familyCrit = [];
  // menu data usage risk new
  dataMenuUsageRiskDrmNew = true;
  disabledButtonRisk = false;
  // end new data usage risk
  constructor(private dynamicScriptLoader: DynamicScriptLoaderService, private drmClassifService: DrmClassifService,
              private objectService: ObjectService, private authenticationService: AuthenticationService,
              private activeRoute: ActivatedRoute, private cryptoService: CryptoService, private routerHistoryService: RouterhistoryService,
              private orderPipe: OrderPipe, private router: Router, private translate: TranslateService) {
              this.dictionaryconfig = { id: 'server', itemsPerPage: this.selectedPageSize, currentPage: this.p, totalItems: this.totalDictionarys };
  }

  ngOnInit() {
    // get params in route
    let subscription0$ = this.activeRoute.paramMap.subscribe(params => {
      let system = params.get("system");
      let model = params.get("model");
      let component = params.get("component");
      // change ---------- to /
      system = system.replace(/__________/g, '/');
      model = model.replace(/__________/g, '/');
      component = component.replace(/__________/g, '/');
      // decrypt params
      component = this.cryptoService.get(component);
      system = this.cryptoService.get(system);
      model = this.cryptoService.get(model);
      // get params if only not empty
      if (system != null && system != '0') {
        this.classificationDicBridge.system = system;
      }
      if (model != null && model!= '0') {
        this.classificationDicBridge.dictionary = model;
      }
      if (component != null && component != '0') {
        this.classificationDicBridge.component = component;
      }
    });
    this.subscription.add(subscription0$);
    this.onGetApplForListDictionarys();
    $('#spinner_getListApprovedDictionay').attr('style', 'display:block!important');
    $('#getListApprovedDictionay').attr('style', 'display:none!important');
    $('#spinner_glb_classif').css('display', 'none');
    $('#glb_classif').attr('style', 'display: none!important');
    // this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
    setTimeout( () => {
      this.showTipsDrmClassif();
      // this.translate.get('MenuSideBarLeft.dashboard').subscribe((res:string) => {
      //   console.log(res);
      // });
    }, 1000);
  }
  showTipsDrmClassif() {
    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsDrmClassif');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      // console.log('first');
      // (localStorage.getItem('dataSoumenu') == 'drm-classification') && (localStorage.getItem('dataTips') == 'true') && (
      if ((localStorage.getItem('dontShowTipsDrmClassif') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsDrmClassif', 'true');
        $('#tipsCompDrmClassif').modal('show');
      }
    } else {
      setTimeout(() => {
        // console.log('second');
        if ((localStorage.getItem('dontShowTipsDrmClassif') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsDrmClassif', 'true');
          $('#tipsCompDrmClassif').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips dashboard
  onUpdateTipsDrmClassif() {
    let tipsDrmDef;
    if ($('#checked_show_drmclassif').is(':checked')) {
      tipsDrmDef = false;
    } else {
      tipsDrmDef = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsDrmDef,
      dataMenu: 'Data Regulation',
      dataSousmenu: 'drm-classification'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }

  // onClick(event) {
  //   $('#' + event).slideToggle(500);
  // }
  // get list applications (for onGetObjectModelsList)
  onGetApplForListDictionarys() {
    this.appEmpty = false;
    let subscription1$ = this.drmClassifService.getApplForListDictionays().subscribe(
      data => {
        if (data.length > 0) {
          this.appEmpty = true;
          this.listAppliForDic$ = data;
          if (this.classificationDicBridge.component == 'global-search') {
            this.selectedApp = this.classificationDicBridge.system;
          } else {
            this.selectedApp = data[0];
          }

        }
      },
      err => {
        console.error(err);
      },
      () => {
        if (this.appEmpty) {
          this.loadingApplication = false;
          this.onGetDictionarysListByApp();
          this.sortedCollection = this.orderPipe.transform(this.asyncDictionarys, 'id');
        } else {
          this.router.navigate(['drm/drm-no-classification']);
        }
      }
    );
    this.subscription.add(subscription1$);
  }
  // get list objectModel by status Approved/Archived in table collapse (tabs: Models) (by application)
  onGetDictionarysListByApp() {
    let subscription2$ = this.drmClassifService.getDictionaysList(this.selectedApp).subscribe(
      result => {
        this.listDictionarys = [];
        this.ids = [];
        for (const object of result.items) {
          this.listDictionarys.push(object);
          this.ids.push(object.id);
        }
        // this.selectedModelChart.push(res.items[0].name);
      },
      err => {
        console.error(err);
      },
      () => {
        $('#spinner_getListApprovedDictionay').hide();
        $('#getListApprovedDictionay').show('slow');

        $('#dictionary_pagin_search').attr('style', 'display: flex!important');
        this.dictionaryFilter = this.classificationDicBridge.dictionary;
        if (this.classificationDicBridge.component == 'global-search') {
          setTimeout(()  => {
            // $('#bt_' + this.classificationBridge.model).click();
            $( 'button[data-name=\'' +  this.classificationDicBridge.dictionary + '\']' )[0].click();
          });
        }
      }
    );
    this.subscription.add(subscription2$);
  }
  // get list Data dictionary by status Approved/Archived in table collapse (tabs: Dictionarys) {change report by effective date}
  onGetChangeDictionaryFromList(event, ancienId) {
    const select = $(event.target);
    const id = select.children('option:selected').val();
    const grandParent = select.parent().parent();
    let subscription3$ = this.drmClassifService.getChangeDictionaryFromList(id).subscribe(
      data => {
        grandParent.parent().animate({
          opacity: 0
        }, 500, 'linear', function() {
        });
        grandParent.children().eq(0).html(data.reportName);
        grandParent.children().eq(2).html(data.database);
        grandParent.children().eq(3).html(data.typeDatabase);
        grandParent.children().eq(4).html(data.environment);
        if (data.status === 'Archived') {
          grandParent.children().eq(0).css('color', 'red');
          grandParent.children().eq(1).css('color', 'red');
          grandParent.children().eq(2).css('color', 'red');
          grandParent.children().eq(3).css('color', 'red');
          grandParent.children().eq(4).css('color', 'red');
        } else {
          grandParent.children().eq(0).css('color', 'black');
          grandParent.children().eq(1).css('color', 'black');
          grandParent.children().eq(2).css('color', 'black');
          grandParent.children().eq(3).css('color', 'black');
          grandParent.children().eq(4).css('color', 'black');
        }
        grandParent.attr('id', data.id);
        grandParent.next().attr('id', 'body' + data.id);
        grandParent.parent().animate({
          opacity: 1
        }, 1000, 'linear', function() {
        });
        const indexModel = this.listDictionarys.findIndex(item => item.id === ancienId);
        this.listDictionarys[indexModel] = data;
      },
      err => {
        console.error(err);
      },
      () => {
      }
    );
    this.subscription.add(subscription3$);
  }
  // show informations from selected model (show Detail/Back)
  // hide show collapcible body
  showHideListMenuByObject(id) {
    $('#listMenuByObject' + id).toggle();
    if ($('#i_' + id ).hasClass('fa-angle-double-up')) {
      $('#i_' + id ).removeClass('fa-angle-double-up');
      $('#i_' + id ).toggleClass('fa-angle-double-down');
    } else {
      $('#i_' + id ).removeClass('fa-angle-double-down');
      $('#i_' + id ).toggleClass('fa-angle-double-up');
    }
  }
  // show / hide chart in menu Indicators
  showOrHideChartInIndicators(id) {
    if (this.hiderIndicators) {
      $('#show_indicators' + id).show();
      $('#arrow_indicators' + id).html('<i class="fas fa-angle-up"></i>');
      this.hiderIndicators = false;
      $('#show_indicators' + id).animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        // Animation complete.
      });
    } else {
      this.hiderIndicators = true;
      $('#arrow_indicators' + id).html('<i class="fas fa-angle-down"></i>');
      $('#show_indicators' + id).animate({
        height: '0'
      }, 1, 'swing', function() {
        $('#show_indicators' + id).hide();
      });
    }
  }
  // sort object model
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  // comparator sort
  sortAlphaNum(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  getPage() {
    this.dictionaryconfig.itemsPerPage = this.selectedPageSize;
    this.dictionaryconfig.currentPage = 1;
    this.dictionaryconfig.totalItems = this.listDictionarys.length;
  }
  pageModelChanged(event) {
    this.dictionaryconfig.currentPage = event;
  }
  showDetailModel(event) {
    const oid = $(event.target).parent().parent().attr('id');
    this.selectedReportID = oid;
    // this.selectedReportName = [];
    // this.selectedReportName.push($('#' + oid).children().html());
    // this.selectedModel = this.selectedReportName[0];
    const ul = $('#' + oid).parent().parent();
    const li = $('#' + oid).parent();
    if (!this.flag) {
      this.flag = true;
      $('#body' + oid).slideDown();
      $('#desc' + oid).hide();
      // $('#drm_data_usage_' + objectId).hide();
      // fin usage risk
      $('#dictionary_pagin_search').attr('style', 'display: none!important');
      $('#apps-search').hide('slow');
      ul.children().hide();
      $('#titleObject').show('slow');
      li.show('slow');
      $('#arrow_indicators' + oid).click();
      // hide chart sunburst and show spinner
      $('#spinner_glb_classif').css('display', 'block');
      $('#glb_classif').attr('style', 'display: none!important');
      // close ng-sidebar if opened in when back
      this._opened = false;
      $('#affichListObj').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
      this.showListObjs = false;
      // end close ng-sidebar if opened in when back
      this. onGetListComplianceByDictionary(oid);


    } else {
      this.dictionaryFilter = null;
      // echart
      $('#containerSunburst').css({"-moz-transform": "scale(1.0)", "webkit-transform": "scale(1.0)"});
      $('#containerSunburst').css({'margin-top': '0', 'margin-left': '0'});
      $('#containerSunburstV2').css({"-moz-transform": "scale(1.0)", "webkit-transform": "scale(1.0)"});
      $('#containerSunburstV2').css({'margin-top': '0', 'margin-left': '0'});
      $('#containerSunburstV3').css({"-moz-transform": "scale(1.0)", "webkit-transform": "scale(1.0)"});
      $('#containerSunburstV3').css({'margin-top': '0', 'margin-left': '0'});
      // end echart
      $('#dictionary_pagin_search').attr('style', 'display: flex!important');
      $('#spinner_glb_classif').css('display', 'none');
      $('#glb_classif').attr('style', 'display: none!important');
      // end hide
      this.hiderIndicators = true;
      $('#arrow_indicators' + oid).html('<i class="fas fa-angle-down"></i>');
      $('#show_indicators' + oid).animate({
        height: '0'
      }, 1, 'swing', function() {
        $('#show_indicators' + oid).hide();
      });

      $('#apps-search').show('slow');
      // event.target.innerHTML = 'Show Details';
      this.flag = false;
      $('#body' + oid).slideUp();
      ul.children().show('slow');
    }
  }
  /////////////////////// new => Start get list compliances in selected Model /////////////////////////////////
  onGetListComplianceByDictionary(dictionaryId) {
    this.listCompliances = [];
    this.drmClassifService.getListComplianceByDictionary(dictionaryId).subscribe(
      result => {
        this.listCompliances = [...result];
      },
      err => {
        console.error(err);
        },
      () => {
        this.selectedCompliance = this.listCompliances[0];
        return new Promise((resolve, reject) => {
          /* inject dataset to chart after view init... */
          setTimeout(() => {
            this.startScriptEchart(dictionaryId, this.selectedCompliance);
          }, 1000);
          // span name sunburst chart
          $('#spanV3').attr('style', 'display: block!important');
          $('#spanV1').attr('style', 'display: none!important');
          $('#spanV2').attr('style', 'display: none!important');
          $('#menuSunburstV1').html('Levels→Objects→Categorys');
          $('#menuSunburstV2').html('Levels→Categorys→Objects');
          $('#menuSunburstV3').html('Objects→Levels→Categorys <i style="float: right; color: darkgreen" class="fas fa-check-circle"></i>');
        });
      }
    );
  }
  /////////////////////// new => Start get list compliances in selected Model /////////////////////////////////
  // toggle flesh opned not opened datatable filter
  _toggleSidebar() {
    this._opened = !this._opened;
    if (this.showListObjs) {
      $('#affichListObj').html('<i class="fas fa-arrow-circle-right fa-2x"></i>');
      this.showListObjs = false;
      $('#containerSunburst').css({"-moz-transform": "scale(1.0)", "webkit-transform": "scale(1.0)"});
      $('#containerSunburst').css({'margin-top': '0', 'margin-left': '0'});
      $('#containerSunburstV2').css({"-moz-transform": "scale(1.0)", "webkit-transform": "scale(1.0)"});
      $('#containerSunburstV2').css({'margin-top': '0', 'margin-left': '0'});
      $('#containerSunburstV3').css({"-moz-transform": "scale(1.0)", "webkit-transform": "scale(1.0)"});
      $('#containerSunburstV3').css({'margin-top': '0', 'margin-left': '0'});
    } else {
      $('#containerSunburst').css({"-moz-transform": "scale(0.7)", "webkit-transform": "scale(0.7)"});
      $('#containerSunburst').css({'margin-top': '-110px', 'margin-left': '-129px'});
      $('#containerSunburstV2').css({"-moz-transform": "scale(0.7)", "webkit-transform": "scale(0.7)"});
      $('#containerSunburstV2').css({'margin-top': '-110px', 'margin-left': '-129px'});
      $('#containerSunburstV3').css({"-moz-transform": "scale(0.7)", "webkit-transform": "scale(0.7)"});
      $('#containerSunburstV3').css({'margin-top': '-110px', 'margin-left': '-129px'});
      $('#affichListObj').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
      this.showListObjs = true;
    }
  }
  // list reportDetailName to sidebar left Sunburst data dictionary
  getObjectsSunburst(id, compliance) {
    const that = this;
    if ($('#objectsByModelSun').length > 0) {
      $('#objectsByModelSun').remove();
      $('#object_model_sun').empty();
    }
    $('#object_model_sun').append('<table id="objectsByModelSun" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>\n' +
      '<tr>' +
      '<th>Object id</th>' +
      '<th>Object name</th>' +
      '</tr>' +
      '</thead></table>');
    var table = $('#objectsByModelSun').DataTable({
      processing: true,
      destroy: true,
      keys: {
        columns: ':first-child'
      },
      columnDefs: [
        {visible: false, targets: [1]}
      ],
      rowCallback(row, data) {
        $('td:eq(0)', row).html('<a class="tabclick" onmouseover="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#000000\', \'text-decoration\': \'none\'});">' + data.objectName + '</a>');
      },
      ajax: {
        url: Shared.API_URL + '/api/drm-classification/getListReportDetailNameByReport/' + compliance + '/' + id,
        contentType: 'application/json',
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
// 'Accept': 'Application/json'
        },
      },
      columns: [
        {data: 'objectId', title: 'Name'},
        {data: 'objectName', title: 'Name'}
      ],
      initComplete(settings, json) {
        // show fleche en attendant la fin de la récupération des données du datatable
        $('#toggleTreeSize').show();
        $('.tabclick').click(function() {
          var nameObj = table.row($(this).parents('tr')).data().objectName;
          nameObj = btoa(nameObj);
          console.log(nameObj);
          that.onGetDataSunburstByDictionaryDrmOLC(nameObj, that.selectedReportID, that.selectedCompliance);
        });
      }
    });
    // $('svg').attr('width', '100%').attr('height', '100vh');
    // $('svg').parent().attr('width', '100%').attr('height', '100vh');
    $('.ng-sidebar').css({width: '400px', overflow: 'hidden'});
  }
  // toggle body
  menuDataClassif() {
    if (this.flagMenuData) {
      $('#menuClassif1').css('display', 'block');
      $('#menuContainer').animate({
        opacity: 1,
        height: 0// '20vh'
      }, 500, 'easeOutBounce', function() {
// Animation complete.
      });
      $('#menuClassif1').animate({
        opacity: 1
      }, 500, 'easeOutBounce', function() {
// Animation complete.
      });
      this.flagMenuData = false;
    } else {
      // $('#submenu-classif').css('display', 'none');
      // $('#submenu-security').css('display', 'none');
      // $('#submenu-mapping').css('display', 'none');
// Animation complete.
      $('#menu2').animate({
        opacity: 0
      }, 100, 'easeOutBounce', function() {
// Animation complete.
        $('#menuClassif1').animate({
          opacity: 0
        }, 100, 'easeOutBounce', function() {
// Animation complete.
          $('#menuContainer').animate({
            opacity: 0,
            height: 0
          }, 100, 'easeOutBounce', function() {
// Animation complete.
          });
        });
      });
      // $('#packMenu').html('Object Model Size');
      // $('#treeMenu').html('Object Model Tree');
      $('#menuClassif1').css('display', 'none');
      this.flagMenuData = true;
    }
    $('.menu-item').removeClass('mn-active');
  }
  showSunburstV3() {
    $('#spanV3').attr('style', 'display: block!important');
    $('#spanV1').attr('style', 'display: none!important');
    $('#spanV2').attr('style', 'display: none!important');
    $('#menuSunburstV1').html('Levels→Objects→Categorys');
    $('#menuSunburstV2').html('Levels→Categorys→Objects');
    $('#menuSunburstV3').html('Objects→Levels→Categorys <i style="float: right; color: darkgreen" class="fas fa-check-circle"></i>');

    this.showOLC = true;
    this.showLOC = false;
    this.showLCO = false;

    $('#containerSunburst').css('display', 'none');
    $('#containerSunburstV2').css('display', 'none');
    $('#containerSunburstV3').css('display', 'block');
  }
  showSunburstV1() {
    $('#spanV3').attr('style', 'display: none!important');
    $('#spanV1').attr('style', 'display: block!important');
    $('#spanV2').attr('style', 'display: none!important');
    $('#menuSunburstV3').html('Objects→Levels→Categorys');
    $('#menuSunburstV2').html('Levels→Categorys→Objects');
    $('#menuSunburstV1').html('Levels→Objects→Categorys <i style="float: right; color: darkgreen" class="fas fa-check-circle"></i>');

    this.showOLC = false;
    this.showLOC = true;
    this.showLCO = false;

    $('#containerSunburst').css('display', 'block');
    $('#containerSunburstV2').css('display', 'none');
    $('#containerSunburstV3').css('display', 'none');
  }
  showSunburstV2() {
    $('#spanV3').attr('style', 'display: none!important');
    $('#spanV1').attr('style', 'display: none!important');
    $('#spanV2').attr('style', 'display: block!important');
    $('#menuSunburstV3').html('Objects→Levels→Categorys');
    $('#menuSunburstV1').html('Levels→Objects→Categorys');
    $('#menuSunburstV2').html('Levels→Categorys→Objects <i style="float: right; color: darkgreen" class="fas fa-check-circle"></i>');

    this.showOLC = false;
    this.showLOC = false;
    this.showLCO = true;

    $('#containerSunburst').css('display', 'none');
    $('#containerSunburstV2').css('display', 'block');
    $('#containerSunburstV3').css('display', 'none');
  }
  // popup ou modal par objet
  onGetPopUsageRisk(id, cat) {
    this.popupUsageRisk = [];
    this.tableRecord = [];
    this.cat  = '';
    this.nameObject = '';
    $('#spinnerPopUsageDrm').show('slow');
    $('#bodyPopUsageDrm').hide('slow');
    $('#DrmDataUsageRiskPopUp').modal();
    let subscription5$ = this.objectService.getPopUsageRisk(id, cat).subscribe(
      data => {
        this.popupUsageRisk = data;
        this.cat  = data.cat;
        this.nameObject = data.nameObject;
        this.tableRecord = data.tableRecord;
        this.usageRiskCategInPopup = data.usageRisk;
      },
      err => {
        console.error(err);
      },
      () => {
        $('#spinnerPopUsageDrm').hide('slow');
        $('#bodyPopUsageDrm').show('slow');
      }
    );
    this.subscription.add(subscription5$);
  }
  // execute asynchrono-chart (PictorialBar, Radar multi-chart, Sunbirst)
  async startScriptEchart(id, compliance) {
    await this.dynamicScriptLoader.load('echart').then(data => {
      $('#containerLineChart' + id).attr('style', 'display: none!important');
      $('#spinnerLineChart' + id).attr('style', 'display: block!important');
      this.onGetDataToLineChartDictionary(id, compliance);
      // $('#containerRadarChartNo' + id).attr('style', 'display: none!important');
      // $('#containerRadarChartMod' + id).attr('style', 'display: none!important');
      // $('#containerRadarChartHigh' + id).attr('style', 'display: none!important');
      // $('#containerRadarChartCrit' + id).attr('style', 'display: none!important');
      // $('#spinnerRadarChart' + id).attr('style', 'display: block!important');
      // $('#firstRowRadar' + id).css('opacity', 0);
      // $('#secondRowRadar' + id).css('opacity', 0);
      this.onGetCategToRadarByComplAndDictDrm(id, compliance);
      this.onGetDataSunburstByDictionaryDrmOLC('all', id, compliance);
    }).catch(error => console.error(error));
  }

  /// change selected compliance in data dictionary
  onChangeSelectedCompliance(idReport) {
    let that = this;
    if (this.hiderIndicators) {
      $('#show_indicators' + idReport).show();
      $('#arrow_indicators' + idReport).html('<i class="fas fa-angle-up"></i>');
      this.hiderIndicators = false;
      $('#show_indicators' + idReport).animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        that.startScriptEchart(idReport, that.selectedCompliance);
        // Animation complete.
      });
    } else {
      that.startScriptEchart(idReport, that.selectedCompliance);
    }
    if (this.dataMenuUsageRiskDrmNew == false) {
      this.onGetDataUsageRiskByComplianceAndDictionary(idReport, this.selectedCompliance);
    }
  }

  // chart 1: PictorialBar
  onGetDataToLineChartDictionary(id, compliance) {
    this.listeCateg = [];
    this.listeCountCateg = [];
    this.listeCountSymbole = [];
    let subscription6$ = this.drmClassifService.getDataToLineChartDictionary(id, compliance).subscribe(
      result => {
        result.map(item => { this.listeCateg.push(item.label); this.listeCountCateg.push(item.value); });
        // result.map(item => );
        result.map(item => this.listeCountSymbole.push(item));
      },
      err => {
        console.error(err);
      },
      () => {
        $('#spinnerLineChart' + id).attr('style', 'display: none!important');
        $('#containerLineChart' + id).attr('style', 'display: block!important');
        var chart = document.getElementById('containerLineChart' + id);
        const myChart = echarts.init(chart, null, {renderer: 'svg'});
        var app = {};
        var option = null;
        option = {
          legend: {
            // Try 'horizontal'
            orient: 'vertical',
            right: 10,
            top: 'center'
          },
          responsive: false,
          backgroundColor: 'white',
          tooltip: {
            trigger: 'axis',
            axisPointer: {
              type: 'none'
            },
            formatter: function (params) {
              return params[0].name + ': ' + params[0].value;
            }
          },
          xAxis: {
            data: this.listeCateg,
            axisTick: {show: false},
            axisLine: {show: false},
            axisLabel: {
              show: false,
              color: '#e54035'
            }
          },
          yAxis: {
            splitLine: {show: false},
            axisTick: {show: false},
            axisLine: {show: true},
            axisLabel: {show: false}
          },
          color: ['#d8d6d8'],
          series: [{
            color: '#e54035',
            name: 'Line',
            type: 'pictorialBar',
            barCategoryGap: '-130%',
            // symbol: 'path://M0,10 L10,10 L5,0 L0,10 z',
            symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
            itemStyle: {
              opacity: 0.5
            },
            emphasis: {
              itemStyle: {
                opacity: 1
              }
            },
            data: this.listeCountCateg,
            z: 10
          }, {
            name: 'icons',
            type: 'pictorialBar',
            barGap: '-100%',
            symbolPosition: 'end',
            symbolSize: 50,
            symbolOffset: [0, '-170%'],
            data: this.listeCountSymbole,
          }]
        };
        if (option && typeof option === 'object') {
          myChart.setOption(option, true);
        }
        $('#containerLineChart' + id).children().css({ border: '1px solid #f1f2f7', 'border-radius': '13px' });
        $('#containerLineChart' + id).children().eq(1).css({height: '0', border: '0px'});
        $('#containerLineChart' + id).css('margin-bottom', '18px');
      }
    );
    this.subscription.add(subscription6$);
  }
  // chart 2: Radar multi-chart
  onGetCategToRadarByComplAndDictDrm(idReport, compliance) {
    var countExist = 0;
    let subscription7$ = this.drmClassifService.getCategToRadarByComplAndDictDrm(idReport, compliance).subscribe(
      result => {
        // console.log(result);
        this.listIndicators = result.indicators;
        this.listSeries = result.series;
        this.dataRadarLegend = result.legend;
      },
      err => {
        console.error(err);
      },
      () => {
        var chart4 = document.getElementById('containerRadarChart' + idReport);
        const myChart4 = echarts.init(chart4, null, {renderer: 'svg'});
        var app4 = {};

        var option4 = null;
        option4 = {
          title: {
            text: null
          },
          tooltip: {
            trigger: 'axis'
          },
          legend: {
            data: this.dataRadarLegend
          },
          radar: {
            // shape: 'circle',
            center: ['50%', '70%'],
            radius: 80,
            indicator: this.listIndicators
          },
          // color: '#830C00',
          series: [
            {
              type: 'radar',
              tooltip: {
                trigger: 'item'
              },
              areaStyle: {},
              data: this.listSeries
            }
          ]
        };
        if (option4 && typeof option4 === 'object') {
          myChart4.setOption(option4, true);
        }
        $('#containerRadarChart' + idReport).children().css({height: '260', border: '1px solid #f1f2f7', 'border-radius': '13px' });
        $('#containerRadarChart' + idReport).children().eq(1).css({height: '0', border: '0px'});
        $('#containerRadarChart' + idReport).children().children().css('height', '260');
        $('#spinnerRadarChart' + idReport).attr('style', 'display: none!important');
        // $('#firstRowRadar' + idReport).css('opacity', 1);
        // $('#secondRowRadar' + idReport).css('opacity', 1);
      }
    );
    this.subscription.add(subscription7$);
  }
  // Default level(dictionaryClassif:family) -> object (reportdetail) -> category (dictionaryClassif:category)
  onGetDataSunburstByDictionaryDrmOLC(reportDetailName, idReport, compliance) {
    if (reportDetailName === 'all') {
      $('#spinner_resetSunburstChart').hide();
      // disable button back
      this.endLoadSunburst = true;
      // hide fleche en attendant la fin de la récupération des données du datatable
      $('#toggleTreeSize').hide();
      $('#resetSunburstChart').hide();
    } else {
      $('#containerSunburst').css('display', 'block');
      $('#containerSunburstV2').css('display', 'block');
      $('#containerSunburstV3').css('display', 'block');
      if (this.showOLC && !this.showLOC && !this.showLCO) {
        $('#containerSunburstV3').css('opacity', 1);
        $('#containerSunburst').css('opacity', 0);
        $('#containerSunburstV2').css('opacity', 0);
      } else if (!this.showOLC && this.showLOC && !this.showLCO) {
        // console.log(this.showOLC, this.showLOC, this.showLCO);
        $('#containerSunburstV3').css('opacity', 0);
        $('#containerSunburst').css('opacity', 1);
        $('#containerSunburstV2').css('opacity', 0);
      } else if (!this.showOLC && !this.showLOC && this.showLCO) {
        $('#containerSunburstV3').css('opacity', 0);
        $('#containerSunburst').css('opacity', 0);
        $('#containerSunburstV2').css('opacity', 1);
      }
      $('#spinner_resetSunburstChart').show();
      $('#resetSunburstChart').hide();
    }
    this.dataChartV3 = [];
    let subscription8$ = this.drmClassifService.getDataSunburstByDictionaryDrmOLC(reportDetailName, idReport, compliance).subscribe(
      data => {
        this.dataChartV3 = data;
        $('#spinner_glb_classif').css('display', 'none');
        $('#glb_classif').attr('style', 'display: block!important; max-width: 97%;');
      },
      (err) => {
        console.error(err);
      },
      () => {
        var chart = document.getElementById('containerSunburstV3');
        const myChart = echarts.init(chart, null, {renderer: 'svg'});
        var app = {};
        var option = null;
        option = {
          title: {
            // text: 'SUNBURST CHART',
            // subtext: 'Objects→Levels→Categorys',
            textStyle: {
              fontSize: 14,
              align: 'center'
            },
            subtextStyle: {
              align: 'center'
            },
          },
          series: {
            itemStyle: {
              // color: 'blue',
              fontSize: 5
            },
            type: 'sunburst',
            highlightPolicy: 'ancestor',
            data: this.dataChartV3,
            radius: [0, '100%'],
            sort: null,
            levels: [{}, {
              r0: '5%',
              r: '40%',
              // itemStyle: {
              //   borderWidth: 2
              // },
              label: {
                fontSize: 8,
                align: 'right'
                // rotate: 'tangential'
              }
            }, {
              r0: '40%',
              r: '75%',
              label: {
                color: '#03135b',
                fontWeight: 'bold',
                fontSize: 9,
                align: 'right'
              }
            }, {
              r0: '75%',
              r: '78%',
              label: {
                color: '#000000',
                fontWeight: 'bold',
                fontSize: 10,
                align: 'right',
                // fontSize: 8,
                position: 'outside',
                padding: 1,
                silent: false
              },
              itemStyle: {
                borderWidth: 2
              }
            }]
          },
          tooltip: {}
        };
        if (option && typeof option === 'object') {
          myChart.setOption(option, true);
        }
        if (reportDetailName !== 'all') {
          if (this.showOLC && !this.showLOC && !this.showLCO) {
            $('#containerSunburstV3').css('display', 'block');
          } else if (!this.showOLC && this.showLOC && !this.showLCO) {
            // console.log(this.showOLC, this.showLOC, this.showLCO);
            $('#containerSunburstV3').css('display', 'none');
          } else if (!this.showOLC && !this.showLOC && this.showLCO) {
            $('#containerSunburstV3').css('display', 'none');
          }
        }
        this.onGetDataSunburstByDictionaryDrmLOC(reportDetailName, idReport, compliance);
      }
    );
    this.subscription.add(subscription8$);
  }
  // chart Sunburst in body (circle) Level (family) -> Object (reportDetail) -> Catepgory
  onGetDataSunburstByDictionaryDrmLOC(reportDetailName, idReport, compliance) {
    this.dataChart = [];
    let subscription9$ = this.drmClassifService.getDataSunburstByDictionaryDrmLOC(reportDetailName, idReport, compliance).subscribe(
      data => {
        this.dataChart = data;
      },
      (err) => {
        console.error(err);
      },
      () => {
        var chart = document.getElementById('containerSunburst');
        const myChart = echarts.init(chart, null, {renderer: 'svg'});
        var app = {};
        var option = null;
        option = {
          title: {
            // text: 'SUNBURST CHART',
            // subtext: 'Levels→Objects→Categorys',
            textStyle: {
              fontSize: 14,
              align: 'center'
            },
            subtextStyle: {
              align: 'center'
            },
          },
          series: {
            itemStyle: {
              // color: 'blue',
              fontSize: 5
            },
            type: 'sunburst',
            highlightPolicy: 'ancestor',
            data: this.dataChart,
            radius: [0, '100%'],
            sort: null,
            levels: [{}, {
              r0: '5%',
              r: '40%',
              itemStyle: {
                borderWidth: 2
              },
              label: {
                fontSize: 8,
                align: 'right',
                // rotate: 'tangential'
              }
            }, {
              r0: '40%',
              r: '75%',
              label: {
                color: '#03135b',
                fontWeight: 'bold',
                fontSize: 9,
                align: 'right'
              }
            }, {
              r0: '75%',
              r: '78%',
              label: {
                color: '#000000',
                fontSize: 10,
                position: 'outside',
                padding: 1,
                silent: false
              },
              itemStyle: {
                borderWidth: 2
              }
            }]
          },
          tooltip: {
          }
        };
        if (option && typeof option === 'object') {
          myChart.setOption(option, true);
        }
        // myChart.on('click', function (params) {
        //   alert(params.name);
        // });
        if (reportDetailName !== 'all') {
          if (this.showOLC && !this.showLOC && !this.showLCO) {
            $('#containerSunburst').css('display', 'none');
          } else if (!this.showOLC && this.showLOC && !this.showLCO) {
            $('#containerSunburst').css('display', 'block');
          } else if (!this.showOLC && !this.showLOC && this.showLCO) {
            $('#containerSunburst').css('display', 'none');
          }
        }
        this.onGetDataSunburstByDictionaryDrmLCO(reportDetailName, idReport, compliance);
      }
    );
    this.subscription.add(subscription9$);
  }

  onGetDataSunburstByDictionaryDrmLCO(reportDetailName, idReport, compliance) {
    this.dataChartV2 = [];
    let subscription10$ = this.drmClassifService.getDataSunburstByDictionaryDrmLCO(reportDetailName, idReport, compliance).subscribe(
      data => {
        this.dataChartV2 = data;
      },
      (err) => {
        console.error(err);
      },
      () => {
        var chart = document.getElementById('containerSunburstV2');
        const myChart = echarts.init(chart, null, {renderer: 'svg'});
        var app = {};
        var option = null;
        option = {
          title: {
            // text: 'SUNBURST CHART',
            // subtext: 'Levels→Categorys→Objects',
            textStyle: {
              fontSize: 14,
              align: 'center'
            },
            subtextStyle: {
              align: 'center'
            },
          },
          series: {
            itemStyle: {
              // color: 'blue',
              fontSize: 5
            },
            type: 'sunburst',
            highlightPolicy: 'ancestor',
            data: this.dataChartV2,
            radius: [0, '100%'],
            sort: null,
            levels: [{}, {
              r0: '5%',
              r: '30%',
              itemStyle: {
                borderWidth: 2
              },
              label: {
                fontSize: 8,
                align: 'right'
                // rotate: 'tangential'
              }
            }, {
              r0: '30%',
              r: '75%',
              label: {
                color: '#03135b',
                fontWeight: 'bold',
                fontSize: 9,
                align: 'right'
              }
            }, {
              r0: '75%',
              r: '78%',
              label: {
                color: '#000000',
                fontSize: 10,
                position: 'outside',
                padding: 1,
                silent: false
              },
              itemStyle: {
                borderWidth: 2
              }
            }]
          },
          tooltip: {}
        };
        if (option && typeof option === 'object') {
          myChart.setOption(option, true);
        }
        $('#containerSunburst').css('opacity', 1);
        $('#containerSunburstV2').css('opacity', 1);
        $('#containerSunburstV3').css('opacity', 1);
        if (this.showOLC && !this.showLOC && !this.showLCO) {
          $('#containerSunburst').css('display', 'none');
          $('#containerSunburstV2').css('display', 'none');
          $('#containerSunburstV3').css('display', 'block');
        } else if (!this.showOLC && this.showLOC && !this.showLCO) {
          $('#containerSunburst').css('display', 'block');
          $('#containerSunburstV2').css('display', 'none');
          $('#containerSunburstV3').css('display', 'none');
        } else if (!this.showOLC && !this.showLOC && this.showLCO) {
          $('#containerSunburst').css('display', 'none');
          $('#containerSunburstV2').css('display', 'block');
          $('#containerSunburstV3').css('display', 'none');
        }
        if (reportDetailName === 'all') {
          // able button back
          this.endLoadSunburst = false;
          $('#spinner_resetSunburstChart').hide();
          $('#resetSunburstChart').hide();
          this.getObjectsSunburst(idReport, compliance);
        } else if (reportDetailName === 'nullReset') {
          this.endLoadSunburst = false;
          $('#spinner_resetSunburstChart').hide();
          $('#resetSunburstChart').hide();
        } else {
          $('#spinner_resetSunburstChart').hide();
          $('#resetSunburstChart').show();
        }
      }
    );
    this.subscription.add(subscription10$);
  }

  // shw detail data usage risk
  showDetailDataUsageRiskNew(idReport) {
    if (this.dataMenuUsageRiskDrmNew) {
      // this.getDataMappingList(id);
      this.dataMenuUsageRiskDrmNew = false;
      $('#drm_usage_arrowNew' + idReport).html('<i class="fas fa-angle-up"></i>');
      $('#drm_data_usage_new_' + idReport).show().animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        // Animation complete.
      });
      this.onGetDataUsageRiskByComplianceAndDictionary(idReport, this.selectedCompliance);
    } else {
      $('#spinnerDataUsageRisk').attr('style', 'display: none!important');
      this.dataMenuUsageRiskDrmNew = true;
      $('#drm_usage_arrowNew' + idReport).html('<i class="fas fa-angle-down"></i>');
      $('#drm_data_usage_new_' + idReport).hide().animate({
        height: 0
      }, 100, 'easeOutBounce', function() {
        // Animation complete.
      });
    }
  }
  // function new data usage risk
  onGetDataUsageRiskByComplianceAndDictionary(idReport, compliance) {
    this.disabledButtonRisk = true;
    $('#spinnerDataUsageRisk').attr('style', 'display: block!important');
    $('#drm_data_usage_new_' + idReport).css('display', 'none');
    this.dataUsageRiskNew = [];
    this.drmClassifService.getDataUsageRiskByComplianceAndDictionary(idReport, compliance).subscribe(
      data => {
        data.map(item => { this.dataUsageRiskNew.push(item); });
      },
      err => {
        console.error(err);
      },
      () => {
        $('#spinnerDataUsageRisk').attr('style', 'display: none!important');
        $('#drm_data_usage_new_' + idReport).css('display', 'block');
        this.disabledButtonRisk = false;
      }
    );
  }
}
