export class RoleJson {
  name: string;
  sup: boolean;
  versionAdmin: boolean;
  description: string;
  admin: any[];
  structure: any[];
  model: any[];
  appdefs: any[];
  appflow: any[];
  appDataFlow: any;
  adminModel: any[];
  infra: any;
  designer: any;
  diagramSoftware: any;
  createGlossary: boolean;
  constructor(name, description, sup, versionAdmin, admin, structure, model, appdefs, appflow, appDataFlow, adminModel, infra, designer, diagramSoftware, createGlossary) {
    this.name = name;
    this.description = description;
    this.sup = sup;
    this.versionAdmin = versionAdmin;
    this.admin = [...admin];
    this.structure = [...structure];
    this.model = [...model];
    this.appdefs = [...appdefs];
    this.appflow = [...appflow];
    this.appDataFlow = appDataFlow;
    this.adminModel = [...adminModel];
    this.infra = infra;
    this.designer = designer;
    this.diagramSoftware = diagramSoftware;
    this.createGlossary = createGlossary;
  }
}
