import {Component, OnInit, Input , Output ,EventEmitter ,OnChanges,OnDestroy, SimpleChange, SimpleChanges
} from '@angular/core';
import {Subscription} from 'rxjs';
import { DdtmService } from '../../services/ddtm/ddtm.service';
import * as d3 from 'd3';
import {Shared, getCookieValue } from '../../shared';
declare const $: any;
import * as Excel from 'exceljs';
import * as FileSaver from 'file-saver';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-viz',
  templateUrl: './viz.component.html',
  styleUrls: ['./viz.component.css']
})
export class VizComponent implements  OnInit , OnDestroy{
  @Input() isVizLaunched:boolean;
  @Input() isVizTableLaunched:boolean;
  @Input() isVizDone:boolean;
  @Input() objectsSize:boolean;
  @Input() objectId:boolean;
  @Output() isVizDoneChange = new EventEmitter<boolean>();
  @Input() isVizChecked:boolean;
  @Input() search:boolean;
  lang: string;
  constructor(private ddtmService: DdtmService,private translate: TranslateService) {}
  nodes = [];
  nodesData:any;
  _opened = false;
  showObjsTree = true;
  objects=[];
  tables_keys_count:any;
  csvType = 'text/csv;charset=utf-8;';
  tsvType = 'text/plain;charset=utf-8;';
  params: {};
  subscription: Subscription = new Subscription();
  ngOnInit() { 
    $('#selectedNode').html('');
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
  const that = this;
  setInterval(() => {
    this.lang = localStorage.getItem('lang') || 'en';
    this.translate.use(this.lang);
  }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
      if(this.isVizLaunched == true){
       
        $('#objectsByReport').DataTable().clear().destroy();
        let subscription1$ = this.ddtmService.getTop10ObjectJoins(this.objectId,localStorage.getItem('userId'),this.objectsSize,this.isVizChecked,this.search).subscribe(
            data => {
                this.nodesData = data;
                this.objects=[];
                var nodes = data.nodes;
                nodes.forEach(el => {
                  this.objects.push(el.name);
                });
                this.render(this.nodesData);
            },
            err => {
                console.error(err);
            },
            () =>{
              this.OnGetReportObjects(this.objectId,this.nodesData);
            }
        );
        this.subscription.add(subscription1$);
      } else if (this.isVizTableLaunched == true){
        $('#objectsByReport').DataTable().table.ajax.load();
      }
  }
  _toggleSidebar() {
    this._opened = !this._opened;
    if (this.showObjsTree) {
        $('#showObjsTree').html('<i class="fas fa-arrow-circle-right fa-2x"></i>');
        this.showObjsTree = false;
    } else {
        $('#showObjsTree').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
        this.showObjsTree = true;
    }
  }
  render(mockedData){

    const getcolor = function(d) {
      var c;
      switch(d){
        case 1:c='red';break;
        case 2:c='blue';break;
        case 3:c='green';break;
      }
      return c;
    };
    const width = 932; 
    const height = 932;
    const svg = d3.select("#svgViz").attr("viewBox", `0 0 ${width} ${height}`);
    this.clearView(svg); 
    const container = svg.append('g')
                        .attr('id', 'nodosContainer')
                        .style("font", "8px sans-serif")
                        .style("text-anchor", "middle");
    svg.attr('width', "100%");
    svg.attr('height', height);
    let { links, nodes } = mockedData;
    svg.call(
      d3.zoom()
        .scaleExtent([.1, 20])
        .on("zoom", function() { container.attr("transform", d3.event.transform); })
    );
    this.initDefinitions(container);
    this.initFilter(container);
    this.initGradian(container);
    const simulation = this.forceSimulation(d3, {width, height});

    const dragDiv = d3.drag()
          .subject(function() {
              return { x: parseInt(d3.select(this).style("left")),
                  y: parseInt(d3.select(this).style("top")) };
          })
          .on("drag", function() {
              d3.select(this)
                  .style("left", d3.event.x + "px")
                  .style("top", d3.event.y + "px");
          });

    d3.select(".d3-tip").call(dragDiv);

    var link = container.selectAll(".protip")
    .data(links.filter(function(d){return d.type == "nr"}))
    .enter()
    .append("line")
    .attr("class", "protip")
    .attr('stroke-width', '.5')
    .attr('id', (d) => 'link'+d.id)
    .attr('marker-end', (d) => 'url(#' + getcolor(d.color) + ')')
    .attr('stroke' , (d) => getcolor(d.color));

    const node = container.selectAll(".node")
      .data(nodes)
      .enter()
      .append("g")
      .attr("class", 'node')
      .style('display','block')
      .on("click", d => this.handleNodeClicked(d))
      .call(d3.drag()
        .on("start", function dragenstart(d){
          d3.event.sourceEvent.stopPropagation();
          if (!d3.event.active) simulation.alphaTarget(0.3).restart();
          d.fx = d.x;
          d.fy = d.y;

      })
        .on("drag", function dragged(d) {
          d.fx = d3.event.x;
          d.fy = d3.event.y;

        })
        .on("end", function dragended(d){
            if (!d3.event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;

        })
      );

    node.append('rect'); 
    node.append("text").text((d) => d.name ).attr("class", "node-text");
    node.append("svg:circle")
    .attr("class", "node-glyph")
    .attr('r', 10)
    .attr('cx', 15)
    .attr('cy', -25)
    .attr('id' , (d) =>'node-glyph_'+ d.name)
    .attr('fill', '#bbdcf9');

    node.append("svg:text").text(d => d.linksC.length)
      .attr("class", "glyph-label")
      .attr('r', 5)
      .attr('x', 15)
      .attr('y', -23)
      .attr('id' , (d) =>'glyph-label_'+ d.name)
      .attr("text-anchor", "middle");

    const paddingLeftRight = 18; // adjust the padding values depending on font and font size
    const paddingTopBottom = 5;

    container.selectAll("rect")
      .attr("x", function(d) { return this.parentNode.childNodes[1].getBBox().x - paddingLeftRight; })
      .attr("y", function(d) { return this.parentNode.childNodes[1].getBBox().y - paddingTopBottom - 6;  })
      .attr("rx", 10)
      .attr("ry", 10)
      .attr("width", function(d) {  return (this.parentNode.childNodes[1].getBBox().width + paddingLeftRight)  ; })
      .attr("height", function(d) { return this.parentNode.childNodes[1].getBBox().height  + paddingTopBottom * 2; })
      .attr('fill', 'url(#grad1)')
      .attr('id' , (d) => d.name)
      .attr('filter','url(#shadow)')
      .style('text-align','middle');

    container.selectAll('text.node-text')
      .attr("x", function(d) { return this.parentNode.childNodes[0].getBBox().x - (this.parentNode.childNodes[0].getBBox().x + 10); })
        .attr('fill','#f8f9fa')
        .attr('id' , (d) =>'text_'+ d.name)
        .style('cursor', 'pointer')
        .attr("y", function(d) { return this.parentNode.childNodes[1].getBBox().y + 2;  });

    simulation
      .nodes(nodes)
      .on("tick", () => { 
          this.ticked(link, node) ; 
          })
      .on("end",() => {});
    simulation.force("link")
    .links(links);
    if (simulation.alpha < simulation.alphaMin) {
      this.isVizDone = true;
      this.isVizDoneChange.emit(this.isVizDone);
    }
  };
  clearView = (svg) => svg.selectAll("*").remove();
  ticked = (link, node) => {
      
    link
      .attr("x1", function (d) {return d.source.x;})
      .attr("y1", function (d) {return d.source.y;})
      .attr("x2", function (d) {return d.target.x;})
      .attr("y2", function (d) {return d.target.y;});

    node.attr("transform", function (d) {return "translate(" + d.x + ", " + d.y + ")";});

  };
  handleNodeClicked(d) {
    $('#nodeModal').modal();
    $('#formModal1').html(d.name);
    
    if(d.name=="NoKey"){
      d.label="";
    }
    var selectedRecords = ['null'];
    var selectedFields = ['null'];
    var idUser = localStorage.getItem('userId');
    $('#table_object_joins').DataTable().destroy();
    $('#table_object_joins').empty();
   
    $('#table_object_joins').DataTable({
      pagingType: 'full_numbers',
      responsive: true,
      lengthChange:false,
      colReorder: true,
      processing: true,
      ajax: {
        url: Shared.API_URL + `/api/report-detail/getObjectJoins/${this.objectId}/${idUser}/${this.isVizChecked}/${this.search}`,
        data: {object: d.label},
        dataSrc: "joinsOut",
        contentType: "application/json",
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      "columnDefs": [{
        "targets": '_all',
        "defaultContent": ""
      }],
      "columns": [
        { "data": "objectLeft", "title": this.lang ? 'Object linked' : 'Objet lié' ,"render": function (data, type, row) {
          function getObjectValue(val) {
            var ic;
            if ( val == "" ||  val == " "){
              ic = 'NoKey';
            } else {
              ic = val;
            }
            return ic;
          }
          return getObjectValue(data) ;
        }},
        { "data": "recordName" ,title:'Tables'},
        { "data": "type" ,title:'Type', "render": function (data, type, row) {
          function getIconeType(val) {
            var ic;
            switch (val) {
              case '2K': ic = '<i style=\'color: #0135ab\' class=\'fas fa-key\'></i>'; break;
              case '3F': ic = '<i style=\'color: #999\' class=\'fas fa-key\'></i>'; break;
              default: { 
                ic = '';
                break; 
             }
            }
            return ic;
          }
          return   getIconeType(data) ;
        }},
        { "data": "name" , "title": this.lang ? 'Fields' : 'Champs' },
      ],
      "pageLength": 10,
      "lengthMenu": [ 5,10,15,20, 25,30,35,40,45, 50, 75, 100 ],
      order: [[1, 'asc']],
    });
    $('#table_report_object_joinsin').DataTable().destroy();
    $('#table_report_object_joinsin').empty();
    $('#table_report_object_joinsin').DataTable({
      pagingType: 'full_numbers',
      responsive: true,
      lengthChange:false,
      colReorder: true,
      processing: true,
      ajax: {
        url: Shared.API_URL + `/api/report-detail/getObjectJoins/${this.objectId}/${idUser}/${this.isVizChecked}/${this.search}`,
        data: {object: d.label},
        dataSrc: "joinsIn",
        contentType: "application/json",
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      "columnDefs": [{
        "targets": '_all',
        "defaultContent": ""
      }],
      "columns": [
        { "data": "name" , "title": this.lang ? 'Object linked' : 'Objet lié' },     
        { "data": "recordName" ,title:'Tables'},
        { "data": "type" ,title:'Type', "render": function (data, type, row) {
          function getIconeType(val) {
            var ic;
            switch (val) {
              case '2K': ic = '<i style=\'color: #0135ab\' class=\'fas fa-key\'></i>'; break;
              case '3F': ic = '<i style=\'color: #999\' class=\'fas fa-key\'></i>'; break;
              default: { 
                ic = '';
                break; 
             }
            }
            return ic;
          }
          return   getIconeType(data) ;
        }},
        { "data": "name" , "title": this.lang ? 'Fields' : 'Champs' }
      ],
      "pageLength": 10,
      "lengthMenu": [ 5,10,15,20, 25,30,35,40,45, 50, 75, 100 ],
      order: [[1, 'asc']],
    });
    const that = this;
    $('#table_object_tables_fields').DataTable().destroy();
    $('#table_object_tables_fields').empty();
    var parametres_conbinaison = {
      id: this.objectId,
      idUser: localStorage.getItem('userId'),
      checked: this.isVizChecked,
      table: selectedRecords,
      field: selectedFields
    }
    $('#table_object_tables_fields').DataTable({
      pagingType: 'full_numbers',
      responsive: true,
      lengthChange:true,
      processing: true,
      "lengthMenu": [ [5,10, 25, 50, 100, -1], [5,10, 25, 50, 100, "All"] ],
      "pageLength": 5,
      order: [[1, 'desc']],
      "rowCallback": function (row, data) {
        $('td:eq(0)', row).html('<a class="datafils_with_key" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.key + '</a>');
        $('td:eq(1)',row).css('text-align', 'right');
      },
      ajax: {
        url: Shared.API_URL + `/api/report-detail/getReportDetailsKeysWordCombinaison`,
        data: {key: d.label , Parametres_conbinaison: parametres_conbinaison},
        dataSrc: "",
        contentType: "application/json",
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      "columns": [
        { "data": "key", "title": this.lang ? 'Keywords' : 'Mots clés', "width": "80%" },
        { "data": "count_table", "title": this.lang ? 'Count' : 'Nombre', "width": "20%" },
      ],
      "initComplete": function(settings, json) {
        // Add event listener for opening and closing details
        $('#table_object_tables_fields'+ ' tbody').on('click', 'a.datafils_with_key', function () {
          var tr = $(this).closest('tr');
          var row = $('#table_object_tables_fields').DataTable().row(tr);

          var rowData = row.data();
          $('.shown_combinaison_records').next('tr').hide();
          var indexRow = row.index();
          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('shown_combinaison_records');
          }
          else {
            selectedRecords = ['null'];
            tr.addClass('shown_combinaison_records');
            if( $('#table_object_keysword_records').length){
              $('#table_object_keysword_records').remove();
            }
            var parametres_conbinaison_tables = {
              id: that.objectId,
              idUser: localStorage.getItem('userId'),
              checked: that.isVizChecked,
              table: selectedRecords,
              field: selectedFields
            }
            // Open this row
            row.child('<h6 style="text-align: center;padding-top: 2%;">List of Tables</h6><table class="table_object_keysword_records" id ="table_object_keysword_records" cellpadding="2" cellspacing="0" border="0" ></table>').show();
            $('#table_object_keysword_records').DataTable({
              pagingType: 'full_numbers',
              responsive: true,
              lengthChange:true,
              processing: true,
              "lengthMenu": [ [5,10, 25, 50, 100, -1], [5,10, 25, 50, 100, "All"] ],
              "pageLength": 5,
              "rowCallback": function (row, data) {
                $('td:eq(0)', row).html('<a class="datafils_with_fields" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.recordName+ '</a>');
                $('td:eq(3)',row).css('text-align', 'right');
                $('td:eq(4)',row).css('text-align', 'right');
              },
              ajax: {
                url: Shared.API_URL + `/api/report-detail/getReportDetailsCombinaisonTables`,
                data: {key: row.data().key ,Parametres_conbinaison_tables: parametres_conbinaison_tables},
                dataSrc: "",
                contentType: "application/json",
                headers: {
                  Accept: 'Application/json',
                  Authorization: 'Bearer ' + getCookieValue('tokendrd')
                },
              },
              "columns": [
                { "data": "recordName", "title": this.lang ? 'Record Name' : 'Nom de l\'enregistrement' },
                { "data": "ownerId", "title": this.lang ? 'Owner' : 'Propriétaire' },
                { "data": "description", "title": this.lang ? 'Description' : 'Description' },
                { "data": "count", "title": this.lang ? 'Record Count' : 'Nombre d\'enregistrements', "width": "10%" },
                { "data": "countFields", "title": this.lang ? 'Count Fields' : 'Nombre de champs', "width": "10%" }
              
              ],
              "initComplete": function(settings, json) {
                // Add event listener for opening and closing details
                $('#table_object_keysword_records tbody').on('click', 'a.datafils_with_fields', function () {
                  var tr = $(this).closest('tr');
                  var row = $('#table_object_keysword_records').DataTable().row(tr);
  
                  var rowData = row.data();
                  $('.shown_records_fields').next('tr').hide();
                  var indexRow = row.index();
                  if (row.child.isShown()) {
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('shown_records_fields');
                  }
                  else {
                    selectedRecords = ['null'];
                    tr.addClass('shown_records_fields');
                    if( $('#table_object_keysword_fields').length){
                      $('#table_object_keysword_fields').remove();
                    }
                    // Open this row
                    row.child('<h6 style="text-align: center;padding-top: 2%;">List of Fields</h6><table class="table_object_keysword_fields" id ="table_object_keysword_fields" cellpadding="2" cellspacing="0" border="0" ></table>').show();
                    $('#table_object_keysword_fields').DataTable({
                      pagingType: 'full_numbers',
                      responsive: true,
                      lengthChange:false,
                      processing: true,
                      colReorder: true,
                      serverSide: true,
                      "pageLength": 10,
                      "lengthMenu": [10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                      ajax: (dataTablesParameters: any, callback) => {
                        var table = $('#table_object_keysword_fields').DataTable();
                        var info = table.page.info();
                        var sortInfo = table.order();
                        var sortColumn = $(table.column(sortInfo[0][0]).header()).html().replace(/\s/g, "");
                        var sortOrder = sortInfo[0][1];
                        var search = table.search();
                        if (search === ""){
                          search = null;
                        }
                        selectedRecords= [];
                        selectedRecords.push(row.data().recordName);
                        selectedFields = ['null'];
                        var parametres = {
                          id: that.objectId,
                          idUser: localStorage.getItem('userId'),
                          field: selectedFields,
                          table: selectedRecords,
                          currentpage: info.page,
                          search: search,
                          sortColumn : sortColumn,
                          sortOrder : sortOrder,
                          length: info.length,
                        }
                        let subscription2$ = that.ddtmService.getSelectedFields(parametres).subscribe(
                          reponse => {
                            var report_tables = [];
                            for (let table of reponse.data) {
                              report_tables.push(table);
                            }
                            callback({
                              recordsTotal: reponse.count,
                              recordsFiltered: reponse.count,
                              data: report_tables,
                            });
                          },
                          err => {
                            console.error(err);
                          }
                        );
                        that.subscription.add(subscription2$);
                      },
                      "columns": [
                        { "data": "recordName", "title": this.lang ? 'Record Name' : 'Nom de l\'enregistrement' },
                        { "data": "name", "title": this.lang ? 'Name' : 'Nom' },
                        { "data": "description", "title": this.lang ? 'Description' : 'Description' },
                        { "data": "dataType", "title": this.lang ? 'Datatype' : 'Type de données', "width": "10%" },
                        { "data": "type", "title": this.lang ? 'Type' : 'Type', "width": "10%", "render": function (data, type, row) {
                        function getIconeType(val) {
                          var ic;
                          switch (val) {
                            case '2K': ic = '<i style=\'color: #0135ab\' class=\'fas fa-key\'></i>'; break;
                            case '3F': ic = '<i style=\'color: #999\' class=\'fas fa-key\'></i>'; break;
                            default: { 
                              ic = '';
                              break; 
                           }
                          }
                          return ic;
                        }
                        return   getIconeType(data) ;
                      }},
                      ],
                      order: [[1, 'asc']]
                    });
                    /*********Add event listener for opening and closing records details*********/ 
                    $('#table_object_keysword_fields tbody').on('click', 'td.details-control', function () {
                      var tr = $(this).closest('tr');
                      var row = $('#table_object_keysword_fields').DataTable().row( tr );

                      if ( row.child.isShown() ) {
                          // This row is already open - close it
                          row.child.hide();
                          tr.removeClass('shown');
                      }
                      else {
                          // Open this row
                          row.child( this.format(row.data()) ).show();
                          tr.addClass('shown');
                      }
                    } );
                    /*********Add event listener for opening and closing records details*********/ 
                  }
                });
              }
            });
            /*********Add event listener for opening and closing records details*********/ 
            $('#table_object_keysword_records tbody').on('click', 'td.details-control', function () {
              var tr = $(this).closest('tr');
              var row = $('#table_object_keysword_records').DataTable().row( tr );

              if ( row.child.isShown() ) {
                  // This row is already open - close it
                  row.child.hide();
                  tr.removeClass('shown');
              }
              else {
                  // Open this row
                  row.child( this.format(row.data()) ).show();
                  tr.addClass('shown');
              }
            } );
            /*********Add event listener for opening and closing records details*********/ 
          }
        });
      }
    });
  }
  initDefinitions =(svg) => {
    //red arrow
    svg.append("svg:defs").selectAll("marker")
        .data(["red"])      // Different link/path types can be defined here
        .enter().append("svg:marker")    // This section adds in the arrows
        .attr("id", String)
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 70)
        .attr("refY", 0)
        .attr("markerWidth", 15)
        .attr("markerHeight", 15)
        .attr("orient", "auto")
        .attr("fill", "red")
        .append("svg:path")
        .attr("d", "M0,-5L10,0L0,5");
    //fin red arrow

    // mid marker
    svg.append("svg:defs").append("marker")
      .attr("id", "arrow")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 0)
      .attr("refY", 0)
      .attr("markerWidth", 4)
      .attr("markerHeight", 4)
      .attr("orient", "auto")
      .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5");
  };
  //fin blue arrow
  initFilter = (svg) => {
    svg.append("svg:defs").selectAll('filter')
        .data(['shadow'])
        .enter().append('svg:filter')
        .attr("id", String)
        .attr('y', -10)
        .attr('x', -10)
        .attr('height', 40)
        .attr('width', 150)
        .append('feOffset')
        .attr('in', 'SourceAlpha')
        .attr('dx', 3)
        .attr('dy', 3)
        .attr('result', 'offset2')
        .attr('stdDeviation', 4);
    svg.selectAll('filter')
        .append('feGaussianBlur')
        .attr('in', 'offset2')
        .attr('result', 'blur2')
        .attr('stdDeviation', 3);
    svg.selectAll('filter')
        .append('feMerge')
        .append('feMergeNode')
        .attr('in', 'blur2');
    svg.selectAll('filter')
        .select('feMerge')
        .append('feMergeNode')
        .attr('in', 'SourceGraphic');
  };
  // gradian color array
  initGradian = (svg) => {
    svg.append("svg:defs").selectAll('linearGradient')
        .data(['grad1'])
        .enter().append('svg:linearGradient')
        .attr("id", String)
        .attr('x1', '0%')
        .attr('x2', '0%')
        .attr('y1', '0%')
        .attr('y2', '100%')
        .attr('spreadMethod', 'pad')
        .append('stop')
        .attr('offset', '0%')
        .attr('stop-color', '#04cef3')
        .attr('stop-opacity', 1);
    svg.select('#grad1')
        .append('stop')
        .attr('offset', '100%')
        .attr('stop-color', '#045485')
        .attr('stop-opacity', 1);
  };
  forceSimulation = (d3, {width, height}) => d3.forceSimulation()
    .force("link",
      d3.forceLink()
        .id(function (d) {return d.id;})
        .distance(150)
        .strength(1))
    .force("charge", d3.forceManyBody().strength(-10000))
    .force("x", d3.forceX(width).strength(1))
    .force("y", d3.forceY(height).strength(1))
    .force('collision', d3.forceCollide())
    .force("center", d3.forceCenter(width / 2, height / 2));

 
   // list object to datatable sidebar left object model tree 
  OnGetReportObjects(id,nodesData) {
    if ($('#objectsByReport').length > 0) {
      $('#objectsByReport').remove();
    }
    $('#objectsByReport').DataTable().clear().destroy();
    var table = $('#objectsByReport').DataTable();
    $('#object_report').append('<table id="objectsByReport" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;width: 100%;">   <thead>\n' +
      '<tr>' +
      '<th></th>' +
      '<th>key</th>'+
      '<th>count_keysword</th>'+
      '<th>count_table</th>'+
      '<th></th>' +
      '</tr>' +
      '</thead></table>');
    $('#objectsByReport').DataTable({
          processing: true,
          responsive: true,
          lengthChange:true,
          destroy: true,
          "pagingType": "numbers",
          rowCallback(row, data) {
              $('td:eq(1)', row).html('<a class="tabclick" onmouseover="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#000000\', \'text-decoration\': \'none\'});" name="'+ data.key +'" id="td_'+ data.key +'"  >' + data.key + '</a>');

          },
          ajax: (dataTablesParameters: any, callback) => {
           let subscription3$ = this.ddtmService.getReportObjectTable(this.objectId,localStorage.getItem('userId'),this.objectsSize,this.isVizChecked,this.search).subscribe(
              reponse => {
                var report_tables = reponse.tables_keys_count;
                callback({
                  recordsTotal: reponse.count_keys,
                  recordsFiltered: reponse.count_keys,
                  data: report_tables,
                });
              },
              err => {
                console.error(err);
              }
            );
            this.subscription.add(subscription3$);
          },
          columns: [
                {
                  "targets": 0,
                  "data": null,
                  "searchable": false, 
                  "orderable": false, 
                  "visible": true,
                  'className': 'check',
                  "width": "10px",
                  'render': function (data, type, full, meta){
                    return '<input type="checkbox" name="'+ data.key +'"  id="check_'+ data.key +'" style="opacity: 1;pointer-events: all;" />  ';
                  }
                },
                { "data": "key", title:'Key'},
                { "data": "count_keysword", title:'Count Keywords', "type": "num",'render': $.fn.dataTable.render.number( ' ', '.', 0)
                },
                { "data": "count_table", title:'Count tables', "type": "num",'render': $.fn.dataTable.render.number( ' ', '.', 0)
                },
                {
                  "targets": 3,
                  "data": null,
                  "orderable": true, 
                  "visible": true,
                  "title": this.lang ? 'Count Object linked' : 'Nombre d\'objets liés', "type": "num",
                  'render': function (data, type, full, meta){
                    var count_links=0;
                    nodesData.nodes.forEach(el => {
                      if(data.key == el.name){
                        count_links=el.linksC.length;
                      }
                    });
                    return count_links;
                  }
                }
          ],
        select: {
            style:    'multi'
        },
          order: [[1, 'desc']]
      });
      $('#objectsByReport').on('click', '.tabclick', function(){
        var nameObj = $(this).attr("name");
        var id = $('#selectedNode').html();
        if(id!=""){
            $("[id ='" + id + "']").toggleClass('pulseNode');
            setTimeout(function(){ $("[id ='" + id + "']").removeClass('pulseNode'); $('#selectedNode').empty(); }, 10000);
        }
        $("[id ='" + nameObj + "']").toggleClass('pulseNode');
        setTimeout(function(){ $("[id ='" + nameObj + "']").removeClass('pulseNode'); $('#selectedNode').empty(); }, 10000);

        $('#selectedNode').html(nameObj);
        if(nameObj === undefined){
          document.getElementById('svgViz').dispatchEvent(new Event('click'));
        }else{
          document.getElementById(nameObj).dispatchEvent(new Event('click'));
        }
      });
      // remove node
      $('#objectsByReport').on('click', 'input[type="checkbox"]', function(){
        var id = $(this).attr("name");
        var nodeId;
        var nodeLinks=[];
        nodesData.nodes.forEach(el => {
          if(id == el.name){
            nodeId=el.id;
          }
        });
        nodesData.links.forEach(el=> {
          if(nodeId == el.source.id || nodeId == el.target.id){
            nodeLinks.push(el.id);
          }
        });
        if(this.checked){
          $("[id ='" +id+ "']").css("opacity", 0);
          $("[id ='text_" +id+ "']").css("opacity", 0);
          $("[id ='node-glyph_" +id+ "']").css("opacity", 0);
          $("[id ='glyph-label_" +id+ "']").css("opacity", 0);
          for (let i = 0; i < nodeLinks.length; i++) {
            $("[id ='link"+nodeLinks[i]+"']").css("opacity", 0);
        }
        }else{
          for (let i = 0; i < nodeLinks.length; i++) {
              $("[id ='link"+nodeLinks[i]+ "']").css("opacity", 1);
          }
          $("[id ='" +id+ "']").css("opacity", 1);
          $("[id ='text_" +id+ "']").css("opacity", 1);
          $("[id ='node-glyph_" +id+ "']").css("opacity", 1);
          $("[id ='glyph-label_" +id+ "']").css("opacity", 1);
        }
      });

  }
 
  }

     
