import {AfterViewInit, Component, OnInit} from '@angular/core';
import {UserMessageService} from '../../services/userMessage/user-message.service';
import {OrderPipe} from 'ngx-order-pipe';
import {Html5Entities} from 'html-entities';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import {RouterhistoryService} from '../../services/appService/routerhistory.service';
import {TipsService} from '../../services/tips/tips.service';
import {AuthenticationService} from '../../services/authentication.service';
import {CryptoService} from '../../crypto.service';
import {ActivatedRoute} from '@angular/router';
import {getCookieValue} from "../../shared";

declare const $: any;

@Component({
  selector: 'app-user-message',
  templateUrl: './user-message.component.html',
  styleUrls: ['./user-message.component.css']
})
export class UserMessageComponent implements OnInit, AfterViewInit {
  messagesInbox = [];
 // messagesSent = [];
  dataMailConfig: any;
  totalListData = 0;
  p = 1;
  sortedCollection: any[];
  order = 'id';
  reverse = false;
  selectedPageSize = 5;
  dataMailFilter = null;
  flag = false;
  pageSizeList = [5, 10 , 15 , 20 , 25 , 30 ];
  inBoxCount = 0;
  sentCount = 0;
  inBoxFlag = true;
  messageLoading = true;
  readMessage = false;
  messageData = {attachments: [],
    id: '',
    msg: '',
    recipient: '',
    sender: '',
    sentAt: '',
    status: '',
    subject: '',
    type: '',
  recipientId: ''
  };
  bulk = false;
  empty = true;
  ids = [];
  fullMessages: any[];
  messageBridge = {idMessage: '', component: ''};
  constructor(private userMessageService: UserMessageService, private orderPipe: OrderPipe, private cryptoService: CryptoService,
              private routerHistoryService: RouterhistoryService, private serviceTips: TipsService, private activeRoute: ActivatedRoute) {
    this.dataMailConfig = { id: 'serverMail', itemsPerPage: this.selectedPageSize, currentPage: this.p, totalItems: this.totalListData };
  }

  ngOnInit(): void {
    // get params in route
    this.activeRoute.paramMap.subscribe(params => {
      let cryptoIdMessage = params.get('id');
      let cryptoComponent = params.get('component');
      // change ---------- to /
      cryptoIdMessage = cryptoIdMessage.replace(/__________/g, '/');
      cryptoComponent = cryptoComponent.replace(/__________/g, '/');
      // decrypt params
      cryptoIdMessage = this.cryptoService.get(cryptoIdMessage);
      cryptoComponent = this.cryptoService.get(cryptoComponent);
      // get params if only not empty
      this.messageBridge.idMessage = cryptoIdMessage;
      this.messageBridge.component = cryptoComponent;
    });
    // end parameter in route
    this.getMessagesInbox();
  }
  ngAfterViewInit() {
    setTimeout( () => {
      this.showTipsMessage();
    }, 1000);
  }
  showTipsMessage() {

    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsMessage');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsMessage') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        this.serviceTips.getTipsPerName('Message').subscribe(
          result => {
            const resVersion = result[0].version;
            const indexLastVersion = resVersion.length - 1;
            const selectedVersion = resVersion[indexLastVersion];
            switch (result[0].typedesc) {
              case 'text': $('#descTipsMsg').html(selectedVersion.description); break;
              case 'image': $('#descTipsMsg').html(selectedVersion.image); break;
              case 'video': $('#descTipsMsg').html(selectedVersion.video); break;
            }
          },
          err => {
            console.error(err);
          },
          () => {
            $('#tipsCompMessage').modal('show');
          }
        );
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsMessage') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          this.serviceTips.getTipsPerName('Dashboard').subscribe(
            result => {
              const resVersion = result[0].version;
              const indexLastVersion = resVersion.length - 1;
              const selectedVersion = resVersion[indexLastVersion];
              switch (result[0].typedesc) {
                case 'text': $('#descTipsMsg').html(selectedVersion.description); break;
                case 'image': $('#descTipsMsg').html(selectedVersion.image); break;
                case 'video': $('#descTipsMsg').html(selectedVersion.video); break;
              }
            },
            err => {
              console.error(err);
            },
            () => {
              $('#tipsCompMessage').modal('show');
            }
          );
        }
      }, 1000);
    }
  }
  // set new etat tips Message
  onUpdateTipsMessage() {
    let tipsMessage;
    if ($('#checked_show_message').is(':checked')) {
      tipsMessage = false;
    } else {
      tipsMessage = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsMessage,
      dataMenu: 'user-message',
      dataSousmenu: 'Inbox'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        // console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  getMessagesInbox() {
    const that = this;
    this.ids = [];
    $('#allMessages_ck').prop('checked', false);
    this.readMessage = false;
    this.messageLoading = true;
    this.userMessageService.getMessages('recipient').subscribe(
      data => {
        this.messagesInbox = data.data;
        this.messagesInbox.map( message => {
          message.msg = Html5Entities.decode(atob(message.msg));
        });
        this.inBoxCount = data.inBoxCount;
        this.sentCount = data.sentCount;
        this.inBoxFlag = true;
        this.fullMessages = this.messagesInbox;
        this.messageLoading = false;
      }, error => {
        console.log(error);
      },
      () => {
        this.messageLoading = false;
        this.messagesInbox.length > 0 ? this.empty = false : this.empty = true;
        if (this.messageBridge.idMessage != null && this.messageBridge.idMessage != '0') {
          this.dataMailFilter = this.messageBridge.idMessage;
          setTimeout(() => {
            $('.max-texts').click();
          }, 300);
        }

        // $('#allMessages_ck').off('change').change(function () {
        //   //console.log('click');
        //   if (this.checked) {
        //     that.bulk = true;
        //     $('input[id^=\'ckms_\']').prop('checked', true)
        //      .each(function(index, element) {
        //        that.ids.push(this.id.split('ckms_')[1]);
        //     });
        //    // console.log(that.ids);
        //   } else {
        //     that.bulk = false;
        //     $('input[id^=\'ckms_\']').prop('checked', false);
        //     that.ids = [];
        //   //  console.log(that.ids);
        //   }
        // });
      }
    );
  }
  getMessagesSent() {
    $('#allMessages_ck').prop('checked', false);
    this.ids = [];
    this.readMessage = false;
    this.messageLoading = true;
    this.userMessageService.getMessages('sender').subscribe(
      data => {
        this.messagesInbox = data.data;
        this.messagesInbox.map( message => {
          message.msg = Html5Entities.decode(atob(message.msg));
        });
        this.inBoxCount = data.inBoxCount;
        this.sentCount = data.sentCount;
        this.fullMessages = this.messagesInbox;
        this.inBoxFlag = false;
        this.messageLoading = false;
        this.messagesInbox = [...this.messagesInbox];
      }, error => {
        console.log(error);
      },
      () => {
        this.messageLoading = false;
        this.messagesInbox.length > 0 ? this.empty = false : this.empty = true;
      }
    );
  }

  getPage() {
    this.dataMailConfig.itemsPerPage = this.selectedPageSize;
    this.dataMailConfig.currentPage = 1;
    this.dataMailConfig.totalItems = this.messagesInbox.length;
  }
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  sortAlphaNum(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  pageListDataChanged(event) {
    this.dataMailConfig.currentPage = event;
    setTimeout(() => {
      if (this.ids.length > 0) {
        this.ids.map(
          item => {
            $('#ckms_' + item).prop('checked', true);
          }
        );
      }
    }, 500);

  }

  readMail(id: string) {
    const message = this.messagesInbox.filter(item => item.id == id);
    this.readMessage = true;
    if (message.length > 0) {
       this.messageData = message[0];
       if (this.inBoxFlag) {
         this.userMessageService.updateMessage(id).subscribe(
           d => {
             this.changeStatus(id, 'read');
           },
           error => {
             console.error(error);
           }
         );
       }
    }
  }

  backToMessage() {
    this.readMessage = false;
  }
  niceBytes(a) {
    let b = 0, c = parseInt(a, 10) || 0;
    for (; 1024 <= c && ++b;) { c /= 1024; }
    return c.toFixed(10 > c && 0 < b ? 1 : 0) + ' ' + ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][b];
  }
  changeStatus( id, status ) {
    for (const i in this.messagesInbox) {
      if (this.messagesInbox[i].id == id) {
        this.messagesInbox[i].status = status;
        break; // Stop this loop, we found it!
      }
    }
  }

  deleteMessages() {
 // console.log(this.ids);
 const type = this.inBoxFlag ? 'recipient' : 'sender';
 let ids = '';
 this.ids.map(i => {
      ids += i + ',';
    });
  //  console.log(ids);
 ids = ids.substr(0, ids.lastIndexOf(','));
  //  console.log(ids);
 ids = 'data=' + ids;

 this.userMessageService.deleteMessage(ids, type).subscribe(
    d => {
      if (type == 'recipient') {
        this.getMessagesInbox();
      } else {
        this.getMessagesSent();
      }

    },
    error => {}
  );
  }

  deleteMessage(id: string) {
    const type = this.inBoxFlag ? 'recipient' : 'sender';
   // let ids = '';
  //  ids.push({'id': id});
    const ids = 'data=' + id;
    this.userMessageService.deleteMessage(ids, type).subscribe(
      d => {
        if (type == 'recipient') {
          this.getMessagesInbox();
        } else {
          this.getMessagesSent();
        }

      },
      error => {}
    );
  }

  filterMessages(type: string) {
   // this.dataMailFilter = type;
    if (type == '') {
      this.messagesInbox = this.fullMessages;
    } else {
      this.messagesInbox = this.fullMessages.filter(e => e.type == type);
    }
  }
  downloadAttach(fileUrl: string, fileName: string , type) {
      let blob;
      if (type == 'xml') {
        const decodedData = unescape(fileUrl.split('text/plain,')[1]);
       // console.log(decodedData);
        blob = new Blob([decodedData], {type: 'application/xml'});
      } else {
         blob = this.convertBase64ToBlob(fileUrl, type);
      }

      FileSaver.saveAs(blob, fileName);

  }
 convertBase64ToBlob(base64Image: string, type) {
    let parts = [];
    let imageType = 'text/plain';
   // Decode Base64 string
    let decodedData = '';
    // Split into two parts
    if (type == 'xml') {
     parts = base64Image.split('text/plain,');
     // Decode Base64 string
     decodedData = unescape(parts[1].split('text/plain,')[1]);
   } else {
     parts = base64Image.split(';base64,');
     // Hold the content type
     imageType = parts[0].split(':')[1];
     // Decode Base64 string
     decodedData = window.atob(parts[1]);
   }

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: imageType });
  }
  // dataURLtoFile(dataurl) {
  //
  //   const arr = dataurl.split(',');
  //   const mime = arr[0].match(/:(.*?);/)[1];
  //   const bstr = atob(arr[1]);
  //   let n = bstr.length;
  //   const u8arr = new Uint8Array(n);
  //
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //
  //   return new Blob([u8arr],  {type: mime});
  // }
    /* added by lotfi: correction vulnerabiliry
    dataURLtoFile(dataurl) {
        const arr = dataurl.split(',');
        const mime = this.extractMimeType(arr[0]);
        const bstr = atob(arr[1]);
        const n = bstr.length;
        const u8arr = new Uint8Array(n);
        for (let i = 0; i < n; i++) {
            u8arr[i] = bstr.charCodeAt(i);
        }
        return new Blob([u8arr], { type: mime });
    }
    extractMimeType(dataurl) {
        const mimeMatch = dataurl.match(/:([^;]+)/);
        if (!mimeMatch) {
            throw new Error('Invalid data URL: MIME type not found.');
        }
        return mimeMatch[1];
    }
    added by lotfi: correction vulnerabiliry */
    checkDeletes(event) {
    const that = this;
    // console.log('click');
    this.ids = [];
    if ($(event.target).prop('checked')) {
      this.bulk = true;
      $('input[id^=\'ckms_\']').prop('checked', true)
        .each(function(index, element) {
          that.ids.push(this.id.split('ckms_')[1]);
        });
      $('tr[id^=\'msg_\']').addClass('checked-message');
      // console.log(that.ids);
    } else {
      this.bulk = false;
      $('input[id^=\'ckms_\']').prop('checked', false);
      $('tr[id^=\'msg_\']').removeClass('checked-message');
      this.ids = [];
      //  console.log(that.ids);
    }
  }

  checkDelete(event, id) {
   // console.log(id);
  //  console.log($('input[id^=\'ckms_\']').length);
    if ($(event.target).prop('checked')) {
      this.ids.push(id);
      $('#msg_' + id).addClass('checked-message');
     // if($('input[id^=\'ckms_\']').length== this.ids.length){
      $('#allMessages_ck').prop('checked', true);
      this.bulk = true;
     // }
    //  console.log(this.ids);
    } else {
      this.ids = this.ids.filter( item => item != id);
      $('#msg_' + id).removeClass('checked-message');
      if (this.ids.length == 0) {
        $('#allMessages_ck').prop('checked', false);
        this.bulk = false;
      }
     // console.log(this.ids);
    }
  }

  showDelete(id: string) {
    $('#hidden-xs_' + id).attr('style', 'display:block!important');
  }

  hideDelete(id: string) {
    $('#hidden-xs_' + id).attr('style', 'display:none!important');
  }

  downloadAll() {
    const zip = new JSZip();
    const that = this;
    this.messageData.attachments.map(
      attachment => {
        if (attachment.type == 'xml') {
          zip.file(attachment.name, attachment.imageData.split('text/plain,')[1],  {base64: true});
        } else {
          zip.file(attachment.name, attachment.imageData.split('base64,')[1],  {base64: true});
        }

      }
    );
    zip.generateAsync({type: 'blob'}).then(function(content) {
      // see FileSaver.js
      FileSaver.saveAs(content, that.messageData.id + '.zip');
    });

  }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    const byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    const ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    const ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    return new Blob([ab], {type: mimeString});

  }
  previewXml(data) {
    return this.formatXml(unescape(data.split('text/plain,')[1]));
  }
 formatXml(xml, tab= '\t') { // tab = optional indent value, default is tab (\t)
    let formatted = '', indent = '';
    xml.split(/>\s*</).forEach(function(node) {
      if (node.match( /^\/\w/ )) { indent = indent.substring(tab.length); } // decrease indent by one 'tab'
      formatted += indent + '<' + node + '>\r\n';
      if (node.match( /^<?\w[^>]*[^\/]$/ )) { indent += tab; }              // increase indent
    });
    return formatted.substring(1, formatted.length - 3);
  }

  replyMessage() {
    localStorage.setItem('recipient', this.messageData.recipientId);
    localStorage.setItem('subject', this.messageData.subject);
    localStorage.setItem('type', this.messageData.type);
    $('#open-button').click();
  }
}
