import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {GlobaldesignerService} from '../../../services/globaldesigner/globaldesigner.service';
import {OrderPipe} from 'ngx-order-pipe';
import {Router} from '@angular/router';
import {CryptoService} from '../../../crypto.service';
import { Output, EventEmitter } from '@angular/core';
import * as pako from 'pako';
declare const $: any;
@Component({
  selector: 'app-shared-component-diag-proj',
  templateUrl: './shared-component-diag-proj.component.html',
  styleUrls: ['./shared-component-diag-proj.component.css']
})
export class SharedComponentDiagProjComponent implements OnInit {
  @Input() public selectedProjectName: string;
  @Input() public selectedProjectId: string;
  /******** tab assigned diagrams to Projects*******************/
    // list Project diagram
  usernameConnected = localStorage.getItem('username');
  hiderListDiagramAssignedProj = true;
  listAssignedProjDiagrams = [];
  pageSizeListAssignedProjDiagram = [5, 10 , 15 , 20 , 25 , 30 ];
  asyncAssignedProjDiagram: Observable<any[]>;
  sortedCollectionAssignedProjDiagram: any[];
  selectedPageSizeAssignedProjDiagram = 5;
  diagramconfigAssignedProjDiagram: any;
  pAssignedProj = 1;
  totalListAssignedProjDiagram = 0;
  dataFilterAssignedProjDiagram: any;
  flagAssignedProjDiagram = false;
  orderAssignedProjDiagram = 'id';
  reverseAssignedProjDiagram = false;
  loadingAssignedProjDiagram: boolean;
  endLoadAssignedProjDiagram = false;
  // show detail AssignedProj diagrams
  selectedDiagramAssignedProjID: string;
  sharedUsersByNameEmailAssignedProj = [];
  // save edit diagram -> get sharedType ans sharedUsers
  sharedTypeAssignedProj = '';
  sharedUsersAssignedProj = [];
  disableEditAssignedProj = true;
  sharedAllUsersRolesToAssignedProj = [];
  existUsersHaventRoles = false;

  typeSelectedAssignedProjDiag: string;
  constructor(private globaldesignerService: GlobaldesignerService, private orderPipeAssignedProj: OrderPipe,
              private router: Router, private cryptoService: CryptoService) {
    this.diagramconfigAssignedProjDiagram = { id: 'serverAssignedProj', itemsPerPage: this.selectedPageSizeAssignedProjDiagram, currentPage: this.pAssignedProj, totalItems: this.totalListAssignedProjDiagram };
  }

  ngOnInit(): void {
    // $('#designer_list_diagram_AssignedProj_' + this.selectedProjectId).hide();
    this.onGetUserHaventRoleGlobalDesignAssignedProj();
    setTimeout(() => {this.showOrHideListDiagramAssignedProj(this.selectedProjectName);}, 1000);
  }
  /*-------------------------------------------*/
  // list users havent roles online diagrams software
  onGetUserHaventRoleGlobalDesignAssignedProj() {
    this.globaldesignerService.getUserHaventRoleGlobalDesign('all', 'no-menu').subscribe(
      result => {
        result.dataAll.map(item => {
          this.sharedAllUsersRolesToAssignedProj.push(item);
        });
      },
      err => {
        this.sharedAllUsersRolesToAssignedProj = [];
        this.existUsersHaventRoles = false;
        console.error(err);
      },
      () => {
        this.sharedAllUsersRolesToAssignedProj.length > 0 ? this.existUsersHaventRoles = true : this.existUsersHaventRoles = false;
      }
    );
  }
  // show / hide list assigned Diagrams to Application in menu
  showOrHideListDiagramAssignedProj(project) {
    let that = this;
    if (this.hiderListDiagramAssignedProj) {
      $('#designer_list_diagram_AssignedProj_' + that.selectedProjectId).show();
      $('#arrow_list_diagram_AssignedProj_' + that.selectedProjectId).html('<i class="fas fa-angle-up"></i>');
      this.hiderListDiagramAssignedProj = false;
      $('#designer_list_diagram_AssignedProj_' + that.selectedProjectId).animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        that.getListAssignedDiagToSelectedProj(project);
      });
    } else {
      this.hiderListDiagramAssignedProj = true;
      $('#arrow_list_diagram_AssignedProj_' + that.selectedProjectId).html('<i class="fas fa-angle-down"></i>');
      $('#designer_list_diagram_AssignedProj_' + that.selectedProjectId).animate({
        height: '0'
      }, 1, 'swing', function() {
        $('#designer_list_diagram_AssignedProj_' + that.selectedProjectId).hide();
        $('#listAssignedProjdiagram_pagin_search_' + that.selectedProjectId).attr('style', 'display: none!important');
      });
    }
  }
  /*************************** tab list shared diagrams *****************************/
  // comparator sort

  sortAlphaNumAssignedProj(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  // sort list assigned diagram to Application
  setOrderAssignedProjDiagram(value: string) {
    if (this.orderAssignedProjDiagram === value) {
      this.reverseAssignedProjDiagram = !this.reverseAssignedProjDiagram;
    }

    this.orderAssignedProjDiagram = value;
  }
  pageListAssignedProjDiagramChanged(event) {
    this.diagramconfigAssignedProjDiagram.currentPage = event;
  }
  getPageAssignedProjDiagram() {
    this.diagramconfigAssignedProjDiagram.itemsPerPage = this.selectedPageSizeAssignedProjDiagram;
    this.diagramconfigAssignedProjDiagram.currentPage = 1;
    this.diagramconfigAssignedProjDiagram.totalItems = this.listAssignedProjDiagrams.length;
  }
  // list AssignedProj diagrams
  getListAssignedDiagToSelectedProj(project) {
    $('#spinner_list_AssignedProj_diagram_' + this.selectedProjectId).css('display', 'block');
    this.listAssignedProjDiagrams = [];
    this.globaldesignerService.getListAssignedDiagToSelectedProj(project).subscribe(
      result => {
        $('#spinner_list_AssignedProj_diagram_' + this.selectedProjectId).css('display', 'none');
        result.map(item => {
          this.listAssignedProjDiagrams.push(item);
        });
        $('#listAssignedProjdiagram_pagin_search_' + this.selectedProjectId).attr('style', 'display: flex!important');
        $('#paginate_AssignedProj_diagram').removeAttr('hidden');
      },
      err => {
        $('#spinner_list_AssignedProj_diagram_' + this.selectedProjectId).css('display', 'none');
        console.error(err);
      },
      () => {
        this.sortedCollectionAssignedProjDiagram = this.orderPipeAssignedProj.transform(this.asyncAssignedProjDiagram, 'id');
      }
    );
  }

  // show detail diagrams
  showDetailAssignedProjGlobDiagram(row, event) {
    this.sharedTypeAssignedProj = this.listAssignedProjDiagrams.find(x => x.id == row.id).sharedtype;
    this.sharedUsersAssignedProj = row.sharedusers;
    this.sharedUsersByNameEmailAssignedProj = [];
    this.sharedUsersAssignedProj.map(item => {
      if (this.sharedAllUsersRolesToAssignedProj.find(x => x.id == item)) {
        this.sharedUsersByNameEmailAssignedProj.push(this.sharedAllUsersRolesToAssignedProj.find(x => x.id == item));
      }
    });
    switch (this.sharedTypeAssignedProj) {
      case 'private' : $('#sharedAssignedProjDiagUP' + row.id + this.selectedProjectId).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
      case 'public': $('#sharedAssignedProjDiagUP' + row.id + this.selectedProjectId).html('<i style="color: blue" class="fas fa-users fa-2x"></i>'); break;
      case 'users': $('#sharedAssignedProjDiagUP' + row.id + this.selectedProjectId).html('<i style="color: green" class="fas fa-user-plus fa-2x"></i>'); break;
      case 'usersWithout': $('#sharedAssignedProjDiagUP' + row.id + this.selectedProjectId).html('<i style="color: red" class="fas fa-user-minus fa-2x"></i>'); break;
      default : $('#sharedAssignedProjDiagUP' + row.id + this.selectedProjectId).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
    }
    var id = row.id;
    this.selectedDiagramAssignedProjID = id;
    this.typeSelectedAssignedProjDiag = row.type;
    const ul = $('#AssignedProj' + id + this.selectedProjectId).parent().parent();
    const li = $('#AssignedProj' + id + this.selectedProjectId).parent();
    if (!this.flagAssignedProjDiagram) {
      if (row.description != null && row.description != 'undefined') {
        if (row.description.length > 0) {
          if (row.description != ' ') {
            $('#descriptionAssignedProjDiagDisabled' + row.id + this.selectedProjectId).html(atob(row.description));
          }
        }
      }
      this.flagAssignedProjDiagram = true;
      $('#bodyAssignedProj' + id + this.selectedProjectId).slideDown();
      $('#listAssignedProjdiagram_pagin_search_' + this.selectedProjectId).attr('style', 'display: none!important');
      ul.children().hide();
      $('#titleAssignedProjDiagram_' + this.selectedProjectId).show('slow');
      li.show('slow');
      // add SVG image to details selected diagram
      // get index of selected diag from list application diagram
      let indexDiag = this.listAssignedProjDiagrams.findIndex(o => o.id === row.id);
      const imgTab = this.listAssignedProjDiagrams[indexDiag].imageData;
      const nameArr = imgTab.split(',');
      const restored = JSON.parse(pako.inflate(nameArr, { to: 'string' }));
      $('#imageAssignedProjDiag' + row.id + this.selectedProjectId).html(restored);
      $('#imageAssignedProjDiag' + row.id + this.selectedProjectId).children().eq(0).css({display: 'block', 'margin-left': 'auto', 'margin-right': 'auto'});

      $('#descriptionAssignedProjDiagDisabled' + row.id + this.selectedProjectId).css({opacity: 1, height: '80%'});
    } else {
      $('#descriptionAssignedProjDiagDisabled' + row.id + this.selectedProjectId).css({opacity: 0, height: 0});
      $('#listAssignedProjdiagram_pagin_search_' + this.selectedProjectId).attr('style', 'display: flex!important');
      $('#spinner_list_AssignedProj_diagram_' + this.selectedProjectId).css('display', 'none');
      this.flagAssignedProjDiagram = false;
      this.dataFilterAssignedProjDiagram = null;
      $('#bodyAssignedProj' + id + this.selectedProjectId).slideUp();
      ul.children().show('slow');
    }
  }
  // redirect to Architect designer
  redirectToGlobalDesigner(row) {
    let cryptComponent = this.cryptoService.set('governance-project');
    cryptComponent = cryptComponent.replace(/\//g, '__________');
    let cryptType = this.cryptoService.set('proj');
    cryptType = cryptType.replace(/\//g, '__________');
    let cryptProjectName = this.cryptoService.set(row.name);
    cryptProjectName = cryptProjectName.replace(/\//g, '__________');
    let cryptProjectId = this.cryptoService.set(this.selectedProjectId);
    cryptProjectId = cryptProjectId.replace(/\//g, '__________');
    let cryptDiagName = this.cryptoService.set(this.selectedProjectName);
    cryptDiagName = cryptDiagName.replace(/\//g, '__________');
    let cryptDiagId = this.cryptoService.set(row.id);
    cryptDiagId = cryptDiagId.replace(/\//g, '__________');
    let cryptCompTarget = this.cryptoService.set('project');
    cryptCompTarget = cryptCompTarget.replace(/\//g, '__________');
    const url = 'global-designer/' + cryptCompTarget + '/' + cryptComponent + '/' + cryptType + '/' + cryptProjectName + '/' + cryptProjectId + '/' + cryptDiagName + '/' + cryptDiagId + '/diagrams-designer';
    this.router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(() => {
      this.router.navigate([url]);
    });
  }

}

