export class ClassificationRole {
  appl: string;
  models: any[];
  securityRiskRead= false;

  constructor(appl,models,securityRiskRead) {
    this.appl = appl;
    this.models = [...models];
    this.securityRiskRead=securityRiskRead;
  }
}
