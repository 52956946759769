import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {DrmClassifService} from '../../services/drm/drm-classif.service';

@Component({
  selector: 'app-no-classifcation',
  templateUrl: './no-classifcation.component.html',
  styleUrls: ['./no-classifcation.component.sass']
})
export class NoClassifcationComponent implements OnInit {
  lang: string;
  constructor(private translate: TranslateService, private drmClassifService: DrmClassifService) { }

  ngOnInit(): void {
     this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }

}
