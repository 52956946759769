import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import {ReportService} from '../../services/reports/report.service';
import {CookieService} from 'ngx-cookie-service';
import {AuthenticationService} from '../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import {CryptoService} from '../../crypto.service';
import Chart from 'chart.js';
import {FormBuilder} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Observable, Subscription} from 'rxjs';
import {ActivatecomponentService} from '../../services/appService/activatecomponent.service';
import {RouterhistoryService} from '../../services/appService/routerhistory.service';
declare const ApexCharts: any;
declare const $: any;
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.css']
})
export class ReportsComponent implements OnInit, AfterViewInit, OnDestroy {
  subscription: Subscription = new Subscription();
  lang: string;
  applicationPie = [];
  applicationCount = [];
  applicationColor = [];
  username: string;
  firstName: string;
  lastName: string;
  roles: string;
  public reports = [];
  public datata = [];
  public reportsData = [];
  environmentReport: string;
  adminRoleStruct = false;
  adminRoleModel = false;
  environmentTab = ['Reference', 'QAL', 'Developpement', 'Pre-Production', 'Production'];
  applicationSwitch: string;
  switchUser: string;

  topTables = [];
  topModels = [];
  selectedModelForObj: string;
  adminBridge = {system: '', category: '', onglet: '', component: ''};
  constructor(private dynamicScriptLoader: DynamicScriptLoaderService, private reportService: ReportService,
              private cookieService: CookieService, private authenticationService: AuthenticationService,
              private cryptoService: CryptoService, private activeRoute: ActivatedRoute,
              private router: Router, private translate: TranslateService, private routerHistoryService: RouterhistoryService,
              private activateComponentService: ActivatecomponentService) {
  }

  ngOnInit() {
    'use strict';
    // get params in route
    let subscription0$ = this.activeRoute.paramMap.subscribe(params => {
      let system = params.get('system');
      // this.cryptoSystem = params.get('system');
      let category = params.get('category');
      // this.cryptoCategory = params.get('category');
      let onglet = params.get('onglet');
      // this.cryptoOnglet = params.get('onglet');
      let component = params.get('component');
      // this.cryptoComponent = params.get('component');
      // change ---------- to /
      system = system.replace(/__________/g, '/');
      category = category.replace(/__________/g, '/');
      onglet = onglet.replace(/__________/g, '/');
      component = component.replace(/__________/g, '/');
      // decrypt params
      system = this.cryptoService.get(system);
      category = this.cryptoService.get(category);
      onglet = this.cryptoService.get(onglet);
      component = this.cryptoService.get(component);
      // get params if only not empty
      this.adminBridge.system = system;
      this.adminBridge.category = category;
      this.adminBridge.onglet = onglet;
      this.adminBridge.component = component;
      // console.log(this.adminBridge);
    });
    this.subscription.add(subscription0$);
    // end parameter in route
    $('.ng-arrow').css({width: '8px', height: '8px'});
    $('#getApplicationByReport').hide();
    $('#listApplication').hide();
    $('#getApplicationReportEmvironment').hide();
    $('#getApplicationReportDestribution').hide();
    // set component active
    // this.activateComponentService.checkReportComponentVisible.next(true);
    // end set component active
    if (sessionStorage.hasOwnProperty('menu')) {
      const menu = window.sessionStorage.getItem('menu');
      const userRole = this.cryptoService.decryptPhp(menu);
      this.adminRoleStruct = userRole.modelAdminStruct;
      this.adminRoleModel = userRole.modelAdminModel;
      if (this.adminRoleStruct) {
        this.onGetApplicationByReport('null');
        this.onGetStatisticApp().then(() => this.startScriptPie());
        this.getStaticsChartBar().then(() => this.startScriptBar());
      }
    }
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
  const that = this;
  setInterval(() => {
    this.lang = localStorage.getItem('lang') || 'en';
    this.translate.use(this.lang);
  }, 10);
  }
    ngAfterViewInit() {
    setTimeout(
      () => {
        if (this.adminRoleModel && (this.adminBridge.onglet == 'tab-model')) {
          $('#onglet-object-model').children().click();
          this.onActivateAdminObjectM();
          setTimeout(
            () => {
              this.OnGetTopModelsByApplicationToChartJS();
            }, 1000
          );
        } else if (this.adminRoleModel && !this.adminRoleStruct) {
          $('#onglet-object-model').children().click();
          this.onActivateAdminObjectM();
          setTimeout(
            () => {
              this.OnGetTopModelsByApplicationToChartJS();
            }, 1000
          );
        } else {
          $('#onglet-structure_li').children().eq(0).click();
        }
        this.showTipsModelAdmin();
      }, 1000
    );
    }
  //////////////////////// model admin tips ///////////////////////////////
  // showTipsModelAdmin() {
  //   if ((localStorage.getItem('dataMenu') == 'Model Administration') && (localStorage.getItem('dataTips') == 'true') && (localStorage.getItem('dontShowTipsModelAdmin') == 'false')) {
  //     localStorage.setItem('dontShowTipsModelAdmin', 'true');
  //     $('#tipsCompModelAdmin').modal('show');
  //   }
  // }
  showTipsModelAdmin() {
    let menu = localStorage.getItem('dataMenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsModelAdmin');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsModelAdmin') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsModelAdmin', 'true');
        setTimeout(() => {
          $('#tipsCompModelAdmin').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsModelAdmin') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsModelAdmin', 'true');
          $('#tipsCompModelAdmin').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips dashboard
  onUpdateTipsModelAdmin() {
    let tipsModelAdmin;
    if ($('#checked_show_modelAdmin').is(':checked')) {
      tipsModelAdmin = false;
    } else {
      tipsModelAdmin = true;
    }
    let dataPut = {
      username: localStorage.getItem('username'),
      mainTips: tipsModelAdmin,
      dataMenu: 'Data Model',
      dataSousmenu: 'Model Administration'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  //////////////////////// end model admin tips //////////////////////////////////////////////
    ngOnDestroy() {
      // set component inactive
      // this.activateComponentService.checkReportComponentVisible.next(false);
      // end set component inactive
      this.subscription.unsubscribe();
    }

    onGetApplicationByReport(envi) {
        let subscription2$ = this.reportService.getApplicationByReport(envi).subscribe(
            data => {
                this.datata = data;
                this.getStaticsChartBar().then(() => this.startScriptTest());
            },
                err => {
                console.error(err);
            },
            () => {
                $('#spinner_getApplicationByReport').hide();
                $('#getApplicationByReport').show();
            }
        );
      this.subscription.add(subscription2$);
    }
// Pie model admin: Application Report Distribution
    async onGetStatisticApp() {
      await this.reportService.getStatisticalByApplication().then(
            rep => {
              this.applicationPie = rep.map(item => item.text);
              this.applicationCount = rep.map(item => item.values);
              this.applicationColor = rep.map(item => item.color);
                // this.startScript();
            }, err => {console.error(err); }
      );
    }
  // Bar model admin: Application Report Distribution
    async getStaticsChartBar() {
      this.reports = [];
      await this.reportService.getApplicationByEnv().then(
          res => {
              this.reports = res;
      },
          err => (console.error(err)));
    }
    async startScriptBar() {
        await this.dynamicScriptLoader.load('apexcharts').then(data => {
          if (this.reports.length == 0) {
            $('#noGetApplicationReportEmvironment').css('display', 'block');
            $('#yesGetApplicationReportEmvironment').css('display', 'none');
          } else {
            $('#noGetApplicationReportEmvironment').css('display', 'none');
            $('#yesGetApplicationReportEmvironment').css('display', 'block');
            this.loadDataBar();
          }
        }).catch(error => console.log(error));
    }
    private loadDataBar() {
      if (document.getElementById('bar-chart-grouped') != null) {
        let chartBar = new Chart(document.getElementById('bar-chart-grouped'), {
          type: 'bar',
          data: {
            labels: ['Reference', 'Developpement', 'QAL', 'Pre-Production', 'Production'],
            datasets: this.reports
          },
          options: {
            title: {
              display: false,
              text: 'Number of metadata report per application and environment'
            },
            legend: {
              position: 'bottom'
            },
            scales: {
              yAxes: [{
                display: false,
                ticks: {
                  beginAtZero: true,
                }
              }]
            }
          }
        });
        chartBar.update();
      }
      $('#getApplicationReportDestribution').show();
      $('#spinner_getApplicationReportEmvironment').hide();
      $('#getApplicationReportEmvironment').show();
      $('#spinner_listApplication').hide();
      $('#listApplication').show();
    }
    async startScriptTest() {
        await this.dynamicScriptLoader.load('apexcharts').then(data => {
            this.chart4();
        }).catch(error => console.error(error));
    }
  async startScriptPie() {
    await this.dynamicScriptLoader.load('apexcharts').then(data => {
      if (this.applicationPie.length == 0) {
        $('#noGetApplicationReportDestribution').css('display', 'block');
        $('#yesGetApplicationReportDestribution').css('display', 'none');
      } else {
        $('#noGetApplicationReportDestribution').css('display', 'none');
        $('#yesGetApplicationReportDestribution').css('display', 'block');
        this.loadDataPie();
      }
    }).catch(error => console.log(error));
  }
  private loadDataPie() {
    if (document.getElementById('pie-chart') != null) {
      let chartPie = new Chart(document.getElementById('pie-chart'), {
        type: 'pie',
        data: {
          labels: this.applicationPie,
          datasets: [{
            backgroundColor: this.applicationColor,
            data: this.applicationCount
          }]
        },
        options: {
          title: {
            display: false,
            text: 'Percentage of metadata reports per application'
          },
          legend: {
            position: 'bottom'
          }
        }
      });
    }
  }

  private chart4() {
    this.datata.map(d => {
      if (document.querySelector('#chart4' + d.app) != null) {
        var reportCountt = [];
        var reportDatest = [];
        var name = d.app;
        var titleReport = d.titleReport;
        let max = 0;
        d['reportCount'].map(d1 => {
          reportCountt.push(d1);
          if (max < d1) { max = d1; }
        });
        d['reportDates'].map(d1 => {
          reportDatest.push(d1);
        });

        var series = [];
        series.push({
          name: 'Count of records',
          data: reportCountt

        });
        var options = {
          chart: {
            width: "100%",
            height: 200,
            type: 'line',
            shadow: {
              enabled: true,
              color: '#000',
              top: 3,
              left: 2,
              blur: 10,
              opacity: 1
            } ,
            toolbar: {
              show: false
            }
          },
          colors: ['#77B6EA', '#545454'],
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'],
              opacity: 0.5
            },
            padding: {
              left: 10,
              right: 10
            },
          },
          stroke: {
            width: 1,
            curve: 'smooth'
          },
          series: series,
          xaxis: {
            type: 'date',
            categories: reportDatest,
            tickPlacement: "between",
            labels: {
              show: false
            }
          },
          title: {
            text: titleReport,
            floating: true,
            offsetX: 0,
            offsetY: 8,
            align: 'center',
            style: {
              fontSize: '10px',
              color: '#01053e'
            }
          },
          markers: {
            size: 6,
          },
          yaxis: {
            show: false,
            min: 0,
            max: max * 1.2,
            labels: {
              style: {
                fontSize: 0.1,
                color: '#000000',
              }
            }
          },
          legend: {
            show: false,
            position: 'top',
            horizontalAlign: 'right',
            floating: true,
            offsetY: -25,
            offsetX: -5
          }
        };
        var chart = new ApexCharts(
          document.querySelector('#chart4' + name),
          options
        );
        chart.render();
      }
    });
  }
    // change breadcrumb
    onActivateAdminStructure() {
      $('#crumbAdminMs').css('display', 'block');
      $('#crumbAdminOm').css('display', 'none');
      $('#crumbAdminUp').css('display', 'none');
    }
    onActivateAdminObjectM() {
      $('#crumbAdminMs').css('display', 'none');
      $('#crumbAdminOm').css('display', 'block');
      $('#crumbAdminUp').css('display', 'none');
      this.OnGetTopModelsByApplicationToChartJS();
    }
    onActivateAdminUpload() {
      $('#crumbAdminMs').css('display', 'none');
      $('#crumbAdminOm').css('display', 'none');
      $('#crumbAdminUp').css('display', 'block');
    }
    // Onglet objectModel
    OnGetTopModelsByApplicationToChartJS() {
      this.selectedModelForObj = localStorage.getItem('appCharObjMod');
      $('#chart-Top-horiz-parent').empty().html('<canvas id="chart-Top-horiz" width="360"></canvas>');
      this.topTables = [];
      this.topModels = [];
      let subscription3$ = this.reportService.getTopModelByApplication(this.selectedModelForObj).subscribe(
        data => {
          this.topTables = data[0].topTables;
          this.topModels = data[0].topModels;
        },
        err => {
          console.error(err);
        },
        () => {
          if (document.getElementById('chart-Top-horiz') != null) {
            let chart = new Chart(document.getElementById('chart-Top-horiz'), {
              type: 'horizontalBar',
              data: {
                labels: this.topModels,
                datasets: [
                  {
                    label: 'count',
                    backgroundColor: ['#004E29', '#007F44', '#009B53', '#00B862', '#00D471', '#00F080', '#0EFF8E', '#2AFF9B', '#46FEA9', '#63FFB6'],
                    data: this.topTables
                  }
                ]
              },
              options: {
                legend: { display: false },
                title: {
                  display: false,
                  text: 'Top 10 most represented ObjectModels'
                }
              }
            });
          }
        }
      );
      this.subscription.add(subscription3$);
    }
  // End Onglet objectModel
}
