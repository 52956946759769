import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import d3Tip from 'd3-tip';
import {Observable, Subject} from "rxjs";
import {getCookieValue, Shared} from '../../shared';
import {tap} from "rxjs/operators";
import {App} from "../../data-transfert/applicationdefinition/App";
import {Flow} from "../../data-transfert/home-transfert/Flow";
import 'd3-selection-multi';
import * as d3 from 'd3';
import { idText } from 'typescript';
// import * as d3Sankey from "d3-sankey";
declare const $: any;
@Injectable({
  providedIn: 'root'
})
/**
 * This service is a System store
 *
 * See {@link Shared} for details about the main data of this store
 */
export class AppassetService {
    /**
 * This option file for service
 *
 * See {@link Shared} for details about the main data of this store
 */
    getOptions1() {
        return {
          headers: new HttpHeaders({
            Accept: 'Application/json',
            Authorization: 'Bearer ' + getCookieValue('tokendrd')
          })
        };
      }
      /**
 * This option file for service
 *
 * See {@link Shared} for details about the main data of this store
 */
    OptionFiles()  {
        return {
            headers: new HttpHeaders({
                //'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'Application/json',
                'Authorization': 'Bearer ' + getCookieValue('tokendrd')
                // 'Accept': 'Application/json'
            })
        };
    };
    Options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            
        })
    };
    OptionsF = {
        headers: new HttpHeaders({
            'Content-Type': 'application/pdf',
            'Accept': 'arraybuffer',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            //  'Accept': 'Application/json'
        })
    };
    headerss = new Headers({
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
    });
    constructor(private http: HttpClient, private router: Router) { }
    getListApplication(): Observable<any> {
        // const userId=localStorage.getItem("userId");
        return this.http.get<any>(Shared.API_URL + '/api/application/getList', this.Options);
    }
    getListMDForAdd(objectName): Observable<any> {
        
        return this.http.get<any>(Shared.API_URL +  `/api/metadatas/getMetaForAdd/${objectName}`, this.Options);
      }

    getTreeMap(d): Observable<any> {
        let formData = new FormData();
    formData.append('data', d);
// console.log(d);
        return this.http.post(Shared.API_URL + '/api/application/getTreeMap',  formData, this.OptionFiles());

    }
    getListReport(): Observable<any> {
        // const userId=localStorage.getItem("userId");
        return this.http.get<any>(Shared.API_URL + '/api/application/getListReport', this.Options);
    }
    getenabledApplication(withFlow:boolean): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/application/getenabledApp/' + withFlow, this.Options);
    }

    deleteApplication(id:string):Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/application/deleteApplication/' + id, this.Options);
    }
    getfilterApplicationDef(d): Observable<any> {
        let formData = new FormData();
        formData.append('data', d);
// console.log(d);
        return this.http.post(Shared.API_URL + '/api/application/getfilterAppDef',  formData, this.OptionFiles());

    }
    resetApps(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/application/resetApps' , this.Options);
    }
    updateApplication(d): Observable<any>{
        let formData = new FormData();
    formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/application/updateApp' , formData,  this.OptionFiles());


    }
    updateVersion(d): Observable<any>{
        let formData = new FormData();
        formData.append('data', d);

        return this.http.post(Shared.API_URL + '/api/application/updateVersion' , formData,  this.OptionFiles());


    }
    getHolders(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/holder/getHolders/' + localStorage.getItem('userId'), this.Options);
      }
    updateFile(d): Observable<any>{
        let formData = new FormData();
        formData.append('data', d);

        return this.http.post(Shared.API_URL + '/api/application/updateFile' , formData,  this.OptionFiles());


    }
    updateModule(d): Observable<any>{
        let formData = new FormData();
        formData.append('data', d);

        return this.http.post(Shared.API_URL + '/api/application/updateModule' , formData,  this.OptionFiles());


    }
    addVersionApp(d): Observable<any>{
        let formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/application/addVersionApp' , formData,  this.OptionFiles());
    }
    // synchronize all versions by selected systems
    synchronizeAllVersionBySystem(idSystem: string): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/application/synchronizeAllVersionsBySystem/' + idSystem, this.Options);
    }
    // addFileApp(d): Observable<any>{
    //     const data ="data=" +d;

    //     return this.http.post(Shared.API_URL + '/api/application/addFileApp' , data,  this.Options);


    // }
    
    deleteFlow(id:string):Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/application/deleteFlow/' + id, this.Options);

    }
    getApplication(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/getApplication/${id}`, this.Options);
    }


    getListAppForBubble(d): Observable<any> {
        let formData = new FormData();
        formData.append('data', d);
// console.log(d);
        return this.http.post(Shared.API_URL + '/api/application/getListAppForBubble',  formData, this.OptionFiles());

    }


    // getFlow(): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/application/getFlow', this.Options);
    // }
    // addApp(d: App){
    //     const data ="data=" +JSON.stringify(d);
    //     console.log( data);
    //
    //     return this.http.post(Shared.API_URL + '/api/application/addApp' , data, this.Options);
    //
    //
    // }
    // addApps(d: App){
    //     const data ="data=" +JSON.stringify(d);
    //
    //     return this.http.post(Shared.API_URL + '/api/application/addApp' , data, this.Options);
    //
    //
    // }
    addApp(d): Observable<any>{
        let formData = new FormData();
        formData.append('data', d);
// console.log(d);
        return this.http.post(Shared.API_URL + '/api/application/addApp' , formData, this.OptionFiles());
    }

    // addApp(data: string) {
    //     return this.http.post(Shared.API_URL + '/api/users/new', data, this.Options)
    //         .pipe(
    //             tap(
    //                 () => {
    //                     this.refreshNeeded.next();
    //                 })
    //         );
    // }
    addFlow(f: Flow){
       
        let formData = new FormData();
        formData.append('data', JSON.stringify(f));
        return this.http.post(Shared.API_URL + '/api/application/addFlow' , formData, this.OptionFiles());
    }
    getModuleAppSrc(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/getModuleAppSrc/${id}`, this.Options);
    }
    getModuleAppTarget(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/getModuleAppTarget/${id}`, this.Options);
    }
    getVersionsApp(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/getVersionsApp/${id}`, this.Options);
    }
    getFilesApp(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/getFilesApp/${id}`, this.Options);
    }
    getModulesApp(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/getModulesApp/${id}`, this.Options);
    }


    deleteModuleApp(id,idobj): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/deleteModuleApp/${id}/${idobj}`, this.Options);
    }
    deleteVersionApp(id,idobj): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/deleteVersionApp/${id}/${idobj}`, this.Options);
    }
    deleteFileApp(id,idobj): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/deleteFileApp/${id}/${idobj}`, this.Options);
    }
    addModuleApp(d): Observable<any>{
        let formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/application/addModuleApp', formData,  this.OptionFiles());
    }
    getChangeappList(id: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/application/getChangeAppList/' + id, this.Options);
    }
    editModApp(editmodule,editmoduledesc,idobj,id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/application/editModuleApp/${editmodule}/${editmoduledesc}/${idobj}/${id}`, this.Options);
    }
    uploadFilesApp(data): Observable<any> {
        let formData = new FormData();
        if(data!=undefined){
            formData.append('file', data);
        }
        return this.http.post<any>(Shared.API_URL + '/api/application/insertApp', formData, this.OptionFiles());
    }
    
 
    uploadFiles(data, id, desc): Observable<any> {
        let formData = new FormData();
        if(data!=undefined){
            data.map( f => formData.append('file[]',f));
        }
        return this.http.post<any>(Shared.API_URL + '/api/application/insertFile/' + id + '/' + desc, formData, this.OptionFiles());
    }

    // sendFile(objId,files,image){
    //     let formData = new FormData();
    //     if(files!=undefined){
    //       files.map( f => formData.append('file[]',f));
    //     }
    //     // formData.append('subject', subject);
    //     formData.append('image', image);
    //     console.log(formData);
    //     return this.http.post<any>(Shared.API_URL + '/api/application/insertFile/' + objId, formData, this.getOptions1());
    //   }

    // getFile(fileCrypt,fileName): Observable<ArrayBuffer> {
      
    //     return this.http.get<ArrayBuffer>(Shared.API_URL + `/api/application/downloadFile/${fileCrypt}/${fileName}`, this.OptionsF);
    // }
    
    // getFiles(): Observable<any> {
    //     return this.http.get(Shared.API_URL +`/web/uploads/assets`);
    //   }
    packo(data) {
        //console.log(data);
        const that = this;
        const width = 932;
        const height = 932;
        // const data= this.data;
        const color = d3.scaleLinear()
            .domain([0, 5])
            .range(["hsl(0,0%,100%)", "hsl(0,0%,80%)"])
            // .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
            .interpolate(d3.interpolateHcl);
        // const format = d3.format(",d");
        const pack = data => d3.pack()
            .size([width, height])
            .padding(10)
            (d3.hierarchy(data)
                .sum(d => d.value)
                .sort((a, b) => b.value - a.value));

        const root = pack(data);
        let focus = root;
        let view;

        const svg = d3.select("#globChart")
            .attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
            .style("display", "block")
            .style("margin", "0 -14px")
            .style("background", "white")
            .style("cursor", "pointer")
            .on("click", () => zoom(root));

        const cont = svg.append("g");
        const node = cont
            .selectAll("circle")
            .data(root.descendants().slice(1))
            .join("circle")
            // .attr("fill", d => d.children ? color(d.depth) : d.data.Color)
            .attr("fill", d => d.children ? color(d.depth) : d.data.Color)
            .attr('id', d => 'globChart' +'_'+ d.data.name)
            .attr("pointer-events", d => !d.children ? "none" : null)
            .on("mouseover", function() { d3.select(this).attr("stroke", "#000"); })
            .on("mouseout", function() { d3.select(this).attr("stroke", null); })
            .on("click", d => focus !== d && (zoom(d), event.stopPropagation()));
        const label = svg.append("g")
            .style("font", "14px sans-serif")
            .attr("pointer-events", "none")
            .attr("text-anchor", "middle")
            .selectAll("text")
            .data(root.descendants())
            .join("text")
            .style("fill-opacity", d => d.parent === root ? 1 : 0)
            .style("display", d => d.parent === root ? "inline" : "none")
            .text(d => d.data.name);

        zoomTo([root.x, root.y, root.r * 2]);

        function zoomTo(v) {
            const k = width / v[2];

            view = v;

            label.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
            node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
            node.attr("r", d => d.r * k);
        }

        function zoom(d) {
            const focus0 = focus;

            focus = d;

            const transition = svg.transition()
                .duration(d3.event.altKey ? 7500 : 750)
                .tween("zoom", d => {
                    const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
                    return t => zoomTo(i(t));
                });

            label
                .filter(function(d) { return d.parent === focus || this.style.display === "inline"; })
                .transition(transition)
                .style("fill-opacity", d => d.parent === focus ? 1 : 0)
                .on("start", function(d) { if (d.parent === focus) this.style.display = "inline"; })
                .on("end", function(d) { if (d.parent !== focus) this.style.display = "none"; });

        }

    }
    legendcirclepack(scaleFactor, id, legendSizes, legendTexts){



        const  commaFormat = d3.format(',');

        const offset = legendSizes.length - 1;
        let width = $('#' + id).empty().width(),
            height = legendSizes[offset]*2*1.2;
        width=210;
        let	legendCenter = -10,
            legendBottom = height,
            legendLineLength = legendSizes[offset]*1.3,
            textPadding = 5;

        //Create SVG for the legend
        const legends = d3.select("#" + id).append("svg")
            .attr("width", width)
            .attr("height", height*1.5)
            .append("g")
            .attr("class", "legendWrapper")
            .attr("transform", "translate(" + width / 2 + "," + 0 + ")");
        //  .style("opacity", 0);

        //Draw the circles
        legends.selectAll(".legendCircle")
            .data(legendSizes)
            .enter().append("circle")
            .attr('r', function(d) { return d; })
            .attr('class',"legendCircle")
            .attr('cx', legendCenter)
            .attr('cy', function(d) { return legendBottom-d; });
        // legends.append('rect')
        //     .attr('x' , legendCenter)
        //     .attr('y' , legendBottom + 10)
        //     .attr('width', 10)
        //     .attr('height', 10)
        //     .attr("rx", 3)
        //     .attr("ry", 3)
        //     .attr('fill', '#4daf4a');
        //Draw the line connecting the top of the circle to the number
        legends.selectAll(".legendLine")
            .data(legendSizes)
            .enter().append("line")
            .attr('class',"legendLine")
            .attr('x1', legendCenter)
            .attr('y1', function(d) { return legendBottom-2*d; })
            .attr('x2', legendCenter + legendLineLength)
            .attr('y2', function(d) { return legendBottom-2*d; });
        //Place the value next to the line
        legends.selectAll(".legendText")
            .data(legendSizes)
            .enter().append("text")
            .attr('class',"legendText")
            .attr('x', legendCenter + legendLineLength + textPadding)
            .attr('y', function(d) { return legendBottom-2*d; })
            .attr('dy', '0.3em')
            .text(function(d , i) { return legendTexts[i]; });
        // legends.append('text')
        //     .attr('class', 'legendText')
        //     .attr('x', legendCenter + legendLineLength + textPadding)
        //     .attr('y', legendBottom + 17)
        //     .text('Principal Table');






//
//
//         // append the svg object to the body of the page
//         var height = 300
//         var width = 350
//         var svg = d3.select("#my_dataviz")
//             .append("svg")
//             .attr("width", width)
//             .attr("height", height)
//             .attr("margin-left", -73+'px')
//             .attr("margin-top", -60+'px')
//
// // The scale you use for bubble size
//         var size = d3.scaleSqrt()
//             .domain([1, 100])  // What's in the data, let's say it is percentage
//             .range([1, 100])  // Size in pixel
//
// // Add legend: circles
//         var valuesToShow = [10, 50, 100]
//         var xCircle = 170
//         var xLabel = 320
//         var yCircle = 270
//         svg
//             .selectAll("legend")
//             .data(valuesToShow)
//             .enter()
//             .append("circle")
//             .attr("cx", xCircle)
//             .attr("cy", function(d){ return yCircle - size(d) } )
//             .attr("r", function(d){ return size(d)})
//             .style("fill", "none")
//             .attr("stroke", "black")
//
// // Add legend: segments
//         svg
//             .selectAll("legend")
//             .data(valuesToShow)
//             .enter()
//             .append("line")
//             .attr('x1', function(d){ return xCircle + size(d) } )
//             .attr('x2', xLabel)
//             .attr('y1', function(d){ return yCircle - size(d) } )
//             .attr('y2', function(d){ return yCircle - size(d) } )
//             .attr('stroke', 'black')
//             .style('stroke-dasharray', ('2,2'))
//
// // Add legend: labels
//         svg
//             .selectAll("legend")
//             .data(valuesToShow)
//             .enter()
//             .append("text")
//             .attr('x', xLabel)
//             .attr('y', function(d){ return yCircle - size(d) } )
//             .text( function(d){ return d } )
//             .style("font-size", 10)
//             .attr('alignment-baseline', 'middle')
    }
    legendtreemap(scaleFactor, id, legendSizes, legendTexts){



        const  commaFormat = d3.format(',');

        const offset = legendSizes.length - 1;
        let width = $('#' + id).empty().width(),
            height = legendSizes[offset]*2*1.2;
        width=250;
        let legendxy=['-45','-40']
        let legendwh=[40,30]
        let	legendCenter = -10,
            legendBottom = height,
            legendLineLength = legendSizes[offset]*1.3,
            textPadding = 5;

        //Create SVG for the legend
        const legends = d3.select("#" + id).append("svg")
            .attr("width", width)
            .attr("height", height*1.5)
            .append("g")
            .attr("class", "legendWrapper")
            .attr("transform", "translate(" + width / 2 + "," + 0 + ")");
        //  .style("opacity", 0);

        //Draw the circles
        legends.selectAll(".legendCircle")
            .data(legendSizes)
            .enter().append("rect")
            .attr('width', (d,i)=>legendwh[i])
            .attr('height', (d,i)=>legendwh[i]+10)
            .attr('class',"legendCircle")
            .attr("x", (d,i)=>legendxy[i])
            .attr("y", 10);
        // legends.append('rect')
        //     .attr('x' , legendCenter)
        //     .attr('y' , legendBottom + 10)
        //     .attr('width', 10)
        //     .attr('height', 10)
        //     .attr("rx", 3)
        //     .attr("ry", 3)
        //     .attr('fill', '#4daf4a');
        //Draw the line connecting the top of the circle to the number
        legends.selectAll(".legendLine")
            .data(legendSizes)
            .enter().append("line")
            .attr('class',"legendLine")
            .attr('x1', legendCenter)
            .attr('y1', function(d) { return legendBottom-2*d; })
            .attr('x2', legendCenter + legendLineLength)
            .attr('y2', function(d) { return legendBottom-2*d; });
        //Place the value next to the line
        legends.selectAll(".legendText")
            .data(legendSizes)
            .enter().append("text")
            .attr('class',"legendText")
            .attr('x', legendCenter + legendLineLength + textPadding)
            .attr('y', function(d) { return legendBottom-2*d; })
            .attr('dy', '0.3em')
            .text(function(d , i) { return legendTexts[i]; });
        // legends.append('text')
        //     .attr('class', 'legendText')
        //     .attr('x', legendCenter + legendLineLength + textPadding)
        //     .attr('y', legendBottom + 17)
        //     .text('Principal Table');






//
//
//         // append the svg object to the body of the page
//         var height = 300
//         var width = 350
//         var svg = d3.select("#my_dataviz")
//             .append("svg")
//             .attr("width", width)
//             .attr("height", height)
//             .attr("margin-left", -73+'px')
//             .attr("margin-top", -60+'px')
//
// // The scale you use for bubble size
//         var size = d3.scaleSqrt()
//             .domain([1, 100])  // What's in the data, let's say it is percentage
//             .range([1, 100])  // Size in pixel
//
// // Add legend: circles
//         var valuesToShow = [10, 50, 100]
//         var xCircle = 170
//         var xLabel = 320
//         var yCircle = 270
//         svg
//             .selectAll("legend")
//             .data(valuesToShow)
//             .enter()
//             .append("circle")
//             .attr("cx", xCircle)
//             .attr("cy", function(d){ return yCircle - size(d) } )
//             .attr("r", function(d){ return size(d)})
//             .style("fill", "none")
//             .attr("stroke", "black")
//
// // Add legend: segments
//         svg
//             .selectAll("legend")
//             .data(valuesToShow)
//             .enter()
//             .append("line")
//             .attr('x1', function(d){ return xCircle + size(d) } )
//             .attr('x2', xLabel)
//             .attr('y1', function(d){ return yCircle - size(d) } )
//             .attr('y2', function(d){ return yCircle - size(d) } )
//             .attr('stroke', 'black')
//             .style('stroke-dasharray', ('2,2'))
//
// // Add legend: labels
//         svg
//             .selectAll("legend")
//             .data(valuesToShow)
//             .enter()
//             .append("text")
//             .attr('x', xLabel)
//             .attr('y', function(d){ return yCircle - size(d) } )
//             .text( function(d){ return d } )
//             .style("font-size", 10)
//             .attr('alignment-baseline', 'middle')
    }
}

