import {Component, Input, OnInit} from '@angular/core';
import {ObjectService} from '../../services/objects/object.service';

declare const $: any;

declare var require: any;

@Component({
  selector: 'app-volume',
  templateUrl: './volume.component.html',
  styleUrls: ['./volume.component.sass']
})
export class VolumeComponent implements OnInit {
  @Input() selectedObjectModel: string;
  @Input() selectedReport: string;

  dataset: any;

  options: any;

  constructor( private objectService: ObjectService) { }

  ngOnInit() {

  }


  // get (reportDetails) recordCand and Count BY selected DDTMReport and selected ObjectModel
    onGetCandidByRepAndMod() {
      $('#container').empty();
      this.dataset = [];
      this.objectService.getObjAndContByRepAndMod(this.selectedObjectModel, this.selectedReport).subscribe(
        data => {
          this.dataset = data;
          console.log(this.dataset);
        },
        err => {
          console.error(err);
        },
        () => {
        this.options = {
            chart: {
              type: 'packedbubble',
              height: '100%'
            },
            title: {
              text: ''
            },
            tooltip: {
              useHTML: true,
              pointFormat: '<b>{point.name}:</b> {point.value}'
            },
          plotOptions: {
            packedbubble: {
              minSize: '30%',
              maxSize: '120%',
              zMin: 0,
              zMax: 1000,
              layoutAlgorithm: {
                splitSeries: false,
                gravitationalConstant: 0.02
              },
              dataLabels: {
                enabled: true,
                format: '{point.name}',
                filter: {
                  property: 'y',
                  operator: '>',
                  value: 0
                },
                style: {
                  color: 'black',
                  textOutline: 'none',
                  fontWeight: 'normal'
                }
              }
            }
          },
            series: this.dataset
          };
        }
      );
    }

    public bubble(dat) {

        }
}
