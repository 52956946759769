export class archid{
    SYSTNAPS_ARCHID:number;
    SYSTNAPS_ARCHNAME:string;
    desc:string = "...";
    SYSTNAPS_DATE: string;
    SYSTNAPS_PROCESSED:number;
    SYSTNAPS_SCHDLID:number;
    SYSTNAPS_ENDDATE:Date;
    SYSTNAPS_PURGED:string;
    SYSTNAPS_ISARCH: boolean;
    SYSTNAPS_ISPURGE: boolean;
    SYSTNAPS_APPLICATION: string;
    base: string;
    constructor(SYSTNAPS_ARCHID,SYSTNAPS_ARCHNAME,desc,SYSTNAPS_DATE,SYSTNAPS_PROCESSED,SYSTNAPS_SCHDLID,SYSTNAPS_ENDDATE,SYSTNAPS_PURGED,SYSTNAPS_ISARCH,SYSTNAPS_ISPURGE,SYSTNAPS_APPLICATION,base){
        this.SYSTNAPS_ARCHID = SYSTNAPS_ARCHID;
        this.SYSTNAPS_ARCHNAME = SYSTNAPS_ARCHNAME;
        this.SYSTNAPS_DATE = SYSTNAPS_DATE;
        this.desc = desc;
        this.SYSTNAPS_PROCESSED = SYSTNAPS_PROCESSED;
        this.SYSTNAPS_SCHDLID = SYSTNAPS_SCHDLID;
        this.SYSTNAPS_ENDDATE = SYSTNAPS_ENDDATE;
        this.SYSTNAPS_PURGED = SYSTNAPS_PURGED;
        this.SYSTNAPS_ISARCH = SYSTNAPS_ISARCH;
        this.SYSTNAPS_ISPURGE = SYSTNAPS_ISPURGE;
        this.SYSTNAPS_APPLICATION = SYSTNAPS_APPLICATION;
        this.base = base;
    }
}
