export class StructureJson {
  appl: string;
  readStruct = false;
  updateStruct = false;
  createStruct = false;
  deleteStruct = false;
  constructor(appl, readStruct, updateStruct,createStruct,deleteStruct) {
    this.appl = appl;
    this.readStruct = updateStruct;
    this.updateStruct = updateStruct;
    this.createStruct = createStruct;
    this.deleteStruct = deleteStruct;
  }
}
