import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Shared, getCookieValue} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class DrmRulesService {

  constructor(private http: HttpClient, private router: Router) { }

  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }

  // liste des applications pour alimenter le tableau object models ayant des rules
  getApplForListModelsRules(): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-rules/getApplForListModelsRules/' + user, this.getOptions());
  }
  // tableau object models by status approved/archived
  getObjectModelsListRules(appli: string): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-rules/getObjectModelsListRules/' + user + '/' + appli, this.getOptions());
  }
  // get count filters and objects containing filters
  getCountDistFiltersByModel(idModel: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/drm-rules/getCountDistFiltersByModel/' + idModel, this.getOptions1());
  }
  // get top ten objects containing filters
  getTopTenObjByModel(idModel): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/drm-rules/getTopTenObjByModel/' + idModel, this.getOptions1());
  }
}
