import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main-rules',
  templateUrl: './main-rules.component.html',
  styleUrls: ['./main-rules.component.sass']
})
export class MainRulesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
