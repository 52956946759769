import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Add} from '../../models/add';
import {Subject} from "rxjs";
import {tap} from "rxjs/operators";
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class UseraddService {
    private refreshNeeded = new Subject<void>();
    data: string;
    Options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'Application/json',
            Authorization: 'Bearer ' + getCookieValue('tokendrd')
            //  'Accept': 'Application/json'
        })
    };
    constructor(private http: HttpClient) { }

    getRefreshNeeded() {
        return this.refreshNeeded;
    }
    checkUserName(userName) {
        return this.http.get(Shared.API_URL + '/api/users/checkUsername/' + userName, this.Options);
    }
    addUser(data: string) {
        return this.http.post(Shared.API_URL + '/api/users/new', data, this.Options)
            .pipe(
                tap(
                    () => {
                      this.refreshNeeded.next();
                    })
            );
    }

  checkEmail(email) {
    return this.http.get(Shared.API_URL + '/api/users/checkEmail/'+ email, this.Options);
  }
}
