import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlossaireComponent } from './glossaire/glossaire.component';
import {GlossaireRoutingModule} from './glossaire-routing.module';
import {ArchwizardModule} from 'angular-archwizard';
import {NgxPaginationModule} from 'ngx-pagination';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {OrderModule} from 'ngx-order-pipe';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {DrmRoutingModule} from '../drm/drm-routing.module';
import {TooltipModule} from 'ng2-tooltip-directive';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {SidebarModule} from 'ng-sidebar';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared/shared.module';
import {EllipsisPipe} from '../ellipsis.pipe';



@NgModule({
  declarations: [GlossaireComponent, EllipsisPipe],
    imports: [
        GlossaireRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        DrmRoutingModule,
        TooltipModule,
        NgSelectModule,
        NgOptionHighlightModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        OrderModule,
        NgxDropzoneModule,
        SidebarModule.forRoot(),
        LeafletModule,
        TranslateModule,
        ArchwizardModule,
        SharedModule
    ]
})
export class GlossaireModule { }
