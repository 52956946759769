export class ConservationPeriod {
  private _country: string;
  private _domain: string;
  private _currentdata: string;
  private _currentduration: number;
  private _termdata: string;
  private _termduration: number;
  private _description: string;

  constructor(country: string, domain: string, currentdata: string, currentduration: number, termdata: string, termduration: number, description: string) {
    this._country = country;
    this._domain = domain;
    this._currentdata = currentdata;
    this._currentduration = currentduration;
    this._termdata = termdata;
    this._termduration = termduration;
    this._description = description;
  }

  get country(): string {
    return this._country;
  }

  set country(value: string) {
    this._country = value;
  }

  get domain(): string {
    return this._domain;
  }

  set domain(value: string) {
    this._domain = value;
  }

  get currentdata(): string {
    return this._currentdata;
  }

  set currentdata(value: string) {
    this._currentdata = value;
  }

  get currentduration(): number {
    return this._currentduration;
  }

  set currentduration(value: number) {
    this._currentduration = value;
  }

  get termdata(): string {
    return this._termdata;
  }

  set termdata(value: string) {
    this._termdata = value;
  }

  get termduration(): number {
    return this._termduration;
  }

  set termduration(value: number) {
    this._termduration = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }
}
