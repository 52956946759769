import * as d3 from 'd3';

import d3Tip from 'd3-tip';

import {sunBurstWithLabels} from '../sunburst/sunburst';

declare const $: any;

export const globSize = (data , model) => {
  const width = 932;
  const height = 932;
  const color = d3.scaleLinear()
    .domain([0, 5])
    .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
    .interpolate(d3.interpolateHcl);
  const pack = data => d3.pack()
    .size([width, height])
    .padding(3)
    (d3.hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value));

  const root = pack(data);
  let focus = root;
  let view;
  $('#globSizeChart').empty();
  const svg = d3.select("#globSizeChart");
  svg.attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
    .style("display", "block")
    .style("margin", "0 -14px")
    .style("background", '#ffffff')
    .style("cursor", "pointer")
    .on("click", () => zoom(root));

  // add shadow filter
  svg.append("svg:defs").selectAll('filter')
    .data(['shadowGlobeSize'])
    .enter().append('svg:filter')
    .attr("id", String)
    .attr('y', -10)
    .attr('x', -10)
    .attr('height', 40)
    .attr('width', 150)
    .append('feOffset')
    .attr('in', 'SourceAlpha')
    .attr('dx', 3)
    .attr('dy', 3)
    .attr('result', 'offset2')
    .attr('stdDeviation', 4);
  svg.selectAll('filter')
    .append('feGaussianBlur')
    .attr('in', 'offset2')
    .attr('result', 'blur2')
    .attr('stdDeviation', 10);
  svg.selectAll('filter')
    .append('feMerge')
    .append('feMergeNode')
    .attr('in', 'blur2');
  svg.selectAll('filter')
    .select('feMerge')
    .append('feMergeNode')
    .attr('in', 'SourceGraphic');
  const cont = svg.append("g");
  // tooltip
  if($('.d3-tipGlobe-Zoom').length > 0){
    $('.d3-tipGlobe-Zoom').remove();
  }
  const tool_tip = d3Tip()
    .direction('n')
    .attr("class", "d3-tipGlobe-Zoom")
    .attr('id', 'toolTipos')
    .html((d) => {
      return '<div id="sizeTipo">' +  `${d.ancestors().map(d => d.data.name).reverse().join(" → ")}` + '</div>';
    });
  svg.call(tool_tip);
  const node = cont
    .selectAll("circle")
    .data(root.descendants().slice(1))
    .join("circle")
    .attr('class', 'circulos')
    .attr('id', d => 'globSize_' + d.data.id)
    .attr('data-type', d => d.data.type)
    .attr("fill", d => d.children ? color(d.depth) : "white")
     .on("click", d => focus !== d && (zoom(d), d3.event.stopPropagation()));

  // add icon type
  const getIcon = (d) => {
    let ic;
    switch (d) {
      case 'Physical Person': ic = '\uf2bd'; break;
      case 'Physical person': ic = '\uf2bd'; break;
      case 'Legal Entity': ic = '\uf1ad'; break;
      case 'Legal entity': ic = '\uf1ad'; break;
      case 'Data Repository': ic = '\uf494'; break;
      case 'Data repository': ic = '\uf494'; break;
      case 'Technical data': ic = '\uf085'; break;
      case 'Technical Data': ic = '\uf085'; break;
      case 'Operational data': ic = '\uf1c0'; break;
      case 'Operational Data': ic = '\uf1c0'; break;
      default: ic = '\uf00d'; break;
    }
    return ic;
  };
  // usage and security risk return color
  const getUsageRisk = (d) => {
    let ic;
    switch (d) {
      case 0: ic = 'grey'; break;
      case 1: ic = 'green'; break;
      case 2: ic = 'orange'; break;
      case 3: ic = 'red'; break;
    }
    return ic;
  };

  const tools = svg.append("g")
    .attr('id', 'toolsSunburst')
    .style('display', 'none')
    .attr("transform", 'translate(0,0)');
  const g = svg.append("g");
  const recordIcons = svg.append('g').attr('id','recordIcons').style('display', 'none');
  // add icon type for each object
  let typeIcon = svg.append('g')
    .attr('id', 'typeIconGlobos')
    .selectAll('.typeIconGlobos')
    .data(root.descendants().slice(1).filter(function(d) {
      return d.height === 1;
    }))
    .enter()
    .append('text')
    .attr('class', 'typeIconGlobos')
    .attr('id', d => 'iconTypeGlobos_' +d.data.id)
    .attr('fill', 'blue')
    .attr("dy", function(d, i) {
     d.titleHeight = (-1 + 0.10) * d.r;
      return d.titleHeight;
    })
    .text(d => getIcon(d.data.typeObject));
// add icon usage risk
  let usageRiskIcon = svg.append('g')
    .attr('id', 'usageRiskIconGlobos')
    .selectAll('.usageRiskGlobos')
    .data(root.descendants().slice(1).filter(function(d) {
      return d.height === 1;
    }))
    .enter()
    .append('text')
    .attr('id', d => 'iconUsageRiskGlobSize_' + d.data.id)
    .attr('class', 'usageRiskGlobos')
    .attr('fill',  d => getUsageRisk(d.data.usageRisk) )
    .style("display",  "none")
    .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r / 10;
      return Math.round(d.fontTitleSize)+"px";
    })
    .attr("dy", function(d, i) {
      d.titleHeight = (-1 + 0.10) * d.r;
      return d.titleHeight;
    })
    .attr("dx", '-1.5em')
    .text(function(d) { return '\uf111'; });
// add icon security risk
  let securityRiskIcon = svg.append('g')
    .attr('id', 'securityRiskGlobos')
    .selectAll('.securityRiskGlobos')
    .data(root.descendants().slice(1).filter(function(d) {
      return d.height === 1;
    }))
    .enter()
    .append('text')
    .attr('id', d => 'iconSecurityGlobSize_' + d.data.id)
    .attr('class', 'securityRiskGlobos')
    .attr('fill', d => getUsageRisk(d.data.criticity))
    .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r / 10;
      return Math.round(d.fontTitleSize)+"px";
    })
   .style("display",  "none")
    .attr("dy", function(d, i) {
      d.titleHeight = (-1 + 0.10) * d.r;
      return d.titleHeight;
    })
    .attr("dx", '1.1em')
    .text(function(d) { return '\uf071'; });
  // zoom global chart buttons
  let scaleAvr = 1;
  $('#zoomIn_button').on('click',function(){
    if(scaleAvr < 2){
      scaleAvr += 0.1;
    }else{
      scaleAvr =2;
    }
    zoomGroups();
  });
  $('#zoomOut_button').on('click',function(){
    if(scaleAvr > 0.5){
      scaleAvr -= 0.1;
    }else{
      scaleAvr =0.5;
    }
    zoomGroups();

  });
  $('#resetSize_button').on('click',function(){
    scaleAvr = 1;
   zoomGroups();
  });
  const zoomGroups =() =>{
    cont.transition().duration(100).style('transform', 'scale('+ scaleAvr + ')');
    d3.select('#typeIconGlobos').transition().duration(100).style('transform', 'scale('+ scaleAvr + ')');
    d3.select('#usageRiskIconGlobos').transition().duration(100).style('transform', 'scale('+ scaleAvr + ')');
    d3.select('#securityRiskGlobos').transition().duration(100).style('transform', 'scale('+ scaleAvr + ')');
  };
  // sunbusrt chart container init
  const width1 = 300;
  let sunburstScale = 0.92;
  g
    .attr('id', 'sunbirstContainer')
    .style('display', 'none')
    .attr("transform", `translate(${width1 / 2},${width1 / 2})`);

  tools.append('text')
    .attr('class', 'tickGlobos')
    .attr('fill', 'red')
    .attr('dy', '1.2em')
    .attr('dx', '-0.3em')
    .on('click', () =>{
      $('#sunbirstContainer').empty();$('#toolsSunburst').css('display', 'none');document.getElementById('globSize_' + model).dispatchEvent(new Event('click'));//zoomTo([root.x, root.y, root.r * 2]);
    })
    .text('\uf057');

  let recordSelected = '';
  recordIcons.append('path')
    .attr('id', 'tickPro')
    .attr('d' , 'M 16.5402 3.678 L 5.3396 14.8786 l -3.9598 -3.9598 c -0.1874 -0.1874 -0.4914 -0.1874 -0.6788 0 L 0.2484 11.3714 c -0.1874 0.1874 -0.1874 0.4914 0 0.6788 l 4.7518 4.7518 c 0.1874 0.1874 0.4914 0.1874 0.6788 0 l 11.9925 -11.9925 c 0.1874 -0.1874 0.1874 -0.4914 0 -0.6788 l -0.4526 -0.4526 c -0.1874 -0.1874 -0.4914 -0.1874 -0.6788 0 z')
    .attr('fill', 'green')
    .attr('transform', 'translate(-45,35)');

  recordIcons.append('path')
    .attr('id', 'tickCube')
    .attr('d' , 'M 16.5402 3.678 L 5.3396 14.8786 l -3.9598 -3.9598 c -0.1874 -0.1874 -0.4914 -0.1874 -0.6788 0 L 0.2484 11.3714 c -0.1874 0.1874 -0.1874 0.4914 0 0.6788 l 4.7518 4.7518 c 0.1874 0.1874 0.4914 0.1874 0.6788 0 l 11.9925 -11.9925 c 0.1874 -0.1874 0.1874 -0.4914 0 -0.6788 l -0.4526 -0.4526 c -0.1874 -0.1874 -0.4914 -0.1874 -0.6788 0 z')
    .attr('fill', 'green')
    .attr('display', 'none')
    .attr('transform', 'translate(75,35)');
  recordIcons
    .append('text')
    .attr('class', 'usageRiskRecordGlobos')
    .attr('dy', '1.3em')
    .attr('dx', '-2.3em')
    .style('cursor', 'pointer')
    .style('pointer-events', 'none')
    .on('click', function (d) {
      sunBurstWithLabels(g,recordSelected, 'dataUsageRisk', tool_tip);
      d3.select('.usageRiskRecordGlobos').style('pointer-events', 'none');
      d3.select('.securityRiskRecordGlobos').style('pointer-events', 'all');
      d3.select('#tickPro').attr('transform', 'translate(-45,35)');
    })
    .text(function(d) { return '\uf111'; });
  recordIcons
    .append('text')
    .attr('class', 'securityRiskRecordGlobos')
    .attr('dy', '1.3em')
    .attr('dx', '1.3em')
    .style('cursor', 'pointer')
    .on('click', function (d) {
      sunBurstWithLabels(g,recordSelected, 'sensibilityField', tool_tip);
      d3.select('.usageRiskRecordGlobos').style('pointer-events', 'all');
      d3.select('.securityRiskRecordGlobos').style('pointer-events', 'none');
      d3.select('#tickPro').attr('transform', 'translate(35,35)');
    })
    .text(function(d) { return '\uf071'; });
  recordIcons
    .append('path')
    .attr('d', 'M 10.7595 0.3375 l -9.36 3.51 c -0.8415 0.315 -1.3995 1.125 -1.3995 2.025 v 10.1295 c 0 0.819 0.4635 1.566 1.1925 1.9305 l 9.36 4.68 c 0.6075 0.306 1.323 0.306 1.9305 0 l 9.36 -4.68 c 0.7335 -0.3645 1.1925 -1.116 1.1925 -1.9305 V 5.8725 c 0 -0.9 -0.558 -1.7055 -1.3995 -2.0205 l -9.36 -3.51 C 11.79 0.153 11.25 0.153 10.7595 0.3375 z m 0.7605 2.025 l 9.36 3.51 v 0.0135 l -9.36 3.8025 l -9.36 -3.8025 v -0.0135 l 9.36 -3.51 z M 2.16 8.217 l 8.28 3.366 v 8.559 l -8.28 -4.14 v -7.785 z m 10.44 11.9205 V 11.583 l 8.28 -3.366 v 7.7805 l -8.28 4.14 z')
    .attr('fill', 'rgb(0, 176, 228)')
    .attr('pointer-events', 'all')
    .on('mouseover', function () {
    d3.select(this).attr('transform', 'translate(70,10) scale(1.2)')
    })
    .on('mouseout', function () {
      d3.select(this).attr('transform', 'translate(70,10) scale(1)')
    })
    .on('click', function () {
     if($('.main-arc').attr('filter')=='none'){
       $('.main-arc').attr('filter', 'url(#shadowGlobeSize)');
       $('#tickCube').attr('display', 'block');
     }  else{
       $('.main-arc').attr('filter', 'none');
       $('#tickCube').attr('display', 'none');
     }
    })
    .attr('transform', 'translate(70,10)');

  const label = svg.append("g")
    .style("font", "10px sans-serif")
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .selectAll("text")
    .attr('class', 'labelZoom')
    .data(root.descendants())
    .join("text")
    .attr('dy', d => d.data.type=='object'? '-3em' : '0em')
    .style("fill-opacity", d => d.parent === root ? 1 : 0)
    .attr('id', d => 'labelGlobos_' + d.data.id)
    .style("display", d => d.parent === root ? "inline" : "none")
    .text(d => d.data.name);

  zoomTo([root.x, root.y, root.r * 2]);

  function zoomTo(v) {
    const k = width / v[2];

    view = v;
    label.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
    usageRiskIcon.style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r * k / 10;
      return Math.round(d.fontTitleSize)+"px";
    }).attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`)
      .attr("dy", function(d) {
      d.titleHeight = (-1 + 0.10) * d.r * k;
      return d.titleHeight;
      });
    securityRiskIcon .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r * k / 10;
      return Math.round(d.fontTitleSize)+"px";
    }).attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`)
      .attr("dy", function(d) {
        d.titleHeight = (-1 + 0.10) * d.r * k;
        return d.titleHeight;
      });
    typeIcon.style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r * k / 10;
      return Math.round(d.fontTitleSize)+"px";
    }).attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`)
      .attr("dy", function(d) {
        d.titleHeight = (-1 + 0.10) * d.r * k;
        return d.titleHeight;
      });
    node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
    node.attr("r", d => d.r * k);
    g.attr("transform", 'translate(0,0) scale(' + sunburstScale + ')');
  }

  function zoom(d) {
    if (d.depth === 0) {
      return;
    }
    // sunbirst draw
    if (d.depth > 1 && $('#globSizeChart').css('display') == 'block' ) {
      let type= null;
      // usagerisk submenu selected
      if($('#submenu-classif').css('display') == 'block'){
         type ='dataUsageRisk';
        $('.usageRiskRecordGlobos').css('display', 'block').css('pointer-events', 'none');
        $('.securityRiskRecordGlobos').css('pointer-events', 'all');
        d3.select('#tickPro').attr('transform', 'translate(-45,35)');
      }else{
        $('.usageRiskRecordGlobos').css('display', 'none');
      }
      // security risk submenu selected
      if($('#submenu-security').css('display') == 'block'){
        type ='dataUsageRisk';
        $('.securityRiskRecordGlobos').css('display', 'block');''
        if($('#usageContainer' + model).css('display') == 'none'){
          type ='sensibilityField';
          $('.usageRiskRecordGlobos').css('pointer-events', 'all');
          $('.securityRiskRecordGlobos').css('pointer-events', 'none');
          d3.select('#tickPro').attr('transform', 'translate(35,35)');
        }
      }else{
        $('.securityRiskRecordGlobos').css('display', 'none');
      }
      g.attr("transform", 'translate(0,0) scale(0.52)');
      g.transition().duration(10).style('opacity', '0');
      tools.transition().duration(10).style('opacity', '0');
       recordIcons.transition().duration(10).style('opacity', '0');
      recordSelected = d.data.id;
       sunBurstWithLabels(g,recordSelected, type, tool_tip);
    }
    //sunbirst end draw
    focus = d;
    if (d.depth == 0 || d.depth == 1 ) {
      g.style('display', 'none');
      tools.style('display', 'none');
      d3.select('#labelGlobos_' + d.data.id).style('display', 'none').style('fill-opacity', '0');
      recordIcons.style('display', 'none').style('opacity', '0');

    } else {
      g.transition().duration(100).style('display', 'block');
      tools.transition().duration(100).style('display', 'block');
    }
    const transition = svg.transition()
      .duration(d3.event.altKey ? 7500 : 750)
      .tween("zoom", d => {
        const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
        return t => zoomTo(i(t));
      });

    label
      .filter(function (d) {
        return d.parent === focus || this.style.display === "inline";
      })
      .transition(transition)
      .style("fill-opacity", d => d.parent === focus ? 1 : 0)
      .on("start", function (d) {
        if (d.parent === focus) {
          this.style.display = "inline";
        }
      })
      .on("end", function (d) {
        if (d.parent !== focus) {
          this.style.display = "none";
        }
      });
  }
};
