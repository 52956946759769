import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {UserMessageRoutingModule} from "./user-message-routing.module";
import {UserMessageComponent} from "./user-message/user-message.component";
import {OrderModule} from "ngx-order-pipe";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import {NgxPaginationModule} from "ngx-pagination";
import {FormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
  declarations: [UserMessageComponent],
    imports: [
        CommonModule,
        UserMessageRoutingModule,
        OrderModule,
        Ng2SearchPipeModule,
        NgxPaginationModule,
        FormsModule,
        NgSelectModule,
        TranslateModule
    ]
})
export class UserMessageModule { }
