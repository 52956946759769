import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {CustomValidators} from '../../custom-validators';
import {Router} from '@angular/router';
import {RoleService} from "../../services/users/role.service";
import { TranslateService } from '@ngx-translate/core';

declare const swal: any;
declare const $: any;
declare const VANTA: any;

@Component({
  selector: 'app-temp-user',
  templateUrl: './temp-user.component.html',
  styleUrls: ['./temp-user.component.sass']
})
export class TempUserComponent implements OnInit {
  public frmtemp: FormGroup;
  password: string;
  confirmPassword: string;
  expired = false;
  token : string;
  lang: string;
  constructor(private router: Router, private fb: FormBuilder,private roleService: RoleService, private translate: TranslateService) {
    this.frmtemp = this.initForm();
  }

  ngOnInit() {
    localStorage.clear();
    sessionStorage.clear();
    // VANTA.RIPPLE({
    //   el: '#tempo',
    //   color: 0x3657,
    //   mouseControls: true,
    //   touchControls: true,
    //   minHeight: 200.00,
    //   minWidth: 200.00,
    //   scale: 1.00,
    //   scaleMobile: 1.00
    // });
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
  const that = this;
  setInterval(() => {
    this.lang = localStorage.getItem('lang') || 'en';
    this.translate.use(this.lang);
  }, 10);
  }

  initForm(): FormGroup {
    return this.frmtemp = this.fb.group(
      {
        password: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true
              }
            ),
            Validators.minLength(8)
          ])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }
  onSubmit() {
    const formValue = this.frmtemp.value;
    this.password =  formValue['password'];
    this.confirmPassword =  formValue['confirmPassword'];
    const r = this.router.url.split('/complete/')[1];

    const token = r.split('/')[0];
    const user = r.split('/')[1];
    const postData = 'password=' + this.password + '&password1=' +
      this.confirmPassword + '&token=' + token + '&user=' + user;
    this.roleService.enableUser(postData).subscribe(
      d => { swal({
        title: this.lang === 'en' ? 'Password added successfully!' : 'Mot de passe ajouté avec succès !',
        text: this.lang === 'en' ? 'Your account is now active and you can log in.' : 'Votre compte est maintenant actif et vous pouvez vous connecter.',       
        timer: 5000,
        showConfirmButton: false
      });   
       this.router.navigateByUrl(''); },
      err => {
        if (err.error.token != null) {
        this.expired = true;
        this.token = err.error.token;
        }
      }
      //   $('#resend_mail').click(function (e) {
      //     e.preventDefault;
      //   });
      //    swal({
      //   title: "error!",
      //   text: err.error.response,
      //   html: true
      // }); }
    );
    // console.log(postData);
  }
  expire(){
    const r = this.router.url.split('/complete/')[1];
    const user = r.split('/')[1];
    const port = window.location.port;
    const postData = 'user=' + user + '&port=' + port ;

    this.roleService.enableUserFinal(this.token, postData).subscribe(
     d =>{   swal({
      title: this.lang === 'en' ? 'Success' : 'Succès',
      text: this.lang === 'en' ? '<p>A new email with a new link to activate your account has been sent successfully to your email</p>' : '<p>Un nouvel e-mail avec un nouveau lien pour activer votre compte a été envoyé avec succès à votre adresse e-mail</p>',
      
         html: true
       });
     },
     err =>{console.log(err);}
   );
  }
}
