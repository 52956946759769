import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MetadataTemplateService} from '../../services/metadata/metadatatemplate.service';
import {formatDate} from '@angular/common';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
declare const $: any;
@Component({
  selector: 'app-metadata-new-field-template',
  templateUrl: './metadata-new-field-template.component.html',
  styleUrls: ['./metadata-new-field-template.component.sass']
})
export class MetadataNewFieldTemplateComponent implements OnInit, AfterViewInit {

  constructor(private metaService: MetadataTemplateService, private fb: FormBuilder, private dynamicScriptLoader: DynamicScriptLoaderService) { }
    @Input() objName: string;
// @Output() metaAp= new EventEmitter<any>()
// metaApp:any;
    isRead = true;
    isEdit = false;
// metadata function
    fieldMDFselect = [];
    fieldMDFtext = [];
    fieldMDFarea = [];
    fieldMDFemail = [];
    fieldMDFcolor = [];
    fieldMDFtel = [];
    fieldMDFdate = [];
    metaNewField = [];
    listUpdated = [];
    // editMeta: FormGroup;
  ngOnInit(): void {
      // setTimeout(() => {
      //     this.startScriptTypeDateMDFnew();
      // }, 1000);
  }
  ngAfterViewInit(): void {
      setTimeout(() => {
          // this.fieldMDFdate.forEach(elem => {
          //     this.startScriptNewTypeDate(elem.idMDF);
          // });
      }, 1000);
  }

    CreateNewField(meta) {
        this.fieldMDFselect = [];
        this.fieldMDFtext = [];
        this.fieldMDFarea = [];
        this.fieldMDFcolor = [];
        this.fieldMDFemail = [];
        this.fieldMDFtel = [];
        this.fieldMDFdate = [];
        meta.forEach(element => {
            if (element.typeMDF == 'select') {
                this.fieldMDFselect.push(element);
            } else if (element.typeMDF == 'text') {
                this.fieldMDFtext.push(element);
            } else if (element.typeMDF == 'email') {
                this.fieldMDFemail.push(element);
            } else if (element.typeMDF == 'color') {
                this.fieldMDFcolor.push(element);
            } else if (element.typeMDF == 'textarea') {
                this.fieldMDFarea.push(element);
            } else if (element.typeMDF == 'tel') {
                this.fieldMDFtel.push(element);
            } else if (element.typeMDF == 'date') {
                this.fieldMDFdate.push(element);
            }
        });
    }
    async startScriptNewTypeDate(idMDF) {
        console.log('init date');
        await this.dynamicScriptLoader.load('form.min').then(data => {
            const that = this;
            $('#date-new_' + idMDF).bootstrapMaterialDatePicker(                {
                format: 'YYYY/MM/D',
                clearButton: true,
                weekStart: 1,
                time: false,
                autoclose: true,
            })
                .on('change', function(e, date) {
                    var dateValue = '';
                    if ($(date).attr('_d') != undefined) {
                        dateValue = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
                    } else {
                        dateValue = '';
                    }
                    const index = that.metaNewField.findIndex(o => o.idMDF == idMDF);
                    if (index !== -1) {
                        that.metaNewField[index].selectedValueMDF = dateValue;
                    }
                    // console.log(that.fieldMDFdate);
                });
        }).catch(error => console.error(error));
    }
    onClickNewMD() {
        if ($('#MD_').html() == '<i class="fas fa-angle-up"></i>') {
            $('#MD_').html('<i class="fas fa-angle-down"></i>');
            $('#Menu_Add_MD_').hide();
        } else {
            $('#Menu_Add_MD_').show();
            // this.startScriptTypeDateMDFnew();
            $('#MD_').html('<i class="fas fa-angle-up"></i>');
        }
    }
    // async startScriptTypeDateMDFnew() {
    //     await this.dynamicScriptLoader.load('form.min').then(data => {
    //         const that = this;
    //         $("input[id^='date-new_']").bootstrapMaterialDatePicker(                {
    //             format: 'YYYY/MM/D',
    //             clearButton: true,
    //             weekStart: 1,
    //             time: false,
    //             autoclose: true,
    //         });
    //     }).catch(error => console.error(error));
    // }
    /// sort new fields by selection type
    compareNewField( a, b ) {
        if ( a.usedMDF > b.usedMDF ) {
            return -1;
        }
        if ( a.usedMDF < b.usedMDF ) {
            return 1;
        }
        return 0;
    }

    sortListNewFieldArea() {
        setTimeout(() => {
            this.fieldMDFarea.sort( this.compareNewField );
        }, 500);
    }
    sortListNewFieldText() {
        setTimeout(() => {
            this.fieldMDFtext.sort( this.compareNewField );
            // console.log(this.fieldMDFtext);
        }, 500);
    }
    sortListNewFieldEmail() {
        setTimeout(() => {
            this.fieldMDFemail.sort( this.compareNewField );
        }, 500);
    }
    sortListNewFieldTel() {
        setTimeout(() => {
            this.fieldMDFtel.sort( this.compareNewField );
        }, 500);
    }
    sortListNewFieldColor() {
        setTimeout(() => {
            this.fieldMDFcolor.sort( this.compareNewField );
        }, 500);
    }
    sortListNewFieldDate() {
        setTimeout(() => {
            this.fieldMDFdate.sort( this.compareNewField );
        }, 500);
    }
    sortListNewFieldSelect() {
        setTimeout(() => {
            this.fieldMDFselect.sort( this.compareNewField );
        }, 500);
    }
    onGetMetaNewField() {
        this.metaNewField = [];
        console.log('not update its new');
        this.metaService.getMetaObj('new', this.objName).subscribe(
            data => {
                this.metaNewField = data;
            },
            err => {
                console.error(err);
            }, () => {
                this.CreateNewField(this.metaNewField);
            });
    }

    onUpdateMetaNewField() {
        return this.metaNewField;
    }
  //   updateItemSelectNewField(e, id) {
  //       this.metaNewField.map(element => {
  //         if (element.id == id) {
  //           element.selectedValue = e;
  //         }
  //       });
  // }
    // updateItemNewField(e, id) {
    //     console.log(id);
    //     console.log('---------');
    //     console.log(this.metaNewField);
    //
    //     console.log('---------');
    //     console.log(this.fieldMDFarea);
        // this.metaNewField.map(element => {
        //     if (element.id == id) {
        //         element.selectedValue = e.target.value;
        //     }
        // });
    // }
    // updateItemCheckNewField(e, id, ini) {
    //     var tabMeta = this.fieldMDR;
    //     var tabM = [];
    //     tabM = this.editMeta.get('metaApp').value;
    //     if (tabM.length != 0) {
    //         var tabmetaf = tabM;
    //         tabmetaf.forEach(el => {
    //             if (el.idfield == id) {
    //                 var fIndex = tabmetaf.indexOf(el);
    //                 tabmetaf.splice(fIndex, 1);
    //             }
    //         });
    //     } else {
    //         tabmetaf = [];
    //     }
    //     tabMeta.forEach(element => {
    //         var sv = e.target.value;
    //         if (element.id == id) {
    //             var elem = {'idfield': element.id, 'idvalue': ini, 'selectedvalue': sv};
    //             tabmetaf.push(elem);
    //         }
    //     });
    //     this.editMeta.get('metaApp').patchValue(tabmetaf);
    // }
}
