import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {ConservationPeriod} from '../../drm/retention-period/ConservationPeriod';

@Injectable({
  providedIn: 'root'
})
export class DrmRetentionService {
  username: string;
  userId: string;
  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }

  constructor(private http: HttpClient, private router: Router) { }

  // get Role CRUD retention
  getListRoleRetention(): Observable<any> {
    this.userId = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/drm-retention/getListRoleRetention/' + this.userId, this.getOptions1());
  }
  // add data per record by data model liste data (retention period)
  addRetentionRecord(country: string, stream: string, current: string, term: string,
                     duration1: string, duration2: string, description: string) {
    this.username = getCookieValue('username');
    // const data = 'data=' + JSON.stringify(rec);
    return this.http.get(Shared.API_URL + '/api/drm-retention/addRetentionByRecord/'
      + country + '/' + stream + '/'
      + current + '/' + term + '/'
      + duration1 + '/' + duration2 + '/'
      + description + '/' + this.username, this.getOptions1());
  }
  // update data per record by data model liste data (retention period)
  setUpdateRecordDataMap(id: string, country: string, stream: string, current: string, term: string,
                         duration1: string, duration2: string, description) {
    this.username = getCookieValue('username');
    // const data = 'data=' + JSON.stringify(rec);
    return this.http.get(Shared.API_URL + '/api/drm-retention/setUpdateRecordDataMap/'
      + id + '/' + country + '/' + stream + '/'
      + current + '/' + term + '/'
      + duration1 + '/' + duration2 + '/'
      + description + '/' + this.username, this.getOptions1());
  }
  // update data conservation period by import fron excel
  setUpdateCPxlxs(listCPs: ConservationPeriod[]) {
    this.username = getCookieValue('username');
    const data = "data=" + JSON.stringify(listCPs);
    return this.http.put(Shared.API_URL + '/api/drm-retention/setUpdateCPxlxs/' + this.username, data, this.getOptions());
  }
  // get list data name country and alpha3code country
  getListCodeCountry(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/drm-retention/getListCodeCountry', this.getOptions1());
  }
  // get list data for collapcible table
  getListRetention(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/drm-retention/getListRetention', this.getOptions1());
  }
  // delete record from list data map
  setRemoveRecordDataMap(id: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/drm-retention/setRemoveRecordDataMap/' + id, this.getOptions1());
  }
  // get count country and domain
  getCountDistStream(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/drm-retention/getCountDistStream', this.getOptions1());
  }
  // get top ten domain
  getTopTenStream(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/drm-retention/getTopTenStream', this.getOptions1());
  }
  // get data to MAPS
  getDataToMaps(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/drm-retention/getDataToMaps', this.getOptions1());
  }
}
