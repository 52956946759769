import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class TipsService {

  constructor(private http: HttpClient, private router: Router) { }
  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  // get all tips format JSON
  getAllTipsDesc(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/tips/getAllTipsDesc', this.getOptions());
  }
  // get tips per name format JSON
  getTipsPerName(name: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/tips/getTipsPerName/' + name, this.getOptions());
  }
  // change selected type description per name
  updateTypeDescTipsPerName(dataUpdate: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdate);
    return this.http.put<any>(Shared.API_URL + '/api/tips/updateTypeDescTipsPerName', data, this.getOptions());
  }
  // update description type text
  updateTextDescTipsPerName(dataUpdate: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdate);
    return this.http.put<any>(Shared.API_URL + '/api/tips/updateTextDescTipsPerName', data, this.getOptions());
  }
}
