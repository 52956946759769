import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { RoleService } from '../../services/users/role.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, NgModel, Validators } from '@angular/forms';
import {cancelTextToVoice, getCookieValue, Shared, textToVoice} from '../../shared';
import { NgSelectModule, NgOption } from '@ng-select/ng-select';
import { RoleJson } from './roleJson';
import { AdministrationJson } from './administrationJson';
import { StructureJson } from './structureJson';
import { ObjmodelJson } from './objmodelJson';
import { SettingsRole } from './SettingsRole';
import { DashBoardRole } from './DashBoardRole';
import { ApplicationDef } from './ApplicationDef';
import { ApplicationFlow } from './ApplicationFlow';
import { AppFlowRole } from './AppFlowRole';
import { ClassificationRole } from './classificationRole';
import { RulesRole } from './RulesRole';
import { RoleRegulationJson } from './RoleRegulationJson';
import { modelAdministration } from './ModelAdministration';
import { WizardComponent } from 'angular-archwizard';
import { DataLifeCycleJson } from './DataLifeCycleJson';
import { LifeCycleJson } from './LifeCycleJson';
import { MetaRole } from './MetaRole';
import Swal from 'sweetalert2';
import { RouterhistoryService } from '../../services/appService/routerhistory.service';
import { TranslateService } from '@ngx-translate/core';
import { ArchiveIdJson } from './ArchiveIdJson';
import { ArchiveJson } from './ArchiveJson';

declare const $: any;
declare const swal: any;
@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.scss']
})
export class UserRolesComponent implements OnInit, AfterViewInit {
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  lang: string;
  frmRoleSignup: FormGroup;
  frmRoleSignup2: FormGroup;
  frmRoleSignup3: FormGroup;
  frmRoleSignup4: FormGroup;

  roleForm: FormGroup;
  RoleName: string;
  roleUpload = false;
  postData: string;
  dataList: any[];
  allApplications: any;
  AppsEnvBase: any;
  AppsEnvBaseRep: any;
  adminApp: any;
  applications: any;
  adminEnv = [{}];
  adminBase = [{}];
  create = [{}];
  read = [{}];
  update = [{}];
  delete = [{}];

  structApp: any;
  appDef: any;
  structEnv = [{}];
  structBase = [{}];
  structReport = [{}];
  readStruct = [{}];
  updateStruct = [{}];
  deleteStruct = [{}];
  createStruct = [{}];
  readDash = [{}];
  createAppDef = [{}];
  readAppDef = [{}];
  updateAppDef = [{}];
  deleteAppDef = [{}];

  createFlow = [{}];
  readFlow = [{}];
  updateFlow = [{}];
  deleteFlow = [{}];

  modelApp: any;
  objectModel = [{}];
  selectModel = [{}];
  createMd = [{}];
  updateMd = [{}];
  deleteMd = [{}];
  // updateModel = [{}];
  // deleteModel = [{}];

  allApplicationsModel: any;
  AppsObjectModel: any;
  applicationDefs: any;
  applicationFlows: any;
  createUser: any;
  readUser: any;
  updateUser: any;
  deleteUser: any;

  createRole: any;
  readRole: any;
  updateRole: any;
  deleteRole: any;
  nameError = false;
  nameSuccess = false;
  editMode = false;
  id: string;
  super = false;
  appDash: any;
  appFlow: any;
  createApp = false;
  readApp = false;
  updateApp = false;
  deleteApp = false;
  createDataFlow = false;
  readDataFlow = false;
  updateDataFlow = false;
  deleteDataFlow = false;
  // classification role
  classificationApps: any;
  classificationModels = [];
  classApp = [];
  classificationModelsApp: any;
  usageRiskCheck = [{}];
  securityRiskReadCheck = [{}];
  securityRiskWriteCheck = [{}];
  securityRiskUpdateCheck = [{}];
  RulesAppsSelected: any;
  rulesModel = [];
  rulesApp = [];
  appsRulesApps: any;
  adminAppModel: any;
  createAdminModel = [{}];
  updateAdminModel = [{}];
  deleteAdminModel = [{}];
  AppsModels: any;
  readAdminModel = [{}];
  allApplicationsReport: any;
  allApplicationsLifeCycle: any;
  LifeCycleApp: any;
  AppsLifeCycle: any;
  LifeCycle = [{}];
  createLifeCycle = [{}];
  updateLifeCycle = [{}];
  deleteLifeCycle = [{}];
  createMeta: any;
  readMeta = true;
  updateMeta: any;
  deleteMeta: any;
  createProject = [{}];
  readProject = [{}];
  updateProject = [{}];
  deleteProject = [{}];
  canDeleteVersion = false;
  canDeleteSetting = false;
  canDeleteProject = false;
  versionAdmin = false;
  securityRiskDeleteCheck = [{}];
  securityAppsSelected = [];
  securityApp = [];
  projectApp = [];
  selectedGlossaries = [];
  createPeriod = false;
  deletePeriod = false;
  readPeriod = true;
  updatePeriod = true;
  updateSecurity = [{}];
  applicationProject: any;
  createDesigner = false;
  readDesigner = true;
  updateDesigner = false;
  deleteDesigner = false;
  canDeleteDesigner = false;
  canDeletePeriod: boolean;
  canDeleteMeta: boolean;
  createStack = false;
  updateStack = false;
  deleteStack = false;
  readStack = false;
  createHolder = false;
  updateHolder = false;
  deleteHolder = false;
  readHolder = false;
  sendingMeta = false;
  sendingProject = false;
  sendingModel = false;
  sendingLifeCycle = false;
  sendingSetting = false;
  sendingDrm = false;
  sendingDesigner = false;
  // dimensions = [
  //   // Governance example
  //   {
  //     "type": "Governance dimension",
  //     "name": "Governance role",
  //     "desc": "Governance role",
  //     "date": "02/02/2012",
  //     "edate": "02/02/2042",
  //     "status": "Enabled",
  //     "category":[{"categoryName":"Data governance Operation","elementName":["opti 1","opti 2"]},
  //       {"categoryName":"Data governance Council","elementName":["opti 1"]},
  //       {"categoryName":"Data governance Steering Commitee ","elementName":["lineage flow1"," lineage flow 2"]}]
  //   }];
  dimensions = [];
  loadingDimension = false;
  categoryDimensions = [];
  virtualDimensions = [];
  // business term
  createBusiness = false;
  readBusiness = true;
  updateBusiness = false;
  deleteBusiness = false;
  sendingBusiness = false;
  canDeleteBusiness = false;
  // country
  createCountry = false;
  readCountry = true;
  updateCountry = false;
  deleteCountry = false;
  sendingCountry = false;
  canDeleteCountry = false;
  // infra
  createInfra = false;
  readInfra = true;
  updateInfra = false;
  deleteInfra = false;
  sendingInfra = false;
  canDeleteInfra = false;
  // Entity
  createEntity = false;
  readEntity = true;
  updateEntity = false;
  deleteEntity = false;
  sendingEntity = false;
  canDeleteEntity = false;
  // classification definition
  createClassifDef = false;
  readClassifDef = false;
  updateClassifDef = false;
  deleteClassifDef = false;
  // dimension
  createDimension = false;
  readDimension = false;
  updateDimension = false;
  deleteDimension = false;
  // diag software
  createDiagSoft = false;
  readDiagSoft = true;
  updateDiagSoft = false;
  deleteDiagSoft = false;
  // data lineage
  createDataLineage = false;
  readDataLineage = true;
  updateDataLineage = false;
  deleteDataLineage = false;
  // data catalogue
  allApplicationsCatalogue: any;
  catalogueApp: any;
  AppsCatalogue: any;
  catalogue = [{}];
  selectCatalogue = [{}];
  createCt = [{}];
  updateCt = [{}];
  deleteCt = [{}];
  // functional map
  createFunctionalMap = false;
  readFunctionalMap = true;
  updateFunctionalMap = false;
  deleteFunctionalMap = false;
  catalogUpdate = [{}];
  modelUpdate = [{}];
  // archiveId
  archiveIdBases = [{}];
  archiveIdApp: any;
  AppsArchiveId: any;
  deleteArchiveId = [{}];
  sendingArchiveId = false;
  allApplicationsArchives: any;
  glossaries = [];
  // Template
  // createTemplate = false;
  // readTemplate = true;
  // updateTemplate = false;
  // deleteTemplate = false;
  // sendingTemplate = false;
  // canDeleteTemplate = false;
  createGlossary = false;
  readGlossary = [{}];
  updateGlossary = [{}];
  deleteGlossary = [{}];
  constructor(private roleService: RoleService, private router: Router, private fb: FormBuilder, private routerHistoryService: RouterhistoryService, private translate: TranslateService) {
    this.frmRoleSignup = this.initForm();
    this.frmRoleSignup2 = this.initForm2();
    this.frmRoleSignup3 = this.initForm3();
    this.frmRoleSignup4 = this.initForm4();
  }

  ngOnInit() {
    const that = this;
    $('#roleModal').on('hidden.bs.modal', () => {
      this.stopVoice();
    });
    this.ongetApplications();
    this.GetRole();
    this.onGetListApplicationsReport();
    this.onGetClassificationApps();
    this.onGetAppsrules();
    this.getListApplicationsReportModel();
    this.getGlossaries();
    this.roleService.getRefreshNeeded().subscribe(
      () => {
        this.GetRole();
      }
    );
    this.roleService.getRefreshDelete().subscribe(
      () => {
        this.GetRole();
      }
    );
    $('#super').change(function() {
      that.super = this.checked;
      that.versionAdmin = this.checked;
    });
    // meta checkbox
    $('#read_meta').change(function() {
      if (!this.checked && that.createMeta || that.updateMeta || that.deleteMeta) {
        that.readMeta = true;
      }
    });
    $('#create_meta').change(function() {
      if (this.checked) {
        that.readMeta = true;
      }
    });
    $('#update_meta').change(function() {
      if (this.checked) {
        that.readMeta = true;
      }
    });
    $('#delete_meta').change(function() {
      if (this.checked) {
        that.readMeta = true;
      }
    });
    // retention checkbox
    $('#read_period').change(function() {
      if (!this.checked && that.createPeriod || that.updatePeriod || that.deletePeriod) {
        that.readPeriod = true;
      }
    });
    $('#create_period').change(function() {
      if (this.checked) {
        that.readPeriod = true;
      }
    });
    $('#update_period').change(function() {
      if (this.checked) {
        that.readPeriod = true;
      }
    });
    $('#delete_period').change(function() {
      if (this.checked) {
        that.readPeriod = true;
      }
    });
    //application & dataFlow checkbox
    $('#read_app').change(function() {
      if (!this.checked && that.createApp || that.updateApp || that.deleteApp) {
        that.readApp = true;
      }
    });
    $('#create_app').change(function() {
      if (this.checked) {
        that.readApp = true;
      }
    });
    $('#update_app').change(function() {
      if (this.checked) {
        that.readApp = true;
      }
    });
    $('#delete_app').change(function() {
      if (this.checked) {
        that.readApp = true;
      }
    });
    $('#read_flow').change(function() {
      if (!this.checked && that.createDataFlow || that.updateDataFlow || that.deleteDataFlow) {
        that.readDataFlow = true;
      }
    });
    $('#create_flow').change(function() {
      if (this.checked) {
        that.readDataFlow = true;
      }
    });
    $('#update_flow').change(function() {
      if (this.checked) {
        that.readDataFlow = true;
      }
    });
    $('#delete_flow').change(function() {
      if (this.checked) {
        that.readDataFlow = true;
      }
    });
    //setting checkbox
    $('#read_user').change(function() {
      if (!this.checked && that.createUser || that.updateUser || that.deleteUser) {
        that.readUser = true;
      }
    });
    $('#create_user').change(function() {
      if (this.checked) {
        that.readUser = true;
      }
    });
    $('#update_user').change(function() {
      if (this.checked) {
        that.readUser = true;
      }
    });
    $('#delete_user').change(function() {
      if (this.checked) {
        that.readUser = true;
      }
    });
    //role checkbox
    $('#read_role').change(function() {
      if (!this.checked && that.createRole || that.updateRole || that.deleteRole) {
        that.readRole = true;
      }
    });
    $('#create_role').change(function() {
      if (this.checked) {
        that.readRole = true;
      }
    });
    $('#update_role').change(function() {
      if (this.checked) {
        that.readRole = true;
      }
    });
    $('#delete_role').change(function() {
      if (this.checked) {
        that.readRole = true;
      }
    });
    //data role checkbox
    $('#read_stack').change(function() {
      if (!this.checked && that.createStack || that.updateStack || that.deleteStack) {
        that.readStack = true;
      }
    });
    $('#create_stack').change(function() {
      if (this.checked) {
        that.readStack = true;
      }
    });
    $('#update_stack').change(function() {
      if (this.checked) {
        that.readStack = true;
      }
    });
    $('#delete_stack').change(function() {
      if (this.checked) {
        that.readStack = true;
      }
    });
    //stakeHolders checkbox
    $('#read_holder').change(function() {
      if (!this.checked && that.createHolder || that.updateHolder || that.deleteHolder) {
        that.readHolder = true;
      }
    });
    $('#create_holder').change(function () {
      if (this.checked) {
        that.readHolder = true;
      }
    });
    $('#update_holder').change(function () {
      if (this.checked) {
        that.readHolder = true;
      }
    });
    $('#delete_holder').change(function () {
      if (this.checked) {
        that.readHolder = true;
      }
    });
    //model designer checkbox
    $('#read_designer').change(function () {
      if (!this.checked && that.createDesigner || that.updateDesigner || that.deleteDesigner) {
        that.readDesigner = true;
      }
    });
    $('#create_designer').change(function () {
      if (this.checked) {
        that.readDesigner = true;
      }
    });
    $('#update_designer').change(function () {
      if (this.checked) {
        that.readDesigner = true;
      }
    });
    $('#delete_designer').change(function () {
      if (this.checked) {
        that.readDesigner = true;
      }
    });
    //export diagrams architect
    $('#read_diag_soft').change(function () {
      if (!this.checked && that.createDiagSoft || that.updateDiagSoft || that.deleteDiagSoft) {
        that.readDiagSoft = true;
      }
    });
    $('#create_diag_soft').change(function () {
      if (this.checked) {
        that.readDiagSoft = true;
      }
    });
    $('#update_diag_soft').change(function () {
      if (this.checked) {
        that.readDiagSoft = true;
      }
    });
    $('#delete_diag_soft').change(function () {
      if (this.checked) {
        that.readDiagSoft = true;
      }
    });
    // infrastructure
    $('#read_infra').change(function () {
      if (!this.checked && that.createInfra || that.updateInfra || that.deleteInfra) {
        that.readInfra = true;
      }
    });
    $('#create_infra').change(function () {
      if (this.checked) {
        that.readInfra = true;
      }
    });
    $('#update_infra').change(function () {
      if (this.checked) {
        that.readInfra = true;
      }
    });
    $('#delete_infra').change(function () {
      if (this.checked) {
        that.readInfra = true;
      }
    });
    // entity
    $('#read_entity').change(function () {
      if (!this.checked && that.createEntity || that.updateEntity || that.deleteEntity) {
        that.readEntity = true;
      }
    });
    $('#create_entity').change(function () {
      if (this.checked) {
        that.readEntity = true;
      }
    });
    $('#update_entity').change(function () {
      if (this.checked) {
        that.readEntity = true;
      }
    });
    $('#delete_entity').change(function () {
      if (this.checked) {
        that.readEntity = true;
      }
    });
    // country
    $('#read_country').change(function () {
      if (!this.checked && that.createCountry || that.updateCountry || that.deleteCountry) {
        that.readCountry = true;
      }
    });
    $('#create_country').change(function () {
      if (this.checked) {
        that.readCountry = true;
      }
    });
    $('#update_country').change(function () {
      if (this.checked) {
        that.readCountry = true;
      }
    });
    $('#delete_country').change(function () {
      if (this.checked) {
        that.readCountry = true;
      }
    });
    // dimension
    $('#read_dimension').change(function () {
      if (!this.checked && that.createDimension || that.updateDimension || that.deleteDimension) {
        that.readDimension = true;
      }
    });
    $('#create_dimension').change(function () {
      if (this.checked) {
        that.readDimension = true;
      }
    });
    $('#update_dimension').change(function () {
      if (this.checked) {
        that.readDimension = true;
      }
    });
    $('#delete_dimension').change(function () {
      if (this.checked) {
        that.readDimension = true;
      }
    });
    // business term
    $('#read_businessTerm').change(function () {
      if (!this.checked && that.createBusiness || that.updateBusiness || that.deleteBusiness) {
        that.readBusiness = true;
      }
    });
    $('#create_businessTerm').change(function () {
      if (this.checked) {
        that.readBusiness = true;
      }
    });
    $('#update_businessTerm').change(function () {
      if (this.checked) {
        that.readBusiness = true;
      }
    });
    $('#delete_businessTerm').change(function () {
      if (this.checked) {
        that.readBusiness = true;
      }
    });
    // classif definition
    $('#read_classif_def').change(function () {
      if (!this.checked && that.createClassifDef || that.updateClassifDef || that.deleteClassifDef) {
        that.readClassifDef = true;
      }
    });
    $('#create_classif_def').change(function () {
      if (this.checked) {
        that.readClassifDef = true;
      }
    });
    $('#update_classif_def').change(function () {
      if (this.checked) {
        that.readClassifDef = true;
      }
    });
    $('#delete_classif_def').change(function () {
      if (this.checked) {
        that.readClassifDef = true;
      }
    });
    // data Lineage
    $('#read_data_lineage').change(function () {
      if (!this.checked && that.createDataLineage || that.updateDataLineage || that.deleteDataLineage) {
        that.readDataLineage = true;
      }
    });
    $('#create_data_lineage').change(function () {
      if (this.checked) {
        that.readDataLineage = true;
      }
    });
    $('#update_data_lineage').change(function () {
      if (this.checked) {
        that.readDataLineage = true;
      }
    });
    $('#delete_data_lineage').change(function () {
      if (this.checked) {
        that.readDataLineage = true;
      }
    });
    // functional map
    $('#read_functional-map').change(function () {
      if (!this.checked && that.createFunctionalMap || that.updateFunctionalMap || that.deleteFunctionalMap) {
        that.readFunctionalMap = true;
      }
    });
    $('#create_functional-map').change(function () {
      if (this.checked) {
        that.readFunctionalMap = true;
      }
    });
    $('#update_functional-map').change(function () {
      if (this.checked) {
        that.readFunctionalMap = true;
      }
    });
    $('#delete_functional-map').change(function () {
      if (this.checked) {
        that.readFunctionalMap = true;
      }
    });
    // template
    // $('#read_template').change(function() {
    //   if (!this.checked && that.createTemplate || that.updateTemplate || that.deleteTemplate) {
    //     that.readTemplate = true;
    //   }
    // });
    // $('#create_template').change(function() {
    //   if (this.checked) {
    //     that.readTemplate = true;
    //   }
    // });
    // $('#update_template').change(function() {
    //   if (this.checked) {
    //     that.readTemplate = true;
    //   }
    // });
    // $('#delete_template').change(function() {
    //   if (this.checked) {
    //     that.readTemplate = true;
    //   }
    // });
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.showTipsRoles();
    }, 1000);
  }
  showTipsRoles() {
    const menu = localStorage.getItem('dataSoumenu');
    const tip = localStorage.getItem('dataTips');
    const firstShow = localStorage.getItem('dontShowTipsRoles');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsRoles') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsRoles', 'true');
        setTimeout(() => {
          $('#tipsCompRoles').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsRoles') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsRoles', 'true');
          $('#tipsCompRoles').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips Roles
  onUpdateTipsRoles() {
    let tipsRoles;
    if ($('#checked_show_roles').is(':checked')) {
      tipsRoles = false;
    } else {
      tipsRoles = true;
    }
    const dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsRoles,
      dataMenu: 'Settings',
      dataSousmenu: 'roles'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  initForm(): FormGroup {
    return this.roleForm = this.fb.group(
      {
        nameRole: [
          null,
          Validators.compose([Validators.required])
        ],
        // dimensionRole: [
        //   null,
        //   Validators.compose([Validators.required])
        // ],
        // dimensionCategoryRole: [
        //   null,
        //   Validators.compose([Validators.required])
        // ],
        DescRole: [
          null,
          // Validators.compose([Validators.required])
        ]
      }
    );
  }
  initForm2(): FormGroup {
    return this.roleForm = this.fb.group(
      {
        selectedApp: [
          null,
          Validators.compose([Validators.required])
        ],
        selectedEnv: [
          null,
          Validators.compose([Validators.required])
        ],
        selectedBases: [
          null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }
  initForm3(): FormGroup {
    return this.roleForm = this.fb.group(
      {
        selectedAppStruct: [
          null,
          Validators.compose([Validators.required])
        ],
        selectedEnvStruct: [
          null,
          Validators.compose([Validators.required])
        ],
        selectedBasesStruct: [
          null,
          Validators.compose([Validators.required])
        ],
        selectedReportStruct: [
          null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }
  initForm4(): FormGroup {
    return this.roleForm = this.fb.group(
      {
        selectedAppModel: [
          null,
          Validators.compose([Validators.required])
        ],
        selectedModel: [
          null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }
  onSubmitAddRole() {
    const formValue = this.roleForm.value;
    this.RoleName = formValue.nameRole;
    // this.roleUpload =  formValue["roleUpload"];
    this.postData = 'name=' + this.RoleName + '&approles=' + 'yyyyyy' + '&upload=' + this.roleUpload;

    this.roleService.addRole(this.postData).subscribe(
      validation => {
        // @ts-ignore
        if (validation.success === false) {
          $('#roleModal').modal('hide');
          swal({
            title: 'Error Role Name!',
            // @ts-ignore
            text: validation.message,
            timer: 2000,
            showConfirmButton: false
          });
        } else {
          this.postData = 'name=' + this.RoleName;

          this.roleService.addRole(this.postData).subscribe(
            data => {
              $('#roleModal').modal('hide');
              swal({
                title: 'Add Success!',
                text: 'New Role Added.',
                timer: 2000,
                showConfirmButton: false
              });
            }, // this.allLocs = data,
            err => {
              console.error(err);
              this.showNotification('alert-danger', 'login ou mot de pass erronés',
                'top', 'right', 'animated fadeInRight', 'animated fadeOutRight');
            }
          );
        }
      },
      err => {
        console.error(err);
      }
    );
  }
  showNotification(colorName, text, placementFrom, placementAlign, animateEnter, animateExit) {
    if (colorName === null || colorName === '') { colorName = 'bg-black'; }
    if (text === null || text === '') { text = 'Turning standard Bootstrap alerts'; }
    if (animateEnter === null || animateEnter === '') { animateEnter = 'animated fadeInDown'; }
    if (animateExit === null || animateExit === '') { animateExit = 'animated fadeOutUp'; }
    const allowDismiss = true;

    $.notify({
      message: text
    },
      {
        type: colorName,
        allow_dismiss: allowDismiss,
        newest_on_top: true,
        timer: 1000,
        placement: {
          from: placementFrom,
          align: placementAlign
        },
        animate: {
          enter: animateEnter,
          exit: animateExit
        },
        template: '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} '
          + (allowDismiss ? 'p-r-35' : '') + '" role="alert">' +
          '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0"' +
          'aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }
  GetRole() {
    const that = this;
    $('#tableRole').DataTable().destroy();
    $('#tableRole').empty();
    const table = $('#tableRole').DataTable({
      // columnDefs: [
      // {"visible": false, "targets": 0},
      // { "visible": false, "targets": 8 }
      ///  ],
      pagingType: 'full_numbers',
      processing: true,
      responsive: true,
      colReorder: true,
      serverSide: true,
      searching: { regex: true },
      pageLength: 5,
      lengthMenu: [[5, 10, 20, -1], ['5 rows', '10 rows', '20 rows', 'Show all']],
      ajax: (dataTablesParameters: any, callback) => {
        const table = $('#tableRole').DataTable();
        const info = table.page.info();
        const sortInfo = table.order();
        const size = table.page.len();
        const sortColumn = $(table.column(sortInfo[0][0]).header()).html().replace(/\s/g, '');
        const sortOrder = sortInfo[0][1];
        let search = table.search();
        if (search === '') {
          search = null;
        }
        this.roleService.getRoleList(info.page, search, sortColumn, sortOrder, size).subscribe(
          data => {
            this.dataList = [];
            for (const row of data.data) {
              this.dataList.push(row);
            }
            callback({
              recordsTotal: data.count,
              recordsFiltered: data.count,
              data: this.dataList,
            });
          },
          err => {
            console.error(err);
          }
        );
      },


      columns: [
        // {data: 'id', title: 'Id'},
        { data: 'name', title: this.lang === 'en' ? 'Role Name' : 'Nom du rôle' },
        { data: 'description', title: this.lang === 'en' ? 'Role Description' : 'Description du rôle' },
        { data: 'super', title: this.lang === 'en' ? 'Super User' : 'Super Utilisateur' },
        {
          className: 'editor_role',
          orderable: false,
          data: 'id',
          render(data, type, row, meta) {
            let htm = '';
            if (!row.create) {
              if ($('#addRowRole').length) {
                $('#addRowRole').remove();
              }
            } else {
              $('#addRowRole').css('display', 'block');
            }
            if (row.delete) {
              htm += '<button class="btn btn-danger btn-circle waves-effect waves-circle waves-float ng-scope editor_remove"  data_item="' + data + '" data-type="html-message" ><i class="fas fa-trash-alt"></i></button> ';
            }
            if (row.update) {
              htm += '<button class="btn bg-light-green btn-circle waves-effect waves-circle waves-float ng-scope editor_save"  data_item="' + data + '" data-type="html-message" ><i class="fas fa-pencil-alt"></i></button>';
            }
            htm += '<button class="btn bg-light-blue btn-circle waves-effect waves-circle waves-float ng-scope editor_cancel"  data_item="' + row.name + '" data-type="html-message" ><i class="fas fa-list-alt"></i></button>';

            return htm;
            // return '<button class="btn btn-danger btn-circle waves-effect waves-circle waves-float ng-scope editor_remove"  onclick="removeRole(\''+data+'\',\'' + Shared.API_URL+'\')" data-type="html-message" ><i class="fas fa-trash-alt"></i></button> ' +
            //   '<button class="btn bg-light-green btn-circle waves-effect waves-circle waves-float ng-scope editor_save"   data-type="html-message" ><i class="fas fa-pencil-alt"></i></button>'+
            //   '<button class="btn bg-light-blue btn-circle waves-effect waves-circle waves-float ng-scope editor_cancel"  data-type="html-message" ><i class="fas fa-list-alt"></i></button>';
            // '<button class="btn btn-circle btn-fab-material-green-200 ng-scope editor_save" onclick="editRole(\''+data+'\',event,\'' + Shared.API_URL+'\')"  data-type="html-message" ><i class="fas fa-pencil-alt"></i></button>';
          },
          title: 'Action'
        }

      ],
      initComplete(settings, json) {
        $('.editor_role').css('display', 'none');
        // $('#tableRole').on('click', 'tbody > tr > td > button.btn-border-radius', function() {
        //   let row = table.row($(this).parents('tr'));
        //   console.log(row.data());
        // });
        $('#tableRole').on('click', 'tbody > tr .editor_save', function () {
          that.editMode = true;
          that.sendingProject = false;
          that.sendingModel = false;
          that.sendingEntity = false;
          that.sendingSetting = false;
          that.sendingCountry = false;
          that.sendingBusiness = false;
          that.sendingLifeCycle = false;
          that.sendingDesigner = false;
          that.sendingDrm = false;
          that.sendingArchiveId = false;
          that.wizard.goToStep(0);
          // console.log($(this).attr('data_item'));
          $('#data_suceess').css('display', 'none');
          $('#data_error').css('display', 'none');
          $('#setting_suceess').css('display', 'none');
          $('#setting_error').css('display', 'none');
          $('#sup_error').css('display', 'none');
          $('#sup_suceess').css('display', 'none');
          $('#version_suceess').css('display', 'none');
          $('#version_error').css('display', 'none');
          $('#project_suceess').css('display', 'none');
          $('#project_error').css('display', 'none');
          $('#datalifeCycle_suceess').css('display', 'none');
          $('#datalifeCycle_error').css('display', 'none');
          $('#designer_suceess').css('display', 'none');
          $('#designer_error').css('display', 'none');
          $('#meta_suceess').css('display', 'none');
          $('#meta_error').css('display', 'none');
          $('#archiveId_suceess').css('display', 'none');
          $('#archiveId_error').css('display', 'none');
          $('#rules_data_suceess').css('display', 'none');
          $('#rules_data_error').css('display', 'none');

          that.translate.get('users.editSecurityRole').subscribe((res: string) => {
            $('#role-title').html(res);
          });
          //  const id = table.row($(this).parents('tr')).data().id;
          const id = $(this).attr('data_item');

          // console.log(id);
          that.id = id;
          $.ajax({
            url: Shared.API_URL + '/api/users-role/editRole/' + id,
            headers: { Authorization: 'Bearer ' + getCookieValue('tokendrd') },
            type: 'put'
            ,
            success: (data, textStatus) => {
              console.log(data);
              const userRole = data[0].role;
              const adminRole = data[1].adminRole;
              const structRole = data[2].structRole;
              const objModelRole = data[3].objModelRole;
              const appdefRole = data[4].appdefRole;
              const flowRole = data[5].flowRole;
              const settingRole = data[6].settingRole;
              const appFlowrole = data[7].appFlowRole;
              const dashboardRole = data[8].dashboardRole;
              const classificationRole = data[9].classificationsRole;
              const ruleRole = data[10].rulesRole;
              const modelAdmin = data[11].adminModel;
              const lifeCyclerole = data[12].lifeCycleRole;
              const metaRole = data[13].metaRole;
              const projectRole = data[14].projectRole;
              const securityRole = data[15].securityRole;
              const retentionRole = data[16].retentionRole;
              const designerRole = data[17].designerRole;
              const stackRole = data[18].stackRole;
              const holderRole = data[19].holderRole;
              const catalogueRole = data[20].catalogueRole;
              const businessTermRole = data[21].businessTermRole;
              const entityRole = data[22].entityRole;
              const countryRole = data[23].countryRole;
              const dimensionRole = data[24].dimensionRole;
              const dataLineageRole = data[25].dataLineageRole;
              const classificationDefinitionRole = data[26].classificationDefinitionRole;
              const diagramSoftwareRole = data[27].diagramSoftwareRole;
              const infraRole = data[28].infraRole;
              const functionalMapRole = data[29].functionalMapRole;
              const archiveRole = data[30].archiveRole;
              const glossaryRole = data[31].glossaryRole;
              // const templateRole = data[31].templateRole;
              // user role
              that.frmRoleSignup.get('nameRole').setValue(userRole.name);
              that.frmRoleSignup.get('DescRole').setValue(userRole.description);
              const dim = that.virtualDimensions.find(e => e.role == userRole.name);
              console.log(dim, userRole.name, that.virtualDimensions);
              // if(dim!=null){
              //   that.frmRoleSignup.get('dimensionRole').setValue(dim.dimension);
              //   that.frmRoleSignup.get('dimensionCategoryRole').setValue(dim.category);
              // } else {
              //   that.frmRoleSignup.get('dimensionRole').reset();
              //   that.frmRoleSignup.get('dimensionCategoryRole').reset();
              // }
              that.super = userRole.super;
              that.versionAdmin = userRole.versionAdmin;
              // admin role
              that.adminApp = [];
              adminRole.map(d => {
                that.adminApp.push({ name: 'Model structure', id: 'struct:::' + d.application });
              });
              that.onAppsEnvBase(that.adminApp);
              let ind = 0;
              adminRole.map((d, i) => {
                ind = i + 1;
                that.create[i] = d.createAdm;
                that.read[i] = d.readAdm;
                that.update[i] = d.updateAdm;
                that.delete[i] = d.deleteAdm;
              });

              // adminModel role
              modelAdmin.map(d => {
                that.adminApp.push({ name: 'Object model', id: 'object:::' + d.application });
              });
              that.onAppsEnvBase(that.adminApp);
              modelAdmin.map((d, i) => {
                const j = ind + i;
                // console.log(j);
                that.createAdminModel[j] = d.createModel;
                that.readAdminModel[j] = d.readModel;
                that.updateAdminModel[j] = d.updateModelo;
                that.deleteAdminModel[j] = d.deleteModelo;
              });
              setTimeout(() => {
                // struct role
                that.structApp = [];
                structRole.map(d => {
                  that.structApp.push(d.application);
                });
                that.onAppsEnvBaseRep(that.structApp);
                structRole.map((d, i) => {
                });
                // objModel role
                that.modelApp = [];
                objModelRole.map(d => {
                  that.modelApp.push(d.application);
                });
                that.onGetAppsModel(that.modelApp, 'all');
                objModelRole.map((d, i) => {
                  that.selectModel[i] = d.readMd;
                  that.modelUpdate[d.application] = d.updateMd;
                  that.objectModel[d.application] = [...d.objectmodels];

                });
                // catalogue role
                that.catalogueApp = [];
                catalogueRole.map(d => {
                  that.catalogueApp.push(d.application);
                });
                that.onGetAppsModel(that.catalogueApp, 'Catalogue');
                catalogueRole.map((d, i) => {
                  that.selectCatalogue[i] = d.readMd;
                  that.catalogUpdate[d.application] = d.updateMd;
                  that.catalogue[d.application] = [...d.objectmodels];

                });
              }, 3000);
              // lifeCycle role
              that.LifeCycleApp = [];
              lifeCyclerole.map(d => {
                that.LifeCycleApp.push(d.application);
              });
              that.getCampainsLifeCycle(that.LifeCycleApp);
              lifeCyclerole?.map((d, i) => {
                that.createLifeCycle[i] = d.createCp;
                that.updateLifeCycle[i] = d.updateCp;
                that.deleteLifeCycle[i] = d.deleteCp;
                that.LifeCycle[d.application] = [...d.campains];

              });
              // archive role
              that.archiveIdApp = [];
              archiveRole.map(d => {
                that.archiveIdApp.push(d.application);
              });
              that.getArchiveIdBases(that.archiveIdApp);
              archiveRole.map((d, i) => {
                that.deleteArchiveId[i] = d.deleteBase;
                that.archiveIdBases[d.application] = [...d.bases];

              });
              // appdef role
              that.appDef = [];
              appdefRole.map(d => {
                that.appDef.push(d.application);
              });
              that.onGetAppsDef(that.appDef);
              appdefRole.map((d, i) => {
                that.createAppDef[i] = d.create;
                that.updateAppDef[i] = d.update;
                that.deleteAppDef[i] = d.delete;
              });
              // flow role
              that.appFlow = [];
              flowRole.map(d => {
                that.appFlow.push(d.application);
              });
              that.onGetAppsFlow(that.appFlow);
              flowRole.map((d, i) => {
                that.createFlow[i] = d.create;
                that.updateFlow[i] = d.update;
                that.deleteFlow[i] = d.delete;
              });
              // setting role
              if (settingRole == 0) {
                that.createUser = false;
                that.readUser = false;
                that.updateUser = false;
                that.deleteUser = false;

                that.createRole = false;
                that.readRole = false;
                that.updateRole = false;
                that.deleteRole = false;

                that.createStack = false;
                that.readStack = false;
                that.updateStack = false;
                that.deleteStack = false;

                that.createHolder = false;
                that.readHolder = false;
                that.updateHolder = false;
                that.deleteHolder = false;
                // that.canDeleteSetting= false;
              } else {
                that.createUser = settingRole.createUser;
                that.readUser = settingRole.readUser;
                that.updateUser = settingRole.updateUser;
                that.deleteUser = settingRole.deleteUser;

                that.createRole = settingRole.createRole;
                that.readRole = settingRole.readRole;
                that.updateRole = settingRole.updateRole;
                that.deleteRole = settingRole.deleteRole;

                that.createStack = settingRole.createStack;
                that.readStack = settingRole.readStack;
                that.updateStack = settingRole.updateStack;
                that.deleteStack = settingRole.deleteStack;

                that.createHolder = settingRole.createHolder;
                that.readHolder = settingRole.readHolder;
                that.updateHolder = settingRole.updateHolder;
                that.deleteHolder = settingRole.deleteHolder;
                //that.canDeleteSetting= true;
              }
              // appFlow role
              that.createApp = appFlowrole.createApp;
              that.readApp = appFlowrole.readApp;
              that.updateApp = appFlowrole.updateApp;
              that.deleteApp = appFlowrole.deleteApp;

              that.createDataFlow = appFlowrole.createflow;
              that.readDataFlow = appFlowrole.readFlow;
              that.updateDataFlow = appFlowrole.updateFlow;
              that.deleteDataFlow = appFlowrole.deleteFlow;
              // dashBoard role
              that.appDash = [];
              dashboardRole.map(app => {
                that.appDash.push(app.application);
              });
              that.onAppsDash(that.appDash);
              dashboardRole.map((app, i) => {
                that.readDash[i] = app.read;
              });
              // classif role
              that.classApp = [];
              classificationRole.map(d => {
                that.classApp.push(d.application);
              });
              that.onGetModelsForClassification(that.classApp);
              classificationRole.map((d, i) => {
                // that.usageRiskCheck[i] = d.usageRisk;
                // that.securityRiskReadCheck[i] = d.securityRiskRead;
                // that.securityRiskWriteCheck[i] = d.securityRiskWrite;
                // that.securityRiskUpdateCheck[i] = d.securityRiskUpdate;
                that.classificationModels[d.application] = [...d.model];
                //  console.log('#updateSecurityClassif_' + d.application);
              });
              //security risk role
              that.securityApp = [];
              securityRole.map(d => {
                that.securityApp.push(d.application);
                that.updateSecurity[d.application] = d.update;
              });
              // rules role
              that.rulesApp = [];
              ruleRole.map(d => {
                that.rulesApp.push(d.application);
              });
              that.onAppsRules(that.rulesApp);
              ruleRole.map((d, i) => {
                // $('#readRulesFilter_' + d.application).prop('checked', d.filtersRead);
                that.rulesModel[d.application] = [...d.model];
              });
              if (retentionRole.length === 0) {
                that.createPeriod = false;
                that.updatePeriod = false;
                that.deletePeriod = false;
                that.readPeriod = false;
                that.canDeletePeriod = false;
              } else {
                that.createPeriod = retentionRole.create;
                that.updatePeriod = retentionRole.update;
                that.deletePeriod = retentionRole.delete;
                that.readPeriod = true;
                that.canDeletePeriod = true;
              }
              if (metaRole.length == 0) {
                that.createMeta = false;
                that.updateMeta = false;
                that.deleteMeta = false;
                that.readMeta = false;
                that.canDeleteMeta = false;
              } else {
                that.createMeta = metaRole.createMeta;
                that.updateMeta = metaRole.updateMeta;
                that.readMeta = true;
                that.deleteMeta = metaRole.deleteMeta;
                that.canDeleteMeta = true;
              }
              // project role
              that.projectApp = [];
              projectRole.map(d => {
                that.projectApp.push(d.application);
                that.createProject[d.application] = d.createProject;
                that.updateProject[d.application] = d.updateProject;
                that.deleteProject[d.application] = d.deleteProject;
              });
              // glossary role
              that.selectedGlossaries = [];
              that.createGlossary = userRole.createGlossary;
              glossaryRole.map(d => {
                that.selectedGlossaries.push(d.glossary);
               // that.createGlossary[d.glossary] = d.createMd;
                that.updateGlossary[d.glossary] = d.updateMd;
                that.deleteGlossary[d.glossary] = d.deleteMd;
              });
              if (designerRole.length == 0) {
                that.createDesigner = false;
                that.updateDesigner = false;
                that.deleteDesigner = false;
                that.readDesigner = false;
                that.canDeleteDesigner = false;
              } else {
                that.createDesigner = designerRole.create;
                that.updateDesigner = designerRole.update;
                that.readDesigner = true;
                that.deleteDesigner = designerRole.delete;
                that.canDeleteDesigner = true;
              }
              if (stackRole.length == 0) {
                that.createStack = false;
                that.updateStack = false;
                that.deleteStack = false;
                that.readStack = false;
              } else {
                that.createStack = stackRole.createStack;
                that.updateStack = stackRole.updateStack;
                that.deleteStack = stackRole.deleteStack;
                that.readStack = true;
              }
              if (holderRole.length == 0) {
                that.createHolder = false;
                that.updateHolder = false;
                that.deleteHolder = false;
                that.readHolder = false;
              } else {
                that.createHolder = holderRole.createHolder;
                that.updateHolder = holderRole.updateHolder;
                that.deleteHolder = holderRole.deleteHolder;
                that.readHolder = holderRole.readHolder;
              }
              if (businessTermRole.length == 0) {
                that.createBusiness = false;
                that.updateBusiness = false;
                that.deleteBusiness = false;
                that.readBusiness = false;
                that.canDeleteBusiness = false;
              } else {
                that.createBusiness = businessTermRole.create;
                that.updateBusiness = businessTermRole.update;
                that.deleteBusiness = businessTermRole.delete;
                that.readBusiness = true;
                that.canDeleteBusiness = true;
              }
              if (entityRole.length == 0) {
                that.createEntity = false;
                that.updateEntity = false;
                that.deleteEntity = false;
                that.readEntity = false;
                that.canDeleteEntity = false;
              } else {
                that.createEntity = entityRole.create;
                that.updateEntity = entityRole.update;
                that.deleteEntity = entityRole.delete;
                that.readEntity = true;
                that.canDeleteEntity = true;
              }
              // if(templateRole.length ==0){
              //   that.createTemplate = false;
              //   that.updateTemplate = false;
              //   that.deleteTemplate = false;
              //   that.readTemplate = false;
              //   that.canDeleteTemplate = false;
              // }else{
              //   that.createTemplate = templateRole.create;
              //   that.updateTemplate = templateRole.update;
              //   that.deleteTemplate = templateRole.delete;
              //   that.readTemplate = true;
              //   that.canDeleteTemplate = true;
              // }
              if (countryRole.length == 0) {
                that.createCountry = false;
                that.updateCountry = false;
                that.deleteCountry = false;
                that.readCountry = false;
                that.canDeleteCountry = false;
              } else {
                that.createCountry = countryRole.create;
                that.updateCountry = countryRole.update;
                that.deleteCountry = countryRole.delete;
                that.readCountry = true;
                that.canDeleteCountry = true;
              }
              if (dimensionRole.length == 0) {
                that.createDimension = false;
                that.updateDimension = false;
                that.deleteDimension = false;
                that.readDimension = false;
              } else {
                that.createDimension = dimensionRole.create;
                that.updateDimension = dimensionRole.update;
                that.deleteDimension = dimensionRole.delete;
                that.readDimension = true;
              }
              if (dataLineageRole.length == 0) {
                that.createDataLineage = false;
                that.updateDataLineage = false;
                that.deleteDataLineage = false;
                that.readDataLineage = false;
              } else {
                that.createDataLineage = dataLineageRole.create;
                that.updateDataLineage = dataLineageRole.update;
                that.deleteDataLineage = dataLineageRole.delete;
                that.readDataLineage = true;
              }
              if (classificationDefinitionRole.length == 0) {
                that.createClassifDef = false;
                that.updateClassifDef = false;
                that.deleteClassifDef = false;
                that.readClassifDef = false;
              } else {
                that.createClassifDef = classificationDefinitionRole.create;
                that.updateClassifDef = classificationDefinitionRole.update;
                that.deleteClassifDef = classificationDefinitionRole.delete;
                that.readClassifDef = true;
              }
              if (diagramSoftwareRole.length == 0) {
                that.createDiagSoft = false;
                that.updateDiagSoft = false;
                that.deleteDiagSoft = false;
                that.readDiagSoft = false;
              } else {
                that.createDiagSoft = diagramSoftwareRole.create;
                that.updateDiagSoft = diagramSoftwareRole.update;
                that.deleteDiagSoft = diagramSoftwareRole.delete;
                that.readDiagSoft = true;
              }
              if (infraRole.length == 0) {
                that.createInfra = false;
                that.updateInfra = false;
                that.deleteInfra = false;
                that.readInfra = false;
              } else {
                that.createInfra = infraRole.create;
                that.updateInfra = infraRole.update;
                that.deleteInfra = infraRole.delete;
                that.readInfra = true;
              }
              if (functionalMapRole.length == 0) {
                that.createFunctionalMap = false;
                that.updateFunctionalMap = false;
                that.deleteFunctionalMap = false;
                that.readFunctionalMap = false;
              } else {
                that.createFunctionalMap = functionalMapRole.create;
                that.updateFunctionalMap = functionalMapRole.update;
                that.deleteFunctionalMap = functionalMapRole.delete;
                that.readFunctionalMap = true;
              }
              $('#usr_tab').click();
              $('#roleModal').modal();
            },
            error(XMLHttpRequest, textStatus, errorThrown) {
              alert('error: ' + textStatus);
            },
            complete(data) {
              // $('#spinnerlg').hide();
              // $('#data_usage1').show();
            }
          });
        });
      }
    });
    $('#tableRole').on('click', 'tbody > tr', function () {
      if (!$(this).hasClass('selected')) {
        $('#tableRole > tbody > tr .editor_role').css('display', 'none');
        $('#tableRole > tbody > tr.selected').removeClass('selected');

        $(this).addClass('selected');

        $('#tableRole > tbody > tr.selected .editor_role').css('display', 'block');
      }

    });
    $('#tableRole').on('click', 'tbody > tr .editor_cancel', function () {
      // var row = table.row($(this).parents('tr'));
      // console.log(row.data());
      const name = $(this).attr('data_item');
      //  console.log(name);
      that.roleService.getUsersByRole(name).subscribe(
        d => {
          // @ts-ignore
          let htm = '<table class=\'table table-condensed\'><thead><tr><th>N°</th><th>UserName</th></tr></thead><tbody>'; d.users.map((r, i) => { htm += '<tr><td style=\'text-align: center;\'>' + (i + 1) + '</td><td style=\'text-align: center;\'>' + r.username + '</td></tr>'; }); htm += '</tbody></table';
          //    swal({
          //      // @ts-ignore
          //   title: 'There are ' + d.users.length + ' users having the role <b style=\'color:#0ba360\'>' + name + '</b>!',
          //   text: '<div class=\'body table-responsive\'>' + htm + '</div>',
          //   html: true
          // });

          swal({
            // @ts-ignore
            title: that.lang === 'en' ? 'There are ' + d.users.length + ' users having the role <b style=\'color:#0ba360\'>' + name + '</b>!' : 'Il y a ' + d.users.length + ' utilisateurs ayant le rôle <b style=\'color:#0ba360\'>' + name + '</b>!',
            text: '<div class=\'body table-responsive\'>' + htm + '</div>',
            html: true,
            confirmButtonText: that.lang === 'en' ? 'Ok' : 'D\'accord',
          });

        },
        err => { console.error(err); }
      );
    });
    $('#tableRole').on('click', 'tbody > tr .editor_remove', function () {
      const id = $(this).attr('data_item');
      swal({
        title: that.lang === 'en' ? "Are you sure?" : "Êtes-vous sûr ?",
        text: that.lang === 'en' ? "You will not be able to recover this Role !" : "Vous ne pourrez pas récupérer ce rôle !",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#DD6B55",
        confirmButtonText: that.lang === 'en' ? "Yes, delete it!" : "Oui, supprimez-le !",
        cancelButtonText: that.lang === 'en' ? "No, cancel plx!" : "Non, annulez s'il vous plaît !",
        closeOnConfirm: false,
        closeOnCancel: false
      }, function (isConfirm) {
        if (isConfirm) {
          that.roleService.removeUser(id).subscribe(
            d => {
              swal({
                title: that.lang === 'en' ? "Success!" : "Succès !",
                text: that.lang === 'en' ? "Stack delete." : "Suppression de la pile.",
                timer: 2000,
                showConfirmButton: false
              });
              $('#tableRole').DataTable().ajax.reload();
              // that.roleService.getRefreshDelete().subscribe(
              //   () => {
              //     that.GetRole();
              //   }
              // );
            },
            err => {
              // console.error(err);
              swal.close();
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 6000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });

              Toast.fire({
                icon: 'error',
                title: err.error
              });
            }
          )
        } else {
          swal({
            title: that.lang === 'en' ? "Cancelled!" : "Annulé !",
            text: that.lang === 'en' ? "This Role is safe." : "Ce rôle est sûr.",
            timer: 2000,
            showConfirmButton: false
          });
        }
      });
    });
  }

  onGetListApplicationsReport() {
    const temp = [];
    this.roleService.getListApplicationsReport().subscribe(
      (result) => {
        this.allApplications = result;
        // this.select.map(d => d = false);
        // this.update.map(d => d = false);
        // this.delete.map(d => d = false);
      },
      err => {
        console.error(err);
      },
      () => {
        this.allApplicationsReport = [];
        this.allApplications.map(d => {
          if (d.id.indexOf('struct:::') != -1) {
            this.allApplicationsReport.push(d.id.slice(9));
          }
        });
      }
    );
  }
  onGetListApplicationsModel(type) {
    if (type == 'all') {
      $('#objmodel_tab').click();
    }
    this.roleService.getListApplicationsModel(type).subscribe(
      (result) => {
        if (type == 'Catalogue') {
          this.allApplicationsCatalogue = result;
        } else {
          this.allApplicationsModel = result;
        }
      },
      err => {
        console.error(err);
      },
      () => {
      }
    );
  }

  onGetAppsrules() {
    const temp = [];
    this.roleService.getListApplicationsModelForRules().subscribe(
      (result) => {
        this.appsRulesApps = result;
      },
      err => {
        console.error(err);
      },
      () => {
      }
    );
  }
  ongetApplications() {
    this.roleService.getApplications().subscribe(
      (result) => {
        this.applications = result;
        // console.log(this.applications);
      },
      err => {
        console.error(err);
      },
      () => {
      }
    );
  }
  // select list Application model administration
  onAppsEnvBase(event) {
    this.AppsEnvBase = [];
    this.adminAppModel = [];

    // console.log('change');
    if (event == '' || event.length == 0) {
      event = 'none';
    } else {
      this.structApp = [];
      this.modelApp = [];
      event.map((e, i) => {
        if (e.name == 'Model structure') {
          this.AppsEnvBase.push({ id: i, app: e.id.split('struct:::')[1] });
          this.structApp.push(e.id.split('struct:::')[1]);
        } else {
          this.adminAppModel.push({ id: i, app: e.id.split('object:::')[1] });
          this.modelApp.push(e.id.split('object:::')[1]);
        }
      });
      this.onAppsEnvBaseRep(this.structApp);
      this.onGetAppsModel(this.modelApp, 'all');
    }

  }
  // select list Application model structure
  onAppsEnvBaseRep(event) {
    // console.log(this.structApp);
    if (event == '') {
      event = 'none';
    }
    this.roleService.getEnvsBasesRep(event).subscribe(
      data => {
        if (data !== 'none') {
          this.AppsEnvBaseRep = data;
        } else {
          this.AppsEnvBaseRep = [];
        }
      },
      err => {
        console.error(err);
      },
      () => {
      }
    );

  }
  onAppsDash(event) {
    if (event == '') {
      event = 'none';
    }
    this.roleService.getEnvsBasesRep(event).subscribe(
      data => {
        // console.log(this.appDash);
        if (data !== 'none') {
          this.appDash = data;
        } else {
          this.appDash = [];
        }
      },
      err => {
        console.error(err);
      },
      () => {
      }
    );
  }
  // classification role apps
  onGetClassificationApps() {
    const temp = [];
    this.roleService.getClassificationApps().subscribe(
      (result) => {
        this.classificationApps = result;
      },
      err => {
        console.error(err);
      },
      () => {
      }
    );
  }
  // select list Application classification
  onGetModelsForClassification(event) {
    if (event == '') {
      event = 'none';
    }
    this.roleService.getModelsForClassification(event).subscribe(
      data => {
        if (data !== 'none') {
          this.classificationModelsApp = data;
        } else {
          this.classificationModelsApp = [];
          // @ts-ignore
          this.classificationModels = [];
        }
      },
      err => {
        console.error(err);
      },
      () => {
        //  console.log(this.classificationModelsApp);
      }
    );

  }
  // remove classification app
  removeModelFromClassifApp(event) {
    // console.log(event);

    if (typeof this.classificationModels[0] != undefined) {
      this.classificationModels.splice(0, 1);
    }
    //  console.log(this.classificationModels[event.value]);
    this.classificationModels[event.value] = [];
    this.classificationModels.splice(event.value, 1);

  }
  // rules model select
  onAppsRules(event) {
    if (event == '') {
      event = 'none';
    }
    this.roleService.getApplModelsRules(event).subscribe(
      data => {
        if (data !== 'none') {
          // this.objectModel = [];
          this.RulesAppsSelected = data;
          // const i = data.length;
          // for(let i =0;i<i;i++){
          //   this.objectModel[i] = data[i].tabModelName;
          // }
        } else {
          this.RulesAppsSelected = [];
          this.rulesModel = [];
        }
      },
      err => {
        console.error(err);
      }
    );

  }
  // remove model from app
  removeModelFromApp(event) {
    if (typeof this.objectModel[0] != undefined) {
      this.objectModel.splice(0, 1);
    }
    //  console.log(this.objectModel[event.value]);
    // this.objectModel.filter((d , i) => { i != event.value});
    this.objectModel[event.value] = [];
    this.objectModel.splice(event.value, 1);

    // console.log('modelappremove', this.modelApp);
    // console.table(this.objectModel);
  }
  // add model from app
  addModelFromApp(event) {
  }
  onGetAppsModel(event, type) {
    //  console.log(event);
    // this.objectModel = [];
    if (event == '') {
      event = 'none';
    }
    this.roleService.getApplModels(event, type).subscribe(
      data => {
        if (data !== 'none') {
          if (type == 'Catalogue') {
            this.AppsCatalogue = data;
          } else {
            this.AppsObjectModel = data;
          }
        } else {
          if (type == 'Catalogue') {
            this.AppsCatalogue = [];
            this.catalogue = [];
          } else {
            this.AppsObjectModel = [];
            this.objectModel = [];
          }

        }
      },
      err => {
        console.error(err);
      }
    );

  }
  // model admin object model
  onGetAppsModelAdmin(event) {
    //  console.log(event);
    if (event == '') {
      event = 'none';
    }
    this.roleService.getApplModels(event, 'all').subscribe(
      data => {
        if (data !== 'none') {
          // this.objectModel = [];
          this.AppsModels = data;
          // }
        } else {
          this.AppsModels = [];
          this.objectModel = [];
        }
      },
      err => {
        console.error(err);
      }
    );

  }

  onGetAppsDef(event) {
    // console.log(event);
    if (event == '') { event = 'none'; }
    if (event !== 'none') {
      this.applicationDefs = event;
    } else {
      this.applicationDefs = [];
    }
  }
  onGetAppsFlow(event) {
    // console.log(event);
    if (event == '') { event = 'none'; }
    if (event !== 'none') {
      this.applicationFlows = event;
    } else {
      this.applicationFlows = [];
    }
  }
  // remove Application
  onApplicationRemovetag(event) {
  }

  submitForm() {
    const formValue = this.frmRoleSignup.value;
    this.createGlossary = $('#create_glossary').prop('checked');
    const admins = [];
    const adminsModel = [];
    if (this.adminApp != undefined) {
      this.adminApp.map((app, i) => {
        //  console.log(app);
        if (app.id.indexOf('struct:::') != -1) {
          this.create[i] = $('#create_' + i).prop('checked');
          this.read[i] = $('#read_' + i).prop('checked');
          this.update[i] = $('#update_' + i).prop('checked');
          this.delete[i] = $('#delete_' + i).prop('checked');
          const admin = new AdministrationJson(app.id.slice(9), this.create[i], this.read[i], this.update[i], this.delete[i]);
          admins.push(admin);
        }
        if (app.id.indexOf('object:::') != -1) {
          this.createAdminModel[i] = $('#createAdminModel_' + i).prop('checked');
          this.readAdminModel[i] = $('#readAdminModel_' + i).prop('checked');
          this.updateAdminModel[i] = $('#updateAdminModel_' + i).prop('checked');
          this.deleteAdminModel[i] = $('#deleteAdminModel_' + i).prop('checked');
          const adminModel = new modelAdministration(app.id.slice(9), this.createAdminModel[i], this.readAdminModel[i], this.updateAdminModel[i], this.deleteAdminModel[i]);
          adminsModel.push(adminModel);
        }
      });
    }


    const structures = [];
    if (this.structApp != undefined) {
      this.structApp.map((app, i) => {
        this.readStruct[i] = $('#readStruct_' + i).prop('checked');
        this.updateStruct[i] = $('#updateStruct_' + i).prop('checked');
        this.createStruct[i] = $('#createStruct_' + i).prop('checked');
        this.deleteStruct[i] = $('#deleteStruct_' + i).prop('checked');
        const struct = new StructureJson(app, this.readStruct[i], this.updateStruct[i], this.createStruct[i], this.deleteStruct[i]);
        structures.push(struct);
      });
    }

    const objModels = [];
    if (this.modelApp != undefined) {
      this.modelApp.map((app, i) => {
        this.selectModel[app] = $('#readModel_' + app).prop('checked');
        if (this.objectModel[app] != undefined) {
          this.createMd[i] = $('#createMd_' + i).prop('checked');
          this.updateMd[i] = $('#updateMd_' + i).prop('checked');
          this.deleteMd[i] = $('#deleteMd_' + i).prop('checked');
          // @ts-ignore
          for (let j = 0; j < this.objectModel[app].length; j++) {
            this.objectModel[app][j] = this.objectModel[app][j].replace(/\//g, '__________');
            this.objectModel[app][j] = this.objectModel[app][j].replace(/&/g, '::::::::');
          }
          //  this.objectModel[app].map(e => {e = e.replace(/\//g, '__________'); e.replace(/&/g, '::::::::');});
          const model = new ObjmodelJson(app, this.selectModel[app], this.objectModel[app], this.createMd[i], this.updateMd[i], this.deleteMd[i]);
          objModels.push(model);
        }
      });
    }

    const appdefs = [];
    if (this.applicationDefs != undefined) {
      this.applicationDefs.map((app, i) => {
        this.createAppDef[i] = $('#createAppDef_' + i).prop('checked');
        this.readAppDef[i] = $('#readAppDef_' + i).prop('checked');
        this.updateAppDef[i] = $('#updateAppDef_' + i).prop('checked');
        this.deleteAppDef[i] = $('#deleteAppDef_' + i).prop('checked');
        const appdefo = new ApplicationDef(app, this.createAppDef[i], this.readAppDef[i], this.updateAppDef[i], this.deleteAppDef[i]);
        appdefs.push(appdefo);
      });
    }
    const appflows = [];
    if (this.applicationFlows != undefined) {
      this.applicationFlows.map((app, i) => {
        this.createFlow[i] = $('#createflow_' + i).prop('checked');
        this.readFlow[i] = $('#readflow_' + i).prop('checked');
        this.updateFlow[i] = $('#updateflow_' + i).prop('checked');
        this.deleteFlow[i] = $('#deleteflow_' + i).prop('checked');
        const appflow = new ApplicationFlow(app, this.createFlow[i], this.readFlow[i], this.updateFlow[i], this.deleteFlow[i]);
        appflows.push(appflow);
      });
    }
    this.createApp = $('#create_app').prop('checked');
    this.readApp = $('#read_app').prop('checked');
    this.updateApp = $('#update_app').prop('checked');
    this.deleteApp = $('#delete_app').prop('checked');

    this.createDataFlow = $('#create_flow').prop('checked');
    this.readDataFlow = $('#read_flow').prop('checked');
    this.updateDataFlow = $('#update_flow').prop('checked');
    this.deleteDataFlow = $('#delete_flow').prop('checked');

    const appdataFlow = new AppFlowRole(this.createApp, this.readApp, this.updateApp, this.deleteApp, this.createDataFlow, this.readDataFlow, this.updateDataFlow, this.deleteDataFlow);
    // infra
    let infraRole = { createInfra: this.createInfra, readInfra: this.readInfra, updateInfra: this.updateInfra, deleteInfra: this.deleteInfra };
    if (!infraRole.createInfra && !infraRole.readInfra && !infraRole.updateInfra && !infraRole.deleteInfra) {
      infraRole = null;
    }
    // designer
    let designerRole = { createDesigner: this.createDesigner, readDesigner: this.readDesigner, updateDesigner: this.updateDesigner, deleteDesigner: this.deleteDesigner };
    if (!designerRole.createDesigner && !designerRole.readDesigner && !designerRole.updateDesigner && !designerRole.deleteDesigner) {
      designerRole = null;
    }
    // diagram software
    let diagramSoftwareRole = { createDiagramSoftware: this.createDiagSoft, readDiagramSoftware: this.readDiagSoft, updateDiagramSoftware: this.updateDiagSoft, deleteDiagramSoftware: this.deleteDiagSoft };
    if (!diagramSoftwareRole.createDiagramSoftware && !diagramSoftwareRole.readDiagramSoftware && !diagramSoftwareRole.updateDiagramSoftware && !diagramSoftwareRole.deleteDiagramSoftware) {
      diagramSoftwareRole = null;
    }
    if (!$('#super').prop('checked')) {
      if (this.modelApp.length > 0) {
        this.modelApp.map(d => {
          //  console.table(this.objectModel[d]);
          if (this.objectModel[d] == '') {
            // this.showError('data_error', 'Attention you have chosen the application ' + d + ' in the tab data model step object model but you haven\'t chosen the allowed models for it', 5000);
            const message = this.lang === 'en' ? 'Attention you have chosen the application ' + d + ' in the tab data model step object model but you haven\'t chosen the allowed models for it' : 'Attention, vous avez choisi l\'application ' + d + ' dans l\'onglet modèle de données étape modèle objet, mais vous n\'avez pas choisi les modèles autorisés pour cela';
            this.showError('data_error', message, 5000);

            return false;
          }
        });
      }
    }
    if (!$('#super').prop('checked') && !admins.length && !adminsModel.length && !structures.length && !objModels.length && !appdataFlow.createApp && !appdataFlow.deleteApp && !appdataFlow.updateApp && !appdataFlow.readApp && !appdataFlow.createflow && !appdataFlow.deleteFlow && !appdataFlow.updateFlow && !appdataFlow.readFlow && infraRole == null && designerRole == null && diagramSoftwareRole == null && !this.editMode) {
      // this.showError('data_error', 'please you must choose at least one  right in the steps of Data Model tab in order to save this!', 4000);
      const message = this.lang === 'en' ? 'please you must choose at least one right in the steps of Data Model tab in order to save this!' : 'veuillez choisir au moins un droit dans les étapes de l\'onglet Modèle de données afin de sauvegarder cela !';
      this.showError('data_error', message, 4000);

      return false;
    }
    this.sendingModel = true;
    const roleJson = new RoleJson(formValue.nameRole, formValue.DescRole, $('#super').prop('checked'), this.versionAdmin, admins, structures, objModels, appdefs, appflows, appdataFlow, adminsModel, infraRole, designerRole, diagramSoftwareRole, $('#create_glossary').prop('checked'));
    console.log(roleJson);
    // return;
    if (this.editMode) {
      const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';

      this.roleService.updateRole(this.id, roleJson).subscribe(
        d => { $('#data_suceess').html(successMessage).css('display', 'block'); $('#data_error').css('display', 'none'); this.saveVirtualDimensions(); },
        err => { $('#data_error').html(errorMessage).css('display', 'block'); $('#data_suceess').css('display', 'none'); this.sendingModel = false; },
        () => {/*$('#roleModal .close').click();*/ this.sendingModel = false; $('#tableRole').DataTable().ajax.reload();
          // this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['usersroles']);
          // });
        }
      );
    } else {
      const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveRole(roleJson).subscribe(

        d => {
          $('#data_suceess').html(successMessage).css('display', 'block'); $('#data_error').css('display', 'none'); this.editMode = true; this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#data_error').html(errorMessage).css('display', 'block'); $('#data_suceess').css('display', 'none'); this.sendingModel = false; },
        () => {
          this.sendingModel = false;/*$('#roleModal .close').click();*/ $('#tableRole').DataTable().ajax.reload(); this.editMode = true; //this.addRole();
          // this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['usersroles']);
          // });
        }
      );
    }
  }
  showError(elem, txt, duration) {
    const htm = txt + ' <button type="button" aria-label="Close" onclick="$(this).parent().css(\'display\',\'none\');" class="close">\n' +
      '<span aria-hidden="true" style="padding: .75rem 1.25rem;position: absolute;top: 0;right: 0;color: white;\n' +
      '">×</span></button>';
    $('#' + elem).html(htm).show('slow');
    textToVoice(txt);
    // setTimeout(() => {$('#data_error').html('').hide(); }, duration);
  }
  removeModel(event) {
    //  console.log(event);
  }
  onApplicationChangeTest(event) {
    // console.log(event);
  }
  selectAllApps(select, t) {
    switch (select) {
      case 'adminApp': this.adminApp = [...t]; this.onAppsEnvBase(this.adminApp); break;
      case 'structApp': this.structApp = [...t]; this.onAppsEnvBaseRep(this.structApp); break;
      case 'modelApp': this.modelApp = [...t]; this.onGetAppsModel(this.modelApp, 'all'); break;
      case 'appDash': this.appDash = [...t]; this.onAppsDash(this.appDash); break;
      case 'appDef': this.appDef = [...t]; this.onGetAppsDef(this.appDef); break;
      case 'appFlow': this.appFlow = [...t]; this.onGetAppsFlow(this.appFlow); break;
      case 'classApp': this.classApp = [...t]; this.onGetModelsForClassification(this.classApp); break;
      case 'rulesApp': this.rulesApp = [...t]; this.onAppsRules(this.classApp); break;
      case 'securityApp': this.securityApp = [...t]; break;
      case 'adminAppModel': this.adminAppModel = [...t]; this.onGetAppsModelAdmin(this.adminAppModel); break;
      case 'LifeCycleApp': this.LifeCycleApp = [...t]; this.getCampainsLifeCycle(this.LifeCycleApp); break;
      case 'projectApp': this.projectApp = [...t]; break;
      case 'catalogueApp': this.catalogueApp = [...t]; this.onGetAppsModel(this.catalogueApp, 'Catalogue'); break;
      case 'archiveIdApp': this.archiveIdApp = [...t]; this.getArchiveIdBases(this.archiveIdApp); break;
      case 'selectedGlossaries' : this.selectedGlossaries = [...t];break;
    }
  }

  deselectAllApps(select) {
    switch (select) {
      case 'adminApp': this.adminApp = []; this.onAppsEnvBase(this.adminApp); break;
      case 'structApp': this.structApp = []; this.onAppsEnvBaseRep(this.structApp); break;
      case 'modelApp': this.modelApp = []; this.onGetAppsModel(this.modelApp, 'all'); break;
      case 'appDash': this.appDash = []; this.onAppsDash(this.appDash); break;
      case 'appDef': this.appDef = []; this.onGetAppsDef(this.appDef); break;
      case 'appFlow': this.appFlow = []; this.onGetAppsFlow(this.appFlow); break;
      case 'classApp': this.classApp = []; this.onGetModelsForClassification(this.classApp); break;
      case 'rulesApp': this.rulesApp = []; this.onAppsRules(this.classApp); break;
      case 'securityApp': this.securityApp = []; break;
      case 'adminAppModel': this.adminAppModel = []; this.onGetAppsModelAdmin(this.adminAppModel); break;
      case 'LifeCycleApp': this.LifeCycleApp = []; this.getCampainsLifeCycle(this.LifeCycleApp); break;
      case 'projectApp': this.projectApp = []; break;
      case 'catalogueApp': this.catalogueApp = []; this.onGetAppsModel(this.catalogueApp, 'Catalogue'); break;
      case 'archiveIdApp': this.archiveIdApp = []; this.getArchiveIdBases(this.archiveIdApp); break;
      case 'selectedGlossaries': this.selectedGlossaries = []; break;
    }
  }
  selectAllModels(i, t) {
    this.objectModel[i] = [...t];
  }
  deselectAllModels(i) {
    this.objectModel[i] = [];
  }
  selectAllClassifModels(i, t) {
    this.classificationModels[i] = [...t];
  }
  deselectAllClassifModels(i) {
    this.classificationModels[i] = [];
  }
  // data life cycle
  selectAllLifeCycle(i, t) {
    this.LifeCycle[i] = [...t];
  }
  deselectAllLifeCycle(i) {
    this.LifeCycle[i] = [];
  }
  // rules

  selectAllRulesModels(i, t) {
    this.rulesModel[i] = [...t];
  }
  deselectAllRulesModels(i) {
    this.rulesModel[i] = [];
  }
  // selectAllSecurityModels(i , t) {
  //   this.securityModel[i] = [...t];
  // }
  // deselectAllSecurityModels(i) {
  //   this.securityModel[i] = [];
  // }
  selectAllCatlogues(i, t) {
    this.catalogue[i] = [...t];
  }
  deselectAllCatalogues(i) {
    this.catalogue[i] = [];
  }
  submitSetting() {
    const formValue = this.frmRoleSignup.value;
    this.createUser = $('#create_user').prop('checked');
    this.readUser = $('#read_user').prop('checked');
    this.updateUser = $('#update_user').prop('checked');
    this.deleteUser = $('#delete_user').prop('checked');

    this.readRole = $('#read_role').prop('checked');
    this.createRole = $('#create_role').prop('checked');
    this.updateRole = $('#update_role').prop('checked');
    this.deleteRole = $('#delete_role').prop('checked');



    if (!this.createUser && !this.readUser && !this.updateUser && !this.deleteUser && !this.createRole && !this.readRole && !this.updateRole && !this.deleteRole && !this.readMeta && !this.createMeta && !this.updateMeta && !this.deleteMeta && !this.editMode) {
      //this.showError('setting_error', 'you can\'t save setting unless you choose at least one right from users management or roles management or meta data' , 10000); return false;
      let message = this.lang === 'en' ? 'you can\'t save setting unless you choose at least one right from users management or roles management or meta data' : 'vous ne pouvez pas sauvegarder le paramètre à moins de choisir au moins un droit depuis la gestion des utilisateurs, la gestion des rôles ou les métadonnées';
      this.showError('setting_error', message, 10000);
      return false;

    }
    const settingRole = new SettingsRole(formValue.nameRole, formValue.DescRole, this.versionAdmin, this.createUser, this.readUser, this.updateUser, this.deleteUser, this.createRole, this.readRole, this.updateRole, this.deleteRole, this.createMeta, this.readMeta, this.updateMeta, this.deleteMeta);

    // console.log(JSON.stringify(settingRole));
    this.sendingSetting = true;
    if (this.editMode) {
      const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editSetting(this.id, settingRole).subscribe(
        d => { $('#setting_suceess').html(successMessage).css('display', 'block'); $('#setting_error').css('display', 'none'); this.saveVirtualDimensions(); },
        err => { $('#setting_error').html(errorMessage).css('display', 'block'); $('#setting_suceess').css('display', 'none'); this.sendingSetting = false; },
        () => { this.sendingSetting = false; $('#tableRole').DataTable().ajax.reload(); }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveSetting(settingRole).subscribe(
        d => {
          $('#setting_suceess').html(successMessage).css('display', 'block'); $('#setting_error').css('display', 'none'); this.editMode = true; this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#setting_error').html(errorMessage).css('display', 'block'); $('#setting_suceess').css('display', 'none'); this.sendingSetting = false; },
        () => {
          this.sendingSetting = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
        }
      );
    }

  }
  checkRole() {
    const name = this.frmRoleSignup.value.nameRole;
    if (name == '') { this.nameError = true; this.nameSuccess = false; return; }
    this.roleService.checkRole(name).subscribe(
      d => {
        // @ts-ignore
        if (d.response.indexOf('taken') != -1) { this.nameError = true; this.nameSuccess = false; } else { this.nameError = false; this.nameSuccess = true; }
      },
      e => { console.error(e); }
    );
  }
  test() {
    // console.log('test');
    // console.log(this.objectModel);
  }
  addRole() {
    this.editMode = false;
    $('#data_suceess').css('display', 'none');
    $('#data_error').css('display', 'none');
    $('#setting_suceess').css('display', 'none');
    $('#setting_error').css('display', 'none');
    $('#sup_error').css('display', 'none');
    $('#sup_suceess').css('display', 'none');
    $('#version_suceess').css('display', 'none');
    $('#version_error').css('display', 'none');
    $('#project_suceess').css('display', 'none');
    $('#project_error').css('display', 'none');
    $('#datalifeCycle_suceess').css('display', 'none');
    $('#datalifeCycle_error').css('display', 'none');
    $('#archiveId_suceess').css('display', 'none');
    $('#archiveId_error').css('display', 'none');
    $('#designer_suceess').css('display', 'none');
    $('#designer_error').css('display', 'none');
    $('#meta_suceess').css('display', 'none');
    $('#meta_error').css('display', 'none');
    this.translate.get('users.newSecurityRole').subscribe((res: string) => {
      $('#role-title').html(res);
    });

    this.frmRoleSignup.get('nameRole').setValue('');
    this.frmRoleSignup.get('DescRole').setValue('');
    // this.frmRoleSignup.get('dimensionRole').reset();
    // this.frmRoleSignup.get('dimensionCategoryRole').reset();
    // admin role
    this.adminApp = [];
    this.adminAppModel = [];
    this.createUser = false;
    this.readUser = false;
    this.updateUser = false;
    this.deleteUser = false;

    this.createRole = false;
    this.readRole = false;
    this.updateRole = false;
    this.deleteRole = false;

    this.createDataFlow = false;
    this.updateDataFlow = false;
    this.readDataFlow = false;
    this.deleteDataFlow = false;

    this.createApp = false;
    this.updateApp = false;
    this.deleteApp = false;
    this.readApp = false;

    this.createDataFlow = false;
    this.updateDataFlow = false;
    this.deleteDataFlow = false;
    this.readDataFlow = false;

    this.objectModel = [];
    this.modelApp = [];
    this.structApp = [];
    this.AppsEnvBase = [];
    this.AppsObjectModel = [];
    this.AppsEnvBaseRep = [];
    this.projectApp = [];
    // life cycle reset
    this.LifeCycleApp = [];
    this.archiveIdApp = [];
    this.AppsLifeCycle = [];
    this.createLifeCycle = [{}];
    this.updateLifeCycle = [{}];
    this.deleteLifeCycle = [{}];
    this.LifeCycle = [{}];
    this.archiveIdBases = [{}];
    this.createMeta = false;
    this.updateMeta = false;
    this.deleteMeta = false;
    this.readMeta = false;
    this.canDeleteSetting = false;
    this.canDeleteVersion = false;
    this.canDeleteProject = false;
    this.canDeleteMeta = false;
    this.createProject = [{}];
    this.updateProject = [{}];
    this.deleteProject = [{}];
    this.updateSecurity = [{}];
    this.createPeriod = false;
    this.updatePeriod = false;
    this.deletePeriod = false;
    this.classApp = [];
    this.classificationModelsApp = [];
    this.securityApp = [];
    this.rulesApp = [];
    this.rulesModel = [{}];
    this.RulesAppsSelected = [];
    this.classificationModels = [{}];
    this.catalogue = [];
    this.catalogueApp = [];

    this.createDiagSoft = false;
    this.updateClassifDef = false;
    this.deleteClassifDef = false;
    this.readClassifDef = false;

    this.createBusiness = false;
    this.updateBusiness = false;
    this.deleteBusiness = false;
    this.readBusiness = false;

    this.createEntity = false;
    this.updateEntity = false;
    this.deleteEntity = false;
    this.readEntity = false;

    this.createCountry = false;
    this.updateCountry = false;
    this.deleteCountry = false;
    this.readCountry = false;

    this.createDimension = false;
    this.updateDimension = false;
    this.deleteDimension = false;
    this.readDimension = false;

    this.createDataLineage = false;
    this.updateDataLineage = false;
    this.deleteDataLineage = false;
    this.readDataLineage = false;

    this.createDiagSoft = false;
    this.updateDiagSoft = false;
    this.deleteDiagSoft = false;
    this.readDiagSoft = false;

    this.createInfra = false;
    this.updateInfra = false;
    this.deleteInfra = false;
    this.readInfra = false;

    this.wizard.goToStep(0);
    $('#usr_tab').click();
    $('#roleModal').modal();
  }
  submitDashBoard() {
    const formValue = this.frmRoleSignup.value;
    this.appDash.map((app, i) => {
      this.readDash[i] = $('#readDash_' + i).prop('checked');
      // console.log(app.app);
      // console.log(this.readDash[i]);
      const dashboardRole = new DashBoardRole(formValue.nameRole, formValue.DescRole, app.app, this.readDash[i]);
      // console.log(JSON.stringify(dashboardRole));
      if (this.editMode) {
        const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
        const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
        this.roleService.editDashboard(this.id, dashboardRole).subscribe(
          d => { $('#dash_suceess').html(successMessage).css('display', 'block'); $('#dash_error').css('display', 'none'); },
          err => { $('#dash_error').html(errorMessage).css('display', 'block'); $('#dash_suceess').css('display', 'none'); this.sendingSetting = false; },
          () => {
            $('#roleModal .close').click(); this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['usersroles']);
            });
          }
        );
      } else {
        const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
        const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
        this.roleService.saveDashboard(dashboardRole).subscribe(
          d => { $('#dash_suceess').html(successMessage).css('display', 'block'); $('#dash_error').css('display', 'none'); },
          err => { $('#dash_error').html(errorMessage).css('display', 'block'); $('#dash_suceess').css('display', 'none'); this.sendingSetting = false; },
          () => {
            $('#roleModal .close').click(); this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
              this.router.navigate(['usersroles']);
            });
          }
        );
      }
    });
  }
  addSuper() {
    const formValue = this.frmRoleSignup.value;
    const roleJson = new RoleJson(formValue.nameRole, formValue.DescRole, $('#super').prop('checked'), this.versionAdmin, [], [], [], [], [], [], [], null, null, null, false);
    console.log(JSON.stringify(roleJson));
    if (this.editMode) {
      const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.updateRole(this.id, roleJson).subscribe(
        d => { $('#sup_suceess').html(successMessage).css('display', 'block'); $('#sup_error').css('display', 'none'); this.saveVirtualDimensions(); },
        err => { $('#data_error').html(errorMessage).css('display', 'block'); $('#sup_suceess').css('display', 'none'); },
        () => {
          $('#roleModal .close').click(); this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['usersroles']);
          });
        }
      );
    } else {
      const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveRole(roleJson).subscribe(
        d => {
          $('#sup_suceess').html(successMessage).css('display', 'block'); $('#sup_error').css('display', 'none'); this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#sup_error').html(errorMessage).css('display', 'block'); $('#sup_suceess').css('display', 'none'); },
        () => {
          $('#roleModal .close').click(); this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
            this.router.navigate(['usersroles']);
          });
        }
      );
    }
  }

  onAppRulesRemove($event: any) {

  }

  submitClassificationRule() {
    this.stopVoice();
    const formValue = this.frmRoleSignup.value;
    // get classification data
    const classifications = [];
    if (this.classApp != undefined) {
      this.classApp.map((app, i) => {
        // const classificationRiskRead = $('#readSecurityClassif_' + app).prop('checked');
        if (this.classificationModels[app] != undefined) {
          // if (!classificationRiskRead ) {
          //   this.showError('rules_data_error', 'you haven\'t selected any rights for models for application ' + app + 'in classification', 4000);
          //   return false;
          // }
          console.log(this.classificationModels[app]);
          for (let i = 0; i < this.classificationModels[app].length; i++) {
            this.classificationModels[app][i] = this.classificationModels[app][i].replace(/\//g, '__________').replace(/&/g, '::::::::');
          }
          // this.classificationModels[app] = this.classificationModels[app].toString().replace(/\//g, '__________');
          // this.classificationModels[app] = this.classificationModels[app].toString().replace(/&/g, '::::::::');
          console.log(this.classificationModels[app])
          const classification = new ClassificationRole(app, this.classificationModels[app], true);
          classifications.push(classification);
        }
      });
    }
    // security risk
    const security = [];
    if (this.securityApp != undefined) {
      this.securityApp.map((app, i) => {
        const securityRiskUpdate = $('#updateSecurityFilter_' + app).prop('checked');
        let securityRisk = { app: app, read: true, update: securityRiskUpdate };
        security.push(securityRisk);
      });
    }
    //rules
    const rules = [];
    if (this.rulesApp != undefined) {
      this.rulesApp.map((app, i) => {
        //  const filterRead = $('#readRulesFilter_' + app).prop('checked');

        if (this.rulesModel[app] != undefined) {
          // if (!filterRead ) {
          //   this.showError('rules_data_error', 'you haven\'t selected any rights for models for application ' + app + ' in rules', 4000);
          //   return false;
          // }
          for (let i = 0; i < this.rulesModel[app].length; i++) {
            this.rulesModel[app][i] = this.rulesModel[app][i].replace(/\//g, '__________').replace(/&/g, '::::::::');
          }
          const rule = new RulesRole(app, true, this.rulesModel[app]);
          rules.push(rule);
        }
      });
    }
    //retention period
    let retention = { read: this.readPeriod, create: this.createPeriod, update: this.updatePeriod, delete: this.deletePeriod };
    if (!$('#super').prop('checked') && !classifications.length && !rules.length && !security.length && !this.editMode) {
      // this.showError('rules_data_error', 'please you must choose at least one application and the rights for it in the steps of Data Regulation tab in order to save this!', 4000);
      const message = this.lang === 'en' ? 'please you must choose at least one application and the rights for it in the steps of Data Regulation tab in order to save this!' : 'veuillez choisir au moins une application et les droits pour cela dans les étapes de l\'onglet Régulation des données afin de sauvegarder cela !';
      this.showError('rules_data_error', message, 4000);

      // this.showError('rules_data_error','s\'il vous plait veillez choisir au moins une application dans data regulation pour pouvoir l\'enregistrer!', 4000);
      return false;
    }
    if (!retention.read && !retention.create && !retention.delete && !retention.update) {
      retention = null;
    }
    let classifDef = { createDef: this.createClassifDef, readDef: this.readClassifDef, updateDef: this.updateClassifDef, deleteDef: this.deleteClassifDef };
    if (!classifDef.createDef && !classifDef.readDef && !classifDef.updateDef && !classifDef.deleteDef) {
      classifDef = null;
    }
    const roleRegulationJson = new RoleRegulationJson(formValue.nameRole, formValue.DescRole, this.super, this.versionAdmin, classifications, security, rules, retention, classifDef);
    console.log(roleRegulationJson);//return;
    this.sendingDrm = true;
    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'there is some errors in editing this role' : 'Il y a des erreurs dans l\'édition de ce rôle.';
      this.roleService.editRegulationRole(this.id, roleRegulationJson).subscribe(
        d => { $('#rules_data_suceess').html(successMessage).css('display', 'block'); this.saveVirtualDimensions(); },
        err => { $('#rules_data_error').html(errorMessage).css('display', 'block'); },
        () => {/*$('#roleModal .close').click(); */this.sendingDrm = false; $('#tableRole').DataTable().ajax.reload();
          // this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['usersroles']);
          // });
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'there is some errors in saving this role' : 'Il y a des erreurs dans l\'enregistrement de ce rôle';
      this.roleService.saveRegulationRole(roleRegulationJson).subscribe(
        d => {
          $('#rules_data_suceess').html(successMessage).css('display', 'block'); this.editMode = true;
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#rules_data_error').html(errorMessage).css('display', 'block'); this.saveVirtualDimensions(); },
        () => {
          this.sendingDrm = false;/*$('#roleModal .close').click();*/ $('#tableRole').DataTable().ajax.reload(); this.editMode = true; //this.addRole();
          //   this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['usersroles']);
          // });
        }
      );
    }
  }

  stopVoice() {
    $('#rules_data_error').html('').hide();
    $('#setting_error').html('').hide();
    $('#data_error').html('').hide();
    this.nameError = true;
    this.nameSuccess = false;
    cancelTextToVoice();
  }

  changeModel(event) {
    // console.log('model: ' + event);
  }

  getCampainsLifeCycle(event) {
    if (event == '') {
      event = 'none';
    }
    this.roleService.getCampainsLifeCycle(event).subscribe(
      data => {
        if (data !== 'none') {
          this.AppsLifeCycle = data;
        } else {
          this.AppsLifeCycle = [];
          this.LifeCycle = [];
        }
      },
      err => {
        console.error(err);
      }
    );
  }

  addLifeCycleFromApp($event: any) {

  }

  removeLifeCycleFromApp(event) {
    if (typeof this.LifeCycle[0] != undefined) {
      this.LifeCycle.splice(0, 1);
    }
    this.LifeCycle[event.value] = [];
    this.LifeCycle.splice(event.value, 1);
  }

  changeLifeCycle($event: any, app) {
  }

  removeLifeCycle($event: any) {

  }

  getAppsLifeCycle() {
    this.roleService.getAppsLifeCycle().subscribe(
      data => {
        this.allApplicationsLifeCycle = data;
        this.allApplicationsArchives = data;
      },
      err => { console.error(err); }
    );
  }

  saveDataLifeCycle() {
    const formValue = this.frmRoleSignup.value;
    const cycles = [];
    this.LifeCycleApp.map((app, i) => {
      if (this.LifeCycle[app] !== undefined) {
        const dt = new DataLifeCycleJson(app, this.LifeCycle[app], $('#readLifeCycle_' + i).prop('checked'), $('#createLifeCycle_' + i).prop('checked'), $('#updateLifeCycle_' + i).prop('checked'), $('#deleteLifeCycle_' + i).prop('checked'));
        cycles.push(dt);
      }
    });
    const lifeCycle = new LifeCycleJson(formValue.nameRole, formValue.DescRole, this.versionAdmin, cycles);
    this.sendingLifeCycle = true;
    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editDataLifeCycle(this.id, lifeCycle).subscribe(
        d => { $('#datalifeCycle_suceess').html(successMessage).css('display', 'block'); $('#datalifeCycle_error').css('display', 'none'); this.saveVirtualDimensions(); },
        err => { $('#datalifeCycle_error').html(errorMessage).css('display', 'block'); $('#datalifeCycle_suceess').css('display', 'none'); },
        () => {/*$('#roleModal .close').click();*/this.sendingLifeCycle = false; $('#tableRole').DataTable().ajax.reload();
          //   this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['usersroles']);
          // });
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveDataLifeCycle(lifeCycle).subscribe(
        d => { $('#datalifeCycle_suceess').html(successMessage).css('display', 'block'); $('#datalifeCycle_error').css('display', 'none'); this.saveVirtualDimensions(); },
        err => { $('#datalifeCycle_error').html(errorMessage).css('display', 'block'); $('#datalifeCycle_suceess').css('display', 'none'); },
        () => {
          this.sendingLifeCycle = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
          //   this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['usersroles']);
          // });
        }
      );
    }
    // console.log(lifeCycle);
  }

  submitMeta() {
    const formValue = this.frmRoleSignup.value;
    // this.createMeta = $('#create_meta').prop('checked');
    // this.readMeta = $('#read_meta').prop('checked');
    // this.updateMeta = $('#update_meta').prop('checked');
    // this.deleteMeta = $('#delete_meta').prop('checked');


    if (!this.createMeta && !this.readMeta && !this.updateMeta && !this.deleteMeta && !this.editMode) {
      // this.showError('meta_error', 'you can\'t save Meta Data unless you choose at least one right from Meta Data role' , 10000); return false;
      let message = this.lang === 'en' ? 'you can\'t save Meta Data unless you choose at least one right from Meta Data role' : 'vous ne pouvez pas sauvegarder les métadonnées à moins de choisir au moins un droit depuis le rôle des métadonnées';
      this.showError('meta_error', message, 10000);
      return false;

    }
    let metaRole = new MetaRole(formValue.nameRole, formValue.DescRole, this.createMeta, this.readMeta, this.updateMeta, this.deleteMeta);

    // console.log(JSON.stringify(settingRole));
    this.sendingMeta = true;
    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editMeta(this.id, metaRole).subscribe(
        d => { $('#meta_suceess').html(successMessage).css('display', 'block'); $('#meta_error').css('display', 'none'); this.canDeleteMeta = true; },
        err => { $('#meta_error').html(errorMessage).css('display', 'block'); $('#meta_suceess').css('display', 'none'); },
        () => {
          this.sendingMeta = false; $('#tableRole').DataTable().ajax.reload();
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveMeta(metaRole).subscribe(
        d => {
          $('#meta_suceess').html(successMessage).css('display', 'block'); $('#meta_error').css('display', 'none'); this.editMode = true; this.canDeleteMeta = true;
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#meta_error').html(errorMessage).css('display', 'block'); $('#meta_suceess').css('display', 'none'); },
        () => {
          this.sendingMeta = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
        }
      );
    }
  }

  removeMeta() {
    this.roleService.deleteMeta(this.id).subscribe(
      d => {
        this.createMeta = false;
        this.updateMeta = false;
        this.deleteMeta = false;
        this.canDeleteMeta = false;
        $('#meta_suceess').html('removed successfully').css('display', 'block'); $('#meta_error').css('display', 'none');
      },
      err => {
        console.log(err);
      },
      // () => {$('#tableRole').DataTable().ajax.reload();
      // }
    );
  }

  removeSetting() {
    this.roleService.deleteSetting(this.id).subscribe(
      d => {
        console.log(d);
      },
      err => {
        console.log(err);
      },
      () => {/*$('#roleModal .close').click();*/$('#tableRole').DataTable().ajax.reload();
        //   this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['usersroles']);
        // });
      }
    );
  }
  submitProject() {
    const formValue = this.frmRoleSignup.value;
    this.createGlossary = $(document.getElementById('create_glossary')).prop('checked');
    const glossaries = [];
    if (this.selectedGlossaries != undefined) {
      this.selectedGlossaries.map((glossary, i) => {
        this.readGlossary[glossary] = true;
        this.updateGlossary[glossary] = $(document.getElementById('update_glossary_' + glossary)).prop('checked');
        this.deleteGlossary[glossary] = $(document.getElementById('delete_glossary_' + glossary)).prop('checked');
        const glossaryRole = { glossary, readMd: true, updateMd: this.updateGlossary[glossary], deleteMd: this.deleteGlossary[glossary] };
        glossaries.push(glossaryRole);
      });
    }

    const projects = [];
    if (this.projectApp != undefined) {
      this.projectApp.map((app, i) => {
        this.createProject[app] = $(document.getElementById('create_project_' + app)).prop('checked');
        this.readProject[app] = true;
        this.updateProject[app] = $(document.getElementById('update_project_' + app)).prop('checked');
        this.deleteProject[app] = $(document.getElementById('delete_project_' + app)).prop('checked');
        const projectRole = { app: app, createProject: this.createProject[app], readProject: true, updateProject: this.updateProject[app], deleteProject: this.deleteProject[app] };
        projects.push(projectRole);
      });
    }
    // data catalogue
    const catalogues = [];
    if (this.catalogueApp != undefined) {
      this.catalogueApp.map((app, i) => {
        this.selectCatalogue[app] = $('#readCt_' + app).prop('checked');
        if (this.catalogue[app] != undefined) {
          this.createCt[i] = $('#createCt_' + i).prop('checked');
          this.updateCt[i] = $('#updateCt_' + i).prop('checked');
          this.deleteCt[i] = $('#deleteCt_' + i).prop('checked');
          // @ts-ignore
          for (let j = 0; j < this.catalogue[app].length; j++) {
            this.catalogue[app][j] = this.catalogue[app][j].replace(/\//g, '__________');
            this.catalogue[app][j] = this.catalogue[app][j].replace(/&/g, '::::::::');
          }
          const catalogue = new ObjmodelJson(app, this.selectCatalogue[app], this.catalogue[app], this.createCt[i], this.updateCt[i], this.deleteCt[i]);
          catalogues.push(catalogue);
        }
      });
    }
    if (glossaries.length == 0 && projects.length == 0 && !this.createStack && !this.updateStack && !this.deleteStack && !this.readStack && !this.createHolder && !this.readHolder && !this.updateHolder && !this.deleteHolder && !this.createEntity && !this.readEntity && !this.updateEntity && !this.deleteEntity && !this.createDimension && !this.readDimension && !this.updateDimension && !this.deleteDimension && !this.createCountry && !this.readCountry && !this.updateCountry && !this.deleteCountry && !this.createGlossary /*!this.createBusiness && !this.readBusiness && !this.updateBusiness && !this.deleteBusiness &&*/ && !this.createDataLineage && !this.readDataLineage && !this.updateDataLineage && !this.deleteDataLineage && !this.editMode) {
      this.showError('project_error', 'you can\'t save DataGovernance unless you choose at least one application from Project role or StewardShip & OwnerShip & Data Roles or organization or Glossary or Data Lineage', 10000); return false;
    }
    // template
    // let template = {create: this.createTemplate, update: this.updateTemplate, read: this.readTemplate, delete: this.deleteTemplate};
    // if( !template.create && !template.update && !template.read && !template.delete){
    //   template = null;
    // }
    // business term
    // let businessTerm = { create: this.createBusiness, update: this.updateBusiness, read: this.readBusiness, delete: this.deleteBusiness };
    // if (!businessTerm.create && !businessTerm.update && !businessTerm.read && !businessTerm.delete) {
    //   businessTerm = null;
    // }
    // entity
    let entity = { create: this.createEntity, update: this.updateEntity, read: this.readEntity, delete: this.deleteEntity };
    if (!entity.create && !entity.update && !entity.read && !entity.delete) {
      entity = null;
    }
    // dimension
    let dimension = { create: this.createDimension, update: this.updateDimension, read: this.readDimension, delete: this.deleteDimension };
    if (!dimension.create && !dimension.update && !dimension.read && !dimension.delete) {
      dimension = null;
    }
    // country
    let country = { create: this.createCountry, update: this.updateCountry, read: this.readCountry, delete: this.deleteCountry };
    if (!country.create && !country.update && !country.read && !country.delete) {
      country = null;
    }
    // data lineage
    let dataLineage = { create: this.createDataLineage, update: this.updateDataLineage, read: this.readDataLineage, delete: this.deleteDataLineage };
    if (!dataLineage.create && !dataLineage.update && !dataLineage.read && !dataLineage.delete) {
      dataLineage = null;
    }
    // functional map
    let functionalMap = { create: this.createFunctionalMap, update: this.updateFunctionalMap, read: this.readFunctionalMap, delete: this.deleteFunctionalMap };
    if (!functionalMap.create && !functionalMap.update && !functionalMap.read && !functionalMap.delete) {
      functionalMap = null;
    }
    const projectRoles = { name: formValue.nameRole, createGlossary: this.createGlossary, description: formValue.DescRole, projects, createStack: this.createStack, readStack: this.readStack, updateStack: this.updateStack, deleteStack: this.deleteStack, createHolder: this.createHolder, readHolder: this.readHolder, updateHolder: this.updateHolder, deleteHolder: this.deleteHolder, catalogues, /*businessTerm,*/ glossaries, entity, country, dimension, dataLineage, functionalMap }; //, template};
    // this.readStack = $('#read_stack').prop('checked');
    // this.createStack = $('#create_stack').prop('checked');
    // this.updateStack = $('#update_stack').prop('checked');
    // this.deleteStack = $('#delete_stack').prop('checked');
    //
    // this.readHolder = $('#read_holder').prop('checked');
    // this.createHolder = $('#create_holder').prop('checked');
    // this.updateHolder = $('#update_holder').prop('checked');
    // this.deleteHolder = $('#delete_holder').prop('checked');
    // console.log(JSON.stringify(projectRoles));//return;
    this.sendingProject = true;
    if (this.editMode) {
      const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editProject(this.id, projectRoles).subscribe(
        d => { $('#project_suceess').html(successMessage).css('display', 'block'); $('#version_error').css('display', 'none'); this.saveVirtualDimensions(); },
        err => { $('#project_error').html(errorMessage).css('display', 'block'); $('#project_suceess').css('display', 'none'); },
        () => {/*$('#roleModal .close').click();*/this.sendingProject = false; $('#tableRole').DataTable().ajax.reload();
          // this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
          //   this.router.navigate(['usersroles']);
          // });
        }
      );
    } else {
      const successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      const errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveProject(projectRoles).subscribe(
        d => {
          $('#project_suceess').html(successMessage).css('display', 'block'); $('#project_error').css('display', 'none'); this.editMode = true; this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response; this.canDeleteProject = true;
        },
        err => { $('#project_error').html(errorMessage).css('display', 'block'); $('#project_suceess').css('display', 'none'); },
        () => {
          this.sendingProject = false;/*$('#roleModal .close').click();*/ $('#tableRole').DataTable().ajax.reload(); this.editMode = true;//this.addRole();
        }
      );
    }
  }
  removeProject() {
    this.roleService.deleteProject(this.id).subscribe(
      d => {
        console.log(d);
      },
      err => {
        console.log(err);
      },
      () => {
        $('#tableRole').DataTable().ajax.reload();
      }
    );
  }
  removeDesigner() {
    this.roleService.deleteDesigner(this.id).subscribe(
      d => {
        this.createDesigner = false;
        this.updateDesigner = false;
        this.deleteDesigner = false;
        this.canDeleteDesigner = false;
        $('#designer_suceess').html('removed successfully').css('display', 'block'); $('#designer_error').css('display', 'none');
      },
      err => {
        console.log(err);
      }
    );
  }
  submitDesigner() {
    const formValue = this.frmRoleSignup.value;
    // this.createDesigner = $('#create_designer').prop('checked');
    // this.updateDesigner = $('#update_designer').prop('checked');
    // this.deleteDesigner = $('#delete_designer').prop('checked');
    // this.readDesigner = $('#read_designer').prop('checked');

    if (!this.createDesigner && !this.readDesigner && !this.updateDesigner && !this.deleteDesigner && !this.editMode) {
      // this.showError('designer_error', 'you can\'t save Object Model designer unless you choose at least one right from Object Model designer role' , 10000); return false;
      let message = this.lang === 'en' ? 'you can\'t save Object Model designer unless you choose at least one right from Object Model designer role' : 'vous ne pouvez pas sauvegarder le concepteur de modèle d\'objet à moins de choisir au moins un droit depuis le rôle du concepteur de modèle d\'objet';
      this.showError('designer_error', message, 10000);
      return false;

    }
    const designerRole = new MetaRole(formValue.nameRole, formValue.DescRole, this.createDesigner, this.readDesigner, this.updateDesigner, this.deleteDesigner);
    this.sendingDesigner = true;

    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editDesigner(this.id, designerRole).subscribe(
        d => { $('#designer_suceess').html(successMessage).css('display', 'block'); $('#designer_error').css('display', 'none'); this.canDeleteDesigner = true; this.saveVirtualDimensions(); },
        err => { $('#designer_error').html(errorMessage).css('display', 'block'); $('#designer_suceess').css('display', 'none'); },
        () => {
          this.sendingDesigner = false; $('#tableRole').DataTable().ajax.reload();
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveDesigner(designerRole).subscribe(
        d => {
          $('#designer_suceess').html(successMessage).css('display', 'block'); $('#designer_error').css('display', 'none'); this.editMode = true; this.canDeleteDesigner = true; this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#designer_error').html(errorMessage).css('display', 'block'); $('#designer_suceess').css('display', 'none'); },
        () => {
          this.sendingDesigner = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
        }
      );
    }
  }

  getListApplicationsReportModel() {
    this.roleService.getListApplicationsReportModel().subscribe(
      data => { this.applicationProject = data; },
      error => { console.log(error); }
    );
  }

  setDimCategories() {
    // const dimension = this.frmRoleSignup.get('dimensionRole').value;
    //this.categoryDimensions = this.dimensions.find(e => e.id == dimension).category;
    this.categoryDimensions = [...this.categoryDimensions];
  }
  saveVirtualDimensions() {
    if (this.virtualDimensions.find(e => e.role == this.frmRoleSignup.value.nameRole) != null) {
      const t = this.virtualDimensions.find(e => e.role == this.frmRoleSignup.value.nameRole);
      const index = this.virtualDimensions.indexOf(t);
      //  this.virtualDimensions[index]= {role: this.frmRoleSignup.value.nameRole, dimension: this.frmRoleSignup.value.dimensionRole, category: this.frmRoleSignup.value.dimensionCategoryRole};
    } else {
      // this.virtualDimensions.push({role: this.frmRoleSignup.value.nameRole, dimension: this.frmRoleSignup.value.dimensionRole, category: this.frmRoleSignup.value.dimensionCategoryRole});
    }
    localStorage.setItem('virtualDimensions', JSON.stringify(this.virtualDimensions));
  }

  submitBusiness() {
    const formValue = this.frmRoleSignup.value;
    if (!this.createBusiness && !this.readBusiness && !this.updateBusiness && !this.deleteBusiness && !this.editMode) {
      //  this.showError('business_error', 'you can\'t save Business Glossary unless you choose at least one right from Business Term role' , 10000); return false;
      let message = this.lang === 'en' ? 'you can\'t save Business Glossary unless you choose at least one right from Business Term role' : 'vous ne pouvez pas sauvegarder le Glossaire des Affaires à moins de choisir au moins un droit depuis le rôle du terme des affaires';
      this.showError('business_error', message, 10000);
      return false;

    }
    const businessRole = { name: formValue.nameRole, description: formValue.DescRole, createBusinessTerm: this.createBusiness, readBusinessTerm: this.readBusiness, updateBusinessTerm: this.updateBusiness, deleteBusinessTerm: this.deleteBusiness };
    this.sendingBusiness = true;

    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editBusinessTerm(this.id, businessRole).subscribe(
        d => { $('#business_suceess').html(successMessage).css('display', 'block'); $('#business_error').css('display', 'none'); this.canDeleteBusiness = true; this.saveVirtualDimensions(); },
        err => { $('#business_error').html(errorMessage).css('display', 'block'); $('#business_suceess').css('display', 'none'); },
        () => {
          this.sendingBusiness = false; $('#tableRole').DataTable().ajax.reload();
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveBusinessTerm(businessRole).subscribe(
        d => {
          $('#business_suceess').html(successMessage).css('display', 'block'); $('#business_error').css('display', 'none'); this.canDeleteBusiness = true; this.editMode = true; this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#business_error').html(errorMessage).css('display', 'block'); $('#business_suceess').css('display', 'none'); },
        () => {
          this.sendingBusiness = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
        }
      );
    }
  }

  submitCountry() {
    const formValue = this.frmRoleSignup.value;

    if (!this.createCountry && !this.readCountry && !this.updateCountry && !this.deleteCountry && !this.editMode) {
      //this.showError('business_error', 'you can\'t save Country unless you choose at least one right from Country role' , 10000); return false;
      let message = this.lang === 'en' ? 'you can\'t save Country unless you choose at least one right from Country role' : 'vous ne pouvez pas sauvegarder le Pays à moins de choisir au moins un droit depuis le rôle du Pays';
      this.showError('business_error', message, 10000);
      return false;


    }
    const countryRole = { name: formValue.nameRole, description: formValue.DescRole, createCountry: this.createCountry, readCountry: this.readCountry, updateCountry: this.updateCountry, deleteCountry: this.deleteCountry };
    this.sendingCountry = true;

    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editCountry(this.id, countryRole).subscribe(
        d => { $('#country_suceess').html(successMessage).css('display', 'block'); $('#country_error').css('display', 'none'); this.canDeleteCountry = true; this.saveVirtualDimensions(); },
        err => { $('#country_error').html(errorMessage).css('display', 'block'); $('#country_suceess').css('display', 'none'); },
        () => {
          this.sendingCountry = false; $('#tableRole').DataTable().ajax.reload();
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveCountry(countryRole).subscribe(
        d => {
          $('#country_suceess').html(successMessage).css('display', 'block'); $('#country_error').css('display', 'none'); this.canDeleteCountry = true; this.editMode = true; this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#country_error').html(errorMessage).css('display', 'block'); $('#country_suceess').css('display', 'none'); },
        () => {
          this.sendingCountry = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
        }
      );
    }
  }
  submitInfra() {
    const formValue = this.frmRoleSignup.value;

    if (!this.createInfra && !this.readInfra && !this.updateInfra && !this.deleteInfra && !this.editMode) {
      //this.showError('business_error', 'you can\'t save Infra unless you choose at least one right from Infra role' , 10000); return false;
      let message = this.lang === 'en' ? 'you can\'t save Infra unless you choose at least one right from Infra role' : 'vous ne pouvez pas sauvegarder Infra à moins de choisir au moins un droit depuis le rôle d\'Infra';
      this.showError('business_error', message, 10000);
      return false;

    }
    const infraRole = { name: formValue.nameRole, description: formValue.DescRole, createInfra: this.createInfra, readInfra: this.readInfra, updateInfra: this.updateInfra, deleteInfra: this.deleteInfra };
    this.sendingInfra = true;

    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editInfra(this.id, infraRole).subscribe(
        d => { $('#infra_suceess').html(successMessage).css('display', 'block'); $('#country_error').css('display', 'none'); this.canDeleteInfra = true; this.saveVirtualDimensions(); },
        err => { $('#infra_error').html(errorMessage).css('display', 'block'); $('#infra_suceess').css('display', 'none'); },
        () => {
          this.sendingInfra = false; $('#tableRole').DataTable().ajax.reload();
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveInfra(infraRole).subscribe(
        d => {
          $('#infra_suceess').html(successMessage).css('display', 'block'); $('#infra_error').css('display', 'none'); this.canDeleteInfra = true; this.editMode = true; this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#infra_error').html(errorMessage).css('display', 'block'); $('#infra_suceess').css('display', 'none'); },
        () => {
          this.sendingInfra = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
        }
      );
    }
  }
  submitEntity() {
    const formValue = this.frmRoleSignup.value;

    if (!this.createEntity && !this.readEntity && !this.updateEntity && !this.deleteEntity && !this.editMode) {
      // this.showError('business_error', 'you can\'t save Entity unless you choose at least one right from Entity role' , 10000); return false;
      let message = this.lang === 'en' ? 'you can\'t save Entity unless you choose at least one right from Entity role' : 'vous ne pouvez pas sauvegarder l\'Entité à moins de choisir au moins un droit depuis le rôle de l\'Entité';
      this.showError('business_error', message, 10000);
      return false;

    }
    const entityRole = { name: formValue.nameRole, description: formValue.DescRole, createEntity: this.createEntity, readEntity: this.readEntity, updateEntity: this.updateEntity, deleteEntity: this.deleteEntity };
    this.sendingEntity = true;

    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editEntity(this.id, entityRole).subscribe(
        d => { $('#entity_suceess').html(successMessage).css('display', 'block'); $('#entity_error').css('display', 'none'); this.canDeleteEntity = true; this.saveVirtualDimensions(); },
        err => { $('#entity_error').html(errorMessage).css('display', 'block'); $('#entity_suceess').css('display', 'none'); },
        () => {
          this.sendingEntity = false; $('#tableRole').DataTable().ajax.reload();
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveEntity(entityRole).subscribe(
        d => {
          $('#entity_suceess').html(successMessage).css('display', 'block'); $('#entity_error').css('display', 'none'); this.canDeleteEntity = true; this.editMode = true; this.saveVirtualDimensions();
          // @ts-ignore
          this.id = d.response;
        },
        err => { $('#infra_error').html(errorMessage).css('display', 'block'); $('#infra_suceess').css('display', 'none'); },
        () => {
          this.sendingEntity = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
        }
      );
    }
  }

  archClick() {
    $('#appflow_tab').click();
  }

  defClick() {
    $('#class_def_tab').click();
  }

  // remove catalogue from app
  removeCatalogueFromApp(event) {
    if (typeof this.catalogue[0] != undefined) {
      this.catalogue.splice(0, 1);
    }
    this.catalogue[event.value] = [];
    this.catalogue.splice(event.value, 1);
  }
  getDimensions() {
    this.roleService.getDimensions().subscribe(
      d => { this.dimensions = d['data']; },
      err => { console.error(err); }
    );
  }
  getArchiveIdBases(event) {
    if (event == '') {
      event = 'none';
    }
    this.roleService.getArchiveIdBases(event).subscribe(
      data => {
        if (data !== 'none') {
          this.AppsArchiveId = data;
        } else {
          this.AppsArchiveId = [];
          this.archiveIdBases = [];
        }
      },
      err => {
        console.error(err);
      }
    );
  }
  // archiveId
  selectAllBases(i, t) {
    this.archiveIdBases[i] = [...t];
  }
  deselectAllBases(i) {
    this.archiveIdBases[i] = [];
  }
  saveArchiveIds() {
    const formValue = this.frmRoleSignup.value;
    const cycles = [];
    this.archiveIdApp.map((app, i) => {
      if (this.archiveIdBases[app] !== undefined) {
        // @ts-ignore
        const dt = new ArchiveIdJson(app, this.archiveIdBases[app], $('#deleteArchiveId_' + i).prop('checked'));
        cycles.push(dt);
      }
    });
    const lifeCycle = new ArchiveJson(formValue.nameRole, formValue.DescRole, this.versionAdmin, cycles);
    this.sendingArchiveId = true;
    if (this.editMode) {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.editArchivesRole(this.id, lifeCycle).subscribe(
        d => { $('#archiveId_suceess').html(successMessage).css('display', 'block'); $('#archiveId_error').css('display', 'none'); this.saveVirtualDimensions(); },
        err => { $('#archiveId_error').html(errorMessage).css('display', 'block'); $('#archiveId_suceess').css('display', 'none'); },
        () => {
          this.sendingArchiveId = false; $('#tableRole').DataTable().ajax.reload();
        }
      );
    } else {
      let successMessage = this.lang === 'en' ? 'saved successfully' : 'sauvegardé avec succès';
      let errorMessage = this.lang === 'en' ? 'An error occurred' : 'Une erreur s\'est produite';
      this.roleService.saveArchivesRole(lifeCycle).subscribe(
        d => { $('#archiveId_suceess').html(successMessage).css('display', 'block'); $('#archiveId_error').css('display', 'none'); this.saveVirtualDimensions(); },
        err => { $('#archiveId_error').html(errorMessage).css('display', 'block'); $('#archiveId_suceess').css('display', 'none'); },
        () => {
          this.sendingArchiveId = false; $('#roleModal .close').click(); $('#tableRole').DataTable().ajax.reload(); this.addRole();
        }
      );
    }
    // console.log(lifeCycle);
  }
  removeBaseFromApp(event) {
    if (typeof this.archiveIdBases[0] != undefined) {
      this.archiveIdBases.splice(0, 1);
    }
    this.archiveIdBases[event.value] = [];
    this.archiveIdBases.splice(event.value, 1);
  }
  addNoSystem() {
    if (this.projectApp.findIndex(e => e === 'No System') == -1) {
      this.projectApp.push('No System');
      this.projectApp = [...this.projectApp];
      this.createProject['No System'] = true;
      this.updateProject['No System'] = true;
      this.deleteProject['No System'] = true;
      this.readProject['No System'] = true;
    }
  }
  getGlossaries() {
    this.roleService.getGlossaries().subscribe(
      d => {
        this.glossaries = d;
      },
      err => {
        console.error(err);
      }
    );
  }
}
