export class archPurge{
    SYSTNAPS_ARCHID:number;
    NAME_TABLE:string;
    COUNT_SRC:number;
    COUNT_ARCH:number;
    COUNT_CSV:number;
    COUNT_BAD:number;
    COUNT_TEMP:number;
    COUNT_PURGE:number;
    OK_ARCH_CSV:number;
    OK_CSV_TEMP:number;
    OK_SRC_PURGE:number;
    TIME_SAVE_CSV:number;
    TIME_LOAD_TEMP:number;
    TIME_PURGE:number;
    base:string;
    constructor(SYSTNAPS_ARCHID,	NAME_TABLE,	COUNT_SRC,	COUNT_ARCH,	COUNT_CSV,	COUNT_BAD,	COUNT_TEMP,	COUNT_PURGE,	OK_ARCH_CSV,	OK_CSV_TEMP,	OK_SRC_PURGE,	TIME_SAVE_CSV,	TIME_LOAD_TEMP,	TIME_PURGE,base){
        this.SYSTNAPS_ARCHID = SYSTNAPS_ARCHID;
        this.NAME_TABLE = NAME_TABLE;
        this.COUNT_SRC = COUNT_SRC;
        this.COUNT_CSV = COUNT_CSV;
        this.COUNT_BAD = COUNT_BAD;
        this.COUNT_ARCH = COUNT_ARCH;
        this.COUNT_TEMP = COUNT_TEMP;
        this.COUNT_PURGE = COUNT_PURGE;
        this.OK_ARCH_CSV = OK_ARCH_CSV;
        this.OK_CSV_TEMP = OK_CSV_TEMP;
        this.OK_SRC_PURGE = OK_SRC_PURGE;
        this.TIME_SAVE_CSV = TIME_SAVE_CSV;
        this.TIME_LOAD_TEMP = TIME_LOAD_TEMP;
        this.TIME_PURGE = TIME_PURGE;
        this.base = base;
    }
}
