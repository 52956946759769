import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  OnInit
} from '@angular/core';
import {ObjectService} from '../../../services/objects/object.service';

const FORCES = {
  LINKS: 1 / 50,
  COLLISION: 1,
  CHARGE: -1
};
declare const $: any;
@Component({
  selector: 'app-diagramme',
  templateUrl: './diagramme.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./diagramme.component.css']
})
export class DiagrammeComponent implements OnInit, AfterViewInit {
  constructor(private objectService: ObjectService) {}
  modelJoins = [];
  objectModelJoinsDistinct = [];
  resultLinks = [];
  nodes = [];
  selectedText: string;
  margin = {
    top: 40,
    bottom: 10,
    left: 20,
    right: 20
  };
  name: string;
  description: string;
  keyCand: string;
  recordKand: string;
  recordList = [];
  isRule = true;
  filter: string;

  ngOnInit(): void {
 
  }
  ngAfterViewInit(): void {
 
  }



}
