import * as d3 from 'd3';
import d3Tip from 'd3-tip';
// import {textToVoice} from '../../shared';
declare const $: any;

export const packChart = (newData) => {
  //set margin
  const svg = d3.select("#sizoChart");
  const margin = 10;
  const commaFormat = d3.format(',');

  const width = +svg.attr("width") - margin - margin;
  const height = +svg.attr("height") - margin - margin;
  svg.attr("viewBox", `0 0 ${width} ${height}`);
  // svg.style('cursor','move');
  //set size for rendering
  const diameter = +svg.attr("width");
  const g = svg.append("g")
    .attr('id','sizeChartGlobal')
    .attr("transform", "translate(" + diameter / 2 + "," + diameter / 2 + ")");


  const getIcon = (d) => {
    let ic;
    switch (d) {
      case 'Physical Person': ic = '\uf2bd'; break;
      case 'Physical person': ic = '\uf2bd'; break;
      case 'Legal Entity': ic = '\uf1ad'; break;
      case 'Legal entity': ic = '\uf1ad'; break;
      case 'Data Repository': ic = '\uf494'; break;
      case 'Data repository': ic = '\uf494'; break;
      case 'Technical data': ic = '\uf085'; break;
      case 'Technical Data': ic = '\uf085'; break;
      case 'Operational data': ic = '\uf1c0'; break;
      case 'Operational Data': ic = '\uf1c0'; break;
      default: ic = '\uf00d'; break;
    }
    return ic;
  };
  const getUsageRisk = (d) => {
    let ic;
    switch (d) {
      case 0: ic = 'grey'; break;
      case 1: ic = 'green'; break;
      case 2: ic = 'orange'; break;
      case 3: ic = 'red'; break;
    }
    return ic;
  };
  // function zoomhandler(){
  //  // d3.event.preventDefault();
  //   let tx = 0 , ty = 0, scale =1;
  //   // set zooming
  //   var factor = 1;
  //   var center = d3.mouse(document.querySelector('#sizeChartGlobal'));
  //   var newTx, newTy, newScale;
  //
  //   // calculate new scale
  //   if (d3.event.deltaY > 0) {
  //     newScale = scale * factor;
  //   } else {
  //     newScale = scale / factor;
  //   }
  //
  //   // calculate new translate position
  //   // [current mouse position] - ([current mouse position] - [current translate]) * magnification
  //   newTx = center[0] - (center[0] - tx) * newScale / scale;
  //   newTy = center[1] - (center[1] - ty) * newScale / scale;
  //
  //   // set new scale and translate position
  //   scale = newScale;
  //   tx = newTx;
  //   ty = newTy;
  //
  //   g.attr('transform', `translate(${tx}, ${ty}) scale(${scale})`);
  // }
  const Zoomos = d3.zoom()
    .scaleExtent([.1, 10])
    // .on('zoom', zoomhandler);
    // .translateExtent([[-width, -height], [width, height]])
    // .extent([[0, 0], [width, height]])
    .on("zoom", function() {
      // current_scale = +current_scale_string.substring(6, current_scale_string.length-1);
      //  let x = d3.event.transform.x / d3.event.transform.k;//start_x + ((d3.event.x - start_x) / current_scale);
      //  let y = d3.event.transform.y / d3.event.transform.k;//start_y + ((d3.event.y - start_y) / current_scale);
      // g.attr('transform', 'translate(' + x + ',' + y + ') scale(' + d3.event.transform.k + ')');});
      g.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ') scale(' + d3.event.transform.k + ')');});
  svg.call(Zoomos);
  svg.on("wheel.zoom", null);

  if($('.d3-tipSize-Zoom').length > 0){
    $('.d3-tipSize-Zoom').remove();
  }
  const tool_tip = d3Tip()
    .direction('n')
    .attr("class", "d3-tipSize-Zoom")
    //   .offset(function(d) {
    //   return [this.parentNode.getBBox().height / 2, 0];
    // })
    .offset([-75, 0])
    .html((d) => {//console.log(d.data.children);return $('#tipo').html();
      // console.log('-----------');
      // console.log(datachart);
      // console.log('-----------');
      // console.log(d.data.children);
      return '<div id="sizeTipo">' + d.data.name + '</div>';
    });
  svg.call(tool_tip);
  // const bigZoom = d3.zoom()
  //   .scaleExtent([1, 40])
  //   .translateExtent([[-100, -100], [width + 90, height + 100]])
  //   .on("zoom", zoomed);
  // function zoomed() {
  //   view.attr("transform", d3.event.transform);
  //   g.call(xAxis.scale(d3.event.transform.rescaleX(x)));
  //   gY.call(yAxis.scale(d3.event.transform.rescaleY(y)));
  // }
  //ele : The g element
  //data : data for which we need to draw chart
  //d : high level pack data(just in case if we need)
  //zoomRadius : Its used under condition when we zoom-in/zoom-out
  const drawBarData = function(ele, data, d, zoomRadius) {
    if (!data && !data.parent)
      return;

    data.data.children.sort((a, b) => d3.descending(a.size, b.size));
    //console.log(data);
    var rectwidth = (zoomRadius) ? zoomRadius : d.r;
    var rectheight = rectwidth;
    var maxDataPoint = d3.max(data.data.children, function(d) {
      return d.size;
    });

    let linearScale = d3.scaleLinear()
      .domain([0, maxDataPoint])
      .range([0, rectheight]);

    let x = d3.scaleBand()
      .range([0, rectwidth])
      .padding(0.1);

    let y = d3.scaleLinear()
      .range([rectheight, 0]);

    // Scale the range of the data in the domains
    x.domain(data.data.children.map(function(d) {
      return d.name + ' - ' +d.size + ' fields';
    }));
    y.domain([0, d3.max(data.data.children, function(d) {
      return d.size;
    })]);

    // $("#" + data.parent.data.id+"_"+data.data.id).html("");
    $("#bars_" + data.data.id).html("");
    // var bg = d3.select("#" + data.parent.data.id+"_"+data.data.id).append("g")
    let bg = d3.select($('#bars_' + data.data.id)[0]).append("g")
      .attr("class", "chart-wrapper")
      .attr("transform", function(d) {
        // return "translate(" + -rectwidth / 2 + "," + -rectwidth / 2 + ")";
        return "rotate(90) translate(" + -rectwidth/2  + "," + -rectwidth/2  + ")";
      });
    //Title inside circle
    // bg.append("text")
    //   .attr("class","innerCircleTitle")
    //   .attr('id', d => 'text_' + d.data.id)
    //   .attr("y", function(d, i) {
    //     d.titleHeight = (-1 + 0.25) * d.r;
    //     return d.titleHeight;
    //   })
    //   .style('display', 'none')
    //   .attr("dy","0em")
    //   .text(function(d,i) { return d.data.name; });
    // .style("font-size", function(d) {
    //   //Calculate best font-size
    //   d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
    //   return Math.round(d.fontTitleSize)+"px";
    //  });
    bg.selectAll(".bar")
      .data(data.data.children)
      .enter().append("rect")
      .attr("class", "bar")
      .style('opacity','0.8')
      .attr("x", function(d) {
        return x(d.name + ' - ' +d.size + ' fields');
      })
      // .attr('transform', 'rotate(90)')
      .attr("width", x.bandwidth())
      .attr("y", function(d) {
        return y(d.size);
      })
      .attr("height", function(d) {
        return rectheight+5  - y(d.size);
      })
      .attr("fill",(d , i) => d.recordCand? '#2bbd2b':'#077dfb')
      .append("svg:title")
      .text((d) => d.name + '  have ' + d.size + ' fields');

    //just a safe check to render axis only if we have space
    if(rectheight > 100){
      bg.append("g")
        .attr("class", "axis axis--x")
        .attr("transform", "translate(0," + rectheight + ")")
        .call(d3.axisBottom(x))
        .selectAll("text")
        .attr('font-weight', 'bold')
        .attr("y", 0)
        // .attr("x", d => y(d.name + ' ' +d.size + ' fields'))
        // .attr("x",  200 )
        .attr("x", function(d){
          const width = this.getBBox().width;
          return width;
          // return Math.max(width + 10, rectheight);
        })
        .attr("dy", ".7em")
        .attr("transform", "rotate(-90)")
        .style("text-anchor", "end");

      bg.append("g")
        .attr("class", "axis axis--y")
        .call(d3.axisLeft(y));
      // $('.chart-wrapper').parent().css('transform', 'rotate(90deg)');
    }
    // $('.chart-wrapper').parent().css('transform', 'rotate(90deg)');
    d3.selectAll('.chart-wrapper').selectAll('text').attr('fill', '#000000');
    d3.selectAll('.chart-wrapper').selectAll('.axis').attr('fill', '#000000');

  };
  // wrap text
  const wrap = (texto, width) => {
    //console.log(d3.select(text));
    let text = d3.select(texto),
      words = text.text().split(/\s+/).reverse(),
      currentSize = +(text.style("font-size")).replace("px",""),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.2, // ems
      extraHeight = 0.2,
      y = text.attr("y"),
      dy = parseFloat(text.attr("dy")),
      //First span is different - smaller font
      tspan = text.text(null)
        .append("tspan")
        .attr("class","subTotal")
        .attr("x", 0).attr("y", y)
        .attr("dy", dy + "em")
        .style("font-size", (Math.round(currentSize*0.5) <= 5 ? 0 : Math.round(currentSize*0.5))+"px");
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width || word === "|") {
        if (word = "|") word = "";
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan")
          .attr('class', 'objectTitle')
          .attr("x", 0).attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + extraHeight + dy + "em")
          .text(word);
      }// if
    }// while
  }; // wrap
  // calculate text font
  const calculateTextFontSize = function(d) {
    // console.log(d3.select(this));
    var id = d3.select(this).attr('id').split('_')[1];
    var radius = 0;
    if (d.fontsize){
      //if fontsize is already calculated use that.
      return d.fontsize;
    }
    if (!d.computed ) {
      //if computed not present get & store the getComputedTextLength() of the text field
      d.computed = this.getComputedTextLength();
      if(d.computed != 0){
        //if computed is not 0 then get the visual radius of DOM
        var r = d3.selectAll("#circle_" + id).attr("r");
        //if radius present in DOM use that
        if (r) {
          radius = r;
        }
        //calculate the font size and store it in object for future
        d.fontsize = (2 * radius - 8) / d.computed * 34 + "px";
        return d.fontsize;
      }
    }
  };
  // zoom element
  const zoomTo = (v, focus, ele) => {
    var k = diameter / v[2];
    view = v;
    // d3.selectAll(".baros").transition().duration(0).style("opacity",0);
    // node.style('opacity',0);
    node.attr("transform", function(d) {
      return "translate(" + (d.x - v[0]) * k + "," + (d.y - v[1]) * k + ")";
    });

    circle.attr("r", function(d) {
      if (d && d.height == 1) {
        setTimeout(function() {
          //reste bar charts
          drawBarData("", d, d, d.r * k);
        }, 0);
        // d3.selectAll(".baros").transition().duration(0).style("opacity",1);
      }
      return d.r * k;
    });
    // node.style('opacity',1);
    // d3.selectAll(".chart-wrapper").transition().duration(0).style("opacity",1);
  };
  // const gradients = defs.selectAll("linearGradient")
  //   .data(newData)
  //   .enter().append('svg:linearGradient')
  //   .attr("id", ( d , i) => 'gradient' + i);
  // gradients.append("stop").attr("offset", 0.0).attr("stop-color", (d,i) => color(i));
  // gradients.append("stop").attr("offset", 1.0).attr("stop-color", (d,i) => color(i + 10));
  //color for circle
  // let color = d3.scaleLinear()
  //   .domain([1, 500])
  //   .range(["hsl(126,92%,50%)", "hsl(317,83%,28%)"])
  //   .interpolate(d3.interpolateHcl);
  // let color = d3.scaleLinear()
  //   .domain([0, 500])
  //   .range(["red", "green", "blue"])
  //   .interpolate(d3.interpolateRgb.gamma(2.2));
  var color = d3.scaleOrdinal()
    .domain([0,500])
    .range(d3.schemeSet1);
  // var color = d3.scaleOrdinal(d3.schemeCategory10);
  // let color = d3.scaleLinear()
  //   .domain([1, 500])
  //   .range(["orange", "white", "green"]);
  //color for bars
  // var colorBar = d3.scaleLinear()
  //   .domain([-1, 7])
  //   .range(["hsl(0, 100%, 50%)", "hsl(0, 100%, 80%)"])
  //   .interpolate(d3.interpolateHcl);
  // let color = d3.scaleSequential()
  //   .domain([0, 500])
  //   .interpolator(d3.interpolateRainbow);

  //initiate circle pack
  let pack = d3.pack()
    .size([diameter - margin, diameter - margin])
    .padding(2);

  let root = newData;
  root = d3.hierarchy(root)
    .sum(function(d) {
      return d.size;
    });

  let focus = root,
    nodes = pack(root).descendants(),
    view;
  // console.log(nodes);
// create gradient for circles and bars
  svg.append("svg:defs").selectAll('radialGradient')
    .data(['grado1'])
    .enter().append('svg:radialGradient')
    .attr("id", String)
    .attr('cx', '50%')
    .attr('cy', '50%')
    .attr('r', '75%')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(116, 215, 202)')
    .attr('stop-opacity', 1);
  svg.select('#grado1')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(116, 215, 202)')
    .attr('stop-opacity', 1);

  svg.append("svg:defs").selectAll('radialGradient')
    .data(['grado2'])
    .enter().append('svg:radialGradient')
    .attr("id", String)
    .attr('cx', '50%')
    .attr('cy', '50%')
    .attr('r', '75%')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#ffffff')
    .attr('stop-opacity', 1);
  svg.select('#grado2')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#fcfff2')
    .attr('stop-opacity', 1);
  // grey gradient
  svg.append("svg:defs").selectAll('radialGradient')
    .data(['axisGrad'])
    .enter().append('svg:radialGradient')
    .attr("id", String)
    .attr('cx', '50%')
    .attr('cy', '50%')
    .attr('r', '75%')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#b9b9b9')
    .attr('stop-opacity', 1);
  svg.select('#axisGrad')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgba(148,145,138,0.96)')
    .attr('stop-opacity', 1);
// gradient for bars
  svg.select("defs").selectAll('linearGradient')
    .data(nodes)
    .enter().append('svg:linearGradient')
    .attr("id", (d , i) => 'gradient' + i )
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', (d , i) => color(i))
    .attr('stop-opacity', 1);
  svg.selectAll('linearGradient')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', (d , i) => color(i))
    .attr('stop-opacity', 1);
// add shadow filter
  svg.append("svg:defs").selectAll('filter')
    .data(['shadowSize'])
    .enter().append('svg:filter')
    .attr("id", String)
    .attr('y', -10)
    .attr('x', -10)
    .attr('height', 40)
    .attr('width', 150)
    .append('feOffset')
    .attr('in', 'SourceAlpha')
    .attr('dx', 3)
    .attr('dy', 3)
    .attr('result', 'offset2')
    .attr('stdDeviation', 4);
  svg.selectAll('filter')
    .append('feGaussianBlur')
    .attr('in', 'offset2')
    .attr('result', 'blur2')
    .attr('stdDeviation', 3);
  svg.selectAll('filter')
    .append('feMerge')
    .append('feMergeNode')
    .attr('in', 'blur2');
  svg.selectAll('filter')
    .select('feMerge')
    .append('feMergeNode')
    .attr('in', 'SourceGraphic');

  let circle = g.selectAll("circle")
    .data(nodes.filter(function(d) {
      return d.height > 0;
    }))
    .enter().append("circle")
    .attr("class", function(d) {
      return d.parent ? d.children ? "nodos" : "nodos node--leaf" : "nodos node--root";
    })
    .attr("fill", function(d) {
      return d.parent ? d.children ? 'url(#grado2)' : null : 'rgb(116, 215, 202)';
    })
    // .attr('filter', 'url(#shadowSize)')
    .attr('id', d => 'circle_' + d.data.id )
    .on('click', null)
    .on('mouseover',function (d) {
      tool_tip.show(d, this);
    })
    .on("mouseout", function(d) {
      tool_tip.hide(); })
    .on("click", function(d) {
      //  textToVoice('you are viewing the object ' + d.data.name + ' of type ' + d.data.objectType + ' which has ' + d.children.length + ' records');
      // console.table(d.children.length);
      // console.log('d', d);
      //  d3.selectAll('text').style('display','none');
      //  $('.usageRiskSize').css('display', 'none');
      //  $('.securityRiskSize').css('display', 'none');
      $('.typeIconSize').css('display', 'none');
      $('text[id^=\'label_\']').css('display','none');
      d3.select('#label_'+d.data.id).style('display','inline');
      // d3.select('#iconUsageRisk_'+d.data.id).style('display','inline');
      // d3.select('#iconSecurity_'+d.data.id).style('display','inline');
      d3.select('#iconType_'+d.data.id).style('display','inline');
      // d3.selectAll(".baros").style("opacity",0);
      // $('.domain').css('display','none');
      // console.log(focus);
      if (focus !== d) {
        if (d.children) {
          // d3.selectAll(".chart-wrapper").style("display",'none');
          zoom(d, this); d3.event.stopPropagation();
          // $('#sizoChart').click();
          //console.log(focus);
          // $('.innerCircleTitle').css('display', 'block');
          // d3.selectAll(".chart-wrapper").style("display",'block');
        }
      } else{
        $('.typeIconSize').css('display', 'inline');
        document.getElementById('sizoChart').dispatchEvent(new Event('click'));
        // g.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');
        //  $('#sizoChart').click();
        //  $('#sizoChart').children(0).attr('transform', 'translate(450,450) scale(1)');
        // console.log(focus);
      }
    });
  // var dragcontainer = d3.drag()
  //   .on("drag", function(d, i) {
  //     d3.select(this).attr("transform", "translate(" + (d.x = d3.event.x) + "," + (d.y = d3.event.y) + ")");
  //   });
  // g.call(dragcontainer);
  // .call(d3.drag() // call specific function when circle is dragged
  //   .on("start", dragstarted)
  //   .on("drag", dragged)
  //   .on("end", dragended));
//used to capture drag position
  var start_x, start_y,current_scale_string,current_scale;

//create drag handler with d3.drag()
//   var drag_handler = d3.drag()
//     .on("start", drag_start)
//     .on("drag", drag_drag);
//
//   function drag_start(){
//     // get starting location of the drag
//     // used to offset the circle
//     start_x = +d3.event.x;
//     start_y = +d3.event.y;
//   }
//   function drag_drag(d) {
//     //Get the current scale of the circle
//     //case where we haven't scaled the circle yet
//     if (this.getAttribute("transform") === null)
//     {
//       current_scale = 1;
//     }
//     //case where we have transformed the circle
//     else {
//       current_scale_string = this.getAttribute("transform").split(' ')[1];
//       current_scale = +current_scale_string.substring(6,current_scale_string.length-1);
//     }
//     console.log(d3.select(this));
//     d3.select(this)
//       .attr("x", d.x = start_x + ((d3.event.x - start_x) / current_scale) )
//       .attr("y", d.y = start_y + ((d3.event.y - start_y) / current_scale));
//   }
  //drag_handler(g);
  // Features of the forces applied to the nodes:
  // var simulation = d3.forceSimulation()
  //   .force("center", d3.forceCenter().x(width / 2).y(height / 2)) // Attraction to the center of the svg area
  //   .force("charge", d3.forceManyBody().strength(.1)) // Nodes are attracted one each other of value is > 0
  //   .force("collide", d3.forceCollide().strength(.2).radius(function(d){ return (d.r ); }).iterations(1)); // Force that avoids circle overlapping
  //
  // // Apply these forces to the nodes and update their positions.
  // // Once the force algorithm is happy with positions ('alpha' value is low enough), simulations will stop.
  // simulation
  //   .nodes(nodes)
  //   .on("tick", function(d){
  //     node
  //       .attr("cx", function(d){ return d.x; })
  //       .attr("cy", function(d){ return d.y; })
  //   });

  // What happens when a circle is dragged?
  // function dragstarted(d) {
  //   // if (!d3.event.active) simulation.alphaTarget(.03).restart();
  //   d.fx = d.x;
  //   d.fy = d.y;
  // }
  // function dragged(d) {
  //   d.fx = d3.event.x;
  //   d.fy = d3.event.y;
  // }
  // function dragended(d) {
  //   // if (!d3.event.active) simulation.alphaTarget(.03);
  //   d.fx = null;
  //   d.fy = null;
  // }


  //platform to draw charts
  var leaf = g.selectAll(".baros")
    .data(nodes.filter(function(d) {
      //get all leaf node data
      return d.height == 1;
    }))
    .enter()
    .append("g")
    // .attr("id", (d) => d.parent.data.id+"_"+d.data.id)
    .attr("id", (d) => 'bars_' + d.data.id)
    .attr("height", function(d) {
      return d.x + d.r;
    })
    .attr("width", function(d) {
      return d.y + d.r;
    })
    .attr("class", "baros")
    .each(function(d) {
      drawBarData(this, this.__data__, d, 0);
    });


  var text = g.selectAll(".label")
    .data(nodes.filter(function(d) {
      return d.height > 0;
    }))
    .enter().append("text")
    .attr("class", "label")
    .style("fill-opacity", function(d) {
      return d.parent === root ? 1 : 0;
    })
    .style("display", function(d) {
      return d.parent === root ? "inline" : "none";
    })
    .attr("y", function(d, i) {
      d.titleHeight = (-1 + 0.25) * d.r;
      return d.titleHeight;
    })
    .attr("id", function(d) {
      return 'label_'+ d.data.id;//setting text name as the ID
    })
    .attr("dy","0em")
    .text(function(d,i) { return d.name; })
    .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
      return Math.round(d.fontTitleSize)+"px";
    })
    // .text(function(d,i) { return "Total records "+commaFormat(d.data.size)+" (in thousands) | "+d.data.name; })
    // .each(function(d) { wrap(this, d.r * 2 * 0.7); });
    .each(function(d) {
      d.textLength = d.r*2*0.7;
      wrap(this, d.textLength);
    })
    .text(function(d) {
      return d.data.name;
    });
// add icon usage risk
  let usageRiskIcon = g.selectAll('.usageRiskSize')
    .data(nodes.filter(function(d) {
      return d.height === 1;
      // return d.type!='model';
    }))
    .enter()
    .append('text')
    .attr('id', d => 'iconUsageRisk_' + d.data.id)
    .attr('class', 'usageRiskSize')
    .attr("dy","0em")
    .attr('display', 'none')
    .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
      return Math.round(d.fontTitleSize)+"px";
    })
    .attr('fill',  d => getUsageRisk(d.data.usageRisk) )
    .style("display", function(d) {
      return d.parent === root ? "inline" : "none";
    })
    .attr("y", function(d, i) {
      d.titleHeight = (-1 + 0.40) * d.r;
      return d.titleHeight;
    })
    .attr("x", function(d, i) {
      // const dim = d3.select('#label_' + d.data.id).node().getBBox();
      // return dim.x - 12;
      return -d.r * 0.4 - d.titleHeight / 2 ;
      // return (-d.r * 0.4) - d.titleHeight  ;
    })
    .text(function(d) { return '\uf111'; });
// add icon security risk
  let securityRiskIcon = g.selectAll('.securityRiskSize')
    .data(nodes.filter(function(d) {
      return d.height === 1;
    }))
    .enter()
    .append('text')
    .attr('display', 'none')
    .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
      return Math.round(d.fontTitleSize)+"px";
    })
    .attr('id', d => 'iconSecurity_' + d.data.id)
    .attr('class', 'securityRiskSize')
    .attr("dy","0em")
    .attr('fill', d => getUsageRisk(d.data.criticity))
    .style("display", function(d) {
      return d.parent === root ? "inline" : "none";
    })
    .attr("y", function(d, i) {
      d.titleHeight = (-1 + 0.40) * d.r;
      return d.titleHeight;
    })
    .attr("x", function(d, i) {
      // const dim = d3.select('#label_' + d.data.id).node().getBBox();
      // return dim.x ;
      return (-d.r * 0.4) - d.titleHeight ;
    })
    .text(function(d) { return '\uf071'; });

// add icon type
  let typeIcon = g.selectAll('.typeIconSize')
    .data(nodes.filter(function(d) {
      return d.height === 1;
    }))
    .enter()
    .append('text')
    .attr('class', 'typeIconSize')
    .attr("dy","0em")
    .attr('id', d => 'iconType_' +d.data.id)
    .attr('fill', 'blue')
    .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
      return Math.round(d.fontTitleSize)+"px";
    })
    .style("display", function(d) {
      return d.parent === root ? "inline" : "none";
    })
    .attr("y", function(d, i) {
      d.titleHeight = (-1 + 0.10) * d.r;
      return d.titleHeight;
    })
    .attr("x", function(d, i) {
      // const dim = d3.select('#label_' + d.data.id).node().getBBox();
      //  return dim.x + 12 ;
      return -d.r * 0.4 - d.titleHeight / 2 ;
    })
    .text(d => getIcon(d.data.objectType));
  var node = g.selectAll("circle,.baros,.label,.usageRiskSize,.securityRiskSize,.typeIconSize");
// icon close for campain
  svg.append('g')
    .append('text')
    .attr('class', 'campain_close')
    .attr('id', 'campain_close')
    .attr('fill', 'red')
    .attr('dy', '2.2em')
    .attr('dx', "16em")
    .style('display', 'none')
    .on('click', () =>{
        $('.taskshown >td > a.dataTaskDetail').click();
      $('#campain_close').hide();
      $('#sizoChart').empty().hide();
      $('#sizoCampainTaskChart').show()
    })
    .text('\uf057');
  // end icon close
  svg
  // .style("background", color(-1))
    .on("click", function() {
      zoom(root, this);
    });
  // @ts-ignore
  zoomTo([root.x, root.y, root.r * 2 + margin]);
  // console.log(root.x, root.y, root.r * 2 + margin);
  const zoom = function(d, ele) {
    // d3.selectAll('.baros').style('display', 'none');
    var focus0 = focus;
    focus = d;
    var transition = d3.transition()
    // .duration(d3.event.altKey ? 500 : 50)
      .duration(0)
      .tween("zoom", function(d) {
        // @ts-ignore
        var i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2 + margin]);
        return function(t) {
          zoomTo(i(t), focus, ele);
        };
      });
    // usage risk icon transition zoom
    transition.selectAll(".usageRiskSize")
      .filter(function(d) {
        return d.parent === focus || this.style.display === "inline";
      })
      .attr("x", function(d, i) {
        if(d.parent === focus ) {
          return -d.r * 0.4 - d.titleHeight / 2 ;
        }else{
          return (-d.r * 0.4) + 80 ;
        }
      })
      .style('font-size', function (d) {
        if(d.parent === focus ) {
          d.fontTitleSize = d.r / 10;
          return Math.round(d.fontTitleSize)+"px";
        }else{
          return '15px';
        }
      })
      .attr('y', function (d) {
        if(d.parent === focus ) {
          // d.titleHeight = (-1 + 0.35) * d.r;
          return d.titleHeight + d.fontTitleSize;

        }else{
          //  console.log(focus);
          return '-210';
        }
      });
    // security risk icon transition zoom
    transition.selectAll(".securityRiskSize")
      .filter(function(d) {
        return d.parent === focus || this.style.display === "inline";
      })
      .attr("x", function(d, i) {
        // console.log(d);
        //let dim = d3.select('#securityRiskSize' + d.data.id).node().getBBox();
        if(d.parent === focus ) {
          return (-d.r * 0.4) - d.titleHeight ;
        }else{
          return (-d.r * 0.4)  - 20 ;
        }
      })
      .style('font-size', function (d) {
        if(d.parent === focus ) {
          d.fontTitleSize = d.r / 10;
          return Math.round(d.fontTitleSize)+"px";
        }else{
          return '15px';
        }
      })
      .attr('y', function (d) {
        if(d.parent === focus ) {
          //  d.titleHeight = (-1 + 0.35) * d.r;
          return d.titleHeight + d.fontTitleSize;

        }else{
          //  console.log(focus);
          return '-210';
        }
      });
    //  icon type transition zoom
    transition.selectAll(".typeIconSize")
      .filter(function(d) {
        return d.parent === focus || this.style.display === "inline";
      })
      .attr("x", function(d, i) {
        if(d.parent === focus ) {
          return -d.r * 0.4 - d.titleHeight / 2 ;
          //return (-d.r * 0.4) ;
        }else{
          return (-d.r * 0.4) + 80 ;
        }
      })
      .style('font-size', function (d) {
        if(d.parent === focus ) {
          d.fontTitleSize = d.r / 10;
          return Math.round(d.fontTitleSize)+"px";
        }else{
          return '15px';
        }
      })
      .attr('y', function (d) {
        if(d.parent === focus ) {
          // d.titleHeight = (-1 + 0.1) * d.r;
          return d.titleHeight - d.fontTitleSize;

        }else{
          return '-260';
        }
      });
    // label transition zoom
    transition.selectAll(".label")
      .filter(function(d) {
        if(d!=undefined){
          return d.parent === focus || this.style.display === "inline";
        }
      }).text(function (d) {
      if(d.parent === focus ) {
        $('#sizoChart').click();
        // $('.axis.axis--x').css('display','none');
        return d.data.name;
      }else{
        // $('.axis.axis--x').css('display','block');
        if(d.data === undefined){ return;}
        if(d.data.size > 1){
          return d.data.name + ' ' +d.data.size +' tables';
        }
        else{
          return d.data.name + ' ' +d.data.size +' tables';
        }
      }
    })
      .attr('y', function (d) {
        if(d.parent === focus ) {
          d3.selectAll('.label').style('display','inline');
          // $('text[id^=\'label_\']').css('display', 'inline');
          // d3.selectAll(".baros").style("opacity",1);
          // d.titleHeight = (-1 + 0.25) * d.r;
          d.titleHeight = (-1 + 0.25) * d.r;
          return d.titleHeight;
          //return d.titleHeight;

        }else{
          //  console.log(focus);
          return '-230';
        }
      })
      // .style("font-family", function(d) {
      //   if(d.parent === focus ) {
      //     return "";
      //   }else{
      //
      //     return "25px";
      //   }
      // })
      .style("font-size", function(d) {
        if(d.parent === focus ) {
          // d3.selectAll('.baros').style('display','none');
          // d3.selectAll('.baros').transition().duration(100).style('display','block');
          d3.selectAll('.subTotal')
            .style('font-size','13px')
            .attr('y' ,function (d) {
              const y = $(this).parent().attr('y');
              return y;
            });
          d3.selectAll('.objectTitle')
            .attr('y',function (d) {
              const y = $(this).parent().attr('y');
              return y;
            });
          // $('.chart-wrapper').css('transform','"rotate( 0 deg)"');
          // $('.chart-wrapper').css('transform','rotate(0deg) translate(0px,0px)');
          // console.log($('.chart-wrapper'));
          // Calculate best font-size
          d3.selectAll('.subTotal').style('font-size','0');
          d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
          return Math.round(d.fontTitleSize)+"px";
        }else{
          d3.selectAll('.subTotal')
            .style('font-size','13px')
            .attr('y' ,function (d) {
              const id = $(this).parent().attr('id').split('_')[1];
              const radius = d3.select('#circle_' + id).attr('r');
              // console.log(radius);
              return -radius * 2;
            });
          d3.selectAll('.objectTitle')
            .attr('y',function (d) {
              const id = $(this).parent().attr('id').split('_')[1];
              const radius = d3.select('#circle_' + id).attr('r');
              // console.log(radius);
              return -radius * 1.5;
            });
          // $('.chart-wrapper').css('transform','rotate(90deg) translate(-200px,-300px)');
          // $('.chart-wrapper').css('transform','"rotate(90deg) translate(-200px,-300px)"');
          return "20px";
        }
      });
    // transition.selectAll(".chart-wrapper")
    //   .filter(function(d) {
    //     return d.parent === focus || this.style.display === "block";
    //   })
    //   .style('transform', function (d) {
    //     if(d.parent === focus ) {
    //       return 'rotate(0)';
    //     }else{
    //       return 'rotate(90)';
    //     }
    //   })
    // .style('y', function(d){
    //   const id = d3.select(this).attr('id').split('_')[1];
    //   let radius = 1;
    //   var r = d3.selectAll("#circle_" + id).attr("r");
    //   //if radius present in DOM use that
    //   if (r) {
    //     radius = r;
    //   }
    //
    //   return r -5 ;
    // } );
    // .style("fill-opacity", function(d) {
    //   return d.parent === focus ? 1 : 0;
    // })
    // .style("font-size", function(d) {
    //   //Calculate best font-size
    //   d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
    //   return Math.round(d.fontTitleSize)+"px";
    // })
    // .each(function(d) {
    //   d.textLength = d.r*2*0.7;
    //   wrap(this, d.textLength);
    // });
    // .style("font-size", calculateTextFontSize);
    // .on("start", function(d) {
    //   // console.log('--------------------------------------------------------------------start');
    //   // console.log(focus);
    //   if (d.parent === focus) {console.log('start'); this.style.display = "inline"; d3.select($('.innerCircleTitle')[0]).attr('style', 'display: none!important')}
    // })
    // .on("end", function(d) {
    //   // console.log('--------------------------------------------------------------------end');
    //   // console.log(focus);
    //   if (d.parent !== focus) {console.log(d3.select($('.innerCircleTitle')[0])); console.log('end'+ ' ' + '#text_' + focus.data.id);this.style.display = "none"; d3.select($('.innerCircleTitle')[0]).attr('style', 'display: block!important')}
    // });
    // transition.selectAll(".innerCircleTitle")
    //   .filter(function(d) {
    //     return d.parent.parent.parent === focus || this.style.display === "inline";
    //   })
    //   .style("fill-opacity", function(d) {
    //     return d.parent.parent.parent === focus ? 1 : 0;
    //   })
    //   .on("start", function(d) {
    //     if (d.parent.parent.parent === focus) this.style.display = "inline";
    //   })
    //   .on("end", function(d) {
    //     if (d.parent.parent.parent !== focus) this.style.display = "none";
    //   });
    // d3.select('.baros').style('display', 'block');

  };
  $('#zoomIn_button').on('click',function(){
    svg.transition().call(Zoomos.scaleBy, 2);
  });
  $('#zoomOut_button').on('click',function(){
    svg.transition().call(Zoomos.scaleBy, 0.5);
  });
  $('#resetSize_button').on('click',function(){
    // $('#sizoChart').children(0).attr('transform', 'translate(' + width + ',' + height + ') scale(1)');
    svg.transition().duration(750).call(
      Zoomos.transform,
      d3.zoomIdentity,
      d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
    );
  });
  // $('#chart_info_size').unbind().on('click',function(){
  // // textToVoice('the big cyan circle is the model '+ newData.name+' and the white circles are it\'s objects, by clicking in one of them it will zoom and show you a a bar chart representing the records with their number of fields');
  // });
  $('#sizoChart').on('click',function(d) {
    d3.selectAll('.typeIconSize').style('display','inline')
    // .attr("y", function(d, i) {
    //   d.titleHeight = (-1 + 0.10) * d.r;
    //   return d.titleHeight;
    // })
    // .attr("x", function(d, i) {
    //   // const dim = d3.select('#label_' + d.data.id).node().getBBox();
    //   //  return dim.x + 12 ;
    //   return -d.r * 0.4 - d.titleHeight / 2 ;
    // });
    //  document.getElementById('sizeChartGlobal').dispatchEvent(new Event('click'));
  });
  // function createLegend(scaleFactor) {
  //   const legendSizes = [10, 20];
  //   const  commaFormat = d3.format(',');
  //   const legendTexts = [ 'Object','Model'];
  //   //d3.select("#legendRowWrapper").style("opacity", 0);
  //
  //   let width = $("#legendCircles").empty().width(),
  //     height = legendSizes[1]*2*1.2;
  //
  //   let	legendCenter = -10,
  //     legendBottom = height,
  //     legendLineLength = legendSizes[1]*1.3,
  //     textPadding = 5;
  //
  //   //Create SVG for the legend
  //   const legends = d3.select("#legendCircles").append("svg")
  //     .attr("width", width)
  //     .attr("height", height*1.5)
  //     .append("g")
  //     .attr("class", "legendWrapper")
  //     .attr("transform", "translate(" + width / 2 + "," + 0 + ")")
  //   //  .style("opacity", 0);
  //
  //   //Draw the circles
  //   legends.selectAll(".legendCircle")
  //     .data(legendSizes)
  //     .enter().append("circle")
  //     .attr('r', function(d) { return d; })
  //     .attr('class',"legendCircle")
  //     .attr('cx', legendCenter)
  //     .attr('cy', function(d) { return legendBottom-d; });
  //   legends.append('rect')
  //     .attr('x' , legendCenter)
  //     .attr('y' , legendBottom +10)
  //     .attr('width', 10)
  //     .attr('height', 10)
  //     .attr("rx", 3)
  //     .attr("ry", 3)
  //     .attr('fill', '#4daf4a');
  //   //Draw the line connecting the top of the circle to the number
  //   legends.selectAll(".legendLine")
  //     .data(legendSizes)
  //     .enter().append("line")
  //     .attr('class',"legendLine")
  //     .attr('x1', legendCenter)
  //     .attr('y1', function(d) { return legendBottom-2*d; })
  //     .attr('x2', legendCenter + legendLineLength)
  //     .attr('y2', function(d) { return legendBottom-2*d; });
  //   //Place the value next to the line
  //   legends.selectAll(".legendText")
  //     .data(legendSizes)
  //     .enter().append("text")
  //     .attr('class',"legendText")
  //     .attr('x', legendCenter + legendLineLength + textPadding)
  //     .attr('y', function(d) { return legendBottom-2*d; })
  //     .attr('dy', '0.3em')
  //     .text(function(d , i) { return legendTexts[i]; });
  //   legends.append('text')
  //     .attr('class', 'legendText')
  //     .attr('x', legendCenter + legendLineLength + textPadding)
  //     .attr('y', legendBottom + 17)
  //     .text('Principal Table');
  // }//createLegend
  // createLegend(1);

};
export const showHideNode = (id , hide = true) => {
  if(hide) {$('#circle_' + id).hide('slow'); $('#bars_' + id).hide('slow'); $('#label_' + id).hide('slow'); } else{
    $('#circle_' + id).show('slow'); $('#bars_' + id).show('slow'); $('#label_' + id).show('slow');
  }
};
export const createLegend =(scaleFactor, id, legendSizes, legendTexts) => {
 // const legendSizes = [10, 20, 30];
  const  commaFormat = d3.format(',');
 // const legendTexts = [ 'Table','Object','Model'];
  //d3.select("#legendRowWrapper").style("opacity", 0);
  const offset = legendSizes.length - 1;
  let width = $('#' + id).empty().width(),
    height = legendSizes[offset]*2*1.2;

  let	legendCenter = -10,
    legendBottom = height,
    legendLineLength = legendSizes[offset]*1.3,
    textPadding = 5;

  //Create SVG for the legend
  const legends = d3.select("#" + id).append("svg")
    .attr("width", width)
    .attr("height", height*1.5)
    .append("g")
    .attr("class", "legendWrapper")
    .attr("transform", "translate(" + width / 2 + "," + 0 + ")");
  //  .style("opacity", 0);

  //Draw the circles
  legends.selectAll(".legendCircle")
    .data(legendSizes)
    .enter().append("circle")
    .attr('r', function(d) { return d; })
    .attr('class',"legendCircle")
    .attr('cx', legendCenter)
    .attr('cy', function(d) { return legendBottom-d; });
  legends.append('rect')
    .attr('x' , legendCenter)
    .attr('y' , legendBottom + 10)
    .attr('width', 10)
    .attr('height', 10)
    .attr("rx", 3)
    .attr("ry", 3)
    .attr('fill', '#4daf4a');
  //Draw the line connecting the top of the circle to the number
  legends.selectAll(".legendLine")
    .data(legendSizes)
    .enter().append("line")
    .attr('class',"legendLine")
    .attr('x1', legendCenter)
    .attr('y1', function(d) { return legendBottom-2*d; })
    .attr('x2', legendCenter + legendLineLength)
    .attr('y2', function(d) { return legendBottom-2*d; });
  //Place the value next to the line
  legends.selectAll(".legendText")
    .data(legendSizes)
    .enter().append("text")
    .attr('class',"legendText")
    .attr('x', legendCenter + legendLineLength + textPadding)
    .attr('y', function(d) { return legendBottom-2*d; })
    .attr('dy', '0.3em')
    .text(function(d , i) { return legendTexts[i]; });
  legends.append('text')
    .attr('class', 'legendText')
    .attr('x', legendCenter + legendLineLength + textPadding)
    .attr('y', legendBottom + 17)
    .text('Principal Table');
};//createLegend
// create legend for chart node
export const createNodeLegend = () => {
  const labels = ['object','group'];
  const colors = ['url(#grad1)', 'url(#grad3)'];
  const ys = [40, 0];
  const textsYs = [50, 10];
  $('#legendNode').empty();
  //Create SVG for the legend
  const legends = d3.select("#legendNode").append("svg")
    .attr("width", 180)
    .attr("height", 75)
    .append("g")
    .attr("class", "legendWrapper")
    .attr("transform", "translate(75,0)");
  legends.selectAll(".legendRect")
    .data(labels)
    .enter().append("rect")
    .attr('x' , -10)
    .attr('y' , (d,i) => ys[i])
    .attr('width', 50)
    .attr('height', 20)
    .attr('class', 'legendRect')
    .attr("rx", 3)
    .attr("ry", 3)
    .attr('fill', (d , i) => colors[i] );
  legends.selectAll('.legendLabel')
    .data(labels)
    .enter().append('text')
    .attr('class', 'legendLabel')
    .attr('x', 60)
    .attr('y', (d,i) =>  textsYs[i] )
    .text((d , i) => labels[i] );
};
// campain legends
export const createCampainLegend =(scaleFactor, id, legendSizes, legendTexts) => {
  // const legendSizes = [10, 20, 30];
  const  commaFormat = d3.format(',');
  // const legendTexts = [ 'Table','Object','Model'];
  //d3.select("#legendRowWrapper").style("opacity", 0);
  const offset = legendSizes.length - 1;
  let width = $('#' + id).empty().width(),
    height = legendSizes[offset]*2*1.2;

  let	legendCenter = -10,
    legendBottom = height,
    legendLineLength = legendSizes[offset]*1.3,
    textPadding = 5;

  //Create SVG for the legend
  const legends = d3.select("#" + id).append("svg")
    .attr("width", width)
    .attr("height", height*1.5)
    .append("g")
    .attr("class", "legendWrapper")
    .attr("transform", "translate(" + width / 2 + "," + 0 + ")");
  //  .style("opacity", 0);

  //Draw the circles
  legends.selectAll(".legendCircle")
    .data(legendSizes)
    .enter().append("circle")
    .attr('r', function(d) { return d; })
    .attr('class',"legendCircle")
    .attr('cx', legendCenter)
    .attr('cy', function(d) { return legendBottom-d; });
  legends.append('rect')
    .attr('x' , legendCenter)
    .attr('y' , legendBottom + 10)
    .attr('width', 10)
    .attr('height', 10)
    .attr("rx", 3)
    .attr("ry", 3)
    .attr('fill', '#4daf4a');
  //Draw the line connecting the top of the circle to the number
  legends.selectAll(".legendLine")
    .data(legendSizes)
    .enter().append("line")
    .attr('class',"legendLine")
    .attr('x1', legendCenter)
    .attr('y1', function(d) { return legendBottom-2*d; })
    .attr('x2', legendCenter + legendLineLength)
    .attr('y2', function(d) { return legendBottom-2*d; });
  //Place the value next to the line
  legends.selectAll(".legendText")
    .data(legendSizes)
    .enter().append("text")
    .attr('class',"legendText")
    .attr('x', legendCenter + legendLineLength + textPadding)
    .attr('y', function(d) { return legendBottom-2*d; })
    .attr('dy', '0.3em')
    .text(function(d , i) { return legendTexts[i]; });
  legends.append('text')
    .attr('class', 'legendText')
    .attr('x', legendCenter + legendLineLength + textPadding)
    .attr('y', legendBottom + 17)
    .text('Principal Table');
};//createLegend
// create legend for chart node campain
export const createCampainNodeLegend = () => {
  const labels = ['Done','Failed', 'To be Processed'];
  const colors = ['url(#gradOk)', 'url(#gradKo)', 'url(#gradToBe)'];
  const ys = [80,40, 0];
  const textsYs = [90,50, 10];
  $('#legendTreeNode').empty();
  //Create SVG for the legend
  const legends = d3.select("#legendTreeNode").append("svg")
    .attr("width", 250)
    .attr("height", 105)
    .append("g")
    .attr("class", "legendWrapper")
    .attr("transform", "translate(75,0)");
  legends.selectAll(".legendRect")
    .data(labels)
    .enter().append("rect")
    .attr('x' , -10)
    .attr('y' , (d,i) => ys[i])
    .attr('width', 50)
    .attr('height', 20)
    .attr('class', 'legendRect')
    .attr("rx", 3)
    .attr("ry", 3)
    .attr('fill', (d , i) => colors[i] );
  legends.selectAll('.legendLabel')
    .data(labels)
    .enter().append('text')
    .attr('class', 'legendLabel')
    .attr('x', 60)
    .attr('y', (d,i) =>  textsYs[i] )
    .text((d , i) => labels[i] );
};
