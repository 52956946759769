import {ArchiveIds} from './ArchiveIds';

export class CampainJson{
  name: string;
  responsible:string;
  type:string;
  status:string;
  description:string;
  date:string;
  archiveIds: ArchiveIds[];
  addedBy: string;
  updateBy: string;
  application: string;
  base: string;
  constructor(name: string, responsible: string, type: string, status: string, description: string, date: string, archiveIds: ArchiveIds[],addedBy: string, updateBy: string, application: string, base: string) {
    this.name = name;
    this.responsible = responsible;
    this.type = type;
    this.status = status;
    this.description = description;
    this.date = date;
    this.archiveIds = archiveIds;
    this.addedBy = addedBy;
    this.updateBy = updateBy;
    this.application = application;
    this.base = base;
  }
}
