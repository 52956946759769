import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RulesRoutingModule } from './rules-routing.module';
import {MainRulesComponent} from './main-rules/main-rules.component';


@NgModule({
  declarations: [MainRulesComponent],
  imports: [
    CommonModule,
    RulesRoutingModule
  ]
})
export class RulesModule { }
