// theme eduardo
export const eduardo = () => {
  const colorPalette = [
    '#59535e',
    '#e7dcef',
    '#f1baf3',
    '#5d4970',
    '#372049',
    '#c0b2cd',
    '#ffccff',
    '#f2f0f5'
  ];

  return {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#59535e'
      }
    },

    visualMap: {
      color: ['#59535e', '#e7dcef']
    },

    toolbox: {
      color: ['#59535e', '#59535e', '#59535e', '#59535e']
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#59535e',
          type: 'dashed'
        },
        crossStyle: {
          color: '#59535e'
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)'
        }
      }
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(200,200,200,0.2)', // Fill the color
      handleColor: '#59535e' // Handle color
    },

    timeline: {
      lineStyle: {
        color: '#59535e'
      },
      controlStyle: {
        color: '#59535e',
        borderColor: '#59535e'
      }
    },

    candlestick: {
      itemStyle: {
        color: '#e7dcef',
        color0: '#f1baf3'
      },
      lineStyle: {
        width: 1,
        color: '#372049',
        color0: '#5d4970'
      },
      areaStyle: {
        color: '#59535e',
        color0: '#e7dcef'
      }
    },

    chord: {
      padding: 4,
      itemStyle: {
        color: '#59535e',
        borderWidth: 1,
        borderColor: 'rgba(128, 128, 128, 0.5)'
      },
      lineStyle: {
        color: 'rgba(128, 128, 128, 0.5)'
      },
      areaStyle: {
        color: '#e7dcef'
      }
    },

    map: {
      itemStyle: {
        color: '#ddd'
      },
      areaStyle: {
        color: '#f1baf3'
      },
      label: {
        color: '#c12e34'
      }
    },

    graph: {
      itemStyle: {
        color: '#59535e'
      },
      linkStyle: {
        color: '#59535e'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#e7dcef'],
            [0.8, '#59535e'],
            [1, '#372049']
          ],
          width: 8
        }
      }
    }
  };
};
// theme macarons2
export const macarons2 = () => {
  const colorPalette = [
    '#ed9678',
    '#e7dac9',
    '#cb8e85',
    '#f3f39d',
    '#c8e49c',
    '#f16d7a',
    '#f3d999',
    '#d3758f',
    '#dcc392',
    '#2e4783',
    '#82b6e9',
    '#ff6347',
    '#a092f1',
    '#0a915d',
    '#eaf889',
    '#6699FF',
    '#ff6666',
    '#3cb371',
    '#d5b158',
    '#38b6b6'
  ];

  return{
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#cb8e85'
      }
    },

    dataRange: {
      color: ['#cb8e85', '#e7dac9'],
      textStyle: {
        color: '#333'
      }
    },

    bar: {
      barMinHeight: 0,
      barGap: '30%',
      barCategoryGap: '20%',
      itemStyle: {
        normal: {
          // color: '各异',
          barBorderColor: '#fff', // 柱条边线
          barBorderRadius: 0, // 柱条边线圆角，单位px，默认为0
          barBorderWidth: 1, // 柱条边线线宽，单位px，默认为1
          label: {
            show: false
            // position: 默认自适应，水平布局为'top'，垂直布局为'right'，可选为
            //           'inside'|'left'|'right'|'top'|'bottom'
            // textStyle: null      // 默认使用全局文本样式，详见TEXTSTYLE
          }
        },
        emphasis: {
          // color: '各异',
          barBorderColor: 'rgba(0,0,0,0)', // 柱条边线
          barBorderRadius: 0, // 柱条边线圆角，单位px，默认为0
          barBorderWidth: 1, // 柱条边线线宽，单位px，默认为1
          label: {
            show: false
            // position: 默认自适应，水平布局为'top'，垂直布局为'right'，可选为
            //           'inside'|'left'|'right'|'top'|'bottom'
            // textStyle: null      // 默认使用全局文本样式，详见TEXTSTYLE
          }
        }
      }
    },

    line: {
      itemStyle: {
        normal: {
          label: {
            show: false
          },
          lineStyle: {
            width: 2,
            type: 'solid',
            shadowColor: 'rgba(0,0,0,0)', // 默认透明
            shadowBlur: 5,
            shadowOffsetX: 3,
            shadowOffsetY: 3
          }
        },
        emphasis: {
          label: {
            show: false
          }
        }
      },
      symbolSize: 2,
      showAllSymbol: false
    },

    candlestick: {
      itemStyle: {
        color: '#fe9778',
        color0: '#e7dac9'
      },
      lineStyle: {
        width: 1,
        color: '#f78766',
        color0: '#f1ccb8'
      },
      areaStyle: {
        color: '#e7dac9',
        color0: '#c8e49c'
      }
    },

    // 饼图默认参数
    pie: {
      center: ['50%', '50%'], // 默认全局居中
      radius: [0, '75%'],
      clockWise: false, // 默认逆时针
      startAngle: 90,
      minAngle: 0, // 最小角度改为0
      selectedOffset: 10, // 选中是扇区偏移量
      itemStyle: {
        normal: {
          // color: 各异,
          borderColor: '#fff',
          borderWidth: 1,
          label: {
            show: true,
            position: 'outer',
            textStyle: { color: '#1b1b1b' },
            lineStyle: { color: '#1b1b1b' }
            // textStyle: null      // 默认使用全局文本样式，详见TEXTSTYLE
          },
          labelLine: {
            show: true,
            length: 20,
            lineStyle: {
              // color: 各异,
              width: 1,
              type: 'solid'
            }
          }
        }
      }
    },

    map: {
      itemStyle: {
        color: '#ddd',
        borderColor: '#fff',
        borderWidth: 1
      },
      areaStyle: {
        color: '#f3f39d'
      },
      label: {
        show: false,
        color: 'rgba(139,69,19,1)'
      },
      showLegendSymbol: true
    },

    graph: {
      itemStyle: {
        color: '#d87a80'
      },
      linkStyle: {
        strokeColor: '#a17e6e'
      },
      nodeStyle: {
        brushType: 'both',
        strokeColor: '#a17e6e'
      },
      label: {
        show: false
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#ed9678'],
            [0.8, '#e7dac9'],
            [1, '#cb8e85']
          ],
          width: 8
        }
      }
    }
  };
};
// theme cool
export const cool = () => {
  const colorPalette = [
    '#b21ab4',
    '#6f0099',
    '#2a2073',
    '#0b5ea8',
    '#17aecc',
    '#b3b3ff',
    '#eb99ff',
    '#fae6ff',
    '#e6f2ff',
    '#eeeeee'
  ];
  return{
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#00aecd'
      }
    },

    visualMap: {
      color: ['#00aecd', '#a2d4e6']
    },

    toolbox: {
      color: ['#00aecd', '#00aecd', '#00aecd', '#00aecd']
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#00aecd',
          type: 'dashed'
        },
        crossStyle: {
          color: '#00aecd'
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)'
        }
      }
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(144,197,237,0.2)', // Fill the color
      handleColor: '#00aecd' // Handle color
    },

    timeline: {
      lineStyle: {
        color: '#00aecd'
      },
      controlStyle: {
        color: '#00aecd',
        borderColor: '00aecd'
      }
    },

    candlestick: {
      itemStyle: {
        color: '#00aecd',
        color0: '#a2d4e6'
      },
      lineStyle: {
        width: 1,
        color: '#00aecd',
        color0: '#a2d4e6'
      },
      areaStyle: {
        color: '#b21ab4',
        color0: '#0b5ea8'
      }
    },

    chord: {
      padding: 4,
      itemStyle: {
        color: '#b21ab4',
        borderWidth: 1,
        borderColor: 'rgba(128, 128, 128, 0.5)'
      },
      lineStyle: {
        color: 'rgba(128, 128, 128, 0.5)'
      },
      areaStyle: {
        color: '#0b5ea8'
      }
    },

    graph: {
      itemStyle: {
        color: '#b21ab4'
      },
      linkStyle: {
        color: '#2a2073'
      }
    },

    map: {
      itemStyle: {
        color: '#c12e34'
      },
      areaStyle: {
        color: '#ddd'
      },
      label: {
        color: '#c12e34'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#dddddd'],
            [0.8, '#00aecd'],
            [1, '#f5ccff']
          ],
          width: 8
        }
      }
    }
  };
};
// theme azul
export const azul = () => {
  const colorPalette = [
    '#f2385a',
    '#f5a503',
    '#4ad9d9',
    '#f7879c',
    '#c1d7a8',
    '#4dffd2',
    '#fccfd7',
    '#d5f6f6'
  ];

  return {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#f2385a'
      }
    },

    visualMap: {
      color: ['#f2385a', '#f5a503']
    },

    toolbox: {
      color: ['#f2385a', '#f2385a', '#f2385a', '#f2385a']
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#f2385a',
          type: 'dashed'
        },
        crossStyle: {
          color: '#f2385a'
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)'
        }
      }
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(200,200,200,0.2)', // Fill the color
      handleColor: '#f2385a' // Handle color
    },

    timeline: {
      lineStyle: {
        color: '#f2385a'
      },
      controlStyle: {
        color: '#f2385a',
        borderColor: '#f2385a'
      }
    },

    candlestick: {
      itemStyle: {
        color: '#f2385a',
        color0: '#f5a503'
      },
      lineStyle: {
        width: 1,
        color: '#f2385a',
        color0: '#f5a503'
      },
      areaStyle: {
        color: '#c1d7a8',
        color0: '#4ad9d9'
      }
    },

    map: {
      itemStyle: {
        color: '#f2385a'
      },
      areaStyle: {
        color: '#ddd'
      },
      label: {
        color: '#c12e34'
      }
    },

    graph: {
      itemStyle: {
        color: '#f2385a'
      },
      linkStyle: {
        color: '#f2385a'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#f5a503'],
            [0.8, '#f2385a'],
            [1, '#c1d7a8']
          ],
          width: 8
        }
      }
    }
  };
};
// theme mint
export const mint = () => {
  const colorPalette = [
    '#8aedd5',
    '#93bc9e',
    '#cef1db',
    '#7fe579',
    '#a6d7c2',
    '#bef0bb',
    '#99e2vb',
    '#94f8a8',
    '#7de5b8',
    '#4dfb70'
  ];

  return {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#8aedd5'
      }
    },

    toolbox: {
      color: ['#8aedd5', '#8aedd5', '#8aedd5', '#8aedd5']
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#8aedd5',
          type: 'dashed'
        },
        crossStyle: {
          color: '#8aedd5'
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)'
        }
      }
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(64,136,41,0.2)', // Fill the color
      handleColor: '#408829' // Handle color
    },

    dataRange: {
      color: ['#93bc92', '#bef0bb']
    },

    candlestick: {
      itemStyle: {
        color: '#8aedd5',
        color0: '#7fe579'
      },
      lineStyle: {
        width: 1,
        color: '#8aedd5',
        color0: '#7fe579'
      },
      areaStyle: {
        color: '#8aedd5',
        color0: '#93bc9e'
      }
    },

    graph: {
      itemStyle: {
        color: '#8aedd5'
      },
      linkStyle: {
        color: '#93bc9e'
      }
    },

    map: {
      itemStyle: {
        color: '#8aedd5'
      },
      areaStyle: {
        color: '#93bc9e'
      },
      label: {
        color: '#cef1db'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#93bc9e'],
            [0.8, '#8aedd5'],
            [1, '#a6d7c2']
          ],
          width: 8
        }
      }
    }
  };
};
// theme caravan
export const caravan = () => {
  const colorPalette = [
    '#fad089',
    '#ff9c5b',
    '#f5634a',
    '#ed303c',
    '#3b8183',
    '#f7826e',
    '#faac9e',
    '#fcd5cf'
  ];

  return {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#fad089'
      }
    },

    visualMap: {
      color: ['#fad089', '#a2d4e6']
    },

    toolbox: {
      color: ['#fad089', '#fad089', '#fad089', '#fad089']
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#fad089',
          type: 'dashed'
        },
        crossStyle: {
          color: '#fad089'
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)'
        }
      }
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(144,197,237,0.2)', // Fill the color
      handleColor: '#fad089' // Handle color
    },

    timeline: {
      lineStyle: {
        color: '#fad089'
      },
      controlStyle: {
        color: '#fad089',
        borderColor: '#fad089'
      }
    },

    candlestick: {
      itemStyle: {
        color: '#ff9c5b',
        color0: '#f5634a'
      },
      lineStyle: {
        width: 1,
        color: '#3b8183',
        color0: '#ed303c'
      },
      areaStyle: {
        color: '#fad089',
        color0: '#ed303c'
      }
    },

    chord: {
      padding: 4,
      itemStyle: {
        color: '#fad089',
        borderWidth: 1,
        borderColor: 'rgba(128, 128, 128, 0.5)'
      },
      lineStyle: {
        color: 'rgba(128, 128, 128, 0.5)'
      },
      areaStyle: {
        color: '#ed303c'
      }
    },

    map: {
      itemStyle: {
        color: '#ddd'
      },
      areaStyle: {
        color: '#f5634a'
      },
      label: {
        color: '#c12e34'
      }
    },

    graph: {
      itemStyle: {
        color: '#f5634a'
      },
      linkStyle: {
        color: '#fad089'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#ff9c5b'],
            [0.8, '#fad089'],
            [1, '#3b8183']
          ],
          width: 8
        }
      }
    }
  };

};
// theme fruit
export const fruit = () => {
  const colorPalette = [
    '#ffcb6a',
    '#ffa850',
    '#ffe2c4',
    '#e5834e',
    '#ffb081',
    '#f7826e',
    '#faac9e',
    '#fcd5cf'
  ];

  return {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#ffcb6a'
      }
    },

    visualMap: {
      color: ['#ffcb6a', '#ffa850']
    },

    toolbox: {
      color: ['#ffcb6a', '#ffcb6a', '#ffcb6a', '#ffcb6a']
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#ffcb6a',
          type: 'dashed'
        },
        crossStyle: {
          color: '#ffcb6a'
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)'
        }
      }
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(200,200,200,0.2)', // Fill the color
      handleColor: '#ffcb6a' // Handle color
    },

    timeline: {
      lineStyle: {
        color: '#ffcb6a'
      },
      controlStyle: {
        color: '#ffcb6a',
        borderColor: '#ffcb6a'
      }
    },

    candlestick: {
      itemStyle: {
        color: '#ffa850',
        color0: '#ffe2c4'
      },
      lineStyle: {
        width: 1,
        color: '#ffb081',
        color0: '#e5834e'
      },
      areaStyle: {
        color: '#e5834e',
        color0: '#fcd5cf'
      }
    },

    chord: {
      padding: 4,
      itemStyle: {
        color: '#fcd5cf',
        borderWidth: 1,
        borderColor: 'rgba(128, 128, 128, 0.5)'
      },
      lineStyle: {
        color: 'rgba(128, 128, 128, 0.5)'
      },
      areaStyle: {
        color: '#e5834e'
      }
    },

    map: {
      itemStyle: {
        color: '#ffe2c4'
      },
      areaStyle: {
        color: '#ddd'
      },
      label: {
        color: '#c12e34'
      }
    },

    graph: {
      itemStyle: {
        color: '#f2385a'
      },
      linkStyle: {
        color: '#ffcb6a'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#ffa850'],
            [0.8, '#ffcb6a'],
            [1, '#ffb081']
          ],
          width: 8
        }
      }
    }
  };
};
// theme london
export const london = () => {
  const colorPalette = [
    '#02151a',
    '#043a47',
    '#087891',
    '#c8c8c8',
    '#b31d14',
    '#0b9cc1',
    '#f2f2f2',
    '#f07b75'
  ];

  return {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#02151a'
      }
    },

    visualMap: {
      color: ['#02151a', '#a2d4e6']
    },

    toolbox: {
      color: ['#02151a', '#02151a', '#02151a', '#02151a']
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#02151a',
          type: 'dashed'
        },
        crossStyle: {
          color: '#02151a'
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)'
        }
      }
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(144,197,237,0.2)', // Fill the color
      handleColor: '#02151a' // Handle color
    },

    timeline: {
      lineStyle: {
        color: '#02151a'
      },
      controlStyle: {
        color: '#02151a',
        borderColor: '#02151a'
      }
    },

    candlestick: {
      itemStyle: {
        color: '#043a47',
        color0: '#087891'
      },
      lineStyle: {
        width: 1,
        color: '#b31d14',
        color0: '#c8c8c8'
      },
      areaStyle: {
        color: '#087891',
        color0: '#c8c8c8'
      }
    },

    map: {
      itemStyle: {
        color: '#ddd'
      },
      areaStyle: {
        color: '#087891'
      },
      label: {
        color: '#c12e34'
      }
    },

    graph: {
      itemStyle: {
        color: '#c12e34'
      },
      linkStyle: {
        color: '#02151a'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#043a47'],
            [0.8, '#02151a'],
            [1, '#b31d14']
          ],
          width: 8
        }
      }
    }
  };
};
// theme inspired
export const inspired = () => {
  const colorPalette = [
    '#cc0000',
    '#002266',
    '#ff9900',
    '#006600',
    '#8a150f',
    '#076278',
    '#808080',
    '#f07b75'
  ];

  return  {
    color: colorPalette,

    title: {
      textStyle: {
        fontWeight: 'normal',
        color: '#cc0000'
      }
    },

    visualMap: {
      color: ['#cc0000', '#002266']
    },

    toolbox: {
      color: ['#cc0000', '#cc0000', '#cc0000', '#cc0000']
    },

    tooltip: {
      backgroundColor: 'rgba(0,0,0,0.5)',
      axisPointer: {
        // Axis indicator, coordinate trigger effective
        type: 'line', // The default is a straight line： 'line' | 'shadow'
        lineStyle: {
          // Straight line indicator style settings
          color: '#cc0000',
          type: 'dashed'
        },
        crossStyle: {
          color: '#cc0000'
        },
        shadowStyle: {
          // Shadow indicator style settings
          color: 'rgba(200,200,200,0.3)'
        }
      }
    },

    // Area scaling controller
    dataZoom: {
      dataBackgroundColor: '#eee', // Data background color
      fillerColor: 'rgba(200,200,200,0.2)', // Fill the color
      handleColor: '#cc0000' // Handle color
    },

    timeline: {
      lineStyle: {
        color: '#cc0000'
      },
      controlStyle: {
        color: '#cc0000',
        borderColor: '#cc0000'
      }
    },

    candlestick: {
      itemStyle: {
        color: '#002266',
        color0: '#ff9900'
      },
      lineStyle: {
        width: 1,
        color: '#8a150f',
        color0: '#006600'
      },
      areaStyle: {
        color: '#cc0000',
        color0: '#ff9900'
      }
    },

    map: {
      itemStyle: {
        color: '#ff9900'
      },
      areaStyle: {
        color: '#ddd'
      },
      label: {
        color: '#c12e34'
      }
    },

    graph: {
      itemStyle: {
        color: '#ff9900'
      },
      linkStyle: {
        color: '#cc0000'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#002266'],
            [0.8, '#cc0000'],
            [1, '8a150f']
          ],
          width: 8
        }
      }
    }
  };
};
// theme roma
export const roma = () => {
  const colorPalette = [
    '#E01F54',
    '#001852',
    '#f5e8c8',
    '#b8d2c7',
    '#c6b38e',
    '#a4d8c2',
    '#f3d999',
    '#d3758f',
    '#dcc392',
    '#2e4783',
    '#82b6e9',
    '#ff6347',
    '#a092f1',
    '#0a915d',
    '#eaf889',
    '#6699FF',
    '#ff6666',
    '#3cb371',
    '#d5b158',
    '#38b6b6'
  ];

  return  {
    color: colorPalette,

    visualMap: {
      color: ['#e01f54', '#e7dbc3'],
      textStyle: {
        color: '#333'
      }
    },

    candlestick: {
      itemStyle: {
        color: '#e01f54',
        color0: '#001852'
      },
      lineStyle: {
        width: 1,
        color: '#f5e8c8',
        color0: '#b8d2c7'
      },
      areaStyle: {
        color: '#a4d8c2',
        color0: '#f3d999'
      }
    },

    graph: {
      itemStyle: {
        color: '#a4d8c2'
      },
      linkStyle: {
        color: '#f3d999'
      }
    },

    gauge: {
      axisLine: {
        lineStyle: {
          color: [
            [0.2, '#E01F54'],
            [0.8, '#b8d2c7'],
            [1, '#001852']
          ],
          width: 8
        }
      }
    }
  };
};
