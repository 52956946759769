import * as d3 from 'd3';
import d3Tip from 'd3-tip';
declare const $: any;

export const volumeGlobe = (data , model) => {
  const width = 932;
  const height = 932;
  const getColor=(d)=>{
    let color = 'rgb(31,97,93)';
    switch(d.data.type){
      case 'model': color='rgb(116, 215, 202)'; break;
      case 'object': color='rgb(81, 183, 196)'; break;
      case 'record': d.data.recordCand? color= 'rgb(24,226,17)': color='white';break;
    }
    return color;
  };
  const pack = data => d3.pack()
    .size([width, height])
    .padding(3)
    (d3.hierarchy(data)
      .sum(d => d.size)
      .sort((a, b) => b.size - a.size));

  const root = pack(data);
  let focus = root;
  let view;
  $('#volumeGlobChart').empty();
  const svg = d3.select("#volumeGlobChart");
  svg.attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
    .style("display", "block")
    .style("margin", "0 -14px")
    .style("background", '#ffffff')
    .style("cursor", "pointer")
    .on("click", () => zoom(root));

  const cont = svg.append("g");
  // tooltip
  if($('.d3-tipVolumeGlb').length > 0){
    $('.d3-tipVolumeGlb').remove();
  }
  const tooltip = d3Tip()
    .direction('n')
    .attr("class", "d3-tipVolumeGlb")
    .html((d) => {
      return '<div id="globevolTipo">' +  `${d.ancestors().map(d => d.data.name).reverse().join(" → ")}` + '</div>';
    });

  svg.call(tooltip);
  const node = cont
    .selectAll("circle")
    .data(root.descendants())
    .join("circle")
    .attr("class", 'circulos')
    .attr('id', d => 'globVlm_' + d.data.id)
    .attr('data-classification', d => d.data.type)
    .attr("fill", d => getColor(d))
    .on('mouseover',function (d) {
      tooltip.show(d, this);
    })
    .on("mouseout", function() {
      tooltip.hide(); })
    .on("click", d => focus !== d && (zoom(d), d3.event.stopPropagation()));;


  const label = svg.append("g")
    .style("font", "10px sans-serif")
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .selectAll("text")
    .attr('class', 'labelVlm')
    .data(root.descendants())
    .join("text")
    .attr('dy', d => d.parent===root? "-3em" : "0em")
    .attr('id', d => 'labelVlm_' + d.data.id)
    .attr("font-size", function(d) {
      d.fontTitleSize = d.r / 10;
      return Math.round(d.fontTitleSize)+"px";
    })
    .attr('fill', 'black')
    .style("display", d => d.parent !== null ? "inline" : "none")
    .text(d => d.data.name + ' - ' + d.data.size);

  zoomTo([root.x, root.y, root.r * 2]);


  function zoomTo(v) {
    const k = width / v[2];

    view = v;

    label.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`).attr("font-size", function(d) {
      d.fontTitleSize = d.r * k / 10;
      return Math.round(d.fontTitleSize)+"px";
    });
    node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`).attr("r", d => d.r * k);
  }
  function zoom(d) {
    const focus0 = focus;

    focus = d;

    const transition = svg.transition()
      .duration(d3.event.altKey ? 7500 : 750)
      .tween("zoom", d => {
        const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
        return function(t) {
          zoomTo(i(t));
        };
      });

    label
      .filter(function(d) { return d.parent === focus || this.style.display === "inline"; })
      .transition(transition);
  }
  cont
    .selectAll("circle").attr('r', d => {
    if(d.r == 0)  d.r = 1;
    return d.r;
  })
};
