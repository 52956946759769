import {RouterModule, Routes} from "@angular/router";

import {NgModule} from "@angular/core";
import {ProjectsComponent} from "./projects/projects.component";
import {CountryComponent} from "./country/country.component";

const routes: Routes = [
  {
    path : '',
    redirectTo : 'data-governance',
    pathMatch : 'full'
  },
  {
    path: 'data-governance/project',
    component: ProjectsComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataGovernanceRoutingModule {

}
