import {Component, Input, OnInit} from '@angular/core';
import {ObjectService} from '../../services/objects/object.service';
import {Observable} from "rxjs";
// import * as d3 from 'd3';
declare const $: any;

declare var require: any;
// let Boost = require('highcharts/modules/boost');
// let noData = require('highcharts/modules/no-data-to-display');
// let More = require('highcharts/highcharts-more');

@Component({
  selector: 'app-volume',
  templateUrl: './volume.component.html',
  styleUrls: ['./volume.component.sass']
})
export class VolumeComponent implements OnInit {
  @Input() selectedObjectModel: string;
  @Input() selectedReport: string;

  // listAppliForMod$: Observable<any>;
  // selectedApp: string;
  // loadingApplication = true;

  // listObjectModel = [];

  // selectedModel: string;
  // loadingModel = true;

  dataset: any;

  options: any;

  constructor( private objectService: ObjectService) { }

  ngOnInit() {
    // this.onGetApplicationForListModels();
    // Highcharts.chart('container', this.options);
  }
  // get list applications (for onGetObjectModelsList)
  // onGetApplicationForListModels() {
  //   this.objectService.getApplicationForListModels()
  //     .subscribe(
  //       data => {
  //         this.listAppliForMod$ = data;
  //       },
  //       err => {
  //         console.error(err);
  //       },
  //       () => {
  //         this.loadingApplication = false;
  //       }
  //     );
  // }
  // get list objectModel by status Approved/Archived(by application)
  //   onGetObjectModelsList() {
  //   this.listObjectModel = [];
  //   this.selectedModel = '';
  //   this.loadingModel = true;
  //   this.objectService.getObjectModelsList(this.selectedApp).subscribe(
  //     result => {
  //       this.listObjectModel = [];
  //       for (let object of result) {
  //         this.listObjectModel.push(object);
  //       }
  //     },
  //     err => {
  //       console.error(err);
  //     },
  //     () => {
  //       this.loadingModel = false;
  //     }
  //   );
  // }

  // get (reportDetails) recordCand and Count BY selected DDTMReport and selected ObjectModel
    onGetCandidByRepAndMod() {
      $('#container').empty();
      this.dataset = [];
      this.objectService.getObjAndContByRepAndMod(this.selectedObjectModel, this.selectedReport).subscribe(
        data => {
          // console.log(data);
          // data.map(item => {this.listRecordCand.push(item); });
          this.dataset = data;
          console.log(this.dataset);
        },
        err => {
          console.error(err);
        },
        () => {
        this.options = {
            chart: {
              type: 'packedbubble',
              height: '100%'
            },
            title: {
              text: ''
            },
            tooltip: {
              useHTML: true,
              pointFormat: '<b>{point.name}:</b> {point.value}'
            },
          plotOptions: {
            packedbubble: {
              minSize: '30%',
              maxSize: '120%',
              zMin: 0,
              zMax: 1000,
              layoutAlgorithm: {
                splitSeries: false,
                gravitationalConstant: 0.02
              },
              dataLabels: {
                enabled: true,
                format: '{point.name}',
                filter: {
                  property: 'y',
                  operator: '>',
                  value: 0
                },
                style: {
                  color: 'black',
                  textOutline: 'none',
                  fontWeight: 'normal'
                }
              }
            }
          },
            // plotOptions: {
            //   packedbubble: {
            //     minSize: '10%',
            //     maxSize: '100%',
            //     zMin: 0,
            //     zMax: 50000,
            //     layoutAlgorithm: {
            //       gravitationalConstant: 0.05,
            //       splitSeries: true,
            //       seriesInteraction: true,
            //       dragBetweenSeries: false,
            //       parentNodeLimit: false
            //     },
            //     dataLabels: {
            //       enabled: true,
            //       format: '{point.name}',
            //       filter: {
            //         property: 'y',
            //         operator: '>',
            //         value: 0
            //       },
            //       style: {
            //         color: 'black',
            //         textOutline: 'none',
            //         fontWeight: 'normal'
            //       }
            //     }
            //   }
            // },
            series: this.dataset
          };
          // Highcharts.chart('container', this.options);
          // this.bubble(this.dataset);
        }
      );
    }

public bubble(dat) {
      //   var width = 1000;
      //   var height = 600;
      //   var color = d3.scaleOrdinal(d3.schemeCategory10);
      //
      //   var bubble = d3.pack(dat)
      //     .size([width, height])
      //     .padding(1);
      //
      //   // var svg = d3.select("body")
      //   var svg = d3.select('#bubble')
      //     .append("svg")
      //     .attr("width", width)
      //     .attr("height", height)
      //     .attr("class", "bubble");
      //
      //   var nodes = d3.hierarchy(dat)
      //     .sum(function(d) { return d.Count; });
      //
      //   var node = svg.selectAll(".node")
      //     .data(bubble(nodes).descendants())
      //     .enter()
      //     .filter(function(d){
      //       return  !d.children
      //     })
      //     .append("g")
      //     .attr("class", "node")
      //     .attr("transform", function(d) {
      //       return "translate(" + d.x + "," + d.y + ")";
      //     });
      //
      //   node.append("title")
      //     .text(function(d) {
      //       return d.Name + ": " + d.Count;
      //     });
      //
      //   node.append("circle")
      //     .attr("r", function(d) {
      //       return d.r;
      //     })
      //     .style("fill", function(d,i) {
      //       return color(i);
      //     });
      //
      //   node.append("text")
      //     .attr("dy", ".2em")
      //     .style("text-anchor", "middle")
      //     .text(function(d) {
      //       return d.data.Name.substring(0, d.r / 3);
      //     })
      //     .attr("font-family", "sans-serif")
      //     .attr("font-size", function(d){
      //       return d.r/5;
      //     })
      //     .attr("fill", "white");
      //
      //   node.append("text")
      //     .attr("dy", "1.3em")
      //     .style("text-anchor", "middle")
      //     .text(function(d) {
      //       return d.data.Count;
      //     })
      //     .attr("font-family",  "Gill Sans", "Gill Sans MT")
      //     .attr("font-size", function(d){
      //       return d.r/5;
      //     })
      //     .attr("fill", "white");
      //
      //   d3.select(self.frameElement)
      //     .style("height", height + "px");
    }
}
