import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RolesComponent } from './roles/roles.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {ArchwizardModule} from 'angular-archwizard';
import { UserslistComponent } from './userslist/userslist.component';
import {DragulaModule} from "ng2-dragula";
import { UserRolesComponent } from './user-roles/user-roles.component';
import {NgSelectModule} from "@ng-select/ng-select";


import { DropzoneModule } from 'ngx-dropzone-wrapper';
import {NgxDropzoneModule} from "ngx-dropzone";
import { OrderModule } from 'ngx-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MetadataComponent } from './metadata/metadata.component';
import { CustomvaluesComponent } from './customvalues/customvalues.component';
import {SidebarModule} from 'ng-sidebar';
import {TranslateModule} from '@ngx-translate/core';
import {ColorSketchModule} from 'ngx-color/sketch';

@NgModule({
  declarations: [RolesComponent, UserslistComponent, UserRolesComponent,  MetadataComponent, CustomvaluesComponent],
    imports: [
        CommonModule,
        DropzoneModule,
        NgxDropzoneModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        ArchwizardModule,
        DragulaModule,
        NgSelectModule,
        ColorSketchModule,
        OrderModule,
        NgxPaginationModule,
        Ng2SearchPipeModule,
        SidebarModule,
        TranslateModule
    ],
  // exports: [MetadatatemplateComponent,
  //   CommonModule, FormsModule,BrowserModule,ReactiveFormsModule,NgSelectModule,OrderModule]
})
export class SettingsModule { }
