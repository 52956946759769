import {Injector, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterHtml'
})
export class FilterHtmlPipe implements PipeTransform {

  public constructor(private readonly injector: Injector) {
  }

  transform(value: Array<any>, callback: any): any {
    return value.filter(callback);
  }

}
