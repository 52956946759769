import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {DataLineageRoutingModule} from './data-lineage-routing.module';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DataLineageComponent} from './data-lineage/data-lineage.component';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {NgxResizableModule} from '@3dgenomes/ngx-resizable';
import {ColorSketchModule} from 'ngx-color/sketch';
import {SanitizeHtmlPipe} from '../sanitize-html.pipe';
import {TreeModule} from '@circlon/angular-tree-component';
import { GlobalLineageComponent } from './global-lineage/global-lineage.component';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';
import {OrderModule} from 'ngx-order-pipe';
import {DataLifeCycleModule} from "../data-life-cycle/data-life-cycle.module";
import {ObjectModelsModule} from "../object-models/object-models.module";
import {SharedModule} from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';
import {SidebarModule} from 'ng-sidebar';
import { MxLineageComponent } from './mx-lineage/mx-lineage.component';



@NgModule({
  declarations: [DataLineageComponent, GlobalLineageComponent, MxLineageComponent],
    imports: [
        CommonModule,
        DataLineageRoutingModule,
        NgSelectModule,
        ReactiveFormsModule,
        FormsModule,
        NgOptionHighlightModule,
        NgxResizableModule,
        ColorSketchModule,
        TreeModule,
        Ng2SearchPipeModule,
        NgxPaginationModule,
        OrderModule,
        DataLifeCycleModule,
        ObjectModelsModule,
        SharedModule,
        TranslateModule,
        SidebarModule,
    ]
})
export class DataLineageModule { }
