import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AuthComponent} from './auth/auth.component';
import {ForgotPasswordComponent} from './auth/forgot-password/forgot-password.component';
import {UserslistComponent} from './settings/userslist/userslist.component';
import {ProfileuserComponent} from './settings/profileuser/profileuser.component';
import {ChangePasswordComponent} from './auth/change-password/change-password.component';
import {AuthGuard} from './services/guard/auth-guard.service';
import {RolesComponent} from './settings/roles/roles.component';
import {UserRolesComponent} from './settings/user-roles/user-roles.component';
import {MetadataComponent} from './settings/metadata/metadata.component';
import {TempUserComponent} from './auth/temp-user/temp-user.component';

import {CampainComponent} from './data-life-cycle/campain/campain.component';
import {ProjectsComponent} from './data-governance/projects/projects.component';
import {HoldersComponent} from './data-governance/holders/holders.component';
import {StackComponent} from './data-governance/stack/stack.component';
import {UserMessageComponent} from './userMessage/user-message/user-message.component';
import {EntityComponent} from './data-governance/entity/entity.component';
import {CountryComponent} from './data-governance/country/country.component';
import {DimensionComponent} from './data-governance/dimension/dimension.component';
import {GlobalSearchComponent} from './global-search/global-search.component';
import {DataLineageTermComponent} from './business-glossary/data-lineage-term/data-lineage-term.component';
import {FunctionalMapComponent} from './business-glossary/functional-map/functional-map.component';
import {FunctionalMapListComponent} from './business-glossary/functional-map-list/functional-map-list.component';
import {FunctionalMapGroupComponent} from './business-glossary/functional-map-group/functional-map-group.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'complete/:token/:user',
    component: TempUserComponent
  },
  {
    path: 'change-password/:token',
    component: ChangePasswordComponent
  },
  {
    path: 'profileUser',
    canActivate: [AuthGuard],
    component: ProfileuserComponent
  },
  {
    path: 'userslist',
    canActivate: [AuthGuard],
    component: UserslistComponent
  },
  {
    path: 'usersroles',
    canActivate: [AuthGuard],
    component: UserRolesComponent
  },
  {
    path: 'roles',
    canActivate: [AuthGuard],
    component: RolesComponent
  },
  {
    path: 'metadata',
    canActivate: [AuthGuard],
    component: MetadataComponent
  },
  {
    path: 'anonym-board',
    canActivate: [AuthGuard],
    loadChildren: () => import('./anonym-board/anonym-board.module').then(m => m.AnonymBoardModule)
  },
  {
    path: 'data-transfert',
    canActivate: [AuthGuard],
    loadChildren: () => import('./data-transfert/data-transfert.module').then(m => m.DataTransfertModule)
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  {
    path: 'dashboard/:system/:category/:onglet/:component',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'ddtm/:application/:report/:componentId/:component',
    canActivate: [AuthGuard],
    loadChildren: () => import('./ddtm/ddtm.module').then(m => m.DdtmModule)
  },
  {
    path: 'data-catalog/:application/:model/:object/:table/:field/:componentId/:component',
    canActivate: [AuthGuard],
    loadChildren: () => import('./data-catalogue/data-catalogue.module').then(m => m.DataCatalogueModule),
  },
  {
    path: 'admin-model/:system/:category/:onglet/:component',
    canActivate: [AuthGuard],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'uploads',
    canActivate: [AuthGuard],
    loadChildren: () => import('./uploads/uploads.module').then(m => m.UploadsModule)
  },
  {
    path: 'model-admin1/:system/:category/:onglet/:component',
    canActivate: [AuthGuard],
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'data-classify',
    canActivate: [AuthGuard],
    loadChildren: () => import('./data-classify/data-classify.module').then(m => m.DataClassifyModule),
  },
  {
    path: 'object-models/:application/:model/:object/:table/:field/:componentId/:component',
    canActivate: [AuthGuard],
    loadChildren: () => import('./object-models/object-models.module').then(m => m.ObjectModelsModule),
  },
  {
    path: 'rules',
    canActivate: [AuthGuard],
    loadChildren: () => import('./rules/rules.module').then(m => m.RulesModule),
  },
  {
    path: 'drm',
    canActivate: [AuthGuard],
    loadChildren: () => import('./drm/drm.module').then(m => m.DrmModule),
  },
  {
    path: 'active-board',
    canActivate: [AuthGuard],
    loadChildren: () => import('./active-board/active-board.module').then(m => m.ActiveBoardModule),
  },
  {
    path: 'anonym-board',
    canActivate: [AuthGuard],
    loadChildren: () => import('./anonym-board/anonym-board.module').then(m => m.AnonymBoardModule),
  },
  {
    path: 'data-life-cycle',
    canActivate: [AuthGuard],
    loadChildren: () => import('./data-life-cycle/data-life-cycle.module').then(m => m.DataLifeCycleModule),
  },
  // {
  //   path: 'archive-board',
  //   canActivate: [AuthGuard],
  //   component: ArchiveBoardComponent
  // },
  {
    path: 'campain/:application/:campaign/:componentId/:component/:base',
    canActivate: [AuthGuard],
    component: CampainComponent
  },
  {
    path: 'data-governance',
    canActivate: [AuthGuard],
    loadChildren: () => import('./data-governance/data-governance.module').then(m => m.DataGovernanceModule),
  },
  {
    path: 'data-governance/project/:project/:componentId/:component',
    canActivate: [AuthGuard],
    component: ProjectsComponent
  },
  {
    path: 'data-governance/stewards',
    canActivate: [AuthGuard],
    component: HoldersComponent
  },
  {
    path: 'data-governance/data-roles',
    canActivate: [AuthGuard],
    component: StackComponent
  },
  {
    path: 'data-governance/country/:country/:componentId/:component',
    canActivate: [AuthGuard],
    component: CountryComponent
  },
  {
    path: 'data-governance/entity',
    canActivate: [AuthGuard],
    component: EntityComponent
  },
  {
    path: 'data-governance/dimension/:sourceName/:sourceId/:component',
    canActivate: [AuthGuard],
    component: DimensionComponent
  },
  {
    path: 'user-message',
    canActivate: [AuthGuard],
    loadChildren: () => import('./userMessage/user-message.module').then(m => m.UserMessageModule)
  },
  {
    path: 'user-message/inbox/:id/:component',
    canActivate: [AuthGuard],
    component: UserMessageComponent
  },
  {
    path: 'data-lineage',
    canActivate: [AuthGuard],
    loadChildren: () => import('./data-lineage/data-lineage.module').then(m => m.DataLineageModule)
  },
  // {
  //   path: 'data-lineage-list/:id/:source/:target/:sourceType/:targetType',
  //   canActivate: [AuthGuard],
  //   component: GlobalLineageComponent
  // },
  {
    path: 'global-designer/:compTarget/:component/:type/:sourceName/:sourceId/:diagName/:diagId',
    canActivate: [AuthGuard],
    loadChildren: () => import('./globaldesigner/globaldesigner.module').then(m => m.GlobaldesignerModule)
  },
  {
    path: 'Business-Glossary',
    canActivate: [AuthGuard],
    loadChildren: () => import('./business-glossary/business-glossary.module').then(m => m.BusinessGlossaryModule)
  },
  {
    path: 'Glossaires',
    canActivate: [AuthGuard],
    loadChildren: () => import('./glossaires/glossaire.module').then(m => m.GlossaireModule)
  },
  {
    path: 'Business-Glossary/data-lineage-term/:id/:name',
    canActivate: [AuthGuard],
    component: DataLineageTermComponent
  },
  {
    path: 'global-search/:textSearch',
    canActivate: [AuthGuard],
    component: GlobalSearchComponent
  },
  {
    path: 'business-Glossary/Functional-map-group/:id/:func',
    canActivate: [AuthGuard],
    component: FunctionalMapGroupComponent
  },
  {
    path: '**',
    redirectTo: 'auth',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
