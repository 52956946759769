import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';
import {MetadataTemplateService} from '../../services/metadata/metadatatemplate.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import {forEach} from 'ag-grid-community/dist/lib/utils/array';

declare const $: any;

@Component({
    selector: 'app-metadatatemplate',
    templateUrl: './metadatatemplate.component.html',
    styleUrls: ['./metadatatemplate.component.sass']
})
export class MetadatatemplateComponent implements OnInit {

    @Input() objId: string;
    @Input() objName: string;
    @Input() isUpdated: boolean;
// @Output() metaAp= new EventEmitter<any>()
// metaApp:any;
    isRead = true;
    isEdit = false;
// metadata function
    listObjVal = [];
    fieldMDFselect = [];
    fieldMDFtext = [];
    fieldMDFarea = [];
    fieldMDFemail = [];
    fieldMDFcolor = [];
    fieldMDFtel = [];
    fieldMDFdate = [];
    metaField = [];
    editMeta: FormGroup;

    /**
     * The Methode
     */
    constructor(private metaService: MetadataTemplateService, private fb: FormBuilder, private dynamicScriptLoader: DynamicScriptLoaderService) {
    }

    ngOnInit() {
        this.editMeta = this.fb.group({
            metaApp: [''],
        });
    }
    CreateField(meta) {
        this.fieldMDFselect = [];
        this.fieldMDFtext = [];
        this.fieldMDFarea = [];
        this.fieldMDFcolor = [];
        this.fieldMDFemail = [];
        this.fieldMDFtel = [];
        this.fieldMDFdate = [];
        meta.forEach(element => {
            if (element.typeMDF == 'select') {
                const listVals = element.listValueMDF;
                const index = listVals.findIndex(val => val.idValMDF == element.selectedValueMDF);
                element.selectedValueMDF = listVals[index];
                this.fieldMDFselect.push(element);
            } else if (element.typeMDF == 'text') {
                this.fieldMDFtext.push(element);
            } else if (element.typeMDF == 'email') {
                this.fieldMDFemail.push(element);
            } else if (element.typeMDF == 'color') {
                this.fieldMDFcolor.push(element);
            } else if (element.typeMDF == 'textarea') {
                this.fieldMDFarea.push(element);
            } else if (element.typeMDF == 'tel') {
                this.fieldMDFtel.push(element);
            } else if (element.typeMDF == 'date') {
                this.fieldMDFdate.push(element);
            }
        });
        this.sortListFieldArea();
        this.sortListFieldText();
        this.sortListFieldEmail();
        this.sortListFieldTel();
        this.sortListFieldColor();
        this.sortListFieldDate();
        this.sortListFieldSelect();
    }
    async startScriptTypeDate(idMDF) {
        $('#date_' + idMDF).css('opacity', 1);
        await this.dynamicScriptLoader.load('form.min').then(data => {
            const that = this;
            $('#date_' + idMDF).bootstrapMaterialDatePicker(                {
                format: 'YYYY/MM/D',
                clearButton: true,
                weekStart: 1,
                time: false,
                autoclose: true,
            })
                .on('change', function(e, date) {
                    var dateValue = '';
                    if ($(date).attr('_d') != undefined) {
                        dateValue = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
                    } else {
                        dateValue = '';
                    }
                    const index = that.fieldMDFdate.findIndex(o => o.idMDF == idMDF);
                    if (index !== -1) {
                        that.fieldMDFdate[index].selectedValueMDF = dateValue;
                    }
                });
        }).catch(error => console.error(error));
    }

    /**
     * The Methode
     */
    onClickMD() {
        if ($('#MD_' + this.objId).html() == '<i class="fas fa-angle-up"></i>') {
            $('#MD_' + this.objId).html('<i class="fas fa-angle-down"></i>');
            $('#Menu_Add_MD_' + this.objId).hide();
        } else {
            $('#Menu_Add_MD_' + this.objId).show();
            $('#MD_' + this.objId).html('<i class="fas fa-angle-up"></i>');
        }
    }

    /**
     * The Methode
     */
    /// sort fields by selection type
    compareField( a, b ) {
        if ( a.usedMDF > b.usedMDF ) {
            return -1;
        }
        if ( a.usedMDF < b.usedMDF ) {
            return 1;
        }
        return 0;
    }

    sortListFieldArea() {
        setTimeout(() => {
            this.fieldMDFarea.sort( this.compareField );
        }, 500);
    }
    sortListFieldText() {
        setTimeout(() => {
            this.fieldMDFtext.sort( this.compareField );
            // console.log(this.fieldMDFtext);
        }, 500);
    }
    sortListFieldEmail() {
        setTimeout(() => {
            this.fieldMDFemail.sort( this.compareField );
        }, 500);
    }
    sortListFieldTel() {
        setTimeout(() => {
            this.fieldMDFtel.sort( this.compareField );
        }, 500);
    }
    sortListFieldColor() {
        setTimeout(() => {
            this.fieldMDFcolor.sort( this.compareField );
        }, 500);
    }
    sortListFieldDate() {
        setTimeout(() => {
            this.fieldMDFdate.sort( this.compareField );
        }, 500);
    }
    sortListFieldSelect() {
        setTimeout(() => {
            this.fieldMDFselect.sort( this.compareField );
        }, 500);
    }
    onGetMeta(id) {
        this.metaField = [];
        this.metaService.getMetaObj(id, this.objName).subscribe(
            data => {
                this.metaField = data;
            },
            err => {
                console.error(err);
            }, () => {
                this.CreateField(this.metaField);
            });
    }

    /**
     * The Methode
     */
    SetModeTemplate() {
        if (this.isUpdated) {

        } else {

        }

        this.isRead = !this.isRead;
        this.isEdit = !this.isEdit;
    }

    /**
     * The Methode
     */
    onUpdateMeta() {
        return this.metaField;
    }
}
