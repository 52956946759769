import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MetadatatemplateComponent} from './metadatatemplate/metadatatemplate.component';
import {NgSelectModule} from "@ng-select/ng-select";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import {NgxDropzoneModule} from "ngx-dropzone";
import { OrderModule } from 'ngx-order-pipe';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MetadataTtemplateComponent} from './metadataTtemplate/metadataTtemplate.component';
import {GlobaldesignerRoutingModule} from '../globaldesigner/globaldesigner-routing.module';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderFactory} from '../app.module';
import {HttpClient} from '@angular/common/http';
import {SanitizeHtmlPipe} from '../sanitize-html.pipe';
import {UsageriskmodelComponent} from './usageriskmodel/usageriskmodel.component';
import {MetadataNewTabletemplateComponent} from './metadataNewTabletemplate/metadataNewTabletemplate.component';
import { MetadataNewFieldTemplateComponent } from './metadata-new-field-template/metadata-new-field-template.component';
@NgModule({
  declarations: [
      MetadatatemplateComponent,
      MetadataTtemplateComponent,
      MetadataNewTabletemplateComponent,
      UploadFilesComponent,
      SanitizeHtmlPipe,
      UsageriskmodelComponent,
      MetadataNewFieldTemplateComponent,],
  imports: [
    NgSelectModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DropzoneModule,
    NgxDropzoneModule,
    NgxPaginationModule,
    OrderModule,
    Ng2SearchPipeModule,
      TranslateModule
  ],
  exports: [
    CommonModule,
    MetadatatemplateComponent,
      MetadataNewFieldTemplateComponent,
    MetadataTtemplateComponent,
    MetadataNewTabletemplateComponent,
    UsageriskmodelComponent,
    NgSelectModule,
    Ng2SearchPipeModule,
    ReactiveFormsModule,
    FormsModule,
    UploadFilesComponent,
    DropzoneModule,
    NgxDropzoneModule,
    NgxPaginationModule,
    OrderModule,
    SanitizeHtmlPipe
  ]
})
export class SharedModule { }
