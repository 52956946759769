import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class CustomvaluesService {

constructor(private http: HttpClient, private router: Router) { }
    Options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'Application/json',
            Authorization: 'Bearer ' + getCookieValue('tokendrd')
            //  'Accept': 'Application/json'
        })
    };
    headerss = new Headers({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
    });

  OptionFiles()  {
    return {
        headers: new HttpHeaders({
            // 'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'Application/json',
            Authorization: 'Bearer ' + getCookieValue('tokendrd')
            // 'Accept': 'Application/json'
        })
    };
  }
    getListValues(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/metadatacv/getListValues', this.Options);
    }
    checkUsedCVAfterDelete(id: string, objectMD: string, fieldName: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/customvalues/checkUsedCV/${id}/${objectMD}/${fieldName}`, this.Options);
    }
    deleteCV(id: string, objectMD: string, fieldName: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/customvalues/deleteCV/${id}/${objectMD}/${fieldName}`, this.Options);
    }
    updateCV(d): Observable<any> {
        const formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/customvalues/updateCV' ,  formData, this.OptionFiles());
    }
// updateValueMD(d): Observable<any> {
//     const formData = new FormData();
//     formData.append('data', d);
//
//     return this.http.post(Shared.API_URL + '/api/customvalues/updateValueCV' , formData,  this.OptionFiles());
//
//
// }
// addValueMD(d): Observable<any> {
//     const formData = new FormData();
//     formData.append('data', d);
//
//     return this.http.post(Shared.API_URL + '/api/customvalues/addValueCV' , formData,  this.OptionFiles());
//
//
// }
getApplication(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/customvalues/getApplication/${id}`, this.Options);
    }
    addMD(d): Observable<any> {
        const formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/customvalues/addCV' , formData, this.OptionFiles());

    }
    getValuesCVonCanceled(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/customvalues/getValuesCVonCanceled/${id}`, this.Options);
    }
    deleteValueMD(id, idobj): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/customvalues/deleteValueCV/${id}/${idobj}`, this.Options);
    }


    setUpdatexlxs(d): Observable<any> {
        const formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/customvalues/addxlsCV' , formData, this.OptionFiles() );
      }

    uploadFilesApp(data): Observable<any> {
        const formData = new FormData();
        formData.append('file', data);
        return this.http.post<any>(Shared.API_URL + '/api/customvalues/insertApp', formData, this.OptionFiles());
    }

}
