import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivatecomponentService {

  constructor() { }
  checkReportComponentVisible = new BehaviorSubject<boolean>(false);
  // checkModelStructureVisible = new BehaviorSubject<boolean>(false);
}
