import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, Subject} from "rxjs";
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
/**
 * This service is a infra store
 *
 * See {@link Shared} for details about the main data of this store
 */
@Injectable({
  providedIn: 'root'
})
export class EntitysService {
/**
 * This service is a infra store
 *
 * See {@link Shared} for details about the main data of this store
 */
  OptionFiles()  {
    return {
        headers: new HttpHeaders({
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            // 'Accept': 'Application/json'
        })
    };
};
/**
 * This service is a infra store
 *
 * See {@link Shared} for details about the main data of this store
 */
Options = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
        //  'Accept': 'Application/json'
    })
};
headerss = new Headers({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + getCookieValue('tokendrd')
});
constructor(private http: HttpClient, private router: Router) { }
/**
 * This service is a get all infras
 *
 * See {@link Shared} for details about the main data of this store
 */
getListEntitys(): Observable<any> {
  const id = window.localStorage.getItem('userId');
  return this.http.get<any>(Shared.API_URL +  `/api/entitys/getListEntitys/${id}`, this.Options);
}
/**
 * This service is a circle map
 *
 * See {@link Shared} for details about the main data of this store
 */

getCircleMap(): Observable<any> {

  return this.http.get(Shared.API_URL +  `/api/entitys/getCircleMap`,  this.Options);

}
/**
 * This service is a infra delete
 *
 * See {@link Shared} for details about the main data of this store
 */
deleteEntitys(id:string):Observable<any> {
  return this.http.get<any>(Shared.API_URL + `/api/entitys/deleteEntitys/${id}`, this.Options);

}
/**
 * This service is a infra update
 *
 * See {@link Shared} for details about the main data of this store
 */
updateEntitys(d): Observable<any>{
  let formData = new FormData();
  formData.append('data', d);
  return this.http.post(Shared.API_URL + '/api/entitys/updateEntitys' , formData,  this.OptionFiles() );


}
/**
 * This service for  infras add entitys
 *
 * See {@link Shared} for details about the main data of this store
 */
addEntitys(d): Observable<any>{
  let formData = new FormData();
  formData.append('data', d);
// console.log(d);
  return this.http.post(Shared.API_URL + '/api/entitys/addEntitys' , formData, this.OptionFiles() );


}
// update data by import fron excel
setUpdatexlxs(d): Observable<any>{
  let formData = new FormData();
  formData.append('data', d);
  return this.http.post(Shared.API_URL + '/api/entitys/addxlsEntitys' , formData, this.OptionFiles() );
}
}
