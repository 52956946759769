declare const $: any;
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
export const autoComplete = (inp, arr) => {
  /*the autocomplete function takes two arguments,
  the text field element and an array of possible autocompleted values:*/
  let currentFocus;
  /*execute a function when someone writes in the text field:*/
  inp.addEventListener('input', function(e) {
    let a;
    let b;
    let i;
    const val = this.value;
    /*close any already open lists of autocompleted values*/
    closeAllLists(null);
    if (!val) { return false; }
    currentFocus = -1;
    /*create a DIV element that will contain the items (values):*/
    a = document.createElement('UL');
    a.setAttribute('id', this.id + 'autocomplete-list');
    a.setAttribute('class', 'autocomplete-items');
    /*append the DIV element as a child of the autocomplete container:*/
    this.parentNode.appendChild(a);
    /*for each item in the array...*/
    // let j = 0;
    for (i = 0; i < arr.length; i++) {
      /*check if the item starts with the same letters as the text field value:*/
      // tslint:disable-next-line:triple-equals
      if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase() /*&& j < 6*/) {
        /*create a DIV element for each matching element:*/
        // j++;
        b = document.createElement('LI');
        /*make the matching letters bold:*/
        b.innerHTML = '<strong>' + arr[i].substr(0, val.length) + '</strong>';
        b.innerHTML += arr[i].substr(val.length);
        /*insert a input field that will hold the current array item's value:*/
        b.innerHTML += '<input type=\'hidden\' value=\'' + arr[i] + '\'>';
        /*execute a function when someone clicks on the item value (DIV element):*/
        // tslint:disable-next-line:no-shadowed-variable
        b.addEventListener('click', function(e) {
          /*insert the value for the autocomplete text field:*/
          inp.value = this.getElementsByTagName('input')[0].value;
          /*close the list of autocompleted values,
          (or any other open lists of autocompleted values:*/
          closeAllLists(null);
        });
        a.appendChild(b);
      }
    }
  });
  /*execute a function presses a key on the keyboard:*/
  inp.addEventListener('keydown', function(e) {
    let x = document.getElementById(this.id + 'autocomplete-list');
    if (x) { // @ts-ignore
      // @ts-ignore
      x = x.getElementsByTagName('UL'); }
    if (e.keyCode === 40) {
      /*If the arrow DOWN key is pressed,
      increase the currentFocus letiable:*/
      currentFocus++;
      /*and and make the current item more visible:*/
      addActive(x);
    } else if (e.keyCode === 38) { // up
      /*If the arrow UP key is pressed,
      decrease the currentFocus letiable:*/
      currentFocus--;
      /*and and make the current item more visible:*/
      addActive(x);
    } else if (e.keyCode === 13) {
      /*If the ENTER key is pressed, prevent the form from being submitted,*/
      e.preventDefault();
      if (currentFocus > -1) {
        /*and simulate a click on the "active" item:*/
        if (x) { x[currentFocus].click(); }
      }
    }
  });
  function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) { return false; }
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    if (currentFocus >= x.length) { currentFocus = 0; }
    if (currentFocus < 0) { currentFocus = (x.length - 1); }
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add('autocomplete-active');
  }
  function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (const item of x) {
      item.classList.remove('autocomplete-active');
    }
  }
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    const x = document.getElementsByClassName('autocomplete-items');
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < x.length; i++) {
      if (elmnt !== x[i] && elmnt !== inp) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }
  /*execute a function when someone clicks in the document:*/
  document.addEventListener('click', e => {
    closeAllLists(e.target);
  });
};
export const highlightText = (searchWord) => {
  $.extend($.expr[':'], {
    containsi(elem, i, match, array) {
      return (elem.textContent || elem.innerText || '').toLowerCase()
        .indexOf((match[3] || '').toLowerCase()) >= 0;
    }
  });
  $('.highlightSearch').attr('data-mdd', null).attr('data-type', null).removeClass('highlightSearch').removeClass('animateHighLightSearch');
  const spans = $('.nodeLabel span:containsi(' + searchWord + ')');
  const tds = $('.nodeLabel td:containsi(' + searchWord + ')');
  const ths = $('.nodeLabel th:containsi(' + searchWord + ')');
  $('.nodeLabel i.fa-layer-group').each(function() {
    if (this.nextSibling.textContent.toLowerCase().includes(searchWord.toLowerCase())) {
      $(this.parentElement).addClass('highlightSearch');
    }
  });
 // console.log(spans, tds, ths);
  if (searchWord !== '') {
    spans.each(function() {
      $(this).addClass('highlightSearch');
    });
    tds.each(function() {
      $(this).addClass('highlightSearch');
    });
    ths.each(function() {
      $(this).addClass('highlightSearch');
    });
  }
 // $('#stickSearch .body').html($('.highlightSearch').length);
  const searchs = [];
  $('.highlightSearch').each(function(i, e) {
   // console.log(e, i);
    const htm = $(this)[0].innerHTML;
    const text = $(this)[0].innerText;
    if (htm.includes('fa-layer-group')) {
      searchs.push({text, type: 'group' , index: i});
      $(this).attr('data-type', 'group');
      $(this).attr('data-mdd', i);
    } else if (htm.includes('fas fa-server')) {
      searchs.push({text, type: 'object' , index: i });
      $(this).attr('data-type', 'object');
      $(this).attr('data-mdd', i);
    } else if (htm.includes('fas fa-vector-square')) {
      searchs.push({text, type: 'record' , index: i});
      $(this).attr('data-type', 'record');
      $(this).attr('data-mdd', i);
    } else {
      searchs.push({text, type: 'field' , index: i});
      $(this).attr('data-type', 'field');
      $(this).attr('data-mdd', i);
    }
  });
 // console.log(searchs);
  return searchs;
 /* const top = $('.highlightSearch').first().offset().top - 70;
  window.scroll({ top , left: 0, behavior: 'smooth'});*/
};
export const draggable = (el) => {
    el.addEventListener('mousedown', function(e) {
      const offsetX = e.clientX - parseInt(window.getComputedStyle(this).left, 10);
      const offsetY = e.clientY - parseInt(window.getComputedStyle(this).top, 10);

      // tslint:disable-next-line:no-shadowed-variable
      function mouseMoveHandler(e) {
        el.style.top = (e.clientY - offsetY) + 'px';
        el.style.left = (e.clientX - offsetX) + 'px';
      }

      function reset() {
        window.removeEventListener('mousemove', mouseMoveHandler);
        window.removeEventListener('mouseup', reset);
      }

      window.addEventListener('mousemove', mouseMoveHandler);
      window.addEventListener('mouseup', reset);
    });
};
export class InternMap extends Map {
  constructor(entries = null, key = keyof) {
    super();
    Object.defineProperties(this, {_intern: {value: new Map()}, _key: {value: key}});
    // tslint:disable-next-line:no-shadowed-variable
    if (entries != null) { for (const [key, value] of entries) { this.set(key, value); } }
  }
  get(key) {
    // @ts-ignore
    return super.get(intern_get(this, key));
  }
  has(key) {
    // @ts-ignore
    return super.has(intern_get(this, key));
  }
  set(key, value) {
    // @ts-ignore
    return super.set(intern_set(this, key), value);
  }
  delete(key) {
    // @ts-ignore
    return super.delete(intern_delete(this, key));
  }
}
export const keyof = (value) => {
  return value !== null && typeof value === 'object' ? value.valueOf() : value;
};
function intern_get({_intern, _key}, value) {
  const key = _key(value);
  return _intern.has(key) ? _intern.get(key) : value;
}
function intern_set({_intern, _key}, value) {
  const key = _key(value);
  if (_intern.has(key)) { return _intern.get(key); }
  _intern.set(key, value);
  return value;
}
function intern_delete({_intern, _key}, value) {
  const key = _key(value);
  if (_intern.has(key)) {
    value = _intern.get(key);
    _intern.delete(key);
  }
  return value;
}
export const barChart = (json) => {
  $('#chart-mdd-frequency').empty();
  const width = 250;
  const height = 200;
  // tslint:disable-next-line:no-shadowed-variable
  const sort = data => data.sort((a, b) => d3.ascending(a.value, b.value));
  // tslint:disable-next-line:no-shadowed-variable
  const totals = data => data.reduce((a, b) => ({value: a.value + b.value})).value;
  // tslint:disable-next-line:no-shadowed-variable
  const percent = (a, data) => a / totals(data);
  const data = sort(json);
  const size = totals(json);
  console.log(data);
  console.log(size);
  console.log(percent(91, data));
  const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];
  const formatPercent = d3.format('.1%');
  const formatPercentY = d3.format('.0');
  const tooltip = d3
    .select('body')
    .append('div')
    .attr('class', 'd3-tooltip')
    .style('position', 'absolute')
    .style('z-index', '999999')
    .style('visibility', 'hidden')
    .style('padding', '10px')
    .style('background', 'rgba(0,0,0,0.6)')
    .style('border-radius', '4px')
    .style('color', '#fff')
    .text('a simple tooltip');
  const x = d3
    .scaleBand()
    .range([0, width])
    .domain(data.map(d => d.type))
    .padding(0.2);
  const y = d3
    .scaleLinear()
    .domain([0, size / 100])
    .range([height, 0]);
  const svg = d3.select('#chart-mdd-frequency')
    .style('display', 'block')
    .attr('viewBox', [0, 0, width, height]);
  const g = svg.append('g').attr('id', 'container-freq');
  const xAxis = d3.axisBottom(x).tickSize([]).tickPadding(10);
  const yAxis = d3.axisLeft(y); // .tickFormat(formatPercentY);
  g.append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + height + ')')
    .call(xAxis);
  g.append('g')
    .attr('class', 'y axis')
    .call(yAxis);
  const rects = g.append('g');
  const rect = rects.selectAll('rect')
    .data(data)
    .enter()
    .append('rect')
    .attr('fill', (d, i) => colors[i])
    .attr('x', (d, i) => x(d.type))
    .attr('width', x.bandwidth())
    .attr('y', d => y(0) / 1000)
    .attr('height', d => height - (y(0) / 100))
    .on('mouseover', function(d, i) {
      tooltip
        .html(
          `<div>${d.type}</div><div>Percent: ${formatPercent(percent(d.value, data))}</div>`
        )
        .style('visibility', 'visible');
      d3.select(this).transition().attr('opacity', 0.5);
    })
    .on('mousemove', () => {
      tooltip
        .style('top', d3.event.pageY - 80 + 'px')
        .style('left', d3.event.pageX + 10 + 'px');
    })
    .on('mouseout', function() {
      tooltip.html(``).style('visibility', 'hidden');
      d3.select(this).transition().attr('opacity', 1);
    });

  rect
    .transition()
    .ease(d3.easeElasticOut.amplitude(1).period(0.3))
    .duration(800)
    .attr('y', d => y(d.value) / 1000)
    .attr('height', d => height - (y(d.value) / 1000))
    .delay((d, i) => i * 1000);
 // return svg.node();
};
export const percentBar = (json, container, type = 'frequency') => {
  $('#' + container).empty();
  const svg = d3.select('#' + container).style('display', 'block').attr('cursor', 'default');
  const colors = ['#1f77b4', '#ff7f0e', '#2ca02c', '#d62728', '#9467bd', '#8c564b', '#e377c2', '#7f7f7f', '#bcbd22', '#17becf'];
  const margin = 0;
  const width = 250 - 2 * margin;
  const height = 200 - 2 * margin;
  const formatPercent = d => {
    if (!Number.isInteger(d)) {
      d = d3.format('.1f')(d); // add the decimal point for non-integers
    }
    return d + '%';
  };
  let totals = data => data.reduce((a, b) => ({value: a.value + b.value})).value;
  if (type !== 'frequency') {
     totals = data => d3.max(data, d => d.size);
  }
  // const totals = data => data.reduce((a, b) => ({value: a.value + b.value})).value;
  // tslint:disable-next-line:no-shadowed-variable
  let percent = (a, data) => (a.value / totals(data)) * 100;
  if (type !== 'frequency') {
    percent = (a, data) => (a.value / a.size) * 100;
  }
  const size = totals(json);
  console.log(size);
  const maxYaxis = size > 20 ? size % 20 > 0 ? (Math.floor(size / 20) * 20) + 20 : size : size;
  const tip = d3Tip()
    .attr('class', 'd3-tip-mdd')
    .offset([-100, 0])
    .html(d => type === 'frequency' ? '<strong>' + d.type + ':</strong> <span style=\'color:#085828\'>' + formatPercent(percent(d, json)) + ' of ' + d.size + ' </span>' : '<strong>' + d.type + ':</strong> <span style=\'color:#085828\'>' + formatPercent(percent(d, json)) + ' of ' + size + ' </span>' );
  svg.call(tip);
 // console.log(size);
 // console.log(percent(91, json));
  let chart;
  if (type !== 'frequency') {
     chart = svg.append('g')
      .attr('id', 'container-perc')
      .attr('transform', 'translate(37,-30) scale(1)');
  } else {
    chart = svg.append('g')
      .attr('id', 'container-freq')
      .attr('transform', 'translate(37,-30) scale(1)');
  }
  const xScale = d3.scaleBand()
    .range([0, width])
    .domain(json.map((s) => s.type))
    .padding(0.3);

  const yScale = d3.scaleLinear()
    .range([height, 0])
    .domain([0, maxYaxis]);

  // vertical grid lines
  // const makeXLines = () => d3.axisBottom()
  //   .scale(xScale)

  const makeYLines = () => d3.axisLeft()
    .scale(yScale);

  chart.append('g')
    .attr('transform', `translate(0, ${height})`)
    .call(d3.axisBottom(xScale));

  chart.append('g')
    .call(d3.axisLeft(yScale));

  // vertical grid lines
  // chart.append('g')
  //   .attr('class', 'grid')
  //   .attr('transform', `translate(0, ${height})`)
  //   .call(makeXLines()
  //     .tickSize(-height, 0, 0)
  //     .tickFormat('')
  //   )

  chart.append('g')
    .attr('class', 'grid')
    .call(makeYLines()
      .tickSize(-width, 0, 0)
      .tickFormat('')
    );

  const barGroups = chart.selectAll()
    .data(json)
    .enter()
    .append('g');

  const rect = barGroups
    .append('rect')
    .attr('class', 'bar')
    .attr('x', (g) => xScale(g.type))
    .attr('y', (g) => yScale(0))
    .attr('fill', (g, i) => colors[i])
   // .attr('height', (g) => height - yScale(g.value))
    .attr('height', 0)
    .attr('width', xScale.bandwidth())
    .on('mouseover', function(d) {
      tip.show(d, this);
    })
    .on('mouseout', function(d) {
      tip.hide(); })
    .on('mouseenter', function(actual, i) {
      d3.selectAll('.value')
        .attr('opacity', 0);
     // tip.show(actual, this);
      d3.select(this)
        .transition()
        .duration(300)
        .attr('opacity', 0.6)
        .attr('x', (a) => xScale(a.type) - 5)
        .attr('width', xScale.bandwidth() + 10);

      const y = yScale(actual.value);

      const line = chart.append('line')
        .attr('id', 'limit')
        .attr('stroke', '#FED966')
        .attr('stroke-width', '3')
        .attr('stroke-dasharray', '3.6')
        .attr('x1', 0)
        .attr('y1', y)
        .attr('x2', width)
        .attr('y2', y);

      barGroups.append('text')
        .attr('class', 'divergence')
        .attr('x', (a) => xScale(a.type) + xScale.bandwidth() / 2)
        .attr('y', (a) => yScale(a.value) + 30)
        .attr('fill', 'white')
        .attr('font-size', '10px')
        .attr('text-anchor', 'middle')
        .text((a, idx) => {
          const newSize = size - actual.value;
          //const divergence = (a.value - actual.value).toFixed(1);
          const divergence = (a.value /  newSize) * 100;
          let text = '';
          // @ts-ignore
          if (divergence > 0) { text += '+'; }
          text += `${formatPercent(divergence)}`;

          return idx !== i ? text : '';
        });

    })
    .on('mouseleave', function() {
      d3.selectAll('.value')
        .attr('opacity', 1);
     // tip.hide();
      d3.select(this)
        .transition()
        .duration(300)
        .attr('opacity', 1)
        .attr('x', (a) => xScale(a.type))
        .attr('width', xScale.bandwidth());

      chart.selectAll('#limit').remove();
      chart.selectAll('.divergence').remove();
    });

  barGroups
    .append('text')
    .attr('class', 'value')
    .attr('x', (a) => xScale(a.type) + xScale.bandwidth() / 2)
    .attr('y', (a) => yScale(a.value) + 30)
    .attr('text-anchor', 'middle')
    .attr('font-size', '10px')
    .text((a) => `${formatPercent(percent(a, json))}`);

  svg
    .append('text')
    .attr('class', 'label')
    .attr('id', 'search-meter')
    .attr('class', 'search-meter')
    .attr('x', -(height / 2) - margin)
    .attr('y', margin / 2.4)
    .attr('transform', 'rotate(-90)')
    .attr('text-anchor', 'middle')
    .text('Search meter (%)');
  rect
    .transition()
    .ease(d3.easeElasticOut.amplitude(1).period(0.3))
    .duration(800)
    .attr('y', d => yScale(d.value))
    .attr('height', d => height - yScale(d.value))
    .delay((d, i) => i * 1000);
  d3.selectAll('.tick line').attr('stroke-opacity', '0.3');
  d3.selectAll('.tick line').attr('stroke', '#9FAAAE');
};
