import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
@Injectable({
  providedIn: 'root'
})
export class ListusersService {
    Options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            //  'Accept': 'Application/json'
        })
    };
  constructor(private http: HttpClient) { }

  getUsersList(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/users/getList/' + localStorage.getItem('userId'), this.Options);
  }
}
