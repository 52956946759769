import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {OrderPipe} from 'ngx-order-pipe';
import {DesignerService} from '../../services/designer/designer.service';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {addLoader, getCookieValue, refreshComponent, removeLoader, Shared} from '../../shared';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {exitCodeFromResult} from '@angular/compiler-cli';
import {CryptoService} from '../../crypto.service';
import {TranslateService} from '@ngx-translate/core';
import {RouterhistoryService} from '../../services/appService/routerhistory.service';
declare const $: any;
declare const CKEDITOR: any;
@Component({
  selector: 'app-designer',
  templateUrl: './designer.component.html',
  styleUrls: ['./designer.component.css']
})
export class DesignerComponent implements OnInit, OnDestroy, AfterViewInit {
  lang: string;
  // role designer
  roleRead = true;
  roleUpdate = false;
  roleCreate = false;
  roleDelete = false;

  hiderListDiagram = true;
  // triage list data
  asyncDiagram: Observable<any[]>;
  sortedCollection: any[];
  pageSizeList = [5, 10 , 15 , 20 , 25 , 30 ];
  selectedPageSize = 5;
  diagramconfig: any;
  totalListDiagram = 0;
  p = 1;
  dataFilter: any;
  listImageInetr = [];
  listDiagramInetr = [];
  listDiagram = [];
  flag = false;
  order = 'id';
  reverse = false;
  loadingDiagram: boolean;
  endLoadDiagram = false;
  selectedDiagramID: string;
  ids = [];
  // edit delete formGroup record data map
  disableEdit = true;
  descriptionDiagUP = '';
  nameUpdate: string;
  editData = false;
  refreshListDiag = false;

  // menu shared
  flagMenuShared = true;
  listAdUsersCheck = [];
  listAdUserspreCheck = [];
  chechAllUsers = false;
  // save modif of shared
   updateInShared = false;
  listUsersToShared = [];
  // save edit diagram -> get sharedType ans sharedUsers
  sharedType = '';
  sharedUsers = [];
  sharedAllUsersRoles = [];
  existUsersHaventRoles = false;
  sharedUsersByNameEmail = [];
  selectedSharedtypeTemp = '';
  existUserRoleDesignerToShared = false;
  alertDiagNotEmty = true;
  tabExistDiagCreatedByUser = [];
  /***************************************************/
  hiderListSharedDiagram = true;
  shareddiagramconfig: any;
  selectedPageSizeShared = 5;
  pShared = 1;
  dataFilterShared: any;
  totalListSharedDiagram = 0;

  listSharedDiagram = [];
  flagShared = false;
  orderShared = 'id';
  reverseShared = false;
  sortedCollectionShared: any[];
  asyncDiagramShared: Observable<any[]>;

  disableEditShared = true;
  descriptionDiagUPShared = '';
  selectedSharedDiagramID: string;
  editDataShared = false;
  refreshListDiagShared = false;
  alertSharedDiagNotEmty = true;
  // add new designer by formGroup
  public frmAddNewDesigner: FormGroup;
  addFormNewDiag: FormGroup;
  descriptionNewDiag: any;
  nameNewDiag: any;
  validateName = true;
  listApplications = [];
  listMs = [];
  selectedApplications = [];
  selectedApplicationName = [];
  selectedMs = [];
  selectedMsTemp = [];
  selectedApplication: null;

  subscription: Subscription = new Subscription();
  modelDesignerBridge = {system: '', diagName: '', diagId: '', component: ''};
  showBack = false;
  id = '';
  constructor(private orderPipe: OrderPipe, private designerService: DesignerService, private fbNewDiag: FormBuilder,
              private dynamicScriptLoader: DynamicScriptLoaderService, private router: Router, private routerHistoryService: RouterhistoryService,
              private cryptoService: CryptoService, private translate: TranslateService, private activeRoute: ActivatedRoute) {
    this.diagramconfig = { id: 'server', itemsPerPage: this.selectedPageSize, currentPage: this.p, totalItems: this.totalListDiagram };
    this.shareddiagramconfig = { id: 'serverShared', itemsPerPage: this.selectedPageSizeShared, currentPage: this.pShared, totalItems: this.totalListSharedDiagram };
    this.frmAddNewDesigner = this.initFormNewDiag();
  }

  ngOnInit() {
    // addLoaderWaitRp('vide', 'f', true);
    let loadingMessage = this.lang ? 'P L E A S E &#160; W A I T' : 'S\'il vous plaît, patientez';
    addLoader(loadingMessage);    
    localStorage.removeItem('addOrUpdateDiag');
    localStorage.removeItem('listExistDiag');
    $('#designer_list_diagram').hide();
    $('#designer_list_shared_diagram').hide();
    this.activeRoute.paramMap.subscribe(params => {
      let system = params.get('application');
      let diagName = params.get('model');
      let diagId = params.get('componentId');
      let component = params.get('component');
      // change ---------- to /
      system = system.replace(/__________/g, '/');
      diagName = diagName.replace(/__________/g, '/');
      diagId = diagId.replace(/__________/g, '/');
      component = component.replace(/__________/g, '/');
      // decrypt params
      system = this.cryptoService.get(system);
      diagName = this.cryptoService.get(diagName);
      diagId = this.cryptoService.get(diagId);
      component = this.cryptoService.get(component);
      this.modelDesignerBridge.system = system;
      this.modelDesignerBridge.diagName = diagName;
      this.modelDesignerBridge.diagId = diagId;
      this.modelDesignerBridge.component = component;
    });
    // console.log(this.modelDesignerBridge);
    this.getListRoleDesigner();
    // this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'en';
      this.translate.use(this.lang);
    }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.showTipsModelDesigner();
    },1000);
  }
  //////////////////////// model designer tips ///////////////////////////////
  // showTipsModelDesigner() {
  //   if ((localStorage.getItem('dataSoumenu') == 'model-designer') && (localStorage.getItem('dataTips') == 'true') && (localStorage.getItem('dontShowTipsModelDesigner') == 'false')) {
  //     localStorage.setItem('dontShowTipsModelDesigner', 'true');
  //     setTimeout(() => {
  //       $('#tipsCompModelDesigner').modal('show');
  //     },1000);
  //   }
  // }
  showTipsModelDesigner() {
    let menu = localStorage.getItem('dataMenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsModelDesigner');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      // console.log('first');
      // (localStorage.getItem('dataSoumenu') == 'model-designer') && (localStorage.getItem('dataTips') == 'true') && (
      if ((localStorage.getItem('dontShowTipsModelDesigner') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsModelDesigner', 'true');
        setTimeout(() => {
          $('#tipsCompModelDesigner').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        // console.log('second');
        if ((localStorage.getItem('dontShowTipsModelDesigner') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsModelDesigner', 'true');
          $('#tipsCompModelDesigner').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips model designer
  onUpdateTipsModelDesigner() {
    let tipsModelDesigner;
    if ($('#checked_show_modelDesigner').is(':checked')) {
      tipsModelDesigner = false;
    } else {
      tipsModelDesigner = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsModelDesigner,
      dataMenu: 'Diagrams',
      dataSousmenu: 'model-designer'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        // console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  //////////////////////// end model designer tips //////////////////////////////////////////////
  // get role designer CRUD
  getListRoleDesigner() {
    let subscription1$ = this.designerService.getListRoleDesigner().subscribe(
      data => {
        this.roleRead = data.read;
        this.roleUpdate = data.update;
        this.roleCreate = data.create;
        this.roleDelete = data.delete;
      },
      err => {
        console.error(err);
      },
      () => {
        this.onGetUserHaventRoleDesign();
      }
    );
    this.subscription.add(subscription1$);
  }
  // show / hide list Diagrams in menu
  showOrHideListDiagram() {
    let that = this;
    if (this.hiderListDiagram) {
      if (this.listDiagram.length == 0) {
        this.onGetListDiagrams();
      }
      $('#designer_list_diagram').show();
      $('#arrow_list_diagram').html('<i class="fas fa-angle-up"></i>');
      this.hiderListDiagram = false;
      $('#designer_list_diagram').animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        // $('#listbutton_export_import').attr('style', 'display: flex!important');
        $('#listdiagram_pagin_search').attr('style', 'display: flex!important');
        // that.onGetListRetention();
      });
    } else {
      this.hiderListDiagram = true;
      $('#arrow_list_diagram').html('<i class="fas fa-angle-down"></i>');
      $('#designer_list_diagram').animate({
        height: '0'
      }, 1, 'swing', function() {
        $('#designer_list_diagram').hide();
        // $('#listbutton_export_import').attr('style', 'display: none!important');
        $('#listdiagram_pagin_search').attr('style', 'display: none!important');
      });
    }
  }
  // list users havent rolesضضض
  onGetUserHaventRoleDesign() {
    let subscription2$ = this.designerService.getUserHaventRoleDesign('all', 'no-menu').subscribe(
      result => {
        result.data.map(item => {
          this.sharedAllUsersRoles.push(item);
        });
      },
      err => {
        this.sharedAllUsersRoles = [];
        this.existUsersHaventRoles = false;
        console.error(err);
      },
      () => {
        this.sharedAllUsersRoles.length > 0 ? this.existUsersHaventRoles = true : this.existUsersHaventRoles = false;
        if (this.modelDesignerBridge.system == 'shared') {
          this.showOrHideListSharedDiagram();
        } else {
          this.showOrHideListDiagram();
        }
      }
    );
    this.subscription.add(subscription2$);
  }
  // list data map
  onGetListDiagrams() {
    $('#spinner_list_diagram').css('display', 'block');
    this.listDiagram = [];
    this.tabExistDiagCreatedByUser = [];
    let subscription3$ = this.designerService.getListDiagramsDesigner().subscribe(
      data => {
        $('#spinner_list_diagram').css('display', 'none');
        data.map(item => {
          this.tabExistDiagCreatedByUser.push(item.name);
          this.listDiagram.push(item);
          if (item.imagedata != 'null') {
            this.listImageInetr.push(atob(item.imagedata));
          } else {
            this.listImageInetr.push(item.imagedata);
          }

        });
        for ( let i = 0; i < this.listDiagram.length; i++) {
          this.listDiagram[i].imagedata = this.listImageInetr[i];
        }
        // $('#bt').css('display', 'block');
        $('#listdiagram_pagin_search').attr('style', 'display: flex!important');
        $('#paginate_diagram').removeAttr('hidden');
      },
      err => {
        $('#spinner_list_diagram').css('display', 'none');
        console.error(err);
      },
      () => {
        $('#spinner_list_diagram').css('display', 'none');
        this.listDiagram.length == 0 ? this.alertDiagNotEmty = false : this.alertDiagNotEmty = true;
        this.sortedCollection = this.orderPipe.transform(this.asyncDiagram, 'id');
        // if (this.modelDesignerBridge.component == 'global-search') {
          // setTimeout(() => {
          //   this.dataFilter = this.modelDesignerBridge.diagName;
          //   $('[data-name=\'' + this.modelDesignerBridge.diagId + '\']').click();
          // }, 500);
        // } else
        if (this.modelDesignerBridge.component == 'designer') {
          this.showBack = false;
          setTimeout(() => {
            if (this.modelDesignerBridge.system == 'notShared') {
              this.dataFilter = this.modelDesignerBridge.diagId;
              $('[data-name=\'' + this.modelDesignerBridge.diagId + '\']')[0].click();
            }
          }, 500);
        }
        if (this.modelDesignerBridge.component == 'project') {
          this.showBack = true;
          setTimeout(() => {
              this.dataFilter = this.modelDesignerBridge.diagName;
              $('[data-name=\'' + this.modelDesignerBridge.diagName + '\']')[0].click();
          }, 500);
        }
        // console.log(this.modelDesignerBridge)
        removeLoader();
        // console.log(this.modelDesignerBridge.diagName);
      }
    );
    this.subscription.add(subscription3$);
  }
  // sort list diagram
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  // comparator sort
  sortAlphaNum(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  pageListDiagramChanged(event) {
    this.diagramconfig.currentPage = event;
  }
  getPage() {
    this.diagramconfig.itemsPerPage = this.selectedPageSize;
    this.diagramconfig.currentPage = 1;
    this.diagramconfig.totalItems = this.listDiagram.length;
  }
  // CKEditor update
  async startScriptUP(row) {
    this.descriptionDiagUP = '';
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [ ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', 'Superscript', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'], '-', 'NumberedList'] ];
      this.descriptionDiagUP = row.description;
      this.loadDataUP(row);
    }).catch(error => console.error(error));
  }

  public loadDataUP(row) {
    const that = this;
    if(typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['descriptionDiagUP' + row.id]) {
      CKEDITOR.instances['descriptionDiagUP' + row.id].destroy(true);
    }
    var editor = CKEDITOR.replace('descriptionDiagUP' + row.id, { toolbar: 'MA' });
    editor.config.height = 110;
    if (row.description != null && row.description != 'undefined') {
      if (row.description.length > 0) {
        if (row.description != ' ') {
          editor.setData(atob(row.description));
        }
      }
    }
    editor.on( 'change', function( evt ) {
      that.descriptionDiagUP = btoa(evt.editor.getData());
      // console.log(that.descriptionDiagUP);
    });
  }
  controlnameUpdate(num, row) {
    if (num == 1) {
      if ($('#nameUpdate' + row.id).val() == '') {
        $('#nameUpdate' + row.id).addClass('is-invalid');
      } else {
        $('#nameUpdate' + row.id).removeClass('is-invalid');
      }
    } else if (num == 2) {
      if ($('#nameUpdateShared' + row.id).val() == '') {
        $('#nameUpdateShared' + row.id).addClass('is-invalid');
      } else {
        $('#nameUpdateShared' + row.id).removeClass('is-invalid');
      }
    }
  }
  cancelUpdate(row, i) {
    this.updateInShared = false;
    this.sharedType = this.listDiagram.find(x => x.id == this.selectedDiagramID).sharedtype;
    this.sharedUsers = this.listDiagram.find(x => x.id == this.selectedDiagramID).sharedusers;
    this.sharedUsersByNameEmail = [];
    this.sharedUsers.map(item => {
      if (this.sharedAllUsersRoles.find(x => x.id == item)) {
        this.sharedUsersByNameEmail.push(this.sharedAllUsersRoles.find(x => x.id == item));
      }
    });
    switch (this.sharedType) {
      case 'private' : $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
      case 'public': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: blue" class="fas fa-users fa-2x"></i>'); break;
      case 'users': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: green" class="fas fa-user-plus fa-2x"></i>'); break;
      case 'usersWithout': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: red" class="fas fa-user-minus fa-2x"></i>'); break;
      default : $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
    }
    $('#menuShare').css('display', 'none');
    $('#menuContainer' + row.id).animate({
      opacity: 0,
      height: 0// '20vh'
    }, 500, 'easeOutBounce', function () {
// Animation complete.
    });
    $('#menuShare').animate({
      opacity: 0
    }, 500, 'easeOutBounce', function () {
// Animation complete.
    });
    this.flagMenuShared = true;

    this.disableEdit = true;
    $('#nameUpdate' + row.id).val(this.listDiagram[i].name);
    if (row.description != null && row.description != 'undefined') {
      if (row.description.length > 0) {
        if (row.description != ' ') {
          $('#descriptionDisabled' + row.id).html(atob(this.listDiagram[i].description));
        } else {
          $('#descriptionDisabled' + row.id).html('');
        }
      } else {
        $('#descriptionDisabled' + row.id).html('');
      }
    } else {
      $('#descriptionDisabled' + row.id).html('');
    }
    $('#descriptionDisabled' + row.id).css({opacity: 1, height: '80%'});
    $('#descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
    $('#cke_descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
  }
  onUpdateInfDiag(row, i) {
    if (this.disableEdit) {
      this.disableEdit = false;
      // row.application != null ? this.selectedApplication = row.application : this.selectedApplication = this.listApplications[0];
      this.startScriptUP(row);
      $('#descriptionDisabled' + row.id).css({opacity: 0, height: 0});
      $('#descriptionDiagUP' + row.id).css({opacity: 1, height: '80%'});
      $('#cke_descriptionDiagUP' + row.id).css({opacity: 1, height: '80%'});
    } else {
      // console.log(this.sharedType);
      // hide modification
      $('#menuShare').css('display', 'none');
      $('#menuContainer' + row.id).animate({
        opacity: 0,
        height: 0// '20vh'
      }, 500, 'easeOutBounce', function () {});
      $('#menuShare').animate({
        opacity: 0
      }, 500, 'easeOutBounce', function () {});
      this.flagMenuShared = true;

      if ($('#nameUpdate' + row.id).val() == '') {
        $('#nameUpdate' + row.id).addClass('is-invalid');
        return false;
      } else {
        $('#nameUpdate' + row.id).removeClass('is-invalid');
        this.nameUpdate = $('#nameUpdate' + row.id).val();
      }
      // end hide modification
      Swal.fire({
        title: this.lang ? 'Are you sure?' : 'Êtes-vous sûr(e) ?',
        text: this.lang ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang ? 'Yes, Update it!' : 'Oui, Mettez à jour !',
        cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
      }).then((result) => {
        if (result.value) {
          // addLoaderUpdateRp('vide', 'f', true);
          let loadingMessage = this.lang ? 'U P D A T E  D I A G R A M' : 'MISE À JOUR  DU  DIAGRAMME';
          addLoader(loadingMessage);
          
          // let dataDiagram;
          // if (this.sharedUsers == null) {
          //   dataDiagram = {
          //     username: getCookieValue('username'),
          //     idDiag: row.id,
          //     descriptionDiag: this.descriptionDiagUP,
          //     nameDiag: this.nameUpdate,
          //     sharedType: this.sharedType,
          //     sharedusers: null
          //   };
          // } else {
          let dataDiagram = {
              username: getCookieValue('username'),
              idDiag: row.id,
              descriptionDiag: this.descriptionDiagUP,
              nameDiag: this.nameUpdate,
              // appsDiag: this.selectedApplications,
              sharedType: this.sharedType,
              sharedusers: this.sharedUsers
            };
          // }

          let subscription4$ = this.designerService.onUpdateInfDiag(dataDiagram).subscribe(
            data => {
              if (data != 'error in request') {
                this.editData = true;
                this.listDiagram[i].name = data['name'];
                // this.listDiagram[i].application = data['application'];
                this.listDiagram[i].updatedAt = data['updatedAt'];
                this.listDiagram[i].updatedBy = data['updatedBy'];
                this.listDiagram[i].sharedtype = data['sharedtype'];
                this.listDiagram[i].sharedusers = data['sharedusers'];
                if (data['description'] != null && data['description'] != 'undefined') {
                  if (data['description'].length > 0) {
                    if (data['description'] != ' ') {
                      $('#descriptionDisabled' + row.id).html(atob(data['description']));
                      this.listDiagram[i].description = data['description'];
                    } else {
                      this.listDiagram[i].description = null;
                    }
                  } else {
                    this.listDiagram[i].description = null;
                  }
                } else {
                  this.listDiagram[i].description = null;
                }
              } else {
                alert(data);
              }
            },
            err => {
              this.disableEdit = true;
              $('#descriptionDisabled' + row.id).css({opacity: 1, height: '80%'});
              $('#descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
              $('#cke_descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
                removeLoader();
              console.error(err);
            },
            () => {
              this.disableEdit = true;
              $('#descriptionDisabled' + row.id).css({opacity: 1, height: '80%'});
              $('#descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
              $('#cke_descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
                removeLoader();
            }
          );
          this.subscription.add(subscription4$);
        }
      });
    }
  }

  onSetRemoveDiagram(id) {
    var that = this;
    Swal.fire({
      title: this.lang ? 'Are you sure?' : 'Êtes-vous sûr(e) ?',
      text: this.lang ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.lang ? 'Yes, delete it!' : 'Oui, Supprimez-le !',
      cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
    }).then((result) => {
      if (result.value) {
        // addLoaderDeleteRp('vide', 'f', true);
        addLoader(this.lang === 'en' ? 'REMOVE DIAGRAM' : 'SUPPRIMER DIAGRAMME');
        let subscription5$ = that.designerService.onSetRemoveDiagram(id, 'check').subscribe(
          data => {
            if( data.success == 'this modelDesigner is associated with some projects'){
              Swal.fire({
                title: this.lang ? 'ModelDesigner is associated with some projects?' : 'Le modèle de concepteur est associé à certains projets ?',
                text: this.lang ? 'This modelDesigner is associated with some projects!\nWould you like to delete it even from these projects?' : 'Ce modèle de concepteur est associé à certains projets !\nVoulez-vous le supprimer même de ces projets ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.lang ? 'Yes, proceed!' : 'Oui, procéder !',
                cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
              }).then((result) => {
                if(result.value){
                  this.designerService.onSetRemoveDiagram(id, 'forceDelete').subscribe(
                    d =>{
                      Swal.fire({
                        title: this.lang ? 'Success!' : 'Succès !',
                        text: this.lang ? 'Deleted with success' : 'Supprimé avec succès',
                        icon: 'success',
                        confirmButtonText: this.lang ? 'Ok' : 'D\'accord'
                      });
                      
                      this.flag = false;
                      this.refreshListDiag = true;
                      this.dataFilter = null;
                    }
                  );
                }
              });
            } else if(data.success == 'OK'){
              Swal.fire({
                title: this.lang ? 'Success!' : 'Succès !',
                text: this.lang ? 'Deleted with success' : 'Supprimé avec succès',
                icon: 'success',
                confirmButtonText: this.lang ? 'Ok' : 'D\'accord'
              });
              this.flag = false;
              this.refreshListDiag = true;
              this.dataFilter = null;
            }
          },
          err => {
            console.error(err);
          },
          () => {
            this.refreshListDiagram();
              removeLoader();
          }
        );
        that.subscription.add(subscription5$);
      }
    });
  }
  // refreshListeDataMap after closing modal
  refreshListDiagram() {
    if (this.refreshListDiag) {
      this.onGetListDiagrams();
      this.editData = false;
    }
  }
  // show detail diagrams
  showDetailDiagram(row, event) {
    this.sharedType = this.listDiagram.find(x => x.id == row.id).sharedtype;
    this.sharedUsers = row.sharedusers;
    this.sharedUsersByNameEmail = [];
    this.sharedUsers.map(item => {
      if (this.sharedAllUsersRoles.find(x => x.id == item)) {
        this.sharedUsersByNameEmail.push(this.sharedAllUsersRoles.find(x => x.id == item));
      }
    });
    switch (this.sharedType) {
      case 'private' : $('#sharedDiagUP' + row.id).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
      case 'public': $('#sharedDiagUP' + row.id).html('<i style="color: blue" class="fas fa-users fa-2x"></i>'); break;
      case 'users': $('#sharedDiagUP' + row.id).html('<i style="color: green" class="fas fa-user-plus fa-2x"></i>'); break;
      case 'usersWithout': $('#sharedDiagUP' + row.id).html('<i style="color: red" class="fas fa-user-minus fa-2x"></i>'); break;
      default : $('#sharedDiagUP' + row.id).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
    }
    var id = row.id;
    this.id = row.id;
    this.selectedDiagramID = id;
    const ul = $('#' + id).parent().parent();
    const li = $('#' + id).parent();
    if (!this.flag) {
      this.selectedApplications = row.applications;
      if (row.description != null && row.description != 'undefined') {
        if (row.description.length > 0) {
          if (row.description != ' ') {
            $('#descriptionDisabled' + row.id).html(atob(row.description));
          }
        }
      }
      this.flag = true;
      $('#body' + id).slideDown();
      // event.target.innerHTML = 'Back';
      // $('#listbutton_export_import').attr('style', 'display: none!important');
      $('#listdiagram_pagin_search').attr('style', 'display: none!important');
      ul.children().hide();
      $('#titleDiagram').show('slow');
      li.show('slow');
      // $('#bt').css('display', 'none');
      // this.selectedUpdateCode = row.country;
      // this.selectedUpdateCurrDate = row.currentdate;
      // this.selectedUpdateTermDate = row.termdate;
      $('#menuShare' + id).css('display', 'none');
      $('#menuContainer' + id).animate({
        opacity: 0,
        height: 0// '20vh'
      }, 500, 'easeOutBounce', function () {
// Animation complete.
      });
      $('#menuShare' + id).animate({
        opacity: 0
      }, 500, 'easeOutBounce', function () {
// Animation complete.
      });
      this.flagMenuShared = true;

      this.disableEdit = true;
      $('#descriptionDisabled' + row.id).css({opacity: 1, height: '80%'});
      $('#descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
      $('#cke_descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
    } else {
      this.dataFilter = null;
      this.disableEdit = true;
      this.selectedApplications = [];
      $('#descriptionDisabled' + row.id).css({opacity: 0, height: 0});
      $('#descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
      $('#cke_descriptionDiagUP' + row.id).css({opacity: 0, height: 0});
      // $('#bt').css('display', 'block');
      // $('#listbutton_export_import').attr('style', 'display: flex!important');
      $('#listdiagram_pagin_search').attr('style', 'display: flex!important');
      // event.target.innerHTML = 'Show Details';
      $('#spinner_list_diagram').css('display', 'none');
      this.flag = false;
      $('#body' + id).slideUp();
      ul.children().show('slow');
      if (this.editData == true) {
        this.onGetListDiagrams();
        this.editData = false;
      }
    }
  }
  // add or import new diagram to mxGraph
  addOrImportNewDiagram() {
    // localStorage.setItem('addOrUpdateDiag', 'addDiag');
    // var diags = this.tabExistDiagCreatedByUser.join(',');
    // localStorage.setItem('listExistDiag', diags);
    // this.route.navigate(['object-models/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/mx-designer', 'null']);
  }
  openDiagInMxGraph(id, share) {
    let cryptComponent = this.cryptoService.set('mx-designer');
    cryptComponent = cryptComponent.replace(/\//g, '__________');
    let cryptComponentId = this.cryptoService.set(id);
    cryptComponentId = cryptComponentId.replace(/\//g, '__________');
    let cryptSHARED = this.cryptoService.set(share);
    cryptSHARED = cryptSHARED.replace(/\//g, '__________');
    const url = 'object-models/' + cryptSHARED + '/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/' + cryptComponentId + '/' + cryptComponent + '/mx-designer';
    // console.log(url);
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
    // this.route.navigate(['object-models/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/mx-designer', id, application]);
  }
  sharedDiagWith(id, event, pos) {
    if (pos == 'oui') {
      $('#menuShare' + id).css('left', '-11px');
    } else {
      $('#menuShare' + id).css('left', '14px');
    }
    if ($(event.target).hasClass('color-8-max')) {
      $('#menuShare' + id).css('top', '30px');
    } else {
      $('#menuShare' + id).css('top', '80px');
    }
    // $('#map_btn').css('display', 'none');
    // $('#classif_btn').css('display', 'none');
    // $('#security_btn').css('display', 'none');
    if (this.flagMenuShared) {
      $('#menuShare' + id).css('display', 'block');
      $('#menuContainer' + id).animate({
        opacity: 1,
        height: '100%'// '20vh'
      }, 500, 'easeOutBounce', function () {
// Animation complete.
      });
      $('#menuShare' + id).animate({
        opacity: 1
      }, 500, 'easeOutBounce', function () {
// Animation complete.
      });
      this.flagMenuShared = false;
    } else {
      $('#menuShare' + id).css('display', 'none');
      $('#menuContainer' + id).animate({
        opacity: 0,
        height: 0// '20vh'
      }, 500, 'easeOutBounce', function () {
// Animation complete.
      });
      $('#shared').animate({
        opacity: 0
      }, 500, 'easeOutBounce', function () {
// Animation complete.
      });
      this.flagMenuShared = true;
    }

    // $('.menu-item1').removeClass('mn-active');
    // $('.menu-item').removeClass('mn-active');
  }
  setSharedTypeToDiagram(selectedMenu, id, oldSelectedMenu) {
    this.sharedType = selectedMenu;
    switch (this.sharedType) {
      case 'private':
        $('#sharedDiagUP' + id).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>');
        this.updateInShared = true;
        this.sharedUsers = [];
        this.sharedUsersByNameEmail = [];
        break;
      case 'public':
        $('#sharedDiagUP' + id).html('<i style="color: blue" class="fas fa-users fa-2x"></i>');
        this.updateInShared = true;
        this.sharedUsers = [];
        this.sharedUsersByNameEmail = [];
        break;
      case 'users':
        $('#sharedDiagUP' + id).html('<i style="color: green" class="fas fa-user-plus fa-2x"></i>');
        if ($('[aria-controls=collapseTwo_17]').attr('aria-expanded') == 'true') {
          $('[aria-controls=collapseTwo_17]').click();
        }
        this.getListUserForShared(id, oldSelectedMenu);
        break;
      case 'usersWithout':
        $('#sharedDiagUP' + id).html('<i style="color: red" class="fas fa-user-minus fa-2x"></i>');
        if ($('[aria-controls=collapseTwo_17]').attr('aria-expanded') == 'true') {
          $('[aria-controls=collapseTwo_17]').click();
        }
        this.getListUserForShared(id, oldSelectedMenu);
        break;
      default :
        $('#sharedDiagUP' + id).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>');
        this.updateInShared = false;
        this.sharedUsers = [];
        this.sharedUsersByNameEmail = [];
        this.sharedType = '';
        alert('Choose other type of share');
        break;
    }
    // if (this.sharedType == 'public') {
    //
    // } else if (this.sharedType == 'users') {
    //
    // } else if (this.sharedType == 'usersWithout') {
    //
    // } else {
    //
    // }
    $('#menuShare' + id).css('display', 'none');
    $('#menuContainer' + id).animate({
      opacity: 0,
      height: 0// '20vh'
    }, 500, 'easeOutBounce', function () {
// Animation complete.
    });
    $('#menuShare' + id).animate({
      opacity: 0
    }, 500, 'easeOutBounce', function () {
// Animation complete.
    });
    this.flagMenuShared = true;
  }
  // show list users to shared diagram
  getListUserForShared(idSelectedDiag, oldSelectedMenu) {
    this.listAdUsersCheck = [];
    this.listAdUserspreCheck = [];
    const that = this;
    $('#ListUsersDesignerRole').empty();
    if ($('#usersDesignerRole').length > 0) {
      $('#usersDesignerRole').remove();
    }
    $('#ListUsersDesignerRole').append('<table id="usersDesignerRole" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
      '<tr>' +
      '<th>Username</th>' +
      '<th>Email</th>' +
      '<th>' +
      ' <label style="margin-bottom: 0; margin-right: -16px;">' +
      '  <input type="checkbox" id="check_user_all" value="all"/>' +
      '  <span></span>' +
      ' </label>' +
      '</th>' +
      '</tr>' +
      '</thead></table>');

    $('#usersDesignerRole').DataTable({
      processing: true,
      destroy: true,
      pageLength: 5,
      rowCallback(row, data) {
      },
      ajax: {
        url: Shared.API_URL + '/api/designer/getUserHaventRoleDesign/' + idSelectedDiag + '/' + this.sharedType + '/' + localStorage.getItem('userId'),
        contentType: 'application/json',
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      columns: [
        { data: 'username', title: this.lang ? 'Username' : 'Nom d\'utilisateur' },
        {data: 'email', title: 'Email'},
        {
          data:   'id',
          render: function ( data, type, row ) {
            return '<label style=\'margin-bottom: 0; margin-right: -16px;\'>' +
              '                    <input type=\'checkbox\' class="editor-active" id=\'' + data + '\'/>' +
              '                    <span></span>' +
              '                  </label>';
          }
        }
      ],
      order: [[2, 'asc']],
      initComplete(data, settings, json) {
        let checkAll = true;
        $('#shared_user_modal_submit').prop( 'disabled', true );
        if ((oldSelectedMenu == that.sharedType) || ((oldSelectedMenu == 'public') && (that.sharedType == 'users'))) {
          data.jqXHR.responseJSON.data.map(item => {
            that.listAdUserspreCheck.push(item.id);
            if (item.checkedUser) {
              that.listAdUsersCheck.push(item.id);
              $('#' + item.id).prop('checked', true);
            } else {
              checkAll = false;
            }
          });
        } else {
          data.jqXHR.responseJSON.data.map(item => {
            that.listAdUserspreCheck.push(item.id);
            checkAll = false;
          });
          that.listAdUsersCheck = [];
        }
        if (checkAll) {
          $('#check_user_all').prop('checked', true);
        } else {
          $('#check_user_all').prop('checked', false);
        }
        if (that.listAdUserspreCheck.length == 0) {
          $('#noListUsersDesignerRole').css('display', 'block');
          if ($('#usersDesignerRole').length > 0) {
            $('#usersDesignerRole').remove();
          }
          that.existUserRoleDesignerToShared = false;
        } else {
          $('#noListUsersDesignerRole').css('display', 'none');
          that.existUserRoleDesignerToShared = true;
          const table = this.api();
          // Handle click on "Select all" control
          $('#check_user_all').on('click', function(){
            that.listAdUsersCheck = [];
            if ($('#check_user_all').prop('checked')) {
              that.chechAllUsers = true;
              that.listAdUsersCheck = that.listAdUserspreCheck;
            } else {
              that.chechAllUsers = false;
            }
            // Get all rows with search applied
            var rows = table.rows({ search: 'applied' }).nodes();
            // Check/uncheck checkboxes for all rows in the table
            $('input[type="checkbox"]', rows).prop('checked', this.checked);
            if (that.listAdUsersCheck.length > 0) {
              $('#shared_user_modal_submit').prop( 'disabled', false );
            } else {
              $('#shared_user_modal_submit').prop( 'disabled', true );
            }
          });
          // Handle click on checkbox to set state of "Select all" control
          $('#usersDesignerRole tbody').on('change', 'input[type="checkbox"]', function() {
            // If checkbox is not checked
            if (!this.checked) {
              that.chechAllUsers = false;
              var el = $('#check_user_all').get(0);
              // If "Select all" control is checked and has 'indeterminate' property
              if (el && el.checked && ('indeterminate' in el)) {
                // Set visual state of "Select all" control
                // as 'indeterminate'
                el.indeterminate = true;
              }
            }
          });
          $('#usersDesignerRole').on( 'change', 'input.editor-active', function (event) {
            that.chechAllUsers = false;
            if ($(event.target).prop('checked')) {
              that.listAdUsersCheck.push($(event.target).attr('id'));
              if (that.listAdUsersCheck.length > 0) {
                $('#shared_user_modal_submit').prop( 'disabled', false );
              } else {
                $('#shared_user_modal_submit').prop( 'disabled', true );
              }
            } else {
              that.listAdUsersCheck.splice(that.listAdUsersCheck.indexOf($(event.target).attr('id')), 1);
              if (that.listAdUsersCheck.length > 0) {
                $('#shared_user_modal_submit').prop( 'disabled', false );
              } else {
                $('#shared_user_modal_submit').prop( 'disabled', true );
              }
            }
          } );
        }
      }
    });
    $('#listUsersShared').modal('show');
  }
  // save selected users to share with or without in model
  saveSelectedUserToShare() {
    this.updateInShared = true;
    this.sharedUsers = this.listAdUsersCheck;
    this.sharedUsersByNameEmail = [];
    this.sharedUsers.map(item => {
      if (this.sharedAllUsersRoles.find(x => x.id == item)) {
        this.sharedUsersByNameEmail.push(this.sharedAllUsersRoles.find(x => x.id == item));
      }
    });
    // console.log(this.listAdUsersCheck);
    $('#listUsersShared').modal('hide');
  }
  // save selected users to share with or without in model
  cancelSelectedUserToShare() {
    $('#shared_user_modal_submit').prop( 'disabled', true );
    this.updateInShared = false;
    this.sharedType = this.listDiagram.find(x => x.id == this.selectedDiagramID).sharedtype;
    this.sharedUsers = this.listDiagram.find(x => x.id == this.selectedDiagramID).sharedusers;
    this.sharedUsersByNameEmail = [];
    this.sharedUsers.map(item => {
      if (this.sharedAllUsersRoles.find(x => x.id == item)) {
        this.sharedUsersByNameEmail.push(this.sharedAllUsersRoles.find(x => x.id == item));
      }
    });
    switch (this.sharedType) {
      case 'public': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: blue" class="fas fa-users fa-2x"></i>'); break;
      case 'users': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: green" class="fas fa-user-plus fa-2x"></i>'); break;
      case 'usersWithout': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: red" class="fas fa-user-minus fa-2x"></i>'); break;
      default : $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
    }
  }

  /****************************************************************************/
  // show / hide list shared Diagrams in menu
  showOrHideListSharedDiagram() {
    let that = this;
    if (this.hiderListSharedDiagram) {
      if (this.listSharedDiagram.length == 0) {
        this.onGetListSharedDiagrams();
      }
      $('#designer_list_shared_diagram').show();
      $('#arrow_list_shared_diagram').html('<i class="fas fa-angle-up"></i>');
      this.hiderListSharedDiagram = false;
      $('#designer_list_shared_diagram').animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        // $('#listbutton_export_import').attr('style', 'display: flex!important');
        $('#listshareddiagram_pagin_search').attr('style', 'display: flex!important');
        // that.onGetListRetention();
      });
    } else {
      this.hiderListSharedDiagram = true;
      $('#arrow_list_shared_diagram').html('<i class="fas fa-angle-down"></i>');
      $('#designer_list_shared_diagram').animate({
        height: '0'
      }, 1, 'swing', function() {
        $('#designer_list_shared_diagram').hide();
        // $('#listbutton_export_import').attr('style', 'display: none!important');
        $('#listshareddiagram_pagin_search').attr('style', 'display: none!important');
      });
    }
  }
  // list data map
  onGetListSharedDiagrams() {
    $('#spinner_list_shared_diagram').css('display', 'block');
    this.listSharedDiagram = [];
    let subscription6$ = this.designerService.getListSharedDiagramsDesigner().subscribe(
      data => {
        $('#spinner_list_shared_diagram').css('display', 'none');
        data.map(item => {
          this.listSharedDiagram.push(item);
          if (item.imagedata != 'null') {
            this.listImageInetr.push(atob(item.imagedata));
          } else {
            this.listImageInetr.push(item.imagedata);
          }

        });
        for ( let i = 0; i < this.listSharedDiagram.length; i++) {
          this.listSharedDiagram[i].imagedata = this.listImageInetr[i];
        }
        // console.log(this.listDiagram);
        // $('#bt').css('display', 'block');
        $('#listshareddiagram_pagin_search').attr('style', 'display: flex!important');
        $('#paginate_shared_diagram').removeAttr('hidden');
        this.listSharedDiagram.length == 0 ? this.alertSharedDiagNotEmty = false : this.alertSharedDiagNotEmty = true;
      },
      err => {
        $('#spinner_list_shared_diagram').css('display', 'none');
          removeLoader();
        console.error(err);
      },
      () => {
        $('#spinner_list_shared_diagram').css('display', 'none');
        this.sortedCollectionShared = this.orderPipe.transform(this.asyncDiagramShared, 'id');
        setTimeout(() => {
          if (this.modelDesignerBridge.system == 'shared') {
            this.dataFilterShared = this.modelDesignerBridge.diagId;
            // console.log(this.dataFilterShared);
            $('[data-name=\'' + this.modelDesignerBridge.diagId + '\']')[0].click();
          }
            removeLoader();
        }, 500);
      }
    );
    this.subscription.add(subscription6$);
  }
  // sort list diagram
  setOrderShared(value: string) {
    if (this.orderShared === value) {
      this.reverseShared = !this.reverseShared;
    }

    this.orderShared = value;
  }
  // comparator sort
  sortAlphaNumShared(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  pageListSharedDiagramChanged(event) {
    this.shareddiagramconfig.currentPage = event;
  }
  getPageShared() {
    this.shareddiagramconfig.itemsPerPage = this.selectedPageSizeShared;
    this.shareddiagramconfig.currentPage = 1;
    this.shareddiagramconfig.totalItems = this.listSharedDiagram.length;
  }
  // show detail shared diagram
  showDetailSharedDiagram(row, event) {
    var id = row.id;
    this.selectedSharedDiagramID = id;
    const ul = $('#shared_' + id).parent().parent();
    const li = $('#shared_' + id).parent();
    if (!this.flagShared) {
      if (row.description != null && row.description != 'undefined') {
        if (row.description.length > 0) {
          if (row.description != ' ') {
            $('#descriptionDisabledShared' + row.id).html(atob(row.description));
          }
        }
      }
      this.flagShared = true;
      $('#body_shared_' + id).slideDown();
      $('#listshareddiagram_pagin_search').attr('style', 'display: none!important');
      ul.children().hide();
      $('#titleSharedDiagram').show('slow');
      li.show('slow');
      this.flagMenuShared = true;
      this.disableEditShared = true;
      $('#descriptionDisabledShared' + row.id).css({opacity: 1, height: '80%'});
      $('#descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
      $('#cke_descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
    } else {
      this.dataFilterShared = null;
      this.disableEditShared = true;
      $('#descriptionDisabledShared' + row.id).css({opacity: 0, height: 0});
      $('#descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
      $('#cke_descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
      $('#listshareddiagram_pagin_search').attr('style', 'display: flex!important');
      $('#spinner_list_shared_diagram').css('display', 'none');
      this.flagShared = false;
      $('#body_shared_' + id).slideUp();
      ul.children().show('slow');
      if (this.editDataShared == true) {
        this.onGetListSharedDiagrams();
        this.editDataShared = false;
      }
    }
  }
  // CKEditor update Shared
  async startScriptUPShared(row) {
    this.descriptionDiagUPShared = '';
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [ ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', 'Superscript', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'], '-', 'NumberedList'] ];
      this.descriptionDiagUPShared = row.description;
      this.loadDataUPShared(row);
    }).catch(error => console.error(error));
  }

  public loadDataUPShared(row) {
    const that = this;
    if(typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['descriptionDiagUPShared' + row.id]) {
      CKEDITOR.instances['descriptionDiagUPShared' + row.id].destroy(true);
    }
    var editor = CKEDITOR.replace('descriptionDiagUPShared' + row.id, { toolbar: 'MA' });
    editor.config.height = 110;
    if (row.description != null && row.description != 'undefined') {
      if (row.description.length > 0) {
        if (row.description != ' ') {
          editor.setData(atob(row.description));
        }
      }
    }
    editor.on( 'change', function( evt ) {
      that.descriptionDiagUPShared = btoa(evt.editor.getData());
      // console.log(that.descriptionDiagUPShared);
    });
  }
  // reset all modification in diagram
  cancelUpdateShared(row, i) {
    // this.updateInShared = false;
    // this.sharedType = this.listDiagram.find(x => x.id == this.selectedDiagramID).sharedtype;
    // this.sharedUsers = this.listDiagram.find(x => x.id == this.selectedDiagramID).sharedusers;
    // this.sharedUsersByNameEmail = [];
    // this.sharedUsers.map(item => {
    //   this.sharedUsersByNameEmail.push(this.sharedAllUsersRoles.find(x => x.id == item));
    // });
    // switch (this.sharedType) {
    //   case 'private' : $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
    //   case 'public': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: blue" class="fas fa-users fa-2x"></i>'); break;
    //   case 'users': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: green" class="fas fa-user-plus fa-2x"></i>'); break;
    //   case 'usersWithout': $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: red" class="fas fa-user-minus fa-2x"></i>'); break;
    //   default : $('#sharedDiagUP' + this.selectedDiagramID).html('<i style="color: black" class="fas fa-user-tie fa-2x"></i>'); break;
    // }
//     $('#menuShare').css('display', 'none');
//     $('#menuContainer' + row.id).animate({
//       opacity: 0,
//       height: 0// '20vh'
//     }, 500, 'easeOutBounce', function () {
// // Animation complete.
//     });
//     $('#menuShare').animate({
//       opacity: 0
//     }, 500, 'easeOutBounce', function () {
// // Animation complete.
//     });
//     this.flagMenuShared = true;

    this.disableEditShared = true;
    $('#nameUpdateShared' + row.id).val(this.listSharedDiagram[i].name);
    if (row.description != null && row.description != 'undefined') {
      if (row.description.length > 0) {
        if (row.description != ' ') {
          $('#descriptionDisabledShared' + row.id).html(atob(this.listSharedDiagram[i].description));
        } else {
          $('#descriptionDisabledShared' + row.id).html('');
        }
      } else {
        $('#descriptionDisabledShared' + row.id).html('');
      }
    } else {
      $('#descriptionDisabledShared' + row.id).html('');
    }
    $('#descriptionDisabledShared' + row.id).css({opacity: 1, height: '80%'});
    $('#descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
    $('#cke_descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
  }
  onUpdateInfSharedDiag(row, i) {
    if (this.disableEditShared) {
      this.disableEditShared = false;
      this.startScriptUPShared(row);
      $('#descriptionDisabledShared' + row.id).css({opacity: 0, height: 0});
      $('#descriptionDiagUPShared' + row.id).css({opacity: 1, height: '80%'});
      $('#cke_descriptionDiagUPShared' + row.id).css({opacity: 1, height: '80%'});
    } else {
      // $('#menuShare').css('display', 'none');
      // $('#menuContainer' + row.id).animate({
      //   opacity: 0,
      //   height: 0
      // }, 500, 'easeOutBounce', function () {});
      // $('#menuShare').animate({
      //   opacity: 0
      // }, 500, 'easeOutBounce', function () {});
      // this.flagMenuShared = true;

      if ($('#nameUpdateShared' + row.id).val() == '') {
        $('#nameUpdateShared' + row.id).addClass('is-invalid');
        return false;
      } else {
        $('#nameUpdateShared' + row.id).removeClass('is-invalid');
        this.nameUpdate = $('#nameUpdateShared' + row.id).val();
      }
      Swal.fire({
        title: this.lang ? 'Are you sure?' : 'Êtes-vous sûr(e) ?',
        text: this.lang ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang ? 'Yes, Update it!' : 'Oui, Mettez à jour !',
        cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
      }).then((result) => {
        if (result.value) {
          // addLoaderUpdateRp('vide', 'f', true);
          let loadingMessage = this.lang ? 'U P D A T E  D I A G R A M' : 'MISE À JOUR  DU  DIAGRAMME';
          addLoader(loadingMessage);         
          let dataDiagram = {
            username: getCookieValue('username'),
            idDiag: row.id,
            descriptionDiag: this.descriptionDiagUPShared,
            nameDiag: this.nameUpdate,
            sharedType: this.listSharedDiagram[i].sharedtype,
            sharedusers: this.listSharedDiagram[i].sharedusers
          };
          let subscription7$ = this.designerService.onUpdateInfDiag(dataDiagram).subscribe(
            data => {
              if (data != 'error in request') {
                this.editDataShared = true;
                this.listSharedDiagram[i].name = data['name'];
                this.listSharedDiagram[i].updatedAt = data['updatedAt'];
                this.listSharedDiagram[i].updatedBy = data['updatedBy'];
                if (data['description'] != null && data['description'] != 'undefined') {
                  if (data['description'].length > 0) {
                    if (data['description'] != ' ') {
                      $('#descriptionDisabledShared' + row.id).html(atob(data['description']));
                      this.listSharedDiagram[i].description = data['description'];
                    } else {
                      this.listSharedDiagram[i].description = null;
                    }
                  } else {
                    this.listSharedDiagram[i].description = null;
                  }
                } else {
                  this.listSharedDiagram[i].description = null;
                }
              } else {
                alert(data);
              }
            },
            err => {
              this.disableEditShared = true;
              $('#descriptionDisabledShared' + row.id).css({opacity: 1, height: '80%'});
              $('#descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
              $('#cke_descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
                removeLoader();
              console.error(err);
            },
            () => {
              this.disableEditShared = true;
              $('#descriptionDisabledShared' + row.id).css({opacity: 1, height: '80%'});
              $('#descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
              $('#cke_descriptionDiagUPShared' + row.id).css({opacity: 0, height: 0});
                removeLoader();
            }
          );
          this.subscription.add(subscription7$);
        }
      });
    }
  }
  onSetRemoveSharedDiagram(id) {
    var that = this;
    Swal.fire({
      title: this.lang ? 'Are you sure?' : 'Êtes-vous sûr(e) ?',
      text: this.lang ? "You won't be able to revert this!" : "Vous ne pourrez pas revenir en arrière !",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.lang ? 'Yes, delete it!' : 'Oui, Supprimez-le !',
      cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
    }).then((result) => {
      if (result.value) {
        // addLoaderDeleteRp('vide', 'f', true);
        addLoader(this.lang === 'en' ? 'REMOVE DIAGRAM' : 'SUPPRIMER DIAGRAMME');
        let subscription8$ = that.designerService.onSetRemoveDiagram(id, 'check').subscribe(
          data => {
            if( data.success == 'this modelDesigner is associated with some projects'){
              Swal.fire({
                title: this.lang ? 'ModelDesigner is associated with some projects?' : 'Le modèle de concepteur est associé à certains projets ?',
                text: this.lang ? 'This modelDesigner is associated with some projects!\nWould you like to delete it even from these projects?' : 'Ce modèle de concepteur est associé à certains projets !\nVoulez-vous le supprimer même de ces projets ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.lang ? 'Yes, proceed!' : 'Oui, procéder !',
                cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
              }).then((result) => {
                if(result.value){
                  this.designerService.onSetRemoveDiagram(id, 'forceDelete').subscribe(
                    d =>{
                      Swal.fire({
                        title: this.lang ? 'Success!' : 'Succès !',
                        text: this.lang ? 'deleted with success' : 'supprimé avec succès',
                        icon: 'success',
                        confirmButtonText: this.lang ? 'Ok' : 'D\'accord'
                      });                      
                      this.refreshListDiagShared = true;
                    }
                  );
                }
              });
            } else if(data.success == 'OK'){
              Swal.fire({
                title: this.lang ? 'Success!' : 'Succès !',
                text: this.lang ? 'deleted with success' : 'supprimé avec succès',
                icon: 'success',
                confirmButtonText: this.lang ? 'Ok' : 'D\'accord'
              });
              this.refreshListDiagShared = true;
            }
          },
          err => {
            console.error(err);
          },
          () => {
            this.refreshListSharedDiagram();
              removeLoader();
          }
        );
        that.subscription.add(subscription8$);
      }
    });
  }
  // refreshListeDataMap after closing modal
  refreshListSharedDiagram() {
    if (this.refreshListDiagShared) {
      this.onGetListSharedDiagrams();
      this.editDataShared = false;
    }
  }
  // get list applications to add new diagram designer
  // onGetListApplicationAndMs(idDiag) {
  //   this.designerService.getListApplicationAndMs(idDiag).subscribe(
  //     data => {
  //       this.listApplications = [... data.listApps];
  //       this.listMs = [... data.listMs];
  //       this.selectedApplications = [... data.selectedApps];
  //       this.selectedMs = [... data.selectedMs];
  //     },
  //     err => {
  //       console.error(err);
  //     }
  //   );
  // }
  // get list applications to add new diagram designer
  onGetListApplications() {
    let subscription9$ = this.designerService.getListApplications().subscribe(
      data => {
        this.listApplications = [... data.map(item => item)];
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription9$);
  }
  // get list applications to add new diagram designer
  onGetListMsBySelectedApps(e) {
    this.selectedApplications = [];
    this.selectedApplicationName = [];
    e.forEach(el => {
      this.selectedApplications.push(el.id);
        this.selectedApplicationName.push(el.name);
    });
    if (this.selectedApplications.length > 0) {
      let subscription10$ = this.designerService.getListMsBySelectedApps(this.selectedApplicationName).subscribe(
        data => {
          // const formValue = this.addFormNewDiag.value;
          this.selectedMs = [];
          this.listMs = [... data.map(item => item)];
          // this.selectedMsTemp =  formValue['selectedMs'];
          // console.log(this.selectedMsTemp);
          // if (this.selectedMsTemp != null) {
          //   this.selectedMsTemp.map(item => {
          //     if (this.listMs.includes(item)) {
          //       this.selectedMs.push(item);
          //     }
          //   });
          //   this.addFormNewDiag.get('selectedMs').patchValue(this.selectedMs);
          // }
        },
        err => {
          console.error(err);
        }
      );
      this.subscription.add(subscription10$);
    }
  }
  // onApplicationChange(e) {
  //   this.selectedApplications = [];
  //   e.forEach(el => {
  //     this.selectedApplications.push(el);
  //   });
  //   console.log(this.selectedApplications);
  // }
  onSetAddNewDiag(version) {
    if (this.listApplications.length == 0) {
      this.onGetListApplications();
    }
    this.descriptionNewDiag = '';
    this.startScriptNewDiag();
    // $('#new_diag_submit').html('save');
    $('#modalAddNewDesigner').modal('show');
  }
  // CKEditor add
  async startScriptNewDiag() {
    // this.descriptionNewDiag = 'null';
    // this.idSelectedVersion = 'null';
    // $('#titleFormModal').html('Add new version');
    this.frmAddNewDesigner = this.initFormNewDiag();
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [ ['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'], '-', 'NumberedList'] ];
      this.loadDataNewDiag();
    }).catch(error => console.error(error));
  }

  private loadDataNewDiag() {
    const that = this;
    if(typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['textNewDescDiagram']) {
      CKEDITOR.instances['textNewDescDiagram'].destroy(true);
    }
    let editor = CKEDITOR.replace('textNewDescDiagram', { toolbar: 'MA' });
    editor.config.height = 80;
    editor.on( 'change', function( evt ) {
      $('#labelNewDescDiagram').css({color: '#26a69a', 'font-size': '0.8rem'});
      that.descriptionNewDiag = btoa(evt.editor.getData());
    });
  }
  initFormNewDiag(): FormGroup {
    const that  = this;
    return this.addFormNewDiag = this.fbNewDiag.group(
      {
        selectedApps: [
          null,
          Validators.compose([Validators.required]),
        ],
        selectedMs: [
          null,
        ],
        newNameDiag: [
          null,
          Validators.compose([Validators.required]),
        ],
        active: [
          null
        ]
      }
    );
  }
  resetIfNotValidName() {
    this.validateName = true;
  }
  changeNameToValidate() {
    const formValue = this.addFormNewDiag.value;
    this.nameNewDiag =  formValue['newNameDiag'];
    // console.log(this.nameNewDiag);
    this.tabExistDiagCreatedByUser.map(item => {
      if (this.nameNewDiag == item) {
        this.nameNewDiag = null;
        this.addFormNewDiag.get('newNameDiag').patchValue(null);
        this.frmAddNewDesigner = this.initFormNewDiag();
        // console.log(this.nameNewDiag);
        this.validateName = false;
      }
    });
  }
  submitAddNewDiag() {
    // addLoaderWaitRp('vide', 'f', true);
    // console.log('ok');
    let loadingMessage = this.lang ? 'P L E A S E &#160; W A I T' : 'S\'il vous plaît, patientez';
    addLoader(loadingMessage);    
    const formValue = this.addFormNewDiag.value;
    this.nameNewDiag =  formValue['newNameDiag'];
    this.selectedApplications =  formValue['selectedApps'];
    this.selectedMs =  formValue['selectedMs'];
    // console.log(this.selectedApplications);
    // return;
    if (this.validateName) {
      // $('#new_diag_submit')
      //   .html('<div class="spinner-grow spinner-grow-sm" style="color: #fbfbff" role="status">\n' +
      //     '  <span class="sr-only">Loading...</span>\n' +
      //     '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //     '  <span class="sr-only">Loading...</span>\n' +
      //     '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //     '  <span class="sr-only">Loading...</span>\n' +
      //     '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //     '</div>');

      if (this.descriptionNewDiag == undefined) {
        this.descriptionNewDiag = 'null';
      }
      var dataNewDiag = {
        username: getCookieValue('username'),
        name: this.nameNewDiag,
        applications: this.selectedApplications,
        modelstructures: this.selectedMs,
        description: this.descriptionNewDiag
      };
      // console.log(dataNewDiag);
      // return;
      let subscription11$ = this.designerService.setNewDesignerEmpty(dataNewDiag).subscribe(
        data => {
          this.onAddTempPkFieldsForDiagFirstCreate(data);
        },
        err => {
          console.error(err);
          // $('#new_diag_submit').html('Save');
        },
        () => {
          // $('#new_diag_submit').html('Save');
          $('#new_diag_cancel').click();
        }
      );
      this.subscription.add(subscription11$);
    }
  }
  cancelModalNewDiag() {
    this.frmAddNewDesigner = this.initFormNewDiag();
  }
  // save list Fields Pk for new Diagrams
  onAddTempPkFieldsForDiagFirstCreate(idDiag) {
    let subscription12$ = this.designerService.addTempPkFieldsForDiagFirstCreate(idDiag).subscribe(
      result => {
        // console.log(result);
      },
      err => {
        console.error(err);
        this.onAddTempNotPkFieldsForDiagFirstCreate(idDiag);
      },
      () => {
        this.onAddTempNotPkFieldsForDiagFirstCreate(idDiag);
      }
    );
    this.subscription.add(subscription12$);
  }
  // save list Fields Not Pk for new Diagrams
  onAddTempNotPkFieldsForDiagFirstCreate(idDiag) {
    let subscription13$ = this.designerService.addTempNotPkFieldsForDiagFirstCreate(idDiag).subscribe(
      result => {
        // console.log(result);
      },
      err => {
          removeLoader();
        console.error(err);
        this.openDiagInMxGraph(idDiag, 'notShared');
      },
      () => {
          removeLoader();
        this.openDiagInMxGraph(idDiag, 'notShared');
      }
    );
    this.subscription.add(subscription13$);
  }
  goToProject() {
    if (this.modelDesignerBridge.diagId != '' && this.modelDesignerBridge.component != '') {
      let cryptProject = this.cryptoService.set(this.modelDesignerBridge.diagId);
      cryptProject = cryptProject.replace(/\//g, '__________');
      let cryptComponent = this.cryptoService.set('modelDesigner');
      cryptComponent = cryptComponent.replace(/\//g, '__________');
      let cryptComponentId = this.cryptoService.set(this.id);
      cryptComponentId = cryptComponentId.replace(/\//g, '__________');
      const url = 'data-governance/project/' + cryptProject + '/' + cryptComponentId + '/' + cryptComponent;
      this.router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(() => {
        this.router.navigate([url]);
      });
    }
  }
}
