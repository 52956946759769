import { Component, OnInit, Input , SimpleChanges,OnDestroy } from '@angular/core';
import { DdtmService } from '../../services/ddtm/ddtm.service';
import {Subscription} from 'rxjs';
import * as D3 from 'd3';
import * as d3Cloud from "d3-cloud";
import { TranslateService } from '@ngx-translate/core';

declare let d3: any;
declare const $: any;

@Component({
  selector: 'app-word-cloud',
  templateUrl: './word-cloud.component.html',
  styleUrls: ['./word-cloud.component.css']
})
export class WordCloudComponent implements OnInit , OnDestroy {

  @Input() isWordLaunched:boolean;
  @Input() objectId:boolean;
  @Input() wordsSize:boolean;
  subscription: Subscription = new Subscription();
  words = [];
  tempData=[];
  countEmpty=0;
  private svg;               // SVG in which we will print our chart
  private margin: {          // Space between the svg borders and the actual chart graphic
    top: number,
    right: number,
    bottom: number,
    left: number
  };
  private width: number;      // Component width
  private height: number;     // Component height
  private fillScale;          // D3 scale for text color
  lang: string;

  constructor(private ddtmService: DdtmService,private translate: TranslateService) {}
  _opened = false;
  showWord = true;
  isChecked = true;

  ngOnInit() {
    $('#legendWordWrapper').attr('style', 'display: block!important');
    // switch between with fonctional/technical words
    $('#wordSwitch').prop('checked', true);
    $('#spinner_report_word_svg').hide();
    $('#word-cloud').show();
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
  const that = this;
  setInterval(() => {
    this.lang = localStorage.getItem('lang') || 'en';
    this.translate.use(this.lang);
  }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isWordLaunched == true){
      const cls = this;
      $('#wordsByReport').DataTable().clear().destroy();
      $('#spinner_report_word').show();
      $('#reportWordChart').hide();
      var parametres = {
        report: this.objectId,
        idUser: localStorage.getItem('userId'),
        search: true,
        field: ['null'],
        record: ['null'],
        checked: this.isChecked,
        size: this.wordsSize,
        uncheckedWords: null
     }
     let subscription1$ = this.ddtmService.getReportWordCloud(parametres).subscribe(
        data => {
          this.countEmpty=data.countEmpty;
          this.words = data.words;
          this.OnGetReportWords(data.words);
        },
        err => {
            console.error(err);
        },
        ()=>{
          $('#spinner_report_word').hide();
          $('#reportWordChart').show();
          this.setup();
          this.buildSVG();
          this.populate(this.words);
         
        }
    );
    this.subscription.add(subscription1$);
    }
  }
  _toggleSidebar() {
    this._opened = !this._opened;
    if (this.showWord) {
        $('#showWord').html('<i class="fas fa-arrow-circle-right fa-2x"></i>');
        this.showWord = false;
    } else {
        $('#showWord').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
        this.showWord = true;
    }
  }
  onSwitchWordChange(e){
    this.isChecked = e.currentTarget.checked;
    const cls = this;
    $('#wordsByReport').DataTable().clear().destroy();
    $('#spinner_report_word').show();
    $('#reportWordChart').hide();
    var parametres = {
      report: this.objectId,
      idUser: localStorage.getItem('userId'),
      search: true,
      field: ['null'],
      record: ['null'],
      checked: this.isChecked,
      size: this.wordsSize,
      uncheckedWords: null
   }
   let subscription2$ = this.ddtmService.getReportWordCloud(parametres).subscribe(
      data => {
        this.countEmpty=data.countEmpty;
        this.words = data.words;
        this.OnGetReportWords(data.words)
      },
      err => {
          console.error(err);
      },
      ()=>{
        $('#spinner_report_word').hide();
        $('#reportWordChart').show();
        this.setup();
        this.buildSVG();
        this.populate(this.words);
      }
  );
  this.subscription.add(subscription2$);
  }
  private setup() {
    this.margin = {
      top   : 20,
      right : 20,
      bottom: 20,
      left  : 20
    };
    var width = document.getElementById('word-cloud-div').offsetWidth;
    this.width = width - this.margin.left - this.margin.right;
    this.height = 932 - this.margin.top - this.margin.bottom;

    this.fillScale = D3.scaleOrdinal(D3.schemeCategory10);
  }

  private buildSVG() {
    $('#word-cloud').empty();
    this.svg = D3.select('#word-cloud')
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .style("display", "block")
      .style("margin", "0")
      .append('g')
      .attr('transform', 'translate(' + (this.width / 2) + ',' + (this.height / 2) + ')');
  }

  private populate(words) {
    const wordScale = D3.scaleLinear()
    .domain([0,D3.max(words, function(d) {
      return d.size;
    })])
    .range([10,72]);
    d3Cloud()
      .size([this.width, this.height])
      .words(words)
      .padding(2)
      .rotate(function() { return ~~(Math.random() * 2) * 0; })
      .font("Impact")
      .fontWeight('normal')
      .fontSize(function(d) { return wordScale (d.size); })
      .spiral("archimedean")
      .on('end', () => {
            this.draw(words);
          })
      .start();
  }

  private draw(words) {
    this.svg
      .selectAll("text")
        .data(words)
      .enter().append("text")
        .style("font-size", function(d) { return d.size + "px"; })
        .style('fill', (d, i) => {
          return this.fillScale(i);
        })
        .style("font-family", "Impact")
        .attr("text-anchor", "middle")
        .attr("transform", function(d) {
          return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
        })
        .text(function(d) { return d.text; });
  }

  // list words for datatable sidebar 
  OnGetReportWords(dataSet) {
    const that = this;
    if ($('#wordsByReport').length > 0) {
      $('#wordsByReport').remove();
    }
    $('#wordsByReport').DataTable().clear().destroy();
    let table = $('#wordsByReport').DataTable();
    $('#word_report').append('<table id="wordsByReport" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;width: 100%;">   <thead>\n' +
      '<tr>' +
      '<th>text</th>'+
      '<th>size</th>'+
      '<th></th>' +
      '</tr>' +
      '</thead></table>');
    $('#wordsByReport').DataTable({
        data: dataSet,
        processing: true,
        responsive: true,
        lengthChange:true,
        destroy: true,
        "pagingType": "numbers",
        columns: [
          { "data": "text", "title": this.lang ? 'Name' : 'Nom' },
          { "data": "size", "title": this.lang ? 'Frequency' : 'Fréquence', "type": "num",'render': $.fn.dataTable.render.number( ' ', '.', 0)},
              {
                "targets": 3,
                "data": null,
                "searchable": false, 
                "orderable": false, 
                "visible": true,
                'className': 'check',
                "width": "10px",
                'render': function (data, type, full, meta){
                  return '<input type="checkbox" name="'+ data.text +'"  id="check_'+ data.text +'" style="opacity: 1;pointer-events: all;" checked/>  ';
                }
              }
        ],
        order: [[1, 'desc']]
    });
    // words cloud update
    $('#wordsByReport').on('change', 'input[type="checkbox"]', function(){
      $('#spinner_report_word_svg').show();
      $('#word-cloud').hide();
      let uncheckedWords= [];
      let uncheckedvalues = $('#wordsByReport').DataTable().$('input:unchecked').each(function () {
        uncheckedWords.push($(this).attr('name'))
      });
      var parametres = {
        report: that.objectId,
        idUser: localStorage.getItem('userId'),
        search: true,
        field: ['null'],
        record: ['null'],
        checked: that.isChecked,
        size: that.wordsSize,
        uncheckedWords: uncheckedWords
      }
      let subscription3$ = that.ddtmService.getReportWordCloud(parametres).subscribe(
        data => {
          this.countEmpty=data.countEmpty;
          that.setup();
          that.buildSVG();
          that.populate(data.words);
        },
        err => {
            console.error(err);
          },
          ()=>{
            $('#spinner_report_word_svg').hide();
            $('#word-cloud').show();
           
          }
      );
      that.subscription.add(subscription3$);
    });
  }
}
