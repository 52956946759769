import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import {Shared} from '../../shared';

declare const $: any;

export  const campainPacko = (data) =>{

  const Assets = Shared.ASSETS;
  const width = 932;
  const height = 932;

  const color = d3.scaleLinear()
    .domain([0, 5])
    .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
    .interpolate(d3.interpolateHcl);

  const pack = data => d3.pack()
    .size([width, height])
    .padding(3)
    (d3.hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value));

  const root = pack(data);
//  console.log(root);
  let focus = root;
  let view;
  $('#globCampainChart').empty();
  const svg = d3.select("#globCampainChart");
  svg.attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
    .style("display", "block")
    .style("margin", "0 -14px")
    .style("background", '#ffffff')
    .style("cursor", "pointer")
    .on("click", () => zoom(root))
  ;
  if($('.d3-tip-Campain').length > 0){
    $('.d3-tip-Campain').remove();
  }
  const tool_tip = d3Tip()
    .direction('n')
    .attr("class", "d3-tip-Campain")

    .html((d) => {
      return '<div id="tipoCampain"><svg id="chartCampainsvg" width="460" height="320"></svg></div>';
    });
  svg.call(tool_tip);
  function compareValues(key, order = 'asc') {
    return function innerSort(a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }

      const varA = (typeof a[key] === 'string')
        ? a[key].toUpperCase() : a[key];
      const varB = (typeof b[key] === 'string')
        ? b[key].toUpperCase() : b[key];

      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return (
        (order === 'desc') ? (comparison * -1) : comparison
      );
    };
  }
  const cont = svg.append("g");
  const node = cont
    .selectAll("circle")
    .data(root.descendants().slice(1))
    .join("circle")
    .attr('id', d => 'camapainglob_' + d.data.id)//d.data.name.split(' :')[0])
    .attr("fill", d => d.children ? color(d.depth) : "white")
    // .attr("pointer-events", d => !d.children ? "none" : null)
    .on("mouseover", function(d) {
      d3.select(this).attr("stroke", "#000");
      tool_tip.show(d, this);
      let datachart = [];
      let sum = 0;
      let tabTopTen = [];
      if(d.data.children){
        d.data.children.map( d => sum += d.value);
        d.data.children.map( d => {
          datachart.push({ 'name': d.name,  'value' : d.value / sum});
        });
      }else{
        d.parent.data.children.map( d => sum += d.value);
        d.parent.data.children.map( d => {
          datachart.push({ 'name': d.name,  'value' : d.value / sum});
        });
      }

      datachart.sort(compareValues('value', 'desc'));

      datachart.map( d => {
        if (tabTopTen.length < 3) {
          tabTopTen.push({ 'name': d.name,  'value' : d.value});
        }
      });
      tipChart(tabTopTen);
    })
    .on("mouseout", function(d) { d3.select(this).attr("stroke", null); tool_tip.hide(); })
    .on("mousemove", function(d) {
      if(d.parent) {
        // let pos = d3.select(this).node().getBoundingClientRect();
        d3.select('.d3-tip-Campain')
          .style("left", (d3.event.pageX - 34) + "px")
          .style("top", (d3.event.pageY - 500) + "px");
      }
    })
    .on("click", d => focus !== d && (zoom(d), d3.event.stopPropagation()));

  // add shadow filter
  svg.append('svg:defs').selectAll('filter')
    .data(['shadowGlobal'])
    .enter().append('svg:filter')
    .attr('id', String)
    .attr('y', -10)
    .attr('x', -10)
    .attr('height', 40)
    .attr('width', 20)
    .append('feOffset')
    .attr('in', 'SourceAlpha')
    .attr('dx', 3)
    .attr('dy', -10)
    .attr('result', 'offset2')
    .attr('stdDeviation', 4);
  svg.selectAll('filter')
    .append('feGaussianBlur')
    .attr('in', 'offset2')
    .attr('result', 'blur2')
    .attr('stdDeviation', 10);
  svg.selectAll('filter')
    .append('feMerge')
    .append('feMergeNode')
    .attr('in', 'blur2');
  svg.selectAll('filter')
    .select('feMerge')
    .append('feMergeNode')
    .attr('in', 'SourceGraphic');
  const label = svg.append("g")
    .style("font", "10px sans-serif")
    .attr("pointer-events", "none")
    .attr("text-anchor", "middle")
    .selectAll("text")
    .attr('class', 'labelZoom')
    .data(root.descendants())
    .join("text")
    .style("fill-opacity", d => d.parent === root ? 1 : 0)
    // .attr('id', d => d.data.name)
    .style("display", d => d.parent === root ? "inline" : "none")
    .text(d => d.data.name);

  const mark =svg.append("g")
    .selectAll('.imago_campain')
    .data(root.descendants().slice(1))
    .enter()
    .append('svg:image')
    .style('display',  'none')
    .attr('filter', 'url(#shadowGlobal)')
    .attr('outline', 'none')
    .attr('id', d => 'campain_markos_' + d.data.id)
    .attr('class', 'imago_campain')
    .attr('width', '20px')
    .attr('height', '36px')
    .attr('xlink:href' , '../../../../' + Assets + '/images/hand.png');

  zoomTo([root.x, root.y, root.r * 2]);

  function zoomTo(v) {
    const k = width / v[2];

    view = v;

    label.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
    mark.attr("transform", d => `translate(${(d.x - v[0]) * k},${((d.y - v[1]) * k) - 15})`);
    node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
    node.attr("r", d => d.r * k);
  }
  function zoom(d) {
    const focus0 = focus;

    focus = d;

    const transition = svg.transition()
      .duration(d3.event.altKey ? 7500 : 750)
      .tween("zoom", d => {
        const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
        return t => zoomTo(i(t));
      });

    label
      .filter(function(d) { return d.parent === focus || this.style.display === "inline"; })
      .transition(transition)
      .style("fill-opacity", d => d.parent === focus ? 1 : 0)
      .on("start", function(d) { if (d.parent === focus) this.style.display = "inline"; })
      .on("end", function(d) { if (d.parent !== focus) this.style.display = "none"; });
  }
};
const tipChart = (dataTip) => {
  // console.log('test chart');
  const margin = {top: 40, right: 30, bottom: 30, left: 50},
    width = 460 - margin.left - margin.right,
    height = 320 - margin.top - margin.bottom;

  const greyColor = "#898989";
  const barColor = d3.interpolateInferno(0.4);
  const highlightColor = d3.interpolateInferno(0.3);

  const formatPercent = d3.format(".0%");
  if($('#chartCampainsvg').length > 0) {
    $('#chartCampainsvg').remove();
  }
  const svg = d3.select("#tipoCampain").append("svg")
    .attr('id', 'chartCampainsvg')
    .attr("width", width + margin.left + margin.right)
    .attr("height", height + margin.top + margin.bottom)
    .append("g")
    .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

  const x = d3.scaleBand()
    .range([0, width])
    .padding(0.3);
  const y = d3.scaleLinear()
    .range([height, 0]);

  const xAxis = d3.axisBottom(x).tickSize([]).tickPadding(10);
  const yAxis = d3.axisLeft(y).tickFormat(formatPercent);

  const dataset = dataTip;

  x.domain(dataset.map( d => { return d.name; }));
  y.domain([0, 1]);

  svg.append("g")
    .attr("class", "x axis")
    .attr("transform", "translate(0," + height + ")")
    .call(xAxis);
  svg.append("g")
    .attr("class","y axis")
    .call(yAxis);

  svg.selectAll(".bar")
    .data(dataset)
    .enter().append("rect")
    .attr("class", "bar")
    .style("display", d => { return d.value === null ? "none" : null; })
    .style("fill",  d => {
      return d.value === d3.max(dataset,  d => { return d.value; })
        ? highlightColor : barColor
    })
    .attr("x",  d => { return x(d.name); })
    .attr("width", x.bandwidth())
    .attr("y",  d => { return height; })
    .attr("height", 0)
    .transition()
    .duration(750)
    .delay(function (d, i) {
      return i * 150;
    })
    .attr("y",  d => { return y(d.value); })
    .attr("height",  d => { return height - y(d.value); });

  svg.selectAll(".label")
    .data(dataset)
    .enter()
    .append("text")
    .attr("class", "label")
    .style("display",  d => { return d.value === null ? "none" : null; })
    .attr("x", ( d => { return x(d.name) + (x.bandwidth() / 2) - 8 ; }))
    .style("fill",  d => {
      return d.value === d3.max(dataset,  d => { return d.value; })
        ? highlightColor : greyColor
    })
    .attr("y",  d => { return height; })
    .attr("height", 0)
    .transition()
    .duration(750)
    .delay((d, i) => { return i * 150; })
    .text( d => { return formatPercent(d.value); })
    .attr("y",  d => { return y(d.value) + .1; })
    .attr("dy", "-.7em");
};
