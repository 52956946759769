import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BusinessTermService} from '../../services/business-term/business-term.service';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import {CryptoService} from '../../crypto.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataLineageService} from '../../services/data-lineage/data-lineage.service';
import {NodeItem} from '../../data-lineage/NodeItem';
import Swal from 'sweetalert2';
import {BusinessObject} from '../data-lineage-term/BusinessObject';
import {BusinessItem} from '../data-lineage-term/BusinessItem';
import {formatDate} from '@angular/common';
import {ITreeOptions, KEYS, TREE_ACTIONS} from '@circlon/angular-tree-component';
import {ExtraItem} from '../../data-lineage/ExtraItem';
import {ColorEvent} from 'ngx-color';
import {renderTreeLine} from '../../data-lineage/TreeChartDataLine';
import {Synonym} from './Synonym';
import {addLoader, addProgressBar, conditionalValidator, removeLoader, setProgressBarValue} from '../../shared';
import {FunctionalMapService} from '../../services/functional-map/functional-map.service';
import {TranslateService} from '@ngx-translate/core';
import * as FileSaver from 'file-saver';
import {Subscription} from "rxjs";
import {AppassetService} from "../../services/data-transfert/appasset.service";


declare const $: any;
declare const LeaderLine: any;
declare const PlainDraggable: any;
declare const CKEDITOR: any;

@Component({
  selector: 'app-functional-map',
  templateUrl: './functional-map.component.html',
  styleUrls: ['./functional-map.component.css']
})
export class FunctionalMapComponent implements OnInit, OnDestroy, AfterViewInit {
  nodes = [
    { // node a
      data: {id: 'nodeFirst', type: 'start', label: ''}
    },
    { // node b
      data: {id: 'nodeLast', type: 'end', label: ''}
    }
  ];
  i = 0;
  types = [{id: 'stock', name: 'stock'}, {id: 'acceder', name: 'access'}, {
    id: 'archivage',
    name: 'archiving'
  }, {id: 'collecter', name: 'collect'}
    , {id: 'control', name: 'control'}, {id: 'destruction', name: 'destruction'}
    , {id: 'traitement', name: 'treatment'}, {id: 'transfert', name: 'transfer'}, {
      id: 'visa',
      name: 'visa'
    }, {id: 'validate', name: 'validate'}
    , {id: 'start', name: 'start'}, {id: 'end', name: 'end'}
  ];
  addNodeFrm: FormGroup;
  saveFrm: FormGroup;
  selectedNode = null;
  textColor: any;
  bgColor: any;
  // lg mindMap
  edges = [];
  draggables = [];
  targets = [];
  applicationTypes = [];
  applications = [];
  models = [];
  objects = [];
  tables = [];
  fields = [];
  choosenIcon = 'start';
  loadingApplication = false;
  loadingModel = false;
  loadingObject = [];
  loadingApplicationType = true;
  loadingTable = [];
  loadingField = [];
  customModelAndObjects = [];
  j = 0;
  modelAndObjectAndTableAndFields = [];
  // ngModel for form
  ngTypeApp = null;
  ngApp = null;
  ngNodeText = null;
  ngModels = [];
  ngObjects = [];
  ngTables = [];
  ngFields = [];
  ngExtern = false;
  ngCatalogue = true;
  ngCustomModel = [];
  ngCustomObjects = [];
  ngCustomTable = [];
  ngCustomsFields = [];
  ngDescription = '';
  ngCustomApplication = null;
  // customs
  customFields = [];
  loaders = [];
  disabled = true;
  error = false;
  errorMessage = '';
  nodesItems = [];
  startConnector = null;
  endConnector = null;
  interval: any;
  startColor = 'black';
  endColor = 'black';
  startParent = null;
  endParent = null;
  linesArch = [];
  clicked = null;
  patterns = [{id: 1, name: 'none'}, {id: 2, name: 'paper'}/*, {id: 4, name: 'tiny'}, {id: 5, name: 'connections'} , {id: 6, name: 'diagonal'} , {id: 7, name: 'parkayFloor'}, {id: 8, name: 'floorTile'}, {id: 9, name: 'polkaDots'}*/, {id: 3, name: 'paperDots'}, {id: 4, name: 'dotDiamond'}, {id: 5, name: 'gradientDots'}, {id: 6, name: 'bluePrint'}];
  pattern = 'none';
  prevPattern = 'none';
  flatDesign = true;
  treeData = [];
  orgData = [];
  modalData: any;
  zoom = 1;
  // external system
  registerFormApp: FormGroup;
  registerFormMod: FormGroup;
  editFormMod: FormGroup;
  registerFormVer: FormGroup;
  registerFormFile: FormGroup;
  editFormFile: FormGroup;
  editFormVer: FormGroup;
  registerForm: FormGroup;
  updateFormApp: FormGroup;
  versions = [];
  filterDefinition: FormGroup;
  listTypeApp = ['Desktop application', 'Web application', 'Mobile application', 'Other'];
  listDepApp = ['On premise', 'Saas'];
  listStatusApp = ['Enabled', 'Disabled'];
  stateOption = ['Enabled', 'Disabled', 'all'];
  stackHolders = [];
  presubmitAdd = true;
  messagedangerAdd: string;
  messagesuccesAdd: string;
  messagedangerNewSys: string;
  messagesuccesNewSys: string;
  presubmitNewSystem = false;
  editedDescApp = false;
  resetbtnDef = false;
  messagedanger: string;
  messagesucces: string;
  presubmitMod = false;
  presubmitModU = false;
  messagedangerMod: string;
  messagesuccesMod: string;
  messagedangerModU: string;
  messagesuccesModU: string;
  // version dec
  presubmitVer = false;
  presubmitVerU = false;
  messagedangerVer: string;
  messagesuccesVer: string;
  messagedangerVerU: string;
  messagesuccesVerU: string;
  // file upload dec
  presubmitFile = false;
  presubmitFileU = false;
  messagedangerFile: string;
  messagesuccesFile: string;
  messagedangerFileU: string;
  messagesuccesFileU: string;
  subscription: Subscription = new Subscription();
  mForAddApps = [];
  fmdSAddApps = [];
  fmdAddApps = [];
  descriptionNewSystem = '';
  descriptionNewModuleSystem = '';
  descriptionUpdateSystem = '';
  descriptionUpdateVersion = '';
  descriptionUpdateModule = '';
  descriptionNewVersion = '';
  descriptionNewModule = '';
  todayDate = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  externalApplications = [];
  loadingApplicationExterne = false;
  holderLoading = false;
  tablesModal = [];
  loadingTableModal = false;
  tableModalValue = [];
  customTableModal = [];
  customTableModalValue = [];
  tableFieldIndex = 0;
  customModalFields = [];
  error1 = false;
  error2 = false;
  extraItems = [];
  extraDescription = '';
  editMode = false;
  options: ITreeOptions = {
    isExpandedField: 'expanded',
    actionMapping: {
      mouse: {
        dblClick: (tree, node, $event) => {
          if (node.hasChildren) { TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event); }
        }
      },
      keys: {
        [KEYS.ENTER]: (tree, node, $event) => {
          node.expandAll();
        }
      }
    },
    nodeHeight: 23,
    levelPadding: 10,
    useVirtualScroll: true,
    animateExpand: true,
    scrollOnActivate: true,
    animateSpeed: 30,
    animateAcceleration: 1.2
  };
  clickedEdges = [];
  selectedEdges = [];
  selectedEdge: any;
  startName: any;
  endName: any;
  simulation: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  tabIndex = 10;
  importData: any;
  offset = {top: 0, left: 0};
  dataLineageName = 'new Data functional map';
  createdBy = localStorage.getItem('username');
  createdAt = new Date();
  updateLn = false;
  owner = '';
  @ViewChild('screen', { static: true }) screen: any;
  img = '';
  name = null;
  businessTerm: any;
  ngTriggerExtra = 'Manual';
  ngDataLinesExtra = [];
  businessTerms = [];
  businessTermFrm: FormGroup;
  nameError = false;
  nameSuccess = false;
  completion = 0;
  ranger: any;
  status = [{id: 'Draft', name: 'Draft'}, {id: 'Reviewed', name: 'Reviewed'} , {id: 'Approved', name: 'Approved'} , {id: 'Rejected', name: 'Rejected'}, {id: 'Published', name: 'Published'}];
  startlinkType = null;
  endlinkType = null;
  businessObject: BusinessObject;
  termsInfo = [];
  businessList = [];
  businessListFrm: FormGroup;
  businessObjectLoading = true;
  businessModalType = true;
  terms = [];
  businessObjects = [];
  lastScrolledLeft = 0;
  lastScrolledTop = 0;
  xMousePos = 0;
  yMousePos = 0;
  selectedLinkDescription: any;
  selectedLinkDescriptionColor = 'gray';
  selectedLinkDescriptionSize = '12px';
  selectedLinkDescriptionStyle = 'normal';
  selectedLinkDescriptionWeigt = 'normal';
  selectedLinkPath = 'fluid';
  selectedLinkShadow = false;
  styles = [{id: 'normal', name: 'normal'}, {id: 'italic', name: 'italic'}];
  weights = [{id: 'normal', name: 'normal'}, {id: 'bold', name: 'bold'}];
  paths = [{id: 'straight', name: 'straight'}, {id: 'arc', name: 'arc'}, {id: 'fluid', name: 'fluid'}, {id: 'magnet', name: 'magnet'}, {id: 'grid', name: 'grid'}];
  initialFlexBasis: number;
  synonymList = [];
  synonymListFrm: FormGroup;
  synonymLoading = true;
  synonymItems = [];
  synonymDescription: any;
  allBusinessItems = [];
  objectsList = [];
  synonyms = [];
  antonyms = [];
  hyperonyms = [];
  synonymTypes = [ {id: 'synonym object', name: 'Synonym object'}, {id: 'synonym term', name: 'Synonym term'}, {id: 'antonym object', name: 'Antonym object'} , {id: 'antonym term', name: 'Antonym term'}, {id: 'hyperonym term', name: 'Hyperonym term'}, {id: 'hyperonym object', name: 'Hyperonym object'}  ];
  businessTypes = [{id: 'term', name: 'Business Term'}, {id: 'object', name: 'Business Object'}];
  displayType = true;
  businessType: string;
 defaultBusinessObject = new BusinessObject('default_object120', 'default', [], [], [], [], '', '', '27/05/2021', 'Draft',  0, null, null, '', this.ceaserCipher('default_object120'), {top: 300, left: 400}, false, false);
 dataSource = {businessObjects: [], businessTerms: [], synonyms: []};
 selectedItem = null;
  statusList = [{id: 'Enabled', name: 'Enabled'}, {id: 'Disabled', name: 'Disabled'}];
  functionalBridge = {id: null, name: null, funcGroup: null};
  lang: string;
  startIcon: string;
  endIcon: string;
  zoomWidth = 170;
  zoomFontSize = 10;
  patternColor: any;
  selectedId = '';
  changement = false;
  selectedTerm = null;
  objectUseds = [];
  resizeObserver: any;
  zoomLine = 4;
  selectedStatusLink = 'To Validate';
  statusLinks = ['To Validate', 'To Invalidate'];
  constructor(private fb: FormBuilder, private businessTermService: BusinessTermService, private dynamicScriptLoader: DynamicScriptLoaderService, private cryptoService: CryptoService, private router: Router, private activeRoute: ActivatedRoute, private dataLineService: DataLineageService, private functionalMapService: FunctionalMapService, private translate: TranslateService, private appService: AppassetService) {
    this.saveFrm = this.initChildForm();
    this.registerFormApp = this.initAppForm();
    this.businessTermFrm = this.initForm();
    this.businessListFrm = this.initBusinessListForm();
    this.synonymListFrm = this.initSynonymListForm();
    this.businessTermFrm.get('type').valueChanges
      .subscribe(value => {
        this.businessTermFrm.get('terms').updateValueAndValidity();
        this.businessTermFrm.get('synonyms').updateValueAndValidity();
      });
  }

  ngAfterViewInit(): void {
    $('#play-area').on('click', event => {
      event.stopPropagation();
      this.clicked = null;
     // $('.node-item').removeClass('selected-node');
      $('.selected-node').removeClass('selected-node');
      $('.selected-term').removeClass('selected-term');
      this.showToolTip('.chart-icons');
      this.clickedEdges = [];
      if (this.selectedEdge != null) {
        // this.selectedEdge.element.outline = false;
        this.selectedEdge.element.dash = {animation: false};
        this.selectedEdge.element.dash = false;
        this.selectedEdge = null;
      }
    }).resize(() => {
      this.updateConnectors();
    }).css('flex-basis', '70%');
    $('#shapes-panel').css('flex-basis', '10%');
    $('#properties-panel').css('flex-basis', '20%');
    this.initialFlexBasis = parseFloat($('#play-area').css('flex-basis'));
    $(window).scroll((e) => {e.preventDefault();this.updateConnectors(); });
    this.showToolTip('#lg-business-object');
    this.showToolTip('#lg-collect');
    this.showToolTip('#lg-synonym');
    $('#play-area').css({'flex-basis': '2000px', 'height': '300vh'});$('#toolbar-ng').css('width','2476.5px');
    $('#toolbar-ng').width($('#shapes-panel').width() + $('#play-area').width() + $('#propriety-panel').width() + 20);
  }
  businessIdUpdate() {
    let id = this.businessTermFrm.get('businessId').value.substr(3);
    this.businessTermFrm.get('type').value == 'term' ? id = 'Bt_' + id : this.businessTermFrm.get('type').value == 'object' ? id = 'Ot_' + id : id = 'Sy_' + id;
    this.businessTermFrm.get('businessId').patchValue(id);
    this.businessTermFrm.get('terms').reset();
    this.businessTermFrm.get('objects').reset();
    this.businessTermFrm.get('synonyms').reset();
  }
  correctPlacement() {
    const that = this;
    const offset = this.initialFlexBasis - parseFloat($('#play-area').css('flex-basis'));
   // console.log(offset);
    let step = 10;
    if (offset > 0) {
      step = -4;
    } else {
      step = 4;
    }
    $('.business-item').each(function() {
      const rectA = that.getBounds($(this));
      const rectB = that.getBounds($('#shapes-panel'));
      if (!that.hitTest(rectA, rectB)) {
        const deplacement = parseFloat($(this).css('left')) + step + 'px';
        $(this).css('left',  deplacement );
      }
    //  console.log(that.hitTest(rectA, rectB));
    });
    $('.node-item').each(function() {
      const rectA = that.getBounds($(this));
      const rectB = that.getBounds($('#shapes-panel'));
      if (!that.hitTest(rectA, rectB)) {
        const deplacement = parseFloat($(this).css('left')) + step + 'px';
        $(this).css('left',  deplacement );
      }
    });
    // const deplacement = parseFloat($('.business-item').css('left')) + step + 'px';
    // const deplacementNode = parseFloat($('.node-item').css('left')) + step + 'px';
    // console.log(deplacement);
    this.initialFlexBasis = parseFloat($('#play-area').css('flex-basis'));
    $('#toolbar-ng').width($('#shapes-panel').width() + $('#play-area').width() + $('#propriety-panel').width() + 20);
    // $('.business-item').css('left',  deplacement );
    // $('.node-item').css('left',  deplacementNode );

    this.updateConnectors();

  }

  ngOnInit(): void {
    this.detectChangeLanguage();
    // get params from url
    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      let name = params.get('name');
      let funcGroup = params.get('funcGroup');
      id = id.replace(/__________/g, '/');
      this.functionalBridge.id = this.cryptoService.get(id);
      name = name.replace(/__________/g, '/');
      this.functionalBridge.name = this.cryptoService.get(name);
      funcGroup = funcGroup.replace(/__________/g, '/');
      this.functionalBridge.funcGroup = this.cryptoService.get(funcGroup);
    });
    if (this.functionalBridge.name != null && this.functionalBridge.name != 0) {
      this.dataLineageName = this.functionalBridge.name;
    }
    if (this.functionalBridge.id != null && this.functionalBridge.id != 0) {
      addLoader(this.lang === 'en' ? 'CONTACTING SERVER...' : 'CONTACTANT SERVEUR...');
      this.getFunctionalMap(this.functionalBridge.id);
    }
    this.edges = [];
    $(document).ready(function() {
      $('.collapsible').collapsible();
    });
    this.initObserver();
    this.getApplicationTypes();
    PlainDraggable.draggableCursor = ['move', 'all-scroll', 'grab'];
    PlainDraggable.draggingCursor = ['move', 'grabbing'];
    this.onGetHolders();
    $(window).scroll((e) => {
      e.preventDefault();
      if (this.lastScrolledLeft != $(document).scrollLeft()) {
        this.xMousePos -= this.lastScrolledLeft;
        this.lastScrolledLeft = $(document).scrollLeft();
        this.xMousePos += this.lastScrolledLeft;
      }
      if (this.lastScrolledTop != $(document).scrollTop()) {
        this.yMousePos -= this.lastScrolledTop;
        this.lastScrolledTop = $(document).scrollTop();
        this.yMousePos += this.lastScrolledTop;
      }
     // this.updateConnectors();
     // window.status = 'x = ' + this.xMousePos + ' y = ' + this.yMousePos;
    });
    //Intialize Form groupe application
    this.registerFormApp = this.fb.group({
      // nameApp: ['', Validators.compose([AppValidator.validApp,Validators.required])],
      nameApp: ['', Validators.compose([Validators.required])],
      effectDateNewApp: ['', Validators.compose([Validators.required])],
      endDateNewApp: [''],
      versionApp: [''],
      statusApp: true,
      external: true,
      typeApp: [''],
      deploymentApp: [''],
      descApp: [''],
      moduleApp: ['', Validators.compose([Validators.required])],
      descriptionModuleSystem: [''],
      createdAt: [''],
      createdBy: [''],
      updatedAt: [''],
      updatedBy: [''],
      manager: [''],
      owner: [''],

      applicationManager: [''],
      metadataApps: []
    });
    //Initialize form update app
    this.updateFormApp = this.fb.group({
      nameApp: ['', Validators.compose([Validators.required])],
      dateApp: [''],
      endDate: [''],
      versionApp: [''],
      statusApp: [''],
      external: [''],
      typeApp: [''],
      deploymentApp: [''],
      descApp: [''],
      idApp: [''],
      createdAt: [''],
      createdBy: [''],
      updatedAt: [''],
      updatedBy: [''],
      manager: ['', Validators.compose([Validators.required])],
      owner: [''],
      applicationManager: [''],

    },);
    //Initialize form filter app
    this.filterDefinition = this.fb.group({
      filterApplication: [''],
      filterReport: [''],
      filterBase: [''],
      filterEnv: [''],
      filterOneApp: '',
      statusAppOption: 'all',
      statusModOption: 'all'
    },);

    //Initialize form update module
    this.registerFormMod = this.fb.group({
      nameMod: ['', Validators.compose([Validators.required])],

      descMod: [''],
      effectiveDate: ['', Validators.compose([Validators.required])],
      status: [''],
      idApp: [''],
      endDate: [''],
      idModule: [''],
    },);
    this.editFormMod = this.fb.group({
      // nameApp: [{ value: '', disabled: true}, Validators.required],
      nameMod: ['', Validators.compose([Validators.required])],

      descMod: [''],
      idModule: [''],
      effectiveDate: ['', Validators.compose([Validators.required])],
      status: [''],
      idApp: [''],
      endDate: ['']

    },);
    //Initialize form update version
    this.registerFormVer = this.fb.group({

      version: [''],
      deploymentApp: [''],
      effectiveDate: ['', Validators.compose([Validators.required])],
      typeApp: [''],
      statusApp: [''],
      idApp: [''],
      idversion: [''],
      endDateApp: [''],
      descVer: ['']
    },);

    this.editFormVer = this.fb.group({
      version: ['', Validators.compose([Validators.required])],
      deploymentApp: ['', Validators.compose([Validators.required])],
      effectiveDate: ['', Validators.compose([Validators.required])],
      typeApp: ['', Validators.compose([Validators.required])],
      statusApp: [''],
      idversion: [''],
      idApp: [''],
      endDateApp: [''],
      descVer: ['']
    },);
    //Initialize form update file
    this.registerFormFile = this.fb.group({

      description: [''],
      file: ['', Validators.compose([Validators.required])],
      idApp: ['']
    },);
    this.editFormFile = this.fb.group({
      name: [''],
      description: [''],
      idApp: [''],
      id: ['']

    },);
    for (const i in this.updateFormApp.controls) {
      if (false) {
        this.updateFormApp.controls[i].enable();
      } else {
        this.updateFormApp.controls[i].disable();
      }
    }
    this.getBusinessTermsList();

  }
  detectChangeLanguage() {
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'en';
      this.translate.use(this.lang);
    }, 10);
  }
  getFunctionalMap(id) {
    // console.log(id);
    $('body').css('position', '');
    window.scrollBy(5, 100);
    window.scrollBy(-5, -100);
    this.businessTermService.getFunctionalMap(id).subscribe(
      d => {
        removeLoader();
        if (d.businessObjects != null) {
			for(let i =0; i <  d.businessObjects.length; i++){
            this.addBusinessObjectHtml(d.businessObjects[i]);
			}
        }
        if (d.catalogues != null) {
			for(let i =0; i <  d.catalogues.length; i++){
				   this.addHtmlContent(d.catalogues[i]);
	      }
      }
        if (d.synonyms != null) {
			for(let i =0; i <  d.synonyms.length; i++){
				this.addSynonymHtml(d.synonyms[i]);
			}
        }
      for(let i =0; i <  d.links.length; i++){
           this.addLine(d.links[i].source, d.links[i].target, d.links[i].startColor, d.links[i].endColor, d.links[i].hideLine, d.links[i].parent,d.links[i].sourceName, d.links[i].targetName, d.links[i].group, d.links[i].path, d.links[i].startSocket!=null?d.links[i].startSocket : null, d.links[i].endsocket!=null?d.links[i].endsocket: null, d.links[i].middleLabel,d.links[i].labelColor, d.links[i].labelSize,d.links[i].labelStyle,d.links[i].labelWeight, d.links[i].dropShadow,d.links[i].startType, d.links[i].endType, d.links[i].status);
         }
        if(d.style!= undefined){this.changePattern(d.style.pattern);}
      if(d.style != undefined){
        this.changePattern(d.style.pattern);
        this.changeComplete(d.style.patternColor);
        this.changeFlatStyle(d.style.flatDesign);
        this.zoom = d.style.zoom;
        $('.node-item').css('transform', 'scale(' + this.zoom + ')');
        $('.business-item').css('transform', 'scale(' + this.zoom + ')');
        $('.lg-synonym').css('transform', 'scale(' + this.zoom + ')');
        if(d.style.flexBasis != undefined){
          $('#play-area').css('flex-basis', d.style.flexBasis);
        }
        if(d.style.zoomFontSize != undefined){
          this.zoomFontSize = d.style.zoomFontSize;
        }
        if(d.style.zoomWidth != undefined){
          this.zoomWidth = d.style.zoomWidth;
        } if(d.style.zoomLine != undefined){
          this.zoomLine = d.style.zoomLine;
        }
        if(d.style.zoomFontSize != undefined){
          this.zoomFontSize = d.style.zoomFontSize;
        }

        this.updateConnectors();
      }
        setTimeout(() => this.updateConnectors(), 500);
      },
      err => {
        console.error(err);
      }
    );
  }
  initBusinessListForm() {
    return this.fb.group(
      {
        btList: [
          null
        ],
      });
  }
  initSynonymListForm() {
    return this.fb.group(
      {
        syList: [
          null
        ],
      });
  }
  initForm() {
    return this.fb.group(
      {
        oldId: [
          null
        ],
        businessId: [
          null,
          Validators.compose([Validators.required])
        ],
        name: [
          new Date(),
          Validators.compose([Validators.required])
        ],
        effective: [
          new Date(),
          Validators.compose([Validators.required])
        ],
        status: [
          null,
          Validators.compose([Validators.required])
        ],
        type: [
          'term',
          Validators.compose([Validators.required])
        ],
        definition: [
          null
        ],
        example: [
          null
        ],
        terms: [null, [
          conditionalValidator(() => this.getTermTypeNotTerm(),
            null)
        ]],
        synonyms: [null, [
          conditionalValidator(() => this.getTermTypeNotSynonym(),
            null)
        ]],
        antonyms: [null, [
          conditionalValidator(() => this.getTermTypeNotSynonym(),
            null)
        ]],
        hyperonyms: [null, [
          conditionalValidator(() => this.getTermTypeNotSynonym(),
            null)
        ]],
        objects: [null, [
          conditionalValidator(() => !this.getTermTypeNotSynonym(),
            null)
        ]]
      }
    );
  }
  getTermTypeNotTerm(): boolean {
    return !(this.businessTermFrm.get('type').value == 'term');
  }
  getTermTypeNotSynonym(): boolean {
    return !(this.businessTermFrm.get('type').value == 'synonym');
  }
  private initChildForm() {
    return this.fb.group(
      {
        functionalName: [
          null,
          Validators.compose([Validators.required])
        ],
        functionalStatus: [
          null,
          Validators.compose([Validators.required])
        ],
        functionalEffective: [
          new Date(),
          Validators.compose([Validators.required])
        ],
        // enabledLine: [
        //   true,
        //   Validators.compose([Validators.required])
        // ],
        owner: [
          null,
          Validators.compose([Validators.required])
        ],
        functionalDescription: [
          null
        ],
      }
    );
  }

  getImageFromType(type: string, color: string) {
    let imag = '';
    switch (type) {
      case 'store':
        imag = '<i class="fas fa-layer-group fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'access':
        imag = '<i class="fas fa-door-open fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'archive':
        imag = '<i class="fas fa-file-archive fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'collect':
        imag = '<i class="fas fa-tasks fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'control':
        imag = '<i class="fas fa-closed-captioning fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'destroy':
        imag = '<i class="fas fa-trash-alt fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'process':
        imag = '<i class="fas fa-microchip fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'transfer':
        imag = '<i class="fas fa-exchange-alt fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'sign':
        imag = '<i class="fab fa-cc-visa fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'validate':
        imag = '<i class="fas fa-clipboard-check fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'start':
        imag = '<i class="fas fa-circle fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'end':
        imag = '<i class="far fa-circle fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'use':
        imag = '<i class="far fa-hand-holding fa-stack-1x fa-inverse fa-middle"></i>';
        break;
    }
    return imag;
  }
  addElement() {
    $('body').css('position', '');
    if (this.ngNodeText == '' || this.ngNodeText == null) {
      this.error = true;
      this.errorMessage = this.lang === 'en' ? 'You must provide a Short Description' : 'Vous devez fournir une description courte';
      return;
    }
    if (this.ngExtern) {
      if (this.ngDescription == '' || this.ngDescription == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide a Short Description' : 'Vous devez fournir une description courte';
        return;
      }
      if (this.ngCustomApplication == '' || this.ngCustomApplication == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide an External System' : 'Vous devez fournir un système externe';

        return;
      }
      if (this.customModelAndObjects.length == 0) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide at least one External detail' : 'Vous devez fournir au moins un détail externe';
        return;
      } else {
        for (let index = 0; index < this.customModelAndObjects.length; index++) {
          if (this.ngCustomModel[index] == null) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an External Model' : 'Vous devez fournir un modèle externe';

            return;
          }
          if (this.ngCustomObjects[index] == null) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an External Object' : 'Vous devez fournir un objet externe';

            return;
          }
          if (this.ngCustomTable[index]!=undefined){
            if (this.ngCustomTable[index].length > 0 && this.ngCustomsFields[index] == undefined) {
              this.error = true;this.errorMessage = this.lang === 'en' ? 'You must provide External Fields since External table was added' : 'Vous devez fournir des champs externes car une table externe a été ajoutée';

              return;
            }
          }
          if (this.ngCustomsFields[index]!= undefined){
            if (this.ngCustomTable[index] == undefined && this.ngCustomsFields[index].length > 0) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide External table since External fields was added' : 'Vous devez fournir une table externe car des champs externes ont été ajoutés';

              return;
            }
          }
        }
      }
    } else {
      // if (this.ngTypeApp == '' || this.ngTypeApp == null) {
      //   this.error = true;
      //   this.errorMessage = 'You must select the application type';
      //   return;
      // }
      if (this.ngApp == '' || this.ngApp == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must select the application' : 'Vous devez sélectionner l\'application';
        return;
      }
      if (this.ngCatalogue) {
        // console.log(this.ngCatalogue);
        if (this.modelAndObjectAndTableAndFields.length != 0) {
          for (let item = 0; item < this.modelAndObjectAndTableAndFields.length; item++) {
            if (this.ngModels[item] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide model' : 'Vous devez fournir un modèle';
              return;
            }
            if (this.ngObjects[item] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide object' : 'Vous devez fournir un objet';

              return;
            }
            if (this.ngModels[item] != undefined && this.ngObjects[item] != undefined && this.ngTables[item] != undefined && this.ngFields[item] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide at least one field since table is selected' : 'Vous devez fournir au moins un champ car une table est sélectionnée';
              return;
            }
            if (this.ngModels[item] != undefined && this.ngObjects[item] != undefined && this.ngTables[item] == undefined && this.ngFields[item] != undefined && this.ngFields[item].length>0) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide a table since fields is not empty' : 'Vous devez fournir une table car les champs ne sont pas vides';

              return;
            }
          }
        }
      } else {
        if (this.customModelAndObjects.length == 0) {
          this.error = true;
          this.errorMessage = this.lang === 'en' ? 'You must provide at least one External detail' : 'Vous devez fournir au moins un détail externe';
          return;
        } else {
          for (let index = 0; index < this.customModelAndObjects.length; index++) {
            if (this.ngCustomModel[index] == null) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide model' : 'Vous devez fournir un modèle';
              return;
            }
            if (this.ngCustomObjects[index] == null) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide object' : 'Vous devez fournir un objet';
              return;
            }
            if (this.ngCustomTable[index].length > 0 && this.ngCustomsFields[index] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide External Fields since External table was added' : 'Vous devez fournir des champs externes car une table externe a été ajoutée';
              return;
            }
            if (this.ngCustomTable[index] == undefined && this.ngCustomsFields[index].length > 0) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide External table since External fields was added' : 'Vous devez fournir une table externe car des champs externes ont été ajoutés';

              return;
            }
          }
        }
      }
    }
    this.error = false;
    this.errorMessage = '';
    const data = [];
    const customs = [];
    if(!this.ngCatalogue){
      if(this.customModelAndObjects.length >0){
        this.customModelAndObjects.map(
          j => {
            const children = [];
            if(this.ngCustomTable[j]!= undefined){
              this.ngCustomTable[j].map(
                tab => {
                  const t = this.ngCustomsFields[j].filter( f => f.table == tab.name);
                  const t0 = [];
                  t.map( tt => t0.push({ id: this.create_UUID(), name: tt.name, description: tt.description, goldSource: false }));
                  children.push({table: {id: this.create_UUID(), name: tab.name}, fields: t0});
                }
              );
            }
            customs.push({model : {id: this.create_UUID(), name: this.ngCustomModel[j]}, object: {id: this.create_UUID(), name: this.ngCustomObjects[j]}, children});
          }
        );
      }
    } else {
      if(this.modelAndObjectAndTableAndFields.length > 0){
        // console.log(this.modelAndObjectAndTableAndFields);
        this.modelAndObjectAndTableAndFields.map(
          i => {
            const children = [];
            if(this.ngTables[i]!=undefined){
              this.ngTables[i].map(
                tab => {
                  // console.log(i);
                  // console.log(tab);
                  // console.log(this.ngFields[i]);
                  // if(this.editMode) {
                  //   for (let tbl of this.ngFields[i]) {
                  //     console.log(tbl);
                  //     if(!Array.isArray(tbl)){
                  //       const tbl1 = [];
                  //       tbl1.push(tbl);
                  //       tbl = tbl1;
                  //     }
                  //     for (const fld of tbl) {
                  //       const tb = fld.id.toString().split(':')[3];
                  //       console.log(fld);
                  //       console.log(tb, tab.name);
                  //       if (tb === tab.name) {
                  //        // const t0 = [];
                  //        // t0.push({id: fld.id, name: fld.name, description: fld.description});
                  //         children.push({table: {id: tab.id, name: tab.name}, fields: tbl});
                  //         break;
                  //       }
                  //     }
                  //   }
                  // }else{
                  //   const t = this.ngFields[i].filter( f => f.table == tab.name);
                  //   const t0 = [];
                  //   t.map( tt => t0.push({ id: tt.id, name: tt.name, description: tt.description }));
                  //   children.push({table: {id: tab.id, name: tab.name}, fields: t0});
                  // }
                  const t = this.ngFields[i].filter( f => f.table == tab.name);
                  const t0 = [];
                  t.map( tt => t0.push({ id: tt.id, name: tt.name, description: tt.description, goldSource: false }));
                  children.push({table: {id: tab.id, name: tab.name}, fields: t0});
                }
              );
            }
            data.push({model: this.ngModels[i], object: this.ngObjects[i], children});
          }
        );
      }
    }
    // data.map( d => {
    //   const t = [];
    //   d.children.map(ch => {
    //    console.log(ch);
    //    if(ch.table.id === )
    //   });
    // });
    const dd = [];
    for (let k = 0; k < data.length; k++) {
      for (let l = 0; l < data[k].children.length; l++) {
        for (let z = 0; z < data[k].children.length; z++) {
          if(data[k].children[l]!= null && data[k].children[z]!= null) {
            if (data[k].children[l].table.id === data[k].children[z].table.id && l != z) {
            //  console.log(data[k].children[l]);
              if(data[k].children[l].fields != data[k].children[l].fields){
                data[k].children[l].fields = data[k].children[l].fields.concat(data[k].children[l].fields);
                data[k].children.splice(z,1);
              }
            }
          }
        }
      }
    }
    //  console.log(data);
    let id = this.create_UUID();
    console.log('created : ' + id);
    let style = null;
    let aux = null;
    if(this.editMode){
      id = $('#'  + $.escapeSelector(this.selectedId)).attr('id');
      style = $('#'  + $.escapeSelector(this.selectedId))[0].style.cssText;
      console.log('edit : ' + id);
      // console.log(this.edges);
      aux = this.edges;
      this.edges = [];
      aux.map(
        l => {
          l.element.remove();
        }
      );
      $('#'  + $.escapeSelector(this.selectedId)).remove();
      const index = this.nodesItems.findIndex(e => e.id === this.selectedId);
      this.nodesItems.splice(index, 1);
    }
    // console.log('après edit : ' + id);
    // console.log(data);
    // @ts-ignore
    const nodeItem = new NodeItem(id, this.choosenIcon, this.ngNodeText, this.ngApp, this.ngExtern, this.ngCatalogue, data, btoa(this.ngDescription), this.ngCustomApplication, 'black', customs, this.ngTypeApp, this.ngTrigger, 'action', this.offset);
    // console.log(nodeItem);
    // const that = this;
    this.addHtmlContent(nodeItem);
    if(style!= null){
      $('#'  + $.escapeSelector(this.selectedId))[0].style = style;
      // setTimeout(() => this.edges = [...t], 1000);
      // console.log(this.edges);
      // console.log(aux);
      // this.updateConnectors();
      aux.map(l => {
        if($('#' + $.escapeSelector(l.source) ).length > 0 && $('#' + $.escapeSelector(l.target) ).length > 0 && !l.hide) {
          $('#' + $.escapeSelector(l.source)).click();
          $('#' + $.escapeSelector(l.target)).click();
        }
      });
      $('#play-area').click();
      // console.log(aux);
      // console.log(this.edges);
    }
  }
  // addElement() {
  //   $('body').css('position', '');
  //   if (this.ngNodeText == '' || this.ngNodeText == null) {
  //     this.error = true;
  //     this.errorMessage = 'You must provide a Short Description';
  //     return;
  //   }
  //   if (this.ngExtern) {
  //     if (this.ngDescription == '' || this.ngDescription == null) {
  //       this.error = true;
  //       this.errorMessage = 'You must provide a Long Description';
  //       return;
  //     }
  //     if (this.ngCustomApplication == '' || this.ngCustomApplication == null) {
  //       this.error = true;
  //       this.errorMessage = 'You must provide an External System';
  //       return;
  //     }
  //     if (this.customModelAndObjects.length == 0) {
  //       this.error = true;
  //       this.errorMessage = 'You must provide at least one External detail';
  //       return;
  //     } else {
  //       for (let index = 0; index < this.customModelAndObjects.length; index++) {
  //         if (this.ngCustomModel[index] == null) {
  //           this.error = true;
  //           this.errorMessage = 'You must provide an External Model';
  //           return;
  //         }
  //         if (this.ngCustomObjects[index] == null) {
  //           this.error = true;
  //           this.errorMessage = 'You must provide an External Object';
  //           return;
  //         }
  //         if (this.ngCustomTable[index] != undefined) {
  //           if (this.ngCustomTable[index].length > 0 && this.ngCustomsFields[index] == undefined) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide External Fields since External table was added';
  //             return;
  //           }
  //         }
  //         if (this.ngCustomsFields[index] != undefined) {
  //           if (this.ngCustomTable[index] == undefined && this.ngCustomsFields[index].length > 0) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide External table since External fields was added';
  //             return;
  //           }
  //         }
  //       }
  //     }
  //   } else {
  //     // if (this.ngTypeApp == '' || this.ngTypeApp == null) {
  //     //   this.error = true;
  //     //   this.errorMessage = 'You must select the application type';
  //     //   return;
  //     // }
  //     if (this.ngApp == '' || this.ngApp == null) {
  //       this.error = true;
  //       this.errorMessage = 'You must select the application';
  //       return;
  //     }
  //     if (this.ngCatalogue) {
  //       // console.log(this.ngCatalogue);
  //       if (this.modelAndObjectAndTableAndFields.length != 0) {
  //         for (let item = 0; item < this.modelAndObjectAndTableAndFields.length; item++) {
  //           if (this.ngModels[item] == undefined) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide model';
  //             return;
  //           }
  //           if (this.ngObjects[item] == undefined) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide object';
  //             return;
  //           }
  //           if (this.ngModels[item] != undefined && this.ngObjects[item] != undefined && this.ngTables[item] != undefined && this.ngFields[item] == undefined) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide at least one field since table is selected';
  //             return;
  //           }
  //           if (this.ngModels[item] != undefined && this.ngObjects[item] != undefined && this.ngTables[item] == undefined && this.ngFields[item] != undefined && this.ngFields[item].length > 0) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide a table since fields is not empty';
  //             return;
  //           }
  //         }
  //       }
  //     } else {
  //       if (this.customModelAndObjects.length == 0) {
  //         this.error = true;
  //         this.errorMessage = 'You must provide at least one External detail';
  //         return;
  //       } else {
  //         for (let index = 0; index < this.customModelAndObjects.length; index++) {
  //           if (this.ngCustomModel[index] == null) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide an External Model';
  //             return;
  //           }
  //           if (this.ngCustomObjects[index] == null) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide an External Object';
  //             return;
  //           }
  //           if (this.ngCustomTable[index] != undefined) {
  //           if (this.ngCustomTable[index].length > 0 && this.ngCustomsFields[index] == undefined) {
  //             this.error = true;
  //             this.errorMessage = 'You must provide External Fields since External table was added';
  //             return;
  //           }
  //         }
  //           if (this.ngCustomsFields[index] != undefined) {
  //             if (this.ngCustomTable[index] == undefined && this.ngCustomsFields[index].length > 0) {
  //               this.error = true;
  //               this.errorMessage = 'You must provide External table since External fields was added';
  //               return;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  //   this.error = false;
  //   this.errorMessage = '';
  //   const data = [];
  //   const customs = [];
  //   if (!this.ngCatalogue) {
  //     if (this.customModelAndObjects.length > 0) {
  //       this.customModelAndObjects.map(
  //         j => {
  //           const children = [];
  //           if (this.ngCustomTable[j] != undefined) {
  //             this.ngCustomTable[j].map(
  //               tab => {
  //                 const t = this.ngCustomsFields[j].filter( f => f.table == tab.name);
  //                 const t0 = [];
  //                 const extraData = this.ngApp.name + ':' + this.ngCustomModel[j] + ':' + this.ngCustomObjects[j] ;
  //                 t.map( tt => t0.push({ id: this.create_UUID(), name: tt.name, description: tt.description, extraData: extraData + ':' + tt.name }));
  //                 children.push({table: {id: this.create_UUID(), name: tab.name}, fields: t0});
  //               }
  //             );
  //           }
  //           const extraData1 = this.ngApp.name + ':' + this.ngCustomModel[j] + ':' + this.ngCustomObjects[j] ;
  //           customs.push({model : {id: this.create_UUID(), name: this.ngCustomModel[j]}, object: {id: this.create_UUID(), name: this.ngCustomObjects[j], extraData: extraData1}, children});
  //         }
  //       );
  //     }
  //   } else {
  //     if (this.modelAndObjectAndTableAndFields.length > 0) {
  //       this.modelAndObjectAndTableAndFields.map(
  //         i => {
  //           const children = [];
  //           if (this.ngTables[i] != undefined) {
  //             this.ngTables[i].map(
  //               tab => {
  //                 const t = this.ngFields[i].filter( f => f.table == tab.name);
  //                 const t0 = [];
  //                 t.map( tt => t0.push({ id: tt.id, name: tt.name, description: tt.description }));
  //                 children.push({table: {id: tab.id, name: tab.name}, fields: t0});
  //               }
  //             );
  //           }
  //           data.push({model: this.ngModels[i], object: this.ngObjects[i], children});
  //         }
  //       );
  //     }
  //   }
  //   if (this.editMode) {
  //
  //   } else {
  //
  //   }
  //   let id = this.create_UUID();
  //   if(this.nodesItems.find(e => e.id == id)!= null){
  //     id = this.create_UUID();
  //   }
  //   if(this.nodesItems.find(e => e.id == id)!= null){
  //     id = this.create_UUID();
  //   }
  //   // @ts-ignore
  //   const nodeItem = new NodeItem(id, this.choosenIcon, this.ngNodeText, this.ngApp, this.ngExtern, this.ngCatalogue, data, btoa(this.ngDescription), this.ngCustomApplication, 'black', customs, this.ngTypeApp, null, 'action', this.offset);
  //    console.log(nodeItem);
  //   // const that = this;
  //
  //   this.addHtmlContent(nodeItem);
  // }

  getEdges(clicked: NodeItem) {
    this.clickedEdges = [];
    this.selectedEdges = [];
    if (clicked.data.length > 0) {
      clicked.data.map(
        d => {
          const startObject = 'start_' + d.object.id;
          const endObject = 'end_' + d.object.id;
          const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
          const objectEdgesParent = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == false);
          if (objectEdges.length > 0) {
            this.clickedEdges = this.clickedEdges.concat(objectEdges);
          }
          if (objectEdgesParent.length > 0) {
            this.selectedEdges = this.selectedEdges.concat(objectEdgesParent);
          }
          if (d.children.length > 0) {
            d.children.map(
              t => {
                t.fields.map(
                  f => {
                    const startField = 'start_' + f.id;
                    const endField = 'end_' + f.id;
                    const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == true);
                    const fieldEdgesParent = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == false);
                    if (fieldEdges.length > 0) {
                      this.clickedEdges = this.clickedEdges.concat(fieldEdges);
                    }
                    if (fieldEdgesParent.length > 0) {
                      this.selectedEdges = this.selectedEdges.concat(fieldEdgesParent);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
    if (clicked.customs.length > 0) {
      clicked.customs.map(
        d => {
          const startObject = 'start_' + d.object.id;
          const endObject = 'end_' + d.object.id;
          const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
          const objectEdgesParent = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == false);
          if (objectEdges.length > 0) {
            this.clickedEdges = this.clickedEdges.concat(objectEdges);
          }
          if (objectEdgesParent.length > 0) {
            this.selectedEdges = this.selectedEdges.concat(objectEdgesParent);
          }
          if (d.children.length > 0) {
            d.children.map(
              t => {
                t.fields.map(
                  f => {
                    const startField = 'start_' + f.id;
                    const endField = 'end_' + f.id;
                    const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == true);
                    const fieldEdgesParent = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == false);
                    if (fieldEdges.length > 0) {
                      this.clickedEdges = this.clickedEdges.concat(fieldEdges);
                    }
                    if (fieldEdgesParent.length > 0) {
                      this.selectedEdges = this.selectedEdges.concat(fieldEdgesParent);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
    //  console.table(this.clickedEdges);
  }

  async enableLinking() {
    const that = this;
    $('.connector').off().on('click', async function(e) {
      e.stopPropagation();
      if (that.startConnector == null) {
        that.startConnector = $(this).attr('id');
        that.startName = $(this).attr('data-name');
        that.startIcon = $(this).attr('node-icon');
        if ($(this).parent().hasClass('lg-start') || $(this).parent().hasClass('lg-end') || $(this).parent().hasClass('lg-aggregate') || $(this).parent().hasClass('lg-condition')) {
          if ($(this).parent().hasClass('lg-start')) {that.startColor = 'green'; that.startlinkType = 'start'; }
          if ($(this).parent().hasClass('lg-end')) {that.startColor = 'red'; that.startlinkType = 'end'; }
          if ($(this).parent().hasClass('lg-aggregate')) {that.startColor = 'blue'; that.startlinkType = 'aggregate'; }
          if ($(this).parent().hasClass('lg-condition')) {that.startColor = 'blue'; that.startlinkType = 'condition'; }
          that.startParent = that.startConnector;
        } else {
          that.startColor = $(this).parent().parent().parent().parent().attr('data-color');
          if ($(this).hasClass('start')) {
            that.startParent = $(this).parent().parent().prev('li').prev('li').find('.start').attr('id');
          }
          if ($(this).hasClass('end')) {
            that.startParent = $(this).parent().parent().prev('li').prev('li').find('.end').attr('id');
          }
        }
        if ($(this).hasClass('field-line')) {
          that.startlinkType = 'field';
        }
        // object circle connector has been clicked
        if ($(this).hasClass('object-line')) {
          that.startColor = $(this).parent().parent().parent().attr('data-color');
          that.startParent = that.startConnector;
          that.startlinkType = 'object';
        }
        // business connector has been clicked
        if ($(this).hasClass('business-line-object') || $(this).hasClass('business-line-term')) {
          that.startColor = $(this).hasClass('business-line-object') ? 'rgb(43,127,144)' : 'rgb(27 ,77 ,87)';
          that.startParent = that.startConnector;
          that.startlinkType = $(this).hasClass('business-line-object') ? 'businessObject' : 'businessTerm';
        }
        // synonym connector has been clicked
        if ($(this).hasClass('synonym-object-line') || $(this).hasClass('synonym-term-line') || $(this).hasClass('antonym-object-line') || $(this).hasClass('antonym-term-line') || $(this).hasClass('hyperonym-object-line') || $(this).hasClass('hyperonym-term-line') ) {
          that.startColor = 'gray';
          that.startParent = that.startConnector;
          that.startlinkType = $(this).hasClass('synonym-object-line') ? 'synonymObject' : $(this).hasClass('synonym-term-line') ?'synonymTerm':$(this).hasClass('antonym-object-line') ? 'antonymObject':$(this).hasClass('antonym-term-line') ? 'antonymTerm':$(this).hasClass('hyperonym-object-line') ? 'hyperonymObject': 'hyperonymTerm';
        }
        return;
      }
      if (that.endConnector == null) {
        that.endConnector = $(this).attr('id');
        that.endName = $(this).attr('data-name');
        that.endIcon = $(this).attr('node-icon');
        if ($(this).parent().hasClass('lg-start') || $(this).parent().hasClass('lg-end') || $(this).parent().hasClass('lg-aggregate') || $(this).parent().hasClass('lg-condition')) {
          if ($(this).parent().hasClass('lg-start')) {
            that.endColor = '#11998e';
            that.endlinkType = 'start';
          }
          if ($(this).parent().hasClass('lg-end')) {
            that.endColor = '#D31027';
            that.endlinkType = 'end';
          }
          if ($(this).parent().hasClass('lg-aggregate')) {
            that.endColor = '#00c6ff';
            that.endlinkType = 'aggregate';
          }
          if ($(this).parent().hasClass('lg-condition')) {
            that.endColor = '#00c6ff';
            that.endlinkType = 'condition';
          }
          that.endParent = that.endConnector;
        } else {
          if (($(this).hasClass('field-line') && that.startlinkType == 'businessObject') || ($(this).hasClass('object-line') && that.startlinkType == 'businessTerm')) {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'you can\'t link businessObject with field' : 'Vous ne pouvez pas lier businessObject avec un champ',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          if (($(this).hasClass('business-line-term') && that.startlinkType == 'object') || ($(this).hasClass('business-line-object') && that.startlinkType == 'field')) {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'you can\'t link field with businessObject' : 'Vous ne pouvez pas lier un champ avec businessObject',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          // deny link field with synonym
          if (($(this).hasClass('field-line')  && (that.startlinkType == 'synonymObject' || that.startlinkType == 'synonymTerm' || that.startlinkType == 'antonymObject' || that.startlinkType == 'antonymTerm' || that.startlinkType == 'hyperonymObject' || that.startlinkType == 'hyperonymTerm')) || (($(this).hasClass('synonym-object-line') || $(this).hasClass('synonym-term-line') || $(this).hasClass('antonym-object-line') || $(this).hasClass('antonym-term-line') || $(this).hasClass('hyperonym-object-line') || $(this).hasClass('hyperonym-term-line'))  && that.startlinkType == 'field' )) {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'you can\'t link synonym with field' : 'Vous ne pouvez pas lier un synonyme avec un champ',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          // deny link object with synonym
          if (($(this).hasClass('object-line')  && ($(this).hasClass('synonym-object-line') || $(this).hasClass('synonym-term-line') || $(this).hasClass('antonym-object-line') || $(this).hasClass('antonym-term-line') || $(this).hasClass('hyperonym-object-line') || $(this).hasClass('hyperonym-term-line'))  && that.startlinkType == 'object' )) {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'you can\'t link synonym with object' : 'Vous ne pouvez pas lier un synonyme avec un objet',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          // deny link synonym object and term
          if ((($(this).hasClass('synonym-object-line') || $(this).hasClass('antonym-object-line') || $(this).hasClass('hyperonym-object-line')) && that.startlinkType == 'businessTerm' ) || ($(this).hasClass('business-line-term')  && (that.startlinkType == 'synonymObject' || that.startlinkType == 'antonymObject' || that.startlinkType == 'hyperonymObject') )) {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'you can\'t link semantic object with business term' : 'Vous ne pouvez pas lier un objet sémantique avec un terme métier',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          // deny link synonym term and object
          if ((($(this).hasClass('synonym-term-line') || $(this).hasClass('antonym-term-line') || $(this).hasClass('hyperonym-term-line')) && that.startlinkType == 'businessObject' ) || ($(this).hasClass('business-line-object')  && (that.startlinkType == 'synonymTerm' || that.startlinkType == 'antonymTerm' || that.startlinkType == 'hyperonymTerm') )) {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'you can\'t link semantic term with business object' : 'Vous ne pouvez pas lier un terme sémantique avec un objet métier',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          that.endColor = $(this).parent().parent().parent().parent().attr('data-color');
          if ($(this).hasClass('start')) {
            that.endParent = $(this).parent().parent().prev('li').prev('li').find('.start').attr('id');
          }
          if ($(this).hasClass('end')) {
            that.endParent = $(this).parent().parent().prev('li').prev('li').find('.end').attr('id');
          }
        }
        if ($(this).hasClass('field-line')) {
          that.endlinkType = 'field';
        }
        // object circle connector has been clicked
        if ($(this).hasClass('object-line')) {
          that.endColor = $(this).parent().parent().parent().attr('data-color');
          that.endParent = that.endConnector;
          that.endlinkType = 'object';
        }
        // business connector has been clicked
        if ($(this).hasClass('business-line-object') || $(this).hasClass('business-line-term')) {
          that.endColor = $(this).hasClass('business-line-object') ? 'rgb(43,127,144)' : 'rgb(27 ,77 ,87)';
          that.endParent = that.endConnector;
          that.endlinkType = $(this).hasClass('business-line-object') ? 'businessObject' : 'businessTerm';
        }
        // synonym connector has been clicked
        if ($(this).hasClass('synonym-object-line') || $(this).hasClass('synonym-term-line')) {
          that.endColor = 'gray';
          that.endParent = that.endConnector;
          that.endlinkType = $(this).hasClass('synonym-object-line') ? 'synonymObject' : $(this).hasClass('synonym-term-line') ?'synonymTerm':$(this).hasClass('antonym-object-line') ? 'antonymObject':$(this).hasClass('antonym-term-line') ? 'antonymTerm':$(this).hasClass('hyperonym-object-line') ? 'hyperonymObject': 'hyperonymTerm';
        }
        // console.log(that.startConnector, that.endConnector, that.startParent, that.endParent);
        // that.drawConnector(that.startConnector, that.endConnector);
        if ( that.startConnector != null && that.endConnector != null && that.startParent != null && that.endParent != null) {
          // console.log(that.startConnector, that.endConnector, that.startParent, that.endParent);
          const groupId = that.create_UUID();
          if (that.startlinkType == 'field' && that.endlinkType == 'field') {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'You can\'t link two fields' : 'Vous ne pouvez pas lier deux champs',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          if (that.startlinkType == 'object' && that.endlinkType == 'object') {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'You can\'t link two objects' : 'Vous ne pouvez pas lier deux objets',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          if ((that.startlinkType == 'field' && that.endlinkType == 'object') || (that.startlinkType == 'object' && that.endlinkType == 'field')) {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'You can\'t link field and object' : 'Vous ne pouvez pas lier un champ et un objet',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          if ((that.startlinkType == 'businessObject' && that.endlinkType == 'businessTerm') || (that.startlinkType == 'businessTerm' && that.endlinkType == 'businessObject')) {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'You can\'t link businessObject with businessTerm' : 'Vous ne pouvez pas lier businessObject avec businessTerm',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
             
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          if (that.startlinkType == 'businessTerm' && that.endlinkType == 'businessTerm') {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'You can\'t link two businessTerm' : 'Vous ne pouvez pas lier deux businessTerm',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
           
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          if (((that.startlinkType == 'synonymObject' || that.startlinkType == 'antonymObject' || that.startlinkType == 'hyperonymObject') && that.endlinkType == 'businessObject') || (that.startlinkType == 'businessObject' && (that.endlinkType == 'synonymObject' || that.endlinkType == 'antonymObject' || that.endlinkType == 'hyperonymObject')) ) {
            if ($('#' + that.startConnector).hasClass('end') && $('#' + that.endConnector).hasClass('end')) {
              Swal.fire({
                title: this.lang === 'en' ? 'Error!' : 'Erreur!',
                text: this.lang === 'en' ? 'Semantic object and business object can only be linked on the left side' : 'Un objet sémantique et un objet métier ne peuvent être liés que du côté gauche',
                icon: 'error',
                confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
               });
               
              that.startConnector = null;
              that.endConnector = null;
              that.startParent = null;
              that.endParent = null;
              that.startlinkType = null;
              that.endlinkType = null;
              that.startIcon = null;
              that.endIcon =  null;
              return;
            }
          }
          if (((that.startlinkType == 'synonymTerm' || that.startlinkType == 'antonymTerm' || that.startlinkType == 'hyperonymTerm') && that.endlinkType == 'businessTerm') || (that.startlinkType == 'businessTerm' && (that.endlinkType == 'synonymTerm' || that.endlinkType == 'antonymTerm' || that.endlinkType == 'hyperonymTerm')) ) {
            if ($('#' + that.startConnector).hasClass('end') && $('#' + that.endConnector).hasClass('end')) {
              Swal.fire({
                title: this.lang === 'en' ? 'Error!' : 'Erreur!',
                text: this.lang === 'en' ? 'Semantic term and business term can only be linked on the left side' : 'Un terme sémantique et un terme métier ne peuvent être liés que du côté gauche',
                icon: 'error',
                confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
               });
               
              that.startConnector = null;
              that.endConnector = null;
              that.startParent = null;
              that.endParent = null;
              that.startlinkType = null;
              that.endlinkType = null;
              that.startIcon = null;
              that.endIcon =  null;
              return;
            }
          }
          if (that.startlinkType == 'businessObject' && that.endlinkType == 'businessObject') {
            Swal.fire({
              title: this.lang === 'en' ? 'Error!' : 'Erreur!',
              text: this.lang === 'en' ? 'You can\'t link the same business Object sides' : 'Vous ne pouvez pas lier les mêmes côtés d\'un objet métier',
              icon: 'error',
              confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
             });
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }

          // remove Links if already exist
          const alreadylinkedLeft = that.edges.find(t => t.source == that.startConnector && t.target == that.endConnector);
          const alreayLinkedRight = that.edges.find(t => t.source == that.endConnector && t.target == that.startConnector);
          if (alreadylinkedLeft != null && alreayLinkedRight != null) {
            Swal.fire({
              title: this.lang === 'en' ? 'DELETE this link?' : 'SUPPRIMER ce lien ?',
              text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
             }).then((result) => {
              if (result.value) {
                that.removeLink(alreadylinkedLeft);
                that.removeLink(alreayLinkedRight);
              }
            });
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          if (alreadylinkedLeft != null && alreayLinkedRight == null) {
            Swal.fire({
              title: this.lang === 'en' ? 'DELETE this link?' : 'SUPPRIMER ce lien ?',
              text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
             }).then((result) => {
              if (result.value) {
                that.removeLink(alreadylinkedLeft);
              }
            });
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          if (alreadylinkedLeft == null && alreayLinkedRight != null) {
            Swal.fire({
              title: this.lang === 'en' ? 'DELETE this link?' : 'SUPPRIMER ce lien ?',
              text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
             }).then((result) => {
              if (result.value) {
                that.removeLink(alreayLinkedRight);
              }
            });
            that.startConnector = null;
            that.endConnector = null;
            that.startParent = null;
            that.endParent = null;
            that.startlinkType = null;
            that.endlinkType = null;
            that.startIcon = null;
            that.endIcon =  null;
            return;
          }
          // otherwise add lin
          const { value: label } = await Swal.fire({
            title: this.lang === 'en' ? 'Enter link description' : 'Entrez la description du lien',
            input: 'text',
            showCancelButton: true
           });
           
          that.addLine(that.startConnector, that.endConnector, that.startColor, that.endColor, false, true, that.startName, that.endName, groupId, 'fluid', 'auto', 'auto', label, 'gray', 12, 'normal', 'normal', false, that.startlinkType, that.endlinkType, that.startIcon, that.endIcon);
          // const parentLine = that.addLine(that.startParent, that.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
          // const leftLine = that.addLine(that.startConnector, that.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
          // const rightLine = that.addLine(that.endConnector, that.startParent, that.endColor, that.startColor, true, false, that.startName, that.endName, groupId);
          // that.linesArch.push({line, parent: parentLine, source: that.startParent, target: that.endParent, leftParent: leftLine, rightParent: rightLine, groupId, start: that.startConnector, end : that.endConnector});
          that.startConnector = null;
          that.endConnector = null;
          that.startParent = null;
          that.endParent = null;
          that.startlinkType = null;
          that.endlinkType = null;
          that.startIcon = null;
          that.endIcon =  null;
        }
      }
    });
  }

  private removeLink(alreadylinkedLeft) {
    if (alreadylinkedLeft != null) {
      const group = alreadylinkedLeft.group;
      const links = this.edges.filter(e => e.group == group);
      if (links.length > 0) {
        links.map(
          l => l.element.remove()
        );
      }
      this.edges = this.edges.filter(e => e.group != group);
    }
  }

  removeElement(id) {
    $('#' + id).remove();
  }

  getFields(nodeItem, obj, fields, id) {
    let htm = '';
    fields.map(
      field => {
        htm += '<li class="list-group-item"><i class="fas fa-circle connector start static field-line" id="start_' + field.id + '" data-parent="' + obj + '" data-name="' + field.name + '" node-id="' + id + '" node-icon="' +  nodeItem.icon + '" extra-data="' +  this.getExtraData(field) + '"></i>  &nbsp; <span style="color: #4e4ebb;text-transform: capitalize"><i class="fas fa-receipt"></i>&nbsp;' + field.name + '</span>&nbsp;' + this.getGoldenSource(field) + '</li>';
      }
    );
    return htm;
  }
  getGoldenSource(field: any){
    return field.goldSource ? '<i class="fas fa-gem goldenSourceDisable goldenSourceEnable" data-title="Click to enable/disable Golden Source"></i>' : '<i class="fas fa-gem goldenSourceDisable" data-title="Click to enable/disable Golden Source"></i>';
  }
 getExtraData(item){
   return item.extraData != undefined ? item.extraData : 'nsp7894523';
 }
  makeDraggable(element) {
    // const draggable = Draggable.create('#'  + $.escapeSelector(element), {
    //   bounds: document.getElementById('play-area')
    // } );
   const that = this;
    const draggable =
      new PlainDraggable(document.getElementById(element), { // eslint-disable-line no-new
        onMove() {
          // that.clicked = that.nodesItems.find(item => item.id = element);
          // console.log(that.clicked);
          that.updateConnectors();
        },
        containment: document.getElementById('#play-area'),
        leftTop: true// ,
        // handle: document.querySelector('#'+element+'>span')
      });
    this.draggables.push({element: draggable, id: element});
  }
  addLine(start, end , startColor, endColor, hideLine, parent, startName, endName, group, path, startSocket, endSocket, middleLabel, labelColor, labelSize, labelStyle, labelWeight, dropShadow, startType, endType, status = 'To Validate' , startIcon = null, endIcon = null) {
   // check for doubles lines
    if(this.edges.find(t => t.source == start && t.target == end)!=null || this.edges.find(t => t.source == end && t.target == start)!=null){
     return;
   }
    const that = this;
    let newLine = null;
//    console.log(start,end)
    start = this.skipString(start);
    end = this.skipString(end);
    if (document.getElementById(start).offsetLeft < document.getElementById(end).offsetLeft) {
      newLine = new LeaderLine(
        document.getElementById(start),
        document.getElementById(end),
        {
          endPlug: 'behind',
          startPlug: 'behind',
          endPlugColor: status === 'To Validate' ? 'rgb(190,245,116)' : 'rgb(1,5,181)', //endColor,
          startPlugColor: status === 'To Validate' ? 'rgb(190,245,116)' : 'rgb(43,127,144)', //startColor,
          gradient: true,
          path,
          startSocket,
          endSocket,
          middleLabel: LeaderLine.captionLabel(middleLabel, {color: labelColor, fontSize: labelSize, fontStyle: labelStyle, fontWeight: labelWeight, outlineColor: 'rgba(0, 0, 0, 0)'}),
          hide: hideLine,
          dropShadow
          // dropShadow: {dx: 0, dy: 7}
        }
      );
    } else {
      newLine = new LeaderLine(
        document.getElementById(end),
        document.getElementById(start),
        {
          endPlug: 'behind',
          startPlug: 'behind',
          endPlugColor: status === 'To Validate' ? 'rgb(190,245,116)' : 'rgb(1,5,181)', //endColor,
          startPlugColor: status === 'To Validate' ? 'rgb(190,245,116)' : 'rgb(43,127,144)', //startColor,
          gradient: true,
          path,
          startSocket,
          endSocket,
          middleLabel: LeaderLine.captionLabel(middleLabel, {color: labelColor, fontSize: labelSize, fontStyle: labelStyle, fontWeight: labelWeight, outlineColor: 'rgba(0, 0, 0, 0)'}),
          hide: hideLine,
          dropShadow
          // dropShadow: {dx: 0, dy: 7}
        }
      );
//      console.log(middleLabel);
    }
    $('body>.leader-line:last-of-type').attr('data-source', start).attr('data-target', end).on('click', function(e) {
      e.stopPropagation();
      Swal.fire({
        title: this.lang === 'en' ? 'DELETE this link?' : 'SUPPRIMER ce lien ?',
        text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
       }).then((result) => {
        if(result.value){
          const source = $(this).attr('data-source');
          const target = $(this).attr('data-target');
          that.selectedEdge = that.edges.find(e => e.source == source && e.target == target);
          that.highLightEdge();
          that.removeEdge();
        }
      });

      /* line.element.outline = true;
       line.element.setOptions({
         outlineColor: 'rgb(1,7,12)'
       });*/
    });
    $('body>.leader-line:last-of-type').find('text').addClass('icon-type-text');
    $('body>.leader-line:last-of-type > text > textPath').addClass('icon-type-text');
    this.edges.push({id: this.create_UUID(), element: newLine, source: start, target: end, sourceName: startName, targetName: endName, group , parent, hide: hideLine, startColor, endColor, middleLabel, labelColor, labelSize, labelStyle, labelWeight, path, dropShadow, startType, endType, startIcon, endIcon, status});
    return newLine;
  }

  create_UUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  create_UUIB() {
    let dt = new Date().getTime();
    return 'xxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  getApplicationTypes() {
    this.loadingApplicationType = true;
    this.dataLineService.getApplicationTypes().subscribe(
      d => {
        this.applicationTypes = d.data;
        this.loadingApplicationType = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getApplicationsByType(external = 'NO') {
    external == 'NO' ? this.loadingApplication = true : this.loadingApplicationExterne = true;
    this.ngApp = null;
    let type;
    this.ngTypeApp == null ? type = 'false' : type = this.ngTypeApp.name;
    this.dataLineService.getApplicationsByType(type, external).subscribe(
      d => {
        if (external == 'NO') {
          this.applications = d.data;
          this.loadingApplication = false;
        } else {
          this.externalApplications = d.data;
          this.loadingApplicationExterne = false;
        }
      },
      e => {
        console.error(e);
      },
      () => {
        this.initToolTip();
      }
    );
  }

  getModelsByApp() {
    this.loadingModel = true;
    this.models = [];
    this.objects = [];
    this.tables = [];
    this.fields = [];
    let d = this.ngApp.id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getModelsByApp(d).subscribe(
      d => {
        for (let i = 0; i <= this.j; i++) {
          this.models[i] = d.data;
        }
        this.loadingModel = false;
      },
      e => {
        console.error(e);
      },
      () => {
        this.initToolTip();
      }
    );
  }

  getObjectsByModel(i: number) {
    this.loadingObject[i] = true;
    this.ngObjects[i] = null;
    let d = this.ngModels[i].id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getObjectsByModel(d).subscribe(
      d => {
        this.objects[i] = d.data;
        this.loadingObject[i] = false;
      },
      e => {
        console.error(e);
      },
      () => {
        this.initToolTip();
      }
    );
  }
  initToolTip(){
    setTimeout(() => this.showToolTip('.ng-select-tooltip'), 500);
  }
  getTablesByObject(i: number) {
    this.loadingTable[i] = true;
    this.ngTables[i] = null;
    // console.log(this.ngObjects[i].id);
    // return;
    let d = this.ngObjects[i].id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getTablesByObject(d).subscribe(
      d => {
        this.tables[i] = d.data;
        this.loadingTable[i] = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getFieldsByTable(i: number) {
    this.loadingField[i] = true;
    this.ngFields[i] = null;
    // console.log(this.ngTables[i]);
    if (this.ngTables[i] == null) { return; }
    let d = this.ngTables[i].replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getFieldsByTable(d).subscribe(
      d => {
        this.fields[i] = d.data;
        this.loadingField[i] = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  setCatalogue() {
    // this.addNodeFrm.patchValue({catalogue: !this.addNodeFrm.value['extern']})
    this.ngCatalogue = !this.ngExtern;
    this.resetModelObjectTableFields();
    this.resetCustomModelObject();
  }

  setCustomValue() {
    if (this.ngCatalogue) {
      this.i = 0;
      this.modelAndObjectAndTableAndFields = [];
      this.modelAndObjectAndTableAndFields.push(this.i);
      this.resetCustomModelObject();
    } else {
      this.j = 0;
      this.customModelAndObjects = [];
      this.customModelAndObjects.push(this.j);
      this.resetModelObjectTableFields();
    }
  }

  getObjectById(id: string) {
    const o = this.objects.filter(item => item.id == id);
    if (o.length > 0) {
      return o[0].name;
    }
  }

  showAddLineageModal() {
    this.addNodeFrm.reset();
    this.addNodeFrm.patchValue({customModel: 'null', customObject: 'null', extern: false, catalogue: true});
    // this.addNodeFrm.patchValue({extern: false, catalogue: true});
    // $('#extern').prop('checked', false).change();
    // $('#catalogue').prop('checked', true).change();
    $('#addLineageModal').modal();
  }
  initConnectors() {
    const ends = [];
    const connectors = document.getElementsByClassName('line-handle');
    Array.from(connectors).forEach(function(start, index) {
      const end = start.previousElementSibling;
      const line = new LeaderLine(start, end, {
        startPlug: 'none',
        startPlugColor: '#31b7f9',
        endPlugColor: '#a6dffc',
        gradient: true,
        path: 'fluid',
        startSocket: 'right',
        endSocket: 'left'
      });

      const draggable = new PlainDraggable(end, {
        onMove() {
          line.position();
        },
        onMoveStart() {
          line.dash = {animation: true};
        },
        onDragEnd() {
          line.dash = false;
        },
        autoScroll: true
      });
      ends.push(draggable);
    });
  }

  updateContainer(event) {
    $('body').css('position', '');
    const that = this;
    if (event.stopPropagation) {
      event.stopPropagation(); // stops the browser from redirecting.
    }
    event.preventDefault();
    // console.log(event);
    const id = event.dataTransfer.getData('text');
    if (id == 'lg-synonym') {
      Swal.fire({
        title: this.lang === 'en' ? 'Add Semantic?' : 'Ajouter un sémantique ?',
        text: this.lang === 'en' ? 'Would you like to select from ancient semantics or add new one!' : 'Voulez-vous sélectionner parmi les anciens sémantiques ou en ajouter un nouveau ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'en' ? 'Old Semantic!' : 'Ancien sémantique !',
        cancelButtonText: this.lang === 'en' ? 'New Semantic!' : 'Nouveau sémantique !'
       }).then((result) => {
        if (result.value) {
          this.businessListFrm.reset();
          this.getBusinessSynonymList();
          $('#synoymListModal').modal('show');
        } else {
          $('#saveBusinessGloassaryTitle').html('New Semantic');
          this.editMode = false;
          this.displayType = true;
          this.businessType = 'synonym';
          this.businessTermFrm.get('type').reset();
          this.openModalBusiness('Sy_');
        }
      });

     // const connectors = [];
      // connectors.push({id: 'start_' + newId});
      // connectors.push({id: 'end_' + newId});
      // const extraItem = new ExtraItem(newId, '', connectors, 'start', {top:  event.clientY , left: event.clientX}, this.ngTriggerExtra, this.ngDataLinesExtra);
      // this.addExtraHtml(extraItem);
      return;
    }
    if (id == 'lg-business-object') {
      this.businessModalType = true;
      this.getBusinessTermsList();
      this.addBusinessGlossary('object');
      return;
    }
    this.startScriptUP();
    // this.choosenIcon = type;
    this.ngExtern = false;
    this.ngCatalogue = true;
    this.ngApp = null;
    this.ngTypeApp = null;
    this.ngNodeText = null;
    this.ngDescription = null;
    this.models = [];
    this.tables = [];
    this.fields = [];
    this.ngModels = [];
    this.ngObjects = [];
    this.ngTables = [];
    this.ngFields = [];
    this.ngCustomApplication = null;
    this.ngCustomModel = [];
    this.ngCustomTable = [];
    this.ngCustomObjects = [];
    this.ngCustomsFields = [];
    this.j = 0;
    this.modelAndObjectAndTableAndFields = [];
    this.modelAndObjectAndTableAndFields.push(0);
    this.i = 0;
    this.customModelAndObjects = [];
    this.customModelAndObjects.push(0);
    this.ngDescription = '';
    this.editMode = false;
    this.getApplicationsByType('NO');
    this.getApplicationsByType( 'YES');
    this.changement = false;
    $('#addLineageModal').modal('show');
    return false;
  }
  getBusinessSynonymList() {
    this.synonymLoading = true;
    this.businessTermService.getBusinessSynonymList().subscribe(
      d => {
        this.synonymList = d;
        this.synonymLoading = false;
      },
      err => {console.error(err); }
    );
  }
  allowEvent(event) {
    event.preventDefault();
  }
  groupByFn1 = (item) => item.type;
  groupValueFn1 = (_: string, children: any[]) => ({ name: children[0].type, total: children.length });
  getIconForModal(type: string) {
    return '<i class="fas fa-circle fa-stack-2x fa-blue" style="color: rgb(60 172 196);vertical-align: middle;"></i>' +
      this.getImageFromType(type, '');
  }

  dragStart(event: DragEvent) {
    $(event.target).css('opacity', '0.4');
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
  }

  dragEnd(event: DragEvent, type: string) {
    $(event.target).css('opacity', 1);
    this.choosenIcon = type;
    this.offset = {top:  event.clientY , left: event.clientX};
  }
  requiredByExtern() {
    this.addNodeFrm.get('extern').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('nodeText').updateValueAndValidity();
        this.addNodeFrm.get('description').updateValueAndValidity();
      });
  }

  requiredByCatalogue() {
    this.addNodeFrm.get('catalogue').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('appType').updateValueAndValidity();
        this.addNodeFrm.get('app').updateValueAndValidity();
        this.addNodeFrm.get('model').updateValueAndValidity();
        this.addNodeFrm.get('objects').updateValueAndValidity();
      });
  }

  requiredByTable() {
    this.addNodeFrm.get('table').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('fields').updateValueAndValidity();
      });
  }

  requiredByCustomModel() {
    this.addNodeFrm.get('catalogue').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('customModel').updateValueAndValidity();
        this.addNodeFrm.get('customObject').updateValueAndValidity();
      });
  }

  addCustomModelObject() {
    this.i++;
    this.customModelAndObjects.push(this.i);
  }

  resetCustomModelObject() {
    this.i = 0;
    this.customModelAndObjects = [];
    this.customModelAndObjects.push(0);
    this.ngCustomApplication = null;
    this.ngCustomModel = [];
    this.ngCustomObjects = [];
    this.ngCustomTable = [];
    this.ngCustomsFields = [];
  }

  removeCustomModelObject(i: number) {
    this.customModelAndObjects.splice(i, 1);
    this.ngCustomModel[i] = null;
    this.ngCustomObjects[i] = null;
    this.ngCustomsFields[i] = null;
  }

  // dynamic model, object, table ,fields
  addModelObjectTableFields() {
    this.j++;
    this.models[this.j] = this.models[this.j - 1];
    this.modelAndObjectAndTableAndFields.push(this.j);
  }

  resetModelObjectTableFields() {
    this.j = 0;
    this.modelAndObjectAndTableAndFields = [];
    this.modelAndObjectAndTableAndFields.push(0);
    this.ngModels = [];
    this.ngObjects = [];
    this.ngTables = [];
    this.ngFields = [];
    this.objects = [];
    this.tables = [];
    this.fields = [];
  }

  removeModelObjectTableFields(i: number) {
    this.modelAndObjectAndTableAndFields.splice(i, 1);
    this.ngModels.splice(i, 1);
    this.ngObjects.splice(i, 1);
    this.ngTables.splice(i, 1);
    this.ngFields.splice(i, 1);
    this.objects.splice(i, 1);
    this.tables.splice(i, 1);
    this.fields.splice(i, 1);
  }

  async startScriptUP() {
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      this.loadData();
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      // this.loadData();
      const that = this;

      $('#addApp').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,


      })
        .on('change', function(e, date) {
          const dateapp = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          that.registerFormApp.get('dateApp').patchValue(dateapp);
        });
    }).catch(error => console.error(error));
  }

  private loadData() {
    // definition editor
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.ngDescription) {
      CKEDITOR.instances.ngDescription.destroy(true);
    }
    const editorDescription = CKEDITOR.replace('ngDescription');
    editorDescription.config.height = 60;
    editorDescription.on( 'change', evt => {
      this.ngDescription = evt.editor.getData();
    });
  }
  addCustomTable = (term) => ({id: term, name: term});

  addCustomField = (term) => ({id: term, name: term});
  // removeTable = (event, i) => {
  //   this.customTable[i].splice(this.customTable[i].indexOf(e => e.id == event.id), 1);
  // }
  removeField = (event, i) => {
    // this.customFields[i].splice(this.customFields[i].indexOf(e => e.id == event.id), 1);
  }
  // drawConnector(a, b) {
  //   const divA  = document.getElementById(a);
  //   const divB  = document.getElementById(b);
  //   const arrowLeft = document.getElementById('arrowLeft');
  //   console.log(divA, divA, arrowLeft);
  //   const posnALeft = {
  //     x: divA.offsetLeft - 8,
  //     y: divA.offsetTop  + divA.offsetHeight / 2
  //   };
  //   const posnARight = {
  //     x: divA.offsetLeft + divA.offsetWidth + 8,
  //     y: divA.offsetTop  + divA.offsetHeight / 2
  //   };
  //   const posnBLeft = {
  //     x: divB.offsetLeft - 8,
  //     y: divB.offsetTop  + divB.offsetHeight / 2
  //   };
  //   const posnBRight = {
  //     x: divB.offsetLeft + divB.offsetWidth + 8,
  //     y: divB.offsetTop  + divB.offsetHeight / 2
  //   };
  //   const dStrLeft =
  //     'M' +
  //     (posnALeft.x      ) + ',' + (posnALeft.y) + ' ' +
  //     'C' +
  //     (posnALeft.x - 100) + ',' + (posnALeft.y) + ' ' +
  //     (posnBLeft.x - 100) + ',' + (posnBLeft.y) + ' ' +
  //     (posnBLeft.x      ) + ',' + (posnBLeft.y);
  //   console.log(dStrLeft);
  //   arrowLeft.setAttribute('d', dStrLeft);
  // }
  // wrapperPosition() {
  //   const lineWrapper = document.getElementById('line-wrapper');
  //   lineWrapper.style.transform = 'none';
  //   const rect = lineWrapper.getBoundingClientRect();
  //   lineWrapper.style.transform = 'translate(' +
  //     (-(rect.left + pageXOffset)) + 'px, ' +
  //     (-(rect.top + pageYOffset)) + 'px)';
  // }
  updateConnectors() {
//    console.log(this.zoomWidth)
    $('.node-item').css({ width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
    $('.business-item').css( { width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
    $('.business-item .card-header').css( 'width', this.zoomWidth +'px');
    $('.lg-synonym').css({ width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
   // console.log("scroll");
    try {
      this.edges.map(
        item => {
          item.element.size = this.zoomLine;
          item.element.position();
        //  if(this.zoomWidth!=170){

        //  }
        }
      );

    } catch (e) {// console.error(e);
    }
  }
  zoomConnectors() {
    try {
      this.edges.map(
        item => {
          item.element.size = this.zoom;
        }
      );
    } catch (e) {// console.error(e);
    }
  }
  getTables(nodeItem, obj, children, id) {
    let html = '';
    children.map(
      t => {
        // console.log(t);
        html += '<li class="list-group-item" style="float: left;"><b style="color: blue;text-transform: capitalize;"><i class="fas fa-table"></i>&nbsp;' + t.table.name + '</b>&nbsp;</li>' +
          this.getFields(nodeItem, obj, t.fields, id);
      }
    );
    return html;
  }

  ngOnDestroy(): void {
    this.edges.map(e => { e.element.remove(); });
    this.resizeObserver.disconnect();
  }

  collapse(collapse: string, event) {
    $('#' + collapse).collapse('toggle');
    if ($(event.target).hasClass('fa-minus')) {
      $(event.target).removeClass('fa-minus').addClass('fa-plus');
    } else {
      $(event.target).removeClass('fa-plus').addClass('fa-minus');
    }
  }
  getApplicationTypeIcon(app, custom) {
    let icon = '';
    const type = app != null ? app.name : custom.name;
    switch (type) {
      case 'Desktop application': icon = '<i class="fas fa-desktop" title="System type ' + type + '"></i>'; break;
      case 'Web application': icon = '<i class="fab fa-chrome" title="System type ' + type + '"></i>'; break;
      case 'Mobile application': icon = '<i class="fas fa-mobile-alt" title="System type ' + type + '"></i>'; break;
      default: icon = '<i class="fas fa-info" title="System type ' + type + '"></i>'; break;
    }
    return icon;
  }
  changePattern(cl) {
    this.pattern = cl;
    if (this.prevPattern != 'none') {
      $('#play-area').removeClass(this.prevPattern);
    }
    $('#play-area').addClass(this.pattern);
    this.prevPattern = this.pattern;
  }

  changeComplete(event: ColorEvent) {
    let style = $('#play-area').attr('style');
    style = style.split('background-color');
    if(event !=  null){
      style[0] += 'background-color:' + event.color.hex + ' !important;';
      $('#play-area').attr('style', style);
      this.patternColor = event;
    }
  }
  getHeaderStyle() {
    if (this.flatDesign) {
      return  '<div class="card-header text-center text-white node-header"  style="background-color: ';
    } else {
      return  '<div class="card-header text-center text-white node-header header-opaque"  style="background-color: ';
    }
  }
  getDetailsStyle() {
    if (this.flatDesign) {
      return '      <ul class="list-group list-group-flush text-black node-details flat-details" id="';
    } else {
      return '      <ul class="list-group list-group-flush text-black node-details opaque-details" id="';
    }
  }
  getFooterStyle() {
    if (this.flatDesign) {
      return '      <div class="card-body tools-line tools-line-flat">\n';
    } else {
      return '      <div class="card-body tools-line tools-line-opaque">\n';
    }
  }
  getNodeStyle() {
    if (this.flatDesign) {
      return '  <div class="card node-item" style="width: 170px;background: transparent;font-size:10px;" data-color="';
    } else {
      return '  <div class="card node-item shadow-design" style="width: 170px;background: transparent;font-size:10px;" data-color="';
    }
  }
  changeFlatStyle(event) {
    // console.log(event);
    const checked = $(event.target).prop('checked');
    if (checked) {
      this.flatDesign = true;
      if ($('.node-item').length > 0) {
        $('.node-item').removeClass('shadow-design');
      }
      if ($('.business-item').length > 0) {
        $('.business-item').removeClass('shadow-design');
      }
    } else {
      this.flatDesign = false;
      if ($('.node-item').length > 0) {
        $('.node-item').addClass('shadow-design');
      }
      if ($('.business-item').length > 0) {
        $('.business-item').addClass('shadow-design');
      }
    }
  }
  changeLinkFlatStyle(event) {
    this.selectedLinkShadow = $(event.target).prop('checked');
    this.changeLinkDescription();
  }
  collapseMenuDetect() {
    console.log('test');
  }
  startEndDrag(event) {

  }
  dragstart_handler(ev) {
    //  console.log('dragStart');
    // Change the source element's background color to signify drag has started
    // ev.currentTarget.style.border = 'dashed';
    // Add the id of the drag source element to the drag data payload so
    // it is available when the drop event is fired
    ev.dataTransfer.setData('text', ev.target.id);
    // Tell the browser both copy and move are possible
    ev.effectAllowed = 'copy';
  }
  dragover_handler(ev) {
    //  console.log('dragOver');
    // Change the target element's border to signify a drag over event
    // has occurred
    // ev.currentTarget.style.background = 'lightblue';
    ev.preventDefault();
  }
  drop_handler(ev) {
    //  console.log('Drop');
    ev.preventDefault();
    // console.log(ev);
    // Get the id of drag source element (that was added to the drag data
    // payload by the dragstart event handler)
    const id = ev.dataTransfer.getData('text');
    // Only Move the element if the source and destination ids are both "move"
    if (id == 'src_move' && ev.target.id == 'dest_move') {
      ev.target.appendChild(document.getElementById(id));
    }
    // Copy the element if the source and destination ids are both "copy"
    if (id == 'src_copy' && ev.target.id == 'dest_copy') {
      const nodeCopy = document.getElementById(id).cloneNode(true);
      // @ts-ignore
      // nodeCopy.id = 'newId';
      // @ts-ignore
      nodeCopy.removeAttribute('draggable');
      // @ts-ignore
      nodeCopy.removeAttribute('id');
      ev.target.appendChild(nodeCopy);
    }
  }

  dragend_handler(ev) {
    // console.log('dragEnd');
    // Restore source's border
    // ev.target.style.border = null;
    // Remove all of the drag data
    ev.dataTransfer.clearData();
    this.offset = {top:  ev.clientY , left: ev.clientX};
	console.log(this.offset);
  }
  groupBy(key) {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  }
  groupByModel(array) {
    return array.reduce((acc, obj) => {
      const property = obj.model.id;
      acc[property] = acc[property] || [];
      acc[property].push(obj);
      return acc;
    }, {});
  }
  getNodeData(clicked) {
    this.treeData = this.unifyObject(clicked);
    // console.log(this.treeData);
    this.orgData = [];
    const treeParent = {id: 1, name : clicked.text, children : [] , expanded: true};
    let orgIndex = 2;
    // tree node
    this.treeData.map(
      m => {
        const orgModeNode = { id: orgIndex, name:  m.model.name, title: 'treeModel', children: [] , className: 'treeModel', icon: '<i class="fas fa-server"></i>', expanded: true};
        // object loop
        m.data.map(
          det => {
            orgIndex++;
            const orgObjNode = { id: orgIndex , name:  det.object.name, title: 'treeObject', children: [], className: 'treeObject', icon: '<i class="fas fa-vector-square"></i>', expanded: true};
            // table loop
            det.children.map(
              t => {
                orgIndex++;
                const orgTabNode = { id: orgIndex , name:  t.table.name, title: 'treeTable', children: [], className: 'treeTable', icon: '<i class="fas fa-table"></i>', expanded: true };
                // fields loop
                t.fields.map(
                  f => {
                    orgIndex++;
                    const orgFieldNode = { id: orgIndex , name:  f.name, title: 'treeField' , className: 'treeField', icon: '<i class="fas fa-receipt"></i>', expanded: true};
                    orgTabNode.children.push(orgFieldNode);
                  }
                );
                orgObjNode.children.push(orgTabNode);
              }
            );
            orgModeNode.children.push(orgObjNode);
          }
        );
        treeParent.children.push(orgModeNode);
      }
    );
    this.orgData.push(treeParent);
  }
  zoomIn() {
   // if (this.zoom < 1.6) {
   //   this.zoom += 0.2;
   //    // $('.node-item').css('transform', 'scale(' + this.zoom + ')');
   //    // $('.business-item').css('transform', 'scale(' + this.zoom + ')');
   //    // $('.lg-synonym').css('transform', 'scale(' + this.zoom + ')');
   //   this.updateConnectors();
   //  }
    if (this.zoomWidth < 200){
      this.zoomWidth += 10;
      if(this.zoomFontSize < 16){
        this.zoomFontSize += 1;
      }
      if(this.zoomLine < 5){
        this.zoomLine += 1;
      }
      $('.node-item').css({ width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
      $('.business-item').css( { width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
      $('.business-item .card-header').css( 'width', this.zoomWidth +'px');
      $('.lg-synonym').css({ width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
      this.updateConnectors();
    }

  }
  zoomOut() {
    // if (this.zoom > 0.3) {
    //   this.zoom -= 0.2;
    //   $('.node-item').css('transform', 'scale(' + this.zoom + ')');
    //   $('.business-item').css('transform', 'scale(' + this.zoom + ')');
    //   $('.lg-synonym').css('transform', 'scale(' + this.zoom + ')');
    //   this.updateConnectors();
    // }
    if (this.zoomWidth > 50){
      this.zoomWidth -= 20;
      if(this.zoomFontSize > 5){
        this.zoomFontSize -= 1;
      }
      if(this.zoomFontSize <= 6){
        this.zoomWidth = 90;
      }
      if(this.zoomLine > 1){
        this.zoomLine -= 1;
      }
      $('.node-item').css({ width: this.zoomWidth + 'px', 'font-size': this.zoomFontSize + 'px'});
      $('.business-item').css( { width: this.zoomWidth + 'px', 'font-size': this.zoomFontSize + 'px'});
      $('.business-item .card-header').css( 'width', this.zoomWidth + 'px');
      $('.lg-synonym').css({ width: this.zoomWidth + 'px', 'font-size': this.zoomFontSize + 'px'});
      this.updateConnectors();
    }
  }
  zoomReset() {
    // this.zoom = 1;
    // $('.node-item').css('transform', 'scale(' + this.zoom + ')');
    // $('.business-item').css('transform', 'scale(' + this.zoom + ')');
    // $('.lg-synonym').css('transform', 'scale(' + this.zoom + ')');
    this.zoomWidth = 170;
    this.zoomFontSize = 11;
    $('.node-item').css({ width: this.zoomWidth + 'px', 'font-size': this.zoomFontSize + 'px', transform: 'scale(1)'});
    $('.business-item').css( { width: this.zoomWidth + 'px', 'font-size': this.zoomFontSize + 'px', transform: 'scale(1)'});
    $('.business-item .card-header').css( { width: this.zoomWidth + 'px', transform: 'scale(1)'});
    $('.lg-synonym').css({width: this.zoomWidth + 'px' , transform: 'scale(1)'});
    this.updateConnectors();
  }

  private initAppForm() {
    return this.fb.group({
      nameApp: ['', Validators.compose([Validators.required])],
      dateApp: [''],
      versionApp: [''],
      statusApp: true,
      external: [{value: true, disabled: true }],
      typeApp: [''],
      deploymentApp: [''],
      descApp: [''],
      moduleApp: ['', Validators.compose([Validators.required])],
      descMod: [''],
      createdAt: [''],
      createdBy: [''],
      updatedAt: [''],
      updatedBy: [''],
      manager: ['', Validators.compose([Validators.required])],
      owner: [''],

      applicationManager: ['']
    });
  }
  onGetHolders() {
    this.holderLoading = true;
    this.dataLineService.getHolders().subscribe(
      data => {
        this.stackHolders = data.data;
        this.holderLoading = false;
      }
    );
  }
  groupByFn = (item) => item.stack.role;
  groupValueFn = (_: string, children: any[]) => ({ name: children[0].stack.role, total: children.length });
  onClose() {
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.registerFormApp.get('nameApp').patchValue('');
    this.registerFormApp.get('dateApp').patchValue('');
    this.registerFormApp.get('applicationManager').patchValue('');
    this.registerFormApp.get('versionApp').patchValue('');
    this.registerFormApp.get('statusApp').patchValue(true);
    this.registerFormApp.get('external').patchValue(true);
    this.registerFormApp.get('typeApp').patchValue('');
    this.registerFormApp.get('deploymentApp').patchValue('');
    this.registerFormApp.get('descApp').patchValue('');
    this.registerFormApp.get('moduleApp').patchValue('');
    this.registerFormApp.get('descMod').patchValue('');
    this.registerFormApp.get('manager').patchValue('');
  }
  checkAppnameAdd() {
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.dataLineService.checkApp(this.registerFormApp.get('nameApp').value).subscribe(
      d => {
        // @ts-ignore
        if (d.response.indexOf('taken') != -1) {
          this.presubmitAdd = true;
          this.messagedangerAdd = this.lang === 'en' ? this.registerFormApp.controls.nameApp.value + ' exist, choose another application !' : this.registerFormApp.controls.nameApp.value + ' existe, choisissez une autre application !';
         } else {
          this.presubmitAdd = false;
          this.messagesuccesAdd = this.lang === 'en' ? this.registerFormApp.controls.nameApp.value + ' is available !' : this.registerFormApp.controls.nameApp.value + ' est disponible !';
         }},
         
      e => {console.error(e); }
    );
  }
  addSystem() {
    const desc = window.btoa(encodeURIComponent(this.registerFormApp.get('descApp').value));
    this.registerFormApp.get('descApp').patchValue(desc);
    const username = localStorage.getItem('username');
    this.registerFormApp.get('createdBy').patchValue(username);
    this.dataLineService.addSystem(JSON.stringify(this.registerFormApp.value)).subscribe(
      d => {
        this.Toast.fire({
          icon: 'success',
          title: this.lang === 'en' ? 'Added in successfully' : 'Ajouté avec succès'
         });
        
        $('#addSystem').modal('hide');
        this.getApplicationsByType('YES');
      },
      err => {console.error(err); }
    );
  }

  addExternalSystem() {
    this.onClose();
    $('#addSystem').modal();
  }

  openTableField(i) {
    this.tableModalValue = [];
    this.customModalFields = [];
    if (this.ngTables[i] != undefined) {
      $('#addTableFieldTitle').html(this.lang==='en'?'Edit Table and Fields':'Modifier Table et Champs');
    }
    this.error1 = false;
    this.getTablesByObjectModal(i);
    this.tableFieldIndex = i;
    $('#addTableField').modal();
  }
  addTableField() {
    let data = [];
    for ( let i = 0; i < this.tableModalValue.length; i++) {
      if (this.customModalFields[i] == undefined) {
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.tableModalValue[i].name : 'Vous devez fournir des champs pour le tableau ' + this.tableModalValue[i].name;
        this.error1 = true;
        return;
        
      }
      if (this.customModalFields[i].length == 0) {
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.tableModalValue[i].name : 'Vous devez fournir des champs pour le tableau ' + this.tableModalValue[i].name;
        this.error1 = true;
        return;
        
      }
    }
    this.ngTables[this.tableFieldIndex] = this.tableModalValue;
    this.tableModalValue.map( (table, i) => {
      data = data.concat(this.customModalFields[i]);
    });
    this.ngFields[this.tableFieldIndex] = data;
    $('#addTableField').modal('hide');
    this.tableModalValue = [];
    this.customModalFields = [];
    this.changement = true;
  }
  hideToolTip(){
    $('#toolTipMap').offset({top: 0, left: 0}).html('').hide();
  }
  getFieldsByTableModal() {
    //  console.log(this.tableModalValue);
    this.tableModalValue.map(
      (table, i) => {
        this.loadingField[i] = true;
        if(this.customModalFields[i] == null){
          this.customModalFields[i] = [];
        }
       // this.customModalFields[i] = [];
        const d = table.id.replace(/\//g, '__________');
        console.log(table);
        this.dataLineService.getFieldsByTableModal(d).subscribe(
          d => {
            this.fields[i] = d.data;
            console.log(this.ngFields);
            console.log(this.tableFieldIndex);
            this.loadingField[i] = false;
            if (this.ngFields[this.tableFieldIndex]!= undefined){
              console.log(this.ngFields[this.tableFieldIndex]);
              for (const field of this.ngFields[this.tableFieldIndex]) {
                const tab = field.id.toString().split(':')[3];
                if (tab === table.name) {
                  this.customModalFields[i].push(field);
                  this.customModalFields[i] = [...this.customModalFields[i]];
                }
              }
              console.log(this.customModalFields[i]);
            }
          },
          e => {
            console.error(e);
          }
        );
      }
    );
  }
  getTablesByObjectModal(i) {
    this.loadingTableModal = true;
    this.tableModalValue = [];
    const dd = this.ngObjects[i].id.replace(/\//g, '__________');
    console.log(dd);
    this.dataLineService.getTablesByObject(dd).subscribe(
      d => {
        this.tablesModal = d.data;
        this.loadingTableModal = false;
        if (this.ngTables[i] != undefined) {
          this.tableModalValue = [...this.ngTables[i]];
          this.getFieldsByTableModal();
        }
      },
      e => {
        console.error(e);
      },
      () => {
        this.initToolTip();
      }
    );
  }
  openCustomTableField(i) {
    this.error2 = false;
    this.customTableModalValue = [];
    this.customTableModal = [];
    this.customModalFields = [];
    this.customFields = [];
    if (this.ngCustomTable[i] != undefined) {
      $('#addExternalTableFieldTitle').html(this.lang==='en'?'Edit External Table and field':'Modifier Table externe et champs');
      this.customTableModalValue = [...this.ngCustomTable[this.tableFieldIndex]];
      this.customTableModalValue.map(
        (tab , i) => this.customFields[i] = this.customModalFields[i] = this.ngCustomsFields[this.tableFieldIndex].filter(e => e.table == tab.name)
      );
    }
    this.tableFieldIndex = i;
    $('#addExternalTableField').modal();
  }
  addCustomTableField() {
    let data = [];
    for ( let i = 0; i < this.customTableModalValue.length; i++) {
      if (this.customModalFields[i] == undefined) {
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.customTableModalValue[i].name : 'Vous devez fournir des champs pour le tableau ' + this.customTableModalValue[i].name;
        this.error2 = true;
        return;
        
      }
      if (this.customModalFields[i].length == 0) {
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.customTableModalValue[i].name : 'Vous devez fournir des champs pour le tableau ' + this.customTableModalValue[i].name;
        this.error2 = true;
        return;
        
      }
    }
    this.ngCustomTable[this.tableFieldIndex] = this.customTableModalValue;
    this.customTableModalValue.map( (table, i) => {
      this.customModalFields[i].map(e => e.table = table.name);
      data = data.concat(this.customModalFields[i]);
    });
    this.ngCustomsFields[this.tableFieldIndex] = data;
    // console.log(this.ngCustomTable[this.tableFieldIndex],this.ngCustomsFields[this.tableFieldIndex] );
    $('#addExternalTableField').modal('hide');
    this.changement = true;
  }

  closeAddTableField() {
    $('#addTableField').modal('hide');
  }
  closeAddCustomTableField() {
    $('#addExternalTableField').modal('hide');
  }
  getApplication(nodeItem) {
    return nodeItem.application != null ? nodeItem.application.name : nodeItem.customApplication.name;
  }
  async getEditorExtra() {
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.extraDescription) {
        CKEDITOR.instances.extraDescription.destroy(true);
      }
      if ( $('#extraDescription').length > 0 ) {
        const editorDescription = CKEDITOR.replace('extraDescription');
        editorDescription.config.height = 60;
        editorDescription.setData(this.extraDescription);
        editorDescription.on( 'change', evt => {
          this.extraDescription = evt.editor.getData();
          const extraItem = this.extraItems.find(e => e.id == this.clicked.id);
          extraItem.description = btoa(this.extraDescription);
          // console.log(this.extraItems);
        });
      }
    }).catch(error => console.error(error));
  }

  geTreeLine() {
    this.getTreeData();
    $('#LineageTreeModal').modal('show');
    setTimeout(() => {this.simulation = renderTreeLine(this.modalData); }, 500);
  }
  unifyObject(clicked: NodeItem) {
    const ids = [];
    const treeData = [];
    if (clicked.data.length > 0) {
      clicked.data.map(item => {
        const data = [];
        if (ids.indexOf(item.model.id) == -1) {
          clicked.data.filter(i => i.model.id == item.model.id).map(
            j => {
              data.push({object : j.object, children: j.children});
            }
          );
          ids.push(item.model.id);
          treeData.push({model: item.model, data, color: clicked.application.color, title: clicked.text});
        }
      });
    } else {
      clicked.customs.map(item => {
        const data = [];
        if (ids.indexOf(item.model) == -1) {
          clicked.customs.filter(i => i.model == item.model).map(
            j => {
              data.push({object : j.object, children: j.children});
            }
          );
          ids.push(item.model);
          treeData.push({model: item.model, data, color: clicked.customApplication!=null? clicked.customApplication.color: clicked.application.color, title: clicked.text});
        }
      });
    }
    return treeData;
  }
  getTreeData() {
    let i = 1;
    let k = 1;
    const nodes = [];
    const links = [];
    const data = [];
    // console.table(this.nodesItems);
    this.nodesItems.map(n => data.push(this.unifyObject(n)));
    // console.log(data);
    // tree node
    data.map(
      (nodeItem, j) => {
        nodeItem.map(
          m => {
            const modIndex = i;
            const modNode = {id: modIndex, name: m.model.name, idModel: m.model.id, type: 'model', group: j, color: m.color, title: m.title};
            nodes.push(modNode);
            // object loop
            m.data.map(
              det => {
                i++;
                const objIndex = i;
                const objNode = {id: objIndex, name: det.object.name, idObject: det.object.id, type: 'object', group: j , color: m.color, title: m.title};
                const linkObj = {id: k, source : modIndex , target: objIndex};
                nodes.push(objNode);
                links.push(linkObj);
                // table loop
                det.children.map(
                  t => {
                    i++;
                    k++;
                    const tabIndex = i;
                    const tabNode = {id: tabIndex, name: t.table.name, idTable: t.table.id, type: 'table', group: j , color: m.color, title: m.title};
                    const linkTab = {id: k, source : objIndex , target: tabIndex};
                    nodes.push(tabNode);
                    links.push(linkTab);
                    // fields loop
                    t.fields.map(
                      f => {
                        i++;
                        k++;
                        const fieldNode = {id: i, name: f.name, idField: f.id, type: 'field', group: j , color: m.color, title: m.title};
                        const linkField = {id: k, source : tabIndex , target: i};
                        nodes.push(fieldNode);
                        links.push(linkField);
                      }
                    );
                  }
                );
                k++;
              }
            );
            i++;
          }
        );
      }
    );
    this.modalData = {links, nodes};
    // console.log(nodes);
  }

  removeEdge() {
    // console.log(this.selectedEdge);
    const group = this.selectedEdge.group;
    const links = this.edges.filter(e => e.group == group);
    if ( links.length > 0) {
      links.map(
        l => l.element.remove()
      );
    }
    this.edges = this.edges.filter(e => e.group != group);
    this.linesArch = this.linesArch.filter(e => e.group != group);
    if (this.clicked != null && this.clicked.data != undefined) {
      this.getEdges(this.clicked);
    }
    this.selectedEdge = null;
  }
  removeEdgeTerm() {
    const group = this.selectedEdge.group;
    const links = this.edges.filter(e => e.group == group);
    if ( links.length > 0) {
      links.map(
        l => l.element.remove()
      );
    }
    this.edges = this.edges.filter(e => e.group != group);
    if (this.clicked != null && this.clicked.status != undefined) {
      this.getEdgesTerm();
    }
    this.selectedEdge = null;
    this.clicked = null;
  }
  highLightEdge() {
    console.log(this.selectedEdge);
    this.selectedStatusLink = this.selectedEdge.status;
    this.edges.map(
      l => {
        // l.element.outline = false;
        l.element.dash = false;
      }
    );
    // this.selectedEdge.element.outline = true;
    this.selectedEdge.element.dash = {animation: true};
    this.selectedEdge.element.setOptions({
      outlineColor: 'rgb(1,7,12)'
    });
    this.selectedLinkDescription = this.selectedEdge.middleLabel;
    this.selectedLinkDescriptionColor = this.selectedEdge.labelColor;
    this.selectedLinkDescriptionSize = this.selectedEdge.labelSize;
    this.selectedLinkDescriptionStyle = this.selectedEdge.labelStyle;
    this.selectedLinkDescriptionWeigt = this.selectedEdge.labelweight;
    this.selectedLinkPath = this.selectedEdge.path;
    this.selectedLinkShadow = this.selectedEdge.dropShadow;
  }
  highLightEdgeOver(){
    this.edges.map(
      l => {
        // l.element.outline = false;
        l.element.dash = false;
      }
    );
    console.log(this.clickedEdges);
    // this.selectedEdge.element.dash = {animation: true};
  }
  highLightEdgeOut(){
    this.edges.map(
      l => {
        // l.element.outline = false;
       // l.element.dash = false;
      }
    );
  }
  getEdgesForRemove(node: NodeItem) {
    this.selectedEdges = [];
    if (node.data.length > 0) {
      for(let i = 0; i < node.data.length; i++){
        const startObject = 'start_' + node.data[i].object.id;
        const endObject = 'end_' + node.data[i].object.id;
        const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject));
        if (objectEdges.length > 0) {
          this.selectedEdges = this.selectedEdges.concat(objectEdges);
        }
        if (node.data[i].children.length > 0) {
          for(let j = 0; j < node.data[i].children.length; j++){
            for(let z = 0; z < node.data[i].children[j].fields.length; z++){
              const startField = 'start_' + node.data[i].children[j].fields[z].id;
              const endField = 'end_' + node.data[i].children[j].fields[z].id;
              const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField));
              if (fieldEdges.length > 0) {
                this.selectedEdges = this.selectedEdges.concat(fieldEdges);
              }
            }
          }

        }
      }
    }
    if (node.customs.length > 0) {
      for(let i = 0; i < node.customs.length; i++){
        const startObject = 'start_' + node.customs[i].object.id;
        const endObject = 'end_' + node.customs[i].object.id;
        const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
        if (objectEdges.length > 0) {
          this.selectedEdges = this.selectedEdges.concat(objectEdges);
        }
        if (node.customs[i].children.length > 0) {
          for( let j = 0; j < node.customs[i].children.length; j++){
            for( let z= 0; z < node.customs[i].children[j].fields.length; z++){
              const startField = 'start_' + node.customs[i].children[j].fields[z].id;
              const endField = 'end_' + node.customs[i].children[j].fields[z].id;
              const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField));
              if (fieldEdges.length > 0) {
                this.selectedEdges = this.selectedEdges.concat(fieldEdges);
              }
            }
          }
        }
      }
    }
    console.log(this.selectedEdges);
    if (this.selectedEdges.length > 0) {
      for(let i = 0; i < this.selectedEdges.length; i++) {
        if (this.selectedEdges[i].element != undefined) {
          this.selectedEdges[i].element.remove();
          this.edges = this.edges.filter(e => e.group != this.selectedEdges[i].group);
          this.linesArch = this.linesArch.filter(e => e.group != this.selectedEdges[i].group);
        }
      }
    }
  }
  getEdgesForRemoveProcess(node: ExtraItem) {
    this.selectedEdges = [];
    console.log(node);
    console.log(node.connectors);
    node.connectors.map(
      con => {
        const edges = this.edges.filter( e => e.source == con.id || e.target == con.id );
        console.log(edges);
        if (edges.length > 0 ) {
          edges.map(l => {
            this.edges = this.edges.filter(e => e.source != con.id && e.target != con.id);
            l.element.remove();
            this.linesArch = this.linesArch.filter(e => e.line.source != con.id && e.line.target != con.id);
          });
        }
      }
    );
  }
  getEdgesForRemoveSynonym(synonym: Synonym) {
    this.selectedEdges = [];
    const target = 'end_' + synonym.cid;
    const edges = this.edges.filter( e => e.source == target || e.target == target );
    if (edges.length > 0 ) {
      this.edges = this.edges.filter(e => e.source != target && e.target != target);
      edges.map(l => {
        l.element.remove();
        // this.linesArch = this.linesArch.filter(e => e.line.source != synonym.id && e.line.target != synonym.id);
      });
    }
  }
  detectDouble(t: any, index){
    const start = 'start_' + t.id;
    const end = 'end_' + t.id;
    if(($('#' + $.escapeSelector(start)).length > 0 || $('#' + $.escapeSelector(end)).length > 0 || this.objectUseds.find(e => e == t.id)!= null) && this.ngObjects.filter(e => e.id == t.id).length > 1){
       t = t.id.split(':');
       Swal.fire({
        title: this.lang === 'en' ? 'Error!' : 'Erreur!',
        text: this.lang === 'en' ? 'Object ' + t[2] + ' of catalog ' + t[1] + ' and system ' + t[0] + ' is already used' : 'L\'objet ' + t[2] + ' du catalogue ' + t[1] + ' et du système ' + t[0] + ' est déjà utilisé',
        icon: 'error',
        confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
       });
       
       this.ngObjects[index] = null;
    } else {
      this.ngTables[index] = [];
      this.ngFields[index] = [];
      this.objectUseds.push(t.id);
    }
  }
  getCatalogueLineage(id: string) {
    this.businessTermService.getCatalogueLineage(id).subscribe(
      d => {
        this.importData = d.catalogue;
        if (this.importData != null) {
          this.importData = atob(this.importData);
          this.importData = JSON.parse(this.importData);
        }
        // console.log(this.importData.content);
        // console.log(this.importData);
        if (this.importData != null) {
          this.importNodes();
        } else {
          removeLoader();
        }
        $('#spinner_getListApprovedObject').hide();
      },
      err => {
        console.log(err);
      }
    );
  }
  async importNodes() {
    $('body').css('position', '');
    this.nodesItems = [];
    this.extraItems = [];
    this.linesArch = [];
    this.edges = [];
    for (let i = 0; i < this.importData.content.nodes.length; i++) {
      const item = new NodeItem(this.importData.content.nodes[i].id, this.importData.content.nodes[i].icon, this.importData.content.nodes[i].text, this.importData.content.nodes[i].application, this.importData.content.nodes[i].extern, this.importData.content.nodes[i].catalogue, this.importData.content.nodes[i].data, this.importData.content.nodes[i].description, this.importData.content.nodes[i].customApplication, 'black', this.importData.content.nodes[i].customs, this.importData.content.nodes[i].applicationType, this.importData.content.nodes[i].trigger, this.importData.content.nodes[i].type, this.importData.content.nodes[i].style);
      this.addHtmlContent(item);
    }
    $('.collapse-lg').toggle();
    for (let j = 0; j < this.importData.content.extra.length; j++) {
      const item = new ExtraItem(this.importData.content.extra[j].id, this.importData.content.extra[j].description, this.importData.content.extra[j].connectors, this.importData.content.extra[j].type, this.importData.content.extra[j].style, this.importData.content.extra[j].trigger, this.importData.content.extra[j].lines);
      this.addExtraHtml(item);
    }

    const links = this.importData.content.links.filter(nd => nd.parent);
    console.log(links);
    // // $('#' + $.escapeSelector(links[0].source)).click().focus();
    // // $('#' + $.escapeSelector(links[0].target)).click().focus();
    for (let i = 0; i < links.length; i++) {
      $('#' + $.escapeSelector(links[i].source)).click().focus();
      await this.sleep(500);
      $('#' + $.escapeSelector(links[i].target)).click().focus();
      // setTimeout(() => {$('#' + $.escapeSelector(links[i].target)).click().focus(); }, 300);
    }
    // for(let k = 0; k < this.importData.content.links.length; k++){
    //   this.addLine(this.importData.content.links[k].source, this.importData.content.links[k].target, this.importData.content.links[k].startColor, this.importData.content.links[k].endColor, this.importData.content.links[k].hide, this.importData.content.links[k].parent, this.importData.content.links[k].sourceName, this.importData.content.links[k].targetName, this.importData.content.links[k].group);
    // }
    // this.importData.content.links.map(nd => {
    //   // $('#' + $.escapeSelector(nd.source)).click().focus();
    //   // setTimeout(() => {$('#' + $.escapeSelector(nd.target)).click().focus(); }, 1000);
    //  this.addLine(nd.source, nd.target, nd.startColor, nd.endColor, nd.hide, nd.parent, nd.sourceName, nd.targetName, nd.group);
    // });
    $('#play-area').click();
    for (let i = 0; i < this.edges.length; i++) {
      const lin = links.find(e => e.source == this.edges[i].source && e.target == this.edges[i].target);
      if(lin!= null ){
        this.edges[i].middleLabel = lin.middleLabel;
        this.edges[i].labelColor = lin.labelColor;
        this.edges[i].labelSize = lin.labelSize;
        this.edges[i].labelStyle = lin.labelStyle;
        this.edges[i].labelWeight = lin.labelWeight;
        this.edges[i].element.setOptions({
          middleLabel: LeaderLine.captionLabel(lin.middleLabel, {color: lin.labelColor, fontSize: lin.labelSize, fontStyle: lin.labelStyle, fontWeight: lin.labelWeight}),
        });
      }
    }
    // for(let z = 0; z < this.importData.content.lineArch.length; z++){
    //   const line = this.edges.find(e => e.source == this.importData.content.lineArch[z].start && e.target == this.importData.content.lineArch[z].end).element;
    //   const parent = this.edges.find(e => e.source == this.importData.content.lineArch[z].source && e.target == this.importData.content.lineArch[z].target).element;
    //   const leftParent = this.edges.find(e => e.source == this.importData.content.lineArch[z].start && e.target == this.importData.content.lineArch[z].target).element;
    //   const rightParent = this.edges.find(e => e.source == this.importData.content.lineArch[z].end && e.target == this.importData.content.lineArch[z].source).element;
    //   this.linesArch.push({line , parent , source: this.importData.content.lineArch[z].source, target: this.importData.content.lineArch[z].target, leftParent , rightParent, groupId: this.importData.content.lineArch[z].groupId, start: this.importData.content.lineArch[z].start, end: this.importData.content.lineArch[z].end});
    // }
    // console.log(this.linesArch);
    // this.importData.content.lineArch.map(nd => {
    //   //  const line = this.addLine(this.startConnector, this.endConnector, this.startColor, that.endColor, false, true, that.startName, that.endName, groupId);
    //   //  const parentLine = this.addLine(this.startParent, this.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
    //   //  const leftLine = this.addLine(this.startConnector, this.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
    //   //  const rightLine = this.addLine(this.endConnector, that.startParent, that.endColor, that.startColor, true, false, that.startName, that.endName, groupId);
    //   // that.linesArch.push({line, parent: parentLine, source: this.startParent, target: that.endParent, leftParent: leftLine, rightParent: rightLine, groupId, start: that.startConnector, end : that.endConnector, startParent: that.startParent, endParent: that.endParent});
    //    const line = this.edges.find(e => e.source == nd.start && e.target == nd.end).element;
    //    const parent = this.edges.find(e => e.source == nd.source && e.target == nd.target).element;
    //    const leftParent = this.edges.find(e => e.source == nd.start && e.target == nd.target).element;
    //    const rightParent = this.edges.find(e => e.source == nd.end && e.target == nd.source).element;
    //    this.linesArch.push({line , parent , source: nd.source, target: nd.target, leftParent , rightParent, groupId: nd.groupId, start: nd.start, end: nd.end});
    // });
    this.updateConnectors();
    this.flatDesign = this.importData.content.styles.flatDesign;
    if (this.flatDesign) {
      if ($('.node-item').length > 0) {
        $('.node-item').removeClass('shadow-design');
      }
    } else {
      if ($('.node-item').length > 0) {
        $('.node-item').addClass('shadow-design');
      }
    }

    this.pattern = this.importData.content.styles.pattern;
    this.changePattern(this.pattern);
    this.zoom = this.importData.content.styles.zoom;
    removeLoader();
  }
  addHtmlContent(nodeItem: NodeItem) {
    $('body').css('position', '');
    const that = this;
    const id = nodeItem.id;
    const childId = 'childs_' + id;
    const headerId = 'header_' + id;
    // const back = ['bg-info', 'bg-success', 'bg-primary', 'bg-danger', 'bg-secondary'];
    let bgColor = '#000000';
    if (nodeItem.application != undefined) {
      bgColor = nodeItem.application.color;
    }
    if (nodeItem.customApplication != undefined) {
      bgColor = nodeItem.customApplication.color;
    }
    //  console.log(nodeType, nodeText);
    let objectsHtml = '';
    if (!nodeItem.extern) {
      if (nodeItem.catalogue) {
        nodeItem.data.map(obj => {
          if (obj.children != undefined) {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '" node-id="' + id + '" node-icon="' +  nodeItem.icon + '"extra-data="' +  this.getExtraData(obj.object) + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
            if (obj.children.length > 0 ) {
              // objectsHtml += '<li style="background-color: white;border-top:0" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
              objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" node-icon="' +  nodeItem.icon + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" node-icon="' +  nodeItem.icon + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
                '<ul class="list-group list-group-flush text-black collapse-lg" style="border-top: 1px solid #f3dcdc">' + this.getTables(nodeItem, obj.object.id, obj.children, id) + '</ul>';
            }
          } else {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '" node-id="' + id + '" node-icon="' +  nodeItem.icon + '"extra-data="' +  this.getExtraData(obj.object) + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
          }
        });
      } else {
        nodeItem.customs.map(obj => {
          if (obj.children != undefined ) {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '" node-id="' + id + '" node-icon="' +  nodeItem.icon + '"extra-data="' +  this.getExtraData(obj.object) + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
            if (obj.children.length > 0 ) {
              objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.name + '" node-icon="' +  nodeItem.icon + '" style="float: left;color:#31b7f9;cursor:pointer;display:none"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.name + '" node-icon="' +  nodeItem.icon + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
                '<ul class="list-group list-group-flush text-black  collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(nodeItem, obj.object.id, obj.children, id) + '</ul>';
            }
          } else {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '" node-id="' + id + '" node-icon="' +  nodeItem.icon + '"extra-data="' +  this.getExtraData(obj.object) + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
          }
        });
      }
    } else {
      nodeItem.customs.map(obj => {
        if (obj.children != undefined ) {
          objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '" node-id="' + id + '" node-icon="' +  nodeItem.icon + '"extra-data="' +  this.getExtraData(obj.object) + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
          if (obj.children.length > 0 ) {
            objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" node-icon="' +  nodeItem.icon + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
              '<ul class="list-group list-group-flush text-black  collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(nodeItem, obj.object.id, obj.children, id) + '</ul>';
          }
        } else {
          objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '" node-id="' + id + '" node-icon="' +  nodeItem.icon + '"extra-data="' +  this.getExtraData(obj.object) + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object + '</b></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
        }
      });
    }
    this.tabIndex++;
    const html = this.getNodeStyle() + bgColor + '" id="' + id + '" tabindex="' + this.tabIndex + '">\n' +
      '<span class="fa-stack" style="align-self: center;font-size: 1.2em;padding-top: 1.3px"><i class="fas fa-circle fa-stack-2x" style=";vertical-align: middle; color:' + bgColor + '"></i> ' + this.getImageFromType(nodeItem.icon, bgColor) + '</span>' +
      '<div class="card-header text-center text-white node-header"  style="background-color: rgb(60 172 196);border-top-left-radius: 23px;\n' +
      '    border-top-right-radius: 23px;text-transform: capitalize" id="' + headerId + '">' + nodeItem.text + '</div>\n' +
      // '      </div>\n' +
      '      <ul class="list-group list-group-flush text-black node-details flat-details" id="' + childId + '" >\n' + objectsHtml +
      '      </ul>\n' +
      '      <div class="card-body tools-line tools-line-flat">\n' +
      '        <span class="remove-drag" data-item="' + id + '"><i class="fas fa-times-circle edit-remove"></i></span>\n' +
      '        <span class="add-drag" data-item="' + id + '"><i class="fas fa-pencil-alt edit-remove"></i></span>\n' +
      '        <span style="float:right">&nbsp;' + this.getApplication(nodeItem) + '</span>\n' +
      '      </div>\n' +
      '      <div id="line-wrapper"></div>\n' +
      '    </div>';
    $('#play-area').append(html);
    // $('.tree-modal').off().on('click', function(e) {
    //   e.stopPropagation();
    //   $('#LineageTreeModal').modal('show');
    //   const clicked = that.nodesItems.find(t => t.id == $(this).attr('data-item') );
    //   that.getNodeData(clicked);
    //   setTimeout(() => {renderTreeLine(that.modalData);}, 500);
    // });
    this.resizeObserver.disconnect();
    this.resizeObserver.observe(document.getElementById(id));
    $('.show-node-Item').off().on('click', function() {
      $(this).parent().next().toggle();
      const p = $(this).attr('data-object');
      const start = 'start_' + p;
      const end = 'end_' + p;
      const childLines = that.linesArch.filter(item => item.source == start || item.target == start || item.source == end || item.target == end);
    //  console.log(childLines);
      // console.log(that.linesArch);
      if ($(this).hasClass('fa-angle-double-down') ) {
        $(this).removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        if (childLines != undefined) {
          childLines.map(l => {
            if ( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') == 'none') {
              l.line.hide('none'); l.parent.show('none'); l.leftParent.hide('none'); l.rightParent.hide();
            }
            if ( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') != 'none') {
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.show('none');
            }
            if ( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') == 'none') {
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.show('none'); l.rightParent.hide('none');
            }
            if ( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') != 'none') {
              l.line.show('none'); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.hide('none');
            }
          });
        }
        that.updateConnectors();
        if ($(this).next('i').length > 0 ) {
          $(this).next('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        }
        if ($(this).prev('i').length > 0 ) {
          $(this).prev('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        }
      } else {
        $(this).removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        if (childLines != undefined) {
          childLines.map(l => {
            if ( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') == 'none') {
              l.line.hide('none'); l.parent.show('none'); l.leftParent.hide('none'); l.rightParent.hide('none');
            }
            if ( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') != 'none') {
              l.line.hide(); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.show('none');
            }
            if ( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') == 'none') {
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.show('none'); l.rightParent.hide('none');
            }
            if ( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') != 'none') {
              l.line.hide('none'); l.parent.show('none'); l.leftParent.hide('none'); l.rightParent.hide('none');
            }
          });
        }
        that.updateConnectors();
        if ($(this).next('i').length > 0 ) {
          $(this).next('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        }
        if ($(this).prev('i').length > 0 ) {
          $(this).prev('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        }
      }
    });
    that.enableLinking();
    this.makeDraggable(id);
    window.scrollBy(5, 100);
    window.scrollBy(-5, -100);
    setTimeout(() => {that.showToolTip('.goldenSourceDisable'); $('.goldenSourceDisable').unbind('click').on('click', function(event) {
     event.stopPropagation();
     $(this).toggleClass('goldenSourceEnable');
     const nodeLink = $($(this).parent().children().get(0));
     const id = nodeLink.attr('node-id');
     let table = nodeLink.attr('id').split('start_')[1].split(':');
     // console.log(table);
     table = table[0] + ':' + table[1] + ':' + table[2] + ':' + table[3];
     const node = that.nodesItems.find(e => e.id === id);
     console.log(node);
     const objectLink = nodeLink.attr('data-parent');
     // console.log(objectLink);
     node.data.forEach(e => {
       if(e.object.id === objectLink){
         e.children.forEach(f => {
           if(f.table.id == table){
             f.fields.forEach(fd => {
               if(fd.id == nodeLink.attr('id').split('start_')[1]){
                 fd.goldSource = $(this).hasClass('goldenSourceEnable');
               }
             });
           }
         });
       }
     });
     // console.log(node.data);
    });},  700);
    this.nodesItems.push(nodeItem);
//    console.log(this.nodesItems);
    $('#addLineageModalClose').click();
    $('#' + id).offset(nodeItem.style).on('click', function(event) {
      event.stopPropagation();
      if (that.clicked != null) {
        if (that.clicked.id == $(this).attr('id')) {
          return;
        }
      }
      $('#play-area').click();
      $('#infosTab').click();
      $('.selected-node').removeClass('selected-node');
      $('.selected-term').removeClass('selected-term');
      $(this).addClass('selected-term').focus();
     // $('.business-item').removeClass('selected-node');
      $(this).addClass('selected-node').focus();
      //  console.log($(this).attr('id') );
      that.clickedEdges = [];
      that.clicked = that.nodesItems.find(t => t.id == $(this).attr('id') );
      //  console.log(that.clicked);
      that.getNodeData(that.clicked);
      that.getEdges(that.clicked);
      // end tree node
      // console.log(that.orgData);

      // const nodeTemplate = (data) => {
      //   return `
      //     <span class="icon-org">${data.icon}</span>
      //     <div class="title">${data.name}</div>
      //     <div class="content">${data.title}</div>
      //   `;
      // };
      //
      // let htmk= '';
      // that.orgData.map(
      //   (u,i) => { htmk += '<div id="org-container_' + i + '" style="position: relative;height: 420px;border: 1px solid #aaa;margin: 0.5rem;text-align: center;overflow:hidden" ></div>'; }
      // );
      // setTimeout(() => {
      //
      //   $('#chart-container').empty().append(htmk);
      //   that.orgData.map(
      //     (u, i) => {
      //      // console.log(u);
      //      // console.log(i);
      //       const oc = $('#org-container_' + i).orgchart({
      //         data : u,
      //         nodeContent: 'title',
      //         nodeTemplate,
      //         pan: true,
      //         zoom: true
      //       });
      //       oc.$chartContainer.on('touchmove', function(event) {
      //         event.preventDefault();
      //       });
      //     }
      //   );
      // }, 1000);

    }).keydown(function(e) {
      if (e.key === 'Delete') {
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Data Object?' : 'SUPPRIMER l\'objet de données ?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id');
            const nodeItem = that.nodesItems.find(e => e.id == id);
            that.getEdgesForRemove(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.nodesItems.splice(that.nodesItems.indexOf(that.nodesItems.find( e => e.id == id)), 1) ;
              $('#play-area').click();
            }
          }
        });
      }
    });
    this.targets.push({name: nodeItem.text, id});
    this.targets = [...this.targets];
    // this.initConnectors();
    $('.card-body').off().on('click', '.remove-drag', function(e) {
      Swal.fire({
        title: this.lang === 'en' ? 'DELETE Data Object?' : 'SUPPRIMER l\'objet de données ?',
        text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
       }).then((result) => {
        if (result.value) {
          const id = $(this).attr('data-item');
          const nodeItem = that.nodesItems.find(e => e.id == id);
          that.getEdgesForRemove(nodeItem);
          const t = that.draggables.find(item => item.id == id);
          if (t != undefined) {
            t.element.remove();
            that.draggables = that.draggables.filter(item => item.id != id);
            $('#' + id).remove();
            that.nodesItems.splice(that.nodesItems.indexOf(that.nodesItems.find( e => e.id == id)), 1) ;
            $('#play-area').click();
          }
        }
      });
    }).on('click', '.add-drag', function() {
      that.changement = false;
      that.selectedId = $(this).attr('data-item');
      const nodeItem = that.nodesItems.find(e => e.id === that.selectedId);
      that.editMode = true;
      that.resetModelObjectTableFields();
      that.resetCustomModelObject();
      that.modelAndObjectAndTableAndFields = [];
      that.customModelAndObjects = [];
      that.ngNodeText = nodeItem.text;
      that.ngExtern = nodeItem.extern;
     // that.ngTrigger = nodeItem.trigger;
      that.choosenIcon = nodeItem.icon;
      if(nodeItem.applicationType!= null){
        that.ngTypeApp = nodeItem.applicationType.id;
      }
      if(nodeItem.application!= null){
        that.ngApp = nodeItem.application;
        that.getModelsByApp();
      }
      that.ngCatalogue = nodeItem.catalogue;
      that.getFieldsByTableModal();
      console.log(nodeItem);
      // bind data to from catalogue model,object,table and fields
      if(nodeItem.data.length > 0) {
        nodeItem.data.map(
          (item , i) => {
            that.modelAndObjectAndTableAndFields.push(i);
            that.ngModels[i] = item.model;
            that.getObjectsByModel(i);
            that.ngObjects[i] = item.object;
            that.ngTables[i] = [];
            that.ngFields[i] = [];
            console.log(i, item.object);
            if(item.children.length > 0) {
              item.children.map(
                tab => {
                  that.ngTables[i].push(tab.table);
                  console.log(i, that.ngFields);
                  const tr = [];
                  for (const tf of tab.fields) {
                    const t = {id: tf.id, name: tf.name, description: tf.description, table: tab.table.name};
                    tr.push(t);
                  }
                  that.ngFields[i] = that.ngFields[i].concat(tr);
                  console.log(tab.fields);
                }
              );
            }
          }
        );
      }
      if(nodeItem.customApplication!= null){
        that.ngCustomApplication = nodeItem.customApplication;
      }
      // bind data to from catalogue model,object,table and fields
      if(nodeItem.customs.length > 0) {
        nodeItem.customs.map(
          (item , i) => {
            that.customModelAndObjects.push(i);
            that.ngCustomModel[i] = item.model.name;
            that.ngCustomObjects[i] = item.object.name;
            that.ngCustomTable[i] = [];
            if(item.children.length > 0) {
              item.children.map(
                tab => {
                  that.ngCustomTable[i].push({id: tab.table, name: tab.table});
                  that.ngCustomsFields[i] = tab.fields;
                }
              );
            }
          }
        );
      }
      nodeItem.description = nodeItem.description.replace(/ /g, '+');
      that.ngDescription = atob(nodeItem.description);

      $('#addLineageModal').modal('show');
      console.log(that.ngFields);
      if(nodeItem.data.length > 0) {
        nodeItem.data.map(d => {
          if(d.children.length > 0) {
            d.children.map(
              tab => {
                that.ngFields.map(e => {
                  e.map(z => {
                    if(tab.table.id + z.id.toString().split(':')[4] === z.id) {
                      if(tab.fields.findIndex(s => s.id === z.id) === -1) {
                        tab.fields.push(z);
                      }
                    }
                  });
                });
              }
            );
          }
        });
      }
    });
  }
  decryptDescr(t: string) {
    try {
      return atob(t.replace(/\s/g, ""));
    }
    catch(err) {
      return '';
    }

  }
  getSynonymColor(type: string){
    let color = '';
    switch(type){
      case 'synonym term': color = ' background-color: rgb(27,77,87);border-bottom-left-radius: 10px;border-top-right-radius: 10px;'; break;
      case 'synonym object': color = ' background-color: rgb(43,127,144);border-bottom-left-radius: 10px;border-top-right-radius: 10px;'; break;
      case 'antonym object': color = ' background-color: rgb(43,127,144);border-top-left-radius: 10px;border-bottom-right-radius: 10px;'; break;
      case 'antonym term': color = ' background-color: rgb(27,77,87);border-top-left-radius: 10px;border-bottom-right-radius: 10px;'; break;
      case 'hyperonym object': color = ' background-color: rgb(43,127,144);border-radius: 10px;'; break;
      case 'hyperonym term': color = ' background-color: rgb(27,77,87);border-radius: 10px;'; break;
    }
    return color;
  }
  getIconColor(type: string){
    let color = '';
    switch(type){
      case 'antonym object':
      case 'hyperonym object':
      case 'synonym object': color = ' color: rgb(43,127,144);'; break;
      case 'antonym term':
      case 'hyperonym term':
      case 'synonym term': color = ' color: rgb(27,77,87);'; break;
    }
    return color;
  }
  getIconType(type: string){
    let icon = '';
    switch(type){
      case 'synonym term':
      case 'synonym object': icon = '<i class="fas fa-exchange-alt fa-stack-1x fa-inverse fa-middle">'; break;
      case 'antonym term':
      case 'antonym object': icon = '<i class="fas fa-not-equal fa-stack-1x fa-inverse fa-middle">'; break;
      case 'hyperonym term':
      case 'hyperonym object': icon = '<i class="fas fa-recycle fa-stack-1x fa-inverse fa-middle">'; break;
    }
    return icon;
  }
  getSynonymClass(type: string){
    let clas = '';
    switch(type){
      case 'synonym term': clas = 'synonym-term-line'; break;
      case 'synonym object': clas = 'synonym-object-line'; break;
      case 'antonym term': clas = 'antonym-term-line'; break;
      case 'antonym object': clas = 'antonym-object-line'; break;
      case 'hyperonym term': clas = 'hyperonym-term-line'; break;
      case 'hyperonym object': clas = 'hyperonym-object-line'; break;
    }
    return clas;
  }
  addSynonymHtml(synonym: Synonym) {
    $('body').css('position', '');
    if(this.dataSource.synonyms.find(t => t.cid == synonym.cid)!= null){
      return;
    }
    const that = this;
    const synonymElement = document.createElement('div');
    this.tabIndex++;
    $(synonymElement).addClass('node-item lg-synonym').attr('id', synonym.cid).css({'min-width': '170px', position: 'absolute','font-size': '11px'}).attr('tabindex', this.tabIndex).html('<div class="card" style="background: transparent" data-color="rgb(97,180,232)" tabindex="' + this.tabIndex + '">\n' +
      '<span class="fa-stack" style="align-self: center;font-size: 1.2em;padding-top: 1.3px"><i class="fas fa-circle fa-stack-2x" style="vertical-align: middle;' + this.getIconColor(synonym.type) + '"></i> ' + this.getIconType(synonym.type) + '</i></span><div class="card-header text-center text-white node-header" id="synonym_header_' + synonym.cid + '" style="\n' +
    this.getSynonymColor(synonym.type) +
    // '    background-color: rgb(118,116,119); \n' +
      '    text-transform: capitalize;">\n' +
      '    ' + synonym.name +
      '    <i class="fas fa-edit edit-term" data-id="' + synonym.cid + '" style="cursor: pointer;float: right;padding-right: 5px" data-title="edit this ' + synonym.type + '"></i></div>\n' +
      '      <div></div>\n' +
      '    </div>\n' +
      '<i class="fas fa-circle connector end ' + this.getSynonymClass(synonym.type) + '" id="end_' + synonym.cid + '" data-name="' + synonym.name + '" node-icon="' + synonym.type + '"></i>');
    if (!this.flatDesign) {
      $(synonymElement).addClass('shadow-design');
    }
    document.getElementById('play-area').appendChild(synonymElement);
    window.scrollBy(5, 100);
    window.scrollBy(-5, -100);
    const connectors = [];
    connectors.push({id: 'end_' + synonym.cid});
    this.dataSource.synonyms.push(synonym);
  //  this.synonymItems.push(synonym);
    this.makeDraggable(synonym.cid);
    this.enableLinking();
    this.enableEditing();
    $(synonymElement).offset(synonym.style).on('click', function(e) {
      e.stopPropagation();
      if (that.clicked != null) {
        if (that.clicked.cid == $(this).attr('id')) {
          return;
        }
      }
      $('#play-area').click();
      $('#infosTab').click();
      that.clickedEdges = [];
      const id = $(this).attr('id');
      $('.selected-node').removeClass('selected-node');
      // $('.business-item').removeClass('selected-node');
      $('.selected-term').removeClass('selected-term');
      $(this).addClass('selected-node').focus();
      $(this).addClass('selected-term').focus();
      console.log(that.dataSource.synonyms);
      that.clicked = that.dataSource.synonyms.find(e => e.cid == id);
      if(that.clicked != null){
        if(that.clicked.effective.date!=null){
          that.clicked.effective = that.clicked.effective.date.toString().substr(0 , 10);
        }
        if(that.clicked.description!= undefined) {
          that.synonymDescription = atob(that.clicked.description);
        }
        that.getEdgesSynonym();
      }

      //that.getEdgesTerm();

     // setTimeout(() => {that.getEditorExtra(); }, 500);
    }).keydown(function(e) {
      if (e.key === 'Delete') {
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Synonym Object?' : 'SUPPRIMER l\'objet de synonyme ?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
         }).then((result) => {
          if (result.value) {
            const id = $(this).attr('data-id');
            const synonymItem = that.dataSource.synonyms.find(e => e.cid == id);
            that.getEdgesForRemoveSynonym(synonymItem);
            const t = that.draggables.find(item => item.cid == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.cid != id);
              $('#' + id).remove();
              that.dataSource.synonyms.splice(that.synonymItems.indexOf(that.synonymItems.find( e => e.cid == id)), 1) ;
              $('#play-area').click();
            }
          }
        });
      }
    });
    $('.delete-synonym').on('click', function() {
      Swal.fire({
        title: this.lang === 'en' ? 'DELETE Synonym Object?' : 'SUPPRIMER l\'objet de synonyme ?',
        text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
       }).then((result) => {
        if (result.value) {
          const id = $(this).attr('data-id');
          const synonymItem = that.dataSource.synonyms.find(e => e.cid == id);
          that.getEdgesForRemoveSynonym(synonymItem);
          const t = that.draggables.find(item => item.cid == id);
          if (t != undefined) {
            t.element.remove();
            that.draggables = that.draggables.filter(item => item.cid != id);
            $('#' + id).remove();
            that.dataSource.synonyms.splice(that.synonymItems.indexOf(that.synonymItems.find( e => e.cid == id)), 1) ;
            $('#play-area').click();
          }
        }
      });
    });
  }
  enableEditing(){
    const that = this;
    $('.edit-term').on('click', function(event) {
      event.stopPropagation();
      const id = $(this).attr('data-id');
      console.log(id);
      console.log(that.dataSource.businessTerms);
      that.selectedItem = that.dataSource.businessObjects.find(e => e.cid == id) != null ? that.dataSource.businessObjects.find(e => e.cid == id) : that.dataSource.businessTerms.find(e => e.cid == id) != null ? that.dataSource.businessTerms.find(e => e.cid == id) : that.dataSource.synonyms.find(e => e.cid == id);
      if (that.selectedItem != null) {
        that.editMode = true;
        that.businessType = that.selectedItem.type;
        console.log(that.selectedItem);
        that.businessTermFrm.get('name').patchValue(that.selectedItem.name);
        that.businessTermFrm.get('businessId').patchValue(that.selectedItem.businessId);
        that.businessTermFrm.get('type').patchValue(that.selectedItem.type);
        that.businessTermFrm.get('effective').patchValue(that.selectedItem.effective.toString().substr(0, 10));
        that.businessTermFrm.get('status').patchValue(that.selectedItem.status);
        that.businessTermFrm.get('definition').patchValue(that.selectedItem.definition);
        that.businessTermFrm.get('example').patchValue(that.selectedItem.example);
        //that.businessTermFrm.get('completion').patchValue(that.selectedItem.completion);
        that.businessTermFrm.get('objects').patchValue(that.selectedItem.objects != undefined ? that.selectedItem.objects : null);
        that.businessTermFrm.get('terms').patchValue(that.selectedItem.terms != undefined ? that.selectedItem.terms : null);
        that.businessTermFrm.get('synonyms').patchValue(that.selectedItem.synonyms != undefined ? that.selectedItem.synonyms : null);
        that.businessTermFrm.get('antonyms').patchValue(that.selectedItem.antonyms != undefined ? that.selectedItem.antonyms : null);
        that.businessTermFrm.get('hyperonyms').patchValue(that.selectedItem.hyperonyms != undefined ? that.selectedItem.hyperonyms : null);
        that.loadSaveScriptModal();
        $('#saveBusinessGloassaryTitle').html('Editing ' + that.selectedItem.name);
        $('#saveBusinessGloassaryModal').modal();
      }
    });
  }
  addExtraHtml(extraItem: ExtraItem) {
    const that = this;
    const type = extraItem.type;
    if (type == 'start') {
      const start = document.createElement('div');
      this.tabIndex++;
      $(start).addClass('node-item lg-start').attr('id', extraItem.id).css({width: '52px', height: '52px', position: 'absolute'}).attr('tabindex', this.tabIndex).html('<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x" style="background: -webkit-linear-gradient(to right, #38ef7d, #11998e);\n' +
        '                          background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ -webkit-background-clip: text; -webkit-text-fill-color: transparent;"></i>\n' +
        '                        <i class="fas fa-circle fa-stack-1x fa-inverse"></i></span>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="start"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="start"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing" title="remove this start"></i>');
      if (!this.flatDesign) {
        $(start).addClass('shadow-design');
      }
      document.getElementById('play-area').appendChild(start);
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(start).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $('.business-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if (e.key === 'Delete') {
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE Start Object?' : 'SUPPRIMER l\'objet de départ ?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
           }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Start Object?' : 'SUPPRIMER l\'objet de départ ?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
         }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'end') {
      const end = document.createElement('div');
      this.tabIndex++;
      $(end).addClass('node-item lg-end').attr('id', extraItem.id).css({width: '52px', height: '52px', position: 'absolute'}).attr('tabindex', this.tabIndex).html('<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x" style=" background: -webkit-linear-gradient(to right, #EA384D, #D31027);  /* Chrome 10-25, Safari 5.1-6 */\n' +
        '                          background: linear-gradient(to right, #EA384D, #D31027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ -webkit-background-clip: text; -webkit-text-fill-color: transparent;"></i>\n' +
        '                        <i class="far fa-circle fa-stack-1x fa-inverse"></i></span>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="end"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="end"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-end-process" title="remove this end"></i>');
      if (!this.flatDesign) {
        $(end).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(end);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(end).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $('.business-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if (e.key === 'Delete') {
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE Start Object?' : 'SUPPRIMER l\'objet de départ ?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
           }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Start Object?' : 'SUPPRIMER l\'objet de départ ?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
         }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'aggregate') {
      const aggregate = document.createElement('div');
      this.tabIndex++;
      $(aggregate).addClass('node-item lg-aggregate').attr('id', extraItem.id).attr('tabindex', this.tabIndex).css({width: '60px', height: '60px', position: 'absolute'}).html('     <svg style="width: 60px; height: 60px;">\n' +
        '                        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">\n' +
        '                          <stop offset="0%" stop-color="#0072ff"></stop>\n' +
        '                          <stop offset="100%" stop-color="#00c6ff"></stop>\n' +
        '                        </linearGradient>\n' +
        '                        <g style="transform: translate(16px, 10px)">\n' +
        '                          <circle cx="15" cy="18" r="26" fill="url(#linearColors1)"></circle>\n' +
        '                          <path fill="white" d="M 30.3188 26.8 H 27.075 c 2.1531 -2.8563 3.3375 -6.425 3.3375 -10.0531 c 0 -8.7844 -6.6344 -15.9313 -14.7875 -15.9313 c -8.1563 0 -14.7875 7.1469 -14.7875 15.9313 c 0 3.6281 1.1844 7.1969 3.3375 10.0531 H 0.9313 c -0.3406 0 -0.6188 0.2781 -0.6188 0.6188 V 29.8125 c 0 0.3406 0.2781 0.6188 0.6188 0.6188 H 9.7813 c 0.3406 0 0.6188 -0.2781 0.6188 -0.6188 v -2.6625 c 0 -0.1469 -0.0531 -0.2906 -0.1469 -0.4031 l -0.0344 -0.0406 c -0.0344 -0.0406 -0.075 -0.0781 -0.1187 -0.1094 c -3.0438 -2.1094 -4.9375 -5.8844 -4.9375 -9.85 c 0 -6.3969 4.6937 -11.6031 10.4625 -11.6031 c 5.7687 0 10.4625 5.2062 10.4625 11.6031 c 0 3.9813 -1.9031 7.7656 -4.9688 9.8719 c -0.1688 0.1156 -0.2687 0.3063 -0.2687 0.5094 V 29.8125 c 0 0.3406 0.2781 0.6188 0.6188 0.6188 h 8.8469 c 0.3406 0 0.6188 -0.2781 0.6188 -0.6188 v -2.3906 C 30.9375 27.0781 30.6594 26.8 30.3188 26.8 z"></path>\n' +
        '                        </g>\n' +
        '                      </svg>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="aggregate"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="aggregate"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-aggregate-process" title="remove this aggregation"></i>');
      if (!this.flatDesign) {
        $(aggregate).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(aggregate);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(aggregate).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $('.business-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if (e.key === 'Delete') {
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE Aggregate Object?' : 'SUPPRIMER l\'objet agrégé ?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
           }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Aggregate Object?' : 'SUPPRIMER l\'objet agrégé ?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
         }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'condition') {
      const condition = document.createElement('div');
      this.tabIndex++;
      $(condition).addClass('node-item lg-condition').attr('id', extraItem.id).attr('tabindex', this.tabIndex).css({width: '60px', height: '60px', position: 'absolute'}).html('   <svg style="width: 60px; height: 60px;">\n' +
        '                        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">\n' +
        '                          <stop offset="0%" stop-color="#0072ff"></stop>\n' +
        '                          <stop offset="100%" stop-color="#00c6ff"></stop>\n' +
        '                        </linearGradient>\n' +
        '                        <g style="transform: translate(16px, 10px);">\n' +
        '                          <circle cx="14" cy="17" r="26" fill="url(#linearColors1)"></circle>\n' +
        '                          <path fill="white" d="M 11.8438 31 L 0.6875 17.8562 c -0.9125 -1.075 -0.9125 -2.6375 0 -3.7188 L 11.8438 1 c 1.1313 -1.3375 3.1812 -1.3313 4.3125 0 L 27.3125 14.1438 c 0.9125 1.075 0.9125 2.6375 0 3.7188 L 16.1563 31 c -1.1313 1.3375 -3.1812 1.3313 -4.3125 0 z M 3 16 l 11 12.9063 L 25 16 L 14 3.0938 L 3 16 z"></path>\n' +
        '                        </g>\n' +
        '                      </svg>' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="condition: yes"></i><i class="fas fa-circle connector end condition-line" id="end_' + extraItem.id + '" data-name="condition: no"></i></i><i class="fas fa-circle connector input-condition extra-line" id="input_' + extraItem.id + '" data-name="condition: input"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-condition-process" title="remove this condition"></i>');
      if (!this.flatDesign) {
        $(condition).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      connectors.push({id: 'input_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(condition);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(condition).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $('.business-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if (e.key === 'Delete') {
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE Condition Object?' : 'SUPPRIMER l\'objet de condition ?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
           }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Condition Object?' : 'SUPPRIMER l\'objet de condition ?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
         }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
  }

  async loadSaveScriptModal() {
    const that = this;
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.BtDefinition) {
        CKEDITOR.instances.BtDefinition.destroy(true);
      }
      const editorDefinition = CKEDITOR.replace('BtDefinition');
      editorDefinition.config.height = 60;
      editorDefinition.on( 'change', evt => {
        this.businessTermFrm.get('definition').patchValue(evt.editor.getData());
      });
      // example editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.BtExample) {
        CKEDITOR.instances.BtExample.destroy(true);
      }
      const editorExample = CKEDITOR.replace('BtExample');
      editorExample.config.height = 60;
      editorExample.on( 'change', evt => {
        this.businessTermFrm.get('example').patchValue(evt.editor.getData());
      });
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      $('#BtEffective').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function(e, date) {
          that.businessTermFrm.get('effective').patchValue(formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en'));
        });
    });
   /* await this.dynamicScriptLoader.load('ion.rangeSlider').then( data => {
      that.completion = 0;
      if (that.ranger != null) {
        that.ranger.reset();
      }
      $('#BtCompletion').ionRangeSlider({
        skin: 'round',
        min: 0,
        max: 100,
        from: 0,
        onChange(data) {

          switch(that.selectedItem.type){
            case 'term': if(that.dataSource.businessTerms.find(e => e.cid == that.selectedItem.cid)!= null) {that.dataSource.businessTerms.find(e => e.cid == that.selectedItem.cid).completion = data.from;}; break;
            case 'object': if(that.dataSource.businessObjects.find(e => e.cid == that.selectedItem.cid)!= null){that.dataSource.businessObjects.find(e => e.cid == that.selectedItem.cid).completion = data.from;}; break;
            default: if(that.dataSource.synonyms.find(e => e.cid == that.selectedItem.cid)!= null){that.dataSource.synonyms.find(e => e.cid == that.selectedItem.cid).completion = data.from}; break;
          }
          that.completion = data.from;
        }
      });
      this.ranger = $('#BtCompletion').data('ionRangeSlider');
      this.ranger.update({
        from: this.selectedItem.completion
      });
    }).catch(error => console.log(error));*/
  }

  changeEnabled(event: Event) {
    const checked = $(event.target).prop('checked');
    this.saveFrm.get('enabledLine').patchValue(checked);

  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  addBusinessItemHtml(businessItem: BusinessItem) {
    this.tabIndex++;
    const classBusiness = 'test'; // (businessItem.type == 'businessObject')? 'business-line-object': 'business-line-term';
    const html = '<div class="card business-item" style="width: 170px; background: transparent; font-size: 10px;box-shadow: transparent 0px 0px 1px;" data-color="rgb(rgb(27, 77 87))" id="' + businessItem.id + '" tabindex="' + this.tabIndex + '">\n' +
      '<div class="card-header text-center text-white node-header" style="background-color: rgb(27 ,77 ,87);border-radius: 23px;height:45px;\n' +
      '    border-top-right-radius: 23px;text-transform: capitalize">' + businessItem.name + '<i class="fas fa-circle connector end ' + classBusiness + '" id="end_' + businessItem.id + '" data-name="end_' + businessItem.id + '"></i></div>\n' +
      '    </div>';
    $('#play-area').append(html);

    if (!this.flatDesign) {
      $('#' + businessItem.cid).addClass('shadow-design');
    }
    //  $('#' + businessItem.id).offset(businessItem.style);
    this.enableLinking();
    this.makeDraggable(businessItem.id);
    this.businessTerms.push(businessItem);
  }
  addBusinessObjectHtml(businessObject: BusinessObject) {
    $('body').css('position', '');
    if(this.dataSource.businessObjects.find(t => t.cid == businessObject.cid)!= null){
      return;
    }
    const that = this;
    // const id = this.create_UUID();
    this.tabIndex++;
    const html = '<div class="card business-item" style="width: 170px;font-size: 10px;background: transparent" data-color="rgb(43,127,144)" id="' + businessObject.cid + '" tabindex="' + this.tabIndex + '">\n' +
      '<div class="card-header text-center text-white node-header" style="\n' +
      '    background-color: transparent;\n' +
      '    text-transform: capitalize;\n' +
      '    border-bottom: 29px solid rgb(184,181,187);\n' +
      '    border-left: 25px solid transparent;\n' +
      '    border-right: 25px solid transparent;\n' +
      '    height: 0;\n' +
      '    width: 169px;">\n' +
      '</div>\n' +
      '      <ul class="list-group list-group-flush text-black node-details flat-details">\n' +
      '<ul class="list-group list-group-flush text-black collapse-lg" style="border-top: 1px solid #f3dcdc" id="terms_' + businessObject.cid + '">' + this.getTerms(businessObject) + '</ul></ul>\n' +
      '<div class="card-body tools-line tools-line-flat">\n' +
      '        \n' +
      '        \n' +
      '       <span class="term-modal" style="cursor: pointer;" data-id="' + businessObject.cid + '"><i class="fas fa-plus" data-title="add Business Term"></i>&nbsp;</span>\n' +
     // '       <span class="editBt-modal" style="cursor: pointer;position:relative;left:20%" data-id="' + businessObject.id + '"><i class="fas fa-edit" data-title="add Business Term"></i>&nbsp;</span>\n' +
      '      </div>\n' +
      '      <div id="line-wrapper"></div>\n' +
      '    </div>';
    $('#play-area').append(html);
    this.businessObjects.push(businessObject);
    this.dataSource.businessObjects.push(businessObject);
    this.dataSource.businessObjects.map(
      ty => {
        this.dataSource.businessTerms = this.dataSource.businessTerms.concat(ty.terms);
      }
    );
    this.resizeObserver.disconnect();
    this.resizeObserver.observe(document.getElementById(businessObject.cid));
    this.showToolTip('.term-modal > i');
    this.showToolTip('.delete-term');
    this.showToolTip('.edit-term');
    this.showToolTip('.delete-business-object');
    $('.term-modal').on('click', function()  {
      console.log(that.businessObjects);
	    console.log($(this).attr('data-id'));
      that.businessObject = that.dataSource.businessObjects.find(e => e.cid == $(this).attr('data-id'));
     // console.log(that.businessObject);
      that.businessModalType = false;
      that.addBusinessGlossary('term');
    });
    $('li[id^=\'term_\']').on('click', function(event) {
      event.stopPropagation();
      const selectedId = $(this).attr('id').toString().replace('term_', '');
      if (that.clicked != null) {
        if (that.clicked.cid == selectedId) {
          return;
        }
      }
      $('.selected-node').removeClass('selected-node');
      $('.selected-term').removeClass('selected-term');
      $(this).addClass('selected-term').focus();
      that.clicked = that.dataSource.businessTerms.find(e => e.cid == selectedId);
      if(that.clicked.effective.date!=null){
        that.clicked.effective = that.clicked.effective.date.toString().substr(0 , 10);
      }
      console.log(that.clicked);
    });
    $('#' + $.escapeSelector(businessObject.cid)).offset(businessObject.style).on('click', function(event) {
      event.stopPropagation();
      if (that.clicked != null) {
        if (that.clicked.cid == $(this).attr('id')) {
          return;
        }
      }
      $('#play-area').click();
      $('#infosTab').click();
     // const id = $(this).attr('id');
      that.clickedEdges = [];
      $('.node-item').removeClass('selected-node');
      $('.selected-term').removeClass('selected-term');
      $(this).addClass('selected-node').focus();
      $(this).addClass('selected-term').focus();
     // console.log(that.businessObjects);
      that.clicked = that.dataSource.businessObjects.find(e => e.cid == businessObject.cid);
//      console.log(that.clicked);
      that.termsInfo = that.clicked.terms;
      that.getEdgesTerm();
    });
    this.enableEditing();
   /* $('.editBt-modal').on('click', function() {
      const id = $(this).attr('data-id');
    });*/

   /* $('.delete-term').on('click', function() {
      Swal.fire({
        title: 'DELETE Business Term?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved it!'
      }).then((result) => {
        if (result.value) {
          const id = $(this).attr('data-id');
          const parentId = $(this).parent().parent().attr('id').toString().replace('terms_', '');
          const bo = that.businessObjects.find(e => e.id = parentId);
          if(bo!=null){
            bo.terms = bo.terms.filter(e => e.id!= id);
            const ed = 'end_' + id;
            console.log(ed);
            const linksToRemove = that.edges.filter(e => e.source == ed || e.target == ed);
            console.log(linksToRemove);
            if(linksToRemove.length > 0){
              linksToRemove.map(
                l => l.element.remove()
              );
            }
            that.edges = that.edges.filter(e => e.source!= ed && e.target!= ed);
            $('#term_' + id).remove();
            $('#play-area').click();
            $('#' + parentId).click();
          }

          that.updateConnectors();
        }
      });

    });*/
   /* $('.delete-business-object').on('click', function() {
      Swal.fire({
        title: 'DELETE Business Object?',
        text: 'You won\'t be able to revert this!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Approved it!'
      }).then((result) => {
        if (result.value) {
          const id = $(this).attr('data-id');
          const bo = that.dataSource.businessObjects.find(e => e.id = id);
          if(bo!=null){
            if(bo.terms!=null){
              bo.terms.map(
                t => {
                  const ted = 'end_' + t.id;
                  const tst = 'start_' + t.id;
                  const termsLinksToRemove = that.edges.filter(e => e.source == ted || e.target == ted || e.source == tst || e.target == tst);
                  console.log(termsLinksToRemove);
                  if(termsLinksToRemove.length > 0){
                    termsLinksToRemove.map(
                      l => l.element.remove()
                    );
                    that.edges = that.edges.filter(e => e.source!= ted && e.target!= ted && e.source != tst || e.target != tst);
                  }
                }
              );
            }
            const ed = 'end_' + id;
            const st = 'start_' + id;
            const linksToRemove = that.edges.filter(e => e.source == ed || e.target == ed || e.source == st || e.target == st);
            console.log(linksToRemove);
            if(linksToRemove.length > 0){
              linksToRemove.map(
                l => l.element.remove()
              );
              that.edges = that.edges.filter(e => e.source!= ed && e.target!= ed && e.source != st || e.target != st);
            }
            $('#' + id).remove();
            $('#play-area').click();
          }

          that.updateConnectors();
        }
      });

    });*/
    if (!this.flatDesign) {
      $('#' + businessObject.cid).addClass('shadow-design');
    }
    this.enableLinking();
    this.makeDraggable(businessObject.cid);
    removeLoader();
    //   this.businessTerms.push(businessItem);
  }
  getEdgesSynonym(){
    if(this.clicked !=null){
      const target = 'end_' + this.clicked.cid;
      this.clickedEdges = this.edges.filter(l => l.source == target || l.target == target);
    }
  }
  getEdgesTerm() {
    if(this.clicked.type == 'object'){
      const sourceObject = 'start_' + this.clicked.cid;
      const targetObject = 'end_' + this.clicked.cid;
      this.clickedEdges = this.edges.filter(l => l.source == targetObject || l.target == targetObject || l.source == sourceObject || l.target == sourceObject);
    }
    if (this.clicked.terms != null) {
      if (this.clicked.terms.length > 0) {
        this.clicked.terms.map(
          e => {
            const source = 'start_' + e.cid;
            const target = 'end_' + e.cid;
            // console.log(target);
            const objectEdges = this.edges.filter(l => l.source == source || l.target == source || l.source == target || l.target == target);
           // console.log(objectEdges);
            if (objectEdges.length > 0 ) {
              objectEdges.map(t => {
                if (this.clickedEdges.find(f => f.source == t.source && f.target == t.target) == null) {
                  this.clickedEdges.push(t);
                }
              });
              // this.clickedEdges = this.clickedEdges.concat(objectEdges);
            }
          }
        );
        // console.log(this.clickedEdges);
        // console.log(this.edges);
        // console.log(this.clicked.terms);
    }

    }
    if (this.clicked.objects != null) {
      if (this.clicked.objects.length > 0) {
        this.clicked.objects.map(
          e => {
            const source = 'start_' + e.cid;
            const target = 'end_' + e.cid;
            console.log(target);
            const objectEdges = this.edges.filter(l => l.source == source || l.target == source || l.source == target || l.target == target);
            console.log(objectEdges);
            if (objectEdges.length > 0 ) {
              objectEdges.map(t => {
                if (this.clickedEdges.find(f => f.source == t.source && f.target == t.target) == null) {
                  this.clickedEdges.push(t);
                }
              });
            }
          }
        );
      }
    }
    if (this.clicked.synonyms != null) {
      if (this.clicked.synonyms.length > 0) {
        this.clicked.synonyms.map(
          e => {
            const source = 'start_' + e.cid;
            const target = 'end_' + e.cid;
            console.log(target);
            const objectEdges = this.edges.filter(l => l.source == source || l.target == source || l.source == target || l.target == target);
            console.log(objectEdges);
            if (objectEdges.length > 0 ) {
              objectEdges.map(t => {
                if (this.clickedEdges.find(f => f.source == t.source && f.target == t.target) == null) {
                  this.clickedEdges.push(t);
                }
              });
            }
          }
        );
      }
    }
  }
 /* getBusinessTerm(id: string){
    this.dataLineService.getBusinessTerm(id).subscribe(
      d => {
        if(d.businessObject.catalogueLineage!=null){
          this.dataLineageName = d.businessObject.name;
          this.businessObject = new BusinessObject(d.businessObject.id, d.businessObject.name, d.businessObject.catalogueLineage.business.terms, d.businessObject.businessId, '', d.businessObject.effective.date, d.businessObject.status,d.businessObject.completion, d.businessObject.definition, d.businessObject.example, d.businessObject.oldId, d.businessObject.catalogueLineage.business.style );
          this.addBusinessObjectHtml(this.businessObject);
          if(d.businessObject.catalogueLineage.nodes.length > 0){
            for (let i = 0; i < d.businessObject.catalogueLineage.nodes.length; i++) {
              const item = new NodeItem(d.businessObject.catalogueLineage.nodes[i].id, d.businessObject.catalogueLineage.nodes[i].icon, d.businessObject.catalogueLineage.nodes[i].text, d.businessObject.catalogueLineage.nodes[i].application,d.businessObject.catalogueLineage.nodes[i].extern, d.businessObject.catalogueLineage.nodes[i].catalogue, d.businessObject.catalogueLineage.nodes[i].data, d.businessObject.catalogueLineage.nodes[i].description, d.businessObject.catalogueLineage.nodes[i].customApplication, 'black', d.businessObject.catalogueLineage.nodes[i].customs, d.businessObject.catalogueLineage.nodes[i].applicationType, d.businessObject.catalogueLineage.nodes[i].trigger, d.businessObject.catalogueLineage.nodes[i].type, d.businessObject.catalogueLineage.nodes[i].style);
              this.addHtmlContent(item);
            }
          }
          if(d.businessObject.catalogueLineage.links.length > 0){
            for (let k = 0; k < d.businessObject.catalogueLineage.links.length; k++) {
              if (d.businessObject.catalogueLineage.links[k].parent) {
                this.addLine(d.businessObject.catalogueLineage.links[k].source, d.businessObject.catalogueLineage.links[k].target, d.businessObject.catalogueLineage.links[k].startColor, d.businessObject.catalogueLineage.links[k].endColor, d.businessObject.catalogueLineage.links[k].hide, d.businessObject.catalogueLineage.links[k].parent, d.businessObject.catalogueLineage.links[k].sourceName, d.businessObject.catalogueLineage.links[k].targetName, d.businessObject.catalogueLineage.links[k].group, d.businessObject.catalogueLineage.path, d.businessObject.catalogueLineage.startSocket, d.businessObject.catalogueLineage.endSocket, d.businessObject.catalogueLineage.middleLabel, d.businessObject.catalogueLineage.labelColor, d.businessObject.catalogueLineage.labelSize, d.businessObject.catalogueLineage.labelStyle, d.businessObject.catalogueLineage.labelWeight, d.businessObject.catalogueLineage.dropShadow);
              }
            }
          }
        } else {
          const terms = [];
          d.terms.map((b, i) => {
            const connectors = [];
            connectors.push({id: 'end_' + b.id});
            // const type = b.name.includes('Ot')? 'businessObject': 'businessTerm';
            const businessItem = new BusinessItem(b.id,  b.businessId, b.name, '', b.effective.date, b.status, b.completion, b.definition, b.example, b.oldId, connectors);
            terms.push(businessItem);
          });
          this.dataLineageName = d.businessObject.name;
          this.businessObject = new BusinessObject(d.businessObject.id, d.businessObject.name, terms, d.businessObject.businessId, '', d.businessObject.effective.date, d.businessObject.status,d.businessObject.completion, d.businessObject.definition, d.businessObject.example, d.businessObject.oldId, {top: 400, left: 250} );
          this.addBusinessObjectHtml(this.businessObject);
        }
        //this.removeLoader();
      },
      err => {
        console.error(err);
      }
    );
  }*/
  saveBusinessTerm() {
  //  console.log(this.dataSource.businessObjects);return;
   // const that = this;
    const data = this.businessTermFrm.value;
    console.log(data);
    console.log('editMode : ' + this.editMode);
   // data.completion = this.completion;
    data.createdBy = localStorage.getItem('username');
    const id = this.create_UUID();
    const connectors = [];
    if (this.editMode) {
      const name = this.selectedItem.name;

      if (data.type == 'object') {
        this.dataSource.businessObjects.find(e => e.cid == this.selectedItem.cid).oldId = data.oldId;
        this.dataSource.businessObjects.find(e => e.cid == this.selectedItem.cid).name = data.name;
        this.dataSource.businessObjects.find(e => e.cid == this.selectedItem.cid).effective = data.effective;
        this.dataSource.businessObjects.find(e => e.cid == this.selectedItem.cid).status = data.status;
        //this.dataSource.businessObjects.find(e => e.cid == this.selectedItem.cid).completion = data.completion;
        this.dataSource.businessObjects.find(e => e.cid == this.selectedItem.cid).definition = data.definition;
        this.dataSource.businessObjects.find(e => e.cid == this.selectedItem.cid).example = data.example;
        const htm = '<i class="fas fa-book-open"></i>&nbsp;' + data.name +  '<i class="fas fa-edit edit-term" data-id="' + this.selectedItem.cid + '" style="cursor: pointer;float: right;padding-right: 5px" data-title="edit this business Object"></i>';
        $('#botsah_' + this.selectedItem.cid).html(htm);
      } else if (data.type == 'term') {
        this.dataSource.businessTerms.find(e => e.cid == this.selectedItem.cid).oldId = data.oldId;
        this.dataSource.businessTerms.find(e => e.cid == this.selectedItem.cid).name = data.name;
        this.dataSource.businessTerms.find(e => e.cid == this.selectedItem.cid).effective = data.effective;
        this.dataSource.businessTerms.find(e => e.cid == this.selectedItem.cid).status = data.status;
       // this.dataSource.businessTerms.find(e => e.cid == this.selectedItem.cid).completion = data.completion;
        this.dataSource.businessTerms.find(e => e.cid == this.selectedItem.cid).definition = data.definition;
        this.dataSource.businessTerms.find(e => e.cid == this.selectedItem.cid).example = data.example;
        const htm = '<i class="fas fa-file-alt"></i>&nbsp;' + data.name + '<i class="fas fa-edit edit-term" data-id="' + this.selectedItem.cid + '" data-parent="' + this.selectedItem.cid + '" style="cursor: pointer;float: right;padding-right: 5px" data-title="edit this business Term"></i>';
        $('#btsah_' + this.selectedItem.cid).html(htm);
      }
      else {
        this.dataSource.synonyms.find(e => e.cid == this.selectedItem.cid).oldId = data.oldId;
        this.dataSource.synonyms.find(e => e.cid == this.selectedItem.cid).name = data.name;
        this.dataSource.synonyms.find(e => e.cid == this.selectedItem.cid).effective = data.effective;
        this.dataSource.synonyms.find(e => e.cid == this.selectedItem.cid).status = data.status;
       // this.dataSource.synonyms.find(e => e.cid == this.selectedItem.cid).completion = data.completion;
        this.dataSource.synonyms.find(e => e.cid == this.selectedItem.cid).definition = data.definition;
        this.dataSource.synonyms.find(e => e.cid == this.selectedItem.cid).example = data.example;
        const htm = data.name + '    <i class="fas fa-edit edit-term" data-id="'+ this.selectedItem.cid +'" style="cursor: pointer;float: right;padding-right: 5px" data-title="edit this ' + this.selectedItem.type + '"></i>';
        $('#synonym_header_' + this.selectedItem.cid).html(htm);
      }

      console.log('name is ' + name);
     // const links = this.edges.filter(l => l.sourceName == name || l.targetName == name );
      if(this.edges.length > 0){
        for(let i = 0; i < this.edges.length; i++){
          if(this.edges[i].sourceName == name){
            this.edges[i].sourceName = data.name;
          }
          if(this.edges[i].targetName == name){
            this.edges[i].targetName = data.name;
          }
        }
      }
      console.log("edges", this.edges);
      $( "i[data-name='" +  name + "']" ).attr('data-name', data.name);
      this.showToolTip('.delete-term');
      this.showToolTip('.edit-term');
      this.enableLinking();
      this.enableEditing();
    } else {
      if (data.type == 'object') {
        const cid = this.create_UUID(); // this.ceaserCipher(data.name);
        const businessObject = new BusinessObject(id, data.name, data.terms != null ? data.terms : [], data.synonyms != null ? data.synonyms : [], data.antonyms != null ? data.antonyms : [],data.hyperonyms != null ? data.hyperonyms : [], data.businessId, '', data.effective, data.status, 0, data.definition, data.example, data.oldId, cid, this.offset, true, false);
        this.addBusinessObjectHtml(businessObject);
        let index =0;
        if(data.synonyms!=null){
          if(data.synonyms.length > 0){
            for(let i = 0; i < data.synonyms.length;i++){
               if(this.dataSource.synonyms.find(e => e.cid == data.synonyms[i].cid) == null){
                 index++;
                 data.synonyms[i].style = {top: this.offset.top +  index * 50, left: this.offset.left +  index * 100};
                 this.addSynonymHtml(data.synonyms[i]);
                 this.dataSource.synonyms.push(data.synonyms[i]);
                 const groupId = this.create_UUID();
                 setTimeout(()=> {
                   this.addLine('end_' + data.synonyms[i].cid, 'start_' + cid, 'gray', 'rgb(43,127,144)', false, true, data.name, data.synonyms[i].name, groupId, 'fluid', 'auto', 'auto', '', 'gray', 12, 'normal', 'normal', false, 'synonymObject', 'businessObject');
                 }, 500);
               }
            }
          }
        }
        if(data.antonyms!=null){
          if(data.antonyms.length > 0){
            for(let i = 0; i < data.antonyms.length;i++){
              if(this.dataSource.synonyms.find(e => e.cid == data.antonyms[i].cid) == null){
                index++;
                data.antonyms[i].style = {top: this.offset.top +  index * 50, left: this.offset.left +  index * 100};
                this.addSynonymHtml(data.antonyms[i]);
                this.dataSource.synonyms.push(data.antonyms[i]);
                const groupId = this.create_UUID();
                setTimeout(()=> {
                  this.addLine('end_' + data.antonyms[i].cid, 'start_' + cid, 'gray', 'rgb(43,127,144)', false, true, data.name, data.antonyms[i].name, groupId, 'fluid', 'auto', 'auto', '', 'gray', 12, 'normal', 'normal', false, 'antonymObject', 'businessObject');
                }, 500);
              }
            }
          }
        }
        if(data.hyperonyms!=null){
          if(data.hyperonyms.length > 0){
            for(let i = 0; i < data.hyperonyms.length;i++){
              if(this.dataSource.synonyms.find(e => e.cid == data.hyperonyms[i].cid) == null){
                index++;
                data.hyperonyms[i].style = {top: this.offset.top +  index * 50, left: this.offset.left +  index * 100};
                this.addSynonymHtml(data.hyperonyms[i]);
                this.dataSource.synonyms.push(data.hyperonyms[i]);
                const groupId = this.create_UUID();
                setTimeout(()=> {
                  this.addLine('end_' + data.hyperonyms[i].cid, 'start_' + cid, 'gray', 'rgb(43,127,144)', false, true, data.name, data.hyperonyms[i].name, groupId, 'fluid', 'auto', 'auto', '', 'gray', 12, 'normal', 'normal', false, 'hyperonymObject', 'businessObject');
                }, 500);
              }
            }
          }
        }
      }
      if (data.type == 'term') {
        const cid = this.create_UUID(); //this.ceaserCipher(data.name);
        connectors.push({id: 'end_' + cid});
        const businessItem = new BusinessItem(id, data.businessId, data.name, data.objects != null ? data.objects : [], data.synonyms != null ? data.synonyms : [],data.antonyms != null ? data.antonyms : [],data.hyperonyms != null ? data.hyperonyms : [], '', data.effective, data.status, 0, data.definition, data.example, data.oldId, connectors, cid, true, false);
        this.businessObject.terms.push(businessItem);
        this.dataSource.businessTerms.push(businessItem);
        /*  const bot = this.dataSource.businessObjects.find(e => e.cid = this.businessObject.cid);
      if(bot!=null){
        if(bot.terms.indexOf(businessItem)==-1){
          bot.terms.push(businessItem);
        }
      }*/
        const html = '<li id="term_' + cid + '" class="list-group-item" style="background-color: rgb(27 ,77 ,87); height: 30px;">&nbsp; <span style="color: white;text-transform: capitalize" title="Business Term"><i class="fas fa-file-alt"></i>&nbsp;' + businessItem.name + '<i class="fas fa-trash-alt delete-term" data-id="' + businessItem.cid + '" style="cursor: pointer;float:right;" data-title="remove this business Term"></i><i class="fas fa-edit edit-term" data-id="' + businessItem.cid + '" style="cursor: pointer;float: right;padding-right: 5px" data-title="edit this business Term"></i></span><i class="fas fa-circle connector start static business-line-term" id="start_' + businessItem.cid + '" data-name="' + businessItem.name + '"></i>&nbsp;<i class="fas fa-circle connector end business-line-term" id="end_' + businessItem.cid + '"  data-name="' + businessItem.name + '"></i></li>';
        // '<li id="term_' + id + '" class="list-group-item" style="background-color: rgb(27 ,77 ,87)"><i class="fas fa-circle connector start static business-line-term" id="start_' + businessItem.id + '" data-name="' + businessItem.name + '"></i>&nbsp; <span style="color: white;text-transform: capitalize" title="Business Term"><i class="fas fa-file-alt"></i>&nbsp;' + businessItem.name + '<i class="fas fa-minus" style="cursor: pointer;float: right" title="remove this business Term"></i></span>&nbsp;<i class="fas fa-circle connector end business-line-term" id="end_' + businessItem.id + '"  data-name="' + businessItem.name + '"></i></li>';

        $('#terms_' + this.businessObject.cid).append(html);
        $('#play-area').click();
        this.showToolTip('.delete-term');
        this.showToolTip('.edit-term');
        this.enableLinking();
        /* $('.editBt-modal').on('click', function() {
           const id = $(this).attr('data-id');
         });*/

        /*$('.delete-term').on('click', function() {
          Swal.fire({
            title: 'DELETE Business Term?',
            text: 'You won\'t be able to revert this!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Approved it!'
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('data-id');
              const parentId = $(this).parent().parent().attr('id').toString().replace('terms_', '');
              const bo = that.businessObjects.find(e => e.id = parentId);
              if(bo!=null){
                bo.terms = bo.terms.filter(e => e.id!= id);
                const ed = 'end_' + id;
                console.log(ed);
                const linksToRemove = that.edges.filter(e => e.source == ed || e.target == ed);
                console.log(linksToRemove);
                if(linksToRemove.length > 0){
                  linksToRemove.map(
                    l => l.element.remove()
                  );
                }
                that.edges = that.edges.filter(e => e.source!= ed && e.target!= ed);
                $('#term_' + id).remove();
                $('#play-area').click();
                $('#' + parentId).click();
              }

              that.updateConnectors();
            }
          });

        });*/
      }

      if (data.type == 'synonym object' || data.type == 'antonym object' || data.type == 'hyperonym object') {
        const objects = [];
        const terms = [];
        console.log(data.objects);
        console.log(this.objectsList);
        if (data.objects != null) {
          if (data.objects.length > 0) {
            data.objects.map(e => {
              const bo = this.dataSource.businessObjects.find(b => b.cid == e);
              if (bo == null) {
                const bot = this.objectsList.find(b => b.cid == e);
                if(bot!=null){
                  bot.style = this.offset;
                  bot.style.left += 170;
                  this.addOldBusinessObject(bot);
                  objects.push(bot);
                }
              }
            });
          }
        }
        const cid =  this.create_UUID(); // this.ceaserCipher(data.name);
        const synonym = new Synonym(id, data.name, terms, objects, data.businessId, '', data.effective, data.status, 0, data.definition, data.example, data.oldId, data.type, cid, this.offset, true, false);
        this.addSynonymHtml(synonym);
        this.dataSource.synonyms.push(synonym);
      }
      if (data.type == 'synonym term' || data.type == 'antonym term' || data.type == 'hyperonym term') {
        const terms = [];
        const objects = [];
        if (data.terms != null) {
          if (data.terms.length > 0) {
            data.terms.map(e => {
              const connectors = [];
              connectors.push({id: 'end_' + e});
              const btt = this.dataSource.businessTerms.find(b => b.cid == e);
              if (btt == null) {
                const bte = this.terms.find(t => t.cid == e);
                if(bte!= null){
                  const bt = new BusinessItem(bte.id, bte.businessId, bte.name, bte.objects, bte.synonyms, bte.antonyms, bte.hyperonyms, '', bte.effective.date, bte.status, 0, bte.definition, bte.example, bte.oldId, connectors, bte.cid, bte.toAdd, bte.toEdit);
                  terms.push(bt);
                }
              }
            });
          }
        }
        /* if($('#default_object120').length >0){
           const html = '<li id="term_' + id + '" class="list-group-item" style="background-color: rgb(27 ,77 ,87)"><i class="fas fa-circle connector start static business-line-term" id="start_' + businessItem.id + '" data-name="' + businessItem.name + '"></i>&nbsp; <span style="color: white;text-transform: capitalize" title="Business Term"><i class="fas fa-file-alt"></i>&nbsp;' + businessItem.name + '<i class="fas fa-minus" style="cursor: pointer;float: right" title="remove this business Term"></i></span>&nbsp;<i class="fas fa-circle connector end business-line-term" id="end_' + businessItem.id + '"  data-name="' + businessItem.name + '"></i></li>';
           $('#terms_' + this.defaultBusinessObject.id).append(html);
         } else {
           this.defaultBusinessObject.terms = terms;
           this.addBusinessObjectHtml(this.defaultBusinessObject);
         }*/
        const cid = this.create_UUID(); // this.ceaserCipher(data.name);
        const synonym = new Synonym(id, data.name, terms, objects, data.businessId, '', data.effective, data.status, 0, data.definition, data.example, data.oldId, data.type, cid, this.offset, true, false);
        this.addSynonymHtml(synonym);
        this.dataSource.synonyms.push(synonym);
      }
    }
    $('#saveBusinessGloassaryModal').modal('hide');
  }
  addBusinessGlossary(type: string) {
    if (type == 'term') {
      $('#saveBusinessGloassaryTitle').html('New Business Term');
      this.displayType = false;
      this.editMode = false;
      this.businessType = 'term';
      setTimeout(() => this.businessTermFrm.get('type').patchValue('term'), 200);
      this.openModalBusiness('Bt_');
    } else {
      Swal.fire({
        title: this.lang === 'en' ? 'Add Business Object to viewPort?' : 'Ajouter un objet métier au port d\'affichage ?',
        text: this.lang === 'en' ? 'Would you like to select from ancient business Objects or add new one!' : 'Voulez-vous sélectionner parmi les anciens objets métiers ou en ajouter un nouveau ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'en' ? 'Old Business Object!' : 'Ancien objet métier !',
        cancelButtonText: this.lang === 'en' ? 'New Business Object!' : 'Nouvel objet métier !'
       }).then((result) => {
        if (result.value) {
          this.businessListFrm.reset();
          this.getBusinessObjectList();
          $('#businessListModal').modal('show');
        } else {
          $('#saveBusinessGloassaryTitle').html(this.lang === 'en' ? 'New Business Object': 'Nouvel objet métier');
          this.displayType = false;
          this.editMode = false;
          this.businessType = 'object';
          setTimeout(() => this.businessTermFrm.get('type').patchValue('object'), 500);

          this.openModalBusiness('Ot_');
        }
      });
    }
  }
  getBusinessObjectList() {
    this.businessObjectLoading = true;
    this.businessTermService.getBusinessObjectList().subscribe(
      d => {
        this.businessList = d;
        this.businessObjectLoading = false;
      }, err => { console.error(err); }
    );
  }
  openModalBusiness(str) {
    this.loadSaveScriptModal();
    this.businessTermFrm.reset();
    this.businessTermFrm.get('status').patchValue(this.status[0].id);
    if(this.businessType == 'object'){
      this.synonyms = this.allBusinessItems.filter(e => e.type == 'synonym object');
      this.antonyms = this.allBusinessItems.filter(e => e.type == 'antonym object');
      this.hyperonyms = this.allBusinessItems.filter(e => e.type == 'hyperonym object');
    }
    if(this.businessType == 'term'){
      this.synonyms = this.allBusinessItems.filter(e => e.type == 'synonym term');
      this.antonyms = this.allBusinessItems.filter(e => e.type == 'antonym term');
      this.hyperonyms = this.allBusinessItems.filter(e => e.type == 'hyperonym term');
    }
    this.generateBusinessId(str);
    $('#saveBusinessGloassaryModal').modal();
  }

  generateBusinessId(str: string) {
    let id = this.create_UUIB();
    id = str + id;
    this.businessTermFrm.get('businessId').patchValue(id);
  }
  checkBusinessTerm() {
    const name = this.businessTermFrm.value.name;
    if (name == '') {this.nameError = true; this.nameSuccess = false; return; }
    this.businessTermService.checkBusinessTerm(name).subscribe(
      d => {
        // @ts-ignore
        if (d.response.indexOf('taken') != -1) {this.nameError = true; this.nameSuccess = false; } else {this.nameError = false; this.nameSuccess = true; }},
      e => {console.error(e); }
    );
  }
  async loadSaveScriptModalBussiness() {
    const that = this;
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.BtDefinition) {
        CKEDITOR.instances.BtDefinition.destroy(true);
      }
      const editorDefinition = CKEDITOR.replace('BtDefinition');
      editorDefinition.config.height = 60;
      editorDefinition.on( 'change', evt => {
        this.businessTermFrm.get('definition').patchValue(evt.editor.getData());
      });
      // example editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.BtExample) {
        CKEDITOR.instances.BtExample.destroy(true);
      }
      const editorExample = CKEDITOR.replace('BtExample');
      editorExample.config.height = 60;
      editorExample.on( 'change', evt => {
        this.businessTermFrm.get('example').patchValue(evt.editor.getData());
      });
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      $('#BtEffective').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function(e, date) {
          that.businessTermFrm.get('effective').patchValue(formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en'));
        });
    });
  /*  await this.dynamicScriptLoader.load('ion.rangeSlider').then( data => {
      that.completion = 0;
      if (that.ranger != null) {
        that.ranger.reset();
      }
      $('#BtCompletion').ionRangeSlider({
        skin: 'round',
        min: 0,
        max: 100,
        from: 0,
        onChange(data) {
          that.completion = data.from;
        }
      });
      this.ranger = $('#BtCompletion').data('ionRangeSlider');
    }).catch(error => console.log(error));*/
  }
  getTerms(businessObject: BusinessObject) {
    let htm = '<li class="list-group-item" style="background-color: rgb(43,127,144);">&nbsp; <span id="botsah_' + businessObject.cid + '" style="color: white;text-transform: capitalize;font-weight: bold" title="Business Object"><i class="fas fa-book-open"></i>&nbsp;' + businessObject.name +  '<i class="fas fa-edit edit-term" data-id="' + businessObject.cid + '" style="cursor: pointer;float: right;padding-right: 5px" data-title="edit this business Object"></i></span>&nbsp;<i class="fas fa-circle connector start business-line-object" id="start_' + businessObject.cid + '"  data-name="' + businessObject.name + '" node-icon="businessObject"></i>&nbsp;<i class="fas fa-circle connector end business-line-object" id="end_' + businessObject.cid + '"  data-name="' + businessObject.name + '"></i></li>';
    if (businessObject.terms != null) {
     businessObject.terms.map(
       term => {
         htm += '<li id="term_' + term.cid + '" class="list-group-item" style="background-color: rgb(27 ,77 ,87); ">&nbsp; <span id="btsah_' + term.cid+ '" style="color: white;text-transform: capitalize" title="Business Term"><i class="fas fa-file-alt"></i>&nbsp;' + term.name + '<i class="fas fa-edit edit-term" data-id="' + term.cid + '" data-parent="' + businessObject.cid + '" style="cursor: pointer;float: right;padding-right: 5px" data-title="edit this business Term"></i></span><i class="fas fa-circle connector start static business-line-term" id="start_' + term.cid + '" data-name="' + term.name + '" node-icon="businessTerm"></i>&nbsp;<i class="fas fa-circle connector end business-line-term" id="end_' + term.cid + '"  data-name="' + term.name + '" node-icon="businessTerm"></i></li>';
       }
     );
   }
    return htm;
  }
  deleteBusinessItem() {
    console.log('edges', this.edges);
    if (this.clicked != null) {
      switch (this.clicked.type) {
        case 'term':
            Swal.fire({
              title: this.lang === 'en' ? 'DELETE ' + this.clicked.name + '?' : 'SUPPRIMER ' + this.clicked.name + ' ?',
              text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
             }).then((result) => {
              if (result.value) {
                // const id = $(this).attr('data-id');
                // const parentId = $(this).parent().parent().attr('id').toString().replace('terms_', '');
                // const bo = that.businessObjects.find(e => e.id = parentId);

               // this.clicked.objects = this.clicked.objects.filter(e => e.cid != this.clicked.cid);
                const ed = 'end_' + this.clicked.cid;
                const st = 'start_' + this.clicked.cid;
                console.log(ed);
                const linksToRemove = this.edges.filter(e => e.source == ed || e.target == ed || e.source == st || e.target == st);
                console.log(linksToRemove);
                if (linksToRemove.length > 0) {
                  linksToRemove.map(
                    l => l.element.remove()
                  );
                }
                let foundIt1 = false;
                for (let i = 0; i < this.dataSource.businessObjects.length - 1; i++) {
                  if (this.dataSource.businessObjects[i].terms.indexOf(this.clicked) != -1 && this.dataSource.businessObjects[i].cid != this.clicked.cid) {
                    foundIt1 = true;
                  }
                }
                this.edges = this.edges.filter(e => e.source != ed && e.target != ed && e.source != st && e.target != st);
                $('#term_' + this.clicked.cid).remove();
                $('#play-area').click();
              }

              this.updateConnectors();
            }
          );
          break;
        case 'object':
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE ' + this.clicked.name + '?' : 'SUPPRIMER ' + this.clicked.name + ' ?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
           }).then((result) => {
            if (result.value) {
              if (this.clicked != null) {
                if (this.clicked.terms != null) {
                  this.clicked.terms.map(
                    t => {
                      const ted = 'end_' + t.cid;
                      const tst = 'start_' + t.cid;
                      const termsLinksToRemove = this.edges.filter(e => e.source == ted || e.target == ted || e.source == tst || e.target == tst);
                       console.log(1, termsLinksToRemove);
                      if (termsLinksToRemove.length > 0) {
                        for(let i=0; i < termsLinksToRemove.length; i++){
                          termsLinksToRemove[i].element.remove();
                          this.edges.splice(this.edges.indexOf(termsLinksToRemove[i]) , 1);
                        }
                        console.log(1, this.edges);
                      }
                      let foundIt = false;
                      for (let i = 0; i < this.dataSource.businessObjects.length - 1; i++) {
                        if (this.dataSource.businessObjects[i].terms.indexOf(t) != -1 && this.dataSource.businessObjects[i].cid != this.clicked.cid) {
                          foundIt = true;
                        }
                      }
                      if (!foundIt) {
                        this.dataSource.businessTerms.splice(this.dataSource.businessTerms.indexOf(t), 1);
                      }
                    }
                  );
                }
                const ed = 'end_' + this.clicked.cid;
                const st = 'start_' + this.clicked.cid;
                const linksToRemove = this.edges.filter(e => e.source == ed || e.target == ed || e.source == st || e.target == st);
                console.log(2, linksToRemove);
                if (linksToRemove.length > 0) {
                  for(let i=0; i < linksToRemove.length; i++){
                    linksToRemove[i].element.remove();
                    this.edges.splice(this.edges.indexOf(linksToRemove[i]) , 1);
                  }
                 // this.edges = this.edges.filter(e => e.source != ed && e.target != ed && e.source != st || e.target != st);
                  console.log(2, this.edges);
                }
                this.resizeObserver.unobserve(document.getElementById(this.clicked.cid));
                $('#' + this.clicked.cid).remove();
                this.dataSource.businessObjects.splice(this.dataSource.businessObjects.indexOf(this.clicked), 1);
                if(this.dataSource.businessObjects.length > 0){
                  this.resizeObserver.observe(this.dataSource.businessObjects[0].cid);
                }
                $('#play-area').click();
              }

              this.updateConnectors();
            }
          });
          break;
        case 'synonym object':
        case 'synonym term':
        case 'antonym object':
        case 'antonym term':
        case 'hyperonym object':
        case 'hyperonym term':
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE ' + this.clicked.name + '?' : 'SUPPRIMER ' + this.clicked.name + ' ?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
           }).then((result) => {
            if (result.value) {
              this.getEdgesForRemoveSynonym(this.clicked);
              const t = this.draggables.find(item => item.id == this.clicked.cid);
              if (t != undefined) {
               // this.resizeObserver.unobserve(document.getElementById(this.clicked.cid));
                t.element.remove();
                this.draggables = this.draggables.filter(item => item.id != this.clicked.cid);
                $('#' + this.clicked.cid).remove();
                this.dataSource.synonyms.splice(this.dataSource.synonyms.indexOf(this.dataSource.synonyms.find(e => e.cid == this.clicked.cid)), 1);
                $('#play-area').click();
              }
            }
          });
          break;
        default:
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE ' + this.clicked.text + '?' : 'SUPPRIMER ' + this.clicked.text + ' ?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !'
           }).then((result) => {
            if (result.value) {
              console.log(this.clicked);
              const nodeItem = this.nodesItems.find(e => e.id == this.clicked.id);
              this.getEdgesForRemove(nodeItem);
              const t = this.draggables.find(item => item.id == this.clicked.id);
              if (t != undefined) {
                t.element.remove();
                this.draggables = this.draggables.filter(item => item.id != this.clicked.id);
                this.resizeObserver.unobserve(document.getElementById(this.clicked.id));
                $('#' + this.clicked.id).remove();
                this.nodesItems.splice(this.nodesItems.indexOf(this.nodesItems.find(e => e.id == this.clicked.id)), 1);
                if(this.nodesItems.length > 0){
                  this.resizeObserver.observe(this.nodesItems[0].cid);
                }
                $('#play-area').click();
              }
            }
          });
          break;
      }
    }
  }
  showTerm(id: any) {
    $('#termModalDetail').modal();
    const data = this.selectedTerm = this.clicked.terms.find(e => e.id == id);
    this.selectedTerm.definition  = this.selectedTerm.definition != null ? this.selectedTerm.definition.toString().replace(/__________/g, '/') : '';
    this.selectedTerm.definition = this.selectedTerm.definition != null ? this.selectedTerm.definition?.replace(/::::::::/g, '&'): '';
    this.selectedTerm.effective = this.selectedTerm.effective.date != undefined? this.selectedTerm.effective.date: this.selectedTerm.effective;
    console.log(this.selectedTerm)
    // console.log(id, this.clicked);
    // console.log(this.businessObjects);
    // console.log(data)
    const columnDefs = [
      {
         data: 'name',
         title: this.lang === 'en' ? 'Name' : 'Nom'
      },
      {
         data: 'oldId',
         title: this.lang === 'en' ? 'OwnId' : 'PropreId'
      },
      {
         data: 'status',
         title: this.lang === 'en' ? 'Status' : 'Statut'
      },
      {
         data: 'effective',
         title: this.lang === 'en' ? 'Effective Date' : 'Date Effective'
      },
      {
         data: 'completion',
         title: this.lang === 'en' ? 'Completion' : 'Complétion'
      },
      {
         data: 'definition',
         title: this.lang === 'en' ? 'Definition' : 'Définition'
      },
      {
         data: 'example',
         title: this.lang === 'en' ? 'Example' : 'Exemple'
      }
     ];
     
    // $('#term-modal-table').DataTable().destroy();
    // $('#term-modal-table').empty();
    // const termInfo = [];
    // if(data != null) {
    //   termInfo.push(data);
    //   $('#term-modal-table').DataTable({
    //     data: termInfo,
    //     columns: columnDefs
    //   });
    // }
  }
  addOldBusinessObject(vals) {
   // const vals = this.businessListFrm.value.btList;
    console.log(vals);
    console.log(this.dataSource.synonyms);
    if (this.dataSource.businessObjects.find(e => e.cid == vals.cid) == null) {
      const terms = [];
      const ids = [];
      const boSynonyms = [];
      const boAntonyms = [];
      const boHyperonyms = [];
      if (vals.terms != null) {
        if (vals.terms.length > 0) {
          vals.terms.map(t => {
            const connectors = [];
            const objects = [];
            const  synonyms = [];
            const btAntonyms = [];
            const btHyperonyms = [];
            connectors.push({id: 'end_' + t});
            ids.push({id: t});
            if(this.dataSource.businessTerms.find(e => e.cid == t) == null){
              const term = this.terms.find(e => e.cid == t);
              if (term != null) {
                if (term.objects != null) {
                  if (term.objects.length > 0) {
                    term.objects.map(o => {
                      const bot = this.objectsList.find(b => b.cid == o);
                      if (bot != null) {
                        objects.push(bot);
                      }
                    });
                  }
                }
                if (term.synonyms != null) {
                  if (term.synonyms.length > 0) {
                    term.synonyms.map(o => {
                      const syn = this.synonyms.find(s => s.cid == o);
                      if (syn != null) {
                        //  const sy = new Synonym(syn.id,syn.name,syn.terms, syn.objects, syn.businessId,'', syn.effective, syn.status,syn.completion,syn.definition, syn.example, syn.oldId, syn.type, {top: 0, left: 0});
                        synonyms.push(syn);
                      }
                    });
                  }
                }
                if (term.antonyms != null) {
                  if (term.antonyms.length > 0) {
                    term.antonyms.map(o => {
                      const syn = this.synonyms.find(s => s.cid == o);
                      if (syn != null) {
                        btAntonyms.push(syn);
                      }
                    });
                  }
                }
                if (term.hyperonyms != null) {
                  if (term.hyperonyms.length > 0) {
                    term.hyperonyms.map(o => {
                      const syn = this.synonyms.find(s => s.cid == o);
                      if (syn != null) {
                        btHyperonyms.push(syn);
                      }
                    });
                  }
                }
                // @ts-ignore
                const bt = new BusinessItem(term.id, term.businessId, term.name, objects, synonyms, btAntonyms, btHyperonyms, '', term.effective.date, term.status, 0, term.definition, term.example, term.oldId, connectors, term.cid,term.toAdd, term.toEdit);
                terms.push(bt);
                this.dataSource.businessTerms.push(bt);
              }
            }
          });
        }
      }

      if (vals.synonyms != null) {
        if (vals.synonyms.length > 0) {
          for(let i =0; i < vals.synonyms.length; i++ ){
            const sy = this.synonyms.find(se => se.cid == vals.synonyms[i]);
            if (sy != null) {
              sy.effective = sy.effective.date.toString().substr(0, 10);
              sy.style = {top: this.offset.top, left: this.offset.left + 300};
              boSynonyms.push(sy);
              if(this.dataSource.synonyms.find(t => t.cid == vals.synonyms[i]) == null){
                this.addSynonymHtml(sy);
               // this.dataSource.synonyms.push(sy);
                const groupId = this.create_UUID();
                setTimeout(()=> {
                  this.addLine('end_' + sy.cid, 'start_' + vals.cid, 'gray', 'rgb(43,127,144)', false, true, vals.name, sy.name, groupId, 'fluid', 'auto', 'auto', '', 'gray', 12, 'normal', 'normal', false, 'synonymObject', 'businessObject');
                },1000);
              }
            }
          }
        }
      }
      if (vals.antonyms != null) {
        if (vals.antonyms.length > 0) {
          for(let i = 0; i < vals.antonyms.length; i++ ){
            const sy = this.antonyms.find(se => se.cid == vals.antonyms[i]);
            if (sy != null) {
              if(sy.effective!=null) {
                if (sy.effective.date != null) {
                  sy.effective = sy.effective.date.toString().substr(0, 10);
                }
              }
              sy.style = {top: this.offset.top, left: this.offset.left + 300};
              boAntonyms.push(sy);
              if(this.dataSource.synonyms.find(t => t.cid == vals.antonyms[i]) == null){
                this.addSynonymHtml(sy);
               // this.dataSource.synonyms.push(sy);
                const groupId = this.create_UUID();
                setTimeout(()=> {
                  this.addLine('end_' + sy.cid, 'start_' + vals.cid, 'gray', 'rgb(43,127,144)', false, true, vals.name, sy.name, groupId, 'fluid', 'auto', 'auto', '', 'gray', 12, 'normal', 'normal', false, 'antonymObject', 'businessObject');
                },1000);
              }
            }
          }
        }
      }
      if (vals.hyperonyms != null) {
        if (vals.hyperonyms.length > 0) {
          for(let i = 0; i < vals.hyperonyms.length; i++ ){
            const sy = this.hyperonyms.find(se => se.cid == vals.hyperonyms[i]);
            if (sy != null) {
              sy.effective = sy.effective.date.toString().substr(0, 10);
              sy.style = {top: this.offset.top, left: this.offset.left + 300};
              boHyperonyms.push(sy);
              if(this.dataSource.synonyms.find(t => t.cid == vals.hyperonyms[i]) == null){
                this.addSynonymHtml(sy);
               // this.dataSource.synonyms.push(sy);
                const groupId = this.create_UUID();
                setTimeout(()=> {
                  this.addLine('end_' + sy.cid, 'start_' + vals.cid, 'gray', 'rgb(43,127,144)', false, true, vals.name, sy.name, groupId, 'fluid', 'auto', 'auto', '', 'gray', 12, 'normal', 'normal', false, 'hyperonymObject', 'businessObject');
                },1000);
              }
            }
          }
        }
      }

      // @ts-ignore
      const businessObject = new BusinessObject(vals.id, vals.name, terms, boSynonyms, boAntonyms, boHyperonyms, vals.businessId, '', vals.effective.date, vals.status, 0, vals.definition, vals.example, vals.oldId, vals.cid, vals.style, vals.toAdd, vals.toEdit);
      this.addBusinessObjectHtml(businessObject);
      $('#businessListModal').modal('hide');
      ids.push({id: vals.id});
     // this.dataSource.businessObjects.push(businessObject);
      this.lockBusinessTerm(ids);
    }/* else {
      Swal.fire({
        title: 'Error!',
        text: 'Business Object ' + vals.name + ' is already in the viewport',
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }*/
  }
  addOldSynonym() {
    const vals = this.synonymListFrm.value.syList;
    console.log(vals);
    console.log(this.dataSource.synonyms);
    if (this.dataSource.synonyms.find(e => e.cid == vals.cid) == null) {
      const terms = [];
      const objects = [];
      const ids = [];
      if (vals.type == 'synonym term' || vals.type == 'antonym term' || vals.type == 'hyperonym term') {
        vals.terms.map(t => {
          const connectors = [];
          connectors.push({id: 'end_' + t});
          ids.push({id: t});
          const term = this.terms.find(e => e.cid == t);
          if (this.dataSource.businessTerms.find(bus => bus.cid == t) == null) {
            if (term != null) {
              if (term.objects != null) {
                if (term.objects.length < 0) {
                  term.objects.map(
                    ter => {
                      const boi = this.dataSource.businessTerms.find(ty => ty.cid == ter);
                      if (boi == null) {
                        const bot = this.objectsList.find(r => r.cid == ter);
                        if(bot!=null){
                          bot.style = {top: this.offset.top, left: this.offset.left + 300};
                          this.addOldBusinessObject(boi);
                        }
                      }
                    }
                  );
                }
              }
              const bt = new BusinessItem(term.id, term.businessId, term.name, term.objects, term.synonyms, term.antonyms, term.hyperonyms, '', term.effective.date, term.status, 0, term.definition, term.example, term.oldId, connectors, term.cid, term.toAdd, term.toEdit);
              terms.push(bt);
              this.dataSource.businessTerms.push(bt);
            }
          }
        });
      } else {
        vals.objects.map(t => {
          const connectors = [];
          connectors.push({id: 'end_' + t});
          ids.push({id: t});
          const object = this.dataSource.businessObjects.find(e => e.cid == t);
          if (object == null) {
            const bot = this.objectsList.find(r => r.cid == t);
            if (bot != null) {
              bot.style = {top: this.offset.top, left: this.offset.left + 300};
            //console.log(this.offset);
           // console.log(bot.style);
            this.addOldBusinessObject(bot);
            objects.push(bot);
          }
          }
        });
      }
        const synonym = new Synonym(vals.id, vals.name, terms, objects, vals.businessId, '', vals.effective.date, vals.status, 0, vals.definition, vals.example, vals.oldId, vals.type, vals.cid, this.offset, false, false);
        this.addSynonymHtml(synonym);

        $('#synoymListModal').modal('hide');
        window.scrollBy(5, 100);
        window.scrollBy(-5, -100);
        ids.push({id: vals.id});
        if(this.dataSource.synonyms.find(t => t.cid == synonym.cid)!=null){
          this.dataSource.synonyms.push(synonym);
        }
        if (objects.length > 0) {
          objects.map(
            ob => {
              const groupId = this.create_UUID();
              this.addLine('end_' + vals.cid, 'start_' + ob.cid, 'gray', 'rgb(43,127,144)', false, true, vals.name, ob.name, groupId, 'fluid', 'auto', 'auto', '', 'gray', 12, 'normal', 'normal', false, 'businessObject', 'synonymObject');
            }
          );
        }
      this.lockBusinessTerm(ids);
    } else {
      Swal.fire({
        title: this.lang === 'en' ? 'Error!' : 'Erreur!',
        text: this.lang === 'en' ? 'Semantic ' + vals.name + ' is already in the viewport' : 'Le sémantique ' + vals.name + ' est déjà dans le port d\'affichage',
        icon: 'error',
        confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
       });
       
    }
    this.dataSource.synonyms = Array.from(this.dataSource.synonyms.reduce((m, t) => m.set(t.cid, t), new Map()).values());
   // console.log(this.dataSource.synonyms);
  }
  lockBusinessTerm(ids) {
    this.businessTermService.lockBusinessTerm(ids).subscribe(
      d => {console.log('locked successfully'); },
      err => {console.error(err); }
    );
  }
  getBusinessTermsList() {
    this.synonymLoading = true;
    this.businessTermService.getBusinessTermsList().subscribe(
      d => {
        this.synonymLoading = false;
        this.allBusinessItems = d;
        this.terms = this.allBusinessItems.filter(e => e.type == 'term');
        this.objectsList = this.allBusinessItems.filter(e => e.type == 'object');
        this.synonyms = this.allBusinessItems.filter(e => e.type == 'synonym object' || e.type == 'synonym term');
        this.antonyms = this.allBusinessItems.filter(e => e.type == 'antonym object' || e.type == 'antonym term');
        this.hyperonyms = this.allBusinessItems.filter(e => e.type == 'hyperonym object' || e.type == 'hyperonym term');
      },
      err => {
        console.error(err);
      }
    );
  }
  showToolTip(id: string) {
    const that = this;
    $(id).unbind('mouseover').on('mouseover', function(event) {
      console.log($(this).attr('data-title'))
     $('#toolTipMap').html($(this).attr('data-title')).offset({top: event.clientY + that.yMousePos, left: event.clientX + that.xMousePos + 15 }).show();
    });
    $(id).on('mouseout', function() {
      $('#toolTipMap').offset({top: 0, left: 0}).html('').hide();
    });
  }
  changeLinkDescription() {
    const index = this.edges.indexOf(this.selectedEdge);
    if (index != -1) {
      this.edges[index].middleLabel = this.selectedLinkDescription;
      this.edges[index].path = this.selectedLinkPath;
      this.edges[index].dropShadow = this.selectedLinkShadow;
      this.edges[index].middleLabel = this.selectedLinkDescription;
      this.edges[index].labelColor = this.selectedLinkDescriptionColor;
      this.edges[index].labelSize = this.selectedLinkDescriptionSize;
      this.edges[index].labelStyle = this.selectedLinkDescriptionStyle;
    }
    this.selectedEdge.element.setOptions({
      path: this.selectedLinkPath,
      dropShadow: this.selectedLinkShadow,
      middleLabel: LeaderLine.captionLabel(this.selectedLinkDescription, {color: this.selectedLinkDescriptionColor, fontSize: this.selectedLinkDescriptionSize, fontStyle: this.selectedLinkDescriptionStyle, fontWeight: this.selectedLinkDescriptionWeigt}),
    });
    console.info(this.edges);
  }
  changeLinkColor(event: ColorEvent){
    this.selectedLinkDescriptionColor = event.color.hex;
    const index = this.edges.indexOf(this.selectedEdge);
    if (index != -1) {
      this.edges[index].middleLabel = this.selectedLinkDescription;
      this.edges[index].labelColor = this.selectedLinkDescriptionColor;
      this.edges[index].labelSize = this.selectedLinkDescriptionSize;
      this.edges[index].labelStyle = this.selectedLinkDescriptionStyle;
      this.edges[index].labelWeight = this.selectedLinkDescriptionWeigt;
    }
    this.selectedEdge.element.setOptions({
      path: this.selectedLinkPath,
      dropShadow: this.selectedLinkShadow,
      middleLabel: LeaderLine.captionLabel(this.selectedLinkDescription, {color: this.selectedLinkDescriptionColor, fontSize: this.selectedLinkDescriptionSize, fontStyle: this.selectedLinkDescriptionStyle, fontWeight: this.selectedLinkDescriptionWeigt}),
    });
  }
  /*
      Returns the bounding rectangle of a jQuery element
      {x,y,w,h}
  */
  getBounds(el) {
    const pos = el.position();
    return {
      x: pos.left,
      y: pos.top,
      w: el.width(),
      h: el.height()
    };
  }

  /*
      Checks for overlap on two rectangles
  */
  hitTest(rectA, rectB) {

    const rA = rectA.x + rectA.w; // Right side of rectA
    const rB = rectB.x + rectB.w; // Right side of rectB
    const bA = rectA.y + rectA.h; // Bottom of rectA
    const bB = rectB.y + rectB.h; // Bottom of rectB

    const hitX = rA > rectB.x && rectA.x < rB; // True if hit on x-axis
    const hitY = bA > rectB.y && rectA.y < bB; // True if hit on y-axis

    // Return true if hit on x and y axis
    return hitX && hitY;
  }

  saveFunctionalMap() {

    this.dataSource.businessObjects.map(
      e => {e.synonyms = [];e.antonyms = [];e.hyperonyms = [];}
    );
     // console.log('nodeitem',this.nodesItems);
     // console.log(this.edges);
    const catalogs = [];
    const businessTermCatalogs = [];
    const ids = [];
    this.dataSource.businessObjects.map(item => {
      const obj = $('#' + item.cid);
      item.style = obj.offset();
      if (item.terms.length > 0) {
        item.terms.map(
          i => {
            const bt = this.dataSource.businessTerms.find(bt => bt.cid == i.cid);
            if (bt.objects.indexOf(item.cid) == -1) {
              bt.objects.push(item.cid);
            }
          }
        );
      }
    });

  //  if (this.edges.length > 0) {
      const btct = this.edges.filter(e => e.startType == 'object');
      const btct1 = this.edges.filter(e => e.startType == 'field');
      const btct2 = this.edges.filter(e => e.endType == 'object');
      const btct3 = this.edges.filter(e => e.endType == 'field');

      // case catalog and business object
      if (btct.length > 0) {
        for (let i = 0; i < btct.length; i++) {
           const sr = btct[i].source.replace('start_', '');
           const tr = btct[i].source;
           const extraData = $('#' + $.escapeSelector(tr)).attr('extra-data');
           const nodeId = $('#' + $.escapeSelector(tr)).attr('node-id');
           let node = null;
           if(nodeId != null){
             node = this.nodesItems.find(e => e.id == nodeId);
           }
           let bot = null;
           bot = this.dataSource.businessObjects.find(e => e.name == btct[i].targetName);
          const t = {catalog: extraData == 'nsp7894523' ? sr.split(':')[1] : extraData.split(':')[1], application: extraData == 'nsp7894523' ? sr.split(':')[0] : extraData.split(':')[0], businessTerm: btct[i].targetName, node, bot, source: btct[i].source, target: btct[i].target};
          if (businessTermCatalogs.find(e => e.catalog == t.catalog && e.application == t.application && e.businessTerm == t.businessTerm) == null){
            businessTermCatalogs.push(t);
          }
        }
      }
      // case catalog and business term
      if (btct1.length > 0) {
        for (let i = 0; i < btct1.length; i++) {
           const sr = btct1[i].source.replace('start_', '');
          const tr = btct1[i].source;
          const nodeId = $('#' + $.escapeSelector(tr)).attr('node-id');
          const extraData = $('#' + $.escapeSelector(tr)).attr('extra-data');
          let node = null;
          if(nodeId != null){
            node = this.nodesItems.find(e => e.id == nodeId);
          }
          let bot = null;
          bot = this.dataSource.businessTerms.find(e => e.name == btct1[i].targetName);
          const t = {catalog: extraData == 'nsp7894523' ? sr.split(':')[1] : extraData.split(':')[1], application: extraData == 'nsp7894523' ? sr.split(':')[0] : extraData.split(':')[0], businessTerm: btct1[i].targetName, node, bot, source: btct1[i].source, target: btct1[i].target};
          if (businessTermCatalogs.find(e => e.catalog == t.catalog && e.application == t.application && e.businessTerm == t.businessTerm) == null){
            businessTermCatalogs.push(t);
          }
        }
      }
      // case business object and catalog
      if (btct2.length > 0) {
        for (let i = 0; i < btct2.length; i++) {
          const tr = btct2[i].target.replace('start_', '');
          const sr = btct2[i].target;
          const nodeId = $('#' + $.escapeSelector(sr)).attr('node-id');
          const extraData = $('#' + $.escapeSelector(sr)).attr('extra-data');
          let node = null;
          if (nodeId != null) {
            node = this.nodesItems.find(e => e.id == nodeId);
          }
          let bot = null;
          bot = this.dataSource.businessObjects.find(e => e.name == btct2[i].sourceName);
          const t = {
            catalog: extraData == 'nsp7894523' ? tr.split(':')[1] : extraData.split(':')[1],
            application: extraData == 'nsp7894523' ? tr.split(':')[0] : extraData.split(':')[0],
            businessTerm: btct2[i].sourceName,
            node,
            bot,
            source: btct2[i].source,
            target: btct2[i].target
          };
          if (businessTermCatalogs.find(e => e.catalog == t.catalog && e.application == t.application && e.businessTerm == t.businessTerm) == null) {
            businessTermCatalogs.push(t);
          }
        }
      }
      //  console.log(btct3.length)
        // case business term and catalog
        if (btct3.length > 0) {
          for (let i = 0; i < btct3.length; i++) {
             const tr = btct3[i].target.replace('start_', '');
            const sr = btct3[i].target;
            const nodeId = $('#' + $.escapeSelector(sr)).attr('node-id');
            const extraData = $('#' + $.escapeSelector(sr)).attr('extra-data');
            let node = null;
            if(nodeId != null){
              node = this.nodesItems.find(e => e.id == nodeId);
            }
            let bot = null;
            bot = this.dataSource.businessTerms.find(e => e.name == btct3[i].sourceName);
            const t = {catalog: extraData == 'nsp7894523' ? tr.split(':')[1] : extraData.split(':')[1], application: extraData == 'nsp7894523' ? tr.split(':')[0] : extraData.split(':')[0], businessTerm: btct3[i].sourceName, node, bot, source: btct3[i].source, target: btct3[i].target};
         //   console.log(t)
            if (businessTermCatalogs.find(e => e.catalog == t.catalog && e.application == t.application && e.businessTerm == t.businessTerm) == null){
              businessTermCatalogs.push(t);
            }
          }
        }

     // console.log(businessTermCatalogs);return;
      this.nodesItems.map(item => {
        const obj = $('#' + item.id);
        item.style = obj.offset();
        if (item.data != null) {
          if (item.data.length > 0) {
            item.data.map(
              d => {
                if (catalogs.indexOf(d.model.id) == -1) {
                  catalogs.push(d.model.id);
                }
              }
            );
          }
        }
      });
      this.dataSource.synonyms.map(item => {
        const obj = $('#' + item.cid);
        item.style = obj.offset();
        const target = 'end_' + item.cid;
        const links = this.edges.filter(e => e.source == target || e.target == target);
        if (links.length > 0) {
          links.map(
            l => {
              if (l.source != target) {
                const sy = l.source.replace('start_', '');
                if (item.type == 'synonym object' || item.type == 'antonym object' || item.type == 'hyperonym object') {
                  if (item.objects.indexOf(sy) == -1) {
                    item.objects.push(sy);
                    if (item.type == 'synonym object' ) {
                      this.dataSource.businessObjects.find(b => b.cid == sy).synonyms.push(item.cid);
                    }
                    if (item.type == 'antonym object') {
                      this.dataSource.businessObjects.find(b => b.cid == sy).antonyms.push(item.cid);
                    }
                    if (item.type == 'hyperonym object') {
                      this.dataSource.businessObjects.find(b => b.cid == sy).hyperonyms.push(item.cid);
                    }
                  }

                } else {
                  if (item.terms.indexOf(sy) == -1) {
                    item.terms.push(sy);
                    if (item.type == 'synonym term' ) {
                      this.dataSource.businessTerms.find(b => b.cid == sy).synonyms.push(item.cid);
                    }
                    if (item.type == 'antonym term' ) {
                      this.dataSource.businessTerms.find(b => b.cid == sy).antonyms.push(item.cid);
                    }
                    if (item.type == 'hyperonym term' ) {
                      this.dataSource.businessTerms.find(b => b.cid == sy).hyperonyms.push(item.cid);
                    }
                  }
                }
              }
              if (l.target != target) {
                const sy = l.target.replace('start_', '');
                if (item.type == 'synonym object' || item.type == 'antonym object' || item.type == 'hyperonym object') {
                  if (item.objects.indexOf(sy) == -1) {
                    item.objects.push(sy);
                    if (item.type == 'synonym object' ) {
                      this.dataSource.businessObjects.find(b => b.cid == sy).synonyms.push(item.cid);
                    }
                    if (item.type == 'antonym object') {
                      this.dataSource.businessObjects.find(b => b.cid == sy).antonyms.push(item.cid);
                    }
                    if (item.type == 'hyperonym object') {
                      this.dataSource.businessObjects.find(b => b.cid == sy).hyperonyms.push(item.cid);
                    }
                  }

                } else {
                  if (item.terms.indexOf(sy) == -1) {
                    item.terms.push(sy);
                    if (item.type == 'synonym term' ) {
                      this.dataSource.businessTerms.find(b => b.cid == sy).synonyms.push(item.cid);
                    }
                    if (item.type == 'antonym term' ) {
                      this.dataSource.businessTerms.find(b => b.cid == sy).antonyms.push(item.cid);
                    }
                    if (item.type == 'hyperonym term' ) {
                      this.dataSource.businessTerms.find(b => b.cid == sy).hyperonyms.push(item.cid);
                    }
                  }
                }
              }
            }
          );
        }
      });
      // delete doubles values
      this.dataSource.businessTerms = Array.from(this.dataSource.businessTerms.reduce((m, t) => m.set(t.cid, t), new Map()).values());
      this.dataSource.businessObjects = Array.from(this.dataSource.businessObjects.reduce((m, t) => m.set(t.cid, t), new Map()).values());
      this.dataSource.businessTerms.forEach(e => {
        const index = e.objects.findIndex(t => t.name == undefined);
        if(index != -1){
          e.objects.splice(index,1);
        }
      });
      const jsonDataSource = {
        id: this.functionalBridge.id,
        businessObjects: this.dataSource.businessObjects,
        businessTerms: this.dataSource.businessTerms,
        synonyms: this.dataSource.synonyms,
        catalogues: this.nodesItems,
        links: this.edges,
        styles: {flatDesign: this.flatDesign, pattern: this.pattern, zoom: this.zoom, patternColor: this.patternColor, flexBasis: $('#play-area').css('flex-basis'), zoomFontSize: this.zoomFontSize, zoomWidth: this.zoomWidth, zoomLine: this.zoomLine},
        createdBy: localStorage.getItem('username'),
        catalogs,
        businessTermCatalogs
      };
      jsonDataSource.businessObjects.forEach(e => {
        e.name = this.skipString(e.name);
        e.definition = this.skipString(e.definition);
        e.terms.forEach(o => {
          o.name = this.skipString(o.name);
          o.definition = this.skipString(o.definition);
        })
      });
    jsonDataSource.businessTerms.forEach(e => {
      e.name = this.skipString(e.name);
      e.definition = this.skipString(e.definition);
      e.objects.forEach(o => {
        o.name = this.skipString(o.name);
        o.definition = this.skipString(o.definition);
      })
    });
      // const content = JSON.stringify(jsonDataSource);
      // console.table(ids);
      // console.table(this.nodesItems);
      // console.table(this.dataSource.businessObjects);
      // console.table(this.dataSource.businessTerms);
      // console.table(this.dataSource.synonyms);
      // console.table(this.edges);
      // console.table(catalogs);
      // console.table(businessTermCatalogs);
      // return;
     // addLoader('S A V I N G&#160;&#160;D A T A ');
      addProgressBar(0, this.lang  ==='en ' ? 'S A V I N G&#160;&#160;D A T A...':'E N R E G I S T R E M E N T&#160;&#160;D E S&#160;&#160;D O N N E E S...');
      this.functionalMapService.saveFunctionalMapEditor(jsonDataSource).subscribe(
        d => {
          setProgressBarValue(50);
          $('#ldp').prev().html(this.lang  ==='en' ? 'generating image please wait...':'génération de l\'image veuillez patienter...');
          this.getScreenShot('upload');
         // removeLoader();
        },
        err => {
          console.error(err);
        }
      );
    //}
  }
  gotoFunctionalList() {
    let url  = 'data-lineage';
    if (this.functionalBridge.id != null && this.functionalBridge.id != 0) {
      let id = this.cryptoService.set(this.functionalBridge.id);
      id = id.replace(/\//g, '__________');
      let funcGroup = this.cryptoService.set(this.functionalBridge.funcGroup);
      funcGroup = funcGroup.replace(/\//g, '__________');
      url = 'Business-Glossary/Functional-map-group/' + encodeURIComponent(funcGroup) + '/' + encodeURIComponent(id); // '/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==';
    } else {
      url = 'Business-Glossary/Functional-map-group/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==';
    }
//    console.log(url);
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
  ceaserCipher(str) {
    // Deciphered reference letters
    const decoded = {
      a: 'n', b: 'o', c: 'p',
      d: 'q', e: 'r', f: 's',
      g: 't', h: 'u', i: 'v',
      j: 'w', k: 'x', l: 'y',
      m: 'z', n: 'a', o: 'b',
      p: 'c', q: 'd', r: 'e',
      s: 'f', t: 'g', u: 'h',
      v: 'i', w: 'j', x: 'k',
      y: 'l', z: 'm',
      ' ' : '1', '&' : '2', '+' : '3', '/' : '4', '_' : '5', '(' : '6', ')' : '7', '-' : '8', '*': '9', 'é' : '10', 'ç' : '11', ',' : '12', ';' : '13', '!' : '14'
    };

    // convert the string to lowercase
    str = str.toLowerCase();

    // decipher the code
    let decipher = '';
    for(let i = 0 ; i < str.length; i++){
      if(decoded[str[i]]!= undefined){
        decipher += decoded[str[i]];
      }
    }

    // return the output
    return decipher;
  }

  getScreenShot(action = 'download') {
    const convHtml = $('#play-area')[0].outerHTML.replaceAll('rsz-layout', 'div');
    const bgColor = $('#play-area').css('background-color');
    const width = $('#play-area').css('flex-basis');
    const height = $('#play-area').css('height');
    this.dataLineService.getScreenShot(convHtml, this.edges, true, this.pattern, bgColor, width, this.functionalBridge.id, action).subscribe(
      result => {
        if(action == 'upload'){
          setProgressBarValue(100);
          $('#divGlobalProgress').remove();
          this.Toast.fire({
            icon: 'success',
            title: 'Saved successfully'
          });
        }
         // console.log(result);
        if(action === 'download'){
          FileSaver.saveAs(result, this.dataLineageName);
        }
      },
      err => {
        $('#divGlobalProgress').remove();
        console.error(err);
      }
    );
  }
  updateItemAddApp(e, id, type) {

    var tabM = [];

    // tabM = this.registerFormApp.get('metadataApps').value;
    //
    // tabM.forEach(element => {
    //
    //   if (element.idfield === id) {
    //     if (type === 'select') {
    //       var svs = e;
    //     } else {
    //       svs = e.target.value;
    //     }
    //     element.selectedvalue = svs;
    //
    //   }
    // });

    this.registerFormApp.get('metadataApps').patchValue(tabM);

  }
  onSubmitAppAsset() {
    // var desc = window.btoa(encodeURIComponent(this.registerFormApp.get('descApp').value));
    if (this.descriptionNewSystem != '') {
      this.registerFormApp.get('descApp').patchValue(btoa(this.descriptionNewSystem));
    } else {
      this.registerFormApp.get('descApp').patchValue(null);
    }
    if (this.descriptionNewModuleSystem != '') {
      this.registerFormApp.get('descriptionModuleSystem').patchValue(btoa(this.descriptionNewModuleSystem));
    } else {
      this.registerFormApp.get('descriptionModuleSystem').patchValue(null);
    }
    const effectiveDateNew = this.registerFormApp.get('effectDateNewApp').value;
    const endDateNew = this.registerFormApp.get('endDateNewApp').value;
    this.registerFormApp.get('external').patchValue(true);
    // const today = new Date();
    if (effectiveDateNew > this.todayDate) {
      this.registerFormApp.get('statusApp').patchValue(false);
    } else if (endDateNew != '') {
      if (endDateNew < this.todayDate) {
        this.registerFormApp.get('statusApp').patchValue(false);
      } else {
        this.registerFormApp.get('statusApp').patchValue(true);
      }
    } else {
      this.registerFormApp.get('statusApp').patchValue(true);
    }
    const username = localStorage.getItem('username');
    this.registerFormApp.get('createdBy').patchValue(username);
    const subscription16$ = this.appService.addApp(JSON.stringify(this.registerFormApp.value)).subscribe(
      d => {
        this.descriptionNewSystem = '';
        this.descriptionNewModuleSystem = '';
        this.getApplicationsByType('YES');
      },
      err => {
      },
      () => {

        this.filterDefinition.get('filterReport').patchValue('');
        this.filterDefinition.get('filterBase').patchValue('');
        this.filterDefinition.get('filterEnv').patchValue('');
        this.filterDefinition.get('filterApplication').patchValue('');
        this.filterDefinition.get('filterOneApp').patchValue('');
        this.resetbtnDef = false;
        this.startScript();
        $('#paginate_Apps').css('display', 'block');
        $('#model_page_sizeAp').css('display', 'block');
      }
    );
    this.subscription.add(subscription16$);
    // display form values on success
    this.registerFormApp.get('nameApp').patchValue('');
    this.registerFormApp.get('effectDateNewApp').patchValue('');
    this.registerFormApp.get('endDateNewApp').patchValue('');
    this.registerFormApp.get('versionApp').patchValue('');
    this.registerFormApp.get('statusApp').patchValue(true);
    this.registerFormApp.get('external').patchValue(true);
    this.registerFormApp.get('typeApp').patchValue('');
    this.registerFormApp.get('deploymentApp').patchValue('');
    this.registerFormApp.get('descApp').patchValue('');
    this.registerFormApp.get('moduleApp').patchValue('');
    this.descriptionNewModuleSystem = '';
    this.registerFormApp.get('manager').patchValue('');
    this.registerFormApp.get('owner').patchValue('');
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.onLoadDescriptionFoAllSystem('newSystem', 'descriptionSystem');
    setTimeout(() => {
      this.onLoadDescriptionFoAllSystem('newSystem', 'descriptionModuleSystem');
    }, 500);


  }
  // start ckeditor for all descriptions
  async onLoadDescriptionFoAllSystem(emplacement, description) {
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']]]; // , '-', 'NumberedList'
      setTimeout(() => {
        this.loadDescriptions(emplacement, description);
      }, 1000);
    }).catch(error => console.error(error));
  }
  private loadDescriptions(emplacement, nameDescription) {
    const that = this;
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances[nameDescription]) {
      CKEDITOR.instances[nameDescription].destroy(true);
    }
    let editor = CKEDITOR.replace(nameDescription, {toolbar: 'MA'});
    editor.config.height = 50;
    ////////////////////--------------------/////////////////////////
    if (emplacement === 'updateSystem') {
      if (this.descriptionUpdateSystem != null && this.descriptionUpdateSystem) {
        if (this.descriptionUpdateSystem.length > 0 || this.descriptionUpdateSystem != ' ') {
          // let decodeDesc = this.descriptionUpdateSystem.split(' ').join('+');
          editor.setData(this.descriptionUpdateSystem);
        }
      }
    }
    //////////////////----------------------///////////////////////////
    if (emplacement === 'updateVersionSystem') {
      if (this.descriptionUpdateVersion != null && this.descriptionUpdateVersion) {
        if (this.descriptionUpdateVersion.length > 0 || this.descriptionUpdateVersion != ' ') {
          // let decodeDescUpVer = this.descriptionUpdateVersion.split(' ').join('+');
          editor.setData(this.descriptionUpdateVersion);
        }
      }
    }
    //////////////////----------------------///////////////////////////
    if (emplacement === 'newVersionSystem') {
      if (this.descriptionNewVersion != null && this.descriptionNewVersion) {
        if (this.descriptionNewVersion.length > 0 || this.descriptionNewVersion != ' ') {
          // let decodeDescUpVer = this.descriptionUpdateVersion.split(' ').join('+');
          editor.setData(this.descriptionNewVersion);
        } else {
          editor.setData('');
        }
      } else {
        editor.setData('');
      }
    }
    //////////////////----------------------///////////////////////////
    if (emplacement === 'updateModuleSystem') {
      if (this.descriptionUpdateModule != null && this.descriptionUpdateModule) {
        if (this.descriptionUpdateModule.length > 0 || this.descriptionUpdateModule != ' ') {
          // let decodeDescUpVer = this.descriptionUpdateVersion.split(' ').join('+');
          editor.setData(this.descriptionUpdateModule);
        }
      }
    }
    //////////////////----------------------///////////////////////////
    if (emplacement === 'newModuleSystem') {
      if (this.descriptionNewModule != null && this.descriptionNewModule) {
        if (this.descriptionNewModule.length > 0 || this.descriptionNewModule != ' ') {
          // let decodeDescUpVer = this.descriptionUpdateVersion.split(' ').join('+');
          editor.setData(this.descriptionNewModule);
        } else {
          editor.setData('');
        }
      } else {
        editor.setData('');
      }
    }

    ////////////////////--------------------//////////////////////////
    editor.on('change', function (evt) {
      if (nameDescription == 'descriptionSystem') {
        // console.log('-- system -- ');
        that.descriptionNewSystem = evt.editor.getData();
        // console.log(that.descriptionNewSystem);
      } else if (nameDescription == 'descriptionModuleSystem') {
        that.descriptionNewModuleSystem = evt.editor.getData();
        // console.log('--- module ---');
        // console.log(that.descriptionNewModuleSystem);
      }
      if (emplacement === 'updateSystem') {
        that.descriptionUpdateSystem = evt.editor.getData();
        that.editedDescApp = true;
        // console.log('--- detail description app ---');
        // console.log(that.descriptionUpdateSystem);
        // console.log(btoa(that.descriptionUpdateSystem));
      }
      if (emplacement === 'updateVersionSystem') {
        that.descriptionUpdateVersion = evt.editor.getData();
        // that.editedDescApp = true;
        // console.log('--- detail description update version ---');
        // console.log(that.descriptionUpdateVersion);
        // console.log(btoa(that.descriptionUpdateVersion));
      }
      if (emplacement === 'updateModuleSystem') {
        that.descriptionUpdateModule = evt.editor.getData();
        // that.editedDescApp = true;
        // console.log('--- detail description update module ---');
        // console.log(that.descriptionUpdateModule);
        // console.log(btoa(that.descriptionUpdateModule));
      }
      if (emplacement === 'newVersionSystem') {
        that.descriptionNewVersion = evt.editor.getData();
        // that.editedDescApp = true;
        // console.log('--- detail description new version ---');
        // console.log(that.descriptionNewVersion);
        // console.log(btoa(that.descriptionNewVersion));
      }
      if (emplacement === 'newModuleSystem') {
        that.descriptionNewModule = evt.editor.getData();
        // that.editedDescApp = true;
        // console.log('--- detail description new module ---');
        // console.log(that.descriptionNewModule);
        // console.log(btoa(that.descriptionNewModule));
      }
    });
  }
  async startScript() {
    await this.dynamicScriptLoader.load('form.min').then(data => {
      // this.loadData();
      var comp = this;
      // effective date new system
      $('#effectDateNewApp').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var effedate = '';
          if ($(date).attr('_d') != undefined) {
            effedate = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            effedate = '';
          }
          comp.registerFormApp.get('effectDateNewApp').patchValue(effedate);
          comp.checkEffectDateNewSys();
        });
      // end date new system
      $('#endDateNewApp').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var enddate = '';
          if ($(date).attr('_d') != undefined) {
            enddate = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            enddate = '';
          }
          comp.registerFormApp.get('endDateNewApp').patchValue(enddate);
          comp.checkEndDateNewSys();
        });
      // INSERT EFFECTIVE VERSION DATE
      $('#addAppVD').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var dateappVD = '';
          if ($(date).attr('_d') != undefined) {
            dateappVD = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            dateappVD = '';
          }
          comp.editFormVer.get('effectiveDate').patchValue(dateappVD);
          comp.checkVerDateU();
        });
      // INSERT END VERSION DATE
      $('#addAppVED').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var dateappVD = '';
          if ($(date).attr('_d') != undefined) {
            dateappVD = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            dateappVD = '';
          }
          comp.editFormVer.get('endDateApp').patchValue(dateappVD);
          comp.checkVerEndDateU();
        });
      // INSERT UPDATE END DATE MODULE
      $('#addAppModD').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var dateappVD = '';
          if ($(date).attr('_d') != undefined) {
            dateappVD = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            dateappVD = '';
          }
          comp.editFormMod.get('endDate').patchValue(dateappVD);
          comp.checkModEndDateU();

        });

    }).catch(erro => console.error(erro));
  }
  checkEffectDateNewSys() {
    // console.log('check effective date');
    this.messagedangerNewSys = '';
    this.messagesuccesNewSys = '';
    const effectiveDateNew = this.registerFormApp.get('effectDateNewApp').value;
    const endDateNew = this.registerFormApp.get('endDateNewApp').value;
    if (effectiveDateNew != '') {
      // var x = this.versions.map(e => e.effectiveDate);
      // if (x.includes(effectiveDateNew)) {
      //     this.presubmitVer = true;
      //     this.messagedangerVer = effectiveDateNew + ' exist choose another date !';
      // } else
      if ((endDateNew !== null) && (endDateNew !== '') && (endDateNew !== undefined) && (effectiveDateNew > endDateNew)) {
        this.presubmitNewSystem = true;
        this.messagedangerNewSys = this.lang === 'en' ? 'Effective-Date must not be later than End-Date !' : 'La date effective ne doit pas être postérieure à la date de fin !';
      }  else {
        this.presubmitNewSystem = false;
        this.messagesuccesNewSys = this.lang === 'en' ? effectiveDateNew + ' is available !' : effectiveDateNew + ' est disponible !';

      }
    } else {
      this.presubmitNewSystem = true;
      this.messagedangerNewSys = this.lang === 'en' ? 'Effective Date is empty !' : 'La date effective est vide !';

    }
  }
  checkEndDateNewSys() {
    this.messagedangerNewSys = '';
    this.messagesuccesNewSys = '';
    var editdE = this.registerFormApp.get('effectDateNewApp').value;
    if (editdE != '') {
      const strdate = editdE.split('-');
      editdE = strdate[0];
    }
    var editd = this.registerFormApp.get('endDateNewApp').value;
    if ((editd == null) || (editd == '') || (editd == undefined)) {
      this.presubmitNewSystem = false;
      this.messagedangerNewSys = '';
      this.messagesuccesNewSys = '';
      this.registerFormApp.get('endDateNewApp').patchValue('');
    } else {
      if (editdE == '') {
        this.presubmitNewSystem = true;
        this.messagedangerNewSys = this.lang === 'en' ? 'Effective Date is empty !' : 'La date effective est vide !';
        this.registerFormApp.get('endDateNewApp').patchValue('');
      } else {
        if (editd > editdE) {

          this.presubmitNewSystem = false;
          this.messagedangerNewSys = '';
          this.messagesuccesNewSys = '';
        } else {
          this.presubmitNewSystem = true;
          this.messagedangerNewSys = this.lang === 'en' ? 'End date should be > of effective date !' : 'La date de fin doit être > de la date effective !';
          this.registerFormApp.get('endDateNewApp').patchValue('');
        }
      }
    }
  }
  checkVerDateU() {
    this.messagedangerVerU = '';
    this.messagesuccesVerU = '';
    var effDate = this.editFormVer.get('effectiveDate').value;
    // console.log(effDate);
    var endDate = this.editFormVer.get('endDateApp').value;
    // console.log('-----');
    // console.log(endDate);
    if (effDate != '') {
      var x = this.versions.map(e => e.effectiveDate);
      if (x.includes(effDate)) {
        this.presubmitVerU = true;
        this.messagedangerVerU = this.lang === 'en' ? effDate + ' exist choose another date!' : effDate + ' existe, choisissez une autre date !';
      } else if ((endDate != null) && (endDate != '') && (endDate != undefined) && (effDate > endDate)) {
        this.presubmitVerU = true;
        this.messagedangerVerU = this.lang === 'en' ? 'Effective-Date must not be later than End-Date!' : 'La date effective ne doit pas être postérieure à la date de fin !';
      } else {
        this.presubmitVerU = false;
        this.messagesuccesVerU = this.lang === 'en' ? effDate + ' is available!' : effDate + ' est disponible !';
      }
    } else {
      this.presubmitVerU = true;
      this.messagedangerVerU = this.lang === 'en' ? 'Effective Date is empty!' : 'La date effective est vide !';
    }
  }
  checkVerEndDateU() {
    this.messagedangerVerU = '';
    this.messagesuccesVerU = '';
    var editdE = this.editFormVer.get('effectiveDate').value;
    var editd = this.editFormVer.get('endDateApp').value;
    if (editdE == '') {
      this.presubmitVerU = true;
      this.messagedangerVerU = this.lang === 'en' ? editd + ' Effective date is empty !' : editd + ' La date effective est vide !';

    } else {
      if (editd == '') {
        this.presubmitVerU = false;
      } else if (editd > editdE) {
        this.presubmitVerU = false;
      } else if (editd < editdE) {
        this.presubmitVerU = true;
        this.messagedangerVerU = this.lang === 'en' ? 'End-Date must not be less than Effective-Date!' : 'La date de fin ne doit pas être antérieure à la date effective !';
      }
    }
  }
  checkModEndDateU() {
    this.messagedangerModU = '';
    this.messagesuccesModU = '';
    var editdE = this.editFormMod.get('effectiveDate').value;
    if (editdE != '') {
      const strdate = editdE.split('-');
      editdE = strdate[0];
    }
    var editd = this.editFormMod.get('endDate').value;
    if ((editd == null) || (editd == '') || (editd == undefined)) {
      this.presubmitModU = false;
      this.messagedangerModU = '';
      this.messagesuccesModU = '';
      this.editFormMod.get('endDate').patchValue('');
    } else {
      if (editdE == '') {
        this.presubmitModU = true;
        this.messagedangerModU = this.lang === 'en' ? 'Effective Date is empty!' : 'La date effective est vide !';
        this.editFormMod.get('endDate').patchValue('');
      } else {
        if (editd > editdE) {

          this.presubmitModU = false;
          this.messagedangerModU = '';
          this.messagesuccesModU = '';
        } else {
          this.presubmitModU = true;
          this.messagedangerModU = this.lang === 'en' ? 'End date should be > of effective date !' : 'La date de fin doit être > de la date effective !';
          this.editFormMod.get('endDate').patchValue('');
        }
      }
    }
  }
  onAddNewSystem() {
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.messagedangerNewSys = '';
    this.messagesuccesNewSys = '';
    $('#appModal').modal('show');
    this.startScript();
    this.onLoadDescriptionFoAllSystem('newSystem', 'descriptionSystem');
    setTimeout(() => {
      this.onLoadDescriptionFoAllSystem('newSystem', 'descriptionModuleSystem');
    }, 500);
  }
  skipString(str) {
    if(str == null) {return str;}
    let name  = str.toString().replace(/\//g, '__________');
    name = name.replace(/&/g, '::::::::');
    return name;
  }
  initObserver(){
    // @ts-ignore
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        $('.node-item').css({ width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
        $('.business-item').css( { width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
        $('.business-item .card-header').css( 'width', this.zoomWidth +'px');
        $('.lg-synonym').css({ width: this.zoomWidth +'px', 'font-size': this.zoomFontSize +'px'});
      }
    });
  }
  changeLinkStatus() {
    // const index = this.edges.indexOf(this.selectedEdge);
    // if (index != -1) {
    //     this.edges[index].status = this.selectedStatusLink;
    // }
    // // let start = false;
    // // if (document.getElementById(this.selectedEdge.source).offsetLeft < document.getElementById(this.selectedEdge.target).offsetLeft) {
    // //   start = true;
    // // }
    // this.selectedEdge.element.setOptions({
    //   path: this.selectedLinkPath,
    //   dropShadow: this.selectedLinkShadow,
    //    startLabel : this.selectedStatusLink === 'To Validate' ? LeaderLine.pathLabel('\uf058', {color: 'green', fontFamily: 'Font Awesome 5 Free'}) : '',
    //   // endLabel : this.selectedStatusLink === 'To Validate' && !start ? LeaderLine.pathLabel('\uf058', {color: 'green', fontFamily: 'Font Awesome 5 Free'}) : '',
    //   middleLabel: LeaderLine.captionLabel( this.selectedLinkDescription, {color: this.selectedLinkDescriptionColor, fontSize: this.selectedLinkDescriptionSize, fontStyle: this.selectedLinkDescriptionStyle, fontWeight: this.selectedLinkDescriptionWeigt}),
    // });
    // console.info(this.edges);
    console.log(this.selectedStatusLink);
    const index = this.edges.indexOf(this.selectedEdge);
    if (index != -1) {
      this.edges[index].endColor = this.selectedStatusLink === 'To Validate' ? 'rgb(190,245,116)' : 'rgb(1,5,181)';
      this.edges[index].startColor = this.selectedStatusLink === 'To Validate' ? 'rgb(190,245,116)' : 'rgb(43,127,144)';
      this.edges[index].status = this.selectedStatusLink;
    }
    this.selectedEdge.element.setOptions({
      endPlugColor: this.selectedStatusLink === 'To Validate' ? 'rgb(190,245,116)' : 'rgb(1,5,181)',
      startPlugColor: this.selectedStatusLink === 'To Validate' ? 'rgb(190,245,116)' : 'rgb(43,127,144)',
      path: this.selectedLinkPath,
      dropShadow: this.selectedLinkShadow,
      middleLabel: LeaderLine.captionLabel(this.selectedLinkDescription, {color: this.selectedLinkDescriptionColor, fontSize: this.selectedLinkDescriptionSize, fontStyle: this.selectedLinkDescriptionStyle, fontWeight: this.selectedLinkDescriptionWeigt}),
    });
    console.info(this.edges);
  }
}
