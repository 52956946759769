import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
@Injectable({
  providedIn: 'root'
})
export class DrmClassifService {

  constructor(private http: HttpClient, private router: Router) { }

  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }

  // liste des applications pour alimenter le tableau object models by status approved/archived
  getApplForListModels(): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getApplForListModels/' + user, this.getOptions());
  }
  // tableau object models by status approved/archived
  getObjectModelsList(appli: string): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getObjectModelsList/' + user + '/' + appli, this.getOptions());
  }
  // count object model classif groupBy category (getLineChart)
  getCountClassifByCateLineChart(idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getCountClassifByCateLineChart/' + idModel, this.getOptions());
  }
  // count object model classif groupBy category (getLiRadarChart)
  getCountClassifByCateRadarChart(idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getCountClassifByCateRadarChart/' + idModel, this.getOptions());
  }
  // Sunsburd object -> level: usageRisk -> category (name, count (chart Sunburst)
  getSunburstChartV3(idObject: string, idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByModelDrmV3/' + idObject + '/' +  idModel, this.getOptions());
  }
  // Sunsburd level: usageRisk -> object -> category (name, count (chart Sunburst)
  getSunburstChart(idObject: string, idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByModelDrm/' + idObject + '/' +  idModel, this.getOptions());
  }
  // Sunsburd level: usageRisk -> category -> object (name, count (chart Sunburst))
  getSunburstChartV2(id: string, idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByModelDrm2/' + id + '/' +  idModel, this.getOptions());
  }
  // Trigger: add column 'idObject' to ObjectModelClassif (chart Sunburst)
  // setIdObjectToClassif(): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/drm-classification/setIdObjForAllClassif', this.getOptions());
  // }
  // setIdModelForAllFilters(): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/drm-rules/setIdModelForAllFilters', this.getOptions());
  // }

  // Partie Security DRM

  getApplForListModelsDrmSecurity(): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/getApplForListModelsDrmSecurity/' + user, this.getOptions());
  }
  // tableau object models by status approved/archived
  getObjectModelsListDrmSecurity(appli: string): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/getObjectModelsListDrmSecurity/' + user + '/' + appli, this.getOptions());
  }
  // test QCM Model before QCM Application
  existModelBeforeShowModalQcm(idApp: string): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/existModelBeforeShowModalQcm/' + idApp + '/' + user, this.getOptions());
  }
  // test QCM Object before QCM Model
  existObjectBeforeShowModalQcm(idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/existObjectBeforeShowModalQcm/' + idModel, this.getOptions());
  }
  // test QCM Record before QCM Object
  existRecordBeforeShowModalQcm(idObject: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/existRecordBeforeShowModalQcm/' + idObject, this.getOptions());
  }
  // test QCM Field before QCM Object
  existFieldBeforeShowModalQcm(idRecord: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/existFieldBeforeShowModalQcm/' + idRecord, this.getOptions());
  }
  // sqm application
  saveQcmApplication(idApp: string, dataAccessApp: string, accessLocationApp: string, identifiabilityApp: string, quantityApp: string, listModel: any): Observable<any> {
    const user = getCookieValue('username');
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/saveQcmApplication/'
      + idApp + '/' + dataAccessApp + '/' + accessLocationApp + '/' + identifiabilityApp + '/' + quantityApp + '/'
      + listModel + '/' + user, this.getOptions());
  }
  // sqm model
  saveQcmModel(idModel: string, dataAccessModel: string, accessLocationModel: string, identifiabilityModel: string, quantityModel: string, listObject: any): Observable<any> {
    const user = getCookieValue('username');
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/saveQcmModel/'
      + idModel + '/' + dataAccessModel + '/' + accessLocationModel + '/' + identifiabilityModel + '/' + quantityModel + '/' + listObject + '/'
      + user, this.getOptions());
  }
  // list des objets by selected model
  getObjectsList(idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/getObjectsList/' + idModel, this.getOptions());
  }
  // sqm object
  saveQcmObject(idObject: string, dataAccessObject: string, accessLocationObject: string, identifiabilityObject: string, quantityObject: string, listRecord: any): Observable<any> {
    const user = getCookieValue('username');
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/saveQcmObject/'
      + idObject + '/' + dataAccessObject + '/' + accessLocationObject + '/' + identifiabilityObject + '/' + quantityObject + '/' + listRecord + '/'
      + user, this.getOptions());
  }
  // list des records by selected object and model
  getRecordsList(idModel: string, idObject: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/getRecordsList/' + idModel + '/' + idObject, this.getOptions());
  }
  // sqm record
  saveQcmRecord(idRecord: string, dataAccessRecord: string, accessLocationRecord: string, identifiabilityRecord: string, quantityRecord: string, listField: any): Observable<any> {
    const user = getCookieValue('username');
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/saveQcmRecord/'
      + idRecord + '/' + dataAccessRecord + '/' + accessLocationRecord + '/' + identifiabilityRecord + '/' + quantityRecord + '/' + listField + '/'
      + user, this.getOptions());
  }
  // list des fields by selected record(Table) and object
  getFieldsList(idObject: string, idRecord: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/getFieldsList/' + idObject + '/' + idRecord, this.getOptions());
  }
  // sqm field
  saveQcmField(idField: string, dataAccessField: string, accessLocationField: string, identifiabilityField: string, quantityField: string): Observable<any> {
    const user = getCookieValue('username');
    return this.http.get<any>(Shared.API_URL + '/api/drm-security/saveQcmField/'
      + idField + '/' + dataAccessField + '/' + accessLocationField + '/' + identifiabilityField + '/' + quantityField + '/'
      + user, this.getOptions());
  }
  /////////////////////// new => Start get list compliances in selected Model /////////////////////////////////
  // BY COMPLIANCE
  getListComplianceByModel(idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getListComplianceByModel/' + idModel, this.getOptions());
  }
  // count object model classif groupBy category (getLineChart) BY COMPLIANCE
  getCountClassifByCateLineChartDrm(idModel: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getCountClassifByCateLineChartDRM/' + idModel + '/' + compliance, this.getOptions());
  }
  // count object model classif groupBy category (getLiRadarChart) BY COMPLIANCE
  getCountClassifByCateRadarChartDrm(idModel: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getCategToRadarChartByComplAndModelDRM/' + idModel + '/' + compliance, this.getOptions());
  }
  // Sunsburd object -> level: usageRisk -> category (name, count (chart Sunburst) BY COMPLIANCE
  getSunburstChartV3Drm(idObject: string, idModel: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByModelDrmV3/' + idObject + '/' +  idModel + '/' + compliance, this.getOptions());
  }
  // Sunsburd level: usageRisk -> object -> category (name, count (chart Sunburst) BY COMPLIANCE
  getSunburstChartDrm(idObject: string, idModel: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByModelDrm/' + idObject + '/' +  idModel + '/' + compliance, this.getOptions());
  }
  // Sunsburd level: usageRisk -> category -> object (name, count (chart Sunburst)) BY COMPLIANCE
  getSunburstChartV2Drm(id: string, idModel: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByModelDrm2/' + id + '/' +  idModel + '/' + compliance, this.getOptions());
  }
  // new data usage risk (menu list) in classification by selected compliance
  getDataUsageRiskByComplianceAndModel(idModel: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataUsageRiskByComplianceAndModelDRM/' + idModel + '/' + compliance, this.getOptions());
  }

  ///////////////////// data dictionary////////////////////////////////////
  // data to sunburst  level(dictionaryClassif:family) -> object (reportdetail) -> category (dictionaryClassif:category) BY REPORT AND COMPLIANCE
  getDataSunburstByDictionaryDrmLOC(reportDetailName: string, idReport: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByDictionaryDrmLOC/' + reportDetailName + '/' +  idReport + '/' + compliance, this.getOptions());
  }
  // data to sunburst object (reportdetail) -> level(dictionaryClassif:family) -> category (dictionaryClassif:category) BY REPORT AND COMPLIANCE
  getDataSunburstByDictionaryDrmOLC(reportDetailName: string, idReport: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByDictionaryDrmOLC/' + reportDetailName + '/' +  idReport + '/' + compliance, this.getOptions());
  }
  // data to sunburst  level(dictionaryClassif:family) -> category (dictionaryClassif:category) -> object (reportdetail) BY REPORT AND COMPLIANCE
  getDataSunburstByDictionaryDrmLCO(reportDetailName: string, idReport: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataSunburstByDictionaryDrmLCO/' + reportDetailName + '/' +  idReport + '/' + compliance, this.getOptions());
  }
  ///////////////////////end data dictionary /////////////////////////////
  /////////////////////// new => end get list compliances in selected Model /////////////////////////////////

  ////////////////////////################### Data Dictionary Classification #####################/////////////////////////////////
  // liste des applications pour alimenter le tableau Data Dictioanry by status approved/archived
  getApplForListDictionays(): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getApplForListDictionays/' + user, this.getOptions());
  }
  // tableau data dictionay by status approved/archived
  getDictionaysList(appli: string): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDictionaysList/' + user + '/' + appli, this.getOptions());
  }
  // tableau data dictionary by status approved/archived (change version dictionary by effective date)
  getChangeDictionaryFromList(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getChangeDictionaryFromList/' + id, this.getOptions());
  }
  // get list COMPLIANCE by selected Dictionary (in show details)
  getListComplianceByDictionary(idModel: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getListComplianceByDictionary/' + idModel, this.getOptions());
  }
  // count data dictionary classif groupBy category (getLineChart) BY COMPLIANCE
  getDataToLineChartDictionary(idReport: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataToLineChartDictionary/' + idReport + '/' + compliance, this.getOptions());
  }
  // count data dictionary classif groupBy category (getLiRadarChart) BY COMPLIANCE
  getCategToRadarByComplAndDictDrm(idReport: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getCategToRadarByComplAndDictDrm/' + idReport + '/' + compliance, this.getOptions());
  }
  // data usage risk (menu list) in classification by selected compliance data dictionary
  getDataUsageRiskByComplianceAndDictionary(idReport: string, compliance: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/drm-classification/getDataUsageRiskByComplianceAndDictionary/' + idReport + '/' + compliance, this.getOptions());
  }
  ///////////////////////################### End Data Dictionary #################/////////////////////////////////
}
