import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DrmRoutingModule } from './drm-routing.module';
import { RetentionPeriodComponent } from './retention-period/retention-period.component';
import { RulesComponent } from './rules/rules.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SidebarModule} from 'ng-sidebar';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {TooltipModule} from 'ng2-tooltip-directive';
import {OrderModule} from 'ngx-order-pipe';
import {LoadersDrmComponent} from '../loaders/loadersDrm/loadersDrm.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import { SecurityRiskComponent } from './security-risk/security-risk.component';
import {LoaderglobwhiteComponent} from '../loaders/loaderglobWhite/loaderglobwhite.component';
import { NoClassifcationComponent } from './no-classifcation/no-classifcation.component';
import { NoRulesComponent } from './no-rules/no-rules.component';
import { NoSecurityComponent } from './no-security/no-security.component';
import {TranslateModule} from '@ngx-translate/core';
import { DefinitionsComponent } from './definitions/definitions.component';
import {ArchwizardModule} from 'angular-archwizard';
import { ClassificationdrmComponent } from './classificationdrm/classificationdrm.component';
import {ClassificationdictdrmComponent} from './classificationDictdrm/classificationdictdrm.component';
import {SharedModule} from '../shared/shared.module';


@NgModule({
  declarations: [ClassificationdrmComponent, DefinitionsComponent, ClassificationdictdrmComponent, RetentionPeriodComponent, RulesComponent,
    SecurityRiskComponent, NoClassifcationComponent, NoRulesComponent, NoSecurityComponent,
    LoadersDrmComponent, LoaderglobwhiteComponent,
    NoClassifcationComponent, NoRulesComponent, NoSecurityComponent, DefinitionsComponent, ClassificationdrmComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DrmRoutingModule,
    TooltipModule,
    NgSelectModule,
    NgOptionHighlightModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    OrderModule,
    NgxDropzoneModule,
    SidebarModule.forRoot(),
    LeafletModule,
    TranslateModule,
    ArchwizardModule,
    SharedModule
  ]
})
export class DrmModule { }
