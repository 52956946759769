import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DataGovernanceRoutingModule} from "./data-governance-routing.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "@ng-select/ng-option-highlight";
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {OrderModule} from 'ngx-order-pipe';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';
import {SidebarModule} from "ng-sidebar";
import {ProjectsComponent} from "./projects/projects.component";
import {ArchwizardModule} from "angular-archwizard";
import { StackComponent } from './stack/stack.component';
import { HoldersComponent } from './holders/holders.component';
import {SharedModule} from "../shared/shared.module";
import {SharedComponentDiagProjComponent} from '../globaldesigner/sharedComponentDiags/shared-component-diag-proj/shared-component-diag-proj.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslationLoaderFactory} from '../app.module';
import { EntityComponent } from './entity/entity.component';
import { CountryComponent } from './country/country.component';
import { DimensionComponent } from './dimension/dimension.component';
import {TreeModule} from "@circlon/angular-tree-component";

@NgModule({
  declarations: [ ProjectsComponent,
    StackComponent,
    HoldersComponent,
    // new dynamic component diag assigned to proj
    SharedComponentDiagProjComponent,
    EntityComponent,
    CountryComponent,
    DimensionComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgOptionHighlightModule,
        ReactiveFormsModule,
        HttpClientModule,
        DataGovernanceRoutingModule,
        OrderModule,
        Ng2SearchPipeModule,
        NgxPaginationModule,
        SidebarModule,
        ArchwizardModule,
        SharedModule,
        TranslateModule,
        TreeModule,

    ]
})
export class DataGovernanceModule { }
