import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {UploadsService} from '../../services/uploads/uploads.service';
import Swal from 'sweetalert2';
import {addLoader, removeLoader} from '../../object-models/graphs/diagramme/utils';
import * as Excel from 'exceljs';
import {ReportService} from '../../services/reports/report.service';
import * as moment from '../../../../bower_components/moment/moment';
import {addProgressBar, setProgressBarValue} from '../../shared';
import {TranslateService} from '@ngx-translate/core';
import * as d3 from 'd3';
import * as echarts from 'echarts';
import {archPurge} from './models/archPurge';
import {archLog} from './models/archLog';
import {archCand} from './models/archCand';
import {archid} from './models/archid';

declare  const $: any;
type EChartsOption = echarts.EChartsOption;
@Component({
  selector: 'app-upload-archive',
  templateUrl: './upload-archive.component.html',
  styleUrls: ['./upload-archive.component.css']
})
export class UploadArchiveComponent implements OnInit {
  lang: string;
  files: File[] = [];
  // dictionaryFiles = [];

  archiveToUpdate = [];
  totalProgress = 0;
  step = 0;
  addedArchid = false;
  addedCand = false;
  addedPurge = false;
  addedLog = false;

  listFilesArchive = [];
  constructor(private http: HttpClient, private uploadService: UploadsService, private reportService: ReportService,
              private translate: TranslateService) {

               }

  ngOnInit() {
    // this.onGetDataArchiveDash();
    this.detectChangeLaguage();
    }
    detectChangeLaguage() {
      const that = this;
      setInterval(() => {
        this.lang = localStorage.getItem('lang') || 'fr';
        this.translate.use(this.lang);
      }, 10);
    }
  updateArchive() {
    // removeLoader('vide', 'f', true);
    // addProgressBar(0, null);
    this.totalProgress = 0;
    this.archiveToUpdate = [];
    this.reportService.updateArchive().subscribe(
      d => {
          $('#divGlobalProgress').remove();
          addProgressBar(100, this.lang === 'en' ? 'Updating archive with last data is success' : 'La mise à jour de l\'archive avec les dernières données a réussi');

        this.archiveToUpdate = d;
        const totals = this.archiveToUpdate.length / 100;
        this.step = Math.round(100 / totals);
        const removed = this.archiveToUpdate.splice(0, 100);
        // console.log('totals => ' + totals, 'archivesToUpadte => ' + this.archiveToUpdate.length, 'step => ' + this.step);
        if (removed.length > 0) {
          removed.map(e => {
            e.systnaps_archname = this.skipString(e.systnaps_archname);
            e.application = this.skipString(e.application);
            e.base = this.skipString(e.base);
          });
          // console.log(removed);
          //   $('#divGlobalProgress').remove();
            setTimeout(() => {
                $('#divGlobalProgress').remove();
                addProgressBar(0, this.lang === 'en' ? 'Mapping Object Model To Archive' : 'Mappage du modèle d\'objet vers l\'archive');

                this.setTaskObjectPurge(removed);
            }, 50);
        } else {
            setTimeout(() => {
                $('#divGlobalProgress').remove();
                $('#uploadArchiveStatus').modal('show');
                // Swal.fire({
                //     icon: 'success',
                //     title: 'Upload of the archive is finished successfully',
                //     confirmButtonText: 'Ok',
                //     timer: 1500
                // });
            }, 10)
          // removeLoader('vide', 'f', true);
        }
        // console.log(d);
      },
      err => {
          console.error(err);
          $('#uploadArchiveStatus').modal('show');
      }
    );
  }
  getProcess(val) {
    if (val) {
      return val;
    } else {
      return 0;
    }
  }
  getChar(val) {
    if (val) {
      if (val.toString().indexOf(' am')) {
        return val.toString().split(' am')[0].trim();
      }
      if (val.toString().indexOf(' pm')) {
        return val.toString().split(' pm')[0].trim();
      }
      // val = val.toString().replace(/&/g, '%26');
      //  val = val.toString().replace(/\//g, '__________');
      //  val = val.replace(/&/g, '::::::::');
      return val;
    } else {
      return null;
    }
  }
  skipString(str) {
    if (str == null) {return str; }
    let name  = this.getChar(str).replace(/\//g, '__________');
    name = name.replace(/&/g, '::::::::');
    return name;
  }
  onRemove(event) {
    // console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
  }
  getDateMoment(str) {
    if (str == null || str == '') {
      return null;
    }
    return moment(str).format('YYYY-MM-DD');
  }
  uploadExcel(event) {
      this.addedArchid = false;
      this.addedCand = false;
      this.addedPurge = false;
      this.addedLog = false;
      this.listFilesArchive = [];
    this.files.push(...event.addedFiles);
    // console.log( this.files);
    // check if file SYSTNAPS_ARCHID.xlsx was uploaded and raise error else
    if (this.files.find(e => e.name == 'SYSTNAPS_ARCHID.xlsx') == null) {
      // Swal.fire({
      //   icon: 'error',
      //   confirmButtonText: 'Ok',
      //   title: 'File SYSTNAPS_ARCHID.xlsx is mandatory to perform upload'
      // });
      Swal.fire(
        this.lang === 'en' ? 'Upload refused' : 'Téléchargement refusé',
        this.lang === 'en' ? 'File SYSTNAPS_ARCHID.xlsx is mandatory to perform upload' : 'Le fichier SYSTNAPS_ARCHID.xlsx est obligatoire pour effectuer le téléchargement',
        'error'
       );
       
      this.files = [];
      return;
    }
    // check if file SYSTNAPS_ARCHLOG_CAND.xlsx was uploaded and raise error else
    if (this.files.find(e => e.name == 'SYSTNAPS_ARCHLOG_CAND.xlsx') == null) {
      // Swal.fire({
      //   icon: 'error',
      //   confirmButtonText: 'Ok',
      //   title: 'File SYSTNAPS_ARCHLOG_CAND.xlsx is mandatory to perform upload'
      // });
      Swal.fire(
        this.lang === 'en' ? 'Upload refused' : 'Téléchargement refusé',
        this.lang === 'en' ? 'File SYSTNAPS_ARCHLOG_CAND.xlsx is mandatory to perform upload' : 'Le fichier SYSTNAPS_ARCHLOG_CAND.xlsx est obligatoire pour effectuer le téléchargement',
        'error'
       );
       
      this.files = [];
      return;
    }
    // check if file SYSTNAPS_ARCHLOG_ARCH.xlsx was uploaded and raise error else
    if (this.files.find(e => e.name == 'SYSTNAPS_ARCHLOG_ARCH.xlsx') == null) {
      Swal.fire({
        title: this.lang === 'en' ? 'Warning in upload' : 'Avertissement lors du téléchargement',
        text: this.lang === 'en' ? 'You are about to upload archives without SYSTNAPS_ARCHLOG_ARCH.xlsx!' : 'Vous êtes sur le point de télécharger des archives sans SYSTNAPS_ARCHLOG_ARCH.xlsx !',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !',
        cancelButtonText: this.lang === 'en' ? 'No, Cancel it!' : 'Non, annulez-le !'
       }).then((result) => {
        if (result.value) {
            if (!this.addedLog) {
                this.listFilesArchive.push({
                    name: 'SYSTNAPS_ARCHLOG_ARCH.xlsx',
                    status: 'KO',
                    detail: this.lang === 'en' ? 'File not supplied' : 'Fichier non fourni'

                });
                this.addedLog = true;
            }

          // check if extension is xlsx
          if (this.files.find(e => e.name == 'SYSTNAPS_ARCHLOG_PURGE.xlsx') == null) {
            Swal.fire({
              title: this.lang === 'en' ? 'Warning upload' : 'Avertissement lors du téléchargement',
              text: this.lang === 'en' ? 'You are about to upload archives without SYSTNAPS_ARCHLOG_PURGE.xlsx!' : 'Vous êtes sur le point de télécharger des archives sans SYSTNAPS_ARCHLOG_PURGE.xlsx !',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !',
              cancelButtonText: this.lang === 'en' ? 'No, Cancel it!' : 'Non, annulez-le !'
             }).then((result) => {
              if (result.value) {

                  if (!this.addedPurge) {
                      this.listFilesArchive.push({
                          name: 'SYSTNAPS_ARCHLOG_PURGE.xlsx',
                          status: 'KO',
                          detail: this.lang === 'en' ? 'File not supplied' : 'Fichier non fourni'
                      });
                      this.addedPurge = true;
                  }
                  this.proceedUpload();
              } else {
                  this.files = [];
              }
            });
          } else {
            this.proceedUpload();
          }
        } else {
            this.files = [];
        }
      });
    } else {
      this.proceedUpload();
    }
  }
  proceedUpload() {
    // addLoader('vide', 'f', true);
    const archidIndex = this.files.findIndex(e => e.name.toLowerCase() == 'systnaps_archid.xlsx');
    this.uploadArchiveId(this.files[archidIndex]);
  }
    hideUploadedStatus() {
        $('#uploadArchiveStatus').modal('hide');
    }
  uploadArchiveId(file) {
    const arryBuffer = new Response(file).arrayBuffer();
    const workbook = new Excel.Workbook();
    const that = this;
    arryBuffer.then(function(data) {
      workbook.xlsx.load(data)
        .then(function() {
          const worksheet = workbook.worksheets[0];
          const archis = [];
          let sql = 'insert into SYSTNAPS_ARCHID("systnaps_archid","systnaps_archname","systnaps_descr","systnaps_date","systnaps_processed","systnaps_schdlid","systnaps_enddate","systnaps_purged","systnaps_isarch","systnaps_ispurge","application","base") values';
          //  let sql = '';
          let ids = '(';
          let base = '';
          worksheet.eachRow(function(row, rowNumber) {
            if (rowNumber > 1) {
              let systnapsDate = that.getDateMoment(row.values[4]).split('T');
              if (systnapsDate.length > 0) {
                const start = systnapsDate[0];
                let end = systnapsDate[1];
                if (end != null) {
                  end = end.split('.')[0];
                }
                if (end != undefined) {
                  // @ts-ignore
                  systnapsDate = start + ' ' + end;
                } else {
                  // @ts-ignore
                  systnapsDate = start;
                }
              }
              let desc = that.skipString(row.values[3]);
              if ( desc == undefined) {
                desc = null;
              }
              ids += row.values[1] + ',';
              base = that.getChar(row.values[12]);
              // = '(' . $item['SYSTNAPS_ARCHID'] . ",'" . $this->skip_string($item['SYSTNAPS_ARCHNAME']) . "','" . $this->skip_string($item['desc']) . "','" . $finalDate . "','" . $item['SYSTNAPS_PROCESSED'] . "'," . $item['SYSTNAPS_SCHDLID'] . ",null,'" . $item['SYSTNAPS_PURGED'] . "','" . $item['SYSTNAPS_ISARCH'] . "','" . $item['SYSTNAPS_ISPURGE'] . "','" . $this->skip_string($item['SYSTNAPS_APPLICATION']) . "','" . $this->skip_string($item['base']) . "'),";
              sql += '(' + row.values[1] + ',\'' + that.skipString(row.values[2]) + '\',\'' + desc + '\',\'' + systnapsDate + '\',\'' + that.getProcess(row.values[5]) + '\','  + that.getProcess(row.values[6]) + ',null,\'' + that.getChar(row.values[8]) + '\',\'' + that.getChar(row.values[9]) +  '\',\'' +  that.getChar(row.values[10]) + '\',\'' + that.getChar(row.values[11]) + '\',\'' + that.getChar(row.values[12]) + '\'),';
              archis.push(new archid(row.values[1], that.skipString(row.values[2]), that.skipString(row.values[3]), that.getDateMoment(row.values[4]), that.getProcess(row.values[5]), that.getProcess(row.values[6]), that.getDateMoment(row.values[7]), that.getChar(row.values[8]), that.getChar(row.values[9]), that.getChar(row.values[10]), that.getChar(row.values[11]), that.getChar(row.values[12])));
            }
          });
          addProgressBar(0, that.lang === 'en' ? 'SYSTNAPS_ARCHID.xlsx is Pending' : 'SYSTNAPS_ARCHID.xlsx est en attente');
          that.reportService.uploadArchiveId(sql, ids, base).subscribe(
            d => {

                if (!that.addedArchid) {
                    that.listFilesArchive.push({
                        name: 'SYSTNAPS_ARCHID.xlsx',
                        status: 'OK',
                        detail: that.lang === 'en' ? 'Uploaded success' : 'Succès du téléchargement'

                    });
                    that.addedArchid = true;
                }
              $('#divGlobalProgress').remove();
              addProgressBar(20, this.lang === 'en' ? 'SYSTNAPS_ARCHID.xlsx uploaded with success' : 'SYSTNAPS_ARCHID.xlsx téléchargé avec succès');
              const archCandIndex = that.files.findIndex(e => e.name.toLowerCase()  == 'systnaps_archlog_cand.xlsx');
              that.files.length === 2 ? that.uploadArchiveCand(that.files[archCandIndex], true) : that.uploadArchiveCand(that.files[archCandIndex], false);

              // Swal.fire({
              //   icon: 'success',
              //   title: 'SYSTNAPS_ARCHID.xlsx uploaded with success',
              //   confirmButtonText: 'Ok',
              //   timer: 1500
              // });
            },
            err => {
                $('#divGlobalProgress').remove();
                addProgressBar(100, that.lang === 'en' ? 'SYSTNAPS_ARCHID.xlsx upload ERROR... Aborting upload!!!' : 'Erreur de téléchargement de SYSTNAPS_ARCHID.xlsx... Annulation du téléchargement!!!');
                $('#ldp').children().eq(0).toggleClass('bg-danger');
                  setTimeout(() => {
                      $('#divGlobalProgress').remove();

                      if (!that.addedArchid) {
                          that.listFilesArchive.push({
                              name: 'SYSTNAPS_ARCHID.xlsx',
                              status: 'KO',
                              detail: that.lang === 'en' ? 'Error in upload... upload exited' : 'Erreur lors du téléchargement... le téléchargement a été interrompu'
                          });
                          that.addedArchid = true;
                      }
                      $('#uploadArchiveStatus').modal('show');
                      that.files = [];
                      // Swal.fire({
                      //     icon: 'error',
                      //     title: 'Error in upload SYSTNAPS_ARCHID.xlsx',
                      //     confirmButtonText: 'Ok'
                      // });
                  }, 10);
                }
          );
        });
    });
  }
  // archive cand upload
  uploadArchiveCand(file, last) {
      $('#divGlobalProgress').remove();
      addProgressBar(20, 'SYSTNAPS_ARCHLOG_CAND.xlsx is Pending');
    const arryBuffer = new Response(file).arrayBuffer();
    const workbook = new Excel.Workbook();
    const that = this;
    arryBuffer.then(function(data) {
      workbook.xlsx.load(data)
        .then(function() {

          // play with workbook and worksheet now
          // console.log(workbook);
          const worksheet = workbook.worksheets[0]; // workbook.getWorksheet('Sheet 1');
          // console.log('rowCount: ', worksheet.rowCount);
          const archCands = [];
          let sql = 'insert into SYSTNAPS_ARCHLOG_CAND("id","systnaps_archid","name_cand","count_csv","count_bad","count_load","ok_csv_load","time_load_csv","base") values';
          // let sql = '';
          let base = '';
          let ids = '(';
          worksheet.eachRow(function(row, rowNumber) {
            if (rowNumber > 1) {
              let OK_CSV_LOAD = '';
              if (parseInt(that.getProcess(row.values[3]), 10) == parseInt(that.getChar(row.values[5]), 10)) {
                OK_CSV_LOAD = 'OK';
              } else {
                OK_CSV_LOAD = 'KO';
              }
              //      id,          systnaps_archid,       name_cand,             count_csv,                             count_bad,                             count_load,                             ok_csv_load,         time_load_csv,                       base
              sql += '(DEFAULT,' + row.values[1] + ',\'' + that.skipString(row.values[2]) + '\',' + that.getProcess(row.values[3]) + ',' + that.getProcess(row.values[4]) + ',' + that.getProcess(row.values[5]) + ',\'' + OK_CSV_LOAD + '\',' + that.getChar(row.values[7]) + ',\'' + that.getChar(row.values[8]) + '\'),';
              base = that.getChar(row.values[8]);
              ids += row.values[1] + ',';
              archCands.push(new archCand(row.values[1], that.skipString(row.values[2]), that.getProcess(row.values[3]), that.getProcess(row.values[4]), that.getProcess(row.values[5]), OK_CSV_LOAD, that.getChar(row.values[7]), that.getChar(row.values[8])));
            }
          });
          //  sql = [...sql].reverse().join("");
          //  let sql1 = that.rot13(sql);
          // console.log(archCands,sql,base,ids,"end cand\n");return;
          that.reportService.uploadArchiveCand(/*archCands*/sql, ids, base).subscribe(
            d => {
                $('#divGlobalProgress').remove();
                addProgressBar(40, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_CAND.xlsx uploaded with success' : 'SYSTNAPS_ARCHLOG_CAND.xlsx téléchargé avec succès');
                if (!that.addedCand) {
                    that.listFilesArchive.push({
                        name: 'SYSTNAPS_ARCHLOG_CAND.xlsx',
                        status: 'OK',
                        detail: that.lang === 'en' ? 'Uploaded success' : 'Succès du téléchargement'

                    });
                    that.addedCand = true;
                }
              if (last) {
                // console.log('cand setTask');return;
                  $('#divGlobalProgress').remove();
                  addProgressBar(20, that.lang === 'en' ? 'SYSTNAPS_ARCHID.xlsx uploaded with success' : 'SYSTNAPS_ARCHID.xlsx téléchargé avec succès');

                  setTimeout(() => {
                      $('#divGlobalProgress').remove();
                      addProgressBar(80, that.lang === 'en' ? 'Updating archive with last data' : 'Mise à jour de l\'archive avec les dernières données');


                      if (!that.addedLog) {
                          that.listFilesArchive.push({
                              name: 'SYSTNAPS_ARCHLOG_ARCH.xlsx',
                              status: 'KO',
                              detail: that.lang === 'en' ? 'File not supplied' : 'Fichier non fourni'

                          });
                          that.addedLog = true;
                      }

                      if (!that.addedPurge) {
                          that.listFilesArchive.push({
                              name: 'SYSTNAPS_ARCHLOG_PURGE.xlsx',
                              status: 'KO',
                              detail: that.lang === 'en' ? 'File not supplied' : 'Fichier non fourni'

                          });
                          that.addedPurge = true;
                      }
                      that.updateArchive();
                  }, 50);
              } else {
                const archLogIndex = that.files.findIndex(e => e.name.toLowerCase() ==  'systnaps_archlog_arch.xlsx');
                const archPurgeIndex = that.files.findIndex(e => e.name.toLowerCase() == 'systnaps_archlog_purge.xlsx');
                // archlog last file
                if (archLogIndex != -1 && archPurgeIndex == -1) {
                    $('#divGlobalProgress').remove();
                    addProgressBar(40, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_ARCH.xlsx is pending' : 'SYSTNAPS_ARCHLOG_ARCH.xlsx est en attente');

                  that.uploadArchiveLog(that.files[archLogIndex], true);
                  // console.log("archlog last");
                }
                // archpurge last file
                if (archLogIndex != -1 && archPurgeIndex != -1) {
                    $('#divGlobalProgress').remove();
                    addProgressBar(40, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_ARCH.xlsx is pending' : 'SYSTNAPS_ARCHLOG_ARCH.xlsx est en attente');
                  that.uploadArchiveLog(that.files[archLogIndex], false);
                  // console.log("purge last with archlog");
                }
                // archpurge last file
                if (archLogIndex == -1 && archPurgeIndex != -1) {
                    $('#divGlobalProgress').remove();
                    addProgressBar(40, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_ARCH.xlsx is pending' : 'SYSTNAPS_ARCHLOG_ARCH.xlsx est en attente');
                  that.uploadArchivePurge(that.files[archPurgeIndex], true);
                  // console.log("purge last only");
                }
              }
              // Swal.fire({
              //   icon: 'success',
              //   confirmButtonText: 'Ok',
              //   title: 'SYSTNAPS_ARCHLOG_CAND.xlsx uploaded with success',
              //   timer: 1500
              // });
            },
            err => {
                $('#divGlobalProgress').remove();
                addProgressBar(100, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_ARCH.xlsx upload ERROR... Aborting upload!!!' : 'Erreur de téléchargement de SYSTNAPS_ARCHLOG_ARCH.xlsx... Annulation du téléchargement!!!');

                $('#ldp').children().eq(0).toggleClass('bg-danger');
                setTimeout(() => {
                    $('#divGlobalProgress').remove();
                    that.files = [];

                    if (!that.addedCand) {
                        that.listFilesArchive.push({
                            name: 'SYSTNAPS_ARCHLOG_CAND.xlsx',
                            status: 'KO',
                            detail: that.lang === 'en' ? 'Error in upload... upload exited' : 'Erreur lors du téléchargement... le téléchargement a été interrompu'

                        });
                        that.addedCand = true;
                    }
                    $('#uploadArchiveStatus').modal('show');
                    // Swal.fire({
                    //     icon: 'error',
                    //     title: 'Error in upload systnaps_archlog_arch.xlsx',
                    //     confirmButtonText: 'Ok'
                    // });
                }, 10);
            }/*,
            ()=>{
              that.reportService.setTaskObjectCand().subscribe(
                d=>{},
                err=>{console.log(err);}
              );
            }*/
          );
        });
    });
  }
  // archive log upload
  uploadArchiveLog(file, last) {
    const arryBuffer = new Response(file).arrayBuffer();
    const workbook = new Excel.Workbook();
    const that = this;
    arryBuffer.then(function(data) {
      workbook.xlsx.load(data)
        .then(function() {
          const worksheet = workbook.worksheets[0]; // workbook.getWorksheet('Sheet 1');
          const archLogs = [];
          let base = '';
          let ids = '(';
          let sql = 'insert into SYSTNAPS_ARCHLOG_ARCH("id","systnaps_archid","name_table","count_src","count_csv","count_bad","count_arch","ok_src_csv","ok_csv_arch","time_save_csv","time_load_arch","base") values';
          // let sql = '';
          worksheet.eachRow(function(row, rowNumber) {
            if (rowNumber > 1) {
              // ok_src_csv check value
              let OK_SRC_CSV = '';
              if (parseInt(that.getProcess(row.values[3]), 10) == parseInt(that.getProcess(row.values[4]), 10)) {
                OK_SRC_CSV  = 'OK';
              } else {
                OK_SRC_CSV  = 'KO';
              }
              // ok_csv_arch check value
              let OK_CSV_ARCH = '';
              if (parseInt(that.getProcess(row.values[6]), 10) == parseInt(that.getProcess(row.values[4]), 10)) {
                OK_CSV_ARCH = 'OK';
              } else {
                OK_CSV_ARCH = 'KO';
              }
              //      id,        systnaps_archid,      name_table,              count_src,                             count_csv,                             count_bad                              count_arch,                             ok_src_csv,          ok_csv_arch ,         time_save_csv,     time_load_arch,                          base
              sql += '(DEFAULT,' + row.values[1] + ',\'' + that.skipString(row.values[2]) + '\',' + that.getProcess(row.values[3]) + ',' + that.getProcess(row.values[4]) + ',' + that.getProcess(row.values[5]) + ',' + that.getProcess(row.values[6]) + ',\'' + OK_SRC_CSV + '\',\'' + OK_CSV_ARCH + '\',' + that.getProcess(row.values[9]) + ',' + that.getChar(row.values[10]) + ',\'' + that.getChar(row.values[11]) + '\'),';
              base = that.getChar(row.values[11]);
              ids += row.values[1] + ',';
              archLogs.push(new archLog(row.values[1], that.skipString(row.values[2]), that.getProcess(row.values[3]), that.getProcess(row.values[4]), that.getProcess(row.values[5]), that.getProcess(row.values[6]), that.getChar(row.values[7]), that.getChar(row.values[8]), that.getProcess(row.values[9]), that.getProcess(row.values[10]), that.getChar(row.values[11])));
            }
            // console.log(row.values);
          });
          //  console.log(JSON.stringify(archLogs));
          // sql = [...sql].reverse().join("");
          // let sql1 = that.rot13(sql);
          // console.log(sql,base, ids,"end log\n");return;
          that.reportService.uploadArchiveLog(/*archLogs*/sql, ids, base).subscribe(
            d => {
                $('#divGlobalProgress').remove();

                if (!that.addedLog) {
                    that.listFilesArchive.push({
                        name: 'SYSTNAPS_ARCHLOG_ARCH.xlsx',
                        status: 'OK',
                        detail: that.lang === 'en' ? 'Uploaded success' : 'Succès du téléchargement'

                    });
                    that.addedLog = true;
                }
                addProgressBar(60, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_ARCH.xlsx uploaded with success' : 'SYSTNAPS_ARCHLOG_ARCH.xlsx téléchargé avec succès');

              if (last) {
                // console.log('arhclog setTask');return;
                  $('#divGlobalProgress').remove();
                  addProgressBar(70, that.lang === 'en' ? 'Archive Purge not supplied' : 'La purge d\'archive n\'est pas fournie');
                  $('#divGlobalProgress').remove();

                  if (!that.addedPurge) {
                      that.listFilesArchive.push({
                          name: 'SYSTNAPS_ARCHLOG_PURGE.xlsx',
                          status: 'KO',
                          detail: that.lang === 'en' ? 'File not supplied' : 'Fichier non fourni'
                      });
                      that.addedPurge = true;
                  }
                  setTimeout(() => {
                      $('#divGlobalProgress').remove();
                      addProgressBar(80, that.lang === 'en' ? 'Updating archive with last data' : 'Mise à jour de l\'archive avec les dernières données');
                      that.updateArchive();
                  }, 50);
              } else {
                const archPurgeIndex = that.files.findIndex(e => e.name.toLowerCase() == 'systnaps_archlog_purge.xlsx');
                // console.log(archPurgeIndex);
                if (archPurgeIndex != -1) {
                    $('#divGlobalProgress').remove();
                    addProgressBar(60, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_PURGE.xlsx is pending' : 'SYSTNAPS_ARCHLOG_PURGE.xlsx est en attente');
                  that.uploadArchivePurge(that.files[archPurgeIndex], true);
                }
              }

              // Swal.fire({
              //   icon: 'success',
              //   confirmButtonText: 'Ok',
              //   title: 'SYSTNAPS_ARCHLOG_ARCH.xlsx uploaded with success',
              //   timer: 1500
              // });
            },
            err => {
                $('#divGlobalProgress').remove();
                addProgressBar(100, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_ARCH.xlsx upload ERROR... Aborting upload!!!' : 'SYSTNAPS_ARCHLOG_ARCH.xlsx erreur de téléchargement... Annulation du téléchargement!!!');
                $('#ldp').children().eq(0).toggleClass('bg-danger');
                setTimeout(() => {
                    that.files = [];
                    $('#divGlobalProgress').remove();

                    if (!that.addedLog) {
                        that.listFilesArchive.push({
                            name: 'SYSTNAPS_ARCHLOG_ARCH.xlsx',
                            status: 'KO',
                            detail: that.lang === 'en' ? 'Upload error' : 'Erreur de téléchargement'
                        });
                        that.addedLog = true;
                    }
                    $('#uploadArchiveStatus').modal('show');
                    // Swal.fire({
                    //     icon: 'error',
                    //     title: 'Error in upload SYSTNAPS_ARCHLOG_ARCH.xlsx',
                    //     confirmButtonText: 'Ok'
                    // });
                }, 10);
            }/*,
            ()=>{
              that.reportService.setTaskObjectArch().subscribe(
                d=>{},
                err=>{console.log(err);},
                ()=>{
                  console.log('upload end');
                  removeLoader('vide','f',true);
                }
              );
            }*/
          );
        });
    });
  }
  // archive log upload
  uploadArchivePurge(file, last) {
    const arryBuffer = new Response(file).arrayBuffer();
    const workbook = new Excel.Workbook();
    const that = this;
    arryBuffer.then(function(data) {
      workbook.xlsx.load(data)
        .then(function() {
          const worksheet = workbook.worksheets[0]; // workbook.getWorksheet('Sheet 1');
          let base = '';
          let ids = '(';
          let sql = 'insert into SYSTNAPS_ARCHLOG_PURGE("id","systnaps_archid","name_table","count_src","count_arch","count_csv","count_bad","count_temp","count_purge","ok_arch_csv","ok_csv_temp","ok_src_purge","time_save_csv","time_load_temp","time_purge", "base") values';
          // let sql = '';
          const archPurges = [];
          worksheet.eachRow(function(row, rowNumber) {
            if (rowNumber > 1) {
              // SYSTNAPS_ARCHID:1,NAME_TABLE:2,COUNT_SRC:3,COUNT_CSV:4,COUNT_BAD:5,COUNT_ARCH:6,COUNT_TEMP:7,COUNT_PURGE:8,OK_ARCH_CSV:9,OK_CSV_TEMP:10,OK_SRC_PURGE:11,TIME_SAVE_CSV:12,TIME_LOAD_TEMP:13,TIME_PURGE:14
              // SYSTNAPS_ARCHID	NAME_TABLE	COUNT_SRC	COUNT_ARCH	COUNT_CSV	COUNT_BAD	COUNT_TEMP	COUNT_PURGE	OK_ARCH_CSV	OK_CSV_TEMP	OK_SRC_PURGE	TIME_SAVE_CSV	TIME_LOAD_TEMP	TIME_PURGE
              // ok_arch_csv check value
              let OK_ARCH_CSV = '';
              if (parseInt(that.getProcess(row.values[4]), 10) == parseInt(that.getProcess(row.values[5]), 10)) {
                OK_ARCH_CSV = 'OK';
              } else {
                OK_ARCH_CSV = 'KO';
              }
              // ok_csv_temp check value
              let OK_CSV_TEMP = '';
              if (parseInt(that.getProcess(row.values[7]), 10) == parseInt(that.getProcess(row.values[5]), 10)) {
                OK_CSV_TEMP = 'OK';
              } else {
                OK_CSV_TEMP = 'KO';
              }
              // ok_src_purge check value
              let OK_SRC_PURGE = '';
              if (parseInt(that.getProcess(row.values[3]), 10) == parseInt(that.getProcess(row.values[8]), 10)) {
                OK_SRC_PURGE = 'OK';
              } else {
                OK_SRC_PURGE = 'KO';
              }
              //       id,          systnaps_archid,     name_table,           count_src,                             count_arch,                            count_csv,                             count_bad,                             count_temp,                            count_purge,                            ok_arch_csv,          ok_csv_temp,           ok_src_purge,        time_save_csv,                         time_load_temp,                         time_purge,                              base
              sql += '(DEFAULT,' + row.values[1] + ',\'' + that.skipString(row.values[2]) + '\',' + that.getProcess(row.values[3]) + ',' + that.getProcess(row.values[4]) + ',' + that.getProcess(row.values[5]) + ',' + that.getProcess(row.values[6]) + ',' + that.getProcess(row.values[7]) + ',' + that.getProcess(row.values[8]) + ',\'' + OK_ARCH_CSV + '\',\'' + OK_CSV_TEMP + '\',\'' + OK_SRC_PURGE + '\',' + that.getProcess(row.values[12]) + ',' + that.getProcess(row.values[13]) + ',' + that.getChar(row.values[14]) + ',\'' + that.getChar(row.values[15]) + '\'),';
              base = that.getChar(row.values[15]);
              ids += row.values[1] + ',';
              archPurges.push(new archPurge(row.values[1], that.skipString(row.values[2]), that.getProcess(row.values[3]), that.getProcess(row.values[4]), that.getProcess(row.values[5]), that.getProcess(row.values[6]), that.getProcess(row.values[7]), that.getProcess(row.values[8]), that.getChar(row.values[9]), that.getChar(row.values[10]), that.getChar(row.values[11]), that.getProcess(row.values[12]), that.getProcess(row.values[13]), that.getProcess(row.values[14]), that.getChar(row.values[15])));
            }
            // console.log(row.values);
          });
          // sql = [...sql].reverse().join("");
          // let sql1 = that.rot13(sql);
          //  console.log(sql, ids, base,"end purge\n");return;
          //  console.log(JSON.stringify(archPurges));
          that.reportService.uploadArchivePurge(/*archPurges*/sql, ids, base).subscribe(
            d => {
                $('#divGlobalProgress').remove();

                if (!that.addedPurge) {
                    that.listFilesArchive.push({
                        name: 'SYSTNAPS_ARCHLOG_PURGE.xlsx',
                        status: 'OK',
                        detail: that.lang === 'en' ? 'Uploaded success' : 'Téléchargement réussi'
                    });
                    that.addedPurge = true;
                }
                addProgressBar(90, that.lang==='en'?'Updating archive with last data':'Mise à jour de l\'archive avec les dernières données');
                that.updateArchive();
            },
            err => {
                $('#divGlobalProgress').remove();

                if (!that.addedPurge) {
                    that.listFilesArchive.push({
                        name: 'SYSTNAPS_ARCHLOG_PURGE.xlsx',
                        status: 'KO',
                        detail: that.lang === 'en' ? 'Upload error' : 'Erreur de téléchargement'

                    });
                    that.addedPurge = true;
                }
                addProgressBar(100, that.lang === 'en' ? 'SYSTNAPS_ARCHLOG_PURGE.xlsx upload ERROR... Aborting upload!!!' : 'SYSTNAPS_ARCHLOG_PURGE.xlsx erreur de téléchargement... Annulation du téléchargement!!!');

                $('#ldp').children().eq(0).toggleClass('bg-danger');
                setTimeout(() => {
                    $('#divGlobalProgress').remove();
                    that.files = [];
                    $('#uploadArchiveStatus').modal('show');
                    // Swal.fire({
                    //     icon: 'error',
                    //     title: 'Error in upload SYSTNAPS_ARCHLOG_PURGE.xlsx',
                    //     confirmButtonText: 'Ok'
                    // });
                }, 10);
            }/*,
            ()=>{
              that.reportService.setTaskObjectPurge().subscribe(
                d=>{},
                err=>{console.log(err);}
              );
            }*/
          );
        });
    });
  }
  setTaskObjectPurge(modelData) {
    this.reportService.setTaskObjectPurge(modelData).subscribe(
      d => {
        const removed = this.archiveToUpdate.splice(0, 100);
        if (removed.length > 0) {
          removed.map(e => {
            e.systnaps_archname = this.skipString(e.systnaps_archname);
            e.application = this.skipString(e.application);
            e.base = this.skipString(e.base);
          });
          this.totalProgress += this.totalProgress < 100 ? this.step : 0;
            // $('#divGlobalProgress').remove();
            // addProgressBar(this.totalProgress, 'set teask object purge');
          setProgressBarValue(this.totalProgress);
          // console.log(this.totalProgress);
          this.setTaskObjectPurge(removed);
        } else {
          this.files = [];
          $('#divGlobalProgress').remove();
            $('#uploadArchiveStatus').modal('show');
          //  Swal.fire({
          //   icon: 'success',
          //   title: 'Upload of the archive is finished successfully',
          //   confirmButtonText: 'Ok'
          // });
           this.onGetStatArchiveToChart()
        }
      },
      err => {
        console.error(err);
        // removeLoader('vide', 'f', true);
      }
    );
  }

  /*************************************************** start dashboard upload archive *******************************/
  onGetDataArchiveDash() {
    // purge donut
    const total = 5; // this.archData.length;
    let ok_purge_Count = 0;
    let ko_purge_Count = 0;
    let tobe_purge_count = 0;
    const purgeData = [
      {
        value: (ok_purge_Count / (ok_purge_Count + ko_purge_Count + tobe_purge_count)) * 100,
        label: 'DONE',
        color: '#00e396'
      },
      {
        value: (ko_purge_Count / (ok_purge_Count + ko_purge_Count + tobe_purge_count)) * 100,
        label: 'FAILED',
        color: '#ff4560'
      },
      {
        value: (tobe_purge_count / (ok_purge_Count + ko_purge_Count + tobe_purge_count)) * 100,
        label: 'NOT',
        color: '#cccccc'
      }
    ];
    const donutSize = 200;
    if ($('#indic_donut_purge').length > 0) {
      $('#indic_donut_purge').donute({
        donutSize,
        center: {
          x: donutSize / 2,
          y: donutSize / 2
        },
        radius: donutSize * 0.2,
        data: purgeData
      }).startShowAnimation(function() {
        $('#arch_times_purge').show();
        $('[id^=indic_donut] > circle').attr('display', 'none');
        $('[id^=indic_donut] >rect').attr('display', 'none');
        $('[id^=indic_donut] >text').attr('display', 'none');
        d3.select('#indic_donut_purge').append('text')
          .attr('id', 'purge_total')
          .style('font-weight', 'bold')
          .attr('x', function() {
            return total < 10 ? 95 : 90;
          })
          .attr('y', 105)
          .text(total);
        d3.select('#indic_donut_purge').append('text')
          .attr('id', 'purge_status')
          .style('font-weight', 'bold')
          .attr('x', 40)
          .attr('y', 20)
          .text('PURGE STATUS');
        // $('#arch_lines_purge' + id).show();
        // if ($('#odometer_purge' + id).length > 0) {
        //   const demo3 = new CountUp('odometer_purge' + id, that.purge_line, options);
        //   if (!demo3.error) {
        //     demo3.start();
        //   } else {
        //     console.error(demo3.error);
        //   }
        // }

      });
    }
  }
  /*************************************************** end dashboard upload archive ********************************/
  // get data to global chart Pie
  onGetStatArchiveToChart() {
      this.uploadService.getStatArchiveToChart().subscribe(
          result => {
              let arch = result.dataArch[0].count;
              let archid = result.dataArchid[0].count;
              let cand = result.dataCand[0].count;
              let purge = result.dataPurge[0].count;
              // tslint:disable-next-line:no-non-null-assertion
              const chartDom = document.getElementById('chart-archive')!;
              const myChart = echarts.init(chartDom);
              let option: EChartsOption;

              option = {
                  tooltip: {
                      trigger: 'item'
                  },
                  legend: {
                      top: '5%',
                      left: 'center'
                  },
                  series: [
                      {
                          name: 'Access From',
                          type: 'pie',
                          radius: ['40%', '70%'],
                          avoidLabelOverlap: false,
                          itemStyle: {
                              borderRadius: 10,
                              borderColor: '#fff',
                              borderWidth: 2
                          },
                          label: {
                              show: false,
                              position: 'center'
                          },
                          emphasis: {
                              label: {
                                  show: true,
                                  fontSize: '30',
                                  fontWeight: 'bold',
                                  formatter(params) {
                                      return  params.name + '\n\n' + params.value;
                                  },
                              }
                          },
                          labelLine: {
                              show: false
                          },
data: [
    { value: arch, name: this.lang === 'en' ? 'Logs' : 'Logs' },
    { value: archid, name: this.lang === 'en' ? 'Archive ID' : 'ID de l\'archive' },
    { value: cand, name: this.lang === 'en' ? 'Candidat' : 'Candidat' },
    { value: purge, name: this.lang === 'en' ? 'Purge' : 'Purge' }
],
                          color: ['blue', 'green', 'orange', 'red', 'gray']
                      }
                  ]
              };
              // tslint:disable-next-line:no-unused-expression
              option && myChart.setOption(option);
          },
          err => {
              console.error(err);
          }
      );
  }
}
