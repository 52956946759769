import { Component, OnInit, Input ,OnDestroy, SimpleChanges } from '@angular/core';
import { DdtmService } from '../../services/ddtm/ddtm.service';
import {Subscription} from 'rxjs';
import * as d3 from 'd3';
import { TranslateService } from '@ngx-translate/core';
declare const $: any;
@Component({
  selector: 'app-size-chart',
  templateUrl: './size-chart.component.html',
  styleUrls: ['./size-chart.component.css']
})
export class SizeChartComponent implements OnInit , OnDestroy{
  @Input() isSizeLaunched:boolean;
  @Input() objectId:boolean;
  @Input() search:boolean;
  @Input() selectedRecords:any;
  @Input() selectedFields:any;
  lang: string;
  constructor(private ddtmService: DdtmService,private translate: TranslateService) {}
  _opened = false;
  showSize = true;
  subscription: Subscription = new Subscription();
  ngOnInit(): void {
    $('#legendSizeWrapper').attr('style', 'display: block!important');
    // creation of legends 
    this.createLegend(1,'legendSizeCircles',[10, 20, 30],[ 'Table','Firstkey','Owner']);
    // switch between with empty/no empty tables
    $('#sizeSwitch').prop('checked', true);

    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
  const that = this;
  setInterval(() => {
    this.lang = localStorage.getItem('lang') || 'en';
    this.translate.use(this.lang);
  }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (this.isSizeLaunched == true){
      $('#sizesByReport').DataTable().clear().destroy();
      $('#spinner_report_size').show();
      $('#reportSizeChart').hide();

      var parametres = {
         report: this.objectId,
         idUser: localStorage.getItem('userId'),
         search: this.search,
        field: this.selectedFields,
       record: this.selectedRecords
      }

     let subscription1$ = this.ddtmService.getReportSize(parametres).subscribe(
        data => {
          this.render(data);
        },
        err => {
            console.error(err);
        },
        ()=>{
          $('#spinner_report_size').hide();
          $('#reportSizeChart').show();
            this.OnGetReportSizes(this.objectId);
        }
     );
     this.subscription.add(subscription1$);
    }
  }
  _toggleSidebar() {
    this._opened = !this._opened;
    if (this.showSize) {
        $('#showSize').html('<i class="fas fa-arrow-circle-right fa-2x"></i>');
        this.showSize = false;
    } else {
        $('#showSize').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
        this.showSize = true;
    }
  }
  render(data)  {
    const width = 932;
    const height = 932;
    const color = d3.scaleLinear()
      .domain([0, 5])
      .range(["hsl(152,80%,80%)", "hsl(228,30%,40%)"])
      .interpolate(d3.interpolateHcl);
    const pack = data => d3.pack()
      .size([width, height])
      .padding(3)
      (d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value));
    const root = pack(data);
    let focus = root;
    let view;
    $('#sizeChart').empty();
    const svg = d3.select("#sizeChart");

    svg.attr("viewBox", `-${width / 2} -${height / 2} ${width} ${height}`)
      .style("display", "block")
      .style("margin", "0")
      .style("background", '#ffffff')
      .style("cursor", "pointer")
      .on("click", () => zoom(root));
  
    const cont = svg.append("g");
    const node = cont
      .selectAll("circle")
      .data(root.descendants().slice(1))
      .join("circle")
      .attr('id', d => 'sizeChart_' + d.data.name + d.data.id )
      .attr('fill', function(d){
        if(!d.children) {
            return d.data.count > 0 ? "white" : color(d.depth)
        }
        else if (d.children){
          return d.data.count > 0 ? color(d.depth) : "#959595"
        }
      })
      .attr("pointer-events", d => !d.children ? "none" : null)
      .on("click", d => focus !== d && (zoom(d), d3.event.stopPropagation()));
    
    const label = svg.append("g")
      .style("font", "10px sans-serif")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .selectAll("text")
      .attr('class', 'labelZoom')
      .data(root.descendants())
      .join("text")
      .style("fill-opacity", d => d.parent === root ? 1 : 0)
      .style("display", d => d.parent === root ? "inline" : "none")
      .text(d => d.data.name + '***' + d.data.value);
  
    zoomTo([root.x, root.y, root.r * 2]);

    function zoomTo(v) {
      const k = width / v[2];
  
      view = v;
  
      label.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
      node.attr("transform", d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
      node.attr("r", d => d.r * k);
     
    }
    function zoom(d) {
      const focus0 = focus;
      focus = d;
      const transition = svg.transition()
        .duration(d3.event.altKey ? 7500 : 750)
        .tween("zoom", d => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return t => zoomTo(i(t));
        });
       
          label
          .filter(function(d) { return d.parent === focus || this.style.display === "inline"; })
          .transition(transition)
          .style("fill-opacity", d => d.parent === focus ? 1 : 0)
          .style("display", function(d){
            if ($('#sizeSwitch').is(":checked")== false){
            return d.data.count > 0 ? "inline" : "none"
            }else{
              return "inline"
            }
          })
          .on("start", function(d) { if (d.parent === focus) this.style.display = "inline"; })
          .on("end", function(d) { if (d.parent !== focus) this.style.display = "none"; });
        
     
    }
    // switch between with empty/no empty tables
    $('#sizeSwitch').change(function() {
      if (this.checked) {
        node
        .transition()
        .duration(750)
        .style("display", "inline")
        label
        .transition()
        .duration(750)
        .style("fill-opacity", d => d.parent === focus ? 1 : 0)
        .style("display",  "inline" )
      }else{
        node
        .transition()
        .duration(750)
        .style("display", function(d){return d.data.count > 0 ? "inline" : "none"
        })
        label
        .transition()
        .duration(750)
        .style("fill-opacity", d => d.parent === focus ? 1 : 0)
        .style("display", function(d){return d.data.count > 0  ? "inline" : "none"
        })
      }
    });
  };
  // list sizes for datatable sidebar 
  OnGetReportSizes(id) {
    
    if ($('#sizesByReport').length > 0) {
      $('#sizesByReport').remove();
    }
    $('#sizesByReport').DataTable().clear().destroy();
    var table = $('#sizesByReport').DataTable();
    $('#size_report').append('<table id="sizesByReport" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;width: 100%;">   <thead>\n' +
      '<tr>' +
      '<th>name</th>'+
      '<th>count_firstkeys</th>'+
      '<th>count_tables</th>'+
      '<th>count</th>'+
      '<th></th>' +
      '</tr>' +
      '</thead></table>');
      function format(d) {
       var tables = [];
        for (let i = 0; i < d.children.length; i++) {
          for (let j = 0; j < d.children[i].children.length; j++) {
            tables.push(d.children[i].children[j]);
          }
        }
        return tables;
      }
    $('#sizesByReport').DataTable({
          processing: true,
          responsive: true,
          lengthChange:true,
          destroy: true,
          "pagingType": "numbers",
          rowCallback(row, data) {
              $('td:eq(0)', row).html('<a class="sizeclick" style="cursor:pointer" onmouseover="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#000000\', \'text-decoration\': \'none\'});" id="'+ data.name +'"  >' + data.name + '</a>');
          },
          ajax: (dataTablesParameters: any, callback) => {
            var parametres = {
               report: this.objectId,
               idUser: localStorage.getItem('userId'),
               search: this.search,
              field: this.selectedFields,
             record: this.selectedRecords
            }
      
            let subscription2$ = this.ddtmService.getReportSize(parametres).subscribe(
              reponse => {
                var report_sizes = reponse.owners;
                callback({
                  recordsTotal: reponse.count_keys,
                  recordsFiltered: reponse.count_keys,
                  data: report_sizes,
                });
              },
              err => {
                console.error(err);
              }
            );
            this.subscription.add(subscription2$);
          },
          columns: [
            { "data": "name", "title": this.lang ? 'Name' : 'Nom' },
            { "data": "count_firstkeys", "title": this.lang ? 'Count FirstKeys' : 'Nombre de premières clés', "type": "num", "render": $.fn.dataTable.render.number( ' ', '.', 0) },
            { "data": "count_tables", "title": this.lang ? 'Count Tables' : 'Nombre de tables', "type": "num", "render": $.fn.dataTable.render.number( ' ', '.', 0) },
            { "data": "value", "title": this.lang ? 'Count Records' : 'Nombre d\'enregistrements', "type": "num", "render": $.fn.dataTable.render.number( ' ', '.', 0) },
                {
                  className: 'details-control',
                  orderable: false,
                  data: null,
                  defaultContent: '',
                  title: ''
                },
          ],
          order: [[1, 'desc']],
         "initComplete": function(settings, json) {
            $('#sizesByReport tbody').on('click', '.details-control', function() {
              const tr = $(this).closest('tr');
              const row = $('#sizesByReport').DataTable().row(tr);
              if (row.child.isShown()) {
                row.child.hide();
                tr.removeClass('shown');
              } else {
                var dataSet = format(row.data());
                tr.addClass('shown');
                row.child('<h6 style="text-align: center;padding-top: 2%;">List of Tables</h6><table class="table_report_sizes_tables" id ="table_report_sizes_tables' + id + '" cellpadding="5" cellspacing="0" border="0" ></table>').show();
                $('#table_report_sizes_tables'+ id).DataTable({
                  "pagingType": "numbers",
                  responsive: true,
                  lengthChange:false,
                  "bInfo" : false,
                  data: dataSet,
                  "columns": [
                    { "data": "name" ,title:'Table'},
                    { "data": "count" ,title:'count', "type": "num",'render': $.fn.dataTable.render.number( ' ', '.', 0)},
                  ],
                  "pageLength": 10,
                  "lengthMenu": [ 5,10,15,20, 25,30,35,40,45, 50, 75, 100 ],
                  order: [[1, 'desc']],
                });
              }
            });
          }
      });
      $('#sizesByReport').on('click', '.sizeclick', function(){
        var nameOwn = $(this).attr("id");
          if(nameOwn === undefined){
            document.getElementById('sizeChart').dispatchEvent(new Event('click'));
          }else{
            if($('#sizeChart').css('display')!='none'){
              document.getElementById('sizeChart_' + nameOwn +'undefined').dispatchEvent(new Event('click'));
            }
            if($('#sizeChart').css('display')!='none'){
              document.getElementById('sizeChart_' + nameOwn+'undefined').dispatchEvent(new Event('click'));
            }
          }
      });

  }
  createLegend (scaleFactor, id, legendSizes, legendTexts)  {
    const  commaFormat = d3.format(',');
    const offset = legendSizes.length - 1;
    let width = 268,
    height = legendSizes[offset]*2*1.2;
    let	legendCenter = -10,
    legendBottom = height,
    legendLineLength = legendSizes[offset]*1.3,
    textPadding = 5;

     //Create SVG for the legend
     const legends = d3.select("#" + id).append("svg")
       .attr("width", width)
       .attr("height", height*1.5)
       .append("g")
       .attr("class", "legendWrapper")
       .attr("transform", "translate(" + width / 3 + "," + 0 + ")");
   
     //Draw the circles
     legends.selectAll(".legendCircle")
       .data(legendSizes)
       .enter().append("circle")
       .attr('r', function(d) { return d; })
       .attr('class',"legendCircle")
       .attr('cx', legendCenter)
       .attr('cy', function(d) { return legendBottom-d; });

     legends.append('rect')
       .attr('x' , legendCenter)
       .attr('y' , legendBottom + 10)
       .attr('width', 10)
       .attr('height', 10)
       .attr("rx", 3)
       .attr("ry", 3)
       .attr('fill', '#74D7CA');

     //Draw the line connecting the top of the circle to the number
     legends.selectAll(".legendLine")
       .data(legendSizes)
       .enter().append("line")
       .attr('class',"legendLine")
       .attr('x1', legendCenter)
       .attr('y1', function(d) { return legendBottom-2*d; })
       .attr('x2', legendCenter + legendLineLength)
       .attr('y2', function(d) { return legendBottom-2*d; });

     //Place the value next to the line
     legends.selectAll(".legendText")
       .data(legendSizes)
       .enter().append("text")
       .attr('class',"legendText")
       .attr('x', legendCenter + legendLineLength + textPadding)
       .attr('y', function(d) { return legendBottom-2*d; })
       .attr('dy', '0.3em')
       .text(function(d , i) { return legendTexts[i]; });
       
     legends.append('text')
       .attr('class', 'legendText')
       .attr('x', legendCenter + legendLineLength + textPadding)
       .attr('y', legendBottom + 17)
       .text('Report');
  };

}
