import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DesignerService} from '../../../services/designer/designer.service';
import Swal from 'sweetalert2';
import {CryptoService} from '../../../crypto.service';
import {TranslateService} from '@ngx-translate/core';
import {addLoader, removeLoader} from '../../../shared';
declare const $: any;
@Component({
  selector: 'app-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.css'],
})
export class GraphComponent implements OnInit, AfterViewInit {
  lang: string;
  id = 'null';
  applicationDiag: string;
  nameDiag: string;
  contentXml: string;
  listApps = [];
  selectedApps = [];
  selectedAppsTemp = [];
  listMs = [];
  selectedMs = [];
  selectedMsTemp = [];
  loadingMs = true;
  loadingApps = true;
  selectedIdsMs = [];
  listTableNames = [];
  tablesBuffer = [];
  searchbtn = false;


  listPkFields = [];
  listNoPkFields = [];
  loadingTn = true;
  selecteTableName: string;
  mxCellValue: any;

  changes = [];
  hasChanges = false;
  mxDisabled = false;
  redirectionToExportDiag = false;

  changeEnabled = true;
  // public cont = '<mxGraphModel dx="506" dy="336" grid="1" gridSize="10" guides="1" tooltips="1" connect="1" arrows="1" fold="1" page="1" pageScale="1" pageWidth="827" pageHeight="1169" background="#ffffff"><root><mxCell id="0"/><mxCell id="1" parent="0"/><mxCell id="2" value="Classname" style="swimlane;fontStyle=0;childLayout=stackLayout;horizontal=1;startSize=26;fillColor=none;horizontalStack=0;resizeParent=1;resizeParentMax=0;resizeLast=0;collapsible=1;marginBottom=0;swimlaneFillColor=#ffffff;" vertex="1" parent="1"><mxGeometry x="20" y="20" width="140" height="104" as="geometry"/></mxCell><mxCell id="3" value="+ field: type" style="text;strokeColor=none;fillColor=none;align=left;verticalAlign=top;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="2"><mxGeometry y="26" width="140" height="26" as="geometry"/></mxCell><mxCell id="4" value="+ field: type" style="text;strokeColor=none;fillColor=none;align=left;verticalAlign=top;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="2"><mxGeometry y="52" width="140" height="26" as="geometry"/></mxCell><mxCell id="5" value="+ field: type" style="text;strokeColor=none;fillColor=none;align=left;verticalAlign=top;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;" vertex="1" parent="2"><mxGeometry y="78" width="140" height="26" as="geometry"/></mxCell></root></mxGraphModel>';
  designerBridge = {application: '', model: '', object: '', table: '', field: '', componentId: '', component: ''};
  constructor(private activeRoute: ActivatedRoute, private designerService: DesignerService, private router: Router,
              private cryptoService: CryptoService, private translate: TranslateService) {}

  @ViewChild('editor') editorElement: ElementRef;

  editor: Editor;
  editorUi: EditorUi;
  mxGraph: mxGraph;
  mxGraphModel: mxGraphModel;
  mxEventSource: mxEventSource;

  ngOnInit() {
    localStorage.setItem('typeDesigner', 'objectModel');
    // get params in route
    this.activeRoute.paramMap.subscribe(params => {
      let shared = params.get('application');
      let diagId = params.get('componentId');
      let component = params.get('component');
      // model = model.replace(/__________/g, '/');
      diagId = diagId.replace(/__________/g, '/');
      component = component.replace(/__________/g, '/');
      shared = shared.replace(/__________/g, '/');
      // decrypt params
      diagId = this.cryptoService.get(diagId);
      this.id = diagId;
      component = this.cryptoService.get(component);
      if (diagId != null && diagId != '0') {
        this.designerBridge.componentId = diagId;
      }
      if (component != null && component != '0') {
        this.designerBridge.component = component;
      }
      shared = this.cryptoService.get(shared);
      if (shared != null && shared != '0') {
        this.designerBridge.application = shared;
      }
    });
    if (this.id != null && this.id != '0') {
      this.getContentOfDiagram();
      // this.onGetListApps();
    } else {
      this.redirectToDesigner();
    }
    // this.detectChangeLaguage();
    this.  detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngAfterViewInit() {
    const RESOURCE_BASE = 'assets/designer/resources/grapheditor';
    const STYLE_PATH = 'assets/designer/styles';
    const editorDiv = this.editorElement.nativeElement;
    const comp = this;

    mxResources.loadDefaultBundle = false;
    var bundle = mxResources.getDefaultBundle(RESOURCE_BASE, undefined) ||
      mxResources.getSpecialBundle(RESOURCE_BASE, undefined);

    // Fixes possible asynchronous requests
    mxUtils.getAll([bundle, STYLE_PATH + '/default.xml'], function(xhr)
    {
      // Adds bundle text to resources
      mxResources.parse(xhr[0].getText());

      // Configures the default graph theme
      var themes = new Object();
      themes[/*Graph.prototype.defaultThemeName*/"default"] = xhr[1].getDocumentElement();

      // Main
      comp.mxEventSource = new mxEventSource();
      comp.mxGraph = new mxGraph(undefined, undefined, undefined, undefined);
      comp.mxGraphModel = new mxGraphModel(null);
      comp.editor = new Editor(false, themes, undefined, undefined, undefined);
      comp.editorUi = new EditorUi(
        comp.editor,
        editorDiv,
        undefined
      );
    }, function()
    {
      console.log('<center style="margin-top:10%;">Error loading resource files. Please check browser console.</center>');
    });
    if (localStorage.getItem('typeDesigner') == 'objectModel') {
      this.getForm();
    }
  }
  detectChangeLocalStorage() {
    if ((localStorage.getItem('typeDesigner') == 'objectModel') && localStorage.getItem('newDiagContent') && (localStorage.getItem('newDiagContent') != null) && (localStorage.getItem('newDiagFilename') != null))
    {
      this.changeEnabled = false;
      // addLoaderMxgraph('vide', 'f', true);
      addLoader(this.lang === 'en' ? 'PLEASE WAIT' : 'VEUILLEZ PATIENTER');
      let dataContent;
      let username = localStorage.getItem('username');
      let typeContent = localStorage.getItem('newDiagContent');
      let nameContent = localStorage.getItem('newDiagFilename');
      dataContent = btoa(localStorage.getItem('newDiagXml'));
      localStorage.removeItem('newDiagContent');
      // if (this.id != 'null') {
        let dataDiag = {
          idDiag: this.id,
          nameuser: username,
          // appications: this.selectedApps,
          // modelStructures: this.selectedMs,
          type: typeContent,
          name: nameContent,
          xml: dataContent
        };
        this.designerService.setUpdateProjDesigner(dataDiag).subscribe(
          data => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });
              Toast.fire({
                icon: 'success',
                title: this.lang ? 'Diagram has been saved' : 'Le diagramme a été enregistré',
              });             
            // console.log(data);
          },
          err => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });
              Toast.fire({
                icon: 'error',
                title: (this.lang ? 'Diagram Not saved' : 'Diagramme non enregistré') + err,
              });
             
            console.error(err);
            this.changeEnabled = true;
            removeLoader();
          },
          () => {
            localStorage.removeItem('newDiagFilename');
            localStorage.removeItem('newDiagXml');
            localStorage.removeItem('errorPNG');
            this.editor.setModified(false);
            //@ts-ignore
            this.editor.undoManager.clear();
            this.hasChanges = false;
            this.changeEnabled = true;
            // removeLoader('vide', 'f', true);
          }
        );
    } else if ((localStorage.getItem('typeDesigner') == 'objectModel') && localStorage.getItem('newDiagContent2') && (localStorage.getItem('newDiagContent2') != null) && (localStorage.getItem('newDiagFilename2') != null))
    {
      this.changeEnabled = false;
let msgWait=this.lang ? 'P L Z &#160; W A I T' : 'S\'i l vous plaît, patientez'
      addLoader(msgWait);
      let dataContent;
      let username = localStorage.getItem('username');
      let typeContent = localStorage.getItem('newDiagContent2');
      let nameContent = localStorage.getItem('newDiagFilename2');
      dataContent = localStorage.getItem('newDiagXml2');
      localStorage.removeItem('newDiagContent2');
      // if (this.id != 'null') {
      let dataDiag = {
        idDiag: this.id,
        nameuser: username,
        // appications: this.selectedApps,
        // modelStructures: this.selectedMs,
        type: typeContent,
        name: nameContent,
        xml: dataContent
      };
      this.designerService.setUpdateProjDesigner(dataDiag).subscribe(
        data => {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            });
            Toast.fire({
              icon: 'success',
              title: this.lang ? 'Image has been saved' : 'L\'image a été enregistrée',
            });            
          // console.log(data);
        },
        err => {
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            });
            let titleError = this.lang ? 'Diagram Not saved' : 'Diagramme non enregistré';
            Toast.fire({
              icon: 'error',
              title: titleError + err,
            });
           
          console.error(err);
          this.changeEnabled = true;
          removeLoader();
          if (this.redirectionToExportDiag) {
            setTimeout(() => {
              this.redirectToDesigner();
            }, 500);
          }
        },
        () => {
          localStorage.removeItem('newDiagFilename2');
          localStorage.removeItem('newDiagXml2');
          localStorage.removeItem('errorPNG2');
          this.editor.setModified(false);
          //@ts-ignore
          this.editor.undoManager.clear();
          this.hasChanges = false;
          this.changeEnabled = true;
          removeLoader();
          if (this.redirectionToExportDiag) {
            setTimeout(() => {
              this.redirectToDesigner();
            }, 500);
          }
        }
      );
    } else if (localStorage.getItem('errorPNG') == 'true') {
      this.changeEnabled = false;
      localStorage.removeItem('errorPNG');
      setTimeout(() => {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });
        Toast.fire({
          icon: 'error',
          title: this.lang ? 'You cannot generate an image!!!' : 'Vous ne pouvez pas générer une image !!!',
        });
        this.changeEnabled = true;
        removeLoader();
        if (this.redirectionToExportDiag) {
          setTimeout(() => {
            this.redirectToDesigner();
          }, 500);
        }
      }, 3000);
    }
  }
  getContentOfDiagram() {
    let loadingMessage = this.lang ? 'PLEASE&#160; W A I T' : 'S\'il vous plaît, patientez';
    addLoader(loadingMessage);   
    this.designerService.getContentDiag(this.id).subscribe(
      data => {
        if (data.length == 0) {
          removeLoader();
        } else {
          this.contentXml = atob(data[0].content);
          this.nameDiag = data[0].name;
          this.onGetListApps();
          this.setNodeToGraph();
        }
      },
      err => {
        console.error(err);
        removeLoader();
      },
      () => {
      }
    );
  }

  // keypress(event) {
  //   const graph = this.editor.getGraphXml(true);
  // }
  setNodeToGraph() {
    var doc = mxUtils.parseXml(this.contentXml);
    setTimeout(() => {
      this.editor.setGraphXml(doc.documentElement);
      this.editor.setModified(false);
      //@ts-ignore
      this.editor.undoManager.clear();
      this.hasChanges = false;
      this.editor.setFilename(this.nameDiag);
      //@ts-ignore
      this.editor.setStatus(mxUtils.htmlEntities(this.nameDiag));
      this.onGetListTablesBySelectedDiag();
    }, 1000);
  }
  // backToDesigner() {
  //   console.log('ok');
    // this.routeRedirect.navigate(['object-models/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/designer']);
  // }
  hasUnsavedChanges() {
    if ((this.hasChanges == true) || (this.mxDisabled == true)) {
      $('.bd-mxGraph-modal-sm').modal('show');
    } else {
      this.redirectToDesigner();
    }
  }
  // redirection to object model security
  backToDesigner() {
    this.redirectionToExportDiag = true;
    this.saveChanged();
  }
  // save editing diag
  saveChanged() {
    this.redirectionToExportDiag = true;
    this.editorUi.saveFile(false);
    this.editor.setModified(false);
    //@ts-ignore
    this.editor.undoManager.clear();
    this.hasChanges = false;
  }
  redirectToDesigner() {
    let cryptComponent = this.cryptoService.set('designer');
    cryptComponent = cryptComponent.replace(/\//g, '__________');
    let cryptComponentId = this.cryptoService.set(this.id);
    cryptComponentId = cryptComponentId.replace(/\//g, '__________');
    let cryptSHARED = this.cryptoService.set(this.designerBridge.application);
    cryptSHARED = cryptSHARED.replace(/\//g, '__________');
    const url = 'object-models/' + cryptSHARED + '/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/' + cryptComponentId + '/' + cryptComponent + '/model-designer';
    this.router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(() => {
      this.router.navigate([url]);
    });
  }

  getForm() {
    const that = this;
    setInterval(()=> {
      $("iframe").contents().find("#openButton1").off();
      $("iframe").contents().find("#openButton1").click(function(){
        var file = $("iframe").contents().find("#upfile").prop("files");
        // console.log(file);
        var reader = new FileReader();
        reader.readAsText(file[0]);
        reader.onloadend = function() {
          var xmlData = reader.result;
          var parser = new DOMParser();
          //@ts-ignore
          var doc = parser.parseFromString(xmlData, 'text/xml'); // For Firefox, Chrome etc
          var x = doc.getElementsByTagName("diagram");
          var nodeXml = x[0].getElementsByTagName("mxGraphModel")[0];
          var ss = new XMLSerializer();
          //@ts-ignore
          nodeXml = ss.serializeToString(nodeXml);
          var docum = mxUtils.parseXml(nodeXml);
          setTimeout(() => {
            that.editor.setGraphXml(docum.documentElement);
            that.editor.setModified(false);
            //@ts-ignore
            that.editor.undoManager.clear();
            that.editor.setFilename(file[0].name);
            //@ts-ignore
            that.editor.setStatus(mxUtils.htmlEntities(file[0].name));
            that.editorUi.hideDialog(false);
          }, 1000);
        };
      });
      // detect changed localhost
      if (this.changeEnabled == true) {
        this.detectChangeLocalStorage();
      }
      if ((this.hasChanges == true) || ($('.geSprite-undo').parent().eq(0).attr('class') != 'geButton mxDisabled')) {
        this.mxDisabled = true;
        // $('#noChanges').css('display', 'none');
        // $('#unsavedChanges').css('display', 'block');
      } else {
        this.mxDisabled = false;
        // $('#noChanges').css('display', 'block');
        // $('#unsavedChanges').css('display', 'none');
      }
    }, 3000);
  }
  addChange() {
    this.hasChanges = true;
  }
// get list applications to mx-designer (selectedApplications and all aplications
  onGetListApps() {
    let loadingWait = this.lang ? 'P L Z &#160; W A I T' : 'S\'il vous plaît, patientez';
    addLoader(loadingWait);
    
    this.designerService.getListApplicationAndMs(this.id).subscribe(
      data => {
        this.listApps = [... data.listApps];
        this.listMs = [... data.listMs];
        if (data.selectedApps != null) {
          this.selectedAppsTemp = [... data.selectedApps];
          this.selectedApps = [... data.selectedApps];
        }
        if (data.selectedMs != null) {
          this.selectedMs = [... data.selectedMs];
          this.selectedMsTemp = [... data.selectedMs];
        }
        this.loadingApps = false;
        this.loadingMs = false;
      },
      err => {
        console.error(err);
      },
      () => {
        // console.log(this.listMs);
        // this.onGetListTablesBySelectedMs();
      }
    );
  }
  // get list Ms by new selectedApps (add applications)
  onGetListMsByAppls() {
    this.loadingMs = true;
    this.designerService.getListMsByAppls(this.id, this.selectedApps).subscribe(
      data => {
        this.listMs = [... data.map(item => item)];
      },
      err => {
        this.loadingMs = true;
        console.error(err);
      },
      () => {
        this.loadingMs = false;
      }
    );
  }

  // get list table name for mxGraph
  getTableByMs() {
    // console.log(this.selectedIdsMs);
    // this.designerService.getListTableNameInBase().subscribe(
    //   data => {
    //     this.listTableNames = data.map(item => item);
    //   },
    //   err => {
    //     console.error(err);
    //   },
    //   () => {
    //     this.loadingTn = false;
    //   }
    // );
  }
  // remove application in 'ng-select' and remove model structures 'list from list model structure
  onRemoveAppDesigner(event) {
    let selectedAppsTemp = this.selectedApps;
    let listMsTemp = this.listMs;
    let selectedMsTemp = this.selectedMs;
    let allTables = [];
    let childrens = this.editor.getGraph().getDefaultParent().children;
    if (childrens != null) {
      childrens.forEach(el => {
        if (el.value != undefined) {
          allTables.push(el.value);
        }
      });
      selectedAppsTemp.push(event.value.id);
      const filteredRemovedTables = this.listTableNames.filter(value => value.idApp == event.value.id);
      const filteredTakedTables = this.listTableNames.filter(value => value.idApp != event.value.id);
      const filteredUsedTables = filteredRemovedTables.filter(value => allTables.includes(value.name));
      if (filteredUsedTables.length == 0) {
        // alert('removed');
        // remove all tables linked to application (FRONT)
        this.listTableNames = [... filteredTakedTables];
        // searsh remove all model structure linked to application
        const listMsToRemove = this.listMs.filter(value => value.application == event.value.name);
        // execute remove all model structure linked to application
        const listMsNotRemoved = this.listMs.filter(value => value.application != event.value.name);
        this.listMs = [... listMsNotRemoved];
        if (listMsToRemove.length > 0) {
          listMsToRemove.forEach(item => {
            if (this.selectedMs.indexOf(item.id) !== -1) {
              this.onRemoveMsAndTableFromSelectedDiag(item.id);
              this.selectedMs = this.selectedMs.filter(value => value != item.id);
            }
          });
        }
        this.onRemoveSystemFromDiag(this.id, event.value.id);
      } else {
        this.selectedApps = [... selectedAppsTemp];
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          }
        });
        Toast.fire({
          icon: 'error',
          title: this.lang ? 'You cannot remove System!!!' : 'Vous ne pouvez pas supprimer le système !!!',

        });
      }
    } else {
      const filteredTakedTables2 = this.listTableNames.filter(value => value.idApp != event.value.id);
      // remove all tables linked to application (FRONT)
      this.listTableNames = [... filteredTakedTables2];
      // searsh remove all model structure linked to application
      const listMsToRemove2 = this.listMs.filter(value => value.application == event.value.name);
      // execute remove all model structure linked to application
      const listMsNotRemoved2 = this.listMs.filter(value => value.application != event.value.name);
      this.listMs = [... listMsNotRemoved2];
      if (listMsToRemove2.length > 0) {
        listMsToRemove2.forEach(item => {
          if (this.selectedMs.indexOf(item.id) !== -1) {
            this.onRemoveMsAndTableFromSelectedDiag(item.id);
            this.selectedMs = this.selectedMs.filter(value => value != item.id);
          }
        });
      }
      this.onRemoveSystemFromDiag(this.id, event.value.id);
    }

    // const filteredRemovedArrayMs = this.listMs.filter(value => value.application == event.value.name);
    // const filteredTakeArrayMs = this.listMs.filter(value => value.application != event.value.name);
    // if (filteredRemovedArrayMs.length > 0) {
    //   filteredRemovedArrayMs.forEach(item => {
    //     // addLoaderMxgraph('vide', 'f', true);
    //     // removeLoader('vide', 'f', true);
    //     let allTables = [];
    //     let childrens = this.editor.getGraph().getDefaultParent().children;
    //     childrens.forEach(el => {
    //       if (el.value != undefined) {
    //         allTables.push(el.value);
    //       }
    //     });
    //     let tempSelectedMs = [];
    //     let tableMs = [];
    //     const filteredArrayMs = this.listTableNames.filter(value => value.idReport == item.id);
    //     const filteredArrayRest = this.listTableNames.filter(value => value.idReport != item.id);
    //     const filteredArrayTable = filteredArrayMs.filter(value => allTables.includes(value.name));
    //     if (filteredArrayTable.length > 0) {
    //       selectedAppsTemp.push(event.value.id);
    //       const Toast = Swal.mixin({
    //         toast: true,
    //         position: 'top-end',
    //         showConfirmButton: false,
    //         timer: 2000,
    //         timerProgressBar: true,
    //         onOpen: (toast) => {
    //           toast.addEventListener('mouseenter', Swal.stopTimer);
    //           toast.addEventListener('mouseleave', Swal.resumeTimer);
    //         }
    //       });
    //       Toast.fire({
    //         icon: 'error',
    //         title: 'You cannot remove System!!!'
    //       });
    //       this.selectedApps = [... selectedAppsTemp];
    //     } else {
    //       this.listMs = [... filteredTakeArrayMs];
    //       this.selectedMs = this.selectedMs.filter(value => value != item.id);
    //       // console.log(this.selectedMs);
    //       this.hasChanges = true;
    //       // this.onGetListTablesBySelectedMs();
    //     }
    //
    //     // this.designerService.getListTablesBySelectedMs(item.id).subscribe(
    //     //   data => {
    //     //     data.map(item => {
    //     //       tableMs.push(item);
    //     //     });
    //     //   },
    //     //   err => {
    //     //     console.error(err);
    //     //     removeLoader('vide', 'f', true);
    //     //   },
    //     //   () => {
    //     //   }
    //     // );
    //
    //   });
    // }
  }
  // real remove application from base after verification and save in designer
  onRemoveSystemFromDiag(idDiag, idApp) {
    this.designerService.removeSystemFromDiag(idDiag, idApp).subscribe(
      result => {
        // console.log(result);
      },
      err => {
        console.error(err);
      }
    );
  }
  // remove model structure from list MS
  onRemoveMsDesigner(event) {
    // console.log('remove from list MS');
    // addLoaderMxgraph('vide', 'f', true);
    let selectedMsTemp = this.selectedMs;
    let allTables = [];
    let childrens = this.editor.getGraph().getDefaultParent().children;
    if (childrens != null) {
      childrens.forEach(el => {
        if (el.value != undefined) {
          allTables.push(el.value);
        }
      });
      const filteredArrayMs = this.listTableNames.filter(value => value.idReport == event.value.id);
      const filteredArrayRest = this.listTableNames.filter(value => value.idReport != event.value.id);
      const filteredArray = filteredArrayMs.filter(value => allTables.includes(value.name));
      if (filteredArray.length > 0) {
        selectedMsTemp.push(event.value.id);
        const Toast = Swal.mixin({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          onOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        });
        Toast.fire({
          icon: 'error',
          title: this.lang ? 'You cannot remove Model Structure!!!' : 'Vous ne pouvez pas supprimer la structure du modèle !!!',
        });
        this.selectedMs = [... selectedMsTemp];
      } else {
        this.listTableNames = [...filteredArrayRest];
        // console.log('removed');
        this.onRemoveMsAndTableFromSelectedDiag(event.value.id);
      }
    } else {
      const filteredArrayRest = this.listTableNames.filter(value => value.idReport != event.value.id);
      this.listTableNames = [...filteredArrayRest];
      this.onRemoveMsAndTableFromSelectedDiag(event.value.id);
      // this.selectedMs.push(event.value.id);
    }

    // removeLoader('vide', 'f', true);
    // console.log(event);
    // console.log('--------------');
    // console.log(this.listTableNames);
    // console.log('--------------');
    // console.log(filteredArray);
    // console.log('--------------');
    // console.log(filteredArraynot);
    // addLoaderMxgraph('vide', 'f', true);
    // let selectedMsTemp = this.selectedMs;
    // let allTables = [];
    // let childrens = this.editor.getGraph().getDefaultParent().children;
    // childrens.forEach(el => {
    //   if (el.value != undefined) {
    //     allTables.push(el.value);
    //   }
    // });
    // let exist = false;
    // let tableMs = [];
    // this.designerService.getListTablesBySelectedMs(event.value.id).subscribe(
    //   data => {
    //     data.map(item => {
    //       tableMs.push(item);
    //     });
    //   },
    //   err => {
    //     console.error(err);
    //     removeLoader('vide', 'f', true);
    //   },
    //   () => {
    //     // $('#noChanges').css('display', 'none');
    //     // $('#unsavedChanges').css('display', 'block');
    //     const filteredArray = tableMs.filter(value => allTables.includes(value.name));
    //     if (filteredArray.length > 0) {
    //       selectedMsTemp.push(event.value.id);
    //       const Toast = Swal.mixin({
    //         toast: true,
    //         position: 'top-end',
    //         showConfirmButton: false,
    //         timer: 2000,
    //         timerProgressBar: true,
    //         onOpen: (toast) => {
    //           toast.addEventListener('mouseenter', Swal.stopTimer)
    //           toast.addEventListener('mouseleave', Swal.resumeTimer)
    //         }
    //       });
    //       Toast.fire({
    //         icon: 'error',
    //         title: 'You cannot remove Model Structure!!!'
    //       });
    //       this.selectedMs = [... selectedMsTemp];
    //     } else {
    //       this.hasChanges = true;
    //       // this.onGetListTablesBySelectedMs();
    //     }
    //     removeLoader('vide', 'f', true);
    //   }
    // );
  }
  // remove Ms from list modelstructure in Diag ans Tables tmpListTable link to Diag => after verification
  onRemoveMsAndTableFromSelectedDiag(idReport) {
    this.designerService.removeMsAndTableFromSelectedDiag(this.id, idReport).subscribe(
      result => {
        // console.log(result);
      },
      err => {
        console.error(err);
      }
    );
  }

  onGetListTablesBySelectedDiag() {
    this.loadingTn = true;
    // this.listTableNames = [];
    this.tablesBuffer = [];
    // console.log(event);
    this.designerService.getListTablesBySelectedDiag(this.id).subscribe(
      data => {
        // console.log(data);
        this.listTableNames  = data.map(item => item);
        // this.loadingTn = false;
        this.tablesBuffer = this.listTableNames.slice(0, 50);
        setTimeout(() => {
          this.loadingTn = false;
        }, 200);
      },
      err => {
        console.error(err);
        removeLoader();
      },
      () => {
        removeLoader();
      }
    );
  }
  onAddAndGetListTablesByNewSelectedMs(event) {
    // console.log(this.listTableNames);
    this.loadingTn = true;
    this.tablesBuffer = [];
    // console.log(this.listTableNames.length);
    this.designerService.addAndGetListTablesByNewSelectedMs(this.id, event.id).subscribe(
      data => {
        // this.listTableNames = [... data.map(item => item)];
        this.listTableNames = this.listTableNames.concat(data);
        // data.map(item => {
        // //
        //   this.listTableNames = [...item];
        // });
        // console.log(this.listTableNames.length);
        // this.loadingTn = false;
        this.tablesBuffer = this.listTableNames.slice(0, 50);
        setTimeout(() => {
          // console.log(this.listTableNames);
          this.loadingTn = false;
        }, 200);
      },
      err => {
        console.error(err);
      },
      () => {
        this.onAddPkFieldsByDiag(this.id, event.id);
      }
    );
  }
  onAddPkFieldsByDiag(idDiag: string, idReport: string) {
    this.designerService.saveAddPkFieldByDiag(idDiag, idReport).subscribe(
      res => {
        // console.log(res);
      },
      err => {
        console.error(err);
        this.onAddNotPkFieldsByDiag(idDiag, idReport);
      },
      () => {
        this.onAddNotPkFieldsByDiag(idDiag, idReport);
      }
    );
  }
  onAddNotPkFieldsByDiag(idDiag: string, idReport: string) {
    this.designerService.saveAddNotPkFieldByDiag(this.id, idReport).subscribe(
      res => {
        // console.log(res);
      },
      err => {
        console.error(err);
      }
    );
  }
  // get list table (name, pk et field) bu selected model structures
  // onGetListTablesBySelectedMs() {
  //   this.loadingTn = true;
  //   this.listTableNames = [];
  //   this.tablesBuffer = [];
  //   if (this.selectedMs.length > 0) {
  //     this.designerService.getListTablesBySelectedMs(this.selectedMs).subscribe(
  //       data => {
  //         this.listTableNames = data.map(item => item); // = data
  //         // this.loadingTn = false;
  //         this.tablesBuffer = this.listTableNames.slice(0, 50);
  //         setTimeout(() => {
  //           this.loadingTn = false;
  //         }, 200);
  //       },
  //       err => {
  //         console.error(err);
  //         removeLoader('vide', 'f', true);
  //       },
  //       () => {
  //         removeLoader('vide', 'f', true);
  //       }
  //     );
  //   }
  // }
  // get list table (name, pk et field) bu selected model structures ---->>>> add new selected model structure
  onGetListTablesByAddedMs(event) {
    this.loadingTn = true;
    // this.listTableNames = [];
    this.tablesBuffer = [];
    // console.log(event);
    this.designerService.getListTablesBySelectedMs(event.id).subscribe(
      data => {
        this.listTableNames  = data.map(item => item);
        // this.loadingTn = false;
        this.tablesBuffer = this.listTableNames.slice(0, 50);
        setTimeout(() => {
          this.loadingTn = false;
        }, 200);
      },
      err => {
        console.error(err);
        removeLoader();
      },
      () => {
        removeLoader();
      }
    );
  }

  // onSetNewListMsToSelectedDiag() {
  //   this.designerService.setNewListMsToSelectedDiag(this.selectedMs, this.id).subscribe(
  //     result => {
  //     },
  //     err => {
  //       console.error(err);
  //     }
  //   );
  // }
  // group by liste tableNames
  groupByFnTb = (item) => item.type;
  groupValueFnTb = (_: string, children: any[]) => ({ name: children[0].type, total: children.length });
  // scroll pagination
  onScrollTable({ end }) {
    if (this.loadingTn || this.listTableNames.length <= this.tablesBuffer.length) {
      return;
    }
    if (end + 10 >= this.tablesBuffer.length) {
      this.fetchMoreTable();
    }
  }
  private fetchMoreTable() {
    const len = this.tablesBuffer.length;
    const more = this.listTableNames.slice(len, 50 + len);
    this.loadingTn = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loadingTn = false;
      this.tablesBuffer = this.tablesBuffer.concat(more);
    }, 200);
  }
  customSearchTable(term: string, item: any) {
    term = term.toLocaleLowerCase();
    return item.name.toLocaleLowerCase().indexOf(term) > -1 ; // .name
  }
  onScrollTableToEnd() {
    this.fetchMoreTable();
  }
  onRecordsRemovetag(e) {
    this.searchbtn = false;
  }
  // get list table name for mxGraph
  getListFieldByTableName(listIdMs, tableName) {
    let nameTable = btoa(tableName);
    this.listPkFields = [];
    this.listNoPkFields = [];
    let loadingMessage = this.lang ? 'P L Z &#160; W A I T' : 'S\'il vous plaît, patientez';
    addLoader(loadingMessage);    
    this.designerService.getListFieldByTableName(listIdMs, this.id, nameTable).subscribe(
    data => {
      this.listPkFields = data.pkField;
      this.listNoPkFields = data.listField;
    },
    err => {
      console.error(err);
      removeLoader();
    },
    () => {
      if (this.mxGraphModel.getChildCount(this.mxCellValue) == 1) {
        let firstChild = this.mxCellValue.children[0];
        let idParent = this.mxCellValue.id;
        let yNextPkField = 26;
        let ind = 0;
        if (this.listPkFields.length > 0) {
          for (let j = 0; j < this.listPkFields.length; j++) {
            ind++;
            idParent++;
            firstChild.geometry.y = yNextPkField;
            firstChild.id = idParent;
            firstChild.value = this.listPkFields[j]; // .name
            this.mxGraph.addCell(firstChild, this.mxCellValue, j, null, null);
            yNextPkField += 26;
            firstChild = firstChild.clone();
          }
        }
        if (this.listNoPkFields.length > 0) {
          // this.mxCellValue.style = 'swimlane;fontStyle=0;childLayout=stackLayout;horizontal=1;startSize=26;fillColor=none;horizontalStack=0;resizeParent=1;resizeParentMax=0;resizeLast=0;collapsible=1;marginBottom=0;swimlaneFillColor=#ffffff;strokeColor=' + this.listNoPkFields[0].color + ';';
          for (let k = 0; k < this.listNoPkFields.length; k++) {
            idParent++;
            firstChild.geometry.y = yNextPkField;
            firstChild.id = idParent;
            firstChild.value = this.listNoPkFields[k]; // .name
            this.mxGraph.addCell(firstChild, this.mxCellValue, (ind + k), null, null);
            yNextPkField += 26;
            firstChild = firstChild.clone();
          }
        }
      // } else if (this.mxGraphModel.getChildCount(this.mxCellValue) > 1) {
        // let childs = this.mxGraphModel.getChildren(this.mxCellValue);
        // childs.forEach(child => {
        //   this.mxGraphModel.setVisible(child, false);
        // });
        // setTimeout(() => {
        //   this.mxGraph.removeCells(childs, true);
        //   console.log(this.mxGraphModel.getChildren(this.mxCellValue));
        // }, 1000);
      // }
      } else if (this.mxGraphModel.getChildCount(this.mxCellValue) > 1) {
        alert('add new table');
        // let firstChild = this.mxCellValue.children[0].clone();
        // this.mxCellValue.children = [];
        // let idParent = this.mxCellValue.id;
        // let yNextPkField = 26;
        // let ind = 0;
        // if (this.listPkFields.length > 0) {
        //   for (let j = 0; j < this.listPkFields.length; j++) {
        //     ind++;
        //     idParent++;
        //     firstChild.geometry.y = yNextPkField;
        //     firstChild.id = idParent;
        //     firstChild.value = this.listPkFields[j];
        //     this.mxGraph.addCell(firstChild, this.mxCellValue, j, null, null);
        //     yNextPkField += 26;
        //     firstChild = firstChild.clone();
        //   }
        // }
        // if (this.listNoPkFields.length > 0) {
        //   for (let k = 0; k < this.listNoPkFields.length; k++) {
        //     idParent++;
        //     firstChild.geometry.y = yNextPkField;
        //     firstChild.id = idParent;
        //     firstChild.value = this.listNoPkFields[k];
        //     this.mxGraph.addCell(firstChild, this.mxCellValue, (ind + k), null, null);
        //     yNextPkField += 26;
        //     firstChild = firstChild.clone();
        //   }
        // }
      }
      let tempXml = this.editor.getGraphXml(true);
      // this.editor.destroy();
      setTimeout(() => {
        // this.editor = new Editor();
        this.editor.setGraphXml(tempXml);
        this.mxGraph.clearSelection();
        this.keypress();
        // this.editor.
        removeLoader();
      }, 500);
    }
  );
  }

  // new
  keypress() {
    $('#listTableName').css('display', 'none');
    const graph = this.editor.getGraph();
    let cell = graph.getSelectionCell();
    if (cell) {
      let idParent = cell.parent.parent.id;
      let last1 = idParent.substr(-1);
      if (last1 == 0) {
        this.mxCellValue = cell;
        // console.log(this.mxCellValue);
        if (this.listTableNames.includes(cell.value)) {
          this.selecteTableName = cell.value;
        } else {
          this.selecteTableName = null;
        }
        if (this.mxGraphModel.getChildCount(this.mxCellValue) == 1) {
          $('#listTableName').css('display', 'block');
        }
      } else {
      }
    }

    // get all nodes parents
    // console.log('-- all node parent --');
    // let listAllParentNodes = graph.getModel().getChildVertices(graph.getDefaultParent());
    // let listExistNameInNodes = [];
    // listExistNameInNodes = listAllParentNodes.map(item => item.value);
    // this.listTableNames = this.arr_diff(this.listTableNames, listExistNameInNodes);

  }
  setNameToComp(ev) {

    this.mxCellValue.style = 'swimlane;fontStyle=0;childLayout=stackLayout;horizontal=1;startSize=26;fillColor=none;horizontalStack=0;resizeParent=1;resizeParentMax=0;resizeLast=0;collapsible=1;marginBottom=0;swimlaneFillColor=#ffffff;strokeColor=' + ev.color + ';;strokeWidth=2;';
    const graph = this.editor.getGraph();
    graph.model.setValue(this.mxCellValue, this.selecteTableName);
    let newListIdMs = this.listMs.filter(value => value.application_id == ev.idApp);
    let listselectdIdMsByApp = newListIdMs.filter(val => this.selectedMs.includes(val.id));
    let newSelectedMs = [];
    newSelectedMs  = listselectdIdMsByApp.map(item => item.id);
    // console.log(this.listMs);
    // console.log(newListIdMs);
    // console.log('---------');
    // console.log(newSelectedMs);
    // console.log(this.selectedMs);
    this.getListFieldByTableName(newSelectedMs, this.selecteTableName);
    // $('#noChanges').css('display', 'none');
    // $('#unsavedChanges').css('display', 'block');
  }

  arr_diff(a1, a2) {
    let a = [], diff = [];
    for (let i = 0; i < a1.length; i++) {
      a[a1[i]] = true;
    }
    for (let i = 0; i < a2.length; i++) {
      if (a[a2[i]]) {
        delete a[a2[i]];
      } else {
        a[a2[i]] = true;
      }
    }
    for (let k in a) {
      diff.push(k);
    }
    return diff;
  }
}

