export class SettingsRole{
  name: string;
  description: string;
  versionAdmin: boolean;
  createUser: boolean;
  readUser: boolean;
  updateUser: boolean;
  deleteUser: boolean;

  createRole: boolean;
  readRole: boolean;
  updateRole: boolean;
  deleteRole: boolean;

  createMeta: boolean;
  readMeta: boolean;
  updateMeta: boolean;
  deleteMeta: boolean;


  constructor(name: string, description: string, versionAdmin: boolean, createUser: boolean, readUser: boolean, updateUser: boolean, deleteUser: boolean, createRole: boolean, readRole: boolean, updateRole: boolean, deleteRole: boolean, createMeta: boolean, readMeta: boolean, updateMeta: boolean, deleteMeta: boolean) {
    this.name = name;
    this.description = description;
    this.versionAdmin = versionAdmin;
    this.createUser = createUser;
    this.readUser = readUser;
    this.updateUser = updateUser;
    this.deleteUser = deleteUser;
    this.createRole = createRole;
    this.readRole = readRole;
    this.updateRole = updateRole;
    this.deleteRole = deleteRole;
    this.createMeta = createMeta;
    this.readMeta = readMeta;
    this.updateMeta = updateMeta;
    this.deleteMeta = deleteMeta;
  }
}
