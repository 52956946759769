import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {getCookieValue, Shared} from '../../shared';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VersionService {
  username: string;
  userId: string;
  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  constructor(private http: HttpClient, private router: Router) { }
  // get list version
  getListVersion(): Observable<any> {
    this.userId = localStorage.getItem('userId');
    this.username = getCookieValue('username');
    return this.http.get(Shared.API_URL + '/api/app-versioning/getListVersion/' + this.userId + '/' + this.username, this.getOptions1());
  }
  // get record version pre-update
  getRecordVersionPreupdate(id: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/app-versioning/getRecordVersionPreupdate/' + id, this.getOptions1());
  }
  // add new version
  addVersion(dataVersion: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataVersion);
    return this.http.post<any>(Shared.API_URL + '/api/app-versioning/addVersion' , data, this.getOptions());
  }
  // update selectde version
  // updateVersion(id: string, application: string, sousmodule: any): Observable<any> {
  //   this.username = getCookieValue('username');
  //   return this.http.get(Shared.API_URL + '/api/app-versioning/updateVersion/' + id + '/' + application + '/' + sousmodule + '/' + this.username, this.getOptions1());
  // }
  updateVersion(dataUpdateVersion: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateVersion);
    return this.http.put<any>(Shared.API_URL + '/api/app-versioning/updateVersion' , data, this.getOptions());
  }

  // addVersion(application: string, sousmodule: any): Observable<any> {
  //   this.username = getCookieValue('username');
  //   return this.http.get(Shared.API_URL + '/api/app-versioning/addVersion/' + application + '/' + sousmodule + '/' + this.username, this.getOptions1());
  // }
  // remove version
  removeVersion(id: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/app-versioning/removeVersion/' + id, this.getOptions1());
  }
}
