import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable} from "rxjs";
import {getCookieValue, Shared} from "../../shared";
import {CampainJson} from '../../data-life-cycle/campain/CampainJson';

@Injectable({
  providedIn: 'root'
})
export class DataLifeCycleService {

  constructor(private http: HttpClient, private  router: Router, public jwtHelper: JwtHelperService) { }

  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  };
  arch(app,campain, base) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/arch/' + app + '/' + campain + '/' + base, this.getOptions());
  }
  getArchiveApplications() : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getArchiveApplications/' + localStorage.getItem('userId'), this.getOptions());
  }
  getArchivesDetails() : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getArchivesDetails', this.getOptions());
  }
  getCampains(app,base) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getCampains/' + app + '/' + base + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  getCampainsArchiveIds(base: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getCampainsArchiveIds/' + base, this.getOptions());
  }
  addCamPain(campain: CampainJson): Observable<any> {
    const data = "data=" + JSON.stringify(campain);
    return this.http.post<any>(Shared.API_URL + '/api/object-model/addCamPain' , data, this.getOptions());
  }
  getArchiveIdsSelect(campain, base): Observable<any>{
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getArchiveIdsSelect/' + campain + '/' + base , this.getOptions());
  }
  deleteCampain(campain, base): Observable<any>{
    return this.http.delete<any>(Shared.API_URL + '/api/object-model/deleteCampain/' + campain + '/' + base, this.getOptions());
  }
  updateCamp(campain: any): Observable<any> {
    const data = "data=" + JSON.stringify(campain);
    return this.http.post<any>(Shared.API_URL + '/api/object-model/updateCamp' , data, this.getOptions());
  }
  getIndicatorsByCampain(app, campain) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getIndicatorsByCampain/' + app + '/' + campain, this.getOptions());
  }
  getCampainPack(app, campain): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getCampainPack/' + app + '/' + campain , this.getOptions());
  }
  getTaskOrtableByCampain(campain,type): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getTaskOrtableByCampain/' + campain + '/' + type , this.getOptions());
  }
  // check campain
  checkCampain(name: string, base: string) {
    return this.http.get(Shared.API_URL + '/api/data-life-cycle-role/checkCampain/' + name + '/' + base,  this.getOptions());
  }
  // getCreateCp
  getCreateCp(app){
    return this.http.get(Shared.API_URL + '/api/object-model/getCreateCp/' + app + '/' + localStorage.getItem('userId'),  this.getOptions());
  }
  // size chart campain show details
  getCampainSize(id,app, campainName): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getCampainPack/'  + id + '/' + app +'/'  +  campainName, this.getOptions());
  }
  getCampainTaskPack(id,app, campainName): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getCampainTaskPack/'  + id + '/' + app +'/'  +  campainName, this.getOptions());
  }
  getGlobalSizeTaskChart(id,app, campainName): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getGlobalSizeTaskChart/'  + id + '/' + app +'/'  +  campainName, this.getOptions());
  }
  getSunburstByObjectAndTask(objectId,id, objectName): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getSunburstByObjectAndTask/'  + objectId + '/' + id +'/'  +  objectName, this.getOptions());
  }
  getTableObjectDoneAndFail(id,objectId, cat): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getTableObjectDoneAndFail/'  + id + '/' +  objectId+'/'  +  cat, this.getOptions());
  }
  getCampainsAll(id, base): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getCampainsAll/'  + id + '/' + base, this.getOptions());
  }
  getTreeCampainChart(id,name, status, app): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getTreeCampainChart/'  + id + '/' + name + '/' +status + '/' + app, this.getOptions());
  }
  getProjectOwner(app: string) {
    return this.http.get(Shared.API_URL + '/api/projects/getProjectOwner/' + app ,  this.getOptions());
  }
  getHolders(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/holder/getHolders/' + localStorage.getItem('userId') , this.getOptions());
  }
  getArchiveBases(app: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getArchiveBases/' + localStorage.getItem('userId') + '/' + app , this.getOptions());
  }
  getArchivesIdByBaseAndApp(base: string, app: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getArchivesIdByBaseAndApp/' + base + '/' + app , this.getOptions());
  }
  deleteTasks(tasks, base, app): Observable<any>{
    const data = "data=" + JSON.stringify(tasks);
    return this.http.post<any>(Shared.API_URL + '/api/data-life-cycle-role/deleteTasks/' + base + '/' + app , data, this.getOptions());
  }
  getTimelineCampain( app: string, base: string, campain: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-life-cycle-role/getTimelineCampain/' + app + '/' + base + '/' + campain, this.getOptions());
  }

  getArchiveGroups( campain: number): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/archive-group/getArchiveGroups/' + campain, this.getOptions());
  }
  addGroupArchive(groupArchive: any){
    const data = "data=" + JSON.stringify(groupArchive);
    return this.http.post<any>(Shared.API_URL + '/api/archive-group/addGroupArchive', data, this.getOptions());
  }
  updateGroupArchive(groupArchive: any){
    const data = "data=" + JSON.stringify(groupArchive);
    return this.http.put<any>(Shared.API_URL + '/api/archive-group/updateGroupArchive', data, this.getOptions());
  }
  removeArchiveGroup( campain: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/archive-group/removeArchiveGroup/' + campain, this.getOptions());
  }
}
