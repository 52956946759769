import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {getCookieValue, Shared} from '../../shared';
import {Observable} from 'rxjs';
import {projectJson} from '../../data-governance/projects/projectJson';
import {local} from 'd3-selection';

@Injectable({
  providedIn: 'root'
})
export class DesignerService {
  username: string;
  userId: string;
  data: string;
  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }

  constructor(private http: HttpClient, private router: Router) { }
  // get list Application for add new diagram (all application and new applications)
  getListApplicationAndMs(idDiag: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/getListApplicationAndMs/' + idDiag, this.getOptions1());
  }
  // get list Application for add new diagram (all application and new applications)
  getListApplications(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/getListApplications', this.getOptions1());
  }
  // get list Application for add new diagram (all application and new applications)
  getListMsBySelectedApps(listApps: any): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/getListMsBySelectedApps/' + listApps, this.getOptions1());
  }
  // // set new diag in designer
  // setProjDesigner(dataDiag: any): Observable<any> {
  //   const data = 'data=' + JSON.stringify(dataDiag);
  //   return this.http.post<any>(Shared.API_URL + '/api/designer/setProjDesigner' , data, this.getOptions());
  // }
  // set new empty diag in designer
  setNewDesignerEmpty(dataDiag: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataDiag);
    return this.http.post<any>(Shared.API_URL + '/api/designer/setNewDesignerEmpty' , data, this.getOptions());
  }
  // set Pk Fields after creation Diagrams
  addTempPkFieldsForDiagFirstCreate(idDiag: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/addTempPkFieldsForDiagFirstCreate/' + idDiag, this.getOptions());
  }
  // set Not Pk Fields after creation Diagrams
  addTempNotPkFieldsForDiagFirstCreate(idDiag: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/addTempNotPkFieldsForDiagFirstCreate/' + idDiag, this.getOptions());
  }
  // get Role CRUD designer
  getListRoleDesigner(): Observable<any> {
    this.userId = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/designer/getListRoleDesigner/' + this.userId, this.getOptions1());
  }
  // set update diag in designer
  setUpdateProjDesigner(dataDiag: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataDiag);
    return this.http.put<any>(Shared.API_URL + '/api/designer/setUpdateProjDesigner' , data, this.getOptions());
  }
  // get Role CRUD designer
  // getListRoleDesigner(): Observable<any> {
  //   this.userId = localStorage.getItem('userId');
  //   return this.http.get(Shared.API_URL + '/api/designer/getListRoleDesigner/' + this.userId, this.getOptions1());
  // }
  // get list diagrams
  getListDiagramsDesigner(): Observable<any> {
    this.username = getCookieValue('username');
    return this.http.get(Shared.API_URL + '/api/designer/getListDiagramsDesigner/' + this.username, this.getOptions1());
  }
  // get list users havent role
  getUserHaventRoleDesign(selectedDiagId: string, selectedMenu: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/getUserHaventRoleDesign/' + selectedDiagId + '/' + selectedMenu + '/' + localStorage.getItem('userId'), this.getOptions1());
  }
  // update name and description Diagram
  onUpdateInfDiag(dataDiag: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataDiag);
    return this.http.put<any>(Shared.API_URL + '/api/designer/onUpdateInfDiag', data, this.getOptions());
  }
  // delete selected Diagram
  onSetRemoveDiagram(id: string, action: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/designer/onSetRemoveDiagram/' + id + '/' + action, this.getOptions());
  }
  // Get content Diagram to EDIT
  getContentDiag(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/designer/getContentDiag/' + id, this.getOptions());
  }
  // set selected users for share Diagram designer
  setShareTypeUsersToDiagram(diagId: string, sharedType: string, sharedUsers: string): Observable<any> {
    this.userId = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/designer/setShareTypeUsersToDiagram/' + diagId + '/' + sharedType + '/' + sharedUsers + '/' + this.userId, this.getOptions());
  }
  // get list Model Structure by Application
  getListMsByAppls(idDiag: string, listApps: any): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/getListMsByAppls/' + idDiag + '/' + listApps, this.getOptions1());
  }
  // get list tableName from base
  getListTablesBySelectedDiag(idDiag: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/getListTablesBySelectedDiag/' + idDiag, this.getOptions1());
  }
  // add table by selected model structure and get list all tableName from base
  addAndGetListTablesByNewSelectedMs(idDiag: string, idMs: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/addAndGetListTablesByNewSelectedMs/' + idDiag + '/' + idMs, this.getOptions1());
  }
  // add PK Fields by selected model structure
  saveAddPkFieldByDiag(idDiag: string, idMs: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/saveAddPkFieldByDiag/' + idDiag + '/' + idMs, this.getOptions1());
  }
  // add Not PK Fields by selected model structure
  saveAddNotPkFieldByDiag(idDiag: string, idMs: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/saveAddNotPkFieldByDiag/' + idDiag + '/' + idMs, this.getOptions1());
  }
  // remove tables and model structure after verification by selected Diagram
  removeMsAndTableFromSelectedDiag(idDiag: string, idReport: string) : Observable <any> {
    return this.http.get<any>(Shared.API_URL + '/api/designer/removeMsAndTableFromSelectedDiag/' + idDiag + '/' + idReport, this.getOptions1());
  }
  // real remove application from base after verification and save in designer
  removeSystemFromDiag(idDiag: string, idApp: string): Observable <any> {
    return this.http.get<any>(Shared.API_URL + '/api/designer/removeSystemFromDiag/' + idDiag + '/' + idApp, this.getOptions1());
  }
  // get list tableName from base
  getListTablesBySelectedMs(listIdMs: any): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/getListTablesBySelectedMs/' + listIdMs, this.getOptions1());
  }
  // get list tableName from base
  getListFieldByTableName(listIdMs: any, idDiag: string, tableName: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/designer/getListFieldByTableName/' + listIdMs + '/' + idDiag + '/' + tableName, this.getOptions1());
  }
  // after change (add new MS) Or remove MS => set new list MS in Base
  // setNewListMsToSelectedDiag(listIdMs: any, idDiag: string): Observable<any> {
  //   return this.http.put(Shared.API_URL + '/api/designer/setNewListMsToSelectedDiag' + listIdMs + '/' + idDiag, this.getOptions1());
  // }
  /********************************Shared Diagram**************************************/
  // get list diagrams
  getListSharedDiagramsDesigner(): Observable<any> {
    this.username = getCookieValue('username');
    this.userId = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/designer/getListSharedDiagramsDesigner/' + this.userId + '/' + this.username, this.getOptions1());
  }
}
