import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {UploadDictionaryComponent} from './upload-dictionary/upload-dictionary.component';
import {UploadModelComponent} from './upload-model/upload-model.component';
import {UploadArchiveComponent} from './upload-archive/upload-archive.component';
const routes: Routes = [
    {
        path: '',
        redirectTo: 'upload-dictionary',
        pathMatch: 'full'

    },
    {
        path: 'upload-dictionary',
        component: UploadDictionaryComponent
    },
    {
      path: 'upload-model',
      component: UploadModelComponent
    },
    {
      path: 'upload-archive',
      component: UploadArchiveComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UploadsRoutingModule { }
