import {AfterViewInit, Component, OnInit} from '@angular/core';
import {StackService} from "../../services/data-governance/stack/stack.service";
import {getCookieValue, Shared} from "../../shared";
import Swal from "sweetalert2";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {stackJson} from "./stackJson";
import {RouterhistoryService} from '../../services/appService/routerhistory.service';
import { TranslateService } from '@ngx-translate/core';


declare const $: any;
declare const swal: any;

@Component({
  selector: 'app-stack',
  templateUrl: './stack.component.html',
  styleUrls: ['./stack.component.css']
})
export class StackComponent implements OnInit, AfterViewInit {
  holdersEdit: any;
  frmStack: FormGroup;
  stackId: string;
  editMode = false;
  nameError = false;
  nameSuccess = false;
  types = [{'id': 1, 'name': 'Supplier'},{'id': 2, 'name': 'Participant'},{'id': 3, 'name': 'Consumer'}];
  role = [];
  lang: string;
  constructor(private stackService: StackService, private fb: FormBuilder, private routerHistoryService: RouterhistoryService, private translate: TranslateService) {
    this.frmStack = this.initForm();
  }

  ngOnInit(): void {
    this.getStacks();
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
  const that = this;
  setInterval(() => {
    this.lang = localStorage.getItem('lang') || 'en';
    this.translate.use(this.lang);
  }, 10);
  }
  ngAfterViewInit() {
    setTimeout( () => {
      this.showTipsGovRole();
    }, 1000);
  }
  showTipsGovRole() {
    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsGovRole');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsGovRole') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsGovRole', 'true');
        setTimeout( () => {
          $('#tipsCompGovRole').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsGovRole') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsGovRole', 'true');
          $('#tipsCompGovRole').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips data governace data role
  onUpdateTipsGovRole() {
    let tipsGovRole;
    if ($('#checked_show_govrole').is(':checked')) {
      tipsGovRole = false;
    } else {
      tipsGovRole = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsGovRole,
      dataMenu: 'Data Governance',
      dataSousmenu: 'data-roles'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  addStack() {
    this.editMode= false;
    this.frmStack.reset();
    $('#stackModal').modal();
  }
  getStacks(){
      var that = this;
    //  var temp;
     // let holders = this.holdersEdit;
      var columnDefs = [
        {
          data: "id",
          title: this.lang === 'en' ? "Id Stack" : "Identifiant Pile",
          visible: false,
          searchable: false
         },
         {
          data: "role",
          title: this.lang === 'en' ? "Data Role" : "Rôle de Données"
         },
         {
          data: "description",
          title: this.lang === 'en' ? "Description" : "Description"
         },
        {
          "className": 'editortab',
          "orderable": false,
          data: "id",
          "render": function (data, type, row, meta) {
            let htm = '';
            if (!that.role['createStack']) {
              if ($('#addStack').length) {
                $('#addStack').remove();
              }
            } else {
              $('#addStack').css('display', 'block');
            }
            if (that.role['deleteStack']) {
              htm += '<button class="btn btn-danger btn-circle waves-effect waves-circle waves-float ng-scope stack_editor_remove" data_item="' + row.id + '" data-type="html-message" ><i class="fas fa-trash-alt"></i></button>';
            }
            if (that.role['updateStack']) {
              htm += '<button class="btn bg-light-green btn-circle waves-effect waves-circle waves-float ng-scope stack_editor_save" data_item="' + data + '" data_role="' + row.role +  '" data_desc="' + row.description + '"  data-type="html-message" ><i class="fas fa-pencil-alt"></i></button>';
            }
           htm += '<button class="btn bg-light-blue btn-circle waves-effect waves-circle waves-float ng-scope stack_editor_cancel"  data_item="' + row.id + '" data_role="' + row.role + '" data-type="html-message" ><i class="fas fa-list-alt"></i></button>' +
           '<button class="btn bg-light-blue btn-circle waves-effect waves-circle waves-float ng-scope stack_editor_dimension"  data_item="' + row.id + '" data_role="' + row.role + '" data-type="html-message" ><i class="fas fa-bezier-curve"></i></button>';
            // if (row.id == localStorage.getItem('userId')) {
            //   return '';
            // }
            return htm;
          },
          title: 'Action'
        }
      ];
      $('#tableStack').empty();
      var dataTable = $('#tableStack').DataTable({
        "sPaginationType": "full_numbers",
        ajax: {
          url: Shared.API_URL + "/api/stack/getStacks/" + localStorage.getItem('userId'),
          headers: {
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
          },
          dataSrc: function (d) {
           that.role = d.stackRole;
            return d.data;
          }
        },
        columns: columnDefs,
       // dom: 'Bfrtip',        // Needs button container
        select: 'single',
        pageLength: 5,
        lengthMenu: [[5, 10, 20, -1], ['5 rows', '10 rows', '20 rows', 'Show all']],
        responsive: true,
        "initComplete": function (settings, json) {
          $('.editortab').css('display', 'none');
        }
      });
      $('#tableStack').on('click', 'tbody > tr', function () {
        if (!$(this).hasClass('selected')) {
          $('#tableStack > tbody > tr .editortab').css('display', 'none');
          $('#tableStack > tbody > tr.selected').removeClass('selected');

          $(this).addClass('selected');

          $('#tableStack > tbody > tr.selected .editortab').css('display', 'block');
        }
      });
      $('#tableStack').on('click', '.stack_editor_save', function () {
        that.editMode = true;
        // if ($('.parent.selected').length) {
        //   var data = dataTable.row($('.parent.selected')).data();
        // } else {
        //   var data = dataTable.row($(this).parents('tr')).data();
        // }

          const role = $(this).attr('data_role');
          const description = $(this).attr('data_desc');
          const id = $(this).attr('data_item');

        // console.log(data);
        $('#stackTitle').html('Editing Data Role');
        that.frmStack.get('role').patchValue(role);
        that.frmStack.get('description').patchValue(description);
        that.stackId = id;
        $('#stackModal').modal();
      });
    $('#tableStack').on('click', '.stack_editor_remove', function () {
      if ($('.parent.selected').length) {
        var data = dataTable.row($('.parent.selected')).data();
      } else {
        var data = dataTable.row($(this).parents('tr')).data();
      }
      if(data!=undefined){
        that.stackId = data.id;
        console.log(data);
      }else{
        that.stackId = $(this).attr('data_item');
      }
      that.removeStack();
    });

      $('#tableStack').on('click', 'tbody > tr .stack_editor_cancel', function () {
        // if ($('.parent.selected').length) {
        //   var data = dataTable.row($('.parent.selected')).data();
        // } else {
        //   var data = dataTable.row($(this).parents('tr')).data();
        // }

          that.stackId = $(this).attr('data_item');
        const role = $(this).attr('data_role');
        that.stackService.getHoldersByStack(that.stackId).subscribe(
          d => {
            let htm = "<table class='table table-condensed'><thead><tr><th>" + (this.lang === 'en' ? "N°" : "N°") + "</th><th>" + (this.lang === 'en' ? "FirstName" : "Prénom") + "</th><th>" + (this.lang === 'en' ? "LastName" : "Nom de famille") + "</th><th>" + (this.lang === 'en' ? "Active" : "Actif") + "</th><th>" + (this.lang === 'en' ? "CreatedAt" : "Créé le") + "</th><th>" + (this.lang === 'en' ? "UpdatedAt" : "Mis à jour le") + "</th></tr></thead><tbody>";
            d.data.map((r, i) => {
              if(r.createdAt==null){
                r.createdAt = '';
              }else{
                r.createdAt = r.createdAt.toString().substring(0, 10);
              }
              if(r.updatedAt==null){
                r.updatedAt = '';
              }else{
                r.updatedAt = r.updatedAt.toString().substring(0, 10);
              }
              r.active? r.active='<i class="fas fa-check" style=""color:green"></i>':r.active='<i class="fas fa-times" style="color:gray"></i>';
              htm += "<td style='text-align: center;'>" + (i + 1) + "</td><td style='text-align: center;'>" + r.firstName + "</td><td style='text-align: center;'>"+ r.lastName +"</td><td style='text-align: center;'>"+r.active+"</td><td style='text-align: center;'>"+r.createdAt+"</td><td style='text-align: center;'>"+ r.updatedAt+"</td></tr>";
            });
            htm += "</tbody></table";
            swal({
              title: this.lang === 'en' ? "Stewardship & Ownership having Data Role <b style='color:#0ba360'>" + role + "</b>!" : "Gestion & Propriété ayant le rôle de données <b style='color:#0ba360'>" + role + "</b>!",
              text: "<div class='body table-responsive'>" + htm + "</div>",
              html: true
             });
             
            $('.sweet-alert').css('width','fit-content');
          },
          err => {
            console.log(err);
          }
        );

      });
   // get dimension by stack
    $('#tableStack').on('click', 'tbody > tr .stack_editor_dimension', function () {
      let role = $(this).attr('data_role');
      role = role.replace(/\//g, '__________');
      role = role.replace(/&/g, ':::::::');
      that.stackService.getDimensionByStack(role).subscribe(
        d => {
          console.log(d);
          $('#dimensionModalTitle').html(this.lang === 'en' ? 'Dimensions affected to ' + $(this).attr('data_role') : 'Dimensions affectées à ' + $(this).attr('data_role'));
          that.getDimensionsTable(d.data, role);
          $('#dimensionModal').modal();
        },
        err => {
          console.log(err);
        }
      );

    });

  }
  getDimensionsTable(d, role){
    const columnDefs = [
      {
        data: 'id',
        title: this.lang === 'en' ? "Id Dimension" : "Identifiant Dimension",
        visible: false,
        searchable: false
       },
       {
        data: "name",
        title: this.lang === 'en' ? "Dimension" : "Dimension"
       },
       {
        data: "description",
        title: this.lang === 'en' ? "Description" : "Description"
       }
       
    ];
    var dataTable = $('#dimensionStack').DataTable({
      "sPaginationType": "full_numbers",
      data: d,
      destroy: true,
      columns: columnDefs,
      dom: 'Bfrtip',        // Needs button container
      select: 'single',
      responsive: true,
      rowCallback(row, data) {
        $('td:eq(0)', row).html('<a class="dataDimension" data-name="' + data.name + '" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
      },
      "initComplete": function (settings, json) {
        $('#dimensionStack tbody').on('click', 'a.dataDimension', function () {
          const dim = $(this).attr('data-name');
      //    console.log(dim);
          const tr = $(this).closest('tr');
          const row = $('#dimensionStack').DataTable().row(tr);

          const rowData = row.data();
         // console.log(rowData);
          $('.dmshown').next('tr').hide();
          const indexRow = row.index();
          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('dmshown');
          } else {
            tr.addClass('dmshown');
            if ($('#table_dimension_children' ).length) {
              $('#table_dimension_children').remove();
            }
             row.child('<table class="table_dimension_fils" id ="table_dimension_children" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();
             const categories = d.find(e => e.name == dim);
            // console.log(categories);
             const categoryJson = [];
             if(categories!= null){
               categories.category.map(c => {
                 if(c.elementName.indexOf(role)!=-1){
                   categoryJson.push(c);
                 }
               });
             }
            console.log(d);
              $('#table_dimension_children').DataTable({
                data: categoryJson,
                responsive: true,
                destroy: true,
                pageLength: 5,
                lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                columns: [
                  {
                    data: 'categoryName',
                    title: this.lang === 'en' ? 'Category' : 'Catégorie'
                   }
                   
                ]
              });
            }
        });
      }
    });
  }
  removeStack() {
    const that = this;
    Swal.fire({
      title: this.lang === 'en' ? 'DELETE this data role?' : 'SUPPRIMER ce rôle de données?',
      text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, L\'approuver!',
      cancelButtonText: this.lang === 'en' ? 'No, Cancel' : 'Non, Annuler'
     }).then((result) => {
      if (result.value) {
        that.stackService.removeStack(that.stackId).subscribe(
          d=>{
            swal({
              title: this.lang === 'en' ? 'Success!' : 'Succès!',
              text: this.lang === 'en' ? 'Data role delete.' : 'Suppression du rôle de données.',
              timer: 2000,
              showConfirmButton: false
             });
            $('#tableStack').DataTable().ajax.reload();
          },
          err => {
            // console.error(err);
            swal.close();
            const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 6000,
              timerProgressBar: true,
              onOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            });

            Toast.fire({
              icon: 'error',
              title: err.error
            });
          }
        )
      }
    });
    }

  onSubmitAdd() {
    const formValue = this.frmStack.value;
    let role = formValue['role'];
   role = role.replace(/\//g, '__________');
    const description = formValue['description'];
   // const type = formValue['type'];
    if (this.editMode) {
      const postData = new  stackJson(this.stackId,role,description);
      this.stackService.updateStack(postData).subscribe(
        data => {
          $('#stackModal').modal('hide');
          swal({
            title: this.lang === 'en' ? "Edit Success!" : "Édition réussie!",
            text: this.lang === 'en' ? "Stack edited." : "Pile éditée.",
            timer: 2000,
            showConfirmButton: false
           });
          $('#tableStack').DataTable().ajax.reload();
        },
        err => {
          console.error(err);
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });

          Toast.fire({
            icon: 'error',
            title: this.lang === 'en' ? 'Wrong Data Role' : 'Mauvais rôle de données'
           });
        }
      );
    }else{
      const postData = new  stackJson('',role,description);
      this.stackService.checkStack(role).subscribe(
        validation => {
          if (validation.response.indexOf('taken') != -1) {
            this.nameError = true; this.nameSuccess = false;
            // $('#stackModal').modal('hide');
            // swal({
            //   title: "Error Role!",
            //   text: validation['response'],
            //   timer: 2000,
            //   showConfirmButton: false
            // });
           // $('#tableStack').DataTable().ajax.reload();
          } else {
            this.nameError = false; this.nameSuccess = true;
            this.stackService.saveStack(postData).subscribe(
              data => {
                $('#stackModal').modal('hide');
                swal({
                  title: this.lang === 'en' ? "Add Success!" : "Ajout réussi!",
                  text: this.lang === 'en' ? "New Data role Added." : "Nouveau rôle de données ajouté.",
                  timer: 2000,
                  showConfirmButton: false
                 });
                $('#tableStack').DataTable().ajax.reload();
              },
              err => {
                console.error(err);
                const Toast = Swal.mixin({
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                  onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                  }
                });
                Toast.fire({
                  icon: 'error',
                  title: this.lang === 'en' ? 'Wrong Data role' : 'Mauvais rôle de données'
                 });
              }
            );
          }
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  private initForm() {
    return  this.fb.group(
      {
        role: [
          null,
          Validators.compose([Validators.required])
        ],
        description: [
          null,
          Validators.compose([Validators.required])
        ],
        // type: [
        //   null,
        //   Validators.compose([Validators.required])
        // ],
      }
    );
  }
  checkName() {
    let name = this.frmStack.value.role;
    if (name == '') {this.nameError = true; this.nameSuccess = false; return; }
    name = name.replace(/\//g, '__________');
    this.stackService.checkStack(name).subscribe(
      d => {
        // @ts-ignore
        if (d.response.indexOf('taken') != -1) {this.nameError = true; this.nameSuccess = false; } else {this.nameError = false; this.nameSuccess = true; }},
      e => {console.error(e); }
    );
  }
  renitializeTable(){
    $('#dimensionStack').DataTable().destroy();
    $('#dimensionStack').empty();
  }
}
