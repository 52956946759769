import {AfterViewInit, Component, OnDestroy, OnInit, Input, EventEmitter, Output} from '@angular/core';
import {ReportService} from '../../../services/reports/report.service';
import {DynamicScriptLoaderService} from "../../../dynamic-script-loader-service.service";
import {Router} from "@angular/router";
import {AuthenticationService} from "../../../services/authentication.service";
import {DdtmService} from "../../../services/ddtm/ddtm.service";
import {local} from "d3-selection";
import {Shared} from '../../../shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {CryptoService} from '../../../crypto.service';
import {RouterhistoryService} from '../../../services/appService/routerhistory.service';
import {MetadatatemplateComponent} from '../../../shared/metadatatemplate/metadatatemplate.component';
import {DdtmComponent} from '../ddtm.component';
declare const ApexCharts: any;
declare const $: any;
@Component({
  selector: 'app-reports-details-user-ddtm',
  templateUrl: './reports-details-user-ddtm.component.html',
  styleUrls: ['./reportDetailUserDdtm.css']
})
export class ReportsDetailsUserDdtmComponent implements OnInit , OnDestroy, AfterViewInit {
  @Input() dictionaryBridge: any;
  @Output() initDictionary = new EventEmitter<string>();
  tEmitter;
    assets = Shared.ASSETS;
    lang: string;
    application: string;
    reportsData = [];
    app: string;
    env: string;
    type: string;
    Application: string;
    countDev: number;
    countPre: number;
    countPro: number;
    countQal: number;
    countRef: number;
    typess: any;
    countAllTables = 0;
    dateReport: string;
    updateStruct = false;

    dateTableReports = [];
    // liste des rapport selectinnés
    allReportSelected = [];
    checkedReport = [];

    countEmptyTableReports = [];
    countNotEmptyTableReports = [];

    listApplicationUser = [];
    applicationSelect: string;
    reportName:any;
    dimensions = [];
    listTypeDimensions:any;
    selectedDimensions = ['null'];
    defaultChecked = true;
    dimensionForm: FormGroup;
    subscription: Subscription = new Subscription();
    countSelectedRep = 0;
    constructor(private router: Router, private ddtmService: DdtmService, private reportService: ReportService,
                private dynamicScriptLoader: DynamicScriptLoaderService, private routerHistoryService: RouterhistoryService,
                private authenticationService: AuthenticationService, private fb: FormBuilder, private translate: TranslateService) { }

  ngOnInit() {
    'use strict';
    this.onGetApplicationList();
    $('#tabModelStructureDDTM').css('display', 'none');
    this.onGetAllDimensions();
    this.dimensionForm = this.fb.group({
      selectedCategories: [],
      selectedDimensions: []
    });
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
    setTimeout( () => {
      this.showTipsDdtm();
    }, 1000);
  }
  showTipsDdtm() {
    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsDdtm');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsDdtm') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsDdtm', 'true');
        $('#tipsCompDdtm').modal('show');
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsDdtm') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsDdtm', 'true');
          $('#tipsCompDdtm').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips dashboard
  onUpdateTipsDdtm() {
    let tipsDdtm;
    if ($('#checked_show_ddtm').is(':checked')) {
      tipsDdtm = false;
    } else {
      tipsDdtm = true;
    }
    let dataPut = {
      username: localStorage.getItem('username'),
      mainTips: tipsDdtm,
      dataMenu: this.lang ? 'Data Model' : 'Modèle de données',
      dataSousmenu: this.lang ? 'Data Dictionary' : 'Dictionnaire de données'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  onClick(event) {
    $(event.target).attr('class') == 'fa fa-plus' ? $(event.target).attr('class','fa fa-minus') : $(event.target).attr('class','fa fa-plus');
    $('#application_body_ddtm').slideToggle(500);
  }
 
    onGetApplicationList() {
      let subscription1$ = this.reportService.getApplicationList().subscribe(
        data => {
          this.listApplicationUser = data.application;
          if ( data.user == 'all'){
            this.updateStruct = true;
            if (this.dictionaryBridge.application != '') {
              this.applicationSelect = this.dictionaryBridge.application;
            } else {
              this.applicationSelect = this.listApplicationUser[0];
            }
          } else {
            if (this.dictionaryBridge.application != '') {
              this.applicationSelect = this.dictionaryBridge.application;
            } else {
              this.applicationSelect = this.listApplicationUser[0].application;
            }
          }
        },
        err => {
          console.error(err);
        },
        () => {
          localStorage.setItem('reportDetailApplication', this.applicationSelect);
          this.setSelectedAppListReps(this.applicationSelect,['null']);
          if (this.dictionaryBridge.application != '' && this.dictionaryBridge.report != '') {
            this.checkSelectedVersionReport(this.dictionaryBridge.report);
          } else {
            this.initDictionary.emit('no');
          }
        }
      );
      this.subscription.add(subscription1$);
    }
    // check selected version of report
  checkSelectedVersionReport(reportName) {
      this.reportService.checkSelectedVersionReport(reportName).subscribe(
        result => {
          if (result != 'null') {
            this.initDictionary.emit(result);
          }

        },
        err => {
          console.error(err);
        },
        () => {
    }
      );
  }
     // list of all dimensions
    onGetAllDimensions(){
      let subscription2$ = this.ddtmService.getReportDimension().subscribe(
        data => {
        this.listTypeDimensions = data;
        },
        err => {
            console.error(err);
        }
      );
      this.subscription.add(subscription2$);
    }
    onDimensionChange(dim){
      this.dimensions = dim.elementName;
      this.dimensionForm.get('selectedDimensions').patchValue(dim.elementName);
    }
    onElementsChange(event){
      this.selectedDimensions=[];
      this.selectedDimensions.push(event.elementName);
      this.setSelectedAppListReps(this.applicationSelect ,this.selectedDimensions);
    }
    onReset() {
      this.dimensionForm.get('selectedCategories').patchValue([]);
      this.dimensionForm.get('selectedDimensions').patchValue([]);
      this.selectedDimensions=['null']
      this.setSelectedAppListReps(this.applicationSelect ,this.selectedDimensions);
    }
    onNext(){
      if (this.countSelectedRep > 0) {
        this.initDictionary.emit('next');
        $('#tabModelStructureDDTM').css('display', 'block');
      }
    }
    // get count selected report by selected application
    onGetCountSelectedReportByUser(app) {
      let subscription3$ = this.reportService.getCountSelectedReportByUser(app).subscribe(
        data => {
          let tabBases = [];
          data.tabBases.forEach(vam => {tabBases.push(vam); });
          this.countSelectedRep = data.countSelectedRep;
          if (data.countSelectedRep > 0) {
            this.onGetDataSetChartsSelectedReportsModeUser(app);
            if (this.defaultChecked) {
              tabBases.forEach(base => {
                $('.' + base).attr('src', 'assets/images/icon-db/checked.png');
              });
              this.defaultChecked = false;
            }
          } else {
            this.onGetDataSetChartsSelectedReportsModeUser(app);
            $('#tabModelStructureDDTM').css('display', 'none');
          }
        },
        err => {
          console.error(err);
        },
        () => {
        }
      );
      this.subscription.add(subscription3$);
    }
    // list of reports by selected Application
    setSelectedAppListReps(app,selectedDimensions) {
      // if(selectedDimensions[0] === "null")
      if(selectedDimensions[0] == ["null"]){
        console.log(this.selectedDimensions[0] );
        this.dimensionForm.get('selectedCategories').patchValue([]);
        this.dimensionForm.get('selectedDimensions').patchValue([]);
        this.selectedDimensions=['null'];
      }
      this.listApplicationUser.forEach(el => {
        if ( app == el.application ) {
          this.updateStruct = el.updateStruct;
        }
      });
      localStorage.setItem('reportDetailApplication', app);
      this.defaultChecked = true;
      this.reportsData = [];
      let subscription4$ =this.reportService.getReportByOneApplication(this.applicationSelect,selectedDimensions).subscribe(
          data => {
              this.Application = data[0].application;
              this.countDev = data[0].countDev;
              this.countPre = data[0].countPre;
              this.countPro = data[0].countPro;
              this.countQal = data[0].countQal;
              this.countRef = data[0].countRef;
              this.typess = data[0].types;
          },
          err => {
              console.error(err);
          },
          () => {
            this.onGetCountSelectedReportByUser(this.applicationSelect);
          }
      );
      this.subscription.add(subscription4$);
    }

    // select report by application mode user (sans DRAFT)
    onGetReportByAppTypeDbEnvUser(appl, typeDB, envi, event) {
      $("img").each(function() {
        $(this).attr('src', $(this).attr("src").replace('assets/images/icon-db/checked.png', 'assets/images/icon-db/clickable.png'));
      });
      $(event.target).attr('src', 'assets/images/icon-db/checked.png');
      switch (envi) {
        case 0: envi = 'Reference'; break;
        case 1: envi = 'Developpement'; break;
        case 2: envi = 'QAL'; break;
        case 3: envi = 'Pre-Production'; break;
        case 4: envi = 'Production'; break;
        default: break;
      }
      let subscription5$ = this.reportService.getReportByAppTypeDbEnvUser(appl, typeDB, envi, this.selectedDimensions).subscribe(
        data => {
          this.reportsData = data;
          $('html, body').animate({
            scrollTop: $('#scrollSelect').offset().top - 70
          }, 2000);
          this.onGetDataSetChartsSelectedReportsModeUser(appl);
        },
        err => {
          console.error(err);
        },
        () => {
        }
      );
      this.subscription.add(subscription5$);
    }
    onGetDataSetChartsSelectedReportsModeUser(application) {
      let subscription6$ =this.reportService.getDataSetChartsSelectedReportsModeUser(application).subscribe(
            data => {
                this.allReportSelected = data;
                this.checkedReport = [];
                for (let checked of this.allReportSelected) {
                    if (checked.etatSelectUser === 1) {
                        this.checkedReport.push(checked.id);
                        $('#' + checked.id).attr('checked', 'checked');
                    }
                }
                return new Promise((resolve, reject) => {
                    /* inject dataset to chart after view init... */
                    setTimeout(() => {
                        this.startScript();
                    }, 1000);
                });
                },
                err => {
                console.error(err);
            },
            () => {
            }
        );
        this.subscription.add(subscription6$);
    }
    // change status etatSelectUser
    onSetSelctReportModeUser(id, etat, application) {
      let subscription7$ = this.reportService.setSelctReportModeUser(id, etat, application).subscribe(
            data => {
                  if (etat == 0) {
                    this.onGetCountSelectedReportByUser(application);
                    this.onGetDataSetChartsSelectedReportsModeUser(this.applicationSelect);
                  } else {
                    this.countSelectedRep = 1;
                    this.onGetDataSetChartsSelectedReportsModeUser(this.applicationSelect);
                  }

            },
            err => {
              this.authenticationService.showNotification('bg-orange', this.lang ? 'Selected Report for this user must be lower than five!' : 'Le rapport sélectionné pour cet utilisateur doit être inférieur à cinq !' ,
                'top', 'right', 'animated rotateInUpLeft', 'animated rotateOutUpLeft');
              $( '#' + id ).prop( 'checked', false );
            },
        );
        this.subscription.add(subscription7$);
    }

    async startScript() {
        await this.dynamicScriptLoader.load('apexcharts').then(data => {
            this.loadData();
        }).catch(err => console.error(err));
    }
    private loadData() {
        this.chartReportUser();
    }

    private chartReportUser() {
        for (let k of this.allReportSelected) {
          $('#chartUser' + k.id).empty();
          var name = k.id;
          var max = 0;
          this.countEmptyTableReports = k.countEmptyTableReports;
          this.countNotEmptyTableReports = k.countNotEmptyTableReports;
          this.countEmptyTableReports.forEach( nbr => {
            if (nbr > max) {
              max = nbr;
            }
          });
          this.countNotEmptyTableReports.forEach( nbr => {
            if (nbr > max) {
              max = nbr;
            }
          });
          this.dateTableReports = k.dateTableReports;
          var series = [];
          series.push(
              {
                name: 'not Empty',
                data: this.countNotEmptyTableReports
              },
              {
                  name: 'Empty',
                  data: this.countEmptyTableReports
              }
          );
          var options = {
            chart: {
              width: "100%",
              height: 170,
              type: 'line',
              shadow: {
                enabled: true,
                color: '#000',
                top: 3,
                left: 2,
                blur: 10,
                opacity: 1
              } ,
              toolbar: {
                show: false
              }
            },
            colors: ['#77B6EA', '#545454'],
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
              padding: {
                left: 10,
                right: 10
              },
            },
            stroke: {
              width: 1,
              curve: 'smooth'
            },
            series: series,
            xaxis: {
              type: 'date',
              categories: this.dateTableReports,
              tickPlacement: "between"
            },
            title: {
              align: 'left',
              style: {
                fontSize: '14px',
              }
            },
            markers: {
              size: 6,
            },
            yaxis: {
              show: false,
              min: 0,
              max: max * 1.2,
              labels: {
                style: {
                  fontSize: 0.1,
                  color: '#000000',
                }
              }
            },
            legend: {
              show: false,
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          };
          var chart = new ApexCharts(
              document.querySelector("#chartUser" + name),
              options
          );
          chart.render();
         }
    }
    FieldsChange(values: any, id, application) {
        if (values.currentTarget.checked) {
            this.onSetSelctReportModeUser(id, 1, application);
        } else {
            this.onSetSelctReportModeUser(id, 0, application);
        }
    }
    FieldsChange2(id, application) {
        this.onSetSelctReportModeUser(id, 0, application);
        $( '#' + id ).prop( 'checked', false );

    }
    // hide les version archiver
    hideArchivedVersionsReport(index) {
      $('.archivedGroupReport' + index).parent().css('display', 'none');
      $('#iconHideVersionReport' + index).css('display', 'none');
      $('#iconShowVersionReport' + index).css('display', 'inline-block');
    }
    // afficher les version archiver
    showArchivedVersionsReport(index) {
      $('.archivedGroupReport' + index).parent().css('display', 'contents');
      $('#iconHideVersionReport' + index).css('display', 'inline-block');
      $('#iconShowVersionReport' + index).css('display', 'none');
    }

}
