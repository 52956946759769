import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataClassifyRoutingModule } from './data-classify-routing.module';
import {DataClassifyComponent} from './mainData/data-classify.component';


@NgModule({
  declarations: [DataClassifyComponent],
  imports: [
    CommonModule,
    DataClassifyRoutingModule,

  ]
})
export class DataClassifyModule { }
