import * as d3 from 'd3';
import d3Tip from 'd3-tip';
import {getCookieValue, Shared} from "../../shared";
import {packChart} from "../../object-models/sizeChart/sizeChart";

declare const $: any;

export const packChartCampain = (newData) => {
  const Assets = Shared.ASSETS;
  //set margin
  const svg = d3.select("#sizoCampainTaskChart");
  const margin = 10;

  const width = 900;
  const height = 900;
  svg.attr("viewBox", `0 0 900 880`);

  //set size for rendering
  const diameter = +svg.attr("width");
  const g = svg.append("g")
    .attr('id','sizoCampainTaskChartGlobal')
    .attr("transform", "translate(" + diameter / 2 + "," + diameter / 2 + ")");

  const Zoomos = d3.zoom()
    .scaleExtent([.1, 10])
    .on("zoom", function() {
      g.attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ') scale(' + d3.event.transform.k + ')');});
  svg.call(Zoomos);
  svg.on("wheel.zoom", null);

  if($('.d3-tipCampainSize-Zoom').length > 0){
    $('.d3-tipCampainSize-Zoom').remove();
  }
  const tool_tip = d3Tip()
    .direction('n')
    .attr("class", "d3-tipCampainSize-Zoom")
    .offset([-75, 0])
    .html((d) => {
      return '<div id="sizeCampainTipo">' + d.data.name + '</div>';
    });
  svg.call(tool_tip);
  // wrap text
  const wrap = (texto, width) => {
    //console.log(d3.select(text));
    let text = d3.select(texto),
      words = text.text().split(/\s+/).reverse(),
      currentSize = +(text.style("font-size")).replace("px",""),
      word,
      line = [],
      lineNumber = 0,
      lineHeight = 1.2, // ems
      extraHeight = 0.2,
      y = text.attr("y"),
      dy = parseFloat(text.attr("dy")),
      //First span is different - smaller font
      tspan = text.text(null)
        .append("tspan")
        .attr("class","subTotal")
        .attr("x", 0).attr("y", y)
        .attr("dy", dy + "em")
        .style("font-size", (Math.round(currentSize*0.5) <= 5 ? 0 : Math.round(currentSize*0.5))+"px");
    while (word = words.pop()) {
      line.push(word);
      tspan.text(line.join(" "));
      if (tspan.node().getComputedTextLength() > width || word === "|") {
        if (word = "|") word = "";
        line.pop();
        tspan.text(line.join(" "));
        line = [word];
        tspan = text.append("tspan")
          .attr('class', 'objectTitle')
          .attr("x", 0).attr("y", y)
          .attr("dy", ++lineNumber * lineHeight + extraHeight + dy + "em")
          .text(word);
      }// if
    }// while
  }; // wrap
  // zoom element
  const zoomTo = (v, focus, ele) => {
    var k = diameter / v[2];
    view = v;
    node.attr("transform", function(d) {
      return "translate(" + (d.x - v[0]) * k + "," + (d.y - v[1]) * k + ")";
    });

    circle.attr("r", function(d) {
      // if (d && d.height == 1) {
      //   setTimeout(function() {
      //     //reste bar charts
      //     drawBarData("", d, d, d.r * k);
      //   }, 0);
      // }
      return d.r * k;
    });
    text.attr('dx', d=> -d.r * k/2);
   // images.attr("transform", d => `translate(${(d.x - v[0]) * k},${((d.y - v[1]) * k) })`);
  };

  var color = d3.scaleOrdinal()
    .domain([0,500])
    .range(d3.schemeSet1);
  //initiate circle pack
  let pack = d3.pack()
    .size([diameter - margin, diameter - margin])
    .padding(2);

  let root = newData;
  root = d3.hierarchy(root)
    .sum(function(d) {
      return d.size;
    });

  let focus = root,
    nodes = pack(root).descendants(),
    view;
// create gradient for circles and bars
  svg.append("svg:defs").selectAll('radialGradient')
    .data(['gradoTask1'])
    .enter().append('svg:radialGradient')
    .attr("id", String)
    .attr('cx', '50%')
    .attr('cy', '50%')
    .attr('r', '75%')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', 'rgb(116, 215, 202)')
    .attr('stop-opacity', 1);
  svg.select('#gradoTask1')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgb(116, 215, 202)')
    .attr('stop-opacity', 1);

  svg.append("svg:defs").selectAll('radialGradient')
    .data(['gradoTask2'])
    .enter().append('svg:radialGradient')
    .attr("id", String)
    .attr('cx', '50%')
    .attr('cy', '50%')
    .attr('r', '75%')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#ffffff')
    .attr('stop-opacity', 1);
  svg.select('#gradoTask2')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#fcfff2')
    .attr('stop-opacity', 1);
  // grey gradient
  svg.append("svg:defs").selectAll('radialGradient')
    .data(['axisGradTask'])
    .enter().append('svg:radialGradient')
    .attr("id", String)
    .attr('cx', '50%')
    .attr('cy', '50%')
    .attr('r', '75%')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#b9b9b9')
    .attr('stop-opacity', 1);
  svg.select('#axisGradTask')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', 'rgba(148,145,138,0.96)')
    .attr('stop-opacity', 1);
// gradient for bars
  svg.select("defs").selectAll('linearGradient')
    .data(nodes)
    .enter().append('svg:linearGradient')
    .attr("id", (d , i) => 'gradientTask' + i )
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', (d , i) => color(i))
    .attr('stop-opacity', 1);
  svg.selectAll('linearGradient')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', (d , i) => color(i))
    .attr('stop-opacity', 1);
// add shadow filter
  svg.append("svg:defs").selectAll('filter')
    .data(['shadowSizeTask'])
    .enter().append('svg:filter')
    .attr("id", String)
    .attr('y', -10)
    .attr('x', -10)
    .attr('height', 40)
    .attr('width', 150)
    .append('feOffset')
    .attr('in', 'SourceAlpha')
    .attr('dx', 3)
    .attr('dy', 3)
    .attr('result', 'offset2')
    .attr('stdDeviation', 4);
  svg.selectAll('filter')
    .append('feGaussianBlur')
    .attr('in', 'offset2')
    .attr('result', 'blur2')
    .attr('stdDeviation', 3);
  svg.selectAll('filter')
    .append('feMerge')
    .append('feMergeNode')
    .attr('in', 'blur2');
  svg.selectAll('filter')
    .select('feMerge')
    .append('feMergeNode')
    .attr('in', 'SourceGraphic');

  let circle = g.selectAll("circle")
    .data(nodes)
    .enter().append("circle")
    .attr("class", function(d) {
      return d.parent ? d.children ? "nodos" : "nodos task_campain" : "nodos node--root";
    })
    .attr("fill", function(d) {
      return d.parent ? d.children ? 'url(#gradoTask2)' : null : 'rgb(116, 215, 202)';
    })
   // .attr('pointer-event', d => d.data.status == 1? null: 'none')
    // .attr('filter', 'url(#shadowSize)')
    .attr('id', d => 'circleTask_' + d.data.id )
    .on('click', null)
    .on('mouseover',function (d) {
      tool_tip.show(d, this);
    })
    .on("mouseout", function(d) {
      tool_tip.hide(); })
    .on("click", function(d) {
      if(d.data.status == 0){
         return;
      }
      $('text[id^=\'labelTask_\']').css('display','none');
      d3.select('#labelTask_'+d.data.id).style('display','inline');
      if (focus !== d) {
        if (!d.children) {
          zoom(d, this); d3.event.stopPropagation();
          // on click on circle will open sizeChart fro selected model
          $('#campain_preloder').attr('opacity', 1);
          d3.json(Shared.API_URL + "/api/object-model-join/getModelPack/" + d.data.id , {
            headers: new Headers({
              'Accept': 'Application/json',
              'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            }),
          }).then(json => {
            $('#sizoChart').empty().css('display', 'block');
            document.getElementById('sizoCampainTaskChart').dispatchEvent(new Event('click'));
            if($('#sizoChart').css('display')=='block' && $('.taskshown').length==0){
              $('#dataTaskDetail_'+d.data.id).click();
            }

            $('#sizoCampainTaskChart').css('display', 'none');
            $('#campain_preloder').attr('opacity', 0);
            packChart(json);
            $('#campain_close').css('display', 'block');
          });
          // end sizeChart model
        }
      } else{
        document.getElementById('sizoCampainTaskChart').dispatchEvent(new Event('click'));

      }
    });
  //preloader
  const preloader = svg.append("g")
    .attr('opacity', 0)
    .attr('id', 'campain_preloder')
    .attr("transform", "translate(" + (diameter -100)/ 2 + "," + (diameter -100)/ 2 + ")");
  $('#campain_preloder').html('<path fill="#25aae1" d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50">\n' +
    '      <animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="360 50 50" repeatCount="indefinite"></animateTransform>\n' +
    '  </path>\n' +
    '    \n' +
    '    <text dx="-3em" font-weight="bold" fill="#25aae1">Loading Task details please wait...</text>');
   //  .append('path')
   //    .attr('fill', '#25aae1')
   //    .attr('d', 'M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50')
   //    .append('animationTransform')
   //      .attr('attributeName', 'Transform')
   //      .attr('attributeType', 'XML')
   //      .attr('type', 'rotate')
   //      .attr('dur', '1s')
   //      .attr('from', '0 50 50')
   //      .attr('to', '360 50 50')
   //      .attr('repeatCount', 'indefinite');
   // d3.select('#campain_preloder')
   //   .append('text')
   //   .attr('dx', '-3em')
   //   .attr('font-weight', 'bold')
   //   .attr('fill', '#25aae1')
   //   .text('Loading Task details please wait...');
// images campains
//   const images =svg.append("g")
//     .attr("transform", "translate(" + diameter / 2 + "," + diameter / 2 + ")")
//     .selectAll('.imago_Task')
//     .data(root.descendants().slice(1))
//     .enter()
//     .append('svg:image')
//   //  .style('display',  'none')
//     .attr('filter', 'url(#shadowSizeTask)')
//     .attr('outline', 'none')
//     .attr('id', d => 'task_image' + d.data.id)
//     .attr('class', 'imago_task')
//     .attr('width', d => 5/6 * d.r)
//     .attr('height', d => 5/6 * d.r)
//     .attr('xlink:href' , '../../../../' + Assets + '/images/taskChart.png');

  var text = g.selectAll(".labelTask")
    .data(nodes.filter(function(d) {
      return d.height == 0;
    }))
    .enter().append("text")
    .attr("class", "labelTask")
    .style("fill-opacity", function(d) {
      return d.parent === root ? 1 : 0;
    })
    .style("display", function(d) {
      return d.parent === root ? "inline" : "none";
    })
    .attr("y", function(d, i) {
      d.titleHeight = (-1 + 0.25) * d.r;
      return d.titleHeight;
    })
    .attr("id", function(d) {
      return 'labelTask_'+ d.data.id;//setting text name as the ID
    })
    .attr("dy","0em")
    .attr('dx', d => -d.r/2)
    .text(function(d,i) { return d.name; })
    .style("font-size", function(d) {
      //Calculate best font-size
      d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
      return Math.round(d.fontTitleSize)+"px";
    })
    .each(function(d) {
      d.textLength = d.r*2*0.7;
      wrap(this, d.textLength);
    })
    .text(function(d) {
      return d.data.name;
    });

  var node = g.selectAll("circle,.labelTask");

  svg
    .on("click", function() {
      zoom(root, this);
    });
  // @ts-ignore
  zoomTo([root.x, root.y, root.r * 2 + margin]);
  // console.log(root.x, root.y, root.r * 2 + margin);
  const zoom = function(d, ele) {
    // d3.selectAll('.baros').style('display', 'none');
    var focus0 = focus;
    focus = d;
    var transition = d3.transition()
      .duration(d3.event.altKey ? 500 : 50)
     // .duration(0)
      .tween("zoom", function(d) {
        // @ts-ignore
        var i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2 + margin]);
        return function(t) {
          zoomTo(i(t), focus, ele);
        };
      });
    transition.selectAll(".labelTask")
      .filter(function(d) {
        return d.parent === focus || this.style.display === "inline";
      }).text(function (d) {
      if(d.parent === focus ) {
        $('#sizoCampainTaskChart').click();
        // $('.axis.axis--x').css('display','none');
        return d.data.name;
      }else{
        // $('.axis.axis--x').css('display','block');
        if(d.data === undefined){ return;}
        if(d.data.size > 1){
          return d.data.name;// + ' ' +d.data.size +' tables';
        }
        else{
          return d.data.name; //+ ' ' +d.data.size +' tables';
        }
      }
    }).attr('y', function (d) {
        if(d.parent === focus ) {
          d3.selectAll('.labelTask').style('display','inline');
          d.titleHeight = (-1 + 0.25) * d.r;
          return d.titleHeight;

        }else{
          return '-230';
        }
      })
      .attr('dx', d => -d.r/2)
      .style("font-size", function(d) {
        if(d.parent === focus ) {
          // Calculate best font-size
          d.fontTitleSize = d.r / 10;//this.getComputedTextLength() * 20;
          return Math.round(d.fontTitleSize)+"px";
        }else{
          return "20px";
        }
      });
  };
  $('#zoomIn_button').on('click',function(){
    svg.transition().call(Zoomos.scaleBy, 2);
  });
  $('#zoomOut_button').on('click',function(){
    svg.transition().call(Zoomos.scaleBy, 0.5);
  });
  $('#resetSize_button').on('click',function(){
    svg.transition().duration(750).call(
      Zoomos.transform,
      d3.zoomIdentity,
      d3.zoomTransform(svg.node()).invert([width / 2, height / 2])
    );
  });

};
