import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class FunctionalMapService {

  constructor(private http: HttpClient) { }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getFunctionalMapList(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/business_glossary/getFunctionalMapList/' + localStorage.getItem('userId') , this.getOptions());
  }
  addFunctionalMap(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/business_glossary/addFunctionalMap/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  updateFunctionalMap(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/business_glossary/updateFunctionalMap/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  removeFunctionalMap(id, action: string): Observable<any> {
    return this.http.delete(Shared.API_URL + '/api/business_glossary/removeFunctionalMap/' + localStorage.getItem('userId')  + '/' + id + '/' + action, this.getOptions());
  }

  saveFunctionalMapEditor(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/business_glossary/saveFunctionalMapEditor/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  packedDimensionByFunctional(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/business_glossary/packedDimensionByFunctional/' + localStorage.getItem('userId') , this.getOptions());
  }

  uploadFunctionalMap(d, type= 'json'): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post<any>(Shared.API_URL + '/api/business_term/uploadGlossary/' + localStorage.getItem('userId') + '/' + type , data , this.getOptions());
  }
  // save description of functionalMap link
  saveFunctionalMapLinkDescription(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/business_glossary/saveFunctionalMapLinkDescription/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  // save goldenSource of functionalMap field
  saveFunctionalMapGoldSource(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/business_glossary/saveFunctionalMapGoldSource/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  // functionalMap group
  getFunctionalMapGroupList(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-map-group/getFunctionalMapGroupList/' + localStorage.getItem('userId') , this.getOptions());
  }

  addFunctionalMapGroup(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/functional-map-group/addFunctionalMapGroup/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  checkFunctionalMapGroup(name: string): Observable<any>  {
    return this.http.get<any>(Shared.API_URL + '/api/functional-map-group/checkFunctionalMapGroup/' + name , this.getOptions());
  }

  // getGlossaries(): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/glossary/getGlossaries/' + localStorage.getItem('userId') , this.getOptions());
  // }
  // functionalMapCateg
  getFunctionalMapCategoriesList(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-map-category/getFunctionalMapCategoriesList/' + localStorage.getItem('userId') , this.getOptions());
  }
  addFunctionalMapCateg(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/functional-map-category/addFunctionalMapCateg/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  updateFunctionalMapCateg(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/functional-map-category/updateFunctionalMapCateg/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  removeFunctionalMapCateg(id): Observable<any> {
    return this.http.delete(Shared.API_URL + '/api/functional-map-category/removeFunctionalMapCateg/' + localStorage.getItem('userId')  + '/' + id , this.getOptions());
  }
  getApplications(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/business_glossary/getApplications/' + localStorage.getItem('userId') , this.getOptions());
  }
  getFunctionalMapById(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.get<any>(Shared.API_URL + '/api/business_glossary/getFunctionalMapById/' + localStorage.getItem('userId') , this.getOptions());
  }
  getFunctionalMapCategoryById(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.get<any>(Shared.API_URL + '/api/business_glossary/getFunctionalMapCategoryById/' + localStorage.getItem('userId') , this.getOptions());
  }
  getGlossaries(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-map-group/getGlossaries/' + localStorage.getItem('userId') , this.getOptions());
  }
  getFunctionalDataByGlossary(idFunctionalMap,idGlossary: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-map-group/getFunctionalDataByGlossary/' + idFunctionalMap + '/' + idGlossary + '/' + localStorage.getItem('userId') , this.getOptions());
  }
  getFunctionalMapping(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getFunctionalMapping/' + localStorage.getItem('userId') , data , this.getOptions());
  }
  addFunctionalMapping(d): Observable<any> {
    const data = 'data='  + d;
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/addFunctionalMapping/' + localStorage.getItem('userId') + '/' + getCookieValue('username') , data , this.getOptions());
  }
  getDistributionChart(idFunctionalMap,idFunctionalObject: string,functionalObjectVersion: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-object-mapping/getDistributionChart/' + idFunctionalMap + '/' + idFunctionalObject + '/' + functionalObjectVersion , this.getOptions());
  }
  getObjectMappingForTerm(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getObjectMappingForTerm' , data , this.getOptions());
  }
  updateFunctionalMapGroup(d): Observable<any> {
    const data = 'data='  + JSON.stringify(d);
    return this.http.put<any>(Shared.API_URL + '/api/functional-map-group/updateFunctionalMapGroup/' + localStorage.getItem('userId') , data , this.getOptions());
  }
  removeFunctionalMapGroup(id: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/functional-map-group/removeFunctionalMapGroup/' + localStorage.getItem('userId') + '/' + id , this.getOptions());
  }
  importMapping(d): Observable<any> {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/importMapping/' + localStorage.getItem('userId'), data , this.getOptions());
  }

  saveStatusMapping(d): Observable<any> {
    const data = 'data='  + d;
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/saveStatusMapping/' + localStorage.getItem('userId'), data , this.getOptions());
  }
  getModelsByApp(app: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-object-mapping/getModelsByApp/' + localStorage.getItem('userId') + '/' + app , this.getOptions());
  }
  getObjectsByModel(model: any): Observable<any> {
    const t = {model };
    const data = 'data=' + JSON.stringify(t);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getObjectsByModel/' + localStorage.getItem('userId') ,data , this.getOptions());
  }
  getTablesByObject(object: string): Observable<any> {
    const t = {object };
    const data = 'data=' + JSON.stringify(t);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getTablesByObject/' + localStorage.getItem('userId')  , data, this.getOptions());
  }
  getFieldsByTableModal(id: string): Observable<any> {
    const t = {object: id };
    const data = 'data=' + JSON.stringify(t);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getFieldsByTableModal/' + localStorage.getItem('userId') , data , this.getOptions());
  }
  getFunctionalDataById(idFunctionalMap, idFunctionaObject: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-map-group/getFunctionalDataById/' + idFunctionalMap + '/' + idFunctionaObject + '/' + localStorage.getItem('userId') , this.getOptions());
  }
  removeFunctionalObjectMapping(id: string, functionaObjectId: string, version: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/functional-object-mapping/removeFunctionalObjectMapping/' + localStorage.getItem('userId') + '/' + id + '/' + functionaObjectId + '/' + version , this.getOptions());
  }
  //////////////////////// COMPARAISON ///////////////////////////////////////////////
  getCompareInFOMapping(idFunctional: string, idMap: string, startVersion: number, targetVersion: number): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-object-mapping/getCompareInFOMapping/' + idFunctional + '/' + idMap + '/' + startVersion + '/' + targetVersion, this.getOptions());
  }
  getFunctionalMappingVersion(idFunc: string): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/functional-object-mapping/getFunctionalMappingVersion/' + idFunc, this.getOptions());
  }
  resetDataBase(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-object-mapping/resetDataBase' , this.getOptions());
  }
  validateFunctionalMapOldMapping(idFunctional: string, version: number): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional/validateFunctionalMapOldMapping/' + idFunctional + '/' + version , this.getOptions());
  }
  getApplicationForDataDictionary(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-object-mapping/getApplicationForDataDictionary/' + localStorage.getItem('userId') , this.getOptions());
  }
  getDataDictionaryByApplication(app: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-object-mapping/getDataDictionaryByApplication/' + app , this.getOptions());
  }
  getDataDictionaryTableByReport(catalog: any): Observable<any> {
    const data = 'data=' + JSON.stringify(catalog);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getDataDictionaryTableByReport',data  , this.getOptions());
  }
  getDataDictionaryFieldByTable(catalog: any): Observable<any> {
    const data = 'data=' + JSON.stringify(catalog);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getDataDictionaryFieldByTable' , data , this.getOptions());
  }
  getFunctionalObjectByGlossary(glossary: string): Observable<any> {
    return this.http.post<any>(Shared.API_URL + '/api/functional-map-group/getFunctionalObjectByGlossary/' + glossary ,null, this.getOptions());
  }
  getModuleGroupsByFunctionalObject(func: any): Observable<any> {
    const data = 'data=' + JSON.stringify(func);
    return this.http.post<any>(Shared.API_URL + '/api/functional-map-group/getModuleGroupsByFunctionalObject'  ,data, this.getOptions());
  }
  getBusinessObjectsByModuleGroup(moduleGroup: any): Observable<any> {
    const data = 'data=' + JSON.stringify(moduleGroup);
    return this.http.post<any>(Shared.API_URL + '/api/functional-map-group/getBusinessObjectsByModuleGroup'  ,data, this.getOptions());
  }
  getBusinessTermsByBusinessObject(businessObject: any): Observable<any> {
    const data = 'data=' + JSON.stringify(businessObject);
    return this.http.post<any>(Shared.API_URL + '/api/functional-map-group/getBusinessTermsByBusinessObject'  ,data, this.getOptions());
  }
  getRecordsByCatalogs(catalog: any): Observable<any> {
    const data = 'data=' + JSON.stringify(catalog);
    return this.http.post<any>(Shared.API_URL + '/api/functional-map-group/getRecordsByCatalogs'   ,data, this.getOptions());
  }
  getRecordsAndFieldsForFilter(): Observable<any> {
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getRecordsAndFieldsForFilter'   , null, this.getOptions());
  }
  getResultForFilter(fields: any): Observable<any> {
    const data = 'data=' + JSON.stringify(fields);
    return this.http.post<any>(Shared.API_URL + '/api/functional-object-mapping/getResultForFilter'   , data, this.getOptions());
  }
  // reference api
  getReferenceGlossaries(ref: boolean): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getReferenceGlossaries/' + localStorage.getItem('userId') + '/' + ref , this.getOptions());
  }
  getFunctionalObjectReferenceByGlossary(glossary: string, ref: boolean): Observable<any> {
    return this.http.post<any>(Shared.API_URL + '/api/data-line/getFunctionalObjectReferenceByGlossary/' + glossary + '/' + ref, null, this.getOptions());
  }
  getModuleGroupsReferenceByFunctionalObject(func: any, ref: boolean): Observable<any> {
    const data = 'data=' + JSON.stringify(func);
    return this.http.post<any>(Shared.API_URL + '/api/data-line/getModuleGroupsReferenceByFunctionalObject/' + ref  , data, this.getOptions());
  }
  getBusinessObjectsReferenceByModuleGroup(moduleGroup: any, ref: boolean): Observable<any> {
    const data = 'data=' + JSON.stringify(moduleGroup);
    return this.http.post<any>(Shared.API_URL + '/api/data-line/getBusinessObjectsReferenceByModuleGroup/'  + ref , data, this.getOptions());
  }
  getBusinessTermsReferenceByBusinessObject(businessObject: any, ref: boolean): Observable<any> {
    const data = 'data=' + JSON.stringify(businessObject);
    return this.http.post<any>(Shared.API_URL + '/api/data-line/getBusinessTermsReferenceByBusinessObject/' + ref  , data, this.getOptions());
  }
  getModulesByApp(app: string): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/data-line/getModulesByApp/' + localStorage.getItem('userId') + '/' + app , this.getOptions());
  }
  getSystems(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getSystems/' + localStorage.getItem('userId') , this.getOptions());
  }
  getApplicationsForCatalog(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getApplicationsForCatalog/' + localStorage.getItem('userId') , this.getOptions());
  }
  getApplicationsForDictionary(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getApplicationsForDictionary/' + localStorage.getItem('userId') , this.getOptions());
  }
}
