import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpEvent} from '@angular/common/http';
import {Router} from '@angular/router';

import { Observable } from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class MetadataTTemplateService {

  OptionFiles()  {
    return {
        headers: new HttpHeaders({
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            // 'Accept': 'Application/json'
        })
    };
};
  
Options = {
headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'Application/json',
    'Authorization': 'Bearer ' + getCookieValue('tokendrd')
    //  'Accept': 'Application/json'
})
};
headerss = new Headers({
'Content-Type': 'application/json',
'Authorization': 'Bearer ' + getCookieValue('tokendrd')
});
constructor(private http: HttpClient, private router: Router) { }

getMetaObj(id,objName): Observable<any> {
  return this.http.get<any>(Shared.API_URL + `/api/metadatas/getTableObj/${id}/${objName}`, this.Options);
}

// updateMetaObj(d,id,objName): Observable<any>{
//   let formData = new FormData();
//   formData.append('data', d);
//
//   return this.http.post(Shared.API_URL + '/api/metadatas/updateTableObj/' + id+'/'+objName, formData,  this.OptionFiles());
// }
}