export class AdministrationJson {
  appl: string;
  createAdm = false;
  readAdm = false;
  updateAdm = false;
  deleteAdm = false;
  constructor(appl, createAdm, readAdm, updateAdm, deleteAdm) {
    this.appl = appl;
    this.createAdm = createAdm;
    this.readAdm = readAdm;
    this.updateAdm = updateAdm ;
    this.deleteAdm = deleteAdm;
  }
}
