import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ObjectModelsComponent} from './mainObject/object-models.component';
import {ChartGlobComponent} from './globChart/chart-glob/chart-glob.component';
import {VolumeComponent} from './volume/volume.component';
import {DesignerComponent} from './designer/designer.component';
import {GraphComponent} from './designer/graph/graph.component';


const routes: Routes = [
  {
      path: '',
      redirectTo: 'object-models',
      pathMatch: 'full'

  },
  {
      path: 'object-models',
      component: ObjectModelsComponent
  },
  {
    path: 'model-designer',
    component: DesignerComponent
  },
  {
    path: 'mx-designer',
    component: GraphComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ObjectModelsRoutingModule { }
