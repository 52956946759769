import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Form, FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import {NodeItem} from '../NodeItem';
import {DataLineageService} from '../../services/data-lineage/data-lineage.service';
import { ColorEvent } from 'ngx-color';
import {renderTreeLine} from '../TreeChartDataLine';
import {formatDate} from '@angular/common';
import {ExtraItem} from '../ExtraItem';
import {ITreeOptions, KEYS, TREE_ACTIONS} from '@circlon/angular-tree-component';
import {CryptoService} from '../../crypto.service';
import {ActivatedRoute, Router} from '@angular/router';
import {refreshComponent} from '../../shared';
import html2canvas from 'html2canvas';
import * as FileSaver from 'file-saver';
import {AppassetService} from "../../services/data-transfert/appasset.service";
import {Subscription} from "rxjs";
import { TranslateService } from '@ngx-translate/core';


declare const $: any;
declare const LeaderLine: any;
declare const PlainDraggable: any;
declare const swal: any;
declare const CKEDITOR: any;

@Component({
  selector: 'app-dala-lineage',
  templateUrl: './data-lineage.component.html',
  styleUrls: ['./data-lineage.component.css']
})
export class DataLineageComponent implements OnInit, OnDestroy, AfterViewInit {
  nodes = [
    { // node a
      data: {id: 'nodeFirst', type: 'start', label: ''}
    },
    { // node b
      data: {id: 'nodeLast', type: 'end', label: ''}
    }
  ];
  i = 0;
  types : any;
  addNodeFrm: FormGroup;
  propertyFrm: FormGroup;
  saveFrm: FormGroup;
  linefrm: FormGroup;
  selectedNode = null;
  textColor: any;
  bgColor: any;
  wrapperColor = 'rgb(255, 255, 255,1)';
  // lg mindMap
  edges = [];
  draggables = [];
  selectedId = '';
  targets = [];
  applicationTypes = [];
  applications = [];
  models = [];
  objects = [];
  dataLineDetails = [];
  tables = [];
  fields = [];
  choosenIcon = 'start';
  loadingApplication = false;
  loadingModel = false;
  loadingObject = [];
  loadingApplicationType = true;
  loadingTable = [];
  loadingField = [];
  customModelAndObjects = [];
  j = 0;
  modelAndObjectAndTableAndFields = [];
  // ngModel for form
  ngTypeApp = null;
  ngApp = null;
  ngNodeText = null;
  ngModels = [];
  ngObjects = [];
  ngTables = [];
  ngFields = [];
  ngExtern = false;
  ngCatalogue = true;
  ngCustomModel = [];
  ngCustomObjects = [];
  ngCustomTable = [];
  ngCustomsFields = [];
  ngDescription = '';
  ngCustomApplication = null;
  // customs
  customTable = [];
  customFields = [];
  loaders = [];
  disabled = true;
  error = false;
  errorMessage = '';
  nodesItems = [];
  startConnector = null;
  endConnector = null;
  interval: any;
  startColor = 'black';
  endColor = 'black';
  startParent = null;
  endParent = null;
  linesArch = [];
  clicked = null;
  patterns = [{id: 1, name: 'none'}, {id: 2, name: 'paper'}/*, {id: 4, name: 'tiny'}, {id: 5, name: 'connections'} , {id: 6, name: 'diagonal'} , {id: 7, name: 'parkayFloor'}, {id: 8, name: 'floorTile'}, {id: 9, name: 'polkaDots'}*/, {id: 3, name: 'paperDots'}, {id: 4, name: 'dotDiamond'}, {id: 5, name: 'gradientDots'}, {id: 6, name: 'bluePrint'}];
  pattern = 'none';
  prevPattern = 'none';
  flatDesign = true;
  treeData = [];
  orgData = [];
  modalData: any;
  zoom = 1;
  // external system
  registerFormApp: FormGroup;
  registerFormMod: FormGroup;
  editFormMod: FormGroup;
  registerFormVer: FormGroup;
  registerFormFile: FormGroup;
  editFormFile: FormGroup;
  editFormVer: FormGroup;
  registerForm: FormGroup;
  updateFormApp: FormGroup;
  filterDefinition: FormGroup;
  versions = [];
  listTypeApp :any;
  listDepApp :any;
  listStatusApp :any;
  stateOption :any;
  stackHolders = [];
  presubmitAdd = true;
  messagedangerAdd: string;
  messagesuccesAdd: string;
  messagedangerNewSys: string;
  messagesuccesNewSys: string;
  presubmitNewSystem = false;
  editedDescApp = false;
  mForAddApps = [];
  fmdSAddApps = [];
  fmdAddApps = [];
  descriptionNewSystem = '';
  descriptionNewModuleSystem = '';
  descriptionUpdateSystem = '';
  descriptionUpdateVersion = '';
  descriptionUpdateModule = '';
  descriptionNewVersion = '';
  descriptionNewModule = '';
  todayDate = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  externalApplications = [];
  loadingApplicationExterne = false;
  holderLoading = false;
  triggers = [{id : 'Manual', name: 'Manual'}, {id : 'Semi-automatic', name: 'Semi-automatic'}, {id : 'Automatic', name: 'Automatic'}, {id : 'Undefined', name: 'Undefined'}];
  ngTrigger = 'Manual';
  tablesModal = [];
  loadingTableModal = false;
  tableModalValue = [];
  tablesFields = [];
  customTableModal = [];
  customTableModalValue = [];
  customTablesFields = [];
  tableFieldIndex = 0;
  customModalFields = [];
  error1 = false;
  error2 = false;
  extraItems = [];
  extraDescription = '';
  editMode = false;
  treeNodes = [];
  options: ITreeOptions = {
    isExpandedField: 'expanded',
    actionMapping: {
      mouse: {
        dblClick: (tree, node, $event) => {
          if (node.hasChildren) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        }
      },
      keys: {
        [KEYS.ENTER]: (tree, node, $event) => {
          node.expandAll();
        }
      }
    },
    nodeHeight: 23,
    levelPadding: 10,
    useVirtualScroll: true,
    animateExpand: true,
    scrollOnActivate: true,
    animateSpeed: 30,
    animateAcceleration: 1.2
  };
  clickedEdges = [];
  selectedEdges = [];
  selectedEdge: any;
  startName: any;
  endName: any;
  simulation: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  id: any;
  tabIndex = 10;
  importData: any;
  offset = {top: 0, left: 0};
  dataLineageName :any;
  createdBy = localStorage.getItem('username');
  createdAt = new Date();
  dataLineageEffective: any;
  dataLineageEnabled: any;
  dataLineageDescription: any;
  updateLn = false;
  deleteLn = false;
  owner = '';
  @ViewChild('screen', { static: true }) screen: any;
  img = '';
  loadingLine = true;
  dataLines = [];
  ngTriggerExtra = 'Manual';
  ngDataLinesExtra = [];
  initialFlexBasis: number;
  xMousePos = 0;
  yMousePos = 0;
  lastScrolledLeft = 0;
  lastScrolledTop = 0;
  patternColor: any;
  changement = false;
  resetbtnDef = false;
  messagedanger: string;
  messagesucces: string;
  presubmitMod = false;
  presubmitModU = false;
  messagedangerMod: string;
  messagesuccesMod: string;
  messagedangerModU: string;
  messagesuccesModU: string;
  // version dec
  presubmitVer = false;
  presubmitVerU = false;
  messagedangerVer: string;
  messagesuccesVer: string;
  messagedangerVerU: string;
  messagesuccesVerU: string;
  // file upload dec
  presubmitFile = false;
  presubmitFileU = false;
  messagedangerFile: string;
  messagesuccesFile: string;
  messagedangerFileU: string;
  messagesuccesFileU: string;
  lang: string;
  subscription: Subscription = new Subscription();
  constructor(private fb: FormBuilder, private dataLineService: DataLineageService, private dynamicScriptLoader: DynamicScriptLoaderService, private cryptoService: CryptoService, private router: Router, private activeRoute: ActivatedRoute, private appService: AppassetService,private translate: TranslateService) {
    // this.addNodeFrm = this.initForm();
    this.saveFrm = this.initChildForm();
    this.linefrm = this.initLineForm();
    this.registerFormApp = this.initAppForm();
    // this.propertyFrm = this.initFormProperty();
  }

  ngAfterViewInit(): void {
    const that = this;
    $('#play-area').on('click', function(event) {
      event.stopPropagation();
      that.clicked = null;
      $('.node-item').removeClass('selected-node');
    }).resize(function() {
      that.updateConnectors();
    }).css('flex-basis', '70%');
    $('#shapes-panel').css('flex-basis', '10%');
    $('#properties-panel').css('flex-basis', '20%');
    this.initialFlexBasis = parseFloat($('#play-area').css('flex-basis'));
    $(window).scroll(()=>{this.updateConnectors();});
    }

  ngOnInit(): void {
    this.edges = [];
    this.id= null;
    // get params from url
    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      id = id.replace(/__________/g, '/');
      this.id = this.cryptoService.get(id);
    });
    'use strict';
    $(document).ready(function() {
      $('.collapsible').collapsible();
    });
    if(this.id!= null && this.id!= 0) {
      this.addLoader();
      this.getDataLineage(this.id);
      this.getDataLineageList(this.id);
    }else{
      $('#bt_back').css('top', '0');
      this.getDataLineageList('0');
    }
    this.getApplicationTypes();
    PlainDraggable.draggableCursor = ['move', 'all-scroll', 'grab'];
    PlainDraggable.draggingCursor = ['move', 'grabbing'];
    this.onGetHolders();
    $(window).scroll(() => {
      if (this.lastScrolledLeft != $(document).scrollLeft()) {
        this.xMousePos -= this.lastScrolledLeft;
        this.lastScrolledLeft = $(document).scrollLeft();
        this.xMousePos += this.lastScrolledLeft;
      }
      if (this.lastScrolledTop != $(document).scrollTop()) {
        this.yMousePos -= this.lastScrolledTop;
        this.lastScrolledTop = $(document).scrollTop();
        this.yMousePos += this.lastScrolledTop;
      }
      // window.status = 'x = ' + this.xMousePos + ' y = ' + this.yMousePos;
    });
    //Intialize Form groupe application
    this.registerFormApp = this.fb.group({
      // nameApp: ['', Validators.compose([AppValidator.validApp,Validators.required])],
      nameApp: ['', Validators.compose([Validators.required])],
      effectDateNewApp: ['', Validators.compose([Validators.required])],
      endDateNewApp: [''],
      versionApp: [''],
      statusApp: true,
      external: false,
      typeApp: [''],
      deploymentApp: [''],
      descApp: [''],
      moduleApp: ['', Validators.compose([Validators.required])],
      descriptionModuleSystem: [''],
      createdAt: [''],
      createdBy: [''],
      updatedAt: [''],
      updatedBy: [''],
      manager: [''],
      owner: [''],

      applicationManager: [''],
      metadataApps: []
    });
    //Initialize form update app
    this.updateFormApp = this.fb.group({
      nameApp: ['', Validators.compose([Validators.required])],
      dateApp: [''],
      endDate: [''],
      versionApp: [''],
      statusApp: [''],
      external: [''],
      typeApp: [''],
      deploymentApp: [''],
      descApp: [''],
      idApp: [''],
      createdAt: [''],
      createdBy: [''],
      updatedAt: [''],
      updatedBy: [''],
      manager: ['', Validators.compose([Validators.required])],
      owner: [''],
      applicationManager: [''],

    },);
    //Initialize form filter app
    this.filterDefinition = this.fb.group({
      filterApplication: [''],
      filterReport: [''],
      filterBase: [''],
      filterEnv: [''],
      filterOneApp: '',
      statusAppOption: 'all',
      statusModOption: 'all'
    },);

    //Initialize form update module
    this.registerFormMod = this.fb.group({
      nameMod: ['', Validators.compose([Validators.required])],

      descMod: [''],
      effectiveDate: ['', Validators.compose([Validators.required])],
      status: [''],
      idApp: [''],
      endDate: [''],
      idModule: [''],
    },);
    this.editFormMod = this.fb.group({
      // nameApp: [{ value: '', disabled: true}, Validators.required],
      nameMod: ['', Validators.compose([Validators.required])],

      descMod: [''],
      idModule: [''],
      effectiveDate: ['', Validators.compose([Validators.required])],
      status: [''],
      idApp: [''],
      endDate: ['']

    },);
    //Initialize form update version
    this.registerFormVer = this.fb.group({

      version: [''],
      deploymentApp: [''],
      effectiveDate: ['', Validators.compose([Validators.required])],
      typeApp: [''],
      statusApp: [''],
      idApp: [''],
      idversion: [''],
      endDateApp: [''],
      descVer: ['']
    },);

    this.editFormVer = this.fb.group({
      version: ['', Validators.compose([Validators.required])],
      deploymentApp: ['', Validators.compose([Validators.required])],
      effectiveDate: ['', Validators.compose([Validators.required])],
      typeApp: ['', Validators.compose([Validators.required])],
      statusApp: [''],
      idversion: [''],
      idApp: [''],
      endDateApp: [''],
      descVer: ['']
    },);
    //Initialize form update file
    this.registerFormFile = this.fb.group({

      description: [''],
      file: ['', Validators.compose([Validators.required])],
      idApp: ['']
    },);
    this.editFormFile = this.fb.group({
      name: [''],
      description: [''],
      idApp: [''],
      id: ['']

    },);
    for (const i in this.updateFormApp.controls) {
      if (false) {
        this.updateFormApp.controls[i].enable();
      } else {
        this.updateFormApp.controls[i].disable();
      }
    }
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
  const that = this;
  setInterval(() => {
    this.lang = localStorage.getItem('lang') || 'en';
    this.translate.use(this.lang);
  }, 10);

  this.types = [
    {id: 'stock', name: this.lang === 'en' ? 'stock' : 'stock'},
    {id: 'acceder', name: this.lang === 'en' ? 'access' : 'accéder'},
    {id: 'archivage', name: this.lang === 'en' ? 'archiving' : 'archivage'},
    {id: 'collecter', name: this.lang === 'en' ? 'collect' : 'collecter'},
    {id: 'control', name: this.lang === 'en' ? 'control' : 'contrôle'},
    {id: 'destruction', name: this.lang === 'en' ? 'destruction' : 'destruction'},
    {id: 'traitement', name: this.lang === 'en' ? 'treatment' : 'traitement'},
    {id: 'transfert', name: this.lang === 'en' ? 'transfer' : 'transfert'},
    {id: 'visa', name: this.lang === 'en' ? 'visa' : 'visa'},
    {id: 'validate', name: this.lang === 'en' ? 'validate' : 'valider'},
    {id: 'start', name: this.lang === 'en' ? 'start' : 'début'},
    {id: 'end', name: this.lang === 'en' ? 'end' : 'fin'}
  ];
  this.listTypeApp = this.lang === 'en' ? ['Desktop application', 'Web application', 'Mobile application', 'Other'] : ['Application de bureau', 'Application Web', 'Application mobile', 'Autre'];
  this.listDepApp = this.lang === 'en' ? ['On premise', 'Saas'] : ['Sur site', 'Saas'];
  this.listStatusApp = this.lang === 'en' ? ['Enabled', 'Disabled'] : ['Activé', 'Désactivé'];
  this.stateOption = this.lang === 'en' ? ['Enabled', 'Disabled', 'all'] : ['Activé', 'Désactivé', 'tous'];
  this.dataLineageName = this.lang === 'en' ? 'new DataLineage' : 'nouvelle DataLineage';
  }


  private initChildForm() {
    return this.fb.group(
      {
        LineName: [
          null,
          Validators.compose([Validators.required])
        ],
        lineEffective: [
          new Date(),
          Validators.compose([Validators.required])
        ],
        enabledLine: [
          true,
          Validators.compose([Validators.required])
        ],
        owner:[
          null,
          Validators.compose([Validators.required])
        ],
        description: [
          null
        ],
      }
    );
  }

  private initLineForm() {
    return this.fb.group(
      {
        // source: [
        //   null,
        //   Validators.compose([Validators.required])
        // ],
        target: [
          null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }
  // private initFormProperty() {
  //   return  this.fb.group(
  //     {
  //       nodeEditText: [
  //         null
  //       ],
  //       nodeBackgroundColor: [
  //         null
  //       ],
  //       nodeTextColor: [
  //         null
  //       ]
  //     }
  //   );
  // }

  getImageFromType(type: string, color: string) {
    let imag = '';
    switch (type) {
      case 'store':
      case 'stock':
        imag = '<i class="fas fa-layer-group fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'access':
        imag = '<i class="fas fa-door-open fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'archive':
        imag = '<i class="fas fa-file-archive fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'collect':
        imag = '<i class="fas fa-tasks fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'control':
        imag = '<i class="fas fa-closed-captioning fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'destroy':
        imag = '<i class="fas fa-trash-alt fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'process':
      case 'treatment':
        imag = '<i class="fas fa-microchip fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'transfer':
        imag = '<i class="fas fa-exchange-alt fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'sign':
      case 'visa':
        imag = '<i class="fab fa-cc-visa fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'validate':
        imag = '<i class="fas fa-clipboard-check fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'start':
        imag = '<i class="fas fa-circle fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'end':
        imag = '<i class="far fa-circle fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'use':
        imag = '<i class="far fa-hand-holding fa-stack-1x fa-inverse fa-middle"></i>';
        break;
    }
    return imag;
  }

  addElement() {
    $('body').css('position', '');
    if (this.ngNodeText == '' || this.ngNodeText == null) {
      this.error = true;
      this.errorMessage = this.lang === 'en' ? 'You must provide a Short Description' : 'Vous devez fournir une description courte';
      return;
    }
    if (this.ngExtern) {
      if (this.ngDescription == '' || this.ngDescription == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide a Long Description' : 'Vous devez fournir une description longue';
        return;
      }
      if (this.ngCustomApplication == '' || this.ngCustomApplication == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide an External System' : 'Vous devez fournir un système externe';
        return;
      }
      if (this.customModelAndObjects.length == 0) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide at least one External detail' : 'Vous devez fournir au moins un détail externe';
        return;
      } else {
        for (let index = 0; index < this.customModelAndObjects.length; index++) {
          if (this.ngCustomModel[index] == null) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an External Model' : 'Vous devez fournir un modèle externe';
            return;
          }
          if (this.ngCustomObjects[index] == null) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an External Object' : 'Vous devez fournir un objet externe';
            return;
          }
          if (this.ngCustomTable[index]!=undefined){
            if (this.ngCustomTable[index].length > 0 && this.ngCustomsFields[index] == undefined) {
              this.error = true;
              this.errorMessage = this.lang === 'en' ? 'You must provide External Fields since External table was added' : 'Vous devez fournir des champs externes car une table externe a été ajoutée';

              return;
            }
          }
         if (this.ngCustomsFields[index]!= undefined){
           if (this.ngCustomTable[index] == undefined && this.ngCustomsFields[index].length > 0) {
             this.error = true;
             this.errorMessage = this.lang === 'en' ? 'You must provide External table since External fields was added' : 'Vous devez fournir une table externe car des champs externes ont été ajoutés';
             return;
           }
         }
        }
      }
    } else {
      // if (this.ngTypeApp == '' || this.ngTypeApp == null) {
      //   this.error = true;
      //   this.errorMessage = 'You must select the application type';
      //   return;
      // }
      if (this.ngApp == '' || this.ngApp == null) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must select the application' : 'Vous devez sélectionner l\'application';
        return;
      }
    if (this.ngCatalogue) {
     // console.log(this.ngCatalogue);
      if (this.modelAndObjectAndTableAndFields.length != 0) {
        for (let item = 0; item < this.modelAndObjectAndTableAndFields.length; item++) {
          if (this.ngModels[item] == undefined) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide a model' : 'Vous devez fournir un modèle';
            return;
          }
          if (this.ngObjects[item] == undefined) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an object' : 'Vous devez fournir un objet';
            return;
          }
          if (this.ngModels[item] != undefined && this.ngObjects[item] != undefined && this.ngTables[item] != undefined && this.ngFields[item] == undefined) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide at least one field since table is selected' : 'Vous devez fournir au moins un champ car une table est sélectionnée';
            return;
          }
          if (this.ngModels[item] != undefined && this.ngObjects[item] != undefined && this.ngTables[item] == undefined && this.ngFields[item] != undefined && this.ngFields[item].length>0) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide a table since fields is not empty' : 'Vous devez fournir une table car des champs ne sont pas vides';
            return;
          }
        }
      }
    } else {
      if (this.customModelAndObjects.length == 0) {
        this.error = true;
        this.errorMessage = this.lang === 'en' ? 'You must provide at least one External detail' : 'Vous devez fournir au moins un détail externe';
        return;
      } else {
        for (let index = 0; index < this.customModelAndObjects.length; index++) {
          if (this.ngCustomModel[index] == null) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide a model' : 'Vous devez fournir un modèle';
            return;
          }
          if (this.ngCustomObjects[index] == null) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an object' : 'Vous devez fournir un objet';
            return;
          }
          if (this.ngCustomTable[index].length > 0 && this.ngCustomsFields[index] == undefined) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide External Fields since External table was added' : 'Vous devez fournir des détails externes car une table externe a été ajoutée';
            return;
          }
          if (this.ngCustomTable[index] == undefined && this.ngCustomsFields[index].length > 0) {
            this.error = true;
            this.errorMessage = this.lang === 'en' ? 'You must provide an External table since External fields was added' : 'Vous devez fournir une table externe car des champs externes ont été ajoutés';
            return;
          }
        }
      }
    }
  }
    this.error = false;
    this.errorMessage = '';
    const data = [];
    const customs = [];
    if(!this.ngCatalogue){
      if(this.customModelAndObjects.length >0){
        this.customModelAndObjects.map(
          j => {
            const children = [];
            if(this.ngCustomTable[j]!= undefined){
              this.ngCustomTable[j].map(
                tab => {
                  const t = this.ngCustomsFields[j].filter( f => f.table == tab.name);
                  const t0 = [];
                  t.map( tt => t0.push({ id: this.create_UUID(), name: tt.name, description: tt.description }));
                  children.push({table: {id: this.create_UUID(), name: tab.name}, fields: t0});
                }
              );
            }
            customs.push({model : {id: this.create_UUID(), name: this.ngCustomModel[j]}, object: {id: this.create_UUID(), name: this.ngCustomObjects[j]}, children});
          }
        );
      }
    } else {
      if(this.modelAndObjectAndTableAndFields.length > 0){
       // console.log(this.modelAndObjectAndTableAndFields);
        this.modelAndObjectAndTableAndFields.map(
          i => {
            const children = [];
            if(this.ngTables[i]!=undefined){
              this.ngTables[i].map(
                tab => {
                  console.log(i);
                  console.log(tab);
                  console.log(this.ngFields[i]);
                  // if(this.editMode) {
                  //   for (let tbl of this.ngFields[i]) {
                  //     console.log(tbl);
                  //     if(!Array.isArray(tbl)){
                  //       const tbl1 = [];
                  //       tbl1.push(tbl);
                  //       tbl = tbl1;
                  //     }
                  //     for (const fld of tbl) {
                  //       const tb = fld.id.toString().split(':')[3];
                  //       console.log(fld);
                  //       console.log(tb, tab.name);
                  //       if (tb === tab.name) {
                  //        // const t0 = [];
                  //        // t0.push({id: fld.id, name: fld.name, description: fld.description});
                  //         children.push({table: {id: tab.id, name: tab.name}, fields: tbl});
                  //         break;
                  //       }
                  //     }
                  //   }
                  // }else{
                  //   const t = this.ngFields[i].filter( f => f.table == tab.name);
                  //   const t0 = [];
                  //   t.map( tt => t0.push({ id: tt.id, name: tt.name, description: tt.description }));
                  //   children.push({table: {id: tab.id, name: tab.name}, fields: t0});
                  // }
                  const t = this.ngFields[i].filter( f => f.table == tab.name);
                  const t0 = [];
                  t.map( tt => t0.push({ id: tt.id, name: tt.name, description: tt.description }));
                  children.push({table: {id: tab.id, name: tab.name}, fields: t0});
                }
              );
            }
            data.push({model: this.ngModels[i], object: this.ngObjects[i], children});
          }
        );
      }
    }
    // data.map( d => {
    //   const t = [];
    //   d.children.map(ch => {
    //    console.log(ch);
    //    if(ch.table.id === )
    //   });
    // });
    const dd = [];
    for (let k = 0; k < data.length; k++) {
      for (let l = 0; l < data[k].children.length; l++) {
        for (let z = 0; z < data[k].children.length; z++) {
          if(data[k].children[l]!= null && data[k].children[z]!= null) {
            if (data[k].children[l].table.id === data[k].children[z].table.id && l != z) {
              console.log(data[k].children[l]);
              if(data[k].children[l].fields != data[k].children[l].fields){
                data[k].children[l].fields = data[k].children[l].fields.concat(data[k].children[l].fields);
                data[k].children.splice(z,1);
              }
            }
          }
        }
      }
    }
  //  console.log(data);
    let id = this.create_UUID();
    console.log('created : ' + id);
    let style = null;
    let aux = null;
    if(this.editMode){
      id = $('#'  + $.escapeSelector(this.selectedId)).attr('id');
      style = $('#'  + $.escapeSelector(this.selectedId))[0].style.cssText;
      console.log('edit : ' + id);
     // console.log(this.edges);
      aux = this.edges;
      this.edges = [];
      aux.map(
        l => {
          l.element.remove();
        }
      );
      $('#'  + $.escapeSelector(this.selectedId)).remove();
      const index = this.nodesItems.findIndex(e => e.id === this.selectedId);
      this.nodesItems.splice(index, 1);
    }
   // console.log('après edit : ' + id);
   // console.log(data);
    // @ts-ignore
    const nodeItem = new NodeItem(id, this.choosenIcon, this.ngNodeText, this.ngApp, this.ngExtern, this.ngCatalogue, data, btoa(this.ngDescription), this.ngCustomApplication, 'black', customs, this.ngTypeApp, this.ngTrigger, 'action', this.offset);
    // console.log(nodeItem);
    // const that = this;
    this.addHtmlContent(nodeItem);
    if(style!= null){
      $('#'  + $.escapeSelector(this.selectedId))[0].style = style;
     // setTimeout(() => this.edges = [...t], 1000);
     // console.log(this.edges);
     // console.log(aux);
     // this.updateConnectors();
      aux.map(l => {
      if($('#' + $.escapeSelector(l.source) ).length > 0 && $('#' + $.escapeSelector(l.target) ).length > 0 && !l.hide) {
        $('#' + $.escapeSelector(l.source)).click();
        $('#' + $.escapeSelector(l.target)).click();
      }
      });
      $('#play-area').click();
     // console.log(aux);
     // console.log(this.edges);
    }
  }
  syncEdges(id: string){
    for (const edge of this.edges) {
      if(edge.source === id || edge.target === id){

      }
    }
  }
  getEdges(clicked: NodeItem) {
    this.clickedEdges = [];
    this.selectedEdges = [];
        if (clicked.data.length > 0){
          clicked.data.map(
            d => {
              const startObject = 'start_' + d.object.id;
              const endObject = 'end_' + d.object.id;
              const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
              const objectEdgesParent = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == false);
              if (objectEdges.length > 0){
                this.clickedEdges = this.clickedEdges.concat(objectEdges);
              }
              if (objectEdgesParent.length > 0){
                this.selectedEdges = this.selectedEdges.concat(objectEdgesParent);
              }
              if(d.children.length > 0){
                d.children.map(
                  t => {
                    t.fields.map(
                      f => {
                        const startField = 'start_' + f.id;
                        const endField = 'end_' + f.id;
                        const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == true);
                        const fieldEdgesParent = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == false);
                        if (fieldEdges.length > 0){
                          this.clickedEdges = this.clickedEdges.concat(fieldEdges);
                        }
                        if (fieldEdgesParent.length > 0){
                          this.selectedEdges = this.selectedEdges.concat(fieldEdgesParent);
                        }
                      }
                    );
                  }
                );
              }
            }
          );
        }
        if(clicked.customs.length > 0){
          clicked.customs.map(
            d => {
              const startObject = 'start_' + d.object.id;
              const endObject = 'end_' + d.object.id;
              const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
              const objectEdgesParent = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == false);
              if (objectEdges.length > 0){
                this.clickedEdges = this.clickedEdges.concat(objectEdges);
              }
              if (objectEdgesParent.length > 0){
                this.selectedEdges = this.selectedEdges.concat(objectEdgesParent);
              }
              if(d.children.length > 0){
                d.children.map(
                  t => {
                    t.fields.map(
                      f => {
                        const startField = 'start_' + f.id;
                        const endField = 'end_' + f.id;
                        const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == true);
                        const fieldEdgesParent = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField) && e.parent == false);
                        if (fieldEdges.length > 0){
                          this.clickedEdges = this.clickedEdges.concat(fieldEdges);
                        }
                        if (fieldEdgesParent.length > 0){
                          this.selectedEdges = this.selectedEdges.concat(fieldEdgesParent);
                        }
                      }
                    );
                  }
                );
              }
            }
          );
        }
      //  console.table(this.clickedEdges);
    }

  enableLinking() {
    const that = this;
    $('.connector').off().on('click', function(e) {
      e.stopPropagation();
      if (that.startConnector == null) {
        that.startConnector = $(this).attr('id');
        that.startName = $(this).attr('data-name');

        if ($(this).parent().hasClass('lg-start') || $(this).parent().hasClass('lg-end') || $(this).parent().hasClass('lg-aggregate') || $(this).parent().hasClass('lg-condition')){
          if ($(this).parent().hasClass('lg-start')){that.startColor = 'green'; }
          if ($(this).parent().hasClass('lg-end')){that.startColor = 'red'; }
          if ($(this).parent().hasClass('lg-aggregate')){that.startColor = 'blue'; }
          if ($(this).parent().hasClass('lg-condition')){that.startColor = 'blue'; }
          that.startParent = that.startConnector;
        } else {
          that.startColor = $(this).parent().parent().parent().parent().attr('data-color');
          if ($(this).hasClass('start')) {
            that.startParent = $(this).parent().parent().prev('li').prev('li').find('.start').attr('id');
          }
          if ($(this).hasClass('end')) {
            that.startParent = $(this).parent().parent().prev('li').prev('li').find('.end').attr('id');
          }
        }
        // object circle connector has been clicked
        if($(this).hasClass('object-line')){
          that.startColor = $(this).parent().parent().parent().attr('data-color');
          that.startParent = that.startConnector;
        }
        return;
      }
      if (that.endConnector == null) {
        that.endConnector = $(this).attr('id');
        that.endName = $(this).attr('data-name');
        if ($(this).parent().hasClass('lg-start') || $(this).parent().hasClass('lg-end') || $(this).parent().hasClass('lg-aggregate') || $(this).parent().hasClass('lg-condition')) {
          if ($(this).parent().hasClass('lg-start')) {
            that.endColor = '#11998e';
          }
          if ($(this).parent().hasClass('lg-end')) {
            that.endColor = '#D31027';
          }
          if ($(this).parent().hasClass('lg-aggregate')) {
            that.endColor = '#00c6ff';
          }
          if ($(this).parent().hasClass('lg-condition')) {
            that.endColor = '#00c6ff';
          }
          that.endParent = that.endConnector;
        } else {
          that.endColor = $(this).parent().parent().parent().parent().attr('data-color');
          if ($(this).hasClass('start')) {
            that.endParent = $(this).parent().parent().prev('li').prev('li').find('.start').attr('id');
          }
          if ($(this).hasClass('end')) {
            that.endParent = $(this).parent().parent().prev('li').prev('li').find('.end').attr('id');
          }
        }
        // object circle connector has been clicked
        if($(this).hasClass('object-line')){
          that.endColor = $(this).parent().parent().parent().attr('data-color');
          that.endParent = that.endConnector;
        }
//        console.log(that.startConnector, that.endConnector, that.startParent, that.endParent);
        // that.drawConnector(that.startConnector, that.endConnector);
        if ( that.startConnector != null && that.endConnector != null && that.startParent != null && that.endParent != null) {
       //   console.log(that.startConnector, that.endConnector, that.startParent, that.endParent);
          const groupId = that.create_UUID();
          const line = that.addLine(that.startConnector, that.endConnector, that.startColor, that.endColor, false, true, that.startName, that.endName, groupId);
          const parentLine = that.addLine(that.startParent, that.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
          const leftLine = that.addLine(that.startConnector, that.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
          const rightLine = that.addLine(that.endConnector, that.startParent, that.endColor, that.startColor, true, false, that.startName, that.endName, groupId);
          that.linesArch.push({line, parent: parentLine, source: that.startParent, target: that.endParent, leftParent: leftLine, rightParent: rightLine, groupId, start: that.startConnector, end : that.endConnector});
          that.startConnector = null;
          that.endConnector = null;
        }
      }
    });
    }

  removeElement(id) {
    $('#' + id).remove();
  }

  changeNodeStyle() {

  }
getFields(obj, fields) {
    let htm = '';
    fields.map(
      field => {
        htm += '<li class="list-group-item"><i class="fas fa-circle connector start static field-line" id="start_' + field.id + '" data-parent=" ' + obj + '" data-name="' + field.name + '"></i>  &nbsp; <span style="color: #4e4ebb;text-transform: capitalize"><i class="fas fa-receipt"></i>&nbsp;' + field.name + '</span>&nbsp;<i class="fas fa-circle connector end child-line" id="end_' + field.id + '" data-parent=" ' + obj + '" data-name="' + field.name + '"></i></li>'; // <i class="fas fa-circle connector end line-handle"></i></li>';
      }
    );
    return htm;
}
  changeElement(opt: string) {

  }

  addHtmlElement() {
  }

  makeDraggable(element) {
    // const draggable = Draggable.create('#'  + $.escapeSelector(element), {
    //   bounds: document.getElementById('play-area')
    // } );
     const that = this;
     const draggable =
      new PlainDraggable(document.getElementById(element), { // eslint-disable-line no-new
        onMove() {
          // that.clicked = that.nodesItems.find(item => item.id = element);
          // console.log(that.clicked);
           that.updateConnectors();
           $('.lg-start').css('width', '52px!important');
        },
        containment: document.getElementById('#play-area'),
        leftTop: true// ,
        // handle: document.querySelector('#'+element+'>span')
      });
     this.draggables.push({element: draggable, id: element});
  }

  updateEdges(id) {
    // if(this.edges.length>0){
    // this.edges.map(item => {
    //   item.element.remove();
    //   const newLine = new LeaderLine(
    //     document.getElementById(item.source),
    //     document.getElementById(item.target),
    //     {
    //       endPlug: 'arrow3',
    //       color: 'rgba(226, 204, 196, 0.5)'
    //     }
    //   );
    //   this.edges = this.edges.filter(el => el.element != item.element && el.target!=item.element && el.source!=el.element);
    //   this.edges.push({element: newLine, source: item.source, target: item.target});
    // })}
    const oldLinks = this.edges.filter(el => el.source == id || el.target == id);
    if (oldLinks.length > 0) {
      const voids = oldLinks.map(e => {
        if (e.element) {
          // e.element.remove();
          // this.edges = this.edges.filter(el => el.element != e.element && el.target!=e.element && el.source!=el.element);
          // this.addLine(e.source, e.target);
          e.element.position();
          // const newLine = new LeaderLine(
          //   LeaderLine.pointAnchor(document.getElementById(e.source), {x: '100%', y: '100%'}),
          //   LeaderLine.pointAnchor(document.getElementById(e.target), {x: '100%', y: '100%'}),
          //   {
          //     endPlug: 'arrow3',
          //     color: 'rgba(222,203,197,0.5)',
          //     path: 'grid'
          //   },
          //   {dropShadow: true}
          // );
          //
          // this.edges.push({element: newLine, source: e.source, target: e.target});
        }
      });
    }
  }

  addLine(start, end , startColor, endColor, hideLine, parent, startName, endName, group) {
    const that = this;
   // console.log(start, end);
    const newLine = new LeaderLine(
      document.getElementById(start),
      document.getElementById(end),
      {
        endPlug: 'arrow3',
        startPlug: 'none',
        endPlugColor: endColor,
        startPlugColor: startColor,
        gradient: true,
        path: 'fluid',
        // startSocket: 'right',
        // endSocket: 'left',
        hide: hideLine
        // dropShadow: {dx: 0, dy: 7}
      }
    );
    $('body>.leader-line:last-of-type').attr('data-source', start).attr('data-target', end).on('click', function(e) {
     e.stopPropagation();
     const source = $(this).attr('data-source');
     const target = $(this).attr('data-target');
     const line = that.edges.find(e => e.source == source && e.target == target);
     line.element.outline = true;
     line.element.setOptions({
       outlineColor: 'rgb(1,7,12)'
     });
    });
    this.edges.push({element: newLine, source: start, target: end, sourceName: startName, targetName: endName, group , parent, hide: hideLine, startColor, endColor});
    return newLine;
  }

  create_UUID() {
    let dt = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }

  getApplicationTypes() {
    this.loadingApplicationType = true;
    this.dataLineService.getApplicationTypes().subscribe(
      d => {
        this.applicationTypes = d.data;
        this.loadingApplicationType = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getApplicationsByType(external = 'NO') {
    external == 'NO' ? this.loadingApplication = true : this.loadingApplicationExterne = true;
    this.ngApp = null;
    let type;
    this.ngTypeApp == null ? type = 'false' : type = this.ngTypeApp.name;
    this.dataLineService.getApplicationsByType(type, external).subscribe(
      d => {
        if(external == 'NO'){
          this.applications = d.data;
          this.loadingApplication = false;
        } else {
          this.externalApplications = d.data;
          this.loadingApplicationExterne = false;
        }
      },
      e => {
        console.error(e);
      }
    );
  }

  getModelsByApp() {
    this.loadingModel = true;
    this.models = [];
    this.objects = [];
    this.tables = [];
    this.fields = [];
    let d = this.ngApp.id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getModelsByApp(d).subscribe(
      d => {
       // this.models[0] = d.data;
        for (let i = 0; i <= this.j; i++) {
          this.models[i] = d.data;
        }
        this.loadingModel = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getObjectsByModel(i: number) {
    this.loadingObject[i] = true;
    this.ngObjects[i] = null;
    let d = this.ngModels[i].id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getObjectsByModel(d).subscribe(
      d => {
        this.objects[i] = d.data;
        this.loadingObject[i] = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getTablesByObject(i: number) {
    this.loadingTable[i] = true;
    this.ngTables[i] = null;
    // console.log(this.ngObjects[i].id);
    // return;
    let d = this.ngObjects[i].id.replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getTablesByObject(d).subscribe(
      d => {
        this.tables[i] = d.data;
        this.loadingTable[i] = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  getFieldsByTable(i: number) {
    this.loadingField[i] = true;
    this.ngFields[i] = null;
   // console.log(this.ngTables[i]);
    if (this.ngTables[i] == null) { return; }
    let d = this.ngTables[i].replace(/\//g, '__________');
    d = d.replace(/&/g, '!!!!!!!!!!');
    this.dataLineService.getFieldsByTable(d).subscribe(
      d => {
        this.fields[i] = d.data;
        this.loadingField[i] = false;
      },
      e => {
        console.error(e);
      }
    );
  }

  setCatalogue() {
    // this.addNodeFrm.patchValue({catalogue: !this.addNodeFrm.value['extern']})
      this.ngCatalogue = !this.ngExtern;
      this.resetModelObjectTableFields();
      this.resetCustomModelObject();
  }

  setCustomValue() {
    if (this.ngCatalogue) {
      this.i = 0;
      this.modelAndObjectAndTableAndFields = [];
      this.modelAndObjectAndTableAndFields.push(this.i);
      this.resetCustomModelObject();
    } else {
      this.j = 0;
      this.customModelAndObjects = [];
      this.customModelAndObjects.push(this.j);
      this.resetModelObjectTableFields();
    }
  }

  getObjectById(id: string) {
    const o = this.objects.filter(item => item.id == id);
    if (o.length > 0) {
      return o[0].name;
    }
  }

  showAddLineageModal() {
    this.addNodeFrm.reset();
    this.addNodeFrm.patchValue({customModel: 'null', customObject: 'null', extern: false, catalogue: true});
    // this.addNodeFrm.patchValue({extern: false, catalogue: true});
    // $('#extern').prop('checked', false).change();
    // $('#catalogue').prop('checked', true).change();
    $('#addLineageModal').modal();
  }
  initConnectors() {
    const ends = [];
    const connectors = document.getElementsByClassName('line-handle');
    Array.from(connectors).forEach(function(start, index) {
      const end = start.previousElementSibling;
      const line = new LeaderLine(start, end, {
        startPlug: 'none',
        startPlugColor: '#31b7f9',
        endPlugColor: '#a6dffc',
        gradient: true,
        path: 'fluid',
        startSocket: 'right',
        endSocket: 'left'
      });

      const draggable = new PlainDraggable(end, {
        onMove() {
          line.position();
        },
        onMoveStart() {
          line.dash = {animation: true};
        },
        onDragEnd() {
          line.dash = false;
        },
        autoScroll: true
      });
      ends.push(draggable);
    });
  }

  updateContainer(event) {
    $('body').css('position', '');
    const that = this;
    if (event.stopPropagation) {
      event.stopPropagation(); // stops the browser from redirecting.
    }
    event.preventDefault();
   // console.log(event);
    const id = event.dataTransfer.getData('text');
    if (id == 'lg-start'){
      const newId = this.create_UUID();
      const connectors = [];
      connectors.push({id: 'start_' + newId});
      connectors.push({id: 'end_' + newId});
      const extraItem = new ExtraItem(newId, '', connectors, 'start', {top:  event.clientY , left: event.clientX}, this.ngTriggerExtra, this.ngDataLinesExtra);
      this.addExtraHtml(extraItem);
      return;
    }
    if (id == 'lg-end'){
      const newId = this.create_UUID();
      const connectors = [];
      connectors.push({id: 'start_' + newId});
      connectors.push({id: 'end_' + newId});
      const extraItem = new ExtraItem(newId, '', connectors, 'end', {top:  event.clientY, left: event.clientX}, null, null);
      this.addExtraHtml(extraItem);
      window.scrollBy(5, 100);
      window.scrollBy(-5, -100);
      return;
    }
    if (id == 'lg-aggregate'){
      const newId = this.create_UUID();
      const connectors = [];
      connectors.push({id: 'start_' + newId});
      connectors.push({id: 'end_' + newId});
      const extraItem = new ExtraItem(newId, '', connectors, 'aggregate', {top:  event.clientY, left: event.clientX}, null, null);
      this.addExtraHtml(extraItem);
      window.scrollBy(5, 100);
      window.scrollBy(-5, -100);
      return;
    }
    if (id == 'lg-condition') {
      const newId = this.create_UUID();
      const connectors = [];
      connectors.push({id: 'start_' + newId});
      connectors.push({id: 'end_' + newId});
      connectors.push({id: 'input_' + newId});
      const extraItem = new ExtraItem(newId, '', connectors, 'condition', {top:  event.clientY, left: event.clientX}, null, null);
      this.addExtraHtml(extraItem);
      window.scrollBy(5, 100);
      window.scrollBy(-5, -100);
      return;
    }
    this.startScriptUP();
   // this.choosenIcon = type;
    this.ngExtern = false;
    this.ngCatalogue = true;
    this.ngApp = null;
    this.ngTypeApp = null;
    this.ngNodeText = null;
    this.ngDescription = null;
    this.models = [];
    this.tables = [];
    this.fields = [];
    this.ngModels = [];
    this.ngObjects = [];
    this.ngTables = [];
    this.ngFields = [];
    this.ngCustomApplication = null;
    this.ngCustomModel = [];
    this.ngCustomTable = [];
    this.ngCustomObjects = [];
    this.ngCustomsFields = [];
    this.j = 0;
    this.modelAndObjectAndTableAndFields = [];
    this.modelAndObjectAndTableAndFields.push(0);
    this.i = 0;
    this.customModelAndObjects = [];
    this.customModelAndObjects.push(0);
    this.ngDescription = '';
    this.editMode = false;
    this.getApplicationsByType( 'NO');
    this.getApplicationsByType( 'YES');
    this.changement = false;
    $('#addLineageModal').modal('show');
    return false;
  }

  allowEvent(event) {
    event.preventDefault();
  }

  getIconForModal(type: string) {
    return '<i class="fas fa-circle fa-stack-2x fa-blue" style="color: rgb(60 172 196);vertical-align: middle;"></i>' +
      this.getImageFromType(type, '');
  }

  dragStart(event: DragEvent) {
    $(event.target).css('opacity', '0.4');
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.dropEffect = 'move';
  }

  dragEnd(event: DragEvent, type: string) {
    $(event.target).css('opacity', 1);
    this.choosenIcon = type;
    this.offset = {top:  event.clientY , left: event.clientX};
  }
  requiredByExtern() {
    this.addNodeFrm.get('extern').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('nodeText').updateValueAndValidity();
        this.addNodeFrm.get('description').updateValueAndValidity();
      });
  }

  requiredByCatalogue() {
    this.addNodeFrm.get('catalogue').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('appType').updateValueAndValidity();
        this.addNodeFrm.get('app').updateValueAndValidity();
        this.addNodeFrm.get('model').updateValueAndValidity();
        this.addNodeFrm.get('objects').updateValueAndValidity();
      });
  }

  requiredByTable() {
    this.addNodeFrm.get('table').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('fields').updateValueAndValidity();
      });
  }

  requiredByCustomModel() {
    this.addNodeFrm.get('catalogue').valueChanges
      .subscribe(value => {
        this.addNodeFrm.get('customModel').updateValueAndValidity();
        this.addNodeFrm.get('customObject').updateValueAndValidity();
      });
  }

  addCustomModelObject() {
    this.i++;
    this.customModelAndObjects.push(this.i);
  }

  resetCustomModelObject() {
    this.i = 0;
    this.customModelAndObjects = [];
    this.customModelAndObjects.push(0);
    this.ngCustomApplication = null;
    this.ngCustomModel = [];
    this.ngCustomObjects = [];
    this.ngCustomTable = [];
    this.ngCustomsFields = [];
  }

  removeCustomModelObject(i: number) {
    this.customModelAndObjects.splice(i, 1);
    this.ngCustomModel[i] = null;
    this.ngCustomObjects[i] = null;
    this.ngCustomsFields[i] = null;
  }

  // dynamic model, object, table ,fields
  addModelObjectTableFields() {
    this.j++;
    this.models[this.j] = this.models[this.j - 1];
    this.modelAndObjectAndTableAndFields.push(this.j);
    this.getModelsByApp();
  }

  resetModelObjectTableFields() {
    this.j = 0;
    this.modelAndObjectAndTableAndFields = [];
    this.modelAndObjectAndTableAndFields.push(0);
    this.ngModels = [];
    this.ngObjects = [];
    this.ngTables = [];
    this.ngFields = [];
    this.objects = [];
    this.tables = [];
    this.fields = [];
  }

  removeModelObjectTableFields(i: number) {
    this.modelAndObjectAndTableAndFields.splice(i, 1);
    this.ngModels.splice(i, 1);
    this.ngObjects.splice(i, 1);
    this.ngTables.splice(i, 1);
    this.ngFields.splice(i, 1);
    this.objects.splice(i, 1);
    this.tables.splice(i, 1);
    this.fields.splice(i, 1);
  }

  async startScriptUP() {
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      this.loadData();
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      // this.loadData();
      const that = this;

      $('#addApp').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,


      })
        .on('change', function(e, date) {
          const dateapp = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          that.registerFormApp.get('dateApp').patchValue(dateapp);
        });
    }).catch(error => console.error(error));
  }

  private loadData() {
    // definition editor
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.ngDescription) {
      CKEDITOR.instances.ngDescription.destroy(true);
    }
    const editorDescription = CKEDITOR.replace('ngDescription');
    editorDescription.config.height = 60;
    editorDescription.on( 'change', evt => {
      this.ngDescription = evt.editor.getData();
    });
  }
   addCustomTable = (term) => ({id: term, name: term});

  addCustomField = (term) => ({id: term, name: term});
  // removeTable = (event, i) => {
  //   this.customTable[i].splice(this.customTable[i].indexOf(e => e.id == event.id), 1);
  // }
  removeField = (event, i) => {
   // this.customFields[i].splice(this.customFields[i].indexOf(e => e.id == event.id), 1);
  }
  // drawConnector(a, b) {
  //   const divA  = document.getElementById(a);
  //   const divB  = document.getElementById(b);
  //   const arrowLeft = document.getElementById('arrowLeft');
  //   console.log(divA, divA, arrowLeft);
  //   const posnALeft = {
  //     x: divA.offsetLeft - 8,
  //     y: divA.offsetTop  + divA.offsetHeight / 2
  //   };
  //   const posnARight = {
  //     x: divA.offsetLeft + divA.offsetWidth + 8,
  //     y: divA.offsetTop  + divA.offsetHeight / 2
  //   };
  //   const posnBLeft = {
  //     x: divB.offsetLeft - 8,
  //     y: divB.offsetTop  + divB.offsetHeight / 2
  //   };
  //   const posnBRight = {
  //     x: divB.offsetLeft + divB.offsetWidth + 8,
  //     y: divB.offsetTop  + divB.offsetHeight / 2
  //   };
  //   const dStrLeft =
  //     'M' +
  //     (posnALeft.x      ) + ',' + (posnALeft.y) + ' ' +
  //     'C' +
  //     (posnALeft.x - 100) + ',' + (posnALeft.y) + ' ' +
  //     (posnBLeft.x - 100) + ',' + (posnBLeft.y) + ' ' +
  //     (posnBLeft.x      ) + ',' + (posnBLeft.y);
  //   console.log(dStrLeft);
  //   arrowLeft.setAttribute('d', dStrLeft);
  // }
  // wrapperPosition() {
  //   const lineWrapper = document.getElementById('line-wrapper');
  //   lineWrapper.style.transform = 'none';
  //   const rect = lineWrapper.getBoundingClientRect();
  //   lineWrapper.style.transform = 'translate(' +
  //     (-(rect.left + pageXOffset)) + 'px, ' +
  //     (-(rect.top + pageYOffset)) + 'px)';
  // }
  updateConnectors() {
    try
    {
      this.edges.map(
        item => {
          if($('#' + $.escapeSelector(item.element.source)) != null && $('#' + $.escapeSelector(item.element.target)) != null){
            item.element.position();
          }
          $('.lg-start').css('width', '52px');
        }
      );
    }
    catch(e)
    {//console.error(e);
      }

  }

  getTables(obj, children) {
    let html = '';
    children.map(
     t => {
      // console.log(t);
       html += '<li class="list-group-item" style="float: left;"><b style="color: blue;text-transform: capitalize;"><i class="fas fa-table"></i>&nbsp;' + t.table.name + '</b>&nbsp;</li>' +
       this.getFields(obj, t.fields);
     }
   );
    return html;
  }

  ngOnDestroy(): void {
    this.edges.map(e => { e.element.remove();});
    $('.row').css('flex-flow', 'row wrap');
    this.subscription.unsubscribe();
   // clearInterval(this.interval);
  }

  collapse(collapse: string, event) {
    $('#' + collapse).collapse('toggle');
    if ($(event.target).hasClass('fa-minus')) {
      $(event.target).removeClass('fa-minus').addClass('fa-plus');
    } else {
      $(event.target).removeClass('fa-plus').addClass('fa-minus');
    }
  }
  getApplicationTypeIcon(app, custom) {
    let icon = '';
	let type = app!=null? app.name: custom.name;
    switch (type) {
      case 'Desktop application': icon = '<i class="fas fa-desktop" title="System type ' + type + '"></i>'; break;
      case 'Web application': icon = '<i class="fab fa-chrome" title="System type ' + type + '"></i>'; break;
      case 'Mobile application': icon = '<i class="fas fa-mobile-alt" title="System type ' + type + '"></i>'; break;
      default: icon = '<i class="fas fa-info" title="System type ' + type + '"></i>'; break;
    }
    return icon;
  }
  changePattern(cl) {
    this.pattern = cl;
    if (this.prevPattern != 'none') {
      $('#play-area').removeClass(this.prevPattern);
    }
    $('#play-area').addClass(this.pattern);
    this.prevPattern = this.pattern;
  }

  changeComplete(event: ColorEvent) {
    let style = $('#play-area').attr('style');
    style = style.split('background-color');
    if(event != null){
      style[0] += 'background-color:' + event.color.hex + ' !important;';
      $('#play-area').attr('style', style);
      this.patternColor = event;
    }
  }
  getHeaderStyle() {
    if (this.flatDesign) {
      return  '<div class="card-header text-center text-white node-header"  style="background-color: ';
    } else {
      return  '<div class="card-header text-center text-white node-header header-opaque"  style="background-color: ';
    }
  }
  getDetailsStyle() {
    if (this.flatDesign) {
      return '      <ul class="list-group list-group-flush text-black node-details flat-details" id="';
    } else {
      return '      <ul class="list-group list-group-flush text-black node-details opaque-details" id="';
    }
  }
  getFooterStyle() {
    if (this.flatDesign) {
      return '      <div class="card-body tools-line tools-line-flat">\n';
    } else {
      return '      <div class="card-body tools-line tools-line-opaque">\n';
    }
  }
  getNodeStyle() {
    if (this.flatDesign) {
      return '  <div class="card node-item" style="width: 170px;background: transparent;font-size:10px;" data-color="';
    } else {
      return '  <div class="card node-item shadow-design" style="width: 170px;background: transparent;font-size:10px;" data-color="';
    }
  }
  changeFlatStyle(event) {
   // console.log(event);
    const checked = $(event.target).prop('checked');
    if (checked) {
      this.flatDesign = true;
      if ($('.node-item').length > 0) {
        $('.node-item').removeClass('shadow-design');
      }
      // // header card
      // if ($('.node-header').length > 0) {
      //   $('.node-header').removeClass('header-opaque');
      // }
      // // model,object, tab,fields
      // if ($('.node-details').length > 0) {
      //   $('.node-details').removeClass('opaque-details').addClass('flat-details');
      // }
      // // footer node delete, edit
      // if ($('.tools-line').length > 0) {
      //   $('.tools-line').removeClass('tools-line-opaque').addClass('tools-line-flat');
      // }
    } else {
      this.flatDesign = false;
      if ($('.node-item').length > 0) {
        $('.node-item').addClass('shadow-design');
      }
      // //  header card
      // if ($('.node-header').length > 0) {
      //   $('.node-header').addClass('header-opaque');
      // }
      // // model,object, tab,fields
      // if ($('.node-details').length > 0) {
      //   $('.node-details').removeClass('flat-details').addClass('opaque-details');
      // }
      // // footer node delete, edit
      // if ($('.tools-line').length > 0) {
      //   $('.tools-line').removeClass('tools-line-flat').addClass('tools-line-opaque');
      // }
    }
  }
  collapseMenuDetect() {
    console.log('test');
  }
  startEndDrag(event) {

  }
  dragstart_handler(ev) {
  //  console.log('dragStart');
    // Change the source element's background color to signify drag has started
   // ev.currentTarget.style.border = 'dashed';
    // Add the id of the drag source element to the drag data payload so
    // it is available when the drop event is fired
    ev.dataTransfer.setData('text', ev.target.id);
    // Tell the browser both copy and move are possible
    ev.effectAllowed = 'copy';
  }
  dragover_handler(ev) {
  //  console.log('dragOver');
    // Change the target element's border to signify a drag over event
    // has occurred
    //ev.currentTarget.style.background = 'lightblue';
    ev.preventDefault();
  }
  drop_handler(ev) {
  //  console.log('Drop');
    ev.preventDefault();
   // console.log(ev);
    // Get the id of drag source element (that was added to the drag data
    // payload by the dragstart event handler)
    const id = ev.dataTransfer.getData('text');
    // Only Move the element if the source and destination ids are both "move"
    if (id == 'src_move' && ev.target.id == 'dest_move') {
      ev.target.appendChild(document.getElementById(id));
    }
    // Copy the element if the source and destination ids are both "copy"
    if (id == 'src_copy' && ev.target.id == 'dest_copy') {
      const nodeCopy = document.getElementById(id).cloneNode(true);
      // @ts-ignore
      //nodeCopy.id = 'newId';
      // @ts-ignore
      nodeCopy.removeAttribute('draggable');
      // @ts-ignore
      nodeCopy.removeAttribute('id');
      ev.target.appendChild(nodeCopy);
    }
  }

  dragend_handler(ev) {
   // console.log('dragEnd');
    // Restore source's border
   // ev.target.style.border = null;
    // Remove all of the drag data
    ev.dataTransfer.clearData();
  }
  groupBy(key) {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  }
  groupByModel(array) {
      return array.reduce((acc, obj) => {
        const property = obj['model'].id;
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
  }
  getNodeData(clicked) {
    this.treeData = this.unifyObject(clicked);
   // console.log(this.treeData);
    this.orgData = [];
    const treeParent = {id: 1, name : clicked.text, children : [] , expanded: true};
    let orgIndex = 2;
    // tree node
    this.treeData.map(
      m => {
        const orgModeNode = { id: orgIndex, name:  m.model.name, title: 'treeModel', children: [] , className: 'treeModel', icon: '<i class="fas fa-server"></i>', expanded: true};
        //object loop
        m.data.map(
          det => {
            orgIndex++;
            const orgObjNode = { id: orgIndex , name:  det.object.name, title: 'treeObject', children: [], className: 'treeObject', icon: '<i class="fas fa-vector-square"></i>', expanded: true};
            // table loop
            det.children.map(
              t => {
                orgIndex++;
                const orgTabNode = { id: orgIndex , name:  t.table.name, title: 'treeTable', children: [], className: 'treeTable', icon: '<i class="fas fa-table"></i>', expanded: true };
                // fields loop
                t.fields.map(
                  f => {
                    orgIndex++;
                    const orgFieldNode = { id: orgIndex , name:  f.name, title: 'treeField' , className: 'treeField', icon: '<i class="fas fa-receipt"></i>', expanded: true};
                    orgTabNode.children.push(orgFieldNode);
                  }
                );
                orgObjNode.children.push(orgTabNode);
              }
            );
            orgModeNode.children.push(orgObjNode);
          }
        );
        treeParent.children.push(orgModeNode);
      }
    );
    this.orgData.push(treeParent);
  }
  zoomIn() {
   /* if (this.zoom < 3) {
      this.zoom += 0.2;
      $('#play-area').css('zoom', this.zoom);
      $('.leader-line').css('zoom', this.zoom);
      this.updateConnectors();
    }*/
    if (this.zoom < 1.4) {
      this.zoom += 0.2;
      $('.node-item').css('transform', 'scale(' + this.zoom + ')');
      this.updateConnectors();
    }
  }
  zoomOut() {
    if (this.zoom > 0.5) {
      this.zoom -= 0.2;
      // $('#play-area').css('zoom', this.zoom);
      // $('.leader-line').css('zoom', this.zoom);
      $('.node-item').css('transform', 'scale(' + this.zoom + ')');
      this.updateConnectors();
    }
  }
  zoomReset() {
      this.zoom = 1;
      $('.node-item').css('transform', 'scale(' + this.zoom + ')');
      this.updateConnectors();
  }

  private initAppForm() {
    return this.fb.group({
      nameApp: ['', Validators.compose([Validators.required])],
      dateApp: [''],
      versionApp: [''],
      statusApp: true,
      external: [{value: true, disabled: true }],
      typeApp: [''],
      deploymentApp: [''],
      descApp: [''],
      moduleApp: ['', Validators.compose([Validators.required])],
      descMod: [''],
      createdAt: [''],
      createdBy: [''],
      updatedAt: [''],
      updatedBy: [''],
      manager: ['', Validators.compose([Validators.required])],
      owner: [''],

      applicationManager: ['']
    });
  }
  onGetHolders() {
    this.holderLoading = true;
    this.dataLineService.getHolders().subscribe(
      data => {
        this.stackHolders = data.data;
        this.holderLoading = false;
      }
    );
  }
  groupByFn = (item) => item.stack.role;
  groupValueFn = (_: string, children: any[]) => ({ name: children[0].stack.role, total: children.length });
  onClose() {
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.registerFormApp.get('nameApp').patchValue('');
    this.registerFormApp.get('dateApp').patchValue('');
    this.registerFormApp.get('applicationManager').patchValue('');
    this.registerFormApp.get('versionApp').patchValue('');
    this.registerFormApp.get('statusApp').patchValue(true);
    this.registerFormApp.get('external').patchValue(true);
    this.registerFormApp.get('typeApp').patchValue('');
    this.registerFormApp.get('deploymentApp').patchValue('');
    this.registerFormApp.get('descApp').patchValue('');
    this.registerFormApp.get('moduleApp').patchValue('');
    this.registerFormApp.get('descMod').patchValue('');
    this.registerFormApp.get('manager').patchValue('');
  }
  checkAppnameAdd() {
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.dataLineService.checkApp(this.registerFormApp.get('nameApp').value).subscribe(
      d => {
        // @ts-ignore
        if (d.response.indexOf('taken') != -1) {this.presubmitAdd = true; this.messagedangerAdd = this.registerFormApp.controls['nameApp'].value + ' exist choose another application !'; } else {this.presubmitAdd = false; this.messagesuccesAdd = this.registerFormApp.controls['nameApp'].value + ' is available !';}},
      e => {console.error(e); }
    );
  }
  addSystem() {
    const desc = window.btoa(encodeURIComponent(this.registerFormApp.get('descApp').value));
    this.registerFormApp.get('descApp').patchValue(desc);
    const username = localStorage.getItem('username');
    this.registerFormApp.get('createdBy').patchValue(username);
    this.dataLineService.addSystem(JSON.stringify(this.registerFormApp.value)).subscribe(
      d => {
        this.Toast.fire({
          icon: 'success',
          title: this.lang === 'en' ? 'Added in successfully' : 'Ajouté avec succès'
         });       
        $('#addSystem').modal('hide');
        this.getApplicationsByType('YES');
      },
        err => {console.error(err); }
    );
  }

  addExternalSystem() {
    this.onClose();
    $('#addSystem').modal();
  }

  openTableField(i) {
    const that=this;
    this.tableModalValue = [];
    this.customModalFields = [];
    if(this.ngTables[i]!=undefined){
      $('#addTableFieldTitle').html(that.lang == 'en'?'Edit Table and Fields': 'Modifier la table et les champs');
    }
    this.error1 = false;
    this.getTablesByObjectModal(i);
    this.tableFieldIndex = i;
    $('#addTableField').modal();
  }
  addTableField() {
    let data = [];
    for( let i = 0; i < this.tableModalValue.length; i++){
      if (this.customModalFields[i] == undefined){
this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.tableModalValue[i].name : 'Vous devez fournir des champs pour la table ' + this.tableModalValue[i].name;
this.error1 = true; 
return;    
  }
     if (this.customModalFields[i].length == 0){
      this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.tableModalValue[i].name : 'Vous devez fournir des champs pour la table ' + this.tableModalValue[i].name;
      this.error1 = true;
      return;
           }
    }
    this.ngTables[this.tableFieldIndex] = this.tableModalValue;
    this.tableModalValue.map( (table, i) => {
     data = data.concat(this.customModalFields[i]);
    });
    this.ngFields[this.tableFieldIndex] = data;
    $('#addTableField').modal('hide');
    this.tableModalValue = [];
    this.customModalFields = [];
    this.changement = true;
  }
  getFieldsByTableModal() {
  //  console.log(this.tableModalValue);
    this.tableModalValue.map(
      (table, i) => {
        this.loadingField[i] = true;
        if(this.customModalFields[i] == null){
          this.customModalFields[i] = [];
        }
        const d = table.id.replace(/\//g, '__________');
        console.log(table);
        this.dataLineService.getFieldsByTableModal(d).subscribe(
          d => {
            this.fields[i] = d.data;
            console.log(this.ngFields);
            console.log(this.tableFieldIndex);
            this.loadingField[i] = false;
            if (this.ngFields[this.tableFieldIndex]!= undefined){
              console.log(this.ngFields[this.tableFieldIndex]);
              for (const field of this.ngFields[this.tableFieldIndex]) {
              //  for (const fieldElement of field) {
                  const tab = field.id.toString().split(':')[3];
                  console.log(tab);
                  if (tab === table.name) {
                    this.customModalFields[i].push(field);
                    this.customModalFields[i] = [...this.customModalFields[i]];
                  }
               // }
              }
          //    this.customModalFields[i] = this.ngFields[this.tableFieldIndex].filter(e => e.table == table.name);
            //  this.customModalFields[i] = [...this.customModalFields[i]];
              console.log(this.customModalFields[i]);
            }
          },
          e => {
            console.error(e);
          }
        );
      }
    );
  }
  getTablesByObjectModal(i) {
    this.loadingTableModal = true;
    this.tableModalValue = [];
    const dd = this.ngObjects[i].id.replace(/\//g, '__________');
    console.log(dd);
    this.dataLineService.getTablesByObject(dd).subscribe(
      d => {
        this.tablesModal = d.data;
        this.loadingTableModal = false;
        if (this.ngTables[i]!= undefined){
          this.tableModalValue = [...this.ngTables[i]];
          this.getFieldsByTableModal();
        }
      },
      e => {
        console.error(e);
      }
    );
  }
  openCustomTableField(i) {
    const that=this;
    this.error2 = false;
    this.customTableModalValue = [];
    this.customTableModal = [];
    this.customModalFields = [];
    this.customFields = [];
    if(this.ngCustomTable[i]!=undefined){
      $('#addExternalTableFieldTitle').html(that.lang === 'en' ? 'Edit External Table and field' : 'Translate this to your desired language');
      this.customTableModalValue = [...this.ngCustomTable[this.tableFieldIndex]];
      this.customTableModalValue.map(
        (tab ,i) => this.customFields[i] = this.customModalFields[i] = this.ngCustomsFields[this.tableFieldIndex].filter(e => e.table == tab.name)
      );
    }
    this.tableFieldIndex = i;
    $('#addExternalTableField').modal();
  }
  addCustomTableField() {
    let data = [];
    for( let i = 0; i < this.customTableModalValue.length; i++){
      if (this.customModalFields[i] == undefined){
      this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.customTableModalValue[i].name : 'Vous devez fournir des champs pour la table ' + this.customTableModalValue[i].name;
      this.error2 = true;
      return;

      }
      if (this.customModalFields[i].length == 0){
        this.errorMessage = this.lang === 'en' ? 'You must provide Fields for table ' + this.customTableModalValue[i].name : 'Vous devez fournir des champs pour la table ' + this.customTableModalValue[i].name;
        this.error2 = true;
        return;      }
    }
    this.ngCustomTable[this.tableFieldIndex] = this.customTableModalValue;
    this.customTableModalValue.map( (table, i) => {
      this.customModalFields[i].map(e => e.table = table.name);
      data = data.concat(this.customModalFields[i]);
    });
    this.ngCustomsFields[this.tableFieldIndex] = data;
   // console.log(this.ngCustomTable[this.tableFieldIndex],this.ngCustomsFields[this.tableFieldIndex] );
    $('#addExternalTableField').modal('hide');
    this.changement = true;
  }

  closeAddTableField() {
    $('#addTableField').modal('hide');
  }
  closeAddCustomTableField(){
    $('#addExternalTableField').modal('hide');
  }
  getApplication(nodeItem){
    return nodeItem.application != null ? nodeItem.application.name : nodeItem.customApplication.name;
  }
  async getEditorExtra() {
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.extraDescription) {
        CKEDITOR.instances.extraDescription.destroy(true);
      }
      if( $('#extraDescription').length > 0 && typeof CKEDITOR !== 'undefined') {
      try {
        const editorDescription = CKEDITOR.replace('extraDescription');
        editorDescription.config.height = 60;
        editorDescription.setData(this.extraDescription);
        editorDescription.on( 'change', evt => {
          this.extraDescription = evt.editor.getData();
          const extraItem = this.extraItems.find(e => e.id == this.clicked.id);
          extraItem.description = btoa(this.extraDescription);
          // console.log(this.extraItems);
        });
      } catch{}

    }
    }).catch(error => console.error(error));
  }

  private getTrigger(trigger: string) {
    let htm = '';
    switch (trigger) {
      case 'Manual': htm = '<i class="far fa-hand-paper" title="Trigger ' + trigger + '"></i>&nbsp;'; break;
      case 'Semi-automatic': htm = '<i class="fab fa-asymmetrik" title="Trigger ' + trigger + '"></i>&nbsp;'; break;
      case 'Automatic': htm = '<i class="fab fa-autoprefixer" title="Trigger ' + trigger + '"></i>&nbsp;'; break;
      case 'Undefined': htm = '<i class="fas fa-question" title="Trigger ' + trigger + '"></i>&nbsp;'; break;
    }
    return htm;
  }
  geTreeLine() {
    this.getTreeData();
    $('#LineageTreeModal').modal('show');
    setTimeout(() => {this.simulation = renderTreeLine(this.modalData); }, 500);
  }
  unifyObject(clicked: NodeItem) {
    console.log(clicked);
    console.log(this.ngFields);
    const ids = [];
    const treeData = [];
    if(clicked.data.length > 0) {
      clicked.data.map(item => {
        const data = [];
        if(ids.indexOf(item.model.id) ==-1){
          clicked.data.filter(i => i.model.id == item.model.id).map(
            j => {
              data.push({object : j.object, children: j.children});
            }
          );
          ids.push(item.model.id);
          treeData.push({model: item.model, data, color: clicked.application.color, title: clicked.text, icon: clicked.icon});
        }
      });
    } else {
      clicked.customs.map(item => {
        const data = [];
        if(ids.indexOf(item.model) ==-1){
          clicked.customs.filter(i => i.model == item.model).map(
            j => {
              data.push({object : {id:j.object.id, name: j.object.name}, children: j.children});
            }
          );
          ids.push(item.model);
          treeData.push({model: {id: item.model.id, name: item.model.name}, data, color: clicked.customApplication!=null? clicked.customApplication.color: clicked.application.color, title: clicked.text});
        }
      });
    }
    return treeData;
  }
  getTreeData() {
    let i = 1;
    let k = 1;
    const nodes = [];
    const links = [];
    const data = [];
   // console.table(this.nodesItems);
    this.nodesItems.map(n => data.push(this.unifyObject(n)));
    const edges = this.edges.filter(e => e.parent);
   // console.log(edges);
   // console.log(data);
    // tree node
    data.map(
      (nodeItem, j) => {
        nodeItem.map(
          m => {
            const modIndex = i;
            const modNode = {id: modIndex, name: m.model.name, ident: m.model.id, type: 'model', group: j, color: m.color, title: m.title, icon: m.icon};
            nodes.push(modNode);
            //object loop
            m.data.map(
              det => {
                i++;
                const objIndex = i;
                const objNode = {id: objIndex, name: det.object.name, ident: det.object.id, type: 'object', group: j , color: m.color, title: m.title, icon: m.icon};
                const linkObj = {id: k, source : modIndex , target: objIndex};
                nodes.push(objNode);
                links.push(linkObj);
                // table loop
                det.children.map(
                  t => {
                    i++;
                    k++;
                    const tabIndex = i;
                    const tabNode = {id: tabIndex, name: t.table.name, ident: t.table.id, type: 'table', group: j , color: m.color, title: m.title, icon: m.icon};
                    const linkTab = {id: k, source : objIndex , target: tabIndex};
                    nodes.push(tabNode);
                    links.push(linkTab);
                    // fields loop
                    t.fields.map(
                      f => {
                        i++;
                        k++;
                        const fieldNode = {id: i, name: f.name, ident: f.id, type: 'field', group: j , color: m.color, title: m.title, icon: m.icon};
                        const linkField = {id: k, source : tabIndex , target: i};
                        nodes.push(fieldNode);
                        links.push(linkField);
                      }
                    );
                  }
                );
                k++;
              }
            );
            i++;
          }
        );
      }
    );
    if(edges.length > 0 ) {
      let z = links.length;
      edges.map(
       edge => {
         let start = edge.source.replace('end_','');
         start = start.replace('start_','');
         let end = edge.target.replace('end_','');
         end = end.replace('start_','');
         const source = nodes.find(n => n.ident == start );
         const target = nodes.find(n => n.ident == end );
         if(source!= null && target!= null){
           z++;
           const link = {id: z, source : source.id , target: target.id, type: 'arrow'};
           links.push(link);
         }
       }
     );
    }
    this.modalData = {links, nodes};
    console.log(this.modalData);
  }

  removeEdge() {
  // console.log(this.selectedEdge);
   const group = this.selectedEdge.group;
   const links = this.edges.filter(e => e.group == group);
   if ( links.length > 0) {
     links.map(
       l => l.element.remove()
     );
   }
   this.edges = this.edges.filter(e => e.group != group);
   this.linesArch = this.linesArch.filter(e => e.group != group);
   this.getEdges(this.clicked);
   this.selectedEdge = null;
  }

  highLightEdge() {
    this.edges.map(
      l => {
        l.element.outline = false;
      }
    );
    this.selectedEdge.element.outline = true;
    this.selectedEdge.element.setOptions({
      outlineColor: 'rgb(1,7,12)'
    });
  }
  getEdgesForRemove(node: NodeItem){
    this.selectedEdges = [];
    if (node.data.length > 0){
      node.data.map(
        d => {
          const startObject = 'start_' + d.object.id;
          const endObject = 'end_' + d.object.id;
          const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject));
          if (objectEdges.length > 0) {
            this.selectedEdges = this.selectedEdges.concat(objectEdges);
          }
          if(d.children.length > 0){
            d.children.map(
              t => {
                t.fields.map(
                  f => {
                    const startField = 'start_' + f.id;
                    const endField = 'end_' + f.id;
                    const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField));
                    if (fieldEdges.length > 0){
                      this.selectedEdges = this.selectedEdges.concat(fieldEdges);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
    if(node.customs.length > 0){
      node.customs.map(
        d => {
          const startObject = 'start_' + d.object.id;
          const endObject = 'end_' + d.object.id;
          const objectEdges = this.edges.filter( e => (e.source == startObject || e.target == startObject || e.source == endObject || e.target == endObject) && e.parent == true);
          if (objectEdges.length > 0){
            this.selectedEdges = this.selectedEdges.concat(objectEdges);
          }
          if(d.children.length > 0){
            d.children.map(
              t => {
                t.fields.map(
                  f => {
                    const startField = 'start_' + f.id;
                    const endField = 'end_' + f.id;
                    const fieldEdges = this.edges.filter( e => (e.source == startField || e.target == startField || e.source == endField || e.target == endField));
                    if (fieldEdges.length > 0){
                      this.selectedEdges = this.selectedEdges.concat(fieldEdges);
                    }
                  }
                );
              }
            );
          }
        }
      );
    }
    if(this.selectedEdges.length > 0){
      this.selectedEdges.map(
        ed => {
          ed.element.remove();
          this.edges = this.edges.filter(e => e.group != ed.group);
          this.linesArch = this.linesArch.filter(e => e.group != ed.group);
        }
      );
    }
  }
  getEdgesForRemoveProcess(node: ExtraItem) {
      this.selectedEdges = [];
      //console.log(node);
     // console.log(node.connectors);
      node.connectors.map(
        con => {
          const edges = this.edges.filter( e => e.source == con.id || e.target == con.id );
          console.log(edges);
          if(edges.length > 0 ) {
            edges.map(l => {
              this.edges = this.edges.filter(e => e.source != con.id && e.target != con.id);
              l.element.remove();
              this.linesArch = this.linesArch.filter(e => e.line.source != con.id && e.line.target != con.id);
            });
          }
        }
    );
  }
  test(t: any, index){
    //console.log(t);
    const start = 'start_' + t.id;
    const end = 'end_' + t.id;
    if($('#' + $.escapeSelector(start)).length > 0 || $('#' + $.escapeSelector(end)).length > 0){
      t = t.id.split(':');
      Swal.fire({
        title: this.lang === 'en' ? 'Error!' : 'Erreur !',
        text: this.lang === 'en' ? 'Object ' + t[2] + ' of catalog ' + t[1] + ' and system ' + t[0] + ' is already used' : 'L\'objet ' + t[2] + ' du catalogue ' + t[1] + ' et du système ' + t[0] + ' est déjà utilisé',
        icon: 'error',
        confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
      });
      
      this.ngObjects[index] = null;
    }
  }

  saveDataLineage() {
  // const data = this.saveFrm.value;
   const catalogs = [];
   this.nodesItems.map(item => {
     const obj = $('#' + item.id);
     item.style = obj.offset();
     if(item.data!= null){
       if(item.data.length > 0){
         item.data.map(
           d => {
             if(catalogs.indexOf(d.model.id) == -1){
               catalogs.push(d.model.id);
             }
           }
         );
       }
     }
   });
   this.extraItems.map(item => {
     const obj = $('#' + item.id);
     item.style = obj.offset();
   });
   const content = btoa(JSON.stringify({nodes: this.nodesItems, extra: this.extraItems, links: this.edges, styles: {flatDesign: this.flatDesign, pattern: this.pattern, zoom: this.zoom, playAreaSize: $('#play-area').css('flex-basis'), toolbarNgSize: $('#toolbar-ng').css('width'), patternColor: this.patternColor}, lineArch: this.linesArch}));
  // const t = {name: data['LineName'], enabled: data['enabledLine'] ,effective: data['lineEffective'] , description: data['description'], content , createdBy: localStorage.getItem('username'), owner: data['owner']};
   const t = { content, catalogs};
   if(this.id!=null && this.id!=0){
     t['id'] = this.id;
     this.dataLineService.addDataLineageContent(t).subscribe(
       d => {
        this.Toast.fire({
          icon: 'success',
          title: this.lang === 'en' ? 'Image saved successfully' : 'Image enregistrée avec succès'
        });       
        // $('#saveLineageModal').modal('hide');
       }, err =>  {
         this.Toast.fire({
           icon: 'error',
           title: err
         });
       }
     );
   }else{
     this.dataLineService.addDataLineage(t).subscribe(
       d => {
        this.Toast.fire({
          icon: 'success',
          title: this.lang === 'en' ? 'Added successfully' : 'Ajouté avec succès'
        });        
         $('#saveLineageModal').modal('hide');
       }, err =>  {
         this.Toast.fire({
           icon: 'error',
           title: err
         });
       }
     );
   }
  }
  // openSaveModal() {
  //  // $('#saveLineageModalTitle').html('');
  //   this.loadSaveScriptModal();
  //   this.saveFrm.reset();
  //  // console.log(this.id);
  //   this.saveFrm.get('enabledLine').patchValue(false);
  //   if (this.id!= null && this.id != 0){
  //     this.saveFrm.get('LineName').patchValue(this.dataLineageName);
  //     this.saveFrm.get('lineEffective').patchValue(this.dataLineageEffective.toString().substr(0,10));
  //     this.saveFrm.get('enabledLine').patchValue(this.dataLineageEnabled);
  //     this.saveFrm.get('description').patchValue(this.dataLineageDescription);
  //     this.saveFrm.get('owner').patchValue(this.owner);
  //   }
  //   $('#saveLineageModal').modal();
  // }
  getDataLineage(id: string){
    this.dataLineService.getDataLineage(id).subscribe(
      d => {
        this.importData = d.data;
        if(this.importData.content!= null){
          this.importData.content = atob(this.importData.content);
          this.importData.content = JSON.parse(this.importData.content);
          $('#bt_back').css('top', '8%');
        }else{
          $('#bt_back').css('top', '0');
        }
//         console.log(this.importData.content);
        this.dataLineageName = this.importData.name;
        this.dataLineageEffective = this.importData.effective.date;
        this.dataLineageEnabled = this.importData.enabled;
        this.dataLineageDescription = this.importData.description;
        this.createdBy = this.importData.createdBy;
        this.createdAt = this.importData.createdAt;
        this.updateLn = d.rights.update;
        this.deleteLn = d.rights.delete;
        this.owner = this.importData.owner;
        if(this.importData.content!= null) {
          if (this.importData.content.styles.playAreaSize != undefined) {
            $('#play-area').css('flex-basis', this.importData.content.styles.playAreaSize);
          }
          if (this.importData.content.styles.toolbarNgSize != undefined) {
            $('#toolbar-ng').css('width', this.importData.content.styles.toolbarNgSize);
          }
        }
       // console.log(this.importData);
        if(this.importData.content!= null){
          this.importNodes();
        }else{
          this.removeLoader();
        }
        $('#spinner_getListApprovedObject').hide();
      },
      err => {
        console.log(err);
      }
    );
  }
  async importNodes() {
    $('body').css('position', '');
    this.nodesItems = [];
    this.extraItems = [];
    this.linesArch = [];
    this.edges = [];
    for (let i = 0; i < this.importData.content.nodes.length; i++) {
      const item = new NodeItem(this.importData.content.nodes[i].id, this.importData.content.nodes[i].icon, this.importData.content.nodes[i].text, this.importData.content.nodes[i].application, this.importData.content.nodes[i].extern, this.importData.content.nodes[i].catalogue, this.importData.content.nodes[i].data, this.importData.content.nodes[i].description, this.importData.content.nodes[i].customApplication, 'black', this.importData.content.nodes[i].customs, this.importData.content.nodes[i].applicationType, this.importData.content.nodes[i].trigger, this.importData.content.nodes[i].type, this.importData.content.nodes[i].style);
      this.addHtmlContent(item);
    }
    //$('.collapse-lg').toggle();
    for (let j = 0; j < this.importData.content.extra.length; j++) {
      const item = new ExtraItem(this.importData.content.extra[j].id, this.importData.content.extra[j].description, this.importData.content.extra[j].connectors, this.importData.content.extra[j].type, this.importData.content.extra[j].style, this.importData.content.extra[j].trigger, this.importData.content.extra[j].lines);
      this.addExtraHtml(item);
    }

    const links = this.importData.content.links.filter(nd => nd.parent);
//    console.log(links);
    // // $('#' + $.escapeSelector(links[0].source)).click().focus();
    // // $('#' + $.escapeSelector(links[0].target)).click().focus();
    for (let i = 0; i < links.length; i++) {
      $('#' + $.escapeSelector(links[i].source)).click().focus();
      await this.sleep(500);
      $('#' + $.escapeSelector(links[i].target)).click().focus();
    //  console.log('test');
      // setTimeout(() => {$('#' + $.escapeSelector(links[i].target)).click().focus(); }, 300);
    }
    // for(let k = 0; k < this.importData.content.links.length; k++){
    //   this.addLine(this.importData.content.links[k].source, this.importData.content.links[k].target, this.importData.content.links[k].startColor, this.importData.content.links[k].endColor, this.importData.content.links[k].hide, this.importData.content.links[k].parent, this.importData.content.links[k].sourceName, this.importData.content.links[k].targetName, this.importData.content.links[k].group);
    // }
    // this.importData.content.links.map(nd => {
    //   // $('#' + $.escapeSelector(nd.source)).click().focus();
    //   // setTimeout(() => {$('#' + $.escapeSelector(nd.target)).click().focus(); }, 1000);
    //  this.addLine(nd.source, nd.target, nd.startColor, nd.endColor, nd.hide, nd.parent, nd.sourceName, nd.targetName, nd.group);
    // });
    $('#play-area').click();
    // for(let z = 0; z < this.importData.content.lineArch.length; z++){
    //   const line = this.edges.find(e => e.source == this.importData.content.lineArch[z].start && e.target == this.importData.content.lineArch[z].end).element;
    //   const parent = this.edges.find(e => e.source == this.importData.content.lineArch[z].source && e.target == this.importData.content.lineArch[z].target).element;
    //   const leftParent = this.edges.find(e => e.source == this.importData.content.lineArch[z].start && e.target == this.importData.content.lineArch[z].target).element;
    //   const rightParent = this.edges.find(e => e.source == this.importData.content.lineArch[z].end && e.target == this.importData.content.lineArch[z].source).element;
    //   this.linesArch.push({line , parent , source: this.importData.content.lineArch[z].source, target: this.importData.content.lineArch[z].target, leftParent , rightParent, groupId: this.importData.content.lineArch[z].groupId, start: this.importData.content.lineArch[z].start, end: this.importData.content.lineArch[z].end});
    // }
    // console.log(this.linesArch);
    // this.importData.content.lineArch.map(nd => {
    //   //  const line = this.addLine(this.startConnector, this.endConnector, this.startColor, that.endColor, false, true, that.startName, that.endName, groupId);
    //   //  const parentLine = this.addLine(this.startParent, this.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
    //   //  const leftLine = this.addLine(this.startConnector, this.endParent, that.startColor, that.endColor, true, false, that.startName, that.endName, groupId);
    //   //  const rightLine = this.addLine(this.endConnector, that.startParent, that.endColor, that.startColor, true, false, that.startName, that.endName, groupId);
    //   // that.linesArch.push({line, parent: parentLine, source: this.startParent, target: that.endParent, leftParent: leftLine, rightParent: rightLine, groupId, start: that.startConnector, end : that.endConnector, startParent: that.startParent, endParent: that.endParent});
    //    const line = this.edges.find(e => e.source == nd.start && e.target == nd.end).element;
    //    const parent = this.edges.find(e => e.source == nd.source && e.target == nd.target).element;
    //    const leftParent = this.edges.find(e => e.source == nd.start && e.target == nd.target).element;
    //    const rightParent = this.edges.find(e => e.source == nd.end && e.target == nd.source).element;
    //    this.linesArch.push({line , parent , source: nd.source, target: nd.target, leftParent , rightParent, groupId: nd.groupId, start: nd.start, end: nd.end});
    // });
    this.updateConnectors();
    this.flatDesign = this.importData.content.styles.flatDesign;
    if (this.flatDesign) {
      if ($('.node-item').length > 0) {
        $('.node-item').removeClass('shadow-design');
      }
    } else {
      if ($('.node-item').length > 0) {
        $('.node-item').addClass('shadow-design');
      }
    }

    this.pattern = this.importData.content.styles.pattern;
    this.changePattern(this.pattern);
    this.zoom = this.importData.content.styles.zoom;
    if(this.importData.content.styles.patternColor != undefined){
      this.changeComplete(this.importData.content.styles.patternColor);
    }
    this.removeLoader();
  }
  addHtmlContent(nodeItem: NodeItem){
    $('#bt_back').css('top', '8%');
    const that = this;
    const id = nodeItem.id;
    const childId = 'childs_' + id;
    const headerId = 'header_' + id;
    // const back = ['bg-info', 'bg-success', 'bg-primary', 'bg-danger', 'bg-secondary'];
    let bgColor = '#000000';
    if (nodeItem.application != undefined) {
      bgColor = nodeItem.application.color;
    }
    if (nodeItem.customApplication != undefined) {
      bgColor = nodeItem.customApplication.color;
    }
    let objectsHtml = '';
    if (!nodeItem.extern) {
      if (nodeItem.catalogue) {
        nodeItem.data.map(obj => {
          if (obj.children != undefined) {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
              if(obj.children.length > 0 ){
          objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
            '<ul class="list-group list-group-flush text-black collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(obj.object.id, obj.children) + '</ul>';
        }
          } else {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
          }
        });
      } else {
        nodeItem.customs.map(obj => {
          if (obj.children != undefined ) {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
            if(obj.children.length > 0 ){
              objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.name + '" style="float: left;color:#31b7f9;cursor:pointer;display:none"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.name + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
                '<ul class="list-group list-group-flush text-black  collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(obj.object.id, obj.children) + '</ul>';
            }
          } else {
            objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line"  id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
          }
        });
      }
    } else {
      nodeItem.customs.map(obj => {
        if (obj.children != undefined ) {
          objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>'; // + // <i class="fas fa-circle connector end line-handle"></i></li>' +
          if(obj.children.length > 0 ){
            objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
            '<ul class="list-group list-group-flush text-black  collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(obj.object.id, obj.children) + '</ul>';
          }
        } else {
          objectsHtml += '<li class="list-group-item"><i class="fas fa-circle connector start static object-line empty-object" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object + '</b>&nbsp;<i class="fas fa-circle connector end object-line" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ; // <i class="fas fa-circle connector end line-handle"></i></li>';
        }
      });
    }
    this.tabIndex++;
    const html = this.getNodeStyle() + bgColor + '" id="' + id + '" tabindex="' + this.tabIndex + '">\n' +
      '<span class="fa-stack" style="align-self: center;font-size: 1.2em;padding-top: 1.3px"><i class="fas fa-circle fa-stack-2x" style=";vertical-align: middle; color:' + bgColor + '"></i> ' + this.getImageFromType(nodeItem.icon, bgColor) + '</span>' +
      '<div class="card-header text-center text-white node-header"  style="background-color: rgb(60 172 196);border-top-left-radius: 23px;\n' +
      '    border-top-right-radius: 23px;text-transform: capitalize" id="' + headerId + '">' + nodeItem.text + '</div>\n' +
      // '      </div>\n' +
      '      <ul class="list-group list-group-flush text-black node-details flat-details" id="' + childId + '" >\n' + objectsHtml +
      '      </ul>\n' +
      '      <div class="card-body tools-line tools-line-flat">\n' +
      '        <span class="remove-drag" data-item="' + id + '"><i class="fas fa-times-circle edit-remove"></i></span>\n' +
      '        <span class="add-drag" data-item="' + id + '"><i class="fas fa-pencil-alt edit-remove"></i></span>\n' +
    //  '        <span style="float:right">' + this.getApplicationTypeIcon(nodeItem.applicationType, nodeItem.customApplication) + '&nbsp;' + this.getApplication(nodeItem) + '</span>\n' +
      '       <span class="tree-modal">' + this.getTrigger(nodeItem.trigger) + '</span>\n' +
      '      </div>\n' +
      '      <div id="line-wrapper"></div>\n' +
      '    </div>';
    $('#play-area').append(html);
    // $('.tree-modal').off().on('click', function(e) {
    //   e.stopPropagation();
    //   $('#LineageTreeModal').modal('show');
    //   const clicked = that.nodesItems.find(t => t.id == $(this).attr('data-item') );
    //   that.getNodeData(clicked);
    //   setTimeout(() => {renderTreeLine(that.modalData);}, 500);
    // });
    $('.show-node-Item').off().on('click', function() {
      $(this).parent().next().toggle();
      const p = $(this).attr('data-object');
      const start = 'start_' + p;
      const end = 'end_' + p;
      const childLines = that.linesArch.filter(item => item.source == start || item.target == start || item.source == end || item.target == end);
      if ($(this).hasClass('fa-angle-double-down') ) {
        $(this).removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        if(childLines!=undefined){
          childLines.map(l => {
            if( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') == 'none'){
              l.line.hide('none'); l.parent.show('none');l.leftParent.hide('none'); l.rightParent.hide();
            }
            if( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') != 'none'){
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.show('none');
            }
            if( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') == 'none'){
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.show('none'); l.rightParent.hide('none');
            }
            if( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') != 'none'){
              l.line.show('none'); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.hide('none');
            }
          });
        }
        that.updateConnectors();
        if ($(this).next('i').length > 0 ) {
          $(this).next('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        }
        if ($(this).prev('i').length > 0 ) {
          $(this).prev('i').removeClass('fa-angle-double-down').addClass('fa-angle-double-up');
        }
      } else {
        $(this).removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        if(childLines!=undefined){
          childLines.map(l => {
            if( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') == 'none'){
              l.line.hide('none'); l.parent.show('none');l.leftParent.hide('none'); l.rightParent.hide('none');
            }
            if( $(l.line.start).parent().parent().css('display') == 'none' && $(l.line.end).parent().parent().css('display') != 'none'){
              l.line.hide(); l.parent.hide('none'); l.leftParent.hide('none'); l.rightParent.show('none');
            }
            if( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') == 'none'){
              l.line.hide('none'); l.parent.hide('none'); l.leftParent.show('none'); l.rightParent.hide('none');
            }
            if( $(l.line.start).parent().parent().css('display') != 'none' && $(l.line.end).parent().parent().css('display') != 'none'){
              l.line.hide('none'); l.parent.show('none'); l.leftParent.hide('none'); l.rightParent.hide('none');
            }
          });
        }
        that.updateConnectors();
        if ($(this).next('i').length > 0 ) {
          $(this).next('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        }
        if ($(this).prev('i').length > 0 ) {
          $(this).prev('i').removeClass('fa-angle-double-up').addClass('fa-angle-double-down');
        }
      }
    });
    that.enableLinking();
    this.makeDraggable(id);
    window.scrollBy(5, 100);
    window.scrollBy(-5, -100);
    this.nodesItems.push(nodeItem);
   // console.log(nodeItem);
    $('#addLineageModalClose').click();
    $('#' + id).offset(nodeItem.style).on('click', function(event) {
      event.stopPropagation();
      if(that.clicked != null){
        if (that.clicked.id == $(this).attr('id')) {
          return;
        }
      }
      $('.node-item').removeClass('selected-node');
      $(this).addClass('selected-node').focus();
      //  console.log($(this).attr('id') );
      that.clicked = that.nodesItems.find(t => t.id == $(this).attr('id') );
      //  console.log(that.clicked);
      that.getNodeData(that.clicked);
      that.getEdges(that.clicked);
      //end tree node
      // console.log(that.orgData);

      // const nodeTemplate = (data) => {
      //   return `
      //     <span class="icon-org">${data.icon}</span>
      //     <div class="title">${data.name}</div>
      //     <div class="content">${data.title}</div>
      //   `;
      // };
      //
      // let htmk= '';
      // that.orgData.map(
      //   (u,i) => { htmk += '<div id="org-container_' + i + '" style="position: relative;height: 420px;border: 1px solid #aaa;margin: 0.5rem;text-align: center;overflow:hidden" ></div>'; }
      // );
      // setTimeout(() => {
      //
      //   $('#chart-container').empty().append(htmk);
      //   that.orgData.map(
      //     (u, i) => {
      //      // console.log(u);
      //      // console.log(i);
      //       const oc = $('#org-container_' + i).orgchart({
      //         data : u,
      //         nodeContent: 'title',
      //         nodeTemplate,
      //         pan: true,
      //         zoom: true
      //       });
      //       oc.$chartContainer.on('touchmove', function(event) {
      //         event.preventDefault();
      //       });
      //     }
      //   );
      // }, 1000);

    }).keydown(function(e) {
      if(e.key === 'Delete') {
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Data Object?' : 'SUPPRIMER l\'objet de données?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id');
            const nodeItem = that.nodesItems.find(e => e.id == id);
            that.getEdgesForRemove(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.nodesItems.splice(that.nodesItems.indexOf(that.nodesItems.find( e => e.id == id)), 1) ;
              $('#play-area').click();
            }
          }
        });
      }
    });
    this.targets.push({name: nodeItem.text, id});
    this.targets = [...this.targets];
    // this.initConnectors();
    $('.card-body').off().on('click', '.remove-drag', function(e) {
      let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
      Swal.fire({
        title: this.lang === 'en' ? 'DELETE Data Object?' : 'SUPPRIMER l\'objet de données?',
        text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
        cancelButtonText: cancelButtonText
      }).then((result) => {
        if (result.value) {
          const id = $(this).attr('data-item');
          const nodeItem = that.nodesItems.find(e => e.id == id);
          that.getEdgesForRemove(nodeItem);
          const t = that.draggables.find(item => item.id == id);
          if (t != undefined) {
            t.element.remove();
            that.draggables = that.draggables.filter(item => item.id != id);
            $('#' + id).remove();
            that.nodesItems.splice(that.nodesItems.indexOf(that.nodesItems.find( e => e.id == id)), 1) ;
          }
        }
      });
    }).on('click', '.add-drag', function() {
      that.changement = false;
      that.selectedId = $(this).attr('data-item');
      const nodeItem = that.nodesItems.find(e => e.id === that.selectedId);
      that.editMode = true;
      that.resetModelObjectTableFields();
      that.resetCustomModelObject();
      that.modelAndObjectAndTableAndFields = [];
      that.customModelAndObjects = [];
      that.ngNodeText = nodeItem.text;
      that.ngExtern = nodeItem.extern;
      that.ngTrigger = nodeItem.trigger;
      that.choosenIcon = nodeItem.icon;
      if(nodeItem.applicationType!= null){
        that.ngTypeApp = nodeItem.applicationType.id;
      }
      if(nodeItem.application!= null){
        that.ngApp = nodeItem.application;
      }
      that.ngCatalogue = nodeItem.catalogue;
      that.getFieldsByTableModal();
      console.log(nodeItem);
      // bind data to from catalogue model,object,table and fields
      if(nodeItem.data.length > 0) {
        nodeItem.data.map(
          (item , i) => {
            that.modelAndObjectAndTableAndFields.push(i);
            that.ngModels[i] = item.model;
            that.ngObjects[i] = item.object;
            that.ngTables[i] = [];
            that.ngFields[i] = [];
            console.log(i);
            if(item.children.length > 0) {
              item.children.map(
                tab => {
                  that.ngTables[i].push(tab.table);
                  console.log(i, that.ngFields);
                  const tr = [];
                  for (const tf of tab.fields) {
                    const t = {id: tf.id, name: tf.name, description: tf.description, table: tab.table.name};
                    tr.push(t);
                  }
                  that.ngFields[i] = that.ngFields[i].concat(tr);
                  //that.ngFields[i].push(tab.fields);
                  console.log(tab.fields);
                 // console.log(tab);
                }
              );
            }
          }
        );
      }
      if(nodeItem.customApplication!= null){
        that.ngCustomApplication = nodeItem.customApplication;
      }
      // bind data to from catalogue model,object,table and fields
      if(nodeItem.customs.length > 0) {
        nodeItem.customs.map(
          (item , i) => {
            that.customModelAndObjects.push(i);
            that.ngCustomModel[i] = item.model.name;
            that.ngCustomObjects[i] = item.object.name;
            that.ngCustomTable[i] = [];
            if(item.children.length > 0) {
              item.children.map(
                tab => {
                  that.ngCustomTable[i].push({id: tab.table, name: tab.table});
                  that.ngCustomsFields[i] = tab.fields;
                }
              );
            }
          }
        );
      }
      that.ngDescription = atob(nodeItem.description);
      // console.log(that.ngDescription);
      $('#addLineageModal').modal('show');
      // that.ngFields.map(f => {
      //   f.map(g => {
      //     console.log(f, g);
      //     if(Array.isArray(g)){
      //       console.log(f, g);
      //       f = f.concat(g);
      //     }
      //   });
      // });
      console.log(that.ngFields);
      if(nodeItem.data.length > 0) {
        nodeItem.data.map(d => {
          if(d.children.length > 0) {
            d.children.map(
              tab => {
                that.ngFields.map(e => {
                  e.map(z => {
                      if(tab.table.id + z.id.toString().split(':')[4] === z.id) {
                        if(tab.fields.findIndex(s => s.id === z.id) === -1) {
                          tab.fields.push(z);
                        }
                      }
                  });
                });
                // console.log(tab);
              }
            );
          }
        });
      }
    });
    console.log(this.ngFields);
  }
  decryptDescr(t: string){
    return atob(t);
  }
  addExtraHtml(extraItem: ExtraItem){
    $('#bt_back').css('top', '8%');
    window.scrollBy(5, 100);
    window.scrollBy(-5, -100);
    const that = this;
    const type = extraItem.type;
    if (type == 'start'){
      const start = document.createElement('div');
      this.tabIndex++;
      $(start).addClass('node-item lg-start').attr('id', extraItem.id).css({width: '52px', height: '52px', position: 'absolute'}).attr('tabindex', this.tabIndex).html('<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x" style="background: -webkit-linear-gradient(to right, #38ef7d, #11998e);\n' +
        '                          background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ -webkit-background-clip: text; -webkit-text-fill-color: transparent;"></i>\n' +
        '                        <i class="fas fa-circle fa-stack-1x fa-inverse"></i></span>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="start"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="start"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing" title="remove this start"></i>');
      if(!this.flatDesign){
        $(start).addClass('shadow-design');
      }
      document.getElementById('play-area').appendChild(start);
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(start).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        that.ngTriggerExtra = that.clicked.trigger;
        that.ngDataLinesExtra = that.clicked.lines;
        setTimeout(() => {that.getEditorExtra(); }, 500);
      }).keydown(function(e) {
        if(e.key === 'Delete') {
          let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE Start Object?' : 'SUPPRIMER l\'objet de départ?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
            cancelButtonText: cancelButtonText
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Start Object?' : 'SUPPRIMER l\'objet de départ?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'end'){
      const end = document.createElement('div');
      this.tabIndex++;
      $(end).addClass('node-item lg-end').attr('id', extraItem.id).css({width: '52px', height: '52px', position: 'absolute'}).attr('tabindex', this.tabIndex).html('<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x" style=" background: -webkit-linear-gradient(to right, #EA384D, #D31027);  /* Chrome 10-25, Safari 5.1-6 */\n' +
        '                          background: linear-gradient(to right, #EA384D, #D31027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ -webkit-background-clip: text; -webkit-text-fill-color: transparent;"></i>\n' +
        '                        <i class="far fa-circle fa-stack-1x fa-inverse"></i></span>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="end"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="end"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-end-process" title="remove this end"></i>');
      if(!this.flatDesign){
        $(end).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(end);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(end).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra();}, 500);
      }).keydown(function(e) {
        if(e.key === 'Delete') {
          let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE End Object?' : 'SUPPRIMER l\'objet de fin?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
            cancelButtonText: cancelButtonText
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE End Object?' : 'SUPPRIMER l\'objet de fin?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'aggregate'){
      const aggregate = document.createElement('div');
      this.tabIndex++;
      $(aggregate).addClass('node-item lg-aggregate').attr('id', extraItem.id).attr('tabindex', this.tabIndex).css({width: '60px', height: '60px', position: 'absolute'}).html('     <svg style="width: 60px; height: 60px;">\n' +
        '                        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">\n' +
        '                          <stop offset="0%" stop-color="#0072ff"></stop>\n' +
        '                          <stop offset="100%" stop-color="#00c6ff"></stop>\n' +
        '                        </linearGradient>\n' +
        '                        <g style="transform: translate(16px, 10px)">\n' +
        '                          <circle cx="15" cy="18" r="26" fill="url(#linearColors1)"></circle>\n' +
        '                          <path fill="white" d="M 30.3188 26.8 H 27.075 c 2.1531 -2.8563 3.3375 -6.425 3.3375 -10.0531 c 0 -8.7844 -6.6344 -15.9313 -14.7875 -15.9313 c -8.1563 0 -14.7875 7.1469 -14.7875 15.9313 c 0 3.6281 1.1844 7.1969 3.3375 10.0531 H 0.9313 c -0.3406 0 -0.6188 0.2781 -0.6188 0.6188 V 29.8125 c 0 0.3406 0.2781 0.6188 0.6188 0.6188 H 9.7813 c 0.3406 0 0.6188 -0.2781 0.6188 -0.6188 v -2.6625 c 0 -0.1469 -0.0531 -0.2906 -0.1469 -0.4031 l -0.0344 -0.0406 c -0.0344 -0.0406 -0.075 -0.0781 -0.1187 -0.1094 c -3.0438 -2.1094 -4.9375 -5.8844 -4.9375 -9.85 c 0 -6.3969 4.6937 -11.6031 10.4625 -11.6031 c 5.7687 0 10.4625 5.2062 10.4625 11.6031 c 0 3.9813 -1.9031 7.7656 -4.9688 9.8719 c -0.1688 0.1156 -0.2687 0.3063 -0.2687 0.5094 V 29.8125 c 0 0.3406 0.2781 0.6188 0.6188 0.6188 h 8.8469 c 0.3406 0 0.6188 -0.2781 0.6188 -0.6188 v -2.3906 C 30.9375 27.0781 30.6594 26.8 30.3188 26.8 z"></path>\n' +
        '                        </g>\n' +
        '                      </svg>\n' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="aggregate"></i><i class="fas fa-circle connector end extra-line" id="end_' + extraItem.id + '" data-name="aggregate"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-aggregate-process" title="remove this aggregation"></i>');
      if(!this.flatDesign){
        $(aggregate).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(aggregate);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(aggregate).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra();},500);
      }).keydown(function(e) {
        if(e.key === 'Delete') {
          let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE Aggregate Object?' : 'SUPPRIMER l\'objet agrégé?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
            cancelButtonText: cancelButtonText
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Aggregate Object?' : 'SUPPRIMER l\'objet agrégé?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
    if (type == 'condition') {
      const condition = document.createElement('div');
      this.tabIndex++;
      $(condition).addClass('node-item lg-condition').attr('id', extraItem.id).attr('tabindex', this.tabIndex).css({width: '60px', height: '60px', position: 'absolute'}).html('   <svg style="width: 60px; height: 60px;">\n' +
        '                        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">\n' +
        '                          <stop offset="0%" stop-color="#0072ff"></stop>\n' +
        '                          <stop offset="100%" stop-color="#00c6ff"></stop>\n' +
        '                        </linearGradient>\n' +
        '                        <g style="transform: translate(16px, 10px);">\n' +
        '                          <circle cx="14" cy="17" r="26" fill="url(#linearColors1)"></circle>\n' +
        '                          <path fill="white" d="M 11.8438 31 L 0.6875 17.8562 c -0.9125 -1.075 -0.9125 -2.6375 0 -3.7188 L 11.8438 1 c 1.1313 -1.3375 3.1812 -1.3313 4.3125 0 L 27.3125 14.1438 c 0.9125 1.075 0.9125 2.6375 0 3.7188 L 16.1563 31 c -1.1313 1.3375 -3.1812 1.3313 -4.3125 0 z M 3 16 l 11 12.9063 L 25 16 L 14 3.0938 L 3 16 z"></path>\n' +
        '                        </g>\n' +
        '                      </svg>' +
        '<i class="fas fa-circle connector start static extra-line" id="start_' + extraItem.id + '" data-name="condition: yes"></i><i class="fas fa-circle connector end condition-line" id="end_' + extraItem.id + '" data-name="condition: no"></i></i><i class="fas fa-circle connector input-condition extra-line" id="input_' + extraItem.id + '" data-name="condition: input"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-condition-process" title="remove this condition"></i>');
      if (!this.flatDesign) {
        $(condition).addClass('shadow-design');
      }
      const connectors = [];
      connectors.push({id: 'start_' + extraItem.id});
      connectors.push({id: 'end_' + extraItem.id});
      connectors.push({id: 'input_' + extraItem.id});
      this.extraItems.push(extraItem);
      document.getElementById('play-area').appendChild(condition);
      this.makeDraggable(extraItem.id);
      this.enableLinking();
      $(condition).offset(extraItem.style).on('click', function(e) {
        e.stopPropagation();
        const id = $(this).attr('id');
        $('.node-item').removeClass('selected-node');
        $(this).addClass('selected-node').focus();
        that.clicked = that.extraItems.find(e => e.id == id);
        that.extraDescription = atob(that.clicked.description);
        setTimeout(() => {that.getEditorExtra();},500);
      }).keydown(function(e) {
        if(e.key === 'Delete') {
          let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
          Swal.fire({
            title: this.lang === 'en' ? 'DELETE Condition Object?' : 'SUPPRIMER l\'objet de condition?',
            text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
            cancelButtonText: cancelButtonText
          }).then((result) => {
            if (result.value) {
              const id = $(this).attr('id');
              const nodeItem = that.extraItems.find(e => e.id == id);
              that.getEdgesForRemoveProcess(nodeItem);
              const t = that.draggables.find(item => item.id == id);
              if (t != undefined) {
                t.element.remove();
                that.draggables = that.draggables.filter(item => item.id != id);
                $('#' + id).remove();
                that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
              }
            }
          });
        }
      });
      $('#remove_' + extraItem.id).on('click', function() {
        let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
        Swal.fire({
          title: this.lang === 'en' ? 'DELETE Condition Object?' : 'SUPPRIMER l\'objet de condition?',
          text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
          cancelButtonText: cancelButtonText
        }).then((result) => {
          if (result.value) {
            const id = $(this).attr('id').split('remove_')[1];
            const nodeItem = that.extraItems.find(e => e.id == id);
            that.getEdgesForRemoveProcess(nodeItem);
            const t = that.draggables.find(item => item.id == id);
            if (t != undefined) {
              t.element.remove();
              that.draggables = that.draggables.filter(item => item.id != id);
              $('#' + id).remove();
              that.extraItems.splice(that.extraItems.indexOf(that.extraItems.find( e => e.id == id)), 1) ;
            }
          }
        });
      });
      return;
    }
  }

  gotoGlobalLineage() {
    let url  = 'data-lineage';
    if(this.id!= null && this.id!= 0) {
      let id = this.cryptoService.set(this.id);
      id = id.replace(/\//g, '__________');
      url = 'data-lineage/data-lineage-list/' + id + '/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==' ;
    } else {
      url = 'data-lineage/data-lineage-list/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==';
    }
   // console.log(url);
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }
  async loadSaveScriptModal() {
    const that = this;
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.LineDescription) {
        CKEDITOR.instances.LineDescription.destroy(true);
      }
      const editorDescription = CKEDITOR.replace('LineDescription');
      editorDescription.config.height = 60;
      editorDescription.on( 'change', evt => {
        this.saveFrm.get('description').patchValue(evt.editor.getData());
      });
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      $('#lineEffective').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function(e, date) {
          that.saveFrm.get('lineEffective').patchValue(formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en'));
        });
    });
  }

  changeEnabled(event: Event) {
    const checked = $(event.target).prop('checked');
    this.saveFrm.get('enabledLine').patchValue(checked)

  }
  addLoader() {
    $('app-root').last().append('<div id="divGlobal"></div>');
    // $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6) url("assets/images/loader/740.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
    $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6)', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0}).append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
    $("#loader").html($('#loaderContainer').html());
  }
  removeLoader(){
    $('#divGlobal').remove();
  }

  removeDataLineage() {
    let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
    Swal.fire({
        title: this.lang === 'en' ? 'DELETE this Data Lineage?' : 'SUPPRIMER cette lignée de données?',
        text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
        cancelButtonText: cancelButtonText
    }).then((result) => {
      if (result.value) {
        this.dataLineService.removeDataLineage(this.id, 'check').subscribe(
          d => {
            if(d['success'] == 'this dataLineage is associated with some projects'){
              let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
              Swal.fire({
                title: this.lang === 'en' ? 'DataLineage is associated with some projects?' : 'DataLineage est associé à certains projets?',
                text: this.lang === 'en' ? 'This dataLineage is associated with some projects!\nWould you like to delete it even from these projects?' : 'Cette lignée de données est associée à certains projets!\nVoulez-vous la supprimer même de ces projets?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.lang === 'en' ? 'Yes, proceed!' : 'Oui, procédez!',
                cancelButtonText: cancelButtonText
              }).then((result) => {
                if(result.value){
                  this.dataLineService.removeDataLineage(this.id, 'forceDelete').subscribe(
                    d =>{
                    Swal.fire({
                        title: this.lang === 'en' ? 'Success!' : 'Succès!',
                        text: this.lang === 'en' ? 'Deleted with success' : 'Supprimé avec succès',
                        icon: 'success',
                        confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
                    });
                      refreshComponent(this.router);
                    }
                  );
                }
              });
            } else if(d['success'] == 'OK'){
            Swal.fire({
                title: this.lang === 'en' ? 'Success!' : 'Succès!',
                text: this.lang === 'en' ? 'Deleted with success' : 'Supprimé avec succès',
                icon: 'success',
                confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
            });
              refreshComponent(this.router);
            }
          },
          error => {console.log(error); }
        );
      }
    });
  }
  saveAs(uri, filename) {

    const link = document.createElement('a');

    if (typeof link.download === 'string') {

      link.href = uri;
      link.download = filename;

      // Firefox requires the link to be in the body
      document.body.appendChild(link);

      // simulate click
      link.click();

      // remove the link when done
      document.body.removeChild(link);

    } else {
      window.open(uri);
    }
  }
  dataURItoBlob(dataURI) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);

    // create a view into the buffer
    var ia = new Uint8Array(ab);

    // set the bytes of the buffer to the correct values
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // write the ArrayBuffer to a blob, and you're done
    var blob = new Blob([ab], {type: mimeString});
    return blob;

  }
  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  getDataLineageList(id){
    this.loadingLine = true;
    this.dataLineService.getDataLineageList(id).subscribe(
      d => {
        this.dataLines = d;
        this.loadingLine = false;
        },
      err => {
        console.error(err);
      }
    );
  }
  updateTriggerStart(){
      this.clicked.trigger = this.ngTriggerExtra;
  }
  updateLinesStart(){
    this.clicked.lines = this.ngDataLinesExtra;
  }
  // correct play area width according to the horizontal bar
  correctPlacement() {
    const that = this;
    const offset = this.initialFlexBasis - parseFloat($('#play-area').css('flex-basis'));
    // console.log(offset);
    let step = 10;
    if (offset > 0) {
      step = -4;
    } else {
      step = 4;
    }
    $('.business-item').each(function() {
      const rectA = that.getBounds($(this));
      const rectB = that.getBounds($('#shapes-panel'));
      if (!that.hitTest(rectA, rectB)) {
        const deplacement = parseFloat($(this).css('left')) + step + 'px';
        $(this).css('left',  deplacement );
      }
   //   console.log(that.hitTest(rectA, rectB));
    });
    $('.node-item').each(function() {
      const rectA = that.getBounds($(this));
      const rectB = that.getBounds($('#shapes-panel'));
      if (!that.hitTest(rectA, rectB)) {
        const deplacement = parseFloat($(this).css('left')) + step + 'px';
        $(this).css('left',  deplacement );
      }
    });
    this.initialFlexBasis = parseFloat($('#play-area').css('flex-basis'));
    $('#toolbar-ng').width($('#shapes-panel').width() + $('#play-area').width() + $('#propriety-panel').width() + 20);
    this.updateConnectors();
  }
  getBounds(el) {
    const pos = el.position();
    return {
      x: pos.left,
      y: pos.top,
      w: el.width(),
      h: el.height()
    };
  }

  /*
      Checks for overlap on two rectangles
  */
  hitTest(rectA, rectB) {

    const rA = rectA.x + rectA.w; // Right side of rectA
    const rB = rectB.x + rectB.w; // Right side of rectB
    const bA = rectA.y + rectA.h; // Bottom of rectA
    const bB = rectB.y + rectB.h; // Bottom of rectB

    const hitX = rA > rectB.x && rectA.x < rB; // True if hit on x-axis
    const hitY = bA > rectB.y && rectA.y < bB; // True if hit on y-axis

    // Return true if hit on x and y axis
    return hitX && hitY;
  }
  initToolTip(){
    setTimeout(() => this.showToolTip('.ng-select-tooltip'), 500);
  }
  showToolTip(id: string) {
    const that = this;
    $(id).off().on('mouseover', function(event) {
      $('#toolTipMap').html($(this).attr('data-title')).offset({top: event.clientY + that.yMousePos, left: event.clientX + that.xMousePos + 15 }).show();
    });
    $(id).on('mouseout', function() {
      $('#toolTipMap').offset({top: 0, left: 0}).html('').hide();
    });
  }
  hideToolTip(){
    $('#toolTipMap').offset({top: 0, left: 0}).html('').hide();
  }

  getScreenShot(action = 'download') {
    const convHtml = $('#play-area')[0].outerHTML;
    const bgColor = $('#play-area').css('background-color');
    const width = $('#play-area').css('flex-basis');
    this.dataLineService.getScreenShot(convHtml, this.edges, false, this.pattern, bgColor, width, this.id, action).subscribe(
      result => {
        //  console.log(result);
        FileSaver.saveAs(result, this.dataLineageName);
      },
      err => {
        console.error(err);
      }
    );
  }
  updateItemAddApp(e, id, type) {

    var tabM = [];

    // tabM = this.registerFormApp.get('metadataApps').value;
    //
    // tabM.forEach(element => {
    //
    //   if (element.idfield === id) {
    //     if (type === 'select') {
    //       var svs = e;
    //     } else {
    //       svs = e.target.value;
    //     }
    //     element.selectedvalue = svs;
    //
    //   }
    // });

    this.registerFormApp.get('metadataApps').patchValue(tabM);

  }
  onSubmitAppAsset() {
    // var desc = window.btoa(encodeURIComponent(this.registerFormApp.get('descApp').value));
    if (this.descriptionNewSystem != '') {
      this.registerFormApp.get('descApp').patchValue(btoa(this.descriptionNewSystem));
    } else {
      this.registerFormApp.get('descApp').patchValue(null);
    }
    if (this.descriptionNewModuleSystem != '') {
      this.registerFormApp.get('descriptionModuleSystem').patchValue(btoa(this.descriptionNewModuleSystem));
    } else {
      this.registerFormApp.get('descriptionModuleSystem').patchValue(null);
    }
    const effectiveDateNew = this.registerFormApp.get('effectDateNewApp').value;
    const endDateNew = this.registerFormApp.get('endDateNewApp').value;
    this.registerFormApp.get('external').patchValue(true);
    // const today = new Date();
    if (effectiveDateNew > this.todayDate) {
      this.registerFormApp.get('statusApp').patchValue(false);
    } else if (endDateNew != '') {
      if (endDateNew < this.todayDate) {
        this.registerFormApp.get('statusApp').patchValue(false);
      } else {
        this.registerFormApp.get('statusApp').patchValue(true);
      }
    } else {
      this.registerFormApp.get('statusApp').patchValue(true);
    }
    const username = localStorage.getItem('username');
    this.registerFormApp.get('createdBy').patchValue(username);
    const subscription16$ = this.appService.addApp(JSON.stringify(this.registerFormApp.value)).subscribe(
      d => {
        this.descriptionNewSystem = '';
        this.descriptionNewModuleSystem = '';
        this.getApplicationsByType('YES');
      },
      err => {
      },
      () => {

        this.filterDefinition.get('filterReport').patchValue('');
        this.filterDefinition.get('filterBase').patchValue('');
        this.filterDefinition.get('filterEnv').patchValue('');
        this.filterDefinition.get('filterApplication').patchValue('');
        this.filterDefinition.get('filterOneApp').patchValue('');
        this.resetbtnDef = false;
        this.startScript();
        $('#paginate_Apps').css('display', 'block');
        $('#model_page_sizeAp').css('display', 'block');
      }
    );
    this.subscription.add(subscription16$);
    // display form values on success
    this.registerFormApp.get('nameApp').patchValue('');
    this.registerFormApp.get('effectDateNewApp').patchValue('');
    this.registerFormApp.get('endDateNewApp').patchValue('');
    this.registerFormApp.get('versionApp').patchValue('');
    this.registerFormApp.get('statusApp').patchValue(true);
    this.registerFormApp.get('external').patchValue(true);
    this.registerFormApp.get('typeApp').patchValue('');
    this.registerFormApp.get('deploymentApp').patchValue('');
    this.registerFormApp.get('descApp').patchValue('');
    this.registerFormApp.get('moduleApp').patchValue('');
    this.descriptionNewModuleSystem = '';
    this.registerFormApp.get('manager').patchValue('');
    this.registerFormApp.get('owner').patchValue('');
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.onLoadDescriptionFoAllSystem('newSystem', 'descriptionSystem');
    setTimeout(() => {
      this.onLoadDescriptionFoAllSystem('newSystem', 'descriptionModuleSystem');
    }, 500);


  }
  // start ckeditor for all descriptions
  async onLoadDescriptionFoAllSystem(emplacement, description) {
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']]]; // , '-', 'NumberedList'
      setTimeout(() => {
        this.loadDescriptions(emplacement, description);
      }, 1000);
    }).catch(error => console.error(error));
  }
  private loadDescriptions(emplacement, nameDescription) {
    const that = this;
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances[nameDescription]) {
      CKEDITOR.instances[nameDescription].destroy(true);
    }
    let editor = CKEDITOR.replace(nameDescription, {toolbar: 'MA'});
    editor.config.height = 50;
    ////////////////////--------------------/////////////////////////
    if (emplacement === 'updateSystem') {
      if (this.descriptionUpdateSystem != null && this.descriptionUpdateSystem) {
        if (this.descriptionUpdateSystem.length > 0 || this.descriptionUpdateSystem != ' ') {
          // let decodeDesc = this.descriptionUpdateSystem.split(' ').join('+');
          editor.setData(this.descriptionUpdateSystem);
        }
      }
    }
    //////////////////----------------------///////////////////////////
    if (emplacement === 'updateVersionSystem') {
      if (this.descriptionUpdateVersion != null && this.descriptionUpdateVersion) {
        if (this.descriptionUpdateVersion.length > 0 || this.descriptionUpdateVersion != ' ') {
          // let decodeDescUpVer = this.descriptionUpdateVersion.split(' ').join('+');
          editor.setData(this.descriptionUpdateVersion);
        }
      }
    }
    //////////////////----------------------///////////////////////////
    if (emplacement === 'newVersionSystem') {
      if (this.descriptionNewVersion != null && this.descriptionNewVersion) {
        if (this.descriptionNewVersion.length > 0 || this.descriptionNewVersion != ' ') {
          // let decodeDescUpVer = this.descriptionUpdateVersion.split(' ').join('+');
          editor.setData(this.descriptionNewVersion);
        } else {
          editor.setData('');
        }
      } else {
        editor.setData('');
      }
    }
    //////////////////----------------------///////////////////////////
    if (emplacement === 'updateModuleSystem') {
      if (this.descriptionUpdateModule != null && this.descriptionUpdateModule) {
        if (this.descriptionUpdateModule.length > 0 || this.descriptionUpdateModule != ' ') {
          // let decodeDescUpVer = this.descriptionUpdateVersion.split(' ').join('+');
          editor.setData(this.descriptionUpdateModule);
        }
      }
    }
    //////////////////----------------------///////////////////////////
    if (emplacement === 'newModuleSystem') {
      if (this.descriptionNewModule != null && this.descriptionNewModule) {
        if (this.descriptionNewModule.length > 0 || this.descriptionNewModule != ' ') {
          // let decodeDescUpVer = this.descriptionUpdateVersion.split(' ').join('+');
          editor.setData(this.descriptionNewModule);
        } else {
          editor.setData('');
        }
      } else {
        editor.setData('');
      }
    }

    ////////////////////--------------------//////////////////////////
    editor.on('change', function (evt) {
      if (nameDescription == 'descriptionSystem') {
        // console.log('-- system -- ');
        that.descriptionNewSystem = evt.editor.getData();
        // console.log(that.descriptionNewSystem);
      } else if (nameDescription == 'descriptionModuleSystem') {
        that.descriptionNewModuleSystem = evt.editor.getData();
        // console.log('--- module ---');
        // console.log(that.descriptionNewModuleSystem);
      }
      if (emplacement === 'updateSystem') {
        that.descriptionUpdateSystem = evt.editor.getData();
        that.editedDescApp = true;
        // console.log('--- detail description app ---');
        // console.log(that.descriptionUpdateSystem);
        // console.log(btoa(that.descriptionUpdateSystem));
      }
      if (emplacement === 'updateVersionSystem') {
        that.descriptionUpdateVersion = evt.editor.getData();
        // that.editedDescApp = true;
        // console.log('--- detail description update version ---');
        // console.log(that.descriptionUpdateVersion);
        // console.log(btoa(that.descriptionUpdateVersion));
      }
      if (emplacement === 'updateModuleSystem') {
        that.descriptionUpdateModule = evt.editor.getData();
        // that.editedDescApp = true;
        // console.log('--- detail description update module ---');
        // console.log(that.descriptionUpdateModule);
        // console.log(btoa(that.descriptionUpdateModule));
      }
      if (emplacement === 'newVersionSystem') {
        that.descriptionNewVersion = evt.editor.getData();
        // that.editedDescApp = true;
        // console.log('--- detail description new version ---');
        // console.log(that.descriptionNewVersion);
        // console.log(btoa(that.descriptionNewVersion));
      }
      if (emplacement === 'newModuleSystem') {
        that.descriptionNewModule = evt.editor.getData();
        // that.editedDescApp = true;
        // console.log('--- detail description new module ---');
        // console.log(that.descriptionNewModule);
        // console.log(btoa(that.descriptionNewModule));
      }
    });
  }
  async startScript() {
    await this.dynamicScriptLoader.load('form.min').then(data => {
      // this.loadData();
      var comp = this;
      // effective date new system
      $('#effectDateNewApp').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var effedate = '';
          if ($(date).attr('_d') != undefined) {
            effedate = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            effedate = '';
          }
          comp.registerFormApp.get('effectDateNewApp').patchValue(effedate);
          comp.checkEffectDateNewSys();
        });
      // end date new system
      $('#endDateNewApp').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var enddate = '';
          if ($(date).attr('_d') != undefined) {
            enddate = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            enddate = '';
          }
          comp.registerFormApp.get('endDateNewApp').patchValue(enddate);
          comp.checkEndDateNewSys();
        });
      // INSERT EFFECTIVE VERSION DATE
      $('#addAppVD').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var dateappVD = '';
          if ($(date).attr('_d') != undefined) {
            dateappVD = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            dateappVD = '';
          }
          comp.editFormVer.get('effectiveDate').patchValue(dateappVD);
          comp.checkVerDateU();
        });
      // INSERT END VERSION DATE
      $('#addAppVED').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var dateappVD = '';
          if ($(date).attr('_d') != undefined) {
            dateappVD = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            dateappVD = '';
          }
          comp.editFormVer.get('endDateApp').patchValue(dateappVD);
          comp.checkVerEndDateU();
        });
      // INSERT UPDATE END DATE MODULE
      $('#addAppModD').bootstrapMaterialDatePicker({
        format: 'YYYY/MM/D',
        clearButton: true,
        weekStart: 1,
        time: false,
        autoclose: true

      })
        .on('change', function (e, date) {
          var dateappVD = '';
          if ($(date).attr('_d') != undefined) {
            dateappVD = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          } else {
            dateappVD = '';
          }
          comp.editFormMod.get('endDate').patchValue(dateappVD);
          comp.checkModEndDateU();

        });

    }).catch(erro => console.error(erro));
  }
  checkEffectDateNewSys() {
    // console.log('check effective date');
    this.messagedangerNewSys = '';
    this.messagesuccesNewSys = '';
    const effectiveDateNew = this.registerFormApp.get('effectDateNewApp').value;
    const endDateNew = this.registerFormApp.get('endDateNewApp').value;
    if (effectiveDateNew != '') {
      // var x = this.versions.map(e => e.effectiveDate);
      // if (x.includes(effectiveDateNew)) {
      //     this.presubmitVer = true;
      //     this.messagedangerVer = effectiveDateNew + ' exist choose another date !';
      // } else
      if ((endDateNew !== null) && (endDateNew !== '') && (endDateNew !== undefined) && (effectiveDateNew > endDateNew)) {
        this.presubmitNewSystem = true;
        this.messagedangerNewSys = this.lang === 'en' ? 'Effective-Date must not be later than End-Date !' : 'La date effective ne doit pas être postérieure à la date de fin !';
      }  else {
        this.presubmitNewSystem = false;
        this.messagesuccesNewSys = this.lang === 'en' ? effectiveDateNew + ' is available !' : effectiveDateNew + ' est disponible !';
      }
    } else {
      this.presubmitNewSystem = true;
      this.messagedangerNewSys = this.lang === 'en' ? 'Effective Date is empty !' : 'La date effective est vide !';
    }
  }
  checkEndDateNewSys() {
    this.messagedangerNewSys = '';
    this.messagesuccesNewSys = '';
    var editdE = this.registerFormApp.get('effectDateNewApp').value;
    if (editdE != '') {
      const strdate = editdE.split('-');
      editdE = strdate[0];
    }
    var editd = this.registerFormApp.get('endDateNewApp').value;
    if ((editd == null) || (editd == '') || (editd == undefined)) {
      this.presubmitNewSystem = false;
      this.messagedangerNewSys = '';
      this.messagesuccesNewSys = '';
      this.registerFormApp.get('endDateNewApp').patchValue('');
    } else {
      if (editdE == '') {
        this.presubmitNewSystem = true;
        this.messagedangerNewSys = this.lang === 'en' ? 'Effective Date is empty !' : 'La date effective est vide !';
        this.registerFormApp.get('endDateNewApp').patchValue('');
      } else {
        if (editd > editdE) {

          this.presubmitNewSystem = false;
          this.messagedangerNewSys = '';
          this.messagesuccesNewSys = '';
        } else {
          this.presubmitNewSystem = true;
          this.messagedangerNewSys = this.lang === 'en' ? 'End date should be > of effective date !' : 'La date de fin doit être > à la date effective !';
          this.registerFormApp.get('endDateNewApp').patchValue('');
        }
      }
    }
  }
  checkVerDateU() {
    this.messagedangerVerU = '';
    this.messagesuccesVerU = '';
    var effDate = this.editFormVer.get('effectiveDate').value;
    // console.log(effDate);
    var endDate = this.editFormVer.get('endDateApp').value;
    // console.log('-----');
    // console.log(endDate);
    if (effDate != '') {
      var x = this.versions.map(e => e.effectiveDate);
      if (x.includes(effDate)) {
        this.presubmitVerU = true;
        this.messagedangerVerU = this.lang === 'en' ? effDate + ' exists, choose another date!' : effDate + ' existe, choisissez une autre date!';
      } else if ((endDate != null) && (endDate != '') && (endDate != undefined) && (effDate > endDate)) {
        this.presubmitVerU = true;
        this.messagedangerVerU = this.lang === 'en' ? 'Effective-Date must not be later than End-Date!' : 'La date effective ne doit pas être postérieure à la date de fin !';
      } else {
        this.presubmitVerU = false;
        this.messagesuccesVerU = this.lang === 'en' ? effDate + ' is available!' : effDate + ' est disponible !';
      }
    } else {
      this.presubmitVerU = true;
      this.messagedangerVerU = this.lang === 'en' ? 'Effactive Date is empty!' : 'La date effective est vide !';
    }
  }
  checkVerEndDateU() {
    this.messagedangerVerU = '';
    this.messagesuccesVerU = '';
    var editdE = this.editFormVer.get('effectiveDate').value;
    var editd = this.editFormVer.get('endDateApp').value;
    if (editdE == '') {
      this.presubmitVerU = true;
      this.messagedangerVerU = this.lang === 'en' ? editd + ' Effective date is empty !' : editd + ' La date effective est vide !';
    } else {
      if (editd == '') {
        this.presubmitVerU = false;
      } else if (editd > editdE) {
        this.presubmitVerU = false;
      } else if (editd < editdE) {
        this.presubmitVerU = true;
        this.messagedangerVerU = this.lang === 'en' ? 'End-Date must not be less than Effective-Date!' : 'La date de fin ne doit pas être inférieure à la date effective !';
      }
    }
  }
  checkModEndDateU() {
    this.messagedangerModU = '';
    this.messagesuccesModU = '';
    var editdE = this.editFormMod.get('effectiveDate').value;
    if (editdE != '') {
      const strdate = editdE.split('-');
      editdE = strdate[0];
    }
    var editd = this.editFormMod.get('endDate').value;
    if ((editd == null) || (editd == '') || (editd == undefined)) {
      this.presubmitModU = false;
      this.messagedangerModU = '';
      this.messagesuccesModU = '';
      this.editFormMod.get('endDate').patchValue('');
    } else {
      if (editdE == '') {
        this.presubmitModU = true;
        this.messagedangerModU = this.lang === 'en' ? ' Effective date is empty !' : ' La date effective est vide !';
        this.editFormMod.get('endDate').patchValue('');
      } else {
        if (editd > editdE) {

          this.presubmitModU = false;
          this.messagedangerModU = '';
          this.messagesuccesModU = '';
        } else {
          this.presubmitModU = true;
          this.messagedangerModU = this.lang === 'en' ? 'End date should be > of effective date !' : 'La date de fin doit être > à la date effective !';
          this.editFormMod.get('endDate').patchValue('');
        }
      }
    }
  }
  onAddNewSystem() {
    this.messagedangerAdd = '';
    this.messagesuccesAdd = '';
    this.messagedangerNewSys = '';
    this.messagesuccesNewSys = '';
    $('#appModal').modal('show');
    this.startScript();
    this.onLoadDescriptionFoAllSystem('newSystem', 'descriptionSystem');
    setTimeout(() => {
      this.onLoadDescriptionFoAllSystem('newSystem', 'descriptionModuleSystem');
    }, 500);
  }

}
