import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { CryptoService } from '../../crypto.service';
import { Profile } from '../../models/profile';
import { RouterhistoryService } from '../../services/appService/routerhistory.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TipsService } from '../../services/tips/tips.service';
import { DynamicScriptLoaderService } from '../../dynamic-script-loader-service.service';
import {getCookieValue} from "../../shared";
declare const PlainDraggable: any;
declare const LeaderLine: any;
declare const $: any;
declare const CKEDITOR: any;
@Component({
  selector: 'app-main-anonym',
  templateUrl: './main-anonym.component.html',
  styleUrls: ['./main-anonym.component.css']
})
export class MainAnonymComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription = new Subscription();
  lang: string;
  updateText = true;
  selectedUpdateText = 'text';
  loadingExecute = false;
  descrTextByName: string;
  uploadedImages = [];
  // roles
  adminoRoleStruct = false;
  adminoRoleModel = false;
  appdefRole = false;
  appflowrole = true;
  infraRole = false;
  settingrole = false;
  structoRole = false;
  classificationRole = false;
  rulesRole = false;
  retentionRole = false;
  definitionCalssificationRole = false;
  securityRole = false;
  objRole = false;
  designerRole = false;
  lifeCycle = false;
  projectRole = false;
  metadataRole = false;
  holderRole = false;
  businessTermRole = false;
  dataLineageRole = false;
  dimensionRole = false;
  countryRole = false;
  dataCatalogueRole = false;
  entityRole = false;
  diagramSoftwareRole = false;
  userRole = false;
  roleRole = false;
  stackRole = false;
  functionalMapRole = false;
  archiveDeleteRole = false;
  // end role
  profileData: any;
  profile: Profile;
  roleCurrentUser: string;
  // leaderLine
  lines = [];
  indexTips = 0;
  descriptionZoneGlob = [
    {
      id: 'leftsidebar',
      icon: '<i class="fa fa-bars" aria-hidden="true"></i>',
      title: 'Left sidebar',
      desc: 'Main Menu, contains all components'
    },
    {
      id: 'switchLang',
      icon: '<i class="far fa-flag" style="color: darkblue;"></i>',
      title: 'Switch Language',
      desc: 'list of flags to choose language'
    },
    {
      id: 'zoneNotification',
      icon: '<i class="far fa-envelope" style="color: darkblue;"></i>',
      title: 'Massage Notification',
      desc: 'Notification by message'
    },
    {
      id: 'zoneFullscreen',
      icon: '<i class="fas fa-expand" style="color: darkblue;"></i>',
      title: 'Full Screen',
      desc: 'Full screen or minimized'
    },
    {
      id: 'zoneGlobalsearch',
      icon: '<i class="fas fa-search" style="color: darkblue;"></i>',
      title: 'Global Serach',
      desc: 'Search, search history, history navigation'
    },
    {
      id: 'zoneProfile',
      icon: '<i class="far fa-id-badge" style="color: darkblue;"></i>',
      title: 'User Profile',
      desc: 'Show profile, change password, logout'
    },
    {
      id: 'zoneVersioning',
      icon: '<i class="fas fa-cog" style="color: darkblue;"></i>',
      title: 'Setting and Versioning',
      desc: 'Versoning of application and setting of template'
    },
  ];
  constructor(private cryptoService: CryptoService, private authenticationService: AuthenticationService,
    private translate: TranslateService, private routerHistoryService: RouterhistoryService,
    private router: Router, private dynamicScriptLoader: DynamicScriptLoaderService, private serviceTips: TipsService) { }

  ngOnInit() {

    // start user profile
    this.profileData = 'username=' + getCookieValue('username');
    this.authenticationService.getUserProfile(this.profileData).subscribe(
      result => {
        this.profile = result;
      },
      (err) => {
      },
      () => {
        var user_login = getCookieValue('username');
        if(this.profile.emailName != ''){
          var parametres = {
            user_email: this.profile.emailName,
            user_login: user_login,
          };
        }
        window.localStorage.setItem('userId', this.profile.userId);
        window.localStorage.setItem('firstName', this.profile.firstName);
        window.localStorage.setItem('lastName', this.profile.lastName);
        window.localStorage.setItem('email', this.profile.emailName);
        window.localStorage.setItem('roles', this.profile.roles);
        this.roleCurrentUser = this.profile.roles;
        // @ts-ignore
        const userRole = this.cryptoService.decryptPhp(this.profile.userRole);
        // @ts-ignore
        window.sessionStorage.setItem('menu', this.profile.userRole);
        // @ts-ignore
        window.sessionStorage.setItem('appAsset', this.cryptoService.set(JSON.stringify(userRole.appsRole)));
        if (sessionStorage.hasOwnProperty('menu')) {
          const menu = window.sessionStorage.getItem('menu');
          const userRole = this.cryptoService.decryptPhp(menu);
          // console.log(userRole);
          this.adminoRoleStruct = userRole.modelAdminStruct;
          this.adminoRoleModel = userRole.modelAdminModel;
          this.structoRole = userRole.modelStruc;
          this.objRole = userRole.objectModel;
          this.designerRole = userRole.designer;
          this.diagramSoftwareRole = userRole.diagramSoftware;
          this.appdefRole = userRole.applDef;
          if (this.appdefRole == true) {
            localStorage.setItem('appdefRole', 'true');
          } else {
            localStorage.setItem('appdefRole', 'false');
          }
          this.appflowrole = userRole.applFlow;
          this.infraRole = userRole.infra;
          this.settingrole = userRole.setting;
          this.classificationRole = userRole.classification;
          this.definitionCalssificationRole = userRole.classificationDefinition;
          this.rulesRole = userRole.rule;
          this.retentionRole = userRole.retention;
          this.securityRole = userRole.security;
          this.lifeCycle = userRole.lifeCycle;
          this.projectRole = userRole.project;
          this.metadataRole = userRole.meta;
          this.stackRole = userRole.stack;
          this.holderRole = userRole.holder;
          this.userRole = userRole.userList;
          this.roleRole = userRole.roleList;
          this.businessTermRole = userRole.businessTerm;
          this.dataLineageRole = userRole.dataLineage;
          this.dimensionRole = userRole.dimension;
          this.countryRole = userRole.country;
          this.dataCatalogueRole = userRole.dataCatalogue;
          this.entityRole = userRole.entity;
          this.functionalMapRole = userRole.functionalMap;
          this.archiveDeleteRole = userRole.archiveDelete;
        }
        // if (localStorage.getItem('redirectToSearch') == 'true') {
        this.router.navigate(['/dashboard/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/dashboard2']);
        // }
        // delete
        // else {
        //   localStorage.setItem('redirectToSearch', 'true');
        //   this.router.navigate(['global-search']);
        // }
        // delete
      }
    );
    // end user profile
    // this.detectChangeLaguage();
    // set default tips status
    localStorage.setItem('dontShowTipsSearch', 'true');
    localStorage.setItem('dontShowTipsDash', 'true');
    localStorage.setItem('dontShowTipsModelAdmin', 'true');
    localStorage.setItem('dontShowTipsDdtm', 'true');
    localStorage.setItem('dontShowTipsObjectModel', 'true');
    localStorage.setItem('dontShowTipsModelDesigner', 'true');
    localStorage.setItem('dontShowTipsDiagramDesigner', 'true');
    localStorage.setItem('dontShowTipsArchSysDef', 'true');
    localStorage.setItem('dontShowTipsArchDataFlow', 'true');
    localStorage.setItem('dontShowTipsArchInfras', 'true');
    localStorage.setItem('dontShowTipsDrmDef', 'true');
    localStorage.setItem('dontShowTipsDrmClassif', 'true');
    localStorage.setItem('dontShowTipsDrmRules', 'true');
    localStorage.setItem('dontShowTipsDrmRetention', 'true');
    localStorage.setItem('dontShowTipsDrmSecurity', 'true');
    localStorage.setItem('dontShowTipsGovProject', 'true');
    localStorage.setItem('dontShowTipsGovProject', 'true');
    localStorage.setItem('dontShowTipsGovSteward', 'true');
    localStorage.setItem('dontShowTipsGovLineage', 'true');
    localStorage.setItem('dontShowTipsFunctionalmap', 'true');
    localStorage.setItem('dontShowTipsGovGlossary', 'true');
    localStorage.setItem('dontShowTipsGovCatalog', 'true');
    localStorage.setItem('dontShowTipsGovEntity', 'true');
    localStorage.setItem('dontShowTipsGovRole', 'true');
    localStorage.setItem('dontShowTipsGovDimension', 'true');
    localStorage.setItem('dontShowTipsGovCountry', 'true');
    localStorage.setItem('dontShowTipsCampain', 'true');
    localStorage.setItem('dontShowTipsUsers', 'true');
    localStorage.setItem('dontShowTipsRoles', 'true');
    localStorage.setItem('dontShowTipsMetadata', 'true');
    localStorage.setItem('dontShowTipsMessage', 'true');

    // hide modifs buttons in modal tips main
    $('.doEdit').css('display', 'block');
    $('.undoEdit').css('display', 'none');
    this.detectChangeLaguage();
  }

  changeIconUpdateTips(clicked) {
    switch (clicked) {
      case 'doEdit': {
        $('.doEdit').css('display', 'block');
        $('.undoEdit').css('display', 'none');
        $('#descTipsMain').css({ opacity: 1, height: '80%', width: '100%' });
        $('#cke_descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
        $('#descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
        $('#zoneUpdateImageTipsMain').css({ opacity: 0, height: 0, width: 0 });
        $('#zoneUpdateVideoTipsMain').css({ opacity: 0, height: 0, width: 0 });
        this.updateText = true;
        this.loadingExecute = false;
        // console.log('retour');
        break;
      }
      case 'undoEdit': {
        $('.doEdit').css('display', 'none');
        $('.undoEdit').css('display', 'block');
        // $('#descTipsMain').css('display', 'block').css({opacity: 1, height: '80%', width: '100%'});
        // $('#cke_descTipsMainEditor').css({opacity: 0, height: 0, width: 0});
        // $('#descTipsMainEditor').css({opacity: 0, height: 0, width: 0});
      }
    }
  }
  showUpdateTextTips() {
    this.selectedUpdateText = 'text';
    this.updateText = false;
    this.loadingExecute = true;
    this.serviceTips.getTipsPerName('Main').subscribe(
      res => {
        const resVersion = res[0].version;
        const indexLastVersion = resVersion.length - 1;
        const selectedVersion = resVersion[indexLastVersion];

        const description = selectedVersion.description;
        // console.log(description);
        this.startScriptCkEditor(description);
      },
      err => {
        console.error(err);
        this.loadingExecute = false;
      },
      () => {
        $('#descTipsMain').css({ opacity: 0, height: 0, width: 0 });
        $('#cke_descTipsMainEditor').css({ opacity: 1, height: '80%', width: '100%' });
        $('#descTipsMainEditor').css({ opacity: 1, height: '80%', width: '100%' });
        this.loadingExecute = false;
      }
    );
  }

  // CKEditor add
  async startScriptCkEditor(desc) {
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Link', 'Unlink', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', 'Superscript', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock'], '-', 'NumberedList']];
      this.loadDataCkEditor(desc);
    }).catch(error => console.error(error));
  }
  private loadDataCkEditor(descr) {
    const that = this;
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['descTipsMainEditor']) {
      CKEDITOR.instances['descTipsMainEditor'].destroy(true);
    }
    var editor = CKEDITOR.replace('descTipsMainEditor', { toolbar: 'MA' });
    editor.config.height = 50;
    editor.setData(descr);
    editor.on('change', function (evt) {
      // $('#descTipsMain').html(evt.editor.getData());
      that.descrTextByName = btoa(evt.editor.getData());
    });
  }

  saveUpdateTipsText(module) {
    this.updateText = false;
    this.loadingExecute = true;
    const dataUpdateText = {
      name: module,
      descrTextByName: this.descrTextByName
    };
    // console.log(this.descrTextByName);
    this.serviceTips.updateTextDescTipsPerName(dataUpdateText).subscribe(
      result => {
        // console.log(result);
        $('#descTipsMain').html(atob(this.descrTextByName)).css({ opacity: 1, height: '100%', width: '100%' });
        $('#cke_descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
        $('#descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
      },
      err => {
        console.error(err);
        this.updateText = true;
        this.loadingExecute = false;
      },
      () => {
        $('#descTipsMain').css({ opacity: 1, height: '100%', width: '100%' });
        $('#cke_descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
        $('#descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
        this.updateText = true;
        this.loadingExecute = false;
      }
    );
  }

  showUpdateImageTips() {
    this.selectedUpdateText = 'image';
    this.updateText = false;
    $('#descTipsMain').css({ opacity: 0, height: 0, width: 0 });
    $('#cke_descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
    $('#descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
    $('#zoneUpdateImageTipsMain').css({ opacity: 1, height: '100%', width: '100%' });
    $('#zoneUpdateVideoTipsMain').css({ opacity: 0, height: 0, width: 0 });
  }
  onAddUploadImageTips(event) {
    // addLoader('vide', 'f', true);
    const countImage = event.addedFiles.length;
    for (let i = 0; i < event.addedFiles.length; i++) {
      this.uploadedImages.push({ 'file': event.addedFiles[i], 'etat': true });
      //submit
      // this.reportService.uploadReportFiles(event.addedFiles[i]).subscribe(
      //   data => {
      //     if (data != 'saved success') {
      //       this.listApplicationNotExist.push(data);
      //     }
      //     this.count--;
      //     if (this.count==0){
      //     }
      //     this.filesreport.push({'file':event.addedFiles[i],'etat':true});
      //   },
      //   err => {
      //     this.count--;
      //     if (this.count==0){
      //     }
      //     this.filesreport.push({'file':event.addedFiles[i],'etat':false});
      //     if (i === event.addedFiles.length - 1) {
      //       const Toast = Swal.mixin({
      //         toast: true,
      //         position: 'top-end',
      //         showConfirmButton: false,
      //         timer: 3000,
      //         timerProgressBar: true,
      //         onOpen: (toast) => {
      //           toast.addEventListener('mouseenter', Swal.stopTimer);
      //           toast.addEventListener('mouseleave', Swal.resumeTimer);
      //         }
      //       });
      //
      //       Toast.fire( {
      //         icon: 'error',
      //         title: 'File format must be .txt'
      //       });
      //     }
      //   },
      //   () => {
      //     if (this.count == 0) {
      //       this.onSetCountTablesForAllReports();
      //     }
      //   }
      // );
    }
  }
  showUpdateVideoTips() {
    this.selectedUpdateText = 'video';
    this.updateText = false;
    $('#descTipsMain').css({ opacity: 0, height: 0, width: 0 });
    $('#cke_descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
    $('#descTipsMainEditor').css({ opacity: 0, height: 0, width: 0 });
    $('#zoneUpdateImageTipsMain').css({ opacity: 0, height: 0, width: 0 });
    $('#zoneUpdateVideoTipsMain').css({ opacity: 1, height: '100%', width: '100%' });
  }
  detectChangeLaguage() {
    $('#switchLang').html('<img id="zoneSwitchLang" height="13" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMCAIAAADgcHrrAAAAK3RFWHRDcmVhdGlvbiBUaW1lAHdvIDI4IGRlYyAyMDA1IDE5OjE1OjU1ICswMTAwahKd2QAAAAd0SU1FB9UMHBISI5QpVKUAAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAEZ0FNQQAAsY8L/GEFAAAAZElEQVR42t2RMQ7AIAhFP42n7lV6whqZtV1QaWyIg2XuC2EAXhg+AQdwAUV7R2THBNE0CHrd6n61T0S1os7pdBrJfPPCqm1+wfJnLdigPaQRQB4Z5GfBzGsnmW9FqxNjXGttXQHJPSkutxhpRwAAAABJRU5ErkJggg==" style="margin-top: -3px;">');
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      $('body').css('position', '');
    }, 1000);
  }
  /**************************************** Start tips global application *************************************/
  // set new etat tips main systnaps
  onUpdateTipsMain() {
    let tipsMain;
    if ($('#checked_show_app').is(':checked')) {
      tipsMain = false;
    } else {
      tipsMain = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsMain
    };
    this.routerHistoryService.updateTipsMain(dataPut).subscribe(
      data => {
        // console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  activeTipsGlobal(index) {
    // video.pause();
    $('#tipsAllApplication').modal('hide');
    let topPosition = window.scrollY + document.querySelector('#' + this.descriptionZoneGlob[index].id).getBoundingClientRect().top;
    let leftPosition = window.scrollY + document.querySelector('#' + this.descriptionZoneGlob[index].id).getBoundingClientRect().left;
    this.addTipsHtmlGlobal(index, topPosition, leftPosition);
  }
  addTipsHtmlGlobal(index, top, left) {
    let divTips =
      '<div id="descTips" class="card bg-light mb-3" style="max-width: 18rem; background-color: white!important;">' +
      ' <div class="card-header" style="text-align: center; height:30px !important; background-color:#18456b; color:white; border-radius: 8px;">' +
      '   <h6> <i class="fas fa-info-circle" style="color: white"></i>&nbsp;&nbsp;' + this.descriptionZoneGlob[index].title + '</h6>' +
      ' </div>' +
      ' <div class="card-body">' +
      '  <p class="card-text">' + this.descriptionZoneGlob[index].icon + '<br>' + this.descriptionZoneGlob[index].desc + '</p>' +
      ' </div>' +
      ' <div class="row">' +
      '   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding-left: 0!important; padding-right: 0 !important;">' +
      '   </div>' +
      '   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding-left: 0!important; padding-right: 0 !important;">' +
      '     <button id="btCancelTips" type="button" class="showDt btn-hover btn-border-radius color-2 bt-bt10" style="width: 58px; height: 25px; font-size: 9px !important;">Cancel</button>' +
      '   </div>' +
      '   <div class="col-lg-4 col-md-4 col-sm-4 col-xs-4" style="padding-left: 0!important; padding-right: 0 !important;">' +
      '     <button id="btNextTips" data-index="' + (index + 1) + '" class="showDt btn-hover btn-border-radius color-8 bt-bt10" style="width: 58px; height: 25px; font-size:9px !important; float: right;">Next</button>' +
      '   </div>' +
      ' </div>' +
      '</div>';
    $('app-root').last().append('<div id="descContainer"></div>');
    $('#descContainer').css({ width: '100%', height: '100%', background: 'rgba(0, 0, 0, .3)', 'z-index': 10000, position: 'fixed', top: 0, left: 0 }).append(divTips);
    $('#' + this.descriptionZoneGlob[index].id).css({ 'box-shadow': '0 0 0 2000px rgb(0 0 0 / 60%)', 'border-radius': '25%' });

    $('#descTips').css('transform', 'translate(' + (left - 111) + 'px, ' + (top + 105) + 'px');
    // if (index == 6) {
    //   $('#tipsCompSearch').modal('show');
    // $('#btNextTips').css('display', 'none');
    // }
    // else {
    //   $('#btNextTips').css('display', 'inline');
    // }
    // const lineTips = new LeaderLine(
    //   document.getElementById('descTips'),
    //   document.getElementById('zoneSwitchLang'),
    //   {
    //     endPlugColor: '#17a2b8',
    //     startPlugColor: '#26D9FF',
    //     gradient: true,
    //     dropShadow: {color: 'white', dx: 0, dy: 5},
    //   }
    // );
    const lineTips = new LeaderLine(
      LeaderLine.mouseHoverAnchor(document.getElementById('descTips'), { showEffectName: 'draw' }),
      document.getElementById(this.descriptionZoneGlob[index].id),
      {
        endPlugColor: '#2a7abc',
        startPlugColor: '#18456b',
        gradient: true,
        dropShadow: { color: 'white', dx: 0, dy: 5 },
      });
    this.lines.push(lineTips);
    let that = this;
    // next tips
    $('#btNextTips').click(function () {
      var index = parseInt($(this).attr('data-index'), 10);
      lineTips.remove();
      that.lines = [];
      $('#descContainer').remove();
      $('#' + that.descriptionZoneGlob[index - 1].id).css({ 'box-shadow': 'unset', 'border-radius': 'unset' });
      if (index < that.descriptionZoneGlob.length) {
        let topPos = window.scrollY + document.querySelector('#' + that.descriptionZoneGlob[index].id).getBoundingClientRect().top;
        let leftPos = window.scrollY + document.querySelector('#' + that.descriptionZoneGlob[index].id).getBoundingClientRect().left;
        that.addTipsHtmlGlobal(index, topPos, leftPos);
      } else {
        that.router.navigate(['/dashboard/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/dashboard2']);
      }
    });
    // cancel tips
    $('#btCancelTips').click(function () {
      lineTips.remove();
      that.lines = [];
      $('#descContainer').remove();
      $('#' + that.descriptionZoneGlob[index].id).css({ 'box-shadow': 'unset', 'border-radius': 'unset' });
      that.router.navigate(['/dashboard/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/dashboard2']);
    });
    new PlainDraggable(document.getElementById('descTips'), {
      onMove() {
        lineTips.position();
      },
      containment: document.getElementById('#searchContent')
    });
    $(window).scroll(() => { this.lines.map(item => { item.position(); }); });
  }
  redirectToSearch() {
    // video.pause();
    this.router.navigate(['/dashboard/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/dashboard2']);
  }
}
