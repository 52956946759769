import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";
import {JwtHelperService} from "@auth0/angular-jwt";
import {Observable} from "rxjs";
import {getCookieValue, Shared} from "../../shared";
import {CampainJson} from "../../data-life-cycle/campain/CampainJson";
import {projectJson} from "../../data-governance/projects/projectJson";


@Injectable({
  providedIn: 'root'
})
export class DataGovernanceService {

  constructor(private http: HttpClient, private  router: Router, public jwtHelper: JwtHelperService) { }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  };
  getAllProjects(dimension: string, entities: string) : Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/projects/getAllProjects/' + localStorage.getItem('userId') + '/' + dimension +  '/' + entities , this.getOptions());
  }
  addProject(project: projectJson): Observable<any> {
    const data = "data=" + JSON.stringify(project);
    return this.http.post<any>(Shared.API_URL + '/api/projects/addProject/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  updateProject(project: projectJson): Observable<any> {
    const data = "data=" + JSON.stringify(project);
    return this.http.put<any>(Shared.API_URL + '/api/projects/updateProject/' + getCookieValue('username') , data, this.getOptions());
  }
  removeProject(id: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/projects/removeProject/' + id, this.getOptions());
  }
  editProject(id: string): Observable<any> {
    return this.http.put<any>(Shared.API_URL + '/api/projects/editProject/' + id, this.getOptions());
  }
  // check project
  checkProject(name: string) {
    return this.http.get(Shared.API_URL + '/api/projects/checkProject/' + name,  this.getOptions());
  }
  getApplicationForListModels(): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getApplicationForListModels/' + user, this.getOptions());
  }
  getObjectModelsByApp(app: string) {
    const user = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/projects/getObjectModelsByApp/' + user + '/' + app,  this.getOptions());
  }
  getAppsForReports() {
    const user = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/projects/getAppsForReports/' + user ,  this.getOptions());
  }
  getReportsByApp(app: string) {
    const user = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/projects/getReportsByApp/' + user + '/' + app,  this.getOptions());
  }
  getCampain(app: string) {
    const user = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/projects/getCampain/' + user + '/' + app  , this.getOptions());
  }
  getTasksByCampain(id: string, app: string) {
    const user = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/projects/getTasksByCampain/' + id + '/' + user + '/' + app,  this.getOptions());
  }
  getProjectModels(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/projects/getProjectModels/' + id + '/' + localStorage.getItem('userId'),  this.getOptions());
  }
  getProjectReports(id: string, app: string) {
    return this.http.get(Shared.API_URL + '/api/projects/getProjectReports/' + id + '/' + app  +  '/' + localStorage.getItem('userId'),  this.getOptions());
  }
  getProjectCampains(id: string) : Observable<any>{
    return this.http.get<any>(Shared.API_URL + '/api/projects/getProjectCampains/' + id + '/' + localStorage.getItem('userId') ,  this.getOptions());
  }
  getProjectStakeHolders(id: string) {
    return this.http.get(Shared.API_URL + '/api/projects/getProjectStakeHolders/' + id ,  this.getOptions());
  }
  getApplications(){
    return this.http.get(Shared.API_URL + '/api/projects/getApplications/' + localStorage.getItem('userId') ,  this.getOptions());
  }
  getCampainsByModels(models): Observable<any> {
    const data = "data=" + JSON.stringify(models);
    return this.http.put<any>(Shared.API_URL + '/api/projects/getCampainsByModels/' + localStorage.getItem('userId')  , data, this.getOptions());
  }
  getUsers(){
    return this.http.get(Shared.API_URL + '/api/object-model/getUsers/' + localStorage.getItem('userId') ,  this.getOptions());
  }

  getProjectOwner(app: string) {
    return this.http.get(Shared.API_URL + '/api/projects/getProjectOwner/' + app ,  this.getOptions());
  }
  getDataLineages() {
    return this.http.get(Shared.API_URL + '/api/projects/getDataLineages'  ,  this.getOptions());
  }
  getProjectDataLineages(id: string) {
    return this.http.get(Shared.API_URL + '/api/projects/getProjectDataLineages/' + id + '/' + localStorage.getItem('userId')  ,  this.getOptions());
  }
  getEntitieEcosystem() {
    return this.http.get(Shared.API_URL + '/api/projects/getEntitieEcosystem'  ,  this.getOptions());
  }
  packedDimensionByProject() {
    return this.http.get(Shared.API_URL + '/api/projects/packedDimensionByProject/' + localStorage.getItem('userId') ,  this.getOptions());
  }
  getProjectDimensions() {
    return this.http.get(Shared.API_URL + '/api/projects/getProjectDimensions' ,  this.getOptions());
  }
  getProjectDimensionsByName(name) {
    return this.http.get(Shared.API_URL + '/api/projects/getProjectDimensionsByName/' + name ,  this.getOptions());
  }
  getEntitiesFilter() {
    return this.http.get(Shared.API_URL + '/api/projects/getEntitiesFilter/'  + localStorage.getItem('userId'),  this.getOptions());
  }
  getProjectTemplates() {
    return this.http.get(Shared.API_URL + '/api/projectTemplate/getProjectTemplates' ,  this.getOptions());
  }
  checkProjectTemplate(name: string) {
    return this.http.get(Shared.API_URL + '/api/projectTemplate/checkProjectTemplate/'  + name,  this.getOptions());
  }
  addProjectTemplate(template: any): Observable<any> {
    const data = "data=" + JSON.stringify(template);
    return this.http.post<any>(Shared.API_URL + '/api/projectTemplate/addProjectTemplate' , data, this.getOptions());
  }
  updateProjectTemplate(template: any): Observable<any> {
    const data = "data=" + JSON.stringify(template);
    return this.http.put<any>(Shared.API_URL + '/api/projectTemplate/updateProjectTemplate' , data, this.getOptions());
  }
  removeProjectTemplate(id: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/projectTemplate/removeProjectTemplate/' + id , this.getOptions());
  }
  getProjectDetail(detailClass: string, detailRole: string, app: string) {
    const user = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/Projects/getProjectDetail/'  + detailClass + '/' + user + '/' + detailRole + '/' + app,  this.getOptions());
  }
  getProjectDetails(id: string, projectDetailClass: string, detailRole: string, detailClass: string, detail: string) {
    const user = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/Projects/getProjectDetails/'  + id + '/' + user + '/' + projectDetailClass + '/' + detailRole + '/' + detailClass + '/' + detail,  this.getOptions());
  }
  getProjectDetailsUpdate(id: string, detail: any) {
    const user = localStorage.getItem('userId');
    const data = "data=" + JSON.stringify(detail);
    return this.http.post(Shared.API_URL + '/api/Projects/getProjectDetailsUpdate/'  + id + '/' + user  , data,  this.getOptions());
  }
  getRules(id: string): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.post(Shared.API_URL + '/api/Projects/getRules/'  + id + '/' + user , null,  this.getOptions());
  }
  getSecurityRisk(id: string): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.post(Shared.API_URL + '/api/Projects/getSecurityRisk/'  + id + '/' + user , null,  this.getOptions());
  }
  getProjectCatalogs(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/projects/getProjectCatalogs/' + id + '/' + localStorage.getItem('userId'),  this.getOptions());
  }
}
