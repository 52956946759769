import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders,HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
import {local} from "d3-selection";

@Injectable({
  providedIn: 'root'
})
export class DdtmService {
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }

  constructor(private http: HttpClient) { }

    getTopVolumetricTablesSelectedReportsDdtm(): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report/getTopVolumetricTablesSelectedReportsDdtm', this.getOptions());
    }
    getReportListAllAttributes(application): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report/getReportListAllAttributes/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
    }
    getReportListByBD(application, bd): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report/getReportListByBD/' + localStorage.getItem('userId') + '/' + application + '/' + bd, this.getOptions());
    }
    getReportbyRecordsFields(params): Observable<any> {
      const data = "data="+JSON.stringify(params);
          return this.http.post(Shared.API_URL + '/api/report-detail/getReportbyRecordsFields',data,this.getOptions());
     }

    getReportListBySelectedReport(params): Observable<any> {
      const data = "data="+JSON.stringify(params);
          return this.http.post(Shared.API_URL + '/api/report/getReportListBySelectedReport',data,this.getOptions());
     }
    getReportTables(id, currentpage, search, sortColumn, sortOrder, length): Observable<any> {
      const idUser = localStorage.getItem('userId');
        return this.http.get(Shared.API_URL + `/api/report-detail/getReportTables/${id}/${idUser}/${currentpage}/${search}/${sortColumn}/${sortOrder}/${length}`, this.getOptions());
    }

    getReportFields(id, currentpage, search, sortColumn, sortOrder, length): Observable<any> {
      const idUser = localStorage.getItem('userId');
        return this.http.get(Shared.API_URL + `/api/report-detail/getReportFields/${id}/${idUser}/${currentpage}/${search}/${sortColumn}/${sortOrder}/${length}`, this.getOptions());
    }
    getReportRecords(id): Observable<any> {
      const idUser = localStorage.getItem('userId');
        return this.http.get(Shared.API_URL + `/api/report-detail/getReportRecords/${id}/${idUser}`, this.getOptions());
    }
    getReportRFields(id, records): Observable<any> {
      const idUser = localStorage.getItem('userId');
        return this.http.get(Shared.API_URL + `/api/report-detail/getReportRFields/${id}/${idUser}/${records}`, this.getOptions());
    }

    getSelectedTables(params): Observable<any> {
      const data = "data="+JSON.stringify(params);
          return this.http.post(Shared.API_URL + '/api/report-detail/getSelectedTables',data,this.getOptions());
     }

    getSelectedFields(params): Observable<any> {
      const data = "data="+JSON.stringify(params);
          return this.http.post(Shared.API_URL + '/api/report-detail/getSelectedFields',data,this.getOptions());
     }
    // filtres tt directions
    getAllFields(application): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report-detail/getAllFields/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
    }
    getAllRecords(application): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report-detail/getAllRecords/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
    }
    getReportbyRecords(application, records): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report-detail/getReportByRecords/' + localStorage.getItem('userId') + '/' + application + '/' + records, this.getOptions());
    }
    getReportbyFields(application, fields): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report-detail/getReportByFields/' + localStorage.getItem('userId') + '/' + application + '/' + fields, this.getOptions());
    }
    getAllObjectModel(application): Observable<any> {
      return this.http.get(Shared.API_URL + '/api/report-detail/getAllObjectModel/' + localStorage.getItem('userId') + '/' +  application, this.getOptions());
    }
    getAllTablesObjectModel(models): Observable<any> {
      return this.http.get(Shared.API_URL + '/api/report-detail/getAllTablesObjectModel/' +  models, this.getOptions());
    }

    /**Charts**/
    getTopVolumetricTablesDdtm(application: string): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report/getTopVolumetricTablesDdtm/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
    }
    getTopVolumetricFieldsDdtm(application: string): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report/getTopTablesWithHighFieldsNumberDdtm/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
    }
    getTopTablesWithHighFieldsNumberByReport(id): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report/getTopTablesWithHighFieldsNumberByReport/' + id, this.getOptions());
    }
    getTopVolumetricTablesByRaport(id): Observable<any> {
        return this.http.get(Shared.API_URL + `/api/report/getTopVolumetricTablesByRaport/${id}`, this.getOptions());
    }

    getTopSelectedTablesWithHighFieldsNumberByReport(params): Observable<any> {
      const data = "data="+JSON.stringify(params);
          return this.http.post(Shared.API_URL + '/api/report/getTopSelectedTablesWithHighFieldsNumberDdtm',data,this.getOptions());
     }

    getTopVolumetricSelectedTablesByRaport(params): Observable<any> {
      const data = "data="+JSON.stringify(params);
          return this.http.post(Shared.API_URL + '/api/report/getTopVolumetricSelectedTablesByRaport',data,this.getOptions());
     }

    getEmptyNotEmptySelectedRaportByDB(params): Observable<any> {
      const data = "data="+JSON.stringify(params);
          return this.http.post(Shared.API_URL + '/api/report/getEmptyNotEmptySelectedRaportByDB',data,this.getOptions());
     }
  getEmptyNotEmptyRaportByDB(application, id): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/report-ddtm/getEmptyNotEmptyRaportByDB/' + application + '/' + id, this.getOptions());
  }
  getDataSetChartsSelectedReportsModeUser(application: string[]): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/report/getDataSetChartsSelectedReportsModeUserDDTM/' + localStorage.getItem('userId') + '/' + application, this.getOptions());
  }

  // Comparison service
  getEmptyNotEmptyRaport(id): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/report-ddtm/getEmptyNotEmptyRaport/'  + id, this.getOptions());
  }
  getReportCommunTablesReport(array,params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTables/' + array , data, this.getOptions());
  }

  getReportCountTablesReport(params): Observable<any> {
   const data = "data="+JSON.stringify(params);
       return this.http.post(Shared.API_URL + '/api/report-detail/getReportCountTables',data,this.getOptions());
  }

  getReportCommunTablesFields(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesFields',data, this.getOptions());
  }
  getReportCommunTablesTables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesTables',data, this.getOptions());
  }
  getReportCommunTablesUpdatedTables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesUpdatedTables',data, this.getOptions());
  }
  getReportCommunTablesOnlyRep1Tables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesOnlyRep1Tables',data, this.getOptions());
  }
  getReportCommunTablesOnlyRep2Tables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesOnlyRep2Tables',data, this.getOptions());
  }
  getReportDeltaTablesTablesRep1(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportDeltaTablesTablesRep1',data, this.getOptions());
  }

  getReportCommunTablesUpdatedFieldsRep1(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesUpdatedFieldsRep1',data, this.getOptions());
  }
  getReportCommunTablesUpdatedFieldsRep2(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesUpdatedFieldsRep2',data, this.getOptions());
  }

  getReportCommunTablesOnlyFields(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesOnlyFields',data, this.getOptions());
  }
  getReportDeltaTablesOnlyFields(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportDeltaTablesOnlyFields',data, this.getOptions());
  }

  getReportCommunTablesChangedTables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/report-detail/getReportCommunTablesTablesChanged' , data, this.getOptions());
  }
  /* export*/

  getReportExportAllFields(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportAllFieldsCommonTables`, data, this.getOptions());
  }

  getExportUpdatedFieldsCommonTablesRep1(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportUpdatedFieldsCommonTablesRep1`,data, this.getOptions());
  }

  getExportUpdatedFieldsCommonTablesRep2(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportUpdatedFieldsCommonTablesRep2`, data, this.getOptions());
  }
  getExportFieldsCommonTablesRep1(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportFieldsCommonTablesRep1`, data,this.getOptions());
  }
  getExportFieldsCommonTablesRep2(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportFieldsCommonTablesRep2`,data, this.getOptions());
  }
  getExportFieldsDeltaTablesRep1(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportFieldsDeltaTablesRep1`, data,this.getOptions());
  }
  getExportFieldsDeltaTablesRep2(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportFieldsDeltaTablesRep2`, data,this.getOptions());
  }

  getExportAllTables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportAllTablesCommonTables`, data, this.getOptions());
  }
  getExportCommunTablesUpdatedTables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportCommunTablesUpdatedTables`, data, this.getOptions());
  }
  getExportCommunTablesOnlyRep1Tables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportCommunTablesOnlyRep1Tables`, data, this.getOptions());
  }
  getExportCommunTablesOnlyRep2Tables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportCommunTablesOnlyRep2Tables`, data, this.getOptions());
  }
  getExportDeltaTablesTablesRep1(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportDeltaTablesTablesRep1`, data, this.getOptions());
  }
  getExportDeltaTablesTablesRep2(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportDeltaTablesTablesRep2`, data, this.getOptions());
  }

  getExportAllTablesChangedToZero(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportAllTablesCommonTablesToZero`, data, this.getOptions());
  }
  getExportAllTablesChangedZeroTo(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/exportAllTablesCommonTablesZeroTo`, data, this.getOptions());
  }
  // Comparison service end
  // export service
  downloadFile(data, filename='data',headerList) {
      let csvData = this.ConvertToCSV(data, headerList);
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
          dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
      let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
      let str = '';
      let row = 'S.No,';

      for (let index in headerList) {
          row += headerList[index] + ',';
      }
      row = row.slice(0, -1);
      str += row + '\r\n';
      for (let i = 0; i < array.length; i++) {
          let line = (i+1)+'';
          for (let index in headerList) {
              let head = headerList[index];

              line += ',' + array[i][head];
          }
          str += line + '\r\n';
      }
      return str;
  }
  // end export

  // Keywords hierarchy
  // get list of keyswords for report
  getReportDetailsKeysWord(id,tables,fields): Observable<any> {
    return this.http.get(Shared.API_URL + `/api/report-detail/getReportDetailsKeysWord/${id}/${tables}/${fields}`, this.getOptions());
  }
  // get list of keyswords for report
  getReportDetailsNoKeysWord(id,tables,fields): Observable<any> {
    return this.http.get(Shared.API_URL + `/api/report-detail/getReportDetailsNoKeysWord/${id}/${tables}/${fields}`, this.getOptions());
  }
  // end Keywords hierarchy

  /*******Viz joins*******/
  getReportObjectsJoins(id,checked,search): Observable<any> {
    return this.http.get(Shared.API_URL + `/api/report-detail/getReportObjectsJoins/${id}/${checked}/${search}`, this.getOptions());
  }
  getTop10ObjectJoins(id,idUser,objectsSize,checked,search): Observable<any> {
    return this.http.get(Shared.API_URL + `/api/report-detail/getTop10ObjectJoins/${id}/${idUser}/${objectsSize}/${checked}/${search}`, this.getOptions());
  }
  getReportObjectTable(id,idUser,objectsSize,checked,search): Observable<any> {
    return this.http.get(Shared.API_URL + `/api/report-detail/getReportDetailsKeysWordViz/${id}/${idUser}/${objectsSize}/${checked}/${search}`, this.getOptions());
  }
  /*******End Viz joins*******/

  /**********Owner chart**********/
  getReportOwner(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/getOwnerTables`, data, this.getOptions());
  }
  /**********Owner chart end**********/
  /**********size chart**********/
   getReportSize(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/getSizeTables`, data, this.getOptions());
  }
  /**********size chart end**********/

  /**********word cloud chart**********/
    getReportWordCloud(params): Observable<any> {
      const data = "data="+JSON.stringify(params);
      return this.http.post(Shared.API_URL + `/api/report-detail/getWordCloud`, data, this.getOptions());
    }
  /**********word cloud chart end**********/

  /********Export ddtm *****/
  getExportTables(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/getExportReportTables`,data, this.getOptions());
  }
  getExportFields(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/getExportReportFields`,data, this.getOptions());
  }
  getExportReportDetailsNoKeysWord(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/getExportReportDetailsNoKeysWord`,data, this.getOptions());
  }
  getExportReportDetailsKeysWord(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/getExportReportDetailsKeysWord`,data, this.getOptions());
  }
  getExportObjectJoins(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/getExportObjectJoins`,data, this.getOptions());
  }
  getTop10ObjectJoinsExport(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
    return this.http.post(Shared.API_URL + `/api/report-detail/getTop10ObjectJoinsExport`,data, this.getOptions());
  }
  /*******Export end********/
  /********Filtre tree/joins *****/
  getReportObjectJoinsFiltred(params): Observable<any> {
    const data = "data="+JSON.stringify(params);
        return this.http.post(Shared.API_URL + '/api/report-detail/getReportObjectJoinsFiltred',data,this.getOptions());
   }
  /********End Filtre tree/joins *****/

  /********Filtre dimensions *****/
  getReportDimension(): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/report-detail/getReportDimension/'+ localStorage.getItem('userId'),this.getOptions());
    }
  /********End Filtre dimensions *****/

  /********Change collection name reportDetail *****/
  changeReportDetailCollection(app): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/report-detail/changeReportDetailCollection/' + app + '/' + localStorage.getItem('userId'),this.getOptions());
  }
  changeReportAI(reportId): Observable<any> {
      const data = {id:reportId};
      return this.http.post(Shared.NODE_URL + '/report-ia' ,data,{ headers: new HttpHeaders({
              Accept: 'application/json','Content-Type': 'application/json'
          })});
  }
  /********End Filtre Change collection name reportDetail *****/
  }
