export class CatalogItem {
  system: string;
  catalog: string;
  object: string;
  table: string;
  field: string;
  systemId: string;
  catalogId: string;
  objectId: string;
  tableId: string;
  fieldId: string;
  group:number;
  id: string;


  constructor(system: string, catalog: string, object: string, table: string, field: string, systemId: string, catalogId: string, objectId: string, tableId: string, fieldId: string, group: number, id: string) {
    this.system = system;
    this.catalog = catalog;
    this.object = object;
    this.table = table;
    this.field = field;
    this.systemId = systemId;
    this.catalogId = catalogId;
    this.objectId = objectId;
    this.tableId = tableId;
    this.fieldId = fieldId;
    this.group = group;
    this.id = id;
  }
}
