export class archLog{
    SYSTNAPS_ARCHID:number;
    NAME_TABLE:string;
    COUNT_SRC:number;
    COUNT_CSV:number;
    COUNT_BAD:number;
    COUNT_ARCH:number;
    OK_SRC_CSV:string;
    OK_CSV_ARCH:string;
    TIME_SAVE_CSV:number;
    TIME_LOAD_ARCH:number;
    base: string;
    constructor(SYSTNAPS_ARCHID,NAME_TABLE,COUNT_SRC,COUNT_CSV,COUNT_BAD,COUNT_ARCH,OK_SRC_CSV,OK_CSV_ARCH,TIME_SAVE_CSV,TIME_LOAD_ARCH,base){
        this.SYSTNAPS_ARCHID = SYSTNAPS_ARCHID;
        this.NAME_TABLE = NAME_TABLE;
        this.COUNT_SRC = COUNT_SRC;
        this.COUNT_CSV = COUNT_CSV;
        this.COUNT_BAD = COUNT_BAD;
        this.COUNT_ARCH = COUNT_ARCH;
        this.OK_SRC_CSV = OK_SRC_CSV;
        this.OK_CSV_ARCH = OK_CSV_ARCH;
        this.TIME_SAVE_CSV = TIME_SAVE_CSV;
        this.TIME_LOAD_ARCH = TIME_LOAD_ARCH;
        this.base = base;
    }
}
