import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {ClassificationdrmComponent} from './classificationdrm/classificationdrm.component';
import {RetentionPeriodComponent} from './retention-period/retention-period.component';
import {RulesComponent} from './rules/rules.component';
import {SecurityRiskComponent} from './security-risk/security-risk.component';
import {NoClassifcationComponent} from './no-classifcation/no-classifcation.component';
import {NoRulesComponent} from './no-rules/no-rules.component';
import {NoSecurityComponent} from './no-security/no-security.component';
import {DefinitionsComponent} from './definitions/definitions.component';
import {ClassificationdictdrmComponent} from './classificationDictdrm/classificationdictdrm.component';


const routes: Routes = [
  {
    path : '',
    redirectTo : 'drm-definition',
    pathMatch : 'full'
  },
  {
    path : 'drm-definition/:definition/:componentId/:component',
    component : DefinitionsComponent
  },
  {
    path : 'drm-classification/:system/:model/:component',
    component : ClassificationdrmComponent
  },
  {
    path : 'drm-classificationDict/:system/:model/:component',
    component : ClassificationdictdrmComponent
  },
  {
    path : 'drm-no-classification',
    component : NoClassifcationComponent
  },
  {
    path : 'drm-retention-period/:retention/:componentId/:component',
    component : RetentionPeriodComponent
  },
  {
    path : 'drm-rules/:application/:model/:componentId/:component',
    component : RulesComponent
  },
  {
    path : 'drm-security/:application/:model/:object/:table/:field/:componentId/:component',
    component : SecurityRiskComponent
  },
  {
    path : 'drm-no-rules',
    component : NoRulesComponent
  },
  {
    path : 'drm-no-security',
    component : NoSecurityComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DrmRoutingModule { }
