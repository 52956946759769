import {AfterViewInit, Directive, ElementRef, Input, OnDestroy} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[inView]'
})
export class InViewDirective implements AfterViewInit, OnDestroy {
  @Input('inView') config = {
   intersectElement: '',
    // handler: Function
  };
  @Input() handler: (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => void;
  prevRatio = 0.0;
  private observer: IntersectionObserver;
  constructor(private el: ElementRef) {
  }

  ngAfterViewInit(): void {
    this.createObserver();
  }
  createObserver() {
    const root = document.querySelector(this.config.intersectElement);
    const options = {
      root,
      rootMargin: '0px',
      threshold: 0.5
    };

    this.observer = new IntersectionObserver(entries => this.handler(entries, this.observer), options);
    this.observer.observe(this.el.nativeElement);
  }
  ngOnDestroy(): void {
    this.observer.unobserve(this.el.nativeElement);
  }
}
