import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-data-classify',
  templateUrl: './data-classify.component.html',
  styleUrls: ['./data-classify.component.sass']
})
export class DataClassifyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
