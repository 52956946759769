import { Injectable } from '@angular/core';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class DataCatalogueService {

  constructor(private http: HttpClient, private router: Router) { }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }

  // liste des applications pour alimenter le tableau object models by status approved/archived
  getApplicationForListModels(): Observable<any> {
    const user = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getApplicationForListModels/' + user, this.getOptions());
  }
  getBusinessTerms(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/data-catalog/getBusinessTerms/'+ localStorage.getItem('userId'),this.getOptions());
  }
  getObjectModelsList(appli: string,selectedTerms,selectedDimensions): Observable<any> {
    const user = localStorage.getItem('userId');
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getObjectModelsListCatalogue/' + user + '/' + appli + '/' + selectedTerms+ '/' + selectedDimensions, this.getOptions());
  }
  getOwnerObjectModelCatalogue(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getOwnerObjectModelCatalogue/'+ localStorage.getItem('userId')+'/'+ modelId , this.getOptions());
  }
  getCampainsObjectModelCatalogue(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getCampainsObjectModelCatalogue/' + modelId , this.getOptions());
  }
  getDataLineageObjectModelCatalogue(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getDataLineageObjectModelCatalogue/' + modelId , this.getOptions());
  }
  getBusinessTermsObjectModelCatalogue(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getBusinessTermsObjectModelCatalogue/' + modelId , this.getOptions());
  }

  updateObjectModelCatalogue(prams): Observable<any> {
    const data = "data=" + JSON.stringify(prams);
    return this.http.put<any>(Shared.API_URL + '/api/data-catalog/updateObjectModelCatalogue', data, this.getOptions());
  }

  getBusinessTermsOTBT(type): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getBusinessTermsOTBT/' + localStorage.getItem('userId') +'/'+ type  , this.getOptions());
  }
  getObjectBusinessTermsOT(idObject): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getObjectBusinessTermsOT/' + localStorage.getItem('userId') +'/'+ idObject  , this.getOptions());
  }
  updateObjectTermsOT(prams): Observable<any> {
    const data = "data=" + JSON.stringify(prams);
    return this.http.put<any>(Shared.API_URL + '/api/data-catalog/updateObjectTermsOT', data, this.getOptions());
  }
  getObjectBusinessTermsBT(idField): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getObjectBusinessTermsBT/' + localStorage.getItem('userId') +'/'+ idField  , this.getOptions());
  }
  updateObjectTermsBT(prams): Observable<any> {
    const data = "data=" + JSON.stringify(prams);
    return this.http.put<any>(Shared.API_URL + '/api/data-catalog/updateObjectTermsBT', data, this.getOptions());
  }
  // list object by model for menu RECORD & FIELD
  getObjectsByModel(id , currentpage, search, sortColumn, sortOrder): Observable<any> {
    return this.http.get<any>(Shared.API_URL + `/api/data-catalog/getObjectsByModel/${id}/${currentpage}/${search}/${sortColumn}/${sortOrder}` , this.getOptions());
  }
  updateModelType(name: string, app, type: string, oldType): Observable<any> {
    const userId = localStorage.getItem('userId');
    return this.http.put(Shared.API_URL + '/api/data-catalog/updateModelType/' + name + '/' + app + '/' + type + '/' + oldType + '/' + userId, null, this.getOptions());
  }
  // counts
  getBusinessTermsObjectModelCatalogueCount(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getBusinessTermsObjectModelCatalogueCount/' + modelId , this.getOptions());
  }
  getCampainsObjectModelCatalogueCount(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getCampainsObjectModelCatalogueCount/' + modelId , this.getOptions());
  }
  getDataLineageObjectModelCatalogueCount(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getDataLineageObjectModelCatalogueCount/' + modelId , this.getOptions());
  }
  getOwnerObjectModelCatalogueCount(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getOwnerObjectModelCatalogueCount/'+ localStorage.getItem('userId')+'/'+ modelId , this.getOptions());
  }
  getCustomObjectModelCatalogueCount(modelId): Observable<any> {
    // tslint:disable-next-line:max-line-length
    return this.http.get<any>(Shared.API_URL + '/api/data-catalog/getCustomObjectModelCatalogueCount/'+ modelId , this.getOptions());
  }
}
