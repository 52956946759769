import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from '../../services/authentication.service';
import {ActivatedRoute, Router} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

declare const jQuery: any;
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {
    url_param = '';
    validForm: FormGroup;
    lang: string;
    constructor(private fb: FormBuilder, private authService: AuthenticationService, private router: Router,
                private route: ActivatedRoute,private translate: TranslateService) {
        this.validForm = fb.group({
            password: ['', [Validators.required]],
            _token: ['' , [Validators.required]],
            confirmPassword: ['']
        }, { validator: this.checkPasswords });

    }

    checkPasswords(group: FormGroup) { // here we have the 'passwords' group
        const pass = group.controls.password.value;
        const confirmPass = group.controls.confirmPassword.value;

        return pass === confirmPass ? null : { notSame: true };
    }
  ngOnInit() {
      document.getElementById('main-component').style.display = 'none';
      // this.route.queryParams.filter(params => params.order)
      //     .subscribe(params => {
      //         console.log(params); // {order: "popular"}
      //
      //         this.order = params.order;
      //         console.log(this.order); // popular
      //         console.log(this.order); // popular
      //     });
      this.route.paramMap.subscribe(params => {
          this.url_param = params.get('token');
      });
      console.log(this.url_param);

      (function($) {
          'use strict';


          /*==================================================================
          [ Focus input ]*/
          $('.input100').each(function() {
              $(this).on('blur', function() {
                  if ($(this).val().trim() != '') {
                      $(this).addClass('has-val');
                  } else {
                      $(this).removeClass('has-val');
                  }
              });
          });


          /*==================================================================
          [ Validate ]*/
          const input = $('.validate-input .input100');

          $('.validate-form').on('submit', function() {
              const check = true;

              // for (var i = 0; i < input.length; i++) {
              //     if (validate(input[i]) == false) {
              //         showValidate(input[i]);
              //         check = false;
              //     }
              // }

              return check;
          });


          $('.validate-form .input100').each(function() {
              $(this).focus(function() {
                  hideValidate(this);
              });
          });

          // function validate(input) {
          //     if ($(input).attr('type') == 'email' || $(input).attr('name') == 'email') {
          //         if ($(input).val().trim().match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
          //             return false;
          //         }
          //     }
          //     else {
          //         if ($(input).val().trim() == '') {
          //             return false;
          //         }
          //     }
          // }

          function showValidate(input) {
              const thisAlert = $(input).parent();

              $(thisAlert).addClass('alert-validate');
              $('.erroe_dis').remove();
              $('.alert-validate').append('<i class="material-icons erroe_dis">error</i>');
          }

          function hideValidate(input) {
              const thisAlert = $(input).parent();

              $(thisAlert).removeClass('alert-validate');
              $('.erroe_dis').remove();
          }

          /*==================================================================
          [ Show pass ]*/
          let showPass = 0;
          $('.btn-show-pass').on('click', function() {
              if (showPass == 0) {
                  $(this).next('input').attr('type', 'text');
                  $(this).addClass('active');
                  showPass = 1;
              } else {
                  $(this).next('input').attr('type', 'password');
                  $(this).removeClass('active');
                  showPass = 0;
              }

          });


      })(jQuery);
      this.detectChangeLaguage();
    }
    detectChangeLaguage() {
      const that = this;
      setInterval(() => {
        this.lang = localStorage.getItem('lang') || 'en';
        this.translate.use(this.lang);
      }, 10);
    }
    confirm() {
        this.authService.changePWD(this.url_param, this.validForm.value.password).subscribe(data => this.authService.showNotification('alert-success', this.lang === 'en' ? 'Success! Connect with your new password.' : 'Succès! Connectez-vous avec votre nouveau mot de passe.',
            'top', 'right', 'animated rotateInUpLeft', 'animated rotateOutUpLeft'));
        this.router.navigate(['/auth']);


    }}
