import { Injectable } from '@angular/core';
import {HttpClient, HttpEvent, HttpEventType, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
import {RoleRegulationJson} from "../../settings/user-roles/RoleRegulationJson";
import {ObjectModelClassif} from "../../object-models/mainObject/ObjectModelClassif";
@Injectable({
  providedIn: 'root'
})
export class ObjectService {
  //   Options = {
  //       headers: new HttpHeaders({
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //           'Accept': 'Application/json',
  //           'Authorization': 'Bearer ' + getCookieValue('tokendrd')
  //           //  'Accept': 'Application/json'
  //       })
  //   };
  //    headerss = new Headers({
  //   'Content-Type': 'application/json',
  //   'Authorization': 'Bearer ' + getCookieValue('tokendrd')
  // });
  constructor(private http: HttpClient, private router: Router) { }
  // function Headers
    getOptions() {
      return {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        })
      };
    }
    getOptions1() {
      return {
        headers: new HttpHeaders({
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        })
      };
    }
  // function Headers
  getOptionsNode() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      })
    };
  }
  // liste des applications pour alimenter le tableau object models by status approved/archived
    getApplicationForListModels(): Observable<any> {
        const user = localStorage.getItem('userId');
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getApplicationForListModels/' + user, this.getOptions());
    }
  // tableau object models by status approved/archived
  //   getObjectModelsList(appli: string, page, size = 5): Observable<any> {
  //       const user = localStorage.getItem('userId');
  //     // tslint:disable-next-line:max-line-length
  //       return this.http.get<any>(Shared.API_URL + '/api/object-model/getObjectModelsList/' + user + '/' + appli + '/' + page + '/' + size, this.getOptions());
  //   }
    getObjectModelsList(appli: string, entities: string): Observable<any> {
      const user = localStorage.getItem('userId');
      // tslint:disable-next-line:max-line-length
      return this.http.get<any>(Shared.API_URL + '/api/object-model/getObjectModelsList/' + user + '/' + appli + '/' + entities , this.getOptions());
    }
    // tableau object models by status approved/archived (change model by effective date)
    getChangeModelFromObjectModelsList(id: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getChangeModelFromObjectModelsList/' + id, this.getOptions());
    }
    // tabs specification et joins
    // getSpecObjectModel(id): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/object-model/list-spec-content/' + id, this.getOptions());
    // }
    // getJoinsObjectModel(id): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/object-model/list-join-content/' + id, this.getOptions());
    // }
    getListModelJoinGraph(id): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/graph/' + id, this.getOptions());
    }
    // list criticity filtred by compliance GRAPH
    getVulnerabScoreByCompliance(idModel: string, compliance: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getVulnerabScoreByCompliance/' + idModel + '/' + compliance, this.getOptions());
    }
  // list Cqtegory filtred by compliance GRAPH
    getCategoryByCompliance(idModel: string, compliance: string): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model/getCategoryByCompliance/' + idModel + '/' + compliance, this.getOptions());
    }
  // list Cqtegory filtred by compliance GRAPH
    getDistinctObjectIdBySelectedCateg(idModel: string, categs: any): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model/getDistinctObjectIdBySelectedCateg/' + idModel + '/' + categs, this.getOptions());
    }
     /// liste des objects avec descriptions du model selectionnée
    // getListObjectsByModel(id): Observable<any> {
    //   return this.http.get(Shared.API_URL  + '/api/object-model/getListObjectsByModel/' + id, this.Options);
    // }
    // fin tabs specification et joins
    // description, recordList, keyCand... of object
    getDescriptionObjectInDiagramme(idModel: string, id: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getPopUp/' + idModel + '/' + id,  this.getOptions() );
    }
    // get list of dataMapping and list of dataClassification
    getDataMappClassif(idModel) {
        return this.http.get<any>(Shared.API_URL + '/api/data-mapp-classif/getDataMappClassif/' + idModel, this.getOptions());
    }
    // liste des objets mappés (par modele et data storage) [First Filter]
    getObjectByMappingAnimation(idModel: string, map: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/data-mapp/getObjectByMappingAnimation/' + idModel + '/' + map, this.getOptions());
    }
    // liste des objets mappés (par modele et data storage and list data source) [Second filter]
    getObjectByModelStorageMapAnim(idModel: string, storage: string, dataMap: any): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/data-mapp/getObjectByModelStorageMapAnim/' + idModel + '/' + storage + '/' + dataMap, this.getOptions());
    }
    // data usage risk (menu list)
    getDataUsageByModel(idModel: string): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model-classif/getDataUsageByModel/' + idModel , this.getOptions());
    }
    // new data usage risk (menu list) in classification 'many classifications'
    // getAllComplianceToUsageRisk(idModel: string): Observable<any> {
    //   return this.http.get<any>(Shared.API_URL + '/api/object-model-classif/getAllComplianceToUsageRisk/' + idModel , this.getOptions());
    // }
    // data usage risk (Popup MODAL)
    getPopUsageRisk(idObj: string, cat: string): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model-classif/getPopUsageRisk/' + idObj + '/' + cat, this.getOptions());
    }
    // records & fields (menu list)
    // getRecordsByModel(idModel: string): Observable<any> {
    //   return this.http.get<any>(Shared.API_URL + '/api/object-model-record/getRecordsByModel/' + idModel , this.getOptions());
    // }
    // popup data security risk
    getDataRiskApi(id: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getDataSecurityRisk/' + id, this.getOptions1());

    }
    getDataMappApi(id: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model/getDataMappingObject/' + id, this.getOptions());

    }
    // list object by model for menu RECORD & FIELD
    getObjectsByModel(id , currentpage, search, sortColumn, sortOrder): Observable<any> {
      return this.http.get<any>(Shared.API_URL + `/api/object-model-record/getObjectsByModel/${id}/${currentpage}/${search}/${sortColumn}/${sortOrder}` , this.getOptions());
    }
    // get List objectModelJoin fore JOIN
    getObjectModelsJoinsRightLeft(id , currentpage, search, sortColumn, sortOrder): Observable<any> {
      return this.http.get<any>(Shared.API_URL + `/api/object-model-join/ObjectModelsJoinsRightLeft/${id}/${currentpage}/${search}/${sortColumn}/${sortOrder}` , this.getOptions());
    }
    // hierarchical JSON app/model/object/record/field
    getModelHierarchicalBubbleZoom(application: string, modelName): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model-join/getModelHierarchicalBubbleZoom/' + application + '/' + modelName, this.getOptions());
      }
      // global size chart
    getGlobalSizeChart(application: string, modelId): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model/getGlobalSizeChart/' + application + '/' + modelId, this.getOptions());
    }
    // filter chart zoom by selected ObjectModel
    getListObjByFiltredModel(application: string, listModel, type): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model-join/getListObjByFiltredModel/' + application + '/' + listModel + '/' + type, this.getOptions());
    }
    // list object by selected model for ObjectModelVolume
    getReportListVolume(modelId: string): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model-join/getReportListVolume/' + modelId, this.getOptions());
    }
    // get ReocrdName/RecordCand and Count By DDTMReport and ObjectModel for objecModelVolume ??ancien route  = getCandidByRepAndModgetCandidByRepAndMod??
    getObjAndContByRepAndMod(idModel: string, reportName: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/object-model-join/getObjAndContByRepAndMod/' + idModel + '/' + reportName, this.getOptions());
      }
    // get count ReocrdName/RecordCand and Count By DDTMReport and Object (chart Po-pup) for objecModelVolume
    getRecordCandAndRecordChartVolume(idObject: string, reportName: string): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model-join/getRecordCandAndRecordChartVolume/' + idObject + '/' + reportName, this.getOptions());
    }
    // hierarchical JSON pack
    getModelPack(id): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model-join/getModelPack/'  + id, this.getOptions());
    }
    //get volume size
    getVolumeSize(id, report): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/object-model-join/getVolumeSize/'  + id + '/' + report, this.getOptions());
    }
    // getModelPackObjects(id): Observable<any> {
    //   return this.http.get<any>(Shared.API_URL + '/api/object-model-join/getModelPackObjects/'  + id, this.getOptions());
    // }
    refreshComponent(router: Router){
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate([router.url]);
      });
    }
  //
  // getData(url) {
  //   const req = new HttpRequest('GET', url, {
  //     reportProgress: true,
  //     headers: new HttpHeaders({
  //       'Content-Type': 'application/x-www-form-urlencoded',
  //       Authorization: 'Bearer ' + getCookieValue('tokendrd')
  //     })
  //   });
  //
  //   this.http.request(req).subscribe((event: HttpEvent<any>) => {
  //     switch (event.type) {
  //       case HttpEventType.Sent:
  //         console.log('Request sent!');
  //         break;
  //       case HttpEventType.ResponseHeader:
  //         console.log('Response header received!');
  //         break;
  //       case HttpEventType.DownloadProgress:
  //         const kbLoaded = Math.round(event.loaded / 1024);
  //         console.log(`Download in progress! ${ kbLoaded }Kb loaded`);
  //         break;
  //       case HttpEventType.Response:
  //         console.log('😺 Done!', event.body);
  //     }
  //   });
  // }
  exportRules(id: any): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model-filter/exportRules/' + id, this.getOptions());
  }
  exportRecordAndFields(id: any): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model-record/exportRecordAndFields/' + id, this.getOptions());
  }
  exportMapping(id: any): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/exportMapping/' + id, this.getOptions());
  }
  exportUsageRisk(id: string, type='usage'): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model-classif/exportUsageRisk/' + id + '/' + type, this.getOptions());
  }
  updateSecurity(id:string,classifs: ObjectModelClassif[]) {
    const data = "data=" + JSON.stringify(classifs);
    return this.http.put(Shared.API_URL + '/api/object-model-classif/updateSecurity/' + id , data, this.getOptions());
  }
  sendTicket(msg,files){
    let formData = new FormData();
    if(files!=undefined){
      files.map( f => formData.append('file[]',f, f.name));
    }
    // formData.append('subject', subject);
    formData.append('msg', msg);
    console.log(formData);
    return this.http.post<any>(Shared.API_URL + '/api/users/sendTicket/' + localStorage.getItem('userId'), formData, this.getOptions1());
  }
  // model administration (Object model) get list ids version by applicationCode (checked all) to Approve
  getAllModelVersionIdByCodeToApprove(appliCode: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getAllModelVersionIdByCodeToApprove/' + appliCode , this.getOptions());
  }
  // model administration (Object model) get list ids version by applicationCode (checked all) to Archive
  getAllModelVersionIdByCodeToArchive(appliCode: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getAllModelVersionIdByCodeToArchive/' + appliCode , this.getOptions());
  }
  // model administration (Object model) get list ids version by applicationCode (checked all) to Delete
  getAllModelVersionIdByCodeToDelete(appliCode: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getAllModelVersionIdByCodeToDelete/' + appliCode , this.getOptions());
  }
  // model administration (Object model) get list ids version by applicationCode (checked all) to Remove
  getAllModelVersionIdByCodeToRemove(appliCode: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getAllModelVersionIdByCodeToRemove/' + appliCode , this.getOptions());
  }
  // model administration APPROVE list applicationCode (by application)
  approveMultipleByListIdsModel(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/object-model/approveMultipleByListIdsModel', data, this.getOptions());
  }
  // model administration ARCHIVE list applicationCode (by application)
  archiveMultipleByListIdsModel(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/object-model/archiveMultipleByListIdsModel', data, this.getOptions());
  }
  // model administration DELETE list applicationCode (by application)
  deleteMultipleByListIdsModel(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/object-model/deleteMultipleByListIdsModel', data, this.getOptions());
  }
  // model administration REMOVE list applicationCode (by application)
  removeMultipleByListIdsModel(dataUpdateMass: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataUpdateMass);
    return this.http.post<any>(Shared.API_URL + '/api/object-model/removeMultipleByListIdsModel', data, this.getOptions());
  }
  updateStatusModel(id: string, status: any): Observable<any> {
    let dataJson = {
      username: getCookieValue('username'),
      idModel: id,
      statusModel: status
    };
    const data = "data=" + JSON.stringify(dataJson);
    return this.http.post<any>(Shared.API_URL + '/api/object-model/updateStatusModel', data, this.getOptions());
  }
  getSecurityRole( app: string): Observable<any> {
    let userId = localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getSecurityRole/' + userId + '/' + app , this.getOptions());
  }
  getDataOwner( id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getDataOwner/' + id , this.getOptions());
  }
  updateModelOwner(name: string, app, owner: string, type: string, oldType, entities, entityNames): Observable<any> {
    const userId = localStorage.getItem('userId');
    const preData = {name, app, owner, type, oldType, entities, entityNames};
    const data = "data=" + JSON.stringify(preData);
    return this.http.put(Shared.API_URL + '/api/object-model/updateModelOwner/'  + userId, data, this.getOptions());
  }
  // count record field
  getTableAndFieldCount(id , search): Observable<any> {
    return this.http.get<any>(Shared.API_URL + `/api/object-model-record/getTableAndFieldCount/${id}/${search}` , this.getOptions());
  }
  // count join
  getObjectModelsJoinsRightLeftCount(id , search): Observable<any> {
    return this.http.get<any>(Shared.API_URL + `/api/object-model-join/getObjectModelsJoinsRightLeftCount/${id}/${search}` , this.getOptions());
  }
  getRulesCount(id): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model-filter/getRulesCount/' + id , this.getOptions());
  }
  getDataMappingCount(id): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getDataMappingCount/' + id , this.getOptions());
  }
  // getDataSecurityRiskCount(id): Observable<any> {
  //   return this.http.get<any>(Shared.API_URL + '/api/object-model/getDataSecurityRiskCount/' + id , this.getOptions());
  // }
  getEntities(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getEntities'  , this.getOptions());
  }
  getEntitiesFilter(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getEntitiesFilter'  , this.getOptions());
  }
  getDiagramObjectModel(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getDiagramObjectModel/' + id  , this.getOptions());
  }
  ///// /////////////////////////// communication with node js /////////////////////////////
  getScreenShotMermaid(element, id) {
      const menu = sessionStorage.getItem('menu');
      const appAsset = sessionStorage.getItem('appAsset');
      const dontShowTipsGovGlossary = localStorage.getItem('dontShowTipsGovGlossary');
      const token = getCookieValue('tokendrd');
      const username = getCookieValue('username');
      const lang = localStorage.getItem('lang');
      const userId = localStorage.getItem('userId');
      const roles = localStorage.getItem('roles');
      const location = window.location.href;
      const data = { menu, appAsset , dontShowTipsGovGlossary , token, username, userId, lang , roles, location, element , id};
      return this.http.post( Shared.NODE_URL + '/report', data, { headers: new HttpHeaders({
              Accept: 'image/jpeg', 'Content-Type': 'application/json'
          }), responseType: 'blob'});
  }
  //////////////////////////////// end communication with js //////////////////////////////
  getHistoryList(app: string, model: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search-model-history/getHistoryList/' + app + '/' + model  , this.getOptions());
  }
  addHistory(app: string, model: string, history: object): Observable<any> {
    const data = "data=" + JSON.stringify(history);
    return this.http.post<any>(Shared.API_URL + '/api/search-model-history/addHistory/' + app + '/' + model , data, this.getOptions());
  }
  incrHistory(app: string, model: string, text: string): Observable<any> {
    return this.http.put<any>(Shared.API_URL + '/api/search-model-history/incrHistory/' + app + '/' + model + '/' + text, null, this.getOptions());
  }
}
