import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DataClassifyComponent} from './mainData/data-classify.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'data-classify',
        pathMatch: 'full'
    },
    {
        path: 'data-classify',
        component: DataClassifyComponent
    },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataClassifyRoutingModule { }
