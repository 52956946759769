import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {getCookieValue, Shared} from "../../shared";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class UploadsService {
  constructor(private http: HttpClient, private router: Router) { }
  OptionJson()  {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  // get all dimensions after upload dictionarys
  getAllDimensions(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/uploads/getAllDimensions', this.getOptions());
  }
  uploadDictionary(info): Observable<any> {
    const username = getCookieValue('username');
    const data = "data=" + JSON.stringify(info);
    return this.http.post<any>(Shared.API_URL + '/api/uploads/dictionary/' + username, data, this.getOptions());
  }
  uploadDictionaryDetail(idReport, listDetail): Observable<any> {
    const data = 'data=' + listDetail;
    return this.http.post<any>(Shared.API_URL + '/api/uploads/dictionary-detail/' + idReport, data, this.getOptions());
  }
  // mise à jour counTable (report/reportDetail)
  setCountAllTables(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/uploads/setCountTablesForAllDictionarys', this.getOptions());//report/setCountTablesForAllReports
  }
  // mise à jour counEmptyTable (report/reportDetailUser)
  setCountEmptyTables(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/uploads/setCountEmptyTablesForAllDictioanrys', this.getOptions());// report/setCountEmptyTablesForAllReports
  }
  // mise à jour counNotTable (report/reportDetailUser)
  setCountNotEmptyTables(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/uploads/setCountNotEmptyTablesForAllDictioanrys', this.getOptions());//report/setCountNotEmptyTablesForAllReports
  }
  // add object joins tree dictionary
  addObjectsJoinsTreeDict(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/uploads/addObjectsJoinsTreeDict', this.getOptions()); // uploads/addObjectsJoinsTreeDict
  }
  // launch trigger model structure
  setCountFieldsByRecordNameDict(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/uploads/setCountFieldsByRecordNameDict', this.getOptions()); // uploads/launchTriggerAggr
  }
  // get list reports not havent dimensions
  getReportsForDimension(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/uploads/getReportIdsForDimension', this.getOptions());
  }
  /***Owner Report Upload***/
  getOwnerReportUpload(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/report/getOwnerReportUpload/' + localStorage.getItem('userId'), this.getOptions());
  }
  /***End Owner Report Upload***/
  getEntities(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/object-model/getEntities'  , this.getOptions());
  }
  addDimensionsToReports(params): Observable<any> {
    const data = 'data=' + JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/uploads/addReportDimensions', data, this.getOptions());
  }
  getStatDictionaryToChart(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/uploads/getStatDictionaryToChart', this.getOptions());
  }
  removeAllReportEnPending(): Observable<any> {
      return this.http.delete(Shared.API_URL + '/api/uploads/removeAllReportEnPending', this.getOptions());
  }

  /************************************** service object model */
  // get list reports not havent dimensions
  getModelsForDimension(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/uploads/getModelIdsForDimension', this.getOptions());
  }
  addDimensionsToModels(params): Observable<any> {
    const data = 'data=' + JSON.stringify(params);
    return this.http.post(Shared.API_URL + '/api/uploads/addModelDimensions', data, this.getOptions());
  }
  uploadModel(infoModel): Observable<any> {
    const data = 'data=' + JSON.stringify(infoModel);
    return this.http.post<any>(Shared.API_URL + '/api/uploads/uploadModel', data, this.getOptions());
  }
  uploadObjectByModel(idModel: string, object): Observable<any> {
    // const data = 'data=' + JSON.stringify(infoModel);
    return this.http.post<any>(Shared.API_URL + '/api/uploads/uploadObjectByModel/' + idModel, object, this.getOptions());
  }
  uploadObjectJoinsByModel(idModel: string, objectJoins): Observable<any> {
    // const data = 'data=' + JSON.stringify(infoModel);
    return this.http.post<any>(Shared.API_URL + '/api/uploads/uploadObjectJoinsByModel/' + idModel, objectJoins, this.getOptions());
  }
  addGroupJoinToModel(idModel): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/uploads/addGroupJoinToModel/' + idModel, this.getOptions());
  }
  addCountSensColToModel(idModel): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/uploads/addCountSensColToModel/' + idModel, this.getOptions());
  }
  checkPendingModel(type: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/uploads/checkPendingModelByType/' + type, this.getOptions());
  }
  removeAllModelEnPending(): Observable<any> {
    return this.http.delete(Shared.API_URL + '/api/uploads/removeAllModelEnPending', this.getOptions());
  }
  getStatModelToChart(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/uploads/getStatModelToChart', this.getOptions());
  }
  /****************************************************upload archive ****************************************************/
  getStatArchiveToChart(): Observable<any> {
      return this.http.get(Shared.API_URL + '/api/uploads/getStatArchiveToChart', this.getOptions());
  }
}
