import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TooltipModule} from 'ng2-tooltip-directive';
import { ObjectModelsRoutingModule } from './object-models-routing.module';
import {ObjectModelsComponent} from './mainObject/object-models.component';
import {DiagrammeComponent} from './graphs/diagramme/diagramme.component';
import {LoadersobjectComponent} from '../loaders/loadersobject/loadersobject.component';
import {SidebarModule} from 'ng-sidebar';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ChartGlobComponent } from './globChart/chart-glob/chart-glob.component';
import { VolumeComponent } from './volume/volume.component';
import { OrderModule } from 'ngx-order-pipe';
import {NgxDropzoneModule} from 'ngx-dropzone';
import { DesignerComponent } from './designer/designer.component';
import { GraphComponent } from './designer/graph/graph.component';
import {LoaderglobMxgraphComponent} from '../loaders/loaderglobMxgraph/loaderglobMxgraph.component';
import {TranslateModule} from '@ngx-translate/core';
import {InViewDirective} from '../in-view.directive';
import {FilterHtmlPipe} from "../filter-html.pipe";
import {SharedModule} from '../shared/shared.module';

@NgModule({
    declarations: [
        ObjectModelsComponent,
        LoadersobjectComponent,
        DiagrammeComponent,
        ChartGlobComponent,
        VolumeComponent,
        DesignerComponent,
        GraphComponent,
        LoaderglobMxgraphComponent,
        InViewDirective,
        FilterHtmlPipe,
    ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ObjectModelsRoutingModule,
    TooltipModule,
    NgSelectModule,
    NgOptionHighlightModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    OrderModule,
    SidebarModule.forRoot(),
    NgxDropzoneModule,
    TranslateModule,
    SharedModule
  ]
})
export class ObjectModelsModule { }
