import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDictionaryComponent } from './upload-dictionary/upload-dictionary.component';
import { UploadModelComponent } from './upload-model/upload-model.component';
import { UploadArchiveComponent } from './upload-archive/upload-archive.component';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {UploadsRoutingModule} from './uploads-routing.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgSelectModule} from '@ng-select/ng-select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';



@NgModule({
  declarations: [UploadDictionaryComponent, UploadModelComponent, UploadArchiveComponent],
    imports: [
      CommonModule,
      NgxDropzoneModule,
      UploadsRoutingModule,
      TranslateModule,
      NgSelectModule,
      ReactiveFormsModule,
    ]
})
export class UploadsModule { }
