export class ArchiveIdJson{
  appl: string;
  bases: any[];
  deleteBase = false;
  constructor(appl: string, bases: any[], deleteBase: boolean) {
    this.appl = appl;
    this.bases = bases;
    this.deleteBase = deleteBase;
  }
}
