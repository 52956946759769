import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
import {tap} from 'rxjs/operators';
import {RoleJson} from "../../settings/user-roles/roleJson";
import {SettingsRole} from "../../settings/user-roles/SettingsRole";
import {DashBoardRole} from "../../settings/user-roles/DashBoardRole";
import {RoleRegulationJson} from "../../settings/user-roles/RoleRegulationJson";
import {LifeCycleJson} from '../../settings/user-roles/LifeCycleJson';
import {MetaRole} from "../../settings/user-roles/MetaRole";
import {ArchiveJson} from '../../settings/user-roles/ArchiveJson';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  private refreshNeeded = new Subject<void>();
  private refreshdelete = new Subject<void>();


  constructor(private http: HttpClient) {
  }
  getRoleList(currentpage, search, sortColumn, sortOrder, size): Observable<any> {
    const userId = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + `/api/users-role/getRoleList/${currentpage}/${search}/${sortColumn}/${sortOrder}/${userId}/${size}`, this.getOptions());
  }
  getRoles(): Observable<any> {
    return this.http.get(Shared.API_URL + `/api/users-role/getRoles`, this.getOptions());
  }
  getRefreshNeeded() {
    return this.refreshNeeded;
  }
  addRole(data: string) {
    return this.http.post(Shared.API_URL + '/api/users-role/addRole', data, this.getOptions())
      .pipe(
        tap(
          () => {
            this.refreshNeeded.next();
          })
      );
  }

  getRefreshDelete() {
    return this.refreshdelete;
  }
  removeUser(id) {
    return this.http.delete(Shared.API_URL + '/api/users-role/removeRole/' + id, this.getOptions())
      .pipe(
        tap(
          () => {
            this.refreshdelete.next();
          })
      );
  }
  getListApplicationsReport() {
    return this.http.get(Shared.API_URL + '/api/users-role/getListApplicationsReport', this.getOptions());
  }
  getEnvsBases(apps): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/users-role/getEnvsBaseByApp/' + apps, this.getOptions());
  }
  getEnvsBasesRep(apps): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/users-role/getEnvsBaseReprtByApp/' + apps, this.getOptions());
  }

  getListApplicationsModel(type) {
    return this.http.get(Shared.API_URL + '/api/users-role/getListApplicationsModel/' + type, this.getOptions());
  }
  getListApplicationsModelForRules() {
    return this.http.get(Shared.API_URL + '/api/users-role/getListApplicationsModelForRules', this.getOptions());
  }
  getApplModels(apps, type): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/users-role/getApplModels/' + apps + '/' + type, this.getOptions());
  }
  getApplModelsRules(apps): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/users-role/getApplModelsRules/' + apps, this.getOptions());
  }
  // classification role apps
  getClassificationApps() {
    return this.http.get(Shared.API_URL + '/api/users-role/getClassificationApps', this.getOptions());
  }
  // classification role modes per app
  getModelsForClassification(apps): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/users-role/getModelsForClassification/' + apps, this.getOptions());
  }
  // data life cycle
  getAppsLifeCycle(): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/users-role/getAppsLifeCycle' , this.getOptions());
  }
  getCampainsLifeCycle(app): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/users-role/getCampainsLifeCycle/' + app , this.getOptions());
  }
  // add role
  saveRole(d: RoleJson) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/users-role/saveRole', data, this.getOptions());
  }
  // mail
  sendmail() {
    return this.http.get(Shared.API_URL + '/api/users/sendMail', this.getOptions());
  }
  // mail
  enableUser(data: string) {
    return this.http.post(Shared.API_URL + '/users/enableUser', data, this.getOptions());
  }
  // expired user token email
  enableUserFinal(token: string, data: string) {
    return this.http.post(Shared.API_URL + '/users/enableUserFinal/' + token, data, this.getOptions());
  }
  // check role
  checkRole(name: string) {
    return this.http.get(Shared.API_URL + '/api/users-role/checkRole/' + name,  this.getOptions());
  }
  // edit role
  editRole(id: string): Observable<any> {
    return this.http.put(Shared.API_URL + '/api/users-role/editRole/' + id,  this.getOptions());
  }
  // edit role
  updateRole(id: string, d: RoleJson) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/users-role/updateRole/' +id , data,  this.getOptions());
  }
  // save setting
  saveSetting(d: SettingsRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/setting-role/saveSetting', data, this.getOptions());
  }
  // edit setting
  editSetting(id: string, d: SettingsRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/setting-role/editSetting/'+ id, data, this.getOptions());
  }
  // save classification and rule role
  saveRegulationRole(d: RoleRegulationJson) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/classification-role/saveClassification', data, this.getOptions());
  }
  // edit classification and rule role
  editRegulationRole(id: string, d: RoleRegulationJson) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/classification-role/editClassification/'+ id, data, this.getOptions());
  }
 // save new data life cycle role
  saveDataLifeCycle(d: LifeCycleJson) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/data-life-cycle-role/saveDataLifeCycle' , data,  this.getOptions());
  }
  // update data life cycle role
  editDataLifeCycle(id: string, d: LifeCycleJson) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/data-life-cycle-role/editDataLifeCycle/' + id, data,  this.getOptions());
  }
  // save dashboard
  saveDashboard(d: DashBoardRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/dashboard-role/saveDashboard', data, this.getOptions());
  }
  // edit setting
  editDashboard(id: string, d: DashBoardRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/dashboard-role/editDashboard/' + id, data, this.getOptions());
  }
  getUsersByRole(name:string) {
    return this.http.get(Shared.API_URL + '/api/users-role/getUsersByRole/' + name, this.getOptions());
  }

  getApplications() {
    return this.http.get(Shared.API_URL + '/api/application/getAplications', this.getOptions());
  }
  getOptions(){
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  // save version
  saveVersion(d: MetaRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/version-role/saveVersion', data, this.getOptions());
  }
  // edit version
  editVersion(id: string, d: MetaRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/version-role/editVersion/'+ id, data, this.getOptions());
  }
 // delete Version
  deleteVersion(id: string) {
    return this.http.delete(Shared.API_URL + '/api/version-role/deleteVersion/'+ id,  this.getOptions());
  }
  // delete Setting
  deleteSetting(id: string) {
    return this.http.delete(Shared.API_URL + '/api/setting-role/deleteSetting/'+ id,  this.getOptions());
  }
  // save meta data
  saveMeta(d: MetaRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/meta-role/saveMeta', data, this.getOptions());
  }
  // edit meta data
  editMeta(id: string, d: MetaRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/meta-role/editMeta/'+ id, data, this.getOptions());
  }
  // delete meta data
  deleteMeta(id: string) {
    return this.http.delete(Shared.API_URL + '/api/meta-role/deleteMeta/'+ id,  this.getOptions());
  }
  // save project role
  saveProject(d: any) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/project-role/saveProject', data, this.getOptions());
  }
// edit project role
  editProject(id: string, d: any) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/project-role/editProject/'+ id, data, this.getOptions());
  }
  // delete project role
  deleteProject(id: string) {
    return this.http.delete(Shared.API_URL + '/api/project-role/deleteProject/'+ id,  this.getOptions());
  }
  // textToSpeech(text){
  //   // list of languages is probably not loaded, wait for it
  //   if(window.speechSynthesis.getVoices().length === 0) {
  //     window.speechSynthesis.addEventListener('voiceschanged', function() {
  //       textToSpeech(text);
  //     });
  //   } else {
  //     // languages list available, no need to wait
  //     textToSpeech(text);
  //   }
  //
  //   function textToSpeech(txt) {
  //     // get all voices that browser offers
  //     const availableVoices = window.speechSynthesis.getVoices();
  //
  //     // this will hold an english voice
  //     let englishVoice = null;
  //
  //     // find voice by language locale "en-US"
  //     // if not then select the first voice
  //     // english_voice = available_voices.filter(d => d.lang === 'en-US')[0];
  //     for(var i= 0 ; i < availableVoices.length; i++) {
  //       if(availableVoices[i].lang === 'en-US') {
  //         englishVoice = availableVoices[i];
  //         break;
  //       }
  //     }
  //     if(englishVoice === null)
  //       englishVoice = availableVoices[0];
  //
  //     // new SpeechSynthesisUtterance object
  //     const utter = new SpeechSynthesisUtterance();
  //     utter.rate = 1;
  //     utter.pitch = 0.5;
  //     utter.lang = 'en-US';
  //     utter.text = txt;
  //     utter.voice = englishVoice;
  //
  //     // event after text has been spoken
  //     // utter.onend = function() {
  //     //   //t();
  //     //   //alert('Speech has finished');
  //     // };
  //
  //     // speak
  //     window.speechSynthesis.speak(utter);
  //   }
  // }
  getStacks(){
    return this.http.get(Shared.API_URL + '/api/user-role/getStacks', this.getOptions());
  }
  getApplicationsProject(){
    return this.http.get(Shared.API_URL + '/api/projects/getApplications/' + localStorage.getItem('userId'),  this.getOptions());
  }
  getApplModelsSecurity(event: any) {

  }

  deleteDesigner(id: string) {
    return this.http.delete(Shared.API_URL + '/api/designer-role/deleteDesigner/'+ id,  this.getOptions());
  }
  // save designer
  saveDesigner(d: MetaRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/designer-role/saveDesigner', data, this.getOptions());
  }
  // edit designer
  editDesigner(id: string, d: MetaRole) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/designer-role/editDesigner/'+ id, data, this.getOptions());
  }
  getListApplicationsReportModel(){
    return this.http.get(Shared.API_URL + '/api/user-role/getListApplicationsReportModel', this.getOptions());
  }
  // expired user resend email with token
  reSendEmailToUser(username: string , userId: string) {
    return this.http.put(Shared.API_URL + '/api/users/reSendEmailToUser/' + username + '/' + userId , null, this.getOptions());
  }
  // delete business term role
  deleteBusiness(id: string) {
    return this.http.delete(Shared.API_URL + '/api/designer-role/deleteDesigner/' + id,  this.getOptions());
  }
  // save business term
  saveBusinessTerm(d: any) {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/businessTerm-role/saveBusinessTerm', data, this.getOptions());
  }
  // edit business term
  editBusinessTerm(id: string, d: any) {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/businessTerm-role/editBusinessTerm/' + id, data, this.getOptions());
  }
  // save country
  saveCountry(d: any) {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/country-role/saveCountry', data, this.getOptions());
  }
  // edit country
  editCountry(id: string, d: any) {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/country-role/editCountry/' + id, data, this.getOptions());
  }
  // save infra
  saveInfra(d: any) {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/infra-role/saveInfra', data, this.getOptions());
  }
  // edit infra
  editInfra(id: string, d: any) {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/infra-role/editInfra/' + id, data, this.getOptions());
  }
  // save entity
  saveEntity(d: any) {
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/entity-role/saveEntity', data, this.getOptions());
  }
  // edit entity
  editEntity(id: string, d: any) {
    const data = 'data=' + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/entity-role/editEntity/' + id, data, this.getOptions());
  }
  // get dimensions
  getDimensions(){
    return this.http.get(Shared.API_URL + '/api/user-role/getDimensions', this.getOptions());
  }

  packedDimensionByStack() {
    return this.http.get(Shared.API_URL + '/api/users/packedDimensionByStack', this.getOptions());
  }
  getArchiveIdBases(app): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/users-role/getArchiveIdBases/' + app , this.getOptions());
  }
  //archiveId manager
  // save new archiveId manager role
  saveArchivesRole(d: ArchiveJson) {
    const data = "data=" + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/archive-role/saveArchivesRole' , data,  this.getOptions());
  }
  // update archiveId manager role
  editArchivesRole(id: string, d: ArchiveJson) {
    const data = "data=" + JSON.stringify(d);
    return this.http.put(Shared.API_URL + '/api/archive-role/editArchivesRole/' + id, data,  this.getOptions());
  }
  getGlossaries(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional-map-group/getGlossariesForRole' , this.getOptions());
  }
}
