import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainRulesComponent} from './main-rules/main-rules.component';


const routes: Routes = [
    {
      path : '' ,
      redirectTo : 'rules',
      pathMatch : 'full'
    },
    {
      path : 'rules',
      component : MainRulesComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RulesRoutingModule { }
