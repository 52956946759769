import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class DataLineageService {

  constructor(private http: HttpClient) { }
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getApplicationsByType(type: string, external: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getApplicationsByType/' + localStorage.getItem('userId') + '/' + type + '/' + external , this.getOptions());
  }
  getModelsByApp(app: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getModelsByApp/' + localStorage.getItem('userId') + '/' + app , this.getOptions());
  }
  getObjectsByModel(model: any): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getObjectsByModel/' + localStorage.getItem('userId') + '/' + model , this.getOptions());
  }
  getApplicationTypes(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getApplicationTypes/' + localStorage.getItem('userId')  , this.getOptions());
  }
  getTablesByObject(object: string): Observable<any> {
    const t = {object };
    const data = 'data=' + JSON.stringify(t);
    return this.http.post<any>(Shared.API_URL + '/api/data-line/getTablesByObject/' + localStorage.getItem('userId')  , data, this.getOptions());
  }
  getFieldsByTable(table: any): Observable<any>{
    console.log(table);
    let tables = '';
    table.map(t => tables += t.id + ',');
    tables = tables.substring(0, tables.lastIndexOf(','));
    console.log(tables);
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getFieldsByTable/' + localStorage.getItem('userId') + '/' + tables , this.getOptions());
  }
  getHolders(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/holder/getHolders/' + localStorage.getItem('userId'), this.getOptions());
  }
  checkApp(name: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/checkApp/'  + name , this.getOptions());
  }
  addSystem(d): Observable<any>{
    const data = 'data=' + d;
    return this.http.post(Shared.API_URL + '/api/data-line/addSystem' , data, this.getOptions());
  }
  getFieldsByTableModal(id: string): Observable<any> {
    const t = {object: id };
    const data = 'data=' + JSON.stringify(t);
    return this.http.post<any>(Shared.API_URL + '/api/data-line/getFieldsByTableModal/' + localStorage.getItem('userId') , data , this.getOptions());
  }
  addDataLineage(d): Observable<any>{
    let data = 'data=' + JSON.stringify(d);
    data = this.skipString(data);
    return this.http.post(Shared.API_URL + '/api/data-line/addDataLineage/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  updateDataLineage(d): Observable<any>{
    let data = 'data=' + JSON.stringify(d);
    data = this.skipString(data);
    return this.http.put(Shared.API_URL + '/api/data-line/updateDataLineage/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  removeDataLineage(id, action: string): Observable<any>{
    return this.http.delete(Shared.API_URL + '/api/data-line/removeDataLineage/' + localStorage.getItem('userId') + '/' + id + '/' + action, this.getOptions());
  }
  getDataLineage(id): Observable<any>{
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getDataLineage/' + localStorage.getItem('userId') + '/' + id , this.getOptions());
  }
  getAllDataLineage(): Observable<any>{
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getAllDataLineage/' + localStorage.getItem('userId') , this.getOptions());
  }
  addDataLineageContent(d): Observable<any>{
    let data = 'data=' + JSON.stringify(d);
    data = this.skipString(data);
    return this.http.post(Shared.API_URL + '/api/data-line/addDataLineageContent/' + localStorage.getItem('userId') , data, this.getOptions());
  }
  getEntities(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getEntities' , this.getOptions());
  }
  getDataLineageList(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/data-line/getDataLineageList/' + id , this.getOptions());
  }
  getBusinessTerm(id: string){
    return this.http.get<any>(Shared.API_URL + '/api/business_term/getBusinessTerm/' + id  , this.getOptions());
  }
  packedDimensionByLineage(){
    return this.http.get<any>(Shared.API_URL + '/api/data-line/packedDimensionByLineage/'  + localStorage.getItem('userId')  , this.getOptions());
  }
  getScreenShot(element, edges, func = false, pattern: string, bgColor: string, width: string, id: string, action) {
      const data = { location, element, edges, func, pattern, bgColor, width, id, action};
      return this.http.post(Shared.NODE_URL + '/dataLineage', data, { headers: new HttpHeaders({
            Accept: 'image/jpeg', 'Content-Type': 'application/json'
          }), responseType: 'blob'});
  }
  removeScreenShot(fileName: string) {
    const data = {fileName};
    return this.http.post(Shared.NODE_URL + '/delete' , data,{ headers: new HttpHeaders({
         'Content-Type': 'application/json'
      }), responseType: 'text'});
  }

  uploadDataLineages(d): Observable<any>{
    const data = 'data=' + JSON.stringify(d);
    return this.http.post(Shared.API_URL + '/api/data-line/uploadDataLineages/' + localStorage.getItem('userId') + '/' + localStorage.getItem('lang') , data, this.getOptions());
  }
  skipString(str) {
    if(str == null) {return str;}
    let name  = str.toString().replace(/\//g, '__________');
    name = name.replace(/&/g, '::::::::');
    return name;
  }
}
