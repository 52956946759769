import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Shared} from '../../shared';
import {RoleService} from '../../services/users/role.service';
import {Observable} from "rxjs";
import { DragulaService } from 'ng2-dragula';
import Swal from "sweetalert2";
import {AppRole} from './AppRole';
import { TranslateService } from '@ngx-translate/core';




declare const $: any;
declare const swal: any;
@Component({
  selector: 'app-roleslist',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css'],
  providers: [DragulaService]
})
export class RolesComponent implements OnInit {
  lang: string;
  public frmRoleSignup: FormGroup;
  roleForm: FormGroup;
  RoleName: string;
  roleUpload = false;
  postData: string;
  dataList: any[];
  // checked = false;
  approles: AppRole;
  listappRoles = [];
  allApplications: any;
  listApps = [];
  // permissions = [
  //   { name: 'Select',
  //     update: '0'},
  //   { name: 'Update',
  //     update: '1' },
  //   { name: 'Delete',
  //     update: '2' }
  // ];
  constructor( private roleService: RoleService, private router: Router, private fb: FormBuilder, private dragulaService: DragulaService,  private translate: TranslateService) {
    this.frmRoleSignup = this.initForm();

    this.dragulaService.dropModel("VAMPIRES").subscribe( (arg) => {
      const {targetModel, item, target} = arg;
    //  console.log(item);
     // console.log(targetModel);
      /** Check when blue column and blue item was picked */
      // if(target['dataset']['group'] === 'blue' && item.group === 'blue') {
      //   const initPos = this.vamps.indexOf(item);
      //   const currPos = targetModel.indexOf(item);
      //   /** Hack that need to change items only after item is lended */
      //   setTimeout(_ =>{
      //     targetModel.splice(currPos, 1);
      //     targetModel.splice(initPos, 0, item);
      //     /**TODO: good user expiriance to add some toast/notification for user that change order is forbidden */
      //   }, 0);
      //
      // } else if(item.group === 'blue'){
      //   /** Change color of item because otherwise we have to handle special order when this item will be drop back to the inital column */
      //   item.group = 'none';
      // }
      // $('#roleUpload').change(function() {
      //   return  $('#roleUpload').is(':checked');
      // });
    });
    this.dragulaService.drop("VAMPIRES").subscribe( (arg) => {
      const {el, target, source, sibling} = arg;
      console.log(sibling);
     // console.log($('#selectPerm' + el.name).is(':checked'));
      /** Check when blue column and blue item was picked */
      // if(target['dataset']['group'] === 'blue' && item.group === 'blue') {
      //   const initPos = this.vamps.indexOf(item);
      //   const currPos = targetModel.indexOf(item);
      //   /** Hack that need to change items only after item is lended */
      //   setTimeout(_ =>{
      //     targetModel.splice(currPos, 1);
      //     targetModel.splice(initPos, 0, item);
      //     /**TODO: good user expiriance to add some toast/notification for user that change order is forbidden */
      //   }, 0);
      //
      // } else if(item.group === 'blue'){
      //   /** Change color of item because otherwise we have to handle special order when this item will be drop back to the inital column */
      //   item.group = 'none';
      // }
      // $('#roleUpload').change(function() {
      //   return  $('#roleUpload').is(':checked');
      // });
    });
    this.dragulaService.createGroup("VAMPIRES", {
      // ...
    });

  }

  ngOnInit() {
    // this.onGetList()
    this.GetRole();
    this.roleService.getRefreshNeeded().subscribe(
      () => {
        this.GetRole();
      }
    );
    this.roleService.getRefreshDelete().subscribe(
      () => {
        this.GetRole();
      }
    );

    this.roleUpload = $('#roleUpload').change(function() {
      return  $('#roleUpload').is(':checked');
    });

    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
  const that = this;
  setInterval(() => {
    this.lang = localStorage.getItem('lang') || 'fr';
    this.translate.use(this.lang);
  }, 10);
  }
  onGetListApplicationsReportModel() {
    var temp = [];
    // this.roleService.getListApplicationsReportModel().subscribe(
    //   (result) => {
    //     this.allApplications = result;
    //     this.allApplications.forEach(function (element) {
    //
    //       temp.push({
    //         name : element,
    //         select: 0,
    //         update: 1,
    //         delete: 0
    //       });
    //     });
    //   },
    //   err => {
    //     console.error(err);
    //   },
    //   () => {
    //     this.listApps = temp;
    //   }
    // );
  }
  //
  // GetRoles() {
  //   this.roleService.getRoleList().subscribe(
  //     (data) => {
  //       this.dataList = data.data;
  //     },
  //     err => console.log(err)
  //   );
  // }

  initForm(): FormGroup {
    return this.roleForm = this.fb.group(
      {
        nameRole: [
          null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }

  onSubmitAddRole() {
    const formValue = this.roleForm.value;
    this.RoleName =  formValue["nameRole"];
    // this.roleUpload =  formValue["roleUpload"];
    this.postData = "name=" + this.RoleName+"&approles="+"yyyyyy"+"&upload="+this.roleUpload;

    this.roleService.addRole(this.postData).subscribe(
      validation => {
        if (validation['success'] === false) {
          $('#roleModal').modal('hide');
          swal({
            title: this.lang === 'en' ? "Error Role Name!" : "Erreur de Nom de Rôle!",
            text: validation['message'],
            timer: 2000,
            showConfirmButton: false
          });
        } else {
          this.postData = "name=" + this.RoleName;

          this.roleService.addRole(this.postData).subscribe(
            data => {
              $('#roleModal').modal('hide');
              let titleAddSwall = this.lang === 'en' ? "Add Success!" : "Ajout réussi !";
              let textAddSwall= this.lang === 'en' ? "New Role Added." : "Nouveau rôle ajouté.";
              
              swal({
               title: titleAddSwall,
               text: textAddSwall,
               timer: 2000,
               showConfirmButton: false
              });
              
            }, // this.allLocs = data,
            err => {console.error(err);
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });

              let title = this.lang === 'en' ? 'Wrong login name or password' : 'Nom d\'utilisateur ou mot de passe incorrect';
              Toast.fire({
               icon: 'success',
               title: title
              });
              
              // this.showNotification('alert-danger', 'login ou mot de pass erronés',
              //   'top', 'right', 'animated fadeInRight', 'animated fadeOutRight');
            }
          );
        }
      },
      err => {console.error(err);
      }
    );
  }
  showNotification(colorName, text, placementFrom, placementAlign, animateEnter, animateExit) {
    if (colorName === null || colorName === '') { colorName = 'bg-black'; }
    if (text === null || text === '') { text = 'Turning standard Bootstrap alerts'; }
    if (animateEnter === null || animateEnter === '') { animateEnter = 'animated fadeInDown'; }
    if (animateExit === null || animateExit === '') { animateExit = 'animated fadeOutUp'; }
    var allowDismiss = true;

    $.notify({
        message: text
      },
      {
        type: colorName,
        allow_dismiss: allowDismiss,
        newest_on_top: true,
        timer: 1000,
        placement: {
          from: placementFrom,
          align: placementAlign
        },
        animate: {
          enter: animateEnter,
          exit: animateExit
        },
        template: '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} '
          + (allowDismiss ? 'p-r-35' : '') + '" role="alert">' +
          '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
          '<span data-notify="icon"></span> ' +
          '<span data-notify="title">{1}</span> ' +
          '<span data-notify="message">{2}</span>' +
          '<div class="progress" data-notify="progressbar">' +
          '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0"' +
          'aria-valuemax="100" style="width: 0%;"></div>' +
          '</div>' +
          '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      });
  }
  GetRole(){

    // $('#tableExport').on('click', '.editor_remove', function (e) {
    //     e.preventDefault();
    //    const id=$(e.target).parent().parent().first().html();
    //     this.onRemoveUser( id );
    // } );
    $('#tableRole' ).empty();
    $('#tableRole' ).DataTable({
      "columnDefs": [
        // {"visible": false, "targets": 0},
// { "visible": false, "targets": 8 }
      ],
      pagingType: 'full_numbers',
      processing: true,
      responsive: true,
      colReorder: true,
      serverSide: true,
      ajax: (dataTablesParameters: any, callback) => {
        var table = $('#tableRole' ).DataTable();
        var info = table.page.info();
        var sortInfo = table.order();
        var sortColumn = $(table.column(sortInfo[0][0]).header()).html().replace(/\s/g, "");
        var sortOrder = sortInfo[0][1];
        var search = table.search();
        if (search === ""){
          search = null;
        }
        // this.roleService.getRoleList(info.page,search,sortColumn,sortOrder).subscribe(
        //   data => {
        //     this.dataList = [];
        //     for (let table of data.data) {
        //       this.dataList.push(table);
        //     }
        //     callback({
        //       recordsTotal: data.count,
        //       recordsFiltered: data.count,
        //       data: this.dataList,
        //     });
        //   },
        //   err => {
        //     console.error(err);
        //   }
        // );
      },


      "columns": [
        {"data": "id", title: 'Id'},
        {"data": "name", title: 'Role Name'},
        {
          "className": '',
          "orderable": false,
          "data":null,
          "defaultContent": '<button class="btn btn-circle btn-danger ng-scope editor_remove" onclick="onRemoveUser(event,\'' + Shared.API_URL+'\')" data-type="html-message" ><i class="fas fa-trash-alt"></i></button>',
          title: 'Action'
        }

      ],
      // "initComplete": function(settings, json) {
      //     $('#tableExport > tbody  > tr').each(function(index, tr) {
      //         $(tr).find('td:first').hide() ;
      //     });
      //
      // }
// rowGroup: {
// dataSrc: 'joinType'
// },
      /*  "drawCallback": function (settings) {
            var api = this.api();
            var rows = api.rows({page: 'current'}).nodes();
            var last = null;

            api.column(7, {page: 'current'}).data().each(function (group, i) {
                if (last !== group) {
                    switch (group) {
                        case 1:
                            $(rows).eq(i).before(
                                '<tr><td class="group_red" colspan="7">Key – Key </td></tr>'
                            );
                            break;
                        case 2:
                            $(rows).eq(i).before(
                                '<tr><td class="group_blue" colspan="7">Key – Field </td></tr>'
                            );
                            break;
                        case 3:
                            $(rows).eq(i).before(
                                '<tr><td class="group_green" colspan="7">Field – Field </td></tr>'
                            );
                            break;
                    }


                    last = group;
                }
            });
        },*/
    });
  }
  checkVal(event) {
    const target = $(event.target);
    const selected = target.is(':checked');
    const idSelect  = target.attr('id');
    const appname = idSelect.split('selectPerm_')[1];
    const data = this.listApps.filter(d => d.name === appname);
    const objIndex = this.listApps.findIndex((obj => obj.name === appname));
    this.listApps[objIndex].select = selected ? 1 : 0;
    console.log(objIndex);

  }
}
