import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from 'rxjs';
import {tap} from "rxjs/operators";
import {getCookieValue, Shared} from '../../shared';
@Injectable({
  providedIn: 'root'
})
export class UserremoveService {
    private refreshdelete = new Subject<void>();
    id: string;
    Options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            Accept: 'Application/json',
            Authorization: 'Bearer ' + getCookieValue('tokendrd')
            //  'Accept': 'Application/json'
        })
    };
  // test = 'https://1de7e37d.dm.systnaps.com/sight/ddtm-api/web';
  // test = 'https://39576b66.ddtm.systnaps.com/ddtm-api/web';
  test = 'http://localhost:8000';
  // test = 'http://54.36.121.107:5678';
  constructor(private http: HttpClient) { }
    getRefreshDelete() {
        return this.refreshdelete;
    }
    removeUser(id) {
        return this.http.post(Shared.API_URL + '/api/users/remove/' + id, this.id, this.Options)
            .pipe(
                tap(
                    () => {
                        this.refreshdelete.next();
                    })
            );
    }
}
