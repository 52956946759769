export class RoleRegulationJson {
  name: string;
  description: string;
  sup = false;
  versionAdmin: boolean;
  classifications: any[];
  security: any[];
  rules: any[];
  retention: any;
  def: any;
  constructor(name: string, description: string, sup: boolean, versionAdmin: boolean, classifications: any[], security: any[], rules: any[], retention: any, def: any) {
    this.name = name;
    this.description = description;
    this.sup = sup;
    this.versionAdmin = versionAdmin;
    this.classifications = [...classifications];
    this.security = [...security];
    this.rules = [...rules];
    this.retention = retention;
    this.def = def;
  }
}
