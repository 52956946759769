import {Component, OnInit, ɵɵqueryRefresh} from '@angular/core';
import {Router} from '@angular/router';
import {NgForm} from '@angular/forms';
import {AuthenticationService} from '../services/authentication.service';
declare const $: any;
import {Token} from '../models/token';
import {ProfileService} from '../services/users/profile.service';
import {Profile} from '../models/profile';
import {ReportService} from "../services/reports/report.service";
import Swal from "sweetalert2";
import {RouterhistoryService} from '../services/appService/routerhistory.service';
import {TipsService} from '../services/tips/tips.service';
import { TranslateService } from '@ngx-translate/core';
import {environment} from '../../environments/environment.onprem';
import {getCookieValue} from '../shared';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.sass']
})
export class AuthComponent implements OnInit {
    username: string;
    usernameReel: string;
    password: string;
    postData: any;
    postUsername: any;
    tok: Token;
    profile: Profile;
    bad = {code: 401, message: 'Bad credentials'};
    // lang = 'fr';
    portal = 'false';
    constructor(private router: Router, private authenticationService: AuthenticationService,
                private profileService: ProfileService, private reportService: ReportService, private serviceTips: TipsService, private translate: TranslateService) {
    }
    ngOnInit() {
        this.tok = null;
        this.portal = environment.envVar.PORTAL;
        if(this.portal == 'true') {
            const tokendrd = getCookieValue('tokendrd');
            const username = getCookieValue('username');
            if(tokendrd != '' && username != '') {
                localStorage.setItem('username', username);
                // localStorage.setItem('token', tokendrd);
                this.tok = new Token(tokendrd);
                this.usernameReel = username;
                this.redirectAfterAuth(this.tok);
            } else {
                document.getElementById('main-component').style['display'] = 'none';
                window.location.href =  environment.envVar.PORTAL_URL;
            }
            console.log(tokendrd);
        } else {
            document.getElementById('main-component').style['display'] = 'none';
        }
    }
    onSubmit(form: NgForm) {
        $('#login_submit')
            .html(' Contacting server <div class="spinner-grow spinner-grow-sm" style="color: #fbfbff" role="status">\n' +
                '  <span class="sr-only">Chargement...</span>\n' +
                '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
                '  <span class="sr-only">Chargement...</span>\n' +
                '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffbb1" role="status">\n' +
                '  <span class="sr-only">Chargement...</span>\n' +
                '</div>');
        this.username = form.value['username'];
        this.usernameReel = form.value['username'];
        this.password = form.value['password'];
        form.reset();
        this.postData ="username=" + this.username + "&password=" + this.password + "&language=" + "fr";
        this.username ="username=" + this.username + "&language=" + "fr";
        this.authenticationService.getToken(this.postData).subscribe(
            data => {
                this.redirectAfterAuth(data);
            },
            err => {console.error(err);
                $('#login_submit')
                    .html('Login');
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                Toast.fire({
                    icon: 'success',
                    title: 'Nom d\'utilisateur ou mot de passe incorrect'
                });
                //     this.authenticationService.showNotification('alert-danger', 'login ou mot de pass erronés',
                // 'top', 'right', 'animated fadeInRight', 'animated fadeOutRight');
            }
        );
    }


    redirectAfterAuth(data) {
        this.tok = data;
        if (this.tok != null) {
            if (this.portal == 'false') {
                document.cookie = 'username=' + this.usernameReel;
                document.cookie = 'tokendrd=' + this.tok.token ;
            }
            localStorage.setItem('username', this.usernameReel);
            const username = getCookieValue('username');
            this.authenticationService.getTipsMain(this.usernameReel).subscribe(
                result => {
                    $('#tipsAllApplication').modal('hide');
                    localStorage.setItem('redirectToSearch', 'false');
                    if (result == true) {
                        this.serviceTips.getTipsPerName('Main').subscribe(
                            res => {
                                if (res.length > 0) {
                                    const resVersion = res[0].version;
                                    if (resVersion != null) {
                                        const indexLastVersion = resVersion.length - 1;
                                        const selectedVersion = resVersion[indexLastVersion];
                                        switch (res[0].typedesc) {
                                            case 'text': {
                                                $('#descTipsMain').html(selectedVersion.description).css({
                                                    opacity: 1,
                                                    height: '80%',
                                                    width: '100%'
                                                });
                                                $('#cke_descTipsMainEditor').css({opacity: 0, height: 0, width: 0});
                                                $('#descTipsMainEditor').css({opacity: 0, height: 0, width: 0});
                                                $('#zoneUpdateImageTipsMain').css({opacity: 0, height: 0, width: 0});
                                                $('#zoneUpdateVideoTipsMain').css({opacity: 0, height: 0, width: 0});
                                                break;
                                            }
                                            case 'image': {
                                                $('#descTipsMain').html(selectedVersion.image).css({
                                                    opacity: 1,
                                                    height: '80%',
                                                    width: '100%'
                                                });
                                                $('#cke_descTipsMainEditor').css({opacity: 0, height: 0, width: 0});
                                                $('#descTipsMainEditor').css({opacity: 0, height: 0, width: 0});
                                                $('#zoneUpdateImageTipsMain').css({opacity: 0, height: 0, width: 0});
                                                $('#zoneUpdateVideoTipsMain').css({opacity: 0, height: 0, width: 0});
                                                break;
                                            }
                                            case 'video': {
                                                $('#descTipsMain').html(selectedVersion.video).css({
                                                    opacity: 1,
                                                    height: '80%',
                                                    width: '100%'
                                                });
                                                $('#cke_descTipsMainEditor').css({opacity: 0, height: 0, width: 0});
                                                $('#descTipsMainEditor').css({opacity: 0, height: 0, width: 0});
                                                $('#zoneUpdateImageTipsMain').css({opacity: 0, height: 0, width: 0});
                                                $('#zoneUpdateVideoTipsMain').css({opacity: 0, height: 0, width: 0});
                                                break;
                                            }
                                        }
                                    }
                                }
                            },
                            err => {
                                console.error(err);
                            },
                            () => {
                                let id = localStorage.getItem('userId');
                                this.authenticationService.removeReportDetailCollectionByUser(id).subscribe(
                                    data => {
                                    },
                                    err => {
                                        console.error(err);
                                    },
                                    () => {

                                    }
                                );
                                $('#tipsAllApplication').modal('hide'); // show
                                localStorage.setItem('redirectToSearch', 'true');//new
                            }
                        );
                        localStorage.setItem('redirectToSearch', 'true'); // false
                        $('#tipsAllApplication').modal('hide'); // new
                    } else {
                        $('#tipsAllApplication').modal('hide');
                        localStorage.setItem('redirectToSearch', 'true');
                    }
                },
                err => {
                    console.error(err);
                }
            );
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });

            Toast.fire({
                icon: 'success',
                title: 'Bienvenue chez Systnaps'
            });

            // this.authenticationService.showNotification('alert-success', 'Welcome to Systnaps',
            //     'top', 'right', 'animated rotateInUpLeft', 'animated rotateOutUpLeft');
            //   setTimeout(() => {
            //   this.router.navigate(['dashboard']);
            // }, 2000);
            //   this.router.navigate(['dashboard/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/main']);
            this.router.navigate(['anonym-board']);
        }
    }
}
