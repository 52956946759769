export class DashBoardRole{
  name: string;
  description: string;
  appl: string;
  read = false;

  constructor(name, description , appl , read) {
    this.name = name;
    this.description = description;
    this.appl = appl;
    this.read = read;
  }
}
