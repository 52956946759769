import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {GlobalSearchService} from '../services/globalSearch/global-search.service';
import {OrderPipe} from 'ngx-order-pipe';
import {CryptoService} from '../crypto.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {RouterhistoryService} from '../services/appService/routerhistory.service';
import Chart from 'chart.js';
import * as D3 from 'd3';
import * as d3Cloud from 'd3-cloud';
import {ActivatedRoute, Router} from '@angular/router';
import {TipsService} from '../services/tips/tips.service';
import {addLoaderSearch, removeLoader} from '../object-models/graphs/diagramme/utils';
import {getCookieValue} from '../shared';
declare let d3: any;
declare const $: any;
@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.css']
})
export class GlobalSearchComponent implements OnInit, OnDestroy, AfterViewInit {
  keysFr = [{id: 'all', name: 'Toutes les categories'}, {id: 'project', name: 'Projets'}, {id: 'glossary', name: 'Glossaire métier'}, {id: 'functionalmap', name: 'Cartographie Fonctionnelle'},
      {id: 'lineage', name: 'Lignage de données'} , {id: 'objectmodel', name: 'Modèle d\'objet'}, {id: 'catalog', name: 'Catalogue des données'}, {id: 'report', name: 'Dictionnaire des donneès'},
      {id: 'dimension', name: 'Dimensions'}];
  keysEng = [{id: 'all', name: 'All Category'}, {id: 'project', name: 'Projects'}, {id: 'glossary', name: 'Business Glossary'}, {id: 'functionalmap', name: 'Functional Maps'},
      {id: 'lineage', name: 'Data Lineage'}, {id: 'objectmodel', name: 'Object Model'}, {id: 'catalog', name: 'Data Catalog'}, {id: 'report', name: 'Data Dictionary'},
      {id: 'dimension', name: 'Dimensions'}];
  selectedCateg = 'all';
  textSearch: string;


  subscription: Subscription = new Subscription();
  lang: string;
  username = getCookieValue('username');
  /****** indicators **********/
  hiderIndicators = false;
  /****** end indicators ******/

  // paginate your search history
  resultSearchHistory = [];
  flagSearchHistory = false;
  pageSearchHistory = 1;
  totalSearchHistory = 0;
  loadingSearchHistory: boolean;
  configSearchHistory: any;
  orderSearchHistory = 'datesearch';
  reverseSearchHistory = true;
  selectedPageSizeSearchHistory = 5;
  pageSizeListSearchHistory = [5, 10];
  filterSearchHistory: any;
  hiderSearchHistory = true;
  // end paginate your search history

  // paginate last opening object
  resultLastOpenObj = [];
  flagLastOpenObj = false;
  pageLastOpenObj = 1;
  totalLastOpenObj = 0;
  loadingLastOpenObj: boolean;
  configLastOpenObj: any;
  orderLastOpenObj = 'countVisit';
  reverseLastOpenObj = true;
  selectedPageSizeLastOpenObj = 5;
  pageSizeListLastOpenObj = [5, 10];
  filterLastOpenObj: any;
  hiderLastOpenObj = true;
  // end paginate last opening object

  // paginate search result (Object Model)
  resultSearchObject = [];
  flagResultSearch = false;
  pag = 1;
  totalResultSearch = 0;
  loadingResultSearch: boolean;
  configResultSearch: any;
  orderResultSearch = 'id';
  reverseResultSearch = false;
  selectedPageSizeResultSearch = 5;
  pageSizeListResultSearch = [5, 10 , 15 , 20 , 25 , 30 ];
  filterResultSearch: any;
  // end paginate search result
  // paginate search result (data catalog)
  resultSearchCatalog = [];
  flagResultSearchCatalog = false;
  pagCatalog = 1;
  totalResultSearchCatalog = 0;
  loadingResultSearchCatalog: boolean;
  configResultSearchCatalog: any;
  orderResultSearchCatalog = 'id';
  reverseResultSearchCatalog = false;
  selectedPageSizeResultSearchCatalog = 5;
  pageSizeListResultSearchCatalog = [5, 10 , 15 , 20 , 25 , 30 ];
  filterResultSearchCatalog: any;
  // end paginate search result

  // paginate search result (data dictionary)
  resultSearchObjectReport = [];
  flagResultSearchReport = false;
  pagReport = 1;
  totalResultSearchReport = 0;
  loadingResultSearchReport: boolean;
  configResultSearchReport: any;
  orderResultSearchReport = 'id';
  reverseResultSearchReport = false;
  selectedPageSizeResultSearchReport = 5;
  pageSizeListResultSearchReport = [5, 10 , 15 , 20 , 25 , 30 ];
  filterResultSearchReport: any;
  // end paginate search result
  // paginate search result (data governance projects)
  resultSearchProjects = [];
  flagResultSearchProjects = false;
  pagProjects = 1;
  totalResultSearchProjects = 0;
  loadingResultSearchProjects: boolean;
  configResultSearchProjects: any;
  orderResultSearchProjects = 'id';
  reverseResultSearchProjects = false;
  selectedPageSizeResultSearchProjects = 5;
  pageSizeListResultSearchProjects = [5, 10 , 15 , 20 , 25 , 30 ];
  filterResultSearchProjects: any;
  // end paginate search result
  // paginate search result (data governance functional maps)
  resultSearchMaps = [];
  flagResultSearchMaps = false;
  pagMaps = 1;
  totalResultSearchMaps = 0;
  loadingResultSearchMaps: boolean;
  configResultSearchMaps: any;
  orderResultSearchMaps = 'id';
  reverseResultSearchMaps = false;
  selectedPageSizeResultSearchMaps = 5;
  pageSizeListResultSearchMaps = [5, 10 , 15 , 20 , 25 , 30 ];
  filterResultSearchMaps: any;
  // end paginate search result
  // paginate search result (data governance data lineage)
  resultSearchLineage = [];
  flagResultSearchLineage = false;
  pagLineage = 1;
  totalResultSearchLineage = 0;
  loadingResultSearchLineage: boolean;
  configResultSearchLineage: any;
  orderResultSearchLineage = 'id';
  reverseResultSearchLineage = false;
  selectedPageSizeResultSearchLineage = 5;
  pageSizeListResultSearchLineage = [5, 10 , 15 , 20 , 25 , 30 ];
  filterResultSearchLineage: any;
  // end paginate search result
  // paginate search result (data governance business glossary)
  resultSearchGlossary = [];
  resultSearchGlossaryOld = [];
  flagResultSearchGlossary = false;
  pagGlossary = 1;
  totalResultSearchGlossary = 0;
  loadingResultSearchGlossary: boolean;
  configResultSearchGlossary: any;
  orderResultSearchGlossary = 'id';
  reverseResultSearchGlossary = false;
  selectedPageSizeResultSearchGlossary = 5;
  pageSizeListResultSearchGlossary = [5, 10 , 15 , 20 , 25 , 30 ];
  filterResultSearchGlossary: any;
  // end paginate search result
  // paginate search result (data governance dimension)
  resultSearchDimension = [];
  flagResultSearchDimension = false;
  pagDimension = 1;
  totalResultSearchDimension = 0;
  loadingResultSearchDimension: boolean;
  configResultSearchDimension: any;
  orderResultSearchDimension = 'id';
  reverseResultSearchDimension = false;
  selectedPageSizeResultSearchDimension = 5;
  pageSizeListResultSearchDimension = [5, 10 , 15 , 20 , 25 , 30 ];
  filterResultSearchDimension: any;
  // end paginate search result

  /*** start indicators word-cloud ***/
  countEmpty = 0;
  private svg;               // SVG in which we will print our chart
  private margin: {          // Space between the svg borders and the actual chart graphic
    top: number,
    right: number,
    bottom: number,
    left: number
  };
  private width: number;      // Component width
  private height: number;     // Component height
  private fillScale;          // D3 scale for text color
  word = [];
  /*** end indicators word-cloud ***/
  // start user profile

  cryptoSystem: any;
  cryptoCategory: any;
  cryptoOnglet: any;
  cryptoComponent: any;
  // end user profile

  // tooltip
  xMousePos = 0;
  yMousePos = 0;
  // end tooltip
  dashboardBridge = {system: '', category: '', onglet: '', component: ''};
  searchBrigde = { application: '', model: '', component: '' };
  constructor(private searchService: GlobalSearchService, private orderPipeRules: OrderPipe,
              public translate: TranslateService, private serviceTips: TipsService,
              private routerHistoryService: RouterhistoryService, private cryptoService: CryptoService,
              private activeRoute: ActivatedRoute, private router: Router) {
    this.configLastOpenObj = { id: 'serverLastOpenObj', itemsPerPage: this.selectedPageSizeLastOpenObj,
      currentPage: this.pageLastOpenObj, totalItems: this.totalLastOpenObj};
    this.configSearchHistory = { id: 'serverSearchHistory', itemsPerPage: this.selectedPageSizeSearchHistory,
      currentPage: this.pageSearchHistory, totalItems: this.totalSearchHistory};
    this.configResultSearch = { id: 'server', itemsPerPage: this.selectedPageSizeResultSearch,
      currentPage: this.pag, totalItems: this.totalResultSearch};
    this.configResultSearchCatalog = { id: 'serverCatalog', itemsPerPage: this.selectedPageSizeResultSearchCatalog,
      currentPage: this.pagCatalog, totalItems: this.totalResultSearchCatalog};
    this.configResultSearchReport = { id: 'serverReport', itemsPerPage: this.selectedPageSizeResultSearchReport,
      currentPage: this.pagReport, totalItems: this.totalResultSearchReport};
    this.configResultSearchProjects = { id: 'serverProjects', itemsPerPage: this.selectedPageSizeResultSearchProjects,
      currentPage: this.pagProjects, totalItems: this.totalResultSearchProjects};
    this.configResultSearchMaps = { id: 'serverMaps', itemsPerPage: this.selectedPageSizeResultSearchMaps,
      currentPage: this.pagMaps, totalItems: this.totalResultSearchMaps};
    this.configResultSearchLineage = { id: 'serverLineage', itemsPerPage: this.selectedPageSizeResultSearchLineage,
      currentPage: this.pagLineage, totalItems: this.totalResultSearchLineage};
    this.configResultSearchGlossary = { id: 'serverGlossary', itemsPerPage: this.selectedPageSizeResultSearchGlossary,
      currentPage: this.pagGlossary, totalItems: this.totalResultSearchGlossary};
    this.configResultSearchDimension = { id: 'serverDimension', itemsPerPage: this.selectedPageSizeResultSearchDimension,
      currentPage: this.pagDimension, totalItems: this.totalResultSearchDimension};
  }

  ngOnInit(): void {
    localStorage.removeItem('redirectToSearch');
    this.onGetLastTenOpeningObject();
    this.onGetSearchHistoryByUser();
    let subscription0$ = this.activeRoute.paramMap.subscribe(
      params => {
        let newTextSearch = params.get('textSearch');
        newTextSearch = newTextSearch.replace(/__________/g, '/');
        newTextSearch = this.cryptoService.get(newTextSearch);
        // get params if only not empty
        if (newTextSearch != null && newTextSearch != '0') {
          $('#inputTextSearch').val(newTextSearch);
          setTimeout(() => {
            this.GlobalSearch();
          }, 1000);
        }
      }
    );
    setTimeout(() => {
      $('#onglet-searchResult').css('display', 'none');
      $('.s002').css('display', 'block');
      $('#listMenu').css('display', 'block');
    }, 1000);
    // this.onGetAllTips();
    // this.onGetTipsPerName('Dashboard');
    this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.onGetTopTenVisitenToBarChartAll();
      this.showTipsSearch();
    }, 1000);
  }
  showTipsSearch() {
    let menu = localStorage.getItem('dataMenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsSearch');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsSearch') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsSearch', 'true');
        this.serviceTips.getTipsPerName('Search').subscribe(
          result => {
            const resVersion = result[0].version;
            const indexLastVersion = resVersion.length - 1;
            const selectedVersion = resVersion[indexLastVersion];
            switch (result[0].typedesc) {
              case 'text': $('#descTipsSearch').html(selectedVersion.description); break;
              case 'image': $('#descTipsSearch').html(selectedVersion.image); break;
              case 'video': $('#descTipsSearch').html(selectedVersion.video); break;
            }
          },
          err => {
            console.error(err);
          },
          () => {
            $('#tipsCompSearch').modal('show');
          }
        );
        // $('#tipsCompSearch').modal('show');
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsSearch') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsSearch', 'true');
          this.serviceTips.getTipsPerName('Dashboard').subscribe(
            result => {
              const resVersion = result[0].version;
              const indexLastVersion = resVersion.length - 1;
              const selectedVersion = resVersion[indexLastVersion];
              switch (result[0].typedesc) {
                case 'text': $('#descTipsSearch').html(selectedVersion.description); break;
                case 'image': $('#descTipsSearch').html(selectedVersion.image); break;
                case 'video': $('#descTipsSearch').html(selectedVersion.video); break;
              }
            },
            err => {
              console.error(err);
            },
            () => {
              $('#tipsCompSearch').modal('show');
            }
          );
        }
      }, 1000);
    }
  }
  // set new etat tips Search
  onUpdateTipsSearch() {
    let tipsSearch;
    if ($('#checked_show_search').is(':checked')) {
      tipsSearch = false;
    } else {
      tipsSearch = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsSearch,
      dataMenu: this.lang === 'en' ? 'Global Search' : 'Recherche Globale',
      dataSousmenu: this.lang === 'en' ? 'Global Search' : 'Recherche Globale'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        // console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  /**************************************** End tips global application *************************************/
  /************************* start Object Model *********************/
  pageChangedResultSearch(event) {
    this.configResultSearch.currentPage = event;
  }
  getPageResultSearch() {
    this.configResultSearch.itemsPerPage = this.selectedPageSizeResultSearch;
    this.configResultSearch.currentPage = 1;
    this.configResultSearch.totalItems = this.resultSearchObject.length;
  }
  // sort rules
  setOrderResultSearch(value: string) {
    if (this.orderResultSearch === value) {
      this.reverseResultSearch = !this.reverseResultSearch;
    }

    this.orderResultSearch = value;
  }
  // comparator sort
  sortAlphaNumResultSearch(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  /************************* end Object Model ********************/
  /************************* start data catalog *********************/
  pageChangedResultSearchCatalog(event) {
    this.configResultSearchCatalog.currentPage = event;
  }
  getPageResultSearchCatalog() {
    this.configResultSearchCatalog.itemsPerPage = this.selectedPageSizeResultSearchCatalog;
    this.configResultSearchCatalog.currentPage = 1;
    this.configResultSearchCatalog.totalItems = this.resultSearchCatalog.length;
  }
  // sort rules
  setOrderResultSearchCatalog(value: string) {
    if (this.orderResultSearchCatalog === value) {
      this.reverseResultSearchCatalog = !this.reverseResultSearchCatalog;
    }

    this.orderResultSearchCatalog = value;
  }
  // comparator sort
  sortAlphaNumResultSearchCatalog(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  /************************* end Data Catalog ********************/
  /************************* start Data dictionary *********************/
  pageChangedResultSearchReport(event) {
    this.configResultSearchReport.currentPage = event;
  }
  getPageResultSearchReport() {
    this.configResultSearchReport.itemsPerPage = this.selectedPageSizeResultSearchReport;
    this.configResultSearchReport.currentPage = 1;
    this.configResultSearchReport.totalItems = this.resultSearchObjectReport.length;
  }
  setOrderResultSearchReport(value: string) {
    if (this.orderResultSearchReport === value) {
      this.reverseResultSearchReport = !this.reverseResultSearchReport;
    }

    this.orderResultSearchReport = value;
  }
  // comparator sort
  sortAlphaNumResultSearchReport(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  /******************************** end Data dictionary ****************************/
  /************************* start Data Governance Projects *********************/
  pageChangedResultSearchProjects(event) {
    this.configResultSearchProjects.currentPage = event;
  }
  getPageResultSearchProjects() {
    this.configResultSearchProjects.itemsPerPage = this.selectedPageSizeResultSearchProjects;
    this.configResultSearchProjects.currentPage = 1;
    this.configResultSearchProjects.totalItems = this.resultSearchProjects.length;
  }
  setOrderResultSearchProjects(value: string) {
    if (this.orderResultSearchProjects === value) {
      this.reverseResultSearchProjects = !this.reverseResultSearchProjects;
    }

    this.orderResultSearchProjects = value;
  }
  // comparator sort
  sortAlphaNumResultSearchProjects(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  /******************************** end Projects ****************************/
  /************************* start Data Governance Functional Maps *********************/
  pageChangedResultSearchMaps(event) {
    this.configResultSearchMaps.currentPage = event;
  }
  getPageResultSearchMaps() {
    this.configResultSearchMaps.itemsPerPage = this.selectedPageSizeResultSearchMaps;
    this.configResultSearchMaps.currentPage = 1;
    this.configResultSearchMaps.totalItems = this.resultSearchMaps.length;
  }
  setOrderResultSearchMaps(value: string) {
    if (this.orderResultSearchMaps === value) {
      this.reverseResultSearchMaps = !this.reverseResultSearchMaps;
    }

    this.orderResultSearchMaps = value;
  }
  // comparator sort
  sortAlphaNumResultSearchMaps(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  /******************************** end Functional Maps ****************************/
  /************************* start Data Governance Data Lineage *********************/
  pageChangedResultSearchLineage(event) {
    this.configResultSearchLineage.currentPage = event;
  }
  getPageResultSearchLineage() {
    this.configResultSearchLineage.itemsPerPage = this.selectedPageSizeResultSearchLineage;
    this.configResultSearchLineage.currentPage = 1;
    this.configResultSearchLineage.totalItems = this.resultSearchLineage.length;
  }
  setOrderResultSearchLineage(value: string) {
    if (this.orderResultSearchLineage === value) {
      this.reverseResultSearchLineage = !this.reverseResultSearchLineage;
    }

    this.orderResultSearchLineage = value;
  }
  // comparator sort
  sortAlphaNumResultSearchLineage(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  /******************************** end Data Lineage ****************************/
  /************************* start Data Governance Business Glossary *********************/
  pageChangedResultSearchGlossary(event) {
    this.configResultSearchGlossary.currentPage = event;
  }
  getPageResultSearchGlossary() {
    this.configResultSearchGlossary.itemsPerPage = this.selectedPageSizeResultSearchGlossary;
    this.configResultSearchGlossary.currentPage = 1;
    this.configResultSearchGlossary.totalItems = this.resultSearchGlossary.length;
  }
  setOrderResultSearchGlossary(value: string) {
    if (this.orderResultSearchGlossary === value) {
      this.reverseResultSearchGlossary = !this.reverseResultSearchGlossary;
    }

    this.orderResultSearchGlossary = value;
  }
  // comparator sort
  sortAlphaNumResultSearchGlossary(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  /******************************** end Business Glossary ****************************/
  /************************* start Data Governance Dimension *********************/
  pageChangedResultSearchDimension(event) {
    this.configResultSearchDimension.currentPage = event;
  }
  getPageResultSearchDimension() {
    this.configResultSearchDimension.itemsPerPage = this.selectedPageSizeResultSearchDimension;
    this.configResultSearchDimension.currentPage = 1;
    this.configResultSearchDimension.totalItems = this.resultSearchDimension.length;
  }
  setOrderResultSearchDimension(value: string) {
    if (this.orderResultSearchDimension === value) {
      this.reverseResultSearchDimension = !this.reverseResultSearchDimension;
    }

    this.orderResultSearchDimension = value;
  }
  // comparator sort
  sortAlphaNumResultSearchDimension(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  /******************************** end Dimension ****************************/
  hideBlockResult() {
    $('#btnSearch').prop('disabled', false);
    $('#listResult').css('display', 'none');
    $('#bodySearchResult').css('display', 'none');
    $('#listMenuEmpty').css('display', 'none');
    this.configResultSearch.currentPage = 1;
    this.configResultSearchCatalog.currentPage = 1;
    this.configResultSearchReport.currentPage = 1;
    this.configResultSearchProjects.currentPage = 1;
    this.configResultSearchMaps.currentPage = 1;
    this.configResultSearchLineage.currentPage = 1;
    this.configResultSearchGlossary.currentPage = 1;
    this.configResultSearchDimension.currentPage = 1;
  }
  GlobalSearch() {
    addLoaderSearch('vide', 'f', true);
    $('#btnSearch').prop('disabled', true);
    $('#bodySearchResult').css('display', 'none');
    $('#listMenuEmpty').css('display', 'none');
    $('#listResult').css('display', 'none');
    this.textSearch = $('#inputTextSearch').val();
    this.resultSearchObject = [];
    this.resultSearchCatalog = [];
    this.resultSearchObjectReport = [];
    this.resultSearchProjects = [];
    this.resultSearchMaps = [];
    this.resultSearchLineage = [];
    this.resultSearchGlossary = [];
    this.resultSearchGlossaryOld = [];
    this.resultSearchDimension = [];
    if (this.selectedCateg == 'objectmodel') {
      this.OnSearchInObjectModel();
    } else if (this.selectedCateg == 'catalog') {
      this.OnSearchInCatalog();
    } else if (this.selectedCateg == 'report') {
      this.OnSearchInDictionary();
    } else if (this.selectedCateg == 'project') {
      this.OnSearchInProjects();
    } else if (this.selectedCateg == 'functionalmap') {
      this.OnSearchInMaps();
    } else if (this.selectedCateg == 'lineage') {
      this.OnSearchInLineage();
    } else if (this.selectedCateg == 'glossary') {
      this.OnSearchInGlossary();
    } else if (this.selectedCateg == 'dimension') {
      this.OnSearchInDimension();
    } else if (this.selectedCateg == 'all') {
      $('#onglet-datadictionary').css('display', 'block');
      $('#onglet-objectmodel').css('display', 'block');
      $('#onglet-project').css('display', 'block');
      this.OnSearchInObjectModel();
    }
  }
  // Start search by category Object Model
  OnSearchInObjectModel() {
    if ((this.textSearch != undefined) && (this.textSearch != null) && (this.textSearch != '')) {
      this.textSearch = btoa(this.textSearch);
      this.resultSearchObject = [];
      let subscription1$ = this.searchService.searchInObjectModel(this.textSearch).subscribe(
        data => {
          data.map(item => {
            // start generate bridge
            // let cryptComponent1 = this.cryptoService.set('project');
            // cryptComponent1 = cryptComponent1.replace(/\//g, '__________');
            let cryptComponent = this.cryptoService.set('global-search');
            cryptComponent = cryptComponent.replace(/\//g, '__________');
            let cryptApplication = this.cryptoService.set(item.system);
            cryptApplication = cryptApplication.replace(/\//g, '__________');
            let cryptApplicationId = this.cryptoService.set(item.systemId);
            cryptApplicationId = cryptApplicationId.replace(/\//g, '__________');
            let cryptModel = this.cryptoService.set(item.name);
            cryptModel = cryptModel.replace(/\//g, '__________');
            // let cryptModelId = this.cryptoService.set(item.modelId);
            // cryptModelId = cryptModelId.replace(/\//g, '__________');
            let cryptOnglet = this.cryptoService.set('tab-model'); // tab-dictionary
            cryptOnglet = cryptOnglet.replace(/\//g, '__________');
            let listCryptedProject = [];
            item.listProject.forEach(proj => {
              listCryptedProject.push({
                idProj: this.cryptoService.set(proj.idProject).replace(/\//g, '__________'),
                nameProj: proj.nameProject
            });
            });

            // Start count of menu GOV/PROJECT
            let countGOVofModel = 0;
            countGOVofModel = listCryptedProject.length;
            // End count of menu GOV/PROJECT

            // Start count of menu ARCHI/SYSTEM
            let countARCHIofModel = 0;
            let appdefrole = localStorage.getItem('appdefRole');
            if ((appdefrole == 'true') && item.appFlowRole) {
              countARCHIofModel = 1;
            } else {
              countARCHIofModel = 0;
            }
            // End count of menu ARCHI/SYSTEM

            // Start count of menu DRM
            let countDRMofModel = 0;
            if (item.roleClassif) {
              countDRMofModel += 1;
            }
            if (item.roleSecurity) {
              countDRMofModel += 1;
            }
            if (item.roleRules) {
              countDRMofModel += 1;
            }
            // End count of menu DRM

            this.resultSearchObject.push({
              system: item.system,
              name: item.name,
              type: item.type,
              objectmodelDashRole: item.objectmodelDashRole,
              countObjectModelInDash: item.countObjectModelInDash,
              adminModelRole: item.adminModelRole,
              countObjectModelInAdmin: item.countObjectModelInAdmin,
              objectmodelRole: item.objectmodelRole,
              countObjectModelInObjectModel: item.countObjectModelInObjectModel,
              roleClassif: item.roleClassif,
              roleSecurity: item.roleSecurity,
              roleRules: item.roleRules,
              countDrm: countDRMofModel,
              projectRole: item.projectRole,
              countProject: countGOVofModel,
              appFlowRole: item.appFlowRole,
              countArchSystem: countARCHIofModel,
              listProject: listCryptedProject,
              allMenu: item.allMenu,
              // cryptComponentSource1: cryptComponent1,
              cryptComponentSource: cryptComponent,
              cryptSystem: cryptApplication,
              cryptSystemId: cryptApplicationId,
              cryptTabModel: cryptOnglet,
              cryptModelName: cryptModel,
              // crypteModelId: cryptModelId
            });


          });
        },
        err => {
          removeLoader('vide', 'f', true);
          $('#btnSearch').prop('disabled', true);
          $('#bodySearchResult').css('display', 'none');
          $('#listMenuEmpty').css('display', 'none');
        },
        () => {
          let resultSearch;
          $('#btnSearch').prop('disabled', true);
          if (this.selectedCateg == 'objectmodel') {
            removeLoader('vide', 'f', true);
            if (this.resultSearchObject.length == 0) {
              resultSearch = 'not done';
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = 'done';
              $('#onglet-objectmodel').children().click();
              $('#onglet-objectmodel').css('display', 'block');
              $('#onglet-catalog').css('display', 'none');
              $('#onglet-datadictionary').css('display', 'none');
              $('#onglet-project').css('display', 'none');
              $('#onglet-maps').css('display', 'none');
              $('#onglet-lineage').css('display', 'none');
              $('#onglet-glossary').css('display', 'none');
              $('#onglet-dimension').css('display', 'none');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 500);
          } else if (this.selectedCateg == 'all') {
            if (this.resultSearchObject.length == 0) {
              $('#onglet-objectmodel').css('display', 'none');
            }
            this.textSearch = atob(this.textSearch);
            this.OnSearchInCatalog();
          }
        }
      );
      this.subscription.add(subscription1$);
    }
  }
  // End search by category Object Model
  // Start search by category data catalog
  OnSearchInCatalog() {
    if ((this.textSearch != undefined) && (this.textSearch != null) && (this.textSearch != '')) {
      this.textSearch = btoa(this.textSearch);
      this.resultSearchCatalog = [];
      let subscription10$ = this.searchService.searchInCatalog(this.textSearch).subscribe(
        data => {
          data.map(item => {
            // start generate bridge
            // let cryptComponent1 = this.cryptoService.set('project');
            // cryptComponent1 = cryptComponent1.replace(/\//g, '__________');
            let cryptComponent = this.cryptoService.set('global-search');
            cryptComponent = cryptComponent.replace(/\//g, '__________');
            let cryptApplication = this.cryptoService.set(item.system);
            cryptApplication = cryptApplication.replace(/\//g, '__________');
            let cryptApplicationId = this.cryptoService.set(item.systemId);
            cryptApplicationId = cryptApplicationId.replace(/\//g, '__________');
            let cryptModel = this.cryptoService.set(item.name);
            cryptModel = cryptModel.replace(/\//g, '__________');
            let cryptOnglet = this.cryptoService.set('tab-model'); // tab-dictionary
            cryptOnglet = cryptOnglet.replace(/\//g, '__________');
            let listCryptedProject = [];
            item.listProject.forEach(proj => {
              listCryptedProject.push({
                idProj: this.cryptoService.set(proj.idProject).replace(/\//g, '__________'),
                nameProj: proj.nameProject
              });
            });

            // Start count of menu GOV/PROJECT
            let countGOVofModel = 0;
            countGOVofModel = listCryptedProject.length;
            // End count of menu GOV/PROJECT

            // Start count of menu ARCHI/SYSTEM
            let countARCHIofModel = 0;
            let appdefrole = localStorage.getItem('appdefRole');
            if ((appdefrole == 'true') && item.appFlowRole) {
              countARCHIofModel = 1;
            } else {
              countARCHIofModel = 0;
            }
            // End count of menu ARCHI/SYSTEM

            // Start count of menu DRM
            let countDRMofModel = 0;
            if (item.roleClassif) {
              countDRMofModel += 1;
            }
            if (item.roleSecurity) {
              countDRMofModel += 1;
            }
            if (item.roleRules) {
              countDRMofModel += 1;
            }
            // End count of menu DRM

            this.resultSearchCatalog.push({
              system: item.system,
              name: item.name,
              type: item.type,
              objectmodelDashRole: item.objectmodelDashRole,
              countObjectModelInDash: item.countObjectModelInDash,
              adminModelRole: item.adminModelRole,
              countObjectModelInAdmin: item.countObjectModelInAdmin,
              // objectmodelRole: item.objectmodelRole,
              // countObjectModelInObjectModel: item.countObjectModelInObjectModel,
              catalogRole: item.catalogRole,
              countObjectModelInCatalog: item.countObjectModelInCatalog,
              roleClassif: item.roleClassif,
              roleSecurity: item.roleSecurity,
              roleRules: item.roleRules,
              countDrm: countDRMofModel,
              projectRole: item.projectRole,
              countProject: countGOVofModel,
              appFlowRole: item.appFlowRole,
              countArchSystem: countARCHIofModel,
              listProject: listCryptedProject,
              allMenu: item.allMenu,
              // cryptComponentSource1: cryptComponent1,
              cryptComponentSource: cryptComponent,
              cryptSystem: cryptApplication,
              cryptSystemId: cryptApplicationId,
              cryptTabModel: cryptOnglet,
              cryptModelName: cryptModel,
            });


          });
        },
        err => {
          removeLoader('vide', 'f', true);
          $('#btnSearch').prop('disabled', true);
          $('#bodySearchResult').css('display', 'none');
          $('#listMenuEmpty').css('display', 'none');
        },
        () => {
          let resultSearch;
          $('#btnSearch').prop('disabled', true);
          if (this.selectedCateg == 'catalog') {
            removeLoader('vide', 'f', true);
            if (this.resultSearchCatalog.length == 0) {
              resultSearch = 'not done';
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = 'done';
              $('#onglet-catalog').children().click();
              $('#onglet-catalog').css('display', 'block');
              $('#onglet-objectmodel').css('display', 'none');
              $('#onglet-datadictionary').css('display', 'none');
              $('#onglet-project').css('display', 'none');
              $('#onglet-maps').css('display', 'none');
              $('#onglet-lineage').css('display', 'none');
              $('#onglet-glossary').css('display', 'none');
              $('#onglet-dimension').css('display', 'none');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 500);
          } else if (this.selectedCateg == 'all') {
            if (this.resultSearchCatalog.length == 0) {
              $('#onglet-catalog').css('display', 'none');
            }
            this.textSearch = atob(this.textSearch);
            this.OnSearchInDictionary();
          }
        }
      );
      this.subscription.add(subscription10$);
    }
  }
  // End search by category data catalog
  // Start search by category Data Dictionary
  OnSearchInDictionary() {
    let resultSearch;
    if ((this.textSearch != undefined) && (this.textSearch != null) && (this.textSearch != '')) {
      this.textSearch = btoa(this.textSearch);
      this.resultSearchObjectReport = [];
      let subscription2$ = this.searchService.searchInDictionary(this.textSearch).subscribe(
        data => {
          data.map(item => {
            // start generate bridge
            let cryptComponent = this.cryptoService.set('global-search');
            cryptComponent = cryptComponent.replace(/\//g, '__________');
            let cryptProject = this.cryptoService.set(item.system);
            cryptProject = cryptProject.replace(/\//g, '__________');
            let cryptApplicationId = this.cryptoService.set(item.systemId);
            cryptApplicationId = cryptApplicationId.replace(/\//g, '__________');
            let cryptReport = this.cryptoService.set(item.name);
            cryptReport = cryptReport.replace(/\//g, '__________');
            let cryptOnglet = this.cryptoService.set('tab-dictionary'); // tab-model
            cryptOnglet = cryptOnglet.replace(/\//g, '__________');
            let listCryptedModelDesignerReport = [];
            item.listModelDesigner.forEach(proj => {
              listCryptedModelDesignerReport.push({
                idModelDesigner: this.cryptoService.set(proj.idModelDesigner).replace(/\//g, '__________'),
                nameModelDesignerCrypt: this.cryptoService.set(proj.nameModelDesigner).replace(/\//g, '__________'),
                nameModelDesigner: proj.nameModelDesigner,
                authorModelDesigner: proj.author
              });
            });
            let listCryptedProjectReport = [];
            item.listProjectReport.forEach(proj => {
              listCryptedProjectReport.push({
                idProj: this.cryptoService.set(proj.idProject).replace(/\//g, '__________'),
                nameProj: proj.nameProject
              });
            });
            this.resultSearchObjectReport.push({
              system: item.system,
              name: item.name,
              dashReportRole: item.dashReportRole,
              adminReportRole: item.adminReportRole,
              countReportInModelAdmin: item.countReportInModelAdmin,
              roleDictionary: item.roleDictionary,
              countReportInDataDictionary: item.countReportInDataDictionary,
              modelDesignerReportRole: item.modelDesignerRole,
              listModelDesigner: listCryptedModelDesignerReport,
              countListModelDesigner: item.countListModelDesigner,
              projectReportRole: item.projectReportRole,
              listProjectRole: listCryptedProjectReport,
              countListProjectReport: item.countListProjectReport,
              allMenu: item.allMenu,
              // cryptComponentSource1: cryptComponent1,
              cryptComponentSource: cryptComponent,
              cryptSystem: cryptProject,
              cryptSystemId: cryptApplicationId,
              cryptTabDictionary: cryptOnglet,
              cryptReportName: cryptReport,
            });
          });
        },
        err => {
          removeLoader('vide', 'f', true);
          $('#btnSearch').prop('disabled', true);
          $('#listResult').css('display', 'none');
          $('#bodySearchResult').css('display', 'none');
          $('#listMenuEmpty').css('display', 'none');
        },
        () => {
          $('#btnSearch').prop('disabled', true);
          if (this.selectedCateg == 'report') {
            removeLoader('vide', 'f', true);
            if (this.resultSearchObjectReport.length == 0) {
              resultSearch = 'not done';
              // $('#onglet-searchResult').css('display', 'block');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = 'done';
              $('#onglet-datadictionary').children().click();
              $('#onglet-datadictionary').css('display', 'block');
              $('#onglet-objectmodel').css('display', 'none');
              $('#onglet-project').css('display', 'none');
              $('#onglet-maps').css('display', 'none');
              $('#onglet-lineage').css('display', 'none');
              $('#onglet-catalog').css('display', 'none');
              $('#onglet-glossary').css('display', 'none');
              $('#onglet-dimension').css('display', 'none');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 1000);
          } else if (this.selectedCateg == 'all') {
            if (this.resultSearchObjectReport.length == 0) {
              $('#onglet-datadictionary').css('display', 'none');
            }
            this.textSearch = atob(this.textSearch);
            this.OnSearchInMaps();
          }
        }
      );
      this.subscription.add(subscription2$);
    }
  }
  // End search by category Data Dictionary
  // Start search by category Projects
  OnSearchInProjects() {
    let resultSearch;
    this.resultSearchProjects = [];
    if ((this.textSearch != undefined) && (this.textSearch != null) && (this.textSearch != '')) {
      this.textSearch = btoa(this.textSearch);
      let subscription8$ = this.searchService.searchInProjects(this.textSearch).subscribe(
        data => {

          data.map(item => {
            // start generate bridge
            let cryptComponent1 = this.cryptoService.set('project');
            cryptComponent1 = cryptComponent1.replace(/\//g, '__________');
            let cryptComponent = this.cryptoService.set('global-search');
            cryptComponent = cryptComponent.replace(/\//g, '__________');
            let cryptType = this.cryptoService.set('proj');
            cryptType = cryptType.replace(/\//g, '__________');
            let cryptProjId = this.cryptoService.set(item.projectId);
            cryptProjId = cryptProjId.replace(/\//g, '__________');
            let cryptProjName = this.cryptoService.set(item.name);
            cryptProjName = cryptProjName.replace(/\//g, '__________');
            let listCryptedDiagramsDesignerProjects = [];
            item.listDiagramDesigner.forEach(diag => {
              listCryptedDiagramsDesignerProjects.push({
                idDiagramsDesigner: this.cryptoService.set(diag.idDiagramDesigner).replace(/\//g, '__________'),
                nameDiagramsDesignerCrypt: this.cryptoService.set(diag.nameDiagramDesigner).replace(/\//g, '__________'),
                nameDiagramsDesigner: diag.nameDiagramDesigner,
                authorDiagramsDesigner: diag.author
              });
            });
            this.resultSearchProjects.push({
              system: item.system,
              name: item.name,
              projectId: cryptProjId,
              cryptName: cryptProjName,
              projectsRole: item.projectRole,
              diagramsDesignerRole: item.diagramDesignerRole,
              listDiagramsDesigner: listCryptedDiagramsDesignerProjects,
              countListDiagramsDesigner: item.countListDiagramDesigner,
              allMenu: item.allMenu,
              cryptComponentSource1: cryptComponent1,
              cryptComponentSource: cryptComponent,
              cryptoType: cryptType,
              cryptTabDiadrams: cryptType
            });
          });
        },
        err => {
          removeLoader('vide', 'f', true);
          $('#btnSearch').prop('disabled', true);
          $('#listResult').css('display', 'none');
          $('#bodySearchResult').css('display', 'none');
          $('#listMenuEmpty').css('display', 'none');
        },
        () => {
          $('#btnSearch').prop('disabled', true);
          if (this.selectedCateg == 'project') {
            if (this.resultSearchProjects.length == 0) {
              resultSearch = 'not done';
              // $('#onglet-searchResult').css('display', 'block');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = 'done';
              $('#onglet-project').children().click();
              $('#onglet-project').css('display', 'block');
              $('#onglet-datadictionary').css('display', 'none');
              $('#onglet-objectmodel').css('display', 'none');
              $('#onglet-maps').css('display', 'none');
              $('#onglet-lineage').css('display', 'none');
              $('#onglet-catalog').css('display', 'none');
              $('#onglet-glossary').css('display', 'none');
              $('#onglet-dimension').css('display', 'none');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 1000);
          } else if (this.selectedCateg == 'all') {
            if (this.resultSearchProjects.length == 0) {
              $('#onglet-project').css('display', 'none');
            }
            if ((this.resultSearchObject.length == 0) && (this.resultSearchObjectReport.length == 0)
              && (this.resultSearchProjects.length == 0) && (this.resultSearchMaps.length == 0)
              && (this.resultSearchLineage.length == 0) && (this.resultSearchCatalog.length == 0)
              && (this.resultSearchGlossary.length == 0) && (this.resultSearchDimension.length == 0)) {
              resultSearch = 'not done';
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = 'done';
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
              if (this.resultSearchObject.length != 0) {
                $('#onglet-objectmodel').children().click();
              } else if ((this.resultSearchObject.length == 0) && (this.resultSearchCatalog.length != 0)) {
                $('#onglet-catalog').children().click();
              } else if ((this.resultSearchObject.length == 0) && (this.resultSearchCatalog.length == 0)
                && (this.resultSearchObjectReport.length != 0)) {
                $('#onglet-datadictionary').children().click();
              } else if ((this.resultSearchObject.length == 0) && (this.resultSearchCatalog.length == 0)
                && (this.resultSearchObjectReport.length == 0) && (this.resultSearchProjects.length != 0)) {
                $('#onglet-project').children().click();
              } else if ((this.resultSearchObject.length == 0) && (this.resultSearchCatalog.length == 0)
                && (this.resultSearchObjectReport.length == 0) && (this.resultSearchProjects.length == 0)
                && (this.resultSearchMaps.length != 0)) {
                $('#onglet-maps').children().click();
              } else if ((this.resultSearchObject.length == 0) && (this.resultSearchCatalog.length == 0)
                && (this.resultSearchObjectReport.length == 0) && (this.resultSearchProjects.length == 0)
                && (this.resultSearchMaps.length == 0) && (this.resultSearchLineage.length != 0)) {
                $('#onglet-lineage').children().click();
              } else if ((this.resultSearchObject.length == 0) && (this.resultSearchCatalog.length == 0)
                && (this.resultSearchObjectReport.length == 0) && (this.resultSearchProjects.length == 0)
                && (this.resultSearchMaps.length == 0) && (this.resultSearchLineage.length == 0)
                && (this.resultSearchGlossary.length != 0)) {
                $('#onglet-glossary').children().click();
              } else if ((this.resultSearchObject.length == 0) && (this.resultSearchCatalog.length == 0)
                && (this.resultSearchObjectReport.length == 0) && (this.resultSearchProjects.length == 0)
                && (this.resultSearchMaps.length == 0) && (this.resultSearchLineage.length == 0)
                && (this.resultSearchGlossary.length == 0) && (this.resultSearchDimension.length != 0)) {
                $('#onglet-dimension').children().click();
              }
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 1000);
          }
          removeLoader('vide', 'f', true);
        }
      );
      this.subscription.add(subscription8$);
    }
  }
  // End search by category Project
  // Start search by category functional maps
  OnSearchInMaps() {
    let resultSearch;
    this.resultSearchMaps = [];
    if ((this.textSearch != undefined) && (this.textSearch != null) && (this.textSearch != '')) {
      this.textSearch = btoa(this.textSearch);
      let subscription9$ = this.searchService.searchInMaps(this.textSearch).subscribe(
        data => {
          var cryptIdCirculosMaps;
          data.map(item => {
            // start generate bridge
            let cryptComponent1 = this.cryptoService.set('maps');
            cryptComponent1 = cryptComponent1.replace(/\//g, '__________');
            let cryptComponent = this.cryptoService.set('global-search');
            cryptComponent = cryptComponent.replace(/\//g, '__________');
            let cryptMapsId = this.cryptoService.set(item.idMaps);
            cryptMapsId = cryptMapsId.replace(/\//g, '__________');
            let cryptMapsName = this.cryptoService.set(item.name);
            cryptMapsName = cryptMapsName.replace(/\//g, '__________');
            // let idCirculosMaps = 'y02M3Dp94wAJwc3nh6QypA==';
            if (item.existInDimension) {
              let idCircleMaps = item.idDimension + 'categMaps1' + item.idMaps;
              cryptIdCirculosMaps = this.cryptoService.set(idCircleMaps);
              cryptIdCirculosMaps = cryptIdCirculosMaps.replace(/\//g, '__________');
            } else {
              cryptIdCirculosMaps = 'y02M3Dp94wAJwc3nh6QypA==';
            }
            this.resultSearchMaps.push({
              name: item.name,
              existInDimension: item.existInDimension,
              idInMaps: cryptIdCirculosMaps,
              status: item.status,
              completion: item.completion,
              cryptId: cryptMapsId,
              cryptName: cryptMapsName,
              mapsRole: item.mapsRole,
              allMenu: item.allMenu,
              cryptComponentSource1: cryptComponent1,
              cryptComponentSource: cryptComponent
            });
          });
        },
        err => {
          removeLoader('vide', 'f', true);
          $('#btnSearch').prop('disabled', true);
          $('#listResult').css('display', 'none');
          $('#bodySearchResult').css('display', 'none');
          $('#listMenuEmpty').css('display', 'none');
        },
        () => {
          $('#btnSearch').prop('disabled', true);
          if (this.selectedCateg == 'functionalmap') {
            removeLoader('vide', 'f', true);
            if (this.resultSearchMaps.length == 0) {
              resultSearch = 'not done';
              // $('#onglet-searchResult').css('display', 'block');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = 'done';
              $('#onglet-maps').children().click();
              $('#onglet-maps').css('display', 'block');
              $('#onglet-glossary').css('display', 'none');
              $('#onglet-catalog').css('display', 'none');
              $('#onglet-lineage').css('display', 'none');
              $('#onglet-project').css('display', 'none');
              $('#onglet-datadictionary').css('display', 'none');
              $('#onglet-objectmodel').css('display', 'none');
              $('#onglet-dimension').css('display', 'none');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 1000);
          } else if (this.selectedCateg == 'all') {
            if (this.resultSearchMaps.length == 0) {
              $('#onglet-maps').css('display', 'none');
            }
            this.textSearch = atob(this.textSearch);
            this.OnSearchInLineage();
          }

        }
      );
      this.subscription.add(subscription9$);
    }
  }
  // End search by category functional maps
// Start search by category data lineage
  OnSearchInLineage() {
    let resultSearch;
    this.resultSearchLineage = [];
    if ((this.textSearch != undefined) && (this.textSearch != null) && (this.textSearch != '')) {
      this.textSearch = btoa(this.textSearch);
      let subscription10$ = this.searchService.searchInLineage(this.textSearch).subscribe(
        data => {
          data.map(item => {
            // start generate bridge
            let cryptComponent1 = this.cryptoService.set('lineage');
            cryptComponent1 = cryptComponent1.replace(/\//g, '__________');
            let cryptComponent = this.cryptoService.set('global-search');
            cryptComponent = cryptComponent.replace(/\//g, '__________');
            let cryptLineageId = this.cryptoService.set(item.lineageId);
            cryptLineageId = cryptLineageId.replace(/\//g, '__________');
            let cryptLineageName = this.cryptoService.set(item.name);
            cryptLineageName = cryptLineageName.replace(/\//g, '__________');
            this.resultSearchLineage.push({
              name: item.name,
              enabled: item.enabled,
              cryptId: cryptLineageId,
              cryptName: cryptLineageName,
              lineageRole: item.lineageRole,
              allMenu: item.allMenu,
              cryptComponentSource1: cryptComponent1,
              cryptComponentSource: cryptComponent
            });
          });
        },
        err => {
          removeLoader('vide', 'f', true);
          $('#btnSearch').prop('disabled', true);
          $('#listResult').css('display', 'none');
          $('#bodySearchResult').css('display', 'none');
          $('#listMenuEmpty').css('display', 'none');
        },
        () => {
          $('#btnSearch').prop('disabled', true);
          if (this.selectedCateg == 'lineage') {
            removeLoader('vide', 'f', true);
            if (this.resultSearchLineage.length == 0) {
              resultSearch = 'not done';
              // $('#onglet-searchResult').css('display', 'block');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = 'done';
              $('#onglet-lineage').children().click();
              $('#onglet-lineage').css('display', 'block');
              $('#onglet-catalog').css('display', 'none');
              $('#onglet-glossary').css('display', 'none');
              $('#onglet-maps').css('display', 'none');
              $('#onglet-project').css('display', 'none');
              $('#onglet-datadictionary').css('display', 'none');
              $('#onglet-objectmodel').css('display', 'none');
              $('#onglet-dimension').css('display', 'none');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 1000);
          } else if (this.selectedCateg == 'all') {
            if (this.resultSearchLineage.length == 0) {
              $('#onglet-lineage').css('display', 'none');
            }
            this.textSearch = atob(this.textSearch);
            this.OnSearchInGlossary();
          }

        }
      );
      this.subscription.add(subscription10$);
    }
  }
  // End search by category data lineage
  // Start search by category Glossaires Métier
  OnSearchInGlossary() {
    let resultSearch;
    this.resultSearchGlossary = [];
    if ((this.textSearch != undefined) && (this.textSearch != null) && (this.textSearch != '')) {
      this.textSearch = btoa(this.textSearch);
      let subscription11$ = this.searchService.searchInGlossary(this.textSearch).subscribe(
        data => {
            this.resultSearchGlossary = data;
          // data.map(item => {
              // console.log(item);
              // start generate bridge
            // crypt glossaryID
            // let cryptGlos = this.cryptoService.set(item.idGlos);
            // if (item.listFunctionalObj.length > 0) {
            //     item.listFunctionalObj.map (itemFunc => {
            //
            //     })
            //     let cryptFunc = this.cryptoService.set(item.idGlos);
            // } else {
            //     let cryptFuncId = 'y02M3Dp94wAJwc3nh6QypA==';
            //     let cryptGroupId = 'y02M3Dp94wAJwc3nh6QypA==';
            //     let cryptObjectId = 'y02M3Dp94wAJwc3nh6QypA==';
            //     let cryptTermId = 'y02M3Dp94wAJwc3nh6QypA==';
            // }
            // cryptComponent1 = cryptComponent1.replace(/\//g, '__________');
            // let cryptComponent = this.cryptoService.set('global-search');
            // cryptComponent = cryptComponent.replace(/\//g, '__________');
            // let cryptGlossaryId = this.cryptoService.set(item.glossaryId);
            // cryptGlossaryId = cryptGlossaryId.replace(/\//g, '__________');
            // let cryptGlossaryName = this.cryptoService.set(item.name);
            // cryptGlossaryName = cryptGlossaryName.replace(/\//g, '__________');
            // this.resultSearchGlossary.push({
            //   name: item.name,
            //   status: item.status,
            //   cryptId: cryptGlossaryId,
            //   cryptName: cryptGlossaryName,
            //   glossaryRole: item.glossaryRole,
            //   allMenu: item.allMenu,
            //   cryptComponentSource1: cryptComponent1,
            //   cryptComponentSource: cryptComponent
            // });

              // new Format
          //     this.resultSearchGlossary.push(item);
          // });
        },
        err => {
          removeLoader('vide', 'f', true);
          $('#btnSearch').prop('disabled', true);
          $('#listResult').css('display', 'none');
          $('#bodySearchResult').css('display', 'none');
          $('#listMenuEmpty').css('display', 'none');
        },
        () => {
          $('#btnSearch').prop('disabled', true);
          if (this.selectedCateg == 'glossary') {
            removeLoader('vide', 'f', true);
            if (this.resultSearchGlossary.length == 0) {
              resultSearch = this.lang ? 'not done' : 'non fait';
              // $('#onglet-searchResult').css('display', 'block');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = this.lang ? 'done' : 'fait';
              $('#onglet-glossary').children().click();
              $('#onglet-glossary').css('display', 'block');
              $('#onglet-lineage').css('display', 'none');
              $('#onglet-catalog').css('display', 'none');
              $('#onglet-maps').css('display', 'none');
              $('#onglet-project').css('display', 'none');
              $('#onglet-datadictionary').css('display', 'none');
              $('#onglet-objectmodel').css('display', 'none');
              $('#onglet-dimension').css('display', 'none');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 1000);
          } else if (this.selectedCateg == 'all') {
            if (this.resultSearchGlossary.length == 0) {
              $('#onglet-glossary').css('display', 'none');
            }
            this.textSearch = atob(this.textSearch);
            this.OnSearchInDimension();
          }

        }
      );
      this.subscription.add(subscription11$);
    }
  }
    showDetailGlossary(glossary) {
        $('#bt_glossary_show_' + glossary.idGlos).hide('slow');
        $('#bt_glossary_hide_' + glossary.idGlos).show('slow');
        $('#bodyGlossaire' + glossary.idGlos).show('slow');
    }
    hideDetailGlossary(glossary) {
        $('#bt_glossary_hide_' + glossary.idGlos).hide('slow');
        $('#bt_glossary_show_' + glossary.idGlos).show('slow');
        $('#bodyGlossaire' + glossary.idGlos).hide('slow');
    }

    // //////////////////////////////////////////////////////////////////// functional object /////////////////////////////////////////////////////////////////////////
    showDetailFunctionalObj(glossary, functionalObj) {
        $('#bt_functional_show_' + glossary.idGlos + '-' + functionalObj.idFunctional).hide('slow');
        $('#bt_functional_hide_' + glossary.idGlos + '-' + functionalObj.idFunctional).show('slow');
        $('#bodyFunctional-' + glossary.idGlos + '-' + functionalObj.idFunctional).show('slow');
    }
    hideDetailFunctionalObj(glossary, functionalObj) {
        $('#bt_functional_hide_' + glossary.idGlos + '-' + functionalObj.idFunctional).hide('slow');
        $('#bt_functional_show_' + glossary.idGlos + '-' + functionalObj.idFunctional).show('slow');
        $('#bodyFunctional-' + glossary.idGlos + '-' + functionalObj.idFunctional).hide('slow');
    }
    showDetailModule(glossary, functionalObj, moduleGroup) {
        $('#bt_module_show_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup).hide('slow');
        $('#bt_module_hide_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup).show('slow');
        $('#bodyGroups-' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup).show('slow');
    }
    hideDetailModule(glossary, functionalObj, moduleGroup) {
        $('#bt_module_hide_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup).hide('slow');
        $('#bt_module_show_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup).show('slow');
        $('#bodyGroups-' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup).hide('slow');
    }
    showDetailObject(glossary, functionalObj, moduleGroup, object) {
        $('#bt_object_show_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object.idObject).hide('slow');
        $('#bt_object_hide_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object.idObject).show('slow');
        $('#bodyObjects-' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object.idObject).show('slow');
        $('#bodyObjectsArchived-' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object.idObject).show('slow');
    }
    hideDetailObject(glossary, functionalObj, moduleGroup, object) {
        $('#bt_object_hide_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object).hide('slow');
        $('#bt_object_show_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object).show('slow');
        $('#bodyObjects-' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object).hide('slow');
        $('#bodyObjectsArchived-' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object).hide('slow');
    }
    showDetailTerm(glossary, functionalObj, moduleGroup, object, term) {
        $('#bt_term_show_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object + '-' + term).hide('slow');
        $('#bt_term_hide_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object + '-' + term).show('slow');
        $('#bodyTermsType-' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object + '-' + term).show('slow');
    }
    hideDetailTerm(glossary, functionalObj, moduleGroup, object, term) {
        $('#bt_term_hide_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object + '-' + term).hide('slow');
        $('#bt_term_show_' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object + '-' + term).show('slow');
        $('#bodyTermsType-' + glossary.idGlos + '-' + functionalObj.idFunctional + '-' + moduleGroup + '-' + object + '-' + term).hide('slow');
    }

    redirectToGlos(idGlos) {
        let cryptGlosId = this.cryptoService.set(idGlos);
        cryptGlosId = cryptGlosId.replace(/\//g, '__________');
        this.router.navigate(['Glossaires/glossaire/' + cryptGlosId + '/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    }
    redirectToFunc(idGlos, idFunc) {
        let cryptGlosId = this.cryptoService.set(idGlos);
        cryptGlosId = cryptGlosId.replace(/\//g, '__________');
        let cryptFuncId = this.cryptoService.set(idFunc);
        cryptFuncId = cryptFuncId.replace(/\//g, '__________');
        this.router.navigate(['Glossaires/glossaire/' + cryptGlosId + '/' + cryptFuncId + '/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    }
    redirectToGroup(idGlos, idFunc, idGroup) {
        let cryptGlosId = this.cryptoService.set(idGlos);
        cryptGlosId = cryptGlosId.replace(/\//g, '__________');
        let cryptFuncId = this.cryptoService.set(idFunc);
        cryptFuncId = cryptFuncId.replace(/\//g, '__________');
        let cryptGroupId = this.cryptoService.set(idGroup);
        cryptGroupId = cryptGroupId.replace(/\//g, '__________');
        this.router.navigate(['Glossaires/glossaire/' + cryptGlosId + '/' + cryptFuncId + '/' + cryptGroupId + '/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==']);
    }
    redirectToObject(idGlos, idFunc, idGroup, idObject) {
        let cryptGlosId = this.cryptoService.set(idGlos);
        cryptGlosId = cryptGlosId.replace(/\//g, '__________');
        let cryptFuncId = this.cryptoService.set(idFunc);
        cryptFuncId = cryptFuncId.replace(/\//g, '__________');
        let cryptGroupId = this.cryptoService.set(idGroup);
        cryptGroupId = cryptGroupId.replace(/\//g, '__________');
        let cryptObjectId = this.cryptoService.set(idObject);
        cryptObjectId = cryptObjectId.replace(/\//g, '__________');
        this.router.navigate(['Glossaires/glossaire/' + cryptGlosId + '/' + cryptFuncId + '/' + cryptGroupId + '/' + cryptObjectId + '/y02M3Dp94wAJwc3nh6QypA==']);
    }
    redirectToTerm(idGlos, idFunc, idGroup, idObject, idTerm) {
        let cryptGlosId = this.cryptoService.set(idGlos);
        cryptGlosId = cryptGlosId.replace(/\//g, '__________');
        let cryptFuncId = this.cryptoService.set(idFunc);
        cryptFuncId = cryptFuncId.replace(/\//g, '__________');
        let cryptGroupId = this.cryptoService.set(idGroup);
        cryptGroupId = cryptGroupId.replace(/\//g, '__________');
        let cryptObjectId = this.cryptoService.set(idObject);
        cryptObjectId = cryptObjectId.replace(/\//g, '__________');
        let cryptTermId = this.cryptoService.set(idTerm);
        cryptTermId = cryptTermId.replace(/\//g, '__________');
        this.router.navigate(['Glossaires/glossaire/' + cryptGlosId + '/' + cryptFuncId + '/' + cryptGroupId + '/' + cryptObjectId + '/' + cryptTermId]);
    }
  // End search by category Glossaire Métier
  // Start search by category business glossary
  OnSearchInDimension() {
    let resultSearch;
    this.resultSearchDimension = [];
    if ((this.textSearch != undefined) && (this.textSearch != null) && (this.textSearch != '')) {
      this.textSearch = btoa(this.textSearch);
      let subscription12$ = this.searchService.searchInDimension(this.textSearch).subscribe(
        data => {
          data.map(item => {
            // start generate bridge
            let cryptComponent1 = this.cryptoService.set('dimension');
            cryptComponent1 = cryptComponent1.replace(/\//g, '__________');
            let cryptComponent = this.cryptoService.set('global-search');
            cryptComponent = cryptComponent.replace(/\//g, '__________');
            let cryptDimensionId = this.cryptoService.set(item.dimensionId);
            cryptDimensionId = cryptDimensionId.replace(/\//g, '__________');
            let cryptDimensionName = this.cryptoService.set(item.name);
            cryptDimensionName = cryptDimensionName.replace(/\//g, '__________');
            this.resultSearchDimension.push({
              name: item.name,
              status: item.status,
              type: item.type,
              cryptId: cryptDimensionId,
              cryptName: cryptDimensionName,
              dimensionRole: item.dimensionRole,
              allMenu: item.allMenu,
              cryptComponentSource1: cryptComponent1,
              cryptComponentSource: cryptComponent
            });
          });
        },
        err => {
          removeLoader('vide', 'f', true);
          $('#btnSearch').prop('disabled', true);
          $('#listResult').css('display', 'none');
          $('#bodySearchResult').css('display', 'none');
          $('#listMenuEmpty').css('display', 'none');
        },
        () => {
          $('#btnSearch').prop('disabled', true);
          if (this.selectedCateg == 'dimension') {
            removeLoader('vide', 'f', true);
            if (this.resultSearchDimension.length == 0) {
              resultSearch = this.lang ? 'not done' : 'non fait';
              // $('#onglet-searchResult').css('display', 'block');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'none');
              $('#listMenuEmpty').css('display', 'block');
            } else {
              resultSearch = this.lang ? 'done' : 'fait';
              $('#onglet-dimension').children().click();
              $('#onglet-dimension').css('display', 'block');
              $('#onglet-glossary').css('display', 'none');
              $('#onglet-lineage').css('display', 'none');
              $('#onglet-catalog').css('display', 'none');
              $('#onglet-maps').css('display', 'none');
              $('#onglet-project').css('display', 'none');
              $('#onglet-datadictionary').css('display', 'none');
              $('#onglet-objectmodel').css('display', 'none');
              $('#listResult').css('display', 'block');
              $('#bodySearchResult').css('display', 'block');
              $('#listMenuEmpty').css('display', 'none');
            }
            // set new search in history search
            let dataSearch = {
              username: getCookieValue('username'),
              category: this.selectedCateg,
              textsearch: this.textSearch,
              result: resultSearch
            };
            this.onSetNewSearchByUser(dataSearch);
            // end set new search in history search
            $('html, body').animate({
              scrollTop: $('#scrollSelect').offset().top + 50
            }, 1000);
          } else if (this.selectedCateg == 'all') {
            if (this.resultSearchDimension.length == 0) {
              $('#onglet-dimension').css('display', 'none');
            }
            this.textSearch = atob(this.textSearch);
            this.OnSearchInProjects();
          }

        }
      );
      this.subscription.add(subscription12$);
    }
  }
  // End search by category dimension
  /****************************************Start Your history search*********************************************/
  onSetNewSearchByUser(dataSearch) {
    let subscription3$ = this.searchService.setNewSearchByUser(dataSearch).subscribe(
      data => {
        if (data == 'success') {
          this.onGetSearchHistoryByUser();
          if (!this.hiderIndicators) {
            this.onGetTextSearchWordCloudChartAll();
          }
        }
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription3$);
  }
  onGetSearchHistoryByUser() {
    let subscription4$ = this.searchService.getSearchHistoryByUser().subscribe(
      data => {
        this.resultSearchHistory = [];
        data.map(item => {
          this.resultSearchHistory.push(item);
        });
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription4$);
  }
  /****************************************End Your history search*********************************************/
  // Start des derniers menus visité par user (top 10)
  onGetLastTenOpeningObject() {
    this.resultLastOpenObj = [];
    let subscription5$ = this.searchService.getLastTenOpeningObject().subscribe(
      result => {
        result.map(item => {
          this.resultLastOpenObj.push(item);
        });
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription5$);
  }
  // End Search by most visited router
  /************************* start last opening object // most visited *********************/
  pageChangedLastOpenObj(event) {
    this.configLastOpenObj.currentPage = event;
  }
  getPageLastOpenObj() {
    this.configLastOpenObj.itemsPerPage = this.selectedPageSizeLastOpenObj;
    this.configLastOpenObj.currentPage = 1;
    this.configLastOpenObj.totalItems = this.resultLastOpenObj.length;
  }
  // sort rules
  setOrderLastOpenObj(value: string) {
    if (this.orderLastOpenObj === value) {
      this.reverseLastOpenObj = !this.reverseLastOpenObj;
    }

    this.orderLastOpenObj = value;
  }
  // comparator sort
  sortAlphaNumLastOpenObj(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  // show / hide in menu search Last opening object
  showOrHideSerachLastOpenObj() {
    if (this.hiderLastOpenObj) {
      $('#show_search_lastOpenObj').show();
      $('#arrow_search_lastOpenObj').html('<i class="fas fa-angle-up"></i>');
      this.hiderLastOpenObj = false;
      $('#show_search_lastOpenObj').animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        // Animation complete.
      });
    } else {
      this.hiderLastOpenObj = true;
      $('#arrow_search_lastOpenObj').html('<i class="fas fa-angle-down"></i>');
      $('#show_search_lastOpenObj').animate({
        height: '0'
      }, 1, 'swing', function() {
        $('#show_search_lastOpenObj').hide();
      });
    }
  }
  // add or delete history
  onAddObjectToFavorite(row) {
    this.resultLastOpenObj.map(item => {
      if (item.id === row.id) {
        item.favorite = true;
      }
    });
    this.searchService.addObjectToFavorite(row).subscribe(
      result => {
        // console.log(result);
      },
      err => {
        console.error(err);
      }
    );
  }
  onDeleteObjectFromFavorite(row) {
    this.resultLastOpenObj.map(item => {
      if (item.id === row.id) {
        item.favorite = false;
      }
    });
    this.searchService.deleteObjectFromFavorite(row).subscribe(
      result => {},
      err => {
        console.error(err);
      }
    );
  }
  /************************* end last opening object // most visited ********************/
  /************************* start your search history*********************/
  pageChangedSearchHistory(event) {
    this.configSearchHistory.currentPage = event;
  }
  getPageSearchHistory() {
    this.configSearchHistory.itemsPerPage = this.selectedPageSizeSearchHistory;
    this.configSearchHistory.currentPage = 1;
    this.configSearchHistory.totalItems = this.resultSearchHistory.length;
  }
  // sort rules
  setOrderSearchHistory(value: string) {
    if (this.orderSearchHistory === value) {
      this.reverseSearchHistory = !this.reverseSearchHistory;
    }

    this.orderSearchHistory = value;
  }
  // comparator sort
  sortAlphaNumSearchHistory(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  // serach from history -> check result search from history (redirection)
  checkResultSearchFromHistory(category, textSearch) {
    if (category == 'Object Model') {
      this.selectedCateg = 'objectmodel';
    } else if (category == 'Data Catalog') {
      this.selectedCateg = 'catalog';
    } else if (category == 'Data Dictionary') {
      this.selectedCateg = 'report';
    } else if (category == 'Governance Projects') {
      this.selectedCateg = 'project';
    } else if (category == 'Functional Maps') {
      this.selectedCateg = 'functionalmap';
    } else if (category == 'Data Lineage') {
      this.selectedCateg = 'lineage';
    } else if (category == 'Dimensions') {
      this.selectedCateg = 'dimension';
    } else if (category == 'All categorys') {
      this.selectedCateg = 'all';
    }
    this.textSearch = textSearch;
    $('#inputTextSearch').val(textSearch);
    this.hideBlockResult();
    this.GlobalSearch();
  }

  // show / hide in menu your search history
  showOrHideSerachHistory() {
    if (this.hiderSearchHistory) {
      $('#show_search_history').show();
      $('#arrow_search_history').html('<i class="fas fa-angle-up"></i>');
      this.hiderSearchHistory = false;
      $('#show_search_history').animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        // Animation complete.
      });
    } else {
      this.hiderSearchHistory = true;
      $('#arrow_search_history').html('<i class="fas fa-angle-down"></i>');
      $('#show_search_history').animate({
        height: '0'
      }, 1, 'swing', function() {
        $('#show_search_history').hide();
      });
    }
  }
  /************************* end your search history ********************/
  /*************************** start search indicators *******************/
  // show / hide chart in menu search Indicators
  showOrHideSerachIndicators() {
    let that = this;
    if (this.hiderIndicators) {
      $('#show_search_indicators').show();
      $('#arrow_search_indicators').html('<i class="fas fa-angle-up"></i>');
      this.hiderIndicators = false;
      $('#show_search_indicators').animate({
        height: '100%'
      }, 100, 'easeOutBounce', function() {
        // Animation complete.
        that.onGetTextSearchWordCloudChartAll();
      });
    } else {
      this.hiderIndicators = true;
      $('#arrow_search_indicators').html('<i class="fas fa-angle-down"></i>');
      $('#show_search_indicators').animate({
        height: '0'
      }, 1, 'swing', function() {
        $('#show_search_indicators').hide();
      });
    }
  }
  // bar chart horizontal top 10 visited of all users
  onGetTopTenVisitenToBarChartAll() {
    let listObjects = [];
    let listCounts = [];
    let subscription6$ = this.searchService.getTopTenVisitenToBarChartAll('search').subscribe(
      result => {
        result.map(item => {
          listObjects.push(item.menu);
          listCounts.push(item.nbrVisit);
        });
      }, err => {
        console.error(err);
      },
      () => {
        if (document.getElementById('chart-Top-horiz') != null) {
          new Chart(document.getElementById('chart-Top-horiz'), {
            type: 'horizontalBar',
            data: {
              labels: listObjects,
              datasets: [
                {
                  label: 'count',
                  backgroundColor: ['#0003ff', '#090cff', '#0C32FF', '#4343ca', '#4a47d4', '#343aff', '#4b4dff', '#535fff', '#9197fe', '#b1b3f7'],
                  data: listCounts
                }
              ]
            },
            options: {
              legend: { display: false },
              title: {
                display: false,
                text: ''
              }
            }
          });
        }
        // word cloud
        this.onGetTextSearchWordCloudChartAll();

      }
    );
    this.subscription.add(subscription6$);
  }

  onGetTextSearchWordCloudChartAll() {
    this.word = [];
    let subscription7$ = this.searchService.getTextSearchWordCloudChartAll('search').subscribe(
      result => {
        result.map(item => {
          this.word.push(item);
        });
      },
      err => {
        console.error(err);
      },
      () => {
        this.setup();
        this.buildSVG();
        this.populate(this.word);
      }
    );
    this.subscription.add(subscription7$);
  }
  private setup() {
    this.margin = {top   : 1, right : 1, bottom: 1, left  : 1};
    let width = document.getElementById('word-cloud-div').offsetWidth;
    this.width = width - this.margin.left - this.margin.right;
    this.height = 240 - this.margin.top - this.margin.bottom;

    this.fillScale = D3.scaleOrdinal(D3.schemeCategory10);
  }

  private buildSVG() {
    $('#word-cloud').empty();
    this.svg = D3.select('#word-cloud')
      .attr("viewBox", `0 0 ${this.width} ${this.height}`)
      .style("display", "block")
      .style("margin", "0")
      .append('g')
      .attr('transform', 'translate(' + (this.width / 2) + ',' + (this.height / 2) + ')');
  }

  private populate(words) {
    const wordScale = D3.scaleLinear()
      .domain([0, D3.max(words, function(d) {
        return d.size;
      })])
      .range([10,72]);
    d3Cloud()
      .size([this.width, this.height])
      .words(words)
      .padding(2)
      .rotate(function() { return ~~(Math.random() * 2) * 0; })
      // .font("Segoe")
      .fontWeight('normal')
      .fontSize(function(d) { return wordScale (d.size); })
      .spiral("archimedean")
      .on('end', () => {
        this.draw(words);
      })
      .start();
  }

  private draw(words) {
    this.svg
      .selectAll("text")
      .data(words)
      .enter().append("text")
      .style("font-size", function(d) { return d.size + "px"; })
      .style('fill', (d, i) => {
        return this.fillScale(i);
      })
      .style("font-family", "Calibri")
      .attr("text-anchor", "middle")
      .attr("transform", function(d) {
        return "translate(" + [d.x, d.y] + ")rotate(" + d.rotate + ")";
      })
      .text(function(d) { return d.text; });
  }
  /*************************** end search indicators **********************/
  /******************** management tips **********************************/
  // get all tips format JSON
  // onGetAllTips() {
  //   this.serviceTips.getAllTips().subscribe(
  //     result => {
  //       console.log(result);
  //     },
  //     err => {
  //       console.error(err);
  //     }
  //   );
  // }
  // get tips per 'name' format JSON
  onGetTipsPerName(name) {
    this.serviceTips.getTipsPerName(name).subscribe(
      result => {
        // console.log(result);
      },
      err => {
        console.error(err);
      }
    );
  }
  /******************** end management tips **********************************/
}
