export class NodeItem {
   id: string;
  icon: string;
   text: string;
   application: {id: string, name: string, color: string};
   extern: boolean;
  catalogue: boolean;
  data: [{model: { id: string, name: string}, object: {id: string, name: string}, children: [{table: {id: string, name: string}, fields: [{id: string, name: string, description: string, goldSource: false}]}]}];
  description: string;
  customApplication: {id: string, name: string, color: string, type: string};
  customColor: string;
  customs: [{model: {id: string , name: string}, object: {id: string, name: string}, children: [{table: {id: string, name: string}, fields: [{id: string, name: string, goldSource: false}]}]}];
  applicationType: {id: string, name: string};
  trigger: string;
  type: string;
  style: {top: number, left: number};
  // tslint:disable-next-line:max-line-length
  constructor(id: string, icon: string, text: string, application: { id: string; name: string; color: string }, extern: boolean, catalogue: boolean, data: [{ model: { id: string; name: string }; object: { id: string; name: string }; children: [{ table: { id: string; name: string }; fields: [{ id: string; name: string; description: string, goldSource: false }] }] }], description: string, customApplication: { id: string; name: string; color: string; type: string }, customColor: string, customs: [{ model: { id: string; name: string }; object: { id: string; name: string }; children: [{ table: { id: string; name: string }; fields: [{ id: string; name: string, goldSource: false }] }] }], applicationType: { id: string; name: string }, trigger: string, type: string, style: {top: number, left: number}) {
    this.id = id;
    this.icon = icon;
    this.text = text;
    this.application = application;
    this.extern = extern;
    this.catalogue = catalogue;
    this.data = data;
    this.description = description;
    this.customApplication = customApplication;
    this.customColor = customColor;
    this.customs = customs;
    this.applicationType = applicationType;
    this.trigger = trigger;
    this.type = type;
    this.style = style;
  }
}
