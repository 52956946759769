import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DataLineageTermComponent} from './data-lineage-term/data-lineage-term.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxResizableModule} from '@3dgenomes/ngx-resizable';
import {ColorSketchModule} from 'ngx-color/sketch';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgOptionHighlightModule} from '@ng-select/ng-option-highlight';
import {TreeModule} from '@circlon/angular-tree-component';
import {OrderModule} from 'ngx-order-pipe';
import {Ng2SearchPipeModule} from 'ng2-search-filter';
import {NgxPaginationModule} from 'ngx-pagination';
import {SharedModule} from '../shared/shared.module';
import { FunctionalMapComponent } from './functional-map/functional-map.component';
import { FunctionalMapListComponent } from './functional-map-list/functional-map-list.component';
import {TranslateModule} from '@ngx-translate/core';
import {SidebarModule} from 'ng-sidebar';
import { FunctionalMapGroupComponent } from './functional-map-group/functional-map-group.component';
import {ArchwizardModule} from 'angular-archwizard';



@NgModule({
  declarations: [ DataLineageTermComponent, FunctionalMapComponent, FunctionalMapListComponent, FunctionalMapGroupComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxResizableModule,
        ColorSketchModule,
        NgSelectModule,
        NgOptionHighlightModule,
        FormsModule,
        TreeModule,
        OrderModule,
        Ng2SearchPipeModule,
        NgxPaginationModule,
        SharedModule,
        TranslateModule,
        SidebarModule,
        ArchwizardModule
    ]
})
export class BusinessGlossaryModule { }
