import {RouterModule, Routes} from "@angular/router";

import {NgModule} from "@angular/core";
import {UserMessageComponent} from "./user-message/user-message.component";


const routes: Routes = [
  {
    path : '',
    redirectTo : 'user-message',
    pathMatch : 'full'
  },
  {
    path: 'user-message/inbox/:id/:component',
    component: UserMessageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserMessageRoutingModule {

}
