import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataCatalogueComponent} from './mainData/data-catalogue.component';
import { DataCatalogueRoutingModule } from './data-catalogue-routing.module';
import {TooltipModule} from 'ng2-tooltip-directive';
import {DiagrammeComponent} from './graphs/diagramme/diagramme.component';
import {LoaderscatalogComponent} from '../loaders/loaderscatalog/loaderscatalog.component';
import {SidebarModule} from 'ng-sidebar';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ChartGlobComponent } from './globChart/chart-glob/chart-glob.component';
import { VolumeComponent } from './volume/volume.component';
import { OrderModule } from 'ngx-order-pipe';
import {NgxDropzoneModule} from 'ngx-dropzone';
import { SharedModule } from '../shared/shared.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
  declarations: [
      DataCatalogueComponent,
      LoaderscatalogComponent,
      DiagrammeComponent,
      ChartGlobComponent,
      VolumeComponent,
  ],
  imports: [
    CommonModule,
    DataCatalogueRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule,
    NgSelectModule,
    NgOptionHighlightModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    OrderModule,
    SidebarModule.forRoot(),
    NgxDropzoneModule,
    SharedModule,
    TranslateModule
  ]
})
export class DataCatalogueModule { }
