import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {UploadFilesService} from 'src/app/services/uploadfiles/upload-files.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {OrderPipe} from 'ngx-order-pipe';
import {getCookieValue, Shared, skipString, unSkipString} from '../../shared';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const Dropzone: any;
declare const M: any;
declare const swal: any;

@Component({
    selector: 'app-upload-files',
    templateUrl: './upload-files.component.html',
    styleUrls: ['./upload-files.component.css']
})
export class UploadFilesComponent implements OnInit {
    @Input() objId: string;
    @Input() objName: string;
    @Input() type: string;
    @Input() isUpdated: boolean;

    selectedFiles = [];
    progressInfos = [];
    message = '';
    files = [];
    lang: string;
    flagUF = false;
    // pagination
    modelconfig: any;
    sortedCollection: any[];
    selectedPageSize = 5;
    order = 'name';
    reverse = false;
    pageSizeList = [5, 10, 15, 20, 25, 30];
    modelFiles: any;
    flags = false;
    // file upload dec
    presubmitFile = false;
    presubmitFileU = false;
    messagedangerFile = '';
    messagesuccesFile = '';
    messagedangerFileU: string;
    messagesuccesFileU: string;
    registerFormFile: FormGroup;
    editFormFile: FormGroup;
    filescsvApp = [];

    constructor(private fb: FormBuilder, private fileService: UploadFilesService, private orderPipe: OrderPipe,private translate: TranslateService) {
        this.modelconfig = {id: 'serverFile', itemsPerPage: this.selectedPageSize, currentPage: 1, totalItems: 0};

    }

    ngOnInit(): void {
        // Initialize form update file
        this.registerFormFile = this.fb.group({
            description: [''],
            file: ['', Validators.compose([Validators.required])],
            idApp: ['']
        });
        this.editFormFile = this.fb.group({
            name: [''],
            description: [''],
            idApp: [''],
            id: ['']
        });
        this.detectChangeLaguage();
    }
    detectChangeLaguage() {
      const that = this;
      setInterval(() => {
        this.lang = localStorage.getItem('lang') || 'en';
        this.translate.use(this.lang);
      }, 10);
    }

    onClickUF() {
        if (this.flagUF) {
            this.flagUF = false;
            $('#UF_' + this.objId).html('<i class="fas fa-angle-down"></i>');
        } else {
            this.flagUF = true;
            $('#UF_' + this.objId).html('<i class="fas fa-angle-up"></i>');
        }
    }

    onGetFile() {
        this.files = [];
        this.fileService.getFilesApp(this.objId, this.objName).subscribe(
            data => {
                this.files = data;
            },
            err => {
                console.error(err);
            }, () => {
                this.sortedCollection = this.orderPipe.transform(this.files, 'nameMod');
                this.modelconfig.id = 'serverFile';
            });
    }

    onCloseFile() {

        this.editFormFile.get('description').patchValue('');
        this.editFormFile.get('name').patchValue('');
        this.editFormFile.get('id').patchValue('');
        this.editFormFile.get('idApp').patchValue('');

    }

    onCloseFileDesc() {

        this.editFormFile.get('description').patchValue('');


    }

    niceBytes(a) {
        let b = 0, c = parseInt(a, 10) || 0;
        for (; 1024 <= c && ++b;) {
            c /= 1024;
        }
        return c.toFixed(10 > c && 0 < b ? 1 : 0) + ' ' + ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][b];
    }

    onSubmitFile() {
        this.fileService.updateFile(JSON.stringify(this.editFormFile.value), this.objName).subscribe(
            data => {
            },
            err => {
                console.error(err);
            },
            () => {

                this.fileService.getFilesApp(this.editFormFile.get('idApp').value, this.objName).subscribe(
                    data => {
                        this.files = data;
                    },
                    err => {
                        console.error(err);
                    },
                    () => {
                    });
            });
    }

    onRemoveF(event) {
        this.filescsvApp.splice(this.filescsvApp.indexOf(event), 1);
        this.selectedFiles.splice(this.selectedFiles.indexOf(event), 1);
    }

    selectFiles(event) {
        this.progressInfos = [];
        this.selectedFiles.push(...event.addedFiles);

        this.registerFormFile.get('file').patchValue('Joined');
        for (let i = 0; i < event.addedFiles.length; i++) {
            this.filescsvApp.push({'file': this.selectedFiles[i], 'etat': true});
        }
    }

    onUploadFileApp() {
        if (this.registerFormFile.status == 'VALID') {
            let description = '';
            if (this.registerFormFile.get('description').value) {
                description = skipString(this.registerFormFile.get('description').value);
            } else {
                description = '...';
            }
            // let listUploadedFiles = [];
            // this.selectedFiles.map(f => {
            //     console.log(f);
            // });
            // return
            // let dataUpload = {
            //     files: this.selectedFiles,
            //     objectId: this.objId,
            //     objectName: this.objName,
            //     description: description,
            //     username: getCookieValue('username')
            // };
            this.fileService.uploadFiles(this.selectedFiles, this.objId, description, this.objName).subscribe(
                data => {
                    this.files = data;
                },
                err => {
                    this.messagedangerFile = this.lang === 'en' ? 'Could not upload the file:' : 'Impossible de télécharger le fichier:';


                },
                () => {
                    this.registerFormFile.get('file').patchValue('');
                    this.registerFormFile.get('description').patchValue('');
                    for (let j = 0; j < this.selectedFiles.length; j++) {
                        this.onRemoveF(this.filescsvApp[j]);
                    }
                }
            );
        }
    }

    onDownloadFie(fileCrypt, fileName, id, type) {
        var downloadUrl = Shared.API_URL + '/api/uploadfile/downloadFile/' + fileCrypt + '/' + fileName + '/' + this.objName;
        $.ajax({
            url: downloadUrl,
            method: 'GET',
            dataType: 'binary',
            processData: false,
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Accept': 'Application/json',
                'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            },
            xhrFields: {
                responseType: 'arraybuffer'
            },
            success: function(data) {
                var file = new Blob([data], {type: type});
                var a = document.createElement('a');
                var url = URL.createObjectURL(file);
                a.href = url;
                a.download = fileCrypt;
                document.body.append(a);
                a.click();
                a.remove();
            }
        });
    }

    async editerFileApp(id) {
        this.files.forEach(element => {
            if (element.id == id) {
                this.editFormFile.get('name').patchValue(element.name.split('.').slice(0, -1).join('.'));
                this.editFormFile.get('description').patchValue(unSkipString(element.description));
                this.editFormFile.get('idApp').patchValue(this.objId);
                this.editFormFile.get('id').patchValue(element.id);
            }
        });
        const {value: formValues} = await Swal.fire({
            title: this.lang === 'en' ? 'Edit Properties File' : 'Modifier le fichier de propriétés',
            showCancelButton: true,
            html:
                '<label>Name</label>' +
                '<input type="text" id="name-input"  value="' + `${this.editFormFile.get('name').value} ` + '" class="swal2-input"/>' +
                '<label>Description</label>' +
                '<input  id="desc-input" type="textarea"  value="' + `${this.editFormFile.get('description').value} ` + '" class="swal2-textarea" />',
            focusConfirm: false,
            preConfirm: () => {
                return [
                    $('#name-input').val(),
                    $('#desc-input').val()
                ];
            },
            inputValidator: () => {
                // @ts-ignore
                let name = $('#name-input').val(),
                if(name !== '')
                {
                    return this.lang === 'en' ? 'You need to write name!' : 'Vous devez écrire le nom!';

                }
            }
        });
        if (formValues && formValues[0] != '') {
            this.editFormFile.get('name').patchValue(formValues[0]);
            this.editFormFile.get('description').patchValue(skipString(formValues[1]));
            this.onSubmitFile();
        }
    }

    openDesc(desc) {
        this.editFormFile.get('description').patchValue(desc);
        Swal.fire({
            title: 'Description',
            html: '<input   type="textarea"  disabled =true value="' + `${this.editFormFile.get('description').value} ` + '" class="swal2-textarea" />',
            focusConfirm: false,
        });
    }

    deleteFileApp1(id, j, code) {


        var OModelservice = this.fileService;
        var upddelete = this;
        swal({
            title: this.lang === 'en' ? 'Are you sure ?' : 'Êtes-vous sûr ?',
            text: this.lang === 'en' ? `You will not be able to recover this file name : ${code} !` : `Vous ne pourrez pas récupérer ce nom de fichier : ${code} !`,
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#DD6B55',
            confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, l\'approuver !',
            cancelButtonText: this.lang === 'en' ? 'No, cancel plx!' : 'Non, annuler s\'il vous plaît !',
            closeOnConfirm: false,
            closeOnCancel: false
           }, function(isConfirm) {
            if (isConfirm) {
                // this.postData2 = "status=" + status;
                OModelservice.deleteFileApp(id, upddelete.objId, upddelete.objName).subscribe(
                    data => {
                        var tabdata = data;
                        upddelete.confirmDdeleteFile(tabdata);
                        // window.location.reload();

                    });

                    swal(
                        this.lang === 'en' ? 'Approved!' : 'Approuvé!',
                        this.lang === 'en' ? 'Your file has been approved.' : 'Votre fichier a été approuvé.',
                        'success'
                       );
                       

            } else {
                swal(
                    this.lang === 'en' ? 'Cancelled' : 'Annulé',
                    this.lang === 'en' ? 'Your file remains unchanged!' : 'Votre fichier reste inchangé!',
                    'error'
                   );
                   

            }

        });


    }

    confirmDdeleteFile(tabdata) {

        this.files = tabdata;


    }

    deleteFileApp(id, j, code) {

        var comp = this;
        var fileservice = this.fileService;
        Swal.fire({
            title: this.lang === 'en' ? 'Are you sure?' : 'Êtes-vous sûr ?',
            text: this.lang === 'en' ? 'You will not be able to recover this file!' : 'Vous ne pourrez pas récupérer ce fichier !',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: this.lang === 'en' ? 'Yes, delete it!' : 'Oui, supprimez-le !',
            cancelButtonText: this.lang === 'en' ? 'No, cancel!' : 'Non, annuler!',
           }).then((result) => {
            if (result.value) {
                fileservice.deleteFileApp(id, comp.objId, comp.objName).subscribe(
                    data => {
                        this.files = data;

                    },
                    err => {
                        console.error(err);
                    }, () => {
                        const Toast = Swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            onOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer);
                                toast.addEventListener('mouseleave', Swal.resumeTimer);
                            }
                        });

                        Toast.fire({
                            icon: 'success',
                            title: this.lang === 'en' ? 'Deleted successfully' : 'Supprimé avec succès'
                           });
                           


                    }
                );
            }

        });


    }

//paginator
// 
    resetpage() {
        this.selectedPageSize = 5;

        this.modelconfig.itemsPerPage = this.selectedPageSize;

        this.modelconfig.currentPage = 1;


        this.modelconfig.totalItems = this.files.length;
    }


    getPage() {
        this.modelconfig.itemsPerPage = this.selectedPageSize;
        this.modelconfig.currentPage = 1;
        this.modelconfig.totalItems = this.files.length;
    }


    // pagination
    sortAlphaNumeric(a, b) {
        if (a === b) {
            return 0;
        }
        if (typeof a === typeof b) {
            return a < b ? -1 : 1;
        }
        return typeof a < typeof b ? -1 : 1;
    }

    pageModelChanged(event) {
        this.modelconfig.currentPage = event;
    }


    setOrder(value: string) {
        if (this.order === value) {
            this.reverse = !this.reverse;
        }
        this.order = value;
    }


}
