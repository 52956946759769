import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {addLoader, getCookieValue, removeLoader, Shared} from '../../shared';
import d3Tip from 'd3-tip';
import * as d3 from 'd3';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrderPipe} from 'ngx-order-pipe';
import {WizardComponent} from 'angular-archwizard';
import {formatDate} from '@angular/common';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import Swal from 'sweetalert2';
import {DefinitionService} from '../../services/drm/definition.service';
import * as Excel from 'exceljs';
import * as FileSaver from 'file-saver';
import {TranslateService} from '@ngx-translate/core';
import {RouterhistoryService} from '../../services/appService/routerhistory.service';
import {ActivatedRoute, Router} from "@angular/router";
import {CryptoService} from "../../crypto.service";
declare const $: any;
declare const CKEDITOR: any;

@Component({
  selector: 'app-definitions',
  templateUrl: './definitions.component.html',
  styleUrls: ['./definitions.component.css']
})
export class DefinitionsComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription = new Subscription();
  @ViewChild(WizardComponent)
  public wizard: WizardComponent;
  Assets = Shared.ASSETS;
  lang: string;
  flagDefinition = false;
  listDefinitions: any;
  create = false;
  update = false;
  delete = false;
  groups1 = [];
  groups2 = [];
  groups3 = [];
  groups4 = [];
  // paginate definition
  asyncModelsDefinition: Observable<any[]>;
  pa = 1;
  totalDefinition = 0;
  loadingDefinitions: boolean;
  modelconfigDefinition: any;
  orderDefinition = 'id';
  reverseDefinition = false;
  sortedCollectionDefinition: any[];
  selectedPageSizeDefinition = 4;
  pageSizeListDefinition = [5, 10, 15, 20, 25, 30];
  modelFilterDefinition: any;
  // end paginate definition
  // menu indicators
  // hiderIndicatorsDefinition = true;
  // add DEFINITION by formGroup
  public frmAddDefinition: FormGroup;
  addForm: FormGroup;
  todayDate = formatDate(new Date(), 'yyyy/MM/dd', 'en');
  startDate = true;
  idDef: string;
  nameDef: string;
  statusDef: string;
  typeDef = 'Usage Risk';
  editStatus = true;
  statusDefin = [{id: 'Enabled', name: 'Enabled'}, {id: 'Disabled', name: 'Disabled'}];
  typeDefin = [{id: 'Usage Risk', name: 'Usage Risk'}, {id: 'Mapping', name: 'Mapping'}];
  effectDate: string;
  endDateDef: string;
  descriptionNewDef: any;
  validateName = true;
  listNameDefinitions = [];
  // edit delete formGroup definition
  disableEditDef = true;
  actionAddDef = false;
  actionEditDef = false;
  // add FAMILY1 by formGroup
  checkedNo = true;
  validationCategory1 = true;
  validationStep1 = false;
  public frmAddFamily1: FormGroup;
  addForm1: FormGroup;
  familyName1: string;
  descriptionNewFam1: string;
  // add FAMILY2 by formGroup
  checkedMod = true;
  validationCategory2 = true;
  validationStep2 = false;
  public frmAddFamily2: FormGroup;
  addForm2: FormGroup;
  familyName2: string;
  descriptionNewFam2: any;
  // add FAMILY3 by formGroup
  checkedHigh = true;
  validationCategory3 = true;
  validationStep3 = false;
  public frmAddFamily3: FormGroup;
  addForm3: FormGroup;
  familyName3: string;
  descriptionNewFam3: any;
  // add FAMILY4 by formGroup
  checkedCrit = true;
  validationCategory4 = true;
  validationStep4 = false;
  public frmAddFamily4: FormGroup;
  addForm4: FormGroup;
  familyName4: string;
  descriptionNewFam4: any;
  // datatable filter
  _opened = false;
  showListDims = true;
  dataFilter: any;
  dataDim: any;
  dataCateg: any;
  dataElem: any;
  listDimensionRemove = [];
  // export definitions
  tsvType = 'text/plain;charset=utf-8;';
  //// start check complmiance not exist in definition ////
  dataComplianceAfterChecked = [];
  listAdComplianceCheck = [];
  listAdCompliancepreCheck = [];
  chechAllCompliance = false;
  //// end check compliance not exist in definition ////
  //// start check complmiance exist in definition ////
  checkSyncDataCompDef = [];
  listSyncDataCompDef = [];
  listCheckedComplInModal = [];
  //// end check compliance exist in definition ////
  flagShema = true;
  flagDim = false;
  definitionBridge = {definition: '', componentId: '', component: ''};
  showBack = false;
  id = '';
  constructor(private fb: FormBuilder, private dynamicScriptLoader: DynamicScriptLoaderService, private routerHistoryService: RouterhistoryService,
              private definitionsevice: DefinitionService, private orderPipe: OrderPipe, private translate: TranslateService, private router: Router, private activeRoute: ActivatedRoute, private cryptoService: CryptoService) {
    this.modelconfigDefinition = {id: 'server', itemsPerPage: this.selectedPageSizeDefinition, currentPage: this.pa, totalItems: this.totalDefinition};
  }

  ngOnInit(): void {
    $('#spinner_getListDef').attr('style', 'display:block!important');
    $('#listDataDefinition').attr('style', 'display:none!important');
    $('#toggleShowList').show();
    // get params from url
    this.activeRoute.paramMap.subscribe(params => {
      let definition = params.get('definition');
      let projectId = params.get('componentId');
      let component = params.get('component');
      definition = definition.replace(/__________/g, '/');
      projectId = projectId.replace(/__________/g, '/');
      component = component.replace(/__________/g, '/');
      definition = this.cryptoService.get(definition);
      projectId = this.cryptoService.get(projectId);
      component = this.cryptoService.get(component);
      if (definition != null && definition != '0') {
        this.definitionBridge.definition = definition;
      }
      if (projectId != null && projectId != '0') {
        this.definitionBridge.componentId = projectId;
      }
      if (component != null && component != '0') {
        this.definitionBridge.component = component;
      }
    });
    this.createLegendClassification(1, 'legendDefinitionRowWrapper', [10, 20, 30], ['Definition', 'Category', 'Dimension']);
    this.onCheckNotExistComplianceInDefinition();
    this.onGetCheckExistComplianceInDefinition();
    this.onGetListDefinitions();
    this.startScriptNewDef();
    this.onGetListClassificationDimensions();
    // this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
    setTimeout( () => {
      this.showTipsDrmDef();
    }, 1000);
  }
  showTipsDrmDef() {
    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsDrmDef');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      // console.log('first');
      // (localStorage.getItem('dataSoumenu') == 'drm-definition') && (localStorage.getItem('dataTips') == 'true') && (
      if ((localStorage.getItem('dontShowTipsDrmDef') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsDrmDef', 'true');
        setTimeout(() => {
          $('#tipsCompDrmDef').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        // console.log('second');
        if ((localStorage.getItem('dontShowTipsDrmDef') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsDrmDef', 'true');
          $('#tipsCompDrmDef').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips dashboard
  onUpdateTipsDrmDef() {
    let tipsDrmDef;
    if ($('#checked_show_drmdef').is(':checked')) {
      tipsDrmDef = false;
    } else {
      tipsDrmDef = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsDrmDef,
      dataMenu: 'Data Regulation',
      dataSousmenu: 'drm-definition'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        // console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }

  createLegendClassification = (scaleFactor, id, legendSizes, legendTexts) => {
    const legendClass = ['legendCircle1', 'legendCircle2', 'legendCircle3'];
    const legendColors = ['#000000', '#078860', '#15cc94'];
    const commaFormat = d3.format(',');
    const offset = legendSizes.length - 1;
    let width = 400,
      height = legendSizes[offset] * 2 * 1.2;

    let legendCenter = -10,
      legendBottom = height,
      legendLineLength = legendSizes[offset] * 1.3,
      textPadding = 5;

    //Create SVG for the legend
    const legends = d3.select('#' + id).append('svg')
      .attr('width', width)
      .attr('height', height * 1.5)
      .append('g')
      .attr('class', 'legendWrapper')
      .attr('transform', 'translate(' + width / 2 + ',' + 0 + ')');

    //Draw the circles
    legends.selectAll('.legendCircle')
      .data(legendSizes)
      .enter().append('circle')
      .attr('r', function (d) {
        return d;
      })
      .attr('class', function (d, i) {
        return legendClass[i];
      })
      .attr('cx', legendCenter)
      .attr('cy', function (d) {
        return legendBottom - d;
      });

    //Draw the line connecting the top of the circle to the number
    legends.selectAll('.legendLine')
      .data(legendSizes)
      .enter().append('line')
      .attr('class', 'legendLine')
      .attr('x1', legendCenter)
      .attr('y1', function (d) {
        return legendBottom - 2 * d;
      })
      .attr('x2', legendCenter + legendLineLength)
      .attr('y2', function (d) {
        return legendBottom - 2 * d;
      });
    //Place the value next to the line
    legends.selectAll('.legendText')
      .data(legendSizes)
      .enter().append('text')
      .attr('class', 'legendText')
      .attr('x', legendCenter + legendLineLength + textPadding)
      .attr('y', function (d) {
        return legendBottom - 2 * d;
      })
      .attr('dy', '0.3em')
      .attr('fill', function (d, i) {
        return legendColors[i];
      })
      .text(function (d, i) {
        return legendTexts[i];
      });
  };

  onGetListDefinitions() {
    this.listDefinitions = [];
    let subscription1$ = this.definitionsevice.getListDefinitions().subscribe(
      result => {
        result['data'].map((item) => {
          this.listDefinitions.push(item);
          this.listNameDefinitions.push(item.name);
        });
        this.create = result['rights'].create;
        this.update = result['rights'].update;
        this.delete = result['rights'].delete;
      },
      err => {
        console.error(err);
        $('#spinner_getListDef').hide();
        $('#listDataDefinition').show('slow');
      }, () => {
        this.sortedCollectionDefinition = this.orderPipe.transform(this.asyncModelsDefinition, 'id');
        $('#spinner_getListDef').hide();
        $('#listDataDefinition').show('slow');
            removeLoader();
        if (this.definitionBridge.component === 'project' && !this.showBack) {
          this.showBack = true;
          console.log(this.showBack)
          if(this.definitionBridge.definition!= ''){
            this.modelFilterDefinition = this.definitionBridge.definition;
            setTimeout(() => {
              $( '#bt_desfinition_' +  this.definitionBridge.definition).click();
            }, 1000);
          }
        }
      }
    );
    this.subscription.add(subscription1$);
  }
  /// show shema
  onClickShema(i: number, id: any) {
    if (this.flagShema) {
      this.flagShema = false;
      $('#schema_definition_' + i).hide('slow');
      $('#schema_definition_' + id).html('<i class="fas fa-angle-down"></i>');
    } else {
      this.flagShema = true;
      $('#schema_definition_' + i).show('slow');
      $('#schema_definition_' + id).html('<i class="fas fa-angle-up"></i>');
    }
  }
  /// show dimension
  onClickDim(i: number, id: any) {
    if (this.flagDim) {
      this.flagDim = false;
      $('#dim_definition_' + i).hide('slow');
      $('#dim_definition_' + id).html('<i class="fas fa-angle-down"></i>');
    } else {
      this.flagDim = true;
      $('#dim_definition_' + i).show('slow');
      $('#dim_definition_' + id).html('<i class="fas fa-angle-up"></i>');
    }
  }

  getDefinitionDimensionByName(name, id){
    const columnDefs = [{
      data: 'id',
      title: 'Id Dimension',
      visible: false,
      searchable: false
    },
 
      { data: 'name', title: this.lang ? 'Dimension' : 'Dimension' },
      { data: 'category', title: this.lang ? 'Affected Categories' : 'Catégories affectées' },
      { data: 'effectiveDate', title: this.lang ? 'Effective Date' : 'Date d\'effet' },
      { data: 'endDate', title: this.lang ? 'End Date' : 'Date de fin' },
      { data: 'status', title: this.lang ? 'Status' : 'Statut' },

  
    ];
    this.definitionsevice.getDefinitionDimensionsByName(name).subscribe(
      d => {
        $('#table_dimension_definition_' + id).empty().DataTable({
          sPaginationType: 'full_numbers',
          data: d,
          destroy: true,
          columns: columnDefs,
          rowCallback(row, data) {
            let htm = '<ul>';
            if(data.category.length > 0){
              for(let i =0; i < data.category.length; i++){
                htm+= '<li>' + data.category[i] + '</li>';
              }
              htm += '</ul>';
            }

            $('td:eq(1)', row).html(htm);
          },
          initComplete(settings, json) {
            $('#table_dimension_definition_' + id + '_wrapper').css('width', '98%');
          }
        });
      },
      err => {console.error(err);}
    );

  }

  async startScriptNewDef() {
    this.frmAddDefinition = this.initFormNewDef();
    this.frmAddFamily1 = this.initFormNew1();
    this.frmAddFamily1.get('familyName1').patchValue('No critical');
    this.frmAddFamily2 = this.initFormNew2();
    this.frmAddFamily2.get('familyName2').patchValue('Moderate');
    this.frmAddFamily3 = this.initFormNew3();
    this.frmAddFamily3.get('familyName3').patchValue('High');
    this.frmAddFamily4 = this.initFormNew4();
    this.frmAddFamily4.get('familyName4').patchValue('Critical');
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      CKEDITOR.config.toolbar_MA = [['Cut', 'Copy', 'Paste', '-', 'Undo', 'Redo', '-', 'Format', 'Templates', 'Bold', 'Italic', 'Underline', '-', '-', ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock']]]; // , '-', 'NumberedList'
      this.loadDataNewDef();
    }).catch(error => console.error(error));

    // date picker
    await this.dynamicScriptLoader.load('form.min').then(data => {
      const that = this;
      let start;
      let end;
      // new Date();
      let now = formatDate(new Date(), 'yyyy/MM/dd', 'en_US');
      $('#effectiveDate').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'yyyy/MM/dd',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function (e, date) {
          that.startDate = false;
          start = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          that.addForm.get('effectiveDate').patchValue(start);
          $('#endDate').bootstrapMaterialDatePicker('setMinDate', date.add(1, 'day'));

          if (start <= now) {
            that.statusDef = 'Enabled';
            that.editStatus = false;
          } else {
            that.statusDef = 'Disabled';
            that.editStatus = true;
          }
        });
      $('#endDate').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'yyyy/MM/dd',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function (e, date) {
          end = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
          that.addForm.get('endDate').patchValue(end);
          $('#effectiveDate').bootstrapMaterialDatePicker('setMaxDate', date);
          const formValueDef = that.addForm.value;
          let startDate = formValueDef['effectiveDate'];
          if ((startDate <= now) && (end >= now)) {
            that.statusDef = 'Enabled';
            that.editStatus = false;
          } else {
            that.statusDef = 'Disabled';
            that.editStatus = true;
          }
        });
    }).catch(error => console.error(error));
  }
  setEndDateToNowIfDisabled() {
    if (this.statusDef == 'Disabled') {
      this.endDateDef = formatDate(new Date(), 'yyyy/MM/dd', 'en_US');
      this.addForm.get('endDate').patchValue(this.endDateDef);
    }
  }
  private loadDataNewDef() {
    const that = this;
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['textNewDescDef']) {
      CKEDITOR.instances['textNewDescDef'].destroy(true);
    }
    let editor = CKEDITOR.replace('textNewDescDef', {toolbar: 'MA'});
    editor.config.height = 50;
    editor.on('change', function (evt) {
      that.descriptionNewDef = btoa(evt.editor.getData());
    });
  }

  pageChangedDefinition(event) {
    this.modelconfigDefinition.currentPage = event;
  }

  getPageDefinition() {
    this.modelconfigDefinition.itemsPerPage = this.selectedPageSizeDefinition;
    this.modelconfigDefinition.currentPage = 1;
    this.modelconfigDefinition.totalItems = this.listDefinitions.length;
  }

  // sort Definitions
  setOrderDefinition(value: string) {
    if (this.orderDefinition === value) {
      this.reverseDefinition = !this.reverseDefinition;
    }

    this.orderDefinition = value;
  }

  // comparator sort
  sortAlphaNumDefinition(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }

  // init form step add definition
  initFormNewDef(): FormGroup {
    return this.addForm = this.fb.group(
      {
        definitionName: [
          null,
          Validators.compose([Validators.required])
        ],
        effectiveDate: [
          null,
          Validators.compose([Validators.required])
        ],
        endDate: [
          null,
        ],
        active: [
          null
        ]
      }
    );
  }

  // init form add family 1 (no criticity)
  initFormNew1(): FormGroup {
    return this.addForm1 = this.fb.group(
      {
        familyName1: [
          null,
          Validators.compose([Validators.required])
        ],
        textNewDescFam1: [
          null,
        ],
        active: [
          null
        ]
      }
    );
  }

  // init form add family 2 (no criticity)
  initFormNew2(): FormGroup {
    return this.addForm2 = this.fb.group(
      {
        familyName2: [
          null,
          Validators.compose([Validators.required])
        ],
        textNewDescFam2: [
          null,
        ],
        active: [
          null
        ]
      }
    );
  }

  // init form add family 3 (high)
  initFormNew3(): FormGroup {
    return this.addForm3 = this.fb.group(
      {
        familyName3: [
          null,
          Validators.compose([Validators.required])
        ],
        textNewDescFam3: [
          null,
        ],
        active: [
          null
        ]
      }
    );
  }

  // init form add family 4 (critical)
  initFormNew4(): FormGroup {
    return this.addForm4 = this.fb.group(
      {
        familyName4: [
          null,
          Validators.compose([Validators.required])
        ],
        textNewDescFam4: [
          null,
        ],
        active: [
          null
        ]
      }
    );
  }

  // no criticity category
  addCategoryFam1() {
    let index = this.groups1.length;
    this.groups1.splice(index, 0, {categoryName: '', categoryDescription: ''});
  }

  removeCategoryFam1(index) {
    this.groups1.splice(index, 1);
  }

  setCategToGroup1() {
    let lengthJson = this.groups1.length;
    let checkInput = true;
    for (let i = 0; i < lengthJson; i++) {
      this.groups1[i].categoryName = $('#famName1' + i).val();
      this.groups1[i].categoryDescription = $('#famDesc1' + i).val();
    }
  }

  // control choose Family no critical
  setCheckedToNo() {
    if (this.checkedNo) {
      $('#StepNo').prop('checked', true);
      $('#ContentStepNo').css('display', 'inline-flex');

    } else {
      $('#StepNo').prop('checked', false);
      $('#ContentStepNo').css('display', 'none');
    }
  }

  getChackedToNo() {
    if ($('#StepNo').is(':checked')) {
      $('#ContentStepNo').css('display', 'inline-flex');
      this.checkedNo = true;
      this.checkValidationStepFam1();
    } else {
      $('#ContentStepNo').css('display', 'none');
      this.checkedNo = false;
      $('#iconTitleNo').css('color', 'gray');
    }
  }

  // moderate category
  addCategoryFam2() {
    let index = this.groups2.length;
    this.groups2.splice(index, 0, {categoryName: '', categoryDescription: ''});
  }

  removeCategoryFam2(index) {
    this.groups2.splice(index, 1);
  }

  setCategToGroup2() {
    let lengthJson2 = this.groups2.length;
    for (let j = 0; j < lengthJson2; j++) {
      this.groups2[j].categoryName = $('#famName2' + j).val();
      this.groups2[j].categoryDescription = $('#famDesc2' + j).val();
    }
  }

  // control choose Family Moderate
  setCheckedToMod() {
    if (this.checkedMod) {
      $('#StepModerate').prop('checked', true);
      $('#ContentStepMod').css('display', 'inline-flex');

    } else {
      $('#StepModerate').prop('checked', false);
      $('#ContentStepMod').css('display', 'none');
    }
  }

  getChackedToMod() {
    if ($('#StepModerate').is(':checked')) {
      $('#ContentStepMod').css('display', 'inline-flex');
      this.checkedMod = true;
      this.checkValidationStepFam2();
    } else {
      $('#ContentStepMod').css('display', 'none');
      this.checkedMod = false;
      $('#iconTitleMod').css('color', 'gray');
    }
  }

  // high category
  addCategoryFam3() {
    let index = this.groups3.length;
    this.groups3.splice(index, 0, {categoryName: '', categoryDescription: ''});
  }

  removeCategoryFam3(index) {
    this.groups3.splice(index, 1);
  }

  setCategToGroup3() {
    let lengthJson3 = this.groups3.length;
    for (let k = 0; k < lengthJson3; k++) {
      this.groups3[k].categoryName = $('#famName3' + k).val();
      this.groups3[k].categoryDescription = $('#famDesc3' + k).val();
    }
  }

  // control choose Family High
  setCheckedToHigh() {
    if (this.checkedHigh) {
      $('#StepHigh').prop('checked', true);
      $('#ContentStepHigh').css('display', 'inline-flex');

    } else {
      $('#StepHigh').prop('checked', false);
      $('#ContentStepHigh').css('display', 'none');
    }
  }

  getChackedToHigh() {
    if ($('#StepHigh').is(':checked')) {
      $('#ContentStepHigh').css('display', 'inline-flex');
      this.checkedHigh = true;
      this.checkValidationStepFam3();
    } else {
      $('#ContentStepHigh').css('display', 'none');
      this.checkedHigh = false;
      $('#iconTitleHigh').css('color', 'gray');
    }
  }

  // critical category
  addCategoryFam4() {
    let index = this.groups4.length;
    this.groups4.splice(index, 0, {categoryName: '', categoryDescription: ''});
  }

  removeCategoryFam4(index) {
    this.groups4.splice(index, 1);
  }

  setCategToGroup4() {
    let lengthJson4 = this.groups4.length;
    for (let l = 0; l < lengthJson4; l++) {
      this.groups4[l].categoryName = $('#famName4' + l).val();
      this.groups4[l].categoryDescription = $('#famDesc4' + l).val();
    }
  }

  // control choose Family Critical
  setCheckedToCrit() {
    if (this.checkedCrit) {
      $('#StepCritical').prop('checked', true);
      $('#ContentStepCrit').css('display', 'inline-flex');

    } else {
      $('#StepCritical').prop('checked', false);
      $('#ContentStepCrit').css('display', 'none');
    }
  }

  getChackedToCrit() {
    if ($('#StepCritical').is(':checked')) {
      $('#ContentStepCrit').css('display', 'inline-flex');
      this.checkedCrit = true;
      this.checkValidationStepFam4();
    } else {
      $('#ContentStepCrit').css('display', 'none');
      this.checkedCrit = false;
      $('#iconTitleCrit').css('color', 'gray');
    }
  }

  // add new Definition: Show modal
  addNewDefinition() {
    this.actionAddDef = true;
    this.actionEditDef = false;
    this.startDate = true;
    // reste description definition
    this.descriptionNewDef = null;
    const that = this;
    this.loadDataNewDef();
    // reste description no family + category
    this.descriptionNewFam1 = null;
    this.groups1 = [{categoryName: '', categoryDescription: ''}];
    // reste description moderate family + category
    this.descriptionNewFam2 = null;
    this.groups2 = [{categoryName: '', categoryDescription: ''}];
    // reste description high family + category
    this.descriptionNewFam3 = null;
    this.groups3 = [{categoryName: '', categoryDescription: ''}];
    // reste description critical family + category
    this.descriptionNewFam4 = null;
    this.groups4 = [{categoryName: '', categoryDescription: ''}];
    this.frmAddDefinition = this.initFormNewDef();
    this.frmAddFamily1 = this.initFormNew1();
    this.frmAddFamily2 = this.initFormNew2();
    this.frmAddFamily3 = this.initFormNew3();
    this.frmAddFamily4 = this.initFormNew4();
    this.wizard.goToStep(0);
    this.groups1 = [];
    this.groups2 = [];
    this.groups3 = [];
    this.groups4 = [];
    this.checkedNo = true;
    this.checkedMod = true;
    this.checkedHigh = true;
    this.checkedCrit = true;
    this.checkValidationStepDef();
    this.checkValidationStepFam1();
    this.checkValidationStepFam2();
    this.checkValidationStepFam3();
    this.checkValidationStepFam4();
    $('#ModalAddDefinition').modal('show');
  }

  // check validation formGroup first step definition
  checkValidationStepDef() {
    if (this.frmAddDefinition.valid) {
      $('#iconTitleDef').css('color', '#0aa2ff');
    } else {
      $('#iconTitleDef').css('color', 'gray');
    }
  }

  // check validation formGroup second step No Critical
  checkValidationStepFam1() {
    this.validationCategory1 = true;
    this.setCategToGroup1();
    if (this.groups1.length == 0) {
      this.validationCategory1 = false;
    } else {
      this.groups1.forEach(category => {
        if ((category.categoryName == null) || (category.categoryName == '')) {
          this.validationCategory1 = false;
        }
      });
    }
    if (this.frmAddFamily1.valid && this.checkedNo && this.validationCategory1) {
      $('#iconTitleNo').css('color', '#3D3D3D');
      this.validationStep1 = true;
    } else {
      $('#iconTitleNo').css('color', 'gray');
      this.validationStep1 = false;
    }
  }

  // check validation formGroup second step Moderate
  checkValidationStepFam2() {
    this.validationCategory2 = true;
    this.setCategToGroup2();
    if (this.groups2.length == 0) {
      this.validationCategory2 = false;
    } else {
      this.groups2.forEach(category => {
        if ((category.categoryName == null) || (category.categoryName == '')) {
          this.validationCategory2 = false;
        }
      });
    }
    if (this.frmAddFamily2.valid && this.checkedMod && this.validationCategory2) {
      $('#iconTitleMod').css('color', 'green');
      this.validationStep2 = true;
    } else {
      $('#iconTitleMod').css('color', 'gray');
      this.validationStep2 = false;
    }
  }

  // check validation formGroup second step High
  checkValidationStepFam3() {
    this.validationCategory3 = true;
    this.setCategToGroup3();
    if (this.groups3.length == 0) {
      this.validationCategory3 = false;
    } else {
      this.groups3.forEach(category => {
        if ((category.categoryName == null) || (category.categoryName == '')) {
          this.validationCategory3 = false;
        }
      });
    }
    if (this.frmAddFamily3.valid && this.checkedHigh && this.validationCategory3) {
      $('#iconTitleHigh').css('color', 'orange');
      this.validationStep3 = true;
    } else {
      $('#iconTitleHigh').css('color', 'gray');
      this.validationStep3 = false;
    }
  }

  // check validation formGroup second step Critical
  checkValidationStepFam4() {
    this.validationCategory4 = true;
    this.setCategToGroup4();
    if (this.groups4.length == 0) {
      this.validationCategory4 = false;
    } else {
      this.groups4.forEach(category => {
        if ((category.categoryName == null) || (category.categoryName == '')) {
          this.validationCategory4 = false;
        }
      });
    }
    if (this.frmAddFamily4.valid && this.checkedCrit && this.validationCategory4) {
      $('#iconTitleCrit').css('color', 'red');
      this.validationStep4 = true;
    } else {
      $('#iconTitleCrit').css('color', 'gray');
      this.validationStep4 = false;
    }
  }

  // show detail object model
  showDetailDefinition(row, event) {
    const ul = $('#' + row.id).parent().parent();
    const li = $('#' + row.id).parent();
    this.id = row.id;
    if (!this.flagDefinition) {
      $('#model_pagin_search_rules').hide();
      let index = this.listDefinitions.findIndex(o => o.id == row.id);
      if (this.listDefinitions[index].dimension != undefined) {
        if (this.listDefinitions[index].dimension.length == 0) {
          $('#listDimensions_' + row.id).css('display', 'none');
        } else {
          $('#listDimensions_' + row.id).css('display', 'block');
        }
      } else {
        $('#listDimensions_' + row.id).css('display', 'none');
      }
      $('#descripDef_' + row.id).html(atob(row.description));
      $('#cirlePackDim').css('display', 'none');
      this.flagDefinition = true;
      $('#body' + row.id).slideDown();
      ul.children().hide();
      $('#titleObjectDesfinition').show('slow');
      li.show('slow');
      this.disableEditDef = true;
      // gategory group 1
      this.groups1 = [];
      if ((row.familyNo.category != undefined) && row.familyNo.category.length > 0) {
        row.familyNo.category.forEach(element => {
          this.groups1.push(element);
        });
      }
      // gategory group 2
      this.groups2 = [];
      if ((row.familyMod.category != undefined) && row.familyMod.category.length > 0) {
        row.familyMod.category.forEach(element => {
          this.groups2.push(element);
        });
      }
      // gategory group 3
      this.groups3 = [];
      if ((row.familyHigh.category != undefined) && row.familyHigh.category.length > 0) {
        row.familyHigh.category.forEach(element => {
          this.groups3.push(element);
        });
      }
      // gategory group 4
      this.groups4 = [];
      if ((row.familyCrit.category != undefined) && row.familyCrit.category.length > 0) {
        row.familyCrit.category.forEach(element => {
          this.groups4.push(element);
        });
      }
      this.showListDims = false;
      this.getDefinitionDimensionByName(row.name, row.id);
    } else {
      $('#model_pagin_search_rules').show();
      this.disableEditDef = true;
      this.flagShema = true;
      // event.target.innerHTML = 'Details';
      this.flagDefinition = false;
      $('#body' + row.id).slideUp();
      ul.children().show('slow');
      // $('#btDef').css('display', 'block');
      $('#cirlePackDim').css('display', 'block');
    }
  }

  onUpDefShowModal(row) {
    this.actionAddDef = false;
    this.actionEditDef = true;
    this.frmAddDefinition = this.initFormNewDef();
    this.frmAddFamily2 = this.initFormNew2();
    this.frmAddFamily3 = this.initFormNew3();
    this.frmAddFamily4 = this.initFormNew4();
    this.idDef = row.id;
    this.nameDef = row.name;
    const that = this;
    /* start Modal step 1 */
    this.frmAddDefinition.get('definitionName').patchValue(row.name);
    this.frmAddDefinition.get('effectiveDate').patchValue(row.effectiveDate);
    let now = formatDate(new Date(), 'yyyy/MM/dd', 'en_US');
    this.statusDef = row.status;
    // this.typeDef = row.type;
    if ((row.effectiveDate != null) && (row.effectiveDate != '---')) {
      this.startDate = false;
    }
    if (row.endDate !== '---') {
      this.frmAddDefinition.get('endDate').patchValue(row.endDate);
    } else if (now >= row.effectiveDate) {
      this.editStatus = false;
    }
    // ckeditor
    this.descriptionNewDef = row.description;
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['textNewDescDef']) {
      CKEDITOR.instances['textNewDescDef'].destroy(true);
    }
    let editor = CKEDITOR.replace('textNewDescDef', {toolbar: 'MA'});
    editor.config.height = 40;
    if (row.description != null && row.description != 'undefined') {
      if (row.description.length > 0 || row.description != ' ') {
        let decodeDesc = row.description.split(' ').join('+');
        editor.setData(atob(decodeDesc));
      }
    }
    editor.on('change', function (evt) {
      that.descriptionNewDef = btoa(evt.editor.getData());
    });
    // end ckeditor
    /* end Modal step 1 */
    ///////////////////////////////////////////////////////////////////////////////////////////
    /* start Modal step 2 */
    this.checkedNo = row.checkedNo;
    if (this.checkedNo === true) {
      this.frmAddFamily1 = this.initFormNew1();
      this.frmAddFamily1.get('familyName1').patchValue(row.familyNo.criticityName);
      this.frmAddFamily1.get('textNewDescFam1').patchValue(row.familyNo.criticityDescription);
      // end category group1
    }
    this.setCheckedToNo();
    /* end Modal step 2 */
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /* start Modal step 3 */
    this.checkedMod = row.checkedMod;
    if (this.checkedMod == true) {
      this.frmAddFamily2.get('familyName2').patchValue(row.familyMod.criticityName);
      this.frmAddFamily2.get('textNewDescFam2').patchValue(row.familyMod.criticityDescription);
      // end category group2
    }
    this.setCheckedToMod();
    /* end Modal step 3 */
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /* start Modal step 4 */
    this.checkedHigh = row.checkedHigh;
    if (this.checkedHigh == true) {
      this.frmAddFamily3.get('familyName3').patchValue(row.familyHigh.criticityName);
      this.frmAddFamily3.get('textNewDescFam3').patchValue(row.familyHigh.criticityDescription);
      // end category group3
    }
    this.setCheckedToHigh();
    /* end Modal step 4 */
    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /* start Modal step 5 */
    this.checkedCrit = row.checkedCrit;
    if (this.checkedCrit == true) {
      this.frmAddFamily4.get('familyName4').patchValue(row.familyCrit.criticityName);
      this.frmAddFamily4.get('textNewDescFam4').patchValue(row.familyCrit.criticityDescription);
      // end category group4
    }
    this.setCheckedToCrit();
    /* end Modal step 5 */
    that.wizard.goToStep(0);
    this.checkValidationStepDef();
    this.checkValidationStepFam1();
    this.checkValidationStepFam2();
    this.checkValidationStepFam3();
    this.checkValidationStepFam4();
    $('#ModalAddDefinition').modal('show');
  }

  onSetUpdateDefinition() {
    if (this.actionEditDef) {
      this.checkValidationStepFam4();
      // step 1
      const formValueDef = this.addForm.value;
      this.nameDef = formValueDef['definitionName'];
      this.effectDate = formValueDef['effectiveDate'];
      this.endDateDef = formValueDef['endDate'];
      // step 2
      if (this.checkedNo) {
        if (this.validationStep1 == false) {
          this.wizard.goToStep(1);
          return;
        } else {
          const formValue1 = this.addForm1.value;
          this.familyName1 = formValue1['familyName1'];
          this.descriptionNewFam1 = formValue1['textNewDescFam1'];
        }
      } else {
        this.familyName1 = null;
        this.descriptionNewFam1 = null;
        this.groups1 = [];
      }
      // step 3
      if (this.checkedMod) {
        if (this.validationStep2 == false) {
          this.wizard.goToStep(2);
          return;
        } else {
          const formValue2 = this.addForm2.value;
          this.familyName2 = formValue2['familyName2'];
          this.descriptionNewFam2 = formValue2['textNewDescFam2'];
        }
      } else {
        this.familyName2 = null;
        this.descriptionNewFam2 = null;
        this.groups2 = [];
      }
      // step 4
      if (this.checkedHigh) {
        if (this.validationStep3 == false) {
          this.wizard.goToStep(3);
          return;
        } else {
          const formValue3 = this.addForm3.value;
          this.familyName3 = formValue3['familyName3'];
          this.descriptionNewFam3 = formValue3['textNewDescFam3'];
        }
      } else {
        this.familyName3 = null;
        this.descriptionNewFam3 = null;
        this.groups3 = [];
      }
      // step 5
      if (this.checkedCrit) {
        if (this.validationStep4 == false) {
          this.wizard.goToStep(4);
          return;
        } else {
          const formValue4 = this.addForm4.value;
          this.familyName4 = formValue4['familyName4'];
          this.descriptionNewFam4 = formValue4['textNewDescFam4'];
        }
      } else {
        this.familyName4 = null;
        this.descriptionNewFam4 = null;
        this.groups4 = [];
      }
      let listDefinitions = {
        id: this.idDef,
        name: this.nameDef,
        description: this.descriptionNewDef,
        effectiveDate: this.effectDate,
        endDate: this.endDateDef,
        status: this.statusDef,
        type: this.typeDef,
        checkedNo: this.checkedNo,
        familyNo: {
          criticityName: this.familyName1,
          criticityDescription: this.descriptionNewFam1,
          category: this.groups1
        },
        checkedMod: this.checkedMod,
        familyMod: {
          criticityName: this.familyName2,
          criticityDescription: this.descriptionNewFam2,
          category: this.groups2
        },
        checkedHigh: this.checkedHigh,
        familyHigh: {
          criticityName: this.familyName3,
          criticityDescription: this.descriptionNewFam3,
          category: this.groups3
        },
        checkedCrit: this.checkedCrit,
        familyCrit: {
          criticityName: this.familyName4,
          criticityDescription: this.descriptionNewFam4,
          category: this.groups4
        }
      };
      let validationCheckedStepsUp = (this.checkedNo || this.checkedMod || this.checkedHigh || this.checkedCrit);
      if (validationCheckedStepsUp) {
        $('#ModalAddDefinition').modal('hide');
        Swal.fire({
          title: this.lang ? 'Are you sure?' : 'Êtes-vous sûr(e) ?',
          text: this.lang ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas revenir en arrière !',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: this.lang ? 'Yes, Update it!' : 'Oui, Mettez à jour !',
          cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
        }).then((result) => {
          if (result.value) {
              addLoader(this.lang ==='en' ? 'UPDATE PLZ&#160;&#160;WAIT . . .' : 'MODIFICATION VEUILLEZ&#160;&#160;PATIENTEZ . . .');
            let subscription2$ = this.definitionsevice.setUpdateDefinition(listDefinitions).subscribe(
              result => {
                if (result['success'] == true) {
                  let index = this.listDefinitions.findIndex(o => o.id == this.idDef);
                  this.listDefinitions[index].name = this.nameDef;
                  this.listDefinitions[index].effectiveDate = this.effectDate;
                  this.listDefinitions[index].endDate = this.endDateDef;
                  this.listDefinitions[index].status = this.statusDef;
                  this.listDefinitions[index].type = this.typeDef;
                  this.listDefinitions[index].description = this.descriptionNewDef;
                  if (this.descriptionNewDef && this.descriptionNewDef != 'undefined') {
                    if (this.descriptionNewDef.length > 0 || this.descriptionNewDef != ' ') {
                      $('#descripDef_' + this.idDef).html(atob(this.descriptionNewDef));
                    }
                  }
                  this.listDefinitions[index].checkedNo = listDefinitions.checkedNo;
                  this.listDefinitions[index].checkedMod = listDefinitions.checkedMod;
                  this.listDefinitions[index].checkedHigh = listDefinitions.checkedHigh;
                  this.listDefinitions[index].checkedCrit = listDefinitions.checkedCrit;
                  this.listDefinitions[index].familyNo = listDefinitions.familyNo;
                  this.listDefinitions[index].familyMod = listDefinitions.familyMod;
                  this.listDefinitions[index].familyHigh = listDefinitions.familyHigh;
                  this.listDefinitions[index].familyCrit = listDefinitions.familyCrit;
                }
                if (result['update'] == true) {
                  let subscription7$ = this.definitionsevice.getListClassificationDimensions().subscribe(
                    data => {
                      Object.values(data).map((item) => {
                        this.circlePackClassif(item);
                      });
                    },
                    err => {
                      console.error(err);
                    }
                  );
                  this.subscription.add(subscription7$);
                }
                this.onCheckNotExistComplianceInDefinition();
                this.onGetCheckExistComplianceInDefinition();
              },
              err => {
                console.error(err);
                removeLoader();
              },
              () => {
                  removeLoader();
              }
            );
            this.subscription.add(subscription2$);
          }
        });


      } else {
        if (!this.checkedNo) {
          this.wizard.goToStep(1);
        } else if (!this.checkedMod) {
          this.wizard.goToStep(2);
        } else if (!this.checkedHigh) {
          this.wizard.goToStep(3);
        } else if (!this.checkedCrit) {
          this.wizard.goToStep(4);
        }
      }
    }
  }

  // remove selected definition
  onSetRemoveDefinition(id) {
    var that = this;
    Swal.fire({
      title: this.lang ? 'Are you sure?' : 'Êtes-vous sûr(e) ?',
      text: this.lang ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas revenir en arrière !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.lang ? 'Yes, delete it!' : 'Oui, Supprimer !',
      cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
    }).then((result) => {
      if (result.value) {
          addLoader(this.lang ==='en' ? 'DELETE PLZ&#160;&#160;WAIT . . .' : 'SUPPRESSION VEUILLEZ&#160;&#160;PATIENTEZ . . .');
        let subscription3$ = that.definitionsevice.setRemoveDefinition(id, 'check').subscribe(
          data => {
            if (data['success'] == 'OK') {
              this.onGetListDefinitions();
              this.onCheckNotExistComplianceInDefinition();
              this.onGetCheckExistComplianceInDefinition();
              this.disableEditDef = true;
              this.flagDefinition = false;
              // $('#btDef').css('display', 'block');
              $('#cirlePackDim').css('display', 'block');
            } else if (data['success'] == 'not OK') {
              this.listDimensionRemove = [];
              data['data'].map(item => {
                let nameDim = item.nameDim;
                item.listCateg.map(sousItem => {
                  let allCateg = {
                    nameDime: nameDim,
                    nameCateg: sousItem
                  };
                  this.listDimensionRemove.push(allCateg);
                });
              });
              // console.log(this.listDimensionRemove);
              if (this.listDimensionRemove.length > 0) {
                const that = this;
                $('#ListAllDims').empty();
                // if ($('#listDimsCateg').length > 0) {
                //   $('#listRole').remove();
                // }
                $('#ListAllDims').append('<table id="listDimsCateg" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: small;">   <thead>' +
                  '<tr>' +
                  '<th>Dimensions</th>' +
                  '<th>Categorys</th>' +
                  '</tr>' +
                  '</thead></table>');

                $('#listDimsCateg').DataTable({
                  processing: true,
                  destroy: true,
                  pageLength: 5,
                  lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                  rowCallback(row, data) {
                  },
                  initComplete(settings, json) {
                    const table = this.api();
                    that.listDimensionRemove.map(item => {
                      table.row.add([
                        item.nameDime,
                        item.nameCateg,
                      ]).draw(false);
                    });
                  }
                });
                $('#listDimAndCateg').modal('show');
              }
            } else if(data['success'] == 'this definition is associated with some projects'){
              Swal.fire({
                title: this.lang ? 'Definition is associated with some projects?' : 'La définition est associée à certains projets ?',
                text: this.lang ? 'This definition is associated with some projects!\nWould you like to delete it even from this projects?' : 'Cette définition est associée à certains projets !\nVoulez-vous le supprimer même de ces projets ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.lang ? 'Yes, proceed!' : 'Oui, continuer !',
                cancelButtonText: this.lang ? 'Cancel' : 'Annuler'
              }).then((result) => {
                if(result.value){
                  that.definitionsevice.setRemoveDefinition(id, 'forceDelete').subscribe(
                    d =>{
                      this.onGetListDefinitions();
                      this.onCheckNotExistComplianceInDefinition();
                      this.onGetCheckExistComplianceInDefinition();
                      this.disableEditDef = true;
                      this.flagDefinition = false;
                      // $('#btDef').css('display', 'block');
                      $('#cirlePackDim').css('display', 'block');
                    }
                  );
                }
              });
            }
          },
          err => {
            console.error(err);
              removeLoader();
            window.location.reload();
          },
          () => {
              removeLoader();
          }
        );
        this.subscription.add(subscription3$);
      }
    });
  }

  resetIfNotValidName() {
    this.validateName = true;
  }

  changeNameToValidate() {
    const formValue = this.frmAddDefinition.value;
    let newName = formValue['definitionName'];
    if (this.actionEditDef || !this.actionAddDef) {
      this.listNameDefinitions.map(item => {
        if (newName == this.nameDef) {
        } else {
          if (newName == item) {
            this.frmAddDefinition.get('definitionName').patchValue(null);
            this.validateName = false;
          }
        }
      });
    }
    if (!this.actionEditDef || this.actionAddDef) {
      this.listNameDefinitions.map(item => {
        if (newName == item) {
          this.frmAddDefinition.get('definitionName').patchValue(null);
          this.validateName = false;
        }
      });
    }
  }

  // add new definition => fields definition
  onAddNewDefinition() {
    if (this.actionAddDef) {
      this.checkValidationStepFam4();
      let validationCheckedSteps = (this.checkedNo || this.checkedMod || this.checkedHigh || this.checkedCrit);
      if (validationCheckedSteps && this.validateName) {
        // step 1
        const formValueDef = this.addForm.value;
        this.nameDef = formValueDef['definitionName'];
        this.effectDate = formValueDef['effectiveDate'];
        this.endDateDef = formValueDef['endDate'];
        // step 2
        if (this.checkedNo) {
          if (this.validationStep1 == false) {
            this.wizard.goToStep(1);
            return;
          } else {
            const formValue1 = this.addForm1.value;
            this.familyName1 = formValue1['familyName1'];
            this.descriptionNewFam1 = formValue1['textNewDescFam1'];
          }
        } else {
          this.familyName1 = null;
          this.descriptionNewFam1 = null;
          this.groups1 = [];
        }
        // step 3
        if (this.checkedMod) {
          if (this.validationStep2 == false) {
            this.wizard.goToStep(2);
            return;
          } else {
            const formValue2 = this.addForm2.value;
            this.familyName2 = formValue2['familyName2'];
            this.descriptionNewFam2 = formValue2['textNewDescFam2'];
          }
        } else {
          this.familyName2 = null;
          this.descriptionNewFam2 = null;
          this.groups2 = [];
        }
        // step 4
        if (this.checkedHigh) {
          if (this.validationStep3 == false) {
            this.wizard.goToStep(3);
            return;
          } else {
            const formValue3 = this.addForm3.value;
            this.familyName3 = formValue3['familyName3'];
            this.descriptionNewFam3 = formValue3['textNewDescFam3'];
          }
        } else {
          this.familyName3 = null;
          this.descriptionNewFam3 = null;
          this.groups3 = [];
        }
        // step 5
        if (this.checkedCrit) {
          if (this.validationStep4 == false) {
            this.wizard.goToStep(4);
            return;
          } else {
            const formValue4 = this.addForm4.value;
            this.familyName4 = formValue4['familyName4'];
            this.descriptionNewFam4 = formValue4['textNewDescFam4'];
          }
        } else {
          this.familyName4 = null;
          this.descriptionNewFam4 = null;
          this.groups4 = [];
        }

        let listDefinitions = {
          name: this.nameDef,
          description: this.descriptionNewDef,
          effectiveDate: this.effectDate,
          endDate: this.endDateDef,
          status: this.statusDef,
          type: this.typeDef,
          checkedNo: this.checkedNo,
          familyNo: {
            criticityName: this.familyName1,
            criticityDescription: this.descriptionNewFam1,
            category: this.groups1
          },
          checkedMod: this.checkedMod,
          familyMod: {
            criticityName: this.familyName2,
            criticityDescription: this.descriptionNewFam2,
            category: this.groups2
          },
          checkedHigh: this.checkedHigh,
          familyHigh: {
            criticityName: this.familyName3,
            criticityDescription: this.descriptionNewFam3,
            category: this.groups3
          },
          checkedCrit: this.checkedCrit,
          familyCrit: {
            criticityName: this.familyName4,
            criticityDescription: this.descriptionNewFam4,
            category: this.groups4
          }
        };
        let subscription4$ = this.definitionsevice.setNewDefinition(listDefinitions).subscribe(
          result => {
            this.onGetListDefinitions();
          },
          err => {
            console.error(err);
          }
        );
        this.subscription.add(subscription4$);
        $('#ModalAddDefinition').modal('hide');
      } else {
        if (!this.checkedNo) {
          this.wizard.goToStep(1);
        } else if (!this.checkedMod) {
          this.wizard.goToStep(2);
        } else if (!this.checkedHigh) {
          this.wizard.goToStep(3);
        } else if (!this.checkedCrit) {
          this.wizard.goToStep(4);
        }
      }
    }
  }

  // data to circle Pack
  onGetListClassificationDimensions() {
    let subscription5$ = this.definitionsevice.getListClassificationDimensions().subscribe(
      data => {
        // @ts-ignore
        if(data.pack== null){
          Object.values(data).map((item) => {
            this.circlePackClassif(item);
          });
        }
      },
      err => {
        console.error(err);
      },
      () => {
        this.getDimensionsToFilter();
      }
    );
    this.subscription.add(subscription5$);
  }

  circlePackClassif(data) {
    const that = this;
    const width = 932;
    const height = 932;
    const color = d3.scaleLinear()
      .domain([0, 5])
      .range(['hsl(152,80%,80%)', 'hsl(228,30%,40%)'])
      .interpolate(d3.interpolateHcl);
    const pack = data => d3.pack()
      .size([width, height])
      .padding(70)
      (d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value));

    const root = pack(data);
    let focus = root;
    let view;
    $('#dimensionChart').empty();
    const svg = d3.select('#dimensionChart');
    svg.attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)
      .style('display', 'block')
      .style('background', 'transparent')
      .style('cursor', 'pointer')
      // .style("border-radius", "50%")
      .on('click', () => zoom(root))
    ;
    if ($('.d3-tip-Zoome').length > 0) {
      $('.d3-tip-Zoome').remove();
    }
    const tool_tip = d3Tip()
      .direction('n')
      .attr('class', 'd3-tip-Zoome')
      .html((d) => {
        return '<div id="tipo">' + d.data.name + '</div>';
      });
    svg.call(tool_tip);

    function compareValues(key, order = 'asc') {
      return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
          return 0;
        }

        const varA = (typeof a[key] === 'string')
          ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
          ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return (
          (order === 'desc') ? (comparison * -1) : comparison
        );
      };
    }

    const cont = svg.append('g');
    const node = cont
      .selectAll('circle')
      .data(root.descendants().slice(1))
      .join('circle')
      .attr('fill', d => d.children ? color(d.depth) : 'white')
      .attr('id', d => {
        let id;
        switch (d.depth) {
          case 1:
            id = d.data.name;
            break;
          case 2:
            id = d.parent.data.name + d.data.name;
            break;
          case 3:
            id = d.parent.parent.data.name + d.parent.data.name + d.data.name;
            break;
        }
        return id;
      })
      .on('mouseover', function (d) {
        d3.select(this).attr('stroke', '#000');
        tool_tip.show(d, this);
      })
      .on('mouseout', function (d) {
        d3.select(this).attr('stroke', null);
        tool_tip.hide();
      })
      .on('mousemove', function (d) {
        if (d.parent) {
          // let pos = d3.select(this).node().getBoundingClientRect();
          d3.select('.d3-tip-Zoome')
            .style('left', (d3.event.pageX - 34) + 'px')
            .style('top', (d3.event.pageY - 100) + 'px');
        }
      })
      .on('click', d => {
        if (d.depth != 3) {
          focus !== d && (zoom(d), d3.event.stopPropagation());
        } else {
          d3.event.stopPropagation();
        }
      });

    // add shadow filter
    svg.append('svg:defs').selectAll('filter')
      .data(['shadowGlobal'])
      .enter().append('svg:filter')
      .attr('id', String)
      .attr('y', -10)
      .attr('x', -10)
      .attr('height', 40)
      .attr('width', 20)
      .append('feOffset')
      .attr('in', 'SourceAlpha')
      .attr('dx', 3)
      .attr('dy', -10)
      .attr('result', 'offset2')
      .attr('stdDeviation', 4);
    svg.selectAll('filter')
      .append('feGaussianBlur')
      .attr('in', 'offset2')
      .attr('result', 'blur2')
      .attr('stdDeviation', 10);
    svg.selectAll('filter')
      .append('feMerge')
      .append('feMergeNode')
      .attr('in', 'blur2');
    svg.selectAll('filter')
      .select('feMerge')
      .append('feMergeNode')
      .attr('in', 'SourceGraphic');
    const label = svg.append('g')
      .style('font', '30px sans-serif')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .selectAll('text')
      .attr('class', 'labelZoom')
      .data(root.descendants())
      .join('text')
      .style('fill-opacity', d => d.parent === root ? 1 : 0)
      // .attr('id', d => d.data.name)
      .style('display', d => d.parent === root ? 'inline' : 'none')
      .text(d => d.data.name);

    const mark = svg.append('g')
      .selectAll('.imago')
      .data(root.descendants().slice(1))
      .enter()
      .append('svg:image')
      .style('display', 'none')
      .attr('filter', 'url(#shadowGlobal)')
      .attr('outline', 'none')
      .attr('id', d => 'markos_' + d.data.name)
      .attr('class', 'imago')
      .attr('width', '20px')
      .attr('height', '36px')
      .attr('xlink:href', 'assets/images/hand.png');

    zoomTo([root.x, root.y, root.r * 2]);

    function zoomTo(v) {
      const k = width / v[2];

      view = v;

      label.attr('transform', d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
      mark.attr('transform', d => `translate(${(d.x - v[0]) * k},${((d.y - v[1]) * k) - 15})`);
      node.attr('transform', d => `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`);
      node.attr('r', d => d.r * k);
    }

    function zoom(d) {
      const focus0 = focus;

      focus = d;

      const transition = svg.transition()
        .duration(d3.event.altKey ? 7500 : 750)
        .tween('zoom', d => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return t => zoomTo(i(t));
        });

      label
        .filter(function (d) {
          return d.parent === focus || this.style.display === 'inline';
        })
        .transition(transition)
        .style('fill-opacity', d => d.parent === focus ? 1 : 0)
        .on('start', function (d) {
          if (d.parent === focus) this.style.display = 'inline';
        })
        .on('end', function (d) {
          if (d.parent !== focus) this.style.display = 'none';
        });
    }
  }

  // toggle flesh opned not opened datatable filter
  _toggleSidebar() {
    this._opened = !this._opened;
    this._opened ? $('#dimensionChart').css({width: '135%', height: '90vh'}) : $('#dimensionChart').css({width: '100%', height: '88vh'});
    if (this.showListDims) {
      $('#affichListDim').html('<i class="fas fa-arrow-circle-right fa-2x"></i>');
      this.showListDims = false;
    } else {
      $('#affichListDim').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
      this.showListDims = true;
    }
  }

  // export data DEFINITOIN to tsv (to Architect)
  async exportDataDefinitionTsv() {
    let workbook: Excel.Workbook = new Excel.Workbook();
    let splitter = '';
    let fileName = '';
    let worksheet = workbook.addWorksheet('definitions');
    let noData = false;
    worksheet.getCell('A1').value = '\uFEFF';
    worksheet.columns = [
      {header: '', key: 'column1'},
      {header: '', key: 'column2'},
      {header: '', key: 'column3'},
      {header: '', key: 'column4'}
    ];

    let subscription6$ = this.definitionsevice.getListDefinitions().subscribe(
      result => {
        if (result['data'].length == 0) {
          noData = true;
        } else {

          result['data'].map(function (value, label) {
            if ((value.name != null) && value.status == 'Enabled') {
              // family NoCriticity
              if ((value['familyNo'].category != undefined) && (value['familyNo'].category.length > 0)) {
                let description = value['familyNo'].criticityDescription;
                var descFomatted = '';
                if ((description != undefined) && (description != null) && (description != '')) {
                  // var descFomatted = description.replace(/<[^>]*>/g, '');
                  // descFomatted = descFomatted.replace(/<A[^>]*>|<\/A>/g, '');
                  // descFomatted = descFomatted.replace(/<input[^>]*>|<\/input>/gi, '');
                  // descFomatted = descFomatted.replace(/&(nbsp);/g, ' ');
                  // descFomatted = descFomatted.replace(/&(amp);/g, '&');
                  // descFomatted = descFomatted.replace(/&(quot);/g, '\'');
                  // descFomatted = descFomatted.replace(/&(lt);/g, '<');
                  // descFomatted = descFomatted.replace(/&(gt);/g, '>');
                  // descFomatted = descFomatted.replace(/&(#39);/g, '\'');
                  // descFomatted = descFomatted.replace(/&(eacute);/g, 'é');
                  // descFomatted = descFomatted.replace(/&(egrave);/g, 'è');
                  // descFomatted = descFomatted.replace(/&(ecirc);/g, 'ê');
                  // descFomatted = descFomatted.replace(/&(agrave);/g, 'à');
                  // descFomatted = descFomatted.replace(/&(ccedil);/g, 'ç');
                    descFomatted = description.replace(/<\/?[a-z][\s\S]*?>/ig, '')
                        .replace(/<A[^>]*?>|<\/A>/g, '')
                        .replace(/<input[^>]*?>|<\/input>/gi, '')
                        .replace(/&(nbsp);/g, ' ')
                        .replace(/&(amp);/g, '&')
                        .replace(/&(quot);/g, '\'')
                        .replace(/&(lt);/g, '<')
                        .replace(/&(gt);/g, '>')
                        .replace(/&(#39);/g, "'")
                        .replace(/&(eacute);/g, 'é')
                        .replace(/&(egrave);/g, 'è')
                        .replace(/&(ugrave);/g, 'ù')
                        .replace(/&(ccedil);/g, 'ç');
                } else {
                  descFomatted = description;
                }
                worksheet.addRow({
                  column1: value['familyNo'].criticityName,
                  column2: 0,
                  column3: descFomatted,
                  column4: value.name
                });
                value['familyNo'].category.map(categ => {
                  worksheet.addRow({
                    column1: '#',
                    column2: categ.categoryName,
                    column3: '',
                    column4: ''
                  });
                });
                worksheet.addRow({
                  column1: '',
                  column2: '',
                  column3: '',
                  column4: ''
                });
              }

              // Family Moderate
              if ((value['familyMod'].category != undefined) && (value['familyMod'].category.length > 0)) {
                let description1 = value['familyMod'].criticityDescription;
                var descFomatted = '';
                if ((description1 != undefined) && (description1 != null) && (description1 != '')) {
                  // var descFomatted = description1.replace(/<[^>]*>/g, '');
                  // descFomatted = descFomatted.replace(/<A[^>]*>|<\/A>/g, '');
                  // descFomatted = descFomatted.replace(/<input[^>]*>|<\/input>/gi, '');
                  // descFomatted = descFomatted.replace(/&(nbsp);/g, ' ');
                  // descFomatted = descFomatted.replace(/&(amp);/g, '&');
                  // descFomatted = descFomatted.replace(/&(quot);/g, '\'');
                  // descFomatted = descFomatted.replace(/&(lt);/g, '<');
                  // descFomatted = descFomatted.replace(/&(gt);/g, '>');
                  // descFomatted = descFomatted.replace(/&(#39);/g, '\'');
                  // descFomatted = descFomatted.replace(/&(eacute);/g, 'é');
                  // descFomatted = descFomatted.replace(/&(egrave);/g, 'è');
                  // descFomatted = descFomatted.replace(/&(ecirc);/g, 'ê');
                  // descFomatted = descFomatted.replace(/&(agrave);/g, 'à');
                  // descFomatted = descFomatted.replace(/&(ccedil);/g, 'ç');
                    descFomatted = description1.replace(/<\/?[a-z][\s\S]*?>/ig, '')
                        .replace(/<A[^>]*?>|<\/A>/g, '')
                        .replace(/<input[^>]*?>|<\/input>/gi, '')
                        .replace(/&(nbsp);/g, ' ')
                        .replace(/&(amp);/g, '&')
                        .replace(/&(quot);/g, '\'')
                        .replace(/&(lt);/g, '<')
                        .replace(/&(gt);/g, '>')
                        .replace(/&(#39);/g, "'")
                        .replace(/&(eacute);/g, 'é')
                        .replace(/&(egrave);/g, 'è')
                        .replace(/&(ugrave);/g, 'ù')
                        .replace(/&(ccedil);/g, 'ç');
                } else {
                  descFomatted = description1;
                }
                worksheet.addRow({
                  column1: value['familyMod'].criticityName,
                  column2: 1,
                  column3: descFomatted,
                  column4: value.name
                });
                value['familyMod'].category.map(categ => {
                  worksheet.addRow({
                    column1: '#',
                    column2: categ.categoryName,
                    column3: '',
                    column4: ''
                  });
                });
                worksheet.addRow({
                  column1: '',
                  column2: '',
                  column3: '',
                  column4: ''
                });
              }

              // Family High
              if ((value['familyHigh'].category != undefined) && (value['familyHigh'].category.length > 0)) {
                let description2 = value['familyHigh'].criticityDescription;
                var descFomatted = '';
                if ((description2 != undefined) && (description2 != null) && (description2 != '')) {
                  // var descFomatted = description2.replace(/<[^>]*>/g, '');
                  // descFomatted = descFomatted.replace(/<A[^>]*>|<\/A>/g, '');
                  // descFomatted = descFomatted.replace(/<input[^>]*>|<\/input>/gi, '');
                  // descFomatted = descFomatted.replace(/&(nbsp);/g, ' ');
                  // descFomatted = descFomatted.replace(/&(amp);/g, '&');
                  // descFomatted = descFomatted.replace(/&(quot);/g, '\'');
                  // descFomatted = descFomatted.replace(/&(lt);/g, '<');
                  // descFomatted = descFomatted.replace(/&(gt);/g, '>');
                  // descFomatted = descFomatted.replace(/&(#39);/g, '\'');
                  // descFomatted = descFomatted.replace(/&(eacute);/g, 'é');
                  // descFomatted = descFomatted.replace(/&(egrave);/g, 'è');
                  // descFomatted = descFomatted.replace(/&(ecirc);/g, 'ê');
                  // descFomatted = descFomatted.replace(/&(agrave);/g, 'à');
                  // descFomatted = descFomatted.replace(/&(ccedil);/g, 'ç');
                    descFomatted = description2.replace(/<\/?[a-z][\s\S]*?>/ig, '')
                        .replace(/<A[^>]*?>|<\/A>/g, '')
                        .replace(/<input[^>]*?>|<\/input>/gi, '')
                        .replace(/&(nbsp);/g, ' ')
                        .replace(/&(amp);/g, '&')
                        .replace(/&(quot);/g, '\'')
                        .replace(/&(lt);/g, '<')
                        .replace(/&(gt);/g, '>')
                        .replace(/&(#39);/g, "'")
                        .replace(/&(eacute);/g, 'é')
                        .replace(/&(egrave);/g, 'è')
                        .replace(/&(ugrave);/g, 'ù')
                        .replace(/&(ccedil);/g, 'ç');
                } else {
                  descFomatted = description2;
                }
                worksheet.addRow({
                  column1: value['familyHigh'].criticityName,
                  column2: 2,
                  column3: descFomatted,
                  column4: value.name
                });
                value['familyHigh'].category.map(categ => {
                  worksheet.addRow({
                    column1: '#',
                    column2: categ.categoryName,
                    column3: '',
                    column4: ''
                  });
                });
                worksheet.addRow({
                  column1: '',
                  column2: '',
                  column3: '',
                  column4: ''
                });
              }

              // Family Critical
              if ((value['familyCrit'].category != undefined) && (value['familyCrit'].category.length > 0)) {
                let description3 = value['familyCrit'].criticityDescription;
                var descFomatted = '';
                if ((description3 != undefined) && (description3 != null) && (description3 != '')) {
                  // var descFomatted = description3.replace(/<[^>]*>/g, '');
                  // descFomatted = descFomatted.replace(/<A[^>]*>|<\/A>/g, '');
                  // descFomatted = descFomatted.replace(/<input[^>]*>|<\/input>/gi, '');
                  // descFomatted = descFomatted.replace(/&(nbsp);/g, ' ');
                  // descFomatted = descFomatted.replace(/&(amp);/g, '&');
                  // descFomatted = descFomatted.replace(/&(quot);/g, '\'');
                  // descFomatted = descFomatted.replace(/&(lt);/g, '<');
                  // descFomatted = descFomatted.replace(/&(gt);/g, '>');
                  // descFomatted = descFomatted.replace(/&(#39);/g, '\'');
                  // descFomatted = descFomatted.replace(/&(eacute);/g, 'é');
                  // descFomatted = descFomatted.replace(/&(egrave);/g, 'è');
                  // descFomatted = descFomatted.replace(/&(ecirc);/g, 'ê');
                  // descFomatted = descFomatted.replace(/&(agrave);/g, 'à');
                  // descFomatted = descFomatted.replace(/&(ccedil);/g, 'ç');
                    descFomatted = description3.replace(/<\/?[a-z][\s\S]*?>/ig, '')
                        .replace(/<A[^>]*?>|<\/A>/g, '')
                        .replace(/<input[^>]*?>|<\/input>/gi, '')
                        .replace(/&(nbsp);/g, ' ')
                        .replace(/&(amp);/g, '&')
                        .replace(/&(quot);/g, '\'')
                        .replace(/&(lt);/g, '<')
                        .replace(/&(gt);/g, '>')
                        .replace(/&(#39);/g, "'")
                        .replace(/&(eacute);/g, 'é')
                        .replace(/&(egrave);/g, 'è')
                        .replace(/&(ugrave);/g, 'ù')
                        .replace(/&(ccedil);/g, 'ç');
                } else {
                  descFomatted = description3;
                }
                worksheet.addRow({
                  column1: value['familyCrit'].criticityName,
                  column2: 3,
                  column3: descFomatted,
                  column4: value.name
                });
                value['familyCrit'].category.map(categ => {
                  worksheet.addRow({
                    column1: '#',
                    column2: categ.categoryName,
                    column3: '',
                    column4: ''
                  });
                });
                worksheet.addRow({
                  column1: '',
                  column2: '',
                  column3: '',
                  column4: ''
                });
              }
            }
          });
        }

      },
      err => {
        console.error(err);
        return;
      },
      () => {
        if (noData) {
          alert('no data to export');
        } else {
          splitter = '\t';
          fileName = 'definition.txt';
          const options = {
            formatterOptions: {
              delimiter: splitter,
              quote: false,
            },
          };
          worksheet.spliceRows(0, 1);
          workbook.csv.writeBuffer(options).then(data => {
            const blob = new Blob([data], {type: this.tsvType});
            FileSaver.saveAs(blob, fileName);
          });
        }
      }
    );
    this.subscription.add(subscription6$);
  }

  // Dimension data table filter
  getDimensionsToFilter() {
    const that = this;
    if ($('#table_Dimensions').length > 0) {
      $('#table_categoryField').remove();
      $('#dim_categ_elem').empty();
    }
    $('#dim_categ_elem').append('<table id="table_Dimensions" class="table table-bordered table-striped js-basic-example dataTable">   <thead>\n' +
      '<tr>' +
      '<th>id</th>' +
      '<th style="text-align: center">dim</th>' +
      '<th>show</th>' +
      '</tr>' +
      '</thead></table>');
    var table = $('#table_Dimensions').DataTable({
      bFilter: true,
      paging: false,
      ordering: true,
      info: false,
      destroy: true,
      processing: true,
      keys: {
        columns: ':first-child'
      },
      columnDefs: [
        {visible: false, targets: [2]}
      ],
      rowCallback(row, data) {
        $('td:eq(0)', row).html('<a class="DIMZoom" data-id="' + data.nameDim + '" onmouseover="$(this).css({\'color\':\'#3f86ed\', \'text-decoration\': \'underline\', \'cursor\': \'pointer\' });" onmouseout="$(this).css({\'color\':\'#000000\', \'text-decoration\': \'none\'});">' + data.nameDim + '</a>');
        $('td:eq(1)', row).html('<a class="DIMShow" data-idDim="' + data.idDim + '" onmouseover="$(this).css({\'color\':\'#106196\', \'cursor\': \'pointer\' });" onmouseout="$(this).css({\'color\':\'#2199e8\', \'text-decoration\': \'none\'});"><i style="color: #2199e8" class="fas fa-plus-circle"></i></a>');
        $('td:eq(1)', row).css('text-align', 'center');
      },
      ajax: {
        url: Shared.API_URL + '/api/drm-definition/getListDimensionsToDataFilter',
        contentType: 'application/json',
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      columns: [
          { data: 'nameDim', title: this.lang ? 'Dimensions' : 'Dimensions' },
          { data: 'nameDim', title: this.lang ? 'Details' : 'Détails' },
          { data: 'idDim', title: this.lang ? 'ID' : 'Identifiant' }
      
      ],
      initComplete(settings, json) {
        // show fleche en attendant la fin de la récupération des données du datatable
        $('#toggleShowList').show();
        $('.DIMZoom').click(function () {
          var nameDim = $(this).attr('data-id');
          // nameDim = $.escapeSelector(nameDim);
          document.getElementById(nameDim).dispatchEvent(new Event('click'));
        });
        // Add event listener for opening and closing details
        $('#table_Dimensions tbody').on('click', 'a.DIMShow', function (event) {
          var idDim = $(this).attr('data-idDim');
          const tr = $(this).closest('tr');
          const row = $('#table_Dimensions').DataTable().row(tr);
          const rowData = row.data();
          $('.rfshown').next('tr').hide();
          const indexRow = row.index();
          if (row.child.isShown()) {
            event.target.parentNode.innerHTML = '<i style="color: #2199e8" class="fas fa-plus-circle"></i>';
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('rfshown');
          } else {
            event.target.parentNode.innerHTML = '<i style="color: #2199e8" class="fas fa-minus-circle"></i>';
            tr.addClass('rfshown');
            if ($('#table_categorys' + idDim).length) {
              $('#table_categorys' + idDim).remove();
            }
            // Open this row
            row.child('<table id ="table_categorys' + idDim + '" cellpadding="5" cellspacing="0" border="0"></table>').show();
            $('#table_categorys' + idDim).DataTable({
              // destroy: true,
              columnDefs: [
                {visible: false, targets: [2]}
              ],
              bFilter: false,
              paging: false,
              ordering: true,
              info: false,
              destroy: true,
              processing: true,
              rowCallback(row1, data) {
                $('td:eq(0)', row1).html('<a class="CATEGZoom" data-id="' + data.idCateg + '" onmouseover="$(this).css({\'color\':\'blue\', \'text-decoration\': \'underline\', \'cursor\': \'pointer\' });" onmouseout="$(this).css({\'color\':\'#000000\', \'text-decoration\': \'none\'});">' + data.nameCateg + '</a>');
                $('td:eq(1)', row1).html('<a class="CATEGShow" data-nameCateg="' + data.nameCateg + '" onmouseover="$(this).css({\'color\':\'#106196\', \'cursor\': \'pointer\' });" onmouseout="$(this).css({\'color\':\'#2199e8\', \'text-decoration\': \'none\'});"><i style="color: #2199e8" class="fas fa-plus-circle"></i></a>');
                $('td:eq(1)', row1).css('text-align', 'center');
              },
              ajax: {
                url: Shared.API_URL + '/api/drm-definition/getListCategorysToDataFilter/' + idDim,
                contentType: 'application/json',
                headers: {
                  Accept: 'Application/json',
                  Authorization: 'Bearer ' + getCookieValue('tokendrd')
                },
              },
              columns: [
                { data: 'nameCateg', title: this.lang ? 'Categorys' : 'Catégories' },
                { data: 'nameCateg', title: this.lang ? 'Details' : 'Détails' },
                { data: 'idCateg', title: this.lang ? 'ID' : 'Identifiant' }
              ],
              initComplete(settings, json) {
                $('.CATEGZoom').click(function () {
                  var nameCateg = $(this).attr('data-id');
                  document.getElementById(nameCateg).dispatchEvent(new Event('click'));
                });
                // Add event listener for opening and closing details
                $('#table_categorys' + idDim + ' tbody').on('click', 'a.CATEGShow', function (event) {
                  var nameCateg = $(this).attr('data-nameCateg');
                  const tr = $(this).closest('tr');
                  const row = $('#table_categorys' + idDim).DataTable().row(tr);

                  const rowData = row.data();
                  $('.rfisshown').next('tr').hide();
                  const indexRow = row.index();
                  if (row.child.isShown()) {
                    event.target.parentNode.innerHTML = '<i style="color: #2199e8" class="fas fa-plus-circle"></i>';
                    // This row is already open - close it
                    row.child.hide();
                    tr.removeClass('rfisshown');
                  } else {
                    event.target.parentNode.innerHTML = '<i style="color: #2199e8" class="fas fa-minus-circle"></i>';
                    tr.addClass('rfisshown');
                    if ($('#table_definition' + idDim).length) {
                      $('#table_definition' + idDim).remove();
                    }
                    // Open this row
                    row.child('<table id ="table_definition' + idDim + '" cellpadding="5" cellspacing="0" border="0" style="text-align: center"></table>').show();
                    $('#table_definition' + idDim).DataTable({
                      // destroy: true,
                      columnDefs: [
                        {visible: false, targets: [1]}
                      ],
                      bFilter: false,
                      paging: false,
                      ordering: true,
                      info: false,
                      destroy: true,
                      processing: true,
                      rowCallback(row2, data) {
                        $('td:eq(0)', row2).html('<a class="DEFZoom" data-id="' + data.idDef + '" data-nameCateg="' + data.idCateg + '" onmouseover="$(this).css({\'color\':\'#3f86ed\', \'text-decoration\': \'underline\', \'cursor\': \'pointer\' });" onmouseout="$(this).css({\'color\':\'#000000\', \'text-decoration\': \'none\'});">' + data.nameDef + '</a>');
                        $('td:eq(0)', row2).css('text-align', 'center');
                      },
                      ajax: {
                        url: Shared.API_URL + '/api/drm-definition/getListDefinitionsToDataFilter/' + idDim + '/' + nameCateg,
                        contentType: 'application/json',
                        headers: {
                          Accept: 'Application/json',
                          Authorization: 'Bearer ' + getCookieValue('tokendrd')
                        },
                      },
                      columns: [
                        { data: 'nameDef', title: this.lang ? 'Definitions' : 'Définitions' },
                        {data: 'idDef', title: ''}
                      ],
                      initComplete(settings, json) {
                        $('.DEFZoom').click(function () {
                          var nameCateg = $(this).attr('data-nameCateg');
                          document.getElementById(nameCateg).dispatchEvent(new Event('click'));
                          var nameDef = $(this).attr('data-id');
                          nameDef = $.escapeSelector(nameDef);
                          $('.pulseDefinition').toggleClass('pulseDefinition');
                          $('[id =\'' + nameDef + '\']').toggleClass('pulseDefinition');
                          setTimeout(function (obj) {
                            obj = nameDef;
                            $('[id =\'' + nameDef + '\']').removeClass('pulseDefinition');
                          }, 10000);
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  }
  ///////////////////// synchronization ClassficationDefinition and ObjectModelClassif //////////////////
  // check compliance not exist in definition
  onCheckNotExistComplianceInDefinition() {
    this.dataComplianceAfterChecked = [];
    this.listAdCompliancepreCheck = [];
    let subscription8$ = this.definitionsevice.checkNotExistComplianceInDefinition().subscribe(
      result => {
        //@ts-ignore
        this.dataComplianceAfterChecked = result.data;
        //@ts-ignore
        result.data.map(item => {
          this.listAdCompliancepreCheck.push({
            item
          });
        });
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription8$);
  }
  // check compliance not exist in definition => show in DataTable Model
  showModalNotExistComplianceInDefinition() {
    this.listAdComplianceCheck = [];
    const that = this;
    $('#ListCompliances').empty();
    if ($('#Compliances').length > 0) {
      $('#Compliances').remove();
    }
    $('#ListCompliances').append('<table id="Compliances" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
      '<tr>' +
      '<th>Category</th>' +
      '<th>Compliance</th>' +
      '<th id="checkType">Type</th>' +
      '<th id="checkComp">' +
      ' <label style="margin-bottom: 0; margin-right: -16px;">' +
      '  <input type="checkbox" id="check_compliance_all" value="all"/>' +
      '  <span></span>' +
      ' </label>' +
      '</th>' +
      '</tr>' +
      '</thead></table>');

    $('#Compliances').DataTable({
      processing: true,
      destroy: true,
      pageLength: 5,
      // ajax: {
      //   url: Shared.API_URL + '/api/drm-definition/checkNotExistComplianceInDefinition',
      //   contentType: 'application/json',
      //   headers: {
      //     Accept: 'Application/json',
      //     Authorization: 'Bearer ' + getCookieValue('tokendrd')
      //   },
      // },
      data: that.dataComplianceAfterChecked,
      columns: [
        { data: 'category', title: this.lang ? 'Category' : 'Catégorie' },
        { data: 'name', title: this.lang ? 'Name' : 'Nom' },
        {
          data:   'name',
          render: function ( data, type, row ) {
            return '<th width="34%">' +
              '<div class="switch switch_glb">' +
              '<label class="bubbleswitch">Mapping' +
              '<input type="checkbox" id=\'typeSwitch' + data + '\' checked>' +
              '<span class="lever switch-col-cyan"></span>Usage Risk' +
              '</label>' +
              '</div>' +
              '</th>';
          }
        },
        {
          data:   'name',
          render: function ( data, type, row ) {
            return '<label style=\'margin-bottom: 0; margin-right: -16px;\'>' +
              '                    <input type=\'checkbox\' class="editor-active" id=\'' + data + '\'/>' +
              '                    <span></span>' +
              '                  </label>';
          }
        }
      ],
      order: [[0, 'asc']],
      rowCallback(row, data) {
        $('td:eq(0)', row).html('<label id=\'category_' + data.name + '\'>' + data.category + '</label>');
      },
      initComplete(settings, json) {
        const table = this.api();
        // Handle click on "Select all" control
        $('#check_compliance_all').on('click', function() {
          that.listAdComplianceCheck = [];
          if ($('#check_compliance_all').prop('checked')) {
            that.chechAllCompliance = true;
            // that.listAdComplianceCheck = that.listAdCompliancepreCheck;
            that.listAdCompliancepreCheck.map(item => {
                that.listAdComplianceCheck.push(item.item);
            });
            console.log(that.listAdComplianceCheck);
          } else {
            that.listAdComplianceCheck = [];
              that.chechAllCompliance = false;
          }
          // Get all rows with search applied
          var rows = table.rows({ search: 'applied' }).nodes();
          // Check/uncheck checkboxes for all rows in the table
          $('input[type="checkbox"]', rows).prop('checked', this.checked);

          if (that.listAdComplianceCheck.length > 0) {
            $('#compliance_modal_submit').prop( 'disabled', false );
          } else {
            $('#compliance_modal_submit').prop( 'disabled', true );
          }
        });
        // Handle click on checkbox to set state of "Select all" control
        $('#Compliances tbody').on('change', 'input[type="checkbox"]', function(){
          // If checkbox is not checked
          if (!this.checked) {
            that.chechAllCompliance = false;
            var el = $('#check_model_all').get(0);
            // If "Select all" control is checked and has 'indeterminate' property
            if (el && el.checked && ('indeterminate' in el)) {
              // Set visual state of "Select all" control
              // as 'indeterminate'
              el.indeterminate = true;
            }
          }
          if (that.listAdComplianceCheck.length > 0) {
            $('#compliance_modal_submit').prop( 'disabled', false );
          } else {
            $('#compliance_modal_submit').prop( 'disabled', true );
          }
        });
        $('#Compliances').on( 'change', 'input.editor-active', function (event) {
          that.chechAllCompliance = false;
          if ($(event.target).prop('checked')) {
            // check what's type selected
            if ($('#typeSwitch' + $(event.target).attr('id')).prop('checked')) {
              that.listAdComplianceCheck.push({
                name: $(event.target).attr('id'),
                type: 'Usage Risk',
                category: $('#category_' + $(event.target).attr('id')).html()
              });
            } else {
              that.listAdComplianceCheck.push({
                name: $(event.target).attr('id'),
                type: 'Mapping',
                category: $('#category_' + $(event.target).attr('id')).html()
              });
            }
            $('#typeSwitch' + $(event.target).attr('id')).prop( 'disabled', true );
          } else {
            $('#typeSwitch' + $(event.target).attr('id')).prop( 'disabled', false );
            // that.listAdComplianceCheck.splice(that.listAdComplianceCheck.indexOf(name => $(event.target).attr('id')), 1);
            that.listAdComplianceCheck = that.listAdComplianceCheck.filter(function(x) {
              return x.name  !== $(event.target).attr('id');
            });
          }
          if (that.listAdComplianceCheck.length > 0) {
            $('#compliance_modal_submit').prop( 'disabled', false );
          } else {
            $('#compliance_modal_submit').prop( 'disabled', true );
          }
        } );
        if (that.listAdComplianceCheck.length > 0) {
          $('#compliance_modal_submit').prop( 'disabled', false );
        } else {
          $('#compliance_modal_submit').prop( 'disabled', true );
        }
      }
    });

    $('#listComplianceNotInDefinitions').modal('show');
    $('#Compliances').css('width', 'inherit');
    $('#checkComp').css('width', '10%');
    $('#checkType').css('width', '34%');
  }
  // submit add new Definition (compliance)
  onSubmitAddSelectedComplianceInDefinition() {
      addLoader(this.lang ==='en' ? 'UPDATE PLZ&#160;&#160;WAIT . . .' : 'MODIFICATION VEUILLEZ&#160;&#160;PATIENTEZ . . .');
    $('#listComplianceNotInDefinitions').modal('hide');
    console.log(this.listAdComplianceCheck);
    let subscription9$ = this.definitionsevice.submitAddSelectedComplianceInDefinition(this.listAdComplianceCheck).subscribe(
      data => {
        if (data = 'saved') {
          this.onCheckNotExistComplianceInDefinition();
          this.onGetCheckExistComplianceInDefinition();
          this.onGetListDefinitions();
          this.flagDefinition = false;
        }
      },
      err => {
          removeLoader();
        console.error(err);
      }
    );
    this.subscription.add(subscription9$);
  }
  // get compliance (definition name) not synchnronized
  onGetCheckExistComplianceInDefinition() {
    this.listSyncDataCompDef = [];
    //// start disable button submit modal ////
    this.listCheckedComplInModal = [];
    $('#comp_def_synch_submit').prop( 'disabled', true );
    //// end disable button submit modal ////
    let subscription10$ = this.definitionsevice.getCheckExistComplianceInDefinition().subscribe(
      result => {
        //@ts-ignore
        result.map(item => {
          if (item.allDefSynchro == false) {
            this.listSyncDataCompDef.push(item);
          }
        });
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription10$);
  }
  showModalCheckExistComplianceInDefinition() {
    $('#listComplianceInDefinitions').modal('show');
  }
  // set list selected compliances for add to definitions
  checkedComplianceInModal(typeData, event) {
    if (event.target.checked) {
      this.listCheckedComplInModal.push({
        name: event.target.value,
        type: typeData,
        category: 'Object Model'
      });
      if (this.listCheckedComplInModal.length > 0) {
        $('#comp_def_synch_submit').prop( 'disabled', false );
      } else {
        $('#comp_def_synch_submit').prop( 'disabled', true );
      }
    } else {
      this.listCheckedComplInModal = this.listCheckedComplInModal.filter(function(x) {
        return x.name  !== event.target.value;
      });
      if (this.listCheckedComplInModal.length > 0) {
        $('#comp_def_synch_submit').prop( 'disabled', false );
      } else {
        $('#comp_def_synch_submit').prop( 'disabled', true );
      }
    }
  }
  // save new versions of definitions by selected compliances
  onRemoveDefinitionBeforeSynchro() {
    $('#listComplianceInDefinitions').modal('hide');
    let subscription10$ = this.definitionsevice.removeDefinitionBeforeSynchro(this.listCheckedComplInModal).subscribe(
      result => {
        if (result == 'saved') {
          this.onCheckNotExistComplianceInDefinition();
          this.onGetCheckExistComplianceInDefinition();
          this.onGetListDefinitions();
          this.flagDefinition = false;
        }
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription10$);
  }
  goToProject() {
    if (this.definitionBridge.componentId != '' && this.definitionBridge.component != '') {
      let cryptProject = this.cryptoService.set(this.definitionBridge.componentId);
      cryptProject = cryptProject.replace(/\//g, '__________');
      let cryptComponent = this.cryptoService.set('classificationDefinition');
      cryptComponent = cryptComponent.replace(/\//g, '__________');
      let cryptComponentId = this.cryptoService.set(this.id);
      cryptComponentId = cryptComponentId.replace(/\//g, '__________');
      const url = 'data-governance/project/' + cryptProject + '/' + cryptComponentId + '/' + cryptComponent;
      this.router.navigateByUrl('/RefreshComponent', {skipLocationChange: true}).then(() => {
        this.router.navigate([url]);
      });
    }
  }
}
