export class archCand{
    SYSTNAPS_ARCHID:number;
    NAME_CAND:string;
    COUNT_CSV:number;
    COUNT_BAD:number;
    COUNT_LOAD:number;
    OK_CSV_LOAD:string;
    TIME_LOAD_CSV:number;
    base: string;
    constructor(SYSTNAPS_ARCHID,NAME_CAND,COUNT_CSV,COUNT_BAD,COUNT_LOAD,OK_CSV_LOAD,TIME_LOAD_CSV,base){
        this.SYSTNAPS_ARCHID = SYSTNAPS_ARCHID;
        this.NAME_CAND = NAME_CAND;
        this.COUNT_CSV = COUNT_CSV;
        this.COUNT_BAD = COUNT_BAD;
        this.COUNT_LOAD = COUNT_LOAD;
        this.OK_CSV_LOAD = OK_CSV_LOAD;
        this.TIME_LOAD_CSV = TIME_LOAD_CSV;
        this.base = base;
    }
}
