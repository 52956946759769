import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-security',
  templateUrl: './no-security.component.html',
  styleUrls: ['./no-security.component.sass']
})
export class NoSecurityComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
