import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class GlossaireService {

    constructor(private http: HttpClient) { }
    getOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/x-www-form-urlencoded',
                Authorization: 'Bearer ' + getCookieValue('tokendrd')
            })
        };
    }
    getGlossaries(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/getGlossaries' , this.getOptions());
    }
    getGlossariesInfos(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/getGlossariesInformations/' + localStorage.getItem('userId') , this.getOptions());
    }
    getDictWithCriticity(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/getDictWithCriticity' , this.getOptions());
    }
    getGlossariesToExport(d): Observable<any> {
        const data = 'data=' + JSON.stringify(d);
        return this.http.post<any>(Shared.API_URL + '/api/glossary/getGlossariesToExport/' + localStorage.getItem('userId'), data, this.getOptions());
    }
    // addGlossary(d): Observable<any> {
    //     const data = 'data=' + d;
    //     return this.http.post(Shared.API_URL + '/api/glossary/addGlossary/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), data, this.getOptions());
    // }
    // updateGlossary(d): Observable<any> {
    //     const data = 'data=' + JSON.stringify(d);
    //     return this.http.post(Shared.API_URL + '/api/glossary/updateGlossary/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), data, this.getOptions());
    // }
    deleteGlossary(id): Observable<any> {
        return this.http.delete(Shared.API_URL + '/api/glossary/deleteGlossary/' + localStorage.getItem('userId')  + '/' + id, this.getOptions());
    }
    getEntities(): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/business_term/getEntities' , this.getOptions());
    }

    checkGlossaryName(name: string, id: string): Observable<any>  {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/checkGlossaryName/' + name + '/' + id , this.getOptions());
    }

    importGlosFOFromExcel(d): Observable<any> {
        const data = 'data=' + JSON.stringify(d);
        return this.http.post(Shared.API_URL + '/api/glossary/importGlosFOFromExcel/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), data, this.getOptions());
    }
    checkFunctionalObjectName(name: string, action: string): Observable<any>  {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/checkFunctionalObjectName/' + name + '/' + action , this.getOptions());
    }
     /////////////////////////// functionalObject/////////////////////////////////////////////////
    // getFunctionalObjects(id: string): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/functional/getFunctionalObjects/' + localStorage.getItem('userId') , this.getOptions());
    // }
    addNewFunctionalObjectToGlos(d): Observable<any> {
        return this.http.post(Shared.API_URL + '/api/functional/addNewFunctionalObjectToGlos/' + localStorage.getItem('userId'), d, this.getOptions());
    }
    updateFunctionalObject(d): Observable<any> {
        return this.http.put(Shared.API_URL + '/api/functional/updateFunctionalObject/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), d, this.getOptions());
    }
    deleteFunctionalObject(id): Observable<any> {
        return this.http.delete(Shared.API_URL + '/api/glossary/deleteFunctionalObject/' + localStorage.getItem('userId')  + '/' + id, this.getOptions());
    }
    getTerms(): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/business_term/getTerms/' + localStorage.getItem('userId'), this.getOptions());
    }
    getObjectsLastVersion(v: any, idF: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/business_term/getObjectsLastVersion/' + v + '/' + idF + '/' + localStorage.getItem('userId'), this.getOptions());
    }
    getTermsByObjectsLastVersion(v: any, idF: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/business_term/getTermsByObjectsLastVersion/' + v + '/' + idF + '/' + localStorage.getItem('userId'), this.getOptions());
    }
    // getFunctionalInfosByVersion(idFunctional: string, version: string): Observable<any> {
    //     return this.http.get<any>(Shared.API_URL + '/api/functional/getFunctionalByVersion/' + idFunctional + '/' + version , this.getOptions());
    // }
    validVersionFunctionObject(idFunctional: string, version: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/functional/validVersionFunctionObject/' + idFunctional + '/' + version + '/' + getCookieValue('username'), this.getOptions());
    }
    getLastValidVersionFO(idFunctional: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/functional/getLastValidVersionFO/' + idFunctional, this.getOptions());
    }
    getArchiveValidVersionFO(idFunctional: string, version: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/functional/getArchiveValidVersionFO/' + idFunctional + '/' + version , this.getOptions());
    }
    getRecentVersionForUpdate(idObj: string, versionObj: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/business_term/getRecentVersionForUpdate/' + idObj + '/' + versionObj , this.getOptions());
    }
    updateBOsetLatestVersion(data): Observable<any> {
        const d = 'data=' + JSON.stringify(data);
        return this.http.post<any>(Shared.API_URL + '/api/business_term/updateBOsetLatestVersion', d, this.getOptions());
    }
    //////////////////////// COMPARAISON ///////////////////////////////////////////////
    getCompareAddUpdateBOTinFO(idFunctional: string, startVersion: number, targetVersion: number): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/functional/getCompareAddUpdateBOTinFO/' + idFunctional + '/' + startVersion + '/' + targetVersion, this.getOptions());
    }
  checkMapping(idFunctional: string, startVersion: number): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional/checkMapping/' + idFunctional + '/' + startVersion , this.getOptions());
  }
  validateFunctionalMapOldMapping(idFunctional: string, version: number): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/functional/validateFunctionalMapOldMapping/' + idFunctional + '/' + version , this.getOptions());
  }
    //////////////////////// Export au format Holding /////////////////
    // getFunctionalMappingDataToExcel(data): Observable<any> {
    //     const d = 'data=' + JSON.stringify(data);
    //     return this.http.post(Shared.API_URL + '/api/functionalObject/getFunctionalMappingDataToExcel', d, this.getOptions());
    // }
  ///////////////////////////////// Manual add Update remove Glossaries, Functional Objects, MG, BO, BT ///////////////////////////////////////////////
    addGlossaryManually(d): Observable<any> {
        const data = 'data=' + d;
        return this.http.post(Shared.API_URL + '/api/glossary/addGlossaryManually/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), data, this.getOptions());
    }
    getTemporarilyGlossaries(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/getTemporarilyGlossaries/' + localStorage.getItem('userId') , this.getOptions());
    }
    deleteTemporarilyGlossary(name): Observable<any> {
        return this.http.delete(Shared.API_URL + '/api/glossary/deleteTemporarilyGlossary/' + name, this.getOptions());
    }
    getTemporarilyFunctionalPerGlos(): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/getTemporarilyFunctionalPerGlos' , this.getOptions());
    }
    deleteTemporarilyFunctionalPerGlos(name): Observable<any> {
        return this.http.delete(Shared.API_URL + '/api/glossary/deleteTemporarilyFunctionalPerGlos/' + name, this.getOptions());
    }
    importGlosFOFromExcelManually(d): Observable<any> {
        const data = 'data=' + JSON.stringify(d);
        return this.http.post(Shared.API_URL + '/api/glossary/importGlosFOFromExcelManually/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), data, this.getOptions());
    }
    checkFunctionalObjectNameInGlos(name: string, idGlos: string, action: string): Observable<any>  {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/checkFunctionalObjectNameInGlos/' + name + '/' + idGlos + '/' + action , this.getOptions());
    }
    addFunctionalInGlossaryManually(d): Observable<any> {
        const data = 'data=' + d;
        return this.http.post(Shared.API_URL + '/api/glossary/addFunctionalInGlossaryManually/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), data, this.getOptions());
    }
    getEditedGlosWithNewAddedFOtoExportModele(idGlos: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/getEditedGlosWithNewAddedFOtoExportModele/' + idGlos , this.getOptions());
    }

    checkModuleGroupNameInFunctional(name: string, idFunc: string, action: string): Observable<any>  {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/checkModuleGroupNameInFunctional/' + name + '/' + idFunc + '/' + action , this.getOptions());
    }
    addGroupInFunctionalManually(d): Observable<any> {
        const data = 'data=' + d;
        return this.http.post(Shared.API_URL + '/api/glossary/addGroupInFunctionalManually/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), data, this.getOptions());
    }
    checkBusinessTermNameInParent(name: string, idParent, idFunc, type, action: string): Observable<any>  {
        return this.http.get<any>(Shared.API_URL + '/api/glossary/checkBusinessTermNameInParent/' + name + '/' + idParent + '/' + idFunc + '/' + type + '/' + action , this.getOptions());
    }
    addObjectInGroupManually(d): Observable<any> {
        const data = 'data=' + d;
        return this.http.post(Shared.API_URL + '/api/glossary/addObjectInGroupManually/' + localStorage.getItem('userId') + '/' + getCookieValue('username'), data, this.getOptions());
    }
  ///////////////////////////////// Manual add Update remove Glossaries, Functional Objects, MG, BO, BT ///////////////////////////////////////////////
}
