export class holderJson{
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  stack: string;
  port: string;
  userId: string;
  user: boolean;
  selectedRole: string;
  userName: string;
  constructor(id='',firstName: string, lastName: string, email: string, stack: string, port, userId, user: boolean, selectedRole, userName) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.stack = stack;
    this.id= id;
    this.port = port;
    this.userId = userId;
    this.user = user;
    this.selectedRole = selectedRole;
    this.userName = userName;
  }

}
