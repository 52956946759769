import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Token} from '../models/token';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Profile} from '../models/profile';
import {CookieService} from 'ngx-cookie-service';
import {getCookieValue, Shared} from '../shared';
import {environment} from "../../environments/environment.onprem";
declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
    Options = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            // 'Access-Control-Allow-Origin': 'http://localhost:4200', 'http://localhodt',
            Authorization: 'Bearer ' + getCookieValue('tokendrd')
        })
    };

    constructor(private http: HttpClient, private  router: Router, public jwtHelper: JwtHelperService,
                private cookies: CookieService) {
    }

    getToken(data: any): Observable<Token> {
        return this.http.post<Token>(Shared.API_URL + '/login_check', data, this.Options);
    }
    getTipsMain(username): Observable<any> {
      return this.http.get<any>(Shared.API_URL + '/api/analytics/getTipsMain/' + username, this.getOptions());
    }
    getUserProfile(username): Observable<Profile> {
        return this.http.post<Profile>(Shared.API_URL
          + '/api/users/getUserProfile', username, this.getOptions());
    }

    signOutNew() {
        localStorage.clear();
        sessionStorage.clear();
       // localStorage.removeItem('tokendrd');
        document.cookie = 'username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'tokendrd=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        localStorage.setItem('lang', 'fr');
        if (environment.envVar.PORTAL == 'true') {
            window.location.href = environment.envVar.PORTAL_URL;
        } else {
            this.router.navigate(['#/auth']);
        }
        // return this.http.post(Shared.API_URL + '/logout', this.Options);
    }


    public isAuthenticated(): boolean {
        const token = getCookieValue('tokendrd');
        // Check whether the token is expired and return
        // true or false
        return !this.jwtHelper.isTokenExpired(token);
    }

    resetPWD(data): Observable<[]> {
        let form_data = new FormData();
        form_data.append('email', data);
        // for (let key in data) {
        //     form_data.append(key, data[key]);
        // }
        return this.http.post<[]>(Shared.API_URL + '/reset_password', form_data);
    }
    changePWD(data1, data2): Observable<[]> {
        let form_data = new FormData();
        form_data.append('_token', data1);
        form_data.append('password', data2);
        console.log(form_data);
        return this.http.post<[]>(Shared.API_URL + '/change_password', form_data);
    }
    showNotification(colorName, text, placementFrom, placementAlign, animateEnter, animateExit) {
        if (colorName === null || colorName === '') { colorName = 'bg-black'; }
        if (text === null || text === '') { text = 'Turning standard Bootstrap alerts'; }
        if (animateEnter === null || animateEnter === '') { animateEnter = 'animated fadeInDown'; }
        if (animateExit === null || animateExit === '') { animateExit = 'animated fadeOutUp'; }
        var allowDismiss = true;

        $.notify({
                message: text
            },
            {
                type: colorName,
                allow_dismiss: allowDismiss,
                newest_on_top: true,
                timer: 1000,
                placement: {
                    from: placementFrom,
                    align: placementAlign
                },
                animate: {
                    enter: animateEnter,
                    exit: animateExit
                },
                template: '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} '
                    + (allowDismiss ? 'p-r-35' : '') + '" role="alert">' +
                    '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                    '<span data-notify="icon"></span> ' +
                    '<span data-notify="title">{1}</span> ' +
                    '<span data-notify="message">{2}</span>' +
                    '<div class="progress" data-notify="progressbar">' +
                    '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0"' +
                    'aria-valuemax="100" style="width: 0%;"></div>' +
                    '</div>' +
                    '<a href="{3}" target="{4}" data-notify="url"></a>' +
                    '</div>'
            });
    }
    getOptions(){
      return {
        headers: new HttpHeaders( {
          // 'Cache-Control': 'no-cache',
          // 'Pragma': 'no-cache',
          // 'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        })
      };
    }
    getEncodedParams(params): Observable<any> {
        const data = "data="+JSON.stringify(params);
            return this.http.post(Shared.API_URL + '/api/application/encodedParams',data,this.getOptions());
       }
    /********remove collection reportDetail by user *****/
    removeReportDetailCollectionByUser(id): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/report-detail/removeReportDetailCollectionByUser/'+ id,this.getOptions());
  }
  /********End Filtre Change collection name reportDetail *****/
}
