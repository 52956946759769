import {RouterModule, Routes} from '@angular/router';

import {NgModule} from '@angular/core';
import {DataLineageComponent} from './data-lineage/data-lineage.component';
import {GlobalLineageComponent} from './global-lineage/global-lineage.component';
import {MxLineageComponent} from "./mx-lineage/mx-lineage.component";



const routes: Routes = [
  {
    path : '',
    redirectTo : 'data-lineage',
    pathMatch : 'full'
  },
  {
    path: 'data-lineage/:id/:name',
    component: MxLineageComponent
  },
  {
    path: 'data-lineage-list/:id/:source/:target/:sourceType/:targetType',
    component: GlobalLineageComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DataLineageRoutingModule {

}
