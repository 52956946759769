export class DataLifeCycleJson{
  appl: string;
  campains: any[];
  readCp = false;
  createCp = false;
  updateCp = false;
  deleteCp = false;

  constructor(appl: string, campains, readCp: boolean, createCp: boolean, updateCp: boolean, deleteCp: boolean) {
    this.appl = appl;
    this.campains = campains;
    this.readCp = readCp;
    this.createCp = createCp;
    this.updateCp = updateCp;
    this.deleteCp = deleteCp;
  }
}
