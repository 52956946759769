import {DataLifeCycleJson} from './DataLifeCycleJson';

export class LifeCycleJson {
  name: string;
  description: string;
  versionAdmin: boolean;
  dataLifeCycles: DataLifeCycleJson[];

  constructor(name: string, description: string, versionAdmin, dataLifeCycles: DataLifeCycleJson[]) {
    this.name = name;
    this.description = description;
    this.dataLifeCycles = dataLifeCycles;
    this.versionAdmin = versionAdmin;
  }
}
