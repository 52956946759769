import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainAnonymComponent} from "./main-anonym/main-anonym.component";


const routes: Routes = [
    {
        path: '',
        redirectTo: 'anonym-board',
        pathMatch: 'full'
    },
    {
        path: 'anonym-board',
        component: MainAnonymComponent
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AnonymBoardRoutingModule { }
