import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DdtmComponent } from './mainDdtm/ddtm.component';
import { ComparaisonComponent } from './comparaison/comparaison.component';
import { VizComponent } from './viz/viz.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'ddtm',
        pathMatch: 'full'
    },
    {
        path: 'ddtm',
        component: DdtmComponent
    },
    {
        path: 'compare',
        component: ComparaisonComponent
    },
      {
        path: 'viz',
        component: VizComponent
    },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DdtmRoutingModule { }
