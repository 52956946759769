import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DataLineageService} from '../../services/data-lineage/data-lineage.service';
import {CryptoService} from '../../crypto.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {formatDate} from '@angular/common';
import {DynamicScriptLoaderService} from '../../dynamic-script-loader-service.service';
import Swal from 'sweetalert2';
import {addLoader, getCookieValue, groupBy, refreshComponent, removeLoader} from '../../shared';
import {NodeItem} from '../NodeItem';
import {ExtraItem} from '../ExtraItem';
import * as d3 from 'd3';
import {RouterhistoryService} from '../../services/appService/routerhistory.service';
import {Subscription} from 'rxjs';
import {OrderPipe} from 'ngx-order-pipe';
import {TranslateService} from '@ngx-translate/core';
import * as pako from 'pako';
import * as Excel from "exceljs";

declare const $: any;
declare const CKEDITOR: any;
declare const LeaderLine: any;

@Component({
  selector: 'app-global-lineage',
  templateUrl: './global-lineage.component.html',
  styleUrls: ['./global-lineage.component.css']
})
export class GlobalLineageComponent implements OnInit, OnDestroy, AfterViewInit {
  lang: string;
  dataLineageFilter: any;
  pageSizeList = [5, 10 , 15 , 20 , 25 , 30 ];
  selectedPageSize = 5;
  dataLineageConfig: any;
  totalListData = 0;
  p = 1;
  sortedCollection: any[];
  flag = false;
  order = 'id';
  reverse = false;
  listDataLineage = [];
  createLn = false;
  updateLn = false;
  deleteLn = false;
  disableEdit = true;
  holderLoading = false;
  stackHolders = [];
  owner = '';
  lineName: any;
  lineEffective: any;
  enabled: any;
  description = '';
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    onOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  saveFrm: FormGroup;
  flatDesign = true;
  tabIndex = 10;
  content = null;
  edges = [];
  flagScroll = true;
  lineageLoad = true;
  modalSending = false;
  enableRedirect = false;
  entities = [];
  loadingEntity = true;
  ngEntityEdit = [];
  lineageBridge = {id: '', sourceComponent: '', targetComponent: '', sourceType: '', targetType: ''};
  marginTop = 330;
  marginLeft = 250;
  _openedSide = false;
  showcirclePack = true;
  firstInit = true;
  files: File[] = [];
  lineagaData = {data: [], edges: []};
  lineageData =  new Map();
  constructor(private fb: FormBuilder, private dataLineService: DataLineageService,
              private router: Router, private cryptoService: CryptoService,
              private dynamicScriptLoader: DynamicScriptLoaderService, private translate: TranslateService,
              private activeRoute: ActivatedRoute, private routerHistoryService: RouterhistoryService,) {
    this.dataLineageConfig = { id: 'serverLineage', itemsPerPage: this.selectedPageSize, currentPage: this.p, totalItems: this.totalListData };
    this.saveFrm = this.initChildForm();
  }

  ngOnInit(): void {
    this.activeRoute.paramMap.subscribe(params => {
      let id = params.get('id');
      id = id.replace(/__________/g, '/');
      if(id != 'y02M3Dp94wAJwc3nh6QypA%3D%3D') {
        id = this.cryptoService.get(id);
        if (id != null && id != '0') {
          this.lineageBridge.id = id;
        }
      }
      //  console.log(this.lineageBridge.id);
      let source = params.get('source');
      source = source.replace(/__________/g, '/');
      source = this.cryptoService.get(source);
      if (source != null && source != '0') {
        this.lineageBridge.sourceComponent = source;
      }
      let target = params.get('target');
      target = target.replace(/__________/g, '/');
      target = this.cryptoService.get(target);
      if (target != null && target != '0') {
        this.lineageBridge.targetComponent = target
      }
      let sourceType = params.get('sourceType');
      sourceType = sourceType.replace(/__________/g, '/');
      sourceType = this.cryptoService.get(sourceType);
      if (sourceType != null && sourceType != '0') {
        this.lineageBridge.sourceType = sourceType;
      }
      let targetType = params.get('targetType');
      targetType = targetType.replace(/__________/g, '/');
      targetType = this.cryptoService.get(targetType);
      if (targetType != null && targetType != '0') {
        this.lineageBridge.targetType = targetType;
      }
      if(this.lineageBridge.sourceType == 'project'){
        this.enableRedirect = true;
      }
      console.log(this.lineageBridge)
      this.legendCirclePack(1, 'legendGlobSizeRowLineage', [10, 20, 30], ['DataLineage', 'Category', 'Dimension'], ['grey', 'rgb(81,183,196)', 'rgb(116,215,202)']);
      this.packedDimensionByLineage();
    });
    this.getAllDataLineage();
    this.onGetHolders();
    this.getEntities();
    // this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngAfterViewInit(): void {
    setTimeout( () => {
      this.showTipsGovLineage();
    }, 1000);
  }
  showTipsGovLineage() {
    //  console.log('lineage');
    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsGovLineage');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      if ((localStorage.getItem('dontShowTipsGovLineage') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsGovLineage', 'true');
        setTimeout( () => {
          $('#tipsCompGovLineage').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        if ((localStorage.getItem('dontShowTipsGovLineage') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsGovLineage', 'true');
          $('#tipsCompGovLineage').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips data governace data role
  onUpdateTipsGovLineage() {
    let tipsGovLineage;
    if ($('#checked_show_govlineage').is(':checked')) {
      tipsGovLineage = false;
    } else {
      tipsGovLineage = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsGovLineage,
      dataMenu: 'Data Governance',
      dataSousmenu: 'Business-Glossary'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  private initChildForm() {
    return this.fb.group(
      {
        LineName: [
          null,
          Validators.compose([Validators.required])
        ],
        lineEffective: [
          new Date(),
          Validators.compose([Validators.required])
        ],
        enabledLine: [
          true,
          Validators.compose([Validators.required])
        ],
        owner: [
          null,
          Validators.compose([Validators.required])
        ],
        description: [
          null
        ],
        entity: [
          null,
          Validators.compose([Validators.required])
        ]
      }
    );
  }
  getPage() {
    this.dataLineageConfig.itemsPerPage = this.selectedPageSize;
    this.dataLineageConfig.currentPage = 1;
    this.dataLineageConfig.totalItems = this.listDataLineage.length;
  }

  changeEnabled(event: Event) {
    this.enabled = $(event.target).prop('checked');
  }
  onGetHolders() {
    this.holderLoading = true;
    this.dataLineService.getHolders().subscribe(
      data => {
        this.stackHolders = data.data;
        this.holderLoading = false;
      }
    );
  }
  groupByFn = (item) => item.stack.role;
  groupValueFn = (_: string, children: any[]) => ({ name: children[0].stack.role, total: children.length });
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  sortAlphaNum(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  addLineage() {
    const url = 'data-lineage/data-lineage/data-lineage/y02M3Dp94wAJwc3nh6QypA==';
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }

  showDetailData(row: any, event) {
    const id = row.id;
    if (this.edges.length > 0) {
      //  this.removeConnectors();
      this.edges = [];
      if(!$('#imageGlobalDataLine' + id).is(':empty')){
        $('#imageGlobalDataLine' + id).empty();
      }
    }
    // if($(window).innerWidth() < 916){
    //   this.marginLeft = 430;
    // } else {
    //   this.marginLeft = 250;
    // }
    const ul = $('#' + id).parent().parent();
    const li = $('#' + id).parent();
    // this.id = id;
    if (!this.flag) {
      $('#cirlePackLineage').css('display', 'none');
      $('#lineage_pagin_search').css('display', 'none');
      //   $(window).scroll(() => {this.updateConnectors(); });
      this.flag = true;
      $('#body' + id).slideDown();
      event.target.innerHTML = this.lang === 'en' ? 'Back' : 'Retour';
      ul.children().hide();
      li.show('slow');
      this.disableEdit = true;
      // ng model
      this.lineName = row.name;
      this.lineEffective = row.effective.date;
      this.enabled = row.enabled;
      this.owner = row.owner;
      this.ngEntityEdit = row.entity;
      const imgTab = row.imageData;
      if (imgTab != null) {
        const nameArr = imgTab.split(',');
        const restored = JSON.parse(pako.inflate(nameArr, { to: 'string' }));
        // console.log('<img alt src="' + restored + '">')
        $('#imageGlobalDataLine' + row.id).html( restored);
        //   $('#imageGlobalDataLine' + row.id).html( '<img alt src="' + restored + '">');
      }
      $('#imageGlobalDataLine' + row.id).children().eq(0).css({display: 'block', 'margin-left': 'auto', 'margin-right': 'auto'})
      // if (row.content != null) {
      //   this.content = atob(row.content);
      //   this.content = JSON.parse(this.content);
      //  // console.log(this.content);
      //   if($('#imageGlobalDataLine' + id).is(':empty')) {
      //     if(this.firstInit){
      //       window.scrollBy(5, 100);
      //       this.importNodes(id);
      //       this.firstInit = false;
      //     }else{
      //       this.importNodes(id);
      //     }
      //   }else{
      //     this.updateConnectors()
      //   }
      //
      // }
      this.flagScroll = true;
      $('#enabledLine_' + id).prop('checked', this.enabled);
    } else {
      $('#cirlePackLineage').css('display', 'block');
      $('#lineage_pagin_search').css('display', 'block');
      $(window).off('scroll');
      this.flagScroll = false;
      this.disableEdit = true;
      this.dataLineageFilter = null;
      event.target.innerHTML = this.lang === 'en' ? 'Show Details' : 'Montrer les détails';
      this.flag = false;
      $('#body' + id).slideUp();
      ul.children().show('slow');
    }
    $('#titleDataMap').css('display', 'block');
  }
  getAllDataLineage() {
    this.lineageLoad = true;
    this.dataLineService.getAllDataLineage().subscribe(
      d => {
        this.listDataLineage = d.data;
        this.createLn = d.rights.create;
        this.updateLn = d.rights.update;
        this.deleteLn = d.rights.delete;
        this.lineageLoad = false;
      },
      error => {console.error(error); },
      () => {
        if (this.lineageBridge.id != null && this.lineageBridge.id != '0'){
          this.dataLineageFilter = this.lineageBridge.id;
          setTimeout(() => {
            $( 'button[data-name=\'' +  this.lineageBridge.id + '\']' ).click();
          }, 1000);
        }
      }
    );
  }

  openDataLineage(row) {
    let id = this.cryptoService.set(row.id);
    id = id.replace(/\//g, '__________');
    let name = this.cryptoService.set(row.name);
    name = name.replace(/\//g, '__________');
    const url = 'data-lineage/data-lineage/' + id + '/' + name;
    // console.log(url);
    this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
      this.router.navigate([url]);
    });
  }

  cancelUpdateDataLineage(row) {
    this.disableEdit = true;
    this.lineName = row.name;
    $('#lineEffective_' + row.id).val(row.effective.date.toString().substr(0, 10));
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['LineDescription_' + row.id]) {
      CKEDITOR.instances['LineDescription_' + row.id].destroy(true);
    }
    this.owner = row.owner;
    $('#enabledLine_' + row.id).prop('checked', row.enabled);
    this.ngEntityEdit = row.entity;
    this.updateConnectors();
  }

  editDataLineage(row) {
    this.disableEdit = false;
    setTimeout(() => {this.startScriptUP(row.id); } , 200);
  }

  removeDataLineage(id: any) {
    let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
    Swal.fire({
      title: this.lang === 'en' ? 'DELETE this Data Lineage?' : 'SUPPRIMER cette lignée de données?',
      text: this.lang === 'en' ? 'You won\'t be able to revert this!' : 'Vous ne pourrez pas annuler cela!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: this.lang === 'en' ? 'Yes, Approved it!' : 'Oui, approuvez-le!',
      cancelButtonText: cancelButtonText
    }).then((result) => {
      if (result.value) {
        this.dataLineService.removeDataLineage(id, 'check').subscribe(
          d => {
            if (d.data == undefined) {
              if (d['success'] == 'this dataLineage is associated with some projects') {
                let cancelButtonText = this.lang === 'en' ? 'Cancel' : 'Annuler';
                Swal.fire({
                  title: this.lang === 'en' ? 'DataLineage is associated with some projects?' : 'DataLineage est associé à certains projets?',
                  text: this.lang === 'en' ? 'This dataLineage is associated with some projects!\nWould you like to delete it even from these projects?' : 'Cette lignée de données est associée à certains projets!\nVoulez-vous la supprimer même de ces projets?',
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: this.lang === 'en' ? 'Yes, proceed!' : 'Oui, procédez!',
                  cancelButtonText: cancelButtonText
                }).then((result) => {
                  if (result.value) {
                    this.dataLineService.removeDataLineage(id, 'forceDelete').subscribe(
                      d => {
                        Swal.fire({
                          title: this.lang === 'en' ? 'Success!' : 'Succès!',
                          text: this.lang === 'en' ? 'Deleted with success' : 'Supprimé avec succès',
                          icon: 'success',
                          confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
                        });
                        refreshComponent(this.router);
                      }
                    );
                  }
                });
              } else if (d['success'] == 'OK') {
                Swal.fire({
                  title: this.lang === 'en' ? 'Success!' : 'Succès!',
                  text: this.lang === 'en' ? 'Deleted with success' : 'Supprimé avec succès',
                  icon: 'success',
                  confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
                });
                refreshComponent(this.router);
              }
            } else {
              $('#listDimensionsDataLine').modal({backdrop: 'static', keyboard: false}, 'show');
              this.getDimensionTable(d.data);
            }
          },
          error => {console.log(error); }
        );
      }
    });
  }

  updateDataLineage(row: any) {
    const dataLine = {id: row.id, name: this.lineName, effective: this.lineEffective, enabled: this.enabled, owner: this.owner, createdBy: getCookieValue('username'), description: this.description, entity: this.ngEntityEdit};
    // console.log(dataLine);return;
    if (dataLine.name.length == 0) {
      Swal.fire({
        title: this.lang === 'en' ? 'Error!' : 'Erreur!',
        text: this.lang === 'en' ? 'You must provide a name for this DataLine' : 'Vous devez fournir un nom pour cette DataLine',
        icon: 'error',
        confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
      });
      return false;
    }
    if (dataLine.owner== null) {
      Swal.fire({
        title: this.lang === 'en' ? 'Error!' : 'Erreur!',
        text: this.lang === 'en' ? 'You must select an owner for this DataLine' : 'Vous devez sélectionner un propriétaire pour cette DataLine',
        icon: 'error',
        confirmButtonText: this.lang === 'en' ? 'Ok' : 'D\'accord'
      });
      return false;
    }
    /* if (dataLine.entity.length == 0) {
       Swal.fire({
         title: 'error!',
         text: 'You must provide at least one entity for this DataLine',
         icon: 'error',
         confirmButtonText: 'Ok'
       });
       return false;
     }*/
    addLoader(this.lang === 'en' ? 'Contacting Server ...' : 'Chargement ...');
    this.dataLineService.updateDataLineage(dataLine).subscribe(
      d => {
        this.Toast.fire({
          icon: 'success',
          title: this.lang === 'en' ? 'Updated successfully' : 'Mis à jour avec succès'
        });
        removeLoader();
        const currentUrl = 'data-lineage/data-lineage-list/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==';
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
        });
      }, err => {
        this.Toast.fire({
          icon: 'error',
          title: err
        });
        removeLoader();
      }
    );
  }
  async startScriptUP(id) {
    const that = this;
    // ckeditor
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      this.loadData(id);
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      $('#lineEffective_' + id).bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function(e, date) {
          that.lineEffective = formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en');
        });
    }).catch(error => console.error(error));
  }
  private loadData(id) {
    // definition editor
    if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances['LineDescription_' + id]) {
      CKEDITOR.instances['LineDescription_' + id].destroy(true);
    }
    const editorDescription = CKEDITOR.replace('LineDescription_' + id);
    // editorDescription.config.readOnly = true;
    // editorDescription.config.height = 60;
    editorDescription.on( 'change', evt => {
      this.description = evt.editor.getData();
    });
    this.updateConnectors();
  }
  addNodes(nodeItem: NodeItem, ido) {
    const id = nodeItem.id;
    const childId = 'childs_' + id;
    const headerId = 'header_' + id;
    let bgColor = '#000000';
    if (nodeItem.application != undefined) {
      bgColor = nodeItem.application.color;
    }
    if (nodeItem.customApplication != undefined) {
      bgColor = nodeItem.customApplication.color;
    }
    //  console.log(nodeType, nodeText);
    let objectsHtml = '';
    if (!nodeItem.extern) {
      if (nodeItem.catalogue) {
        nodeItem.data.map(obj => {
          if (obj.children != undefined) {
            objectsHtml += '<li class="list-group-item tooltipe"><span class="tooltiptextLeft" style="font-size: 20px"><i class="fas fa-server"></i>&nbsp;' + obj.model.name + '</span><i class="fas fa-circle connector start static object-line event-none" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line event-none" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>';
            if (obj.children.length > 0 ) {
              objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
                '<ul class="list-group list-group-flush text-black collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(obj.object.id, obj.children) + '</ul>';
            }
          } else {
            objectsHtml += '<li class="list-group-item tooltipe"><span class="tooltiptextLeft"  style="font-size: 20px"><i class="fas fa-server"></i>&nbsp;' + obj.model.name + '</span><i class="fas fa-circle connector start static object-line empty-object event-none" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line event-none" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ;
          }
        });
      } else {
        nodeItem.customs.map(obj => {
          if (obj.children != undefined ) {
            objectsHtml += '<li class="list-group-item tooltipe"><span class="tooltiptextLeft"  style="font-size: 20px"><i class="fas fa-server"></i>&nbsp;' + obj.model.name + '</span><i class="fas fa-circle connector start static object-line event-none" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line event-none" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>';
            if (obj.children.length > 0 ) {
              objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.name + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.name + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
                '<ul class="list-group list-group-flush text-black  collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(obj.object.id, obj.children) + '</ul>';
            }
          } else {
            objectsHtml += '<li class="list-group-item tooltipe"><span class="tooltiptextLeft"  style="font-size: 20px"><i class="fas fa-server"></i>&nbsp;' + obj.model.name + '</span><i class="fas fa-circle connector start static object-line empty-object event-none" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line event-none"  id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ;
          }
        });
      }
    } else {
      nodeItem.customs.map(obj => {
        if (obj.children != undefined ) {
          objectsHtml += '<li class="list-group-item tooltipe"><span class="tooltiptextLeft"  style="font-size: 20px"><i class="fas fa-server"></i>&nbsp;' + obj.model.name + '</span><i class="fas fa-circle connector start static object-line event-none" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object.name + '</b>&nbsp;<i class="fas fa-circle connector end object-line event-none" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>';
          if (obj.children.length > 0 ) {
            objectsHtml += '<li style="background-color: white;border-top:0;display:none" class="list-group-item event-none"><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: left;color:#31b7f9;cursor:pointer"></i><i class="fas fa-angle-double-down show-node-Item" data-object="' + obj.object.id + '" style="float: right;color:#31b7f9;cursor:pointer"></i></li>' +
              '<ul class="list-group list-group-flush text-black  collapse-lg" style="border-top: 1px solid #f3dcdc;">' + this.getTables(obj.object.id, obj.children) + '</ul>';
          }
        } else {
          objectsHtml += '<li class="list-group-item tooltipe"><span class="tooltiptextLeft"  style="font-size: 20px"><i class="fas fa-server"></i>&nbsp;' + obj.model.name + '</span><i class="fas fa-circle connector start static object-line empty-object event-none" id="start_' + obj.object.id + '" data-name="' + obj.object.name + '"></i>  &nbsp;<b style="text-transform: capitalize;"><i class="fas fa-vector-square"></i>&nbsp;' + obj.object + '</b>&nbsp;<i class="fas fa-circle connector end object-line event-none" id="end_' + obj.object.id + '" data-name="' + obj.object.name + '"></i></li>' ;
        }
      });
    }
    this.tabIndex++;
    const html = this.getNodeStyle() + bgColor + '" id="' + id + '" tabindex="' + this.tabIndex + '">\n' +
      '<span class="fa-stack" style="align-self: center;font-size: 1.2em;padding-top: 1.3px"><i class="fas fa-circle fa-stack-2x" style=";vertical-align: middle; color:' + bgColor + '"></i> ' + this.getImageFromType(nodeItem.icon, bgColor) + '</span>' +
      '<div class="card-header text-center text-white node-header"  style="background-color: rgb(60 172 196);border-top-left-radius: 23px;\n' +
      '    border-top-right-radius: 23px;text-transform: capitalize" id="' + headerId + '">' + nodeItem.text + '</div>\n' +
      // '      </div>\n' +
      '      <ul class="list-group list-group-flush text-black node-details flat-details" id="' + childId + '" >\n' + objectsHtml +
      '      </ul>\n' +
      '      <div class="card-body tools-line tools-line-flat" style="max-height:28px">\n' +
      '        <span class="remove-drag event-none" data-item="' + id + '"><i class="fas fa-times-circle edit-remove"></i></span>\n' +
      '        <span class="add-drag event-none" data-item="' + id + '"><i class="fas fa-pencil-alt edit-remove"></i></span>\n' +
      '        <span style="float:right">' + /*this.getApplicationTypeIcon(nodeItem.applicationType, nodeItem.customApplication) + '&nbsp;' +*/ this.getApplication(nodeItem) + '</span>\n' +
      '       <span class="tree-modal event-none">' + this.getTrigger(nodeItem.trigger) + '</span>\n' +
      '      </div>\n' +
      '      <div id="line-wrapper"></div>\n' +
      '    </div>';
    $('#imageGlobalDataLine' + ido).append(html);
    nodeItem.style.top += $('#imageGlobalDataLine' + ido).offset().top ;
    nodeItem.style.left -= this.marginLeft;
    // nodeItem.style.left += $('#' + id).offset().left();
    $('#' + id).offset(nodeItem.style);
  }
  getTables(obj, children) {
    let html = '';
    children.map(
      t => {
        // console.log(t);
        html += '<li class="list-group-item event-none" style="float: left;"><b style="color: blue;text-transform: capitalize;"><i class="fas fa-table"></i>&nbsp;' + t.table.name + '</b>&nbsp;</li>' +
          this.getFields(obj, t.fields);
      }
    );
    return html;
  }
  getFields(obj, fields) {
    let htm = '';
    fields.map(
      field => {
        htm += '<li class="list-group-item event-none"><i class="fas fa-circle connector start static field-line event-none" id="start_' + field.id + '" data-parent=" ' + obj + '" data-name="' + field.name + '"></i>  &nbsp; <span style="color: #4e4ebb;text-transform: capitalize"><i class="fas fa-receipt"></i>&nbsp;' + field.name + '</span>&nbsp;<i class="fas fa-circle connector end child-line event-none" id="end_' + field.id + '" data-parent=" ' + obj + '" data-name="' + field.name + '"></i></li>';
      }
    );
    return htm;
  }
  getApplicationTypeIcon(app, custom) {
    let icon = '';
    const type = app != null ? app.name : custom.name;
    switch (type) {
      case 'Desktop application': icon = '<i class="fas fa-desktop" title="System type ' + type + '"></i>'; break;
      case 'Web application': icon = '<i class="fab fa-chrome" title="System type ' + type + '"></i>'; break;
      case 'Mobile application': icon = '<i class="fas fa-mobile-alt" title="System type ' + type + '"></i>'; break;
      default: icon = '<i class="fas fa-info" title="System type ' + type + '"></i>'; break;
    }
    return icon;
  }
  getTrigger(trigger: string) {
    let htm = '';
    switch (trigger) {
      case 'Manual': htm = '<i class="far fa-hand-paper" title="Trigger ' + trigger + '"></i>&nbsp;'; break;
      case 'Semi-automatic': htm = '<i class="fab fa-asymmetrik" title="Trigger ' + trigger + '"></i>&nbsp;'; break;
      case 'Automatic': htm = '<i class="fab fa-autoprefixer" title="Trigger ' + trigger + '"></i>&nbsp;'; break;
      case 'Undefined': htm = '<i class="fas fa-question" title="Trigger ' + trigger + '"></i>&nbsp;'; break;
    }
    return htm;
  }
  getApplication(nodeItem) {
    return nodeItem.application != null ? nodeItem.application.name : nodeItem.customApplication.name;
  }
  getImageFromType(type: string, color: string) {
    let imag = '';
    switch (type) {
      case 'store':
      case 'stock':
        imag = '<i class="fas fa-layer-group fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'access':
        imag = '<i class="fas fa-door-open fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'archive':
        imag = '<i class="fas fa-file-archive fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'collect':
        imag = '<i class="fas fa-tasks fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'control':
        imag = '<i class="fas fa-closed-captioning fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'destroy':
        imag = '<i class="fas fa-trash-alt fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'process':
      case 'treatment':
        imag = '<i class="fas fa-microchip fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'transfer':
        imag = '<i class="fas fa-exchange-alt fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'sign':
      case 'visa':
        imag = '<i class="fab fa-cc-visa fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'validate':
        imag = '<i class="fas fa-clipboard-check fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'start':
        imag = '<i class="fas fa-circle fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'end':
        imag = '<i class="far fa-circle fa-stack-1x fa-inverse fa-middle"></i>';
        break;
      case 'use':
        imag = '<i class="far fa-hand-holding fa-stack-1x fa-inverse fa-middle"></i>';
        break;
    }
    return imag;
  }
  getNodeStyle() {
    if (this.flatDesign) {
      return '  <div class="card node-item" style="outline: none;width: 170px;background: transparent;font-size:10px;" data-color="';
    } else {
      return '  <div class="card node-item shadow-design" style="outline: none;width: 170px;background: transparent;font-size:10px;" data-color="';
    }
  }
  importNodes(id) {
    $('body').css('position', '');
    let totals = 0;
    for (let i = 0; i < this.content.nodes.length; i++) {
      const item = new NodeItem(this.content.nodes[i].id, this.content.nodes[i].icon, this.content.nodes[i].text, this.content.nodes[i].application, this.content.nodes[i].extern, this.content.nodes[i].catalogue, this.content.nodes[i].data, this.content.nodes[i].description, this.content.nodes[i].customApplication, 'black', this.content.nodes[i].customs, this.content.nodes[i].applicationType, this.content.nodes[i].trigger, this.content.nodes[i].type, this.content.nodes[i].style);
      this.addNodes(item, id);
      totals++;
    }
    this.updateConnectors();
    // this.content.nodes.map(nd => {
    //   const item = new NodeItem(nd.id, nd.icon, nd.text, nd.application, nd.extern, nd.catalogue, nd.data, nd.description, nd.customApplication, 'black', nd.customs, nd.applicationType, nd.trigger, nd.type, nd.style);
    //   this.addNodes(item, id);
    // });
    // $('.collapse-lg').toggle();
    for (let j = 0; j < this.content.extra.length; j++) {
      const item = new ExtraItem(this.content.extra[j].id , this.content.extra[j].description, this.content.extra[j].connectors, this.content.extra[j].type, this.content.extra[j].style, this.content.extra[j].trigger, this.content.extra[j].lines);
      this.addExtraHtml(item, id);
      totals++;
    }

    // this.content.extra.map(nd => {
    //   const item = new ExtraItem(nd.id , nd.description, nd.connectors, nd.type, nd.style);
    //   this.addExtraHtml(item, id);
    // });
    for (let k = 0; k < this.content.links.length; k++) {
      if (this.content.links[k].parent) {
        this.addLine(this.content.links[k].source, this.content.links[k].target, this.content.links[k].startColor, this.content.links[k].endColor, this.content.links[k].hide, this.content.links[k].parent, this.content.links[k].sourceName, this.content.links[k].targetName, this.content.links[k].group);
        totals++;
      }
    }
    // setTimeout(()=>{
    //   this.content.links.filter(d => d.parent).map(nd => {
    //     this.addLine(nd.source, nd.target, nd.startColor, nd.endColor, nd.hide, nd.parent, nd.sourceName, nd.targetName, nd.group);
    //   });
    // }, 1000);


    this.flatDesign = this.content.styles.flatDesign;
    if (this.flatDesign) {
      if ($('.node-item').length > 0) {
        $('.node-item').removeClass('shadow-design');
      }
    } else {
      if ($('.node-item').length > 0) {
        $('.node-item').addClass('shadow-design');
      }
    }
    $('#imageGlobalDataLine' + id).parent().addClass(this.content.styles.pattern);
    setTimeout(() =>{if($('#imageGlobalDataLine' + id).innerWidth() < 960){$('.node-item').css('transform', 'scale(0.7)'); }this.updateConnectors();}, 500);

  }
  addLine(start, end , startColor, endColor, hideLine, parent, startName, endName, group) {
    const newLine = new LeaderLine(
      document.getElementById(start),
      document.getElementById(end),
      {
        endPlug: 'arrow3',
        startPlug: 'none',
        endPlugColor: endColor,
        startPlugColor: startColor,
        gradient: true,
        path: 'fluid'
      }
    );
    this.edges.push({element: newLine, source: start, target: end, sourceName: startName, targetName: endName, group , parent, hide: hideLine, startColor, endColor});
    return newLine;
  }
  addExtraHtml(extraItem: ExtraItem, id) {
    const type = extraItem.type;
    if (type == 'start') {
      const start = document.createElement('div');
      this.tabIndex++;
      $(start).addClass('node-item lg-start').attr('id', extraItem.id).css({width: '52px', height: '52px', position: 'absolute', outline: 'none'}).attr('tabindex', this.tabIndex).html('<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x" style="background: -webkit-linear-gradient(to right, #38ef7d, #11998e);\n' +
        '                          background: linear-gradient(to right, #38ef7d, #11998e); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ -webkit-background-clip: text; -webkit-text-fill-color: transparent;"></i>\n' +
        '                        <i class="fas fa-circle fa-stack-1x fa-inverse"></i></span>\n' +
        '<i class="fas fa-circle connector start static extra-line event-none" id="start_' + extraItem.id + '" data-name="start"></i><i class="fas fa-circle connector end extra-line event-none" id="end_' + extraItem.id + '" data-name="start"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing event-none" title="remove this start"></i>');
      if (!this.flatDesign) {
        $(start).addClass('shadow-design');
      }
      document.getElementById('imageGlobalDataLine' + id).appendChild(start);
      extraItem.style.top += this.marginTop;
      extraItem.style.left -= this.marginLeft;
      $(start).offset(extraItem.style);
      return;
    }
    if (type == 'end') {
      const end = document.createElement('div');
      this.tabIndex++;
      $(end).addClass('node-item lg-end').attr('id', extraItem.id).css({width: '52px', height: '52px', position: 'absolute', outline: 'none'}).attr('tabindex', this.tabIndex).html('<span class="fa-stack fa-2x"><i class="fas fa-circle fa-stack-2x" style=" background: -webkit-linear-gradient(to right, #EA384D, #D31027);  /* Chrome 10-25, Safari 5.1-6 */\n' +
        '                          background: linear-gradient(to right, #EA384D, #D31027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ -webkit-background-clip: text; -webkit-text-fill-color: transparent;"></i>\n' +
        '                        <i class="far fa-circle fa-stack-1x fa-inverse"></i></span>\n' +
        '<i class="fas fa-circle connector start static extra-line event-none" id="start_' + extraItem.id + '" data-name="end"></i><i class="fas fa-circle connector end extra-line event-none" id="end_' + extraItem.id + '" data-name="end"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-end-process event-none" title="remove this end"></i>');
      if (!this.flatDesign) {
        $(end).addClass('shadow-design');
      }
      document.getElementById('imageGlobalDataLine' + id).appendChild(end);
      extraItem.style.top += this.marginTop;
      extraItem.style.left -= this.marginLeft;
      $(end).offset(extraItem.style);
      return;
    }
    if (type == 'aggregate') {
      const aggregate = document.createElement('div');
      this.tabIndex++;
      $(aggregate).addClass('node-item lg-aggregate').attr('id', extraItem.id).attr('tabindex', this.tabIndex).css({width: '60px', height: '60px', position: 'absolute', outline: 'none'}).html('     <svg style="width: 60px; height: 60px;">\n' +
        '                        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">\n' +
        '                          <stop offset="0%" stop-color="#0072ff"></stop>\n' +
        '                          <stop offset="100%" stop-color="#00c6ff"></stop>\n' +
        '                        </linearGradient>\n' +
        '                        <g style="transform: translate(16px, 10px)">\n' +
        '                          <circle cx="15" cy="18" r="26" fill="url(#linearColors1)"></circle>\n' +
        '                          <path fill="white" d="M 30.3188 26.8 H 27.075 c 2.1531 -2.8563 3.3375 -6.425 3.3375 -10.0531 c 0 -8.7844 -6.6344 -15.9313 -14.7875 -15.9313 c -8.1563 0 -14.7875 7.1469 -14.7875 15.9313 c 0 3.6281 1.1844 7.1969 3.3375 10.0531 H 0.9313 c -0.3406 0 -0.6188 0.2781 -0.6188 0.6188 V 29.8125 c 0 0.3406 0.2781 0.6188 0.6188 0.6188 H 9.7813 c 0.3406 0 0.6188 -0.2781 0.6188 -0.6188 v -2.6625 c 0 -0.1469 -0.0531 -0.2906 -0.1469 -0.4031 l -0.0344 -0.0406 c -0.0344 -0.0406 -0.075 -0.0781 -0.1187 -0.1094 c -3.0438 -2.1094 -4.9375 -5.8844 -4.9375 -9.85 c 0 -6.3969 4.6937 -11.6031 10.4625 -11.6031 c 5.7687 0 10.4625 5.2062 10.4625 11.6031 c 0 3.9813 -1.9031 7.7656 -4.9688 9.8719 c -0.1688 0.1156 -0.2687 0.3063 -0.2687 0.5094 V 29.8125 c 0 0.3406 0.2781 0.6188 0.6188 0.6188 h 8.8469 c 0.3406 0 0.6188 -0.2781 0.6188 -0.6188 v -2.3906 C 30.9375 27.0781 30.6594 26.8 30.3188 26.8 z"></path>\n' +
        '                        </g>\n' +
        '                      </svg>\n' +
        '<i class="fas fa-circle connector start static extra-line event-none" id="start_' + extraItem.id + '" data-name="aggregate"></i><i class="fas fa-circle connector end extra-line event-none" id="end_' + extraItem.id + '" data-name="aggregate"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-aggregate-process event-none" title="remove this aggregation"></i>');
      if (!this.flatDesign) {
        $(aggregate).addClass('shadow-design');
      }
      document.getElementById('imageGlobalDataLine' + id).appendChild(aggregate);
      extraItem.style.top += this.marginTop;
      extraItem.style.left -= this.marginLeft;
      $(aggregate).offset(extraItem.style);
      return;
    }
    if (type == 'condition') {
      const condition = document.createElement('div');
      this.tabIndex++;
      $(condition).addClass('node-item lg-condition').attr('id', extraItem.id).attr('tabindex', this.tabIndex).css({width: '60px', height: '60px', position: 'absolute', outline: 'none'}).html('   <svg style="width: 60px; height: 60px;">\n' +
        '                        <linearGradient id="linearColors1" x1="0" y1="0" x2="1" y2="1">\n' +
        '                          <stop offset="0%" stop-color="#0072ff"></stop>\n' +
        '                          <stop offset="100%" stop-color="#00c6ff"></stop>\n' +
        '                        </linearGradient>\n' +
        '                        <g style="transform: translate(16px, 10px);">\n' +
        '                          <circle cx="14" cy="17" r="26" fill="url(#linearColors1)"></circle>\n' +
        '                          <path fill="white" d="M 11.8438 31 L 0.6875 17.8562 c -0.9125 -1.075 -0.9125 -2.6375 0 -3.7188 L 11.8438 1 c 1.1313 -1.3375 3.1812 -1.3313 4.3125 0 L 27.3125 14.1438 c 0.9125 1.075 0.9125 2.6375 0 3.7188 L 16.1563 31 c -1.1313 1.3375 -3.1812 1.3313 -4.3125 0 z M 3 16 l 11 12.9063 L 25 16 L 14 3.0938 L 3 16 z"></path>\n' +
        '                        </g>\n' +
        '                      </svg>' +
        '<i class="fas fa-circle connector start static extra-line event-none" id="start_' + extraItem.id + '" data-name="condition: yes"></i><i class="fas fa-circle connector end condition-line event-none" id="end_' + extraItem.id + '" data-name="condition: no"></i></i><i class="fas fa-circle connector input-condition extra-line event-none" id="input_' + extraItem.id + '" data-name="condition: input"></i><i id="remove_' + extraItem.id + '" class="fas fa-times-circle remove-processing remove-condition-process event-none" title="remove this condition"></i>');
      if (!this.flatDesign) {
        $(condition).addClass('shadow-design');
      }
      document.getElementById('imageGlobalDataLine' + id).appendChild(condition);
      extraItem.style.top += this.marginTop;
      extraItem.style.left -= this.marginLeft;
      $(condition).offset(extraItem.style);
      return;
    }
  }
  getContainerOffset(id){
    const offset = $('#imageGlobalDataLine' + id).offset();
    const w = $('#imageGlobalDataLine' + id).innerWidth;
    const h = $('#imageGlobalDataLine' + id).innerHeight;
    return {w:w,h:h,offset:offset};
  }
  updateConnectors() {
    if (this.flag) {
      if (this.edges.length > 0) {
        this.edges.map(
          item => {
            if (item.element != undefined) {
              item.element.position();
            }
          }
        );
      }
    }
  }
  removeConnectors() {
    // for(let i = 0; i < this.edges.length; i++){
    //   this.edges[i].element.remove();
    // }
    this.edges.map(e => { e.element.remove(); });
  }
  ngOnDestroy(): void {
    this.edges.map(e => { e.element.remove(); });
    this.edges = [];
  }
  pageListDataChanged(event) {
    this.dataLineageConfig.currentPage = event;
  }
  openSaveModal() {
    this.loadSaveScriptModal();
    this.saveFrm.reset();
    this.saveFrm.get('enabledLine').patchValue(false);
    $('#saveLineageModal').modal();
  }
  saveDataLineage() {
    this.modalSending = true;
    const data = this.saveFrm.value;
    const t = {name: data.LineName, enabled: data.enabledLine , effective: data.lineEffective , description: data.description,  createdBy: getCookieValue('username'), owner: data.owner, entity: data.entity,catalogs: [], dictionaries: [], glossaries: [], customs: []};
    this.dataLineService.addDataLineage(t).subscribe(
      d => {
        this.Toast.fire({
          icon: 'success',
          title: this.lang === 'en' ? 'Added successfully' : 'Ajouté avec succès'
        });
        $('#saveLineageModal').modal('hide');
        this.modalSending = false;
        const currentUrl = 'data-lineage/data-lineage-list/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==/y02M3Dp94wAJwc3nh6QypA==';
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
        });
      }, err =>  {
        this.Toast.fire({
          icon: 'error',
          title: err
        });
        this.modalSending = false;
      }
    );
  }
  async loadSaveScriptModal() {
    const that = this;
    // ckeditor 4.16
    await this.dynamicScriptLoader.load('ckeditor').then(data => {
      // definition editor
      if (typeof CKEDITOR !== 'undefined' && CKEDITOR.instances.LineDescription) {
        CKEDITOR.instances.LineDescription.destroy(true);
      }
      const editorDescription = CKEDITOR.replace('LineDescription');
      editorDescription.config.height = 60;
      editorDescription.on( 'change', evt => {
        this.saveFrm.get('description').patchValue(evt.editor.getData());
      });
    }).catch(error => console.error(error));
    await this.dynamicScriptLoader.load('form.min').then(data => {
      $('#lineEffective').bootstrapMaterialDatePicker({
        // format: 'Y m d',
        format: 'YYYY/MM/D',
        // clearButton: true,
        weekStart: 1,
        time: false,
      })
        .on('change', function(e, date) {
          that.saveFrm.get('lineEffective').patchValue(formatDate($(date).attr('_d'), 'yyyy/MM/dd', 'en'));
        });
    });
  }
  changeEnabledAdd(event: Event) {
    const checked = $(event.target).prop('checked');
    this.saveFrm.get('enabledLine').patchValue(checked);

  }
  // addLoader() {
  //   $('app-root').last().append('<div id="divGlobal"></div>');
  //   // $("#divGlobal").css({ 'width': '100%', 'height': '100%', 'background': 'rgba(0, 0, 0, .6) url("assets/images/loader/740.gif") 50% 50% no-repeat', 'z-index': 10000, 'position': 'fixed','top': 0,'left':0});
  //   $('#divGlobal').css({ width: '100%', height: '100%', background: 'rgba(0, 0, 0, .6)', 'z-index': 10000, position: 'fixed', top: 0, left: 0}).append('<div id="loader" style="position: fixed; top: 50%; left: 50%"></div>');
  //   $('#loader').html($('#loaderContainer').html());
  // }
  // removeLoader() {
  //   $('#divGlobal').remove();
  // }

  goToProject() {

    if(this.lineageBridge.sourceComponent!='' && this.lineageBridge.targetComponent!=''){
      let cryptProject = this.cryptoService.set(this.lineageBridge.sourceComponent);
      cryptProject = cryptProject.replace(/\//g, '__________');
      let cryptComponent = this.cryptoService.set('dataLineage');
      cryptComponent = cryptComponent.replace(/\//g, '__________');
      let cryptComponentId = this.cryptoService.set(this.lineageBridge.targetComponent);
      cryptComponentId = cryptComponentId.replace(/\//g, '__________');
      const url = 'data-governance/project/' + cryptProject + '/' + cryptComponentId + '/' + cryptComponent;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([url]);
      });
    }
  }
  getEntities(){
    this.loadingEntity = true;
    this.dataLineService.getEntities().subscribe(
      data => {
        this.entities = data;
        this.loadingEntity = false;
      },
      err => {
        console.error(err);
      }
    );
  }
  _toggleSidebar() {
    this._openedSide = !this._openedSide;
    this._openedSide? $('#LineageGlobChart').css({'width':'135%', 'height': '86vh'}): $('#LineageGlobChart').css({'width':'100%', 'height': '86vh'});
    if (this.showcirclePack) {
      $('#showCircleLineagePack').html('<i class="fas fa-arrow-circle-right fa-2x"></i>');
      this.showcirclePack = false;
      $('#legendGlobSizeRowLineage').css('display', 'block');
    } else {

      $('#legendGlobSizeRowLineage').css('display', 'none');
      $('#showCircleLineagePack').html('<i class="fas fa-arrow-circle-left fa-2x"></i>');
      this.showcirclePack = true;
    }
  }
  legendCirclePack(scaleFactor, id, legendSizes, legendTexts, legendColor) {
    const  commaFormat = d3.format(',');

    const offset = legendSizes.length - 1;
    let width = $('#' + id).empty().width(),
      height = legendSizes[offset] * 2 * 1.2;
    width = 280;
    const	legendCenter = -10,
      legendBottom = height,
      legendLineLength = legendSizes[offset] * 1.3,
      textPadding = 5;

    // Create SVG for the legend
    const legends = d3.select('#' + id).append('svg')
      .attr('width', width)
      .attr('height', height * 1.5)
      .append('g')
      .attr('class', 'legendWrapper')
      .attr('transform', 'translate(' + width / 2 + ',' + 0 + ')');
    //  .style("opacity", 0);

    // Draw the circles
    legends.selectAll('.legendCircle')
      .data(legendSizes)
      .enter().append('circle')
      .attr('r', d => d)
      .attr('class', 'legendCircle')
      .attr('cx', legendCenter)
      .style('stroke', (d, i) => legendColor[i] )
      .attr('cy', d => legendBottom - d);

    legends.selectAll('.legendLine')
      .data(legendSizes)
      .enter().append('line')
      .attr('class', 'legendLine')
      .attr('x1', legendCenter)
      .attr('y1', d => legendBottom - 2 * d)
      .attr('x2', legendCenter + legendLineLength)
      .attr('y2', d => legendBottom - 2 * d);
    // Place the value next to the line
    legends.selectAll('.legendText')
      .data(legendSizes)
      .enter().append('text')
      .attr('class', 'legendText')
      .style('stroke', (d, i) => legendColor[i] )
      .attr('x', legendCenter + legendLineLength + textPadding)
      .attr('y', d => legendBottom - 2 * d)
      .attr('dy', '0.3em')
      .text((d, i) => legendTexts[i]);

  }
  packedDimensionByLineage(){
    this.dataLineService.packedDimensionByLineage().subscribe(
      d => {
        if (d.children.length > 0) {
          this.onGetCirclePack(d);
          this.onGetTableDimension(d);
        }
      },
      err => {
        console.error(err);
      }
    );
  }
  onGetCirclePack(data) {

    const color = d3.scaleLinear()
      .domain([0, 5])
      .range(['hsl(152,80%,80%)', 'hsl(228,30%,40%)'])
      .interpolate(d3.interpolateHcl);
    const width = 932;
    const height = 932;

    const pack = data => d3.pack()
      .size([width, height])
      .padding(20)
      (d3.hierarchy(data)
        .sum(d => d.value)
        .sort((a, b) => b.value - a.value));

    const root = pack(data);
    //console.log(root);

    let focus = root;
    let view;
    $('#LineageGlobChart').empty();
    const svg = d3.select('#LineageGlobChart');
    svg.attr('viewBox', `-${width / 2} -${height / 2} ${width} ${height}`)

      //.style('margin', '6 35px')
      .style('background', '#ffffff')
      .style('cursor', 'pointer')
      .on('click', () => zoom(root));

    // add shadow filter
    svg.append('svg:defs').selectAll('filter')
      .data(['shadowGlobeSizeLineage'])
      .enter().append('svg:filter')
      .attr('id', String)
      .attr('y', -10)
      .attr('x', -10)
      .attr('height', 40)
      .attr('width', 150)
      .append('feOffset')
      .attr('in', 'SourceAlpha')
      .attr('dx', 3)
      .attr('dy', 3)
      .attr('result', 'offset2')
      .attr('stdDeviation', 4);
    svg.selectAll('filter')
      .append('feGaussianBlur')
      .attr('in', 'offset2')
      .attr('result', 'blur2')
      .attr('stdDeviation', 10);
    svg.selectAll('filter')
      .append('feMerge')
      .append('feMergeNode')
      .attr('in', 'blur2');
    svg.selectAll('filter')
      .select('feMerge')
      .append('feMergeNode')
      .attr('in', 'SourceGraphic');
    const cont = svg.append('g');

    const node = cont
      .selectAll('circle')
      .data(root.descendants().slice(1))
      .join('circle')
      .attr('class', 'circulosLineage')
      .attr('id', d => d.data.type == 'Datalineage' ? d.parent.parent.data.id + d.parent.data.id + d.data.id : d.data.id)
      .attr('fill', d => d.children ? color(d.depth) : 'white')
      .on('click', d => focus !== d && (zoom(d), d3.event.stopPropagation()));

    const g = svg.append('g');

    let scaleAvr = 1;
    $('#zoomIn_button_lineage').on('click', function() {
      if (scaleAvr < 2) {
        scaleAvr += 0.1;
      } else {
        scaleAvr = 2;
      }
      zoomGroups();
    });
    $('#zoomOut_button_lineage').on('click', function() {
      if (scaleAvr > 0.5) {
        scaleAvr -= 0.1;
      } else {
        scaleAvr = 0.5;
      }
      zoomGroups();

    });
    $('#resetSize_button_lineage').on('click', function() {
      scaleAvr = 1;
      zoomGroups();
    });
    const zoomGroups = () => {
      cont.transition().duration(100).style('transform', 'scale(' + scaleAvr + ')');

    };
    // sunbusrt chart container init
    const width1 = 300;

    const label = svg.append('g')
      .style('font', '14px sans-serif')
      .attr('pointer-events', 'none')
      .attr('text-anchor', 'middle')
      .selectAll('text')
      .attr('class', 'labelZoom')
      .data(root.descendants())
      .join('text')
      .style('fill-opacity', d => d.parent === root ? 1 : 0)
      .style('display', d => d.parent === root ? 'inline' : 'none')
      .text(d => d.data.name);

    zoomTo([root.x, root.y, root.r * 2]);

    function zoomTo(v) {
      const k = width / v[2];

      view = v;

      label.attr('transform', d => { if ( !isNaN(d.x) || !isNaN(d.y)) { return `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`; } });

      node.attr('transform', d => { if ( !isNaN(d.x) || !isNaN(d.y)) { return `translate(${(d.x - v[0]) * k},${(d.y - v[1]) * k})`; } });
      node.attr('r', d => { if ( !isNaN(d.r)) { return d.r * k; } });

    }
    function zoom(d) {

      if (d.depth > 1 ) {
        g.attr('transform', 'translate(0,0) scale(2)');
        g.transition().duration(10).style('opacity', '1');
      }
      focus = d;

      if (d.depth == 0 || d.depth == 1 ) {
        g.style('display', 'none');
      } else {
        g.transition().duration(100).style('display', 'block');
      }
      const transition = svg.transition()
        .duration(d3.event.altKey ? 7500 : 750)
        .tween('zoom', d => {
          const i = d3.interpolateZoom(view, [focus.x, focus.y, focus.r * 2]);
          return t => zoomTo(i(t));
        });

      label
        .filter(function(d) {
          return d.parent === focus || this.style.display === 'inline';
        })
        .transition(transition)
        .style('fill-opacity', d => d.parent === focus ? 1 : 0)
        .on('start', function(d) {
          if (d.parent === focus) {
            this.style.display = 'inline';
          }
        })
        .on('end', function(d) {
          if (d.parent !== focus) {
            this.style.display = 'none';
          }
        });
    }
  }
  onGetTableDimension(d) {
    const t = d.children;

    const columnDefs = [
      {
        data: 'id',
        title: this.lang === 'en' ? 'Id Dimension' : 'Id Dimension',
        visible: false,
        searchable: false
      },
      {
        data: 'name',
        title: this.lang === 'en' ? 'Dimension' : 'Dimension'
      },
      {
        data: 'value',
        title: this.lang === 'en' ? 'Affected Categories' : 'Catégories affectées'
      }
    ];
    const dataTable = $('#tableDimensionLineage').DataTable({
      sPaginationType: 'full_numbers',
      data: t,
      destroy: true,
      columns: columnDefs,
      dom: 'Bfrtip',        // Needs button container
      select: 'single',
      responsive: true,
      rowCallback(row, data) {
        $('td:eq(0)', row).html('<a class="dataDimensionLineageType" data-name="' + data.name + '" data-id="' + data.id + '" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
      },
      initComplete(settings, json) {
        $('#tableDimensionLineage tbody').on('click', 'a.dataDimensionLineageType', function() {
          const dim = $(this).attr('data-name');
          const tr = $(this).closest('tr');
          const id = $(this).attr('data-id');
          document.getElementById(id).dispatchEvent(new Event('click'));
          const row = $('#tableDimensionLineage').DataTable().row(tr);
          $('.dmLineageshown').next('tr').hide();
          const indexRow = row.index();
          if (row.child.isShown()) {
            // This row is already open - close it
            row.child.hide();
            tr.removeClass('dmLineageshown');
          } else {
            tr.addClass('dmLineageshown');
            if ($('#table_dimensionLineageType_children' ).length) {
              $('#table_dimensionLineageType_children').remove();
            }
            row.child('<table class="table_dimensionLineageType_fils" id ="table_dimensionLineageType_children" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();

            // console.log(d);
            const categories = t.find(e => e.name == dim).children;
            $('#table_dimensionLineageType_children').DataTable({
              data: categories,
              responsive: true,
              destroy: true,
              pageLength: 5,
              rowCallback(row, data) {
                $('td:eq(0)', row).html('<a class="dataDimensionLineageDetail" data-count=' + data.funcs + ' data-name="' + data.name + '" data-id="' + data.id + '" data-parent ="' + id + '" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
              },
              lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
              columns: [
                {
                  data: 'name',
                  title: this.lang === 'en' ? 'Categories' : 'Catégories'
                },
                {
                  data: 'funcs',
                  title: this.lang === 'en' ? 'Affected Data roles' : 'Rôles de données affectés'
                }
              ],
              // init complete data roles
              initComplete(settings, json) {
                $('#table_dimensionLineageType_children tbody').on('click', 'a.dataDimensionLineageDetail', function() {
                  const funcs = $(this).attr('data-count');
                  if (funcs == 0) {
                    const id = $(this).attr('data-id');
                    document.getElementById(id).dispatchEvent(new Event('click'));
                  }else{
                    const dim = $(this).attr('data-name');
                    const tr = $(this).closest('tr');
                    const row = $('#table_dimensionLineageType_children').DataTable().row(tr);
                    const id = $(this).attr('data-id');
                    const parent = $(this).attr('data-parent');
                    document.getElementById(id).dispatchEvent(new Event('click'));
                    $('.dmLineagedetailshown').next('tr').hide();
                    const indexRow = row.index();
                    if (row.child.isShown()) {
                      // This row is already open - close it
                      row.child.hide();
                      tr.removeClass('dmLineagedetailshown');
                    } else {
                      tr.addClass('dmLineagedetailshown');
                      if ($('#table_dimensionLineageDetail_children').length) {
                        $('#table_dimensionLineageDetail_children').remove();
                      }
                      row.child('<table class="table_dimensionLineageDetail_fils" id ="table_dimensionLineageDetail_children" cellpadding="5" cellspacing="0" border="0" style="padding-left:50px;"></table>').show();

                      const data = categories.find(e => e.name == dim);
                      $('#table_dimensionLineageDetail_children').DataTable({
                        data: data.children,
                        responsive: true,
                        destroy: true,
                        pageLength: 5,
                        rowCallback(row, data) {
                          $('td:eq(0)', row).html('<a class="dataRoleLineageClick" data-name="' + data.name + '" data-id="' + parent + id + data.id + '" style="color:#00b0e4;cursor:pointer;" onmouseover="$(this).css({\'color\':\'#00b0e6\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'none\'});">' + data.name + '</a>');
                        },
                        initComplete(settings, json) {
                          $('#table_dimensionLineageDetail_children tbody').on('click', 'a.dataRoleLineageClick', function() {
                            const id = $(this).attr('data-id');
                            document.getElementById('LineageGlobChart').dispatchEvent(new Event('click'));
                            $('.pulseVolume').toggleClass('pulseVolume');
                            $('#' + $.escapeSelector(id)).toggleClass('pulseVolume');
                            setTimeout(() => {
                              $('#' + $.escapeSelector(id)).removeClass('pulseVolume');
                            }, 10000);
                          });
                        },
                        lengthMenu: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 75, 100],
                        columns: [
                          {
                            data: 'name',
                            title: this.lang === 'en' ? 'Data Role' : 'Rôle de données'
                          },
                          {
                            data: 'value',
                            title: this.lang === 'en' ? 'Affected Stewardship & Ownership' : 'Intendance & Propriété affectées'
                          }
                        ]
                      });
                    }
                  }
                });
              }
              // end init complete data roles
            });
          }
        });
      }
    });

  }
  getDimensionTable(dimensions){
    const that = this;
    const columnDefs = [{
      data: 'id',
      title: 'Id Dimension',
      visible: false,
      searchable: false
    },
      {
        data: 'name',
        title: this.lang === 'en' ? 'Dimension' : 'Dimension'
      },
      {
        data: 'category',
        title: this.lang === 'en' ? 'Affected Categories' : 'Catégories affectées'
      },
      {
        data: 'effectiveDate',
        title: this.lang === 'en' ? 'Effective Date' : 'Date effective'
      },
      {
        data: 'endDate',
        title: this.lang === 'en' ? 'End Date' : 'Date de fin'
      },
      {
        data: 'status',
        title: this.lang === 'en' ? 'Status' : 'Statut'
      }
    ];
    $('#table_dimension_dataline').empty().DataTable({
      sPaginationType: 'full_numbers',
      data: dimensions,
      destroy: true,
      columns: columnDefs,
      rowCallback(row, data) {
        let id = that.cryptoService.set(data.id);
        id = id.replace(/\//g, '__________');
        id= encodeURIComponent(id);
        let name = that.cryptoService.set(data.name);
        name = name.replace(/\//g, '__________');
        name= encodeURIComponent(name);
        let component = that.cryptoService.set('global-search');
        component = component.replace(/\//g, '__________');
        component = encodeURIComponent(component);
        const uri = location.href.split('/data-lineage')[0] + '/data-governance/dimension/' + name + '/' + id + '/' + component ;
        $('td:eq(0)', row).html('<a class="dimLineageClick" href="' + uri + '" onmouseover="$(this).css({\'color\':\'#00b0e4\', \'text-decoration\': \'underline\' });" onmouseout="$(this).css({\'color\':\'#000000\', \'text-decoration\': \'none\'});" style="cursor:pointer">' + data.name + '</a>');
        let htm = '<ul>';
        if(data.category.length > 0){
          for(let i =0; i < data.category.length; i++){
            htm+= '<li>' + data.category[i].categoryName + '</li>';
          }
          htm += '</ul>';
        }

        $('td:eq(1)', row).html(htm);
      },
      initComplete(settings, json) {
        $('#table_dimension_dataline_wrapper').css('width', '98%');
      }
    });
  }
  returnToFirstPage() {
    this.dataLineageConfig.currentPage = 1
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }
  onSelectMapping(event) {
    this.files = [];
    // const extention = report.name.split('.')[1].trim();
    this.files.push(...event.addedFiles);
    if (this.files.length > 0) {
      this.translate.get('lang').subscribe((res: string) => {
        if(res === 'en' && this instanceof GlobalLineageComponent){
          addLoader(this.lang === 'en' ? 'Uploading dataLineage please wait ...' : 'Téléchargement de dataLineage veuillez patienter ...');
        } else {
          if(this instanceof GlobalLineageComponent){
            addLoader(this.lang === 'en' ? 'Uploading please wait ...' : 'Téléchargement veuillez patienter ...');
          }
        }

        // addProgressBar(0, 'processing upload...');
        const selectedFile = this.files.splice(0, 1);
        this.processUpload(selectedFile[0]);
      });

    }
  }

  processUpload(file: File) {
    if(file == null){
      return;
    }
    //removeLoader();
    this.lineagaData = {data: [], edges: []};;
    this.lineageData = new Map();

    const extension = file.name.toString().split('.')[1];
    if(extension.toLowerCase() == 'xlsx') {
      // read Excel file
      const that = this;
      const arrayBuffer = new Response(file).arrayBuffer();
      const workbook = new Excel.Workbook();
      arrayBuffer.then(function (data) {
        workbook.xlsx.load(data)
          .then(function () {
            const worksheetData = workbook.worksheets[0];
            const worksheetEdge = workbook.worksheets[1];

            worksheetData.eachRow({includeEmpty: true }, function (row, rowNumber) {
              if (rowNumber > 7) {
                // console.log(rowNumber);
                // console.log('-------------');
                // console.log(row);
                const item = {
                  name: that.skipString(row.values[1]),
                  status: that.skipString(row.values[2]),
                  owner: that.skipString(row.values[3]),
                  description: that.skipString(row.values[4]),
                  entity: that.skipString(row.values[5]),
                  type: that.skipString(row.values[6]),
                  value: that.skipString(row.values[7]),
                  system: that.skipString(row.values[8]),
                  catalog: that.skipString(row.values[9]),
                  object: that.skipString(row.values[10]),
                  table: that.skipString(row.values[11]),
                  field: that.skipString(row.values[12]),
                  groupAction: that.skipString(row.values[13]),
                  user: getCookieValue('username'),
                  groupSource: that.skipString(row.values[8]) + '::' + that.skipString(row.values[9]) + '::' + that.skipString(row.values[10]) + '::'+ that.skipString(row.values[11]),
                  // sourceType: that.skipString(row.values[6]),
                  // sourceValue: that.skipString(row.values[7]),
                  // sourceSystem: that.skipString(row.values[8]),
                  // sourceCatalog: that.skipString(row.values[9]),
                  // sourceObject: that.skipString(row.values[10]),
                  // sourceTable: that.skipString(row.values[11]),
                  // sourceField: that.skipString(row.values[12]),
                  // targetType: that.skipString(row.values[13]),
                  // targetValue: that.skipString(row.values[14]),
                  // targetSystem: that.skipString(row.values[15]),
                  // targetCatalog: that.skipString(row.values[16]),
                  // targetObject: that.skipString(row.values[17]),
                  // targetTable: that.skipString(row.values[18]),
                  // targetField: that.skipString(row.values[19]),
                  // groupTarget: that.skipString(row.values[15]) + '::' + that.skipString(row.values[16]) + '::' + that.skipString(row.values[17]) + '::'+ that.skipString(row.values[18]),
                  // groupSource: that.skipString(row.values[8]) + '::' + that.skipString(row.values[9]) + '::' + that.skipString(row.values[10]) + '::'+ that.skipString(row.values[11]),
                };
                // console.log(item);
                that.lineagaData.data.push(item);
                //  if(that.lineageData.has(item.groupSource)){
                //    that.lineageData.get(item.groupSource).
                //  }
                //  that.lineageData.set(item.groupSource,item);
              }
            });
            worksheetEdge.eachRow({includeEmpty: true }, function (row, rowNumber) {
              if (rowNumber > 7) {
                const edge = {
                  name: that.skipString(row.values[1]),
                  sourceType: that.skipString(row.values[2]),
                  sourceValue: that.skipString(row.values[3]),
                  sourceSystem: that.skipString(row.values[4]),
                  sourceCatalog: that.skipString(row.values[5]),
                  sourceObject: that.skipString(row.values[6]),
                  sourceTable: that.skipString(row.values[7]),
                  sourceField: that.skipString(row.values[8]),
                  targetType: that.skipString(row.values[9]),
                  targetValue: that.skipString(row.values[10]),
                  targetSystem: that.skipString(row.values[11]),
                  targetCatalog: that.skipString(row.values[12]),
                  targetObject: that.skipString(row.values[13]),
                  targetTable: that.skipString(row.values[14]),
                  targetField: that.skipString(row.values[15]),
                  description: that.skipString(row.values[16]),
                  groupTarget: that.skipString(row.values[10]) + '::' + that.skipString(row.values[11]) + '::' + that.skipString(row.values[12]) + '::' + that.skipString(row.values[13]),
                  groupSource: that.skipString(row.values[4]) + '::' + that.skipString(row.values[5]) + '::' + that.skipString(row.values[6]) + '::' + that.skipString(row.values[7]),
                }
                that.lineagaData.edges.push(edge);
              }
            });
            // console.log(that.mappingData);
            // return;
            that.uploadLineage(that.lineagaData);
          });
      });
    }
  }

  uploadLineage(lineagaData: any) {

    lineagaData.data = groupBy(lineagaData.data, 'name');
    lineagaData.data.forEach(e => e.childs= groupBy(e.childs,'groupAction'));
    lineagaData.data.forEach(e => e.childs.forEach(f => f.childs = groupBy(f.childs, 'groupSource')));
    lineagaData.edges = groupBy(lineagaData.edges, 'name');
    console.log(lineagaData);
    // removeLoader();
    // return;
    this.dataLineService.uploadDataLineages(lineagaData).subscribe(
      e => {
        removeLoader();
        refreshComponent(this.router);
        // const xml = mxUtils.parseXml(e);
        // const xmlText = new XMLSerializer().serializeToString(xml);
        // console.log(xml);
        // $('#mxgraph').text(xmlText);
      },
      error => {
        removeLoader();
        console.error(error);
      }
    );
  }
  skipString(str) {
    if (str == null) {
      return '';
    }
    if(str == undefined){
      return '';
    }
    if(str.hasOwnProperty('error')){
      return str.error;
    }
    let name = str.toString().replace(/\//g, '__________');
    name = name.replace(/&/g, '::::::::');
    return name.trim();
  }
}
