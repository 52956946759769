import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, Subject} from "rxjs";
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class MetadataService {

  OptionFiles()  {
    return {
        headers: new HttpHeaders({
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            // 'Accept': 'Application/json'
        })
    };
};
Options = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
        //  'Accept': 'Application/json'
    })
};
headerss = new Headers({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + getCookieValue('tokendrd')
});
constructor(private http: HttpClient, private router: Router) { }
getListMDF(): Observable<any> {
    const id = window.localStorage.getItem('userId');
    return this.http.get<any>(Shared.API_URL +  `/api/metadatas/getListMDF/${id}`, this.Options);
}
// getCanceledUpdateSelectedTable(id: string): Observable<any> {
//     return this.http.get<any>(Shared.API_URL + `api/metadatas/getResetSelectedTable/${id}`, this.Options);
// }
getCanceledUpF(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + `/api/metadatas/getCanceledUpF/${id}`, this.Options);
}
getCanceledUpT(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + `/api/metadatas/getCanceledUpT/${id}`, this.Options);
}
deleteMD(id:string, objectMD:string):Observable<any> {
    return this.http.get<any>(Shared.API_URL + `/api/metadatas/deleteMD/${id}/${objectMD}`, this.Options);
}
updateMD(d): Observable<any>{
    let formData = new FormData();
    formData.append('data', d);

    return this.http.post(Shared.API_URL + '/api/metadatas/updateMD' , formData,  this.OptionFiles());


}
// updateValueMD(d): Observable<any>{
//     const data ="data=" +d;
//     return this.http.post(Shared.API_URL + '/api/metadatas/updateValueMD' , data,  this.Options);
// }
//   addValueMD(d): Observable<any>{
//     const data ="data=" +d;
//
//     return this.http.post(Shared.API_URL + '/api/metadatas/addValueMD' , data,  this.Options);
//
//
// }
    addMD(d): Observable<any> {
        let formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/metadatas/addMetaData' , formData, this.OptionFiles());
    }
// getValuesMD(id): Observable<any> {
//     return this.http.get<any>(Shared.API_URL + `/api/metadatas/getValuesMD/${id}`, this.Options);
// }
// deleteValueMD(id,idobj,obj): Observable<any> {
//     return this.http.get<any>(Shared.API_URL + `/api/metadatas/deleteValueMD/${id}/${idobj}/${obj}`, this.Options);
// }
// update data by import fron excel
    setUpdatexlxs(d): Observable<any> {
        let formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/metadatas/addxlsEntitys' , formData, this.OptionFiles() );
      }
    // uploadFilesApp(data): Observable<any> {
    //     let formData = new FormData();
    //     formData.append('file', data);
    //     return this.http.post<any>(Shared.API_URL + '/api/metadatas/insertTable', formData, this.OptionFiles());
    // }
    // uploadFilesAppF(data): Observable<any> {
    //     let formData = new FormData();
    //     formData.append('file', data);
    //     return this.http.post<any>(Shared.API_URL + '/api/metadatas/insertField', formData, this.OptionFiles());
    // }
    // service table md
    addMetaDataT(d): Observable<any> {
        let formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/metadatas/addMetaDataT' , formData, this.OptionFiles());
    }
    getListMDT(): Observable<any> {
        const id = window.localStorage.getItem('userId');
        return this.http.get<any>(Shared.API_URL +  `/api/metadatas/getListMDT/${id}`, this.Options);
    }
    getListLinkedColumns(): Observable<any> {
        return this.http.get<any>(Shared.API_URL +  '/api/metadatas/getListLinkedColumns', this.Options);
    }
    deleteMDT(id: string, objectMD: string): Observable<any> {
        return this.http.get<any>(Shared.API_URL + `/api/metadatas/deleteMDT/${id}/${objectMD}`, this.Options);
    }
    updateMDT(d): Observable<any> {
        let formData = new FormData();
        formData.append('data', d);
        return this.http.post(Shared.API_URL + '/api/metadatas/updateMDT' , formData,  this.OptionFiles());
    }
    checkExistMDTbyName(name: string): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/metadatas/checkExistMDTbyName/' + name,  this.OptionFiles());
    }
    setMDTDataToBTs(customTab: any, listBt: any): Observable<any> {
        customTab.bts = listBt;
        const data = 'data=' + JSON.stringify(customTab);
        return this.http.post<any>(Shared.API_URL + '/api/metadatas/setMDTDataToBTs', data, this.Options);
    }

    // export all ENUMs By Glos and FO in Glossary
    getEnumsMDTforExport(d): Observable<any> {
        const data = 'data=' + JSON.stringify(d);
        return this.http.post<any>(Shared.API_URL + '/api/metadatas/getEnumsMDTforExport', data, this.Options);
    }
    // export ENUMs By MG/BO/BO in Template Holding
    getEnumsMDTforExportFromHolding(d, id): Observable<any> {
        const data = 'data=' + JSON.stringify(d);
        return this.http.post<any>(Shared.API_URL + '/api/metadatas/getEnumsMDTforExportFromHolding/' + id, data, this.Options);
    }
    ////////////////////// add champ type /////////////////////////////
    getCTperObjectForTypes(objMd: string, action: string, objectId: string): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/metadatas/getCTperObjectForTypes/' + objMd + '/' + action + '/' + objectId, this.Options);
    }
    getColumnsCTforTypes(idTable: string): Observable<any> {
        return this.http.get(Shared.API_URL + '/api/metadatas/getColumnsCTforTypes/' + idTable, this.Options);
    }
    ///////////////////// end add champ type //////////////////////////
}
