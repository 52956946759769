import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpEvent} from '@angular/common/http';
import {Router} from '@angular/router';

import { Observable } from 'rxjs';
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
@Injectable({
  providedIn: 'root'
})
export class UploadFilesService {

  OptionFiles()  {
  return {
    headers: new HttpHeaders({
        //'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
        // 'Accept': 'Application/json'
    })
};
};
Options = {
headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'Application/json',
    'Authorization': 'Bearer ' + getCookieValue('tokendrd')
    //  'Accept': 'Application/json'
})
};
headerss = new Headers({
'Content-Type': 'application/json',
'Authorization': 'Bearer ' + getCookieValue('tokendrd')
});
constructor(private http: HttpClient, private router: Router) { }

uploadFiles(data,id,desc,objName): Observable<any> {
    let formData = new FormData();
    if(data!=undefined){
        data.map( f => formData.append('file[]',f));
    }
    var username = getCookieValue('username');
    return this.http.post<any>(Shared.API_URL + '/api/uploadfile/insertFile/' + id+ '/' + desc + '/' + objName + '/' + username, formData, this.OptionFiles());
}
deleteFileApp(id,idobj,objName): Observable<any> {
  return this.http.get<any>(Shared.API_URL + `/api/uploadfile/deleteFileApp/${id}/${idobj}/${objName}`, this.Options);
}
getFilesApp(id,objName): Observable<any> {
  return this.http.get<any>(Shared.API_URL + `/api/uploadfile/getFilesApp/${id}/${objName}`, this.Options);
}
updateFile(d,objName): Observable<any>{
  const data ="data=" +d;
  var username = getCookieValue('username');
  
  return this.http.post(Shared.API_URL + '/api/uploadfile/updateFile/' + objName +'/'+username, data,  this.Options);


}
}