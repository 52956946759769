import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {Shared, addLoader, removeLoader, getCookieValue} from '../../shared';
import { Observable, Subscription } from 'rxjs';
import { DynamicScriptLoaderService } from '../../dynamic-script-loader-service.service';
import { DrmClassifService } from '../../services/drm/drm-classif.service';
import { ObjectService } from '../../services/objects/object.service';
import { AuthenticationService } from '../../services/authentication.service';
import { OrderPipe } from 'ngx-order-pipe';
import { ActivatedRoute, Router } from '@angular/router';
import * as echarts from 'echarts';


import { DomUtil } from 'leaflet';
import removeClass = DomUtil.removeClass;
import { CryptoService } from '../../crypto.service';
import { TranslateService } from '@ngx-translate/core';
import { RouterhistoryService } from '../../services/appService/routerhistory.service';
declare const $: any;
@Component({
  selector: 'app-security-risk',
  templateUrl: './security-risk.component.html',
  styleUrls: ['./security-risk.css']
})
export class SecurityRiskComponent implements OnInit, OnDestroy, AfterViewInit {
  subscription: Subscription = new Subscription();
  lang: string;
  Assets = Shared.ASSETS;
  // application
  appEmpty = false;
  listAplications = [];
  idsApp = [];
  selectedApplication: string;
  selectedApplicationId: string;
  asyncApplications: Observable<any[]>;
  flagApplication = false;
  reverseApplication = false;
  totalApplications = 0;
  pApp = 1;
  selectedPageSizeApp = 5;
  orderApp = 'id';
  applicationFilter: any;
  securityRiskUpdate = false;
  // qcm application: test models
  indexSelectedApplQSM: any;
  listAdModelCheck = [];
  listAdModelpreCheck = [];
  chechAllModel = false;
  versionApprovedQcmALL = true;
  enableSaveModalApp = false;
  // qcm application: test models

  // end application
  // object
  listObjects = [];
  selectedObjectId: string;
  selectedObject: string;
  idsObjets = [];
  totalObjects = 0;
  loadingObjects: boolean;
  objectconfig: any;
  orderObj = 'id';
  flagObject = false;
  reverseObj = false;
  asyncObjects: Observable<any[]>;
  selectedPageSizeObj = 5;
  pageSizeList = [5, 10, 15, 20, 25, 30];
  pObj = 1;
  objectFilter: any;
  indexSelectedObjectQSM: any;
  listAdRecordCheck = [];
  listAdRecordPreCheck = [];
  chechAllRecord = false;
  // end object
  // record
  listRecords = [];
  selectedRecordId: string;
  idsRecords = [];
  totalRecords = 0;
  loadingRecords: boolean;
  recordconfig: any;
  orderRecord = 'id';
  flagRecord = false;
  reverseRecord = false;
  asyncRecords: Observable<any[]>;
  selectedPageSizeRecord = 5;
  pRecord = 1;
  recordFilter: any;
  indexSelectedRecordQSM: any;
  listAdFieldCheck = [];
  listAdFieldPreCheck = [];
  chechAllField = false;
  selectedRecord: string;
  // end record
  // field
  listFields = [];
  selectedFieldId: string;
  idsFields = [];
  totalFields = 0;
  loadingFields: boolean;
  fieldconfig: any;
  orderField = 'id';
  flagField = false;
  reverseField = false;
  indexSelectedFieldQSM: any;
  selectedField: string;
  asyncFields: Observable<any[]>;
  selectedPageSizeField = 5;
  pField = 1;
  fieldFilter: any;
  // end field
  selectedObjectModelID: string;
  selectedObjectModelIDAncien: string;
  selectedObjectModelCode = [];
  selectedModel = '';
  listObjectModel = [];
  applicationconfig: any;
  // qcm model: test objects
  indexSelectedModelQSM: any;
  listAdObjectCheck = [];
  listAdObjectPreCheck = [];
  chechAllObject = false;
  enableSaveModalModel = false;
  changedModel = [];
  versionApprovedQcm = true;
  // qcm application: test models

  loadingApplication = true;
  flag = false;
  ids = [];
  hiderIndicators = true;
  listAppliForMod$: Observable<string[]>;
  selectedApp = '';

  // paginate object models
  asyncModels: Observable<any[]>;
  p = 1;
  totalModels = 0;
  loadingModels: boolean;
  modelconfig: any;
  order = 'id';
  reverse = false;
  sortedCollection: any[];
  selectedPageSize = 5;
  modelFilter: any;
  // end paginate object models
  tableRecord = [];
  cat = '';
  nameObject = '';
  // pagination usage risk
  term: any;
  model: any;
  // recupération des objets par categorie et pagination
  pagesize = 6;
  // get params from route
  applicationNameREdirect: string;
  modelNameREdirect: string;
  objectNameREdirect: string;
  tableNameREdirect: string;
  fieldNameREdirect: string;

  showBack = false;
  securityBridge = { application: '', model: '', object: '', table: '', field: '', componentId: '', component: '' };
  constructor(private dynamicScriptLoader: DynamicScriptLoaderService, private drmClassifService: DrmClassifService,
    private objectService: ObjectService, private authenticationService: AuthenticationService,
    private orderPipe: OrderPipe, private router: Router, private activeRoute: ActivatedRoute,
    private cryptoService: CryptoService, private translate: TranslateService,
    private routerHistoryService: RouterhistoryService) {
    this.applicationconfig = { id: 'serverApplication', itemsPerPage: this.selectedPageSizeApp, currentPage: this.pApp, totalItems: this.totalApplications };
    this.modelconfig = { id: 'serverModel', itemsPerPage: this.selectedPageSize, currentPage: this.p, totalItems: this.totalModels };
    this.objectconfig = { id: 'serverObject', itemsPerPage: this.selectedPageSize, currentPage: this.pObj, totalItems: this.totalObjects };
    this.recordconfig = { id: 'serverRecord', itemsPerPage: this.selectedPageSize, currentPage: this.pRecord, totalItems: this.totalRecords };
    this.fieldconfig = { id: 'serverField', itemsPerPage: this.selectedPageSize, currentPage: this.pField, totalItems: this.totalFields };
  }

  ngOnInit() {
    // get params in route
    let subscription0$ = this.activeRoute.paramMap.subscribe(params => {
      let application = params.get("application");
      let model = params.get("model");
      let object = params.get("object");
      let table = params.get("table");
      let field = params.get("field");
      let componentId = params.get("componentId");
      let component = params.get("component");
      // change ---------- to /
      application = application.replace(/__________/g, '/');
      model = model.replace(/__________/g, '/');
      object = object.replace(/__________/g, '/');
      table = table.replace(/__________/g, '/');
      field = field.replace(/__________/g, '/');
      componentId = componentId.replace(/__________/g, '/');
      component = component.replace(/__________/g, '/');
      // decrypt params
      componentId = this.cryptoService.get(componentId);
      component = this.cryptoService.get(component);
      application = this.cryptoService.get(application);
      this.applicationNameREdirect = application;
      model = this.cryptoService.get(model);
      object = this.cryptoService.get(object);
      table = this.cryptoService.get(table);
      field = this.cryptoService.get(field);
      // get params if only not empty
      if (application != null && application != '0') {
        this.securityBridge.application = application;
      }
      if (model != null && model != '0') {
        this.securityBridge.model = model;
      }
      if (componentId != null && componentId != '0') {
        this.securityBridge.componentId = componentId;
      }
      if (component != null && component != '0') {
        this.securityBridge.component = component;
      }
      if (object != null && object != '0') {
        this.securityBridge.object = object;
      }
      if (table != null && table != '0') {
        this.securityBridge.table = table;
      }
      if (field != null && field != '0') {
        this.securityBridge.field = field;
      }
    });
    this.subscription.add(subscription0$);
    // applications
    $('#spinner_getListApplication').attr('style', 'display:block!important');
    $('#getListApplication').attr('style', 'display:none!important');
    $('#application_pagin_search').attr('style', 'display: none!important');
    this.onGetApplForListModelsDrmSecurity();
    // this.detectChangeLaguage();
  }
  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
  ngAfterViewInit() {
    setTimeout(() => {
      this.showTipsDrmSecurity();
    }, 1000);
  }
  showTipsDrmSecurity() {
    let menu = localStorage.getItem('dataSoumenu');
    let tip = localStorage.getItem('dataTips');
    let firstShow = localStorage.getItem('dontShowTipsDrmSecurity');
    if ((menu != null) || (tip != null) || (firstShow != null)) {
      // console.log('first');
      // (localStorage.getItem('dataSoumenu') == 'drm-security') && (localStorage.getItem('dataTips') == 'true') && (
      if ((localStorage.getItem('dontShowTipsDrmSecurity') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
        localStorage.setItem('dontShowTipsDrmSecurity', 'true');
        setTimeout(() => {
          $('#tipsCompDrmSecurity').modal('show');
        }, 1000);
      }
    } else {
      setTimeout(() => {
        // console.log('second');
        if ((localStorage.getItem('dontShowTipsDrmSecurity') == 'false') && (localStorage.getItem('dataTips') == 'true')) {
          localStorage.setItem('dontShowTipsDrmSecurity', 'true');
          $('#tipsCompDrmSecurity').modal('show');
        }
      }, 1000);
    }
  }
  // set new etat tips data security
  onUpdateTipsDrmSecurity() {
    let tipsDrmSecurity;
    if ($('#checked_show_drmsecurity').is(':checked')) {
      tipsDrmSecurity = false;
    } else {
      tipsDrmSecurity = true;
    }
    let dataPut = {
      username: getCookieValue('username'),
      mainTips: tipsDrmSecurity,
      dataMenu: this.lang ? 'Data Regulation' : 'Réglementation des données',
      dataSousmenu: 'drm-security'
    };
    this.routerHistoryService.setUpdateTipsMenuByUser(dataPut).subscribe(
      data => {
        console.log(data);
      },
      err => {
        console.error(err);
      }
    );
  }
  onClick(event) {
    $('#' + event).slideToggle(500);
  }
  ////////////// START APPLICATION //////////////
  // get list applications (First collapsible)
  onGetApplForListModelsDrmSecurity() {
    let subscription1$ = this.drmClassifService.getApplForListModelsDrmSecurity()
      .subscribe(
        result => {
          this.listAplications = [];
          this.idsApp = [];
          for (const appl of result.items) {
            if (this.securityBridge.application == appl.name) {
              this.securityBridge.application = appl.id;
            }
            this.listAplications.push(appl);
            this.idsApp.push(appl.id);
          }
          console.log(this.listAplications);
        },
        err => {
          console.error(err);
        },
        () => {
          if (this.listAplications.length > 0) {
            $('#spinner_getListApplication').attr('style', 'display:none!important');
            $('#getListApplication').attr('style', 'display:block!important');
            $('#application_pagin_search').attr('style', 'display: flax!important');
            this.sortedCollection = this.orderPipe.transform(this.asyncApplications, 'id');
            // test redirection from objectModel
            if (this.securityBridge.application != '') {
              this.applicationFilter = this.applicationNameREdirect;
              setTimeout(
                () => {
                  // $('#bt_' + this.securityBridge.application).click();
                  $('button[data-name=\'' + this.securityBridge.application + '\']')[0].click();
                }, 500
              );
            }
          } else {
            this.router.navigate(['drm/drm-no-security']);
          }
        }
      );
    this.subscription.add(subscription1$);
  }
  // sort application
  setOrderApplication(value: string) {
    if (this.orderApp === value) {
      this.reverseApplication = !this.reverseApplication;
    }

    this.orderApp = value;
  }
  // comparator sort application
  sortAlphaNumApplication(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  pageApplicationChanged(event) {
    this.applicationconfig.currentPage = event;
  }
  getPageApplication() {
    this.applicationconfig.itemsPerPage = this.selectedPageSizeApp;
    this.applicationconfig.currentPage = 1;
    this.applicationconfig.totalItems = this.listAplications.length;
  }
  showDetailApplication(id, filsModelApproved, nameApp, role, event) {
    this.selectedApplicationId = id;
    this.versionApprovedQcmALL = filsModelApproved;
    let that = this;
    $('#spinner_getListApprovedModel' + this.selectedApplicationId).show();
    $('#getListApprovedModel' + this.selectedApplicationId).hide('slow');
    $('#model_pagin_search' + this.selectedApplicationId).attr('style', 'display: none!important');
    this.selectedApplication = nameApp;
    this.securityRiskUpdate = role;
    this.selectedApplicationId = id;
    // const oid = $(event.target).parent().parent().attr('id');
    // id = oid;
    const ul = $('#' + id).parent().parent();
    const li = $('#' + id).parent();
    if (!this.flagApplication) {
      $('#application_pagin_search').attr('style', 'display: none!important');
      this.flagApplication = true;
      $('#bodyApplication' + id).slideDown();
      // event.target.innerHTML = 'Back';
      ul.children().hide();
      $('#titleApplication').show('slow');
      li.show('slow');

      $('#qcm_application_' + this.selectedApplicationId + ' input[type="radio"]').on('click change', function (e) {
        if (that.securityRiskUpdate && that.versionApprovedQcmALL) {
          if (($('input[name=\'dataAccessApp' + id + '\']:checked').val() != null)
            && ($('input[name=\'accessLocationApp' + id + '\']:checked').val() != null)
            && ($('input[name=\'identifiabilityApp' + id + '\']:checked').val() != null)
            && ($('input[name=\'quantityApp' + id + '\']:checked').val() != null)) {
            $('#saveQcmApp' + id).prop('disabled', false).removeClass('color-8');
            $('#cancelQcmApp' + id).prop('disabled', false).removeClass('color-2');
          }
          if ($('#saveQcmApp' + id).prop('disabled') === false) {
            $('#saveQcmApp' + id).addClass('color-8');
            $('#cancelQcmApp' + id).addClass('color-2');
          } else {
            $('#saveQcmApp' + id).removeClass('color-8');
            $('#cancelQcmApp' + id).removeClass('color-2');
          }
        }
      });
      this.onGetObjectModelsListDrmSecurity(this.selectedApplication);
    } else {
      this.applicationFilter = null;
      this.onCancelQcmApplication();
      $('#application_pagin_search').attr('style', 'display: flex!important');
      $('#apps-search').show('slow');
      // $('.d3-tip').remove();
      // event.target.innerHTML = 'Show Details';
      this.flagApplication = false;
      $('#bodyApplication' + id).slideUp();
      ul.children().show('slow');
    }
  }
  setChecked(val1, val) {
    if (val == val1) { return 'checked'; }
  }
  // get ObjectModel if au moin un model a un QCM pour appliquer le QSM de l'application au Models sélectionnés
  onGetModelToDatatableSQM() {
    this.listAdModelCheck = [];
    const that = this;
    $('#ListObjectModelQCM').empty();
    if ($('#objectModelQSM').length > 0) {
      $('#objectModelQSM').remove();
    }
    $('#ListObjectModelQCM').append('<table id="objectModelQSM" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
      '<tr>' +
      '<th>Name</th>' +
      '<th>Effective Date</th>' +
      '<th>Level Score</th>' +
      '<th>Updated By</th>' +
      '<th>Updated At</th>' +
      '<th>' +
      ' <label style="margin-bottom: 0; margin-right: -16px;">' +
      '  <input type="checkbox" id="check_model_all" value="all"/>' +
      '  <span></span>' +
      ' </label>' +
      '</th>' +
      '</tr>' +
      '</thead></table>');

    $('#objectModelQSM').DataTable({
      processing: true,
      destroy: true,
      pageLength: 5,
      rowCallback(row, data) {
        function getIconeLevelScore(val) {
          let ic;
          switch (val) {
            case 0:
              ic = '<i style=\'color: gray\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 1:
              ic = '<i style=\'color: green\' class=\'fas fa-exclamation-triangle\'></i>';
              break;

            case 2:
              ic = '<i style=\'color: orange\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 3:
              ic = '<i style=\'color: red\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
          }
          return ic;
        }
        $('td:eq(2)', row).html('<p style="text-align: center">' + getIconeLevelScore(data.levelScore) + '</p>');
      },
      ajax: {
        url: Shared.API_URL + '/api/drm-security/getModelBeforeSaveQcm/' + this.selectedApplicationId + '/' + localStorage.getItem('userId'),
        contentType: 'application/json',
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      columns: [
        { data: 'name', title: this.lang ? 'Name' : 'Nom' },
        { data: 'effectiveDate', title: this.lang ? 'Effective Date' : 'Date d\'effet' },
        { data: 'levelScore', title: this.lang ? 'Level Score' : 'Score de niveau' },
        { data: 'userScore', title: this.lang ? 'Updated By' : 'Mis à jour par' },
        { data: 'dateScore', title: this.lang ? 'Updated At' : 'Mis à jour le' },
        {
          data: 'id',
          render: function (data, type, row) {
            return '<label style=\'margin-bottom: 0; margin-right: -16px;\'>' +
              '                    <input type=\'checkbox\' class="editor-active" id=\'' + data + '\'/>' +
              '                    <span></span>' +
              '                  </label>';
          }
        }
      ],
      order: [[2, 'asc']],
      initComplete(settings, json) {
        const table = this.api();
        // Handle click on "Select all" control
        $('#check_model_all').on('click', function () {
          that.listAdModelCheck = [];
          if ($('#check_model_all').prop('checked')) {
            that.chechAllModel = true;
            that.listAdModelCheck = that.listAdModelpreCheck;
          } else {
            that.chechAllModel = false;
          }
          // Get all rows with search applied
          var rows = table.rows({ search: 'applied' }).nodes();
          // Check/uncheck checkboxes for all rows in the table
          $('input[type="checkbox"]', rows).prop('checked', this.checked);
          // $('.editor-active:checked').each(function () {
          //
          // });
          if (that.listAdModelCheck.length > 0) {
            $('#qcm_model_modal_submit').prop('disabled', false);
          } else {
            $('#qcm_model_modal_submit').prop('disabled', true);
          }
        });
        // Handle click on checkbox to set state of "Select all" control
        $('#objectModelQSM tbody').on('change', 'input[type="checkbox"]', function () {
          // If checkbox is not checked
          if (!this.checked) {
            that.chechAllModel = false;
            var el = $('#check_model_all').get(0);
            // If "Select all" control is checked and has 'indeterminate' property
            if (el && el.checked && ('indeterminate' in el)) {
              // Set visual state of "Select all" control
              // as 'indeterminate'
              el.indeterminate = true;
            }
          }
        });
        $('#objectModelQSM').on('change', 'input.editor-active', function (event) {
          that.chechAllModel = false;
          if ($(event.target).prop('checked')) {
            that.listAdModelCheck.push($(event.target).attr('id'));
            if (that.listAdModelCheck.length > 0) {
              $('#qcm_model_modal_submit').prop('disabled', false);
            } else {
              $('#qcm_model_modal_submit').prop('disabled', true);
            }
          } else {
            that.listAdModelCheck.splice(that.listAdModelCheck.indexOf($(event.target).attr('id')), 1);
            if (that.listAdModelCheck.length > 0) {
              $('#qcm_model_modal_submit').prop('disabled', false);
            } else {
              $('#qcm_model_modal_submit').prop('disabled', true);
            }
          }
        });


      }
    });
    $('#listModel').modal('show');
  }
  onGetModelBeforeSaveQcm() {
    this.listAdModelpreCheck = [];
    let subscription2$ = this.drmClassifService.existModelBeforeShowModalQcm(this.selectedApplicationId).subscribe(
      result => {
        result.map(item => this.listAdModelpreCheck.push(item));
        if (this.listAdModelpreCheck.length > 0) {
          this.onGetModelToDatatableSQM();
        } else {
          this.chechAllModel = true;
          this.onSaveQcmApplication();
        }
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription2$);
  }
  onSaveQcmApplication() {
    if (this.securityRiskUpdate && this.versionApprovedQcmALL) {
      if (this.chechAllModel) {
        this.listAdModelCheck = [];
        this.listAdModelCheck.push('all');
      }
      $('#cancelQcmApp' + this.selectedApplicationId).prop('disabled', true).removeClass('color-2');
      $('#saveQcmApp' + this.selectedApplicationId).prop('disabled', true).removeClass('color-8');
      // .html('<div class="spinner-grow spinner-grow-sm" style="color: #fbfbff" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '</div>');
      $('#listModel').modal('hide');
      addLoader(this.lang === 'en' ? 'SAVING&#160;&#160;DATA&#160;. . .' : 'ENREGISTREMENT&#160;&#160;DONNÉES&#160;. . .');
      let dataAccessApp = $('input[name=\'dataAccessApp' + this.selectedApplicationId + '\']:checked').val();
      let accessLocationApp = $('input[name=\'accessLocationApp' + this.selectedApplicationId + '\']:checked').val();
      let identifiabilityApp = $('input[name=\'identifiabilityApp' + this.selectedApplicationId + '\']:checked').val();
      let quantityApp = $('input[name=\'quantityApp' + this.selectedApplicationId + '\']:checked').val();
      let subscription3$ = this.drmClassifService.saveQcmApplication(this.selectedApplicationId,
        dataAccessApp, accessLocationApp, identifiabilityApp, quantityApp,
        this.listAdModelCheck).subscribe(
          data => {
            let appl = this.listAplications.findIndex(o => o.id === this.selectedApplicationId);
            this.listAplications[appl].levelScore = data[0].levelScore;
            this.listAplications[appl].dateScore = data[0].dateScore;
            this.listAplications[appl].userScore = data[0].userScore;
            this.listAplications[appl].leveleditedin = data[0].leveleditedin;
            this.listAplications[appl].dataAccessApp = data[0].dataAccessApp;
            this.listAplications[appl].accessLocationApp = data[0].accessLocationApp;
            this.listAplications[appl].identifiabilityApp = data[0].identifiabilityApp;
            this.listAplications[appl].quantityApp = data[0].quantityApp;
            this.listAplications[appl].percentCompletion = data[0].percentCompletion;
          },
          err => {
            console.error(err);
          },
          () => {
            this.flag = false;
            this.flagObject = false;
            this.flagRecord = false;
            this.flagField = false;
            this.onGetObjectModelsListDrmSecurity(this.selectedApplication);
            $('#saveQcmApp' + this.selectedApplicationId).prop('disabled', true).removeClass('color-8'); //.html('save')
            $('#cancelQcmModel' + this.selectedApplicationId).prop('disabled', true).removeClass('color-2');
          }
        );
      this.subscription.add(subscription3$);
    }
  }
  onCancelQcmApplication() {
    let appl = this.listAplications.find(o => o.id === this.selectedApplicationId);
    $('#saveQcmApp' + this.selectedApplicationId).prop('disabled', true).removeClass('color-8');//.html('save')
    $('#cancelQcmApp' + this.selectedApplicationId).prop('disabled', true).removeClass('color-2');
    $('input[name=dataAccessApp' + this.selectedApplicationId + '][value=' + appl.dataAccessApp + ']').prop('checked', true);
    $('input[name=accessLocationApp' + this.selectedApplicationId + '][value=' + appl.accessLocationApp + ']').prop('checked', true);
    $('input[name=identifiabilityApp' + this.selectedApplicationId + '][value=' + appl.identifiabilityApp + ']').prop('checked', true);
    $('input[name=quantityApp' + this.selectedApplicationId + '][value=' + appl.quantityApp + ']').prop('checked', true);
  }
  // hide show collapcible body application
  showHideQcmApplication() {
    $('#qcm_application_' + this.selectedApplicationId).toggle();
    if ($('#icon_double_app' + this.selectedApplicationId).hasClass('fa-angle-double-down')) {
      $('#icon_double_app' + this.selectedApplicationId).removeClass('fa-angle-double-down');
      $('#icon_double_app' + this.selectedApplicationId).toggleClass('fa-angle-double-up');
    } else {
      $('#icon_double_app' + this.selectedApplicationId).removeClass('fa-angle-double-up');
      $('#icon_double_app' + this.selectedApplicationId).toggleClass('fa-angle-double-down');
    }
  }
  ////////////// END APPLICATION //////////////
  ////////////// START MODELS //////////////
  // get list objectModel by status Approved/Archived in table collapse (tabs: Models) (by application)
  onGetObjectModelsListDrmSecurity(nameApp) {
    let subscription4$ = this.drmClassifService.getObjectModelsListDrmSecurity(nameApp).subscribe(
      result => {
        this.listObjectModel = [];
        this.ids = [];
        for (const model of result.items) {
          if (this.securityBridge.model == model.id) {
            this.modelNameREdirect = model.id;
          }
          // if (this.securityBridge.application != '') {
          //   this.applicationFilter = this.applicationNameREdirect;
          //   setTimeout(
          //     () => {
          //       $('#bt_' + this.securityBridge.application).click();
          //     }, 500
          //   );
          // }
          this.listObjectModel.push(model);
          this.ids.push(model.id);
        }
        // this.selectedModelChart.push(res.items[0].name);
      },
      err => {
        console.error(err);
      },
      () => {
        $('#spinner_getListApprovedModel' + this.selectedApplicationId).hide();
        $('#getListApprovedModel' + this.selectedApplicationId).show('slow');
        $('#model_pagin_search' + this.selectedApplicationId).attr('style', 'display: flex!important');
        $('#saveQcmApp' + this.selectedApplicationId).prop('disabled', true).removeClass('color-8');//.html('save')
        removeLoader();
        console.log(this.securityBridge)
        if (this.securityBridge.model != '') {
          this.modelFilter = this.securityBridge.model;
          setTimeout(
            () => {
              // $('#bt_' + this.modelNameREdirect).click();
              $('button[data-name=\'' + this.modelNameREdirect + '\']')[0].click();
            }, 500
          );
        }
      }
    );
    this.subscription.add(subscription4$);
  }
  // get list objectModel by status Approved/Archived in table collapse (tabs: Models) {change model by effective date}
  onGetChangeModelFromObjectModelsList(idModel, index, event) {
    const select = $(event.target);
    const id = select.children('option:selected').val();
    this.selectedObjectModelID = id;
    const grandParent = select.parent().parent();
    this.selectedObjectModelIDAncien = grandParent.children().eq(8).children().attr('data-name');

    this.changedModel = [];
    let subscription5$ = this.objectService.getChangeModelFromObjectModelsList(id).subscribe(
      data => {
        this.changedModel = data;
        data.status === 'Approved' ? this.versionApprovedQcm = true : this.versionApprovedQcm = false;
        grandParent.parent().animate({
          opacity: 0
        }, 500, 'linear', function () {
        });
        function getIconeLevelScore(val) {
          let ic;
          switch (val) {
            case 0:
              ic = '<i style=\'color: gray\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 1:
              ic = '<i style=\'color: green\' class=\'fas fa-exclamation-triangle\'></i>';
              break;

            case 2:
              ic = '<i style=\'color: orange\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 3:
              ic = '<i style=\'color: red\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
          }
          return ic;
        }
        // grandParent.children().eq(1).html(data.status);
        // grandParent.children().eq(3).html(data.countSensitiveColModel);
        // grandParent.children().eq(4).html('' +
        //   '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
        //   '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
        //   + data.percentCompletion + '" role="progressbar" " aria-valuemin="0" aria-valuemax="100">' +
        //   data.percentCompletion + '%</div></div>');
        // grandParent.children().eq(5).html(getIconeLevelScore(data.levelScoreModel));
        // grandParent.children().eq(6).html(data.dateScoreModel);
        // grandParent.children().eq(7).html(data.userScoreModel);
        if (data.status === 'Archived') {
          grandParent.children().eq(0).css('color', 'red');
          grandParent.children().eq(1).css('color', 'red');
          grandParent.children().eq(2).css('color', 'red');
          grandParent.children().eq(3).css('color', 'red');
          grandParent.children().eq(4).css('color', 'red');
          grandParent.children().eq(5).css('color', 'red');
          grandParent.children().eq(6).css('color', 'red');
          grandParent.children().eq(7).css('color', 'red');
        } else {
          grandParent.children().eq(0).css('color', 'black');
          grandParent.children().eq(1).css('color', 'black');
          grandParent.children().eq(2).css('color', 'black');
          grandParent.children().eq(3).css('color', 'black');
          grandParent.children().eq(4).css('color', 'black');
          grandParent.children().eq(5).css('color', 'black');
          grandParent.children().eq(6).css('color', 'black');
          grandParent.children().eq(7).css('color', 'black');
        }
        // const index = grandParent.children().eq(8).children().eq(0).attr('id');
        // grandParent.attr('id', this.selectedApplicationId + data.id);
        // grandParent.next().attr('id', 'bodyModel' + this.selectedApplicationId + data.id);
        // grandParent.next().children().eq(2).attr('id', 'object_pagin_search' + this.selectedApplicationId + data.id);
        // grandParent.next().children().eq(3).attr('id', 'listObjects' + this.selectedApplicationId + data.id);
        // grandParent.next().children().eq(0).children().eq(0).children().eq(0).attr('id', 'icon_double_model' + this.selectedApplicationId + data.id);
        // grandParent.next().children().eq(3).children().eq(0).children().eq(0).attr('id', 'spinner_getListApprovedObject' + this.selectedApplicationId + data.id);
        // grandParent.next().children().eq(3).children().eq(0).children().eq(1).attr('id', 'getListApprovedObject' + this.selectedApplicationId + data.id);
        // grandParent.next().children().eq(1).attr('id', 'qcm_model_' + this.selectedApplicationId + data.id);
        grandParent.children().eq(8).children().attr('data-name', data.id);
        grandParent.parent().animate({
          opacity: 1
        }, 1000, 'linear', function () {
        });
      },
      err => {
        console.error(err);
      },
      () => {
        let indexModel = this.listObjectModel.findIndex(o => o.id === this.selectedObjectModelIDAncien);
        // console.log(this.listObjectModel);
        // this.listObjectModel[indexModel] = this.changedModel;
        this.listObjectModel[indexModel].levelScoreModel = this.changedModel['levelScoreModel'];
        this.listObjectModel[indexModel].dateScoreModel = this.changedModel['dateScoreModel'];
        this.listObjectModel[indexModel].userScoreModel = this.changedModel['userScoreModel'];
        this.listObjectModel[indexModel].leveleditedinModel = this.changedModel['leveleditedinModel'];
        this.listObjectModel[indexModel].dataAccessModel = this.changedModel['dataAccessModel'];
        this.listObjectModel[indexModel].accessLocationModel = this.changedModel['accessLocationModel'];
        this.listObjectModel[indexModel].identifiabilityModel = this.changedModel['identifiabilityModel'];
        this.listObjectModel[indexModel].quantityModel = this.changedModel['quantityModel'];
        this.listObjectModel[indexModel].percentCompletion = this.changedModel['percentCompletion'];
        this.listObjectModel[indexModel].countSensitiveColModel = this.changedModel['countSensitiveColModel'];
        this.listObjectModel[indexModel].effectiveDate = this.changedModel['effectiveDate'];
        this.listObjectModel[indexModel].id = this.changedModel['id'];
        this.listObjectModel[indexModel].modelType = this.changedModel['modelType'];
        this.listObjectModel[indexModel].status = this.changedModel['status'];
        this.listObjectModel[indexModel].objectModelsArch = this.changedModel['objectModelsArch'];
        // data Access
        if (this.listObjectModel[indexModel].dataAccessModel == 0) {
          $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
        } else if (this.listObjectModel[indexModel].dataAccessModel == 1) {
          $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
        } else if (this.listObjectModel[indexModel].dataAccessModel == 2) {
          $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
        } else if (this.listObjectModel[indexModel].dataAccessModel == 3) {
          $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
        }
        // access location
        if (this.listObjectModel[indexModel].accessLocationModel == 0) {
          $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
        } else if (this.listObjectModel[indexModel].accessLocationModel == 1) {
          $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
        } else if (this.listObjectModel[indexModel].accessLocationModel == 2) {
          $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
        } else if (this.listObjectModel[indexModel].accessLocationModel == 3) {
          $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
        }
        // identifiability
        if (this.listObjectModel[indexModel].identifiabilityModel == 0) {
          $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
        } else if (this.listObjectModel[indexModel].identifiabilityModel == 1) {
          $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
        } else if (this.listObjectModel[indexModel].identifiabilityModel == 2) {
          $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
        } else if (this.listObjectModel[indexModel].identifiabilityModel == 3) {
          $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
        }
        if (this.listObjectModel[indexModel].quantityModel == 0) {
          $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
        } else if (this.listObjectModel[indexModel].quantityModel == 1) {
          $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
        } else if (this.listObjectModel[indexModel].quantityModel == 2) {
          $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
        } else if (this.listObjectModel[indexModel].quantityModel == 3) {
          $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
        }
      }
    );
    this.subscription.add(subscription5$);
  }
  // get ObjectModel if au moin un model a un QCM pour appliquer le QSM de l'application au Models sélectionnés
  onGetObjectToDatatableSQM() {
    this.listAdObjectCheck = [];
    const that = this;
    $('#ListObjectQCM').empty();
    if ($('#objectQSM').length > 0) {
      $('#objectQSM').remove();
    }
    $('#ListObjectQCM').append('<table id="objectQSM" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
      '<tr>' +
      '<th>Name</th>' +
      '<th>Type</th>' +
      '<th>Level Score</th>' +
      '<th>Updated By</th>' +
      '<th>Updated At</th>' +
      '<th>' +
      ' <label style="margin-bottom: 0; margin-right: -16px;">' +
      '  <input type="checkbox" id="check_object_all" value="all"/>' +
      '  <span></span>' +
      ' </label>' +
      '</th>' +
      '</tr>' +
      '</thead></table>');

    $('#objectQSM').DataTable({
      processing: true,
      destroy: true,
      pageLength: 5,
      rowCallback(row, data) {
        function getIconeLevelScore(val) {
          let ic;
          switch (val) {
            case 0:
              ic = '<i style=\'color: gray\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 1:
              ic = '<i style=\'color: green\' class=\'fas fa-exclamation-triangle\'></i>';
              break;

            case 2:
              ic = '<i style=\'color: orange\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 3:
              ic = '<i style=\'color: red\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
          }
          return ic;
        }
        function getIconeTypeObject(val) {
          let ic;
          switch (val) {
            case 'Physical Person':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-user-circle\'></i>';
              break;
            case 'Physical person':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-user-circle\'></i>';
              break;

            case 'Legal Entity':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-building\'></i>';
              break;
            case 'Legal entity':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-building\'></i>';
              break;

            case 'Data Repository':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-warehouse\'></i>';
              break;
            case 'Data repository':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-warehouse\'></i>';
              break;

            case 'Technical Data':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-cogs\'></i>';
              break;
            case 'Technical data':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-cogs\'></i>';
              break;

            case 'Operational Data':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-database\'></i>';
              break;
            case 'Operational data':
              ic = '<i style=\'color: #257EAD\' class=\'fas fa-database\'></i>';
              break;

            case 'no_type':
              ic = '<i style=\'color: #797276\' class=\'fas fa-times\'></i>';
              break;
          }
          return ic;
        }
        $('td:eq(1)', row).html('<p style="text-align: center">' + getIconeTypeObject(data.type) + '</p>');
        $('td:eq(2)', row).html('<p style="text-align: center">' + getIconeLevelScore(data.levelScore) + '</p>');
      },
      ajax: {
        url: Shared.API_URL + '/api/drm-security/getObjectBeforeSaveQcm/' + this.selectedObjectModelID,
        contentType: 'application/json',
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      columns: [
        { data: 'name', title: this.lang ? 'Name' : 'Nom' },
        { data: 'effectiveDate', title: this.lang ? 'Effective Date' : 'Date d\'effet' },
        { data: 'levelScore', title: this.lang ? 'Level Score' : 'Score de niveau' },
        { data: 'userScore', title: this.lang ? 'Updated By' : 'Mis à jour par' },
        { data: 'dateScore', title: this.lang ? 'Updated At' : 'Mis à jour le' },
        {
          data: 'id',
          render: function (data, type, row) {
            return '<label style=\'margin-bottom: 0; margin-right: -16px;\'>' +
              '                    <input type=\'checkbox\' class="editor-active" id=\'' + data + '\'/>' +
              '                    <span></span>' +
              '                  </label>';
          }
        }
      ],
      order: [[2, 'desc']],
      initComplete(settings, json) {
        const table = this.api();
        // Handle click on "Select all" control
        $('#check_object_all').on('click', function () {
          that.listAdObjectCheck = [];
          if ($('#check_object_all').prop('checked')) {
            that.chechAllObject = true;
            // console.log(that.chechAllObject);
            that.listAdObjectCheck = that.listAdObjectPreCheck;
          } else {
            that.chechAllObject = false;
            // console.log(that.chechAllObject);
          }
          // Get all rows with search applied
          var rows = table.rows({ search: 'applied' }).nodes();
          // Check/uncheck checkboxes for all rows in the table
          $('input[type="checkbox"]', rows).prop('checked', this.checked);
          if (that.listAdObjectCheck.length > 0) {
            $('#qcm_object_modal_submit').prop('disabled', false);
          } else {
            $('#qcm_object_modal_submit').prop('disabled', true);
          }
        });
        // Handle click on checkbox to set state of "Select all" control
        $('#objectQSM tbody').on('change', 'input[type="checkbox"]', function () {
          that.chechAllObject = false;
          // console.log(that.chechAllObject);
          // If checkbox is not checked
          if (!this.checked) {
            var el = $('#check_object_all').get(0);
            // If "Select all" control is checked and has 'indeterminate' property
            if (el && el.checked && ('indeterminate' in el)) {
              // Set visual state of "Select all" control
              // as 'indeterminate'
              el.indeterminate = true;
            }
          }
        });
        $('#objectQSM').on('change', 'input.editor-active', function (event) {
          that.chechAllObject = false;
          // console.log(that.chechAllObject);
          if ($(event.target).prop('checked')) {
            that.listAdObjectCheck.push($(event.target).attr('id'));
            // console.log(that.listAdObjectCheck.length);
            if (that.listAdObjectCheck.length > 0) {
              $('#qcm_object_modal_submit').prop('disabled', false);
            } else {
              $('#qcm_object_modal_submit').prop('disabled', true);
            }
          } else {
            that.listAdObjectCheck.splice(that.listAdObjectCheck.indexOf($(event.target).attr('id')), 1);
            // console.log(that.listAdObjectCheck.length);
            if (that.listAdObjectCheck.length > 0) {
              $('#qcm_object_modal_submit').prop('disabled', false);
            } else {
              $('#qcm_object_modal_submit').prop('disabled', true);
            }
          }
        });
      }
    });
    $('#listObject').modal('show');
  }
  // teste l'existance d'un objet fils contient un SCORE
  onGetObjectBeforeSaveQcm() {
    this.listAdObjectPreCheck = [];
    let subscription6$ = this.drmClassifService.existObjectBeforeShowModalQcm(this.selectedObjectModelID).subscribe(
      result => {
        result.map(item => this.listAdObjectPreCheck.push(item));
        if (this.listAdObjectPreCheck.length > 0) {
          this.onGetObjectToDatatableSQM();
        } else {
          this.chechAllObject = true;
          this.onSaveQcmModel();
        }
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription6$);
  }
  ////////////// END MODELS //////////////
  ////////////// START OBJECTS //////////////
  // show informations from selected model (show Detail/Back)
  // hide show collapcible body
  // showHideListMenuByObject(id) {
  //   $('#listMenuByObject' + id).toggle();
  //   if ($('#i_' + id ).hasClass('fa-angle-double-up')) {
  //     $('#i_' + id ).removeClass('fa-angle-double-up');
  //     $('#i_' + id ).toggleClass('fa-angle-double-down');
  //   } else {
  //     $('#i_' + id ).removeClass('fa-angle-double-down');
  //     $('#i_' + id ).toggleClass('fa-angle-double-up');
  //   }
  // }
  // sort object model
  setOrder(value: string) {
    if (this.order === value) {
      this.reverse = !this.reverse;
    }

    this.order = value;
  }
  // comparator sort
  sortAlphaNum(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  pageModelChanged(event) {
    this.modelconfig.currentPage = event;
  }
  getPage() {
    this.modelconfig.itemsPerPage = this.selectedPageSize;
    this.modelconfig.currentPage = 1;
    this.modelconfig.totalItems = this.listObjectModel.length;
  }
  showDetailModel(id, code, index, event) {
    // let id = $(event.target).attr('data-name');
    let that = this;
    this.selectedObjectModelID = id;
    let indexModel = this.listObjectModel.findIndex(o => o.id === this.selectedObjectModelID);
    this.listObjectModel[indexModel].status === 'Approved' ? this.versionApprovedQcm = true : this.versionApprovedQcm = false;
    this.indexSelectedModelQSM = indexModel;
   // this.selectedModel = name;
    $('#spinner_getListApprovedObject' + this.selectedApplicationId + this.selectedObjectModelID).show();
    $('#getListApprovedObject' + this.selectedApplicationId + this.selectedObjectModelID).hide('slow');
    $('#object_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID).attr('style', 'display: none!important');
    // const oid = $(event.target).parent().parent().attr('id');
    // id = oid;
    // this.selectedObjectModelCode = [];
    // this.selectedObjectModelCode.push($('#' + id).children().html());
    // this.selectedModel = this.selectedObjectModelCode[0];
    const ul = $('#' + this.selectedApplicationId + this.selectedObjectModelID).parent().parent();
    const li = $('#' + this.selectedApplicationId + this.selectedObjectModelID).parent();
    if (!this.flag) {
      this.onGetObjectsList(this.selectedObjectModelID);
      this.flag = true;
      $('#bodyModel' + this.selectedApplicationId + this.selectedObjectModelID).slideDown();
      // event.target.innerHTML = 'Back';
      $('#model_pagin_search' + this.selectedApplicationId).attr('style', 'display: none!important');
      // $('#apps-search').hide('slow');
      ul.children().hide();
      $('#titleModel' + this.selectedApplicationId).show('slow');
      li.show('slow');
      // $('#arrow_indicators' + id).click();
      $('input[name=dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + this.listObjectModel[index].dataAccessModel + ']').prop('checked', true);
      $('input[name=accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + this.listObjectModel[index].accessLocationModel + ']').prop('checked', true);
      $('input[name=identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + this.listObjectModel[index].identifiabilityModel + ']').prop('checked', true);
      $('input[name=quantityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + this.listObjectModel[index].quantityModel + ']').prop('checked', true);
      $('#qcm_model_' + this.selectedApplicationId + this.selectedObjectModelID + ' input[type="radio"]').on('click change', function (e) {
        if (that.securityRiskUpdate && that.versionApprovedQcm) {
          if (($('input[name=\'dataAccessModel' + that.selectedApplicationId + that.selectedObjectModelID + '\']:checked').val() != null)
            && ($('input[name=\'accessLocationModel' + that.selectedApplicationId + that.selectedObjectModelID + '\']:checked').val() != null)
            && ($('input[name=\'identifiabilityModel' + that.selectedApplicationId + that.selectedObjectModelID + '\']:checked').val() != null)
            && ($('input[name=\'quantityModel' + that.selectedApplicationId + that.selectedObjectModelID + '\']:checked').val() != null)) {
            $('#saveQcmModel' + that.selectedApplicationId + that.selectedObjectModelID).prop('disabled', false).removeClass('color-8');
            $('#cancelQcmModel' + that.selectedApplicationId + that.selectedObjectModelID).prop('disabled', false).removeClass('color-2');
          }
          if ($('#saveQcmModel' + that.selectedApplicationId + that.selectedObjectModelID).prop('disabled') === false) {
            $('#saveQcmModel' + that.selectedApplicationId + that.selectedObjectModelID).addClass('color-8');
            $('#cancelQcmModel' + that.selectedApplicationId + that.selectedObjectModelID).addClass('color-2');
          } else {
            $('#saveQcmModel' + that.selectedApplicationId + that.selectedObjectModelID).removeClass('color-8');
            $('#cancelQcmModel' + that.selectedApplicationId + that.selectedObjectModelID).removeClass('color-2');
          }
        }
      });
      if (this.securityBridge.component === 'project') {
        this.showBack = true;
      }
    } else {
      this.modelFilter = null;
      this.onCancelQcmModel();
      $('#model_pagin_search' + this.selectedApplicationId).attr('style', 'display: flex!important');
      // end hide
      this.hiderIndicators = true;
      // event.target.innerHTML = 'Show Details';
      this.flag = false;
      $('#bodyModel' + this.selectedApplicationId + this.selectedObjectModelID).slideUp();
      ul.children().show('slow');
    }
  }
  // QSM MODEL
  onSaveQcmModel() {
    if (this.securityRiskUpdate && this.versionApprovedQcm) {
      if (this.chechAllObject) {
        this.listAdObjectCheck = [];
        this.listAdObjectCheck.push('all');
      }
      $('#cancelQcmModel' + this.selectedApplicationId + this.selectedObjectModelID).prop('disabled', true).removeClass('color-2');
      $('#saveQcmModel' + this.selectedApplicationId + this.selectedObjectModelID).prop('disabled', true).removeClass('color-8');
      // .html('<div class="spinner-grow spinner-grow-sm" style="color: #fbfbff" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '</div>');
      $('#listObject').modal('hide');
      addLoader(this.lang === 'en' ? 'SAVING&#160;&#160;DATA&#160;. . .' : 'ENREGISTREMENT&#160;&#160;DONNÉES&#160;. . .');
      let dataAccessModel = $('input[name=\'dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID + '\']:checked').val();
      let accessLocationModel = $('input[name=\'accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID + '\']:checked').val();
      let identifiabilityModel = $('input[name=\'identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID + '\']:checked').val();
      let quantityModel = $('input[name=\'quantityModel' + this.selectedApplicationId + this.selectedObjectModelID + '\']:checked').val();
      let subscription7$ = this.drmClassifService.saveQcmModel(this.selectedObjectModelID,
        dataAccessModel, accessLocationModel, identifiabilityModel, quantityModel,
        this.listAdObjectCheck).subscribe(
          data => {
            // edit level in
            $('#qcm_model_' + this.selectedApplicationId + this.selectedObjectModelID).children().children().children().children().eq(0).html(' Assessment Status: <b>Model</b>');
            // progressBar
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(4).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.model[0].percentCompletion + '" role="progressbar" " aria-valuemin="0" aria-valuemax="100">' +
              data.model[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.model[0].levelScoreModel == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].levelScoreModel == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].levelScoreModel == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].levelScoreModel == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(6).html(data.model[0].dateScoreModel);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(7).html(data.model[0].userScoreModel);
            // data Access
            if (data.model[0].dataAccessModel == 0) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].dataAccessModel == 1) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].dataAccessModel == 2) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].dataAccessModel == 3) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.model[0].accessLocationModel == 0) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].accessLocationModel == 1) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].accessLocationModel == 2) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].accessLocationModel == 3) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.model[0].identifiabilityModel == 0) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].identifiabilityModel == 1) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].identifiabilityModel == 2) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].identifiabilityModel == 3) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.model[0].quantityModel == 0) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].quantityModel == 1) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].quantityModel == 2) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].quantityModel == 3) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // m à j selected Model
            let indexModel = this.listObjectModel.findIndex(o => o.id === this.selectedObjectModelID);
            this.listObjectModel[indexModel].levelScoreModel = data.model[0].levelScoreModel;
            this.listObjectModel[indexModel].dateScoreModel = data.model[0].dateScoreModel;
            this.listObjectModel[indexModel].userScoreModel = data.model[0].userScoreModel;
            this.listObjectModel[indexModel].leveleditedinModel = data.model[0].leveleditedinModel;
            this.listObjectModel[indexModel].dataAccessModel = data.model[0].dataAccessModel;
            this.listObjectModel[indexModel].accessLocationModel = data.model[0].accessLocationModel;
            this.listObjectModel[indexModel].identifiabilityModel = data.model[0].identifiabilityModel;
            this.listObjectModel[indexModel].quantityModel = data.model[0].quantityModel;
            this.listObjectModel[indexModel].percentCompletion = data.model[0].percentCompletion;
            // m à j selected Application
            let indexAppl = this.listAplications.findIndex(o => o.id === this.selectedApplicationId);
            this.listAplications[indexAppl]['levelScore'] = data.application[0].levelScore;
            this.listAplications[indexAppl]['dateScore'] = data.application[0].dateScore;
            this.listAplications[indexAppl]['userScore'] = data.application[0].userScore;
            this.listAplications[indexAppl]['leveleditedin'] = data.application[0].leveleditedin;
            this.listAplications[indexAppl]['dataAccessApp'] = data.application[0].dataAccessApp;
            this.listAplications[indexAppl]['accessLocationApp'] = data.application[0].accessLocationApp;
            this.listAplications[indexAppl]['identifiabilityApp'] = data.application[0].identifiabilityApp;
            this.listAplications[indexAppl]['quantityApp'] = data.application[0].quantityApp;
            this.listAplications[indexAppl]['percentCompletion'] = data.application[0].percentCompletion;
          },
          err => {
            console.error(err);
          },
          () => {
            this.flagObject = false;
            this.flagRecord = false;
            this.flagField = false;
            this.onGetObjectsList(this.selectedObjectModelID);
            $('#saveQcmModel' + this.selectedApplicationId + this.selectedObjectModelID).prop('disabled', true).removeClass('color-8');//.html('save')
            $('#cancelQcmModel' + this.selectedApplicationId + this.selectedObjectModelID).prop('disabled', true).removeClass('color-2');
          }
        );
      this.subscription.add(subscription7$);
    }
  }
  onCancelQcmModel() {
    let modl = this.listObjectModel.find(o => o.id === this.selectedObjectModelID);
    $('#saveQcmModel' + this.selectedApplicationId + this.selectedObjectModelID).prop('disabled', true).removeClass('color-8');//.html('save')
    $('#cancelQcmModel' + this.selectedApplicationId + this.selectedObjectModelID).prop('disabled', true).removeClass('color-2');
    $('input[name=dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + modl.dataAccessModel + ']').prop('checked', true);
    $('input[name=accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + modl.accessLocationModel + ']').prop('checked', true);
    $('input[name=identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + modl.identifiabilityModel + ']').prop('checked', true);
    $('input[name=quantityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + modl.quantityModel + ']').prop('checked', true);
  }
  // hide show collapcible body Model
  showHideQcmModel() {
    $('#qcm_model_' + this.selectedApplicationId + this.selectedObjectModelID).toggle();
    if ($('#icon_double_model' + this.selectedApplicationId + this.selectedObjectModelID).hasClass('fa-angle-double-down')) {
      $('#icon_double_model' + this.selectedApplicationId + this.selectedObjectModelID).removeClass('fa-angle-double-down');
      $('#icon_double_model' + this.selectedApplicationId + this.selectedObjectModelID).toggleClass('fa-angle-double-up');
    } else {
      $('#icon_double_model' + this.selectedApplicationId + this.selectedObjectModelID).removeClass('fa-angle-double-up');
      $('#icon_double_model' + this.selectedApplicationId + this.selectedObjectModelID).toggleClass('fa-angle-double-down');
    }
  }
  ///////////////// END MODELS ///////////////
  // get liste des objets by selected model in SQM
  onGetObjectsList(idModel) {
    let subscription8$ = this.drmClassifService.getObjectsList(idModel).subscribe(
      data => {
        this.listObjects = [];
        this.idsObjets = [];
        for (const objet of data.items) {
          this.listObjects.push(objet);
          this.idsObjets.push(objet.id);
          if (this.securityBridge.object == objet.id) {
            this.objectNameREdirect = objet.name;
          }
        }
      },
      err => {
        console.error(err);
      },
      () => {
        $('#spinner_getListApprovedObject' + this.selectedApplicationId + this.selectedObjectModelID).hide();
        $('#getListApprovedObject' + this.selectedApplicationId + this.selectedObjectModelID).show('slow');
        $('#object_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID).attr('style', 'display: flex!important');
        removeLoader();
        if (this.securityBridge.object != '') {
          this.objectFilter = this.objectNameREdirect;
          setTimeout(
            () => {
              // $('#bt_' + this.securityBridge.application + this.securityBridge.model + this.securityBridge.object).click();
              $('button[data-name=\'' + this.securityBridge.object + '\']')[0].click();
            }, 500
          );
        }
      }
    );
    this.subscription.add(subscription8$);
  }
  // sort object
  setOrderObject(value: string) {
    if (this.orderObj === value) {
      this.reverseObj = !this.reverseObj;
    }

    this.orderObj = value;
  }
  // comparator sort object
  sortAlphaNumObject(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  pageObjectChanged(event) {
    this.objectconfig.currentPage = event;
  }
  getPageObject() {
    this.objectconfig.itemsPerPage = this.selectedPageSizeObj;
    this.objectconfig.currentPage = 1;
    this.objectconfig.totalItems = this.listObjects.length;
  }
  showDetailObject(idObj, nameObj, index, event) {
    let that = this;
    this.selectedObjectId = idObj;
    this.indexSelectedObjectQSM = index;
    this.selectedObject = nameObj;
    $('#spinner_getListApprovedRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).show();
    $('#getListApprovedRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).hide('slow');
    $('#record_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).attr('style', 'display: none!important');
    let idSelectedModel = this.selectedObjectModelID;
    let idSelectedAppl = this.selectedApplicationId;
    // console.log(idObj);
    // console.log('---');
    const li = $('#' + this.selectedApplicationId + this.selectedObjectModelID + idObj).parent();
    const ul = li.parent();
    if (!this.flagObject) {
      this.onGetRecordsList(this.selectedObjectModelID, idObj);
      this.flagObject = true;
      ul.children().hide();
      li.show('slow');
      $('#titleObject' + this.selectedApplicationId + this.selectedObjectModelID).show('slow');
      $('#bodyObject' + this.selectedApplicationId + this.selectedObjectModelID + idObj).slideDown();
      // event.target.innerHTML = 'Back';
      $('#object_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID).attr('style', 'display: none!important');
      $('#qcm_object_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + ' input[type="radio"]').on('click change', function (e) {
        if (that.securityRiskUpdate && that.versionApprovedQcm) {
          if (($('input[name=\'dataAccessObject' + idSelectedAppl + idSelectedModel + idObj + '\']:checked').val() != null)
            && ($('input[name=\'accessLocationObject' + idSelectedAppl + idSelectedModel + idObj + '\']:checked').val() != null)
            && ($('input[name=\'identifiabilityObject' + idSelectedAppl + idSelectedModel + idObj + '\']:checked').val() != null)
            && ($('input[name=\'quantityObject' + idSelectedAppl + idSelectedModel + idObj + '\']:checked').val() != null)) {
            $('#saveQcmObject' + idSelectedAppl + idSelectedModel + idObj).prop('disabled', false).removeClass('color-8');
            $('#cancelQcmObject' + idSelectedAppl + idSelectedModel + idObj).prop('disabled', false).removeClass('color-2');
          }
          if ($('#saveQcmObject' + idSelectedAppl + idSelectedModel + idObj).prop('disabled') === false) {
            $('#saveQcmObject' + idSelectedAppl + idSelectedModel + idObj).addClass('color-8');
            $('#cancelQcmObject' + idSelectedAppl + idSelectedModel + idObj).addClass('color-2');
          } else {
            $('#saveQcmObject' + idSelectedAppl + idSelectedModel + idObj).removeClass('color-8');
            $('#cancelQcmObject' + idSelectedAppl + idSelectedModel + idObj).removeClass('color-2');
          }
        }

      });
    } else {
      this.onCancelQcmObject();
      $('#object_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID).attr('style', 'display: flex!important');
      $('#bodyObject' + this.selectedApplicationId + this.selectedObjectModelID + idObj).slideUp();
      // event.target.innerHTML = 'Show Details';
      this.flagObject = false;
      ul.children().show('slow');
    }
  }
  // get ObjectModel if au moin un model a un QCM pour appliquer le QSM de l'application au Models sélectionnés
  onGetRecordToDatatableSQM() {
    this.listAdRecordCheck = [];
    const that = this;
    $('#ListRecordQCM').empty();
    if ($('#recordQSM').length > 0) {
      $('#recordQSM').remove();
    }
    $('#ListRecordQCM').append('<table id="recordQSM" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
      '<tr>' +
      '<th>Name</th>' +
      '<th>Description</th>' +
      '<th>Level Score</th>' +
      '<th>Updated By</th>' +
      '<th>Updated At</th>' +
      '<th>' +
      ' <label style="margin-bottom: 0; margin-right: -16px;">' +
      '  <input type="checkbox" id="check_record_all" value="all"/>' +
      '  <span></span>' +
      ' </label>' +
      '</th>' +
      '</tr>' +
      '</thead></table>');

    $('#recordQSM').DataTable({
      processing: true,
      destroy: true,
      pageLength: 5,
      rowCallback(row, data) {
        function getIconeLevelScore(val) {
          let ic;
          switch (val) {
            case 0:
              ic = '<i style=\'color: gray\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 1:
              ic = '<i style=\'color: green\' class=\'fas fa-exclamation-triangle\'></i>';
              break;

            case 2:
              ic = '<i style=\'color: orange\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 3:
              ic = '<i style=\'color: red\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
          }
          return ic;
        }
        $('td:eq(2)', row).html('<p style="text-align: center">' + getIconeLevelScore(data.levelScore) + '</p>');
      },
      ajax: {
        url: Shared.API_URL + '/api/drm-security/getRecordBeforeSaveQcm/' + this.selectedObjectId,
        contentType: 'application/json',
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      columns: [
        { data: 'name', title: this.lang ? 'Name' : 'Nom' },
        { data: 'effectiveDate', title: this.lang ? 'Effective Date' : 'Date d\'effet' },
        { data: 'levelScore', title: this.lang ? 'Level Score' : 'Score de niveau' },
        { data: 'userScore', title: this.lang ? 'Updated By' : 'Mis à jour par' },
        { data: 'dateScore', title: this.lang ? 'Updated At' : 'Mis à jour le' },
        {
          data: 'id',
          render: function (data, type, row) {
            return '<label style=\'margin-bottom: 0; margin-right: -16px;\'>' +
              '                    <input type=\'checkbox\' class="editor-active" id=\'' + data + '\'/>' +
              '                    <span></span>' +
              '                  </label>';
          }
        }
      ],
      order: [[2, 'desc']],
      initComplete(settings, json) {
        const table = this.api();
        // Handle click on "Select all" control
        $('#check_record_all').on('click', function () {
          that.listAdRecordCheck = [];
          if ($('#check_record_all').prop('checked')) {
            that.chechAllRecord = true;
            // console.log(that.chechAllRecord);
            that.listAdRecordCheck = that.listAdRecordPreCheck;
          } else {
            that.chechAllRecord = false;
            // console.log(that.chechAllRecord);
          }
          // Get all rows with search applied
          var rows = table.rows({ search: 'applied' }).nodes();
          // Check/uncheck checkboxes for all rows in the table
          $('input[type="checkbox"]', rows).prop('checked', this.checked);
          if (that.listAdRecordCheck.length > 0) {
            $('#qcm_record_modal_submit').prop('disabled', false);
          } else {
            $('#qcm_record_modal_submit').prop('disabled', true);
          }
        });
        // Handle click on checkbox to set state of "Select all" control
        $('#recordQSM tbody').on('change', 'input[type="checkbox"]', function () {
          that.chechAllRecord = false;
          // console.log(that.chechAllRecord);
          // If checkbox is not checked
          if (!this.checked) {
            var el = $('#check_record_all').get(0);
            // If "Select all" control is checked and has 'indeterminate' property
            if (el && el.checked && ('indeterminate' in el)) {
              // Set visual state of "Select all" control
              // as 'indeterminate'
              el.indeterminate = true;
            }
          }
        });
        $('#recordQSM').on('change', 'input.editor-active', function (event) {
          that.chechAllRecord = false;
          // console.log(that.chechAllRecord);
          if ($(event.target).prop('checked')) {
            that.listAdRecordCheck.push($(event.target).attr('id'));
            // console.log(that.listAdRecordCheck.length);
            if (that.listAdRecordCheck.length > 0) {
              $('#qcm_record_modal_submit').prop('disabled', false);
            } else {
              $('#qcm_record_modal_submit').prop('disabled', true);
            }
          } else {
            that.listAdRecordCheck.splice(that.listAdRecordCheck.indexOf($(event.target).attr('id')), 1);
            // console.log(that.listAdRecordCheck.length);
            if (that.listAdRecordCheck.length > 0) {
              $('#qcm_record_modal_submit').prop('disabled', false);
            } else {
              $('#qcm_record_modal_submit').prop('disabled', true);
            }
          }
        });
      }
    });
    // $('#saveQcmObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).prop('disabled', false).html('save');
    $('#listRecord').modal('show');
  }
  // teste l'existance d'un Record fils contient un SCORE
  onGetRecordBeforeSaveQcm() {
    this.listAdRecordPreCheck = [];
    let subscription9$ = this.drmClassifService.existRecordBeforeShowModalQcm(this.selectedObjectId).subscribe(
      result => {
        result.map(item => this.listAdRecordPreCheck.push(item));
        if (this.listAdRecordPreCheck.length > 0) {
          this.onGetRecordToDatatableSQM();
        } else {
          this.chechAllRecord = true;
          this.onSaveQcmObject();
        }
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription9$);
  }
  // QSM OBJECT
  onSaveQcmObject() {
    if (this.securityRiskUpdate && this.versionApprovedQcm) {
      if (this.chechAllRecord) {
        this.listAdRecordCheck = [];
        this.listAdRecordCheck.push('all');
      }
      $('#cancelQcmObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).prop('disabled', true).removeClass('color-2');
      $('#saveQcmObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).prop('disabled', false).removeClass('color-8');
      // .html('<div class="spinner-grow spinner-grow-sm" style="color: #fbfbff" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '</div>');
      $('#listRecord').modal('hide');
      addLoader(this.lang === 'en' ? 'SAVING&#160;&#160;DATA&#160;. . .' : 'ENREGISTREMENT&#160;&#160;DONNÉES&#160;. . .');
      let dataAccessObject = $('input[name=\'dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '\']:checked').val();
      let accessLocationObject = $('input[name=\'accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '\']:checked').val();
      let identifiabilityObject = $('input[name=\'identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '\']:checked').val();
      let quantityObject = $('input[name=\'quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '\']:checked').val();
      let subscription10$ = this.drmClassifService.saveQcmObject(this.selectedObjectId,
        dataAccessObject, accessLocationObject, identifiabilityObject, quantityObject,
        this.listAdRecordCheck).subscribe(
          data => {
            // edit level in
            $('#qcm_object_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().children().children().children().eq(0).html(' Assessment Status: <b>Object</b>');
            // progressBar Object
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(3).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.object[0].percentCompletion + '" role="progressbar" aria-valuemin="0" aria-valuemax="100">' +
              data.object[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.object[0].levelScoreObject == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.object[0].levelScoreObject == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.object[0].levelScoreObject == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.object[0].levelScoreObject == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(5).html(data.object[0].dateScoreObject);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(6).html(data.object[0].userScoreObject);
            // data Access
            if (data.object[0].dataAccessObject == 0) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.object[0].dataAccessObject == 1) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.object[0].dataAccessObject == 2) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.object[0].dataAccessObject == 3) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.object[0].accessLocationObject == 0) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.object[0].accessLocationObject == 1) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.object[0].accessLocationObject == 2) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.object[0].accessLocationObject == 3) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.object[0].identifiabilityObject == 0) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.object[0].identifiabilityObject == 1) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.object[0].identifiabilityObject == 2) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.object[0].identifiabilityObject == 3) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.object[0].quantityObject == 0) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.object[0].quantityObject == 1) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.object[0].quantityObject == 2) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.object[0].quantityObject == 3) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }

            // m à j selected Model
            let indexObject = this.listObjects.findIndex(o => o.id === this.selectedObjectId);
            this.listObjects[indexObject].levelScoreObject = data.object[0].levelScoreObject;
            this.listObjects[indexObject].dateScoreObject = data.object[0].dateScoreObject;
            this.listObjects[indexObject].userScoreObject = data.object[0].userScoreObject;
            this.listObjects[indexObject].leveleditedinObject = data.object[0].leveleditedinObject;
            this.listObjects[indexObject].dataAccessObject = data.object[0].dataAccessObject;
            this.listObjects[indexObject].accessLocationObject = data.object[0].accessLocationObject;
            this.listObjects[indexObject].identifiabilityObject = data.object[0].identifiabilityObject;
            this.listObjects[indexObject].quantityObject = data.object[0].quantityObject;
            this.listObjects[indexObject].percentCompletion = data.object[0].percentCompletion;
            // m à j selected Model
            let indexModel = this.listObjectModel.findIndex(o => o.id === this.selectedObjectModelID);
            this.listObjectModel[indexModel].levelScoreModel = data.model[0].levelScoreModel;
            this.listObjectModel[indexModel].dateScoreModel = data.model[0].dateScoreModel;
            this.listObjectModel[indexModel].userScoreModel = data.model[0].userScoreModel;
            this.listObjectModel[indexModel].leveleditedinModel = data.model[0].leveleditedinModel;
            this.listObjectModel[indexModel].dataAccessModel = data.model[0].dataAccessModel;
            this.listObjectModel[indexModel].accessLocationModel = data.model[0].accessLocationModel;
            this.listObjectModel[indexModel].identifiabilityModel = data.model[0].identifiabilityModel;
            this.listObjectModel[indexModel].quantityModel = data.model[0].quantityModel;
            this.listObjectModel[indexModel].percentCompletion = data.model[0].percentCompletion;
            // edit level in
            $('#qcm_model_' + this.selectedApplicationId + this.selectedObjectModelID).children().children().children().children().eq(0).html(' Assessment Status: <b>Object</b>');
            // progressBar Model
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(4).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.model[0].percentCompletion + '" role="progressbar" aria-valuemin="0" aria-valuemax="100">' +
              data.model[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.model[0].levelScoreModel == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].levelScoreModel == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].levelScoreModel == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].levelScoreModel == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(6).html(data.model[0].dateScoreModel);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(7).html(data.model[0].userScoreModel);
            // data Access
            if (data.model[0].dataAccessModel == 0) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].dataAccessModel == 1) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].dataAccessModel == 2) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].dataAccessModel == 3) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.model[0].accessLocationModel == 0) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].accessLocationModel == 1) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].accessLocationModel == 2) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].accessLocationModel == 3) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.model[0].identifiabilityModel == 0) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].identifiabilityModel == 1) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].identifiabilityModel == 2) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].identifiabilityModel == 3) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.model[0].quantityModel == 0) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.model[0].quantityModel == 1) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.model[0].quantityModel == 2) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.model[0].quantityModel == 3) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            $('input[name=dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.model[0].dataAccessModel + ']').prop('checked', true);
            $('input[name=accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.model[0].accessLocationModel + ']').prop('checked', true);
            $('input[name=identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.model[0].identifiabilityModel + ']').prop('checked', true);
            $('input[name=quantityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.model[0].quantityModel + ']').prop('checked', true);
            // m à j selected Application
            let indexAppl = this.listAplications.findIndex(o => o.id === this.selectedApplicationId);
            this.listAplications[indexAppl]['levelScore'] = data.application[0].levelScore;
            this.listAplications[indexAppl]['dateScore'] = data.application[0].dateScore;
            this.listAplications[indexAppl]['userScore'] = data.application[0].userScore;
            this.listAplications[indexAppl]['leveleditedin'] = data.application[0].leveleditedin;
            this.listAplications[indexAppl]['dataAccessApp'] = data.application[0].dataAccessApp;
            this.listAplications[indexAppl]['accessLocationApp'] = data.application[0].accessLocationApp;
            this.listAplications[indexAppl]['identifiabilityApp'] = data.application[0].identifiabilityApp;
            this.listAplications[indexAppl]['quantityApp'] = data.application[0].quantityApp;
            this.listAplications[indexAppl]['percentCompletion'] = data.application[0].percentCompletion;
          },
          err => {
            console.error(err);
          },
          () => {
            this.flagRecord = false;
            this.flagField = false;
            this.onGetRecordsList(this.selectedObjectModelID, this.selectedObjectId);
            $('#saveQcmObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).prop('disabled', true).removeClass('color-8');//.html('save')
            $('#cancelQcmObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).prop('disabled', true).removeClass('color-2');
            // $('#saveQcmObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).prop( 'disabled', true ).html('save');
          }
        );
      this.subscription.add(subscription10$);
    }
  }
  onCancelQcmObject() {
    let obj = this.listObjects.find(o => o.id === this.selectedObjectId);
    $('#saveQcmObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).prop('disabled', true).removeClass('color-8');//.html('save')
    $('#cancelQcmObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).prop('disabled', true).removeClass('color-2');
    $('input[name=dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + obj.dataAccessObject + ']').prop('checked', true);
    $('input[name=accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + obj.accessLocationObject + ']').prop('checked', true);
    $('input[name=identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + obj.identifiabilityObject + ']').prop('checked', true);
    $('input[name=quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + obj.quantityObject + ']').prop('checked', true);
  }
  // hide show collapcible body Object
  showHideQcmObject() {
    $('#qcm_object_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).toggle();
    if ($('#icon_double_object' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).hasClass('fa-angle-double-down')) {
      $('#icon_double_object' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).removeClass('fa-angle-double-down');
      $('#icon_double_object' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).toggleClass('fa-angle-double-up');
    } else {
      $('#icon_double_object' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).removeClass('fa-angle-double-up');
      $('#icon_double_object' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).toggleClass('fa-angle-double-down');
    }
  }

  // get liste des record by selected object and model in SQM
  onGetRecordsList(idModel, idObject) {
    let subscription11$ = this.drmClassifService.getRecordsList(idModel, idObject).subscribe(
      data => {
        this.listRecords = [];
        this.idsRecords = [];
        for (const record of data.items) {
          this.listRecords.push(record);
          this.idsRecords.push(record.id);
          if (this.securityBridge.table == record.id) {
            this.tableNameREdirect = record.name;
          }
        }
      },
      err => {
        console.error(err);
      },
      () => {
        $('#spinner_getListApprovedRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).hide();
        $('#getListApprovedRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).show('slow');
        $('#record_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).attr('style', 'display: flex!important');
        removeLoader();
        if (this.securityBridge.table != '') {
          this.recordFilter = this.tableNameREdirect;
          setTimeout(
            () => {
              // $('#bt_' + this.securityBridge.application + this.securityBridge.model + this.securityBridge.object + this.securityBridge.table).click();
              $('button[data-name=\'' + this.securityBridge.table + '\']')[0].click();
            }, 500
          );
        }
      }
    );
    this.subscription.add(subscription11$);
  }
  // sort record
  setOrderRecord(value: string) {
    if (this.orderRecord === value) {
      this.reverseRecord = !this.reverseRecord;
    }

    this.orderRecord = value;
  }
  // comparator sort record
  sortAlphaNumRecord(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  pageRecordChanged(event) {
    this.recordconfig.currentPage = event;
  }
  getPageRecord() {
    this.recordconfig.itemsPerPage = this.selectedPageSizeRecord;
    this.recordconfig.currentPage = 1;
    this.recordconfig.totalItems = this.listRecords.length;
  }
  showDetailRecord(idRec, name, index, event) {
    let that = this;
    this.selectedRecordId = idRec;
    this.selectedRecord = name;
    this.indexSelectedRecordQSM = index;
    $('#spinner_getListApprovedField' + this.selectedObjectId + this.selectedRecordId).show();
    $('#getListApprovedField' + this.selectedObjectId + this.selectedRecordId).attr('style', 'display: none!important');
    $('#field_pagin_search' + this.selectedObjectId + this.selectedRecordId).attr('style', 'display: none!important');
    let idSelectedAppl = this.selectedApplicationId;
    let idSelectedModel = this.selectedObjectModelID;
    let idSelectedObject = this.selectedObjectId;
    // console.log(idObj);
    // console.log('---');
    const li = $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).parent();
    const ul = li.parent();
    if (!this.flagRecord) {
      this.onGetFieldsList(this.selectedObjectId, this.selectedRecordId);
      this.flagRecord = true;
      ul.children().hide();
      li.show('slow');
      $('#titleRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).show('slow');
      $('#bodyRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).slideDown();
      // event.target.innerHTML = 'Back';
      $('#record_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).attr('style', 'display: none!important');
      $('#qcm_record_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + ' input[type="radio"]').on('click change', function (e) {
        if (that.securityRiskUpdate && that.versionApprovedQcm) {
          if (($('input[name=\'dataAccessRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId + '\']:checked').val() != null)
            && ($('input[name=\'accessLocationRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId + '\']:checked').val() != null)
            && ($('input[name=\'identifiabilityRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId + '\']:checked').val() != null)
            && ($('input[name=\'quantityRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId + '\']:checked').val() != null)) {
            $('#saveQcmRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId).prop('disabled', false).removeClass('color-8');
            $('#cancelQcmRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId).prop('disabled', false).removeClass('color-2');
          }
          if ($('#saveQcmRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + idRec).prop('disabled') === false) {
            $('#saveQcmRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId).addClass('color-8');
            $('#cancelQcmRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId).addClass('color-2');
          } else {
            $('#saveQcmRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId).removeClass('color-8');
            $('#cancelQcmRecord' + idSelectedAppl + idSelectedModel + idSelectedObject + that.selectedRecordId).removeClass('color-2');
          }
        }
      });
    } else {
      this.onCancelQcmRecord();
      $('#record_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).attr('style', 'display: flex!important');
      $('#bodyRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + that.selectedRecordId).slideUp();
      // event.target.innerHTML = 'Show Details';
      this.flagRecord = false;
      ul.children().show('slow');
    }
  }
  // get ObjectModelClassif if au moin un field a un QCM pour appliquer le QSM de l'application au Record sélectionnés
  onGetFieldToDatatableSQM() {
    this.listAdFieldCheck = [];
    const that = this;
    $('#ListFieldQCM').empty();
    if ($('#fieldQSM').length > 0) {
      $('#fieldQSM').remove();
    }
    $('#ListFieldQCM').append('<table id="fieldQSM" class="table table-bordered table-striped js-basic-example dataTable" style="font-size: x-small;">   <thead>' +
      '<tr>' +
      '<th>Name</th>' +
      '<th>Level Score</th>' +
      '<th>Updated By</th>' +
      '<th>Updated At</th>' +
      '<th>' +
      ' <label style="margin-bottom: 0; margin-right: -16px;">' +
      '  <input type="checkbox" id="check_field_all" value="all"/>' +
      '  <span></span>' +
      ' </label>' +
      '</th>' +
      '</tr>' +
      '</thead></table>');

    $('#fieldQSM').DataTable({
      processing: true,
      destroy: true,
      pageLength: 5,
      rowCallback(row, data) {
        function getIconeLevelScore(val) {
          let ic;
          switch (val) {
            case 0:
              ic = '<i style=\'color: gray\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 1:
              ic = '<i style=\'color: green\' class=\'fas fa-exclamation-triangle\'></i>';
              break;

            case 2:
              ic = '<i style=\'color: orange\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
            case 3:
              ic = '<i style=\'color: red\' class=\'fas fa-exclamation-triangle\'></i>';
              break;
          }
          return ic;
        }
        $('td:eq(2)', row).html('<p style="text-align: center">' + getIconeLevelScore(data.levelScore) + '</p>');
      },
      ajax: {
        url: Shared.API_URL + '/api/drm-security/getFieldBeforeSaveQcm/' + this.selectedRecordId,
        contentType: 'application/json',
        headers: {
          Accept: 'Application/json',
          Authorization: 'Bearer ' + getCookieValue('tokendrd')
        },
      },
      columns: [
        { data: 'name', title: this.lang ? 'Name' : 'Nom' },
        { data: 'effectiveDate', title: this.lang ? 'Effective Date' : 'Date d\'effet' },
        { data: 'levelScore', title: this.lang ? 'Level Score' : 'Score de niveau' },
        { data: 'userScore', title: this.lang ? 'Updated By' : 'Mis à jour par' },
        { data: 'dateScore', title: this.lang ? 'Updated At' : 'Mis à jour le' },
        {
          data: 'id',
          render: function (data, type, row) {
            return '<label style=\'margin-bottom: 0; margin-right: -16px;\'>' +
              '                    <input type=\'checkbox\' class="editor-active" id=\'' + data + '\'/>' +
              '                    <span></span>' +
              '                  </label>';
          }
        }
      ],
      order: [[2, 'desc']],
      initComplete(settings, json) {
        const table = this.api();
        // Handle click on "Select all" control
        $('#check_field_all').on('click', function () {
          that.listAdFieldCheck = [];
          if ($('#check_field_all').prop('checked')) {
            that.chechAllField = true;
            that.listAdFieldCheck = that.listAdFieldPreCheck;
          } else {
            that.chechAllField = false;
          }
          // Get all rows with search applied
          var rows = table.rows({ search: 'applied' }).nodes();
          // Check/uncheck checkboxes for all rows in the table
          $('input[type="checkbox"]', rows).prop('checked', this.checked);
          if (that.listAdFieldCheck.length > 0) {
            $('#qcm_field_modal_submit').prop('disabled', false);
          } else {
            $('#qcm_field_modal_submit').prop('disabled', true);
          }
        });
        // Handle click on checkbox to set state of "Select all" control
        $('#fieldQSM tbody').on('change', 'input[type="checkbox"]', function () {
          that.chechAllField = false;
          // console.log(that.chechAllField);
          // If checkbox is not checked
          if (!this.checked) {
            var el = $('#check_field_all').get(0);
            // If "Select all" control is checked and has 'indeterminate' property
            if (el && el.checked && ('indeterminate' in el)) {
              // Set visual state of "Select all" control
              // as 'indeterminate'
              el.indeterminate = true;
            }
          }
        });
        $('#fieldQSM').on('change', 'input.editor-active', function (event) {
          that.chechAllField = false;
          // console.log(that.chechAllField);
          if ($(event.target).prop('checked')) {
            that.listAdFieldCheck.push($(event.target).attr('id'));
            // console.log(that.listAdFieldCheck.length);
            if (that.listAdFieldCheck.length > 0) {
              $('#qcm_field_modal_submit').prop('disabled', false);
            } else {
              $('#qcm_field_modal_submit').prop('disabled', true);
            }
          } else {
            that.listAdFieldCheck.splice(that.listAdFieldCheck.indexOf($(event.target).attr('id')), 1);
            // console.log(that.listAdFieldCheck.length);
            if (that.listAdFieldCheck.length > 0) {
              $('#qcm_field_modal_submit').prop('disabled', false);
            } else {
              $('#qcm_field_modal_submit').prop('disabled', true);
            }
          }
        });
      }
    });
    // $('#saveQcmRecord' + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).prop('disabled', false).html('save');
    $('#listField').modal('show');
  }
  // teste l'existance d'un Record fils contient un SCORE
  onGetFieldBeforeSaveQcm() {
    this.listAdFieldPreCheck = [];
    let subscription12$ = this.drmClassifService.existFieldBeforeShowModalQcm(this.selectedRecordId).subscribe(
      result => {
        result.map(item => this.listAdFieldPreCheck.push(item));
        if (this.listAdFieldPreCheck.length > 0) {
          this.onGetFieldToDatatableSQM();
        } else {
          this.chechAllField = true;
          this.onSaveQcmRecord();
        }
      },
      err => {
        console.error(err);
      }
    );
    this.subscription.add(subscription12$);
  }
  // QSM RECORD
  onSaveQcmRecord() {
    if (this.securityRiskUpdate && this.versionApprovedQcm) {
      if (this.chechAllField) {
        this.listAdFieldCheck = [];
        this.listAdFieldCheck.push('all');
      }
      $('#cancelQcmRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).prop('disabled', true).removeClass('color-2');
      $('#saveQcmRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).prop('disabled', false).removeClass('color-8');
      // .html('<div class="spinner-grow spinner-grow-sm" style="color: #fbfbff" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '</div>');
      $('#listField').modal('hide');
      addLoader(this.lang === 'en' ? 'SAVING&#160;&#160;DATA&#160;. . .' : 'ENREGISTREMENT&#160;&#160;DONNÉES&#160;. . .');
      let dataAccessRecord = $('input[name=\'dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '\']:checked').val();
      let accessLocationRecord = $('input[name=\'accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '\']:checked').val();
      let identifiabilityRecord = $('input[name=\'identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '\']:checked').val();
      let quantityRecord = $('input[name=\'quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '\']:checked').val();
      let subscription13$ = this.drmClassifService.saveQcmRecord(this.selectedRecordId,
        dataAccessRecord, accessLocationRecord, identifiabilityRecord, quantityRecord,
        this.listAdFieldCheck).subscribe(
          data => {
            // edit level in
            $('#qcm_record_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().children().children().children().eq(0).html(' Assessment Status: <b>Table</b>');
            // progressBar record
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(2).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.listRecords[0].percentCompletion + '" role="progressbar" aria-valuemin="0" aria-valuemax="100">' +
              data.listRecords[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.listRecords[0].levelScoreRecord == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(3).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].levelScoreRecord == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(3).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].levelScoreRecord == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(3).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].levelScoreRecord == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(3).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(4).html(data.listRecords[0].dateScoreRecord);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(5).html(data.listRecords[0].userScoreRecord);
            // data Access
            if (data.listRecords[0].dataAccessRecord == 0) {
              $('#dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].dataAccessRecord == 1) {
              $('#dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].dataAccessRecord == 2) {
              $('#dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].dataAccessRecord == 3) {
              $('#dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.listRecords[0].accessLocationRecord == 0) {
              $('#accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].accessLocationRecord == 1) {
              $('#accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].accessLocationRecord == 2) {
              $('#accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].accessLocationRecord == 3) {
              $('#accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.listRecords[0].identifiabilityRecord == 0) {
              $('#identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].identifiabilityRecord == 1) {
              $('#identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].identifiabilityRecord == 2) {
              $('#identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].identifiabilityRecord == 3) {
              $('#identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.listRecords[0].quantityRecord == 0) {
              $('#quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].quantityRecord == 1) {
              $('#quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].quantityRecord == 2) {
              $('#quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].quantityRecord == 3) {
              $('#quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }

            // edit level in
            $('#qcm_object_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().children().children().children().eq(0).html(' Assessment Status: <b>Table</b>');
            // progressBar Object
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(3).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.listObjects[0].percentCompletion + '" role="progressbar" aria-valuemin="0" aria-valuemax="100">' +
              data.listObjects[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.listObjects[0].levelScoreObject == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].levelScoreObject == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].levelScoreObject == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].levelScoreObject == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(5).html(data.listObjects[0].dateScoreObject);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(5).html(data.listObjects[0].userScoreObject);
            // data Access
            if (data.listObjects[0].dataAccessObject == 0) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].dataAccessObject == 1) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].dataAccessObject == 2) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].dataAccessObject == 3) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.listObjects[0].accessLocationObject == 0) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].accessLocationObject == 1) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].accessLocationObject == 2) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].accessLocationObject == 3) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.listObjects[0].identifiabilityObject == 0) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].identifiabilityObject == 1) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].identifiabilityObject == 2) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].identifiabilityObject == 3) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.listObjects[0].quantityObject == 0) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].quantityObject == 1) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].quantityObject == 2) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].quantityObject == 3) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // m à j button radio Object
            $('input[name=dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + data.listObjects[0].dataAccessObject + ']').prop('checked', true);
            $('input[name=accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + data.listObjects[0].accessLocationObject + ']').prop('checked', true);
            $('input[name=identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + data.listObjects[0].identifiabilityObject + ']').prop('checked', true);
            $('input[name=quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + data.listObjects[0].quantityObject + ']').prop('checked', true);

            // m à j selected Object
            let indexObject = this.listObjects.findIndex(o => o.id === this.selectedObjectId);
            this.listObjects[indexObject].levelScoreObject = data.listObjects[0].levelScoreObject;
            this.listObjects[indexObject].dateScoreObject = data.listObjects[0].dateScoreObject;
            this.listObjects[indexObject].userScoreObject = data.listObjects[0].userScoreObject;
            this.listObjects[indexObject].leveleditedinObject = data.listObjects[0].leveleditedinObject;
            this.listObjects[indexObject].dataAccessObject = data.listObjects[0].dataAccessObject;
            this.listObjects[indexObject].accessLocationObject = data.listObjects[0].accessLocationObject;
            this.listObjects[indexObject].identifiabilityObject = data.listObjects[0].identifiabilityObject;
            this.listObjects[indexObject].quantityObject = data.listObjects[0].quantityObject;
            this.listObjects[indexObject].percentCompletion = data.listObjects[0].percentCompletion;
            // m à j selected Model
            let indexModel = this.listObjectModel.findIndex(o => o.id === this.selectedObjectModelID);
            this.listObjectModel[indexModel].levelScoreModel = data.listModels[0].levelScoreModel;
            this.listObjectModel[indexModel].dateScoreModel = data.listModels[0].dateScoreModel;
            this.listObjectModel[indexModel].userScoreModel = data.listModels[0].userScoreModel;
            this.listObjectModel[indexModel].leveleditedinModel = data.listModels[0].leveleditedinModel;
            this.listObjectModel[indexModel].dataAccessModel = data.listModels[0].dataAccessModel;
            this.listObjectModel[indexModel].accessLocationModel = data.listModels[0].accessLocationModel;
            this.listObjectModel[indexModel].identifiabilityModel = data.listModels[0].identifiabilityModel;
            this.listObjectModel[indexModel].quantityModel = data.listModels[0].quantityModel;
            this.listObjectModel[indexModel].percentCompletion = data.listModels[0].percentCompletion;
            // edit level in
            $('#qcm_model_' + this.selectedApplicationId + this.selectedObjectModelID).children().children().children().children().eq(0).html(' Assessment Status: <b>Table</b>');
            // progressBar model
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(4).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.listModels[0].percentCompletion + '" role="progressbar" aria-valuemin="0" aria-valuemax="100">' +
              data.listModels[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.listModels[0].levelScoreModel == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].levelScoreModel == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].levelScoreModel == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].levelScoreModel == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(6).html(data.listModels[0].dateScoreModel);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(6).html(data.listModels[0].userScoreModel);
            // data Access
            if (data.listModels[0].dataAccessModel == 0) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].dataAccessModel == 1) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].dataAccessModel == 2) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].dataAccessModel == 3) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.listModels[0].accessLocationModel == 0) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].accessLocationModel == 1) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].accessLocationModel == 2) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].accessLocationModel == 3) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.listModels[0].identifiabilityModel == 0) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].identifiabilityModel == 1) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].identifiabilityModel == 2) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].identifiabilityModel == 3) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.listModels[0].quantityModel == 0) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].quantityModel == 1) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].quantityModel == 2) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].quantityModel == 3) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            $('input[name=dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.listModels[0].dataAccessModel + ']').prop('checked', true);
            $('input[name=accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.listModels[0].accessLocationModel + ']').prop('checked', true);
            $('input[name=identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.listModels[0].identifiabilityModel + ']').prop('checked', true);
            $('input[name=quantityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.listModels[0].quantityModel + ']').prop('checked', true);
            // m à j selected Application
            let indexAppl = this.listAplications.findIndex(o => o.id === this.selectedApplicationId);
            this.listAplications[indexAppl]['levelScore'] = data.listApplications[0].levelScore;
            this.listAplications[indexAppl]['dateScore'] = data.listApplications[0].dateScore;
            this.listAplications[indexAppl]['userScore'] = data.listApplications[0].userScore;
            this.listAplications[indexAppl]['leveleditedin'] = data.listApplications[0].leveleditedin;
            this.listAplications[indexAppl]['dataAccessApp'] = data.listApplications[0].dataAccessApp;
            this.listAplications[indexAppl]['accessLocationApp'] = data.listApplications[0].accessLocationApp;
            this.listAplications[indexAppl]['identifiabilityApp'] = data.listApplications[0].identifiabilityApp;
            this.listAplications[indexAppl]['quantityApp'] = data.listApplications[0].quantityApp;
            this.listAplications[indexAppl]['percentCompletion'] = data.listApplications[0].percentCompletion;
          },
          err => {
            console.error(err);
          },
          () => {
            this.flagField = false;
            this.onGetFieldsList(this.selectedObjectId, this.selectedRecordId);
            $('#saveQcmRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).prop('disabled', true).removeClass('color-8');//.html('save')
            $('#cancelQcmRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).prop('disabled', true).removeClass('color-2');
          }
        );
      this.subscription.add(subscription13$);
    }
  }
  onCancelQcmRecord() {
    let recor = this.listRecords.find(o => o.id === this.selectedRecordId);
    $('#saveQcmRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).prop('disabled', true).removeClass('color-8');//.html('save')
    $('#cancelQcmRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).prop('disabled', true).removeClass('color-2');
    $('input[name=dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '][value=' + recor.dataAccessRecord + ']').prop('checked', true);
    $('input[name=accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '][value=' + recor.accessLocationRecord + ']').prop('checked', true);
    $('input[name=identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '][value=' + recor.identifiabilityRecord + ']').prop('checked', true);
    $('input[name=quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '][value=' + recor.quantityRecord + ']').prop('checked', true);
  }
  // hide show collapcible body Record
  showHideQcmRecord() {
    $('#qcm_record_' + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).toggle();
    if ($('#icon_double_record' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).hasClass('fa-angle-double-down')) {
      $('#icon_double_record' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).removeClass('fa-angle-double-down');
      $('#icon_double_record' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).toggleClass('fa-angle-double-up');
    } else {
      $('#icon_double_record' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).removeClass('fa-angle-double-up');
      $('#icon_double_record' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).toggleClass('fa-angle-double-down');
    }
  }


  // get liste des fields (Classifs) by selected record(Tables) and Object in SQM
  onGetFieldsList(idObject, idRecord) {
    let subscription14$ = this.drmClassifService.getFieldsList(idObject, idRecord).subscribe(
      data => {

        this.listFields = [];
        this.idsFields = [];
        for (const field of data.items) {
          this.listFields.push(field);
          this.idsFields.push(field.id);
          if (this.securityBridge.field == field.id) {
            this.fieldNameREdirect = field.name;
          }
        }
      },
      err => {
        console.error(err);
      },
      () => {
        $('#spinner_getListApprovedField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).hide();
        $('#getListApprovedField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).attr('style', 'display: flex!important');
        $('#field_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).attr('style', 'display: flex!important');
        removeLoader();
        if (this.securityBridge.field != '') {
          this.fieldFilter = this.fieldNameREdirect;
          setTimeout(
            () => {
              // $('#bt_' + this.securityBridge.application + this.securityBridge.model + this.securityBridge.object + this.securityBridge.table + this.securityBridge.field).click();
              $('button[data-name=\'' + this.securityBridge.field + '\']')[0].click();
              if (this.securityBridge.component == 'objectModel') {
                this.showBack = true;
              }
              // console.log(this.securityBridge.component);
            }, 500
          );
        }
      }
    );
    this.subscription.add(subscription14$);
  }
  // sort field
  setOrderField(value: string) {
    if (this.orderField === value) {
      this.reverseField = !this.reverseField;
    }

    this.orderField = value;
  }
  // comparator sort field
  sortAlphaNumField(a, b) {
    if (a === b) {
      return 0;
    }
    if (typeof a === typeof b) {
      return a < b ? -1 : 1;
    }
    return typeof a < typeof b ? -1 : 1;
  }
  pageFieldChanged(event) {
    this.fieldconfig.currentPage = event;
  }
  getPageField() {
    this.fieldconfig.itemsPerPage = this.selectedPageSizeRecord;
    this.fieldconfig.currentPage = 1;
    this.fieldconfig.totalItems = this.listFields.length;
  }
  showDetailField(idField, nameField, index, event) {
    let that = this;
    this.selectedFieldId = idField;
    this.selectedField = nameField;
    this.indexSelectedFieldQSM = index;
    let idSelectedApplication = this.selectedApplicationId;
    let idSelectedModel = this.selectedObjectModelID;
    let idSelectedObject = this.selectedObjectId;
    let idSelectedRecord = this.selectedRecordId;
    // console.log(idObj);
    // console.log('---');
    const li = $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + idField).parent();
    const ul = li.parent();
    if (!this.flagField) {
      this.flagField = true;
      ul.children().hide();
      li.show('slow');
      $('#titleField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).show('slow');
      $('#bodyField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + idField).slideDown();
      // event.target.innerHTML = 'Back';
      $('#field_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).attr('style', 'display: none!important');
      $('#qcm_field_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + ' input[type="radio"]').on('click change', function (e) {
        if (that.securityRiskUpdate && that.versionApprovedQcm) {
          if (($('input[name=\'dataAccessField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField + '\']:checked').val() != null)
            && ($('input[name=\'accessLocationField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField + '\']:checked').val() != null)
            && ($('input[name=\'identifiabilityField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField + '\']:checked').val() != null)
            && ($('input[name=\'quantityField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField + '\']:checked').val() != null)) {
            $('#saveQcmField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField).prop('disabled', false).removeClass('color-8');
            $('#cancelQcmField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField).prop('disabled', false).removeClass('color-2');
          }
          if ($('#saveQcmField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField).prop('disabled') === false) {
            $('#saveQcmField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField).addClass('color-8');
            $('#cancelQcmField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField).addClass('color-2');
          } else {
            $('#saveQcmField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField).removeClass('color-8');
            $('#cancelQcmField' + idSelectedApplication + idSelectedModel + idSelectedObject + idSelectedRecord + idField).removeClass('color-2');
          }
        }
      });
    } else {
      this.onCancelQcmField();
      $('#field_pagin_search' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).attr('style', 'display: flex!important');
      $('#bodyField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).slideUp();
      // event.target.innerHTML = 'Show Details';
      this.flagField = false;
      ul.children().show('slow');
    }
  }
  // QSM Field
  onSaveQcmField() {
    if (this.securityRiskUpdate && this.versionApprovedQcm) {
      addLoader(this.lang === 'en' ? 'SAVING&#160;&#160;DATA&#160;. . .' : 'ENREGISTREMENT&#160;&#160;DONNÉES&#160;. . .');
      $('#cancelQcmField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).prop('disabled', true).removeClass('color-2');
      $('#saveQcmField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).prop('disabled', false).removeClass('color-8');
      // .html('<div class="spinner-grow spinner-grow-sm" style="color: #fbfbff" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '  <span class="sr-only">Loading...</span>\n' +
      //   '</div><div class="spinner-grow spinner-grow-sm" style="color: #fffcd3" role="status">\n' +
      //   '</div>');
      let dataAccessField = $('input[name=\'dataAccessField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + '\']:checked').val();
      let accessLocationField = $('input[name=\'accessLocationField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + '\']:checked').val();
      let identifiabilityField = $('input[name=\'identifiabilityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + '\']:checked').val();
      let quantityField = $('input[name=\'quantityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + '\']:checked').val();
      let subscription15$ = this.drmClassifService.saveQcmField(this.selectedFieldId,
        dataAccessField, accessLocationField, identifiabilityField, quantityField).subscribe(
          data => {
            // edit level in
            $('#qcm_field_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).children().children().children().children().eq(0).html(' Assessment Status: <b>Field</b>');
            // level Score
            if (data.listFields[0].levelScoreField == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).children().eq(1).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listFields[0].levelScoreField == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).children().eq(1).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listFields[0].levelScoreField == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).children().eq(1).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listFields[0].levelScoreField == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).children().eq(1).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).children().eq(2).html(data.listFields[0].dateScoreField);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).children().eq(3).html(data.listFields[0].userScoreField);
            // data Access
            if (data.listFields[0].dataAccessField == 0) {
              $('#dataAccessField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listFields[0].dataAccessField == 1) {
              $('#dataAccessField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listFields[0].dataAccessField == 2) {
              $('#dataAccessField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listFields[0].dataAccessField == 3) {
              $('#dataAccessField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.listFields[0].accessLocationField == 0) {
              $('#accessLocationField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listFields[0].accessLocationField == 1) {
              $('#accessLocationField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listFields[0].accessLocationField == 2) {
              $('#accessLocationField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listFields[0].accessLocationField == 3) {
              $('#accessLocationField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.listFields[0].identifiabilityField == 0) {
              $('#identifiabilityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listFields[0].identifiabilityField == 1) {
              $('#identifiabilityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listFields[0].identifiabilityField == 2) {
              $('#identifiabilityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listFields[0].identifiabilityField == 3) {
              $('#identifiabilityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.listFields[0].quantityField == 0) {
              $('#quantityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listFields[0].quantityField == 1) {
              $('#quantityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listFields[0].quantityField == 2) {
              $('#quantityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listFields[0].quantityField == 3) {
              $('#quantityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // End Field
            // record
            // edit level in
            $('#qcm_record_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().children().children().children().eq(0).html(' Assessment Status: <b>Field</b>');
            // progressBar record
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(2).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.listRecords[0].percentCompletion + '" role="progressbar" aria-valuemin="0" aria-valuemax="100">' +
              data.listRecords[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.listRecords[0].levelScoreRecord == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(3).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].levelScoreRecord == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(3).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].levelScoreRecord == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(3).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].levelScoreRecord == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(3).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(4).html(data.listRecords[0].dateScoreRecord);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).children().eq(5).html(data.listRecords[0].userScoreRecord);
            // data Access
            if (data.listRecords[0].dataAccessRecord == 0) {
              $('#dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].dataAccessRecord == 1) {
              $('#dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].dataAccessRecord == 2) {
              $('#dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].dataAccessRecord == 3) {
              $('#dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.listRecords[0].accessLocationRecord == 0) {
              $('#accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].accessLocationRecord == 1) {
              $('#accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].accessLocationRecord == 2) {
              $('#accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].accessLocationRecord == 3) {
              $('#accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.listRecords[0].identifiabilityRecord == 0) {
              $('#identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].identifiabilityRecord == 1) {
              $('#identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].identifiabilityRecord == 2) {
              $('#identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].identifiabilityRecord == 3) {
              $('#identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.listRecords[0].quantityRecord == 0) {
              $('#quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listRecords[0].quantityRecord == 1) {
              $('#quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listRecords[0].quantityRecord == 2) {
              $('#quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listRecords[0].quantityRecord == 3) {
              $('#quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // m à j button radio Record
            $('input[name=dataAccessRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '][value=' + data.listRecords[0].dataAccessRecord + ']').prop('checked', true);
            $('input[name=accessLocationRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '][value=' + data.listRecords[0].accessLocationRecord + ']').prop('checked', true);
            $('input[name=identifiabilityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '][value=' + data.listRecords[0].identifiabilityRecord + ']').prop('checked', true);
            $('input[name=quantityRecord' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + '][value=' + data.listRecords[0].quantityRecord + ']').prop('checked', true);
            // m à j selected Record
            let indexRecord = this.listRecords.findIndex(o => o.id === this.selectedRecordId);
            this.listRecords[indexRecord].levelScoreRecord = data.listRecords[0].levelScoreRecord;
            this.listRecords[indexRecord].dateScoreRecord = data.listRecords[0].dateScoreRecord;
            this.listRecords[indexRecord].userScoreRecord = data.listRecords[0].userScoreRecord;
            this.listRecords[indexRecord].leveleditedinRecord = data.listRecords[0].leveleditedinRecord;
            this.listRecords[indexRecord].dataAccessRecord = data.listRecords[0].dataAccessRecord;
            this.listRecords[indexRecord].accessLocationRecord = data.listRecords[0].accessLocationRecord;
            this.listRecords[indexRecord].identifiabilityRecord = data.listRecords[0].identifiabilityRecord;
            this.listRecords[indexRecord].quantityRecord = data.listRecords[0].quantityRecord;
            this.listRecords[indexRecord].percentCompletion = data.listRecords[0].percentCompletion;
            // End Record
            // objects
            // edit level in
            $('#qcm_object_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().children().children().children().eq(0).html(' Assessment Status: <b>Field</b>');
            // progressBar Object
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(3).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.listObjects[0].percentCompletion + '" role="progressbar" aria-valuemin="0" aria-valuemax="100">' +
              data.listObjects[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.listObjects[0].levelScoreObject == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].levelScoreObject == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].levelScoreObject == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].levelScoreObject == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(4).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(5).html(data.listObjects[0].dateScoreObject);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).children().eq(6).html(data.listObjects[0].userScoreObject);
            // data Access
            if (data.listObjects[0].dataAccessObject == 0) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].dataAccessObject == 1) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].dataAccessObject == 2) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].dataAccessObject == 3) {
              $('#dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.listObjects[0].accessLocationObject == 0) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].accessLocationObject == 1) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].accessLocationObject == 2) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].accessLocationObject == 3) {
              $('#accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.listObjects[0].identifiabilityObject == 0) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].identifiabilityObject == 1) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].identifiabilityObject == 2) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].identifiabilityObject == 3) {
              $('#identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.listObjects[0].quantityObject == 0) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listObjects[0].quantityObject == 1) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listObjects[0].quantityObject == 2) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listObjects[0].quantityObject == 3) {
              $('#quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // m à j button radio Object
            $('input[name=dataAccessObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + data.listObjects[0].dataAccessObject + ']').prop('checked', true);
            $('input[name=accessLocationObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + data.listObjects[0].accessLocationObject + ']').prop('checked', true);
            $('input[name=identifiabilityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + data.listObjects[0].identifiabilityObject + ']').prop('checked', true);
            $('input[name=quantityObject' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + '][value=' + data.listObjects[0].quantityObject + ']').prop('checked', true);
            // m à j selected Object
            let indexObject = this.listObjects.findIndex(o => o.id === this.selectedObjectId);
            this.listObjects[indexObject].levelScoreObject = data.listObjects[0].levelScoreObject;
            this.listObjects[indexObject].dateScoreObject = data.listObjects[0].dateScoreObject;
            this.listObjects[indexObject].userScoreObject = data.listObjects[0].userScoreObject;
            this.listObjects[indexObject].leveleditedinObject = data.listObjects[0].leveleditedinObject;
            this.listObjects[indexObject].dataAccessObject = data.listObjects[0].dataAccessObject;
            this.listObjects[indexObject].accessLocationObject = data.listObjects[0].accessLocationObject;
            this.listObjects[indexObject].identifiabilityObject = data.listObjects[0].identifiabilityObject;
            this.listObjects[indexObject].quantityObject = data.listObjects[0].quantityObject;
            this.listObjects[indexObject].percentCompletion = data.listObjects[0].percentCompletion;
            // End Object
            // Models
            // edit level in
            $('#qcm_model_' + this.selectedApplicationId + this.selectedObjectModelID).children().children().children().children().eq(0).html(' Assessment Status: <b>Field</b>');
            // progressBar
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(4).html('' +
              '<div style="margin-top: 0; margin-bottom: 0; background-color: #b4bcc4 !important; box-shadow: inset 2px 2px 2px #888888;border-radius: 3px;" class="progress">' +
              '<div class="progress-bar progress-bar-info progress-bar-striped width-per-'
              + data.listModels[0].percentCompletion + '" role="progressbar" aria-valuemin="0" aria-valuemax="100">' +
              data.listModels[0].percentCompletion + '%</div></div>');
            // level Score
            if (data.listModels[0].levelScoreModel == 0) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].levelScoreModel == 1) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].levelScoreModel == 2) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].levelScoreModel == 3) {
              $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(5).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // date level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(6).html(data.listModels[0].dateScoreModel);
            // user level Score
            $('#' + this.selectedApplicationId + this.selectedObjectModelID).children().eq(7).html(data.listModels[0].userScoreModel);
            // data Access
            if (data.listModels[0].dataAccessModel == 0) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].dataAccessModel == 1) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].dataAccessModel == 2) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].dataAccessModel == 3) {
              $('#dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // access location
            if (data.listModels[0].accessLocationModel == 0) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].accessLocationModel == 1) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].accessLocationModel == 2) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].accessLocationModel == 3) {
              $('#accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // identifiability
            if (data.listModels[0].identifiabilityModel == 0) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].identifiabilityModel == 1) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].identifiabilityModel == 2) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].identifiabilityModel == 3) {
              $('#identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            if (data.listModels[0].quantityModel == 0) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:gray;"></i>');
            } else if (data.listModels[0].quantityModel == 1) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:green;"></i>');
            } else if (data.listModels[0].quantityModel == 2) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:orange;"></i>');
            } else if (data.listModels[0].quantityModel == 3) {
              $('#quantityModel' + this.selectedApplicationId + this.selectedObjectModelID).html('<i class="fas fa-exclamation-triangle" style="color:red;"></i>');
            }
            // m à j button radio Model
            $('input[name=dataAccessModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.listModels[0].dataAccessModel + ']').prop('checked', true);
            $('input[name=accessLocationModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.listModels[0].accessLocationModel + ']').prop('checked', true);
            $('input[name=identifiabilityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.listModels[0].identifiabilityModel + ']').prop('checked', true);
            $('input[name=quantityModel' + this.selectedApplicationId + this.selectedObjectModelID + '][value=' + data.listModels[0].quantityModel + ']').prop('checked', true);
            // m à j selected Model
            let indexModel = this.listObjectModel.findIndex(o => o.id === this.selectedObjectModelID);
            this.listObjectModel[indexModel].levelScoreModel = data.listModels[0].levelScoreModel;
            this.listObjectModel[indexModel].dateScoreModel = data.listModels[0].dateScoreModel;
            this.listObjectModel[indexModel].userScoreModel = data.listModels[0].userScoreModel;
            this.listObjectModel[indexModel].leveleditedinModel = data.listModels[0].leveleditedinModel;
            this.listObjectModel[indexModel].dataAccessModel = data.listModels[0].dataAccessModel;
            this.listObjectModel[indexModel].accessLocationModel = data.listModels[0].accessLocationModel;
            this.listObjectModel[indexModel].identifiabilityModel = data.listModels[0].identifiabilityModel;
            this.listObjectModel[indexModel].quantityModel = data.listModels[0].quantityModel;
            this.listObjectModel[indexModel].percentCompletion = data.listModels[0].percentCompletion;
            // End Model
            // Application
            // m à j selected Application
            let indexAppli = this.listAplications.findIndex(o => o.id === this.selectedApplicationId);
            this.listAplications[indexAppli]['levelScore'] = data.listApplications[0].levelScore;
            this.listAplications[indexAppli]['dateScore'] = data.listApplications[0].dateScore;
            this.listAplications[indexAppli]['userScore'] = data.listApplications[0].userScore;
            this.listAplications[indexAppli]['leveleditedin'] = data.listApplications[0].leveleditedin;
            this.listAplications[indexAppli]['dataAccessApp'] = data.listApplications[0].dataAccessApp;
            this.listAplications[indexAppli]['accessLocationApp'] = data.listApplications[0].accessLocationApp;
            this.listAplications[indexAppli]['identifiabilityApp'] = data.listApplications[0].identifiabilityApp;
            this.listAplications[indexAppli]['quantityApp'] = data.listApplications[0].quantityApp;
            this.listAplications[indexAppli]['percentCompletion'] = data.listApplications[0].percentCompletion;
            // End Application

          },
          err => {
            console.error(err);
          },
          () => {
            $('#saveQcmField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).prop('disabled', true).removeClass('color-8');//.html('save')
            $('#cancelQcmField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).prop('disabled', true).removeClass('color-2');
            removeLoader();
          }
        );
      this.subscription.add(subscription15$);
    }
  }
  onCancelQcmField() {
    let fiel = this.listFields.find(o => o.id === this.selectedFieldId);
    $('#saveQcmField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).prop('disabled', true).removeClass('color-8');//.html('save')
    $('#cancelQcmField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).prop('disabled', true).removeClass('color-2');
    $('input[name=dataAccessField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + '][value=' + fiel.dataAccessField + ']').prop('checked', true);
    $('input[name=accessLocationField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + '][value=' + fiel.accessLocationField + ']').prop('checked', true);
    $('input[name=identifiabilityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + '][value=' + fiel.identifiabilityField + ']').prop('checked', true);
    $('input[name=quantityField' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId + '][value=' + fiel.quantityField + ']').prop('checked', true);
  }
  // hide show collapcible body Field
  showHideQcmField() {
    $('#qcm_field_' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).toggle();
    if ($('#icon_double_field' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).hasClass('fa-angle-double-down')) {
      $('#icon_double_field' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).removeClass('fa-angle-double-down');
      $('#icon_double_field' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).toggleClass('fa-angle-double-up');
    } else {
      $('#icon_double_field' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).removeClass('fa-angle-double-up');
      $('#icon_double_field' + this.selectedApplicationId + this.selectedObjectModelID + this.selectedObjectId + this.selectedRecordId + this.selectedFieldId).toggleClass('fa-angle-double-down');
    }
  }

  // redirection to object model security
  goToObjectModelSecurity() {
    if (this.securityBridge.componentId != '' && this.securityBridge.component != '') {
      let cryptComponent = this.cryptoService.set('DRMSecurity');
      cryptComponent = cryptComponent.replace(/\//g, '__________');
      let cryptComponentId = this.cryptoService.set(this.applicationNameREdirect);
      cryptComponentId = cryptComponentId.replace(/\//g, '__________');
      let cryptModel = this.cryptoService.set(this.securityBridge.model);
      cryptModel = cryptModel.replace(/\//g, '__________');
      let cryptObject = this.cryptoService.set(this.securityBridge.object);
      cryptObject = cryptObject.replace(/\//g, '__________');
      let cryptTable = this.cryptoService.set(this.securityBridge.table);
      cryptTable = cryptTable.replace(/\//g, '__________');
      let cryptField = this.cryptoService.set(this.securityBridge.field);
      cryptField = cryptField.replace(/\//g, '__________');
      const url = 'object-models/' + cryptComponentId + '/' + cryptModel + '/' + cryptObject + '/' + cryptTable + '/' + cryptField + '/' + cryptComponentId + '/' + cryptComponent;
      console.log(url);
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([url]);
      });
    }
  }
  goToProject() {
    if (this.securityBridge.componentId != '' && this.securityBridge.component != '') {
      let cryptProject = this.cryptoService.set(this.securityBridge.componentId);
      cryptProject = cryptProject.replace(/\//g, '__________');
      let cryptComponent = this.cryptoService.set('DRMSecurity');
      cryptComponent = cryptComponent.replace(/\//g, '__________');
      let cryptComponentId = this.cryptoService.set(this.securityBridge.model);
      cryptComponentId = cryptComponentId.replace(/\//g, '__________');
      const url = 'data-governance/project/' + cryptProject + '/' + cryptComponentId + '/' + cryptComponent;
      this.router.navigateByUrl('/RefreshComponent', { skipLocationChange: true }).then(() => {
        this.router.navigate([url]);
      });
    }
  }
}

