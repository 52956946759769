import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ActiveBoardRoutingModule } from './active-board-routing.module';
import {MainActiveComponent} from "./main-active/main-active.component";


@NgModule({
  declarations: [MainActiveComponent],
  imports: [
    CommonModule,
    ActiveBoardRoutingModule
  ]
})
export class ActiveBoardModule { }
