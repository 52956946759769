
export const environment = {
  production: false,
  envVar: {
    PORTAL: "false",
    PORTAL_URL: "https://localhost:5000/portal/home",
    NODE_URL: "https://systnaps-devutil.huxium.net",
    API_URL: "https://3b741620.dm.systnaps.com/ddtm-api/web",
    ANGULAR_URL: "http://localhost:4200",
    LOGO: "logo.png",
    BlACKLOGO: "Black-Logo.png",
    DRI_URL: "https://dataset.huxium.io"
}
};
