import {Injector, Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

  public constructor(private readonly injector: Injector) {
  }
  transform(value: Array<string> | string, limit: number): string {
    if ( Array.isArray(value)) {
      // pick whatever number fits your need
      if (value.length > limit) {
        return value[0].concat('...');
      } else if ( value.length === 0) {
        return value[0];
      } else {
        return value.reduce((a, b) => a.concat(',').concat(b));
      }
    } else {
    if ( value.length > limit) {
      return value.substring(0, limit - 1).concat('...');
    } else {
      return value;
    }
    }

  }
}
