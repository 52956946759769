import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnInit
} from '@angular/core';
import {render} from './index';
import * as d3 from 'd3';
import {ObjectService} from '../../../services/objects/object.service';

const FORCES = {
  LINKS: 1 / 50,
  COLLISION: 1,
  CHARGE: -1
};
declare const $: any;
@Component({
  selector: 'app-diagramme',
  templateUrl: './diagramme.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./diagramme.component.css']
})
export class DiagrammeComponent implements OnInit, AfterViewInit {
  constructor(private objectService: ObjectService) {}
  modelJoins = [];
  objectModelJoinsDistinct = [];
  resultLinks = [];
  nodes = [];
  selectedText: string;
  margin = {
    top: 40,
    bottom: 10,
    left: 20,
    right: 20
  };
  name: string;
  description: string;
  keyCand: string;
  recordKand: string;
  recordList = [];
  isRule = true;
  filter: string;

  ngOnInit(): void {
    // this.onGetListModelJoinGraph();
  }
  ngAfterViewInit(): void {
    // this.onGetDescriptionObjectInDiagramme();
    // this.onGetListModelJoinGraph();
    // render(mockedData);
    // const width = 800 - this.margin.left - this.margin.right;
    // const height = 600 - this.margin.top - this.margin.bottom;
    // Creates sources <svg> element and inner g (for margins)
    // const svg = d3.select('#graph').append('svg')
    //   .attr('width', width + this.margin.left + this.margin.right)
    //   .attr('height', height + this.margin.top + this.margin.bottom)
    //   .append('g')
    //   .attr('transform', `translate(${this.margin.left}, ${this.margin.top})`);
    /////////////////////////

    // const simulation = d3.forceSimulation()
    //   .force('link', d3.forceLink().id((d) => d.id))
    //   .force('charge', d3.forceManyBody())
    //   .force('center', d3.forceCenter(width / 2, height / 2));
    //  const color = d3.scaleOrdinal(d3.schemeCategory10);
  }
    // onGetDescriptionObjectInDiagramme() {
    // this.selectedText = window.localStorage.getItem('selectedText');
    // if (this.selectedText) {
    //     this.objectService.getDescriptionObjectInDiagramme().subscribe(
    //         data => {
    //             this.name = data.name;
    //             this.description = data.description;
    //             this.keyCand = data.keyCand;
    //             this.recordKand = data.recordKand;
    //             this.recordList = data.recordList;
    //             this.isRule = data.isRule;
    //         },
    //         err => {
    //             console.error(err);
    //         },
    //         () => {
    //             // $('#fullHeightModalRight').ngAfterViewInit();
    //         }
    //     );
    // }
    //
    // }


}
