import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, NgForm, Validators} from '@angular/forms';
import {ProfileService} from '../../services/users/profile.service';
import {AuthComponent} from '../../auth/auth.component';
import {AppComponent} from '../../app.component';
import {Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {CustomValidators} from '../../custom-validators';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import {getCookieValue} from "../../shared";


@Component({
  selector: 'app-profileuser',
  templateUrl: './profileuser.component.html',
  styleUrls: ['./profileuser.component.sass']
})
export class ProfileuserComponent implements OnInit {
  public frmUpdateSecurity: FormGroup;
  userPassForm: FormGroup;
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  userId: string;
  roles: string;
  firstNameNew: string;
  lastNameNew: string;
  usernameNew: string;
  currentPassword: string;
  passwordChange: string;
  passwordChangeConf: string;
  emailNew: string;
  postData: string;
  lang: string;
  // profileList: Profile;
  constructor(private profileService: ProfileService, private authComponent: AuthComponent,
              private appCompenet: AppComponent, private router: Router, private authenticateService: AuthenticationService, private translate: TranslateService,
              private fb: FormBuilder) {
      this.frmUpdateSecurity = this.initForm();
  }
  ngOnInit() {
      this.onGetProfile();
      this.detectChangeLaguage();
    }
    detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'en';
      this.translate.use(this.lang);
    }, 10);
    }
  // update user
    onSubmitUpdateAccount(form2: NgForm) {
        this.firstNameNew = form2.value['firstName11'];
        this.lastNameNew = form2.value['lastName11'];
        this.emailNew = form2.value['email11'];
        this.usernameNew = form2.value['username11'];
        if (this.usernameNew || this.emailNew || this.lastNameNew || this.firstNameNew) {
            this.postData = "firstName=" + this.firstNameNew + "&lastName=" +
                this.lastNameNew + "&email=" + this.emailNew + "&username=" + this.usernameNew;
            this.profileService.updateSettingUser(this.postData).subscribe(
                data => {
                  const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  });
                  let titleUpdate = this.lang === 'en' ? 'update success' : 'mise à jour réussie';
                  Toast.fire({
                   icon: 'success',
                   title: titleUpdate
                  });
                  
                  this.authenticateService.signOutNew();
                }, // this.allLocs = data,
                err => {
                  console.error(err);
                  const Toast = Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                    onOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  });
                  let title = this.lang === 'en' ? 'error updating' : 'erreur de mise à jour';
                  Toast.fire({
                   icon: 'error',
                   title: title
                  });
               
                });
        } else {
          const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            onOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
          });
          let titleFill = this.lang === 'en' ? 'fill at least one field' : 'remplissez au moins un champ';
          Toast.fire({
           icon: 'error',
           title: titleFill
          });
          
        }
    }
    // update password
    initForm(): FormGroup {
        return this.userPassForm = this.fb.group(
            {
                currentPassword: [
                    null,
                    Validators.compose([
                        Validators.required
                    ])
                ],
                password: [
                    null,
                    Validators.compose([
                        Validators.required,
                        // check whether the entered password has a number
                        CustomValidators.patternValidator(/\d/, {
                            hasNumber: true
                        }),
                        // check whether the entered password has upper case letter
                        CustomValidators.patternValidator(/[A-Z]/, {
                            hasCapitalCase: true
                        }),
                        // check whether the entered password has a lower case letter
                        CustomValidators.patternValidator(/[a-z]/, {
                            hasSmallCase: true
                        }),
                        // check whether the entered password has a special character
                        CustomValidators.patternValidator(
                            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
                            {
                                hasSpecialCharacters: true
                            }
                        ),
                        Validators.minLength(8)
                    ])
                ],
                confirmPassword: [null, Validators.compose([Validators.required])]
            },
            {
                // check whether our password and confirm password match
                validator: CustomValidators.passwordMatchValidator
            }
        );
    }
    onSubmitUpdateSecurity() {
        const formValue = this.userPassForm.value;
        this.currentPassword = formValue['currentPassword'];
        this.passwordChange = formValue['password'];
        // console.log(this.currentPassword);
        // console.log(this.passwordChange);

        this.postData = "password=" + this.passwordChange + "&currentPassword=" + this.currentPassword ;
        this.profileService.updateSettingUser(this.postData).subscribe(
            data => {
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });
              let title = this.lang === 'en' ? 'update success' : 'mise à jour réussie';
              Toast.fire({
               icon: 'success',
               title: title
              });
              
            },
            err => {
              console.error(err);
              const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                onOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
              });
              let titleUpdate = this.lang === 'en' ? 'error updating' : 'erreur de mise à jour';
              Toast.fire({
               icon: 'error',
               title: titleUpdate
              });
              
            },
          () => {
              setTimeout(() => {
                // console.log('ok');
                this.authenticateService.signOutNew();
              }, 1600);
          });
    }

    onGetProfile() {
        this.username = getCookieValue('username');
        this.lastName = window.localStorage.getItem('lastName');
        this.firstName = window.localStorage.getItem('firstName');
        this.email = window.localStorage.getItem('email');
        this.roles = window.localStorage.getItem('roles');
        /*this.profileService.getProfile().subscribe(
            (data) => {
                this.profileList = data;
                this.firstName = this.profileList.firstName;
                this.lastName = this.profileList.lastName;
                this.username = this.profileList.username;
                this.email = this.profileList.email;
                this.userId = this.profileList.userId;
                this.roles = this.profileList.roles
                window.localStorage.setItem('userId', data.userId);
                window.localStorage.setItem('roles', data.roles);
                this.roles = this.profileList.roles;
                this.router.navigate(['profileUser']);
            },
            err => console.error(err)
        );*/
    }

}
