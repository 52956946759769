import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportService } from 'src/app/services/reports/report.service';
import { UploadsService } from 'src/app/services/uploads/uploads.service';
import { addMultiProgressBar, addProgressBar, conditionalValidator, setMultiProgressBarValue, Shared, skipString, unSkipString } from 'src/app/shared';
import { ObjectService } from '../../services/objects/object.service';
import { addLoader, removeLoader } from '../../shared';
import * as echarts from 'echarts';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';

declare const $: any;
type EChartsOption = echarts.EChartsOption;
@Component({
  selector: 'app-upload-model',
  templateUrl: './upload-model.component.html',
  styleUrls: ['./upload-model.component.css']
})
export class UploadModelComponent implements OnInit {

  // start dimensions
  modelDimensionForm: FormGroup;
  uploadForm: FormGroup;
  conditions = [];
  listTypeDimensions: any;
  listModels = {
    models: []
  };
  entitys = [];
  loadingEntities = true;
  listOwner: any;
  categoryId: any;
  categoriesIds = [];
  // end dimenpsions
  files: File[] = [];
  modelFiles = [];
  idSelectedModel: string;
  nameSelectedModel: string;
  lang: string;
  step = 0;
  totalProgress = 0;
  uploadedFileName: string;
  listObjectToUpload = [];
  listObjectJoinsToUpload = [];
  listIdsModelsError = [];
  listNameModelsError = [];
  modelsInPending = false;
  modelUploadedSuccess = false;
  draftModel = 0;
  approvedModel = 0;
  archivedModel = 0;
  deletedModel = 0;
  pendingModel = 0;
  stopWatch = { milliseconds: 0, seconds: 0, minutes: 0, hours: 0 };
  interval: any;
  constructor(private http: HttpClient, private uploadService: UploadsService, private reportService: ReportService, private objectService: ObjectService, private fb: FormBuilder, private translate: TranslateService) {
    translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.lang = event.lang;
      this.setStatModelToChart(this.draftModel, this.approvedModel, this.archivedModel, this.deletedModel, this.pendingModel);
    });
    this.uploadForm = fb.group({
      file_input: ['', [Validators.required]]
    });
  }

  ngOnInit(): void {
    this.stopWatch.milliseconds = 0;
    this.stopWatch.seconds = 0;
    this.stopWatch.minutes = 0;
    this.stopWatch.hours = 0;
    this.onCheckPendingModel('model');
    this.modelDimensionForm = this.fb.group({
      models: this.fb.array([...this.createItems(1)])
    });
    this.detectChangeLaguage();
  }

  detectChangeLaguage() {
    const that = this;
    setInterval(() => {
      this.lang = localStorage.getItem('lang') || 'fr';
      this.translate.use(this.lang);
    }, 10);
  }

  onCheckPendingModel(type) {
    this.uploadService.checkPendingModel(type).subscribe(
      result => {
        if (result === 0) {
          this.GetAllDimensions();
          this.GetOwnerUpload();
          this.getEntities();
          this.getModelStatusChart();
        } else {
          this.removeCheckedPendingModel();
        }
      },
      err => {
        this.GetAllDimensions();
        this.GetOwnerUpload();
        this.getEntities();
        this.getModelStatusChart();
      }
    );
  }
  removeCheckedPendingModel() {
    addLoader(this.lang === 'en' ? 'C L E A N G&#160;&#160;M O D E L S . . .' : 'N E T T O YAGE&#160;&#160;MODÈLES . . .');

    this.uploadService.removeAllModelEnPending().subscribe(
      result => {
        this.GetAllDimensions();
        this.GetOwnerUpload();
        this.getEntities();
        this.getModelStatusChart();
      },
      err => {
        this.GetAllDimensions();
        this.GetOwnerUpload();
        this.getEntities();
        this.getModelStatusChart();
      }
    );
  }
  /*********************** start add dimension to model **************************/
  private createItems(count: number): FormGroup[] {
    const arr = [];
    for (let i = 0; i < count; i++) {
      this.conditions[i] = false;
      // console.log('this.conditions[i] =>' + this.conditions[i], 'i =>' + i);
      arr.push(this.createItem(i));
    }
    return arr;
  }
  private createItem(i): FormGroup {
    return this.fb.group({
      modelName: '',
      modelId: '',
      ownerId: '',
      selectedDimension: [],
      categoriesIds: [],
      selectedDimensions: [null, [
        conditionalValidator(() => this.conditions[i],
          Validators.compose([Validators.required]))
      ]],
      entities: []
    });
  }
  get models(): FormArray {
    return this.modelDimensionForm.get('models') as FormArray;
  }
  // list entities in base
  getEntities() {
    this.loadingEntities = true;
    this.uploadService.getEntities().subscribe(
      d => {
        // @ts-ignore
        this.entitys = d;
        this.loadingEntities = false;
      }, err => {
        console.error(err);
      }
    );
  }
  /*******Owner********/
  onDimensionChange(event, i) {
    this.categoryId = event.id;
    // console.log(event.elementName);
    this.models.get([i + '', 'selectedDimension']).patchValue(event.elementName);
    this.conditions[i] = event.elementName.length > 0;
    // console.log('this.conditions[i] => ' + this.conditions[i], 'i => ' + i, 'modelDimensionForm.invalid => ' + this.modelDimensionForm.invalid);
  }
  addElements(item, array, i) {
    let found = false;
    for (i in array) {
      if (array[i].index == item.index) {
        array[i].elements = item.elements;
        found = true;
        break;
      }
    }
    if (!found) {
      array.push(item);
    }
  }
  onElementsChange(event, i) {
    const item = {
      index: i,
      elements: event
    };
    this.addElements(item, this.categoriesIds, i);
    this.models.get([i + '', 'categoriesIds']).patchValue(this.categoriesIds);
  }
  GetOwnerUpload() {
    this.uploadService.getOwnerReportUpload().subscribe(
      data => {
        this.listOwner = data;
      },
      err => { },
      () => {
      });
  }

  /*******End Owner************/
  GetAllDimensions() {
    this.uploadService.getAllDimensions().subscribe(
      data => {
        this.listTypeDimensions = data;
      },
      err => { });
  }
  // get list model for dimensions
  getModelsForDimension() {
    this.uploadService.getModelsForDimension().subscribe(
      data => {
        $('#divGlobalProgress').remove();
        this.listModels.models = data;
        this.modelDimensionForm = this.fb.group({
          models: this.fb.array([...this.createItems(data.length)])
        });
      },
      err => {
        $('#divGlobalProgress').remove();
        console.error(err);
      },
      () => {
        if (this.listModels.models.length == 0) {
          $('#uploadModelStatus').modal('show');
        } else {
          this.modelDimensionForm.setValue(this.listModels);
          $('#uploadModelDimension').modal('show');
        }
        this.getModelStatusChart();
      }
    );
  }
  addDimensionToModels() {
    addLoader(this.lang === 'en' ? 'A D D &#160;&#160; D I M E N S I O N S' : 'A J O U TER &#160;&#160; D I M E N S I O N S');
    $('#uploadModelDimension').modal('hide');
    const data = this.modelDimensionForm.value;
    // console.log(data);
    data.models.map(e => {
      if (e.entities != null) {
        e.entityNames = [];
        for (let i = 0; i < e.entities.length; i++) {
          const ent = this.entitys.find(et => et.id == e.entities[i]);
          if (ent != null) {
            e.entityNames.push(skipString(ent.name));
          }
        }
      } else {
        e.entityNames = null;
      }
      e.modelName = skipString(e.modelName);
    });
    this.uploadService.addDimensionsToModels(data).subscribe(
      result => {
      },
      err => {
        console.error(err);
        removeLoader();
      },
      () => {
        removeLoader();
        $('#uploadModelStatus').modal('show');
      }
    );
  }
  onDiscardAddDimensions() {
    $('#uploadModelStatus').modal('show');
  }
  hideUploadedStatus() {
    $('#uploadModelStatus').modal('hide');
  }
  /*********************** end add dimension to model **************************/

  // ************ start pass to next model */

  // skipString(str) {
  //   if (str == null) {
  //     return str;
  //   }
  //   let name = str.toString().replace(/\//g, '__________');
  //   name = name.replace(/&/g, '::::::::');
  //   // if (str.localeCompare(name) != 0) {
  //   //   console.log(name);
  //   // }
  //   return name;
  // }
  onSelectModel(event) {
    this.files = [];
    this.modelFiles = [];
    this.listIdsModelsError = [];
    this.listNameModelsError = [];
    // const extention = report.name.split('.')[1].trim();
    this.files.push(...event.addedFiles);
    if (this.files.length > 0) {
      addMultiProgressBar(this.files, 0);
      const selectedFile = this.files.splice(0, 1);
      // console.log(selectedFile);
      this.listObjectToUpload = [];
      this.listObjectJoinsToUpload = [];
      this.modelsInPending = false;
      this.loadSelectedModelInBase(selectedFile[0]);
    }
  }
  loadSelectedModelInBase(model) {
    // start update chart stat
    this.pendingModel++;
    this.setStatModelToChart(this.draftModel, this.approvedModel, this.archivedModel, this.deletedModel, this.pendingModel);
    // end update chart stat
    const reader = new FileReader();
    reader.readAsText(model);
    reader.onload = () => {
      const jsonData = reader.result as string;
      try {
        let uploadedName = model.name.split('.')[0].trim();
        this.uploadedFileName = skipString(uploadedName);
        const JsonFile = JSON.parse(jsonData);
        this.step = 0;
        this.totalProgress = 0;
        // add control
        if (JsonFile.Objects) {
          for (const object of JsonFile.Objects) {
            this.listObjectToUpload.push(object);
          }
        }
        if (JsonFile.ObjectModelJoins) {
          for (const join of JsonFile.ObjectModelJoins) {
            this.listObjectJoinsToUpload.push(join);
          }
        }
        const infoModel = {
          username: localStorage.getItem('username'),
          code: skipString(JsonFile.code),
          name: skipString(JsonFile.name),
          description: skipString(JsonFile.description),
          application: skipString(JsonFile.application),
          objectCount: skipString(JsonFile.objectCount),
          joinsCount: skipString(JsonFile.joinsCount),
          tablesCount: skipString(JsonFile.tablesCount),
          modelType: skipString(JsonFile.modelType),
          effectiveDate: JsonFile.effectiveDate
        };
        // this.progressMessage = 'Upload Object Model: ' + this.uploadedFileName;
        this.idSelectedModel = undefined;
        setTimeout(() => {
          this.uploadService.uploadModel(infoModel).subscribe(
            result => {
              if (result.status == 'saved success') {
                setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? 'UPLOAD OBJECT MODEL: ' + unSkipString(this.uploadedFileName) + ' SUCCESS' : 'TÉLÉCHARGEMENT DU MODEL OBJET: ' + unSkipString(this.uploadedFileName) + ' SUCCÈS');
                $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-success');
                this.step = 0;
                this.totalProgress = 0;
                // this.progressMessage = this.uploadedFileName + ': Add Objects...1/';
                setTimeout(() => {
                  if ($('#loaderProgress').height() > 374) {
                    $('#loaderProgress').css('overflow-y', 'scroll');
                  }
                  this.idSelectedModel = result.modelId.$id;
                  if (this.listObjectToUpload.length > 0) {
                    this.step = 100 / this.listObjectToUpload.length;
                    setMultiProgressBarValue(this.uploadedFileName, 0, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': ADD OBJECTS ... ' + this.listObjectToUpload.length + ' objects left' : unSkipString(this.uploadedFileName) + ': AJOUTER DES OBJETS ... ' + this.listObjectToUpload.length + ' objets restants');
                    const selectedObject = this.listObjectToUpload.splice(0, 1);
                    // convert json to string
                    const stringObj = 'data=' + JSON.stringify(selectedObject[0]);
                    // skip special caracter from string
                    const skippedObject = skipString(stringObj);
                    this.getObjectsByModel(this.idSelectedModel, skippedObject);
                  } else {
                    setTimeout(() => {
                      setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': NO OBJECT ADDED' : unSkipString(this.uploadedFileName) + ': AUCUN OBJET AJOUTÉ');
                      $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-warning');
                      // start load object model join
                      this.step = 0;
                      this.totalProgress = 0;
                      // this.progressMessage = this.uploadedFileName + ': Add Objects Model Joins...';
                      setTimeout(() => {
                        if (this.listObjectJoinsToUpload.length > 0) {
                          this.step = 100 / this.listObjectJoinsToUpload.length;
                          setMultiProgressBarValue(this.uploadedFileName, 0, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': ADD OBJECT MODEL JOINS ... ' + this.listObjectJoinsToUpload.length + ' object joins left' : unSkipString(this.uploadedFileName) + ': AJOUTER LES JOINS DU MODEL OBJET ... ' + this.listObjectJoinsToUpload.length + ' jointures d\'objets restantes');
                          const selectedJoin = this.listObjectJoinsToUpload.splice(0, 1);
                          // convert json to string
                          const stringJoin = 'data=' + JSON.stringify(selectedJoin[0]);
                          // skip special caracter from string
                          const skippedJoin = skipString(stringJoin);
                          this.getObjectJoinsByModel(this.idSelectedModel, skippedJoin);
                        } else {
                          setTimeout(() => {
                            setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': NO OBJECT MODEL JOINS ADDED' : unSkipString(this.uploadedFileName) + ': AUCUN JOINS DU MODEL OBJET AJOUTÉ');
                            $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-warning');
                            setTimeout(() => {
                              setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': NO OBJECT MODEL JOINS ADDED' : unSkipString(this.uploadedFileName) + ': AUCUN JOINS DU MODEL OBJET AJOUTÉ');
                              $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-errors');
                              if (!this.listIdsModelsError.includes(this.idSelectedModel)) {
                                this.listIdsModelsError.push(this.idSelectedModel);
                              }
                              // if (!this.listNameModelsError.includes(this.nameSelectedModel)) {
                              this.modelFiles.push({
                                name: this.uploadedFileName,
                                status: 'KO',
                                detail: this.lang === 'en' ? 'NO OBJECT, NO JOINS ADDED' : 'AUCUN OBJET, AUCUNS JOINS AJOUTÉS'
                              });
                              // }
                              this.selectNextModelForUpoadOrFinishTrigger();
                            }, 5);
                          }, 5);
                        }
                      }, 5);
                      // end load object model join
                    }, 5);
                  }
                }, 5);
              }
            },
            error => {
              this.modelsInPending = true;
              setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': CREATION OBJECT MODEL ERROR !!!' : unSkipString(this.uploadedFileName) + ': ERREUR DE CRÉATION DU MODEL OBJET !!!');
              $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
              if ((this.idSelectedModel !== undefined) && !this.listIdsModelsError.includes(this.idSelectedModel)) {
                this.listIdsModelsError.push(this.idSelectedModel);
              }
              this.modelFiles.push({
                name: this.uploadedFileName,
                status: 'KO',
                detail: this.lang === 'en' ? 'OBJECT MODEL MALFORMATED' : 'MODÈLE D\'OBJET MAL FORME'
              });
              this.selectNextModelForUpoadOrFinishTrigger();
            },
          );
        }, 5);
      } catch (e) {
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': CREATION OBJECT MODEL ERROR !!!' : unSkipString(this.uploadedFileName) + ': ERREUR DE CRÉATION DU MODEL OBJET !!!');

        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
        if ((this.idSelectedModel !== undefined) && !this.listIdsModelsError.includes(this.idSelectedModel)) {
          this.listIdsModelsError.push(this.idSelectedModel);
        }
        this.modelFiles.push({
          name: this.uploadedFileName,
          status: 'KO',
          detail: this.lang === 'en' ? 'OBJECT MODEL MALFORMATED' : 'MODÈLE D\'OBJET MAL FORME'
        });
        this.selectNextModelForUpoadOrFinishTrigger();
      }

    };
  }
  getObjectsByModel(idModel, object) {
    this.uploadService.uploadObjectByModel(idModel, object).subscribe(
      result => {
        if (this.listObjectToUpload.length > 0) {
          this.totalProgress += this.step;
          setMultiProgressBarValue(this.uploadedFileName, Math.round(this.totalProgress), this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': ADD OBJECTS ... ' + this.listObjectToUpload.length + ' objects left' : unSkipString(this.uploadedFileName) + ': AJOUTER DES OBJETS ... ' + this.listObjectToUpload.length + ' objets restants');
          const selectedObject = this.listObjectToUpload.splice(0, 1);
          // convert json to string
          const stringObj = 'data=' + JSON.stringify(selectedObject[0]);
          // skip special caracter from string
          const skippedObject = skipString(stringObj);
          this.getObjectsByModel(this.idSelectedModel, skippedObject);
        } else {
          setTimeout(() => {
            setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': OBJECT ADDED SUCCESSFULLY' : unSkipString(this.uploadedFileName) + ': OBJET AJOUTÉ AVEC SUCCÈS');

            $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-success');
            // start load object model join
            this.step = 0;
            this.totalProgress = 0;
            // this.progressMessage = this.uploadedFileName + ': Add Objects Model Joins...';
            setTimeout(() => {
              if (this.listObjectJoinsToUpload.length > 0) {
                this.step = 100 / this.listObjectJoinsToUpload.length;
                setMultiProgressBarValue(this.uploadedFileName, 0, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': ADD OBJECT MODEL JOINS ... ' + this.listObjectJoinsToUpload.length + ' object joins left' : unSkipString(this.uploadedFileName) + ': AJOUTER LES JOINS DU MODEL OBJET ... ' + this.listObjectJoinsToUpload.length + ' jointures d\'objets restantes');
                const selectedJoin = this.listObjectJoinsToUpload.splice(0, 1);
                // convert json to string
                const stringJoin = 'data=' + JSON.stringify(selectedJoin[0]);
                // skip special caracter from string
                const skippedJoin = skipString(stringJoin);
                this.getObjectJoinsByModel(this.idSelectedModel, skippedJoin);
              } else {
                setTimeout(() => {
                  setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': NO OBJECT JOINS ADDED' : unSkipString(this.uploadedFileName) + ': AUCUN JOINS D\'OBJET AJOUTÉS');

                  $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-warning');
                  setTimeout(() => {
                    // this.onAddGroupJoinToModel(this.idSelectedModel);
                    this.onAddCountSensColToModel(this.idSelectedModel);
                  }, 1000);
                }, 1000);
              }
            }, 1000);
            // end load object model join
          }, 1000);
        }
      },
      err => {
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': CREATION OBJECT MODEL RECORD ERROR !!!' : unSkipString(this.uploadedFileName) + ': ERREUR DE CRÉATION D\'ENREGISTREMENT DU MODEL OBJET !!!');
        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
        if (!this.listIdsModelsError.includes(this.idSelectedModel)) {
          this.listIdsModelsError.push(this.idSelectedModel);
        }
        // if (!this.listNameModelsError.includes(this.nameSelectedModel)) {
        //   this.listNameModelsError.push(this.nameSelectedModel);
        // }
        this.modelFiles.push({
          name: this.uploadedFileName,
          status: 'KO',
          detail: this.lang === 'en' ? 'OBJECT MODEL RECORD ERROR' : 'ERREUR D\'ENREGISTREMENT DU MODEL OBJET'
        });
        this.selectNextModelForUpoadOrFinishTrigger();
        console.error(err);
      }
    );
  }
  // inster Object Model Join
  getObjectJoinsByModel(idModel, joins) {
    this.uploadService.uploadObjectJoinsByModel(idModel, joins).subscribe(
      result => {
        if (this.listObjectJoinsToUpload.length > 0) {
          this.totalProgress += this.step;
          setMultiProgressBarValue(this.uploadedFileName, Math.round(this.totalProgress), this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': ADD OBJECT MODEL JOINS ... ' + this.listObjectJoinsToUpload.length + ' object joins left' : unSkipString(this.uploadedFileName) + ': AJOUTER LES JOINS DU MODEL OBJET ... ' + this.listObjectJoinsToUpload.length + ' jointures d\'objets restantes');
          const selectedJoin = this.listObjectJoinsToUpload.splice(0, 1);
          // convert json to string
          const stringJoin = 'data=' + JSON.stringify(selectedJoin[0]);
          // skip special caracter from string
          const skippedJoin = skipString(stringJoin);
          this.getObjectJoinsByModel(this.idSelectedModel, skippedJoin);
        } else {
          setTimeout(() => {
            setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': OBJECT JOINS ADDED SUCCESSFULLY' : unSkipString(this.uploadedFileName) + ': JOINTURES D\'OBJETS AJOUTÉES AVEC SUCCÈS');
            $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-success');
            setTimeout(() => {
              this.onAddGroupJoinToModel(this.idSelectedModel);
            }, 5);
          }, 5);
        }
      },
      err => {
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': CREATION OBJECT MODEL JOINS ERROR !!!' : unSkipString(this.uploadedFileName) + ': ERREUR DE CRÉATION DES JOINS DU MODEL OBJET !!!');

        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
        if (!this.listIdsModelsError.includes(this.idSelectedModel)) {
          this.listIdsModelsError.push(this.idSelectedModel);
        }
        this.modelFiles.push({
          name: this.uploadedFileName,
          status: 'KO',
          detail: this.lang === 'en' ? 'OBJECT MODEL JOINS ERROR' : 'ERREUR DES JOINS DU MODEL OBJET'
        });
        this.selectNextModelForUpoadOrFinishTrigger();
        console.error(err);
      }
    );
  }
  // add Groups between Model, Objects and Joins
  onAddGroupJoinToModel(idModel) {
    setMultiProgressBarValue(this.uploadedFileName, 0, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': ADD OBJECT GROUP JOINS ...' : unSkipString(this.uploadedFileName) + ': AJOUTER LES JOINS DE GROUPE D\'OBJETS ...');
    this.uploadService.addGroupJoinToModel(idModel).subscribe(
      resultGroup => {
        this.onAddCountSensColToModel(idModel);
      },
      errGroup => {
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': CREATION OBJECT GROUP JOINS ERROR !!!' : unSkipString(this.uploadedFileName) + ': ERREUR DE CRÉATION DES JOINS DE GROUPE D\'OBJETS !!!');
        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
        if (!this.listIdsModelsError.includes(this.idSelectedModel)) {
          this.listIdsModelsError.push(this.idSelectedModel);
        }
        this.modelFiles.push({
          name: this.uploadedFileName,
          status: 'KO',
          detail: this.lang === 'en' ? 'OBJECT GROUP JOINS ERROR' : 'ERREUR DES JOINS DE GROUPE D\'OBJETS'
        });
        this.selectNextModelForUpoadOrFinishTrigger();
        console.error(errGroup);
      }
    );
  }
  // add sensitive statistic security to Model
  onAddCountSensColToModel(idModel) {
    setMultiProgressBarValue(this.uploadedFileName, 60, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': ADD COUNT SENSITIVE COLUMN TO MODEL ...' : unSkipString(this.uploadedFileName) + ': AJOUTER UNE COLONNE SENSIBLE AU COMPTE DANS LE MODEL ...');

    this.uploadService.addCountSensColToModel(idModel).subscribe(
      resultSens => {
        this.modelUploadedSuccess = true;
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': UPLOAD COMPLETE SUCCESS' : unSkipString(this.uploadedFileName) + ': TÉLÉCHARGEMENT TERMINÉ AVEC SUCCÈS');
        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-success');
        this.modelFiles.push({
          name: this.uploadedFileName,
          status: 'OK',
          detail: this.lang === 'en' ? 'UPLOAD COMPLETE SUCCESS' : 'TÉLÉCHARGEMENT TERMINÉ AVEC SUCCÈS'
        });
        this.modelsInPending = false;
        // start update chart stat
        this.pendingModel--;
        this.draftModel++;
        this.setStatModelToChart(this.draftModel, this.approvedModel, this.archivedModel, this.deletedModel, this.pendingModel);
        // end update chart stat
        this.selectNextModelForUpoadOrFinishTrigger();
      },
      errSens => {
        setMultiProgressBarValue(this.uploadedFileName, 100, this.lang === 'en' ? unSkipString(this.uploadedFileName) + ': ADD DATA SECURITY ERROR !!!' : unSkipString(this.uploadedFileName) + ': ERREUR DE SÉCURITÉ DES DONNÉES AJOUTÉE !!!');

        $('#' + this.uploadedFileName + '_Values').children().eq(0).toggleClass('bg-danger');
        if (!this.listIdsModelsError.includes(this.idSelectedModel)) {
          this.listIdsModelsError.push(this.idSelectedModel);
        }
        this.modelFiles.push({
          name: this.uploadedFileName,
          status: 'KO',
          detail: this.lang === 'en' ? 'DATA SECURITY ERROR' : 'ERREUR DE SÉCURITÉ DES DONNÉES'
        });
        this.selectNextModelForUpoadOrFinishTrigger();
        console.error(errSens);
      }
    );
  }
  // ************ start pass to next model */
  selectNextModelForUpoadOrFinishTrigger() {
    setTimeout(() => {
      if (this.files.length > 0) {
        const selectedFile = this.files.splice(0, 1);
        this.loadSelectedModelInBase(selectedFile[0]);
      } else {
        if (this.listIdsModelsError.length > 0) {
          const dataModel = {
            username: localStorage.getItem('username'),
            list: this.listIdsModelsError,
          };
          $('#divGlobalProgress').remove();
          addProgressBar(10, this.lang === 'en' ? 'Remove cancelled Objects Model ...' : 'Supprimer le modèle d\'objets annulé ...');
          $('#ldp').children().eq(0).toggleClass('bg-danger');
          this.removeMultipleCancelledModels(dataModel);
        } else if (this.modelsInPending) {
          $('#divGlobalProgress').remove();
          addProgressBar(10, this.lang === 'en' ? 'Remove cancelled Objects Model ...' : 'Supprimer le modèle d\'objets annulé ...');
          $('#ldp').children().eq(0).toggleClass('bg-danger');
          this.onRemovePendinModels();
        } else {
          this.getModelsForDimension();
        }
      }
    }, 5);
  }
  removeMultipleCancelledModels(dataModel) {
    let subscription19$ = this.objectService.removeMultipleByListIdsModel(dataModel).subscribe(
      data1 => {
        $('#divGlobalProgress').remove();
        addProgressBar(100, this.lang === 'en' ? 'Remove cancelled Objects Model ...' : 'Supprimer le modèle d\'objets annulé ...');
        $('#ldp').children().eq(0).toggleClass('bg-danger');
        this.getModelsForDimension();
      },
      err => {
        this.getModelsForDimension();
      }
    );
  }
  onRemovePendinModels() {
    $('#divGlobalProgress').remove();
    this.uploadService.removeAllModelEnPending().subscribe(
      result => {
        this.getModelsForDimension();
      },
      err => {
        this.getModelsForDimension();
      }
    );
  }
  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  // global chart status
  getModelStatusChart() {
    this.uploadService.getStatModelToChart().subscribe(
      result => {
        this.draftModel = result.draftModel;
        this.approvedModel = result.approvedModel;
        this.archivedModel = result.archivedModel;
        this.deletedModel = result.deletedModel;
        this.pendingModel = result.pendingModel;
        this.setStatModelToChart(this.draftModel, this.approvedModel, this.archivedModel, this.deletedModel, this.pendingModel);
        removeLoader();
      },
      err => {
        removeLoader();
      }
    );
  }
  setStatModelToChart(draftModel, approvedModel, archivedModel, deletedModel, pendingModel) {
    // tslint:disable-next-line:no-non-null-assertion
    const chartDom = document.getElementById('chart-model')!;
    const myChart = echarts.init(chartDom);
    let option: EChartsOption;

    option = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: 'Access From',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '30',
              fontWeight: 'bold',
              formatter(params) {
                return params.name + '\n\n' + params.value;
              },
            }
          },
          labelLine: {
            show: false
          },
          data: [
            { value: draftModel, name: this.lang === 'en' ? 'Draft' : 'Brouillon'},
            { value: approvedModel, name: this.lang === 'en' ? 'Approved' : 'Approuvé' },
            { value: archivedModel, name: this.lang === 'en' ? 'Archived' : 'Archivé' },
            { value: deletedModel, name: this.lang === 'en' ? 'Deleted' : 'Supprimé' },
            { value: pendingModel, name: this.lang === 'en' ? 'Pending' : 'En attente' }
          ],
       color: ['blue', 'green', 'orange', 'red', 'gray']
        }
        
      ]
      
    };
    // tslint:disable-next-line:no-unused-expression

    option && myChart.setOption(option);
  }


}
