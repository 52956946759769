import * as d3 from 'd3';
declare const $: any;

export  const renderTaskObject = (taskData, app) => {

  const width = 700;
  const height = 700;
  const svg = d3.select("#TreeChartObject").attr("viewBox", `0 0 ${width} ${height}`);
  svg.selectAll("*").remove();
  const container = svg.append('g')
    .attr('id', 'taskObjectContainer')
    .style("font", "8px sans-serif")
    .style("text-anchor", "middle");
  svg.attr('width', width);
  svg.attr('height', height);
  let { links, nodes } = taskData;
  svg.call(
    d3.zoom()
      .scaleExtent([.1, 20])
      .on("zoom", function() { container.attr("transform", d3.event.transform); })
  );
  initDefinitions(container);
  initFilter(container);
  initGradian(container);
  const simulation = forceSimulation(d3, {width, height});

  const dragDiv = d3.drag()
    .subject(function() {
      return { x: parseInt(d3.select(this).style("left")),
        y: parseInt(d3.select(this).style("top")) };
    })
    .on("drag", function() {
      d3.select(this)
        .style("left", d3.event.x + "px")
        .style("top", d3.event.y + "px");
    });

  d3.select(".d3-tip").call(dragDiv);

  const link = container.selectAll(".objectLink")
    .data(links)
    .enter()
    .append("line")
    .attr("class", "objectLink")
    .attr('style', 'cursor:pointer;')
    .attr('stroke-width', '.4')
    .style('display', 'none')
    .attr('id', d => 'objectLink_' +  d.id)
    // .attr('marker-end', (d) => 'url(#' + d.color + ')')
    // .attr('marker-mid', (d) => 'url(#arrow)')
    .attr('stroke' , d => d.color);


  const node = container.selectAll(".objectNode")
    .data(nodes)
    .enter()
    .append("g")
    .attr("class", 'objectNode')
    .attr('id', d => 'objectNode_' + d.id)
    .style('display', (d) => d.group!='noGroup'? 'none' : 'block')
    .on("click", d => handleNodeClicked(d, nodes, links, app))
    .call(d3.drag()
      .on("start", function dragenstart(d){
        d3.event.sourceEvent.stopPropagation();
        if (!d3.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;

      })
      .on("drag", function dragged(d) {
        d.fx = d3.event.x;
        d.fy = d3.event.y;

      })
      .on("end", function dragended(d){
        if (!d3.event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;

      })
    );

  node.append('rect');
  node.append("text").text((d) => d.name ).attr("class", "node-text");
  node.append("svg:circle")
    .attr("class", "node-glyph")
    .attr('r', 10)
    .attr('cx', -5)
    .attr('cy', -25)
    .attr('id' , (d) =>'node-glyph_'+ d.name)
    .attr('fill', '#044278');

  node.append("svg:text").text(d => d.count)
    .attr("class", "glyph-label")
    .attr('x', -5)
    .attr('y', -23)
    .attr('fill', 'white')
    .attr('id' , (d) =>'glyph-label_'+ d.name)
    .attr("text-anchor", "middle");



  const paddingLeftRight = 18; // adjust the padding values depending on font and font size
  const paddingTopBottom = 5;

  container.selectAll("rect")
    .attr("x", function(d) { return this.parentNode.childNodes[1].getBBox().x - paddingLeftRight; })
    .attr("y", function(d) { return this.parentNode.childNodes[1].getBBox().y - paddingTopBottom - 6;  })
    .attr("rx", 10)
    .attr("ry", 10)
    .attr("width", function(d) {  return (this.parentNode.childNodes[1].getBBox().width + paddingLeftRight)  ; })
    .attr("height", function(d) { return this.parentNode.childNodes[1].getBBox().height  + paddingTopBottom * 2; })
    .attr('fill', d => {
      if(d.status=='OK'){
        return 'url(#objectGradOk)';
      }
      if(d.status=='KO'){
        return 'url(#objectGradKo)';
      }
      if(d.status=='TO BE PROCESSED'){
        return 'url(#objectGradToBe)';
      }
    })
    .attr('id' , (d) => {
      if(d.type=='object'){
        return d.name + '_' + d.group;
      }
      if(d.type=='task'){
        return d.name ;
      }
      if(d.type=='table'){
        return d.name + '_' + d.parent;
      }
      if(d.type=='cat'){
        return d.name + '_' + d.parent;
      }
    })
    .attr('data-group', d => d.group)
    .attr('filter','url(#shadow)')
    .style('text-align','middle');

  // zoom global chart buttons
  let scaleAvr = 1;
  $('#zoomIn_button').on('click',function(){
    if($('#TreeChartCampain').css('display')=='block') {
      if (scaleAvr < 2) {
        scaleAvr += 0.1;
      } else {
        scaleAvr = 2;
      }
      zoomGroups();
    }
  });
  $('#zoomOut_button').on('click',function(){
    if($('#TreeChartCampain').css('display')=='block') {
      if (scaleAvr > 0.5) {
        scaleAvr -= 0.1;
      } else {
        scaleAvr = 0.5;
      }
      zoomGroups();
    }
  });
  $('#resetSize_button').on('click',function(){
    if($('#TreeChartCampain').css('display')=='block') {
      scaleAvr = 1;
      zoomGroups();
    }
  });
  const zoomGroups =() =>{
    container.transition().duration(100).style('transform', 'scale('+ scaleAvr + ')');
  };
  container.selectAll('text.node-text')
    .attr("x", function(d) { return this.parentNode.childNodes[0].getBBox().x - (this.parentNode.childNodes[0].getBBox().x + 10); })
    .attr('fill','#f8f9fa')
    .attr('id' , (d) =>'text_'+ d.name)
    .style('cursor', 'pointer')
    .attr("y", function(d) { return this.parentNode.childNodes[1].getBBox().y + 2;  });

  simulation
    .nodes(nodes)
    .on("tick", () => {
      ticked(link, node) ;
    })
    .on("end",() => {});
  simulation.force("link")
    .links(links);
};

export const ticked = (link, node) => {

  link
    .attr("x1", function (d) {return d.source.x;})
    .attr("y1", function (d) {return d.source.y;})
    .attr("x2", function (d) {return d.target.x;})
    .attr("y2", function (d) {return d.target.y;});

  node.attr("transform", function (d) {return "translate(" + d.x + ", " + d.y + ")";});

};

export const initDefinitions =(svg) => {
  //green arrow
  svg.append("svg:defs").selectAll("marker")
    .data(["green"])      // Different link/path types can be defined here
    .enter().append("svg:marker")    // This section adds in the arrows
    .attr("id", String)
    .attr("viewBox", "0 -5 10 10")
    .attr("refX", 70)
    .attr("refY", 0)
    .attr("markerWidth", 15)
    .attr("markerHeight", 15)
    .attr("orient", "auto")
    .attr("fill", "green")
    .append("svg:path")
    .attr("d", "M0,-5L10,0L0,5");
  //fin red arrow
  //red arrow
  svg.append("svg:defs").selectAll("marker")
    .data(["red"])      // Different link/path types can be defined here
    .enter().append("svg:marker")    // This section adds in the arrows
    .attr("id", String)
    .attr("viewBox", "0 -5 10 10")
    .attr("refX", 70)
    .attr("refY", 0)
    .attr("markerWidth", 15)
    .attr("markerHeight", 15)
    .attr("orient", "auto")
    .attr("fill", "red")
    .append("svg:path")
    .attr("d", "M0,-5L10,0L0,5");
  //fin red arrow
  //grey arrow
  svg.append("svg:defs").selectAll("marker")
    .data(["grey"])      // Different link/path types can be defined here
    .enter().append("svg:marker")    // This section adds in the arrows
    .attr("id", String)
    .attr("viewBox", "0 -5 10 10")
    .attr("refX", 70)
    .attr("refY", 0)
    .attr("markerWidth", 15)
    .attr("markerHeight", 15)
    .attr("orient", "auto")
    .attr("fill", "grey")
    .append("svg:path")
    .attr("d", "M0,-5L10,0L0,5");
  //fin red arrow
};
//fin blue arrow
export const initFilter = (svg) => {
  svg.append("svg:defs").selectAll('filter')
    .data(['shadow'])
    .enter().append('svg:filter')
    .attr("id", String)
    .attr('y', -10)
    .attr('x', -10)
    .attr('height', 40)
    .attr('width', 150)
    .append('feOffset')
    .attr('in', 'SourceAlpha')
    .attr('dx', 3)
    .attr('dy', 3)
    .attr('result', 'offset2')
    .attr('stdDeviation', 4);
  svg.selectAll('filter')
    .append('feGaussianBlur')
    .attr('in', 'offset2')
    .attr('result', 'blur2')
    .attr('stdDeviation', 3);
  svg.selectAll('filter')
    .append('feMerge')
    .append('feMergeNode')
    .attr('in', 'blur2');
  svg.selectAll('filter')
    .select('feMerge')
    .append('feMergeNode')
    .attr('in', 'SourceGraphic');
};
// gradian color array
export const initGradian = (svg) => {
  svg.append("svg:defs").selectAll('linearGradient')
    .data(['objectGradOk'])
    .enter().append('svg:linearGradient')
    .attr("id", String)
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#04f364')
    .attr('stop-opacity', 1);
  svg.select('#objectGradOk')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#048551')
    .attr('stop-opacity', 1);
  // ko gradient
  svg.append("svg:defs").append('svg:linearGradient')
    .attr("id", 'objectGradKo')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#ea1248')
    .attr('stop-opacity', 1);
  svg.select('#objectGradKo')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#900404')
    .attr('stop-opacity', 1);
  // to be processed gradient
  svg.append("svg:defs").append('svg:linearGradient')
    .attr("id", 'objectGradToBe')
    .attr('x1', '0%')
    .attr('x2', '0%')
    .attr('y1', '0%')
    .attr('y2', '100%')
    .attr('spreadMethod', 'pad')
    .append('stop')
    .attr('offset', '0%')
    .attr('stop-color', '#888888')
    .attr('stop-opacity', 1);
  svg.select('#objectGradToBe')
    .append('stop')
    .attr('offset', '100%')
    .attr('stop-color', '#806969')
    .attr('stop-opacity', 1);
};
export const forceSimulation = (d3: d3, {width, height}) => d3.forceSimulation()
  .force("link",
    d3.forceLink()
      .id(function (d) {return d.id;})
      .distance(70)
      .strength(1))
  .force("charge", d3.forceManyBody().strength(-4000))
  .force("x", d3.forceX(width).strength(1))
  .force("y", d3.forceY(height).strength(1))
  .force('collision', d3.forceCollide())
  .force("center", d3.forceCenter(width / 2, height / 2));
export const handleNodeClicked = (d, nodes, links, app) => {
  const nodeChilds = getNodesByGroup(nodes, d.id);
  const linkChilds = getNodesByGroup(links, d.id);
  if(d.closed){
    $.each( nodeChilds, function( i, val ) {
      $('#objectNode_' + val.id).css('display' , 'block');
      if(val.closed!= undefined){
        const nodesChilds = getNodesByGroup(nodes, val.id);
        const linksChilds = getNodesByGroup(links, val.id);
        $.each( nodesChilds, function( i, data ) {
          $('#objectNode_' + data.id).css('display' , 'none');
        });
        $.each( linksChilds, function( i, data ) {
          $('#objectLink_' + data.id).css('display' , 'none');
        });
      }
    });
    $.each( linkChilds, function( i, val ) {
      $('#objectLink_' + val.id).css('display' , 'block');
    });
  }else{
    $.each( nodeChilds, function( i, val ) {
      $('#objectNode_' + val.id).css('display' , 'none');
      if(val.closed!= undefined){
        const nodesChilds = getNodesByGroup(nodes, val.id);
        const linksChilds = getNodesByGroup(links, val.id);
        $.each( nodesChilds, function( i, data ) {
          $('#objectNode_' + data.id).css('display' , 'none');
        });
        $.each( linksChilds, function( i, data ) {
          $('#objectLink_' + data.id).css('display' , 'none');
        });
      }
    });
    $.each( linkChilds, function( i, val ) {
      $('#objectLink_' + val.id).css('display' , 'none');
    });
  }
  d.closed = !d.closed;
};
export const getNodesByGroup = (nodes, nodeId) => {
  return nodes.reduce((_nodes, node) => {
    if (node.group == nodeId) {
      _nodes.push(node);
    }
    return _nodes;
  }, []);
};
export const toggleGroup = (nodes,open =true) => {
  if(open){
    $.each( nodes, function( i, data ) {
      if(data.closed!=undefined){
        if(!data.closed) {
          document.getElementById('objectNode_' + data.id).dispatchEvent(new Event('click'));
        }
      }
    });
  }else{
    $.each( nodes, function( i, data ) {
      if(data.closed!=undefined){
        if(data.closed) {
          document.getElementById('objectNode_' + data.id).dispatchEvent(new Event('click'));
        }
      }
    });
  }

};
