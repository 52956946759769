import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class GlobaldesignerService {
  username: string;
  userId: string;
  data: string;
  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  // getOptions2() {
  //   return {
  //     headers: new HttpHeaders({
  //       // Accept: 'Application/json',
  //       'Content-Encoding': 'gzip',
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + getCookieValue('tokendrd')
  //     })
  //   };
  // }

  constructor(private http: HttpClient, private router: Router) { }
  // get list users havent role online diagram software
  getUserHaventRoleGlobalDesign(selectedDiagId: string, selectedMenu: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/global-designer/getUserHaventRoleGlobalDesign/' + selectedDiagId + '/' + selectedMenu + '/' + localStorage.getItem('userId'), this.getOptions1());
  }
  // get list all global-diagrams
  getAllGlobalDiagram(): Observable<any> {
    this.username = getCookieValue('username');
    this.userId = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/global-designer/getAllGlobalDiagram/' + this.username + '/' + this.userId, this.getOptions1());
  }
  // get list applications or projects or infrastructures
  getListAppsProjsInfrasBySelectedType(type: string): Observable<any> {
    return this.http.get(Shared.API_URL + '/api/global-designer/getListAppsProjsInfrasBySelectedType/' + type, this.getOptions1());
  }
  // set new empty diag in designer
  setNewGlobalDesignerEmpty(dataDiag: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataDiag);
    return this.http.post<any>(Shared.API_URL + '/api/global-designer/setNewGlobalDesignerEmpty' , data, this.getOptions());
  }
  // delete selected Diagram
  onSetRemoveGlobalDiagram(id: string): Observable<any> {
    return this.http.delete<any>(Shared.API_URL + '/api/global-designer/onSetRemoveGlobalDiagram/' + id, this.getOptions());
  }
  // update name and description global Diagram
  onUpdateInfGlobalDiag(dataDiag: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataDiag);
    return this.http.put<any>(Shared.API_URL + '/api/global-designer/onUpdateInfGlobalDiag', data, this.getOptions());
  }
  /***********************mxGraph****************************/
  // Get content Diagram to EDIT
  getContentGlobalDiag(id: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/global-designer/getContentGlobalDiag/' + id, this.getOptions());
  }
  // update content diag in global designer (from mxGraph)
  setUpdateProjGlobalDesigner(dataDiag: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataDiag);
    return this.http.put<any>(Shared.API_URL + '/api/global-designer/setUpdateProjGlobalDesigner' , data, this.getOptions());
  }
  /*********************** end mxGraph****************************/
  /********************************Shared Diagram**************************************/
  // get list shared diagrams
  getListSharedGlobalDiagramsDesigner(): Observable<any> {
    this.username = getCookieValue('username');
    this.userId = localStorage.getItem('userId');
    return this.http.get(Shared.API_URL + '/api/global-designer/getListSharedGlobalDiagramsDesigner/' + this.userId + '/' + this.username, this.getOptions1());
  }
  /********************************end Shared Diagram**************************************/

  /********************************Diagram Assigned to Application**************************************/
  // get list assigned diagrams to application
  getListAssignedDiagToSelectedApp(application: string): Observable<any> {
    let appEncoded = btoa(application);
    this.username = getCookieValue('username');
    return this.http.get(Shared.API_URL + '/api/global-designer/getListAssignedDiagToSelectedApp/' + appEncoded + '/' + this.username, this.getOptions1());
  }
  /********************************end Diagram Assigned to Application**************************************/

  /********************************Diagram Assigned to Projects**************************************/
  // get list assigned diagrams to project
  getListAssignedDiagToSelectedProj(project: string): Observable<any> {
    let projEncoded = btoa(project);
    return this.http.get(Shared.API_URL + '/api/global-designer/getListAssignedDiagToSelectedProj/' + projEncoded, this.getOptions1());
  }
  /********************************end Diagram Assigned to Application**************************************/

  // get list assigned diagrams to infrastructure
  getListAssignedDiagToSelectedInfras(infras: string): Observable<any> {
    let infrasEncoded = btoa(infras);
    return this.http.get(Shared.API_URL + '/api/global-designer/getListAssignedDiagToSelectedInfras/' + infrasEncoded, this.getOptions1());
  }
  /********************************end Diagram Assigned to Application**************************************/
}
