import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './mainReports/reports.component';
import {ReportsDetailsComponent} from './mainReports/reports-details/reports-details.component';


const routes: Routes = [
    {
        path: '',
        redirectTo: 'main',
        pathMatch: 'full'

    },
    {
        path: 'main',
        component: ReportsComponent
    },
    {
        path: 'reportDetails',
        component: ReportsDetailsComponent
    },
    {
        path: 'reportDetails2',
        component: ReportsDetailsComponent
    }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportsRoutingModule { }
