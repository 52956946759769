import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {getCookieValue, Shared} from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  username = getCookieValue('username');
  userId = localStorage.getItem('userId');
  data: string;
  // function Headers
  getOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }
  getOptions1() {
    return {
      headers: new HttpHeaders({
        Accept: 'Application/json',
        Authorization: 'Bearer ' + getCookieValue('tokendrd')
      })
    };
  }

  constructor(private http: HttpClient, private router: Router) { }
  // search in object model
  searchInObjectModel(textSerach: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/searchInObjectModel/' + textSerach + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  // search in data catalog
  searchInCatalog(textSerach: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/searchInCatalog/' + textSerach + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  // search in data dictionary
  searchInDictionary(textSerach: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/searchInDictionary/' + textSerach + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  // search in data governance/project
  searchInProjects(textSerach: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/searchInProjects/' + textSerach + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  // search in data governance/functional maps
  searchInMaps(textSerach: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/searchInMaps/' + textSerach + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  // search in data governance/data lineage
  searchInLineage(textSerach: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/searchInLineage/' + textSerach + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  // search in data governance/business glossary
  searchInGlossary(textSerach: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/searchInGlossary/' + textSerach + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  // search in data governance/dimension
  searchInDimension(textSerach: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/searchInDimension/' + textSerach + '/' + localStorage.getItem('userId'), this.getOptions());
  }
  // search top 10 visited menu
  getLastTenOpeningObject(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/getLastTenOpeningObject/' + getCookieValue('username'), this.getOptions());
  }
  deleteObjectFromFavorite(item: any): Observable<any> {
    const data = 'data=' + JSON.stringify(item);
    return this.http.post<any>(Shared.API_URL + '/api/search/deleteObjectFromFavorite', data, this.getOptions());
  }
  addObjectToFavorite(item: any): Observable<any> {
    const data = 'data=' + JSON.stringify(item);
    return this.http.post<any>(Shared.API_URL + '/api/search/addObjectToFavorite', data, this.getOptions());
  }
  // set new search by user
  // add new path (navigation router) in DB
  setNewSearchByUser(dataRouter: any): Observable<any> {
    const data = 'data=' + JSON.stringify(dataRouter);
    return this.http.post<any>(Shared.API_URL + '/api/analytics-history/setNewSearchByUser' , data, this.getOptions());
  }
  // list search history by user
  getSearchHistoryByUser(): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/analytics-history/getSearchHistoryByUser/' + getCookieValue('username'), this.getOptions());
  }
  // bar chart horizontal top 10 visited of all users
  getTopTenVisitenToBarChartAll(module: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/getTopTenVisitenToBarChartAll/' + getCookieValue('username') + '/' + module, this.getOptions());
  }
  // word-cloud chart  text--search of all users
  getTextSearchWordCloudChartAll(module: string): Observable<any> {
    return this.http.get<any>(Shared.API_URL + '/api/search/getTextSearchWordCloudChartAll/' + getCookieValue('username') + '/' + module, this.getOptions());
  }
  ////////////////////////////////// tips /////////////////////////////////////////////
  // updateTipsMain(dataPut): Observable<any> {
  //   const data = 'data=' + JSON.stringify(dataPut);
  //   return this.http.put<any>(Shared.API_URL + '/api/analytics/updateTipsMain', data, this.getOptions());
  // }
  ///////////////////////////////// end tips /////////////////////////////////////////
}
