import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {Observable, Subject} from "rxjs";
import {getCookieValue, Shared} from '../../shared';
declare const $: any;
/**
 * This service is a country store
 *
 * See {@link Shared} for details about the main data of this store
 */
@Injectable({
  providedIn: 'root'
})
export class CountryService {
/**
 * This option file for service
 *
 * See {@link Shared} for details about the main data of this store
 */
  OptionFiles()  {
    return {
        headers: new HttpHeaders({
            //'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'Application/json',
            'Authorization': 'Bearer ' + getCookieValue('tokendrd')
            // 'Accept': 'Application/json'
        })
    };
};
/**
 * This option file for service
 *
 * See {@link Shared} for details about the main data of this store
 */
Options = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'Application/json',
        'Authorization': 'Bearer ' + getCookieValue('tokendrd')
        //  'Accept': 'Application/json'
    })
};
headerss = new Headers({
    'Content-Type': 'application/json',
    'Authorization': 'Bearer ' + getCookieValue('tokendrd')
});
constructor(private http: HttpClient, private router: Router) { }
/**
 * This service for   countries Json
 *
 * See {@link Shared} for details about the main data of this store
 */
getListCountry(): Observable<any> {
  const id = window.localStorage.getItem('userId');
  return this.http.get<any>(Shared.API_URL +  `/api/country/getListCountry/${id}`, this.Options);
}
getListCountryForADD(): Observable<any> {

  return this.http.get<any>(Shared.API_URL +  `/api/country/getListCountryForADD`, this.Options);
}
/**
 * This service for  get all countries
 *
 * See {@link Shared} for details about the main data of this store
 */
getListCountryJson(): Observable<any> {
  const id = window.localStorage.getItem('userId');
  return this.http.get<any>(Shared.API_URL +  `/api/country/getListCountryJson/${id}`, this.Options);
}
/**
 * This service for  get circle map
 *
 * See {@link Shared} for details about the main data of this store
 */

getCircleMap(): Observable<any> {

  return this.http.get(Shared.API_URL +  `/api/country/getCircleMap`,  this.Options);

}
/**
 * This service for  delete action
 *
 * See {@link Shared} for details about the main data of this store
 */
deleteCountry(id:string, action: string):Observable<any> {
  return this.http.get<any>(Shared.API_URL + `/api/country/deleteCountry/${id}/${action}`, this.Options);

}
/**
 * This service for  update action
 *
 * See {@link Shared} for details about the main data of this store
 */
updateCountry(d): Observable<any>{
  let formData = new FormData();
    formData.append('data', d);

  return this.http.post(Shared.API_URL + '/api/country/updateCountry' , formData, this.OptionFiles() );


}
// update data by import fron excel
setUpdatexlxs(d): Observable<any>{
  let formData = new FormData();
  formData.append('data', d);
  return this.http.post(Shared.API_URL + '/api/country/addxlscountries' , formData, this.OptionFiles() );
}
/**
 * This service for  add action
 *
 * See {@link Shared} for details about the main data of this store
 */
addCountry(d): Observable<any>{
  let formData = new FormData();
    formData.append('data', d);
// console.log(d);
  return this.http.post(Shared.API_URL + '/api/country/addCountry' , formData, this.OptionFiles());


}
}
