import {BusinessObject} from './BusinessObject';
import {Synonym} from '../functional-map/Synonym';

export class BusinessItem {
  id: string;
  businessId: string;
  name: string;
  objects: any[];
  synonyms: any[];
  antonyms: any[];
  hyperonyms: [];
  description: string;
  effective: string;
  status: string;
  completion: number;
  definition: string;
  example: string;
  oldId: string;
  connectors: any[];
  toAdd: boolean;
  type = 'term';
  cid: string;
  toEdit: boolean;
  constructor(id: string, businessId: string, name: string, objects: any[], synonyms: any[], antonyms: any[], hyperonyms: [], description: string, effective: string, status: string, completion: number, definition: string, example: string, oldId: string, connectors: any[], cid: string, toAdd = false, toEdit: boolean) {
    this.id = id;
    this.businessId = businessId;
    this.name = name;
    this.description = description;
    this.effective = effective;
    this.status = status;
    this.completion = completion;
    this.definition = definition;
    this.example = example;
    this.oldId = oldId;
    this.connectors = connectors;
    this.toAdd = toAdd;
    this.objects = objects;
    this.synonyms = synonyms;
    this.antonyms = antonyms;
    this.hyperonyms = hyperonyms;
    this.cid = cid;
    this.toEdit = toEdit;
  }
}
