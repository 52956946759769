import { Injectable } from '@angular/core';
import {ObjectService} from '../../services/objects/object.service';

@Injectable({
  providedIn: 'root'
})
export class ObjectDownload {
    constructor(private objectService: ObjectService) {
    }
    SecurityRisk(){
        let csv ="";
        let ligne="";
        let lignes=[];
        let i=0;
        this.objectService.getDataRiskApi(window.localStorage.getItem('idSecData')).subscribe(
            data => {
                csv +="Tables" +",";csv +="Description" +",";csv +="Vulnerability score"+",";csv +="Data usage risk level" +",";csv +="Data category" +",";csv +="Compliance" +",";csv +="Vulnerability score"+",";csv +="Data usage risk"+",";csv +="Data usage risk"+",";csv +="Data access"+",";csv +="Data usage risk"+",";csv +="Access Location"+",";csv +="Identifiability"+",";csv +="Quantity"+"\r\n";
                data.data.map(iden => { csv +=iden.usageRiskObject +",";csv +=iden.criticityObject +",";csv +=iden.userobject +",";csv +=iden.objectName +",";csv +=iden.countSensitiveObject+"\r\n"; });
                //Download the file as CSV
                let downloadLink = document.createElement("a");
                let blob = new Blob(["\ufeff", csv]);
                const url = URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = "Security_Risk.csv";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        )
    }
    UsageRisk(Financier,Localisation,Connexion,Identification,Perso,Pro,Politique,Syndicat,Nir,Religion,Ethnie,Biometrie,Sante,Penal,Sexe,Genetique){

        let csv ="";
        let lignes=[];
        let recordslignes=[];
        let i=0;
        let k=0;
        //Title Name
        csv +="Object Name" +",";csv +="Record Name" +",";csv +="Field Name"+",";csv +="Data Classification" +",";csv +="Data usage risk" +",";csv +="Categoy" +"\r\n";
       //Moderate
        //RGBG Identification//collect tab records name
        Identification.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        //collect tab fields name
        Identification.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Identification.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Moderate Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        //Perso//collect tab records name
        lignes=[]; recordslignes=[];i=0; k=0;
        Perso.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Perso.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Perso.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Moderate Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Pro//collect tab records name
        Pro.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Pro.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Pro.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Moderate Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Connexion//collect tab records name
        Connexion.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Connexion.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Connexion.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Moderate Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Financier//collect tab records name
        Financier.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Financier.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Financier.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Moderate Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Localisation//collect tab records name
        Localisation.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Localisation.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Localisation.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Moderate Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Higt
        //Ethnie//collect tab records name
        Ethnie.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Ethnie.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Ethnie.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="High Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Politique//collect tab records name
        Politique.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Politique.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Politique.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="High Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Nir//collect tab records name
        Nir.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Nir.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Nir.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="High Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Religion//collect tab records name
        Religion.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Religion.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Religion.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="High Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Syndicat//collect tab records name
        Syndicat.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Syndicat.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Syndicat.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="High Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Critical
        //Sante//collect tab records name
        Sante.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Sante.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Sante.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Critical Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Sexe//collect tab records name
        Sexe.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Sexe.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Sexe.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Critical Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Genetique//collect tab records name
        Genetique.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Genetique.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Genetique.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Critical Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Biometrie//collect tab records name
        Biometrie.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Biometrie.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Biometrie.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Critical Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );
        lignes=[]; recordslignes=[];i=0; k=0;
        //Penal//collect tab records name
        Penal.map(iden =>{iden.tabletab.map( tab =>{recordslignes.push(tab.table);} )});
        Penal.map(iden =>{iden.tabletab.map( tab =>{tab.champtab.map( champ => {lignes.push(champ.champ) })})});
        Penal.map(iden =>{iden.tabletab.map(tab =>{tab.champtab.map( champ => {csv +=iden.nameObject +",";csv += recordslignes[k]+","+lignes[i]+",";csv +="GDPR" +","; csv +="Critical Risk Data"+","; csv +=iden.category +"\r\n"; i++;});k++;})} );



        //Download the file as CSV
        let downloadLink = document.createElement("a");
        let blob = new Blob(["\ufeff", csv]);
        const url = URL.createObjectURL(blob);
        downloadLink.href = url;
        downloadLink.download = "Usage_Risk.csv";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
   }
    DataMapping(){
        let csv ="";
        let ligne="";
        let lignes=[];
        let i=0;

        this.objectService.getDataMappApi(window.localStorage.getItem('idSecData')).subscribe(

            data => {

                csv +="Objects" +",";csv +="Type" +",";csv +="Order"+",";csv +="Count Mapping Column" +",";csv +="Vulnerability score" +",";csv +="Data usage risk level"+",";csv +="Tables"+",";csv +="Description"+",";csv +="Count Mapping Column"+",";csv +="Vulnerability score"+",";csv +="Data usage risk level"+",";csv +="Fields"+",";csv +="Data Storage"+",";csv +="Data Source"+",";csv +="Data Mapping"+"\r\n";

                data.data.map(iden => { csv +=iden.objectName +",";csv +=iden.typeObject +",";csv +=iden.orderObject +",";csv +=iden.countMapObject +",";csv +=iden.criticityObject+",";csv +=iden.usageRiskObject+",";csv +=iden.usageRiskObject+"\r\n"; });

                //Download the file as CSV
                let downloadLink = document.createElement("a");
                let blob = new Blob(["\ufeff", csv]);
                const url = URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = "Mapping.csv";
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
        )
    }

}
